import { useAssembly } from '../../resources/assembly/assemblyHandler';
import { useHttpQuery } from '../../resources/http/useHttpQuery';
import { nthValueInArray } from '../../utils/typingUtils';

export const useCalculationAssemblyDetails = (assemblyId: string) => {
    const { data: assemblyDetailsData, isLoading: isLoadingAssemblyDetails } = useAssembly(assemblyId);

    const { data: calculationAssemblyDetailsData, isLoading: isLoadingCalculationAssemblyDetails } = useHttpQuery(
        'GET /calculations/calculation-assembly-details',

        { queryParams: { assembly_id: assemblyId } },
    );

    return {
        isLoading: isLoadingAssemblyDetails || isLoadingCalculationAssemblyDetails,
        assemblyDetails: assemblyDetailsData,
        calculationAssemblyDetails:
            isLoadingCalculationAssemblyDetails !== true && calculationAssemblyDetailsData?.data !== undefined
                ? nthValueInArray(calculationAssemblyDetailsData.data, 0)
                : undefined,
    };
};
