import { I18nProvider } from '@lingui/react';
import { AuthProvider, AuthenticatedContextProvider } from '@luminovo/auth';
import { id } from '@luminovo/commons';
import { CenteredLayout, DefaultSnackbarProviderProps } from '@luminovo/design-system';
import { CircularProgress, CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material';
import * as Sentry from '@sentry/react';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { SnackbarProvider } from 'notistack';
import React, { useEffect } from 'react';
import { Route, Router, Switch, useLocation } from 'react-router-dom';
import AuthenticatedApp from './AuthenticatedApp';
import { IntercomWidget } from './IntercomWidget';
import { VersionUpdatedNotifier } from './components/VersionUpdatedNotifier';
import { AppQueryClientProvider } from './components/contexts/AppQueryClientProvider';
import { ModalContextProvider } from './components/contexts/ModalContext';
import { ErrorFallback } from './components/errorHandlers/ErrorBoundary';
import { DevFeatureFlagsProvider } from './devFeatureFlags';
import { PageEnterEmail } from './pages/auth/PageEnterEmail';
import CustomerSignUpPage from './pages/auth/signup/customer';
import SignUpPage from './pages/auth/signup/invite';
import { getCustomerPortalTenant } from './permissions/tenants';
import { themeLuminovo } from './themes';
import { analytics } from './utils/analytics';
import history from './utils/applicationHistory';
import { initializeI18n } from './utils/internationalisation';
import { route } from './utils/routes';

// Auth0 parameters are passed as environment variables and are placed in window
// https://jakobzanker.de/blog/inject-environment-variables-into-a-react-app-docker-on-runtime/
declare global {
    interface Window {
        AUTH0_AUTHORITY: string;
        AUTH0_CLIENT_ID: string;
        MAGIC_BELL_API_KEY: string;
        SENTRY_DSN: string;
        SENTRY_TRACE_SAMPLE_RATE: string;
        SENTRY_RELEASE: string;
        SEGMENT_WRITE_KEY: string;
        INTERCOM_APP_ID: string;
        EPIBATOR_SHA: string;
        LUMINOVO_VERSION: string;

        // this is added by a script in the index.html
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        Intercom: any;
    }
}

const i18n = initializeI18n();

const App: React.FunctionComponent = (): JSX.Element => {
    return (
        <React.StrictMode>
            <I18nProvider i18n={i18n}>
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={themeLuminovo}>
                        <CssBaseline />
                        <Router history={history}>
                            <ScrollToHash />
                            <SnackbarProvider {...DefaultSnackbarProviderProps}>
                                <AppQueryClientProvider>
                                    <AuthProvider
                                        onRedirectCallback={(_, user) => {
                                            if (user) {
                                                analytics.track(id('auth/login_success'));
                                            } else {
                                                analytics.track(id('auth/login_failed'));
                                            }
                                        }}
                                        auth0OrgId={getCustomerPortalTenant()?.auth0OrgId}
                                        history={history}
                                    >
                                        <Sentry.ErrorBoundary fallback={ErrorFallback}>
                                            <Switch>
                                                <Route path={route('/auth/enter-email')} exact>
                                                    <PageEnterEmail />
                                                </Route>
                                                <Route path={route('/auth/signup/invite')} exact>
                                                    <React.Suspense
                                                        fallback={
                                                            <CenteredLayout>
                                                                <CircularProgress />
                                                            </CenteredLayout>
                                                        }
                                                    >
                                                        <Sentry.ErrorBoundary fallback={ErrorFallback}>
                                                            <SignUpPage />
                                                        </Sentry.ErrorBoundary>
                                                    </React.Suspense>
                                                </Route>
                                                <Route path={route('/auth/signup/customer')} exact>
                                                    <React.Suspense
                                                        fallback={
                                                            <CenteredLayout>
                                                                <CircularProgress />
                                                            </CenteredLayout>
                                                        }
                                                    >
                                                        <Sentry.ErrorBoundary fallback={ErrorFallback}>
                                                            <CustomerSignUpPage />
                                                        </Sentry.ErrorBoundary>
                                                    </React.Suspense>
                                                </Route>
                                                <Route>
                                                    <AuthenticatedContextProvider
                                                        onNotAuthenticated={({ redirectTo }) => {
                                                            analytics.track(id('auth/email_form_redirected'));
                                                            history.push(
                                                                route('/auth/enter-email', {}, { redirectTo }),
                                                            );
                                                        }}
                                                        auth0OrgId={getCustomerPortalTenant()?.auth0OrgId}
                                                    >
                                                        <Sentry.ErrorBoundary fallback={ErrorFallback}>
                                                            <DevFeatureFlagsProvider>
                                                                <ModalContextProvider>
                                                                    <VersionUpdatedNotifier />
                                                                    <AuthenticatedApp />
                                                                    <Sentry.ErrorBoundary fallback={ErrorFallback}>
                                                                        <IntercomWidget />
                                                                    </Sentry.ErrorBoundary>
                                                                </ModalContextProvider>
                                                            </DevFeatureFlagsProvider>
                                                        </Sentry.ErrorBoundary>
                                                    </AuthenticatedContextProvider>
                                                </Route>
                                            </Switch>
                                        </Sentry.ErrorBoundary>
                                    </AuthProvider>
                                    <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-left" />
                                </AppQueryClientProvider>
                            </SnackbarProvider>
                        </Router>
                    </ThemeProvider>
                </StyledEngineProvider>
            </I18nProvider>
        </React.StrictMode>
    );
};

function ScrollToHash() {
    const { pathname, hash } = useLocation();

    useEffect(() => {
        if (hash) {
            const element = document.getElementById(hash.replace('#', ''));
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        } else {
            window.scrollTo(0, 0); // Scroll to top if no hash
        }
    }, [pathname, hash]); // Re-run this effect when pathname or hash changes

    return null; // This component doesn't render anything
}

export default App;
