import { t } from '@lingui/macro';
import { CollapsibleSidebar } from '@luminovo/design-system';
import { PersonRounded, SpaceDashboardRounded } from '@mui/icons-material';
import { route } from '../../utils/routes';

export function NavigationSidebarSupplier() {
    return (
        <CollapsibleSidebar
            id="suppliers"
            title={t`Suppliers`}
            intialOpen={false}
            contents={[
                {
                    type: 'link',
                    href: route('/suppliers'),
                    Icon: SpaceDashboardRounded,
                    label: t`Suppliers`,
                },
                {
                    type: 'link',
                    href: route('/suppliers/supplier-contacts'),
                    Icon: PersonRounded,
                    label: t`Supplier contacts`,
                },
            ]}
        />
    );
}
