import * as z from 'zod';
import { ApiIntegrationStatusRuntype } from './3rdPartyBackendTypes';

export interface FutureCredentialsDTO extends z.infer<typeof FutureCredentialsDTORuntype> {}
export const FutureCredentialsDTORuntype = z.object({
    api_key: z.string(),
});

export interface FutureCredentialsInputDTO extends z.infer<typeof FutureCredentialsInputDTORuntype> {}
export const FutureCredentialsInputDTORuntype = z.object({
    api_key: z.string().optional(),
});

export interface FutureResponseBodyDTO extends z.infer<typeof FutureResponseBodyDTORuntype> {}
export const FutureResponseBodyDTORuntype = z.object({
    data: FutureCredentialsDTORuntype.nullable(),
    status: ApiIntegrationStatusRuntype,
});
