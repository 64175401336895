import { InfoRounded } from '@mui/icons-material';
import { Skeleton, styled } from '@mui/material';
import { ReactNode } from 'react';
import { colorSystem, focusedStyles, hoveredStyles } from '../../theme';
import { Flexbox, FlexboxProps } from '../Flexbox';
import { Text } from '../Text';
import { Tooltip } from '../Tooltip';
import { SecondaryButton } from '../buttons';

interface Action {
    label?: string;
    onClick: () => void;
    disabled?: boolean;
    icon?: ReactNode;
}

export interface SummaryCardProps extends FlexboxProps {
    title: string;
    value: string | JSX.Element;
    tooltip?: string;
    description?: string;
    isSelected?: boolean;
    isLoading?: boolean;
    action?: Action;
}

export function SummaryCard({
    title,
    value,
    description,
    tooltip,
    isSelected,
    isLoading,
    action,
    ...boxProps
}: SummaryCardProps): JSX.Element {
    switch (!!description) {
        case true:
            return (
                <LargeSummaryCard
                    title={title}
                    value={value}
                    description={description}
                    tooltip={tooltip}
                    isSelected={isSelected}
                    isLoading={isLoading}
                    action={action}
                    {...boxProps}
                />
            );
        case false:
            return (
                <SmallSummaryCard
                    title={title}
                    value={value}
                    tooltip={tooltip}
                    isSelected={isSelected}
                    isLoading={isLoading}
                    action={action}
                    {...boxProps}
                />
            );
    }
}

const LargeSummaryCard = ({
    title,
    value,
    description,
    tooltip,
    isSelected,
    isLoading,
    action,
    ...boxProps
}: SummaryCardProps) => {
    const isSelectable = isSelected !== undefined;
    switch (isSelectable) {
        case true:
            if (isLoading) {
                return (
                    <LargeContainerSelectable
                        {...boxProps}
                        style={isSelected ? { ...focusedStyles, ...boxProps.style } : { ...boxProps.style }}
                    >
                        <LoadingContainer description={description} />
                    </LargeContainerSelectable>
                );
            }
            return (
                <LargeContainerSelectable
                    {...boxProps}
                    style={isSelected ? { ...focusedStyles, ...boxProps.style } : { ...boxProps.style }}
                >
                    <Flexbox gap="8px" flexDirection="column">
                        <Title title={title} tooltip={tooltip} action={action} />
                        <Text variant="h1" color={colorSystem.neutral[9]}>
                            {value}
                        </Text>
                    </Flexbox>
                    <Text variant="body-small" color={colorSystem.neutral[8]}>
                        {description}
                    </Text>
                </LargeContainerSelectable>
            );
        case false:
            if (isLoading) {
                return (
                    <LargeContainer {...boxProps}>
                        <LoadingContainer description={description} />
                    </LargeContainer>
                );
            }
            return (
                <LargeContainer {...boxProps}>
                    <Flexbox gap="8px" flexDirection="column">
                        <Title title={title} tooltip={tooltip} action={action} />
                        <Text variant="h1" color={colorSystem.neutral[9]}>
                            {value}
                        </Text>
                    </Flexbox>
                    <Text variant="body-small" color={colorSystem.neutral[8]}>
                        {description}
                    </Text>
                </LargeContainer>
            );
    }
};

const SmallSummaryCard = ({ title, value, tooltip, isSelected, isLoading, action }: SummaryCardProps) => {
    const isSelectable = isSelected !== undefined;
    switch (isSelectable) {
        case true:
            if (isLoading) {
                return (
                    <SmallContainerSelectable style={isSelected ? { ...focusedStyles } : {}}>
                        <LoadingContainer />
                    </SmallContainerSelectable>
                );
            }
            return (
                <SmallContainerSelectable style={isSelected ? { ...focusedStyles } : {}}>
                    <Title title={title} tooltip={tooltip} action={action} />
                    <Text variant="h1" color={colorSystem.neutral[9]}>
                        {value}
                    </Text>
                </SmallContainerSelectable>
            );
        case false:
            if (isLoading) {
                return (
                    <SmallContainer>
                        <LoadingContainer />
                    </SmallContainer>
                );
            }
            return (
                <SmallContainer>
                    <Title title={title} tooltip={tooltip} action={action} />
                    <Text variant="h1" color={colorSystem.neutral[9]}>
                        {value}
                    </Text>
                </SmallContainer>
            );
    }
};

const Title = ({ title, tooltip, action }: { title: string; tooltip?: string; action: Action | undefined }) => {
    return (
        <Flexbox gap="4px" justifyContent="space-between">
            <Flexbox gap="4px" alignItems="center">
                <Text variant="h4" color={colorSystem.neutral[7]}>
                    {title}
                </Text>
                {tooltip && (
                    <Tooltip title={tooltip}>
                        <InfoRounded fontSize="inherit" style={{ color: colorSystem.neutral[5] }} />
                    </Tooltip>
                )}
            </Flexbox>
            {action && (
                <SecondaryButton
                    size="small"
                    startIcon={action.icon}
                    disabled={action.disabled}
                    onClick={action.onClick}
                >
                    {action.label}
                </SecondaryButton>
            )}
        </Flexbox>
    );
};

const LoadingContainer = ({ description }: { description?: string }) => {
    return (
        <>
            <Skeleton height="20px" width="45%" />
            <Skeleton height="24px" width="70%" />
            {description && <Skeleton height="20px" width="100%" />}
        </>
    );
};

const Container = styled(Flexbox)({
    background: colorSystem.neutral[0],
    border: `1px solid ${colorSystem.neutral[2]}`,
    borderRadius: '8px',
    width: '100%',
    flexDirection: 'column',
    gap: '8px',
});

const SmallContainer = styled(Container)({
    padding: '20px 20px 24px 20px',
});

const SmallContainerSelectable = styled(SmallContainer)({
    background: colorSystem.neutral.white,
    cursor: 'pointer',
    '&:hover': {
        ...hoveredStyles,
    },
});

const LargeContainer = styled(Container)({
    padding: '20px',
    gap: '4px',
});

const LargeContainerSelectable = styled(LargeContainer)({
    background: colorSystem.neutral.white,
    cursor: 'pointer',
    '&:hover': {
        ...hoveredStyles,
    },
});
