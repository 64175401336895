/* eslint-disable spellcheck/spell-checker */

import { Trans } from '@lingui/macro';
import { intersection, isEqual } from '@luminovo/commons';
import { Card, PrimaryButton, SecondaryButton, colorSystem, useNavigate } from '@luminovo/design-system';
import {
    BomItemApprovalStatus,
    ComplianceStatus,
    DesignItemOriginTypes,
    LifecycleEnum,
    PartCountEnum,
    QuantityUnit,
} from '@luminovo/http-client';
import { Typography } from '@mui/material';
import * as React from 'react';
import { PageLayout } from '../../components/PageLayout';
import { SpinnerWithBackdrop } from '../../components/Spinners';
import { transEnum } from '../../components/localization/TransEnum';
import { useAssembly } from '../../resources/assembly/assemblyHandler';
import { BomItem } from '../../resources/designItem/bomItemFrontendTypes';
import { isPcbDesignItem } from '../../resources/designItem/designItemFunctions';
import { PartSpecificationTypes } from '../../resources/part/PartSpecificationTypes';
import { assemblyTypePublicTranslations } from '../../resources/rfq/i18n';
import { useRfQ } from '../../resources/rfq/rfqHandler';
import { analytics } from '../../utils/analytics';
import { id } from '../../utils/ids';
import { useIsRfqEditable } from '../../utils/rfqUtils';
import { QueryParams, UrlParams, route } from '../../utils/routes';
import { assertUnreachable } from '../../utils/typingUtils';
import { newDesignItemId } from '../Bom/components/ImportBomButton';
import {
    BomItemTableData,
    ModuleTableData,
    ViewContext,
    isAssemblyTableData,
    isBomItemTableData,
} from '../Bom/components/ModuleTableData';
import { useBOMTableData } from '../Bom/components/ModuleTableData/useBOMTableData';
import { BomDetailsSidebar } from './components/BomDetailsSidebar/BomDetailsSidebar';
import { MainContentContainer } from './components/BomSummary/MainContentContainer';
import { ItemDetailsLayout } from './components/ItemDetailsLayout';
import { BomItemForm, PCBDesignItemForm } from './components/SpecificationTypeForms/BomItemForm';
import { ToolbarBomEditPage } from './components/ToolbarBomEditPage';

type FindSelectedModuleProps = {
    modules: ModuleTableData[];
    selectedModuleId: ModuleTableData['id'];
    assemblyId: string;
    rfqId?: string;
    showPartAlternatives: boolean;
};

const findSelectedModule = ({
    modules,
    rfqId,
    selectedModuleId,
    assemblyId,
    showPartAlternatives,
}: FindSelectedModuleProps): ModuleTableData | undefined => {
    if (isEqual(selectedModuleId, [newDesignItemId])) {
        return createEmptyBomItem({ parentId: assemblyId, rfqId, showPartAlternatives });
    }

    const bomItemMatch = modules.find((c) => {
        return isEqual(c.id, selectedModuleId ?? []);
    });

    if (bomItemMatch) {
        return bomItemMatch;
    }

    const assemblyMatch = modules.find((c) => {
        return isEqual([c.id], selectedModuleId);
    });

    if (assemblyMatch) {
        return assemblyMatch;
    }

    return modules.find((c) => intersection(c.id, selectedModuleId).length > 0);
};

export function BomEditPage({
    pathParams,
    queryParams,
}: UrlParams<'/rfqs/:rfqId/bom/assembly/:assemblyId/details'>): JSX.Element {
    const rfqId = pathParams.rfqId;
    const assemblyId = pathParams.assemblyId;
    const currentParentAssemblyId = queryParams.currentParentAssemblyId;

    const { data: rfq } = useRfQ(rfqId);
    const { isRfqEditable } = useIsRfqEditable(rfq?.status, rfq?.is_archived, rfq?.workflow_type);

    const viewContext: ViewContext = React.useMemo(() => {
        return {
            type: 'WithinRfQ',
            rfqId,
        };
    }, [rfqId]);

    return (
        <BomDetails
            viewContext={viewContext}
            assemblyId={assemblyId}
            isEditable={isRfqEditable}
            queryParams={queryParams}
            renderToolBar={(selectedBomItem) => (
                <ToolbarBomEditPage
                    assemblyId={pathParams.assemblyId}
                    rfqId={pathParams.rfqId}
                    selectedModule={selectedBomItem}
                    currentParentAssemblyId={currentParentAssemblyId}
                />
            )}
        />
    );
}

export function BomDetails({
    viewContext,
    assemblyId,
    isEditable,
    renderToolBar,
    queryParams,
}: {
    viewContext: ViewContext;
    assemblyId: string;
    isEditable: boolean;
    renderToolBar: (selectedBomItem: ModuleTableData | undefined) => JSX.Element;
    queryParams: QueryParams<'/rfqs/:rfqId/bom/assembly/:assemblyId/details'>;
}) {
    const rfqId = viewContext.rfqId;
    const { data: assembly } = useAssembly(assemblyId);
    const currentParentAssemblyId = queryParams.currentParentAssemblyId;

    const { moduleData, bomItems, showPartAlternatives } = useBOMTableData({ assemblyId, rfqId });

    const selectedModuleId = queryParams.designItemId?.split(',') ?? undefined;

    const selectedBomItem = React.useMemo(() => {
        return selectedModuleId
            ? findSelectedModule({
                  modules: moduleData ?? [],
                  selectedModuleId,
                  assemblyId,
                  rfqId,
                  showPartAlternatives,
              })
            : undefined;
    }, [assemblyId, moduleData, rfqId, selectedModuleId, showPartAlternatives]);
    // Used because there can't be repeated designators
    const siblingBomItems = bomItems?.filter((c) => !isEqual(c.id, selectedBomItem?.id)) ?? [];

    if (!bomItems) {
        return (
            <PageLayout layout="centered">
                <SpinnerWithBackdrop noBackdrop={true} />
            </PageLayout>
        );
    }

    return (
        <PageLayout
            layout="fragment"
            removeBottomPadding
            header={renderToolBar(selectedBomItem)}
            style={{ background: colorSystem.neutral[1] }}
        >
            <ItemDetailsLayout flexGrow={1}>
                <BomDetailsSidebar
                    assemblyId={assemblyId}
                    assembly={assembly}
                    queryParams={queryParams}
                    viewContext={viewContext}
                    moduleData={moduleData}
                    selectedModuleId={selectedModuleId}
                    selectedBomItem={selectedBomItem}
                />
                {selectedModuleId && (
                    <MainContent
                        key={selectedBomItem?.id.toString()}
                        queryParams={queryParams}
                        viewContext={viewContext}
                        assemblyId={assemblyId}
                        isEditable={isEditable}
                        selectedModule={selectedBomItem}
                        siblingBomItems={siblingBomItems}
                        modules={moduleData}
                        customerId={assembly?.customer ?? undefined}
                        currentParentAssemblyId={currentParentAssemblyId}
                    />
                )}
            </ItemDetailsLayout>
        </PageLayout>
    );
}

const MainContent = React.memo(function MainContent({
    queryParams,
    viewContext,
    assemblyId,
    selectedModule,
    siblingBomItems,
    isEditable,
    modules,
    customerId,
    currentParentAssemblyId,
}: {
    queryParams: QueryParams<'/rfqs/:rfqId/bom/assembly/:assemblyId/details'>;
    viewContext: ViewContext;
    assemblyId: string;
    selectedModule?: ModuleTableData;
    siblingBomItems: BomItem[];
    isEditable: boolean;
    modules: ModuleTableData[];
    customerId?: string;
    currentParentAssemblyId: string | null | undefined;
}) {
    const navigate = useNavigate();
    const rfqId = viewContext.rfqId;
    if (modules.length === 0 && !selectedModule) {
        return (
            <MainContentContainer>
                <Card height="auto" marginTop="15%" marginBottom="10%" gap={16} textAlign={'center'}>
                    <Typography variant="h1" style={{ color: colorSystem.neutral[6] }}>
                        <Trans>No BOM items</Trans>
                    </Typography>
                    <Typography variant="body1" color={'textPrimary'}>
                        <Trans>There are no BOM items in this assembly</Trans>
                    </Typography>
                    {isEditable && rfqId && (
                        <PrimaryButton
                            id={id('design/button_add_placed_component')}
                            onClick={() => {
                                analytics.track('create_bom_item', {
                                    rfq_id: rfqId,
                                    assembly_uuid: assemblyId,
                                });

                                navigate(
                                    route(
                                        '/rfqs/:rfqId/bom/assembly/:assemblyId/details',
                                        { rfqId, assemblyId },
                                        {
                                            isReadonly: null,
                                            designItemId: newDesignItemId,
                                            filters: null,
                                            bomTab: null,
                                            dashboardFilters: null,
                                            search: null,
                                            currentParentAssemblyId,
                                            onlyShowItemsWithManufacturingWarnings: null,
                                        },
                                    ),
                                );
                            }}
                        >
                            <Trans>Add BOM item</Trans>
                        </PrimaryButton>
                    )}
                </Card>
            </MainContentContainer>
        );
    }

    if (!selectedModule) {
        return (
            <MainContentContainer>
                <Card height="auto" style={{ marginTop: '15%', marginBottom: '10%' }}>
                    <Typography variant="h1" color={'textSecondary'}>
                        <Trans>No BOM item selected</Trans>
                    </Typography>
                    <Typography variant="body1">
                        <Trans>Click on any of the items on the left sidebar to select a BOM item.</Trans>
                    </Typography>
                </Card>
            </MainContentContainer>
        );
    }

    if (isAssemblyTableData(selectedModule)) {
        return (
            <MainContentContainer>
                <Card height="auto" style={{ marginTop: '15%', marginBottom: '10%', textAlign: 'center' }}>
                    <Typography variant="h1" style={{ color: colorSystem.neutral[6] }}>
                        <Trans>
                            {transEnum(selectedModule.type, assemblyTypePublicTranslations)} with{' '}
                            {selectedModule.designItems.length} design items
                        </Trans>
                    </Typography>
                    {selectedModule.notes && (
                        <Typography variant="body1" color={'textPrimary'}>
                            {selectedModule.notes}
                        </Typography>
                    )}

                    <SecondaryButton
                        href={
                            viewContext.type === 'WithinRfQ'
                                ? route(
                                      '/rfqs/:rfqId/bom/assembly/:assemblyId',
                                      { assemblyId: selectedModule.id, rfqId: viewContext.rfqId },
                                      { currentParentAssemblyId: assemblyId, tab: null, monitoring: null },
                                  )
                                : route('/assemblies/:assemblyId/dashboard', { assemblyId })
                        }
                    >
                        <Trans>Go to assembly</Trans>
                    </SecondaryButton>
                </Card>
            </MainContentContainer>
        );
    }

    if (isPcbModule(selectedModule) && rfqId) {
        return (
            <MainContentContainer style={{ minWidth: '1052px' }} padding="24px">
                <PCBDesignItemForm
                    rfqId={rfqId}
                    assemblyId={assemblyId}
                    bomItem={selectedModule}
                    isEditable={isEditable}
                    customerId={customerId}
                    currentParentAssemblyId={currentParentAssemblyId}
                />
            </MainContentContainer>
        );
    }

    if (isBomItemTableData(selectedModule)) {
        const uniqueKey = [viewContext.rfqId, assemblyId, isEditable, selectedModule.id.join(',')].join('|');
        return (
            <MainContentContainer
                style={{
                    background: 'unset',
                    minWidth: 'unset',
                    width: 'clamp(1000px, 100%, 2200px)',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                }}
            >
                <BomItemForm
                    key={uniqueKey}
                    queryParams={queryParams}
                    viewContext={viewContext}
                    assemblyId={assemblyId}
                    bomItem={selectedModule}
                    siblingDesignators={siblingBomItems.flatMap((bomItem) => bomItem.designator).sort()}
                    isEditable={isEditable}
                    customerId={customerId}
                    currentParentAssemblyId={currentParentAssemblyId}
                />
            </MainContentContainer>
        );
    }

    assertUnreachable(selectedModule);
});

function isPcbModule(selectedModule: ModuleTableData): boolean {
    return isBomItemTableData(selectedModule) && isPcbDesignItem(selectedModule);
}

function createEmptyBomItem({
    parentId,
    rfqId,
    showPartAlternatives,
}: {
    parentId: string;
    rfqId?: string;
    showPartAlternatives: boolean;
}): BomItemTableData {
    return {
        moduleType: 'bomItem',
        rfqId,
        id: [],
        designator: [],
        quantity: { quantity: 1, unit: QuantityUnit.Pieces },
        doNotPlace: false,
        notes: '',
        parentId,
        optionIds: [],
        specification: {
            type: PartSpecificationTypes.OffTheShelf,
            data: {
                is_manufacturer_free: false,

                part_options: [],
            },
        },
        parts: [],
        approvedPartOptions: [],
        origin: { type: DesignItemOriginTypes.Manual },
        individualDesignItems: [],
        isConsigned: false,
        ignorePackageNameMismatch: false,
        // using same defaults as the backend
        reachCompliant: ComplianceStatus.Unknown,
        rohsCompliant: ComplianceStatus.Unknown,
        lifecycleStatus: LifecycleEnum.Unknown,
        availability: null,
        showPartAlternatives,
        yearsToEndOfLife: null,
        approvedPartOptionCardinality: PartCountEnum.None,
        filterIds: new Set(),
        approvalStatus: BomItemApprovalStatus.Approved,
        issues: [],
        partDataIssues: [],
        generalProperties: [],
        emissionData: null,
        countryOfOrigin: [],
        bomCpns: [],
    };
}
