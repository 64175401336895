import * as z from 'zod';
import { endpoint } from '../http/endpoint';
import {
    CreateOrderInputRuntype,
    InitializePaymentInputRuntype,
    ManyOrderSelectionOptionsRuntype,
    OrderDetailsResponseRuntype,
    OrderOverviewRuntype,
    SalesOrderResponseRuntype,
} from './orderBackendTypes';

export const orderEndpoints = {
    'GET /order-management/order-selection-options': endpoint({
        description: 'Gets all the options for the customer portal that are available to order',
        pathParams: z.undefined(),
        queryParams: z.object({
            rfq_id: z.string(),
        }),
        requestBody: z.undefined(),
        responseBody: ManyOrderSelectionOptionsRuntype,
    }),

    'DELETE /order-management/order-selection-options': endpoint({
        description: 'Deletes the order selection options from the cache to force a recomputation',
        pathParams: z.undefined(),
        queryParams: z.object({
            rfq_id: z.string(),
        }),
        requestBody: z.undefined(),
        responseBody: z.null(),
        // We invalidate and don't remove the GET endpoint because we want to refetch fresh options
        invalidates: ['GET /order-management/order-selection-options'],
    }),

    'POST /order-management/orders/initialize-payment': endpoint({
        description: 'Initializes an order from the customer portal',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: InitializePaymentInputRuntype,
        responseBody: OrderDetailsResponseRuntype,
    }),

    'POST /order-management/orders': endpoint({
        description: 'Places an order from the customer portal',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: CreateOrderInputRuntype,
        responseBody: z.object({ data: SalesOrderResponseRuntype }),
    }),

    'GET /order-management/orders': endpoint({
        description: 'Gets a placed order for an RfQ',
        pathParams: z.undefined(),
        queryParams: z.object({
            rfq_id: z.string(),
        }),
        requestBody: z.undefined(),
        responseBody: z.object({
            data: OrderOverviewRuntype.nullable(),
        }),
    }),
};
