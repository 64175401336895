import * as z from 'zod';
import { endpoint } from '../http/endpoint';
import {
    DigikeyIsAuthenticatedDTORuntype,
    DigikeyRedirectUrlDTORuntype,
    DigiKeySettingDTORuntype,
    DigiKeySupportedSettingDTORuntype,
} from './digikeyBackendTypes';

export const digikeyEndpoints = {
    'GET /3rdparty/digikey/is-authenticated': endpoint({
        description: 'Returns whether or not the user is already authenticated with Digikey',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({ data: DigikeyIsAuthenticatedDTORuntype }),
    }),
    'GET /3rdparty/digikey/auth-redirect-uri': endpoint({
        description: 'Redirects user to the Digikey authorization page',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({ data: DigikeyRedirectUrlDTORuntype }),
    }),
    'GET /3rdparty/digikey/acquire-access-token-from-code': endpoint({
        description: 'Returns auth token',
        pathParams: z.undefined(),
        queryParams: z.object({ auth_code: z.string() }),
        requestBody: z.undefined(),
        responseBody: z.unknown(),
    }),
    'DELETE /3rdparty/digikey/delete-token': endpoint({
        description: 'Deletes digikey token',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.unknown(),
    }),
    'GET /3rdparty/digikey/store-locations': endpoint({
        description: 'Returns possible store locations and currencies',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.array(DigiKeySupportedSettingDTORuntype),
    }),
    'GET /3rdparty/digikey/settings': endpoint({
        description: 'Returns the settings',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: DigiKeySettingDTORuntype,
    }),
    'PATCH /3rdparty/digikey/settings': endpoint({
        description: 'Updates the settings',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: DigiKeySettingDTORuntype,
        responseBody: z.unknown(),
    }),
};
