import { plural, Trans } from '@lingui/macro';
import { assertPresent } from '@luminovo/commons';
import { Flexbox, PrimaryButton, SecondaryButton, Text } from '@luminovo/design-system';
import React from 'react';
import { ImporterCell, ParsedValue } from '../../../../types';
import { usePopup } from '../../../PopupStepper';
import { PopupStep } from '../../../PopupStepper/PopupStepper';
import { useUniversalImporter } from '../../context';
import { CellInput } from './CellInput';

export function useInputPopup({
    cell,
    columnIndex,
    rowIndex,
}: {
    cell?: ImporterCell;
    columnIndex: number;
    rowIndex: number;
}): {
    anchorEl: HTMLElement | null;
    openPopup: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    closePopup: () => void;
    popup: JSX.Element | null;
} {
    const { dispatch, state } = useUniversalImporter();
    const { importerTable } = state;

    type PopupState = {
        text: string;
        value?: ParsedValue<unknown>;
        applyToAll: boolean;
    };

    const steps: PopupStep<PopupState>[] = React.useMemo(() => {
        return [
            {
                canContinue: (state) => {
                    return state.text.trim() !== '';
                },
                Component: ({ setState, state, onNext }) => {
                    return <CellInput cell={assertPresent(cell)} onNext={onNext} setState={setState} state={state} />;
                },
            },
            {
                shouldSkip: (state) => {
                    if (state.value === undefined) {
                        return false;
                    }
                    // If the cell is already unique, we don't need to ask the user
                    // if they want to apply the rule to all matching cells.
                    const identicalMatches = importerTable?.countIdenticalMatches(columnIndex, cell?.text ?? '') ?? 0;
                    return identicalMatches <= 1;
                },
                canContinue: () => true,
                Component: function Component({ onNext, state }) {
                    const matches = importerTable?.countIdenticalMatches(columnIndex, cell?.text ?? '') ?? 0;

                    return (
                        <Flexbox sx={{ padding: 1, flexDirection: 'column', gap: 1 }}>
                            <Text variant="h4">{state.value?.label}</Text>
                            <Text>
                                <Trans>Where do you want to apply this change?</Trans>
                            </Text>

                            <Flexbox sx={{ gap: 1 }}>
                                <SecondaryButton
                                    onClick={() => {
                                        onNext({ ...state, applyToAll: false });
                                    }}
                                    sx={{ flexGrow: 1 }}
                                    size="small"
                                    autoFocus
                                >
                                    <Trans>Only this cell</Trans>
                                </SecondaryButton>
                                <PrimaryButton
                                    onClick={() => {
                                        onNext({ ...state, applyToAll: true });
                                    }}
                                    sx={{ flexGrow: 1 }}
                                    size="small"
                                >
                                    {plural(matches, {
                                        one: `${matches.toString()} match`,
                                        other: `${matches.toString()} matches`,
                                    })}
                                </PrimaryButton>
                            </Flexbox>
                        </Flexbox>
                    );
                },
            },
        ];
    }, [cell, columnIndex, importerTable]);

    return usePopup<PopupState>({
        steps,
        defaultState: { text: cell?.text ?? '', applyToAll: false },
        onSubmit: async (state) => {
            if (!cell || !importerTable) {
                return;
            }

            const newCell: ImporterCell = {
                ...cell,
                text: state.text,
                status: state.value
                    ? {
                          status: 'done',
                          value: state.value,
                          alternatives: cell.status.alternatives,
                      }
                    : {
                          status: 'pending',
                      },
            };

            // Either apply the rule to all matching cells or just this cell
            const table = state.applyToAll
                ? importerTable.setMatching({ column: columnIndex }, cell.text, newCell)
                : importerTable.setCell({ column: columnIndex, row: rowIndex }, newCell);
            dispatch({
                type: 'setImporterTable',
                table,
            });

            const rowRange = state.applyToAll ? { from: 0, to: table.getSize() } : { from: rowIndex, to: rowIndex + 1 };

            const tableAfterParsing = await table?.applyParsers({
                row: rowRange,
                column: { from: columnIndex, to: columnIndex + 1 },
            });

            dispatch({
                type: 'setImporterTable',
                table: tableAfterParsing,
            });
        },
    });
}
