/* eslint-disable camelcase */
import * as z from 'zod';
import { ApiIntegrationStatusRuntype } from './3rdPartyBackendTypes';

export interface SosElectronicCredentialsDTO extends z.infer<typeof SosElectronicCredentialsDTORuntype> {}
export const SosElectronicCredentialsDTORuntype = z.object({
    username: z.string(),
    password: z.string(),
});

export interface SosElectronicCredentialsInputDTO extends z.infer<typeof SosElectronicCredentialsInputDTORuntype> {}
export const SosElectronicCredentialsInputDTORuntype = z.object({
    username: z.string().optional(),
    password: z.string().optional(),
});

export interface SosElectronicResponseBodyDTO extends z.infer<typeof SosElectronicResponseBodyDTORuntype> {}
export const SosElectronicResponseBodyDTORuntype = z.object({
    data: SosElectronicCredentialsDTORuntype.nullable(),
    status: ApiIntegrationStatusRuntype,
});
