import * as z from 'zod';

export const CalculationAssemblyPostRuntype = z.object({
    assembly_id: z.string(),
    notes: z.string().nullable(),
    include_excess_material_in_material_cost: z.boolean(),
});

export type CalculationAssemblyPostDTO = z.infer<typeof CalculationAssemblyPostRuntype>;

export const CalculationAssemblyResponseRuntype = z.object({
    id: z.string(),
    notes: z.string().nullable(),
    assembly_id: z.string(),
    created_from_calculation_template_id: z.string().nullable(),
    include_excess_material_in_material_cost: z.boolean(),
    include_one_time_cost_in_project_cost: z.boolean(),
    include_project_cost_in_manufacturing_cost: z.boolean(),
    last_template_reset_at: z.string().nullable(),
    created_at: z.string(),
    updated_at: z.string().nullable(),
});

export type CalculationAssemblyDTO = z.infer<typeof CalculationAssemblyResponseRuntype>;
