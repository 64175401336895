import { t, Trans } from '@lingui/macro';
import {
    colorSystem,
    FieldNumericControlled,
    FieldTextControlled,
    Flexbox,
    FormItem,
    SecondaryButton,
    Tooltip,
} from '@luminovo/design-system';
import { ManufacturingScenarioTemplateDTO } from '@luminovo/http-client';
import { InfoRounded } from '@mui/icons-material';
import { Box } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { FormContainer, ValidationErrors } from '../../../components/formLayouts/FormContainer';
import { SubmitButton } from '../../../components/formLayouts/SubmitButton';
import { useHttpMutation } from '../../../resources/mutation/useHttpMutation';

const validationErrors: ValidationErrors<TemplateFormFields> = {
    'manufacturing_scenario_template.already_exists': {
        fieldPath: 'name',
    },
};
export interface TemplateFormFields {
    name: string;
    notes: string | undefined;
    manufacturingLeadTime: number | undefined;
}

const TemplateFormInner = ({ onCloseCallback }: { onCloseCallback: () => void }) => {
    const { control } = useFormContext<TemplateFormFields>();
    return (
        <Flexbox flexDirection={'column'} gap={16}>
            <FormItem label={t`Name`} required>
                <FieldTextControlled name={'name'} control={control} required />
            </FormItem>
            <FormItem label={t`Notes`}>
                <FieldTextControlled name={'notes'} control={control} FieldProps={{ multiline: true, rows: 3 }} />
            </FormItem>

            <FormItem
                label={
                    <Flexbox gap={4}>
                        <Trans>Manufacturing lead time (in days) </Trans>
                        <Tooltip
                            title={t`The total number of days (including non-working days) it takes to manufacture an assembly using this template. The manufacturing lead time is included in the customer portal lead time.`}
                        >
                            <InfoRounded fontSize="small" style={{ color: colorSystem.neutral[5] }} />
                        </Tooltip>
                    </Flexbox>
                }
            >
                <FieldNumericControlled name={'manufacturingLeadTime'} control={control} min={-10_000} max={10_000} />
            </FormItem>

            <Flexbox mt={5} gap={8} justifyContent={'flex-end'}>
                <SecondaryButton onClick={onCloseCallback}>
                    <Trans>Cancel</Trans>
                </SecondaryButton>
                <Box style={{ marginLeft: '8px' }}>
                    <SubmitButton />
                </Box>
            </Flexbox>
        </Flexbox>
    );
};
export const AddManufacturingScenarioTemplateForm = ({
    onCloseCallback,
}: {
    onCloseCallback: () => void;
}): JSX.Element => {
    const { mutateAsync } = useHttpMutation('POST /manufacturing-scenario-templates', {
        snackbarMessage: t`Template created successfully`,
        onSuccess: onCloseCallback,
    });
    const onSubmit = async (data: TemplateFormFields) => {
        await mutateAsync({
            requestBody: {
                name: data.name,
                notes: data.notes ?? null,
                status: 'Inactive',

                ordered_activities: [],
                manufacturing_lead_time: data.manufacturingLeadTime ?? null,
                ordered_expenses: [],
            },
        });
    };
    return (
        <FormContainer onSubmit={onSubmit} validationErrors={validationErrors}>
            <TemplateFormInner onCloseCallback={onCloseCallback} />
        </FormContainer>
    );
};

export const EditManufacturingScenarioTemplateForm = ({
    template,
    onCloseCallback,
}: {
    template: ManufacturingScenarioTemplateDTO;
    onCloseCallback: () => void;
}): JSX.Element => {
    const { mutateAsync } = useHttpMutation('PATCH /manufacturing-scenario-templates/:templateId', {
        snackbarMessage: t`Template created successfully`,
        onSuccess: onCloseCallback,
    });
    const onSubmit = async (data: TemplateFormFields) => {
        await mutateAsync({
            pathParams: { templateId: template.id },
            requestBody: {
                name: data.name,
                notes: data.notes ?? null,

                manufacturing_lead_time: data.manufacturingLeadTime ?? null,
            },
        });
    };
    const defaultValues: Partial<TemplateFormFields> = {
        ...template,
        notes: template.notes === null ? undefined : template.notes,
        manufacturingLeadTime: template.manufacturing_lead_time ?? undefined,
    };
    return (
        <FormContainer onSubmit={onSubmit} defaultValues={defaultValues} validationErrors={validationErrors}>
            <TemplateFormInner onCloseCallback={onCloseCallback} />
        </FormContainer>
    );
};
