import { t } from '@lingui/macro';
import { isPresent, transEnum } from '@luminovo/commons';
import {
    CenteredLayout,
    Dropzone,
    FieldSelectControlled,
    FieldTextControlled,
    Flexbox,
    FormItem,
    FormSection,
} from '@luminovo/design-system';
import { ComplianceStatus, CustomFullPart, CustomPartUpdateInput } from '@luminovo/http-client';
import { ComplianceStatusTranslations } from '@luminovo/sourcing-core';
import { CircularProgress, styled } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { useFormContext, useWatch } from 'react-hook-form';
import { CancelButton } from '../../../../../components/formLayouts/CancelButton';
import { FormContainer } from '../../../../../components/formLayouts/FormContainer';
import { SubmitButton } from '../../../../../components/formLayouts/SubmitButton';
import { uploadFileToBlobStorage } from '../../../../../resources/http/useHttpFileUpload';
import { useHttpMutation } from '../../../../../resources/mutation/useHttpMutation';
import {
    useCustomPart,
    useCustomPartGetUploadFilesUrl,
    useCustomPartResources,
} from '../../../../../resources/part/partHandler';
import { getFileNameFromResourcesString } from '../../../../../utils/stringFunctions';

interface CustomFormValues {
    description: string | undefined;
    rohsCompliant: ComplianceStatus;
    reachCompliant: ComplianceStatus;
    files: Array<LocalFile | AzureFile>;
}

interface LocalFile {
    url: string;
    name: string;
    file: File;
    state: 'PendingUpload' | 'Removed';
}

interface AzureFile {
    url: string;
    name: string;
    file: undefined;
    state: 'StoredInAzure' | 'PendingRemoval';
}

function convertToInitialFormValues(part: CustomFullPart, resources: string[]): CustomFormValues {
    const files = resources.map((resource) => ({
        state: 'StoredInAzure' as const,
        file: undefined,
        url: resource,
        name: getFileNameFromResourcesString(resource),
    }));

    return {
        description: part.description !== null ? part.description : undefined,
        rohsCompliant: part.rohs_compliant,
        reachCompliant: part.reach_compliant,
        files,
    };
}

function generatePartUpdateFormData(
    formValues: CustomFormValues,
    initialFormValues: CustomFormValues,
): CustomPartUpdateInput {
    const result: CustomPartUpdateInput = {
        type: null,
        description: null,
        rohs_compliant: null,
        reach_compliant: null,
        package: null,
    };

    if (formValues.description && formValues.description !== initialFormValues.description) {
        result['description'] = formValues.description;
    }

    if (formValues.reachCompliant && formValues.reachCompliant !== initialFormValues.reachCompliant) {
        result['reach_compliant'] = formValues.reachCompliant;
    }

    if (formValues.rohsCompliant && formValues.rohsCompliant !== initialFormValues.rohsCompliant) {
        result['rohs_compliant'] = formValues.rohsCompliant;
    }

    return result;
}

export const CustomPartEditForm = ({ customPartId, onClose }: { customPartId: string; onClose: () => void }) => {
    const { data: customPartDTO } = useCustomPart(customPartId);
    const { data: resources } = useCustomPartResources(customPartId);
    const { data: uploadUrl } = useCustomPartGetUploadFilesUrl(customPartId);

    const { mutateAsync: deleteFileAsync } = useHttpMutation('DELETE /parts/custom/:partId/files', {
        snackbarMessage: t`File deleted`,
    });
    const { mutateAsync } = useHttpMutation('PATCH /parts/custom/:partId', {
        snackbarMessage: t`Custom part updated`,
        onSuccess: () => onClose(),
    });

    if (!isPresent(customPartDTO) || !isPresent(resources) || !isPresent(uploadUrl)) {
        return (
            <CenteredLayout height="50vh">
                <CircularProgress />
            </CenteredLayout>
        );
    }

    const defaultValues = convertToInitialFormValues(customPartDTO, resources);
    const handleSubmit = async (values: CustomFormValues): Promise<void> => {
        const requestBody = generatePartUpdateFormData(values, defaultValues);

        // TODO: We should find a generic way to handle this, as this is a common pattern. @Rene
        await Promise.allSettled(
            values.files.map(async (file) => {
                switch (file.state) {
                    case 'PendingUpload':
                        return uploadFileToBlobStorage(file.file, uploadUrl);
                    case 'PendingRemoval':
                        return deleteFileAsync({
                            pathParams: { partId: customPartId },
                            queryParams: { filename: file.name },
                        });
                    default:
                        return;
                }
            }),
        );
        await mutateAsync({ pathParams: { partId: customPartId }, requestBody });
    };

    return (
        <FormContainer defaultValues={defaultValues} onSubmit={handleSubmit} UNSAFE_disableStablePropCheck>
            <CustomPartForm onCancel={onClose} />
        </FormContainer>
    );
};

const CustomPartForm = ({ onCancel }: { onCancel: () => void }) => {
    const { control } = useFormContext<CustomFormValues>();

    return (
        <Flexbox flexDirection={'column'}>
            <Flexbox flexDirection={'column'} paddingRight={'180px'}>
                <FormSection title={t`Part overview`}>
                    <FormItem label={t`Description`}>
                        <FieldTextControlled
                            control={control}
                            name={'description'}
                            FieldProps={{
                                placeholder: t`Description`,
                                multiline: true,
                            }}
                        />
                    </FormItem>
                </FormSection>
                <FormSection title={t`Compliance`}>
                    <FormItem label={'RoHS'}>
                        <FieldSelectControlled
                            control={control}
                            name={'rohsCompliant'}
                            FieldProps={{
                                options: Object.values(ComplianceStatus),
                                getOptionLabel: (option) => transEnum(option, ComplianceStatusTranslations),
                                disableClearable: true,
                            }}
                        />
                    </FormItem>
                    <FormItem label={'REACH'}>
                        <FieldSelectControlled
                            control={control}
                            name={'reachCompliant'}
                            FieldProps={{
                                options: Object.values(ComplianceStatus),
                                getOptionLabel: (option) => transEnum(option, ComplianceStatusTranslations),
                                disableClearable: true,
                            }}
                        />
                    </FormItem>
                </FormSection>
                <FormSection title={t`Files`}>
                    <FilesUploader />
                </FormSection>
            </Flexbox>
            <Flexbox gap={8} paddingTop={'24px'} justifyContent={'flex-end'}>
                <CancelButton onClick={onCancel} />
                <SubmitButton />
            </Flexbox>
        </Flexbox>
    );
};

const forbiddenMIMETypes: string[] = ['application/x-ms-dos-executable', 'application/octet-stream'];

const FilesUploader = () => {
    const { control, setValue } = useFormContext<CustomFormValues>();
    const files = useWatch({ control, name: 'files' });

    const handleDelete = (file: LocalFile | AzureFile) => {
        setValue(
            'files',
            files.map((f) => {
                if (f.url === file.url) {
                    if (f.file) {
                        f.state = 'Removed';
                    } else {
                        f.state = 'PendingRemoval';
                    }
                }
                return f;
            }),
        );
    };

    const handleChangeDropzone = (innerFiles: File[]) => {
        const filesWithUrl = innerFiles.flatMap((file) => {
            if (forbiddenMIMETypes.includes(file.type)) {
                enqueueSnackbar(t`Cannot upload unsupported file type`, { variant: 'error' });
                return [];
            }
            const url = URL.createObjectURL(file);

            const filesWithUrl = {
                file,
                url,
                name: file.name,
                state: 'PendingUpload' as const,
            };
            return [filesWithUrl];
        });

        setValue('files', [...files, ...filesWithUrl]);
    };

    const renderFiles = files.filter((file) => file.state === 'StoredInAzure' || file.state === 'PendingUpload');
    return (
        <Dropzone
            multiple
            title=""
            onDropAccepted={handleChangeDropzone}
            accept={null}
            persistentFiles={renderFiles.map((file) => ({
                name: file.name,
                onDelete: () => handleDelete(file),
                onClick: () => window.open(file.url, '_blank'),
            }))}
            overrides={{
                Container: DropzoneContainer,
            }}
        />
    );
};

const DropzoneContainer = styled(Flexbox)({
    flexDirection: 'column',
});
