import { endpointRegistry } from './endpointRegistry';

import { printFormattedRuntypeError } from '../../utils/runtypeUtils';
import { RegisteredHttpEndpoint } from './types/httpTypes';

export function composeRuntypeAssertionErrorMessage({
    endpoint,
    url,
    method,
    requestBody,
    responseBody,
    runtypeError,
}: {
    endpoint: string;
    url: string;
    method: string;
    requestBody: unknown;
    responseBody: unknown;
    runtypeError: unknown;
}): string {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const description = endpointRegistry[endpoint as RegisteredHttpEndpoint]?.description || '';

    return `Schema validation failed!

Endpoint      : ${endpoint}
Description   : ${description}

Error message :
${printFormattedRuntypeError(runtypeError)}

Here's the relevant information:

URL           : ${url}
Method        : ${method}
Request Body  :
${requestBody === undefined ? 'None' : JSON.stringify(requestBody, null, 2)}
Response Body :
${responseBody === undefined ? 'None' : JSON.stringify(responseBody, null, 2)}
`;
}
