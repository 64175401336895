import { useDownloadPCBFiles, useExportPcbSpecificationInPDF } from '@/resources/export/exportHandler';
import { downloadPcbFilesAnalytic, downloadPcbPDFAnalytic } from '@/resources/pcb/analytics/analytic';
import { t } from '@lingui/macro';
import { MenuButton, MenuItem, Toolbar } from '@luminovo/design-system';
import { PCBV2 } from '@luminovo/http-client';
import { Delete, GetApp, MoreHoriz, PictureAsPdf } from '@mui/icons-material';
import React from 'react';
import { AssemblyMenuButton } from '../../../../components/AssemblyMenuButton';
import { ProgressAwareTabs } from '../../../../components/ProgressAwareTabs';
import { useAssembly, useBomBreadcrumbs } from '../../../../resources/assembly/assemblyHandler';
import { usePcbOfAssembly } from '../../../../resources/pcb/pcbHandlers';
import { useRfQ } from '../../../../resources/rfq/rfqHandler';
import { useIsRfqEditable } from '../../../../utils/rfqUtils';
import { ViewContext } from '../../../Bom/components/ModuleTableData';
import { DeletePCBFilesButton } from './components/DeletePCBFilesButton';
import { DownloadPCBFilesButton } from './components/DownloadPCBFilesButton';
import { ExportSpecificationInPDFButton } from './components/ExportSpecificationInPDFButton';
import { PcbAlertCenter } from './components/PcbAlertsCenter';
import { PcbCommentsButton } from './components/PcbCommentsButton';
import { useDeletePCB } from './utils/useDeletePCBFiles';

type ToolbarPcbProps = {
    assemblyId: string;
    rfqId: string;
    currentParentAssemblyId?: string | null | undefined;
};

const PcbMenuButton = ({
    pcb,
    rfqId,
    assemblyId,
    isRfqEditable,
}: {
    pcb: PCBV2;
    rfqId: string;
    assemblyId: string;
    isRfqEditable: boolean;
}) => {
    const { mutateAsync: exportSpecificationInPDF, isLoading: isExportingSpecificationInPDF } =
        useExportPcbSpecificationInPDF(pcb, {
            onSuccess: () => {
                downloadPcbPDFAnalytic({
                    pcbId: pcb.id,
                    rfqId,
                    assemblyId,
                });
            },
        });

    const { downloadPCBFiles, isLoading: isDownloadingPCBFiles } = useDownloadPCBFiles({
        pcbId: pcb.id,
        options: {
            onSuccess: () => {
                downloadPcbFilesAnalytic({
                    pcbId: pcb.id,
                    rfqId,
                    assemblyId,
                });
            },
        },
    });

    const {
        dialog,
        onClick: deletePcbSpecification,
        isLoading: isDeleteInProgress,
    } = useDeletePCB({
        pcbId: pcb.id,
        assemblyId,
        rfqId,
    });

    const menuButtonOptions: {
        label: string;
        onClick: () => void;
        icon: JSX.Element;
        enabled: boolean;
    }[] = [
        {
            label: t`Export specification in PDF`,
            onClick: () => exportSpecificationInPDF(),
            icon: <PictureAsPdf fontSize="inherit" />,
            enabled: isExportingSpecificationInPDF ? false : true,
        },
        {
            label: t`Download PCB files`,
            onClick: () => downloadPCBFiles(),
            icon: <GetApp fontSize="inherit" />,
            enabled: isDownloadingPCBFiles ? false : true,
        },
        {
            label: t`Delete PCB specification`,
            onClick: () => deletePcbSpecification(),
            icon: <Delete fontSize="inherit" />,
            enabled: isRfqEditable && !isDeleteInProgress,
        },
    ];

    return (
        <>
            <MenuButton appearance="secondary" icon={<MoreHoriz />} size="medium">
                {menuButtonOptions.map((option) => (
                    <MenuItem
                        key={option.label}
                        onClick={(event) => {
                            event.stopPropagation();
                            option.onClick();
                        }}
                        startIcon={option.icon}
                        variant="primary"
                        label={option.label}
                        disabled={!option.enabled}
                    />
                ))}
            </MenuButton>
            {dialog}
        </>
    );
};

export function ToolbarPcb({ assemblyId, rfqId, currentParentAssemblyId }: ToolbarPcbProps) {
    const { data: pcb } = usePcbOfAssembly({ assemblyId });
    const { data: assembly } = useAssembly(assemblyId);
    const { data: rfq } = useRfQ(rfqId);
    const { isRfqEditable } = useIsRfqEditable(rfq?.status, rfq?.is_archived, rfq?.workflow_type);
    const isPcbOnlyAssembly = assembly?.type.type === 'PcbOnly';

    const breadcrumbs = useBomBreadcrumbs({ assemblyId, rfqId, assembly, currentParentAssemblyId });
    const viewContext: ViewContext = React.useMemo(() => {
        return {
            type: 'WithinRfQ',
            rfqId,
        };
    }, [rfqId]);

    return (
        <Toolbar
            breadcrumbs={[...breadcrumbs, { title: t`PCB` }]}
            center={
                assembly &&
                assembly.type.type !== 'PcbOnly' && (
                    <ProgressAwareTabs
                        rfqId={rfqId}
                        assembly={assembly}
                        viewContext={viewContext}
                        currentParentAssemblyId={currentParentAssemblyId}
                    />
                )
            }
        >
            {isPcbOnlyAssembly && (
                <AssemblyMenuButton
                    assemblyId={assemblyId}
                    viewContext={viewContext}
                    currentParentAssemblyId={currentParentAssemblyId}
                />
            )}
            {pcb && <ExportSpecificationInPDFButton pcb={pcb} assemblyId={assemblyId} rfqId={rfqId} />}
            {pcb && <DownloadPCBFilesButton pcb={pcb} assemblyId={assemblyId} rfqId={rfqId} />}
            {pcb && isRfqEditable && <DeletePCBFilesButton pcb={pcb} assemblyId={assemblyId} rfqId={rfqId} />}
            {pcb && <PcbAlertCenter pcbId={pcb.id} assemblyId={assemblyId} rfqId={rfqId} viewContext={viewContext} />}
            <PcbCommentsButton assemblyId={assemblyId} rfqId={rfqId} />
            {pcb && <PcbMenuButton pcb={pcb} rfqId={rfqId} assemblyId={assemblyId} isRfqEditable={isRfqEditable} />}
        </Toolbar>
    );
}
