import { Validated } from './validate';

export function validateArray<T>(array: Validated<T>[]): Validated<T[]> {
    const values: T[] = [];
    for (const item of array) {
        if (item.status === 'success') {
            values.push(item.value);
        }
        if (item.status === 'error') {
            return { status: 'error', message: item.message };
        }
        if (item.status === 'pending') {
            return { status: 'pending' };
        }
    }
    return { status: 'success', value: values };
}
