import { t } from '@lingui/macro';
import { formatDecimal } from '@luminovo/commons';
import { Dialog, DialogContent, PrimaryButton, PropertiesTable, SecondaryButton, Text } from '@luminovo/design-system';
import { Box } from '@mui/material';

export function DialogImportDone({
    totalRecords,
    successfullyImported,
    failedToImport,
    onViewImportedRecords = () => {},
    onClose = () => {},
    onRestartImport = () => {},
}: {
    totalRecords: number;
    successfullyImported: number;
    failedToImport: number;
    onViewImportedRecords?: () => void;
    onClose?: () => void;
    onRestartImport?: () => void;
}): JSX.Element {
    const states = {
        success: {
            title: t`Import successful`,
            message: t`All records have been successfully imported. Your data is now up to date and ready to be used.`,
            primary: {
                label: t`View imported records`,
                onClick: onViewImportedRecords,
            },
            secondary: {
                label: t`Close`,
                onClick: onClose,
            },
            showSummary: false,
        },
        partialSuccess: {
            title: t`Import partially successful`,
            message: t`Some lines could not be imported due to errors. You can review these lines and try importing them again.`,
            primary: {
                label: t`Review errors`,
                onClick: onClose,
            },
            secondary: {
                label: t`View imported records`,
                onClick: onViewImportedRecords,
            },
            showSummary: true,
        },
        failure: {
            title: t`Import failed`,
            message: t`Unfortunately, none of the lines could be imported successfully.`,
            primary: {
                label: t`Review errors`,
                onClick: onClose,
            },
            secondary: {
                label: t`Restart imports`,
                onClick: onRestartImport,
            },
            showSummary: false,
        },
    };

    const state =
        failedToImport === totalRecords
            ? states.failure
            : failedToImport === 0
              ? states.success
              : states.partialSuccess;

    return (
        <Dialog maxWidth="xs" open title={state.title} onClose={onClose}>
            <DialogContent
                sx={{
                    display: 'flex',
                    gap: 2,
                    flexDirection: 'column',
                    padding: 3,
                }}
            >
                <Text>{state.message}</Text>
                {state.showSummary && (
                    <PropertiesTable
                        variant="side-by-side"
                        properties={{
                            [t`Total records`]: formatDecimal(totalRecords),
                            [t`Successfully imported`]: formatDecimal(successfullyImported),
                            [t`Failed to import`]: formatDecimal(failedToImport),
                        }}
                    />
                )}
                <Box
                    sx={{
                        display: 'grid',
                        gap: 1,
                        gridTemplateColumns: '1fr 1fr',
                    }}
                >
                    <SecondaryButton size="medium" onClick={state.secondary.onClick}>
                        {state.secondary.label}
                    </SecondaryButton>
                    <PrimaryButton size="medium" onClick={state.primary.onClick}>
                        {state.primary.label}
                    </PrimaryButton>
                </Box>
            </DialogContent>
        </Dialog>
    );
}
