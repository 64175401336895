import * as z from 'zod';

export type EmailType = z.infer<typeof EmailTypeRuntype>;
export const EmailTypeRuntype = z.literal('QuoteRequest');

export interface EmailTemplateDTO extends z.infer<typeof EmailTemplateDTORuntype> {}
export const EmailTemplateDTORuntype = z.object({
    id: z.string(),
    name: z.string(),
    email_type: EmailTypeRuntype,
    suppliers: z.array(z.string()),
    is_default: z.boolean(),
    subject: z.string(),
    body: z.string(),
    updated_at: z.string(),
    ccs: z.array(z.string()),
});
