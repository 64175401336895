import * as z from 'zod';

export const ScenarioCombinationForCalculationRuntype = z.object({
    batch_sizes: z.array(z.number()),
    order_size: z.number(),
    manufacturing_scenario: z.string(),
    sourcing_scenario: z.string(),
    assembly_id: z.string(),
});

export type ScenarioCombinationForCalculationDTO = z.infer<typeof ScenarioCombinationForCalculationRuntype>;

export const ScenarioCombinationForCalculationRuntypePatch = z.object({
    batch_sizes: z.array(z.number()),
    manufacturing_scenario_id: z.string(),
    sourcing_scenario_id: z.string(),
});

export const ScenarioCombinationForCalculationRuntypePatchResponse = z.object({
    batch_sizes: z.array(z.number()),
    manufacturing_scenario: z.string(),
    sourcing_scenario: z.string(),
    id: z.string(),
});

export type ScenarioCombinationForCalculationPatchDTO = z.infer<typeof ScenarioCombinationForCalculationRuntypePatch>;
