import * as z from 'zod';
import { CurrencyRuntype, MonetaryValueBackendRuntype } from '../backendTypes';
import { DriverIdRuntype } from '../driver';
import { CategoryRuntype, ManufacturingEntityStatusRuntype, ProcessRuntype } from '../sharedManufacturingBackendTypes';

export const ExpenseCostCalculationTypeRuntype = z.union([
    z.literal('Linear'),
    z.literal('Fixed'),
    z.literal('Formula'),
]);
export type ExpenseCostCalculationType = z.infer<typeof ExpenseCostCalculationTypeRuntype>;

const LinearVariableCost = z.object({
    driver: DriverIdRuntype,
    variable_unit: MonetaryValueBackendRuntype,
});

const LinearCostComponentRuntype = z.object({
    type: z.literal('Linear'),
    details: z.object({
        fixed_cost: MonetaryValueBackendRuntype.nullable(),
        variable_cost: LinearVariableCost,
    }),
});

const FormulaCostComponentRuntype = z.object({
    type: z.literal('Formula'),
    details: z.object({
        formula: z.string(),
        currency: CurrencyRuntype,
    }),
});

const FixedCostComponentRuntype = z.object({
    type: z.literal('Fixed'),
    details: z.object({
        fixed_cost: MonetaryValueBackendRuntype,
    }),
});

export type CostComponentFixedCost = z.infer<typeof FixedCostComponentRuntype>;
export type CostComponentLinearCost = z.infer<typeof LinearCostComponentRuntype>;
export type CostComponentFormulaCost = z.infer<typeof FormulaCostComponentRuntype>;

export const ExpenseLevelRuntype = z.union([z.literal('Project'), z.literal('Unit'), z.literal('Batch')]);
export const ALL_EXPENSE_LEVELS = ExpenseLevelRuntype.options.map((alternative) => alternative.value);

export type ExpenseLevel = z.infer<typeof ExpenseLevelRuntype>;

//follows a similar structure to TimeComponentsRuntype but for costs
const ExpensesCostComponentRuntype = z.union([
    FixedCostComponentRuntype,
    LinearCostComponentRuntype,
    FormulaCostComponentRuntype,
]);

export type ExpensesCostComponent = z.infer<typeof ExpensesCostComponentRuntype>;

export const ExpenseRuntype = z.object({
    id: z.string(),
    name: z.string(),
    internal_number: z.string().nullable(),
    description: z.string().nullable(),
    level: ExpenseLevelRuntype,
    process: ProcessRuntype,
    category: CategoryRuntype,
    cost_components: ExpensesCostComponentRuntype,
    status: ManufacturingEntityStatusRuntype,
    site_id: z.string().nullable(),
});

export type ExpenseDTO = z.infer<typeof ExpenseRuntype>;

export const ExpensePostRuntype = ExpenseRuntype.omit({ id: true, status: true });
export type ExpensePostDTO = z.infer<typeof ExpensePostRuntype>;
