import { Trans } from '@lingui/macro';
import { RightBoxDrawer, Text } from '@luminovo/design-system';
import { CloseDrawerButton, useDrawerContext } from '../../components/contexts/ModalContext';
import { useHttpQuery } from '../../resources/http/useHttpQuery';
import TimeLineItem from './TimeLineItems';
import LoadingTimeline from './TimeLineItems/LoadingTimeline';
import { HistoryOfChangesContainer, StyledTimeLine } from './TimeLineItems/Utils';

export default function useAssemblyChangeHistory({ assembly }: { assembly: string }) {
    const { closeDrawer, isOpen, setDrawer } = useDrawerContext();
    const { data: history = [], isLoading } = useHttpQuery('GET /assemblies/:id/history', {
        pathParams: { id: assembly },

        queryParams: { include_design_items: true },
    });

    return {
        closeDrawer,
        isOpen,
        openDrawer: () => {
            setDrawer(
                <RightBoxDrawer onClose={closeDrawer}>
                    <CloseDrawerButton />
                    <HistoryOfChangesContainer>
                        <Text variant={'h3'}>
                            <Trans>History of changes</Trans>
                        </Text>
                        {isLoading ? (
                            <LoadingTimeline />
                        ) : (
                            <StyledTimeLine>
                                {history.map((data, i) => {
                                    const isTheLastItem = i === history.length - 1;
                                    return <TimeLineItem key={i} data={data} isTheLastElement={isTheLastItem} />;
                                })}
                            </StyledTimeLine>
                        )}
                    </HistoryOfChangesContainer>
                </RightBoxDrawer>,
            );
        },
    };
}
