import * as z from 'zod';
import { ApiIntegrationStatusRuntype } from './3rdPartyBackendTypes';

export interface WuerthEisosCredentialsDTO extends z.infer<typeof WuerthEisosCredentialsDTORuntype> {}
export const WuerthEisosCredentialsDTORuntype = z.object({
    username: z.string(),
    password: z.string(),
});

export interface WuerthEisosCredentialsInputDTO extends z.infer<typeof WuerthEisosCredentialsInputDTORuntype> {}
export const WuerthEisosCredentialsInputDTORuntype = z.object({
    username: z.string().optional(),
    password: z.string().optional(),
});

export interface WuerthEisosResponseBodyDTO extends z.infer<typeof WuerthEisosResponseBodyDTORuntype> {}
export const WuerthEisosResponseBodyDTORuntype = z.object({
    data: WuerthEisosCredentialsDTORuntype.nullable(),
    status: ApiIntegrationStatusRuntype,
});
