import { Trans } from '@lingui/macro';
import { getLocale } from '@luminovo/commons';
import { Text } from '@luminovo/design-system';
import { Dialog, DialogContent, DialogTitle, Grid } from '@mui/material';
import { CustomerUserInviteFormState, UserInviteForm } from './UserInviteForm';

const paperStyles = {
    style: { padding: '8px 0px' },
};

export function DialogInviteUsers({
    open,
    onClose,
    onSubmit,
}: {
    open: boolean;
    onClose: () => void;
    onSubmit: (form: CustomerUserInviteFormState) => Promise<void>;
}) {
    const initialValues: CustomerUserInviteFormState = {
        data: [{ first_name: '', last_name: '', email: '', language: getLocale(), user_role: 'Member' }],
    };
    return (
        <Dialog keepMounted={false} open={open} onClose={onClose} maxWidth={'xl'} PaperProps={paperStyles}>
            <DialogTitle style={{ margin: '0px 40px' }}>
                <Grid container justifyContent={'center'}>
                    <Grid item>
                        <Text variant="h2">
                            <Trans>Invite users</Trans>
                        </Text>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent style={{ margin: '0px 16px 16px' }}>
                <UserInviteForm
                    multiple
                    initialValues={initialValues}
                    onClose={() => onClose()}
                    onSubmit={async (form) => {
                        await onSubmit(form);
                        onClose();
                    }}
                />
            </DialogContent>
        </Dialog>
    );
}
