import { t } from '@lingui/macro';
import { ResourceDTO } from '@luminovo/http-client';
import { CircularProgress } from '@mui/material';
import { usePageParams } from '../../../resources/hooks';
import { useHttpQuery } from '../../../resources/http/useHttpQuery';
import { capitalizeFirstLetter } from '../../../utils/stringFunctions';
import { ResourceForm } from './ResourceForm';
import { convertResourceDTOToDefaultValues } from './utils/convertResourceDTOToDefaultValues';
import { createResourceSchema } from './utils/resourceValidationSchema';

export function EditResourceForm() {
    const { resourceId } = usePageParams<'/manufacturing/resource/:resourceId/edit'>();
    const { data: resources, isLoading } = useHttpQuery('GET /resources', {});
    const resource: ResourceDTO | undefined = resources?.find((resource) => resource.id === resourceId);
    const existingResourceNames: string[] =
        resources?.filter((resource) => resource.id !== resourceId).map((resource) => resource.name) ?? [];

    if (isLoading) {
        return <CircularProgress />;
    }

    if (resource === undefined) {
        throw new Error('resource should not be undefined if isLoading is false');
    }

    return (
        <ResourceForm
            onSubmitType="PATCH"
            defaultValues={convertResourceDTOToDefaultValues(resource)}
            schema={createResourceSchema(existingResourceNames)}
            formTitle={capitalizeFirstLetter(t`Edit resource`)}
        />
    );
}
