import * as z from 'zod';
import { ApiIntegrationStatusRuntype } from './3rdPartyBackendTypes';

export type DigiKeyLocaleSiteDTO = z.infer<typeof DigiKeyLocaleSiteDTORuntype>;
export const DigiKeyLocaleSiteDTORuntype = z.enum([
    'DE',
    'US',
    'CA',
    'JP',
    'UK',
    'AT',
    'BE',
    'DK',
    'FI',
    'GR',
    'IE',
    'IT',
    'LU',
    'NL',
    'NO',
    'PT',
    'ES',
    'KR',
    'HK',
    'SG',
    'CN',
    'TW',
    'AU',
    'FR',
    'IN',
    'NZ',
    'SE',
    'MX',
    'CH',
    'IL',
    'PL',
    'SK',
    'SI',
    'LV',
    'LT',
    'EE',
    'CZ',
    'HU',
    'BG',
    'MY',
    'ZA',
    'RO',
    'TH',
    'PH',
]);

export type DigiKeyLocaleCurrencyDTO = z.infer<typeof DigiKeyLocaleCurrencyDTORuntype>;
export const DigiKeyLocaleCurrencyDTORuntype = z.enum([
    'EUR',
    'USD',
    'CAD',
    'JPY',
    'GBP',
    'HKD',
    'SGD',
    'TWD',
    'KRW',
    'AUD',
    'NZD',
    'INR',
    'DKK',
    'NOK',
    'SEK',
    'ILS',
    'CNY',
    'PLN',
    'CHF',
    'CZK',
    'HUF',
    'RON',
    'ZAR',
    'MYR',
    'THB',
    'PHP',
]);

export type DigiKeyCustomerIdDTO = z.infer<typeof DigiKeyCustomerIdDTORuntype>;
export const DigiKeyCustomerIdDTORuntype = z.union([z.string(), z.null()]);

export interface DigikeyRedirectUrlDTO extends z.infer<typeof DigikeyRedirectUrlDTORuntype> {}
export const DigikeyRedirectUrlDTORuntype = z.object({
    redirect_url: z.string(),
});

export interface DigikeyIsAuthenticatedDTO extends z.infer<typeof DigikeyIsAuthenticatedDTORuntype> {}
export const DigikeyIsAuthenticatedDTORuntype = z.object({
    status: ApiIntegrationStatusRuntype,
});

export interface DigiKeySupportedSettingDTO extends z.infer<typeof DigiKeySupportedSettingDTORuntype> {}
export const DigiKeySupportedSettingDTORuntype = z.object({
    store_location: DigiKeyLocaleSiteDTORuntype,
    currency: z.array(DigiKeyLocaleCurrencyDTORuntype),
});

export interface DigiKeySettingDTO extends z.infer<typeof DigiKeySettingDTORuntype> {}
export const DigiKeySettingDTORuntype = z.object({
    store_location: DigiKeyLocaleSiteDTORuntype,
    currency: DigiKeyLocaleCurrencyDTORuntype,
    customer_id: DigiKeyCustomerIdDTORuntype,
});
