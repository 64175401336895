import * as z from 'zod';
import { ApiIntegrationStatusRuntype } from './3rdPartyBackendTypes';

export interface GudecoCredentialsDTO extends z.infer<typeof GudecoCredentialsDTORuntype> {}
export const GudecoCredentialsDTORuntype = z.object({
    api_key: z.string(),
    customer_key: z.string(),
});

export interface GudecoCredentialsInputDTO extends z.infer<typeof GudecoCredentialsInputDTORuntype> {}
export const GudecoCredentialsInputDTORuntype = z.object({
    api_key: z.string().optional(),
    customer_key: z.string().optional(),
});

export interface GudecoResponseBodyDTO extends z.infer<typeof GudecoResponseBodyDTORuntype> {}
export const GudecoResponseBodyDTORuntype = z.object({
    data: GudecoCredentialsDTORuntype.nullable(),
    status: ApiIntegrationStatusRuntype,
});
