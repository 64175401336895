import * as z from 'zod';
import { endpoint } from '../http/endpoint';
import { PackageDTORuntype } from '../part';
import {
    BomImporterBackendInputRuntype,
    BomLineBuildingInputRuntype,
    BomProgressRuntype,
    TechnicalParamsRuntype,
} from './bomImporterBackendTypes';

/**
 * Split the bomImporterEndpoints into smaller groups to avoid TypeScript compiler serialization limits.
 */

// Group: BOM Files endpoints
const bomFilesEndpoints = {
    'GET /bom-files/examples': endpoint({
        description:
            'returns the example urls for Single level and Multi level BOMs - used in the guidance drawer for the BOM structure',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({
            data: z.object({
                single_level_bom: z.string(),
                multi_level_bom: z.string(),
            }),
        }),
    }),
};

// Group: BOM Importer task endpoints
const bomImporterTaskEndpoints = {
    // Calls to bom-importer service
    // Note, these calls are just to put things in task queue, you get task id as response
    // and then need to GET status of that task
    // display it as a progress bar until you get the response information
    'POST /bom-importer/screener': endpoint({
        description: 'returns a task endpoint for the screener',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.instanceof(FormData),
        responseBody: z.object({ task_endpoint: z.string() }),
    }),
    'GET /bom-importer/screener/tasks/:taskId': endpoint({
        description: 'returns the status of a BOM import task',
        pathParams: z.object({ taskId: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({ data: BomProgressRuntype }),
    }),
};

// Group: More BOM Importer task endpoints
const moreBomImporterTaskEndpoints = {
    'POST /bom-importer/line-builder': endpoint({
        description: 'returns a task endpoint for the line builder',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: BomLineBuildingInputRuntype,
        responseBody: z.object({ task_endpoint: z.string() }),
    }),
    'GET /bom-importer/line-builder/tasks/:taskId': endpoint({
        description: 'returns the status of a BOM import task',
        pathParams: z.object({ taskId: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({ data: BomProgressRuntype }),
    }),
};

const bomFileUpload = {
    'GET /bom-files/:bomFileId/upload': endpoint({
        description: 'returns a URL to upload a BOM file',
        pathParams: z.object({ bomFileId: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({
            data: z.object({
                url: z.string(),
            }),
        }),
        invalidates: ['GET /assemblies/:assemblyId/bom-files'],
    }),
};

// Group: BOM Importer data processing endpoints
const bomImporterProcessingEndpoints = {
    'POST /design-items/bom-importer': endpoint({
        description: 'posts BOM data to the backend',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: BomImporterBackendInputRuntype,
        responseBody: z.object({ data: z.object({ bom_file_id: z.string(), design_items: z.array(z.string()) }) }),
        invalidates: [
            'POST /parts/off-the-shelf/bulk',
            'POST /design-items/bulk',
            'POST /parts/generic/bulk',
            'POST /ipns/bulk',
            'GET /assemblies/:assemblyId/descendants',
            'GET /assemblies/:assemblyId/descendants-summary',
        ],
        handleResponse: async (response) => {
            const responseData = await response.json();
            if (response.ok) {
                return responseData;
            }
            throw responseData;
        },
    }),
};

// Group: BOM Importer parameter extraction endpoints
const bomImporterParameterEndpoints = {
    'POST /bom-importer/extract-possible-parameters': endpoint({
        description: 'extracts technical parameters from a BOM file',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.object({
            items: z.array(z.string()),
        }),
        responseBody: z.object({
            data: z.record(z.string(), z.unknown()),
        }),
    }),
};

// Group: More BOM Importer parameter extraction endpoints
const moreBomImporterParameterEndpoints = {
    'POST /bom-importer/extract-technical-parameters': endpoint({
        description: 'extracts technical parameters from a BOM file',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.object({
            items: z.array(z.string()),
            packages: z.array(PackageDTORuntype),
            require_generic_part_type_extracted: z.boolean(),
        }),
        responseBody: z.object({
            data: z.record(z.string(), TechnicalParamsRuntype.nullable()),
        }),
    }),
};

const failedBomImportEndpoint = {
    'GET /bom-files/upload-failed': endpoint({
        description: 'returns a URL to upload a file that failed to import',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({ data: z.object({ url: z.string() }) }),
    }),
};

// Define types for each group
type BomFilesEndpoints = typeof bomFilesEndpoints;
type BomImporterTaskEndpoints = typeof bomImporterTaskEndpoints;
type MoreBomImporterTaskEndpoints = typeof moreBomImporterTaskEndpoints;
type BomFileUploadEndpoints = typeof bomFileUpload;
type BomImporterProcessingEndpoints = typeof bomImporterProcessingEndpoints;
type BomImporterParameterEndpoints = typeof bomImporterParameterEndpoints;
type MoreBomImporterParameterEndpoints = typeof moreBomImporterParameterEndpoints;
type FailedBomImportEndpoint = typeof failedBomImportEndpoint;
// Combine all endpoint groups in a single interface
interface BomImporterEndpoints
    extends BomFilesEndpoints,
        BomImporterTaskEndpoints,
        MoreBomImporterTaskEndpoints,
        BomFileUploadEndpoints,
        BomImporterProcessingEndpoints,
        BomImporterParameterEndpoints,
        MoreBomImporterParameterEndpoints,
        FailedBomImportEndpoint {}

// Export the combined endpoints with a type assertion to avoid TypeScript serialization limits
export const bomImporterEndpoints: BomImporterEndpoints = {
    ...bomFilesEndpoints,
    ...bomImporterTaskEndpoints,
    ...moreBomImporterTaskEndpoints,
    ...bomFileUpload,
    ...bomImporterProcessingEndpoints,
    ...bomImporterParameterEndpoints,
    ...moreBomImporterParameterEndpoints,
    ...failedBomImportEndpoint,
} as const;
