import { Trans } from '@lingui/macro';
import { Chip, Flexbox, Tooltip } from '@luminovo/design-system';
import { ComponentSpecificationWithFullPackage, SpecificationsConflict } from '@luminovo/http-client';
import { PackageChip } from '@luminovo/sourcing-core';
import {
    formatCapacitance,
    formatDielectricMaterial,
    formatPowerRating,
    formatResistance,
    formatTemperatureCoefficient,
    formatTolerance,
    formatVoltageRating,
} from '../utils/converterUtils';
import { assertUnreachable } from '../utils/typingUtils';

export const TechParamsMultiChip = ({
    componentSpecification,
    conflicts,
    flexWrap = 'wrap',
    hidePackage = false,
    disabled,
    fallback,
    showConflictTooltip = false,
    betterTechnicalProperties,
}: {
    componentSpecification: ComponentSpecificationWithFullPackage;
    conflicts?: SpecificationsConflict;
    flexWrap?: 'wrap' | 'nowrap';
    hidePackage?: boolean;
    disabled?: boolean;
    fallback?: JSX.Element;
    showConflictTooltip?: boolean;
    betterTechnicalProperties?: string[];
}) => {
    const functionSpecification = componentSpecification.function_specification;
    const formfit = componentSpecification.form_and_fit;
    if (!functionSpecification) {
        if (formfit && !hidePackage) {
            return (
                <Flexbox gap={5}>
                    <PackageChip formfit={formfit} />
                </Flexbox>
            );
        } else {
            return fallback ?? null;
        }
    }

    if (functionSpecification.type === 'Resistor') {
        const techParams = functionSpecification.technical_parameters;
        return (
            <Flexbox gap="4px" flexWrap={flexWrap}>
                {functionSpecification && (
                    <Chip color={'neutral'} label={functionSpecification.type || ''} disabled={disabled} />
                )}
                {techParams && techParams.resistance && (
                    <ConflictColouredChip
                        conflicts={conflicts}
                        param={'resistance'}
                        label={formatResistance(techParams.resistance)}
                        disabled={disabled}
                        showTooltip={showConflictTooltip}
                        betterTechnicalProperties={betterTechnicalProperties}
                    />
                )}
                {techParams && techParams.voltage_rating && (
                    <ConflictColouredChip
                        conflicts={conflicts}
                        param={'voltage_rating'}
                        label={formatVoltageRating(techParams.voltage_rating)}
                        disabled={disabled}
                        showTooltip={showConflictTooltip}
                        betterTechnicalProperties={betterTechnicalProperties}
                    />
                )}
                {techParams && techParams.power_rating && (
                    <ConflictColouredChip
                        conflicts={conflicts}
                        param={'power_rating'}
                        label={formatPowerRating(techParams.power_rating)}
                        disabled={disabled}
                        showTooltip={showConflictTooltip}
                        betterTechnicalProperties={betterTechnicalProperties}
                    />
                )}
                {techParams && techParams.tolerance && (
                    <ConflictColouredChip
                        conflicts={conflicts}
                        param={'tolerance'}
                        label={formatTolerance(techParams.tolerance)}
                        disabled={disabled}
                        showTooltip={showConflictTooltip}
                        betterTechnicalProperties={betterTechnicalProperties}
                    />
                )}
                {techParams && techParams.temperature_coefficient && (
                    <ConflictColouredChip
                        conflicts={conflicts}
                        param={'temperature_coefficient'}
                        label={formatTemperatureCoefficient(techParams.temperature_coefficient)}
                        disabled={disabled}
                        showTooltip={showConflictTooltip}
                        betterTechnicalProperties={betterTechnicalProperties}
                    />
                )}
                {formfit && !hidePackage && <PackageChip formfit={formfit} conflicts={conflicts?.form_and_fit} />}
            </Flexbox>
        );
    }
    if (functionSpecification.type === 'Capacitor') {
        const techParams = functionSpecification.technical_parameters;
        return (
            <Flexbox gap="4px" flexWrap={flexWrap}>
                {functionSpecification && (
                    <Chip color={'neutral'} label={functionSpecification.type || ''} disabled={disabled} />
                )}
                {techParams && techParams.capacitance && (
                    <ConflictColouredChip
                        conflicts={conflicts}
                        param={'capacitance'}
                        label={formatCapacitance(techParams.capacitance)}
                        disabled={disabled}
                        showTooltip={showConflictTooltip}
                        betterTechnicalProperties={betterTechnicalProperties}
                    />
                )}
                {techParams && techParams.voltage_rating && (
                    <ConflictColouredChip
                        conflicts={conflicts}
                        param={'voltage_rating'}
                        label={formatVoltageRating(techParams.voltage_rating)}
                        disabled={disabled}
                        showTooltip={showConflictTooltip}
                        betterTechnicalProperties={betterTechnicalProperties}
                    />
                )}
                {techParams && techParams.tolerance && (
                    <ConflictColouredChip
                        conflicts={conflicts}
                        param={'tolerance'}
                        label={formatTolerance(techParams.tolerance)}
                        disabled={disabled}
                        showTooltip={showConflictTooltip}
                        betterTechnicalProperties={betterTechnicalProperties}
                    />
                )}
                {techParams && techParams.dielectric && (
                    <ConflictColouredChip
                        conflicts={conflicts}
                        param={'dielectric'}
                        label={formatDielectricMaterial(techParams.dielectric)}
                        disabled={disabled}
                        showTooltip={showConflictTooltip}
                        betterTechnicalProperties={betterTechnicalProperties}
                    />
                )}
                {formfit && !hidePackage && <PackageChip formfit={formfit} conflicts={conflicts?.form_and_fit} />}
            </Flexbox>
        );
    }
    if (functionSpecification.type === 'Unsupported') {
        const techParams = functionSpecification.technical_parameters;
        return (
            <Flexbox gap="4px" flexWrap={flexWrap}>
                {techParams && techParams.part_type && (
                    <Chip color="neutral" label={techParams.part_type} disabled={disabled} />
                )}
                {formfit && !hidePackage && <PackageChip formfit={formfit} conflicts={conflicts?.form_and_fit} />}
            </Flexbox>
        );
    }
    assertUnreachable(functionSpecification);
};

function getChipColourFromConflictsOrBetterParameters(
    param: string,
    conflicts?: SpecificationsConflict,
    betterTechnicalProperties?: string[],
): 'yellow' | 'blue' | 'neutral' {
    // by convention - we rank the conflicts higher https://www.notion.so/luminovo/Highlight-part-suggestions-that-are-upgrades-voltage-tolerance-266d887f6a864aefb3f576130c0cfe04?pvs=4#3f60643ee4d8402996bc1b7f0f6bf818
    if (conflicts?.function_specification_keys?.includes(param)) return 'yellow';
    if (betterTechnicalProperties?.includes(param)) return 'blue';
    return 'neutral';
}

function ConflictColouredChip({
    conflicts,
    param,
    label,
    disabled,
    showTooltip = false,
    betterTechnicalProperties,
}: {
    conflicts?: SpecificationsConflict;
    param: string;
    label: string;
    disabled?: boolean;
    showTooltip?: boolean;
    betterTechnicalProperties?: string[];
}) {
    const hasConflict = conflicts?.function_specification_keys?.includes(param);
    return (
        <Tooltip
            enterDelay={500}
            enterNextDelay={500}
            title={
                showTooltip && hasConflict ? (
                    <Trans>
                        There are discrepancies between the component specifications, whether edited manually, inferred
                        from linked parts, or imported via ERP data.
                    </Trans>
                ) : (
                    ''
                )
            }
        >
            <Chip
                color={getChipColourFromConflictsOrBetterParameters(param, conflicts, betterTechnicalProperties)}
                label={label}
                disabled={disabled}
            />
        </Tooltip>
    );
}
