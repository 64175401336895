import {
    ApprovalStatus,
    DielectricTypes,
    GenericCapacitor,
    GenericPartTypes,
    GenericResistor,
    PackageDTO,
} from '@luminovo/http-client';
import { GenericCapacitorForm, GenericResistorForm } from '../../../resources/designItem/designItemFrontendTypes';

export function createGenericResistorFormInitialValues(
    part: GenericResistor,
    packageForResistor: PackageDTO | null,
): GenericResistorForm {
    const { technical_parameters } = part;
    return {
        approvalStatus: ApprovalStatus.Approved,
        package: packageForResistor?.name
            ? {
                  name: packageForResistor?.name ?? null,
                  aliases: packageForResistor?.aliases ?? [],
                  id: technical_parameters.package_id ?? '',
                  mounting: packageForResistor?.mounting ?? null,
                  number_of_pins: packageForResistor?.number_of_pins ?? null,
                  tags: packageForResistor?.tags ?? [],
              }
            : undefined,
        type: GenericPartTypes.Resistor,
        resistance: technical_parameters.resistance,
        powerRating: technical_parameters.power_rating,
        tolerance: technical_parameters.tolerance,
        temperatureCoefficient: technical_parameters.temperature_coefficient,
        voltageRating: technical_parameters.voltage_rating,
    };
}

export function createGenericCapacitorFormInitialValues(
    part: GenericCapacitor,
    packageForCapacitor: PackageDTO | null,
): GenericCapacitorForm {
    const { technical_parameters } = part;
    return {
        package: packageForCapacitor?.name
            ? {
                  name: packageForCapacitor?.name ?? null,
                  aliases: packageForCapacitor?.aliases ?? [],
                  id: technical_parameters.package_id ?? '',
                  mounting: packageForCapacitor?.mounting ?? null,
                  number_of_pins: packageForCapacitor?.number_of_pins ?? null,
                  tags: packageForCapacitor?.tags ?? [],
              }
            : undefined,
        approvalStatus: ApprovalStatus.Approved,
        type: GenericPartTypes.Capacitor,
        capacitance: technical_parameters.capacitance,
        dielectricMaterial: technical_parameters.dielectric ?? {
            type: DielectricTypes.Ceramic,
            dielectric: null,
        },
        tolerance: technical_parameters.tolerance,
        voltageRating: technical_parameters.voltage_rating,
    };
}
