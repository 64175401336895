import { FormContainer } from '@/components/formLayouts/FormContainer';
import { SubmitButton } from '@/components/formLayouts/SubmitButton';
import { useManufacturers } from '@/resources/manufacturer/manufacturerHandler';
import { useHttpMutation } from '@/resources/mutation/useHttpMutation';
import { t, Trans } from '@lingui/macro';
import { isPresent } from '@luminovo/commons';
import {
    CenteredLayout,
    Checkbox,
    colorSystem,
    DestructiveSecondaryButton,
    Flexbox,
    SearchField,
    Switch,
    Text,
    Tooltip,
} from '@luminovo/design-system';
import { ManufacturerDTO } from '@luminovo/http-client';
import { Info } from '@mui/icons-material';
import { Box, CircularProgress } from '@mui/material';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { FixedSizeList } from 'react-window';

export type SupplierLineCardFormState = {
    selected: Set<string>;
};

type SelectionStatus = 'none' | 'all' | 'indeterminate';

const PrefernceTableHeader: React.FunctionComponent<{
    selectionIds: Set<string>;
    selectionStatus: SelectionStatus;
    toggleSelection: () => void;
}> = ({ selectionIds, selectionStatus, toggleSelection }) => {
    return (
        <Flexbox padding={'8px 12px'} gap={'8px'} bgcolor={colorSystem.neutral[0]} alignItems={'center'}>
            <Checkbox
                size="small"
                checked={selectionStatus === 'all'}
                indeterminate={selectionStatus === 'indeterminate'}
                onClick={toggleSelection}
            />
            <Text variant="h5">
                <Trans>Manufacturer</Trans>
            </Text>
            <Flexbox flex={1} />
            <Flexbox gap={6} alignItems={'center'}>
                <Text variant="body-small">{t`${selectionIds.size} selected`}</Text>
            </Flexbox>
        </Flexbox>
    );
};

const filterAlternativeManufacturerNames = (
    manufacturerName: string,
    alternativeNames: string[],
    query: string,
): string => {
    if (manufacturerName.toUpperCase().includes(query.toUpperCase())) {
        // In this case there is no real benefit of displaying any (or even a truckload of) alternative names.
        return '';
    }
    const filteredNames = alternativeNames.filter((element) => element.toUpperCase().includes(query.toUpperCase()));
    if (filteredNames.length > 0) {
        return filteredNames.join(', ');
    }
    return '';
};

const renderManufacturerWithAlternativeNames = (option: ManufacturerDTO, query: string | null) => {
    return (
        <span style={{ display: 'flex', flexDirection: 'column' }}>
            {option.name}
            {isPresent(query) && (
                <Text variant={'body-small'} showEllipsis={true} style={{ color: colorSystem.neutral[6] }}>
                    {filterAlternativeManufacturerNames(option.name, option.alternative_names, query)}
                </Text>
            )}
        </span>
    );
};

function getItemDataAndSelectionStatus({
    selectionIds,
    manufacturers,
    query = '',
}: {
    selectionIds: Set<string>;
    manufacturers: ManufacturerDTO[];
    query: string | null;
}): { filteredItems: string[]; selectionStatus: SelectionStatus } {
    let itemData: ManufacturerDTO[] = manufacturers;
    let selectionStatus: SelectionStatus = 'none';

    switch (selectionIds.size) {
        case 0:
            selectionStatus = 'none';
            break;
        case itemData.length:
            selectionStatus = 'all';
            break;
        default:
            selectionStatus = 'indeterminate';
            break;
    }

    const filteredIds = manufacturers
        .filter(
            (m) =>
                !isPresent(query) ||
                m.name.toLowerCase().includes(query.toLowerCase().trim()) ||
                m.alternative_names.some((m) => m.toLowerCase().includes(query.toLowerCase().trim())),
        )
        .map((m) => m.id);

    const filteredItems = itemData.map((m) => m.id).filter((id) => filteredIds.includes(id));

    return { filteredItems, selectionStatus };
}

const PreferenceTable: React.FunctionComponent<{
    query: string | null;
    isLoading: boolean;
    manufacturers: ManufacturerDTO[];
    showAllManufacturers: boolean;
}> = ({ query, isLoading, manufacturers, showAllManufacturers }) => {
    const { control, setValue } = useFormContext<SupplierLineCardFormState>();
    const selectionIds = useWatch({ control, name: 'selected' });

    const { filteredItems, selectionStatus } = getItemDataAndSelectionStatus({
        selectionIds,
        query,
        manufacturers,
    });

    const toggleSelection = React.useCallback(() => {
        const newSelectionIds = new Set(selectionIds);

        const allSelected = filteredItems.every((item) => newSelectionIds.has(item));

        if (allSelected) {
            filteredItems.forEach((item) => newSelectionIds.delete(item));
        } else {
            filteredItems.forEach((item) => newSelectionIds.add(item));
        }

        setValue('selected', newSelectionIds);
    }, [selectionIds, setValue, filteredItems]);

    const onSelect = React.useCallback(
        (value: string) => {
            const newSelectionIds = new Set(selectionIds);
            if (newSelectionIds.has(value)) {
                newSelectionIds.delete(value);
            } else {
                newSelectionIds.add(value);
            }
            setValue('selected', newSelectionIds);
        },
        [setValue, selectionIds],
    );

    const ManufacturerRow = ({ index, style, data }: { index: number; style: React.CSSProperties; data: string[] }) => {
        const value = data[index];
        const manufacturer = manufacturers.find((item) => item.id === value);

        if (!isPresent(manufacturer)) {
            return <></>;
        }

        return (
            <Flexbox
                style={{ boxSizing: 'border-box', ...style }}
                alignItems={'center'}
                borderTop={`1px solid ${colorSystem.neutral[2]}`}
                paddingX={'12px'}
                gap={'8px'}
            >
                <Checkbox size="small" onClick={() => onSelect(value)} checked={selectionIds.has(value)} />

                <Text variant={'body-small'}>{renderManufacturerWithAlternativeNames(manufacturer, query)}</Text>
            </Flexbox>
        );
    };

    return (
        <Box
            style={{
                border: `1px solid ${colorSystem.neutral[2]}`,
                borderRadius: '8px',
                overflow: 'hidden',
            }}
        >
            <PrefernceTableHeader
                selectionIds={selectionIds}
                selectionStatus={selectionStatus}
                toggleSelection={toggleSelection}
            />
            {isLoading ? (
                <CenteredLayout height={180}>
                    <CircularProgress />
                </CenteredLayout>
            ) : filteredItems.length === 0 && !showAllManufacturers ? (
                <CenteredLayout height={180}>
                    <Text color={colorSystem.neutral[7]}>
                        <Trans>
                            No high quality manufacturers to show. You might be able to see more manufacturers by
                            activating "Show all manufacturers"
                        </Trans>
                    </Text>
                </CenteredLayout>
            ) : filteredItems.length === 0 && !isPresent(query) ? (
                <CenteredLayout height={180}>
                    <Text color={colorSystem.neutral[7]}>
                        <Trans>No manufacturer in this category</Trans>
                    </Text>
                </CenteredLayout>
            ) : filteredItems.length === 0 && isPresent(query) ? (
                <CenteredLayout height={180}>
                    <Text color={colorSystem.neutral[7]}>
                        <Trans>Sorry, your filter produced no results</Trans>
                    </Text>
                </CenteredLayout>
            ) : (
                <FixedSizeList
                    itemData={filteredItems}
                    itemCount={filteredItems.length}
                    width="100%"
                    height={300}
                    itemSize={38}
                    overscanCount={5}
                >
                    {ManufacturerRow}
                </FixedSizeList>
            )}
        </Box>
    );
};

const ShowAllManufacturersSwitch: React.FunctionComponent<{
    handleChange: (checked: boolean) => void;
}> = ({ handleChange }) => {
    return (
        <Flexbox alignItems={'center'} gap={'12px'}>
            <Switch onChange={(e) => handleChange(e.target.checked)} />
            <Flexbox alignItems={'center'}>
                <Text>
                    <Trans>Show all manufacturers</Trans>
                </Text>
                <Tooltip
                    title={t`Please note that some manufacturers may be duplicates or do not have any parts available.`}
                >
                    <Info
                        style={{
                            color: colorSystem.neutral[5],
                            height: '16px',
                        }}
                    />
                </Tooltip>
            </Flexbox>
        </Flexbox>
    );
};

const ResetLineCardButton: React.FunctionComponent<{
    supplierId: string;
    onSuccess: () => void;
}> = ({ supplierId, onSuccess }) => {
    const { mutateAsync, isPending } = useHttpMutation('PATCH /suppliers/:id/reset-line-card', {
        snackbarMessage: t`Line card reset successfully`,
        onSuccess: () => onSuccess(),
    });

    return (
        <Tooltip title={t`Reset the line card to the default system line card`}>
            <DestructiveSecondaryButton
                onClick={() => mutateAsync({ pathParams: { id: supplierId } })}
                isLoading={isPending}
            >
                {t`Reset line card`}
            </DestructiveSecondaryButton>
        </Tooltip>
    );
};

export function SupplierLineCardForm({
    defaultValues,
    onSubmit,
    supplierId,
    onClose,
}: {
    defaultValues: SupplierLineCardFormState;
    onSubmit: (fromValues: SupplierLineCardFormState) => void;
    supplierId: string | null;
    onClose: () => void;
}) {
    const [query, setQuery] = React.useState<string | null>(null);
    const [showAllManufacturers, setShowAllManufacturers] = React.useState<boolean>(false);

    const { data: manufacturers, isLoading } = useManufacturers({ highQuality: !showAllManufacturers });

    return (
        <FormContainer defaultValues={defaultValues} onSubmit={onSubmit}>
            <Flexbox flexDirection={'column'} gap={20} paddingTop={'12px'}>
                <Flexbox flexDirection="column" gap={12}>
                    <Text>
                        <Trans>Select manufacturers from the table to include in this supplier's line card</Trans>
                    </Text>
                    <Flexbox alignItems={'center'} justifyContent={'space-between'}>
                        <SearchField
                            value={query ?? ''}
                            onChange={(e) => setQuery(e.target.value)}
                            placeholder={t`Search Manufacturers`}
                        />
                        <ShowAllManufacturersSwitch handleChange={setShowAllManufacturers} />
                    </Flexbox>
                    <PreferenceTable
                        query={query}
                        isLoading={isLoading}
                        manufacturers={manufacturers}
                        showAllManufacturers={showAllManufacturers}
                    />
                </Flexbox>
                <Flexbox width={'100%'} justifyContent={'flex-end'} gap={'8px'}>
                    {isPresent(supplierId) && <ResetLineCardButton supplierId={supplierId} onSuccess={onClose} />}
                    <SubmitButton />
                </Flexbox>
            </Flexbox>
        </FormContainer>
    );
}
