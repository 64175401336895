import * as z from 'zod';
import { ApiIntegrationStatusRuntype } from './3rdPartyBackendTypes';

export interface LcscCredentialsDTO extends z.infer<typeof LcscCredentialsDTORuntype> {}
export const LcscCredentialsDTORuntype = z.object({
    api_key: z.string(),
    api_secret: z.string(),
});

export interface LcscCredentialsInputDTO extends z.infer<typeof LcscCredentialsInputDTORuntype> {}
export const LcscCredentialsInputDTORuntype = z.object({
    api_key: z.string().optional(),
    api_secret: z.string().optional(),
});

export interface LcscResponseBodyDTO extends z.infer<typeof LcscResponseBodyDTORuntype> {}
export const LcscResponseBodyDTORuntype = z.object({
    data: LcscCredentialsDTORuntype.nullable(),
    status: ApiIntegrationStatusRuntype,
});
