import { Menu, MenuItem } from '@mui/material';
import React from 'react';
import { usePdfViewerState } from '../../hooks/usePdfViewerState';
import { Region } from '../../model/RegionNetwork/types';
import { Viewbox } from '../../model/Viewbox';
import { findSelectedRegions } from '../../model/findSelectedRegions';
import { PolygonView } from '../PolygonView';

export function Polygons({ viewbox, pageNumber }: { viewbox: Viewbox; pageNumber: number }): JSX.Element {
    const [state] = usePdfViewerState();
    const [_, setHoveredRegion] = React.useState<Region | undefined>(undefined);

    const polygons = state.regs.findRegions({ pageNumber }).map((region, i) => {
        return <PolygonContainer region={region} setHoveredRegion={setHoveredRegion} viewbox={viewbox} key={i} />;
    });

    return <>{polygons}</>;
}

function PolygonContainer({
    region,
    viewbox,
    setHoveredRegion,
}: {
    viewbox: Viewbox;
    region: Region;
    setHoveredRegion: (region: Region | undefined) => void;
}) {
    const [state, dispatch] = usePdfViewerState();
    const [isOpenMenu, setOpenMenu] = React.useState(false);
    const selectedRegions = findSelectedRegions(state);

    const isAssociated = state.regs.hasLinks({ with: region });

    const ref = React.useRef<SVGRectElement>(null);

    // TODO - fix useRef
    return (
        <>
            <Menu onClose={() => setOpenMenu(false)} open={isOpenMenu} anchorEl={ref.current}>
                {isAssociated && (
                    <MenuItem
                        onClick={() => {
                            dispatch({ type: 'clearAssociations', region: region });
                            setOpenMenu(false);
                        }}
                    >
                        Remove link
                    </MenuItem>
                )}
                <MenuItem disabled>Link as</MenuItem>
                {selectedRegions.length === 1 &&
                    state.attributes.map((attr, i) => {
                        return (
                            <MenuItem
                                key={i}
                                onClick={() => {
                                    dispatch({
                                        type: 'associateRegion',
                                        attribute: attr,
                                        regionIdentifier: selectedRegions[0],
                                        boundingBox: region.box,
                                    });

                                    setOpenMenu(false);
                                }}
                            >
                                {attr}
                            </MenuItem>
                        );
                    })}
            </Menu>
            <PolygonView
                innerRef={ref}
                isSelected={selectedRegions.some((r) => r.id === region.id)}
                viewbox={viewbox}
                onClick={() => {
                    dispatch({
                        type: 'setMode',
                        mode: {
                            type: 'selected',
                            selectedRegionIds: [region.id],
                        },
                    });
                }}
                key={region.id}
                region={region}
                onMouseEnter={() => {
                    setHoveredRegion(region);
                }}
                onMouseLeave={() => {
                    setHoveredRegion(undefined);
                }}
            />
        </>
    );
}
