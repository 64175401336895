import { Trans, t } from '@lingui/macro';
import { formatPercentage, isPresent } from '@luminovo/commons';
import { CloseRounded, CloudUploadOutlined, DescriptionOutlined } from '@mui/icons-material';
import { CircularProgress, styled } from '@mui/material';
import React from 'react';
import { FileRejection, DropzoneProps as ReactDropzoneProps } from 'react-dropzone';
import { colorSystem } from '../../theme';
import { Flexbox } from '../Flexbox';
import { Link } from '../Link';
import { Message } from '../Message';
import { Text } from '../Text';
import { DestructiveTertiaryIconButton } from '../buttons';
import { CenteredLayout } from '../layout/CenteredLayout';

type PersistedFile = { name: string; onClick: () => void; onDelete: () => void; disabled?: boolean };

type DropzoneProps = {
    /**
     * Title to be displayed in the dropzone area.
     */
    title: string;

    /**
     * Optional subheader to be displayed in the dropzone area.
     */
    subHeader?: string;

    onDropAccepted: NonNullable<ReactDropzoneProps['onDropAccepted']>;

    /**
     * When set to true, the dropzone will be disabled and show a loading indicator.
     */
    isLoading?: boolean;

    /**
     * If known, the progress of the upload as a number between 0 and 1.
     */
    progress?: number;

    /**
     * When set to true, the dropzone will be disabled.
     */
    disabled?: boolean;

    /**
     * Specifies the file types that the dropzone will accept.
     * See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
     */
    accept: NonNullable<ReactDropzoneProps['accept']> | null;

    /**
     * Specifies whether the dropzone accepts multiple files.
     */
    multiple: NonNullable<ReactDropzoneProps['multiple']>;

    /**
     * Optional additional content to be rendered below the dropzone.
     */
    extraContent?: React.ReactNode;

    /**
     * Renders a list of files that should persist after being uploaded.
     */
    persistentFiles?: Array<PersistedFile>;

    overrides?: {
        Container?: React.ComponentType<React.ComponentProps<typeof DefaultContainer>>;
        Title?: React.ComponentType<React.ComponentProps<typeof Text>>;
        FileExtensionsInfo?: React.ComponentType<Pick<DropzoneProps, 'accept'>>;
        MessageInfo?: React.ComponentType<Pick<DropzoneProps, 'multiple' | 'disabled'>>;
    };
};

const LazyReactDropzone = React.lazy(async () => {
    const ReactDropzone = await import('react-dropzone');
    return ReactDropzone;
});

function joinFileExtensions(accept: NonNullable<DropzoneProps['accept']>, separator: 'and' | 'or'): string {
    const separatorMap = { and: t`and`, or: t`or` };

    const fileExtensions = Object.entries(accept).flatMap(([_, value]) => value);
    const last = fileExtensions.pop() ?? t`Unknown file type`;
    return fileExtensions.length ? `${fileExtensions.join(', ')} ${separatorMap[separator]} ${last}` : last;
}

const DefaultFileExtensionsInfo: React.FunctionComponent<Pick<DropzoneProps, 'accept'>> = ({ accept }) => {
    if (!isPresent(accept)) {
        return null;
    }

    return (
        <Text variant={'body-small'} color={colorSystem.neutral[6]}>
            <Trans>We support {joinFileExtensions(accept, 'and')} files</Trans>
        </Text>
    );
};

const DefaultMessageInfo: React.FunctionComponent<Pick<DropzoneProps, 'multiple' | 'disabled'>> = ({
    multiple,
    disabled,
}) => {
    if (disabled) {
        return (
            <Text variant={'body-small-semibold'} color={colorSystem.neutral[8]}>
                <Trans>Please remove the current files to upload new ones.</Trans>
            </Text>
        );
    }

    if (multiple) {
        return (
            <Flexbox gap={'4px'} alignItems={'baseline'}>
                <Text variant={'body-small-semibold'} color={colorSystem.neutral[8]}>
                    <Trans>Drag & Drop files here or</Trans>
                </Text>
                <Link variant={'body-small-semibold'} style={{ cursor: undefined }}>
                    <Trans>click to upload</Trans>
                </Link>
            </Flexbox>
        );
    }

    return (
        <Flexbox gap={'4px'} alignItems={'baseline'}>
            <Text variant={'body-small-semibold'} color={colorSystem.neutral[8]}>
                <Trans>Drag & Drop file here or</Trans>
            </Text>
            <Link variant={'body-small-semibold'} style={{ cursor: undefined }}>
                <Trans>click to upload</Trans>
            </Link>
        </Flexbox>
    );
};

const UploadPrompt: React.FunctionComponent<{
    isDragActive: boolean;
    accept: DropzoneProps['accept'];
    isLoading: boolean;
    disabled: boolean;
    multiple: DropzoneProps['multiple'];
    overrides: DropzoneProps['overrides'];
    progress?: DropzoneProps['progress'];
}> = ({ isDragActive, accept, isLoading, disabled, multiple, overrides, progress }) => {
    const { FileExtensionsInfo = DefaultFileExtensionsInfo, MessageInfo = DefaultMessageInfo } = overrides ?? {};

    const state = disabled ? 'disabled' : isDragActive ? 'active' : 'default';
    const stateStyles = {
        default: {
            border: `1px dashed ${colorSystem.neutral[3]}`,
            bgcolor: colorSystem.neutral[0],
            cursor: 'pointer',
            iconColor: colorSystem.neutral[5],
        },
        active: {
            border: `1px dashed ${colorSystem.blue[5]}`,
            bgcolor: colorSystem.blue[1],
            cursor: 'pointer',
            iconColor: colorSystem.primary[6],
        },
        disabled: {
            border: `1px solid ${colorSystem.neutral[3]}`,
            bgcolor: colorSystem.neutral[1],
            cursor: 'not-allowed',
            iconColor: colorSystem.neutral[4],
        },
    };

    return (
        <Flexbox
            sx={{
                transition: 'all 0.2s ease-in',
                boxShadow: '0 4px 8px 0 transparent',
                '&:hover': {
                    background: `linear-gradient(45deg, ${colorSystem.primary[1]} 0%, ${colorSystem.blue[1]} 100%)`,
                },
            }}
            flexDirection={'column'}
            justifyContent={'center'}
            alignItems={'center'}
            gap={'12px'}
            padding={'16px'}
            borderRadius={'4px'}
            border={stateStyles[state].border}
            bgcolor={stateStyles[state].bgcolor}
            style={{ cursor: isLoading ? undefined : stateStyles[state].cursor }}
        >
            {isLoading ? (
                <CircularProgress
                    value={progress === undefined ? undefined : progress * 100}
                    variant={progress === undefined ? 'indeterminate' : 'determinate'}
                />
            ) : (
                <CloudUploadOutlined fontSize="large" style={{ color: stateStyles[state].iconColor }} />
            )}
            <Flexbox flexDirection={'column'} justifyContent={'center'} alignItems={'center'} gap={'8px'}>
                {isLoading ? (
                    <Text variant={'body-small-semibold'} color={colorSystem.neutral[8]}>
                        <Trans>Uploading... {progress ? `${formatPercentage(progress)}` : ''}</Trans>
                    </Text>
                ) : (
                    <>
                        <MessageInfo disabled={disabled} multiple={multiple} />
                        <FileExtensionsInfo accept={accept} />
                    </>
                )}
            </Flexbox>
        </Flexbox>
    );
};

const FileTypeError: React.FunctionComponent<{
    fileRejections: readonly FileRejection[];
    accept: DropzoneProps['accept'];
}> = ({ fileRejections, accept }) => {
    const firstRejectedFile = fileRejections.find((fileRejection) =>
        fileRejection.errors.some((error) => error.code === 'file-invalid-type'),
    );

    if (!isPresent(firstRejectedFile) || !isPresent(accept)) {
        return null;
    }

    return (
        <Message
            variant="red"
            size="large"
            attention="high"
            title={t`File type not supported`}
            message={t`${firstRejectedFile.file.name} is not supported. Please use a ${joinFileExtensions(
                accept,
                'or',
            )} file instead.`}
        />
    );
};

const FileLimitError: React.FunctionComponent<{ fileRejections: readonly FileRejection[] }> = ({ fileRejections }) => {
    const firstRejectedFile = fileRejections.find((fileRejection) =>
        fileRejection.errors.some((error) => error.code === 'too-many-files'),
    );

    if (!isPresent(firstRejectedFile)) {
        return null;
    }

    return (
        <Message
            variant="red"
            size="small"
            attention="high"
            title={t`File Limit Exceeded`}
            message={t`Please upload only one file at a time.`}
        />
    );
};

const PersistentFiles: React.FunctionComponent<{ persistentFiles: DropzoneProps['persistentFiles'] }> = ({
    persistentFiles,
}) => {
    if (!isPresent(persistentFiles) || persistentFiles.length === 0) {
        return null;
    }

    return (
        <Flexbox flexDirection={'column'} gap={'12px'} sx={{ marginTop: 1 }}>
            {persistentFiles?.map(({ name, onClick, onDelete, disabled }) => (
                <Flexbox key={name} gap={'20px'} alignItems={'center'} justifyContent={'space-between'}>
                    <Link
                        startIcon={<DescriptionOutlined />}
                        attention="low"
                        onClick={onClick}
                        textStyle={{ overflowWrap: 'anywhere' }}
                    >
                        {name}
                    </Link>
                    <DestructiveTertiaryIconButton disabled={disabled} size={'small'} onClick={onDelete}>
                        <CloseRounded fontSize="inherit" />
                    </DestructiveTertiaryIconButton>
                </Flexbox>
            ))}
        </Flexbox>
    );
};

export const Dropzone = React.forwardRef<unknown, DropzoneProps>(
    (
        {
            title,
            subHeader,
            extraContent,
            persistentFiles,
            onDropAccepted,
            accept,
            multiple,
            isLoading = false,
            progress,
            disabled = false,
            overrides = {},
        },
        ref,
    ) => {
        const { Container = DefaultContainer, Title = DefaultTitle } = overrides;
        return (
            <React.Suspense
                fallback={
                    <CenteredLayout minHeight={'300px'}>
                        <CircularProgress />
                    </CenteredLayout>
                }
            >
                <LazyReactDropzone
                    onDropAccepted={onDropAccepted}
                    accept={isPresent(accept) ? accept : undefined}
                    multiple={multiple}
                    disabled={isLoading || disabled}
                >
                    {({ getRootProps, getInputProps, isDragActive, fileRejections }) => (
                        <Container ref={ref}>
                            <Flexbox flexDirection={'column'}>
                                {title && <Title>{title}</Title>}
                                {isPresent(subHeader) && (
                                    <Text
                                        variant={'body-small'}
                                        color={colorSystem.neutral[6]}
                                        style={{ whiteSpace: 'pre-wrap' }}
                                    >
                                        {subHeader}
                                    </Text>
                                )}
                            </Flexbox>

                            <div {...getRootProps()}>
                                <input autoFocus {...getInputProps()} />
                                <UploadPrompt
                                    isDragActive={isDragActive}
                                    accept={accept}
                                    isLoading={isLoading}
                                    disabled={disabled}
                                    multiple={multiple}
                                    overrides={overrides}
                                    progress={progress}
                                />
                            </div>
                            <FileTypeError fileRejections={fileRejections} accept={accept} />
                            <FileLimitError fileRejections={fileRejections} />
                            <PersistentFiles persistentFiles={persistentFiles} />
                            {extraContent}
                        </Container>
                    )}
                </LazyReactDropzone>
            </React.Suspense>
        );
    },
);

const DefaultContainer = styled(Flexbox)({
    width: '600px',
    gap: '16px',
    padding: '32px',
    borderRadius: '12px',
    backgroundColor: colorSystem.neutral.white,
    flexDirection: 'column',
    height: 'fit-content',
});

const DefaultTitle: React.FunctionComponent<React.PropsWithChildren<{}>> = ({ children }) => {
    return <Text variant="h3">{children}</Text>;
};
