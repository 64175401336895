export { assertUnreachable, typeSafeObjectKeys } from '@luminovo/commons';

export const undefinedToNull = <T>(obj: T | undefined): T | null => {
    return obj === undefined ? null : obj;
};

export const nullToUndefined = <T>(obj: T | null): T | undefined => {
    return obj === null ? undefined : obj;
};

export const undefinedIfEmpty = (value: string | undefined): string | undefined =>
    value && value.trim().length > 0 ? value : undefined;

export const nullIfEmptyOrUndefined = (value: string | undefined | null): string | null =>
    value && value.trim().length > 0 ? value : null;

export const typed = <T>(x: T): T => x;

/**
 * Similar to `typed`, but shorter for the common special case
 * where you refer to an object key:
 * `const x = keyOf<OTSSourceFormValues>("quantity")`
 * is equivalent to
 * `const x = typed<keyof OTSSourceFormValues & string>("quantity")`
 */
export const keyOf = <T extends object>(name: keyof T & string): keyof T & string => name;

export function getEnumKeyByEnumValue<T extends { [index: string]: string }>(
    myEnum: T,
    enumValue: string | null,
): keyof T | null {
    if (enumValue) {
        const keys = Object.keys(myEnum).find((x) => myEnum[x] === enumValue);
        return keys || null;
    } else {
        return null;
    }
}

export const notUndefined = <T>(x: T | undefined): x is T => {
    return x !== undefined;
};

/**Sets all fields to optional.
 * Good as the type for default values in forms or PATCH bodies
 */
export type RecursivePartial<T> = {
    [P in keyof T]?: T[P] extends (infer U)[]
        ? RecursivePartial<U>[]
        : T[P] extends object
          ? RecursivePartial<T[P]>
          : T[P];
};

export function getValueOf<T extends object>(object: T, key: string): T[keyof T] | undefined {
    if (isKeyOf(object, key)) {
        return object[key];
    }
    return undefined;
}

export function isKeyOf<T extends object | string>(object: T, key: string | number | symbol): key is keyof T {
    if (typeof object === 'object' && object) {
        return key in object;
    }
    return false;
}

export function nthValueInArray<T>(array: T[], index: number): T | undefined {
    return array.length < index + 1 ? undefined : array[index];
}

export const stringToNumber = (data: string | null | undefined): number | undefined => {
    return data ? Number(data) : undefined;
};
