import { t } from '@lingui/macro';
import { compareByStringKey, isPresent } from '@luminovo/commons';
import { TanStackTable, createColumnHelper, useNavigate, useTanStackTable } from '@luminovo/design-system';
import {
    ManufacturerDTO,
    ManufacturerLineCardDTO,
    ManufacturerWithPartCountDTO,
    SupplierAndStockLocationWithPreferenceDTO,
    SupplierPreference,
} from '@luminovo/http-client';
import { SupplierChip, formatSupplierDTO } from '@luminovo/sourcing-core';
import React from 'react';
import { useHttpQuery } from '../../resources/http/useHttpQuery';
import { useManufacturers } from '../../resources/manufacturer/manufacturerHandler';
import { useSortedSupplierAndStockLocationsByPreference } from '../../resources/supplierAndStockLocation/supplierAndStockLocationHandler';
import { route } from '../../utils/routes';

export type ManufacturerLineCardTableItem = {
    type: 'subRow';
    manufacturer: ManufacturerWithPartCountDTO;
    supplierAndStockLocation: SupplierAndStockLocationWithPreferenceDTO;
};

// eslint-disable-next-line max-params
export function convertToManufacturerLineCardTableItems(
    manufacturers: ManufacturerWithPartCountDTO[],
    manufacturerLineCard: ManufacturerLineCardDTO,
    supplierAndStockLocations: SupplierAndStockLocationWithPreferenceDTO[],
): ManufacturerLineCardTableItem[] {
    return manufacturerLineCard.supported_by
        .flatMap((id) => {
            const manufacturer = manufacturers.find((m) => m.id === manufacturerLineCard.manufacturer_id);
            if (!isPresent(manufacturer)) {
                return [];
            }

            const supplierAndStockLocation = supplierAndStockLocations.find(
                (supplier_and_stock_location) => supplier_and_stock_location.supplier.id === id,
            );

            if (!isPresent(supplierAndStockLocation)) {
                return [];
            }

            return {
                type: 'subRow' as const,
                manufacturer,
                supplierAndStockLocation,
            };
        })
        .sort(compareByStringKey((s) => formatSupplierDTO(s.supplierAndStockLocation.supplier)));
}

const columnHelper = createColumnHelper<ManufacturerLineCardTableItem>();

const columns = [
    columnHelper.text((row) => formatSupplierDTO(row.supplierAndStockLocation.supplier), {
        id: 'supplierAndStockLocation',
        size: 180,
        label: () => t`Supplier`,
        cell: ({ row }) => (
            <SupplierChip
                isPreferred={row.original.supplierAndStockLocation.preference === SupplierPreference.Preferred}
                isApproved={row.original.supplierAndStockLocation.preference === SupplierPreference.Approved}
                supplier={row.original.supplierAndStockLocation.supplier}
            />
        ),
    }),
];

export const ManufacturerLineCardTab: React.FunctionComponent<{
    manufacturer: ManufacturerDTO;
}> = ({ manufacturer }) => {
    const navigate = useNavigate();
    const { data: manufacturers } = useManufacturers();
    const { data: supplierAndStockLocations } = useSortedSupplierAndStockLocationsByPreference();

    const { data: manufacturerLineCard } = useHttpQuery('GET /manufacturers/:id/line-card', {
        pathParams: { id: manufacturer.id },
    });

    const data = React.useMemo(() => {
        if (!manufacturerLineCard) {
            return undefined;
        }
        return convertToManufacturerLineCardTableItems(manufacturers, manufacturerLineCard, supplierAndStockLocations);
    }, [manufacturers, supplierAndStockLocations, manufacturerLineCard]);

    const { table } = useTanStackTable({
        columns,
        data,
        enableExcelExport: true,
        onRowClick: (row) => {
            navigate(
                route(
                    '/suppliers/:supplierAndStockLocationId',
                    {
                        supplierAndStockLocationId: row.original.supplierAndStockLocation.id,
                    },
                    { tab: 'lineCard' },
                ),
            );
        },
    });

    return <TanStackTable table={table} size={'small'} />;
};
