import * as z from 'zod';
import { CurrencyRuntype } from '../backendTypes';
import { ApiIntegrationStatusRuntype } from './3rdPartyBackendTypes';

export type NewarkLocaleSiteDTO = z.infer<typeof NewarkLocaleSiteDTORuntype>;
export const NewarkLocaleSiteDTORuntype = z.enum(['US', 'CA', 'MX']);

export interface NewarkCredentialsDTO extends z.infer<typeof NewarkCredentialsDTORuntype> {}
export const NewarkCredentialsDTORuntype = z.object({
    api_key: z.string().optional(),
    customer_id: z.string().nullable().optional(),
    secret_key: z.string().nullable().optional(),
    store_location: NewarkLocaleSiteDTORuntype,
    list_price_currency: CurrencyRuntype,
    contract_price_currency: CurrencyRuntype,
    status: ApiIntegrationStatusRuntype,
});

export interface NewarkCredentialsInputDTO extends z.infer<typeof NewarkCredentialsInputDTORuntype> {}
export const NewarkCredentialsInputDTORuntype = z.object({
    api_key: z.string(),
    customer_id: z.string().nullable(),
    secret_key: z.string().nullable(),
    store_location: NewarkLocaleSiteDTORuntype,
    list_price_currency: CurrencyRuntype,
    contract_price_currency: CurrencyRuntype,
});

export interface NewarkSupportedSettingDTO extends z.infer<typeof NewarkSupportedSettingDTORuntype> {}
export const NewarkSupportedSettingDTORuntype = z.object({
    store_location: NewarkLocaleSiteDTORuntype,
    list_price_currency: z.array(CurrencyRuntype),
    contract_price_currency: z.array(CurrencyRuntype),
});
