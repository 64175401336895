import { assertPresent } from '../../../../../utils/assertPresent';
import {
    assertCellBreakdown,
    assertCellFixedCost,
    assertCellFixedPercentageCost,
    assertCellIsDynamicCost,
    FixedCostCell,
} from '../../types/cellTypes';
import { startsWithExtraField } from '../../types/extraFieldType';
import { RowId } from '../../types/rowIdTypes';
import { Row } from '../../types/rowTypes';
import {
    AdditionalOtherCostsSection,
    AdditionalProfitAndDiscountSection,
    ExtraCost,
    ManufacturingCostSection,
    MaterialCostSection,
    NotIncludedCostsSection,
    PostProfitCostsSection,
    ProjectCostsSection,
    SummarySection,
    TableColumn,
} from '../../types/tableColumnTypes';

const mapExtraCosts = (row: Row, index: number): ExtraCost => {
    const cell = assertCellIsDynamicCost(row.cells[index]);
    return {
        type: 'extra',
        name: row.label,
        cost: cell,
    };
};

const getRowWithId = (rows: Row[]) => {
    return (id: RowId) => rows.find((row) => row.id === id);
};

// Create a default manufacturing cost section when needed
const createDefaultManufacturingSection = (defaultCell: FixedCostCell): ManufacturingCostSection => {
    const defaultCostCell: FixedCostCell = {
        type: 'fixed',
        unitCost: { amount: '0', currency: defaultCell.unitCost.currency },
        totalCost: { amount: '0', currency: defaultCell.totalCost.currency },
        orderSize: defaultCell.orderSize,
        batchSize: defaultCell.batchSize,
        sourcingTotalAvailability: defaultCell.sourcingTotalAvailability,
        sourcingCombinationId: defaultCell.sourcingCombinationId,
        preferredCurrency: defaultCell.preferredCurrency,
        manufacturingLeadTime: undefined,
    };

    return {
        type: 'manufacturing-cost',
        cost: defaultCostCell,
        subTotalCost: defaultCostCell,
        price: defaultCostCell,
        customCosts: {
            type: 'custom',
            profit: {
                type: 'dynamic',
                unitCostValue: { amount: '0', currency: defaultCell.unitCost.currency },
                costFraction: '0',
                costSpecification: {
                    type: 'fixed',
                    value: { amount: '0', currency: defaultCell.unitCost.currency },
                },
                totalCostValue: { amount: '0', currency: defaultCell.totalCost.currency },
                isLocked: false,
                manufacturingLeadTime: undefined,
                rule: () => ({ result: 'Ok' }),
                orderSize: defaultCell.orderSize,
                batchSize: defaultCell.batchSize,
                sourcingTotalAvailability: defaultCell.sourcingTotalAvailability,
                sourcingCombinationId: defaultCell.sourcingCombinationId,
                preferredCurrency: defaultCell.preferredCurrency,
            },
            discount: {
                type: 'dynamic',
                unitCostValue: { amount: '0', currency: defaultCell.unitCost.currency },
                costFraction: '0',
                costSpecification: {
                    type: 'fixed',
                    value: { amount: '0', currency: defaultCell.unitCost.currency },
                },
                totalCostValue: { amount: '0', currency: defaultCell.totalCost.currency },
                isLocked: false,
                rule: () => ({ result: 'Ok' }), // Fixed
                orderSize: defaultCell.orderSize,
                batchSize: defaultCell.batchSize,
                manufacturingLeadTime: undefined,
                sourcingTotalAvailability: defaultCell.sourcingTotalAvailability,
                sourcingCombinationId: defaultCell.sourcingCombinationId,
                preferredCurrency: defaultCell.preferredCurrency,
            },
            extraCosts: [],
        },
    };
};

const convertMaterialCostRowsToColumn = (
    rows: Row[],
    index: number,
    isCalculationWithoutManufacturing: boolean,
): TableColumn => {
    const getRow = getRowWithId(rows);

    const materialCostSection: MaterialCostSection = {
        type: 'material-cost',
        cost: assertCellFixedCost(assertPresent(getRow('material-cost-cost')).cells[index]),
        price: assertCellFixedCost(assertPresent(getRow('material-cost-price')).cells[index]),
        subTotalCost: assertCellFixedCost(assertPresent(getRow('material-cost-subtotal-cost')).cells[index]),
        excessMaterialCost: assertCellBreakdown(assertPresent(getRow('material-cost-excess')).cells[index]),
        customCosts: {
            type: 'custom',
            profit: assertCellIsDynamicCost(assertPresent(getRow('material-cost-profit')).cells[index]),
            discount: assertCellIsDynamicCost(assertPresent(getRow('material-cost-discount')).cells[index]),
            extraCosts: rows
                .filter((row) => startsWithExtraField(row.id, 'material-cost-extra'))
                .map((row) => mapExtraCosts(row, index)),
        },
    };

    const manufacturingCostSection = isCalculationWithoutManufacturing
        ? createDefaultManufacturingSection(materialCostSection.cost)
        : {
              type: 'manufacturing-cost' as const,
              cost: assertCellFixedCost(assertPresent(getRow('manufacturing-cost-cost')).cells[index]),
              subTotalCost: assertCellFixedCost(assertPresent(getRow('manufacturing-cost-subtotal-cost')).cells[index]),
              price: assertCellFixedCost(assertPresent(getRow('manufacturing-cost-price')).cells[index]),
              customCosts: {
                  type: 'custom' as const,
                  profit: assertCellIsDynamicCost(assertPresent(getRow('manufacturing-cost-profit')).cells[index]),
                  discount: assertCellIsDynamicCost(assertPresent(getRow('manufacturing-cost-discount')).cells[index]),
                  extraCosts: rows
                      .filter((row) => startsWithExtraField(row.id, 'manufacturing-cost-extra'))
                      .map((row) => mapExtraCosts(row, index)),
              },
          };

    const additionalOtherCostsSection: AdditionalOtherCostsSection = {
        type: 'additional-other-costs',
        value: rows
            .filter((row) => startsWithExtraField(row.id, 'additional-other-costs-extra'))
            .map((row) => mapExtraCosts(row, index)),
    };

    const additionalProfitAndDiscountSection: AdditionalProfitAndDiscountSection = {
        type: 'additional-profit',
        profit: assertCellIsDynamicCost(assertPresent(getRow('additional-profit-profit')).cells[index]),
        discount: assertCellIsDynamicCost(assertPresent(getRow('additional-profit-discount')).cells[index]),
    };

    const postProfitCostsSection: PostProfitCostsSection = {
        type: 'additional-post-profit',
        value: rows
            .filter((row) => startsWithExtraField(row.id, 'additional-post-profit-extra'))
            .map((row) => mapExtraCosts(row, index)),
    };

    const projectCostsSection: ProjectCostsSection = {
        type: 'project-cost',
        cost: {
            cost: assertCellFixedCost(assertPresent(getRow('project-cost-cost')).cells[index]),
            activities: [],
            expenses: [],
            activityCosts: {},
            expenseCosts: {},
            oneTimeCosts: assertCellBreakdown(assertPresent(getRow('project-cost-one-time-cost')).cells[index]),
        },
        price: assertCellFixedCost(assertPresent(getRow('project-cost-price')).cells[index]),
        profit: assertCellIsDynamicCost(assertPresent(getRow('project-cost-profit')).cells[index]),
    };

    const summarySection: SummarySection = {
        type: 'summary',
        price: assertCellFixedCost(assertPresent(getRow('summary-price')).cells[index]),
        totalCost: assertCellFixedCost(assertPresent(getRow('summary-totalCost')).cells[index]),
        totalProfit: assertCellFixedPercentageCost(assertPresent(getRow('summary-totalProfit')).cells[index]),
    };

    const notIncludedCostsSection: NotIncludedCostsSection = {
        type: 'not-included-costs',
        excessMaterialCost: assertCellFixedCost(
            assertPresent(getRow('not-included-costs-excess-material-cost')).cells[index],
        ),
    };

    return {
        sourcingCombinationId: assertCellFixedCost(assertPresent(getRow('material-cost-cost')).cells[index])
            .sourcingCombinationId,
        batchSize: assertCellFixedCost(assertPresent(getRow('material-cost-cost')).cells[index]).batchSize,
        orderSize: assertCellFixedCost(assertPresent(getRow('material-cost-cost')).cells[index]).orderSize,
        leadTime: assertCellFixedCost(assertPresent(getRow('material-cost-cost')).cells[index])
            .sourcingTotalAvailability,
        manufacturingLeadTime: assertCellFixedCost(assertPresent(getRow('material-cost-cost')).cells[index])
            .manufacturingLeadTime,
        sections: [
            materialCostSection,
            manufacturingCostSection,
            additionalOtherCostsSection,
            additionalProfitAndDiscountSection,
            postProfitCostsSection,
            projectCostsSection,
            summarySection,
            notIncludedCostsSection,
        ],
    };
};

export const convertRowsToTableColumns = (rows: Row[], isCalculationWithoutManufacturing: boolean): TableColumn[] => {
    const allCellsHere = assertPresent(rows.find((row) => row.id === 'material-cost-cost')).cells;
    return allCellsHere.map((_, index) =>
        convertMaterialCostRowsToColumn(rows, index, isCalculationWithoutManufacturing),
    );
};
