import { Trans } from '@lingui/macro';
import { assertUnreachable } from '@luminovo/commons';
import { Flexbox, Tab, Tabs, colorSystem } from '@luminovo/design-system';
import { QuoteTrackingDTO } from '@luminovo/http-client';
import React from 'react';
import { QuotedCustomPartOfferTab } from './QuotedCustomPartOfferTab';
import { QuotedStandardPartOfferTab } from './QuotedStandardPartOfferTab';
import { RequestedPartsTab } from './RequestedPartsTab';

export type QuoteTrackingTabs = 'quoted' | 'requested';

const ContentSelector = ({ tab, quoteTracking }: { tab: QuoteTrackingTabs; quoteTracking: QuoteTrackingDTO }) => {
    switch (tab) {
        case 'quoted':
            if (quoteTracking.request_standard_parts.length > 0 || quoteTracking.standard_part_offers.length > 0) {
                return <QuotedStandardPartOfferTab quoteTracking={quoteTracking} />;
            }
            if (quoteTracking.request_custom_parts.length > 0 || quoteTracking.custom_part_offers.length > 0) {
                return <QuotedCustomPartOfferTab quoteTracking={quoteTracking} />;
            }
            return <></>;
        case 'requested':
            return <RequestedPartsTab quoteTracking={quoteTracking} />;
        default:
            assertUnreachable(tab);
    }
};

export const QuoteTrackingTabsController = ({ quoteTracking }: { quoteTracking: QuoteTrackingDTO }): JSX.Element => {
    const [tab, setTab] = React.useState<QuoteTrackingTabs>('quoted');

    return (
        <Flexbox
            flexDirection={'column'}
            gap={'12px'}
            bgcolor={colorSystem.neutral.white}
            padding={'24px'}
            borderRadius={'12px'}
            boxSizing={'border-box'}
        >
            <Tabs value={tab} size="large">
                <Tab value={'quoted'} label={<Trans>Quoted</Trans>} onClick={() => setTab('quoted')} />
                <Tab value={'requested'} label={<Trans>Requested</Trans>} onClick={() => setTab('requested')} />
            </Tabs>
            <ContentSelector tab={tab} quoteTracking={quoteTracking} />
        </Flexbox>
    );
};
