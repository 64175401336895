/* eslint-disable spellcheck/spell-checker */
import { uniqBy } from '@luminovo/commons';
import { CandidateCpn, PartOptionDTO, PartSuggestionFull, StandardPartTypes } from '@luminovo/http-client';
import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useAssembly } from '../../../../resources/assembly/assemblyHandler';
import { BomItem } from '../../../../resources/designItem/bomItemFrontendTypes';
import { getCandidateCpns } from '../../../../resources/designItem/getCandidateCpns';
import { getCandidateMpns } from '../../../../resources/designItem/getCandidateMpns';
import { ViewContext } from '../../../Bom/components/ModuleTableData';
import { AddParts } from '../AddParts/AddParts';
import { useSuggestions } from '../AddParts/useSuggestions';
import { PartOptions } from '../PartOptions/PartOptionsTable';
import { BomItemFormState } from './BomItemFormState';
import { MainContainer } from './MainContainer';
import { SeparateContainer } from './SeparateContainer';
import { AddMultiplePartOptionsParams, AddSinglePartParams } from './types';

const useBomItemCandidateMpns = (bomItem: BomItem, suggestions: PartSuggestionFull[]) => {
    return React.useMemo(() => {
        return getCandidateMpns(bomItem, suggestions);
    }, [bomItem, suggestions]);
};

const useBOMItemCandidates = (bomItem: BomItem, suggestions: PartSuggestionFull[]) => {
    const candidateMpns = useBomItemCandidateMpns(bomItem, suggestions);
    return React.useMemo(() => {
        const candidateCpns: CandidateCpn[] = getCandidateCpns(bomItem, suggestions);
        return {
            candidateMpns,
            candidateCpns,
        };
    }, [bomItem, candidateMpns, suggestions]);
};

export const OtsPartSpecificationForm = React.memo(function OtsPartSpecificationForm({
    assemblyId,
    viewContext,
    bomItem,
    isEditable,
    submitForm,
    handleAddMultiplePartOptions,
}: {
    assemblyId: string;
    viewContext: ViewContext;
    bomItem: BomItem;
    isEditable: boolean;
    submitForm: () => void;
    handleAddMultiplePartOptions: (params: AddMultiplePartOptionsParams) => void;
}) {
    const { control, setValue } = useFormContext<BomItemFormState>();
    const { field } = useController({
        name: 'otsPart.data.part_options',
        control,
        defaultValue: [],
    });

    const { data: assembly } = useAssembly(assemblyId);

    const partOptions: PartOptionDTO[] = field.value;

    const fullPartsWithApprovalStatus = bomItem.parts;

    const aggregationKey = bomItem.individualDesignItems[0]?.aggregation_key;
    const { suggestions, isLoading: isSuggestionsLoading } = useSuggestions(assembly?.id, aggregationKey);
    const candidates = useBOMItemCandidates(bomItem, suggestions);

    const countOfExistingGenericPartOptions = partOptions.filter(
        (option) => option.part.type === StandardPartTypes.Generic,
    ).length;

    const setPartOptions = React.useCallback(
        (fn: (existingPats: PartOptionDTO[]) => PartOptionDTO[]) => {
            setValue(
                'otsPart.data.part_options',
                uniqBy(fn(partOptions), (p) => p.part.data /* the part ID */),
                {
                    shouldDirty: true,
                    shouldValidate: true,
                },
            );
            submitForm();
        },
        [partOptions, submitForm, setValue],
    );

    const handleAddPartOption = React.useCallback(
        (params: AddSinglePartParams) => {
            const { data: newPart, sectionOfScreen, alternativeSimilarity, suggestionOrigin } = params;
            handleAddMultiplePartOptions({
                data: [newPart],
                sectionOfScreen,
                alternativeSimilarity,
                suggestionOrigin,
            });
        },
        [handleAddMultiplePartOptions],
    );

    const handleRemoveMultiplePartOptions = React.useCallback(
        (partIds: string[]) => {
            const partOptionsToDelete = partOptions.filter((part) => partIds.includes(part.part.data));
            if (partOptionsToDelete.length === 0) {
                return;
            }
            const countOfGenericPartsToDelete = partOptionsToDelete.filter(
                (part) => part.part.type === StandardPartTypes.Generic,
            ).length;

            if (countOfGenericPartsToDelete === countOfExistingGenericPartOptions) {
                setValue('otsPart.data.is_manufacturer_free', false);
            }

            setPartOptions((parts) => {
                return parts.filter((part) => !partIds.includes(part.part.data));
            });
        },
        [countOfExistingGenericPartOptions, partOptions, setPartOptions, setValue],
    );

    const handleRemovePartOption = React.useCallback(
        (partId: string) => {
            const partOption = partOptions.find((partOption) => partOption.part.data === partId);
            if (!partOption) {
                return;
            }
            const isTheLastGenericPartToBeRemovedFromOptions = countOfExistingGenericPartOptions === 1;
            if (partOption.part.type === StandardPartTypes.Generic && isTheLastGenericPartToBeRemovedFromOptions) {
                setValue('otsPart.data.is_manufacturer_free', false);
            }
            setPartOptions((parts) => {
                return parts.filter((p) => p.part.data !== partId);
            });
        },
        [countOfExistingGenericPartOptions, partOptions, setPartOptions, setValue],
    );

    const suggestionsRef = React.useRef<HTMLDivElement>(null);
    const isVisible = useAreSuggestionsVisible(suggestionsRef);

    const rfqId = viewContext.rfqId;

    if (!isEditable) {
        return (
            <PartOptions
                assemblyId={assemblyId}
                viewContext={viewContext}
                bomItem={bomItem}
                isEditable={isEditable}
                partOptions={partOptions}
                fullPartsWithApprovalStatus={fullPartsWithApprovalStatus}
                candidates={candidates}
                handleRemovePartOption={handleRemovePartOption}
                assemblyIndustry={assembly?.industry}
                setPartOptions={setPartOptions}
                handleRemoveMultiplePartOptions={handleRemoveMultiplePartOptions}
            />
        );
    }

    return (
        <>
            <MainContainer>
                <PartOptions
                    assemblyId={assemblyId}
                    viewContext={viewContext}
                    bomItem={bomItem}
                    isEditable={isEditable}
                    partOptions={partOptions}
                    fullPartsWithApprovalStatus={fullPartsWithApprovalStatus}
                    candidates={candidates}
                    handleRemovePartOption={handleRemovePartOption}
                    assemblyIndustry={assembly?.industry}
                    setPartOptions={setPartOptions}
                    handleRemoveMultiplePartOptions={handleRemoveMultiplePartOptions}
                />
            </MainContainer>
            <SeparateContainer ref={suggestionsRef}>
                {isVisible && rfqId && (
                    <AddParts
                        rfqId={rfqId}
                        assemblyId={assemblyId}
                        bomItem={bomItem}
                        partOptions={partOptions}
                        isEditable={isEditable}
                        handleAddPart={handleAddPartOption}
                        handleAddMultiplePartOptions={handleAddMultiplePartOptions}
                        handleRemovePartOption={handleRemovePartOption}
                        suggestions={suggestions}
                        isSuggestionsLoading={isSuggestionsLoading}
                        assemblyIndustry={assembly?.industry}
                    />
                )}
            </SeparateContainer>
        </>
    );
});

function useAreSuggestionsVisible(ref: React.RefObject<HTMLElement>) {
    const [isVisible, setIsVisible] = React.useState(false);

    React.useEffect(() => {
        // if the element is already visible, we don't need to toggle its visibility again when its no longer visible.
        if (isVisible) return;
        const observer = new IntersectionObserver(([entry]) => setIsVisible(entry.isIntersecting));
        if (ref.current) observer.observe(ref.current);
        return () => {
            observer.disconnect();
        };
    }, [isVisible, ref]);

    return isVisible;
}
