import { getCustomerPortalTenant } from '@/permissions/tenants';
import { t } from '@lingui/macro';
import { useAuth } from '@luminovo/auth';
import { isPresent, throwErrorUnlessProduction } from '@luminovo/commons';
import { CenteredLayout, NonIdealState } from '@luminovo/design-system';
import { ExitToApp } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import { useHttpQuery } from '../../../resources/http/useHttpQuery';
import { useGlobalCurrency } from '../../../resources/organizationSettings/currencySettingsHandler';
import { useQuoteTracking } from '../../../resources/quoteTracking/quoteTrackingHandler';
import { analytics } from '../../../utils/analytics';
import { CustomPartQuoteDetails } from './CustomPartQuoteDetailsPage';

export function QuoteDetailsPage({ quoteTrackingId }: { quoteTrackingId: string }) {
    const { data: quoteTracking, isLoading } = useQuoteTracking({ quoteTrackingId });
    const { isLoading: isCurrencyLoading } = useGlobalCurrency();
    const { data: supplierPortalSettings } = useHttpQuery('GET /organization-settings/supplier-portal-settings', {});

    const { logout } = useAuth({
        auth0OrgId: getCustomerPortalTenant()?.auth0OrgId,
    });

    if (isLoading || isCurrencyLoading || !isPresent(supplierPortalSettings)) {
        return (
            <CenteredLayout>
                <CircularProgress />
            </CenteredLayout>
        );
    }

    if (!quoteTracking) {
        return (
            <CenteredLayout>
                <NonIdealState
                    title={t`Quote request not found`}
                    description={t`The quote request does not exist.`}
                    action={{
                        onClick: () => {
                            analytics.track('supplier_quote_request_not_found');
                            logout();
                        },
                        variant: 'outlined',
                        color: 'primary',
                        startIcon: <ExitToApp />,
                        children: t`Log out`,
                    }}
                />
            </CenteredLayout>
        );
    }

    if (quoteTracking.request_custom_parts.length > 0 || quoteTracking.custom_part_offers.length > 0) {
        return <CustomPartQuoteDetails quoteTrackingId={quoteTracking.id} />;
    }

    throwErrorUnlessProduction(new Error('No parts found in quote tracking'));
    return <NonIdealState title={t`No parts found`} description={undefined} />;
}
