import { Trans, t } from '@lingui/macro';
import { isPresent } from '@luminovo/commons';
import React from 'react';

import {
    BreadcrumbItem,
    CenteredLayout,
    Flexbox,
    Message,
    ReleaseTag,
    Tab,
    Tabs,
    colorSystem,
} from '@luminovo/design-system';
import { AssemblyDTO, UserType } from '@luminovo/http-client';
import { CircularProgress } from '@mui/material';
import { useCurrentUserDetailsContext } from '../../../components/contexts/CurrentUserDetailsContext';
import { PageLayoutSidebar } from '../../../components/PageLayoutSidebar';
import { parseDesignTab } from '../../../components/ProgressAwareTabs/ProgressAwareTabs';
import { isSupplyChainCollaborationEnvironment, useIsPnpImporterActive } from '../../../featureFlags';
import { useAssembly, useParentsIdentifiers } from '../../../resources/assembly/assemblyHandler';
import { useHttpQuery } from '../../../resources/http/useHttpQuery';
import { analytics } from '../../../utils/analytics';
import { UrlParams, route } from '../../../utils/routes';
import AssemblySidebar from '../../Bom/components/AssemblySidebar/AssemblySidebar';
import { BomHealthDashboard } from '../../Bom/components/BomHealthDashboard/BomHealthDashboard';
import { dashboardWidth } from '../../Bom/components/BomHealthDashboard/BomOverviewTable/BomOverview';
import { FilesSection } from '../../Bom/components/FilesSection';
import { ViewContext } from '../../Bom/components/ModuleTableData';
import { useBOMTableData } from '../../Bom/components/ModuleTableData/useBOMTableData';
import { PnPImporter } from '../../PickAndPlaceImporter/PickAndPlaceImporterPage';
import { useIsAssemblyEditable } from '../utils';
import { DemandTab } from './DemandTab';
import AssemblyOverviewToolbar from './Toolbar';

type ActiveTab = 'designHealth' | 'demand';
export default function AssemblyDashboardPage({
    pathParams,
    queryParams,
}: UrlParams<'/assemblies/:assemblyId/dashboard'>) {
    const { assemblyId } = pathParams;
    const { rfqId, tab } = queryParams;
    const { userType } = useCurrentUserDetailsContext();
    const parsedTab = parseDesignTab(tab);
    const { isPnpImporterActive } = useIsPnpImporterActive();

    const isCustomer = userType === UserType.Customer;
    const { data: { multi_parent_items: multiParentItems = [[]] } = {} } = useParentsIdentifiers(assemblyId);
    const { data: assembly } = useAssembly(assemblyId, { with_aggregations: true, enabled: true });
    const currentAssembly = assembly as AssemblyDTO | undefined;
    const {
        descendants,
        moduleData,
        containsNestedAssembliesOrDesignItems,
        isLoading: isModuleDataLoading,
    } = useBOMTableData({
        assemblyId,
        rfqId: rfqId ?? undefined,
    });
    const { data: assemblyIpn, isLoading: isAssemblyIpnLoading } = useHttpQuery(
        'GET /assemblies/:assemblyId/data',
        {
            pathParams: { assemblyId },
        },
        {
            select: (data) => data.ipn?.id,
        },
    );
    const [isSideBarOpen, setIsSideBarOpen] = React.useState(true);

    const [activeTab, setActiveTab] = React.useState<ActiveTab>('designHealth');

    const viewContext: ViewContext = React.useMemo(() => {
        return {
            type: 'AssemblyOverview',
            rfqId: rfqId ?? undefined,
        };
    }, [rfqId]);

    const handleTabChange = React.useCallback(
        (_event, value) => {
            analytics.track('assembly_dashboard_tab_change', {
                tab: value,
            });
            setActiveTab(value);
        },
        [setActiveTab],
    );
    const toggleIsSidebarOpen = React.useCallback(() => {
        setIsSideBarOpen((current) => !current);
    }, []);

    const isEditable = useIsAssemblyEditable(assembly);

    const sideBar = React.useMemo(() => {
        if (parsedTab === 'Bom' && currentAssembly)
            return (
                <AssemblySidebar
                    assembly={currentAssembly}
                    isSideBarOpen={isSideBarOpen}
                    toggleIsSidebarOpen={toggleIsSidebarOpen}
                    viewContext={viewContext}
                />
            );
    }, [currentAssembly, isSideBarOpen, parsedTab, toggleIsSidebarOpen, viewContext]);

    if (!isPresent(currentAssembly) || !isPresent(descendants) || isAssemblyIpnLoading) {
        return (
            <CenteredLayout height={'30vh'}>
                <CircularProgress />
            </CenteredLayout>
        );
    }

    const additionalBreadCrumbsFromRoot = (): BreadcrumbItem[] => {
        const result: BreadcrumbItem[] = [
            {
                title: currentAssembly.designator,
                href: parsedTab !== 'Bom' ? route('/assemblies/:assemblyId/dashboard', { assemblyId }) : undefined,
            },
        ];

        if (parsedTab === 'Cad') result.push({ title: t`CAD` });
        if (parsedTab === 'Manufacturing') result.push({ title: t`Files` });
        if (parsedTab === 'Pnp') result.push({ title: t`PnP` });

        return result;
    };

    const isBomNestedTableShown =
        currentAssembly.origin.type === 'Manual' ? containsNestedAssembliesOrDesignItems : true;
    return (
        <PageLayoutSidebar
            header={
                <AssemblyOverviewToolbar
                    tab={parsedTab}
                    additionalBreadcrumbsFromRoot={additionalBreadCrumbsFromRoot()}
                    assembly={currentAssembly}
                    isCustomer={isCustomer}
                />
            }
            defaultSidebarWidth={isSideBarOpen ? '300px' : '56px'}
            sidebar={sideBar}
            contentBackground={colorSystem.neutral[1]}
        >
            <div
                style={{
                    padding: '20px 20px 0px 20px',
                    maxWidth: dashboardWidth,
                    margin: '0 auto',
                }}
            >
                {parsedTab === 'Bom' && !isCustomer && (
                    <Tabs value={activeTab} size="large" onChange={handleTabChange}>
                        <Tab value={'designHealth'} label={<Trans>Design health</Trans>} />
                        {isSupplyChainCollaborationEnvironment() && (
                            <Tab
                                value={'demand'}
                                label={
                                    <Flexbox gap={4}>
                                        <Trans>Demand</Trans> <ReleaseTag color="violet" label={t`Beta`} />
                                    </Flexbox>
                                }
                            />
                        )}
                    </Tabs>
                )}
            </div>
            {activeTab === 'demand' && !isCustomer && (
                <div
                    style={{
                        paddingTop: '20px',
                        paddingLeft: '20px',
                        paddingRight: '20px',
                        height: '100%',
                    }}
                >
                    {!assemblyIpn && (
                        <Message
                            attention="high"
                            size="small"
                            variant="blue"
                            message={
                                <Trans>
                                    No IPN found for this assembly. An assembly must have an IPN to be included in
                                    supply chain calculations.
                                </Trans>
                            }
                        />
                    )}

                    {assemblyIpn && <DemandTab assemblyId={assemblyId} />}
                </div>
            )}
            {activeTab === 'designHealth' && (
                <>
                    {currentAssembly.origin.type === 'ApiImport' && (
                        <div
                            style={{
                                paddingTop: '20px',
                                paddingLeft: '20px',
                                paddingRight: '20px',
                            }}
                        >
                            <Message
                                attention="high"
                                size="small"
                                variant="blue"
                                message={
                                    <Trans>
                                        You cannot manually edit imported assemblies. If you want to make changes,
                                        please re-import the assemblies again with the changes.
                                    </Trans>
                                }
                            />
                        </div>
                    )}
                    {parsedTab === 'Bom' && (
                        <BomHealthDashboard
                            assemblyId={assemblyId}
                            assembly={currentAssembly}
                            descendants={descendants}
                            moduleData={moduleData}
                            isModuleDataLoading={isModuleDataLoading}
                            isEditable={isEditable}
                            parents={multiParentItems}
                            viewContext={viewContext}
                            isBomNestedTableShown={isBomNestedTableShown}
                        />
                    )}

                    {parsedTab === 'Cad' && rfqId && (
                        <CenteredLayout bgcolor={colorSystem.neutral[1]} justifyContent="unset" paddingTop={'48px'}>
                            <FilesSection tab={'Cad'} isEditable={isEditable} assemblyId={assemblyId} rfqId={rfqId} />
                        </CenteredLayout>
                    )}

                    {parsedTab === 'Manufacturing' && rfqId && (
                        <CenteredLayout bgcolor={colorSystem.neutral[1]} justifyContent="unset" paddingTop={'48px'}>
                            <FilesSection
                                tab={'Manufacturing'}
                                isEditable={isEditable}
                                assemblyId={assemblyId}
                                rfqId={rfqId}
                            />
                        </CenteredLayout>
                    )}
                    {parsedTab === 'Pnp' && rfqId && isPnpImporterActive && (
                        <div
                            style={{
                                height: 'max-content',
                                margin: '20px',
                            }}
                        >
                            <PnPImporter rfqId={rfqId} assemblyId={assemblyId} viewContext={viewContext} />
                        </div>
                    )}
                </>
            )}
        </PageLayoutSidebar>
    );
}
