import type { GridApi } from 'ag-grid-community';

export function getVisibleRows<T>(api?: GridApi<T>): Array<T> {
    if (!api) return [];
    const rows: Array<T> = [];
    api.forEachNodeAfterFilterAndSort((node) => {
        rows.push(node.data!);
    });
    return rows;
}

export function getAllRows<T>(api?: GridApi<T>): Array<T> {
    if (!api) return [];
    const rows: Array<T> = [];
    api.forEachNode((node) => {
        rows.push(node.data!);
    });
    return rows;
}
