import { route } from '@/utils/routes';
import { Trans } from '@lingui/macro';
import { id } from '@luminovo/commons';
import { PrimaryButton, SecondaryButton, Text } from '@luminovo/design-system';
import { QuoteTrackingDTO } from '@luminovo/http-client';
import { PictureAsPdfRounded } from '@mui/icons-material';
import { ButtonProps } from '@mui/material';
import { withErrorBoundary } from '@sentry/react';
import React from 'react';
import { useLocation } from 'react-router-dom';

export const PdfQuoteImportButton = withErrorBoundary(
    function PdfQuoteImportButton({
        quoteTracking,
        label = <Trans>Import PDF</Trans>,
        size = 'small',
        variant,
    }: {
        quoteTracking: QuoteTrackingDTO;
        label?: React.ReactNode;
        variant?: 'primary' | 'secondary';
        size?: ButtonProps['size'];
    }) {
        const ButtonComponent = variant === 'primary' ? PrimaryButton : SecondaryButton;
        const { pathname, search } = useLocation();
        return (
            <ButtonComponent
                href={route(
                    '/offers/pdf-offer-importer/:rfqId',
                    { rfqId: quoteTracking.rfq },
                    {
                        returnTo: pathname + search,
                        quoteTrackingId: quoteTracking.id,
                    },
                )}
                id={id('sourcing/button_import_pdf')}
                size={size}
                startIcon={<PictureAsPdfRounded />}
            >
                {label}
            </ButtonComponent>
        );
    },
    { fallback: <Text>Something went wrong</Text> },
);
