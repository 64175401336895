import * as z from 'zod';

import { ComplianceStatus, ComplianceStatusRuntype, EmissionDataRuntype } from '../backendTypes';
import { BomImportedAssemblyIssueRuntype } from '../bomImporter';
import {
    DesignItemExcelOriginDTORuntype,
    DesignItemResponseDTONewRuntype,
    DesignItemResponseDTORuntype,
} from '../designItem';
import { LifecycleEnum } from '../part';
import { RegionsEnumRuntype } from '../supplierAndStockLocation';

export const PcbAssemblyTypeEnumRuntype = z.enum(['Pcba', 'PcbOnly']);

export const NonPcbAssemblyTypeEnumRuntype = z.enum(['BOM', 'Box', 'Cable', 'System']);

export const AssemblyTypeEnumRuntype = PcbAssemblyTypeEnumRuntype.or(NonPcbAssemblyTypeEnumRuntype);

export type AssemblyTypeEnum = z.infer<typeof AssemblyTypeEnumRuntype>;
export type AssemblyTypePublic = Exclude<AssemblyTypeEnum, 'BOM'>;

export interface AssemblyType extends z.infer<typeof AssemblyTypeRuntype> {}

export const AssemblyTypeRuntype = z.object({
    type: AssemblyTypeEnumRuntype,
    //Pcba Type can hold an ID from a Stackrate Pcba
    data: z.string().nullable().optional(),
});

export enum OverallRisk {
    LowRisk = 'LowRisk',
    MediumLowRisk = 'MediumLowRisk',
    MediumRisk = 'MediumRisk',
    MediumHighRisk = 'MediumHighRisk',
    HighRisk = 'HighRisk',
}

const OverallRiskRuntype = z.nativeEnum(OverallRisk);

export enum AvailabilityStatusEnum {
    InStock = 'InStock',
    InsufficientStock = 'InsufficientStock',
    OutOfStock = 'OutOfStock',
    InventoryOfEMS = 'InventoryOfEMS',
    ConsignedByOEM = 'ConsignedByOEM',
}

export const AvailabilityStatusEnumRuntype = z.nativeEnum(AvailabilityStatusEnum);

export enum AssemblyIndustry {
    Aero = 'Aero',
    Auto = 'Auto',
    Construction = 'Construction',
    Consumer = 'Consumer',
    Defense = 'Defense',
    Industrial = 'Industrial',
    Medical = 'Medical',
    Other = 'Other',
    Rail = 'Rail',
}

export const AssemblyIndustryRuntype = z.nativeEnum(AssemblyIndustry);

const UuidItemsRuntype = z.object({
    items: z.array(z.string()),
});

const StockRuntype = z.object({
    required_stock: z.number(),
    inventory_stock: z.number(),
    external_stock: z.number(),
});

export type AvailabilityDTO = z.infer<typeof AvailabilityRuntype>;
const AvailabilityRuntype = z.union([
    z.object({
        type: z.literal(AvailabilityStatusEnum.OutOfStock),
        data: StockRuntype.nullable(),
    }),
    z.object({
        type: z.literal(AvailabilityStatusEnum.InsufficientStock),
        data: StockRuntype,
    }),
    z.object({
        type: z.literal(AvailabilityStatusEnum.InStock),
        data: StockRuntype.nullable(),
    }),
    z.object({
        type: z.literal(AvailabilityStatusEnum.InventoryOfEMS),
        data: StockRuntype,
    }),
    z.object({
        type: z.literal(AvailabilityStatusEnum.ConsignedByOEM),
    }),
]);

export enum PartCountEnum {
    Multiple = 'Multiple',
    None = 'None',
    DNP = 'DNP',
    Single = 'Single',
}

const PartCountRuntype = z.nativeEnum(PartCountEnum);

export enum BomItemApprovalStatus {
    DNP = 'DNP',
    Pending = 'Pending',
    Rejected = 'Rejected',
    Warning = 'Warning',
    Approved = 'Approved',
}

export enum BomItemIssue {
    InsufficientStock = 'insufficientStock',
    Lifecycle = 'lifecycle',
    Compliance = 'compliance',
    // MissingPartData will always be included whenever
    // we have PinsMissing | MountingMissing | PackageNameMissing | PackageMismatch
    MissingPartData = 'missingPartData',
    PinsMissing = 'pinsMissing',
    MountingMissing = 'mountingMissing',
    PackageNameMissing = 'packageNameMissing',
    PackageNameOnlyMismatch = 'packageNameOnlyMismatch',
    PackageMismatch = 'packageMismatch',
    NoPartOptionsApproved = 'noPartOptionsApproved',
    DesignatorAndQuantityUnclear = 'designatorAndQuantityUnclear',
    DoNotPlace = 'doNotPlace',
}

export enum BomItemPartDataIssues {
    PinsMissing = 'PinsMissing',
    MountingMissing = 'MountingMissing',
    PackageNameMissing = 'PackageNameMissing',
    PackageMismatch = 'PackageMismatch',
    PackageNameOnlyMismatch = 'PackageNameOnlyMismatch',
}

export enum BomItemGeneralProperties {
    MissingDataWithSuggestions = 'MissingDataWithSuggestions',
    ApprovedBomItemsWithPartialMatches = 'ApprovedBomItemsWithPartialMatches',
    HasChangesFromPreviousImport = 'HasChangesFromPreviousImport',
    FewerPartOptionsAddedThanAlternativesInOriginalBOM = 'FewerPartOptionsAddedThanAlternativesInOriginalBOM',
}

export interface BomItemDTO extends z.infer<typeof BomItemsRuntype> {}

const BomItemsRuntype = z.object({
    design_items: z.array(z.string()),
    reach_compliant: z.nativeEnum(ComplianceStatus).optional(),
    rohs_compliant: z.nativeEnum(ComplianceStatus).optional(),
    aecq_compliant: z.nativeEnum(ComplianceStatus).optional(),
    lifecycle_status: z.nativeEnum(LifecycleEnum).optional(),
    availability: AvailabilityRuntype.nullable(),
    years_to_end_of_life: z.number().nullable(),
    part_option_cardinality: PartCountRuntype,
    approval_status: z.nativeEnum(BomItemApprovalStatus),
    issues: z.array(z.nativeEnum(BomItemIssue)),
    part_data_issues: z.array(z.nativeEnum(BomItemPartDataIssues)),
    general_properties: z.array(z.nativeEnum(BomItemGeneralProperties)),
    emission_data: EmissionDataRuntype.nullable(),
    country_of_origin: z.array(RegionsEnumRuntype),
    bom_cpns: z.array(z.string()),
});

export const EmissionsDataSummaryRuntype = z.object({
    kind: z.enum(['Equal', 'Gte']), // Gte = greater than or equal
    product_phase_gwp_in_kg_co2e_min: z.string(),
    product_phase_gwp_in_kg_co2e_max: z.string(),
});

export interface EmissionsDataSummary extends z.infer<typeof EmissionsDataSummaryRuntype> {}

export interface AssemblyDataDTO extends z.infer<typeof AssemblyDataDTORuntype> {}

const SubAssemblies = z.object({
    items: z.array(
        z.object({
            assembly_id: z.string(),
            quantity: z.number(),
        }),
    ),
});

export const BareIpnIdentifierRuntype = z.object({
    id: z.string(),
    value: z.string(),
    revision: z.string().nullable(),
});

export const BareCpnIdentifierRuntype = z.object({
    value: z.string(),
    revision: z.string().nullable(),
});

// Manual origin means the assemblies were created in the UI
// BomImport -> They were extracted in the BOM Importer
// ApiImport -> Imported via the API
const AssemblyOriginTypeRuntype = z.enum(['Manual', 'ApiImport', 'BomImport']);

const AssemblyOriginFilterRuntype = z.object({
    type: AssemblyOriginTypeRuntype,
});

export const BomImportedAssemblyIdentifierRuntype = z.object({
    ipns: z.array(z.string()),
    cpns: z.array(z.string()),
    cpn_revisions: z.array(z.string()),
});

const AssemblyExcelOriginRuntype = z.object({
    excel_origin_id: z.string(),
    identifier: BomImportedAssemblyIdentifierRuntype,
});

const AssemblyOriginValueRuntype = z.object({
    excel_origin: AssemblyExcelOriginRuntype.nullable(),
    issues: z.array(BomImportedAssemblyIssueRuntype),
});

export const AssemblyOriginDTORuntype = z.union([
    z.object({
        type: z.enum(['Manual', 'ApiImport']),
    }),
    z.object({
        type: z.literal('BomImport'),
        value: AssemblyOriginValueRuntype,
    }),
]);

export const AssemblyDataDTORuntype = z.object({
    id: z.string(),
    designator: z.string(),
    type: AssemblyTypeRuntype,
    notes: z.null().or(z.string()),
    parents: z.record(z.string(), z.number()),
    subassemblies: SubAssemblies,
    rfq: z.string().nullable(),
    customer: z.null().or(z.string()),
    industry: AssemblyIndustryRuntype,
    created_at: z.string(),
    ipn: BareIpnIdentifierRuntype.nullable(),
    origin: AssemblyOriginDTORuntype,
});

export interface AssemblyDTO extends z.infer<typeof AssemblyDTORuntype> {}

export const AssemblyDTORuntype = z.object({
    id: z.string(),
    designator: z.string(),
    type: AssemblyTypeRuntype,
    design_items: UuidItemsRuntype,
    bom_items: z.array(BomItemsRuntype),
    subassemblies: SubAssemblies,
    notes: z.null().or(z.string()),
    ipn: BareIpnIdentifierRuntype.nullable(),
    rfq: z.string().nullable(),
    customer: z.null().or(z.string()),
    industry: AssemblyIndustryRuntype,
    reach_compliant: z.nativeEnum(ComplianceStatus).optional(),
    rohs_compliant: z.nativeEnum(ComplianceStatus).optional(),
    aecq_compliant: z.nativeEnum(ComplianceStatus).optional(),
    lifecycle_status: z.nativeEnum(LifecycleEnum).optional(),
    availability: AvailabilityStatusEnumRuntype.nullable(),
    overall_risk: OverallRiskRuntype,
    years_to_end_of_life: z.number().nullable(),
    approval_status: z.nativeEnum(BomItemApprovalStatus),
    parents: z.record(z.string(), z.number()),
    origin: AssemblyOriginDTORuntype,
    emission_data: EmissionsDataSummaryRuntype.nullable(),
    depths: z.array(z.number()),
});

export type AssemblyResponseDTO = z.infer<typeof AssemblyResponseRuntype>;

export const AssemblyResponseRuntype = z.union([AssemblyDTORuntype, AssemblyDataDTORuntype]);

export interface AssemblyFormDTO extends z.infer<typeof AssemblyFormRequest> {}

export const SubAssemblyQuantity = z.object({
    assembly_id: z.string(),
    quantity: z.number(),
});

const ResolvableBomImportedIssues = z.literal('AutomaticallyGenerated');
export const AssemblyFormRequest = z.object({
    designator: z.string().optional(),
    type: AssemblyTypeRuntype,
    rfq: z.string(),
    customer: z.string(),
    notes: z.string().optional(),
    industry: AssemblyIndustryRuntype,
    parents: z.array(z.string()),
    ipn_value: z.string().optional(),
    ipn_revision: z.string().optional(),
    sub_assembly_quantities: z.array(SubAssemblyQuantity),
    bom_imported_issues_to_resolve: z.array(ResolvableBomImportedIssues).optional(),
});

export interface PaginatedAssemblyDTO {
    data: AssemblyDTO;
    total_pages: number;
}

export interface YearsToEndOfLifeWithCount extends z.infer<typeof YearsToEndOfLifeWithCountRuntype> {}

const YearsToEndOfLifeWithCountRuntype = z.object({
    years_to_end_of_life: z.number(),
    count: z.number(),
});

export interface DescendantsSummaryDTO extends z.infer<typeof DescendantsSummaryDTORuntype> {}

export const DescendantsSummaryDTORuntype = z.object({
    top_level_assembly_types: z.array(AssemblyTypeRuntype),
    assembly_types: z.array(AssemblyTypeRuntype),
    assembly_count: z.number(),
    design_item_count: z.number(),
    emission_data: EmissionsDataSummaryRuntype.nullable(),
    lifecycle: z.array(
        z.object({
            status_type: z.nativeEnum(LifecycleEnum),
            count: z.number(),
        }),
    ),
    availability: z.array(
        z.object({
            count: z.number(),
            status_type: z.nativeEnum(AvailabilityStatusEnum),
        }),
    ),
    reach_compliance: z.array(
        z.object({
            count: z.number(),
            status_type: ComplianceStatusRuntype,
        }),
    ),
    rohs_compliance: z.array(
        z.object({
            count: z.number(),
            status_type: ComplianceStatusRuntype,
        }),
    ),
    aecq_compliance: z.array(
        z.object({
            count: z.number(),
            status_type: ComplianceStatusRuntype,
        }),
    ),
    part_options: z.array(
        z.object({
            count: z.number(),
            status_type: PartCountRuntype,
        }),
    ),
    overall_risk: OverallRiskRuntype,
    years_to_end_of_life: z.array(YearsToEndOfLifeWithCountRuntype),
});

export interface DesignItemExcelOriginsDictionary extends z.infer<typeof DesignItemExcelOriginsDictionaryRuntype> {}

export const DesignItemExcelOriginsDictionaryRuntype = z.record(z.string(), DesignItemExcelOriginDTORuntype);

export interface DescendantsDTO extends z.infer<typeof DescendantsDTORuntype> {}

export const DescendantsDTORuntype = z.object({
    parent: AssemblyDTORuntype,
    assemblies: z.array(AssemblyDTORuntype),
    design_items: z.array(DesignItemResponseDTORuntype),
    excel_lines: DesignItemExcelOriginsDictionaryRuntype.optional(),
    can_see_part_alternatives: z.boolean(),
});

export interface DescendantsDTONew extends z.infer<typeof DescendantsDTONewRuntype> {}

export const DescendantsDTONewRuntype = z.object({
    parent: AssemblyDTORuntype,
    assemblies: z.array(AssemblyDTORuntype),
    design_items: z.array(DesignItemResponseDTONewRuntype),
    excel_lines: DesignItemExcelOriginsDictionaryRuntype,
    can_see_part_alternatives: z.boolean(),
});

export const AssemblyResourcesRuntype = z.object({
    other: z.array(z.string()),
    cad: z.string().nullable(),
});

export enum UploadedFileType {
    Pnp = 'Pnp',
    Cad = 'Cad',
    Other = 'Other',
}

export interface InterestsDTO extends z.infer<typeof InterestsDTORuntype> {}

const InterestsDTORuntype = z.object({
    lifecycle: z.boolean(),
    compliance: z.boolean(),
});

export interface AssemblyMonitoring extends z.infer<typeof AssemblyMonitoringRuntype> {}

export const AssemblyMonitoringRuntype = z.object({
    top_level_assembly: z.string(),
    frequency: z.enum(['Daily', 'Weekly', 'Monthly', 'Inactive']),
    interests: InterestsDTORuntype,
    is_active: z.boolean(),
    users: z.array(
        z.object({
            first_name: z.string(),
            last_name: z.string(),
            email: z.string(),
            id: z.string(),
        }),
    ),
});

const MinimalCustomerDTORuntype = z.object({
    id: z.string(),
    name: z.string(),
    customer_number: z.string().nullable(),
});

export interface BareIpnIdentifier extends z.infer<typeof BareIpnIdentifierRuntype> {}
export interface BareCpnIdentifier extends z.infer<typeof BareCpnIdentifierRuntype> {}

export type InventoryQuantityDTO = z.infer<typeof InventoryQuantityDTORuntype>;
export const InventoryQuantityDTORuntype = z.object({
    site_name: z.string(),
    quantity: z.number(),
});

export type AssemblyOverviewDTO = z.infer<typeof AssemblyOverviewDTORuntype>;
export const AssemblyOverviewDTORuntype = z.object({
    id: z.string(),
    designator: z.string(),
    ipn: BareIpnIdentifierRuntype.nullable(),
    cpn: BareCpnIdentifierRuntype.nullable(),
    type: AssemblyTypeRuntype,
    notes: z.null().or(z.string()),
    rfq: z.string().nullable(),
    customer: z.null().or(MinimalCustomerDTORuntype),
    industry: AssemblyIndustryRuntype,
    created_at: z.string(),
    updated_at: z.string(),
    origin: AssemblyOriginDTORuntype,
    inventory_quantities: z.array(InventoryQuantityDTORuntype),
});

const CustomAssemblyFilters = z.enum(['TopLevelAssemblies', 'AssembliesWithDemand', 'ImportedAssemblies']);

export type AssemblyOverviewFilterRequest = z.infer<typeof AssemblyOverviewFilterRequestRuntype>;
export const AssemblyOverviewFilterRequestRuntype = z.union([
    z.object({
        field: z.literal('Name'),
        operator: z.literal('includesString'),
        parameter: z.string(),
    }),
    z.object({
        field: z.literal('Ipn'),
        operator: z.literal('includesString'),
        parameter: z.string(),
    }),
    z.object({
        field: z.literal('Customer'),
        operator: z.literal('equalsAny'),
        parameter: z.array(z.string()),
    }),
    z.object({
        field: z.literal('Origin'),
        operator: z.literal('equalsAny'),
        parameter: z.array(AssemblyOriginFilterRuntype),
    }),
    z.object({
        field: z.literal('Industry'),
        operator: z.literal('equalsAny'),
        parameter: z.array(AssemblyIndustryRuntype),
    }),
    z.object({
        field: z.literal('Custom'),
        operator: z.literal('equalsAny'),
        parameter: z.array(CustomAssemblyFilters),
    }),
]);

export type AssemblyOverviewAggregationDTO = z.infer<typeof AssemblyOverviewAggregationDTORuntype>;
export const AssemblyOverviewAggregationDTORuntype = z.union([
    z.object({
        field: z.literal('Industry'),
        options: z.array(
            z.object({
                value: AssemblyIndustryRuntype,
                count: z.number(),
            }),
        ),
    }),
    z.object({
        field: z.literal('Origin'),
        options: z.array(
            z.object({
                value: AssemblyOriginFilterRuntype,
                count: z.number(),
            }),
        ),
    }),
    z.object({
        field: z.literal('Customer'),
        options: z.array(
            z.object({
                value: z.string(),
                count: z.number(),
            }),
        ),
    }),
]);

export interface AssemblyOverviewResponse extends z.infer<typeof AssemblyOverviewResponseRuntype> {}

export const AssemblyOverviewResponseRuntype = z.object({
    total_count: z.number(),
    page_params: z.unknown().or(z.undefined()),
    aggregations: z.array(AssemblyOverviewAggregationDTORuntype),
    page: z.array(AssemblyOverviewDTORuntype),
});

const InternalPartNumber = z.object({
    value: z.string(),
});

const PartOption = z.object({
    internal_part_number: InternalPartNumber,
});

const BomItem = z.object({
    designators: z.array(z.string()),
    quantity: z.number(),
    unit: z.literal('Pieces'),
    part_options: z.array(PartOption),
    notes: z.string().nullable().optional(),
});

const Customer = z.object({
    number: z.string(),
});

const CustomerPartNumber = z.object({
    customer: Customer,
});

const ImportAssemblyTypeRuntype = z.enum(['Box build', 'PCBA', 'Cable', 'System']);

const Assembly = z.object({
    bom_items: z.array(BomItem),
    customer_part_numbers: z.array(CustomerPartNumber),
    internal_part_number: InternalPartNumber,
    type: ImportAssemblyTypeRuntype,
});

export const AssemblyImportRuntype = z.array(Assembly);
