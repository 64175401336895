import { FileTypeUpdateRequest, http } from '@luminovo/http-client';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useToken } from '../../../../../resources/hooks';
import { httpQueryKey } from '../../../../../resources/http/httpQueryKey';

/**
 * Function to update the files of a pcb
 * @param param0 pcbId
 * @returns
 */
export function useMutationUpdatePcbFileTypes({ pcbId }: { pcbId: string }) {
    const { token } = useToken();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (requestBody: FileTypeUpdateRequest) => {
            if (requestBody.updates.length === 0) return;

            http(
                'PUT /pcb/:pcbId/files',
                {
                    pathParams: { pcbId },
                    requestBody,
                },
                token,
            );
        },
        onSuccess: async () => {
            await Promise.allSettled([
                queryClient.invalidateQueries({ queryKey: httpQueryKey('GET /ems/pcb/v2/pcbs/:pcbId') }),
                queryClient.invalidateQueries({ queryKey: httpQueryKey('GET /custom-part-alerts/:partId') }),
            ]);
        },
    });
}
