import { HttpError } from './HttpError';
import { ResponseWithMethod } from './types/responseWithMethodType';

// simpler version of assertSuccessfulResponse which does not include the import of endpointRegistry
/**
 * @deprecated Use assertSuccessfulResponse where possible. This is only here to avoid circular dependencies.
 */
export const assertSuccessfulResponseGenericErrorCode = async (
    response: ResponseWithMethod,
    endpoint: string,
): Promise<Response> => {
    if (!response.ok) {
        const { status, statusText, method } = response;
        const sid = response.headers.get('x-epibator-sid') ?? undefined;
        const epibatorSha = response.headers.get('x-epibator-sha') ?? undefined;
        const luminovoVersion = response.headers.get('x-luminovo-version') ?? undefined;

        const error = new HttpError({
            // This has to be a string literal here to avoid importing of validateErrorCode
            code: 'frontend_error.export.generic_error',
            status,
            statusText,
            endpoint,
            sid,
            epibatorSha,
            luminovoVersion,
            method,
        });
        throw error;
    }
    return response;
};
