import { typeSafeObjectKeys } from '@luminovo/commons';
import {
    FlammabilityRating,
    PCBBaseMaterial,
    PCBIPC600Class,
    PCBLayerSides,
    PCBSilkscreenColors,
    PCBSoldermaskColors,
    PCBSurfaceFinish,
    UnitEnum,
} from '@luminovo/http-client';
import { MergedAttributes } from '../../framework/types';
import { PcbAttribute } from '../PcbAttribute';

export function inferDefaults(input: MergedAttributes<PcbAttribute>): MergedAttributes<PcbAttribute> {
    const merged: MergedAttributes<PcbAttribute> = { ...input };
    for (const key of typeSafeObjectKeys(input)) {
        const defaultValue = defaultSpec[key];
        const mergedValue = merged[key];
        if (mergedValue === undefined && defaultValue !== undefined) {
            // @ts-ignore
            merged[key] = defaultValue;
        }
    }
    return merged;
}

const defaultSpec: MergedAttributes<PcbAttribute> = {
    hardGold: { value: false, regions: [] },
    silkscreenColor: { value: PCBSilkscreenColors.WHITE, regions: [] },
    silkscreenSide: { value: PCBLayerSides.BOTH, regions: [] },
    surfaceFinish: { value: PCBSurfaceFinish.ENIG, regions: [] },
    enigThickness: { value: { value: 2, unit: UnitEnum.Microinch }, regions: [] },
    soldermaskColor: { value: PCBSoldermaskColors.GREEN, regions: [] },
    soldermaskSide: { value: PCBLayerSides.BOTH, regions: [] },
    ipc600Class: { value: PCBIPC600Class.IPC2, regions: [] },
    eTest: { value: true, regions: [] },
    pressFit: { value: false, regions: [] },
    impedanceTested: { value: false, regions: [] },
    peelableMask: { value: PCBLayerSides.NONE, regions: [] },
    captonTape: { value: PCBLayerSides.NONE, regions: [] },
    itar: { value: false, regions: [] },
    carbonPrint: { value: false, regions: [] },
    edgeMetalization: { value: false, regions: [] },
    flammabilityRating: { value: FlammabilityRating.V0, regions: [] },
    ctiClass: { value: 'iiia', regions: [] },
    maxXOutsAllowed: { value: 0, regions: [] },
    halogenFree: { value: false, regions: [] },
    ulLayerStack: { value: false, regions: [] },
    ulMarkingType: { value: 'default-marking', regions: [] },
    layerstackType: { value: 'rigid', regions: [] },
    finalThickness: { value: { value: 1.55, unit: UnitEnum.Millimeter }, regions: [] },
    baseMaterial: { value: PCBBaseMaterial.FR4, regions: [] },
    outerCopperThickness: { value: { value: 35, unit: UnitEnum.Micrometer }, regions: [] },
    rohsCompilant: { value: true, regions: [] },
    tgValue: { value: { value: 150, unit: UnitEnum.Celsius }, regions: [] },
    viaFilling: { value: 'none', regions: [] },
    chamfering: { value: 'none', regions: [] },
};
