import React from 'react';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import { colorSystem } from '../../theme';
import { Text } from '../Text';

type ValidationWrapperProps<TFieldValues extends FieldValues, TName extends Path<TFieldValues> = Path<TFieldValues>> = {
    control: Control<TFieldValues>;
    name: TName;
    validate?: (value: any) => string | undefined;
    children: React.ReactNode;
};

export function ValidationWrapper<
    TFieldValues extends FieldValues,
    TName extends Path<TFieldValues> = Path<TFieldValues>,
>({ control, name, validate, children }: ValidationWrapperProps<TFieldValues, TName>): JSX.Element {
    return (
        <Controller
            control={control}
            name={name}
            rules={{
                validate: validate,
            }}
            render={({ fieldState }) => (
                <>
                    {fieldState.error && (
                        <Text variant="body" color={colorSystem.red[7]}>
                            {fieldState.error.message}
                        </Text>
                    )}
                    {children}
                </>
            )}
        />
    );
}
