import * as z from 'zod';
import { CustomComponentFullRuntype, OtsComponentFullRuntype } from '../internalPartNumber';
import { CustomOptionOfferDTORuntype, StandardPartOfferDTORuntype } from '../offer';
import { CustomFullPartRuntype, GenericFullPartRuntype, OtsFullPartRuntype } from '../part';
import { SupplierAndStockLocationDTORuntype } from '../supplierAndStockLocation';
import { SupplierContactDTORuntype } from '../supplierContact';

export enum QuoteTrackingState {
    Pending = 'Pending',
    Received = 'Received',
    Overdue = 'Overdue',
    Discarded = 'Discarded',
    FailedToSend = 'FailedToSend',
}
export const QuoteTrackingStateRuntype = z.nativeEnum(QuoteTrackingState);

export const RequestedStandardPartRuntype = z.object({
    kind: z.union([OtsFullPartRuntype, GenericFullPartRuntype, OtsComponentFullRuntype]),
    required_quantity: z.number().nullable(),
    solution_configurations: z.array(z.string()),
    offers: z.array(z.string()),
});

export const RequestedCustomPartRuntype = z.object({
    kind: z.union([CustomFullPartRuntype, CustomComponentFullRuntype]),
    required_quantity: z.number().nullable(),
    solution_configurations: z.array(z.string()),
});

export interface FileManagementPreferenceDTO extends z.infer<typeof FileManagementPreferenceRuntype> {}
export const FileManagementPreferenceRuntype = z.object({
    should_include_pcb_specification: z.boolean(),
    should_include_shipping_panel_specification: z.boolean(),
});

export interface QuoteTrackingDTO extends z.infer<typeof QuoteTrackingDTORuntype> {}
export const QuoteTrackingDTORuntype = z.object({
    id: z.string(),
    rfq: z.string(),
    supplier_and_stock_location: SupplierAndStockLocationDTORuntype,
    supplier_contacts: z.array(SupplierContactDTORuntype),
    creation_date: z.string(),
    due_date: z.string().nullable(),
    received_date: z.string().nullable(),
    state: QuoteTrackingStateRuntype,
    number: z.number(),
    request_standard_parts: z.array(RequestedStandardPartRuntype),
    alternative_standard_parts: z.array(RequestedStandardPartRuntype),
    standard_part_offers: z.array(StandardPartOfferDTORuntype),
    request_custom_parts: z.array(RequestedCustomPartRuntype),
    custom_part_offers: z.array(CustomOptionOfferDTORuntype),
    files: z.array(
        z.object({
            name: z.string(),
            created_at: z.string().nullable(),
            uploaded_by: z.string().nullable(),
            url: z.string(),
        }),
    ),
    file_management_preference: FileManagementPreferenceRuntype,
    notes: z.string().nullable(),
});
