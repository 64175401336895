import { MessageDescriptor } from '@lingui/core';
import { defineMessage } from '@lingui/macro';
import { transEnum } from '@luminovo/commons';
import { VATIdentificationTypeDTO, allVATIdentificationTypes } from '@luminovo/http-client';
import { ValueWithTranslation, sortValuesByTranslation } from '../../../utils/internationalisation';

/* eslint-disable spellcheck/spell-checker */
export const VATIdentificationTypeTranslations: Record<VATIdentificationTypeDTO, MessageDescriptor> = {
    ad_nrt: defineMessage({ message: 'Andorran NRT number' }),
    ar_cuit: defineMessage({ message: 'Argentinian tax ID number' }),
    au_abn: defineMessage({ message: 'Australian Business Number (AU ABN)' }),
    au_arn: defineMessage({ message: 'Australian Taxation Office Reference Number' }),
    eu_vat: defineMessage({ message: 'European VAT number' }),
    bo_tin: defineMessage({ message: 'Bolivian tax ID' }),
    br_cnpj: defineMessage({ message: 'Brazilian CNPJ number' }),
    br_cpf: defineMessage({ message: 'Brazilian CPF number' }),
    bg_uic: defineMessage({ message: 'Bulgaria Unified Identification Code' }),
    ca_bn: defineMessage({ message: 'Canadian BN' }),
    ca_gst_hst: defineMessage({ message: 'Canadian GST/HST number' }),
    ca_pst_bc: defineMessage({ message: 'Canadian PST number (British Columbia)' }),
    ca_pst_mb: defineMessage({ message: 'Canadian PST number (Manitoba)' }),
    ca_pst_sk: defineMessage({ message: 'Canadian PST number (Saskatchewan)' }),
    ca_qst: defineMessage({ message: 'Canadian QST number (Québec)' }),
    cl_tin: defineMessage({ message: 'Chilean TIN' }),
    cn_tin: defineMessage({ message: 'Chinese tax ID' }),
    co_nit: defineMessage({ message: 'Colombian NIT number' }),
    cr_tin: defineMessage({ message: 'Costa Rican tax ID' }),
    do_rcn: defineMessage({ message: 'Dominican RCN number' }),
    ec_ruc: defineMessage({ message: 'Ecuadorian RUC number' }),
    eg_tin: defineMessage({ message: 'Egyptian Tax Identification Number' }),
    sv_nit: defineMessage({ message: 'El Salvadorian NIT number' }),
    ge_vat: defineMessage({ message: 'Georgian VAT' }),
    eu_oss_vat: defineMessage({ message: 'European One Stop Shop VAT number for non-Union scheme' }),
    hk_br: defineMessage({ message: 'Hong Kong BR number' }),
    hu_tin: defineMessage({ message: 'Hungary tax number (adószám)' }),
    is_vat: defineMessage({ message: 'Icelandic VAT' }),
    in_gst: defineMessage({ message: 'Indian GST number' }),
    id_npwp: defineMessage({ message: 'Indonesian NPWP number' }),
    il_vat: defineMessage({ message: 'Israel VAT' }),
    jp_cn: defineMessage({ message: 'Japanese Corporate Number (*Hōjin Bangō*)' }),
    jp_rn: defineMessage({
        message:
            "Japanese Registered Foreign Businesses' Registration Number (*Tōroku Kokugai Jigyōsha no Tōroku Bangō*)",
    }),
    jp_trn: defineMessage({ message: 'Japanese Tax Registration Number (*Tōroku Bangō*)' }),
    ke_pin: defineMessage({ message: 'Kenya Revenue Authority Personal Identification Number' }),
    li_uid: defineMessage({ message: 'Liechtensteinian UID number' }),
    my_frp: defineMessage({ message: 'Malaysian FRP number' }),
    my_itn: defineMessage({ message: 'Malaysian ITN' }),
    my_sst: defineMessage({ message: 'Malaysian SST number' }),
    mx_rfc: defineMessage({ message: 'Mexican RFC number' }),
    nz_gst: defineMessage({ message: 'New Zealand GST number' }),
    no_vat: defineMessage({ message: 'Norwegian VAT number' }),
    pe_ruc: defineMessage({ message: 'Peruvian RUC number' }),
    ph_tin: defineMessage({ message: 'Philippines Tax Identification Number' }),
    ro_tin: defineMessage({ message: 'Romanian tax ID number' }),
    ru_inn: defineMessage({ message: 'Russian INN' }),
    ru_kpp: defineMessage({ message: 'Russian KPP' }),
    sa_vat: defineMessage({ message: 'Saudi Arabia VAT' }),
    rs_pib: defineMessage({ message: 'Serbian PIB number' }),
    sg_gst: defineMessage({ message: 'Singaporean GST' }),
    sg_uen: defineMessage({ message: 'Singaporean UEN' }),
    si_tin: defineMessage({ message: 'Slovenia tax number (davčna številka)' }),
    za_vat: defineMessage({ message: 'South African VAT number' }),
    kr_brn: defineMessage({ message: 'Korean BRN' }),
    es_cif: defineMessage({ message: 'Spanish NIF number (previously Spanish CIF number)' }),
    ch_vat: defineMessage({ message: 'Switzerland VAT number' }),
    tw_vat: defineMessage({ message: 'Taiwanese VAT' }),
    th_vat: defineMessage({ message: 'Thai VAT' }),
    tr_tin: defineMessage({ message: 'Turkish Tax Identification Number' }),
    ua_vat: defineMessage({ message: 'Ukrainian VAT' }),
    ae_trn: defineMessage({ message: 'United Arab Emirates TRN' }),
    gb_vat: defineMessage({ message: 'United Kingdom VAT number' }),
    us_ein: defineMessage({ message: 'United States EIN' }),
    uy_ruc: defineMessage({ message: 'Uruguayan RUC number' }),
    ve_rif: defineMessage({ message: 'Venezuelan RIF number' }),
    vn_tin: defineMessage({ message: 'Vietnamese tax ID number' }),
};

/* eslint-enable spellcheck/spell-checker */

function translatedVATIdentificationTypes(): ValueWithTranslation<VATIdentificationTypeDTO>[] {
    return allVATIdentificationTypes.map((vatIdentificationTypeCode) => {
        return {
            value: vatIdentificationTypeCode,
            translation: transEnum(vatIdentificationTypeCode, VATIdentificationTypeTranslations),
        };
    });
}

export function sortedVATIdentificationTypes() {
    return translatedVATIdentificationTypes().sort(sortValuesByTranslation);
}
