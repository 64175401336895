import { throwErrorUnlessProduction } from '@luminovo/commons';
import {
    ExistingPanelDTO,
    PerPcbPanelDTO,
    PerSourcingScenarioPanelDTO,
    SourcingScenarioDTO,
} from '@luminovo/http-client';
import { DefaultPanelFormState, ExistingPanelFormState, PanelFormData, PanelFormState, ScopeType } from './types';

const defaultPanelData: PanelFormData = {
    row_count: 1,
    column_count: 1,
    vertical_spacing_in_mm: '0',
    horizontal_spacing_in_mm: '0',
    min_milling_distance_in_mm: '0',
    padding: {
        bottom_in_mm: '0',
        left_in_mm: '0',
        right_in_mm: '0',
        top_in_mm: '0',
    },

    depanelization: 'VCut',
    sourcingScenarioId: undefined,
    panel_preference: null,
    max_x_outs: undefined,
    pcb_is_rotated: false,
    id: undefined,
};

type PanelFormValueType = {
    scope?: ScopeType;
    perPcbSavedData: PerPcbPanelDTO[];
    perScenarioSavedData: PerSourcingScenarioPanelDTO[];
    existingPanelSavedData: ExistingPanelDTO[];
    sourcingScenarios?: SourcingScenarioDTO[];
};

export const getExistingPanelFormValues = ({
    existingPanelSavedData = [],
}: PanelFormValueType): ExistingPanelFormState => {
    if (existingPanelSavedData !== undefined && existingPanelSavedData.length) {
        // There should be exactly one element in existingPanelSavedData and it should be of type 'PerPcb'
        if (existingPanelSavedData.length > 1) {
            throwErrorUnlessProduction(`Unexpected length ${existingPanelSavedData.length} in existingPanelSavedData`);
        }
        const { data } = existingPanelSavedData[0];

        return {
            scope: 'ExistingPanel',
            data: {
                numberOfPcbs: data.number_of_pcbs,
                panelHeight: data.panel_height,
                panelWidth: data.panel_width,
                depanelizationType: data.depanelization,
                pcb_is_rotated: data.pcb_is_rotated,
            },
        };
    } else {
        return {
            scope: 'ExistingPanel',
            data: {
                numberOfPcbs: 1,
                panelHeight: '100',
                panelWidth: '100',
                depanelizationType: 'VCut',
                pcb_is_rotated: false,
            },
        };
    }
};

export const getDefaultPanelFormValues = ({
    scope,
    perPcbSavedData = [],
    sourcingScenarios = [],
    perScenarioSavedData = [],
}: PanelFormValueType): DefaultPanelFormState => {
    if (scope === 'PerPcb') {
        if (perPcbSavedData !== undefined && perPcbSavedData.length) {
            // There should be exactly one element in perPcbSavedData and it should be of type 'PerPcb'
            if (perPcbSavedData.length > 1) {
                throwErrorUnlessProduction(`Unexpected length ${perPcbSavedData.length} in perPcbSavedData`);
            }
            const { data } = perPcbSavedData[0];

            return {
                scope: 'PerPcb',
                data: [
                    {
                        row_count: data.panel_details.row_count,
                        column_count: data.panel_details.column_count,
                        horizontal_spacing_in_mm: data.panel_details.horizontal_spacing_in_mm,
                        vertical_spacing_in_mm: data.panel_details.vertical_spacing_in_mm,
                        min_milling_distance_in_mm: data.panel_details.min_milling_distance_in_mm,
                        padding: data.panel_details.padding,
                        depanelization: data.panel_details.depanelization,
                        sourcingScenarioId: undefined,
                        panel_preference: data.panel_details.panel_preference ?? undefined,
                        max_x_outs: data.panel_details.max_x_outs ?? undefined,
                        pcb_is_rotated: data.panel_details.pcb_is_rotated,
                        id: data.panel_details.id,
                    },
                ],
            };
        } else {
            return {
                scope: 'PerPcb',
                data: [defaultPanelData],
            };
        }
    }

    return {
        scope: 'PerSourcingScenario',
        data: sourcingScenarios.map((sourcingScenario) => {
            const savedData = perScenarioSavedData.find((s) => s.data.sourcing_scenario === sourcingScenario.id);
            if (!savedData) {
                return {
                    ...defaultPanelData,
                    sourcingScenarioId: sourcingScenario.id,
                };
            }
            const data = savedData.data.panel_details;
            return {
                ...data,
                sourcingScenarioId: sourcingScenario.id,
            };
        }),
    };
};

export const getPanelFormInitialValues = (props: PanelFormValueType): PanelFormState => {
    switch (props.scope) {
        case 'ExistingPanel':
            return getExistingPanelFormValues(props);
        case 'PerPcb':
        case 'PerSourcingScenario':
        default:
            return getDefaultPanelFormValues(props);
    }
};
