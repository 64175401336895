import { FormContainer, ValidationErrors } from '@/components/formLayouts/FormContainer';
import { SubmitButton } from '@/components/formLayouts/SubmitButton';
import { t } from '@lingui/macro';
import { useHasPermission } from '@luminovo/auth';
import { Currency, transEnum } from '@luminovo/commons';
import {
    FieldNumericControlled,
    FieldSelectControlled,
    FieldTextControlled,
    Flexbox,
    FormItem,
} from '@luminovo/design-system';
import {
    RegionsEnum,
    SupplierAndStockLocationDTO,
    SupplierPartType,
    SupplierPreference,
    SupplierTag,
    SupplierType,
} from '@luminovo/http-client';
import {
    hasSupplierTag,
    supplierPartTypeTranslations,
    supplierPreferenceTranslations,
    supplierTypeTranslations,
} from '@luminovo/sourcing-core';
import { useFormContext, useWatch } from 'react-hook-form';

export type SupplierFormState = {
    name: string;
    stockLocation: RegionsEnum;
    supplierNumber: string;
    supplierPreference?: SupplierPreference;
    supplierType: SupplierType;
    supplierPartType: SupplierPartType;
    shippingTimeInDays?: number | null;
    defaultCurrency?: Currency;

    originalSupplierAndStockLocation?: SupplierAndStockLocationDTO;
};

export function SupplierForm({
    defaultValues,
    onSubmit,
    options: { mode, disableSupplierPreferenceField },
}: {
    defaultValues: SupplierFormState;
    onSubmit: (fromValues: SupplierFormState) => void;
    options: {
        mode: 'create' | 'edit';
        disableSupplierPreferenceField: boolean;
    };
}) {
    const validationErrors: ValidationErrors<SupplierFormState> = {
        'supplier_and_stock_location.already_exists': {
            fieldPath: 'name',
        },
        'supplier.name.already_exists': {
            fieldPath: 'name',
        },
        'supplier.name.empty': {
            fieldPath: 'name',
        },
        'supplier.supplier_number.already_exists': {
            fieldPath: 'supplierNumber',
        },
        'supplier.supplier_number.empty': {
            fieldPath: 'supplierNumber',
        },
    };

    const isManuallyAddedSupplier =
        defaultValues.originalSupplierAndStockLocation &&
        hasSupplierTag(defaultValues.originalSupplierAndStockLocation, SupplierTag.ManuallyAdded);

    const disabled = mode === 'edit' && !isManuallyAddedSupplier;

    return (
        <FormContainer defaultValues={defaultValues} onSubmit={onSubmit} validationErrors={validationErrors}>
            <Flexbox flexDirection={'column'} gap={20} paddingTop={'12px'}>
                <FormItemName disabled={disabled} />
                <FormItemSupplierNumber />
                <FormItemSupplierPreference disableSupplierPreferenceField={disableSupplierPreferenceField} />
                <FormItemSupplerType disabled={disabled} />
                <FormItemSupplerPartType disabled={disabled} />
                <FormItemShippingTime />
                <FormItemSupplierDefaultCurrency disabled={disabled} />
                <Flexbox width={'100%'} justifyContent={'space-between'} flexDirection={'row-reverse'}>
                    <SubmitButton />
                </Flexbox>
            </Flexbox>
        </FormContainer>
    );
}

function FormItemName({ disabled }: { disabled: boolean }) {
    const { control } = useFormContext<SupplierFormState>();

    return (
        <FormItem label={t`Supplier name`} required={true}>
            <FieldTextControlled
                name={'name'}
                control={control}
                FieldProps={{
                    placeholder: t`Supplier name`,
                    disabled,
                    style: { width: '50%' },
                }}
            />
        </FormItem>
    );
}

function FormItemSupplierNumber() {
    const { control } = useFormContext<SupplierFormState>();

    return (
        <FormItem label={t`Supplier number`}>
            <FieldTextControlled
                name={'supplierNumber'}
                control={control}
                FieldProps={{
                    style: { width: '50%' },
                }}
            />
        </FormItem>
    );
}

function FormItemSupplierPreference({ disableSupplierPreferenceField }: { disableSupplierPreferenceField: boolean }) {
    const { control } = useFormContext<SupplierFormState>();
    const hasPermission = useHasPermission(['edit:organization_settings']);

    return (
        <FormItem label={t`Supplier preference`}>
            <FieldSelectControlled
                control={control}
                name="supplierPreference"
                FieldProps={{
                    style: { width: '50%' },
                    options: Object.values(SupplierPreference),
                    getOptionLabel: (option) => transEnum(option, supplierPreferenceTranslations),
                    disabled: !hasPermission || disableSupplierPreferenceField,
                    disableClearable: true,
                }}
            />
        </FormItem>
    );
}

function FormItemSupplerType({ disabled }: { disabled: boolean }) {
    const { control } = useFormContext<SupplierFormState>();

    return (
        <FormItem label={t`Supplier type`}>
            <FieldSelectControlled
                control={control}
                name="supplierType"
                FieldProps={{
                    style: { width: '50%' },
                    options: [SupplierType.Distributor, SupplierType.Manufacturer, SupplierType.Ems],
                    getOptionLabel: (option) => transEnum(option, supplierTypeTranslations),
                    disabled,
                    disableClearable: true,
                }}
            />
        </FormItem>
    );
}

function FormItemSupplierDefaultCurrency({ disabled }: { disabled: boolean }) {
    const { control } = useFormContext<SupplierFormState>();

    return (
        <FormItem label={t`Default currency`}>
            <FieldSelectControlled
                control={control}
                name="defaultCurrency"
                FieldProps={{
                    style: { width: '50%' },
                    options: Object.values(Currency),
                    getOptionLabel: (option) => Currency[option],
                    disabled,
                    disableClearable: true,
                }}
            />
        </FormItem>
    );
}

function FormItemSupplerPartType({ disabled }: { disabled: boolean }) {
    const { control, setValue } = useFormContext<SupplierFormState>();

    return (
        <FormItem label={t`Supplier part type`}>
            <FieldSelectControlled
                control={control}
                name="supplierPartType"
                FieldProps={{
                    style: { width: '50%' },
                    options: [SupplierPartType.OffTheShelf, SupplierPartType.Custom],
                    getOptionLabel: (option) => transEnum(option, supplierPartTypeTranslations),
                    disabled,
                    disableClearable: true,
                    onValueChange: () => {
                        setValue('shippingTimeInDays', null);
                    },
                }}
            />
        </FormItem>
    );
}

function FormItemShippingTime() {
    const { control } = useFormContext<SupplierFormState>();
    const supplierPartType = useWatch({
        control,
        name: 'supplierPartType',
    });

    const showShippingTimeField = [SupplierPartType.Custom, SupplierPartType.Pcb].includes(supplierPartType);

    if (showShippingTimeField === false) return null;

    return (
        <FormItem label={t`Shipping time in days`} required={false}>
            <FieldNumericControlled
                min={0}
                required={false}
                control={control}
                name={'shippingTimeInDays'}
                FieldProps={{
                    placeholder: t`e.g. 3`,
                    style: { width: '50%' },
                }}
            />
        </FormItem>
    );
}
