import * as z from 'zod';

function CalculationResultOk(dataRuntype: z.ZodTypeAny) {
    return z.object({
        result: z.literal('Ok'),
        data: dataRuntype,
    });
}

const CalculationResultScriptError = z.object({
    result: z.literal('ScriptError'),
    data: z.object({}), //TODO: support full error types in the FE https://www.notion.so/luminovo/Support-full-error-types-in-the-FE-2de9777f465f4ab68cab55bc01729cd8
});

const CalculationResultUnknownIdentifier = z.object({
    result: z.literal('UnknownIdentifier'),
    data: z.string(),
});

export const CalculationResultNullValue = z.object({
    result: z.literal('NullValue'),
    data: z.string(),
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function CalculationResult(dataRuntype: z.ZodTypeAny) {
    return z.union([
        CalculationResultOk(dataRuntype),
        CalculationResultScriptError,
        CalculationResultUnknownIdentifier,
        CalculationResultNullValue,
    ]);
}
