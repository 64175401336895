import { plural, t } from '@lingui/macro';
import { formatDecimal, formatRelativeTime, formatToLongDate, transEnum } from '@luminovo/commons';
import {
    createColumnHelper,
    DestructivePrimaryButton,
    NonIdealState,
    SupportedFilterFn,
    Table,
    Tag,
    TanStackTable,
    Text,
    useConfirmationDialog,
    useTanStackTable,
} from '@luminovo/design-system';
import { QuoteRequestDTO, QuoteRequestStatus } from '@luminovo/http-client';
import { formatSupplierDTO } from '@luminovo/sourcing-core';
import { Delete, EmailOutlined } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import React from 'react';

import { QuoteRequestStatusChip } from '@/modules/Negotiations/components/QuoteRequestStatusChip';
import { useNegotiationQuoteRequests } from '@/modules/Negotiations/hooks/negotiationHandlers';
import { quoteRequestStatusTranslations } from '@/modules/Negotiations/i18n';
import { useHttpMutation } from '@/resources/mutation/useHttpMutation';

const columnHelper = createColumnHelper<QuoteRequestDTO>();
const columns = [
    columnHelper.text((row) => formatSupplierDTO(row.supplier), {
        id: 'supplier.name',
        label: () => t`Supplier`,
        size: 160,
    }),
    columnHelper.text('number', {
        id: 'quoteNumber',
        label: () => t`Quote number`,
        size: 80,
        renderType: 'generic',
        cell: (info) => <Tag attention="low" color={'neutral'} label={`#${info.getValue()}`} />,
    }),
    columnHelper.number((qr) => qr.line_item_count, {
        id: 'lineItems',
        size: 100,
        label: () => t`Line items`,
        cell: ({ row }) => formatDecimal(row.original.line_item_count),
    }),
    columnHelper.enum('status', {
        id: 'status',
        getOptionLabel: (status) => transEnum(status, quoteRequestStatusTranslations),
        quickFilters: [
            {
                label: () => t`Not sent`,
                value: { filterFn: SupportedFilterFn.equalsAny, value: [QuoteRequestStatus.NotSent] },
                showCount: true,
            },
            {
                label: () => 'Received',
                value: { filterFn: SupportedFilterFn.equalsAny, value: [QuoteRequestStatus.Received] },
                showCount: true,
            },
        ],
        label: () => t`Status`,
        size: 100,
        cell: ({ row }) => <QuoteRequestStatusChip status={row.original.status} />,
    }),
    columnHelper.date('created_at', {
        id: 'created_at',
        label: () => t`Created on`,
        size: 100,
        cell: (item) => (
            <Tooltip title={formatToLongDate(item.getValue())}>
                <Text variant={'inherit'}>{formatRelativeTime(item.getValue())}</Text>
            </Tooltip>
        ),
    }),
];

function ActionButton({ table }: { table: Table<QuoteRequestDTO> }) {
    const quoteRequests = table.getSelectedRowModel().rows.map((row) => row.original);
    const { mutateAsync, isPending } = useHttpMutation('DELETE /quote-request/delete-many', {
        snackbarMessage: t`Successfully deleted quote requests`,
        onSuccess: () => table.resetRowSelection(),
    });

    const { modal, setOpen } = useConfirmationDialog({
        title: t`Delete quote requests`,
        description: t`Are you sure you want to delete the selected quote requests?`,
        onConfirmation: () => mutateAsync({ requestBody: { quote_request_ids: quoteRequests.map((qr) => qr.id) } }),
    });

    return (
        <>
            {modal}
            <Tooltip
                title={plural(quoteRequests.length, {
                    zero: `Please select at least one quote request`,
                    one: `Delete quote request`,
                    other: `Delete ${formatDecimal(quoteRequests.length)} quote requests`,
                })}
            >
                <span>
                    <DestructivePrimaryButton
                        size="medium"
                        isLoading={isPending}
                        onClick={() => setOpen(true)}
                        disabled={quoteRequests.length === 0}
                        startIcon={<Delete />}
                    >
                        {t`Delete quote request`}
                    </DestructivePrimaryButton>
                </span>
            </Tooltip>
        </>
    );
}

function EmptyPlaceholder(): JSX.Element {
    return (
        <NonIdealState
            Icon={EmailOutlined}
            title={t`No quote requests found`}
            description={t`Create a quote request by selecting a part option and assign suppliers`}
        />
    );
}

export function useDraftQuoteRequestsTable({ negotiationId }: { negotiationId: number }) {
    const { data, isLoading } = useNegotiationQuoteRequests(negotiationId);

    const filteredData = React.useMemo(
        () => data?.filter((qr: QuoteRequestDTO) => qr.status === QuoteRequestStatus.NotSent) || [],
        [data],
    );

    const { table } = useTanStackTable({
        data: filteredData,
        columns,
        enableColumnHiding: true,
        enableColumnOrdering: true,
        enableSelection: {
            enabled: true,
            getRowId: (row: QuoteRequestDTO) => String(row.id),
        },
    });

    return { table, isLoading };
}

export function TableDraftQuoteRequests({ table }: { table: Table<QuoteRequestDTO> }) {
    return <TanStackTable table={table} EmptyPlaceholder={EmptyPlaceholder} ActionButton={ActionButton} />;
}
