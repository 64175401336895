import { t } from '@lingui/macro';
import { Currency } from '@luminovo/commons';
import { FieldSelect, FieldSelectProps } from '@luminovo/design-system';

export interface FieldSelectCurrencyProps extends Omit<FieldSelectProps<Currency>, 'options'> {}

export function FieldSelectCurrency({ ...props }: FieldSelectCurrencyProps): JSX.Element {
    return (
        <FieldSelect
            placeholder={t`Currency`}
            options={Object.values(Currency)}
            getOptionLabel={(option) => option}
            {...props}
        />
    );
}
