import * as z from 'zod';
import { endpoint } from '../http/endpoint';
import { RutronikCredentialsInputDTORuntype, RutronikResponseBodyDTORuntype } from './rutronikBackendTypes';

export const rutronikEndpoints = {
    'GET /3rdparty/rutronik/credentials': endpoint({
        description: 'Returns credentials for rutronik integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: RutronikResponseBodyDTORuntype,
    }),
    'POST /3rdparty/rutronik/credentials': endpoint({
        description: 'Uploads credentials for rutronik API integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: RutronikCredentialsInputDTORuntype,
        responseBody: RutronikResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/rutronik/credentials'],
    }),
    'PATCH /3rdparty/rutronik/credentials': endpoint({
        description: 'Updates credentials for rutronik API integratoin',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: RutronikCredentialsInputDTORuntype,
        responseBody: RutronikResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/rutronik/credentials'],
    }),
    'DELETE /3rdparty/rutronik/credentials': endpoint({
        description: 'Deletes existing credentials for rutronik API integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({ deleted: z.number() }),
        invalidates: ['GET /3rdparty/rutronik/credentials'],
    }),
};
