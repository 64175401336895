import { ErrorFallback } from '@/components/errorHandlers/ErrorBoundary';
import { useQuoteRequestEmailErrorDialog } from '@/modules/Negotiations/components/QuoteRequestEmailErrorDialog/QuoteRequestEmailErrorDialog';
import { useDialogAddSupplierContact } from '@/modules/Suppliers/SupplierManagement/components/SupplierContactDialogs/AddSupplierContactDialog';
import { useCustomer } from '@/resources/customer/customerHandler';
import { t, Trans } from '@lingui/macro';
import { formatDecimal, formatToLongDate, isPresent, uniqBy } from '@luminovo/commons';
import {
    colorSystem,
    createSnackbar,
    DestructiveSecondaryIconButton,
    FieldMultiSelectControlled,
    FieldSelectControlled,
    Flexbox,
    SecondaryButton,
    Tag,
    Text,
    Toolbar,
    useBackNavigation,
    XlsIcon,
} from '@luminovo/design-system';
import {
    CustomerDTO,
    EmailTemplateDTO,
    OrganizationDTO,
    QuoteRequestDTO,
    RfqDTO,
    SupplierContactDTO,
    UserDTO,
} from '@luminovo/http-client';
import { renderTemplate, RichTextEditorInput } from '@luminovo/rich-text-editor';
import { formatSupplierDTO } from '@luminovo/sourcing-core';
import { DeleteForeverRounded, InfoOutlined } from '@mui/icons-material';
import { Box, ListItem, Tooltip } from '@mui/material';
import * as Sentry from '@sentry/react';
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import React from 'react';
import { Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import AutoSizer from 'react-virtualized-auto-sizer';
import { useCurrentUserDetailsContext } from '../../../../components/contexts/CurrentUserDetailsContext';
import { FormContainer } from '../../../../components/formLayouts/FormContainer';
import { SubmitButton } from '../../../../components/formLayouts/SubmitButton';
import { PageLayoutCollapsibleSidebar } from '../../../../components/PageLayoutCollapsibleSidebar';
import { useDownloadQuoteRequestExcel } from '../../../../resources/export/exportHandler';
import { useHttpQuery, useSuspenseHttpQuery } from '../../../../resources/http/useHttpQuery';
import { useHttpMutation } from '../../../../resources/mutation/useHttpMutation';
import { useRfQ } from '../../../../resources/rfq/rfqHandler';
import {
    useSupplierContacts,
    useSupplierContactsFromSupplier,
} from '../../../../resources/supplierContact/supplierContactHandler';
import { useAllOrganizationUsers } from '../../../../resources/user/userHandler';
import { route, UrlParams } from '../../../../utils/routes';
import { useQuoteRequestsByNumber } from '../../../Negotiations/hooks/negotiationHandlers';
import { NavigationSidebarSourcing } from '../NavigationSidebarSourcing';
import { createVars, VarId } from '../QuoteExporter/createVars';
import { getQuoteRequestEmailTemplateBySupplier } from '../QuoteExporter/getQuoteRequestEmailTemplateBySupplier';

type MailData = {
    quoteRequest: QuoteRequestDTO;
    email: {
        template?: EmailTemplateDTO;
        subject: string;
        body: string;
    };
    recipients: Array<SupplierContactDTO>;
    ccs: Array<UserDTO>;
};
type FormValues = {
    selectedQuoteRequestId: string;
    mail_data: MailData[];
    include_pcb_specification: boolean;
    include_shipping_panel_specification: boolean;
    include_mail_attachments: boolean;
    sharedTemplateContext: {
        rfq: RfqDTO;
        sender: UserDTO;
        organization: OrganizationDTO;
        customer: CustomerDTO;
    };
};

export function useRenderQuoteRequestEmailTemplate({ index }: { index: number }) {
    const { control } = useFormContext<FormValues>();
    const sharedTemplateContext = useWatch({ control, name: `sharedTemplateContext` });
    const quoteRequest = useWatch({ control, name: `mail_data.${index}.quoteRequest` });
    const recipients = useWatch({ control, name: `mail_data.${index}.recipients` }) ?? [];

    return (template: EmailTemplateDTO) => {
        return renderQuoteRequestEmailTemplate({
            template,
            quoteRequest,
            recipient: recipients[0],
            ...sharedTemplateContext,
        });
    };
}

function renderQuoteRequestEmailTemplate({
    quoteRequest,
    template,
    sender,
    organization,
    recipient,
    rfq,
    customer,
}: {
    quoteRequest: QuoteRequestDTO;
    template: EmailTemplateDTO | null;
    sender: { first_name: string; last_name: string } | null;
    recipient: { first_name: string; last_name: string } | null;
    organization: { name: string } | null;
    rfq: RfqDTO | null;
    customer: CustomerDTO | null;
}) {
    if (!template) {
        return { subject: '', body: '' };
    }

    const context: Record<VarId, string> = {
        'quote_request.due_date': quoteRequest.due_date ?? '',
        'quote_request.number': String(quoteRequest.number),
        'customer.name': customer?.name ?? '',
        'quote_request.notes': quoteRequest.notes ?? '',
        'quote_request.send_date': formatToLongDate(new Date()),
        'quote_request.supplier_portal_link':
            window.origin +
            route('/supplier-portal/quote-requests/:quoteRequestId', {
                quoteRequestId: quoteRequest.id,
            }),
        'rfq.name': rfq?.name ?? '',
        'rfq.number': rfq?.ems_internal_number ?? '',
        'sender.first_name': sender?.first_name ?? '',
        'sender.last_name': sender?.last_name ?? '',
        'sender.organization.name': organization?.name ?? '',
        'supplier.name': quoteRequest.supplier.name ?? '',
        'recipient.first_name': recipient?.first_name ?? '',
        'recipient.last_name': recipient?.last_name ?? '',
    };

    const subject = renderTemplate(template.subject, context, { asText: true });
    const body = renderTemplate(template.body, context, { asText: false });

    return { subject, body };
}

function useSubmitEmail({ rfqId, negotiationId }: { rfqId: string; negotiationId: number }) {
    const { navigateBack } = useBackNavigation();
    const { openDialog } = useQuoteRequestEmailErrorDialog();
    const { mutateAsync: sendEmails } = useHttpMutation('POST /quote-request/send-many', {
        snackbarMessage: (response) => {
            const errors = response.responseBody.results.filter((result) => isPresent(result.error_code));
            switch (errors.length) {
                case 0:
                    return { message: t`Emails sent`, variant: 'success' };
                case 1:
                    return { message: t`An email failed to send, please try again later`, variant: 'error' };
                default:
                    return { message: t`Multiple emails failed to send, please try again later`, variant: 'error' };
            }
        },
        onSuccess: (response) => {
            const hasErrors = response.results.some((result) => isPresent(result.error_code));
            if (hasErrors) {
                // Show the dialog with the status of each quote requests
                openDialog({
                    statuses: response.results.map((result) => ({
                        quoteRequestId: result.quote_request_id,
                        error: result.error_code,
                    })),
                    onClose: () =>
                        navigateBack({
                            defaultPath: route('/rfqs/:rfqId/sourcing/negotiations/:negotiationId/quote-requests', {
                                rfqId: rfqId,
                                negotiationId: negotiationId,
                            }),
                        }),
                });
            } else {
                navigateBack({
                    defaultPath: route('/rfqs/:rfqId/sourcing/negotiations/:negotiationId/quote-requests', {
                        rfqId: rfqId,
                        negotiationId: negotiationId,
                    }),
                });
            }
        },
    });

    return async (form: FormValues) => {
        await sendEmails({
            requestBody: {
                mail_data: form.mail_data.map((mail) => {
                    return {
                        quote_request_id: mail.quoteRequest.id,
                        email: { subject: mail.email.subject, body: mail.email.body },
                        recipient_emails: mail.recipients.map((recipient) => ({
                            email: recipient.email,
                            name: `${recipient.first_name} ${recipient.last_name}`,
                        })),
                        cc_emails: mail.ccs.map((cc) => ({
                            email: cc.email,
                            name: `${cc.first_name} ${cc.last_name}`,
                        })),
                    };
                }),
                include_mail_attachments: form.include_mail_attachments,
                include_pcb_specification: form.include_pcb_specification,
                include_shipping_panel_specification: form.include_shipping_panel_specification,
            },
        });
    };
}

function useDefaultValues(
    urlParams: UrlParams<'/rfqs/:rfqId/sourcing/negotiations/:negotiationId/quote-requests/preview-email'>,
): FormValues | undefined {
    const quoteRequestIds = urlParams.queryParams.quoteRequestNumbers
        .split(',')
        .map(Number)
        .filter((x) => !isNaN(x));

    // TODO useSuspenseHttpQuery should be used here
    const { data: rfq } = useRfQ(urlParams.pathParams.rfqId);
    const { data: quoteRequests } = useQuoteRequestsByNumber(quoteRequestIds);
    const { data: organizationUsers } = useAllOrganizationUsers();
    const { user: sender, organization } = useCurrentUserDetailsContext();
    const { data: supplierContacts } = useSupplierContacts({ refetchOnWindowFocus: true });
    const { data: customer } = useCustomer(rfq?.customer);
    const { data: templates } = useSuspenseHttpQuery(
        'GET /email-template',
        {},
        {
            select: (res) => res.items,
        },
    );

    if (
        !isPresent(rfq) ||
        !isPresent(quoteRequests) ||
        !isPresent(organizationUsers) ||
        !isPresent(organization) ||
        !isPresent(supplierContacts) ||
        !isPresent(templates) ||
        !isPresent(customer)
    ) {
        return undefined;
    }

    return {
        selectedQuoteRequestId: quoteRequests[0].id,
        mail_data: quoteRequests.map((quoteRequest) => {
            const template = getQuoteRequestEmailTemplateBySupplier(templates, quoteRequest.supplier.id);
            const recipient = supplierContacts
                .filter((c) => c.supplier === quoteRequest.supplier.id)
                .find((c) => c.is_main_contact);
            const resolvedCcs = organizationUsers.filter((user) => template?.ccs.includes(user.id));

            const { subject, body } = renderQuoteRequestEmailTemplate({
                sender,
                organization,
                rfq,
                quoteRequest,
                recipient: recipient ?? null,
                template: template ?? null,
                customer,
            });

            return {
                quoteRequest,
                email: { subject, body, template },
                recipients: isPresent(recipient) ? [recipient] : [],
                ccs: uniqBy([sender, ...resolvedCcs], (mail) => mail.email),
            };
        }),
        include_pcb_specification: false,
        include_shipping_panel_specification: false,
        include_mail_attachments: true,
        sharedTemplateContext: {
            rfq,
            sender,
            organization,
            customer,
        },
    };
}

function FormItemHorizontal({ label, children }: { label: string; children: React.ReactNode }) {
    return (
        <Box
            sx={{
                alignItems: 'center',
                display: 'grid',
                gridTemplateColumns: '1fr 9fr',
                height: '100%',
                width: '100%',
            }}
        >
            <Text variant={'h4'} color={colorSystem.neutral[7]}>
                {label}
            </Text>
            {children}
        </Box>
    );
}

const SupplierList: React.FunctionComponent = () => {
    const { control } = useFormContext<FormValues>();

    const mailData = useWatch({
        control: control,
        name: 'mail_data',
    });

    return (
        <Flexbox
            flexDirection={'column'}
            borderRight={`1px solid ${colorSystem.neutral[2]}`}
            bgcolor={colorSystem.neutral[0]}
        >
            <Flexbox padding={'20px 24px 20px 24px'} borderBottom={`1px solid ${colorSystem.neutral[2]}`}>
                <Text variant={'h3'} color={colorSystem.neutral[8]}>
                    <Trans>Requests</Trans>
                </Text>
            </Flexbox>
            <Box flexGrow={1} position="relative">
                <AutoSizer>
                    {({ height, width }) => (
                        <Box width={width} height={height} overflow="auto">
                            <Flexbox flexDirection={'column'}>
                                {mailData.map((mail, index) => (
                                    <SupplierListItem key={mail.quoteRequest.id} index={index} mail={mail} />
                                ))}
                            </Flexbox>
                        </Box>
                    )}
                </AutoSizer>
            </Box>
        </Flexbox>
    );
};

function useRemoveQuoteRequest() {
    const { control } = useFormContext<FormValues>();
    const { setValue, getValues } = useFormContext<FormValues>();
    const { enqueueSnackbar } = useSnackbar();
    const { remove } = useFieldArray({
        name: 'mail_data',
        control: control,
    });

    return useMutation({
        mutationFn: async ({ index, mail }: { index: number; mail: FormValues['mail_data'][number] }) => {
            remove(index);
            const remainingMails = getValues('mail_data');
            const nextIndex = Math.min(index, remainingMails.length - 1);
            const nextSelectedId = remainingMails[nextIndex]?.quoteRequest.id;

            if (nextSelectedId) {
                setValue('selectedQuoteRequestId', nextSelectedId);
            }

            return { mail };
        },
        onSuccess: ({ mail }) => {
            enqueueSnackbar(
                ...createSnackbar({
                    title: t`Quote request removed`,
                    message: t`${formatSupplierDTO(mail.quoteRequest.supplier)} #${mail.quoteRequest.number} removed`,
                    overrides: {
                        notistackSnackbarOptions: {
                            variant: 'success',
                            autoHideDuration: 3000,
                        },
                    },
                }),
            );
        },
    });
}

const SupplierListItem: React.FunctionComponent<{
    index: number;
    mail: FormValues['mail_data'][number];
}> = ({ index, mail }) => {
    const { control, setValue, trigger } = useFormContext<FormValues>();
    const { mutate, isPending } = useRemoveQuoteRequest();

    const selectedQuoteRequestId = useWatch({
        control: control,
        name: 'selectedQuoteRequestId',
    });

    const mailData = useWatch({
        control: control,
        name: 'mail_data',
    });

    const isSelected = selectedQuoteRequestId === mail.quoteRequest.id;
    const showRemoveButton = isSelected && mailData.length > 1;

    return (
        <ListItem
            onClick={() => {
                trigger();
                setValue('selectedQuoteRequestId', mail.quoteRequest.id);
            }}
            style={{
                padding: 0,
                backgroundColor: isSelected ? colorSystem.primary[1] : undefined,
            }}
        >
            <Flexbox
                flexDirection={'column'}
                gap={8}
                padding={'20px 24px 20px 24px'}
                width={'100%'}
                borderBottom={`1px solid ${colorSystem.neutral[2]}`}
            >
                <Flexbox justifyContent="space-between" alignItems="center">
                    <Text
                        variant={'body-semibold'}
                        showEllipsis={true}
                        color={isSelected ? colorSystem.primary[7] : colorSystem.neutral[8]}
                        style={{ maxWidth: '200px' }}
                    >
                        {formatSupplierDTO(mail.quoteRequest.supplier)}
                    </Text>
                    <Tag attention="low" color={'neutral'} label={`#${mail.quoteRequest.number}`} />
                </Flexbox>
                <Flexbox justifyContent="space-between" alignItems="center">
                    <MissingRecipientLabel index={index} />
                    {showRemoveButton && (
                        <DestructiveSecondaryIconButton
                            size="small"
                            isLoading={isPending}
                            onClick={(e) => {
                                e.stopPropagation();
                                mutate({ index, mail });
                            }}
                        >
                            <DeleteForeverRounded fontSize="inherit" />
                        </DestructiveSecondaryIconButton>
                    )}
                </Flexbox>
            </Flexbox>
        </ListItem>
    );
};

function MissingRecipientLabel({ index }: { index: number }) {
    const { control } = useFormContext<FormValues>();

    return (
        <Controller
            control={control}
            name={`mail_data.${index}`}
            rules={{
                validate: (value) => {
                    if (value.recipients.length === 0) {
                        return t`At least one recipient is required`;
                    }
                    if (value.email.subject === '') {
                        return t`Subject is required`;
                    }
                    return true;
                },
            }}
            render={({ field, fieldState }) => {
                if (fieldState.error) {
                    return (
                        <Text variant="body-small" color={colorSystem.red[7]}>
                            {fieldState.error.message}
                        </Text>
                    );
                }
                const recipients = field.value.recipients;
                const firstSupplierContact = recipients[0];

                if (recipients.length === 0) {
                    return (
                        <Text variant="body-small" color={colorSystem.yellow[7]}>
                            {t`No recipient`}
                        </Text>
                    );
                }

                return (
                    <Text variant={'body-small'} color={colorSystem.neutral[8]}>
                        {`${firstSupplierContact.email}  •  ${firstSupplierContact.user_language.toLocaleUpperCase()}${recipients.length > 1 ? ` +${recipients.length - 1}` : ''}`}
                    </Text>
                );
            }}
        />
    );
}

const SupplierPortalIndicator = ({ index }: { index: number }) => {
    const { control } = useFormContext<FormValues>();
    const emailBody = useWatch({
        control,
        name: `mail_data.${index}.email.body`,
    });

    const hasSupplierPortalLink = emailBody?.includes('/supplier-portal/quote-requests/');

    if (hasSupplierPortalLink) {
        return (
            <Tooltip
                title={t`The supplier portal is enabled. Suppliers can access the portal by clicking the link in the email.`}
            >
                <Tag attention="low" color="green" label={t`Supplier portal enabled`} />
            </Tooltip>
        );
    }

    return (
        <Tooltip
            title={t`This template doesn't have a supplier portal link. Choose a template with a link to let suppliers access the portal.`}
        >
            <Tag attention="low" color="neutral" label={t`Supplier portal disabled`} />
        </Tooltip>
    );
};

const FormTitle = ({ index, mail }: { index: number; mail: MailData }) => {
    return (
        <Flexbox alignItems={'center'} justifyContent="space-between" alignContent="center">
            <Text variant={'h3'} color={colorSystem.neutral[8]}>
                {formatSupplierDTO(mail.quoteRequest.supplier)}
            </Text>
            <Flexbox gap={2} alignItems="center">
                <SupplierPortalIndicator index={index} />
                <FormItemEmailTemplate index={index} />
            </Flexbox>
        </Flexbox>
    );
};

const FormItemEmailTemplate = function FormItemEmailTemplate({ index }: { index: number }): JSX.Element {
    const { control, setValue } = useFormContext<FormValues>();

    const { data: options = [] } = useHttpQuery(
        'GET /email-template',
        {},
        {
            refetchOnWindowFocus: true,
            select: (res) => Array.from(res.items).sort((a, b) => a.name.localeCompare(b.name)),
        },
    );

    const renderTemplate = useRenderQuoteRequestEmailTemplate({ index });
    const handleChange = React.useCallback(
        (value: EmailTemplateDTO) => {
            const { subject, body } = renderTemplate(value);
            setValue(`mail_data.${index}.email.template`, value);
            setValue(`mail_data.${index}.email.subject`, subject);
            setValue(`mail_data.${index}.email.body`, body);
        },
        [index, renderTemplate, setValue],
    );

    return (
        <Flexbox alignItems="center" gap={'4px'} justifyContent="center">
            <Tooltip title={t`Changing the template will reset the email body and subject`}>
                <InfoOutlined fontSize="small" sx={{ cursor: 'help', color: colorSystem.neutral[6] }} />
            </Tooltip>
            <FieldSelectControlled
                control={control}
                name={`mail_data.${index}.email.template`}
                FieldProps={{
                    options,
                    size: 'small',
                    style: { width: '240px' },
                    disableClearable: true,
                    getOptionKey: (opt) => opt.id,
                    getOptionLabel: (opt) => opt.name,
                    isOptionEqualToValue: (option, value) => option.id === value.id,
                    onValueChange: (value) => isPresent(value) && handleChange(value),
                }}
            />
        </Flexbox>
    );
};

const FormItemRecipients = ({ index, mail }: { index: number; mail: MailData }) => {
    const { control, setValue, trigger } = useFormContext<FormValues>();
    const value = useWatch({ control, name: `mail_data.${index}.recipients` });

    const { data: options = [] } = useSupplierContactsFromSupplier(mail.quoteRequest.supplier.id, {
        refetchOnWindowFocus: true,
    });

    const { openDialog } = useDialogAddSupplierContact(mail.quoteRequest.supplier.id, (data) => {
        setValue(`mail_data.${index}.recipients`, [...value, data]);
        trigger();
    });

    return (
        <FormItemHorizontal label={t`Recipient`}>
            <FieldMultiSelectControlled
                control={control}
                name={`mail_data.${index}.recipients`}
                required={true}
                FieldProps={{
                    options,
                    fullWidth: true,
                    disableClearable: true,
                    disableCloseOnSelect: true,
                    placeholder: 'Select recipient or create new contact',
                    getOptionKey: (opt) => opt.id,
                    getOptionLabel: (opt) => `${opt.first_name} ${opt.last_name}`,
                    getOptionSublabel: (opt) => opt.email,
                    onAfterChange: () => {
                        trigger();
                    },
                    action: {
                        label: t`Add supplier contact`,
                        onClick: () => openDialog(),
                    },
                }}
            />
        </FormItemHorizontal>
    );
};

function FormItemCcs({ index }: { index: number }) {
    const { control } = useFormContext<FormValues>();
    const { data = [] } = useAllOrganizationUsers();

    return (
        <FormItemHorizontal label={t`Cc`}>
            <FieldMultiSelectControlled
                control={control}
                // eslint-disable-next-line spellcheck/spell-checker
                name={`mail_data.${index}.ccs`}
                FieldProps={{
                    options: data,
                    getOptionLabel: (contact) => `${contact.first_name} ${contact.last_name}`,
                    getOptionSublabel: (contact) => contact.email,
                    getOptionKey: (contact) => contact.email,
                }}
            />
        </FormItemHorizontal>
    );
}

const FormItemSubject = function FormItemSubject({ index }: { index: number }): JSX.Element {
    const { control, trigger } = useFormContext<FormValues>();
    const templateId = useWatch({ control, name: `mail_data.${index}.email.template.id` });

    // This is necessary because RichTextEditorInput wraps text in <p> tags,
    // but we want to store only the plain text content for the email subject
    const stripHtml = (html: string) => {
        const tmp = document.createElement('DIV');
        tmp.innerHTML = html;
        return tmp.textContent || tmp.innerText || '';
    };

    return (
        <FormItemHorizontal label={t`Subject`}>
            <Controller
                control={control}
                name={`mail_data.${index}.email.subject`}
                render={({ field }) => {
                    return (
                        <RichTextEditorInput
                            key={templateId}
                            namespace="quote-requests::subject"
                            vars={createVars()}
                            hideVariables
                            initialHtml={field.value}
                            disableRichText={true}
                            onChange={(html) => {
                                field.onChange(stripHtml(html));
                                trigger();
                            }}
                            autosaveEveryMillis={200}
                        />
                    );
                }}
            />
        </FormItemHorizontal>
    );
};

const FormItemBody = function FormItemBody({ index }: { index: number }) {
    const { control } = useFormContext<FormValues>();
    const templateId = useWatch({ control, name: `mail_data.${index}.email.template.id` });

    return (
        <Box flexGrow={1} position="relative" minHeight={100}>
            <AutoSizer>
                {({ height, width }) => (
                    <Box width={width} height={height} overflow="auto">
                        <Controller
                            control={control}
                            name={`mail_data.${index}.email.body`}
                            render={({ field }) => {
                                return (
                                    <RichTextEditorInput
                                        key={templateId}
                                        namespace="quote-requests::subject"
                                        vars={createVars()}
                                        hideVariables
                                        initialHtml={field.value}
                                        onChange={(html) => {
                                            field.onChange(html);
                                        }}
                                        autosaveEveryMillis={200}
                                    />
                                );
                            }}
                        />
                    </Box>
                )}
            </AutoSizer>
        </Box>
    );
};

const Attachments = ({ index }: { index: number }) => {
    const { control } = useFormContext<FormValues>();
    const quoteRequest = useWatch({ control, name: `mail_data.${index}.quoteRequest` });
    const { mutateAsync, isLoading } = useDownloadQuoteRequestExcel(quoteRequest.id);

    return (
        <Flexbox flexDirection={'column'} gap={8} paddingBottom={'52px'}>
            <Text variant={'h4'} color={colorSystem.neutral[7]}>
                <Trans>Attachments</Trans>
            </Text>
            <SecondaryButton onClick={() => mutateAsync()} isLoading={isLoading} startIcon={<XlsIcon />}>
                <Text
                    variant={'body-semibold'}
                    color={colorSystem.neutral[8]}
                    style={{ textAlign: 'left', width: '100%' }}
                >
                    {`${formatSupplierDTO(quoteRequest.supplier)}.xls • ${formatDecimal(quoteRequest.line_item_count)} parts`}
                </Text>
            </SecondaryButton>
        </Flexbox>
    );
};

const EmailEditor: React.FunctionComponent = () => {
    const { control } = useFormContext<FormValues>();

    const selectedQuoteRequestId = useWatch({
        control: control,
        name: 'selectedQuoteRequestId',
    });
    const mailData = useWatch({
        control: control,
        name: 'mail_data',
    });
    const index = mailData.findIndex((elem) => elem.quoteRequest.id === selectedQuoteRequestId);
    const mail = mailData.find((elem) => elem.quoteRequest.id === selectedQuoteRequestId);

    if (!isPresent(mail)) {
        return <></>;
    }

    return (
        <Sentry.ErrorBoundary fallback={ErrorFallback}>
            <Flexbox
                key={mail.quoteRequest.id}
                flexDirection={'column'}
                gap={24}
                paddingX={'24px'}
                paddingY={'20px'}
                bgcolor={colorSystem.neutral[0]}
            >
                <FormTitle key={`title-${index}`} index={index} mail={mail} />
                <Flexbox flexDirection={'column'} gap={'18px'}>
                    <FormItemRecipients index={index} mail={mail} />
                    <FormItemCcs index={index} />
                    <FormItemSubject index={index} />
                </Flexbox>
                <FormItemBody index={index} />
                <Attachments index={index} />
            </Flexbox>
        </Sentry.ErrorBoundary>
    );
};

function ToolbarInner({ rfqId, negotiationId }: { rfqId: string; negotiationId: number }) {
    const { control } = useFormContext<FormValues>();
    const quoteRequests = useWatch({ control, name: 'mail_data' });

    return (
        <Toolbar
            breadcrumbs={[
                {
                    title: t`Negotiation`,
                    href: route(`/rfqs/:rfqId/sourcing/negotiations/:negotiationId/line-items`, {
                        rfqId,
                        negotiationId,
                    }),
                },
                {
                    title: t`Email preview`,
                },
            ]}
        >
            <SubmitButton size="medium" label={t`Send ${formatDecimal(quoteRequests.length)} emails`} />
        </Toolbar>
    );
}

export default function QuoteRequestEmailPreviewPage(
    urlParams: UrlParams<'/rfqs/:rfqId/sourcing/negotiations/:negotiationId/quote-requests/preview-email'>,
) {
    const { rfqId } = urlParams.pathParams;
    const negotiationId = Number(urlParams.pathParams.negotiationId);

    const defaultValues = useDefaultValues(urlParams);
    const onSubmit = useSubmitEmail({ rfqId, negotiationId });

    if (!isPresent(defaultValues)) {
        return <></>;
    }

    return (
        <FormContainer defaultValues={defaultValues} onSubmit={onSubmit} UNSAFE_disableStablePropCheck>
            <PageLayoutCollapsibleSidebar
                sidebar={<NavigationSidebarSourcing rfqId={rfqId} />}
                header={<ToolbarInner rfqId={rfqId} negotiationId={negotiationId} />}
                layoutVariant="fragment"
            >
                <Box display="grid" gridTemplateColumns="2fr 5fr" height={'100%'}>
                    <SupplierList />
                    <EmailEditor />
                </Box>
            </PageLayoutCollapsibleSidebar>
        </FormContainer>
    );
}
