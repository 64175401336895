import { MessageDescriptor } from '@lingui/core';
import { defineMessage } from '@lingui/macro';
import { ErrorCode } from '@luminovo/http-client';

export const errorCodeTranslations: Record<ErrorCode, MessageDescriptor> = {
    'additional_service.english_name_cannot_be_empty': defineMessage({
        message: 'English name cannot be empty',
    }),
    'additional_service.german_name_cannot_be_empty': defineMessage({
        message: 'German name cannot be empty',
    }),
    'assembly.bom_file_not_found': defineMessage({
        message: 'No BOM file found for assembly.',
    }),
    'supplier_contact.cannot_edit_invited_supplier_contact': defineMessage({
        message: `We currently don't support editing invited supplier contacts. Try creating a new one instead.`,
    }),
    'assembly.designator_already_exists_among_siblings': defineMessage({
        message: 'Assembly name already exists among siblings.',
    }),
    'assembly.not_found': defineMessage({
        message: 'Assembly not found',
    }),
    'assembly.cannot_add_top_level_assembly_as_customer_user': defineMessage({
        message: 'You can only add subassemblies, no additional top-level assemblies',
    }),
    'assembly.ipn_already_exists': defineMessage({
        message: 'Cannot associate the IPN with this assembly. Its already linked to another assembly.',
    }),
    'bom_export.cannot_delete_default_template': defineMessage({
        message: 'Cannot delete default BOM export template',
    }),
    'bom_export.cannot_delete_last_template': defineMessage({
        message: 'Cannot delete last BOM export template',
    }),
    'bom_export.cannot_update_default_status_of_default_template': defineMessage({
        message: 'Cannot update default status of default BOM export template',
    }),
    'bom_export.should_contain_at_least_one_column': defineMessage({
        message: 'BOM export template should contain at least one column',
    }),
    'bom_export.template_with_name_already_exists': defineMessage({
        message: 'There already exists a BOM export template with this name',
    }),
    'bom_import.duplicate_designators': defineMessage({
        message: 'Designator already exists.',
    }),
    'bom_import.cannot_read_file': defineMessage({
        message:
            'The uploaded file could not be read. Make sure it is not corrupted, for example by opening the file in Excel and saving it again.',
    }),
    'bom_import.bom_file_too_big': defineMessage({
        message:
            'The BOM is too large to process at once. Consider splitting it up while we work on scaling the import process to accommodate larger files.',
    }),
    'costed_bom_template.cannot_delete_default_template': defineMessage({
        message: 'Cannot delete default costed bom export template',
    }),
    'costed_bom_template.cannot_delete_last_template': defineMessage({
        message: 'Cannot delete last costed bom export template',
    }),
    'costed_bom_template.cannot_update_default_status_of_default_template': defineMessage({
        message: 'Cannot update default status of default costed bom export template',
    }),
    'costed_bom_template.should_contain_at_least_one_column': defineMessage({
        message: 'Costed bom export template should contain at least one column',
    }),
    'costed_bom_template.template_with_name_already_exists': defineMessage({
        message: 'There already exists a costed bom export template with this name',
    }),
    'customer.billing_address_incomplete': defineMessage({
        message: 'Billing address is incomplete. Please contact your EMS to add this information.',
    }),
    'customer.vat_id_incomplete': defineMessage({
        message: 'VAT ID is incomplete. Please contact your EMS to add this information.',
    }),
    'customer.name_unavailable': defineMessage({
        message: 'Customer with this name already exists.',
    }),
    'customer.not_found': defineMessage({
        message: 'Customer not found',
    }),
    'customer.payload_vat_identification_number_missing': defineMessage({
        message: 'VAT identification number and type must be set together.',
    }),
    'customer.payload_vat_identification_type_invalid': defineMessage({
        message: 'VAT identification number and type must be set together.',
    }),
    'customer.number_already_exists': defineMessage({
        message: 'Customer with this number already exists.',
    }),
    'customer.has_referenced_demands': defineMessage({
        message: 'Cannot delete customer referenced in a demand.',
    }),
    'demand.ambiguous_participant_site_number': defineMessage({
        message: 'Site number is ambiguous, multiple sites have this site number',
    }),
    'demand.prototype_demand_scenario_date_not_in_future': defineMessage({
        message: 'Prototype demand scenario date not in future',
    }),
    'demand.invalid_prototype_demand_quantity': defineMessage({
        message: 'Invalid prototype demand quantity',
    }),
    'demand.invalid_dates': defineMessage({
        message: 'Production start date must on or before delivery date',
    }),
    'demand.invalid_delivery_dates': defineMessage({
        message: 'Delivery start date must be on or before delivery end date',
    }),
    'demand.invalid_demand_scenario_year': defineMessage({ message: 'Invalid demand scenario year' }),
    'demand.invalid_demand_scenario_end_year': defineMessage({ message: 'Invalid demand scenario end year' }),
    'demand.no_annual_demands': defineMessage({ message: 'No annual demands' }),
    'demand.only_site_number_or_site_id_required': defineMessage({
        message: 'Only one of site number or site id should be given',
    }),
    'demand.only_customer_number_or_customer_id_required': defineMessage({
        message: 'Only one of customer number or customer id should be given',
    }),
    'demand.invalid_annual_demand': defineMessage({ message: 'Invalid annual demand' }),
    'demand.multiple_annual_demands_for_one_year': defineMessage({ message: 'Multiple annual demands for one year' }),
    'demand.missing_annual_demand': defineMessage({ message: 'Missing annual demand' }),
    'demand.more_than_three_delivery_batch_sizes': defineMessage({
        message: "Can't have more than 3 delivery batch sizes",
    }),
    'demand.delivery_batch_size_out_of_bounds': defineMessage({
        message: 'Delivery batch sizes must be between 1 and 100_000_000 (inclusive)',
    }),
    'demand.invalid_assembly_id': defineMessage({ message: 'The assembly id specified is not a valid UUID' }),
    'driver.name_already_in_use': defineMessage({ message: 'Driver name already exists' }),
    'driver.name_must_not_be_empty': defineMessage({ message: 'Driver name must not be empty' }),
    'driver.name_must_be_alphanumeric': defineMessage({
        message: 'Driver name must be alphanumeric including whitespace only',
    }),
    'driver.first_char_must_be_letter': defineMessage({ message: 'First character of driver name must be a letter' }),
    'driver.name_is_reserved_keyword': defineMessage({ message: 'Driver name is reserved as a keyword' }),
    'driver.name_is_system_driver': defineMessage({ message: 'Driver name is system driver' }),
    'driver.invalid_lexorank': defineMessage({
        message: 'Invalid driver rank',
    }),
    'driver.invalid_package_name': defineMessage({ message: 'Package name in filter does not exist' }),
    'email_template.name.already_exists': defineMessage({
        message: `This email template name is already in use. Please choose a different name.`,
    }),
    'email_template.suppliers.conflict': defineMessage({
        message: `There is already an email template assign to one of the selected suppliers.`,
    }),
    'integration.error': defineMessage({
        message:
            'Oops! We encountered an issue with the API integration. Please double-check your API key and try again. If the issue continues to persist, please contact our customer support.',
    }),
    'integration.invalid_api_key': defineMessage({
        message: 'Invalid API key. Please double-check your API key and try again.',
    }),
    'integration.invalid_credentials': defineMessage({
        message: 'Invalid credentials. Please verify your login information and try again.',
    }),
    'integration.invalid_settings': defineMessage({
        message: 'Invalid settings. Please review your integration settings and make necessary corrections.',
    }),
    'integration.rate_limited': defineMessage({
        message:
            'Rate limited. You have exceeded the rate limit. Please wait a while or contact our customer support for assistance.',
    }),
    'invite.email_already_exists': defineMessage({
        message: 'A user with this email address already exists',
    }),
    'invite.invalid_user_invite': defineMessage({
        message: 'The invitation link is invalid or has expired. Please request another invitation.',
    }),
    'invite.invite_already_sent': defineMessage({
        message: 'An invitation link has already been sent',
    }),
    'manufacturing_scenario.name_already_exists': defineMessage({
        message: 'Manufacturing scenario name already in use',
    }),
    'manufacturing_scenario_template.already_exists': defineMessage({
        message: 'Template name already in use',
    }),
    'manufacturing_assembly_details.panel_factor_less_than_one': defineMessage({
        message: 'Panel factor cannot be less than 1',
    }),
    'manufacturing_assembly_details.driver_does_not_use_sourcing_field': defineMessage({
        message: 'Driver does not use sourcing field',
    }),
    'panel.generation.pcb_width_exceeds_panel': defineMessage({
        message: 'PCB is too large to fit the panel preferences..',
    }),
    'panel.generation.pcb_height_exceeds_panel': defineMessage({
        message: 'PCB is too large to fit the panel preferences..',
    }),
    'panel.generation.unknown_error': defineMessage({
        message: 'PCB is too large to fit the panel preferences..',
    }),
    'payload.email_cannot_be_empty': defineMessage({
        message: 'Email is required',
    }),
    'payload.first_name_cannot_be_empty': defineMessage({
        message: 'First name is required',
    }),
    'payload.last_name_cannot_be_empty': defineMessage({
        message: 'Last name is required',
    }),
    'payload.password_cannot_contain_personal_data': defineMessage({
        message: 'Password cannot contain personal information such as name or email address.',
    }),
    'payload.password_mismatch': defineMessage({
        message: 'Passwords do not match',
    }),
    'payload.weak_password': defineMessage({
        message:
            'Password not strong enough. It must be least 8 characters long and contain lower case, upper case, numeric, and special characters.',
    }),
    'payload.customer_company_name_cannot_be_empty': defineMessage({
        message: 'Company name is required',
    }),
    'payload.vat_identification_number_cannot_be_empty': defineMessage({
        message: 'VAT identification number is required',
    }),
    'purchase_order.conflict': defineMessage({
        message: 'This purchase order ID is already in use. Please use a different ID.',
    }),
    'pnp_file.not_found': defineMessage({
        message: 'Pick and Place file not found',
    }),
    'pnp_importer.column_missing': defineMessage({
        message: 'One of the required columns is missing',
    }),
    'pnp_importer.csv': defineMessage({
        message: 'There was an error uploading your file. Please try again',
    }),
    'pnp_importer.invalid_mapping': defineMessage({
        message: 'Mapping is invalid',
    }),
    'pnp_importer.multiple_mapping': defineMessage({
        message: 'Multiple mapping',
    }),
    'pnp_importer.invalid_rfq_state': defineMessage({
        message: 'The current RfQ status does not allow uploading or modifying PnP file',
    }),
    'pnp_item.not_found': defineMessage({
        message: 'PnP item not found',
    }),
    'quote_importer.extraction': defineMessage({
        message: `Extraction failed`,
    }),
    'quote_importer.general': defineMessage({
        message: `Import failed`,
    }),
    'quote_importer.no_supplier': defineMessage({
        message: `No supplier found`,
    }),
    'quote_importer.negative_stock': defineMessage({
        message: `Stock must not be negative`,
    }),
    'quote_importer.invalid_factory_lead_time': defineMessage({
        message: `Factory lead time must be greater than 0 days`,
    }),
    'quote_importer.negative_factory_quantity': defineMessage({
        message: `Factory quantity must not be negative`,
    }),
    'quote_importer.invalid_moq': defineMessage({
        message: `MOQ must be greater than 0`,
    }),
    'quote_importer.invalid_mpq': defineMessage({
        message: `MPQ must be greater than 0`,
    }),
    'quote_importer.negative_unit_price': defineMessage({
        message: `Unit price must not be negative`,
    }),
    'quote_importer.invalid_lead_time': defineMessage({
        message: `Lead time must be greater than 0 days`,
    }),
    'quote_importer.invalid_unit_of_measurement': defineMessage({
        message: `Unit of measurement cannot be <= 0`,
    }),
    'quote_importer.part_not_found': defineMessage({
        message: `Part not found`,
    }),
    'quote_importer.unsupported_part_type': defineMessage({
        message: `Generic part is not supported`,
    }),
    'quote_importer.suppliers_not_equal': defineMessage({
        message: `An offer's supplier and stock location is different from the provided main one`,
    }),
    'rfq.cannot_create_customer_as_customer_user': defineMessage({
        message: 'Cannot create a customer as customer user',
    }),
    'rfq.cannot_create_rfq_for_different_customer': defineMessage({
        message: 'Cannot create an RfQ for a different customer',
    }),
    'rfq.cannot_update_archived': defineMessage({
        message: 'An archived RfQ cannot be updated',
    }),
    'rfq.customer_does_not_exist': defineMessage({
        message: 'The selected customer does not exist',
    }),
    'rfq.customer_user_has_no_customer': defineMessage({
        message: 'Customer user has no customer',
    }),
    'rfq.delete_rfq_with_sales_order_prohibited': defineMessage({
        message:
            'Cannot delete an RfQ where the customer has purchased a sales order. Please contact customer support to confirm deletion of both the RfQ and sales order',
    }),
    'rfq.duplicate_order_sizes': defineMessage({
        message: 'Order sizes for each scenario date must be unique',
    }),
    'rfq.invalid_assembly_name': defineMessage({
        message: 'Invalid assembly name',
    }),
    'rfq.duplicate_assembly_names': defineMessage({
        message: 'Please choose unique assembly names, duplicates are not supported.',
    }),
    'rfq.invalid_customer_name': defineMessage({
        message: 'Invalid customer name',
    }),
    'rfq.invalid_name': defineMessage({
        message: 'Invalid RfQ name',
    }),
    'rfq.invalid_order_date': defineMessage({
        message: 'Invalid order scenario date',
    }),
    'rfq.invalid_order_size': defineMessage({
        message: 'Invalid order size',
    }),
    'rfq.invalid_rfq_number': defineMessage({
        message: 'Invalid RfQ number',
    }),
    'rfq.invalid_volume_estimate_bounds': defineMessage({
        message: 'Invalid volume estimate',
    }),
    'rfq.new_customer_name_unavailable': defineMessage({
        message: 'The name for your new customer is unavailable',
    }),
    'rfq.not_found': defineMessage({
        message: 'The RfQ was not found',
    }),
    'rfq.request_in_draft_only_allowed_if_customer_portal_is_enabled': defineMessage({
        message: 'Request in draft only allowed if customer portal is enabled',
    }),
    'rfq.unauthorized': defineMessage({
        message: 'You are not authorized to perform this action',
    }),
    unauthorized: defineMessage({
        message: 'You are not authorized to perform this action',
    }),
    'rfq.workflow_changes_only_allowed_by_ems': defineMessage({
        message: 'Workflow changes are only allowed by the EMS',
    }),
    'self_signup_settings.contact_person_must_be_from_ems': defineMessage({
        message: 'The default contact person must be a member of the EMS.',
    }),
    'self_signup_settings.contact_person_not_found': defineMessage({
        message: 'The default contact person does not exist.',
    }),
    'self_signup_settings.invalid_color_scheme': defineMessage({
        message: 'The color scheme is invalid. It must consist of hex codes.',
    }),
    'self_signup_settings.invalid_organization_logo': defineMessage({
        message: 'The link to the organization logo is invalid.',
    }),
    'site.cannot_delete_default_inventory_site': defineMessage({
        message: 'Default inventory site cannot be deleted',
    }),
    'site.cannot_delete_inventory_site_with_linked_inventory_offers': defineMessage({
        message: 'Inventory site with linked inventory offers cannot be deleted',
    }),
    'site.cannot_remove_default_setting_from_last_inventory_site': defineMessage({
        message: 'There must be at least one default inventory site',
    }),
    'site.invalid_default_site_setting': defineMessage({
        message: 'Non-inventory sites cannot be used as default inventory site',
    }),
    'site.must_have_at_least_one_tag': defineMessage({
        message: 'Site must have at least one tag',
    }),
    'site.name_already_exists': defineMessage({
        message: 'Site name already in use',
    }),
    'site.site_number_already_exists': defineMessage({
        message: 'Site number already in use',
    }),
    'solution_configuration.not_found': defineMessage({
        message: 'The selected solution does not exist anymore.',
    }),
    'solution_configuration.invalid_offers': defineMessage({
        message: 'The selected solution is invalid or has expired. Please select another solution.',
    }),
    'solution_configuration.invalid_scrap_quantity': defineMessage({
        message: `Invalid scrap quantity`,
    }),
    'solution_configuration.no_design_items': defineMessage({
        message: 'The selected solution has no design items.',
    }),
    'solution_configuration.no_part_options': defineMessage({
        message: 'The selected solution has no part options.',
    }),
    'solution_configuration.solution_not_found': defineMessage({
        message: 'The selected solution does not exist anymore.',
    }),
    'sourcing_scenario.name_already_exists': defineMessage({
        message: 'Sourcing scenario name already in use',
    }),
    'sourcing_scenario.not_found': defineMessage({
        message: 'Sourcing scenario not found',
    }),
    'supplier_and_stock_location.already_exists': defineMessage({
        message: `Supplier name already in use`,
    }),
    'supplier.not_found': defineMessage({
        message: `Supplier not found`,
    }),
    'supplier.name.empty': defineMessage({
        message: `Supplier name is empty`,
    }),
    'supplier.name.already_exists': defineMessage({
        message: `Supplier name already in use`,
    }),
    'supplier.supplier_number.empty': defineMessage({
        message: `Supplier number is empty`,
    }),
    'supplier.supplier_number.already_exists': defineMessage({
        message: `Supplier number already in use`,
    }),
    'supplier_terms.not_found': defineMessage({
        message: `Supplier terms not found`,
    }),
    'supplier_terms.payment_due_days_constraint_violation': defineMessage({
        message: `Net payment due days must be greater than early payment due days`,
    }),
    'supplier_terms.already_exists': defineMessage({
        message: `Supplier terms for this site have already been added`,
    }),
    unknown: defineMessage({
        message: 'Sorry, there was a problem. Please try again later or contact our customer support.',
    }),

    unprocessable_entity: defineMessage({
        /* eslint-disable-next-line spellcheck/spell-checker */
        message: 'Sorry, there was a problem. Please try again later or contact our customer support.',
    }),
    'off_the_shelf.duplicated_mpn': defineMessage({
        message: `Duplicated MPN`,
    }),
    'off_the_shelf.mpn_length_violation': defineMessage({
        message: `Must be 3 characters or more`,
    }),
    'off_the_shelf.package_violation': defineMessage({
        message: `Invalid package`,
    }),
    'off_the_shelf.part_already_exists': defineMessage({
        message: `Part already exists`,
    }),
    'customer_portal_settings.manufacturing_template_required': defineMessage({
        message: 'You must define a manufacturing template for the automatic order confirmation workflow.',
    }),
    'customer_portal_settings.calculation_template_required': defineMessage({
        message: 'You must define a calculation template for the automatic order confirmation workflow.',
    }),
    'customer_portal_settings.invalid_auto_rfq_number_range': defineMessage({
        message: 'the start of the range must be less than end',
    }),
    'payment.no_configured_payment_processor': defineMessage({
        message: 'Your payment processor is not configured.',
    }),
    'payment.not_found': defineMessage({
        message: 'Payment not found.',
    }),
    'payment.refunded': defineMessage({
        message: 'Payment has been refunded.',
    }),
    'payment.already_scheduled_for_refunded': defineMessage({
        message: 'Already scheduled for refund',
    }),
    'payment.scheduled_for_refunded': defineMessage({
        message: 'Payment is scheduled for refund',
    }),
    'payment.amount_mismatch': defineMessage({
        message: 'Amount mismatch',
    }),
    'payment.invalid_currency': defineMessage({
        message: 'Invalid currency',
    }),
    'order.tax_id_invalid': defineMessage({
        message: 'Invalid Tax ID. Please reach out to our support team to update your information.',
    }),
    'order.payment_method_not_allowed': defineMessage({
        message: 'Payment method not allowed.',
    }),
    'order.not_found': defineMessage({
        message: 'Order not found',
    }),
    'order.sourcing_offers_pending': defineMessage({
        message: 'Sourcing offers are pending',
    }),

    not_found: defineMessage({
        message: 'Record not found',
    }),
    'design_item.rfq_not_found': defineMessage({
        message: 'RfQ not found',
    }),
    'design_item.belong_to_different_rfq': defineMessage({
        message: 'Design items belongs to a different RfQ',
    }),
    'design_item.not_found': defineMessage({
        message: 'Design item not found',
    }),
    'email.payload_too_large': defineMessage({
        message:
            'The email could not be sent because the attachments are too large. Please reduce the size of the attachments and try again.',
    }),
    'email.other_error': defineMessage({
        message: 'An unexpected error occurred while sending the email. Please try again later.',
    }),
    'frozen_scenario_cost.already_frozen': defineMessage({
        message: 'Calculation is already frozen, please refresh the page',
    }),
    // eslint-disable-next-line spellcheck/spell-checker
    'auto_rfq_number_settings.cant_increment_manual_rfq_number': defineMessage({
        message: 'Trying to increment RfQ number when settings are set to manual',
    }),
    'auto_rfq_number_settings.invalid_auto_rfq_number_range': defineMessage({
        message: 'Invalid range for automatic RfQ number',
    }),
    'total_cost_of_ownership_rule.absolute_cost_cannot_be_negative': defineMessage({
        message: 'Absolute cost cannot be negative',
    }),
    'total_cost_of_ownership_rule.conditions_cannot_be_empty': defineMessage({
        message: 'A rule must have at least one condition',
    }),
    'total_cost_of_ownership_rule.invalid_name': defineMessage({
        message: 'Invalid name',
    }),
    'award_scenario.name_conflict': defineMessage({
        message: 'A scenario with this name already exists',
    }),
    'supplier_contact.contact_has_associated_user': defineMessage({
        message: 'Contact cannot be deleted because it is connected to a supplier portal user',
    }),
    'user.has_made_a_payment': defineMessage({
        message: 'This user has already made a payment. Please contact support to remove the user.',
    }),
    'supplier_contact.email_already_exists_as_user': defineMessage({
        message: 'This email is already associated with an existing user account.',
    }),
    'quote_request.contacts_with_different_suppliers': defineMessage({
        message: 'The selected contacts belong to different suppliers. All contacts must be from the same supplier.',
    }),
    'quote_request.negotiation_line_items_not_found': defineMessage({
        message: 'No negotiation line items could be found.',
    }),
    'quote_request.invalid_data': defineMessage({
        message: 'Invalid data in quote request.',
    }),
    'quote_request.missing_user': defineMessage({
        message: 'Unable to find a user to be the sender of the quote request.',
    }),
    'quote_request.excel_generation_failure': defineMessage({
        message: 'Failed to generate quote request excel file.',
    }),
    'quote_request.cannot_send_in_current_status': defineMessage({
        message: 'Quote request cannot be sent in its current status.',
    }),
    'quote_request.no_email_recipients': defineMessage({
        message: 'No email recipients were provided.',
    }),
    'quote_request.multiple_emails_for_quote_request': defineMessage({
        message: 'Multiple emails were provided for a single quote request.',
    }),
    'quote_request.not_found': defineMessage({
        message: 'Quote request not found.',
    }),
    'quote_request.multiple_quote_requests_not_sent_for_supplier': defineMessage({
        message: 'Multiple quote requests not sent for the same supplier.',
    }),
    'quote_request.file_not_found': defineMessage({
        message: 'Quote request file not found.',
    }),
    // Frontend only error code - used for assertSuccessfulResponseGenericErrorCode.ts
    // eslint-disable-next-line spellcheck/spell-checker
    'frontend_error.export.generic_error': defineMessage({
        message: 'An error occurred while exporting the data.',
    }),
};
