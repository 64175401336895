import { colorSystem } from '../../../theme';
import { Flexbox, FlexboxProps } from '../../Flexbox';

export const StickyLayout = (props: FlexboxProps): JSX.Element => (
    <Flexbox
        position="sticky"
        top="0"
        left="0"
        zIndex="10"
        flexDirection="column"
        gap={8}
        maxWidth="100%"
        style={{
            background: colorSystem.neutral.white,
            ...props.style,
        }}
        {...props}
    />
);
