import { RfqListItemDTO, RfqStatus } from '@luminovo/http-client';

export interface RfqCategory {
    count: number;
    percent: number;
}

export interface RfqMetrics {
    total: number;
    categories: {
        won: RfqCategory;
        lost: RfqCategory;
        in_progress: RfqCategory;
        no_quotation: RfqCategory;
    };
}

export const WON_STATUSES = [
    RfqStatus.OrderPlaced,
    RfqStatus.OrderShipped,
    RfqStatus.OrderConfirmed,
    RfqStatus.PaymentFailed,
    RfqStatus.OrderInProduction,
    RfqStatus.OrderInProcurement,
] as const;

export const LOST_STATUSES = [RfqStatus.NoOrder] as const;

export const IN_PROGRESS_STATUSES = [
    RfqStatus.RequestInDraft,
    RfqStatus.QuotationInProgress,
    RfqStatus.QuotationAvailable,
] as const;

export const NO_QUOTATION_STATUSES = [RfqStatus.NoQuotation] as const;

// Type check to ensure all RfqStatus values are covered
type StatusGroupUnion =
    | (typeof WON_STATUSES)[number]
    | (typeof LOST_STATUSES)[number]
    | (typeof IN_PROGRESS_STATUSES)[number]
    | (typeof NO_QUOTATION_STATUSES)[number];
type UncoveredStatuses = Exclude<RfqStatus, StatusGroupUnion>;

// This will cause a type error if any RfqStatus is not covered
type EnsureAllStatusesCovered<T> = T extends never ? true : false;
const _exhaustiveCheck: true = true as EnsureAllStatusesCovered<UncoveredStatuses>;

export function calculateRfqMetrics(rfqs: RfqListItemDTO[]): RfqMetrics {
    const won = rfqs.filter((rfq) => (WON_STATUSES as readonly RfqStatus[]).includes(rfq.status)).length;
    const lost = rfqs.filter((rfq) => (LOST_STATUSES as readonly RfqStatus[]).includes(rfq.status)).length;
    const in_progress = rfqs.filter((rfq) =>
        (IN_PROGRESS_STATUSES as readonly RfqStatus[]).includes(rfq.status),
    ).length;
    const no_quotation = rfqs.filter((rfq) =>
        (NO_QUOTATION_STATUSES as readonly RfqStatus[]).includes(rfq.status),
    ).length;
    const total = rfqs.length;

    const getPercent = (value: number) => (total === 0 ? 0 : (value / total) * 100);
    const createCategory = (count: number): RfqCategory => ({
        count,
        percent: getPercent(count),
    });

    return {
        total,
        categories: {
            won: createCategory(won),
            lost: createCategory(lost),
            in_progress: createCategory(in_progress),
            no_quotation: createCategory(no_quotation),
        },
    };
}

export function calculateWinRate(rfqs: RfqListItemDTO[]): {
    winRate: number;
} {
    // Filter out archived RFQs and those in progress
    const filteredRfqs = rfqs.filter(
        (rfq) => !rfq.is_archived && !(IN_PROGRESS_STATUSES as readonly RfqStatus[]).includes(rfq.status),
    );

    const won = filteredRfqs.filter((rfq) => (WON_STATUSES as readonly RfqStatus[]).includes(rfq.status)).length;

    const lost = filteredRfqs.filter((rfq) => (LOST_STATUSES as readonly RfqStatus[]).includes(rfq.status)).length;

    const no_quotation = filteredRfqs.filter((rfq) =>
        (NO_QUOTATION_STATUSES as readonly RfqStatus[]).includes(rfq.status),
    ).length;

    // Calculate conversion rate
    // If there are no completed RfQs (won, lost, or no_quotation), return 100%
    return { winRate: won + lost + no_quotation === 0 ? 100 : (won / (won + lost + no_quotation)) * 100 };
}
