import { t, Trans } from '@lingui/macro';
import { id } from '@luminovo/commons';
import {
    colorSystem,
    Column,
    Flexbox,
    HeaderCheckbox,
    Row,
    RowCheckbox,
    SecondaryIconButton,
} from '@luminovo/design-system';
import { OtsFullPart, PartAlternative, PartAlternativeSimilarityEnum } from '@luminovo/http-client';
import { LifecycleTooltip, SimilarityTag, SimilarityTooltip } from '@luminovo/sourcing-core';
import { Add, Info } from '@mui/icons-material';
import { TableCell, Tooltip } from '@mui/material';
import { useCallback } from 'react';
import { PartSuggestionAvailabilityView } from '../../../../../components/PartAvailabilityView';
import {
    RenderDescription,
    RenderManufacturer,
    RenderMpn,
    RenderPackage,
    StickyTableCell,
} from '../../../../../components/partColumns';
import { RenderDatasheet } from '../../../../../components/partColumns/RenderDatasheet';
import {
    StickyLeftHeaderTableCell,
    StickyRightHeaderTableCell,
} from '../../../../../components/partColumns/StickyHeadTableCell';
import { PartComplianceView } from '../../../../../components/PartComplianceView';
import { EmissionsTooltip } from '../../../../../components/PartEmissionsView/EmissionsTooltip';
import { PartEmissionsView } from '../../../../../components/PartEmissionsView/PartEmissionsView';
import { PartLifecycleView } from '../../../../../components/PartLifecycleView';
import { otsComponentLinkingDialog } from '../../LinkPartsDialog/useOtsComponentLinkingDialog';
import { ManufacturerTooltip } from '../PartialMatches/ManufacturerTooltip';
import { useBomItemCandidateMpns } from '../useBomItemCandidateMpns';
import { PartAlternativeContext } from './partAlternativesTypes';

enum partAlternativesColumnIds {
    columnSelection = 'part-alternatives-selection',
    columnMpn = 'part-alternatives-mpn',
    columnManufacturer = 'part-alternatives-manufacturer',
    columnDescription = 'part-alternatives-description',
    columnPackage = 'part-alternatives-package',
    columnDatasheet = 'part-alternatives-datasheet',
    columnAvailability = 'part-alternatives-availability',
    columnLifecycle = 'part-alternatives-lifecycle',
    columnCompliance = 'part-alternatives-compliance',
    columnEmissions = 'part-alternatives-emissions',
    columnSimilarity = 'part-alternatives-similarity',
}

const columnSelection: Column<PartAlternative, PartAlternativeContext> = {
    id: partAlternativesColumnIds.columnSelection,
    label: '',
    renderHead: ({ items, filteredItems, sharedContext, ...rest }) => (
        <HeaderCheckbox
            {...rest}
            items={items}
            filteredItems={filteredItems}
            sharedContext={sharedContext}
            disabled={sharedContext.isSubmitting}
            overrides={{ HeaderTableCell: StickyLeftHeaderTableCell }}
        />
    ),
    render: (data, sharedContext) => {
        return (
            <RowCheckbox
                data={data}
                isDisabled={sharedContext.isSubmitting}
                style={{
                    position: 'sticky',
                    left: 0,
                    background: colorSystem.neutral.white,
                    boxShadow: `inset -1px 0px 0px 0px ${colorSystem.neutral[2]}`,
                }}
            />
        );
    },
};

const RenderPartAlternativesMpn = (
    { data }: Row<PartAlternative>,
    { bomItem, rfqContext }: PartAlternativeContext,
): JSX.Element => {
    const searchQuery = useBomItemCandidateMpns(bomItem, []);
    return <RenderMpn part={data.off_the_shelf_part} candidateMpns={searchQuery} rfqContext={rfqContext} />;
};

const columnMpn = {
    id: partAlternativesColumnIds.columnMpn,
    label: <Trans>MPN</Trans>,
    render: RenderPartAlternativesMpn,
};

const columnManufacturer = {
    id: partAlternativesColumnIds.columnManufacturer,
    label: (
        <Flexbox alignItems={'center'}>
            <Trans>Manufacturer</Trans>
            <ManufacturerTooltip>
                <Info style={{ color: colorSystem.neutral[5], height: 16 }} />
            </ManufacturerTooltip>
        </Flexbox>
    ),
    render: ({ data }: Row<PartAlternative>): JSX.Element => {
        return <RenderManufacturer part={data.off_the_shelf_part} />;
    },
};

const columnDescription = {
    id: partAlternativesColumnIds.columnDescription,
    label: <Trans>Description</Trans>,
    render: ({ data }: Row<PartAlternative>): JSX.Element => {
        return <RenderDescription part={data.off_the_shelf_part} />;
    },
};

const columnPackage = {
    id: partAlternativesColumnIds.columnPackage,
    label: <Trans>Package</Trans>,
    render: ({ data }: Row<PartAlternative>): JSX.Element => {
        return <RenderPackage part={data.off_the_shelf_part} />;
    },
};

const columnDatasheet = {
    id: partAlternativesColumnIds.columnDatasheet,
    label: '',
    render: ({ data }: Row<PartAlternative>): JSX.Element => {
        return <RenderDatasheet part={data.off_the_shelf_part} />;
    },
};

const columnAvailability: Column<PartAlternative, PartAlternativeContext> = {
    id: partAlternativesColumnIds.columnAvailability,
    label: <Trans>Availability</Trans>,
    render: ({ data: part }, shareContext) => {
        return (
            <TableCell>
                <PartSuggestionAvailabilityView
                    rfqId={shareContext.rfqId}
                    part={part.off_the_shelf_part}
                    bomItem={shareContext.bomItem}
                    assemblyId={shareContext.assemblyId}
                />
            </TableCell>
        );
    },
};

const columnLifecycle = {
    id: partAlternativesColumnIds.columnLifecycle,
    label: (
        <Flexbox alignItems="center">
            <Trans>Lifecycle</Trans>
            <LifecycleTooltip>
                <Info style={{ color: colorSystem.neutral[5], height: 16 }} />
            </LifecycleTooltip>
        </Flexbox>
    ),
    render: ({ data: part }: Row<PartAlternative>): JSX.Element => {
        return (
            <TableCell>
                <PartLifecycleView part={part.off_the_shelf_part} />
            </TableCell>
        );
    },
};

const columnCompliance = {
    id: partAlternativesColumnIds.columnCompliance,
    label: <Trans>Compliance</Trans>,
    render: ({ data: part }: Row<PartAlternative>, sharedContext: PartAlternativeContext): JSX.Element => {
        return (
            <TableCell>
                <PartComplianceView
                    part={part.off_the_shelf_part}
                    showAecq={sharedContext.assemblyIndustry === 'Auto'}
                />
            </TableCell>
        );
    },
};

const columnEmissions = {
    id: partAlternativesColumnIds.columnEmissions,
    label: '',
    renderHead: () => (
        <TableCell>
            <Flexbox alignItems="center" color={colorSystem.neutral[7]}>
                <Trans>kgCO2e</Trans>
                <EmissionsTooltip>
                    <Info style={{ color: colorSystem.neutral[5], height: 16 }} />
                </EmissionsTooltip>
            </Flexbox>
        </TableCell>
    ),
    render: ({ data }: Row<PartAlternative>): JSX.Element => {
        return (
            <TableCell>
                <PartEmissionsView part={data.off_the_shelf_part} />
            </TableCell>
        );
    },
};

const columnSimilarity = {
    id: partAlternativesColumnIds.columnSimilarity,
    label: (
        <Flexbox alignItems="center">
            <Trans>Similarity</Trans>
            <SimilarityTooltip>
                <Info style={{ color: colorSystem.neutral[5], height: 16 }} />
            </SimilarityTooltip>
        </Flexbox>
    ),
    overrides: {
        HeaderTableCell: StickyRightHeaderTableCell,
    },
    render: ({ data: part }: Row<PartAlternative>, sharedContext: PartAlternativeContext): JSX.Element => {
        return (
            <StickyTableCell>
                <Flexbox gap="24px" alignItems="center" justifyContent="space-between">
                    <SimilarityTag similarity={part.similarity} />
                    <RenderActions
                        part={part.off_the_shelf_part}
                        similarity={part.similarity}
                        sharedContext={sharedContext}
                    />
                </Flexbox>
            </StickyTableCell>
        );
    },
};

const RenderActions = ({
    part,
    similarity,
    sharedContext,
}: {
    part: OtsFullPart;
    similarity: PartAlternativeSimilarityEnum;
    sharedContext: PartAlternativeContext;
}): JSX.Element => {
    const {
        bomItem,
        permissions,
        openOtsCompLinkingDialog,
        handleAddMultiplePartOptions,
        hasDesignatorQuantityMismatch,
        isSubmitting,
    } = sharedContext;
    const handleOnClick = useCallback(() => {
        const otsCompLinkingData = otsComponentLinkingDialog({
            partOptions: bomItem.parts,
            partsToAddAsPartOption: [part],
            permissions,
        });
        if (otsCompLinkingData.shouldOpenComponentLinkingDialog) {
            const { otsComponent, partsToLink, partsToAddAsPartOption } = otsCompLinkingData;
            return openOtsCompLinkingDialog({
                otsComponent,
                partsToLink,
                onConfirm: () =>
                    handleAddMultiplePartOptions({
                        data: partsToAddAsPartOption,
                        sectionOfScreen: 'alternativeParts',
                    }),
                onCancel: () =>
                    handleAddMultiplePartOptions({
                        data: partsToAddAsPartOption,
                        sectionOfScreen: 'alternativeParts',
                    }),
            });
        }

        handleAddMultiplePartOptions({
            data: [part],
            sectionOfScreen: 'alternativeParts',
            alternativeSimilarity: similarity,
        });
    }, [bomItem.parts, similarity, handleAddMultiplePartOptions, openOtsCompLinkingDialog, part, permissions]);

    return (
        <Tooltip
            title={
                hasDesignatorQuantityMismatch
                    ? t`Designator or quantity unclear. Please address the issue before continuing.`
                    : t`Add to selected part options`
            }
        >
            <span>
                <SecondaryIconButton
                    disabled={isSubmitting || hasDesignatorQuantityMismatch}
                    className={id('design/button_add_part_suggestion')}
                    onClick={(e) => {
                        e.stopPropagation();
                        handleOnClick();
                    }}
                    size="small"
                >
                    <Add fontSize="inherit" />
                </SecondaryIconButton>
            </span>
        </Tooltip>
    );
};

export const allPartAlternativesColumns: Column<PartAlternative, PartAlternativeContext>[] = [
    columnSelection,
    columnMpn,
    columnManufacturer,
    columnDescription,
    columnPackage,
    columnDatasheet,
    columnAvailability,
    columnLifecycle,
    columnCompliance,
    columnEmissions,
    columnSimilarity,
];
