import * as z from 'zod';
import { endpoint } from '../http/endpoint';
import { TiCredentialsInputDTORuntype, TiLocaleCurrencyDTORuntype, TiResponseBodyDTORuntype } from './tiBackendTypes';

export const tiEndpoints = {
    'GET /3rdparty/tistore/credentials': endpoint({
        description: 'Returns credentials for ti integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: TiResponseBodyDTORuntype,
    }),
    'POST /3rdparty/tistore/credentials': endpoint({
        description: 'Uploads credentials for ti API integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: TiCredentialsInputDTORuntype,
        responseBody: TiResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/tistore/credentials'],
    }),
    'PATCH /3rdparty/tistore/credentials': endpoint({
        description: 'Updates credentials for ti API integratoin',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: TiCredentialsInputDTORuntype,
        responseBody: TiResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/tistore/credentials'],
    }),
    'DELETE /3rdparty/tistore/credentials': endpoint({
        description: 'Deletes existing credentials for ti API integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({ deleted: z.number() }),
        invalidates: ['GET /3rdparty/tistore/credentials'],
    }),
    'GET /3rdparty/tistore/supported-currencies': endpoint({
        description: 'Returns possible currencies for ti',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({ currencies: z.array(TiLocaleCurrencyDTORuntype) }),
    }),
};
