import { BomItem } from '@/resources/designItem/bomItemFrontendTypes';
import { FullPartWithApprovalStatus } from '@/resources/part/partFrontendTypes';
import { t, Trans } from '@lingui/macro';
import { usePermissions } from '@luminovo/auth';
import { isPresent } from '@luminovo/commons';
import {
    Action,
    colorSystem,
    DataTable,
    DefaultStyledTable,
    Flexbox,
    SecondaryButton,
    TableState,
    Text,
    Tooltip,
    useDataTableState,
} from '@luminovo/design-system';
import {
    ApprovalStatus,
    AssemblyIndustry,
    isOtsFullPart,
    OtsComponentFull,
    OtsFullPart,
    PartAlternative,
    RfqContext,
} from '@luminovo/http-client';
import { partAlternativesIdExtractor as idExtractor } from '@luminovo/sourcing-core';
import { styled } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { BomItemComparePartsDialog } from '../../../../../components/CompareItemsDialog/BomItemComparisonDialog/BomItemComparePartsDialog';
import { useOtsPartDetailsDrawer } from '../../../../../components/partSpecificationCards/OTSPart/useOtsPartDetailsDrawer';
import {
    otsComponentLinkingDialog,
    useOtsComponentLinkingDialog,
} from '../../LinkPartsDialog/useOtsComponentLinkingDialog';
import { AddMultiplePartOptionsParams } from '../../SpecificationTypeForms/types';
import { TableContainer } from '../TableContainer';
import {
    blockedManufacturersDialog,
    useAddPartWithBlockedManufacturerDialog,
} from '../useAddPartWithBlockedManufacturerDialog';
import { allPartAlternativesColumns } from './columns';
import { PartAlternativeContext } from './partAlternativesTypes';

export const getApprovedOrPendingIpnOrOtsParts = (bomItem: BomItem): (OtsFullPart | OtsComponentFull)[] => {
    return bomItem.parts.reduce<(OtsFullPart | OtsComponentFull)[]>((acc, part) => {
        if (part.approvalStatus === ApprovalStatus.Rejected) {
            return acc;
        }

        if (part.type === 'OffTheShelf') {
            return [...acc, part.part];
        }

        if (part.type === 'Ipn' && part.part.matches.some((match) => isOtsFullPart(match.part.data))) {
            return [...acc, part.part];
        }

        return acc;
    }, []);
};

const selectionOptions = {
    idExtractor,
};

export const PartAlternativesTable = ({
    partAlternatives,
    handleAddMultiplePartOptions,
    rfqId,
    bomItem,
    assemblyId,
    assemblyIndustry,
    hasDesignatorQuantityMismatch,
    isSubmitting,
}: {
    partAlternatives: PartAlternative[];
    handleAddMultiplePartOptions: (params: AddMultiplePartOptionsParams) => void;
    rfqId: string;
    bomItem: BomItem;
    assemblyId: string;
    assemblyIndustry?: AssemblyIndustry;
    hasDesignatorQuantityMismatch: boolean;
    isSubmitting: boolean;
}) => {
    const location = useLocation();
    const { permissions } = usePermissions();
    const { openDialog: openOtsCompLinkingDialog } = useOtsComponentLinkingDialog();
    const { openDrawer: openOtsDrawer } = useOtsPartDetailsDrawer();
    const rfqContext: RfqContext = useMemo(() => ({ type: 'WithinRfQ', rfq_id: rfqId }), [rfqId]);

    const handleRowClick = useCallback(
        (partAlternative: PartAlternative) => {
            return openOtsDrawer({ part: partAlternative.off_the_shelf_part, rfqContext });
        },
        [openOtsDrawer, rfqContext],
    );
    const partAlternativeContext: PartAlternativeContext = useMemo(
        () => ({
            rfqContext,
            bomItem,
            rfqId,
            assemblyId,
            handleAddMultiplePartOptions,
            permissions,
            openOtsCompLinkingDialog,
            hasDesignatorQuantityMismatch,
            isSubmitting,
            assemblyIndustry,
        }),
        [
            rfqContext,
            rfqId,
            bomItem,
            assemblyId,
            handleAddMultiplePartOptions,
            permissions,
            openOtsCompLinkingDialog,
            hasDesignatorQuantityMismatch,
            isSubmitting,
            assemblyIndustry,
        ],
    );

    const tableState: TableState<PartAlternative, PartAlternativeContext> = useDataTableState({
        columns: allPartAlternativesColumns,
        items: partAlternatives,
        paginationOptions: { showPagination: false },
        persistenceId: `part-alternatives.${location.pathname}`,
        sharedContext: partAlternativeContext,
        selectionOptions,
    });

    if (partAlternatives.length === 0) {
        return (
            <Text variant="h5" color={colorSystem.neutral[6]}>
                <Trans>No alternative part matches found</Trans>
            </Text>
        );
    }
    return (
        <>
            <PartAlternativesActionButtons
                rfqId={rfqId}
                assemblyId={assemblyId}
                bomItem={bomItem}
                tableState={tableState}
                partAlternatives={partAlternatives}
                handleAddMultiplePartOptions={handleAddMultiplePartOptions}
                assemblyIndustry={assemblyIndustry}
            />
            <DataTable
                tableState={tableState}
                overrides={{ Container: StyledTableContainer, Table: StyledTable }}
                stickyHeader={false}
                onItemClick={handleRowClick}
                size="large"
            />
        </>
    );
};

const PartAlternativesActionButtons = ({
    rfqId,
    assemblyId,
    bomItem,
    tableState,
    partAlternatives,
    handleAddMultiplePartOptions,
    assemblyIndustry,
}: {
    rfqId: string;
    assemblyId: string;
    bomItem: BomItem;
    tableState: TableState<PartAlternative, PartAlternativeContext>;
    partAlternatives: PartAlternative[];
    handleAddMultiplePartOptions: (params: AddMultiplePartOptionsParams) => void;
    assemblyIndustry?: AssemblyIndustry;
}) => {
    const selectedPartAlternatives = tableState.state.selectedIds
        .map((id) => partAlternatives.find((item) => idExtractor(item) === id))
        .filter(isPresent);

    return (
        <Flexbox justifyContent="flex-end" marginBottom="8px" position="absolute" top="71px" right="20px" gap="8px">
            <ComparePartAlternativesButton
                rfqId={rfqId}
                assemblyId={assemblyId}
                bomItem={bomItem}
                tableState={tableState}
                partAlternatives={partAlternatives}
                handleAddMultiplePartOptions={handleAddMultiplePartOptions}
                assemblyIndustry={assemblyIndustry}
                selectedPartAlternatives={selectedPartAlternatives}
            />
            <AddMultiplePartsButton
                selectedPartAlternatives={selectedPartAlternatives}
                partOptions={bomItem.parts}
                handleAddMultiplePartOptions={handleAddMultiplePartOptions}
                dispatch={tableState.dispatch}
            />
        </Flexbox>
    );
};

const ComparePartAlternativesButton = ({
    rfqId,
    assemblyId,
    bomItem,
    tableState,
    partAlternatives,
    handleAddMultiplePartOptions,
    assemblyIndustry,
    selectedPartAlternatives,
}: {
    rfqId: string;
    assemblyId: string;
    bomItem: BomItem;
    tableState: TableState<PartAlternative, PartAlternativeContext>;
    partAlternatives: PartAlternative[];
    handleAddMultiplePartOptions: (params: AddMultiplePartOptionsParams) => void;
    assemblyIndustry?: AssemblyIndustry;
    selectedPartAlternatives: PartAlternative[];
}) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const approvedOrPendingIpnOrOtsParts = getApprovedOrPendingIpnOrOtsParts(bomItem);
    const isCompareDisabled =
        approvedOrPendingIpnOrOtsParts.length === 0 ||
        partAlternatives.length === 0 ||
        selectedPartAlternatives.length > 3 ||
        selectedPartAlternatives.length === 0;

    const handleApprovePartOption = useCallback(
        (newPart) => {
            handleAddMultiplePartOptions({ data: [newPart], sectionOfScreen: 'alternativeParts' });
            // If there was only one part selected, close the dialog
            if (selectedPartAlternatives.length === 1) {
                setIsDialogOpen(false);
            }
        },
        [selectedPartAlternatives, setIsDialogOpen, handleAddMultiplePartOptions],
    );
    return (
        <>
            <Tooltip title={t`Select up to 3 parts and hit compare`}>
                <span>
                    <SecondaryButton size="medium" onClick={() => setIsDialogOpen(true)} disabled={isCompareDisabled}>
                        <Trans>Compare</Trans>
                    </SecondaryButton>
                </span>
            </Tooltip>
            <BomItemComparePartsDialog
                assemblyId={assemblyId}
                isOpen={isDialogOpen}
                onClose={() => setIsDialogOpen(false)}
                partOptions={approvedOrPendingIpnOrOtsParts}
                partAlternatives={selectedPartAlternatives}
                rfqId={rfqId}
                bomItem={bomItem}
                handleAddPartOption={handleApprovePartOption}
                partAlternativesTableState={tableState}
                assemblyIndustry={assemblyIndustry}
            />
        </>
    );
};

const AddMultiplePartsButton = ({
    selectedPartAlternatives,
    partOptions,
    handleAddMultiplePartOptions,
    dispatch,
}: {
    selectedPartAlternatives: PartAlternative[];
    partOptions: FullPartWithApprovalStatus[];
    handleAddMultiplePartOptions: (params: AddMultiplePartOptionsParams) => void;
    dispatch: React.Dispatch<Action>;
}) => {
    const { permissions } = usePermissions();
    const { openDialog: openOtsCompLinkingDialog } = useOtsComponentLinkingDialog();
    const { openDialog: openBlockedManuDialog } = useAddPartWithBlockedManufacturerDialog();

    const handleClick = useCallback(() => {
        const fullPartsToAdd = selectedPartAlternatives.map((part) => part.off_the_shelf_part);

        const checkAndHandleComponentLinking = () => {
            const otsCompLinkingData = otsComponentLinkingDialog({
                partOptions,
                partsToAddAsPartOption: fullPartsToAdd,
                permissions,
            });
            if (otsCompLinkingData.shouldOpenComponentLinkingDialog) {
                const { otsComponent, partsToLink } = otsCompLinkingData;
                return openOtsCompLinkingDialog({
                    otsComponent,
                    partsToLink,
                    onConfirm: () => {
                        handleAddMultiplePartOptions({ data: fullPartsToAdd, sectionOfScreen: 'alternativeParts' });
                        dispatch({ type: 'clear-selected-items' });
                    },
                    onCancel: () => {
                        handleAddMultiplePartOptions({ data: fullPartsToAdd, sectionOfScreen: 'alternativeParts' });
                        dispatch({ type: 'clear-selected-items' });
                    },
                });
            }
            handleAddMultiplePartOptions({ data: fullPartsToAdd, sectionOfScreen: 'alternativeParts' });
            dispatch({ type: 'clear-selected-items' });
        };

        const checkAndHandleBlockedManufacturers = () => {
            const blockedManufacturersData = blockedManufacturersDialog({
                fullParts: fullPartsToAdd,
            });
            if (blockedManufacturersData.shouldOpenBlockedManufacturersDialog) {
                const { partsWithBlockedManufacturers } = blockedManufacturersData;
                return openBlockedManuDialog({
                    partsWithBlockedManufacturers,
                    onConfirm: checkAndHandleComponentLinking,
                });
            }
            checkAndHandleComponentLinking();
        };

        checkAndHandleBlockedManufacturers();
    }, [
        selectedPartAlternatives,
        handleAddMultiplePartOptions,
        dispatch,
        partOptions,
        permissions,
        openOtsCompLinkingDialog,
        openBlockedManuDialog,
    ]);
    return (
        <Tooltip title={t`Select the parts you want to add first`}>
            <span>
                <SecondaryButton size="medium" onClick={handleClick} disabled={selectedPartAlternatives.length === 0}>
                    <Trans>Add parts</Trans>
                </SecondaryButton>
            </span>
        </Tooltip>
    );
};

// The table & its container are rotated by 180 degrees so that the scrollbar is moved to the top of the table.
const rotate180degrees = 'rotateX(180deg)';

const StyledTableContainer = styled(TableContainer)({
    overflow: 'invisible',
    transform: rotate180degrees,
});

const StyledTable = styled(DefaultStyledTable)({
    transform: rotate180degrees,
});
