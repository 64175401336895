import * as z from 'zod';
import { HttpEndpoint } from '../http/HttpEndpoint';
import { endpoint } from '../http/endpoint';
import {
    BulkPnpItemsResponseRuntype,
    CheckedBulkPnpItemsResponseRuntype,
    ColumnMappingRequestRuntype,
    ParsedPnpResponseRuntype,
    PnpFileInsertRuntype,
    PnpFileResponseRuntype,
    PnpFileRuntype,
    PnpItemRuntype,
    PnpUploadFailedURLResponseRuntype,
} from './pnpBackendTypes';

const invalidates: HttpEndpoint[] = [
    'GET /pnp/bulk',
    'POST /rfqs/:rfqId/customer-portal',
    'GET /assemblies/:assemblyId/state',
];

export const pnpEndpoints = {
    'GET /pnp': endpoint({
        description: 'Get a single PnP item',
        pathParams: z.undefined(),
        queryParams: z.object({
            id: z.string(),
        }),
        requestBody: z.undefined(),
        responseBody: PnpItemRuntype,
    }),

    'GET /pnp/bulk': endpoint({
        description: 'Gets all pnp items for a given assembly',
        pathParams: z.undefined(),
        queryParams: z.object({
            assembly: z.string(),
        }),
        requestBody: z.undefined(),
        responseBody: CheckedBulkPnpItemsResponseRuntype,
    }),

    'POST /pnp/upload-url': endpoint({
        description: 'Create a new PnP file and request a URL to upload it for parsing',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: PnpFileInsertRuntype,
        responseBody: PnpFileResponseRuntype,
    }),

    'POST /pnp/upload-failed-url': endpoint({
        description: 'Sets a PnP file to failed and request a URL to upload it for parsing',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: PnpFileInsertRuntype,
        responseBody: PnpUploadFailedURLResponseRuntype,
    }),

    'POST /pnp/parse': endpoint({
        description: 'Parse the PnP file that was previously uploaded and get a table back',
        pathParams: z.undefined(),
        queryParams: z.object({
            id: z.string(),
        }),
        requestBody: z.undefined(),
        responseBody: ParsedPnpResponseRuntype,
        invalidates,
    }),

    'POST /pnp/column-mapping': endpoint({
        description: 'Submit a column mapping to check if it is valid and save the PnP items if it is valid',
        pathParams: z.undefined(),
        queryParams: z.object({
            id: z.string(),
        }),
        requestBody: ColumnMappingRequestRuntype,
        responseBody: BulkPnpItemsResponseRuntype,
        invalidates,
    }),

    'GET /pnp/file': endpoint({
        description: 'Get the details of PnP file for this asssembly, errors if not created',
        pathParams: z.undefined(),
        queryParams: z.object({
            assembly: z.string(),
        }),
        requestBody: z.undefined(),
        responseBody: PnpFileRuntype,
    }),
    'DELETE /pnp/file': endpoint({
        description: 'Deletes a pnp file for a specific assembly',
        pathParams: z.undefined(),
        queryParams: z.object({
            assembly: z.string(),
        }),
        requestBody: z.undefined(),
        responseBody: z.object({
            deleted: z.number(),
        }),
        invalidates,
    }),
};
