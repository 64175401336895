import * as z from 'zod';
import { DesignItemExcelOriginsDictionaryRuntype } from '../assembly';
import { DesignItemOriginDTORuntype, QuantityUnitDTORuntype } from '../backendTypes';
import { HistoryRecordResponseRuntype } from '../changeHistory';
import { endpoint } from '../http/endpoint';
import { AddPartEventsRuntype, PartAlternativeRuntype, PartSpecificationRuntype } from '../part';
import { DesignItemPartAlternativesRuntype, DesignItemResponseDTONewRuntype } from './designItemBackendTypes';

export const designItemEndpoints = {
    'POST /design-items/bulk': endpoint({
        description: 'Fetches a list of design items given their ID',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.object({ ids: z.array(z.string()) }),
        responseBody: z.object({
            items: z.array(DesignItemResponseDTONewRuntype),
            excel_lines: DesignItemExcelOriginsDictionaryRuntype,
        }),
    }),
    'POST /design-items/many': endpoint({
        description: 'Creates many design items',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.object({
            inputs: z.array(
                z.object({
                    designator: z.string(),
                    assembly: z.string(),
                    quantity: QuantityUnitDTORuntype.nullable(),
                    notes: z.string(),
                    do_not_place: z.boolean(),
                    part_specification: PartSpecificationRuntype.nullable(),
                    origin: DesignItemOriginDTORuntype.nullable(),
                    is_consigned: z.boolean(),
                    aggregation_key: z.string().nullable(),
                    ignore_package_name_mismatch: z.boolean(),
                }),
            ),
        }),
        responseBody: z.object({
            items: z.array(z.string()),
        }),
    }),
    'PATCH /design-items/bulk': endpoint({
        description: 'Updates all the design items with the given IDs',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.object({
            do_not_place: z.boolean().optional(),
            ids: z.array(z.string()),
            notes: z.string().optional(),
            part_specification: PartSpecificationRuntype.nullable(),
            quantity: z.union([QuantityUnitDTORuntype, z.null()]).optional(),
            is_consigned: z.boolean().optional(),
            ignore_package_name_mismatch: z.boolean().optional(),
            add_part_events: z.array(AddPartEventsRuntype),
        }),
        responseBody: z.object({
            items: z.array(z.string()),
        }),
    }),
    'POST /design-items/bulk-delete': endpoint({
        description: `Deletes all the design items with the given IDs. We are using a POST request instead of delete because the list of IDs 
        can be very long in the query parameters and we don't want to hit the URL limit. If the URL is too long, the request won't be sent out by the browser. https://luminovo.slack.com/archives/C0212SLDS3D/p1695114671371699`,
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.object({
            ids: z.array(z.string()),
        }),
        responseBody: z.object({
            deleted: z.number(),
        }),
        invalidates: [
            'GET /assemblies/:assemblyId/descendants',
            'GET /assemblies/:assemblyId/descendants-summary',
            'GET /assemblies/:id/history',
        ],
    }),
    'GET /design-items/:designItemId/part-alternatives': endpoint({
        description:
            'Get part alternatives for a design item (and a "bom item" also, since all design items in a bom item have the same part alternatives)',
        pathParams: z.object({ designItemId: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.array(PartAlternativeRuntype),
    }),
    'POST /design-items/part-alternatives/bulk': endpoint({
        description: 'Fetches design-item part alternatives based off the part options',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.object({
            ids: z.array(z.string()),
        }),
        responseBody: z.object({
            items: z.array(DesignItemPartAlternativesRuntype),
        }),
    }),
    'POST /design-items/history': endpoint({
        description: 'Fetches the history of a design item',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.object({
            ids: z.array(z.string()),
        }),
        responseBody: z.array(HistoryRecordResponseRuntype),
    }),
};
