import * as z from 'zod';

export interface ManufacturerDTO extends z.infer<typeof ManufacturerDTORuntype> {}
export const ManufacturerDTORuntype = z.object({
    alternative_names: z.array(z.string()),
    id: z.string(),
    name: z.string(),
});

export interface ManufacturerWithPartCountDTO extends z.infer<typeof ManufacturerWithPartCountDTORuntype> {}
export const ManufacturerWithPartCountDTORuntype = ManufacturerDTORuntype.extend({
    part_count: z.number(),
});

export interface ManufacturerLineCardDTO extends z.infer<typeof ManufacturerLineCardDTORuntype> {}

export const ManufacturerLineCardDTORuntype = z.object({
    manufacturer_id: z.string(),
    supported_by: z.array(z.string()),
});
