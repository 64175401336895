import * as z from 'zod';

export enum ConsentType {
    /**
     * Cookies that are required for the app to work. The user
     * must agree to use these.
     */
    required = 'required',
    analytics = 'analytics',
    support = 'support',
}

export const ConsentTypeRuntype = z.nativeEnum(ConsentType);
