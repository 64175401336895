import { logToExternalErrorHandlers } from '@luminovo/commons';
import { RegisteredHttpEndpoint } from '@luminovo/http-client';
import React from 'react';
import { PageLayout } from '../../components/PageLayout';
import { ResourceNotFound } from './NotFoundCard';

interface NotFoundPageProps {
    endpoint?: RegisteredHttpEndpoint | undefined;
}

const NotFoundPage: React.FunctionComponent<NotFoundPageProps> = ({ endpoint }: NotFoundPageProps): JSX.Element => {
    React.useEffect(() => {
        logToExternalErrorHandlers(new Error(`in-app route not found: ${window.location.href}`));
    }, []);

    return (
        <PageLayout layout="centered">
            <ResourceNotFound endpoint={endpoint} />
        </PageLayout>
    );
};

export default NotFoundPage;
