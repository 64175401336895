import * as z from 'zod';
import { LanguageEnumRuntype } from '../../utils/i18n';
import { MonetaryValueBackendRuntype, QuantityUnitDTORuntype } from '../backendTypes';
import { AvailabilityRuntype, PackagingRuntype } from '../offer';

export type LineItemStatus = z.infer<typeof PurchaseOrderStatusRuntype>;
export const LineItemStatusRuntype = z.enum([
    'waiting_for_approval',
    'rejected',
    'approved',
    'sent',
    'canceled',
    'received',
]);

export type PurchaseOrderStatus = z.infer<typeof PurchaseOrderStatusRuntype>;
export const PurchaseOrderStatusRuntype = z.union([LineItemStatusRuntype, z.literal('partially_received')]);

const ConsignedPart = z.union([
    z.object({
        type: z.literal('OffTheShelf'),
        part: z.string(),
    }),
    z.object({
        type: z.literal('Custom'),
        part: z.string(),
    }),
    z.object({
        type: z.literal('CustomComponent'),
        part: z.string(),
    }),
    z.object({
        type: z.literal('Ipn'),
        part: z.string(),
    }),
    z.object({
        type: z.literal('Generic'),
        part: z.string(),
    }),
]);

const PurchaseOrderLinkedPartRuntype = z.union([
    z.object({
        type: z.literal('OffTheShelf'),
        part: z.string(),
    }),
    z.object({
        type: z.literal('Custom'),
        part: z.string(),
    }),
    z.object({
        type: z.literal('CustomComponent'),
        part: z.string(),
    }),
    z.object({
        type: z.literal('Ipn'),
        part: z.string(),
    }),
    z.object({
        type: z.literal('Consigned'),
        part_options: z.array(ConsignedPart),
    }),
]);

const BaseLineItem = {
    linked_part: PurchaseOrderLinkedPartRuntype,
    quantity: QuantityUnitDTORuntype,
    expected_unit_price: MonetaryValueBackendRuntype,
    total_price: MonetaryValueBackendRuntype,
    availability: AvailabilityRuntype.nullable(),
    packaging: PackagingRuntype.nullable(),
    /**
     * If true, the line item will be excluded from the PO
     * This field is placed here for convenience, it's not part of the backend.
     */
    included: z.boolean().optional(),
    offer_created_at: z.string().nullable().optional(),
    received_at: z.string().nullable().optional(),
    offer_number: z.string().nullable().optional(),
    ipns: z.array(z.string()).nullable().optional(),
    /**
     * The ID of the solution configuration that was used to create this line item.
     */
    solution_config_id: z.string(),
};

export type LineItemDTO = z.infer<typeof LineItemRuntype>;
export const LineItemRuntype = z.object({
    id: z.string(),
    ...BaseLineItem,
});

export type PurchaseOrderRecipient = z.infer<typeof PurchaseOrderRecipientRuntype>;
export const PurchaseOrderRecipientRuntype = z.union([
    z.object({
        type: z.literal('Supplier'),
        supplier_and_stock_location_id: z.string(),
        supplier_contact: z.string().nullable(),
    }),
    z.object({
        type: z.literal('Internal'),
        inventory_id: z.string(),
        internal_user: z.string().nullable(),
    }),
    z.object({
        type: z.literal('Customer'),
        user: z.string().nullable(),
    }),
    z.object({
        type: z.literal('Unknown'),
    }),
]);

/**
 * A subset of the UserDTO, containing only the fields that are relevant for the purchase order.
 */
export type PurchaseOrderUserDTO = z.infer<typeof PurchaseOrderUserRuntype>;
export const PurchaseOrderUserRuntype = z.object({
    id: z.string(),
    email: z.string(),
    first_name: z.string(),
    last_name: z.string(),
    user_language: LanguageEnumRuntype.optional(),
});

export interface PurchasingSupplierContactDTO extends z.infer<typeof PurchasingSupplierContactDTORuntype> {}
export const PurchasingSupplierContactDTORuntype = z.object({
    id: z.string(),
    supplier_and_stock_location: z.string(),
    first_name: z.string(),
    last_name: z.string(),
    email: z.string(),
    user_language: LanguageEnumRuntype,
    position: z.string().nullable(),
    is_main_contact: z.boolean(),
    is_public: z.boolean(),
});

export type PendingPurchaseOrderRecipient = z.infer<typeof PurchaseOrderRecipientRuntype>;
export const PendingPurchaseOrderRecipientRuntype = z.union([
    z.object({
        type: z.literal('Supplier'),
        supplier_and_stock_location_id: z.string(),
        supplier_contact: PurchasingSupplierContactDTORuntype.nullable(),
    }),
    z.object({
        type: z.literal('Internal'),
        inventory_id: z.string(),
        internal_user: PurchaseOrderUserRuntype.nullable(),
    }),
    z.object({
        type: z.literal('Customer'),
        user: PurchaseOrderUserRuntype.nullable(),
    }),
    z.object({
        type: z.literal('Unknown'),
    }),
]);

const BasePurchaseOrderRuntype = {
    id: z.string(),
    shipping_address: z.string(),
    billing_address: z.string(),
    supplier_purchase_order_number: z.string().nullable().optional(),
    notes: z.string().nullable().optional(),
    line_items: z.array(LineItemRuntype),
    // Initially set to null, user can set it to a value
    shipping_costs: MonetaryValueBackendRuntype.nullable().optional(),
    purchase_order_number: z.string().nullable().optional(),
    sourcing_scenario_id: z.string(),
    approved_at: z.string().nullable().optional(),
    expected_delivery_at: z.string().nullable().optional(),
    rfq_id: z.string(),
};

export type PurchaseOrderDTO = z.infer<typeof PurchaseOrderRuntype>;
export const PurchaseOrderRuntype = z.object({
    ...BasePurchaseOrderRuntype,
    recipient: PurchaseOrderRecipientRuntype,
    created_at: z.string(),
    sent_at: z.string().nullable(),
});

export type DispatchMethodDTO = z.infer<typeof DispatchMethodRuntype>;
export const DispatchMethodRuntype = z.union([
    z.object({
        type: z.literal('Email'),
        subject: z.string(),
        body: z.string(),
    }),
    z.object({
        type: z.literal('None'),
    }),
]);

export type PendingPurchaseOrderDTO = z.infer<typeof PendingPurchaseOrderRuntype>;
export const PendingPurchaseOrderRuntype = z.object({
    ...BasePurchaseOrderRuntype,
    recipient: PendingPurchaseOrderRecipientRuntype,
    dispatch_method: DispatchMethodRuntype,
});

export type PendingPurchaseOrderDraft = z.infer<typeof PendingPurchaseOrderDraftRuntype>;
export const PendingPurchaseOrderDraftRuntype = z.object({
    recipient: PendingPurchaseOrderRecipientRuntype,
    line_items: z.array(z.object(BaseLineItem)),
});
