import { t } from '@lingui/macro';
import { assertPresent } from '@luminovo/commons';
import { TableCell, TableRow } from '@mui/material';
import { Table } from '@tanstack/react-table';
import React, { ReactNode } from 'react';
import { colorSystem } from '../../theme';
import { Text } from '../Text';
import { CenteredLayout } from '../layout/CenteredLayout';

export function getFixedFooterContent<TData>({ table }: { table: Table<TData> }): ReactNode {
    const { isFetchingNextPage, getIsLoading } = assertPresent(table.options.meta);

    if (!isFetchingNextPage || getIsLoading()) {
        return null;
    }

    return (
        <TableRow style={{ borderColor: colorSystem.neutral.white }}>
            <TableCell
                colSpan={table.getAllLeafColumns().filter((c) => c.getIsVisible()).length}
                style={{ backgroundColor: colorSystem.neutral.white }}
            >
                <CenteredLayout style={{ height: '100%', paddingBottom: '6px', paddingTop: '8px' }}>
                    <LoadingText />
                </CenteredLayout>
            </TableCell>
        </TableRow>
    );
}

const LoadingText: React.FunctionComponent = () => {
    const [loadingText, setLoadingText] = React.useState(t`Loading more`);

    React.useEffect(() => {
        const intervalId = setInterval(() => {
            setLoadingText((prev) => {
                if (prev.endsWith('...')) {
                    return t`Loading more`;
                }
                return prev + '.';
            });
        }, 500);

        return () => clearInterval(intervalId);
    }, []);

    return (
        <Text variant={'h5'} style={{ width: '100px' }}>
            {loadingText}
        </Text>
    );
};
