import { plural, t, Trans } from '@lingui/macro';
import { assertUnreachable } from '@luminovo/commons';
import {
    DestructivePrimaryButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    SecondaryButton,
    Text,
} from '@luminovo/design-system';
import {
    FullPart,
    isCustomComponentFull,
    isCustomFullPart,
    isGenericFullPart,
    isOtsComponentFull,
    isOtsFullPart,
    PreferenceStatusEnum,
} from '@luminovo/http-client';
import { styled } from '@mui/material';
import { useDialogContext } from '../../../../components/contexts/ModalContext';

export interface BlockedManufacturersDialogProps {
    partsWithBlockedManufacturers: FullPart[];
    onConfirm: (e: any) => void;
}

type BlockedManufacturersDialogType =
    | {
          shouldOpenBlockedManufacturersDialog: false;
      }
    | {
          shouldOpenBlockedManufacturersDialog: true;
          partsWithBlockedManufacturers: FullPart[];
      };

function hasBlockedManufacturers({ fullPart }: { fullPart: FullPart }) {
    if (isCustomFullPart(fullPart) || isGenericFullPart(fullPart) || isCustomComponentFull(fullPart)) {
        return false;
    }
    if (isOtsFullPart(fullPart)) {
        return fullPart.preference_status === PreferenceStatusEnum.Blocked;
    }
    if (isOtsComponentFull(fullPart)) {
        return fullPart.manufacturer_preference_status === PreferenceStatusEnum.Blocked;
    }
    assertUnreachable(fullPart);
}

export const blockedManufacturersDialog = ({
    fullParts,
}: {
    fullParts: FullPart[];
}): BlockedManufacturersDialogType => {
    const partsWithBlockedManufacturers = fullParts.filter((fullPart) => hasBlockedManufacturers({ fullPart }));

    if (partsWithBlockedManufacturers.length === 0) {
        return { shouldOpenBlockedManufacturersDialog: false };
    }
    return { shouldOpenBlockedManufacturersDialog: true, partsWithBlockedManufacturers };
};

export function useAddPartWithBlockedManufacturerDialog() {
    const { setDialog, closeDialog } = useDialogContext();

    return {
        openDialog: ({ partsWithBlockedManufacturers, onConfirm }: BlockedManufacturersDialogProps) =>
            setDialog(
                <Dialog maxWidth="xs" open={true} onClose={closeDialog}>
                    <DialogTitle title={t`Blocked manufacturer`} onClose={closeDialog} />
                    <DialogContent>
                        <Text>
                            {plural(partsWithBlockedManufacturers.length, {
                                one: t`Are you sure you want to add the part below from a blocked manufacturer? You can change the manufacturer preferences in the settings.`,
                                other: t`Are you sure you want to add the parts below from blocked manufacturers? You can change the manufacturer preferences in the settings.`,
                            })}
                        </Text>
                        <List>
                            {partsWithBlockedManufacturers.map((part) => {
                                if (isOtsFullPart(part)) {
                                    return <li key={part.id}>{t`${part.mpn} from ${part.manufacturer.name}`}</li>;
                                }
                                if (isOtsComponentFull(part)) {
                                    const manufacturerName =
                                        part.matches.reduce<string[]>((acc, { part }) => {
                                            if (
                                                part.type === 'OffTheShelf' &&
                                                part.data.preference_status === PreferenceStatusEnum.Blocked
                                            ) {
                                                acc.push(part.data.manufacturer.name);
                                            }
                                            return acc;
                                        }, [])[0] ?? '';

                                    return (
                                        <li key={part.id}>
                                            {manufacturerName.length > 0
                                                ? t`${part.id} from ${manufacturerName}`
                                                : part.id}
                                        </li>
                                    );
                                }
                                return null;
                            })}
                        </List>
                    </DialogContent>
                    <DialogActions>
                        <SecondaryButton autoFocus size="medium" onClick={closeDialog}>
                            <Trans>No</Trans>
                        </SecondaryButton>
                        <DestructivePrimaryButton
                            size="medium"
                            onClick={(e) => {
                                closeDialog();
                                onConfirm(e);
                            }}
                        >
                            <Trans>Add part</Trans>
                        </DestructivePrimaryButton>
                    </DialogActions>
                </Dialog>,
            ),
    };
}

const List = styled('ul')({
    margin: '4px',
    padding: '12px',
});
