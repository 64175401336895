import { http } from '../../../resources/http';
import {
    SupplierAndStockLocationWithPreferenceDTO,
    SupplierPreference,
    SupplierTag,
} from '../../../resources/supplierAndStockLocation/supplierAndStockLocationBackendTypes';

export async function getFirstOTSApprovedSupplierAndStockLocation(
    token: string,
): Promise<SupplierAndStockLocationWithPreferenceDTO> {
    const response = await http('GET /organization-settings/organization-solution-preference', {}, token);
    const approvedSuppliers = response.data.approved_suppliers_and_stock_locations;
    if (!approvedSuppliers) {
        throw new Error('No approved suppliers found in organization');
    }
    if (approvedSuppliers.length === 0) {
        throw new Error('No approved suppliers found in organization');
    }
    const suppliers = await http('GET /suppliers-and-stock-locations', {}, token);
    const supplierAndStockLocation = suppliers.data.find(
        (s) =>
            s.tags.some((tag) => tag.tag === SupplierTag.OffTheShelfPartSupplier) && approvedSuppliers.includes(s.id),
    );
    if (!supplierAndStockLocation) {
        throw new Error('No approved suppliers found in organization');
    }

    return {
        ...supplierAndStockLocation,
        preference: SupplierPreference.Approved,
    };
}
