import * as z from 'zod';
import { RegionsEnum } from '../supplierAndStockLocation';

export interface PCBCredentialsDTO extends z.infer<typeof PCBCredentialsDTORuntype> {}

export type WuerthRegion = z.infer<typeof WuerthRegionRuntype>;
export const WuerthRegionRuntype = z.enum([
    RegionsEnum.Germany,
    RegionsEnum.France,
    RegionsEnum.Spain,
    RegionsEnum.UnitedKingdomOfGreatBritainAndNorthernIreland,
    RegionsEnum.Switzerland,
    RegionsEnum.Unknown,
]);

export const PCBCredentialsDTORuntype = z.object({
    username: z.string(),
    region: WuerthRegionRuntype.nullable(),
    is_valid: z.boolean(),
    error: z.string().nullable(),
});

export interface UserPasswordPCBCredentialsInputDTO extends z.infer<typeof UserPasswordCredentialsInputDTORuntype> {}

export const UserPasswordCredentialsInputDTORuntype = z.object({
    username: z.string(),
    password: z.string(),
});
