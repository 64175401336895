import { t } from '@lingui/macro';
import { Chip, colorSystem, Flexbox, Text } from '@luminovo/design-system';
import { SolutionStatus, SolutionTag } from '@luminovo/http-client';
import React from 'react';
import { extractSolutionStatus, extractSolutionTagChips, SolutionTagChip } from '../../extractors';
import { formatSolutionStatus } from '../../formatters';
import { Solution, SolutionErrorTags, SolutionWarningTags } from '../../types';
import { SolutionStatusCircleIcon } from './SolutionStatusIcon';

export const SolutionStatusInformation: React.FunctionComponent<{
    solution: Pick<Solution, 'solutionTags'>;
}> = ({ solution }) => {
    const chips = extractSolutionTagChips(solution);

    const effectiveStatus = extractSolutionStatus(solution, 'effective');
    const originalStatus = extractSolutionStatus(solution, 'original');

    return (
        <SolutionStatusInformationInternal
            effectiveStatus={effectiveStatus}
            originalStatus={originalStatus}
            chips={chips}
        />
    );
};

export const SolutionStatusInformationInternal: React.FunctionComponent<{
    effectiveStatus: SolutionStatus;
    originalStatus: SolutionStatus;
    chips: SolutionTagChip[];
}> = ({ effectiveStatus, originalStatus, chips }) => {
    const filteredChips = chips.filter(
        (c) =>
            SolutionWarningTags.includes(c.solutionTag) ||
            SolutionErrorTags.includes(c.solutionTag) ||
            c.solutionTag === SolutionTag.NonCancellableNonReturnable,
    );

    return (
        <Flexbox flexDirection={'column'} gap={8} padding={'4px'}>
            <Flexbox gap={4} alignItems={'center'}>
                <SolutionStatusCircleIcon status={effectiveStatus} />
                <Text variant="h5">{formatSolutionStatus(effectiveStatus)}</Text>
            </Flexbox>

            {effectiveStatus !== originalStatus && (
                <Text variant={'caption'} color={colorSystem.neutral[7]}>
                    {t`Manually changed from ${formatSolutionStatus(originalStatus)}`}
                </Text>
            )}

            {filteredChips.length !== 0 && (
                <Flexbox gap={4} flexWrap={'wrap'}>
                    {filteredChips.map((c, idx) => (
                        <Chip key={idx} color={c.color} label={c.label} />
                    ))}
                </Flexbox>
            )}
        </Flexbox>
    );
};
