import * as z from 'zod';
import { UserDriverDetailsRuntype } from './driverDetailsBackendTypes';

export const UserDriverRuntype = z.object({
    type: z.literal('User'),
    data: UserDriverDetailsRuntype,
});

export type UserDriverDTO = z.infer<typeof UserDriverRuntype>;

export const SystemDriverNameRuntype = z.union([
    z.literal('pcb_width'),
    z.literal('pcb_height'),
    z.literal('pcb_thickness'),
    z.literal('panel_factor'),
    z.literal('panel_width'),
    z.literal('panel_height'),
    z.literal('Sides_of_placement'),
]);

export type SystemDriverName = z.infer<typeof SystemDriverNameRuntype>;

export const SystemDriverRuntype = z.object({
    type: z.literal('System'),
    data: SystemDriverNameRuntype,
});

export const DriverRuntype = z.union([UserDriverRuntype, SystemDriverRuntype]);
export type DriverDTO = z.infer<typeof DriverRuntype>;
