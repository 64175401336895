import * as z from 'zod';
import { endpoint } from '../http/endpoint';
import { BuerklinCredentialsInputDTORuntype, BuerklinResponseBodyDTORuntype } from './buerklinBackendTypes';

export const buerklinEndpoints = {
    'GET /3rdparty/buerklin/credentials': endpoint({
        description: 'Returns credentials for buerklin integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: BuerklinResponseBodyDTORuntype,
    }),
    'POST /3rdparty/buerklin/credentials': endpoint({
        description: 'Uploads credentials for buerklin API integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: BuerklinCredentialsInputDTORuntype,
        responseBody: BuerklinResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/buerklin/credentials'],
    }),
    'PATCH /3rdparty/buerklin/credentials': endpoint({
        description: 'Updates credentials for buerklin API integratoin',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: BuerklinCredentialsInputDTORuntype,
        responseBody: BuerklinResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/buerklin/credentials'],
    }),
    'DELETE /3rdparty/buerklin/credentials': endpoint({
        description: 'Deletes existing credentials for buerklin API integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({ deleted: z.number() }),
        invalidates: ['GET /3rdparty/buerklin/credentials'],
    }),
};
