import { t, Trans } from '@lingui/macro';
import { logToExternalErrorHandlers } from '@luminovo/commons';
import { ErrorOutlineRounded } from '@mui/icons-material';
import React from 'react';
import { Flexbox } from '../Flexbox';
import { NonIdealState } from '../NonIdealState';
import { PrimaryButton, SecondaryButton } from '../buttons';
import { CenteredLayout } from '../layout/CenteredLayout';

type FallbackProps = {
    error: unknown;
    resetError?: () => void;
    onReset: () => void;
};

export const DefaultFallbackComponent: React.FunctionComponent<FallbackProps> = ({ error, resetError, onReset }) => {
    // Log the error to external handlers
    logToExternalErrorHandlers(error instanceof Error ? error : new Error(String(error)));

    // Get the error message safely
    const errorMessage =
        error instanceof Error ? error.message : t`An unexpected error occurred. Please try again or reload the page.`;

    // Use resetError from Sentry or resetErrorBoundary for backward compatibility
    const handleReset = () => {
        onReset();
        resetError?.();
    };

    return (
        <CenteredLayout>
            <NonIdealState
                Icon={ErrorOutlineRounded}
                title={t`Oops! Something went wrong.`}
                description={errorMessage}
                overrides={{
                    ActionButton: () => (
                        <Flexbox gap={'8px'}>
                            <SecondaryButton size="small" onClick={() => handleReset()}>
                                <Trans>Try again</Trans>
                            </SecondaryButton>
                            <PrimaryButton
                                size="small"
                                onClick={() => {
                                    handleReset();
                                    window.location.reload();
                                }}
                            >
                                <Trans>Reload the page</Trans>
                            </PrimaryButton>
                        </Flexbox>
                    ),
                }}
            />
        </CenteredLayout>
    );
};
