import { leadTimeUnitTranslations } from '@/modules/Negotiations/i18n';
import { monetaryValue } from '@/modules/Negotiations/model/monetaryValueMath';
import { useGlobalCurrency } from '@/resources/organizationSettings/currencySettingsHandler';
import { t, Trans } from '@lingui/macro';
import {
    formatDecimal,
    formatMonetaryValue,
    formatToLongDate,
    isPresent,
    MonetaryValue,
    transEnum,
} from '@luminovo/commons';
import {
    Checkbox,
    colorSystem,
    DestructiveSecondaryIconButton,
    FieldCheckbox,
    FieldDate,
    FieldMonetaryValue,
    FieldNumeric,
    FieldSelect,
    FieldText,
    Flexbox,
    SecondaryIconButton,
    Text,
    Tooltip,
} from '@luminovo/design-system';
import { Field, Validator } from '@luminovo/fields';
import {
    ItemClass,
    ItemClassEnumRunType,
    MonetaryValueBackendRuntype,
    OneTimeCostDTO,
    OneTimeCostDTORuntype,
    Packaging,
    PackagingRuntype,
    QuantityUnitDTO,
    QuantityUnitDTORuntype,
} from '@luminovo/http-client';
import {
    FieldSelectCurrency,
    formatPackaging,
    formatQuantity,
    itemClassTranslations,
    LeadTimeUnit,
} from '@luminovo/sourcing-core';
import { Add, Delete, Notes } from '@mui/icons-material';
import { Box, InputAdornment } from '@mui/material';
import * as z from 'zod';

function formatString(x: string | undefined) {
    return x ?? '-';
}

function withRuntype<T>(type: z.ZodType<T>) {
    return (x: unknown): T | undefined => (isPresent(x) ? type.parse(x) : undefined);
}

const maxLength =
    (max: number): Validator<string> =>
    (x) =>
        x === undefined || x.length <= max
            ? { status: 'success' }
            : { status: 'error', message: t`Must be less than ${max} characters` };

const number =
    ({ min, max }: { min?: number; max?: number }): Validator<number> =>
    (x) => {
        if (x === undefined) {
            return { status: 'success' };
        }

        if (isNaN(x)) {
            return { status: 'error', message: t`Must be a number` };
        }
        if (!isFinite(x)) {
            return { status: 'error', message: t`Must be a finite number` };
        }

        if (min !== undefined && x < min) {
            return { status: 'error', message: t`Must be greater than ${min}` };
        }
        if (max !== undefined && x > max) {
            return { status: 'error', message: t`Must be less than ${max}` };
        }

        return { status: 'success' };
    };

const alwaysOk =
    <T,>(): Validator<T> =>
    () => ({ status: 'success' });

const todo =
    <T,>(): Validator<T> =>
    () => ({ status: 'warning', message: `Validator not implemented yet` });

const localDate: Validator<string> = (date) => {
    if (!date) return { status: 'success' };
    if (!REGEX_LOCAL_DATE.test(date)) return { status: 'error', message: t`Invalid date: ${date}` };
    return { status: 'success' };
};

const validators = {
    alwaysOk,
    todo,
    maxLength,
    number,
    localDate,
};

const moq: Field<number> = {
    id: 'moq',
    label: () => t`MOQ`,
    description: () => t`The minimum order quantity`,
    type: withRuntype(z.number()),
    mapValue: (x) => x ?? 1,
    kind: 'number',
    validate: validators.number({ min: 1 }),
    formatter: formatDecimal,
    inputField: (props) => <FieldNumeric placeholder={t`MOQ`} autoFocus {...props} />,
};

const quantity: Field<number> = {
    id: 'quantity',
    label: () => t`Quantity`,
    type: withRuntype(z.number()),
    mapValue: (x) => x ?? 1,
    kind: 'number',
    validate: validators.number({ min: 1 }),
    formatter: formatDecimal,
    inputField: (props) => <FieldNumeric placeholder={t`Quantity`} autoFocus {...props} />,
};

const potentialQuantity: Field<QuantityUnitDTO> = {
    id: 'potentialQuantity',
    label: () => t`Potential Qty`,
    kind: 'custom',
    description: () =>
        t`If the same part is approved in different IPNs or different BOMs, we add up all the required quantities for that part for you and show it as the potential quantity the supplier could get awarded if they provide a good offer for this part.`,
    type: withRuntype(QuantityUnitDTORuntype),
    mapValue: (x) => x ?? { quantity: 0, unit: 'Pieces' },
    validate: (x) => {
        if (x === undefined) {
            return { status: 'success' };
        }
        return { status: 'success' };
    },
    formatter: (qty) => (qty ? formatQuantity(qty, { showPiecesUnit: false }) : '-'),
};

const mpq: Field<number> = {
    id: 'mpq',
    label: () => t`MPQ`,
    description: () => `Minimum package quantity`,
    type: withRuntype(z.number()),
    mapValue: (x) => x ?? 1,
    kind: 'number',
    validate: validators.number({ min: 1 }),
    formatter: formatDecimal,
    inputField: (props) => <FieldNumeric placeholder={t`MPQ`} autoFocus {...props} />,
};

const unitPrice: Field<MonetaryValue> = {
    id: 'unitPrice',
    label: () => t`Unit price`,
    description: () =>
        t`The "unit price" column represents the cost of the number of units specified in the "price per" column.`,
    type: withRuntype(MonetaryValueBackendRuntype),
    formatter: (x) => formatMonetaryValue(x, 'unit-price', { ifAbsent: '-' }),
    kind: 'monetary-value',
    validate: (x) => {
        if (x === undefined) {
            return { status: 'error', message: t`Required` };
        }
        const value = parseFloat(x.amount);
        if (!isFinite(value)) {
            return { status: 'error', message: t`Must be a number` };
        }
        if (isNaN(value)) {
            return { status: 'error', message: t`Must be a number` };
        }
        if (value < 0) {
            return { status: 'error', message: t`Must be positive` };
        }
        if (value === 0) {
            return { status: 'warning', message: t`Zero price` };
        }

        return { status: 'success' };
    },
    inputField: function InputField({ value, onChange, onBlur }) {
        const { preferredCurrency } = useGlobalCurrency();
        const amount = value === null ? null : parseFloat(value.amount);
        const currency = value?.currency ?? preferredCurrency;
        return (
            <Box sx={{ display: 'flex', gap: 1 }}>
                <FieldNumeric
                    placeholder={t`Unit price`}
                    onBlur={onBlur}
                    onChange={(newAmount) => onChange({ amount: String(newAmount ?? '0'), currency })}
                    autoFocus={true}
                    value={amount}
                />
                <FieldSelectCurrency
                    placeholder={t`Currency`}
                    onBlur={onBlur}
                    value={currency}
                    onChange={(newCurrency) =>
                        onChange({ amount: String(amount ?? '0'), currency: newCurrency ?? preferredCurrency })
                    }
                />
            </Box>
        );
    },
};

const totalPrice: Field<MonetaryValue> = {
    id: 'totalPrice',
    label: () => t`Total price`,
    description: () => t`The unit price multiplied by the required quantity`,
    type: withRuntype(MonetaryValueBackendRuntype),
    formatter: (x) => formatMonetaryValue(x, 'default', { ifAbsent: '-' }),
    kind: 'monetary-value',
    validate: (x) => {
        if (x === undefined) {
            return { status: 'error', message: t`Required` };
        }
        const value = parseFloat(x.amount);
        if (!isFinite(value)) {
            return { status: 'error', message: t`Must be a number` };
        }
        if (isNaN(value)) {
            return { status: 'error', message: t`Must be a number` };
        }
        if (value < 0) {
            return { status: 'error', message: t`Must be positive` };
        }
        if (value === 0) {
            return { status: 'warning', message: t`Zero price` };
        }

        return { status: 'success' };
    },
};

const pricePer: Field<number> = {
    id: 'pricePer',
    label: () => t`Price per`,
    description: () =>
        `The "price per" column specifies the unit quantity for the "unit price." For instance, if the unit price is 10 and the "price per" is 1000, then the cost is 0.01 per unit.`,
    type: withRuntype(z.number()),
    formatter: formatDecimal,
    // we want to convert both 0 and null to 1
    mapValue: (x) => x || 1,
    kind: 'number',
    validate: validators.number({ min: 1 }),
    inputField: (props) => <FieldNumeric placeholder={t`Price per`} autoFocus {...props} />,
};

const notes: Field<string> = {
    id: 'notes',
    label: () => t`Notes`,
    description: () => t`Can be used to leave a comment about the offer`,
    type: withRuntype(z.string()),
    kind: 'string',
    validate: validators.maxLength(1000),
    formatter: formatString,
    mapValue: (x) => x ?? '',
    inputField: (props) => <FieldText minRows={3} autoFocus {...props} />,
    render: (notes) => {
        if (!notes) {
            return '-';
        }
        return (
            <Tooltip
                variant={'white'}
                title={
                    <Flexbox flexDirection={'column'} gap={4} padding={'4px'}>
                        <Text variant="h5" color={colorSystem.neutral[8]}>
                            <Trans>Notes</Trans>
                        </Text>
                        <Text variant="body-small" color={colorSystem.neutral[8]}>
                            {notes}
                        </Text>
                    </Flexbox>
                }
            >
                <Notes
                    style={{
                        color: colorSystem.neutral[6],
                        border: `1px solid ${colorSystem.neutral[2]}`,
                        borderRadius: '24px',
                        padding: '2px 8px',
                        fontSize: '16px',
                        background: colorSystem.neutral.white,
                    }}
                />
            </Tooltip>
        );
    },
};

const ipn: Field<string> = {
    id: 'ipn',
    label: () => t`IPN`,
    description: () => t`The internal part number`,
    type: withRuntype(z.string()),
    kind: 'string',
    formatter: (x) => x ?? '-',
    validate: validators.alwaysOk(),
    render: (x) => <Text>{x}</Text>,
};

const selected: Field<boolean> = {
    id: 'selected',
    label: () => t`Include`,
    description: () => `Whether to include this item or not`,
    type: withRuntype(z.boolean()),
    formatter: (x) => (x ? t`Yes` : t`No`),
    mapValue: (x) => x ?? false,
    validate: validators.alwaysOk(),
    kind: 'boolean',
    render: (x) => <Checkbox checked={x ?? false} />,
    inputField: (props) => <FieldCheckbox {...props} value={props.value ?? false} />,
};

const ncnr: Field<boolean> = {
    id: 'ncnr',
    label: () => t`NCNR`,
    description: () => `Non-cancellable, non-returnable`,
    type: withRuntype(z.boolean()),
    formatter: (x) => (x ? t`Yes` : t`No`),
    mapValue: (x) => x ?? false,
    validate: validators.alwaysOk(),
    kind: 'boolean',
    render: (x) => <Checkbox checked={x ?? false} />,
    inputField: (props) => <FieldCheckbox {...props} value={props.value ?? false} />,
};

const formatPkg = (x: Packaging | undefined) => formatPackaging(x, { ifUnknown: '-' });
const packaging: Field<Packaging> = {
    id: 'packaging',
    label: () => t`Packaging`,
    description: () => t`Packaging`,
    type: withRuntype(PackagingRuntype),
    kind: 'enum',
    formatter: formatPkg,
    validate: validators.alwaysOk(),
    inputField: (props) => (
        <FieldSelect
            getOptionKey={(x) => x}
            getOptionLabel={formatPkg}
            options={Object.values(Packaging)}
            placeholder={packaging.label()}
            {...props}
        />
    ),
};

const REGEX_LOCAL_DATE = /^\d{4}-\d{2}-\d{2}$/;

const validFrom: Field<string> = {
    id: 'validFrom',
    label: () => t`Valid from`,
    description: () => `Date from which the offer is valid`,
    type: withRuntype(z.string()),
    kind: 'string',
    formatter: (x) => (x ? formatToLongDate(x) : '-'),
    validate: validators.localDate,
    inputField: (props) => <FieldDate placeholder={t`Valid from`} autoFocus {...props} />,
};

const validUntil: Field<string> = {
    id: 'validUntil',
    label: () => t`Valid until`,
    description: () => `Date until which the offer is valid`,
    type: withRuntype(z.string()),
    kind: 'string',
    formatter: (x) => (x ? formatToLongDate(x) : '-'),
    validate: (date: string | undefined, row) => {
        if (!date) {
            return { status: 'success' };
        }
        const localDateValidation = validators.localDate(date, row);
        if (localDateValidation.status !== 'success') {
            return localDateValidation;
        }

        // Compare dates at start of day to avoid timezone issues
        const validUntilDate = new Date(date);
        validUntilDate.setHours(0, 0, 0, 0);
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        if (validUntilDate < today) {
            return { status: 'warning', message: t`This date is in the past` };
        }
        return { status: 'success' };
    },
    inputField: (props) => <FieldDate placeholder={t`Valid until`} autoFocus {...props} />,
};

const formatItemClass = (x: ItemClass | undefined) => (x ? transEnum(x, itemClassTranslations) : '-');
const itemClass: Field<ItemClass> = {
    id: 'itemClass',
    label: () => t`Item class`,
    description: () =>
        t`"Standard" means the supplier has this part listed and sells it to all customers. "Non-standard" means it is usually not listed and only sold to one (or a few customers). The item class usually affects the terms around canceling an order.`,
    type: withRuntype(ItemClassEnumRunType),
    formatter: formatItemClass,
    kind: 'enum',
    validate: validators.alwaysOk(),
    inputField: (props) => (
        <FieldSelect
            getOptionKey={(x) => x}
            getOptionLabel={formatItemClass}
            options={Object.values(ItemClass)}
            placeholder={itemClass.label()}
            {...props}
        />
    ),
};

const supplierPartNumber: Field<string> = {
    id: 'supplierPartNumber',
    label: () => t`Supplier part number`,
    description: () => `Your internal part number for this component`,
    type: withRuntype(z.string()),
    formatter: formatString,
    kind: 'string',
    validate: validators.alwaysOk(),
    inputField: (props) => <FieldText placeholder={t`Supplier part number`} autoFocus {...props} />,
};

const stock: Field<number> = {
    id: 'stock',
    label: () => t`Stock`,
    description: () => `The amount of stock available for this part`,
    type: withRuntype(z.number()),
    formatter: (x) => formatDecimal(x, { ifAbsent: '-' }),
    kind: 'number',
    validate: (x) => {
        if (x === undefined) {
            return { status: 'success' };
        }
        if (x < 0) {
            return { status: 'error', message: t`Stock must be positive` };
        }
        return { status: 'success' };
    },
    inputField: (props) => <FieldNumeric placeholder={t`Stock`} autoFocus {...props} />,
};

const bid: Field<boolean> = {
    id: 'bid',
    label: () => t`Bid`,
    description: () => t`Whether you want to bid on this item`,
    type: withRuntype(z.boolean()),
    formatter: (x) => (x ? t`Yes` : t`No`),
    mapValue: (x) => x ?? false,
    validate: validators.alwaysOk(),
    kind: 'boolean',
    render: (x) => <Checkbox checked={x ?? false} />,
    inputField: (props) => <FieldCheckbox {...props} value={props.value ?? false} />,
};

const cancellationWindow: Field<number> = {
    id: 'cancellationWindow',
    label: () => t`Cancellation window`,
    description: () => t`The time window in which the order can be canceled, starting from the order submission date.`,
    type: withRuntype(z.number()),
    formatter: (x) => formatDecimal(x, { ifAbsent: '-' }),
    kind: 'number',
    validate: (x) => {
        if (x === undefined) {
            return { status: 'success' };
        }
        if (x < 0) {
            return { status: 'error', message: t`Must be a positive number` };
        }
        return { status: 'success' };
    },
    inputField: (props) => <FieldNumeric placeholder={t`Cancellation window`} autoFocus {...props} />,
};

const stdFactoryLeadTime: Field<number> = {
    id: 'stdFactoryLeadTime',
    label: () => t`Std. factory lead time`,
    description: () =>
        t`If you don't have anything in stock and nothing on-order, the lead time to restock from the manufacturer.`,
    type: withRuntype(z.number()),
    formatter: (x) => formatDecimal(x, { ifAbsent: '-' }),
    kind: 'number',
    validate: validators.number({ min: 1 }),
    inputField: (props) => <FieldNumeric placeholder={t`Std. factory lead time`} autoFocus {...props} />,
};

const leadTimeDays: Field<number> = {
    id: 'leadTimeDays',
    label: () => t`Lead time`,
    type: withRuntype(z.number()),
    formatter: (x) => formatDecimal(x, { ifAbsent: '-' }),
    validate: validators.number({ min: 1 }),
    kind: 'number',
    inputField: (props) => (
        <FieldNumeric
            placeholder={t`Lead time`}
            InputProps={{
                endAdornment: <InputAdornment position="end">{t`Days`}</InputAdornment>,
            }}
            autoFocus
            {...props}
        />
    ),
};

const formatLeadTimeUnit = (x: LeadTimeUnit | undefined) => (x ? transEnum(x, leadTimeUnitTranslations) : '-');
const stdFactoryLeadTimeUnit: Field<LeadTimeUnit> = {
    id: 'stdFactoryLeadTimeUnit',
    label: () => t`Std. factory lead time unit`,
    description: () => t`Determines if the lead time is in days, weeks or months`,
    type: withRuntype(z.nativeEnum(LeadTimeUnit)),
    formatter: formatLeadTimeUnit,
    mapValue: (x) => x ?? LeadTimeUnit.Weeks,
    kind: 'enum',
    validate: validators.alwaysOk(),
    inputField: (props) => (
        <FieldSelect
            getOptionKey={(x) => x}
            getOptionLabel={formatLeadTimeUnit}
            options={Object.values(LeadTimeUnit)}
            placeholder={stdFactoryLeadTimeUnit.label()}
            {...props}
        />
    ),
};

const cancellationWindowUnit: Field<LeadTimeUnit> = {
    ...stdFactoryLeadTimeUnit,
    id: 'cancellationWindowUnit',
    label: () => t`Cancellation window unit`,
};

const oneTimeCosts: Field<OneTimeCostDTO[]> = {
    id: 'oneTimeCosts',
    label: () => t`One-time costs`,
    description: () => t`One-time costs`,
    kind: 'custom',
    type: withRuntype(z.array(OneTimeCostDTORuntype)),
    formatter: (oneTimeCosts) => {
        if (!oneTimeCosts || oneTimeCosts.length === 0) {
            return '-';
        }
        return formatMonetaryValue(monetaryValue.sum(oneTimeCosts.map((cost) => cost.price)));
    },
    validate: (oneTimeCosts) => {
        if (!oneTimeCosts || oneTimeCosts.length === 0) {
            return { status: 'success' };
        }

        for (const cost of oneTimeCosts) {
            if (monetaryValue.isNegative(cost.price)) {
                return {
                    status: 'error',
                    message: t`One-time costs must be positive`,
                };
            }
            if (cost.description && cost.description.length > 200) {
                return {
                    status: 'error',
                    message: t`Description is too long`,
                };
            }
        }
        const currencies = new Set(oneTimeCosts.map((cost) => cost.price.currency));
        if (currencies.size > 1) {
            return {
                status: 'error',
                message: t`All one-time costs must use the same currency`,
            };
        }
        return { status: 'success' };
    },
    mapValue: (oneTimeCosts) => (oneTimeCosts ?? []).filter((cost) => Number(cost.price.amount) !== 0),
    inputField: function InputField(props) {
        const { preferredCurrency } = useGlobalCurrency();
        const zero = { amount: '0.00', currency: preferredCurrency };
        const currentOneTimeCosts = props.value ?? [];
        const oneTimeCosts =
            currentOneTimeCosts.length === 0 ? [{ price: zero, description: '' }] : currentOneTimeCosts;

        return (
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
                <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr auto', gap: 1 }}>
                    {oneTimeCosts.map((cost, index) => {
                        return (
                            <>
                                <FieldMonetaryValue
                                    value={cost.price}
                                    onChange={(newCost) => {
                                        const newOneTimeCosts = [...currentOneTimeCosts];
                                        newOneTimeCosts.splice(index, 1, {
                                            ...newOneTimeCosts[index],
                                            price: newCost ?? zero,
                                        });
                                        props.onChange(newOneTimeCosts);
                                    }}
                                    placeholder={formatMonetaryValue(zero)}
                                    autoFocus
                                    defaultCurrency={preferredCurrency}
                                />
                                <FieldText
                                    fullWidth
                                    onChange={(newDescription) => {
                                        const newOneTimeCosts = [...currentOneTimeCosts];
                                        newOneTimeCosts.splice(index, 1, {
                                            ...newOneTimeCosts[index],
                                            description: newDescription ?? '',
                                        });
                                        props.onChange(newOneTimeCosts);
                                    }}
                                    value={cost.description}
                                    placeholder={t`Description`}
                                />
                                {index === oneTimeCosts.length - 1 ? (
                                    <SecondaryIconButton
                                        onClick={() => {
                                            const newOneTimeCosts = [...currentOneTimeCosts];
                                            newOneTimeCosts.push({
                                                price: { amount: '0.00', currency: preferredCurrency },
                                                description: '',
                                            });
                                            props.onChange(newOneTimeCosts);
                                        }}
                                    >
                                        <Add fontSize="inherit" />
                                    </SecondaryIconButton>
                                ) : (
                                    <DestructiveSecondaryIconButton
                                        onClick={() => {
                                            const newOneTimeCosts = [...currentOneTimeCosts];
                                            newOneTimeCosts.splice(index, 1);
                                            props.onChange(newOneTimeCosts);
                                        }}
                                    >
                                        <Delete fontSize="inherit" />
                                    </DestructiveSecondaryIconButton>
                                )}
                            </>
                        );
                    })}
                </Box>
            </Box>
        );
    },
};

export const fields = {
    selected,
    ipn,
    moq,
    mpq,
    quantity,
    potentialQuantity,
    unitPrice,
    totalPrice,
    pricePer,
    notes,
    ncnr,
    packaging,
    validFrom,
    validUntil,
    itemClass,
    supplierPartNumber,
    stock,
    bid,
    cancellationWindow,
    cancellationWindowUnit,
    stdFactoryLeadTime,
    stdFactoryLeadTimeUnit,
    leadTimeDays,
    oneTimeCosts,
};
