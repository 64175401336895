import { useDialogContext } from '@/components/contexts/ModalContext';
import { CancelButton } from '@/components/formLayouts/CancelButton';
import { FormContainer } from '@/components/formLayouts/FormContainer';
import { SubmitButton } from '@/components/formLayouts/SubmitButton';
import { totalCostOfOwnershipScalingDropdownTranslations } from '@/modules/SolutionManager/components/Sidebar/ManualAdditionalCostButton';
import { inputCurrenciesPublicTranslations } from '@/resources/currencyInputTypes';
import { useGlobalCurrency } from '@/resources/organizationSettings/currencySettingsHandler';
import {
    useSourcingCalculation,
    useSourcingScenarioSuspense,
} from '@/resources/sourcingScenario/sourcingScenarioHandlers';
import { t, Trans } from '@lingui/macro';
import {
    assertPresent,
    Currency,
    isPresent,
    isProductionEnvironment,
    MonetaryValue,
    transEnum,
} from '@luminovo/commons';
import {
    CenteredLayout,
    colorSystem,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FieldCheckboxControlled,
    FieldNumericControlled,
    FieldSelectControlled,
    Flexbox,
    MenuButton,
    MenuItem,
    Message,
    RequestViaMailIcon,
    SecondaryButton,
    Table,
    TanStackMenuBar,
    TanStackTable,
    Text,
} from '@luminovo/design-system';
import {
    PartDTO,
    PartIdTypes,
    PartLite,
    PartLiteTypes,
    SolutionSelection,
    SolutionStatus,
    SolutionTag,
    Tag,
    TotalCostOfOwnershipScaling,
} from '@luminovo/http-client';
import {
    CostTag,
    CostType,
    formatSolutionStatus,
    hasSolutionTag,
    SolutionStatusCircleIcon,
} from '@luminovo/sourcing-core';
import { ArrowDropDownRounded, AutoAwesomeRounded, GetApp, LockOutlined } from '@mui/icons-material';
import { Box, CircularProgress, Divider, InputAdornment } from '@mui/material';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useDownloadQuotePriceTemplate } from '../../../../resources/export/exportHandler';
import { useHttpMutation } from '../../../../resources/mutation/useHttpMutation';
import { assertUnreachable } from '../../../../utils/typingUtils';
import { usePersistedState } from '../../../../utils/usePersistedState';
import { chartSpecLeadTime } from '../Charts/ChartLeadTime';
import { ChartSolutionConfiguration } from '../Charts/ChartSolutionConfiguration';
import { chartSpecSuppliers } from '../Charts/ChartSuppliers';
import { chartSpecTotalPrice } from '../Charts/ChartTotalPrice';
import { chartSpecUnitPrice } from '../Charts/ChartUnitPrice';
import { useRequestConfigurationDialog } from '../QuoteExporter/RequestConfigurationDialog';
import { SourcingScenarioSummaryBox } from '../SourcingScenarios/SourcingScenarioSummaryBox';
import { EditScrapQuantityButton } from './EditScrapQuantityButton';
import { useSolutionConfigurationTableState } from './SolutionConfigurationTable';
import {
    FieldId,
    ScrapQuantityHandlers,
    SolutionConfigurationsTableContext,
    SolutionConfigurationTableData,
} from './SolutionConfigurationTypes';
import { SourcingConfigurationContentHeader } from './SourcingConfigurationContentHeader';

const extractPartDTOFromPartLite = (part: PartLite): PartDTO | undefined => {
    const partKind = part.kind;
    const data = part.id;
    switch (partKind) {
        case PartLiteTypes.OffTheShelf:
            return {
                type: PartIdTypes.OffTheShelf,
                data,
            };
        case PartLiteTypes.Custom:
            return {
                type: PartIdTypes.Custom,
                data,
            };
        case PartLiteTypes.CustomComponent:
            return {
                type: PartIdTypes.CustomComponent,
                data,
            };
        case PartLiteTypes.Generic:
            return {
                type: PartIdTypes.Generic,
                data,
            };
        case PartLiteTypes.Ipn:
            return {
                type: PartIdTypes.Ipn,
                data,
            };
        case PartLiteTypes.RawSpecification:
            return {
                type: PartIdTypes.RawSpecification,
                data,
            };
        case PartLiteTypes.Unknown:
            return undefined;
        default:
            assertUnreachable(partKind);
    }
};

const extractSolutionStatus2 = (row: SolutionConfigurationTableData): SolutionStatus => {
    const status = row.solutionConfigurationSummary.originalStatus;
    const type = status.type;
    switch (type) {
        case 'NoApprovedParts':
        case 'NoOffer':
            return SolutionStatus.Error;
        case 'Status':
            return status.data.status;
        default:
            assertUnreachable(type);
    }
};

const ActionButton: React.FunctionComponent<{
    table: Table<SolutionConfigurationTableData>;
    sharedContext: SolutionConfigurationsTableContext;
}> = ({ table, sharedContext }) => {
    const selectedItems = table.getSelectedRowModel().rows.map((row) => row.original);

    const manuallySelectedPartIds = selectedItems
        .flatMap((item) => item.partSummary.approved_part_options)
        .map(extractPartDTOFromPartLite)
        .filter(isPresent);

    const solutionStatuses = selectedItems.map(extractSolutionStatus2);

    const solutionConfigurationsWithOffers = selectedItems
        .filter((row) => row.offerSummary.type === 'Offer')
        .map((row) => row.id);

    const solutionConfigurationIds = table.getRowModel().rows.map((row) => row.original.id);

    const selectedSolutionConfigurations: MultiManualSelectedSolutionConfiguration[] = selectedItems.map((item) => {
        const id = item.id;
        const solutionTags =
            item.solutionConfigurationSummary.originalStatus.type === 'Status'
                ? item.solutionConfigurationSummary.originalStatus.data.tags
                : undefined;
        const hasSolution = isPresent(solutionTags);
        const isConsigned = item.offerSummary.type === 'Consigned';
        const token = item.token ?? undefined;
        const selection = item.solutionConfigurationSummary.selection;

        return {
            id,
            hasSolution,
            isConsigned,
            solutionTags,
            selection,
            token,
        };
    });

    return (
        <ActionButtonInternal
            rfqId={sharedContext.rfqId}
            selectedItemCount={selectedItems.length}
            manuallySelectedPartIds={manuallySelectedPartIds}
            solutionStatuses={solutionStatuses}
            solutionConfigurationsWithOffers={solutionConfigurationsWithOffers}
            solutionConfigurationIds={solutionConfigurationIds}
            scrapQuantityHandlers={sharedContext.scrapQuantityHandlers}
            selectedSolutionConfigurations={selectedSolutionConfigurations}
        />
    );
};

const ActionButtonInternal: React.FunctionComponent<{
    rfqId: string;
    selectedItemCount: number;
    manuallySelectedPartIds: PartDTO[];
    solutionStatuses: SolutionStatus[];
    solutionConfigurationsWithOffers: string[];
    solutionConfigurationIds: string[];
    scrapQuantityHandlers: ScrapQuantityHandlers;
    selectedSolutionConfigurations: MultiManualSelectedSolutionConfiguration[];
}> = ({
    rfqId,
    selectedItemCount,
    manuallySelectedPartIds,
    solutionStatuses,
    solutionConfigurationsWithOffers,
    solutionConfigurationIds,
    scrapQuantityHandlers,
    selectedSolutionConfigurations,
}): JSX.Element => {
    const hasSelectedItems = selectedItemCount > 0;

    return (
        <Flexbox gap={8}>
            {hasSelectedItems && (
                <Flexbox gap={8} alignItems={'center'}>
                    <Text variant="body" color={colorSystem.neutral[6]}>{t`${selectedItemCount} rows selected`}</Text>

                    <RequestQuoteButtons rfqId={rfqId} manuallySelectedPartIds={manuallySelectedPartIds} />

                    {!isProductionEnvironment() && (
                        <>
                            <Divider
                                orientation="vertical"
                                variant="middle"
                                flexItem
                                style={{ marginLeft: '8px', marginRight: '8px' }}
                            />

                            <MultiManuallySelectedAdditionalCostButton
                                solutionConfigurations={selectedSolutionConfigurations}
                            />
                        </>
                    )}

                    <Divider
                        orientation="vertical"
                        variant="middle"
                        flexItem
                        style={{ marginLeft: '8px', marginRight: '8px' }}
                    />

                    <MultiManualSolutionStatusSelector
                        solutionStatuses={solutionStatuses}
                        solutionConfigurationsWithOffer={solutionConfigurationsWithOffers}
                    />

                    <Divider
                        orientation="vertical"
                        variant="middle"
                        flexItem
                        style={{ marginLeft: '8px', marginRight: '8px' }}
                    />

                    <MultiManuallySelectedSolutionSelector solutionConfigurations={selectedSolutionConfigurations} />
                </Flexbox>
            )}

            {!hasSelectedItems && (
                <EditScrapQuantityButton
                    solutionConfigurationIds={solutionConfigurationIds}
                    handlers={scrapQuantityHandlers}
                />
            )}
        </Flexbox>
    );
};

const RequestQuoteButtons: React.FunctionComponent<{
    rfqId: string;
    manuallySelectedPartIds: PartDTO[];
}> = ({ rfqId, manuallySelectedPartIds }): JSX.Element => {
    const { openDialog } = useRequestConfigurationDialog({
        rfqId,
        manuallySelectedPartIds,
    });

    const { mutateAsync: download, isPending: isLoadingFile } = useDownloadQuotePriceTemplate();
    const disabled = isLoadingFile || manuallySelectedPartIds.length === 0;

    return (
        <Flexbox gap={8}>
            <SecondaryButton
                size="medium"
                disabled={disabled}
                onClick={() =>
                    download({ requestBody: { rfq: rfqId, part_ids: manuallySelectedPartIds.map((p) => p.data) } })
                }
                isLoading={isLoadingFile}
                startIcon={<GetApp />}
            >
                <Trans>Download quote template</Trans>
            </SecondaryButton>
            <SecondaryButton
                size="medium"
                disabled={disabled}
                onClick={() => openDialog()}
                startIcon={<RequestViaMailIcon />}
            >
                {t({ id: 'request.quote.cta', message: 'Request quote' })}
            </SecondaryButton>
        </Flexbox>
    );
};

const MultiManualSolutionStatusSelector: React.FunctionComponent<{
    solutionStatuses: SolutionStatus[];
    solutionConfigurationsWithOffer: string[];
}> = ({ solutionStatuses, solutionConfigurationsWithOffer }): JSX.Element => {
    const { mutateAsync, isPending: isLoading } = useHttpMutation('PATCH /solution-configurations', {
        snackbarMessage: t`Status updated`,
    });

    const isAllSameValue = solutionStatuses.every((status) => status === solutionStatuses[0]);

    const handleClick = React.useCallback(
        (manualSolutionStatus: SolutionStatus) => {
            const items = solutionConfigurationsWithOffer.map((id) => {
                return { id, update: { manual_solution_status: manualSolutionStatus } };
            });
            mutateAsync({ requestBody: { items } });
        },
        [solutionConfigurationsWithOffer, mutateAsync],
    );

    return (
        <Flexbox gap={12} alignItems={'center'}>
            <Text variant="h4" color={colorSystem.neutral[7]}>
                <Trans>Status</Trans>
            </Text>

            <MenuButton
                size="medium"
                label={
                    <Flexbox gap={4} alignItems={'center'}>
                        {isAllSameValue && <SolutionStatusCircleIcon status={solutionStatuses[0]} />}
                        {isAllSameValue ? formatSolutionStatus(solutionStatuses[0]) : t`Mixed`}
                        <ArrowDropDownRounded />
                    </Flexbox>
                }
                appearance="secondary"
                disabled={isLoading || solutionConfigurationsWithOffer.length === 0}
                menuProps={{
                    anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
                    transformOrigin: { horizontal: 'right', vertical: -4 },
                }}
            >
                {[SolutionStatus.Good, SolutionStatus.Warning, SolutionStatus.Error].map((manualSolutionStatus) => (
                    <MenuItem
                        key={manualSolutionStatus}
                        startIcon={<SolutionStatusCircleIcon status={manualSolutionStatus} />}
                        onClick={() => handleClick(manualSolutionStatus)}
                        label={formatSolutionStatus(manualSolutionStatus)}
                    />
                ))}
            </MenuButton>
        </Flexbox>
    );
};

type MultiManualSelectedSolutionConfiguration = {
    id: string;
    hasSolution: boolean;
    solutionTags: Tag[] | undefined;
    isConsigned: boolean;
    token: string | undefined;
    selection: SolutionSelection;
};

const MultiManuallySelectedSolutionSelector = ({
    solutionConfigurations,
}: {
    solutionConfigurations: MultiManualSelectedSolutionConfiguration[];
}): JSX.Element => {
    const selections: ('Automatic' | 'Manual')[] = solutionConfigurations
        .flatMap((solutionConfig) => {
            const selection = solutionConfig.selection;
            switch (selection) {
                case SolutionSelection.Unknown:
                    return undefined;
                case SolutionSelection.Manual:
                    return 'Manual';
                case SolutionSelection.Automatic:
                    return 'Automatic';
                default:
                    return assertUnreachable(selection);
            }
        })
        .filter(isPresent);

    const isSelectionEqualForAll = new Set(selections).size === 1;

    const changeableSolutionConfigurations = solutionConfigurations.filter(
        (solutionConfig) => solutionConfig.hasSolution && !solutionConfig.isConsigned,
    );

    const formatSelection = (selection: 'Automatic' | 'Manual'): string => {
        if (!isSelectionEqualForAll) {
            return t`Mixed`;
        }
        return selection === 'Automatic' ? t`Automatic` : t`Manual`;
    };

    const { mutateAsync, isPending: isLoading } = useHttpMutation('PATCH /solution-configurations', {
        snackbarMessage: t`Selection updated`,
    });

    const handleClick = React.useCallback(
        (manualSolutionStatus: 'Automatic' | 'Manual') => {
            const items = changeableSolutionConfigurations.map(({ id, solutionTags, token }) => {
                if (!isPresent(solutionTags)) {
                    return { id, update: {} };
                }

                if (manualSolutionStatus === 'Automatic') {
                    return {
                        id,
                        update: {
                            manually_selected_solution_token: null,
                        },
                    };
                }

                if (manualSolutionStatus === 'Manual' && !hasSolutionTag({ solutionTags }, SolutionTag.Selected)) {
                    return {
                        id,
                        update: { manually_selected_solution_token: token },
                    };
                }

                return { id, update: {} };
            });
            mutateAsync({ requestBody: { items } });
        },
        [changeableSolutionConfigurations, mutateAsync],
    );

    return (
        <Flexbox gap={12} alignItems="center">
            <Text variant="h4" color={colorSystem.neutral[7]}>
                <Trans>Selection</Trans>
            </Text>

            <MenuButton
                size="medium"
                label={
                    <Flexbox gap={4} alignItems="center">
                        {formatSelection(selections[0])}
                        <ArrowDropDownRounded />
                    </Flexbox>
                }
                appearance="secondary"
                disabled={isLoading || changeableSolutionConfigurations.length === 0}
                menuProps={{
                    anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
                    transformOrigin: { horizontal: 'right', vertical: -4 },
                }}
            >
                <MenuItem
                    startIcon={<AutoAwesomeRounded />}
                    label={t`Automatic`}
                    onClick={() => handleClick('Automatic')}
                />
                <MenuItem startIcon={<LockOutlined />} label={t`Manual`} onClick={() => handleClick('Manual')} />
            </MenuButton>
        </Flexbox>
    );
};

type MultiManualAdditionalCostFormState = {
    packaging: {
        cost: MonetaryValue;
        scaling: TotalCostOfOwnershipScaling;
        enabled: boolean;
    };
    discount: {
        cost: MonetaryValue;
        scaling: TotalCostOfOwnershipScaling;
        enabled: boolean;
    };
    shipping: {
        cost: MonetaryValue;
        scaling: TotalCostOfOwnershipScaling;
        enabled: boolean;
    };
    customs: {
        cost: MonetaryValue;
        scaling: TotalCostOfOwnershipScaling;
        enabled: boolean;
    };
    other: {
        cost: MonetaryValue;
        scaling: TotalCostOfOwnershipScaling;
        enabled: boolean;
    };
};

const MultiManualAdditionalCostLine: React.FunctionComponent<{
    type: CostType;
}> = ({ type }): JSX.Element => {
    const { control } = useFormContext<MultiManualAdditionalCostFormState>();
    const additionalCost = useWatch({ control, name: `${type}` });
    const disabled = !additionalCost.enabled;

    return (
        <Box
            display="grid"
            gridTemplateColumns="1fr 1fr 1fr 1fr"
            gap={'4px'}
            paddingBottom={'12px'}
            alignItems="center"
        >
            <Flexbox gap={4} alignItems="center">
                <FieldCheckboxControlled
                    control={control}
                    name={`${type}.enabled`}
                    FieldProps={{
                        size: 'small',
                    }}
                />
                <CostTag costType={type} />
            </Flexbox>
            <FieldNumericControlled
                control={control}
                name={`${type}.cost.amount`}
                required={true}
                min={0}
                isFinite={true}
                FieldProps={{
                    disabled,
                    InputProps: {
                        endAdornment: (
                            <InputAdornment position="end">
                                {transEnum(additionalCost?.cost.currency ?? 'EUR', inputCurrenciesPublicTranslations)}
                            </InputAdornment>
                        ),
                    },
                    fullWidth: true,
                    size: 'small',
                }}
            />
            <FieldSelectControlled
                control={control}
                name={`${type}.cost.currency`}
                required={true}
                FieldProps={{
                    options: Object.values(Currency),
                    getOptionLabel: (option) => transEnum(option, inputCurrenciesPublicTranslations),
                    disableClearable: true,
                    disabled,
                    size: 'small',
                    style: { width: 'fit-content' },
                }}
            />
            <FieldSelectControlled
                control={control}
                name={`${type}.scaling`}
                required={true}
                FieldProps={{
                    options: Object.values(TotalCostOfOwnershipScaling),
                    getOptionLabel: (option) => transEnum(option, totalCostOfOwnershipScalingDropdownTranslations),
                    disableClearable: true,
                    disabled,
                    fullWidth: true,
                    size: 'small',
                }}
            />
        </Box>
    );
};

function MultiManuallySelectedAdditionalCostButton({
    solutionConfigurations,
}: {
    solutionConfigurations: MultiManualSelectedSolutionConfiguration[];
}) {
    const { setDialog, closeDialog } = useDialogContext();
    const { preferredCurrency } = useGlobalCurrency();
    const { mutateAsync } = useHttpMutation('PATCH /solution-configurations', {
        snackbarMessage: t`Status updated`,
        onSuccess: () => closeDialog(),
    });

    const changeableSolutionConfigurations = solutionConfigurations.filter(
        (solutionConfig) => solutionConfig.hasSolution && !solutionConfig.isConsigned,
    );

    const onSubmit = async (formValues: MultiManualAdditionalCostFormState) => {
        const items = changeableSolutionConfigurations.map(({ id, token }) => ({
            id,
            update: {
                manually_selected_solution_token: token,
                manual_total_cost_of_ownership: {
                    packaging_cost: formValues.packaging.enabled ? formValues.packaging : null,
                    discount: formValues.discount.enabled ? formValues.discount : null,
                    shipping_cost: formValues.shipping.enabled ? formValues.shipping : null,
                    customs_cost: formValues.customs.enabled ? formValues.customs : null,
                    other_cost: formValues.other.enabled ? formValues.other : null,
                },
            },
        }));

        await mutateAsync({
            requestBody: { items },
        });
    };

    const defaultValues: MultiManualAdditionalCostFormState = {
        packaging: {
            cost: {
                amount: '0',
                currency: preferredCurrency,
            },
            scaling: TotalCostOfOwnershipScaling.PerUnit,
            enabled: false,
        },
        discount: {
            cost: {
                amount: '0',
                currency: preferredCurrency,
            },
            scaling: TotalCostOfOwnershipScaling.PerUnit,
            enabled: false,
        },
        shipping: {
            cost: {
                amount: '0',
                currency: preferredCurrency,
            },
            scaling: TotalCostOfOwnershipScaling.PerUnit,
            enabled: false,
        },
        customs: {
            cost: {
                amount: '0',
                currency: preferredCurrency,
            },
            scaling: TotalCostOfOwnershipScaling.PerUnit,
            enabled: false,
        },
        other: {
            cost: {
                amount: '0',
                currency: preferredCurrency,
            },
            scaling: TotalCostOfOwnershipScaling.PerUnit,
            enabled: false,
        },
    };

    const openDialog = () => {
        setDialog(
            <Dialog open={true} maxWidth="md" onClose={() => closeDialog()}>
                <DialogTitle title={t`Edit additional costs`} onClose={() => closeDialog()} />
                <FormContainer defaultValues={defaultValues} onSubmit={onSubmit}>
                    <DialogContent>
                        <Message
                            variant="red"
                            title="TCO costs are still in beta!"
                            message="Manual TCO costs override other cost entries."
                            size="small"
                            attention="high"
                        />
                        <Text variant="body" color={colorSystem.neutral[8]} style={{ marginTop: '12px' }}>
                            <Trans>
                                You can manually add extra costs to the selected solutions. The manually added costs
                                will only be applied to this sourcing scenario.
                            </Trans>
                        </Text>
                        <Flexbox overflow={'auto'} flexDirection="column">
                            <MultiManualAdditionalCostLine type={'packaging'} />
                            <MultiManualAdditionalCostLine type={'discount'} />
                            <MultiManualAdditionalCostLine type={'shipping'} />
                            <MultiManualAdditionalCostLine type={'customs'} />
                            <MultiManualAdditionalCostLine type={'other'} />
                        </Flexbox>
                    </DialogContent>
                    <DialogActions>
                        <CancelButton onClick={() => closeDialog()} />
                        <SubmitButton />
                    </DialogActions>
                </FormContainer>
            </Dialog>,
        );
    };

    return (
        <SecondaryButton size="medium" onClick={() => openDialog()}>
            <Trans>Edit TCO costs</Trans>
        </SecondaryButton>
    );
}

const SolutionConfigurationGraph: React.FunctionComponent<{
    graphId: FieldId;
    table: Table<SolutionConfigurationTableData>;
}> = ({ graphId, table }): JSX.Element => {
    const renderGraph = React.useCallback(
        (graphId: FieldId) => {
            const data = table.getRowModel().rows.map((row) => row.original);
            const isLoading = table.options.meta?.getIsLoading();
            const sharedContext = assertPresent(
                table.options.meta?.sharedContext,
            ) as SolutionConfigurationsTableContext;

            if (isLoading) {
                return (
                    <CenteredLayout height={'30vh'}>
                        <CircularProgress />
                    </CenteredLayout>
                );
            }

            if (graphId === 'unitPrice') {
                return (
                    <ChartSolutionConfiguration
                        rfqId={sharedContext.rfqId}
                        data={data}
                        chartSpec={chartSpecUnitPrice}
                        sharedContext={sharedContext}
                    />
                );
            }
            if (graphId === 'effectiveTotalPrice') {
                return (
                    <ChartSolutionConfiguration
                        rfqId={sharedContext.rfqId}
                        data={data}
                        chartSpec={chartSpecTotalPrice}
                        sharedContext={sharedContext}
                    />
                );
            }
            if (graphId === 'leadTime') {
                return (
                    <ChartSolutionConfiguration
                        rfqId={sharedContext.rfqId}
                        data={data}
                        chartSpec={chartSpecLeadTime}
                        sharedContext={sharedContext}
                    />
                );
            }
            if (graphId === 'supplierName') {
                return (
                    <ChartSolutionConfiguration
                        rfqId={sharedContext.rfqId}
                        data={data}
                        chartSpec={chartSpecSuppliers}
                        sharedContext={sharedContext}
                    />
                );
            }

            if (graphId === 'table') {
                return <></>;
            }

            assertUnreachable(graphId);
        },
        [table],
    );

    return (
        <Flexbox flexDirection="column">
            <TanStackMenuBar table={table} enableMenuBar={{ controlButtons: true }} />
            <React.Fragment key={graphId}>{renderGraph(graphId)}</React.Fragment>
        </Flexbox>
    );
};

export const SolutionConfigurationsInner: React.FunctionComponent<{
    rfqId: string;
    sourcingScenarioId: string;
    filteredAssemblyId: string | undefined;
}> = ({ rfqId, sourcingScenarioId, filteredAssemblyId }) => {
    const [graphId, setSelectedGraphId] = usePersistedState<FieldId>(
        'sourcing.solutionConfiguration.selectedGraphId',
        'table',
    );

    const { data: sourcingScenarioDTO } = useSourcingScenarioSuspense(sourcingScenarioId);
    const { data: sourcingCalculationDTO } = useSourcingCalculation(sourcingScenarioId);

    const isPcbOnly = sourcingScenarioDTO.assembly_demands.every(
        (demand) => demand.assembly?.assembly_type.type === 'PcbOnly',
    );

    const { table } = useSolutionConfigurationTableState({
        rfqId,
        sourcingScenarioId,
        filteredAssemblyId,
        overrideFormatter: isPcbOnly ? 'unit-price' : undefined,
    });

    return (
        <Flexbox flexDirection="column" flexGrow={1} gap={'12px'} padding={'18px 24px'}>
            <SourcingConfigurationContentHeader
                rfqId={rfqId}
                sourcingScenarioDTO={sourcingScenarioDTO}
                sourcingCalculationDTO={sourcingCalculationDTO}
            />

            <SourcingScenarioSummaryBox
                selectedGraphId={graphId}
                setSelectedGraphId={setSelectedGraphId}
                sourcingCalculation={sourcingCalculationDTO}
                formatter={isPcbOnly ? 'unit-price' : 'default'}
            />

            {graphId !== 'table' && <SolutionConfigurationGraph graphId={graphId} table={table} />}
            {graphId === 'table' && (
                <Box height={'100%'}>
                    <TanStackTable<SolutionConfigurationTableData, SolutionConfigurationsTableContext>
                        table={table}
                        size={'medium'}
                        ActionButton={ActionButton}
                        enableMenuBar={({ table, sharedContext }) => {
                            const hasSelectedItems = table.getSelectedRowModel().rows.length > 0;
                            const hasEnabledScrapQuantity = sharedContext.scrapQuantityHandlers.isEditable;
                            return {
                                resultCount: !hasSelectedItems && !hasEnabledScrapQuantity,
                                controlButtons: !hasSelectedItems && !hasEnabledScrapQuantity,
                            };
                        }}
                    />
                </Box>
            )}
        </Flexbox>
    );
};
