import * as z from 'zod';

interface WebsocketEndpointProps<
    TPathParams extends z.ZodTypeAny,
    TQueryParams extends z.ZodTypeAny,
    TWebsocketResponseMessage extends z.ZodTypeAny,
> {
    /**
     * Provide a human-readable description for the endpoint.
     */
    description: string;

    /**
     * Describes the path parameters of the endpoint.
     */
    pathParams: TPathParams;

    /**
     * Describes the query parameters of the endpoint
     */
    queryParams: TQueryParams;

    responseMessage: TWebsocketResponseMessage;
}

/**
 * Utility function that defines a websocket endpoint. As you can see from the implementation,
 * this function doesn't do anything - it exists merely to enforce the definition of types.
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function websocketEndpoint<
    TPathParams extends z.ZodTypeAny,
    TQueryParams extends z.ZodTypeAny,
    TWebsocketResponseMessage extends z.ZodTypeAny,
>({
    description,
    pathParams,
    queryParams,
    responseMessage,
}: WebsocketEndpointProps<TPathParams, TQueryParams, TWebsocketResponseMessage>) {
    return {
        description,
        pathParams,
        queryParams,
        responseMessage,
    };
}
