import { Action, PersistentTableState } from './types';

export function storageKey(tableId: string): string {
    return `luminovo.data-table-storage.${tableId}`;
}

export const defaultState: PersistentTableState = {
    selectedComparatorIds: [],
    selectedFilterIds: [],
    page: 0,
    memorizedPage: null,
    rowsPerPage: 25,
    selectedIds: [],
};

function toggleComparator(ids: string[], newId: string): string[] {
    if (ids.includes(newId)) {
        return ids.filter((x) => x !== newId);
    }
    return [newId];
}

function toggleFilter(ids: string[], newId: string) {
    if (ids.includes(newId)) {
        return ids.filter((x) => x !== newId);
    }
    return [...ids, newId];
}

/**
 * @returns true if the table has a search query on the column with the given ID.
 */
export function isQueryOnColumn({ queries = {} }: PersistentTableState, columnId: string): boolean {
    const query = queries[columnId] ?? '';
    return query.length > 0;
}

export function reducer(state: PersistentTableState, action: Action): PersistentTableState {
    switch (action.type) {
        case 'toggle-comparator':
            return {
                ...state,
                selectedComparatorIds: toggleComparator(state.selectedComparatorIds, action.comparatorId),
                page: 0,
            };
        case 'toggle-filter':
            return {
                ...state,
                selectedFilterIds: toggleFilter(state.selectedFilterIds, action.filterId),
                page: 0,
            };
        case 'clear-comparators':
            return {
                ...state,
                selectedComparatorIds: [],
                page: 0,
            };
        case 'clear-filters':
            return {
                ...state,
                selectedFilterIds: [],
                queries: {},
                page: 0,
            };
        case 'set-page':
            return {
                ...state,
                page: action.page,
                // Prevents overwriting the memorized page
                memorizedPage: action.memorize && state.memorizedPage === null ? state.page : state.memorizedPage,
            };
        case 'restore-memorized-page':
            return {
                ...state,
                page: state.memorizedPage !== null ? state.memorizedPage : state.page,
                memorizedPage: null,
            };
        case 'set-query':
            return {
                ...state,
                queries: {
                    ...state.queries,
                    [action.columnId]: action.query,
                },
            };
        case 'set-rows': {
            return {
                ...state,
                page: 0,
                memorizedPage: null,
                rowsPerPage: action.rows,
            };
        }
        case 'select-items': {
            if (action.selected) {
                return {
                    ...state,
                    selectedIds: [...new Set([...action.ids, ...state.selectedIds])],
                };
            } else {
                return {
                    ...state,
                    selectedIds: state.selectedIds.filter((id) => !action.ids.includes(id)),
                };
            }
        }
        case 'toggle-selected-item': {
            return {
                ...state,
                selectedIds: state.selectedIds.includes(action.id) ? [] : [action.id],
            };
        }
        case 'clear-selected-items': {
            return {
                ...state,
                selectedIds: [],
            };
        }
        case 'reset-state':
            return action.state;
        default:
            throw new Error(`unknown type`);
    }
}
