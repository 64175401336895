import React from 'react';
import { PageLayout } from '../../components/PageLayout';
import { useRfQ } from '../../resources/rfq/rfqHandler';
import { useIsRfqEditable } from '../../utils/rfqUtils';
import { UrlParams } from '../../utils/routes';
import { ViewContext } from '../Bom/components/ModuleTableData';
import { PanelizationTab } from './PanelizationTab';
import { ToolbarPcb } from './components/ToolbarPcb/ToolbarPcb';

export const PcbPanelizationPage = ({
    pathParams,
}: UrlParams<'/rfqs/:rfqId/bom/assembly/:assemblyId/pcb/panelization'>) => {
    const { assemblyId, rfqId } = pathParams;
    const { data: rfq } = useRfQ(rfqId);
    const { isRfqEditable } = useIsRfqEditable(rfq?.status, rfq?.is_archived, rfq?.workflow_type);

    const viewContext: ViewContext = React.useMemo(() => {
        return { type: 'WithinRfQ', rfqId };
    }, [rfqId]);
    return (
        <PageLayout removeBottomPadding header={<ToolbarPcb assemblyId={assemblyId} rfqId={rfqId} />} layout="fragment">
            <PanelizationTab
                assemblyId={assemblyId}
                rfqId={rfqId}
                isEditable={isRfqEditable}
                viewContext={viewContext}
            />
        </PageLayout>
    );
};
