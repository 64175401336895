import { MonetaryValue } from '@luminovo/commons';
import {
    Currency,
    ItemClass,
    Packaging,
    PartLite,
    QuantityUnitDTO,
    QuoteRequestLineItemStatus,
} from '@luminovo/http-client';
import { LeadTimeUnit } from '@luminovo/sourcing-core';

export const QUOTE_REQUEST_FORM_ID = 'quote-request-form';

export type OfferedPart = {
    part: PartLite;
    warning?: string;
};

export type RowItem = {
    selected: boolean;
    id: string;

    // --- non editable fields
    lineItemId: number;
    component_origin: string | undefined;
    requested_part: PartLite;
    description: string | undefined;
    requiredQuantity: QuantityUnitDTO;
    potentialQuantity: QuantityUnitDTO;
    recipients: string | undefined;
    customerName: string | undefined;
    targetPrice: MonetaryValue | undefined;
    offerId: string | undefined;
    offerCreatedAt: string | undefined;

    // --- editable fields (required)
    bid: boolean;
    /**
     * The part that the supplier is offering.
     * For unknown parts, we show the MPN and manufacturer instead.
     * We also show a warning if the part is not found in our catalog.
     */
    offeredPart: OfferedPart | undefined;
    unitPrice: MonetaryValue | undefined;
    pricePer: number;

    // --- editable fields (optional)
    supplierPartNumber: string | undefined;
    packaging: Packaging | undefined;
    moq: number | undefined;
    mpq: number | undefined;
    stdFactoryLeadTime: number | undefined;
    stdFactoryLeadTimeUnit: LeadTimeUnit | undefined;
    cancellationWindow: number | undefined;
    cancellationWindowUnit: LeadTimeUnit | undefined;
    itemClass: ItemClass | undefined;
    ncnr: boolean;
    stock: number | undefined;
    validFrom: string | undefined;
    validUntil: string | undefined;
    notes: string;
    status: QuoteRequestLineItemStatus;
    oneTimeCosts: {
        description: string | null;
        price: MonetaryValue;
    }[];
};

export type FormValuesQuoteRequest = {
    rows: RowItem[];
    offerNumber: string;
    additionalFiles: { url: string; name: string }[];

    defaultValidUntil?: string;
    defaultCurrency?: Currency;
};
