import * as z from 'zod';
/**
 * This is needed for some requests made to the backend.
 * It needs to know where a request originates from to determine the visibility of some
 * information (CPN / IPN related).
 */
export type RfqContext = z.infer<typeof RfqContextRuntype>;
export const RfqContextRuntype = z.union([
    z.object({
        type: z.literal('WithinRfQ'),
        rfq_id: z.string(),
    }),
    z.object({
        type: z.literal('OutsideRfQ'),
    }),
]);

export type RfQContextQueryParams = z.infer<typeof RfQContextQueryParamsRuntype>;
export const RfQContextQueryParamsRuntype = z.union([
    z.object({
        rfq_context: z.literal('WithinRfQ'),
        rfq_id: z.string(),
    }),
    z.object({
        rfq_context: z.literal('OutsideRfQ'),
    }),
]);
