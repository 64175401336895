import { t, Trans } from '@lingui/macro';
import { formatDecimal, formatMonetaryValue, formatPercentage, transEnum } from '@luminovo/commons';
import { colorSystem, Text } from '@luminovo/design-system';
import { NegotiationLineItemStatus } from '@luminovo/http-client';
import { Box, Skeleton, Tooltip } from '@mui/material';
import * as React from 'react';
import { LayoutCard } from '../../../components/LayoutCard';
import { useNegotiationsContext } from '../context/NegotiationsContext';
import { useNegotiationAggregatedStatuses, useNegotiationLineItems } from '../hooks/negotiationHandlers';
import { negotiationLineItemStatusTranslations } from '../i18n';
import {
    calculateNegotiationLineItemQuoteRequestSummary,
    getNegotiationLineItemStatusRawColor,
} from '../model/getNegotiationLineItemStatus';
import { getScenarioPricingDiff } from '../model/getScenarioPricingDiff';
import { getScenarioTotalVolume } from '../model/getScenarioTotalVolume';
import { LabelPercentageChange } from './LabelPercentageChange';

export function CardSummary({ negotiationId }: { negotiationId: number }) {
    const { referenceScenario, selectedScenario } = useNegotiationsContext();
    const referenceScenarioId = referenceScenario?.id;
    const selectedScenarioId = selectedScenario?.id;
    const { data: lineItems = [], isLoading } = useNegotiationLineItems(negotiationId);
    const referenceVolume = getScenarioTotalVolume(lineItems, referenceScenarioId);
    const selectedVolume = getScenarioTotalVolume(lineItems, selectedScenarioId);

    const {
        savings,
        increase,
        result: net,
        resultRatio,
    } = getScenarioPricingDiff(lineItems, referenceScenarioId, selectedScenarioId);

    return (
        <LayoutCard title={undefined}>
            <Box sx={{ display: 'grid', gridTemplateColumns: 'auto auto auto auto auto', rowGap: 1, columnGap: 2 }}>
                <Text variant="h5" color={colorSystem.neutral[7]}>
                    <Trans>Reference volume</Trans>
                </Text>
                <Text variant="h5" color={colorSystem.neutral[7]}>
                    <Trans>Selected volume</Trans>
                </Text>
                <Text variant="h5" color={colorSystem.neutral[7]}>
                    <Trans>Total savings</Trans>
                </Text>
                <Text variant="h5" color={colorSystem.neutral[7]}>
                    <Trans>Total increase</Trans>
                </Text>
                <Text variant="h5" color={colorSystem.neutral[7]}>
                    <Trans>Result</Trans>
                </Text>

                <Text
                    variant="h1"
                    style={{
                        whiteSpace: 'nowrap',
                    }}
                    color={colorSystem.neutral[9]}
                >
                    {isLoading ? <Skeleton width={80} /> : formatMonetaryValue(referenceVolume, 'estimate')}
                </Text>
                <Text
                    variant="h1"
                    style={{
                        whiteSpace: 'nowrap',
                    }}
                    color={colorSystem.neutral[9]}
                >
                    {isLoading ? <Skeleton width={80} /> : formatMonetaryValue(selectedVolume, 'estimate')}
                </Text>
                <Text
                    variant="h1"
                    style={{
                        whiteSpace: 'nowrap',
                    }}
                    color={colorSystem.neutral[9]}
                >
                    {isLoading ? (
                        <Skeleton width={80} />
                    ) : (
                        formatMonetaryValue(savings, 'estimate', { alwaysShowSign: true })
                    )}
                </Text>
                <Text
                    variant="h1"
                    style={{
                        whiteSpace: 'nowrap',
                    }}
                    color={colorSystem.neutral[9]}
                >
                    {isLoading ? (
                        <Skeleton width={80} />
                    ) : (
                        formatMonetaryValue(increase, 'estimate', { alwaysShowSign: true })
                    )}
                </Text>
                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                    <Text
                        variant="h1"
                        style={{
                            whiteSpace: 'nowrap',
                        }}
                        color={colorSystem.neutral[9]}
                    >
                        {isLoading ? (
                            <Skeleton width={80} />
                        ) : (
                            formatMonetaryValue(net, 'estimate', { alwaysShowSign: true })
                        )}
                    </Text>
                    <LabelPercentageChange percent={resultRatio} />
                </Box>
            </Box>

            <ChartNegotiationLineItemStatus negotiationId={negotiationId} />
        </LayoutCard>
    );
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function ChartNegotiationLineItemStatus({ negotiationId }: { negotiationId: number }) {
    const { data: aggregatedStatuses, isLoading: isLoadingAggregatedStatuses } =
        useNegotiationAggregatedStatuses(negotiationId);
    const { data: lineItems = [], isLoading: isLoadingLineItems } = useNegotiationLineItems(negotiationId);

    const data = React.useMemo(() => {
        const statusData = lineItems.map(
            (li) =>
                calculateNegotiationLineItemQuoteRequestSummary(
                    aggregatedStatuses?.statuses_by_negotiation_line_item.find(
                        (statuses) => statuses.negotiation_line_item_id === li.id,
                    ),
                ).status,
        );

        return Object.values(NegotiationLineItemStatus).map((status) => {
            return {
                id: status,
                label: transEnum(status, negotiationLineItemStatusTranslations),
                value: statusData?.filter((d) => d === status).length ?? 0,
                color: getNegotiationLineItemStatusRawColor(status),
            };
        });
    }, [aggregatedStatuses, lineItems]);

    return (
        <MultiProgressBar
            title={t`Progress`}
            data={data}
            isLoading={isLoadingAggregatedStatuses || isLoadingLineItems}
            showTotalCount={false}
            getColor={(data) => data.color}
            getLabel={(data) => data.label}
            getValue={(data) => data.value}
        />
    );
}

export function MultiProgressBar<
    T extends { id: string; label: string; value: number; color: string; isSelected?: boolean },
>({
    title = t`Progress`,
    data,
    showTotalCount = false,
    showPercentages = false,
    showLegend = true,
    getColor = (data) => data.color,
    getLabel = (data) => data.label,
    getValue = (data) => data.value,
    getBorder = (data) => undefined,
    isLoading,
    onClick,
    legendType = 'horizontal',
}: {
    title?: string;
    data: T[];
    showTotalCount?: boolean;
    showPercentages?: boolean;
    showLegend?: boolean;
    getColor?: (data: T) => string;
    getLabel?: (data: T) => string;
    getValue?: (data: T) => number;
    getBorder?: (data: T) => string | undefined;
    isLoading?: boolean;
    onClick?: (data: T) => void;
    legendType?: 'vertical' | 'horizontal';
}): JSX.Element {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
                overflow: 'hidden',
                width: showLegend ? 'auto' : '100%',
            }}
        >
            {title && (
                <Text variant="h5" color={colorSystem.neutral[7]}>
                    {title}
                </Text>
            )}
            {showTotalCount && (
                <Text variant="h1" color={colorSystem.neutral[9]}>
                    {isLoading ? (
                        <Skeleton width={100} />
                    ) : (
                        formatDecimal(data.reduce((acc, item) => acc + Number(getValue(item)), 0))
                    )}
                </Text>
            )}

            {isLoading ? (
                <Skeleton variant="rounded" width="90%" height={20} />
            ) : (
                <Box sx={{ display: 'flex', gap: 0.5, borderRadius: 0.5, overflow: 'hidden', flexDirection: 'row' }}>
                    {data
                        .filter((item) => item.value > 0)
                        .map((item, index) => {
                            const flex = getValue(item);
                            const selectedStyles: React.CSSProperties = {
                                border: `2px solid ${colorSystem.primary[5]}`,
                            };

                            return (
                                <Tooltip key={index} title={getLabel(item)}>
                                    <Box
                                        sx={{
                                            flex: flex,
                                            backgroundColor: getColor(item),
                                            border: getBorder(item),
                                            height: 20,
                                            borderRadius: 0.5,
                                            boxSizing: 'border-box',
                                            cursor: onClick ? 'pointer' : 'default',
                                            ...(item.isSelected ? selectedStyles : {}),
                                        }}
                                        onClick={() => onClick && onClick(item)}
                                    />
                                </Tooltip>
                            );
                        })}
                </Box>
            )}

            {showLegend && (
                <Box
                    sx={{
                        display: 'flex',
                        gap: 1,
                        alignItems: legendType === 'horizontal' ? 'center' : 'flex-start',
                        flexDirection: legendType === 'horizontal' ? 'row' : 'column',
                    }}
                >
                    {data.map((item, index) => {
                        return (
                            <Box
                                key={index}
                                sx={{
                                    display: 'flex',
                                    gap: 1,
                                    alignItems: 'center',
                                    ...(legendType === 'horizontal' ? {} : { width: '100%' }),
                                }}
                            >
                                <div
                                    style={{
                                        borderRadius: 4,
                                        backgroundColor: getColor(item),
                                        border: getBorder(item),
                                        height: 12,
                                        width: 12,
                                        cursor: onClick ? 'pointer' : 'default',
                                        flexShrink: 0,
                                    }}
                                    onClick={() => onClick && onClick(item)}
                                />
                                <Text
                                    showEllipsis
                                    variant="body-small"
                                    color={colorSystem.neutral[7]}
                                    style={{
                                        cursor: onClick ? 'pointer' : 'default',
                                        ...(legendType === 'vertical' ? { flex: 1 } : {}),
                                    }}
                                    onClick={() => onClick && onClick(item)}
                                >
                                    {isLoading ? <></> : getLabel(item)}
                                </Text>
                                <Text
                                    variant="body-small"
                                    color={colorSystem.neutral[7]}
                                    style={{
                                        marginRight: legendType === 'horizontal' ? 20 : 0,
                                        cursor: onClick ? 'pointer' : 'default',
                                        flexShrink: 0,
                                    }}
                                    onClick={() => onClick && onClick(item)}
                                >
                                    {isLoading ? (
                                        <Skeleton width={100} />
                                    ) : (
                                        <>
                                            {getValue(item)}
                                            {showPercentages &&
                                                ` (${formatPercentage(getValue(item) / data.reduce((acc, d) => acc + getValue(d), 0))})`}
                                        </>
                                    )}
                                </Text>
                            </Box>
                        );
                    })}
                </Box>
            )}
        </Box>
    );
}
