import { Trans } from '@lingui/macro';
import { Breakpoint } from '@mui/material';
import React from 'react';
import { usePersistedState } from '../../../hooks/usePersistedState';
import { PrimaryButton } from '../../buttons';
import { Dialog, DialogActions, DialogContent } from '../../Dialog';
import { Flexbox } from '../../Flexbox';
import { Text } from '../../Text';

import { Checkbox } from '../../Checkbox';

export function StepHelpDialog({
    title,
    body,
    width = 'md',
}: {
    title: string;
    body: JSX.Element;
    width?: Breakpoint;
}): JSX.Element {
    const [hideForeverLabel, setHideForever] = usePersistedState<'true' | ''>('hide-dialog-forever' + title, '');
    const hideForever = hideForeverLabel === 'true';
    const [isVisible, setVisible] = React.useState(true);
    const [checked, setChecked] = React.useState(false);

    const showDialog = !hideForever && isVisible;

    return (
        <>
            <Dialog maxWidth={width ?? false} onClose={() => setVisible(false)} title={title} open={showDialog}>
                <DialogContent>{body}</DialogContent>
                <DialogActions
                    sx={{
                        padding: '16px 24px',
                    }}
                >
                    <Flexbox alignItems={'center'} gap="8px">
                        <Checkbox checked={checked} onChange={() => setChecked(!checked)} size="small" />
                        <Text>
                            <Trans>Never show this dialog again</Trans>
                        </Text>
                    </Flexbox>
                    <span style={{ flexGrow: 1 }} />
                    <PrimaryButton
                        onClick={() => {
                            setVisible(false);
                            if (checked) {
                                setHideForever('true');
                            }
                        }}
                        size="small"
                        autoFocus
                        disableFocusRipple
                    >
                        <Trans>OK</Trans>
                    </PrimaryButton>
                </DialogActions>
            </Dialog>
        </>
    );
}
