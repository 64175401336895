import * as z from 'zod';
import { AssemblyTypeRuntype } from '../assembly';

export interface AssemblySettingsDTO extends z.infer<typeof AssemblySettingsRuntype> {}
export const AssemblySettingsRuntype = z.object({
    allowed_assembly_types: z.array(AssemblyTypeRuntype),
});

export interface PatchAssemblySettingsDTO extends z.infer<typeof PatchAssemblySettingsRuntype> {}
export const PatchAssemblySettingsRuntype = z.object({
    allowed_assembly_types: z.array(AssemblyTypeRuntype).optional(),
});
