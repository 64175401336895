import * as z from 'zod';
import { MonetaryValueBackendRuntype } from '../backendTypes';
import { ErrorCodeRuntype } from '../errorCodes';
import { endpoint } from '../http/endpoint';
import { NegotiationsStandardPartOfferBulkInputDTORuntype } from '../offer';
import { PartDTORuntype } from '../part';
import {
    AwardScenarioDTORuntype,
    DemandDTORuntype,
    EmailHistoryEventDTORuntype,
    NegotiationDTORuntype,
    NegotiationLineItemRuntype,
    PartOptionRequestSummaryDTORuntype,
    QuoteRequestDTORuntype,
    QuoteRequestLineItemAggregatedStatusesRuntype,
    QuoteRequestLineItemDTORuntype,
    SupplierSelectionStrategyRuntype,
} from './negotiationBackendTypes';

export const negotiationEndpoints = {
    'GET /negotiation': endpoint({
        description: 'Retrieves all negotiations',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({
            items: z.array(NegotiationDTORuntype),
        }),
    }),

    'GET /negotiation/:id': endpoint({
        description: 'Retrieves a negotiation',
        pathParams: z.object({
            id: z.number(),
        }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: NegotiationDTORuntype,
    }),

    'PATCH /negotiation/:id': endpoint({
        description: 'Updates a negotiation',
        pathParams: z.object({
            id: z.number(),
        }),
        queryParams: z.undefined(),
        requestBody: z.object({
            name: z.string(),
        }),
        responseBody: z.object({
            id: z.number(),
            name: z.string(),
        }),
        invalidates: ['GET /negotiation'],
    }),

    'GET /negotiation/:id/line-items': endpoint({
        description: 'Retrieves line items for a negotiation',
        pathParams: z.object({
            id: z.number(),
        }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.array(NegotiationLineItemRuntype),
    }),

    'POST /award-scenario/find': endpoint({
        description: 'Retrieves all award scenarios for a negotiation',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.object({
            negotiation_id: z.number(),
        }),
        responseBody: z.object({
            items: z.array(AwardScenarioDTORuntype),
        }),
    }),

    'GET /demand': endpoint({
        description: 'Retrieves all demands',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.array(DemandDTORuntype),
    }),

    'GET /negotiation/:id/demands': endpoint({
        description: 'Retrieves demands associated with a negotiation',
        pathParams: z.object({
            id: z.number(),
        }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.array(DemandDTORuntype),
    }),

    'GET /negotiation/:id/aggregated-statuses': endpoint({
        description: 'Retrieves aggregated statuses for quote request line items',
        pathParams: z.object({
            id: z.number(),
        }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: QuoteRequestLineItemAggregatedStatusesRuntype,
    }),

    'POST /negotiation/line-items/part-options-with-quote-request-line-items': endpoint({
        description: 'Finds part options with quote request line items',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.object({
            negotiation_line_item_ids: z.array(z.number()),
        }),
        responseBody: z.array(PartOptionRequestSummaryDTORuntype),
    }),

    'GET /quote-request/:id/additional-files': endpoint({
        description: 'Gets additional files for a quote request',
        pathParams: z.object({
            id: z.string(),
        }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.array(z.object({ name: z.string(), url: z.string() })),
    }),

    'GET /quote-request/:id/additional-files/upload-link': endpoint({
        description: 'Gets an upload link for additional files for a quote request',
        pathParams: z.object({
            id: z.string(),
        }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.string(),
        invalidates: ['GET /quote-request/:id/additional-files'],
    }),

    'GET /quote-request/:id/email-logs': endpoint({
        description: 'Gets the email history for a quote request',
        pathParams: z.object({ id: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.array(EmailHistoryEventDTORuntype),
    }),

    'DELETE /quote-request/:id/offers': endpoint({
        description: 'Deletes offers offers for a quote request',
        pathParams: z.object({ id: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.unknown(),
    }),

    'DELETE /quote-request/line-item/delete-offer': endpoint({
        description: 'Delete offers for a set of quote request line items',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.object({
            ids: z.array(z.number()),
        }),
        responseBody: z.unknown(),
    }),

    'DELETE /quote-request/:id/additional-files': endpoint({
        description: 'Deletes additional files for a quote request',
        pathParams: z.object({ id: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.object({
            file_name: z.string(),
        }),
        responseBody: z.unknown(),
        invalidates: ['GET /quote-request/:id/additional-files'],
    }),

    'GET /quote-request/:id': endpoint({
        description: 'Get a quote request',
        pathParams: z.object({ id: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: QuoteRequestDTORuntype,
    }),

    'POST /quote-request/send-reminders': endpoint({
        description: 'Sends a reminder for a quote request',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.object({ ids: z.array(z.string()) }),
        responseBody: z.unknown(),
    }),

    'POST /quote-request/find': endpoint({
        description: 'Finds quote requests',
        requestBody: z.object({
            // either quote_request_ids or negotiation_id or negotiation_line_item_ids must be provided
            // if neither is provided, throws an error
            quote_request_ids: z.array(z.string()).optional(),
            negotiation_id: z.number().optional(),
            negotiation_line_item_ids: z.array(z.number()).optional(),
            quote_request_numbers: z.array(z.number()).optional(),
        }),
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        responseBody: z.object({
            items: z.array(QuoteRequestDTORuntype),
        }),
    }),

    'GET /quote-request/:id/line-items': endpoint({
        description: 'Finds quote request line items for a quote request',
        pathParams: z.object({
            id: z.string(),
        }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({
            items: z.array(QuoteRequestLineItemDTORuntype),
        }),
    }),
    'POST /quote-request/line-items/find': endpoint({
        description: 'Finds quote request line items by negotiation line item ids',
        requestBody: z.object({
            negotiation_line_item_ids: z.array(z.number()),
        }),
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        responseBody: z.object({
            items: z.array(QuoteRequestLineItemDTORuntype),
        }),
    }),

    'POST /quote-request/draft': endpoint({
        description: 'Creates a quote request draft',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.object({
            supplier_ids: z.array(z.string()),
            items: z.array(
                z.object({
                    // negotiation part options
                    part_option_id: z.number(),
                    part: PartDTORuntype,
                }),
            ),
            supplier_selection_strategy: SupplierSelectionStrategyRuntype,
        }),
        responseBody: z.object({
            quote_requests: z.array(
                z.object({
                    id: z.string(),
                    quote_request_line_items: z.number(),
                }),
            ),
        }),
        invalidates: [
            'POST /quote-request/line-items/find',
            'GET /quote-request/:id/line-items',
            'POST /quote-request/find',
            'GET /quote-request/:id',
            'GET /negotiation/:id/aggregated-statuses',
            'POST /negotiation/line-items/part-options-with-quote-request-line-items',
        ],
    }),

    // TODO(negotiations): move to quote-request-line-item endpoints
    'POST /quote-request/:id/add-offers/off-the-shelf': endpoint({
        description: 'Adds off-the-shelf offers to a quote request',
        pathParams: z.object({
            id: z.string(),
        }),
        queryParams: z.undefined(),
        requestBody: z.object({
            supplier_and_stock_location: z.string(),
            inputs: z.array(NegotiationsStandardPartOfferBulkInputDTORuntype),
            event_metadata: z.object({
                type: z.literal('Excel'),
            }),
        }),
        responseBody: z.unknown(),
        invalidates: [
            'POST /quote-request/line-items/find',
            'POST /quote-request/find',
            'GET /quote-request/:id/line-items',
            'GET /negotiation/:id/aggregated-statuses',
        ],
    }),

    // TODO(negotiations): move to award-scenario endpoints
    'POST /award-scenario': endpoint({
        description: 'Creates an award scenario',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        responseBody: z.object({
            award_scenario_id: z.number().optional(),
        }),
        requestBody: z.object({
            name: z.string(),
            negotiation_id: z.number(),
            clone_from: z.number().optional(),
        }),
        invalidates: [
            'POST /award-scenario/find',
            'GET /quote-request/:id/line-items',
            'POST /award-scenario/refresh-automatic',
            'POST /quote-request/line-items/find',
            'GET /negotiation/:id/line-items',
        ],
    }),

    'POST /award-scenario/:id/award-from-quote-requests': endpoint({
        description: 'Upserts an award scenario with best offers from a set of quote requests',
        pathParams: z.object({
            id: z.number(),
        }),
        queryParams: z.undefined(),
        requestBody: z.object({
            items: z.array(
                z.object({
                    negotiation_line_item_id: z.number(),
                    quote_request_ids: z.array(z.string()),
                }),
            ),
        }),
        responseBody: z.object({
            award_scenario_id: z.number().nullable(),
        }),
        invalidates: [
            'POST /award-scenario/find',
            'GET /quote-request/:id/line-items',
            'POST /award-scenario/refresh-automatic',
            'POST /quote-request/line-items/find',
            'GET /negotiation/:id/line-items',
            'GET /negotiation/:id/aggregated-statuses',
        ],
    }),

    'POST /award-scenario/:id/copy-awarded-offers': endpoint({
        description: 'Upserts an award scenario with an array of awarded offers',
        pathParams: z.object({
            id: z.number(),
        }),
        queryParams: z.undefined(),
        requestBody: z.object({
            items: z.array(
                z.object({
                    negotiation_line_item_id: z.number(),
                    awarded_offer_id: z.number(),
                }),
            ),
        }),
        responseBody: z.object({
            award_scenario_id: z.number().nullable(),
        }),
        invalidates: [
            'POST /award-scenario/find',
            'GET /quote-request/:id/line-items',
            'POST /award-scenario/refresh-automatic',
            'POST /quote-request/line-items/find',
            'GET /negotiation/:id/line-items',
            'GET /negotiation/:id/aggregated-statuses',
        ],
    }),

    // TODO(negotiations): move to quote-request endpoints
    'POST /award-scenario/:id/awarded-offer/upsert': endpoint({
        description: 'Upserts an awarded offer on a negotiation line item',
        pathParams: z.object({
            id: z.number(),
        }),
        queryParams: z.undefined(),
        requestBody: z.object({
            name: z.string(),
            negotiation_line_item_id: z.number(),
            offer_id: z.object({
                kind: z.union([z.literal('OffTheShelf'), z.literal('Custom')]),
                id: z.string(),
            }),
        }),
        responseBody: z.object({
            award_scenario_id: z.number(),
        }),
        invalidates: [
            'POST /award-scenario/find',
            'GET /quote-request/:id/line-items',
            'POST /award-scenario/refresh-automatic',
            'POST /quote-request/line-items/find',
            'GET /negotiation/:id/line-items',
        ],
    }),

    // TODO(negotiations): move to quote-request endpoints
    'POST /quote-request/send-many': endpoint({
        description: 'Sends multiple quote requests',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.object({
            include_pcb_specification: z.boolean(),
            include_shipping_panel_specification: z.boolean(),
            include_mail_attachments: z.boolean(),
            mail_data: z.array(
                z.object({
                    quote_request_id: z.string(),
                    recipient_emails: z.array(z.object({ name: z.string().optional(), email: z.string() })),
                    cc_emails: z.array(z.object({ name: z.string().nullable(), email: z.string() })),
                    email: z.object({ subject: z.string(), body: z.string() }),
                }),
            ),
        }),
        responseBody: z.object({
            results: z.array(
                z.object({
                    quote_request_id: z.string(),
                    status_code: z.number(),
                    error_code: ErrorCodeRuntype.nullable(),
                }),
            ),
        }),
        invalidates: [
            'POST /quote-request/line-items/find',
            'POST /quote-request/find',
            'GET /quote-request/:id',
            'GET /quote-request/:id/line-items',
            'GET /negotiation/:id/aggregated-statuses',
        ],
    }),

    // TODO(negotiations): move to quote-request-line-item endpoints
    'PATCH /quote-request/:id': endpoint({
        description: 'Updates a quote request',
        pathParams: z.object({
            id: z.string(),
        }),
        responseBody: z.unknown(),
        queryParams: z.undefined(),
        requestBody: z.object({
            due_date: z.string().nullable().optional(),
            discarded: z.boolean().optional(),
            show_customer: z.boolean().optional(),
            show_target_price: z.boolean().optional(),
            notes: z.string().nullable().optional(),
        }),
        invalidates: [
            'POST /quote-request/line-items/find',
            'POST /quote-request/find',
            'GET /quote-request/:id',
            'GET /quote-request/:id/line-items',
            'GET /negotiation/:id/aggregated-statuses',
        ],
    }),

    // TODO(negotiations): move to quote-request endpoints
    'POST /quote-request/line-item/update-many': endpoint({
        description: 'Updates multiple quote request line items',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.object({
            updates: z.array(
                z.object({
                    id: z.number(),
                    target_price: MonetaryValueBackendRuntype,
                }),
            ),
        }),
        responseBody: z.unknown(),
        invalidates: [
            'POST /quote-request/line-items/find',
            'GET /quote-request/:id/line-items',
            'GET /negotiation/:id/aggregated-statuses',
        ],
    }),

    'POST /negotiation': endpoint({
        description: 'Creates a negotiation',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.object({
            name: z.string(),
            demand_groups: z.array(z.array(z.string())),
            rfq_id: z.string().optional(),
            filter_by_categories: z.array(
                z.union([
                    z.object({ type: z.literal('OtsCategory'), id: z.number() }),
                    z.object({ type: z.literal('CustomPartType'), data: z.string() }),
                ]),
            ),
            filter_manufacturers: z.array(z.string()),
            filter_not_quoted_since: z.string().nullable(),
            group_by_customer: z.boolean(),
            group_by_site_groups: z.array(z.array(z.string())),
            group_by_supplier_groups: z.array(z.array(z.string())),
            group_by_sourcing_scenario: z.boolean(),
        }),
        responseBody: z.object({
            id: z.number(),
        }),
        invalidates: ['GET /negotiation', 'GET /demand'],
    }),

    'DELETE /negotiation/:id': endpoint({
        description: 'Deletes a negotiation',
        pathParams: z.object({
            id: z.number(),
        }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.unknown(),
    }),

    // TODO(negotiations): move to award-scenario endpoints
    'POST /award-scenario/refresh-automatic': endpoint({
        description: 'Refreshes automatic award scenarios',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.object({
            negotiation_id: z.number(),
        }),
        responseBody: z.unknown(),
        invalidates: [
            'GET /negotiation/:id/line-items',
            'POST /award-scenario/find',
            'GET /quote-request/:id/line-items',
            'POST /quote-request/line-items/find',
        ],
    }),

    'DELETE /award-scenario/:id': endpoint({
        description: 'Deletes an award scenario',
        pathParams: z.object({
            id: z.number(),
        }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.unknown(),
    }),

    'DELETE /quote-request/:id': endpoint({
        description: 'Deletes a quote request',
        pathParams: z.object({
            id: z.string(),
        }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.unknown(),
    }),

    'DELETE /quote-request/delete-many': endpoint({
        description: 'Deletes mulitple quote requests',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.object({
            quote_request_ids: z.array(z.string()),
        }),
        responseBody: z.unknown(),
    }),

    'POST /demand/delete-bulk': endpoint({
        description: 'Deletes multiple demands',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.object({
            ids: z.array(z.string()),
        }),
        responseBody: z.unknown(),
        invalidates: ['GET /demand'],
    }),

    'POST /quote-request/line-item/delete': endpoint({
        description: 'Deletes a quote request line item',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.object({
            ids: z.array(z.number()),
        }),
        responseBody: z.unknown(),
        invalidates: [
            'POST /negotiation/line-items/part-options-with-quote-request-line-items',
            'GET /quote-request/:id/line-items',
        ],
    }),
} satisfies Record<string, Endpoint>;

type Endpoint = ReturnType<typeof endpoint>;
