import * as z from 'zod';
import { QuantityUnitRuntype } from '../backendTypes';
import { endpoint } from '../http/endpoint';
import { CustomOptionOfferDTORuntype, StandardPartOfferDTORuntype } from '../offer';
import { SupplierSolutionRuntype } from '../sourcingScenario';
import { SolutionDTORuntype } from './solutionBackendTypes';

const solutionEndpoints_1 = {
    'GET /solutions': endpoint({
        description: 'Returns an array of solutions for the query parameters specified in the request',
        pathParams: z.undefined(),
        queryParams: z.object({
            solution_conf: z.string(), // (sourcingConfigId)
            quantity: z.number(),
            unit: QuantityUnitRuntype,
        }),
        requestBody: z.undefined(),
        responseBody: z.object({
            data: z.array(SolutionDTORuntype),
            standard_part_offers: z.array(StandardPartOfferDTORuntype),
            custom_part_offers: z.array(CustomOptionOfferDTORuntype),
        }),
    }),
};

const solutionEndpoints_2 = {
    'POST /solutions/custom-part/bulk': endpoint({
        description: 'Fetches the solutions for a particular assembly',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.object({
            rfq_id: z.string(),
            assembly_id: z.string(),
        }),
        responseBody: z.object({
            custom_part_offers: z.array(CustomOptionOfferDTORuntype),
            supplier_solutions: z.array(SupplierSolutionRuntype),
        }),
    }),
};

// Define types for each endpoint group
type InnerSolutionEndpoints_1 = typeof solutionEndpoints_1;
type InnerSolutionEndpoints_2 = typeof solutionEndpoints_2;

// Create an interface that extends all endpoint types
interface SolutionEndpointsRegistry extends InnerSolutionEndpoints_1, InnerSolutionEndpoints_2 {}

export const solutionEndpoints: SolutionEndpointsRegistry = {
    ...solutionEndpoints_1,
    ...solutionEndpoints_2,
};
