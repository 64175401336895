import * as z from 'zod';
import { endpoint } from '../http/endpoint';
import { ChipCartCredentialsInputDTORuntype, ChipCartResponseBodyDTORuntype } from './chipCartBackendTypes';

export const chipCartEndpoints = {
    'GET /3rdparty/chipcart/credentials': endpoint({
        description: 'Returns credentials for chipcart integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: ChipCartResponseBodyDTORuntype,
    }),
    'POST /3rdparty/chipcart/credentials': endpoint({
        description: 'Uploads credentials for chipcart API integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: ChipCartCredentialsInputDTORuntype,
        responseBody: ChipCartResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/chipcart/credentials'],
    }),
    'DELETE /3rdparty/chipcart/credentials': endpoint({
        description: 'Deletes existing credentials for chipcart API integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({ deleted: z.number() }),
        invalidates: ['GET /3rdparty/chipcart/credentials'],
    }),
};
