import * as z from 'zod';

const IncompleteRuntype = z.literal('Incomplete');
const ResultTypeMismatchRuntype = z.object({ type: z.literal('ResultTypeMismatch'), details: z.string() });
const UnableToConvertInputToFloatRuntype = z.object({
    type: z.literal('UnableToConvertInputToFloat'),
    details: z.object({ name: z.string() }),
});

const LexerErrorKindRuntype = z.object({
    type: z.literal('InvalidCharacter'),
    details: z.string(),
});

export type ErrorSpan = z.infer<typeof SpanRuntype>;
const SpanRuntype = z.object({
    start_line: z.number(),
    start_column: z.number(),
    end_line: z.number(),
    end_column: z.number(),
});

export type LexerErrorDTO = z.infer<typeof LexerErrorRuntype>;
const LexerErrorRuntype = z.object({
    type: z.literal('LexerError'),
    details: z.object({ kind: LexerErrorKindRuntype, span: SpanRuntype }),
});

export type TokenType = z.infer<typeof TokenRuntypes>;
const TokenRuntypes = z.union([
    z.literal('If'),
    z.literal('ElseIf'),
    z.literal('Else'),
    z.literal('Plus'),
    z.literal('Minus'),
    z.literal('Divide'),
    z.literal('Multiply'),
    z.literal('And'),
    z.literal('Or'),
    z.literal('Equal'),
    z.literal('NotEqual'),
    z.literal('GreaterThanOrEqual'),
    z.literal('LessThanOrEqual'),
    z.literal('GreaterThan'),
    z.literal('LessThan'),
    z.literal('Comma'),
    z.literal('LParen'),
    z.literal('RParen'),
    z.literal('LBrace'),
    z.literal('RBrace'),
    z.literal('SingleLineComment'),
    z.literal('Eof'),
    z.literal('Blank'),
]);

export type SyntaxErrorTokenKind = z.infer<typeof TokenRuntype>;
const TokenRuntypeWithDetailsOfStringType = z.object({
    token: z.union([z.literal('Ident'), z.literal('String')]),
    details: z.string(),
});
const TokenRuntypeWithDetailsOfBooleanType = z.object({
    token: z.literal('Boolean'),
    details: z.boolean(),
});

const TokenRuntypeWithDetailsOfNumberType = z.object({
    token: z.literal('Float'),
    details: z.number(),
});

const TokenRuntype = z.object({
    token: TokenRuntypes,
});

export type SyntaxErrorDetails = z.infer<typeof SyntaxErrorDataTypeRuntype>;
const SyntaxErrorDataTypeRuntype = z.union([
    TokenRuntypeWithDetailsOfBooleanType,
    TokenRuntypeWithDetailsOfStringType,
    TokenRuntypeWithDetailsOfNumberType,
    TokenRuntype,
]);

const ExpectedRuntype = z.object({
    type: z.literal('Expected'),
    details: z.object({
        type: z.literal('Token'),
        details: SyntaxErrorDataTypeRuntype,
    }),
});

const ExpectedAnyOfRuntype = z.object({
    type: z.literal('ExpectedAnyOf'),
    details: z.array(
        z.object({
            type: z.literal('Token'),
            details: SyntaxErrorDataTypeRuntype,
        }),
    ),
});

const UnexpectedRuntype = z.object({
    type: z.literal('Unexpected'),
    details: z.object({
        type: z.literal('Token'),
        details: SyntaxErrorDataTypeRuntype,
    }),
});

export type SyntaxErrorKind = z.infer<typeof SyntaxErrorKindRuntype>;
const SyntaxErrorKindRuntype = z.union([ExpectedRuntype, ExpectedAnyOfRuntype, UnexpectedRuntype]);

export type SyntaxErrorDTO = z.infer<typeof SyntaxErrorRuntype>;
export const SyntaxErrorRuntype = z.object({
    type: z.literal('SyntaxError'),
    details: z.object({
        kind: SyntaxErrorKindRuntype,
        span: SpanRuntype,
    }),
});

export type UndefinedIdentifierKind = z.infer<typeof UndefinedIdentifierRuntype>;
const UndefinedIdentifierRuntype = z.object({
    type: z.literal('UndefinedIdentifier'),
    details: z.string(),
});
export type ReservedKeywordKind = z.infer<typeof ReservedKeywordRuntype>;

const ReservedKeywordRuntype = z.object({
    type: z.literal('ReservedKeyword'),
    details: z.string(),
});

export type RuntimeErrorKind = z.infer<typeof RuntimeErrorKindRuntype>;
const RuntimeErrorKindRuntype = z.union([
    z.object({
        type: z.union([
            z.literal('ExpectedBoolean'),
            z.literal('ExpectedFloat'),
            z.literal('ExpectedCall'),
            z.literal('ArgumentMissing'),
            z.literal('DivideByZero'),
            z.literal('TooManyArguments'),
            z.literal('ExpectedUnsignedInteger'),
            z.literal('OutOfI32Range'),
        ]),
    }),
    UndefinedIdentifierRuntype,
    ReservedKeywordRuntype,
]);

export type RuntimeErrorDTO = z.infer<typeof RuntimeErrorRuntype>;
const RuntimeErrorRuntype = z.object({
    kind: RuntimeErrorKindRuntype,
    span: SpanRuntype,
});

export type ValidationErrorDTO = z.infer<typeof ValidationErrorRuntype>;
const ValidationErrorRuntype = z.object({
    type: z.literal('ValidationError'),
    details: RuntimeErrorKindRuntype,
});

export type RuntimeTestErrorDTO = z.infer<typeof RuntimeTestErrorRuntype>;
const RuntimeTestErrorRuntype = z.object({
    type: z.literal('RuntimeError'),
    details: RuntimeErrorRuntype,
});

export type FormulaErrorDTO = z.infer<typeof FormulaScriptErrorRuntype>;
export const FormulaScriptErrorRuntype = z.union([
    ResultTypeMismatchRuntype,
    UnableToConvertInputToFloatRuntype,
    IncompleteRuntype,
    LexerErrorRuntype,
    SyntaxErrorRuntype,
    RuntimeErrorRuntype,
    ValidationErrorRuntype,
    RuntimeTestErrorRuntype,
]);
