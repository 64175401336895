import { Trans } from '@lingui/macro';
import { Flexbox, Select, Text, colorSystem } from '@luminovo/design-system';
import { InputAdornment, MenuItem } from '@mui/material';
import { UseFormSetValue } from 'react-hook-form';
import { useEmsAndOemNames } from './BomItemFormHeader/fields/useEmsAndOemNames';
import { BomItemFormState } from './SpecificationTypeForms/BomItemFormState';

export function ConsignmentButton({
    isConsigned,
    setValue,
    submitForm,
    customerId,
    disabled = false,
}: {
    isConsigned: boolean;
    setValue: UseFormSetValue<BomItemFormState>;
    submitForm: () => void;
    customerId?: string;
    disabled?: boolean;
}) {
    const { oemName, emsName } = useEmsAndOemNames({ customerId });
    const handleChange = (value: boolean) => {
        setValue('isConsigned', value === null ? isConsigned : !isConsigned, {
            shouldDirty: true,
            shouldTouch: true,
            shouldValidate: true,
        });
        submitForm();
    };

    return (
        <Select
            size="small"
            value={isConsigned ? 'true' : 'false'}
            startAdornment={
                <InputAdornment position="start">
                    <Flexbox padding="4px 6px" borderRadius="8px" style={{ background: colorSystem.neutral[1] }}>
                        <Text variant="caption" color={colorSystem.neutral[7]} style={{ whiteSpace: 'nowrap' }}>
                            <Trans>Sourced by</Trans>
                        </Text>
                    </Flexbox>
                </InputAdornment>
            }
            style={{ maxWidth: '240px' }}
            disabled={disabled}
        >
            <MenuItem value={'false'} onClick={() => handleChange(true)}>
                <Text variant="body" color="inherit">
                    {emsName}
                </Text>
            </MenuItem>
            <MenuItem value={'true'} onClick={() => handleChange(false)}>
                <Text variant="body" color="inherit">
                    {oemName}
                </Text>
            </MenuItem>
        </Select>
    );
}
