import * as z from 'zod';
import { ApiIntegrationStatusRuntype } from './3rdPartyBackendTypes';

export interface QuestComponentsCredentialsDTO extends z.infer<typeof QuestComponentsCredentialsDTORuntype> {}
export const QuestComponentsCredentialsDTORuntype = z.object({
    api_key: z.string(),
});

export interface QuestComponentsCredentialsInputDTO extends z.infer<typeof QuestComponentsCredentialsInputDTORuntype> {}
export const QuestComponentsCredentialsInputDTORuntype = z.object({
    api_key: z.string().optional(),
});

export interface QuestComponentsResponseBodyDTO extends z.infer<typeof QuestComponentsResponseBodyDTORuntype> {}
export const QuestComponentsResponseBodyDTORuntype = z.object({
    data: QuestComponentsCredentialsDTORuntype.nullable(),
    status: ApiIntegrationStatusRuntype,
});
