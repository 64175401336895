import { t } from '@lingui/macro';
import { getToken } from '@luminovo/auth';
import { ExtractArguments, ExtractResponseBody, http, RemoveByValue, RfqContext } from '@luminovo/http-client';
import { TablePagination, Typography } from '@mui/material';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { useRef, useState } from 'react';
import { httpQueryKey } from '../../../../resources/http/httpQueryKey';
import { buildFiltersFromSearchState } from './buildFiltersFromSearchState';
import { UseComponentsSearchStateResult } from './ErpParametricSearchFunctions';

export type ComponentsSearchResultsPage = ExtractResponseBody<'POST /components/search'>;

const PAGE_SIZE = 50;

export function useComponentsParametricSearch({
    searchState,
    rfqContext,
    onAnalytic,
    prioritisePreferredIpns = false,
}: {
    searchState: UseComponentsSearchStateResult;
    rfqContext: RfqContext;
    onAnalytic?: (searchState: UseComponentsSearchStateResult) => void;
    prioritisePreferredIpns?: boolean;
}) {
    const convertedBlocks = buildFiltersFromSearchState(searchState);

    const rfq_context =
        rfqContext.type === 'WithinRfQ'
            ? { rfq_context: rfqContext.type, rfq_id: rfqContext.rfq_id }
            : { rfq_context: rfqContext.type };
    const fuzzyTerm = convertedBlocks
        .filter((b) => b.field === '*')
        .map((b) => String(b.parameter).trim())
        .filter((x) => x.length > 0)
        .join(' ');

    const body = {
        requestBody: {
            filters: convertedBlocks.filter((b) => b.field !== '*'),
            global_filter: fuzzyTerm,
            page_size: PAGE_SIZE,
            prioritise_preferred_ipns: prioritisePreferredIpns,
            page_params: undefined as number | undefined,
            ...rfq_context,
        },
    };

    return useInfiniteQuery({
        // eslint-disable-next-line @tanstack/query/exhaustive-deps
        queryKey: httpQueryKey('POST /components/search', body),
        queryFn: async ({ pageParam }): Promise<ComponentsSearchResultsPage> => {
            if (searchState.state.selectedBlocks.length > 0) {
                onAnalytic?.(searchState);
            }

            return await http(
                'POST /components/search',
                {
                    requestBody: {
                        ...body.requestBody,
                        page_params: pageParam,
                    },
                },
                getToken(),
            );
        },
        initialPageParam: undefined as number | undefined,
        getNextPageParam: (data) => data.page_params,
        // Entries can get really big really quickly, so we don't want to cache them for too long
        gcTime: 10_000,
    });
}

/***
 * With this hook, the table pagination controls the offset of the request body.
 * This is useful for the part library ipn table where we want to fetch the next page results when the user clicks on the next page button.
 */
export function useComponentsParamSearchWithTablePagination({
    searchState,
    rfqContext,
    onAnalytic,
    isEditedToday = false,
    prioritisePreferredIpns = false,
}: {
    searchState: UseComponentsSearchStateResult;
    rfqContext: RfqContext;
    onAnalytic?: (searchState: UseComponentsSearchStateResult) => void;
    isEditedToday?: boolean;
    prioritisePreferredIpns?: boolean;
}) {
    const [offset, setOffset] = useState(0);

    const convertedBlocks = buildFiltersFromSearchState(searchState);

    const scrollTargetRef = useRef<HTMLDivElement>(null);

    const rfq_context =
        rfqContext.type === 'WithinRfQ'
            ? { rfq_context: rfqContext.type, rfq_id: rfqContext.rfq_id }
            : { rfq_context: rfqContext.type };
    const fuzzyTerm = convertedBlocks
        .filter((b) => b.field === '*')
        .map((b) => String(b.parameter).trim())
        .filter((x) => x.length > 0)
        .join(' ');

    const startOfToday = new Date();
    startOfToday.setHours(0, 0, 0, 0);

    const filters = isEditedToday
        ? [
              ...convertedBlocks.filter((b) => !['*', 'last-changed'].includes(b.field)),
              {
                  field: 'last-changed',
                  operator: 'equalsAny',
                  parameter: ['Today'],
              },
          ]
        : convertedBlocks.filter((b) => b.field !== '*');

    const body: RemoveByValue<ExtractArguments<'POST /components/search'>, undefined> = {
        requestBody: {
            filters,

            global_filter: fuzzyTerm,
            page_size: PAGE_SIZE,
            page_params: offset,
            prioritise_preferred_ipns: prioritisePreferredIpns,

            ...rfq_context,
        },
    };

    const result = useQuery({
        // eslint-disable-next-line @tanstack/query/exhaustive-deps
        queryKey: httpQueryKey('POST /components/search', body),
        queryFn: async (): Promise<ComponentsSearchResultsPage> => {
            if (searchState.state.selectedBlocks.length > 0) {
                onAnalytic?.(searchState);
            }

            return await http(
                'POST /components/search',
                {
                    requestBody: body.requestBody,
                },
                getToken(),
            );
        },
    });

    const { data } = result;
    const canLoadPreviousPage = offset > 0;
    const totalHits = data?.total_hits.value ?? 0;
    const canLoadNextPage = totalHits > offset + PAGE_SIZE;

    const currentPage = Math.floor(offset / PAGE_SIZE);

    const onPageChange = (newPage: number) => {
        if (newPage < currentPage) {
            setOffset(() => offset - PAGE_SIZE);
        } else {
            setOffset(() => offset + PAGE_SIZE);
        }
        if (scrollTargetRef.current) {
            scrollTargetRef.current.scrollIntoView({ behavior: 'instant' });
        }
    };

    return {
        result,
        scrollTargetRef,
        TablePagination: (
            <TablePagination
                labelDisplayedRows={({ from, to, count }) => (
                    <Typography variant={'body1'} component="span">{t`${from}-${to} of ${count}`}</Typography>
                )}
                component="div"
                rowsPerPage={PAGE_SIZE}
                rowsPerPageOptions={[PAGE_SIZE]}
                count={totalHits}
                page={currentPage}
                nextIconButtonProps={{
                    disabled: !canLoadNextPage,
                }}
                backIconButtonProps={{
                    disabled: !canLoadPreviousPage,
                }}
                onPageChange={(_, page) => {
                    onPageChange(page);
                }}
            />
        ),
    };
}
