import { MessageDescriptor } from '@lingui/core';
import { defineMessage } from '@lingui/macro';
import { DisplayableColumnIds, PreviousLinesSearchColumns, PreviousLinesSearchVariant } from '@luminovo/http-client';

export interface Column {
    // currently, the column index
    id: string;
    label: string;
}

export type ColumnTag = {
    id: DisplayableColumnIds;
    associatedTo: Column[];
    label: string;
};

export interface TaggedSheet {
    tags: ColumnTag[];
}

export const matchingMethodTranslations: Record<PreviousLinesSearchVariant, MessageDescriptor> = {
    [PreviousLinesSearchVariant.Inactive]: defineMessage({ message: 'Inactive' }),
    [PreviousLinesSearchVariant.AllColumns]: defineMessage({
        message: 'All columns must match (incl. not tagged)',
    }),
    [PreviousLinesSearchVariant.SelectedColumns]: defineMessage({
        message: 'Select tagged columns manually',
    }),
};

export type ColumnTagsSheetsState = {
    sheets: TaggedSheet[];
    hasChanged: boolean;
    previousLinesSearch: PreviousLinesSearchColumns;
    activeTab: 'BOM' | 'AML';
    bomSheetIndex: number;
    amlSheetIndex: number | null;
};

export type ColumnTagsSheetsAction =
    | {
          type: 'assign-tag';
          tag: ColumnTag;
          column: Column;
      }
    | {
          type: 'remove-tag';
          tag: ColumnTag;
          column: Column;
      }
    | {
          type: 'set-initial-state';
          state: ColumnTagsSheetsState;
      }
    | {
          type: 'set-active-tab';
          tab: 'BOM' | 'AML';
      }
    | {
          type: 'set-bom-sheet-index';
          index: number;
      }
    | {
          type: 'set-aml-sheet-index';
          index: number | null;
      }
    | {
          type: 'display-column-mapping';
          state: ColumnTagsSheetsState;
      }
    | {
          type: 'set-previous-lines-search-variant';
          variant: PreviousLinesSearchVariant;
      }
    | {
          type: 'assign-previous-lines-search-columns';
          columns: string[];
      }
    | {
          type: 'remove-previous-lines-search-column';
          column: string;
      };
