import * as z from 'zod';
import { endpoint } from '../http/endpoint';
import { ApiRelevanceRuntype, SourcesApiRuntype } from './relevanceBackendTypes';

export const sourcingApisEndpoints = {
    'GET /sourcing-apis/relevance': endpoint({
        description: 'Returns a list of all integrations',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.array(ApiRelevanceRuntype),
    }),
    'PUT /sourcing-apis/:sourcesApi/relevance': endpoint({
        description: 'Edits an integration id',
        pathParams: z.object({ sourcesApi: SourcesApiRuntype }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.unknown(),
        invalidates: ['GET /sourcing-apis/relevance'],
    }),
    'DELETE /sourcing-apis/:sourcesApi/relevance': endpoint({
        description: 'Edits an integration id',
        pathParams: z.object({ sourcesApi: SourcesApiRuntype }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.unknown(),
        invalidates: ['GET /sourcing-apis/relevance'],
    }),
};
