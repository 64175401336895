import { t } from '@lingui/macro';
import { DestructiveSecondaryIconButton, Tooltip, useConfirmationDialog, useNavigate } from '@luminovo/design-system';
import { QuoteRequestStatus } from '@luminovo/http-client';
import { Delete } from '@mui/icons-material';
import { useHttpMutation } from '../../../resources/mutation/useHttpMutation';
import { useSuspendedQuoteRequest } from '../hooks/negotiationHandlers';
export function ButtonDeleteQuoteRequest({ quoteRequestId }: { quoteRequestId: string }) {
    const navigate = useNavigate();

    const { mutateAsync, isPending: isLoading } = useHttpMutation('DELETE /quote-request/:id', {
        snackbarMessage: t`Quote request deleted`,
        onSuccess: () => navigate(-1),
    });

    const { data: quoteRequest } = useSuspendedQuoteRequest(quoteRequestId);

    const { modal, setOpen } = useConfirmationDialog({
        title: t`Delete quote request`,
        description: t`Are you sure you want to delete this quote request?`,
        onConfirmation: () => mutateAsync({ pathParams: { id: quoteRequestId } }),
    });

    if (quoteRequest.status !== QuoteRequestStatus.NotSent) {
        return null;
    }

    return (
        <>
            {modal}
            <Tooltip title={t`Delete quote request`}>
                <DestructiveSecondaryIconButton size="medium" isLoading={isLoading} onClick={() => setOpen(true)}>
                    <Delete fontSize="inherit" />
                </DestructiveSecondaryIconButton>
            </Tooltip>
        </>
    );
}
