import { t, Trans } from '@lingui/macro';
import { colorSystem, Flexbox, Text, Tooltip } from '@luminovo/design-system';
import { InfoRounded, MoreHoriz } from '@mui/icons-material';
import { Fab, styled, TableCell } from '@mui/material';
import { UseFieldArrayInsert, UseFieldArrayRemove, UseFieldArrayUpdate } from 'react-hook-form';
import { ManufacturingCacheRefreshButton } from '../../../../components/ManufacturingCache/ManufacturingCacheRefreshButton';
import { ManufacturingCacheInfoIcon } from '../../../../components/ManufacturingCache/ManufacturingCacheText';
import { RowActionMenu } from '../RowActionMenu';
import { CalculationTableForm } from '../types/formTypes';
import { Row } from '../types/rowTypes';
import { CELL_HEIGHT } from './cells';

const StyledMoreHorizIcon = styled(MoreHoriz)({
    color: colorSystem.neutral[7],
});

const StyledFab = styled(Fab)({
    backgroundColor: colorSystem.neutral[1],
    boxShadow: 'none',
    marginTop: -10,
    marginBottom: -10,
});

const greyMenuButton = (
    <StyledFab size={'small'}>
        <StyledMoreHorizIcon fontSize="small" />
    </StyledFab>
);

const sectionHeaderCellStyle = {
    background: colorSystem.neutral[1],
    borderRight: `8px solid ${colorSystem.neutral[1]}`,
    borderBottom: `1px solid ${colorSystem.neutral[1]}`,
    height: CELL_HEIGHT,
};

export const SectionHeaderCell = ({
    index,
    insert,
    remove,
    update,
    data,
    assemblyId,
    isProjectCostsSeparateFromManufacturingCost,
    isCalculationFrozen,
}: {
    index: number;
    insert: UseFieldArrayInsert<CalculationTableForm, 'rows'>;
    remove: UseFieldArrayRemove;
    update: UseFieldArrayUpdate<CalculationTableForm, 'rows'>;
    data: Row;
    assemblyId: string;
    isProjectCostsSeparateFromManufacturingCost: boolean;
    isCalculationFrozen: boolean;
}) => {
    return (
        <TableCell style={sectionHeaderCellStyle}>
            <Flexbox justifyContent={'space-between'} alignItems={'center'}>
                <Flexbox alignItems={'center'}>
                    <Flexbox flexDirection={'column'} gap={3}>
                        <Text variant="h4" color={colorSystem.neutral[8]}>
                            {data.label}
                        </Text>
                        {data.id === 'summary-header' && isProjectCostsSeparateFromManufacturingCost && (
                            <Text variant="body-small" color={colorSystem.neutral[7]}>
                                <Trans>Doesn't include project costs.</Trans>
                            </Text>
                        )}
                    </Flexbox>
                    {data.id === 'material-cost-header' && !isCalculationFrozen && (
                        <Flexbox style={{ marginLeft: '4px' }} alignItems={'center'}>
                            <ManufacturingCacheInfoIcon assemblyId={assemblyId} />
                            <ManufacturingCacheRefreshButton assemblyId={assemblyId} />
                        </Flexbox>
                    )}
                    {data.id === 'not-included-costs-header' && (
                        <Flexbox style={{ marginLeft: '4px' }} alignItems={'center'}>
                            <Tooltip
                                variant={'white'}
                                title={t`The following costs are for information and not included in the calculation.`}
                            >
                                <InfoRounded fontSize="inherit" style={{ color: colorSystem.neutral[5] }} />
                            </Tooltip>
                        </Flexbox>
                    )}
                </Flexbox>
                {data.rowActions.length > 0 && !isCalculationFrozen && (
                    <RowActionMenu
                        insert={insert}
                        remove={remove}
                        actions={data.rowActions}
                        update={update}
                        index={index}
                        data={data}
                        button={greyMenuButton}
                    />
                )}
            </Flexbox>
        </TableCell>
    );
};
