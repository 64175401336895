import * as z from 'zod';

import { MonetaryValueBackendRuntype } from '../backendTypes';
import { PackagingRuntype } from '../offer';
import { PackageMountingEnum } from '../part';
import { PnpSideRuntype } from '../pnp';
import { ManufacturingEntityStatusRuntype } from '../sharedManufacturingBackendTypes';
import {
    AttributeReferenceRuntype,
    ConditionValuesRuntype,
    EnumFilterOperatorRuntype,
    MonetaryAttributeRuntype,
    NumberAttributeRuntype,
    NumberFilterOperatorRuntype,
    OfferTypeRuntype,
    PartTypeRuntype,
} from './driverCalculationBackendTypes';

export enum DriverDetailsType {
    Automatic = 'Automatic',
    Manual = 'Manual',
}

export enum DriverOrigin {
    Bom = 'Bom',
    Sourcing = 'Sourcing',
    Pnp = 'Pnp',
}

const AggregationTypeRuntype = z.union([z.literal('Count'), z.literal('Sum')]);
export type AggregationType = z.infer<typeof AggregationTypeRuntype>;

const ManualRuntype = z.object({
    type: z.literal(DriverDetailsType.Manual),
    automatic_details: z.null().optional(),
});

const PackageFamilyRuntype = z.object({
    type: z.literal('PackageFamily'),
    value: z.object({
        mounting: z.nativeEnum(PackageMountingEnum),
        name: z.string(),
    }),
});

const MountingOnlyRuntype = z.object({
    type: z.literal('MountingOnly'),
    value: z.nativeEnum(PackageMountingEnum),
});

const MountingPackageRuntype = z.union([PackageFamilyRuntype, MountingOnlyRuntype]);

export type MountingPackageRhs = z.infer<typeof MountingPackageRuntype>;

const SidesOfPlacementRhsRuntype = z.object({
    attribute_reference: z.literal('PnpSide'),
    value: PnpSideRuntype,
});

const OfferTypeRhsRuntype = z.object({
    attribute_reference: z.literal('OfferType'),
    value: OfferTypeRuntype,
});

const CustomPartTypeRhsRuntype = z.object({
    attribute_reference: z.literal('CustomPartType'),
    value: PartTypeRuntype,
});

const PackageRhsRuntype = z.object({
    attribute_reference: z.literal('Package'),
    value: MountingPackageRuntype,
});

const PackagingRhsRuntype = z.object({
    attribute_reference: z.literal('Packaging'),
    value: PackagingRuntype,
});

const EnumDriverFilterPropertiesRuntype = z.object({
    type: z.literal('Enum'),
    filter: z.object({
        operator: EnumFilterOperatorRuntype,
        rhs: SidesOfPlacementRhsRuntype.or(OfferTypeRhsRuntype)
            .or(CustomPartTypeRhsRuntype)
            .or(PackageRhsRuntype)
            .or(PackagingRhsRuntype),
    }),
});

const NumberDriverFilterPropertiesRuntype = z.object({
    type: z.literal('Number'),
    filter: z.object({
        operator: NumberFilterOperatorRuntype,
        attribute: NumberAttributeRuntype,
        rhs: z.string(),
    }),
});

const AnyOfAttributeRhsRuntype = z.object({
    attribute_reference: AttributeReferenceRuntype,
    value: MountingPackageRuntype,
});

const MonetaryDriverFilterPropertiesRuntype = z.object({
    type: z.literal('Monetary'),
    filter: z.object({
        operator: NumberFilterOperatorRuntype,
        attribute: MonetaryAttributeRuntype,
        rhs: MonetaryValueBackendRuntype,
    }),
});
export interface AnyOfAttributeRhsDTO extends z.infer<typeof AnyOfAttributeRhsRuntype> {}

const AnyofDriverFilterPropertiesRuntype = z.object({
    type: z.literal('AnyOf'),
    filter: z.object({
        operator: EnumFilterOperatorRuntype,
        rhs: z.array(AnyOfAttributeRhsRuntype),
    }),
});

export interface NumberDriverFilterProperties extends z.infer<typeof NumberDriverFilterPropertiesRuntype> {}
export interface AnyOfDriverFilterProperties extends z.infer<typeof AnyofDriverFilterPropertiesRuntype> {}
export interface MonetaryDriverFilterProperties extends z.infer<typeof MonetaryDriverFilterPropertiesRuntype> {}

const FilterFormulaPropertiesRuntype = z.union([
    EnumDriverFilterPropertiesRuntype,
    NumberDriverFilterPropertiesRuntype,
    AnyofDriverFilterPropertiesRuntype,
    MonetaryDriverFilterPropertiesRuntype,
]);
export type FilterFormulaPropertiesDTO = z.infer<typeof FilterFormulaPropertiesRuntype>;

const filterFormulaRuntype = z.object({
    filter: FilterFormulaPropertiesRuntype,
    join_with_previous_using: ConditionValuesRuntype,
});

export interface DriverFilterDTO extends z.infer<typeof filterFormulaRuntype> {}

const CountAggregationValueRuntype = z.union([
    z.literal('UniqueParts'),
    z.literal('DesignItems'),
    z.literal('UniqueSuppliers'),
]);
export type CountAggregationValue = z.infer<typeof CountAggregationValueRuntype>;

const CountAggregationRuntype = z.object({
    type: z.literal('Count'),
    value: CountAggregationValueRuntype,
});

const SumAggregationValueRuntype = z.union([z.literal('PartNumberOfPins'), z.literal('MaterialPrice')]);
export type SumAggregationValue = z.infer<typeof SumAggregationValueRuntype>;

const SumAggregationRuntype = z.object({
    type: z.literal('Sum'),
    value: SumAggregationValueRuntype,
});

const AggregationRuntype = CountAggregationRuntype.or(SumAggregationRuntype);
export type AutomaticDriverAggregationDTO = z.infer<typeof AggregationRuntype>;

const AutomaticDetailsRuntype = z.object({
    aggregation: AggregationRuntype,
    filter_formula: z.array(filterFormulaRuntype),
});

export interface AutomaticDetailsDTO extends z.infer<typeof AutomaticDetailsRuntype> {}

const AutomaticRuntype = z.object({
    type: z.literal(DriverDetailsType.Automatic),
    automatic_details: AutomaticDetailsRuntype,
});

const DriverDetailsPostBaseRuntype = z.object({
    name: z.string(),
    is_per_panel: z.boolean(),
    notes: z.string().nullable(),
    lexorank: z.string(),
});

export interface DriverDetailsPostBaseDTO extends z.infer<typeof DriverDetailsPostBaseRuntype> {}

const ManualDriverDetailsPostRuntype = DriverDetailsPostBaseRuntype.extend({
    details: ManualRuntype,
});

const AutomaticDriverDetailsPostRuntype = DriverDetailsPostBaseRuntype.extend({
    details: AutomaticRuntype,
});

const DriverOriginRuntype = z.union([
    z.literal(DriverOrigin.Bom),
    z.literal(DriverOrigin.Sourcing),
    z.literal(DriverOrigin.Pnp),
]);
export type DriverOriginDTO = z.infer<typeof DriverOriginRuntype>;

export const ManualDriverDetailsRuntype = ManualDriverDetailsPostRuntype.extend({
    id: z.string(),
    status: ManufacturingEntityStatusRuntype,
    origin_tags: z.array(DriverOriginRuntype).optional(),
});

export const AutomaticDriverDetailsRuntype = AutomaticDriverDetailsPostRuntype.extend({
    id: z.string(),
    status: ManufacturingEntityStatusRuntype,
    origin_tags: z.array(DriverOriginRuntype),
});

export const UserDriverDetailsDTOPatchRuntype = z.object({
    name: z.string().optional(),
    is_per_panel: z.boolean().optional(),
    details: z.union([AutomaticRuntype, ManualRuntype]).optional(),
    status: ManufacturingEntityStatusRuntype.optional(),
    notes: z.string().nullable().optional(),
    lexorank: z.string().optional(),
});
export type UserDriverDetailsPatchDTO = z.infer<typeof UserDriverDetailsDTOPatchRuntype>;
export const UserDriverDetailsBulkPatchDTORuntype = z.object({
    drivers: z.array(
        z.object({
            id: z.string(),
            update: UserDriverDetailsDTOPatchRuntype,
        }),
    ),
});
export type UserDriverDetailsBulkPatchDTO = z.infer<typeof UserDriverDetailsBulkPatchDTORuntype>;

export const UserDriverDetailsDTOPostRuntype = z.union([
    ManualDriverDetailsPostRuntype,
    AutomaticDriverDetailsPostRuntype,
]);

export type UserDriverDetailsPostDTO = z.infer<typeof UserDriverDetailsDTOPostRuntype>;

export const UserDriverDetailsRuntype = z.union([AutomaticDriverDetailsRuntype, ManualDriverDetailsRuntype]);

export type UserDriverDetailsDTO = z.infer<typeof UserDriverDetailsRuntype>;
