import * as React from 'react';
import {
    Column,
    ComparableResult,
    ComparableWithContext,
    Comparator,
    Filter,
    isPredicateFilter,
    PersistentTableState,
} from './types';
import { matchesSearch } from './useGlobalSearch';

function groupFiltersByColumn<TRowData, TContext>(
    columns: Column<TRowData, TContext>[],
    selectedFilterIds: string[],
): Array<Array<Filter<TRowData, TContext>>> {
    const result: Array<Array<Filter<TRowData, TContext>>> = [];

    for (const column of columns) {
        const { filters = [] } = column;
        const selectedFilters = filters.filter((f) => {
            const isFilterSelected = selectedFilterIds.includes(f.id);
            return isFilterSelected;
        });
        if (selectedFilters.length > 0) {
            result.push(selectedFilters);
        }
    }

    return result;
}

type Predicate<TRowData, TContext> = (el: TRowData, context: TContext) => boolean;

function createPredicateUnion<TRowData, TContext>(
    predicates: Array<Predicate<TRowData, TContext>>,
): Predicate<TRowData, TContext> {
    return (el: TRowData, sharedContext: TContext) => {
        return predicates.some((predicate) => predicate(el, sharedContext));
    };
}

/**
 * Takes the selected filters and returns a new filter that applies all the selected filters.
 */
function aggregatedPredicate<TRowData, TContext>(
    columns: Column<TRowData, TContext>[],
    selectedFilterIds: string[],
): Predicate<TRowData, TContext> {
    const groupedFilters = groupFiltersByColumn(columns, selectedFilterIds);
    const predicatesFromDifferentColumns = groupedFilters.map((filters) => {
        const predicates = filters.filter(isPredicateFilter).map((f) => f.predicate);
        return createPredicateUnion(predicates);
    });

    const composedIntersection = (el: TRowData, sharedContext: TContext) => {
        if (predicatesFromDifferentColumns.length === 0) {
            return true;
        }
        return predicatesFromDifferentColumns.every((predicate) => predicate(el, sharedContext));
    };
    return composedIntersection;
}

function invertComparable<TRowData, TContext>(
    compare: ComparableWithContext<TRowData, TContext>,
): ComparableWithContext<TRowData, TContext> {
    return (a, b, context) => compare(b, a, context);
}

/**
 * Compares two values by the given comparable and returns the maximum.
 *
 * Returns the second argument if the comparison determines them to be equal.
 */
// keeping this unused function for future consistency with the minByComparable function
// eslint-disable-next-line import/no-unused-modules
export function maxByComparable<TRowData, TContext>({
    compare,
    a,
    b,
    sharedContext,
}: {
    compare: ComparableWithContext<TRowData, TContext>;
    a: TRowData;
    b: TRowData;
    sharedContext: TContext;
}): TRowData {
    switch (compare(a, b, sharedContext)) {
        case ComparableResult.less:
            return b;
        case ComparableResult.equal:
            return b;
        case ComparableResult.greater:
            return a;
    }
}

function convertComparatorsToComparables<TRowData, TContext>(
    comp: Comparator<TRowData, TContext>,
): ComparableWithContext<TRowData, TContext> {
    if ('compare' in comp) {
        return comp.order === 'asc' ? comp.compare : invertComparable(comp.compare);
    }
    const compare: ComparableWithContext<TRowData, TContext> = (left: TRowData, right: TRowData, context: TContext) => {
        const leftValue = comp.compareBy(left, context);
        const rightValue = comp.compareBy(right, context);

        if (leftValue === rightValue) {
            return ComparableResult.equal;
        }
        if (leftValue < rightValue) {
            return ComparableResult.less;
        }
        return ComparableResult.greater;
    };

    return comp.order === 'asc' ? compare : invertComparable(compare);
}

function composeComparables<TRowData, TContext>(
    leftComparable: ComparableWithContext<TRowData, TContext>,
    rightComparable: ComparableWithContext<TRowData, TContext>,
): ComparableWithContext<TRowData, TContext> {
    return (left: TRowData, right: TRowData, context: TContext) => {
        const compareResult = leftComparable(left, right, context);
        if (compareResult !== ComparableResult.equal) {
            return compareResult;
        }
        return rightComparable(left, right, context);
    };
}

/**
 * Sorts the given `items` by the selected comparators
 */
function applySorting<TRowData, TContext>({
    items,
    columns,
    selectedComparatorIds,
    sharedContext,
}: {
    items: TRowData[];
    columns: Column<TRowData, TContext>[];
    selectedComparatorIds: string[];
    sharedContext: TContext;
}): TRowData[] {
    const comparators: Comparator<TRowData, TContext>[] = columns.flatMap((column) => column.comparators ?? []);
    const selectedComparators = comparators.filter((comparator) => selectedComparatorIds.includes(comparator.id));

    if (selectedComparators.length === 0) {
        return items;
    }

    const reducedComparable: ComparableWithContext<TRowData, TContext> = selectedComparators
        .map(convertComparatorsToComparables)
        .reduce(composeComparables);

    return items.sort((left, right) => reducedComparable(left, right, sharedContext));
}

function isColumnMatchingQuery<TRowData, TContext>({
    column,
    item,
    sharedContext,
    query,
}: {
    column: Column<TRowData, TContext> | undefined;
    item: TRowData;
    sharedContext: TContext;
    query: string;
}): boolean {
    if (!column) {
        return true;
    }
    const extractSearchText = column?.searchable?.searchBy ?? (() => '');
    const searchText = extractSearchText(item, sharedContext);
    return matchesSearch(searchText, query);
}

function matchesColumnSearch<TRowData, TContext>({
    item,
    sharedContext,
    columns,
    queries,
}: {
    item: TRowData;
    sharedContext: TContext;
    columns: Column<TRowData, TContext>[];
    queries: PersistentTableState['queries'];
}): boolean {
    for (const [columnId, query] of Object.entries(queries ?? {})) {
        const column = columns.find((column) => column.id === columnId);
        if (!isColumnMatchingQuery({ column, item, sharedContext, query })) {
            return false;
        }
    }
    return true;
}

/**
 * Applies sorting and filtering to the given `items` based on the selected comparators and filters.
 */
export function useSortedData<TRowData, TContext>({
    items,
    sharedContext,
    columns,
    state,
}: {
    items: TRowData[];
    sharedContext: TContext;
    columns: Column<TRowData, TContext>[];
    state: PersistentTableState;
}): TRowData[] {
    const itemsWithSortingApplied = React.useMemo(() => {
        return applySorting({
            items: Array.from(items),
            columns,
            selectedComparatorIds: state.selectedComparatorIds,
            sharedContext,
        });
    }, [items, columns, state.selectedComparatorIds, sharedContext]);

    const itemsWithFilteringApplied = React.useMemo(() => {
        return itemsWithSortingApplied.filter((item) =>
            aggregatedPredicate(columns, state.selectedFilterIds)(item, sharedContext),
        );
    }, [itemsWithSortingApplied, columns, state.selectedFilterIds, sharedContext]);

    const itemsWithSearchingApplied = React.useMemo(() => {
        return itemsWithFilteringApplied.filter((item) => {
            return matchesColumnSearch({ item, sharedContext, columns, queries: state.queries });
        });
    }, [itemsWithFilteringApplied, columns, state.queries, sharedContext]);

    return itemsWithSearchingApplied;
}
