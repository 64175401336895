import * as z from 'zod';
import { endpoint } from '../http/endpoint';
import { BlumeCredentialsInputDTORuntype, BlumeResponseBodyDTORuntype } from './blumeBackendTypes';

export const blumeEndpoints = {
    'GET /3rdparty/blume/credentials': endpoint({
        description: 'Returns credentials for blume integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: BlumeResponseBodyDTORuntype,
    }),
    'POST /3rdparty/blume/credentials': endpoint({
        description: 'Uploads credentials for blume API integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: BlumeCredentialsInputDTORuntype,
        responseBody: BlumeResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/blume/credentials'],
    }),
    'PATCH /3rdparty/blume/credentials': endpoint({
        description: 'Updates credentials for blume API integratoin',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: BlumeCredentialsInputDTORuntype,
        responseBody: BlumeResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/blume/credentials'],
    }),
    'DELETE /3rdparty/blume/credentials': endpoint({
        description: 'Deletes existing credentials for blume API integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({ deleted: z.number() }),
        invalidates: ['GET /3rdparty/blume/credentials'],
    }),
};
