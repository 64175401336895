import * as z from 'zod';
import { endpoint } from '../http/endpoint';
import {
    ArrowCredentialsInputDTORuntype,
    ArrowLocaleCurrencyDTORuntype,
    ArrowResponseBodyDTORuntype,
} from './arrowBackendTypes';

export const arrowEndpoints = {
    'GET /3rdparty/arrow/credentials': endpoint({
        description: 'Returns credentials for arrow integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: ArrowResponseBodyDTORuntype,
    }),
    'POST /3rdparty/arrow/credentials': endpoint({
        description: 'Uploads credentials for arrow API integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: ArrowCredentialsInputDTORuntype,
        responseBody: ArrowResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/arrow/credentials'],
    }),
    'PATCH /3rdparty/arrow/credentials': endpoint({
        description: 'Updates credentials for arrow API integratoin',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: ArrowCredentialsInputDTORuntype,
        responseBody: ArrowResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/arrow/credentials'],
    }),
    'DELETE /3rdparty/arrow/credentials': endpoint({
        description: 'Deletes existing credentials for arrow API integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({ deleted: z.number() }),
        invalidates: ['GET /3rdparty/arrow/credentials'],
    }),
    'GET /3rdparty/arrow/supported-currencies': endpoint({
        description: 'Returns possible currencies for arrow',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({ currencies: z.array(ArrowLocaleCurrencyDTORuntype) }),
    }),
};
