import * as z from 'zod';

import { MonetaryValueBackendRuntype } from '../backendTypes';
import { PackagingRuntype } from '../offer';
import { StandardPartDTORuntype } from '../part';
import { RegionsEnumRuntype } from '../supplierAndStockLocation';
import { IncotermsRuntype } from '../supplierTerms/supplierTermsBackendTypes';

export enum TotalCostOfOwnershipRuleCategory {
    Packaging = 'Packaging',
    Discount = 'Discount',
    Customs = 'Customs',
    Shipping = 'Shipping',
    Other = 'Other',
}
export const TotalCostOfOwnershipRuleCategoryRuntype = z.nativeEnum(TotalCostOfOwnershipRuleCategory);

export enum TotalCostOfOwnershipRuleConditionType {
    Packaging = 'Packaging',
    Supplier = 'Supplier',
    PartNumber = 'PartNumber',
    CountryOfOrigin = 'CountryOfOrigin',
    CustomsCode = 'CustomsCode',
    Incoterms = 'Incoterms',
    Manufacturer = 'Manufacturer',
}

const PackagingConditionRuntype = z.object({
    condition_type: z.literal(TotalCostOfOwnershipRuleConditionType.Packaging),
    value: PackagingRuntype,
});

const SupplierConditionRuntype = z.object({
    condition_type: z.literal(TotalCostOfOwnershipRuleConditionType.Supplier),
    value: z.string(),
});

const PartNumberConditionRuntype = z.object({
    condition_type: z.literal(TotalCostOfOwnershipRuleConditionType.PartNumber),
    value: StandardPartDTORuntype,
});

const CountryOfOriginConditionRuntype = z.object({
    condition_type: z.literal(TotalCostOfOwnershipRuleConditionType.CountryOfOrigin),
    value: RegionsEnumRuntype,
});

const CustomsCodeConditionRuntype = z.object({
    condition_type: z.literal(TotalCostOfOwnershipRuleConditionType.CustomsCode),
    value: z.object({
        code_type: z.enum(['Hts', 'Taric']),
        code: z.string(),
    }),
});

const IncotermsConditionRuntype = z.object({
    condition_type: z.literal(TotalCostOfOwnershipRuleConditionType.Incoterms),
    value: IncotermsRuntype,
});

const ManufacturerConditionRuntype = z.object({
    condition_type: z.literal(TotalCostOfOwnershipRuleConditionType.Manufacturer),
    value: z.string(),
});

const ConditionRuntype = z.union([
    SupplierConditionRuntype,
    PackagingConditionRuntype,
    PartNumberConditionRuntype,
    CountryOfOriginConditionRuntype,
    IncotermsConditionRuntype,
    CustomsCodeConditionRuntype,
    ManufacturerConditionRuntype,
]);

export enum TotalCostOfOwnershipScaling {
    Total = 'Total',
    PerUnit = 'PerUnit',
}

export const TotalCostOfOwnershipScalingRuntype = z.nativeEnum(TotalCostOfOwnershipScaling);

const FixedAmountAction = z.object({
    type: z.literal('Absolute'),
    value: MonetaryValueBackendRuntype,
});

const PercentageBasedAction = z.object({
    type: z.literal('Relative'),
    value: z.string(),
});

const ActionRuntype = z.union([FixedAmountAction, PercentageBasedAction]);
export type TotalCostOfOwnershipActionDTO = z.infer<typeof ActionRuntype>;

export interface TotalCostOfOwnershipRuleDTO extends z.infer<typeof TotalCostOfOwnershipRuleDTORuntype> {}
export const TotalCostOfOwnershipRuleDTORuntype = z.object({
    id: z.string(),
    name: z.string(),
    description: z.string().nullable(),
    category: TotalCostOfOwnershipRuleCategoryRuntype,
    action: ActionRuntype,
    scaling: TotalCostOfOwnershipScalingRuntype,
    conditions: z.array(ConditionRuntype),
    is_valid: z.boolean(),
    updated_at: z.string(),
});

export interface TotalCostOfOwnershipInputDTO extends z.infer<typeof TotalCostOfOwnershipInputDTORuntype> {}
export const TotalCostOfOwnershipInputDTORuntype = z.object({
    name: z.string(),
    description: z.string().nullable(),
    category: TotalCostOfOwnershipRuleCategoryRuntype,
    action: ActionRuntype,
    scaling: TotalCostOfOwnershipScalingRuntype,
    conditions: z.array(ConditionRuntype),
});
