import { t } from '@lingui/macro';
import { assertUnreachable, formatDecimal, uniqBy } from '@luminovo/commons';
import {
    ButtonGroupItem,
    Flexbox,
    PrimaryButton,
    SecondaryButton,
    TanStackTable,
    Toolbar,
} from '@luminovo/design-system';
import { PartIdTypes, PartLite, SupplierSelectionStrategyDTO } from '@luminovo/http-client';
import { Box, ButtonGroup } from '@mui/material';
import React from 'react';
import { FormContainer } from '../../../../../components/formLayouts/FormContainer';
import { LayoutCard } from '../../../../../components/LayoutCard';
import { PageLayoutCollapsibleSidebar } from '../../../../../components/PageLayoutCollapsibleSidebar';
import { useHttpMutation } from '../../../../../resources/mutation/useHttpMutation';
import { route, UrlParams } from '../../../../../utils/routes';
import { FormItemAwardScenario } from '../../../../Negotiations/pages/QuoteRequestAssignPage/FormItem';
import { NavigationSidebarSourcing } from '../../NavigationSidebarSourcing';
import {
    ButtonAddToQuoteRequest,
    FormItemSelectionStrategy,
    FormItemStrategyOptions,
    FormItemSuppliers,
    FormItemSupplierSelectionStrategy,
} from './FormItem';
import { useCustomPartOptionsTable } from './TableCustomPartOptions';
import { TableDraftQuoteRequests, useDraftQuoteRequestsTable } from './TableDraftQuoteRequests';
import { useManufacturersTable } from './TableManufacturers';
import { useOffTheShelfPartOptionsTable } from './TableOffTheShelfPartOptions';
import { QuoteRequestAssignmentFormValues } from './types';

function convertToSupplierSelectionStrategy(form: QuoteRequestAssignmentFormValues): SupplierSelectionStrategyDTO {
    switch (form.supplierSelectionStrategy.type) {
        case 'SupplierPartTypeMatches':
            return { type: 'SupplierPartTypeMatches' as const };
        case 'SupplierLineCard':
            return {
                type: 'SupplierLineCard' as const,
                part_option_selection_strategy: form.supplierSelectionStrategy.partOptionSelectionStrategy,
                ignore_empty_line_card_supplier_matches:
                    form.supplierSelectionStrategy.ignoreEmptyLineCardSupplierMatches,
                include_empty_ipns: form.supplierSelectionStrategy.includeEmptyIpns,
                include_generic_part_options: form.supplierSelectionStrategy.includeGenericPartOptions,
                include_raw_specification_parts: form.supplierSelectionStrategy.includeRawSpecificationParts,
            };
        case 'ApprovedVendorList':
            return {
                type: 'ApprovedVendorList' as const,
                part_option_selection_strategy: form.supplierSelectionStrategy.partOptionSelectionStrategy,
                ignore_empty_approved_vendor_list: form.supplierSelectionStrategy.ignoreEmptyApprovedVendorList,
                include_empty_ipns: form.supplierSelectionStrategy.includeEmptyIpns,
                include_generic_part_options: form.supplierSelectionStrategy.includeGenericPartOptions,
                include_raw_specification_parts: form.supplierSelectionStrategy.includeRawSpecificationParts,
            };
    }
}

function convertPartLiteToPartDTO(part: PartLite) {
    const { kind } = part;

    if (kind === 'OffTheShelf') {
        return { type: PartIdTypes.OffTheShelf, data: part.id };
    }
    if (kind === 'Generic') {
        return { type: PartIdTypes.Generic, data: part.id };
    }
    if (kind === 'Ipn') {
        return { type: PartIdTypes.Ipn, data: part.id };
    }
    if (kind === 'Custom') {
        return { type: PartIdTypes.Custom, data: part.id };
    }
    if (kind === 'CustomComponent') {
        return { type: PartIdTypes.CustomComponent, data: part.id };
    }
    if (kind === 'RawSpecification') {
        return { type: PartIdTypes.RawSpecification, data: part.id };
    }
    if (kind === 'Unknown') {
        throw new Error('Unknown part');
    }

    assertUnreachable(kind);
}

function InnerForm({
    negotiationId,
    lineItems,
    setNewQuoteRequestIds,
}: {
    rfqId: string;
    negotiationId: number;
    lineItems: number[];
    setNewQuoteRequestIds: React.Dispatch<React.SetStateAction<string[]>>;
}) {
    const [groupMode, setGroupMode] = React.useState<
        'manufacturers' | 'offTheShelfPartOptions' | 'customPartOptions' | 'draftQuoteRequests'
    >('manufacturers');
    const { table: manufacturersTable, isLoading: isManufacturersLoading } = useManufacturersTable(
        negotiationId,
        lineItems,
    );
    const { table: offTheShelfPartOptionsTable, isLoading: isOffTheShelfPartOptionsLoading } =
        useOffTheShelfPartOptionsTable(negotiationId, lineItems);

    const { table: customPartOptionsTable, isLoading: isCustomPartOptionsLoading } = useCustomPartOptionsTable(
        negotiationId,
        lineItems,
    );

    const { table: draftQuoteRequestsTable, isLoading: isDraftQuoteRequestsLoading } = useDraftQuoteRequestsTable({
        negotiationId,
    });

    const { mutateAsync } = useHttpMutation('POST /quote-request/draft', {
        snackbarMessage: ({ responseBody }) => {
            const count = responseBody.quote_requests.filter((q) => q.quote_request_line_items > 0).length;

            if (count === 0) {
                return { message: t`No quote requests created`, variant: 'info' };
            }

            return { message: t`Successfully created ${count} quote requests`, variant: 'success' };
        },
        delay: 3000,
        onSuccess: (data) => {
            setNewQuoteRequestIds((prev) => [...prev, ...data.quote_requests.map((q) => q.id)]);
            manufacturersTable.resetRowSelection();
            offTheShelfPartOptionsTable.resetRowSelection();
            customPartOptionsTable.resetRowSelection();
        },
    });

    const { manufacturersCount, offTheShelfPartOptionsCount, customPartOptionsCount } = React.useMemo(() => {
        return {
            manufacturersCount: isManufacturersLoading ? undefined : manufacturersTable.getCoreRowModel().rows.length,
            offTheShelfPartOptionsCount: isOffTheShelfPartOptionsLoading
                ? undefined
                : offTheShelfPartOptionsTable.getCoreRowModel().rows.length,
            customPartOptionsCount: isCustomPartOptionsLoading
                ? undefined
                : customPartOptionsTable.getCoreRowModel().rows.length,
        };
    }, [
        manufacturersTable,
        offTheShelfPartOptionsTable,
        customPartOptionsTable,
        isManufacturersLoading,
        isOffTheShelfPartOptionsLoading,
        isCustomPartOptionsLoading,
    ]);

    const draftQuoteRequestsCount = isDraftQuoteRequestsLoading
        ? undefined
        : draftQuoteRequestsTable.getCoreRowModel().rows.length;

    const onSubmit = React.useCallback(
        async (form: QuoteRequestAssignmentFormValues) => {
            const getItems = () => {
                switch (groupMode) {
                    case 'manufacturers':
                        const manufacturerRows = manufacturersTable
                            .getSelectedRowModel()
                            .rows.flatMap((row) => (row.original.type === 'row' ? [row.original] : []));

                        const itemsFromManufacturers = manufacturerRows.flatMap((row) =>
                            row.items.map((i) => ({
                                part: convertPartLiteToPartDTO(i.part),
                                part_option_id: i.part_option_id,
                            })),
                        );

                        return uniqBy(
                            itemsFromManufacturers,
                            (i) => `${i.part.type}-${i.part.data}-${i.part_option_id}`,
                        );
                    case 'offTheShelfPartOptions':
                        const offTheShelfPartOptionRows = offTheShelfPartOptionsTable
                            .getSelectedRowModel()
                            .rows.flatMap((row) => (row.original.type === 'row' ? [row.original] : []));

                        const itemsFromOffTheShelfPartOptions = offTheShelfPartOptionRows.map((row) => ({
                            part: convertPartLiteToPartDTO(row.part),
                            part_option_id: row.partOptionId,
                        }));

                        return uniqBy(
                            itemsFromOffTheShelfPartOptions,
                            (i) => `${i.part.type}-${i.part.data}-${i.part_option_id}`,
                        );
                    case 'customPartOptions':
                        const customPartOptionRows = customPartOptionsTable
                            .getSelectedRowModel()
                            .rows.flatMap((row) => (row.original.type === 'row' ? [row.original] : []));

                        const itemsFromCustomPartOptions = customPartOptionRows.map((row) => ({
                            part: convertPartLiteToPartDTO(row.part),
                            part_option_id: row.partOptionId,
                        }));

                        return uniqBy(
                            itemsFromCustomPartOptions,
                            (i) => `${i.part.type}-${i.part.data}-${i.part_option_id}`,
                        );
                    case 'draftQuoteRequests':
                        return [];
                }
            };

            await mutateAsync({
                requestBody: {
                    supplier_selection_strategy: convertToSupplierSelectionStrategy(form),
                    items: getItems(),
                    supplier_ids: form.selectedQuoteRequestSuppliers.map((supplier) => supplier.id.toString()),
                },
            });
        },
        [mutateAsync, groupMode, manufacturersTable, offTheShelfPartOptionsTable, customPartOptionsTable],
    );

    const defaultValues: QuoteRequestAssignmentFormValues = {
        negotiationId,
        selectedQuoteRequestSuppliers: [],
        supplierSelectionStrategy: {
            type: 'SupplierPartTypeMatches',
        },
    };

    const manufacturerRowSelection = manufacturersTable.getState().rowSelection;
    const offTheShelfPartOptionRowSelection = offTheShelfPartOptionsTable.getState().rowSelection;
    const customPartOptionRowSelection = customPartOptionsTable.getState().rowSelection;
    const disabled = React.useMemo(() => {
        switch (groupMode) {
            case 'manufacturers':
                return Object.values(manufacturerRowSelection).every((value) => !value);
            case 'offTheShelfPartOptions':
                return Object.values(offTheShelfPartOptionRowSelection).every((value) => !value);
            case 'customPartOptions':
                return Object.values(customPartOptionRowSelection).every((value) => !value);
            case 'draftQuoteRequests':
                return true;
        }
    }, [groupMode, manufacturerRowSelection, offTheShelfPartOptionRowSelection, customPartOptionRowSelection]);

    return (
        <FormContainer onSubmit={onSubmit} defaultValues={defaultValues} UNSAFE_disableStablePropCheck>
            <Box display="grid" gridTemplateColumns="380px 5fr" gap={'16px'} height={'100%'}>
                <LayoutCard title={undefined}>
                    <FormItemSupplierSelectionStrategy />
                    <FormItemSelectionStrategy />
                    <FormItemStrategyOptions />
                    <FormItemSuppliers />
                    <FormItemAwardScenario />
                    <ButtonAddToQuoteRequest disabled={disabled} groupMode={groupMode} />
                </LayoutCard>
                <LayoutCard
                    title={
                        <Flexbox justifyContent="space-between" width="100%">
                            <ButtonGroup size="medium">
                                <ButtonGroupItem
                                    size="medium"
                                    selected={groupMode === 'manufacturers'}
                                    onClick={() => setGroupMode('manufacturers')}
                                    count={manufacturersCount}
                                >
                                    {t`Manufacturer`}
                                </ButtonGroupItem>
                                <ButtonGroupItem
                                    size="medium"
                                    selected={groupMode === 'offTheShelfPartOptions'}
                                    onClick={() => setGroupMode('offTheShelfPartOptions')}
                                    count={offTheShelfPartOptionsCount}
                                >
                                    {t`Off the shelf part options`}
                                </ButtonGroupItem>
                                <ButtonGroupItem
                                    size="medium"
                                    selected={groupMode === 'customPartOptions'}
                                    onClick={() => setGroupMode('customPartOptions')}
                                    count={customPartOptionsCount}
                                >
                                    {t`Custom part options`}
                                </ButtonGroupItem>
                                <ButtonGroupItem
                                    size="medium"
                                    selected={groupMode === 'draftQuoteRequests'}
                                    onClick={() => setGroupMode('draftQuoteRequests')}
                                    count={draftQuoteRequestsCount}
                                >
                                    {t`Quote requests`}
                                </ButtonGroupItem>
                            </ButtonGroup>
                        </Flexbox>
                    }
                >
                    <Box height={'calc(100% - 48px)'}>
                        {groupMode === 'manufacturers' && <TanStackTable table={manufacturersTable} />}
                        {groupMode === 'offTheShelfPartOptions' && (
                            <TanStackTable table={offTheShelfPartOptionsTable} />
                        )}
                        {groupMode === 'customPartOptions' && <TanStackTable table={customPartOptionsTable} />}
                        {groupMode === 'draftQuoteRequests' && (
                            <TableDraftQuoteRequests table={draftQuoteRequestsTable} />
                        )}
                    </Box>
                </LayoutCard>
            </Box>
        </FormContainer>
    );
}

function ToolbarInner({
    rfqId,
    negotiationId,
    newQuoteRequestIds,
}: {
    rfqId: string;
    negotiationId: number;
    newQuoteRequestIds: string[];
}) {
    const count = uniqBy(newQuoteRequestIds, (id) => id).length;
    const Button = count === 0 ? SecondaryButton : PrimaryButton;

    return (
        <Toolbar
            breadcrumbs={[
                {
                    title: t`Negotiation`,
                    href: route(`/rfqs/:rfqId/sourcing/negotiations/:negotiationId/line-items`, {
                        rfqId,
                        negotiationId,
                    }),
                },
                {
                    title: t`Create quote requests`,
                },
            ]}
        >
            <Button
                size="medium"
                href={route('/rfqs/:rfqId/sourcing/negotiations/:negotiationId/quote-requests', {
                    rfqId,
                    negotiationId,
                })}
            >
                {count === 0 ? t`View quote requests` : t`View ${formatDecimal(count)} quote requests`}
            </Button>
        </Toolbar>
    );
}

export default function QuoteRequestAssignPage({
    pathParams,
    queryParams,
}: UrlParams<'/rfqs/:rfqId/sourcing/negotiations/:negotiationId/quote-requests/assign'>) {
    const [newQuoteRequestIds, setNewQuoteRequestIds] = React.useState<string[]>([]);
    const { rfqId } = pathParams;
    const negotiationId = Number(pathParams.negotiationId);
    const lineItems = queryParams.lineItems?.split(',').map(Number) ?? [];

    return (
        <PageLayoutCollapsibleSidebar
            sidebar={<NavigationSidebarSourcing rfqId={rfqId} />}
            header={
                <ToolbarInner rfqId={rfqId} negotiationId={negotiationId} newQuoteRequestIds={newQuoteRequestIds} />
            }
            layoutVariant="fullWidth"
        >
            <InnerForm
                rfqId={rfqId}
                negotiationId={negotiationId}
                lineItems={lineItems}
                setNewQuoteRequestIds={setNewQuoteRequestIds}
            />
        </PageLayoutCollapsibleSidebar>
    );
}
