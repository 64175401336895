import { t } from '@lingui/macro';
import { transEnum } from '@luminovo/commons';
import { CenteredLayout, colorSystem, Flexbox, Tag } from '@luminovo/design-system';
import { PcbAttribute, Region } from '@luminovo/pdf-extractor';
import {
    getPdfDocument,
    ImmutableRegionNetwork,
    PdfViewer,
    PdfViewerContextProvider,
    scrollToRegions,
    usePdfViewerState,
} from '@luminovo/sourcing-core';
import { CircularProgress } from '@mui/material';
import { ErrorBoundary } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import { pdfExtractionTranslations } from '../../../../resources/pcb/i18n';
import { NotFoundCard } from '../../../Error/NotFoundCard';

interface Props {
    pathToPdf: string;
    regions: Region<PcbAttribute>[];
    onClose?: () => void;
}

export function PcbPdfViewer(props: Props) {
    // Wrap the component in a context provider so that the pdf viewer state is available to all components
    return (
        <PdfViewerContextProvider>
            <PcbPdfViewerInner {...props} />
        </PdfViewerContextProvider>
    );
}

function PcbPdfViewerInner({ pathToPdf, regions, onClose }: Props) {
    const [, dispatch] = usePdfViewerState();
    const {
        data: pdf,
        isLoading,
        error,
    } = useQuery({
        queryKey: [pathToPdf, regions],
        queryFn: async () => {
            const pdf = await getPdfDocument(pathToPdf);
            dispatch({
                type: 'setRegions',
                regions: new ImmutableRegionNetwork((regions ?? []) as any),
            });
            //@ts-ignore
            scrollToRegions(regions);
            return pdf;
        },
        enabled: Boolean(pathToPdf),
    });

    if (error) {
        const is403 = (error as any)?.status === 403;
        return (
            <CenteredLayout>
                <NotFoundCard
                    title={t`Error loading PDF`}
                    description={
                        is403
                            ? t`Sorry for the inconvenience, You don't have permission to view this PDF. Contact an admin to get access.`
                            : t`Sorry for the inconvenience, we couldn't load this PDF. Please try again later.`
                    }
                    cta={t`Close`}
                    onClick={onClose}
                />
            </CenteredLayout>
        );
    }

    if (!pdf || isLoading || pdf.numPages === 0) {
        return (
            <CenteredLayout>
                <CircularProgress />
            </CenteredLayout>
        );
    }

    if (!pdf.getPage || typeof pdf.getPage !== 'function') {
        return (
            <CenteredLayout>
                <NotFoundCard
                    title={t`Error rendering PDF`}
                    description={t`Sorry for the inconvenience, please try again.`}
                    cta={t`Close`}
                    onClick={onClose}
                />
            </CenteredLayout>
        );
    }

    return (
        <ErrorBoundary
            fallback={() => (
                <CenteredLayout>
                    <NotFoundCard
                        title={t`Error rendering PDF`}
                        description={t`Sorry for the inconvenience, please try again.`}
                        cta={t`Close`}
                        onClick={onClose}
                    />
                </CenteredLayout>
            )}
        >
            <PdfViewer
                pdf={pdf}
                pdfViewerOptions={{
                    regionTooltip({ region }) {
                        return (
                            <Flexbox gap={'4px'} flexWrap={'wrap'}>
                                {region.attributes.map((attribute) => (
                                    <Tag
                                        key={attribute.attr}
                                        color="violet"
                                        label={`${transEnum(attribute.attr, pdfExtractionTranslations)}: ${
                                            attribute.value
                                        }`}
                                    />
                                ))}
                            </Flexbox>
                        );
                    },
                    actionStyles: {
                        position: 'fixed',
                        top: 'unset',
                        bottom: 40,
                        left: '70%',
                        transform: 'translateX(-50%)',
                        background: colorSystem.neutral[8],
                        padding: '8px',
                        borderRadius: 8,
                    },
                }}
            />
        </ErrorBoundary>
    );
}
