import { Trans } from '@lingui/macro';
import { formatDecimal } from '@luminovo/commons';
import { colorSystem, Flexbox, StatusChip, Text, Tooltip } from '@luminovo/design-system';
import { FullPart, isOtsComponentFull } from '@luminovo/http-client';
import { InfoOutlined } from '@mui/icons-material';
import { Box, Skeleton } from '@mui/material';
import React from 'react';
import { useCurrentUserDetailsContext } from '../contexts/CurrentUserDetailsContext';
import { UseStockAvailabilityInfoResult } from './usePartAvailability';

interface Props {
    part: FullPart;
    availability: UseStockAvailabilityInfoResult;
    disabled?: boolean;
}

export const PartAvailabilityLabel = React.memo(function PartAvailabilityLabel({
    part,
    availability,
    disabled = false,
}: Props) {
    if (!availability.stock.external.last_updated) {
        return <Skeleton style={{ cursor: 'progress' }} width={70} />;
    }

    const selected = labelsByAvailabilityType[availability.type];

    const tooltip = isOtsComponentFull(part) ? <IpnTooltip {...availability} /> : <PartTooltip {...availability} />;

    return (
        <Tooltip variant={'white'} title={tooltip}>
            <span>
                <StatusChip label={selected.label} color={selected.color} disabled={disabled} enableHoverEffect />
            </span>
        </Tooltip>
    );
});

function PartTooltip({ maxRequiredStock, stock }: UseStockAvailabilityInfoResult) {
    const tenantName = useCurrentUserDetailsContext().organization.name;
    const { external, inventory } = stock;

    const hasInventory = Boolean(inventory.quantity?.quantity);

    return (
        <React.Suspense fallback={<Skeleton style={{ cursor: 'progress' }} width={70} />}>
            <Flexbox flexDirection={'column'} gap={8}>
                <Box
                    display="grid"
                    gridTemplateColumns={'auto 1fr'}
                    columnGap={'8px'}
                    rowGap={'4px'}
                    padding={hasInventory ? '16px 16px 0' : '16px'}
                >
                    <Text variant="body-small-semibold">
                        <Trans>Required stock</Trans>
                    </Text>
                    <Text variant="body-small" style={{ textAlign: 'end' }}>
                        {formatDecimal(maxRequiredStock)}
                    </Text>

                    <Text variant="body-small-semibold">
                        <Trans>Available stock</Trans>
                    </Text>
                    <Text style={{ textAlign: 'end' }}> </Text>

                    <Text variant="body-small" style={{ marginLeft: 4 }}>
                        <Trans>External stock</Trans>
                    </Text>
                    <Text variant="body-small" style={{ textAlign: 'end' }}>
                        {formatDecimal(external.quantity?.quantity ?? 0)}
                    </Text>

                    {!hasInventory && (
                        <>
                            <Text variant="body-small" style={{ marginLeft: 4 }}>
                                {tenantName} <Trans>Inventory</Trans>
                            </Text>
                            <Text variant="body-small" style={{ textAlign: 'end' }}>
                                {formatDecimal(inventory.quantity?.quantity ?? 0)}
                            </Text>
                        </>
                    )}
                </Box>

                {hasInventory && (
                    <Flexbox
                        flexDirection={'column'}
                        gap={8}
                        style={{ background: colorSystem.neutral[1] }}
                        padding={'12px 16px 16px 12px'}
                    >
                        <Box
                            display="grid"
                            columnGap={'4px'}
                            alignItems={'center'}
                            gridTemplateColumns="auto 1fr auto"
                            width={'100%'}
                        >
                            <Text color={colorSystem.neutral[7]} variant="body-small-semibold">
                                <Trans>Inventory</Trans>
                            </Text>
                            <InfoOutlined style={{ fontSize: '16px' }} />
                            <Text color={colorSystem.neutral[7]} variant="body-small-semibold" style={{ fontSize: 12 }}>
                                {formatDecimal(inventory.quantity?.quantity ?? 0)}
                            </Text>
                        </Box>
                        <Flexbox alignItems={'center'} gap={4}>
                            <Text variant="caption" color={colorSystem.neutral[7]}>
                                <Trans>Add {tenantName}’s internal part number to access their stock.</Trans>
                            </Text>
                        </Flexbox>
                    </Flexbox>
                )}
            </Flexbox>
        </React.Suspense>
    );
}

function IpnTooltip({ maxRequiredStock, stock }: UseStockAvailabilityInfoResult) {
    const tenantName = useCurrentUserDetailsContext().organization.name;
    const { external, inventory } = stock;
    return (
        <Box display="grid" padding="16px" gridTemplateColumns={'auto 1fr'} columnGap={'8px'} rowGap={'4px'}>
            <Text variant="body-small-semibold">
                <Trans>Required stock</Trans>
            </Text>
            <Text variant="body-small" style={{ textAlign: 'end' }}>
                {formatDecimal(maxRequiredStock)}
            </Text>

            <Text variant="body-small-semibold">
                <Trans>Available stock</Trans>
            </Text>
            <Text variant="body-small" style={{ textAlign: 'end' }}>
                {' '}
            </Text>

            <Text variant="body-small" style={{ marginLeft: 4 }}>
                <Trans>External stock</Trans>
            </Text>
            <Text variant="body-small" style={{ textAlign: 'end' }}>
                {formatDecimal(external.quantity?.quantity ?? 0)}
            </Text>

            <Text variant="body-small" style={{ marginLeft: 4 }}>
                {tenantName} <Trans>Inventory</Trans>
            </Text>
            <Text variant="body-small" style={{ textAlign: 'end' }}>
                {formatDecimal(inventory.quantity?.quantity ?? 0)}
            </Text>
        </Box>
    );
}

interface AvalabilityLabelProps {
    label: JSX.Element;
    color: 'green' | 'yellow' | 'red';
}

const labelsByAvailabilityType: {
    inStock: AvalabilityLabelProps;
    insufficientStock: AvalabilityLabelProps;
    outOfStock: AvalabilityLabelProps;
} = {
    inStock: {
        label: <Trans>In stock</Trans>,
        color: 'green',
    },
    insufficientStock: {
        label: <Trans>Insufficient stock</Trans>,
        color: 'yellow',
    },
    outOfStock: {
        label: <Trans>Out of stock</Trans>,
        color: 'red',
    },
};
