import * as z from 'zod';
import { Currency } from './currencyBackendTypes';

export const RowRuntype = z.record(z.string(), z.string().nullable());
export type RowRecord = Record<string, string | null>;

export type ObjectResponse<ResponseType> = {
    data: ResponseType;
};

export type ObjectResponseItems<ResponseType> = {
    items: ResponseType[];
};

export type PaginatedResponse<ResponseType> = {
    data: ResponseType[];
    total_pages: number;
};

export interface ModuleIdentifier extends z.infer<typeof ModuleIdentifierRuntype> {}
export const ModuleIdentifierRuntype = z.object({
    designator: z.string(),
    id: z.string(),
});

export interface ParentAssembly extends z.infer<typeof ParentAssemblyRuntype> {}
export const ParentAssemblyRuntype = z.object({
    id: z.string(),
    designator: z.string(),
    parents: z.array(z.string()),
});

export interface ParentsDTO extends z.infer<typeof ParentsDTORuntype> {}
export const ParentsDTORuntype = z.object({
    multi_parent_items: z.array(z.array(ParentAssemblyRuntype)),
});

export enum QuantityUnit {
    Kg = 'Kg',
    Meters = 'Meters',
    Liters = 'Liters',
    Pieces = 'Pieces',
}
export type QuantityUnitType = z.infer<typeof QuantityUnitRuntype>;
export const QuantityUnitRuntype = z.enum(['Kg', 'Meters', 'Liters', 'Pieces']);

export type QuantityUnitDTO = z.infer<typeof QuantityUnitDTORuntype>;
export const QuantityUnitDTORuntype = z.object({
    unit: QuantityUnitRuntype,
    quantity: z.number(),
});

export interface ExcelLineType extends z.infer<typeof ExcelLinesRuntype> {}
export const ExcelLinesRuntype = z.array(
    z.object({
        line_number: z.number(),
        raw_original_line: RowRuntype,
    }),
);

export interface DesignItemExcelOrigin extends z.infer<typeof DesignItemExcelOriginRuntype> {}
export const DesignItemExcelOriginRuntype = z.object({
    bom_file_id: z.string(),
    design_item_edited_since_import: z.boolean(),
    excel_lines: ExcelLinesRuntype,
});

export interface DesignItemExcelOriginV2 extends z.infer<typeof DesignItemExcelOriginV2Runtype> {}
export const DesignItemExcelOriginV2Runtype = z.object({
    design_item_edited_since_import: z.boolean(),
    excel_lines_id: z.string(),
});

export enum DesignItemOriginTypesNew {
    Manual = 'Manual',
    ExcelFile = 'ExcelFile',
    ExcelFileV2 = 'ExcelFileV2',
    PCB = 'PCB',
    AssemblyImport = 'AssemblyImport',
}

export enum DesignItemOriginTypes {
    Manual = 'Manual',
    ExcelFile = 'ExcelFile',
    PCB = 'PCB',
    AssemblyImport = 'AssemblyImport',
}
export const DesignItemOriginTypesRuntype = z.nativeEnum(DesignItemOriginTypes);

export type DesignItemOriginDTONew = z.infer<typeof DesignItemOriginDTONewRuntype>;
export const DesignItemOriginDTONewRuntype = z.union([
    z.object({
        type: z.literal(DesignItemOriginTypesNew.Manual),
    }),
    z.object({
        type: z.literal(DesignItemOriginTypesNew.AssemblyImport),
    }),
    z.object({
        type: z.literal(DesignItemOriginTypesNew.ExcelFile),
        data: DesignItemExcelOriginRuntype,
    }),
    z.object({
        type: z.literal(DesignItemOriginTypesNew.ExcelFileV2),
        data: DesignItemExcelOriginV2Runtype,
    }),
    z.object({
        type: z.literal(DesignItemOriginTypesNew.PCB),
        data: z.string().nullable(),
    }),
]);

export type DesignItemOriginDTO = z.infer<typeof DesignItemOriginDTORuntype>;
export const DesignItemOriginDTORuntype = z.union([
    z.object({
        type: z.literal(DesignItemOriginTypes.Manual),
    }),
    z.object({
        type: z.literal(DesignItemOriginTypes.ExcelFile),
        data: DesignItemExcelOriginRuntype,
    }),
    z.object({
        type: z.literal(DesignItemOriginTypes.PCB),
        data: z.string().nullable(),
    }),
    z.object({
        type: z.literal(DesignItemOriginTypes.AssemblyImport),
    }),
]);

export enum ApprovalStatus {
    Rejected = 'Rejected',
    Pending = 'Pending',
    Approved = 'Approved',
}

export enum DoNotPlace {
    DNP = 'DNP',
    Warning = 'Warning',
}

export enum ManufacturingScenarioStatus {
    Warning = 'Warning',
}

export const ApprovalStatusRuntype = z.nativeEnum(ApprovalStatus);

export type BomItemStatus = ApprovalStatus | DoNotPlace | ManufacturingScenarioStatus;
export enum OfferOriginEnum {
    Manual = 'Manual',
    QuoteRequest = 'QuoteRequest',
    Octopart = 'Octopart',
    TrustedParts = 'TrustedParts',
    Farnell = 'Farnell',
    Newark = 'Newark',
    Element14 = 'Element14',
    Buerklin = 'Buerklin',
    Mouser = 'Mouser',
    MyArrow = 'MyArrow',
    Arrow = 'Arrow',
    Schukat = 'Schukat',
    Tme = 'Tme',
    Weltron = 'Weltron',
    Gudeco = 'Gudeco',
    Future = 'Future',
    Rutronik = 'Rutronik',
    AvnetAbacus = 'AvnetAbacus',
    AvnetApac = 'AvnetApac',
    AvnetSilica = 'AvnetSilica',
    AvnetEbv = 'AvnetEbv',
    AvnetUsa = 'AvnetUsa',

    Digikey = 'Digikey',

    Orbweaver = 'Orbweaver',

    WuerthEisos = 'WuerthEisos',
    Import = 'Import',
    Consigned = 'Consigned',

    Wuerth = 'Wuerth',
    BetaLayout = 'BetaLayout',
    IBRRingler = 'IBRRingler',

    SafePcb = 'SafePcb',
    APCT = 'APCT',
    Gatema = 'Gatema',
    Alba = 'Alba',
    MultiCB = 'MultiCB',

    Stackrate = 'Stackrate',
    Lcsc = 'Lcsc',
    ChipCart = 'ChipCart',
    Eve = 'Eve',
    Ti = 'Ti',
    Tti = 'Tti',
    Samtec = 'Samtec',
    Blume = 'Blume',
    AlElektronik = 'AlElektronik',
    OnlineComponents = 'Onlinecomponents',
    Corestaff = 'Corestaff',
    Master = 'Master',
    Sourcengine = 'Sourcengine',
    Venkel = 'Venkel',
    Rochester = 'Rochester',
    WinSource = 'WinSource',
    QuestComponents = 'QuestComponents',
    RsComponents = 'RsComponents',
    Sos = 'Sos',
    Heilind = 'Heilind',
}

export interface EmissionData extends z.infer<typeof EmissionDataRuntype> {}

export const EmissionDataRuntype = z.object({
    product_phase_gwp_in_kg_co2e_min: z.string(),
    product_phase_gwp_in_kg_co2e_max: z.string(),
});

export enum ComplianceStatus {
    Compliant = 'Compliant',
    CompliantWithExemption = 'CompliantWithExemption',
    NonCompliant = 'NonCompliant',
    NotRequired = 'NotRequired',
    Unknown = 'Unknown',
}

export const ComplianceStatusRuntype = z.nativeEnum(ComplianceStatus);

export enum RohsVersion {
    Rohs_2002_95_EC = 'Rohs_2002_95_EC',
    Rohs2_2011_65_EU = 'Rohs2_2011_65_EU',
    Rohs2_2015_863_EU = 'Rohs2_2015_863_EU',
}
export const RohsVersionRuntype = z.nativeEnum(RohsVersion);

export enum Qualification {
    DLA = 'DLA',
    RadHard = 'RadHard',
    Space = 'Space',
    UL = 'UL',
}
export const QualificationRuntype = z.nativeEnum(Qualification);

export const CurrencyRuntype = z.nativeEnum(Currency);

export type Option<ValueType> = ValueType | null;

export interface MonetaryValueBackend extends z.infer<typeof MonetaryValueBackendRuntype> {}

export const MonetaryValueBackendRuntype = z.object({
    amount: z.string(),
    currency: CurrencyRuntype,
});

export interface ExchangedMonetaryValueBackend extends z.infer<typeof ExchangedMonetaryValueBackendRuntype> {}

export const ExchangedMonetaryValueBackendRuntype = z.object({
    converted_mv: MonetaryValueBackendRuntype,
    original_mv: MonetaryValueBackendRuntype,
});

export const ExchangedMonetaryValueBackendConvertedMVOnlyRuntype = ExchangedMonetaryValueBackendRuntype.omit({
    original_mv: true,
});

export const ExchangedMonetaryValueBackendOriginalMVOnlyRuntype = ExchangedMonetaryValueBackendRuntype.omit({
    converted_mv: true,
});

export type ExchangedMonetaryValueBackendConvertedMVOnlyDTO = z.infer<
    typeof ExchangedMonetaryValueBackendConvertedMVOnlyRuntype
>;

export interface ElasticTotalHitsDTO extends z.infer<typeof ElasticTotalHitsDTORuntype> {}
export const ElasticTotalHitsDTORuntype = z.object({
    value: z.number(),
    relation: z.enum(['eq', 'gt', 'gte']),
});
