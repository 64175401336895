import { useHttpQuery } from '@/resources/http/useHttpQuery';
import { t } from '@lingui/macro';
import { useHasPermission } from '@luminovo/auth';
import { compareByString, formatDecimal } from '@luminovo/commons';
import {
    CenteredLayout,
    createColumnHelper,
    Flexbox,
    NonIdealState,
    PrimaryButton,
    TanStackTable,
    useTanStackTable,
} from '@luminovo/design-system';
import { SupplierAndStockLocationDTO, SupplierLineCardManufacturerDTO } from '@luminovo/http-client';
import { LineCardSourceChip } from '@luminovo/sourcing-core';
import { Close } from '@mui/icons-material';
import { Box } from '@mui/material';
import React from 'react';
import { useDialogEditSupplierLineCard } from './SupplierLineCardDialog/EditSupplierLineCardDialog';

type SupplierLineCardTableData = SupplierLineCardManufacturerDTO;
type SupplierLineCardTableSharedContext = {
    supplierAndStockLocation: SupplierAndStockLocationDTO;
};

const columnHelper = createColumnHelper<SupplierLineCardTableData, SupplierLineCardTableSharedContext>();

const columns = [
    columnHelper.text('name', {
        id: 'manufacturer',
        label: () => t`Manufacturer`,
        size: 140,
        cell: (item) => item.getValue(),
    }),
    columnHelper.number('part_count', {
        id: 'partCount',
        label: () => t`# Parts`,
        size: 100,
        description: () => t`Number of parts for this manufacturer`,
        cell: (item) => formatDecimal(item.getValue()),
    }),
    columnHelper.text('source', {
        id: 'source',
        label: () => t`Origin`,
        size: 40,
        cell: ({ row }) => <LineCardSourceChip source={row.original.source} />,
    }),
];

function EmptyPlaceholder() {
    return (
        <CenteredLayout>
            <NonIdealState
                Icon={Close}
                title={t`Empty line card`}
                description={t`This supplier doesn't support any manufacturers.`}
            />
        </CenteredLayout>
    );
}

const ActionButton: React.FunctionComponent<{
    sharedContext: SupplierLineCardTableSharedContext;
}> = ({ sharedContext }) => {
    const { openDialog } = useDialogEditSupplierLineCard();
    const is_admin = useHasPermission(['edit:organization_settings']);

    return (
        <Flexbox gap={8}>
            {is_admin && (
                <PrimaryButton
                    size="medium"
                    onClick={() => openDialog(sharedContext.supplierAndStockLocation.supplier.id)}
                >
                    {t`Edit line card`}
                </PrimaryButton>
            )}
        </Flexbox>
    );
};

export function SupplierLineCardTab({
    supplierAndStockLocation,
}: {
    supplierAndStockLocation: SupplierAndStockLocationDTO;
}) {
    const { data } = useHttpQuery(
        'GET /suppliers/:id/line-card',
        {
            pathParams: { id: supplierAndStockLocation.supplier.id },
        },
        {
            select: (res) => res.manufacturers.sort((a, b) => compareByString(a.name, b.name)) ?? [],
        },
    );

    const { table } = useTanStackTable({
        columns,
        data,
        sharedContext: {
            supplierAndStockLocation,
        },
        enableColumnHiding: true,
        enableColumnOrdering: true,
        enablePersistentColumnFilters: true,
        enablePersistentGlobalFilter: true,
        enablePersistentColumnVisibility: true,
        enablePersistentScrollPosition: false,
    });

    return (
        <Flexbox flexDirection={'column'} gap={18} height={'100%'}>
            <Box height={'100%'}>
                <TanStackTable
                    table={table}
                    size="medium"
                    EmptyPlaceholder={EmptyPlaceholder}
                    ActionButton={ActionButton}
                />
            </Box>
        </Flexbox>
    );
}
