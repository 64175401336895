import { t } from '@lingui/macro';
import { formatLongDateTime, formatRelativeTime } from '@luminovo/commons';
import { Delete, Reply } from '@mui/icons-material';
import { styled } from '@mui/material';
import React from 'react';
import { colorSystem } from '../../../theme';
import { AvatarIcon } from '../../AvatarIcon';
import { useConfirmationDialog } from '../../Dialog/hooks/useConfirmationDialog';
import { Flexbox } from '../../Flexbox';
import { Text } from '../../Text';
import { Tooltip } from '../../Tooltip';
import { DestructiveSecondaryIconButton, SecondaryIconButton } from '../../buttons';
import { Comment, MentionComponent } from '../types';
import { renderMarkup } from '../utils/renderMarkup';

export const CommentItem = React.memo(
    ({
        comment,
        onReply,
        onDelete,
        overrides,
    }: {
        comment: Comment;
        onReply: (comment: Comment) => void;
        onEdit: (comment: Comment) => void;
        onDelete: (comment: Comment) => void;
        overrides: { MentionComponent: MentionComponent };
    }): JSX.Element => {
        const renderedMarkup = renderMarkup(comment.content, overrides);
        const { isCurrent } = comment.author;

        const authorNameArray = comment.author.label.split(' ').map((n) => n.trim());
        return (
            <Container>
                <AvatarIcon
                    size="small"
                    user={{ email: '', firstName: authorNameArray[0] ?? '', lastName: authorNameArray[1] ?? '' }}
                />
                <Flexbox flexDirection={'column'} gap={4}>
                    <Flexbox alignItems={'center'} gap={8}>
                        <Text
                            variant="body-small-semibold"
                            style={{ flexGrow: 1 }}
                            color={comment.resolvedAt === null ? colorSystem.neutral[8] : colorSystem.neutral[6]}
                        >
                            {comment.author.label}{' '}
                            <Tooltip title={formatLongDateTime(comment.updatedAt)}>
                                <Text
                                    variant="body-small"
                                    style={{ marginLeft: 4, whiteSpace: 'pre' }}
                                    color={colorSystem.neutral[6]}
                                >
                                    {formatRelativeTime(comment.updatedAt)}
                                </Text>
                            </Tooltip>
                        </Text>
                        <Flexbox gap={2}>
                            <Tooltip title={t`Reply`}>
                                <SecondaryIconButton
                                    onClick={() => onReply(comment)}
                                    className="btn-actions"
                                    size="medium"
                                    style={{ width: 20, height: 20 }}
                                >
                                    <Reply style={{ fontSize: 12 }} />
                                </SecondaryIconButton>
                            </Tooltip>
                            {isCurrent && <ButtonDelete onDelete={() => onDelete(comment)} />}
                        </Flexbox>
                    </Flexbox>

                    <Text
                        style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}
                        color={comment.resolvedAt === null ? colorSystem.neutral[7] : colorSystem.neutral[5]}
                    >
                        {renderedMarkup}
                    </Text>
                </Flexbox>
            </Container>
        );
    },
);

function ButtonDelete({ onDelete }: { onDelete: () => void }): JSX.Element {
    const { modal, setOpen } = useConfirmationDialog({
        title: t`Delete comment`,
        description: t`Are you sure you want to delete this comment? This cannot be undone.`,
        onConfirmation: () => onDelete(),
    });
    return (
        <>
            {modal}
            <Tooltip title={t`Delete`}>
                <DestructiveSecondaryIconButton
                    //here
                    className="btn-actions"
                    size="medium"
                    style={{ width: 20, height: 20 }}
                    onClick={() => setOpen(true)}
                >
                    <Delete style={{ fontSize: 12 }} />
                </DestructiveSecondaryIconButton>
            </Tooltip>
        </>
    );
}

const Container = styled('div')({
    display: 'grid',
    width: '100%',
    alignItems: 'baseline',
    gridTemplateColumns: 'auto 1fr',
    gridColumnGap: 8,
    '& .btn-actions': {
        visibility: 'hidden',
    },
    '&:hover .btn-actions': {
        visibility: 'visible',
    },
});
