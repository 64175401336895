import { t } from '@lingui/macro';
import { assertUnreachable } from '@luminovo/commons';
import { colorSystem, Flexbox, SecondaryButton, Text } from '@luminovo/design-system';
import { SimpleOffersDTO } from '@luminovo/http-client';
import { CrisisAlert } from '@mui/icons-material';
import { Box } from '@mui/material';
import React from 'react';
import { convertOfferErrorToText, PriceRadarViolation } from '../utils/convertOfferErrorToText';

export const OfferErrorBox = ({
    children,
    title,
    Icon = CrisisAlert,
}: {
    children?: React.ReactNode;
    title: string;
    Icon?: React.ComponentType<{ style: React.CSSProperties }>;
}) => {
    return (
        <Box
            style={{
                background: colorSystem.neutral.white,
                borderRadius: '8px',
                padding: '16px',
            }}
        >
            <Flexbox alignItems={'center'} flexDirection={'column'} gap={'8px'}>
                <Icon
                    style={{
                        width: '40px',
                        height: '40px',
                        color: colorSystem.neutral[5],
                    }}
                />
                <Text variant={'h4'}>{title}</Text>
                {children}
            </Flexbox>
        </Box>
    );
};

export const OffersError = ({ offers }: { offers: SimpleOffersDTO }) => {
    const errorText = convertOfferErrorToText(offers.data);
    const [showDetails, setShowDetails] = React.useState(false);

    if (errorText === undefined) return null;

    return (
        <>
            <SecondaryButton
                size="small"
                onClick={() => setShowDetails(!showDetails)}
                style={{
                    marginBlockStart: '8px',
                }}
            >
                {showDetails ? t`Hide details` : t`Show details`}
            </SecondaryButton>
            {showDetails === true && <SupplierViolationList errors={errorText} />}
        </>
    );
};

export const SupplierViolationList = ({ errors }: { errors: PriceRadarViolation[] }) => {
    const length = errors.length;
    return (
        <ul style={{ paddingInlineStart: '16px' }}>
            {errors.map((error, index) => (
                <SupplierViolationListEntry key={index} error={error} isLast={index === length - 1} />
            ))}
        </ul>
    );
};

export const SupplierViolationListEntry = ({ error, isLast }: { error: PriceRadarViolation; isLast: boolean }) => {
    const style: React.CSSProperties = {};
    if (!isLast) {
        style.marginBlockEnd = '16px';
    }

    const errorType = error.type;
    switch (errorType) {
        case 'capability':
            return (
                <li style={style}>
                    <Text
                        variant="body-small"
                        style={{
                            color: colorSystem.red[7],
                            background: colorSystem.neutral[1],
                            border: `1px solid ${colorSystem.neutral[2]}`,
                            borderRadius: '4px',
                            padding: '2px 4px',
                            marginInlineEnd: '1ch',
                        }}
                    >
                        {error.name}
                    </Text>
                    <Text variant="body-small">{error.message}</Text>
                </li>
            );
        case 'price_break':
            return (
                <li style={style}>
                    <Text variant="body-small">{error.message}</Text>
                </li>
            );
        case 'error':
            return (
                <li style={style}>
                    <Text variant="body-small">{error.message}</Text>
                </li>
            );
        default:
            assertUnreachable(errorType);
    }
};
