import { isPresent } from '@luminovo/commons';
import { useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { httpQueryKey } from '../../resources/http/httpQueryKey';
import { useHttpQuery } from '../../resources/http/useHttpQuery';
import { AlertPartialResults } from './AlertPartialResults';

export const AlertLoadingRfqOffers: React.FunctionComponent<{
    rfqId: string;
    partIds: string[] | null;
    Placeholder?: React.ComponentType;
}> = ({ rfqId, partIds, Placeholder }) => {
    const queryClient = useQueryClient();
    const [oldTotalCount, setOldTotalCount] = React.useState(Infinity);

    const { data } = useHttpQuery(
        'GET /sourcing/progress/:rfqId',
        {
            pathParams: { rfqId },
        },
        {
            refetchInterval: ({ state }) => {
                if (!state.data) {
                    return 5000;
                }
                if (state.data.total > 0) {
                    return 5000;
                }
                return false;
            },
            meta: { globalErrorHandler: false },
        },
    );

    React.useEffect(() => {
        if (isPresent(data) && data.total !== oldTotalCount) {
            setOldTotalCount(data.total);
            queryClient.invalidateQueries({ queryKey: httpQueryKey('GET /solutions') });
        }
    }, [data, oldTotalCount, queryClient]);

    return <AlertPartialResults data={data} partIds={partIds} Placeholder={Placeholder} />;
};
