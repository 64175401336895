import { t } from '@lingui/macro';
import { assertUnreachable, formatList, getLocale } from '@luminovo/commons';
import { FieldSelectControlled, Flexbox, FormItem, Text } from '@luminovo/design-system';
import { ColumnMappingAttemptDTO, ExtractRequestBody } from '@luminovo/http-client';
import { useFormContext } from 'react-hook-form';
import { FormContainer, ValidationErrors } from '../../../components/formLayouts/FormContainer';
import { SubmitButton } from '../../../components/formLayouts/SubmitButton';
import { useHttpMutation } from '../../../resources/mutation/useHttpMutation';
import { convertCSVLinesToCSVContent } from '../types/typeConversions';
import { CSVColumn, CSVLines } from '../types/types';

type ColumnMappingFormStructure = {
    rowMapping: {
        designator: CSVColumn;
        side?: CSVColumn;
    };
};

const renderLabel = (option: CSVColumn) => `column - ${option.columnNumber} - ${option.content}`;

const renderOption = (option: CSVColumn) => (
    <Text
        style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            width: '100%',
        }}
    >{`column - ${option.columnNumber} - ${option.content}`}</Text>
);

const DesignatorColumnField = ({ columns }: { columns: CSVColumn[] }): JSX.Element => {
    const { control } = useFormContext<ColumnMappingFormStructure>();

    return (
        <FormItem label={t`Designator column`}>
            <FieldSelectControlled
                name={'rowMapping.designator'}
                control={control}
                required={true}
                FieldProps={{
                    options: columns,
                    style: { width: '400px' },
                    renderOption: renderOption,
                    getOptionLabel: renderLabel,
                }}
            />
        </FormItem>
    );
};

const SideColumnField = ({ columns }: { columns: CSVColumn[] }): JSX.Element => {
    const { control } = useFormContext<ColumnMappingFormStructure>();

    return (
        <FormItem label={t`Side column`}>
            <FieldSelectControlled
                name={'rowMapping.side'}
                control={control}
                required={false}
                FieldProps={{
                    options: columns,
                    style: { width: '400px' },
                    renderOption: renderOption,
                    getOptionLabel: renderLabel,
                }}
            />
        </FormItem>
    );
};

const InnerForm = ({ columns }: { columns: CSVColumn[] }): JSX.Element => {
    return (
        <Flexbox flexDirection={'row'} alignItems={'flex-end'} gap={8}>
            <Flexbox flexDirection={'row'} gap={8}>
                <DesignatorColumnField columns={columns} />
                <SideColumnField columns={columns} />
            </Flexbox>
            <SubmitButton label={t`Import`} />
        </Flexbox>
    );
};

const convertFormToColumnMapping = (
    form: ColumnMappingFormStructure,
    csvLines: CSVLines,
): ExtractRequestBody<'POST /pnp/column-mapping'> => {
    return {
        column_mapping: {
            side: form.rowMapping.side?.columnNumber || null,
            designator: form.rowMapping.designator.columnNumber,
        },

        contents: convertCSVLinesToCSVContent(csvLines),
    };
};

const getColumns = (csvLines: CSVLines): CSVColumn[] => {
    const locale = getLocale();
    const csvLinesType = csvLines.type;
    switch (csvLinesType) {
        case 'with-header':
            const headerLine = csvLines.lines[0];
            return headerLine.content.map((column, index) => ({
                columnNumber: index,
                content: column,
            }));
        case 'without-header':
            const firstLine = csvLines.lines[0];
            const otherLines = csvLines.lines.slice(1);
            return firstLine.content.map((column, index) => ({
                columnNumber: index,
                content: `${column}, ${formatList(
                    otherLines.map((c) => c.content[index]),
                    locale,
                )}`,
            }));

        default:
            assertUnreachable(csvLinesType);
    }
};

export const ColumnMappingForm = ({
    csvLines,
    uploadId,
    columnMappingAttempt,
    onSuccess,
}: {
    csvLines: CSVLines;
    uploadId: string;
    columnMappingAttempt: ColumnMappingAttemptDTO;
    onSuccess: () => void;
}): JSX.Element => {
    const columns: CSVColumn[] = getColumns(csvLines);

    const { mutateAsync } = useHttpMutation('POST /pnp/column-mapping', {
        snackbarMessage: t`Pick and place import successful`,
        onSuccess: () => onSuccess(),
    });
    const handleSubmit = async (form: ColumnMappingFormStructure) => {
        await mutateAsync({
            requestBody: convertFormToColumnMapping(form, csvLines),
            queryParams: { id: uploadId },
        });
    };

    const validationErrors: ValidationErrors<ColumnMappingFormStructure> = {
        'pnp_importer.column_missing': {
            fieldPath: 'root.serverError',
        },
        'pnp_importer.invalid_mapping': {
            fieldPath: 'root.serverError',
        },
        'pnp_importer.multiple_mapping': {
            fieldPath: 'root.serverError',
        },
        'pnp_file.not_found': {
            fieldPath: 'root.serverError',
        },
        'pnp_importer.csv': {
            fieldPath: 'root.serverError',
        },
    };

    const columnMapping = columnMappingAttempt.type === 'WithHeader' ? columnMappingAttempt.column_mapping : undefined;

    return (
        <FormContainer
            onSubmit={handleSubmit}
            validationErrors={validationErrors}
            defaultValues={{
                rowMapping: {
                    designator:
                        columnMapping !== undefined && columnMapping.designator !== null
                            ? columns.at(columnMapping.designator)
                            : undefined,
                    side:
                        columnMapping !== undefined && columnMapping.side !== null
                            ? columns.at(columnMapping.side)
                            : undefined,
                },
            }}
        >
            <InnerForm columns={columns} />
        </FormContainer>
    );
};
