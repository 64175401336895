import * as z from 'zod';
import { DriverIdRuntype } from '../driver';

const OKDriverStatusResponseRuntype = z.object({
    type: z.literal('Ok'),
});

export type OKDriverStatusDTO = z.infer<typeof OKDriverStatusResponseRuntype>;

export const DriverStatusDesignItemRuntype = z.object({
    id: z.string(),
    designator: z.string().nullable(),
    assembly: z.string(),
});

export type DriverStatusDesignItem = z.infer<typeof DriverStatusDesignItemRuntype>;

const MissingDesignItemInformationRuntype = z.object({
    type: z.literal('MissingDesignItemInformation'),
    data: z.array(DriverStatusDesignItemRuntype),
});
export type MissingDesignItemInformationDTO = z.infer<typeof MissingDesignItemInformationRuntype>;

const PanelMismatchRuntype = z.object({
    type: z.literal('PanelMismatch'),
});
export type PanelMismatchDTO = z.infer<typeof PanelMismatchRuntype>;

const MissingPnpInformationRuntype = z.object({
    type: z.literal('MissingPnpInformation'),
    data: z.array(DriverStatusDesignItemRuntype),
});
export type MissingPnpInformationDTO = z.infer<typeof MissingPnpInformationRuntype>;

const MissingOfferDataRuntype = z.object({
    design_item: DriverStatusDesignItemRuntype,
    sourcing_scenario_id: z.string(),
});

const AssemblyTypeNotPCBARuntype = z.object({
    type: z.literal('AssemblyNotPCBA'),
});

const MissingOfferRuntype = z.object({
    type: z.literal('MissingOffer'),
    data: z.array(MissingOfferDataRuntype),
});
export type MissingOfferDriverStatusDTO = z.infer<typeof MissingOfferRuntype>;

const WarningDriverStatusResponseRuntype = z.object({
    type: z.literal('Warning'),
    data: z.union([
        MissingDesignItemInformationRuntype,
        MissingPnpInformationRuntype,
        MissingOfferRuntype,
        AssemblyTypeNotPCBARuntype,
        PanelMismatchRuntype,
    ]),
});
export type WarningDriverStatusDTO = z.infer<typeof WarningDriverStatusResponseRuntype>;

const MissingDriverCountRuntype = z.object({
    type: z.literal('MissingDriverCount'),
});

const MissingPcbInformationRuntype = z.object({
    type: z.literal('MissingPcbInformation'),
});

const MissingPnpFileRuntype = z.object({
    type: z.literal('MissingPnpFile'),
});

const MissingPlacementDataRuntype = z.object({
    type: z.literal('MissingPlacementData'),
});

const ConflictingPlacementDataRuntype = z.object({
    type: z.literal('ConflictingPlacementData'),
    data: z.object({
        pcb_spec_count: z.string(),
        pnp_file_count: z.string(),
    }),
});

const PlacementSideInPcbSpecificationRequiredRuntype = z.object({
    type: z.literal('PlacementSideInPcbSpecificationRequired'),
});

const NoSourcingScenarioLinkedRuntype = z.object({
    type: z.literal('NoSourcingScenarioLinked'),
    data: z.object({
        manufacturing_scenario_id: z.string(),
    }),
});
const ErrorDriverStatusResponseRuntype = z.object({
    type: z.literal('Error'),
    data: z.union([
        MissingDriverCountRuntype,
        NoSourcingScenarioLinkedRuntype,
        MissingPcbInformationRuntype,
        MissingPnpFileRuntype,
        MissingPlacementDataRuntype,
        ConflictingPlacementDataRuntype,
        PlacementSideInPcbSpecificationRequiredRuntype,
    ]),
});
export type ErrorDriverStatusDTO = z.infer<typeof ErrorDriverStatusResponseRuntype>;

export const DriverStatusRuntype = z.union([
    OKDriverStatusResponseRuntype,
    WarningDriverStatusResponseRuntype,
    ErrorDriverStatusResponseRuntype,
]);
export type DriverStatusDTO = z.infer<typeof DriverStatusRuntype>;
export const DriverStatusWithDriverIdRuntype = z.object({
    driver_id: DriverIdRuntype,
    status: DriverStatusRuntype,
});
export type DriverStatusWithDriverIdDTO = z.infer<typeof DriverStatusWithDriverIdRuntype>;

const DriverStatusInvalidFormulaRuntype = z.object({
    type: z.literal('InvalidFormula'),
});
const DriverStatusActualStatusRuntype = z.object({
    type: z.literal('Status'),
    details: DriverStatusWithDriverIdRuntype,
});

export const DriverStatusDetailsRuntype = z.union([DriverStatusInvalidFormulaRuntype, DriverStatusActualStatusRuntype]);
export type DriverStatusDetailsDTO = z.infer<typeof DriverStatusDetailsRuntype>;
