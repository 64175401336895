import * as z from 'zod';
import { endpoint } from '../http/endpoint';
import { ManufacturerLineCardDTORuntype, ManufacturerWithPartCountDTORuntype } from './manufacturerBackendTypes';

export const manufacturerEndpoints = {
    'GET /manufacturers': endpoint({
        description: 'Fetches all manufacturers',
        pathParams: z.undefined(),
        queryParams: z.object({ high_quality: z.boolean() }),
        requestBody: z.undefined(),
        responseBody: z.object({ data: z.array(ManufacturerWithPartCountDTORuntype) }),
    }),

    'GET /manufacturers/line-card': endpoint({
        description: 'Fetches all linecards for every manufacturer',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({ data: z.array(ManufacturerLineCardDTORuntype) }),
    }),

    'GET /manufacturers/:id/line-card': endpoint({
        description: 'Fetches linecards for that manufacturer',
        pathParams: z.object({ id: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: ManufacturerLineCardDTORuntype,
    }),
};
