import * as z from 'zod';

import { ApprovalStatusRuntype, QuantityUnitDTORuntype } from '../backendTypes';
import { BomImportedAssemblyIssueRuntype } from '../bomImporter';
import { CommentCategoryRuntype } from '../comments';
import { CustomPartTypeRuntype, TechnicalParametersRuntype } from '../part';
import { UserDTORuntype } from '../user';

export enum HistoryOperation {
    AutoAdd = 'AutoAdd',
    AutoGenerate = 'AutoGenerate',
    AutoAddViaQuoteImport = 'AutoAddViaQuoteImport',
    AutoUpdate = 'AutoUpdate',
    ManualAdd = 'ManualAdd',
    ManualUpdate = 'ManualUpdate',
    ManualRemove = 'ManualRemove',
    ManualApprove = 'ManualApprove',
    ManualUpdateApproval = 'ManualUpdateApproval',
    ManualResolve = 'ManualResolve',
    ManualUnresolve = 'ManualUnresolve',
    ManualUpload = 'ManualUpload',
    AutoRemove = 'AutoRemove',
    AutoResolve = 'AutoResolve',
}

const HistoryRecordFlagRunType = z.object({
    marked: z.boolean(),
});
export type HistoryRecordFlagDTO = z.infer<typeof HistoryRecordFlagRunType>;
export const HistoryRecordOtsPartRuntype = z.object({
    mpn: z.string(),
    manufacturer: z.string(),
    approval: ApprovalStatusRuntype,
});

export type IpnHistoryData = z.infer<typeof IpnHistoryDataRuntype>;
const IpnHistoryDataRuntype = z.object({
    id: z.string(),
    value: z.string(),
    revision: z.string().nullable().optional(),
});

export type HistoryRecordOtsDTO = z.infer<typeof HistoryRecordOtsPartRuntype>;
const HistoryRecordIpnRuntype = z.object({
    ipn: IpnHistoryDataRuntype,
    approval: ApprovalStatusRuntype,
});
export type HistoryRecordIPNDTO = z.infer<typeof HistoryRecordIpnRuntype>;
const GenericPartHistoryDataRuntype = z.object({
    part: TechnicalParametersRuntype,
    approval: ApprovalStatusRuntype,
});
export type GenericPartHistoryDataDTO = z.infer<typeof GenericPartHistoryDataRuntype>;
const HistoryCommentDataRuntype = z.object({
    category: CommentCategoryRuntype,
    content: z.string(),
});
export type HistoryCommentDataDTO = z.infer<typeof HistoryCommentDataRuntype>;
const CustomPartDataWithApproval = z.object({
    part_type: CustomPartTypeRuntype,
    approval: ApprovalStatusRuntype,
});
export type HistoryCustomPartDataDTO = z.infer<typeof CustomPartDataWithApproval>;

const CustomComponentDataWithApproval = z.object({
    ipn: IpnHistoryDataRuntype,
    approval: ApprovalStatusRuntype,
});
export type HistoryCustomComponentDataDTO = z.infer<typeof CustomComponentDataWithApproval>;

export enum HistoryFileType {
    BOM = 'BOM',
    Cad = 'Cad',
    Pnp = 'Pnp',
    Other = 'Other',
}

export const SubAssemblyQuantityChanged = z.object({
    old_quantity: z.number(),
    new_quantity: z.number(),
    designator: z.string(),
});

export type SubAssemblyQuantityChangedDTO = z.infer<typeof SubAssemblyQuantityChanged>;

export const HistoryRecordDataRuntype = z.union([
    z.object({
        type: z.literal('BomItem'),
        data: z.object({
            designators: z.array(z.string()),
        }),
    }),
    z.object({
        type: z.literal('Designators'),
        data: z.object({
            designators: z.array(z.string()),
        }),
    }),
    z.object({
        type: z.literal('Comment'),
        data: HistoryCommentDataRuntype,
    }),
    z.object({
        type: z.literal('CustomPart'),
        data: CustomPartDataWithApproval,
    }),
    z.object({
        type: z.literal('CustomComponent'),
        data: CustomComponentDataWithApproval,
    }),
    z.object({
        type: z.literal('Dnp'),
        data: HistoryRecordFlagRunType,
    }),
    z.object({
        type: z.literal('IgnoreManufacturerDataWarning'),
        data: HistoryRecordFlagRunType,
    }),
    z.object({
        type: z.literal('GenericPart'),
        data: GenericPartHistoryDataRuntype,
    }),
    z.object({
        type: z.literal('Ipn'),
        data: z.object({
            ipn: IpnHistoryDataRuntype,
            approval: ApprovalStatusRuntype,
        }),
    }),
    z.object({
        type: z.literal('IpnData'),
        data: IpnHistoryDataRuntype,
    }),
    z.object({
        type: z.literal('ManufacturerFree'),
        data: HistoryRecordFlagRunType,
    }),
    z.object({
        type: z.literal('Note'),
        data: z.string(),
    }),
    z.object({
        type: z.literal('OtsPart'),
        data: HistoryRecordOtsPartRuntype,
    }),
    z.object({
        type: z.literal('SourcedBy'),
        data: HistoryRecordFlagRunType,
    }),
    z.object({
        type: z.literal('Unit'),
        data: QuantityUnitDTORuntype,
    }),
    z.object({
        type: z.literal('File'),
        data: z.object({
            file_type: z.nativeEnum(HistoryFileType),
            file_name: z.string().nullable(),
        }),
    }),
    z.object({
        type: z.union([z.literal('Assembly'), z.literal('PCB'), z.literal('ParentAssembly'), z.literal('SubAssembly')]),
        data: z.string(),
    }),
    z.object({
        type: z.literal('DesignItemsExtracted'),
        data: z.number(),
    }),
    z.object({
        type: z.literal('SubAssemblyQuantityChanged'),
        data: SubAssemblyQuantityChanged,
    }),
    z.object({
        type: z.literal('BomImportedAssemblyIssue'),
        data: BomImportedAssemblyIssueRuntype,
    }),
]);

export const AssemblyIdWithNameRuntype = z.object({
    id: z.string(),
    name: z.string(),
    parent: z.string().nullable(),
});

export const DesignatorWithDesignItemIdRuntype = z.object({
    id: z.string(),
    designator: z.string(),
});

export const HistoryRecordRelationRuntype = z.union([
    z.object({
        type: z.literal('Assembly'),
        data: z.string(),
    }),
    z.object({
        type: z.literal('DesignItems'),
        data: z.array(DesignatorWithDesignItemIdRuntype),
    }),
]);

export const HistoryRecordBreadCrumbRuntype = z.object({
    parent_assemblies: z.array(AssemblyIdWithNameRuntype),
    relation: HistoryRecordRelationRuntype,
});

export const ActorResponseRuntype = z.union([
    z.object({
        type: z.literal('User'),
        data: UserDTORuntype.nullable(),
    }),
    z.object({
        type: z.literal('Machine'),
    }),
]);

export type ActorResponseRuntypeDTO = z.infer<typeof ActorResponseRuntype>;

export type HistoryRecordBreadCrumb = z.infer<typeof HistoryRecordBreadCrumbRuntype>;
export const HistoryRecordResponseRuntype = z.object({
    created_by: ActorResponseRuntype,
    created_at: z.string(),
    operation: z.nativeEnum(HistoryOperation),
    data: HistoryRecordDataRuntype,
    bread_crumb: HistoryRecordBreadCrumbRuntype.nullable(),
});
export type HistoryRecordResponseDTO = z.infer<typeof HistoryRecordResponseRuntype>;
