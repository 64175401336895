import * as z from 'zod';
import { LanguageEnumRuntype } from '../../utils/i18n';

export interface UserDTO extends z.infer<typeof UserDTORuntype> {}
export const UserDTORuntype = z.object({
    id: z.string(),
    first_name: z.string(),
    last_name: z.string(),
    email: z.string(),
    user_language: LanguageEnumRuntype,
    customer: z
        .object({
            id: z.string(),
        })
        .nullable(),
    position: z.string().nullable(),
    phone_number: z.string().nullable(),
});

export interface UserRolesDTO extends z.infer<typeof UserRolesDTORuntype> {}
export const UserRolesDTORuntype = z.object({
    user_id: z.string(),
    roles: z.array(z.string()).nullable().optional(),
});

export interface UserRolesUpdateDTO extends z.infer<typeof UserRolesUpdateDTORuntype> {}
export const UserRolesUpdateDTORuntype = z.object({
    admin: z.boolean(),
});

export type UserRole = z.infer<typeof UserRoleRuntype>;
export const UserRoleRuntype = z.enum(['Admin', 'Member']);

export interface UserInviteDTO extends z.infer<typeof UserInviteDTORuntype> {}
export const UserInviteDTORuntype = z.object({
    id: z.string(),
    first_name: z.string(),
    last_name: z.string(),
    email: z.string(),
    user_language: LanguageEnumRuntype,
    user_role: UserRoleRuntype,
    created_at: z.string(),
    customer: z
        .object({
            id: z.string(),
        })
        .nullable(),
});

export interface UserInviteInsertDTO extends z.infer<typeof UserInviteInsertDTORuntype> {}
export const UserInviteInsertDTORuntype = z.object({
    email: z.string(),
    first_name: z.string(),
    last_name: z.string(),
    language: LanguageEnumRuntype.nullable(),
    user_role: UserRoleRuntype,
});

export interface UserUpdateDTO extends z.infer<typeof UserUpdateDTORuntype> {}
export const UserUpdateDTORuntype = z.object({
    first_name: z.string(),
    last_name: z.string(),
    phone_number: z.string().nullable(),
    position: z.string().nullable(),
});

export interface CurrentUserUpdateDTO extends z.infer<typeof CurrentUserUpdateDTORuntype> {}
export const CurrentUserUpdateDTORuntype = z.object({
    user_language: LanguageEnumRuntype,
});
