import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { runParsers } from '../../../model/runParsers';
import { ParseDateOptions } from '../../../parsers/parseDate';
import { ImporterConfig } from '../../../types';
import { useUniversalImporter } from '../context';
import { DateFormatGlobalFieldValue } from '../util/getDefaultDateFormat';

export const useRunParsersQueryKey = ['step-check'];

export function useRunParsers(): UseQueryResult<null> {
    const { dispatch, state } = useUniversalImporter();
    const { config, table, dateFormat, decimalSeparator, transformRecordAction } = state;

    const withDecimalSeparator = applyDecimalSeparatorAndDateFormatter(config, decimalSeparator, dateFormat);

    return useQuery({
        queryKey: [...useRunParsersQueryKey, config, dateFormat],
        queryFn: async () => {
            for await (const intermediateState of runParsers(
                table ?? [],
                withDecimalSeparator,
                transformRecordAction,
            )) {
                dispatch({ type: 'setImporterTable', table: intermediateState });
            }
            return null;
        },
        retry: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        staleTime: Infinity,
        gcTime: Infinity,
    });
}

function applyDecimalSeparatorAndDateFormatter(
    config: ImporterConfig,
    decimalSeparator: '.' | ',',
    format: DateFormatGlobalFieldValue,
) {
    return {
        ...config,
        fields: config.fields.map((field) => {
            if (field.parser.type === 'date') {
                const opts: ParseDateOptions = {
                    formats: format.formats,
                    ...field.parser.options,
                };
                return {
                    ...field,
                    parser: {
                        ...field.parser,
                        options: opts,
                    },
                };
            }
            if (field.parser.type === 'number') {
                return {
                    ...field,
                    parser: {
                        ...field.parser,
                        options: {
                            ...field.parser.options,
                            decimalSeparator,
                        },
                    },
                };
            }
            return field;
        }),
    };
}
