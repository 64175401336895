import * as z from 'zod';
import {
    FixedCostDTO,
    FixedCostRuntype,
    FixedFormulaRuntype,
    FractionCostDTO,
    FractionCostRuntype,
    FractionFormulaRuntype,
} from '../calculation/calculationBackendTypes';
import { SyntaxErrorRuntype } from '../formula';

export const FormulaCostTemplateRuntype = z.object({
    type: z.literal('Formula'),
    data: z.object({
        formula: z.union([FixedFormulaRuntype, FractionFormulaRuntype]),
        errors: z.array(SyntaxErrorRuntype).optional(),
    }),
});

type FormulaCostTemplateDTO = z.infer<typeof FormulaCostTemplateRuntype>;

export type UnitCostTemplateDTO = FixedCostDTO | FractionCostDTO | FormulaCostTemplateDTO;

const CostCellRuntype = z.object({
    unit_cost: z.union([FixedCostRuntype, FractionCostRuntype, FormulaCostTemplateRuntype]),
    is_locked: z.boolean(),
});
export type CostCellTemplateDTO = z.infer<typeof CostCellRuntype>;

const ExtraCostRuntype = z.object({
    name: z.string(),
    cost: CostCellRuntype,
});
export type ExtraCostTemplateDTO = z.infer<typeof ExtraCostRuntype>;

const CustomCostTemplateRuntype = z.object({
    extra_costs: z.array(ExtraCostRuntype),
    profit: CostCellRuntype.nullable(),
    discount: CostCellRuntype.nullable(),
});

const AdditionalCostRuntype = z.object({
    profit: CostCellRuntype.nullable(),
    discount: CostCellRuntype.nullable(),
    other_costs: z.array(ExtraCostRuntype),
    post_profit_costs: z.array(ExtraCostRuntype),
});

const ProjectCostTemplateRuntype = z.object({
    profit: CostCellRuntype.nullable(),
});

// POST. Post is slightly different from the GET request, because it has a different structure for the Cost. POST only contains the custom_costs.

const FormulaCostPostRuntype = z.object({
    type: z.literal('Formula'),
    data: z.union([FixedFormulaRuntype, FractionFormulaRuntype]),
});

type FormulaCostPostDTO = z.infer<typeof FormulaCostPostRuntype>;

export type UnitCostPostTemplateDTO = FixedCostDTO | FractionCostDTO | FormulaCostPostDTO;

const CostCellPostRuntype = z.object({
    unit_cost: z.union([FixedCostRuntype, FractionCostRuntype, FormulaCostPostRuntype]),
    is_locked: z.boolean(),
});

export type CostCellPostTemplateDTO = z.infer<typeof CostCellPostRuntype>;

const ExtraCostPostRuntype = z.object({
    name: z.string(),
    cost: CostCellPostRuntype,
});
export type ExtraCostPostDTO = z.infer<typeof ExtraCostPostRuntype>;

const CustomCostPostRuntype = z.object({
    extra_costs: z.array(ExtraCostPostRuntype),
    profit: CostCellPostRuntype.nullable(),
    discount: CostCellPostRuntype.nullable(),
});

export type CustomCostPostDTO = z.infer<typeof CustomCostPostRuntype>;

const ProjectCostPostRuntype = z.object({
    profit: CostCellPostRuntype.nullable(),
});

const AdditionalCostPostRuntype = z.object({
    profit: CostCellPostRuntype.nullable(),
    discount: CostCellPostRuntype.nullable(),
    other_costs: z.array(ExtraCostPostRuntype),
    post_profit_costs: z.array(ExtraCostPostRuntype),
});

const CalculationTemplateRuntypeBase = z.object({
    name: z.string(),
    notes: z.string().nullable(),
    include_excess_material_in_material_cost: z.boolean(),
    include_project_cost_in_manufacturing_cost: z.boolean(),
    include_one_time_cost_in_project_cost: z.boolean(),
});

export const CalculationTemplatePostRuntype = CalculationTemplateRuntypeBase.extend({
    material_cost: CustomCostPostRuntype,
    manufacturing_cost: CustomCostPostRuntype,
    additional_cost: AdditionalCostPostRuntype,
    project_cost: ProjectCostPostRuntype,
});

export const CalculationTemplateRuntype = CalculationTemplateRuntypeBase.extend({
    id: z.string(),
    created_at: z.string(),
    updated_at: z.string(),
    material_cost: CustomCostTemplateRuntype,
    manufacturing_cost: CustomCostTemplateRuntype,
    additional_cost: AdditionalCostRuntype,
    project_cost: ProjectCostTemplateRuntype,
});

export type CalculationTemplateResponseDTO = z.infer<typeof CalculationTemplateRuntype>;

export type CalculationTemplatePostDTO = z.infer<typeof CalculationTemplatePostRuntype>;
