import * as z from 'zod';

const ManufacturingScenarioTemplateStatusRuntype = z.union([z.literal('Active'), z.literal('Inactive')]);
export type ManufacturingScenarioTemplateStatusDTO = z.infer<typeof ManufacturingScenarioTemplateStatusRuntype>;

export const IdWithIndexRuntype = z.object({
    id: z.string(),
    index: z.number(),
});
export type IdWithIndex = z.infer<typeof IdWithIndexRuntype>;

export const ManufacturingScenarioTemplateDTORuntype = z.object({
    id: z.string(),
    name: z.string(),
    notes: z.string().nullable(),
    status: ManufacturingScenarioTemplateStatusRuntype,
    manufacturing_lead_time: z.number().nullable(),

    ordered_activities: z.array(IdWithIndexRuntype),

    ordered_expenses: z.array(IdWithIndexRuntype),
    sites: z.array(z.string()),
});

export const ManufacturingScenarioTemplatePostDTORuntype = ManufacturingScenarioTemplateDTORuntype.omit({
    id: true,
    sites: true,
});

export const ManufacturingScenarioTemplatePatchDTORuntype = ManufacturingScenarioTemplateDTORuntype.omit({
    id: true,
    sites: true,
}).partial();

export type ManufacturingScenarioTemplateDTO = z.infer<typeof ManufacturingScenarioTemplateDTORuntype>;
export type ManufacturingScenarioTemplatePostDTO = z.infer<typeof ManufacturingScenarioTemplatePostDTORuntype>;
export type ManufacturingScenarioTemplatePatchDTO = z.infer<typeof ManufacturingScenarioTemplatePatchDTORuntype>;
