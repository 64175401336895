import * as z from 'zod';

export const ACTIVITY_CREATION_ALL_LEVELS = ['Unit', 'Batch', 'Project'] as const;
const ActivityLevelRuntype = z.enum(ACTIVITY_CREATION_ALL_LEVELS);
export type ActivityLevel = z.infer<typeof ActivityLevelRuntype>;

export type TimeCalculationTypes = ActivityLevel;

export const CALCULATION_TYPE = ['Fixed', 'Linear', 'Formula'] as const;
export const CalculationTypeRuntype = z.enum(CALCULATION_TYPE);
export type CalculationType = z.infer<typeof CalculationTypeRuntype>;

export type CalculationTypeWithoutFormula = Exclude<CalculationType, 'Formula'>;

export const CALCULATION_TYPE_WITH_NONE = ['Fixed', 'Linear', 'None', 'Formula'] as const;
export const CalculationTypeWithNoneRuntype = z.enum(CALCULATION_TYPE_WITH_NONE);
export type CalculationTypeWithNone = z.infer<typeof CalculationTypeWithNoneRuntype>;

//this order is used for sorting so be careful when changing https://miro.com/app/board/o9J_kuYwZUQ=/?moveToWidget=3074457359358058617&cot=10
export const ALL_PROCESS_SELECTION_OPTIONS = [
    'ProcessPreparation',
    'NPI',
    'SMT',
    'THT',
    'HandSoldering',
    'PanelSeparation',
    'Potting',
    'Ruggedizing',
    'Testing',
    'Inspection',
    'CableAssembly',
    'MechanicalAssembly',
    'QualityControl',
    'Packaging',
    'Logistics',
    'Other',
] as const;

export const ProcessRuntype = z.enum(ALL_PROCESS_SELECTION_OPTIONS);
export type ProcessSelection = z.infer<typeof ProcessRuntype>;

export const ALL_CATEGORY_SELECTION_OPTIONS = ['Preparation', 'Execution', 'Inspection', 'PostProcessing'] as const;
export const CategoryRuntype = z.enum(ALL_CATEGORY_SELECTION_OPTIONS);

export type CategorySelection = z.infer<typeof CategoryRuntype>;

export const ManufacturingEntityStatusRuntype = z.enum(['Active', 'Inactive']);
const ManufacturingEntityStatusWithErrorRuntype = z.union([ManufacturingEntityStatusRuntype, z.literal('Error')]);
export type ManufacturingEntityStatusWithError = z.infer<typeof ManufacturingEntityStatusWithErrorRuntype>;

export type ManufacturingEntityStatusSelection = z.infer<typeof ManufacturingEntityStatusRuntype>;
