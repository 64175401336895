import { t } from '@lingui/macro';
import { assertUnreachable } from '@luminovo/commons';
import * as DataGrid from '@luminovo/data-grid';
import { ColumnBuilder } from '@luminovo/data-grid';
import { Box } from '@mui/material';
import React from 'react';
import { formatImportStatus } from '../../../../model/formatImportStatus';
import { formatLinkStatus } from '../../../../model/formatLinkStatus';
import { getCellValue } from '../../../../model/getCellValue';
import { ImporterRow, LinkStatus } from '../../../../types';
import { useUniversalImporter } from '../../context';

const columnBuilder = new ColumnBuilder<ImporterRow>();

export function TablePreview({ rows }: { rows: ImporterRow[] }): JSX.Element {
    const { state } = useUniversalImporter();
    const { config } = state;

    const fields = config.fields;

    const columnFields = React.useMemo(
        () =>
            fields.map((field) => {
                return columnBuilder.text({
                    valueGetter: ({ data: row }) => getCellValue(row!, field)?.label,
                    colId: field.id,
                    headerName: field.label ?? field.id,
                    lockVisible: true,
                    editable: false,
                });
            }),
        [fields],
    );

    const hasImporterRanAlready = state.importerTable?.getRows().some((r) => r.import);

    const columns = React.useMemo(
        () =>
            hasImporterRanAlready
                ? [...columnFields, messageColumn(), columnImportStatus()]
                : [...columnFields, columnStatus()],
        [hasImporterRanAlready, columnFields],
    );

    return (
        <DataGrid.DataGridReadonlyContextProvider rowData={rows}>
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                <DataGrid.DataGridSearch />
                <DataGrid.DataGridQuickFilters />
            </Box>
            <DataGrid.DataGrid editable={false} themeId={'tanstackTable'} columnDefs={columns} />
        </DataGrid.DataGridReadonlyContextProvider>
    );
}

const statuses: LinkStatus[] = ['skipped', 'insert', 'update'];

const columnStatus = () =>
    columnBuilder.status({
        quickFilters: statuses.map((status) => ({
            label: () => formatLinkStatus(status),
            value: { label: formatLinkStatus(status), color: 'neutral', description: '' },
        })),
        filterValueGetter: ({ data }) => formatLinkStatus(data?.record?.action),
        valueGetter: ({ data }) => {
            const status = data?.record?.action ?? 'skipped';
            if (status === 'skipped') {
                return {
                    label: formatLinkStatus(status),
                    color: 'neutral',
                    description: t`This record is excluded from the import`,
                };
            }
            if (status === 'insert') {
                return {
                    label: formatLinkStatus(status),
                    color: 'violet',
                    description: t`This will result in a new record`,
                };
            }
            if (status === 'update') {
                return {
                    label: formatLinkStatus(status),
                    color: 'blue',
                    description: t`This will result in an update of an existing record`,
                };
            }
            assertUnreachable(status);
        },
        colId: 'status',
        label: () => t`Status`,
        pinned: 'right',
    });

const messageColumn = () =>
    columnBuilder.text({
        colId: 'message',
        valueGetter: ({ data }) => {
            const importResult = data?.import;
            if (importResult?.success) {
                return '';
            }
            return importResult?.message ?? '';
        },
        label: () => t`Message`,
        pinned: 'right',
    });

const importStatuses = [true, false, undefined];

const columnImportStatus = () =>
    columnBuilder.status({
        quickFilters: importStatuses.map((status) => ({
            value: {
                label: formatImportStatus(status),
                color: 'neutral',
            },
            label: () => formatImportStatus(status),
        })),
        // options: importStatuses,
        label: () => t`Status`,
        colId: 'importStatus',
        pinned: 'right',
        valueGetter: ({ data }) => {
            const importStatus = data?.import;
            if (!importStatus) {
                return { label: formatImportStatus(importStatus), color: 'neutral' };
            }
            if (importStatus.success) {
                return { label: formatImportStatus(importStatus.success), color: 'green' };
            }
            return { label: formatImportStatus(importStatus.success), color: 'red' };
        },
    });
