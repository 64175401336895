import { t } from '@lingui/macro';
import { formatLongDateTime } from '@luminovo/commons';
import { colorSystem, Tooltip } from '@luminovo/design-system';
import { InfoRounded } from '@mui/icons-material';
import { CircularProgress, Typography } from '@mui/material';
import { useHttpQuery } from '../../resources/http/useHttpQuery';

const useLastRefreshTime = (assemblyId: string): { isLoading: boolean; displayText: string | undefined } => {
    const { data, isLoading } = useHttpQuery('GET /manufacturing-cache/sourcing/last_refresh/:assemblyId', {
        pathParams: { assemblyId },
    });

    return {
        displayText:
            data === undefined
                ? undefined
                : data.time === null
                  ? t`Sourcing information unavailable, please update`
                  : t`Sourcing information was last updated at: ${formatLongDateTime(data.time)}`,
        isLoading,
    };
};

export const ManufacturingCacheInformationText = ({ assemblyId }: { assemblyId: string }): JSX.Element => {
    const { isLoading, displayText } = useLastRefreshTime(assemblyId);

    if (isLoading) {
        return <CircularProgress color={'secondary'} size={16} />;
    }

    return <Typography style={{ marginRight: '4px', color: colorSystem.neutral[8] }}>{displayText}</Typography>;
};

export const ManufacturingCacheInfoIcon = ({ assemblyId }: { assemblyId: string }): JSX.Element => {
    const { isLoading, displayText } = useLastRefreshTime(assemblyId);

    if (isLoading) {
        return <CircularProgress color={'secondary'} size={16} />;
    }
    if (displayText === undefined) {
        return <></>;
    }

    return (
        <Tooltip variant={'white'} title={displayText}>
            <InfoRounded fontSize="inherit" style={{ color: colorSystem.neutral[5] }} />
        </Tooltip>
    );
};
