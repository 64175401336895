import * as z from 'zod';
import { ApiIntegrationStatusRuntype } from './3rdPartyBackendTypes';

export type AvnetSilicaLocaleCurrencyDTO = z.infer<typeof AvnetSilicaLocaleCurrencyDTORuntype>;
export const AvnetSilicaLocaleCurrencyDTORuntype = z.enum(['EUR', 'USD', 'GBP', 'JPY']);

export interface AvnetSilicaCredentialsDTO extends z.infer<typeof AvnetSilicaCredentialsDTORuntype> {}
export const AvnetSilicaCredentialsDTORuntype = z.object({
    username: z.string(),
    password: z.string(),
    currency: AvnetSilicaLocaleCurrencyDTORuntype,
});

export interface AvnetSilicaCredentialsInputDTO extends z.infer<typeof AvnetSilicaCredentialsInputDTORuntype> {}
export const AvnetSilicaCredentialsInputDTORuntype = z.object({
    username: z.string().optional(),
    password: z.string().optional(),
    currency: AvnetSilicaLocaleCurrencyDTORuntype.optional(),
});

export interface AvnetSilicaResponseBodyDTO extends z.infer<typeof AvnetSilicaResponseBodyDTORuntype> {}
export const AvnetSilicaResponseBodyDTORuntype = z.object({
    data: AvnetSilicaCredentialsDTORuntype.nullable(),
    status: ApiIntegrationStatusRuntype,
});
