import { ScopeContext } from '@sentry/core';
import * as Sentry from '@sentry/react';

export type SentryAttachment = {
    file: File;
    contentType?: string;
};

export async function getFileAsUint8Array(file: File): Promise<Uint8Array> {
    const arrayBuffer = await file.arrayBuffer();
    return new Uint8Array(arrayBuffer);
}

/**
 * Log exception or error to all configured external error monitoring services.
 * @param error Exception
 * @param captureOptions Optional capture options object
 * @param attachment Optional attachment file - useful to attach excel files
 */
export const logToExternalErrorHandlers = (
    error: Error,
    captureOptions?: Partial<ScopeContext>,
    attachment?: SentryAttachment,
): void => {
    Sentry.withScope(async (scope) => {
        const fingerprint = getFingerprint(error);
        if (fingerprint) {
            scope.setFingerprint(fingerprint);
        }

        if (attachment) {
            const uint8Array = await getFileAsUint8Array(attachment.file);
            scope.addAttachment({
                data: uint8Array,
                filename: attachment.file.name,
                contentType: attachment.contentType,
                attachmentType: 'event.attachment',
            });
        }

        Sentry.captureException(error, getFinalCaptureOptions(error, captureOptions));
        scope.clearAttachments();
    });
};

export function getFinalCaptureOptions(error: Error, captureOptions?: Partial<ScopeContext>): Partial<ScopeContext> {
    return {
        ...captureOptions,
        extra: {
            'honeycomb-link': getHoneycombLink(error),
            'epibator-sha': getEpibatorSha(error),
            'luminovo-version': getLuminovoVersion(error),
            identifier: getFingerprint(error),
            ...captureOptions?.extra,
        },
    };
}

function getFingerprint(error: Error & { status?: unknown; endpoint?: unknown }): string[] | undefined {
    if (!('status' in error) || !('endpoint' in error)) {
        return undefined;
    }
    if (typeof error.status !== 'number' || typeof error.endpoint !== 'string') {
        return undefined;
    }
    return [String(error.status), error.endpoint];
}

function getHoneycombLink(error: Error & { getHoneycombLink?: unknown }): string {
    if (!('getHoneycombLink' in error)) {
        return 'N/A';
    }
    if (typeof error.getHoneycombLink !== 'function') {
        return 'N/A';
    }
    return String(error.getHoneycombLink());
}

function getEpibatorSha(error: Error & { epibatorSha?: unknown }): string {
    if (!('epibatorSha' in error)) {
        return 'N/A';
    }
    return String(error.epibatorSha);
}

function getLuminovoVersion(error: Error & { luminovoVersion?: unknown }): string {
    if (!('luminovoVersion' in error)) {
        return 'N/A';
    }
    return String(error.luminovoVersion);
}
