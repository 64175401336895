import { formatDecimal } from '@luminovo/commons';
import { Flexbox, Text } from '@luminovo/design-system';
import { SolutionStatus, SourcingCalculationDTO } from '@luminovo/http-client';
import { SolutionStatusCircleIcon } from '@luminovo/sourcing-core';
import React from 'react';

interface StatusSummariesProps {
    sourcingCalculation: SourcingCalculationDTO;
}
export const StatusSummaries: React.FunctionComponent<StatusSummariesProps> = ({
    sourcingCalculation,
}: StatusSummariesProps): JSX.Element => {
    const {
        number_of_ok: numberOfSuccess,
        number_of_warning: numberOfWarnings,
        number_of_error: numberOfErrors,
    } = sourcingCalculation.status_count;

    const statusCounts: Array<{ status: SolutionStatus; count: number }> = [
        { status: SolutionStatus.Good, count: numberOfSuccess },
        { status: SolutionStatus.Warning, count: numberOfWarnings },
        { status: SolutionStatus.Error, count: numberOfErrors },
    ];

    return (
        <Flexbox gap={16} justifyContent={'center'}>
            {statusCounts.map(({ status, count }) => {
                return (
                    <Flexbox key={status} alignItems="center" gap={4}>
                        <SolutionStatusCircleIcon status={status} />
                        <Text variant={'body-semibold'}>{formatDecimal(count)}</Text>
                    </Flexbox>
                );
            })}
        </Flexbox>
    );
};
