import * as z from 'zod';
import { endpoint } from '../http/endpoint';
import { TechnicalParametersRuntype } from '../part/partBackendTypes';
import { UsedInResponseRuntype } from '../rfq';
import { RfQContextQueryParamsRuntype } from '../rfqContext';
import {
    ComponentSpecificationRuntype,
    ComponentsSearchResponseDTORuntype,
    IpnFormAndFitFormPatchValuesRuntype,
    OtsComponentFullRuntype,
} from './internalPartNumberBackendTypes';

/**
 * Split the internalPartNumberEndpoints into smaller chunks to avoid TypeScript serialization limits.
 * Each chunk gets its own type definition, and then we combine them in the final export.
 */

// Chunk 1: Component-related endpoints
const componentEndpoints = {
    'POST /components/search': endpoint({
        description:
            'Searches IPNs via elasticsearch, expected to be faster than GET /ipns/search, returns an array of OTS and Custom components',
        pathParams: z.undefined(),
        queryParams: z.undefined(),

        requestBody: z
            .object({
                filters: z.array(
                    z.object({
                        field: z.string(),
                        operator: z.string(),
                        parameter: z.unknown(),
                    }),
                ),
                page_params: z.number().nullable().optional(),
                global_filter: z.string(),
                page_size: z.number().optional(),
                prioritise_preferred_ipns: z.boolean().optional(),
            })
            .and(RfQContextQueryParamsRuntype),
        responseBody: ComponentsSearchResponseDTORuntype,
    }),

    'POST /components/import': endpoint({
        description: 'Imports components',
        pathParams: z.undefined(),
        queryParams: z.object({
            live: z.boolean().optional(),
        }),
        requestBody: z.array(
            z.object({
                component: z.object({
                    type: z.literal('OffTheShelf'),
                    data: z.object({
                        parts: z.array(
                            z.object({
                                manufacturer: z.string().optional(),
                                mpn: z.string().optional(),
                            }),
                        ),
                    }),
                }),
                customer_part_numbers: z.array(z.unknown()),
                description: z.string(),
                category: z.string().nullable().optional(),
                internal_part_number: z.object({
                    revision: z.string().nullable().optional(),
                    value: z.string(),
                }),
                is_preferred: z.boolean(),
                is_restricted_to_customers: z.boolean(),
            }),
        ),
        responseBody: z.unknown(),
    }),
};

// Chunk 2: IPN search endpoints
const ipnSearchEndpoints = {
    'POST /ipns/search/linked-parts/bulk': endpoint({
        description: 'Returns an array of IpnWithMatchesFullPartRuntype that have the provided parts as linked parts',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.object({
            generic_parts: z.array(z.string()),
            off_the_shelf_parts: z.array(z.string()),
            rfq_context: z.literal('WithinRfQ'),
            rfq_id: z.string(),
        }),
        responseBody: z.object({
            items: z.array(OtsComponentFullRuntype),
        }),
    }),
    'POST /ipns/search/suggested-parts/bulk': endpoint({
        description:
            'Returns an array of IpnWithMatchesFullPartRuntype that have the provided parts in their suggestions',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.object({
            generic_parts: z.array(z.string()),
            incomplete_generic_parts: z.array(TechnicalParametersRuntype),
            rfq_context: z.literal('WithinRfQ'),
            rfq_id: z.string(),
        }),
        responseBody: z.object({
            items: z.array(OtsComponentFullRuntype),
        }),
    }),
    'POST /ipns/search/generics/bulk': endpoint({
        description:
            'Returns an array of IpnWithMatchesFullPartRuntype with matching component spec for each generic part id',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.object({
            generic_parts: z.array(z.string()),
            rfq: z.string(),
            rfq_context: z.union([z.literal('WithinRfQ'), z.literal('OutsideRfQ')]),
            rfq_id: z.string().optional(),
        }),
        responseBody: z.object({
            items: z.record(z.string(), z.array(OtsComponentFullRuntype)),
        }),
    }),
};

// Chunk 3: IPN update and assembly endpoints
const ipnUpdateAndAssemblyEndpoints = {
    'PATCH /ipns/:id/form-and-fit': endpoint({
        description: 'Updates edited form-and-fit for this ipn',
        pathParams: z.object({ id: z.string() }),
        queryParams: z.undefined(),
        requestBody: IpnFormAndFitFormPatchValuesRuntype,
        responseBody: z.null(),
        invalidates: [
            'POST /ipns/bulk',
            'POST /parts/off-the-shelf/bulk',
            'GET /assemblies/:assemblyId/descendants',
            'POST /parts/availability',
            'POST /components/bulk',
            'POST /components/custom/bulk',
        ],
    }),
    'PATCH /ipns/:id': endpoint({
        description: 'Updates edited component specification for this ipn',
        pathParams: z.object({ id: z.string() }),
        queryParams: z.undefined(),
        requestBody: ComponentSpecificationRuntype,
        responseBody: z.undefined().or(z.null()),
        invalidates: [
            'POST /components/search',
            'POST /components/bulk',
            'POST /ipns/search/linked-parts/bulk',
            'POST /ipns/bulk',
        ],
    }),
    'POST /ipns/assemblies/bulk': endpoint({
        description: 'Returns an array of assemblies in which the specified ipn have been used',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.object({ ids: z.array(z.string()), rfq_context: z.literal('OutsideRfQ') }),
        responseBody: z.object({
            data: UsedInResponseRuntype,
        }),
    }),
};

// Define types for each chunk
type ComponentEndpoints = typeof componentEndpoints;
type IpnSearchEndpoints = typeof ipnSearchEndpoints;
type IpnUpdateAndAssemblyEndpoints = typeof ipnUpdateAndAssemblyEndpoints;

// Define the combined interface
interface InternalPartNumberEndpoints extends ComponentEndpoints, IpnSearchEndpoints, IpnUpdateAndAssemblyEndpoints {}

// Export the combined object with explicit type annotation
export const internalPartNumberEndpoints: InternalPartNumberEndpoints = {
    ...componentEndpoints,
    ...ipnSearchEndpoints,
    ...ipnUpdateAndAssemblyEndpoints,
};
