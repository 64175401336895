import * as z from 'zod';
import { endpoint } from '../http/endpoint';
import { CommentCategoryRuntype, CommentEntityRuntype, CommentRuntype } from './commentBackendTypes';

export const commentsEndpoints = {
    'POST /comments/bulk': endpoint({
        description: 'Fetches all the events associated with the given entity',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: CommentEntityRuntype,
        responseBody: z.object({
            comments: z.array(CommentRuntype),
        }),
    }),
    'PATCH /comments/:id': endpoint({
        description: 'Updates an event',
        pathParams: z.object({ id: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.object({ resolved: z.boolean() }),
        responseBody: z.unknown(),
    }),
    'DELETE /comments/:id': endpoint({
        description: `Deletes an event given it's ID`,
        requestBody: z.undefined(),
        responseBody: z.unknown(),
        pathParams: z.object({ id: z.string() }),
        queryParams: z.undefined(),
    }),
    'POST /comments': endpoint({
        description: `Creates an event`,
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.object({
            comment_entity: CommentEntityRuntype,
            content: z.string(),
            category: CommentCategoryRuntype,
        }),
        responseBody: z.unknown(),
    }),
};
