import { detectLanguageEnum, LocaleConfig } from '@luminovo/commons';
import { parseRuntype } from '@luminovo/http-client';
import * as cc from 'vanilla-cookieconsent';
import 'vanilla-cookieconsent/dist/cookieconsent.css';
import * as z from 'zod';
import { ConsentType, ConsentTypeRuntype } from './ConsentType';
import { de } from './i18n/de';
import { en } from './i18n/en';
import { es } from './i18n/es';
import { fr } from './i18n/fr';

export function runCookieConsent() {
    cc.reset(true);
    cc.run({
        hideFromBots: true,
        autoClearCookies: true,
        cookie: {
            name: 'consent_management',
            expiresAfterDays: 365,
        },
        revision: 0,
        guiOptions: {
            consentModal: {
                layout: `box inline`,
                position: `bottom left`,
                flipButtons: false,
                equalWeightButtons: true,
            },
            preferencesModal: {
                layout: `box`,
                equalWeightButtons: false,
                flipButtons: true,
            },
        },

        onConsent: () => {
            document.dispatchEvent(new Event('cookie-consent'));
        },
        onChange: () => {
            document.dispatchEvent(new Event('cookie-consent'));
        },

        categories: {
            required: {
                enabled: true, // this category is enabled by default
                readOnly: true, // this category cannot be disabled
            },
            analytics: {
                enabled: false,
                readOnly: false,
            },
            support: {
                enabled: false,
                readOnly: false,
            },
        },

        language: {
            default: detectLanguageEnum(),
            translations: {
                en,
                de,
                es,
                fr,
            },
        },
    });
}

export function showCookieConsentSettings() {
    cc.showPreferences();
}

/**
 * @returns the cookies categories to which the user has given consent to.
 */
export function getConsentedCookies(): ConsentType[] {
    const categories = cc.getCookie('categories');
    if (parseRuntype(z.array(ConsentTypeRuntype), categories)) {
        return categories;
    }
    return [];
}

export function setLanguageCookieConsent(localeConfig: LocaleConfig) {
    cc.setLanguage(localeConfig.locale);
}

export function acceptRequiredCategory() {
    cc.acceptCategory([ConsentType.required]);
}

export function acceptAllCategory() {
    cc.acceptCategory(Object.values(ConsentType));
}
