import { Trans, t } from '@lingui/macro';
import { id, isProductionEnvironment, transEnum } from '@luminovo/commons';
import {
    CenteredLayout,
    Dropzone,
    Flexbox,
    Link,
    MenuButton,
    MenuItem,
    Message,
    RightBoxDrawer,
    Text,
    Tooltip,
    colorSystem,
    useNavigate,
} from '@luminovo/design-system';
import {
    BomLineBuildingOutput,
    ColumnMap,
    LineScreenerErrorOutput,
    ScreenerHandlerOutput,
    SingleOriginalExcelRow,
    isScreenerHandlerOutput,
} from '@luminovo/http-client';
import { Download, InfoRounded } from '@mui/icons-material';
import React, { useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { CloseDrawerButton, useDrawerContext } from '../../components/contexts/ModalContext';
import { ErrorDialog } from '../../components/errorHandlers/ErrorDialog';
import { useHttpQuery } from '../../resources/http/useHttpQuery';
import { LumiQuoteRoute, route } from '../../utils/routes';
import { typed } from '../../utils/typingUtils';
import { ViewContext } from '../Bom/components/ModuleTableData';
import {
    ColumnTagsSheetsAction,
    ColumnTagsSheetsState,
} from '../DesignItemDetails/components/AutocompleteColumnTags/types';
import {
    createInitialTagsState,
    useTagsState,
} from '../DesignItemDetails/components/AutocompleteColumnTags/useTagsState';
import { errorCodeTranslations } from '../Error/errorCodeTranslations';
import { UseBomImporterReturn } from './BomImporterContext';
import { BomImporterLinesDialog } from './BomImporterLinesDialog';
import BomImporterLoadingDialog from './BomImporterLoadingDialog';
import { BomImporterScreeningDialog } from './BomImporterScreeningDialog';
import { GuidanceTableImage } from './GuidanceTableImage';

interface BomImporterProps {
    rfqId: string;
    assemblyId: string;
    viewContext: ViewContext;
    importer: UseBomImporterReturn;
    isBomImporterDropzoneShown: boolean;
}

type UseBomImporterLinesDataReturn<T> = {
    bomImporterLinesData: T;
    handleSetBomImporterLinesData: React.Dispatch<React.SetStateAction<T>>;
    setInitialBomImporterLinesData: React.Dispatch<React.SetStateAction<T>>;
    haveBomImporterLinesChanged: boolean;
    setHaveBomImporterLinesChanged: React.Dispatch<React.SetStateAction<boolean>>;
};

function useBomImporterLinesData<T>(initialState: T): UseBomImporterLinesDataReturn<T> {
    const [bomImporterLinesData, setBomImporterLinesData] = React.useState<T>(() => {
        return initialState;
    });
    const [haveBomImporterLinesChanged, setHaveBomImporterLinesChanged] = React.useState<boolean>(false);

    const handleSetBomImporterLinesData = React.useCallback((newState) => {
        setBomImporterLinesData(newState);
        setHaveBomImporterLinesChanged(true);
    }, []);

    const setInitialBomImporterLinesData = React.useCallback((newState) => {
        setBomImporterLinesData(newState);
        setHaveBomImporterLinesChanged(false);
    }, []);

    return {
        bomImporterLinesData,
        handleSetBomImporterLinesData,
        setInitialBomImporterLinesData,
        haveBomImporterLinesChanged,
        setHaveBomImporterLinesChanged,
    };
}

const convertTagStateToColumnMap = (sheetsState: ColumnTagsSheetsState, sheetIndex: number): ColumnMap => {
    const columnMap: ColumnMap = {
        designators: [],
        quantity: [],
        unit: [],
        dnp: [],
        manufacturer_free: [],
        generic_part_type: [],
        manufacturer: [],
        mpn: [],
        supplier_part_number: [],
        cpn: [],
        technical_parameters: [],
        notes: [],
        enumeration: [],
        ipn: [],
        consigned: [],
        cpn_revision: [],
        export_pass_through: [],
        level: [],
        assembly_name: [],
    };

    for (const tag of sheetsState.sheets[sheetIndex]?.tags ?? []) {
        columnMap[tag.id] = tag.associatedTo.map((col) => Number(col.id));
    }

    return columnMap;
};

const PAGE_1_ROUTES = [
    typed<LumiQuoteRoute>('/rfqs/:rfqId/bom/assembly/:assemblyId/bom-importer/page-1'),
    typed<LumiQuoteRoute>('/assemblies/:assemblyId/dashboard/bom-importer/page-1'),
];

const PAGE_2_ROUTES = [
    typed<LumiQuoteRoute>('/rfqs/:rfqId/bom/assembly/:assemblyId/bom-importer/page-2'),
    typed<LumiQuoteRoute>('/assemblies/:assemblyId/dashboard/bom-importer/page-2'),
];

export const BomImporter = ({
    assemblyId,
    importer,
    viewContext,
    isBomImporterDropzoneShown,
    rfqId,
}: BomImporterProps): JSX.Element => {
    const navigate = useNavigate();
    const [bomImporterScreeningMultiData, setBomImporterScreeningMultiData] = useState<ScreenerHandlerOutput | null>(
        null,
    );
    const {
        bomImporterLinesData,
        setInitialBomImporterLinesData,
        haveBomImporterLinesChanged,
        setHaveBomImporterLinesChanged,
    } = useBomImporterLinesData<BomLineBuildingOutput | null>(null);
    const [tagsState, dispatch] = useTagsState();
    const { bomImportState, error } = importer;
    const handleBomImported = React.useCallback(
        (value: ScreenerHandlerOutput) => {
            setBomImporterScreeningMultiData(value);
            setInitialBomImporterLinesData(null);
            dispatch({ type: 'set-initial-state', state: createInitialTagsState(value) });
        },
        [dispatch, setInitialBomImporterLinesData],
    );

    const handleBomImport = (files: File[]): Promise<void> => {
        setBomImporterScreeningMultiData(null);
        return importer.handleBomImport({ files, rfqId, assemblyId, viewContext });
    };

    const currentSheetIndex =
        tagsState.activeTab === 'AML' && tagsState.amlSheetIndex ? tagsState.amlSheetIndex : tagsState.bomSheetIndex;

    const bomImporterScreeningData = bomImporterScreeningMultiData?.sheets[currentSheetIndex];

    const excelRows: SingleOriginalExcelRow[] | undefined = bomImporterScreeningData?.excel_lines;
    const columnMap = convertTagStateToColumnMap(tagsState, currentSheetIndex);
    // Callback for going from BOM Importer Screening Dialog to BOM Importer Screening Dialog
    const goToBomImporterLinesDialog = (data: BomLineBuildingOutput) => {
        const linesDialoagRoute =
            viewContext.type === 'WithinRfQ'
                ? route('/rfqs/:rfqId/bom/assembly/:assemblyId/bom-importer/page-2', { assemblyId, rfqId })
                : route('/assemblies/:assemblyId/dashboard/bom-importer/page-2', { assemblyId }, { rfqId });
        navigate(linesDialoagRoute);
    };

    const resetStateCallback = React.useCallback(() => {
        const resetRoute =
            viewContext.type === 'WithinRfQ'
                ? route('/rfqs/:rfqId/bom/assembly/:assemblyId', { rfqId, assemblyId })
                : route(
                      '/assemblies/:assemblyId/dashboard',
                      { assemblyId },
                      { rfqId, tab: undefined, isMonitoringOpen: undefined },
                  );
        navigate(resetRoute);
        dispatch({ type: 'set-initial-state', state: { ...tagsState, hasChanged: false } });
    }, [assemblyId, dispatch, navigate, rfqId, tagsState, viewContext.type]);

    const handleBack = React.useCallback(() => {
        navigate(-1);
        dispatch({ type: 'set-initial-state', state: { ...tagsState, hasChanged: false } });
    }, [dispatch, navigate, tagsState]);

    const errorAndResetStateCallback = (message: string) => {
        error.openBOMErrorDialogWithMessage({ message, assemblyId });
        resetStateCallback();
    };

    return (
        <>
            <Switch>
                {PAGE_1_ROUTES.map((route) => {
                    return (
                        <Route key={route} path={route}>
                            {bomImporterScreeningData && excelRows && (
                                <BomImporterScreeningDialog
                                    screeningOutputMultiData={bomImporterScreeningMultiData}
                                    onClose={resetStateCallback}
                                    goToBomImporterLinesDialog={goToBomImporterLinesDialog}
                                    errorAndResetStateCallback={errorAndResetStateCallback}
                                    tagsState={tagsState}
                                    columnMap={columnMap}
                                    dispatch={dispatch}
                                    excelRows={excelRows}
                                    taskResponseData={bomImporterLinesData}
                                    setTaskResponseData={setInitialBomImporterLinesData}
                                    haveBomImporterLinesChanged={haveBomImporterLinesChanged}
                                    setHaveBomImporterLinesChanged={setHaveBomImporterLinesChanged}
                                    rfqId={rfqId}
                                    assemblyId={assemblyId}
                                    viewContext={viewContext}
                                />
                            )}
                        </Route>
                    );
                })}

                {PAGE_2_ROUTES.map((route) => {
                    return (
                        <Route key={route} path={route}>
                            {bomImportState.bomFile && bomImporterScreeningData && bomImporterLinesData && (
                                <BomImporterLinesDialog
                                    rfqId={rfqId}
                                    bomImporterLineBuildingOutput={bomImporterLinesData}
                                    bomScreeningSheet={bomImporterScreeningData}
                                    open={true}
                                    viewContext={viewContext}
                                    assemblyId={assemblyId}
                                    bomImporterFile={bomImportState.bomFile}
                                    onClose={resetStateCallback}
                                    onBack={handleBack}
                                    errorAndResetBomStateCallback={errorAndResetStateCallback}
                                    columnMap={columnMap}
                                    tagsState={tagsState}
                                    dispatch={dispatch}
                                    fileName={bomImportState.bomFile.name}
                                />
                            )}
                        </Route>
                    );
                })}
            </Switch>

            <ImportBomContainer
                openBOMErrorDialogWithMessage={error.openBOMErrorDialogWithMessage}
                handleBomImport={handleBomImport}
                setBomImporterData={handleBomImported}
                rfqId={rfqId}
                viewContext={viewContext}
                dispatch={dispatch}
                assemblyId={assemblyId}
                taskEndpoint={bomImportState.taskEndpoint}
                isUploading={bomImportState.isUploading}
                taskCleanUpCallback={importer.bomImportTaskCleanUp}
                isBomImporterDropzoneShown={isBomImporterDropzoneShown}
            />
            <ErrorDialog
                message={error.message}
                isOpen={error.isBOMErrorDialogOpen}
                setIsOpen={error.setBOMErrorDialogOpen}
                shouldRefreshPage={true}
            />
        </>
    );
};

interface ImportBomContainerProps {
    setBomImporterData: (value: ScreenerHandlerOutput) => void;
    assemblyId: string;
    rfqId: string;
    openBOMErrorDialogWithMessage: ({ message, assemblyId }: { message: string; assemblyId: string }) => void;
    handleBomImport: (files: File[]) => Promise<void>;
    taskCleanUpCallback: () => void;
    taskEndpoint: string | null;
    isUploading: boolean;
    isBomImporterDropzoneShown: boolean;
    viewContext: ViewContext;
    dispatch: React.Dispatch<ColumnTagsSheetsAction>;
}

const ImportBomContainer = ({
    setBomImporterData,
    assemblyId,
    rfqId,
    openBOMErrorDialogWithMessage,
    handleBomImport,
    taskCleanUpCallback,
    taskEndpoint,
    isUploading,
    isBomImporterDropzoneShown,
    viewContext,
    dispatch,
}: ImportBomContainerProps): JSX.Element => {
    const bomScreeningTaskFinishedCallback = (data: LineScreenerErrorOutput | ScreenerHandlerOutput) => {
        if (isScreenerHandlerOutput(data)) return setBomImporterData(data);
        openBOMErrorDialogWithMessage({ message: transEnum(data.error_code, errorCodeTranslations), assemblyId });
    };
    const { openDrawer } = useBomImporterGuidanceDrawer();

    return (
        <>
            {taskEndpoint && isUploading && (
                <BomImporterLoadingDialog
                    viewContext={viewContext}
                    taskFinishedCallback={bomScreeningTaskFinishedCallback}
                    taskEndpoint={taskEndpoint}
                    taskCleanUpCallback={taskCleanUpCallback}
                    defaultMessage={t`Uploading and analyzing BOM... This could take a few minutes`}
                    rfqId={rfqId}
                    dispatch={dispatch}
                    assemblyId={assemblyId}
                />
            )}

            {isBomImporterDropzoneShown && (
                <CenteredLayout bgcolor={colorSystem.neutral[1]} justifyContent="unset" paddingTop={'48px'}>
                    <span id={id('bom_importer/image_drag_and_drop')}>
                        <Dropzone
                            title={isProductionEnvironment() ? 'BOM' : 'BOM & AML'}
                            overrides={{
                                Title: () => (
                                    <Flexbox alignItems="center" gap={4}>
                                        <Text variant="h3">{isProductionEnvironment() ? 'BOM' : 'BOM & AML'}</Text>
                                        {!isProductionEnvironment() && (
                                            <Tooltip
                                                title={t`
                                            Upload a single Excel file containing two sheets: one for the BOM and one for the AML.
                                            Including an AML sheet is optional. Only part options with CPNs or IPNs referenced in both
                                            sheets will be recognized; others will be ignored during the import.
                                            `}
                                                arrow
                                            >
                                                <InfoRounded
                                                    style={{
                                                        cursor: 'pointer',
                                                        color: colorSystem.neutral[5],
                                                        fontSize: '16px',
                                                    }}
                                                />
                                            </Tooltip>
                                        )}
                                    </Flexbox>
                                ),
                            }}
                            multiple={false}
                            onDropAccepted={(files) => handleBomImport(files)}
                            accept={{
                                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
                                'application/vnd.ms-excel': ['.xls'],
                                'application/vnd.ms-excel.sheet.macroEnabled.12': ['.xlsm'],
                                'text/csv': ['.csv'],
                                /* eslint-disable-next-line spellcheck/spell-checker */
                                'application/vnd.oasis.opendocument.spreadsheet': ['.ods'],
                            }}
                            extraContent={
                                <Message
                                    variant="primary"
                                    size="small"
                                    attention="high"
                                    title={''}
                                    message={
                                        <Trans>
                                            Not sure about the BOM requirements?{' '}
                                            <Link onClick={openDrawer} variant="body-small-semibold" attention="high">
                                                Click here
                                            </Link>{' '}
                                            for guidance.
                                        </Trans>
                                    }
                                />
                            }
                        />
                    </span>
                </CenteredLayout>
            )}
        </>
    );
};

function useBomImporterGuidanceDrawer() {
    const { closeDrawer, setDrawer } = useDrawerContext();
    const { data = [], isLoading } = useHttpQuery(
        'GET /bom-files/examples',
        {},
        {
            select: (data) => {
                const singleLevelFileURl = data.data.single_level_bom;
                const multiLevelFileURl = data.data.multi_level_bom;

                return [
                    {
                        url: singleLevelFileURl,
                        label: <Trans>Single level BOM</Trans>,
                    },
                    {
                        url: multiLevelFileURl,
                        label: <Trans>Multi level BOM</Trans>,
                    },
                ];
            },
        },
    );

    // @Tev: I initially tried avoiding doing this, however, it seemed impossible to download different files
    // using the MenuItem component, even after wrapping it in the <a> component
    // we cleanup the dom by removing the <a> element once we are done
    const handleFileDownload = (url: string) => {
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = url;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
    };

    return {
        openDrawer: () => {
            setDrawer(
                <RightBoxDrawer onClose={() => closeDrawer()}>
                    <CloseDrawerButton />
                    <Flexbox flexDirection={'column'} gap={24} padding={'0px 24px 0px 16px'} width={'560px'}>
                        <Flexbox justifyContent="space-between" alignItems="center">
                            <Text variant="h2">
                                <Trans>Guidance on BOM structure</Trans>
                            </Text>

                            <MenuButton
                                label={<Trans>Download example</Trans>}
                                appearance="secondary"
                                size={'medium'}
                                disabled={isLoading}
                                icon={<Download />}
                            >
                                {data.map((file) => {
                                    return (
                                        <MenuItem
                                            key={file.url}
                                            onClick={() => handleFileDownload(file.url)}
                                            label={file.label}
                                        />
                                    );
                                })}
                            </MenuButton>
                        </Flexbox>
                        <Flexbox flexDirection={'column'} gap={8}>
                            <Text variant="h4">
                                <Trans>File format</Trans>
                            </Text>
                            <Text color={colorSystem.neutral[8]}>
                                <Trans>
                                    We support <Text variant="code">.xlsx</Text> and <Text variant="code">.xls</Text>{' '}
                                    files. Support for <Text variant="code">.csv</Text> files is only limited. If you
                                    experience problems with a .csv or macro-enabled file, please save it as{' '}
                                    <Text variant="code">.xlsx</Text> and import it again.
                                </Trans>
                            </Text>
                        </Flexbox>
                        <Flexbox flexDirection={'column'} gap={8}>
                            <Text variant="h4">
                                <Trans>Essential columns</Trans>
                            </Text>
                            <Text>
                                •{' '}
                                <Trans>
                                    <Text variant="code">Quantity</Text> to determine the component quantity.
                                </Trans>
                            </Text>
                            <Text>
                                •{' '}
                                <Trans>
                                    <Text variant="code">Designator</Text> to determine the component location.
                                </Trans>
                            </Text>
                            <Text>
                                •{' '}
                                <Trans>
                                    <Text variant="code">MPN</Text> to identify the manufacturer-bound part.
                                </Trans>
                            </Text>
                            <Text>
                                •{' '}
                                <Trans>
                                    <Text variant="code">Manufacturer</Text> to determine the part's manufacturer.
                                </Trans>
                            </Text>
                            <Text>
                                •{' '}
                                <Trans>
                                    <Text variant="code">Technical parameters</Text> to identify the manufacturer-free
                                    parts.
                                </Trans>
                            </Text>
                            <Text>
                                <Trans>
                                    Alternatively, the identification of the components can also be done via the{' '}
                                    <Text variant="code">Customer Part Number (CPN)</Text> or the{' '}
                                    <Text variant="code">Internal Part Number (IPN)</Text>, provided that these part
                                    numbers have been previously uploaded in Luminovo.
                                </Trans>
                            </Text>
                        </Flexbox>
                        <Flexbox flexDirection={'column'} gap={8}>
                            <Text variant="h4">
                                <Trans>Generic component</Trans>
                            </Text>
                            <Text>
                                <Trans>
                                    Resistors and Capacitors can be identified via{' '}
                                    <Text variant="code">Technical Parameters</Text>. Please do not specify technical
                                    parameters in the <Text variant="code">MPN</Text> or{' '}
                                    <Text variant="code">Manufacturer</Text> columns.
                                </Trans>
                            </Text>
                        </Flexbox>
                        <Flexbox flexDirection={'column'} gap={8}>
                            <Text variant="h4">
                                <Trans>Part alternatives</Trans>
                            </Text>
                            <Text>
                                <Trans>
                                    Part alternatives can be added with a second/third column via{' '}
                                    <Text variant="code">MPN</Text> & <Text variant="code">Manufacturer</Text> or via
                                    extra lines with the same Designator and entries for all essential fields.
                                </Trans>
                            </Text>
                        </Flexbox>
                        <GuidanceTableImage />
                    </Flexbox>
                </RightBoxDrawer>,
            );
        },
    };
}
