export type Validated<T> =
    | {
          status: 'success';
          value: T;
          message?: string;
          timestamp?: number;
      }
    | {
          status: 'error';
          message: string;
          value?: T;
          timestamp?: number;
      }
    | {
          status: 'pending';
          value?: T;
          message?: string;
          timestamp?: number;
      };

export function reduceValidated<T extends Record<string, Validated<any>>>(
    validatedObj: T,
): Validated<{ [K in keyof T]: T[K] extends Validated<infer U> ? U : never }> {
    // Check if any validation is 'error'
    for (const key in validatedObj) {
        const validation = validatedObj[key];
        if (validation.status === 'error') {
            return { ...validation, value: undefined } as Validated<any>;
        }
    }

    // Check if any validation is 'pending'
    for (const key in validatedObj) {
        const validation = validatedObj[key];
        if (validation.status === 'pending') {
            return { status: 'pending', value: undefined };
        }
    }

    // If all validations are 'success', combine their values
    const combinedValue = {} as { [K in keyof T]: T[K] extends Validated<infer U> ? U : never };
    let message = '';
    for (const key in validatedObj) {
        const validation = validatedObj[key];
        if (validation.status === 'success' && validation.value !== undefined) {
            combinedValue[key] = validation.value;
        }
        if (validation.message) {
            message = validation.message;
        }
    }

    return { status: 'success', value: combinedValue, message };
}
