import * as z from 'zod';
import { UserDTORuntype } from '../user';

const CommentTypeRuntype = z.union([
    z.literal('Calculation'),
    z.literal('DesignItem'),
    z.literal('QuoteTracking'),
    z.literal('Rfq'),
    z.literal('Assembly'),
    z.literal('QuoteRequest'),
]);

export interface CommentEntity extends z.infer<typeof CommentEntityRuntype> {}
export const CommentEntityRuntype = z.object({
    type: CommentTypeRuntype,
    data: z.array(z.string()).or(z.string()),
});

export type CommentCategory = z.infer<typeof CommentCategoryRuntype>;
export const CommentCategoryRuntype = z.union([z.literal('Internal'), z.literal('Public')]);

export interface CommentDTO extends z.infer<typeof CommentRuntype> {}
export const CommentRuntype = z.object({
    id: z.string(),
    content: z.string(),
    created_by: UserDTORuntype.omit({ customer: true })
        .and(z.object({ customer: z.string().nullable() }))
        .nullable(),
    resolved_at: z.string().nullable(),
    updated_at: z.string(),
    category: CommentCategoryRuntype,
});
