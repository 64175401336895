import { useDialogAddSupplierAndStockLocation } from '@/modules/Suppliers/SupplierManagement/components/SupplierDialogs/AddSupplierDialog';
import { useHttpQuery } from '@/resources/http/useHttpQuery';
import { plural, t, Trans } from '@lingui/macro';
import { useHasPermission } from '@luminovo/auth';
import { assertUnreachable, Currency, formatRelativeTime, id, isPresent } from '@luminovo/commons';
import {
    colorSystem,
    DestructiveTertiaryIconButton,
    Dropzone,
    FieldDateControlled,
    FieldNumeric,
    FieldNumericControlled,
    FieldRadio,
    FieldSelectControlled,
    FieldTextControlled,
    Flexbox,
    FormItem,
    FormSection,
    Message,
    SecondaryButton,
    TertiaryButton,
    Text,
    useNavigate,
} from '@luminovo/design-system';
import {
    CustomLinkedPartDTO,
    CustomPartPriceBreakInputDTO,
    CustomPartTypeEnum,
    OneTimeCostInputDTO,
    Option,
    PriceType,
    QuantityUnit,
    QuantityUnitDTO,
    RegionsEnum,
    RfqContext,
    SupplierAndStockLocationDTO,
    SupplierPartType,
    SupplierPreference,
    SupplierType,
} from '@luminovo/http-client';
import {
    formatSupplierAndStockLocationDTO,
    priceTypeTranslations,
    QuantityUnitRecordPlural,
    QuantityUnitRecordSingular,
    supplierPreferenceTranslations,
} from '@luminovo/sourcing-core';
import { Add, Close } from '@mui/icons-material';
import { Box, Divider, FormLabel, InputAdornment, styled, Typography } from '@mui/material';
import * as React from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { FormContainer } from '../../../components/formLayouts/FormContainer';
import { SubmitButton } from '../../../components/formLayouts/SubmitButton';
import { transEnum } from '../../../components/localization/TransEnum';
import { PartCard } from '../../../components/partSpecificationCards/PartCard';
import { inputCurrenciesPublicTranslations } from '../../../resources/currencyInputTypes';
import { AttachmentFile } from '../../../resources/http/useHttpFileUpload';
import { useCustomComponent, useCustomPart } from '../../../resources/part/partHandler';
import { useNonExcludedSupplierAndStockLocations } from '../../../resources/supplierAndStockLocation/supplierAndStockLocationHandler';

export type CustomPartOfferFormValues = {
    linkedPart: CustomLinkedPartDTO;
    supplierAndStockLocation: SupplierAndStockLocationDTO;
    quantityUnit: QuantityUnitDTO;
    currency: Currency;
    priceBreaks: CustomPartPriceBreakInputDTO[];
    totalOfferedQuantity?: number | null;
    oneTimeCosts: OneTimeCostInputDTO[];
    validUntilDate?: string;
    notes?: Option<string>;
    priceType: PriceType;
    sourcingBatchSize?: number | null;
    files: AttachmentFile[];
};

function FormItemPriceType() {
    const { control } = useFormContext<CustomPartOfferFormValues>();
    return (
        <FormItem label={t`Type`} required>
            <FieldSelectControlled
                control={control}
                name="priceType"
                required
                FieldProps={{
                    options: [
                        PriceType.ListPrice,
                        PriceType.ContractPrice,
                        PriceType.QuotePrice,
                        PriceType.CustomerNegotiatedPrice,
                    ],
                    getOptionLabel: (option) => transEnum(option, priceTypeTranslations),
                    disableClearable: true,
                }}
            />
        </FormItem>
    );
}

export function CustomPartOfferForm({
    onSubmit,
    defaultValues,
    rfqContext,
    isPanel,
    showUnitOfMeasurementWarning = false,
}: {
    onSubmit: (form: CustomPartOfferFormValues) => Promise<void>;
    defaultValues: CustomPartOfferFormValues;
    rfqContext: RfqContext;
    isPanel: boolean;
    showUnitOfMeasurementWarning?: boolean;
}) {
    return (
        <FormContainer defaultValues={defaultValues} onSubmit={onSubmit} UNSAFE_disableStablePropCheck>
            <Flexbox gap={32} flexDirection="column">
                <FormSection title={t`Offer information`}>
                    <FormItemLinkedPart rfqContext={rfqContext} />
                    <FormItemSupplier />
                    <FormItemPriceType />
                    <FormItemUnitOfMeasurement isPanel={isPanel} showWarning={showUnitOfMeasurementWarning} />
                    <FormItemSourcingBatchSize />
                </FormSection>
                <Divider />
                <FormSection title={t`Pricing information`}>
                    <FormItemCurrency />
                    <FormItemTotalOfferedQuantity />
                    <FormItemPriceBreaks />
                    <FormItemOneTimeCosts />
                </FormSection>
                <Divider />
                <FormSection title={t`Offer validity`}>
                    <FormItemValidUntilDate />
                </FormSection>
                <Divider />
                <FormSection title={t`Additional information`}>
                    <FormItemNotes />
                    <FormItemFiles />
                </FormSection>
                <Divider />
                <Flexbox flexDirection="row" gap={8} justifyContent="flex-end">
                    <CancelButton />
                    <SubmitButton />
                </Flexbox>
            </Flexbox>
        </FormContainer>
    );
}

function CancelButton() {
    const navigate = useNavigate();
    const handleClose = React.useCallback(() => {
        navigate(-1);
    }, [navigate]);
    return (
        <SecondaryButton onClick={handleClose}>
            <Trans>Cancel</Trans>
        </SecondaryButton>
    );
}

function FormItemLinkedPart({ rfqContext }: { rfqContext: RfqContext }) {
    const { control } = useFormContext<CustomPartOfferFormValues>();
    const linkedPart = useWatch({ control, name: 'linkedPart' });

    if (linkedPart.type === 'CustomPart') {
        return <FormItemCustomPart partId={linkedPart.id} rfqContext={rfqContext} />;
    }

    if (linkedPart.type === 'CustomComponent') {
        return <FormItemCustomComponent ipnId={linkedPart.id} rfqContext={rfqContext} />;
    }

    assertUnreachable(linkedPart);
}

function FormItemCustomPart({ partId, rfqContext }: { partId: string; rfqContext: RfqContext }) {
    const { data: part } = useCustomPart(partId);

    return (
        <FormItem label={t`Part`}>
            <PartCard part={part} rfqContext={rfqContext} collapsed={true} />
        </FormItem>
    );
}

function FormItemCustomComponent({ ipnId, rfqContext }: { ipnId: string; rfqContext: RfqContext }) {
    const { data: customComponent } = useCustomComponent(ipnId, rfqContext);

    return (
        <FormItem label={t`Custom component`}>
            <PartCard part={customComponent} rfqContext={rfqContext} collapsed={true} />
        </FormItem>
    );
}

function FormItemSupplier() {
    const { control, setValue } = useFormContext<CustomPartOfferFormValues>();

    const hasCreateSupplierPermission = useHasPermission(['create:supplier']);
    const { data: supplierAndStockLocations } = useNonExcludedSupplierAndStockLocations();
    const { openDialog } = useDialogAddSupplierAndStockLocation({
        onSuccessCallback: (s) => {
            setValue('supplierAndStockLocation', s, { shouldValidate: true });
        },
        disableSupplierPreferenceField: true,
    });

    return (
        <FormItem label={t`Supplier`} required>
            <FieldSelectControlled
                control={control}
                name="supplierAndStockLocation"
                required
                FieldProps={{
                    id: id('sourcing/input_supplier'),
                    options: supplierAndStockLocations,
                    getOptionKey: (option) => option.id,
                    getOptionLabel: (option) => formatSupplierAndStockLocationDTO(option),
                    getOptionSublabel: (option) => option.supplier_number,
                    isOptionEqualToValue: (option, value) => option.id === value.id,
                    groupBy: (option) => transEnum(option.preference, supplierPreferenceTranslations),
                    action: {
                        label: t`Add new supplier`,
                        disabled: !hasCreateSupplierPermission,
                        onClick: () => {
                            openDialog({
                                name: '',
                                stockLocation: RegionsEnum.Unknown,
                                supplierNumber: '',
                                supplierPreference: SupplierPreference.NotApproved,
                                supplierType: SupplierType.Manufacturer,
                                supplierPartType: SupplierPartType.Custom,
                            });
                        },
                    },
                }}
            />
        </FormItem>
    );
}

function FormItemUnitOfMeasurement({ showWarning, isPanel }: { showWarning: boolean; isPanel: boolean }) {
    const { control } = useFormContext<CustomPartOfferFormValues>();
    const linkedPart = useWatch({ control, name: 'linkedPart' });
    const quantityUnit = useWatch({ control, name: 'quantityUnit' });
    const [editMode, setEditMode] = React.useState(false);

    return (
        <>
            <FormItem
                label={isPanel ? t`Panel size` : t`Unit of measurement`}
                required
                description={
                    <Trans>
                        All other quantities are multiplied by the unit of measurement to obtain the total quantity. For
                        example, if the unit of measurement is “10 meters” and you have “2” in stock, then we calculate
                        this as 2 * 10 meters = 20 meters in stock
                    </Trans>
                }
                actions={
                    showWarning ? (
                        <TertiaryButton size="small" onClick={() => setEditMode((edit) => !edit)}>
                            <Trans>Edit unit of measurement</Trans>
                        </TertiaryButton>
                    ) : undefined
                }
            >
                {editMode && (
                    <Message
                        size={'small'}
                        variant={'yellow'}
                        attention={'low'}
                        message={t`Modifying the unit of measurement will affect both quantity and stock`}
                    />
                )}
                <Box display={'grid'} gridTemplateColumns="1fr 120px" columnGap={8}>
                    <FieldNumericControlled
                        control={control}
                        name="quantityUnit.quantity"
                        required={true}
                        min={1}
                        FieldProps={{
                            disabled: showWarning ? !editMode : false,
                        }}
                    />
                    <FieldSelectControlled
                        control={control}
                        name="quantityUnit.unit"
                        FieldProps={{
                            disableClearable: true,
                            options: Object.values(QuantityUnit),
                            getOptionLabel: (option) => transEnum(option, QuantityUnitRecordSingular),
                            disabled: showWarning ? !editMode : false,
                        }}
                    />
                </Box>
            </FormItem>
            {linkedPart.type === 'CustomPart' && (
                <PanelMismatchWarningMessage partId={linkedPart.id} quantityUnit={quantityUnit} />
            )}
        </>
    );
}

const PanelMismatchWarningMessage = ({ partId, quantityUnit }: { partId: string; quantityUnit: QuantityUnitDTO }) => {
    const { setValue } = useFormContext<CustomPartOfferFormValues>();
    const { data: part } = useCustomPart(partId);
    const pcbId = part?.type.name === CustomPartTypeEnum.PCB ? part?.type.content : undefined;

    const { data: panel } = useHttpQuery(
        'GET /panels',

        { queryParams: { pcb_id: pcbId ?? '' } },
        {
            enabled: isPresent(pcbId),
            select: (data) => {
                if (data.length === 1) {
                    return data[0];
                } else {
                    return undefined;
                }
            },
        },
    );

    if (!panel || quantityUnit.unit !== QuantityUnit.Pieces) {
        return <></>;
    }

    const { rowCount, columnCount, numberOfPcbs } =
        panel.type === 'Existing'
            ? {
                  numberOfPcbs: panel.data.number_of_pcbs,
                  rowCount: undefined,
                  columnCount: undefined,
              }
            : {
                  rowCount: panel.data.panel_details.row_count,
                  columnCount: panel.data.panel_details.column_count,
                  numberOfPcbs: panel.data.panel_details.row_count * panel.data.panel_details.column_count,
              };

    const translatedNumberOfRows = rowCount
        ? plural(rowCount, {
              zero: 'rows',
              one: 'row',
              other: 'rows',
          })
        : undefined;

    const translatedNumberOfColumns = columnCount
        ? plural(columnCount, {
              zero: 'columns',
              one: 'column',
              other: 'columns',
          })
        : undefined;

    if (numberOfPcbs !== quantityUnit.quantity) {
        return (
            <Message
                size={'large'}
                variant={'yellow'}
                attention={'high'}
                title={t`Unit of measurement mismatch`}
                message={t`The specified panel configuration (${rowCount && columnCount ? `${rowCount} ${translatedNumberOfRows} × ${columnCount} ${translatedNumberOfColumns}` : `${numberOfPcbs} pcbs`}) does not match the defined unit of measurement. Please ensure the 'Unit of measurement' field reflects the correct panel size(${numberOfPcbs} ${transEnum(
                    quantityUnit.unit,
                    QuantityUnitRecordPlural,
                )}).`}
                action={{
                    label: t`Set unit of measurement to panel size`,
                    onClick: () => setValue('quantityUnit.quantity', numberOfPcbs),
                }}
            />
        );
    }

    return <></>;
};

function FormItemCurrency() {
    const { control } = useFormContext<CustomPartOfferFormValues>();
    return (
        <FormItem label={t`Currency`} required>
            <FieldSelectControlled
                control={control}
                name="currency"
                required
                FieldProps={{
                    options: Object.values(Currency),
                    getOptionLabel: (option) => transEnum(option, inputCurrenciesPublicTranslations),
                    disableClearable: true,
                }}
            />
        </FormItem>
    );
}

function FormItemPriceBreaks() {
    const { control } = useFormContext<CustomPartOfferFormValues>();
    const currency = useWatch({ control, name: 'currency' });
    const unitOfMeasurement = useWatch({ control, name: 'quantityUnit.quantity' });
    const unit = useWatch({ control, name: 'quantityUnit.unit' });
    const disabled = !isPresent(unitOfMeasurement) || isNaN(unitOfMeasurement);

    const linkedPart = useWatch({ control, name: 'linkedPart' });
    const { data: part } = useCustomPart(linkedPart.id);
    const isPcbCustomPart = part?.type.name === CustomPartTypeEnum.PCB;

    const { fields, append, remove } = useFieldArray({ control, name: 'priceBreaks' });

    if (isPcbCustomPart) {
        return <FormItemPanelPriceBreaks />;
    }

    return (
        <FormItem label={t`Price breaks`}>
            <Box
                display={'grid'}
                alignItems="center"
                gridTemplateColumns={'1fr 1fr 1fr auto'}
                rowGap={'16px'}
                columnGap={'16px'}
            >
                <Typography color="textSecondary">
                    <Trans>Minimum order quantity</Trans>*
                </Typography>
                <Typography color="textSecondary">
                    {isPcbCustomPart ? <Trans>Panel price</Trans> : <Trans>Unit price</Trans>}*
                </Typography>
                <Typography color="textSecondary">
                    <Trans>Lead time (days)</Trans>
                </Typography>

                <span />
                {fields.map((item, index) => {
                    return (
                        <React.Fragment key={`${index} -${JSON.stringify(item)} `}>
                            <FieldNumericControlled
                                control={control}
                                name={`priceBreaks.${index}.minimum_order_quantity`}
                                exclusiveMin={0}
                                required
                                FieldProps={{
                                    placeholder: t`Quantity`,
                                    InputProps: {
                                        endAdornment:
                                            disabled || unitOfMeasurement === 1 ? undefined : (
                                                <InputAdornment position="end">
                                                    {`× ${unitOfMeasurement} ${transEnum(
                                                        unit,
                                                        QuantityUnitRecordPlural,
                                                    )} `}
                                                </InputAdornment>
                                            ),
                                    },
                                }}
                            />
                            <FieldNumericControlled
                                control={control}
                                name={`priceBreaks.${index}.amount`}
                                exclusiveMin={0}
                                required
                                FieldProps={{
                                    InputProps: {
                                        endAdornment: <InputAdornment position="end">{currency}</InputAdornment>,
                                    },
                                }}
                            />
                            <FieldNumericControlled
                                control={control}
                                name={`priceBreaks.${index}.lead_time_days`}
                                isInteger={true}
                                min={1}
                                FieldProps={{
                                    placeholder: t`Full days`,
                                }}
                            />
                            <DestructiveTertiaryIconButton
                                onClick={() => remove(index)}
                                size="medium"
                                disabled={fields.length < 2}
                            >
                                <Close fontSize="inherit" />
                            </DestructiveTertiaryIconButton>
                        </React.Fragment>
                    );
                })}
                <SecondaryButton
                    startIcon={<Add />}
                    onClick={() => {
                        append({
                            minimum_order_quantity: fields
                                .map((x) => x.minimum_order_quantity)
                                .reduce((a, b) => Math.max(a, b), 1),
                            amount: '0',
                            lead_time_days: undefined,
                            description: undefined,
                        });
                    }}
                    size="medium"
                >
                    <Trans>Add price point</Trans>
                </SecondaryButton>
            </Box>
        </FormItem>
    );
}

const FormItemPanelPriceBreaks = () => {
    const { control } = useFormContext<CustomPartOfferFormValues>();
    const { fields, append, remove } = useFieldArray({ control, name: 'priceBreaks' });
    const [mode, setMode] = React.useState<'panel' | 'unit'>('panel');

    return (
        <>
            <Flexbox alignItems={'center'} gap={8}>
                <FormLabel>
                    <Flexbox alignItems={'center'} gap={8}>
                        <FieldRadio value={mode} fieldValue={'panel'} onChange={() => setMode('panel')} />
                        <Text>
                            <Trans>Panel price</Trans>
                        </Text>
                    </Flexbox>
                </FormLabel>
                <FormLabel>
                    <Flexbox alignItems={'center'} gap={8}>
                        <FieldRadio value={mode} fieldValue={'unit'} onChange={() => setMode('unit')} />
                        <Text>
                            <Trans>Unit price</Trans>
                        </Text>
                    </Flexbox>
                </FormLabel>
            </Flexbox>

            <Box
                display={'grid'}
                alignItems="center"
                gridTemplateColumns={'1fr 1fr 1fr auto'}
                rowGap={'16px'}
                columnGap={'16px'}
            >
                <Text style={{ color: colorSystem.neutral[7] }}>
                    {mode === 'panel' ? <Trans>Panel MOQ</Trans> : <Trans>Unit MOQ</Trans>}*
                </Text>
                <Text style={{ color: colorSystem.neutral[7] }}>
                    {mode === 'panel' ? <Trans>Panel price</Trans> : <Trans>Unit price</Trans>}*
                </Text>
                <Text style={{ color: colorSystem.neutral[7] }}>
                    <Trans>Lead time (days)</Trans>
                </Text>

                <span />
                {fields.map((item, index) => {
                    return (
                        <FormItemPricePointInput
                            key={`${index} -${JSON.stringify(item)} `}
                            index={index}
                            disabled={fields.length < 2}
                            remove={remove}
                            mode={mode}
                        />
                    );
                })}

                <SecondaryButton
                    startIcon={<Add />}
                    onClick={() => {
                        append({
                            minimum_order_quantity: fields
                                .map((x) => x.minimum_order_quantity)
                                .reduce((a, b) => Math.max(a, b), 1),
                            amount: '0',
                            lead_time_days: undefined,
                            description: undefined,
                        });
                    }}
                    size="medium"
                >
                    <Trans>Add price point</Trans>
                </SecondaryButton>
            </Box>
        </>
    );
};

const FormItemPricePointInput = ({
    index,
    disabled,
    remove,
    mode,
}: {
    index: number;
    disabled: boolean;
    remove: (index?: number | number[]) => void;
    mode: 'panel' | 'unit';
}) => {
    const { control, setValue } = useFormContext<CustomPartOfferFormValues>();
    const unitOfMeasurementQuantity = useWatch({ control, name: `quantityUnit.quantity` });
    const panelQuantity = useWatch({ control, name: `priceBreaks.${index}.minimum_order_quantity` });
    const panelPrice = useWatch({ control, name: `priceBreaks.${index}.amount` });
    const [unitPrice, setUnitPrice] = React.useState<number>(0);
    const [unitQuantity, setUnitQuantity] = React.useState<number>(0);
    const [showWarning, setShowWarning] = React.useState(true);
    const currency = useWatch({ control, name: 'currency' });

    const showPanelWarning = mode === 'panel' && panelQuantity <= 1 && unitOfMeasurementQuantity === 1;

    const handleUnitPriceChange = (value: number | null) => {
        if (value !== null) {
            setUnitPrice(value);
            setValue(`priceBreaks.${index}.amount`, String(value * unitOfMeasurementQuantity));
        }
    };

    const handleUnitQuantityChange = (value: number | null) => {
        if (value !== null) {
            setUnitQuantity(value);
            setValue(`priceBreaks.${index}.minimum_order_quantity`, value / unitOfMeasurementQuantity);
        }
    };

    React.useEffect(() => {
        const updatedUnitPrice =
            panelPrice && unitOfMeasurementQuantity ? Number(panelPrice) / unitOfMeasurementQuantity : undefined;
        const updatedUnitQuantity =
            panelQuantity && unitOfMeasurementQuantity ? Number(panelQuantity) * unitOfMeasurementQuantity : undefined;

        if (updatedUnitQuantity) {
            setUnitQuantity(updatedUnitQuantity);
        }

        if (updatedUnitPrice) {
            setUnitPrice(updatedUnitPrice);
        }
    }, [unitOfMeasurementQuantity, panelPrice, panelQuantity]);

    return (
        <>
            {mode === 'panel' ? (
                <>
                    <FieldNumericControlled
                        control={control}
                        name={`priceBreaks.${index}.minimum_order_quantity`}
                        exclusiveMin={0}
                        required={mode === 'panel'}
                        FieldProps={{
                            onChangeCapture: (event: React.ChangeEvent<HTMLInputElement>) => {
                                const value = event.target.value;
                                setUnitQuantity(Number(value) * unitOfMeasurementQuantity);
                            },
                            placeholder: t`Panel MOQ`,
                        }}
                    />
                    <FieldNumericControlled
                        control={control}
                        name={`priceBreaks.${index}.amount`}
                        exclusiveMin={0}
                        required={mode === 'panel'}
                        FieldProps={{
                            onChangeCapture: (event: React.ChangeEvent<HTMLInputElement>) => {
                                const value = event.target.value;
                                setUnitPrice(Number(value) / unitOfMeasurementQuantity);
                            },
                            InputProps: {
                                endAdornment: <InputAdornment position="end">{currency}</InputAdornment>,
                            },
                        }}
                    />
                </>
            ) : (
                <>
                    <FieldNumeric required={mode === 'unit'} value={unitQuantity} onChange={handleUnitQuantityChange} />
                    <FieldNumeric
                        required={mode === 'unit'}
                        value={unitPrice}
                        onChange={handleUnitPriceChange}
                        slotProps={{
                            input: {
                                endAdornment: <InputAdornment position="end">{currency}</InputAdornment>,
                            },
                        }}
                    />
                </>
            )}
            <FieldNumericControlled
                control={control}
                name={`priceBreaks.${index}.lead_time_days`}
                isInteger={true}
                min={1}
                FieldProps={{
                    placeholder: t`Full days`,
                }}
            />
            <DestructiveTertiaryIconButton onClick={() => remove(index)} size="medium" disabled={disabled}>
                <Close fontSize="inherit" />
            </DestructiveTertiaryIconButton>

            {showWarning && showPanelWarning && (
                <>
                    <Box style={{ gridColumn: 'span 3' }}>
                        <Message
                            size={'small'}
                            variant={'yellow'}
                            attention={'high'}
                            message={t`The unit of measurement has been set to 1, which may indicate that the panel size has not been updated. Please verify that the 'Unit of measurement' field reflects the correct number of PCB boards per panel.`}
                            onClose={() => setShowWarning(false)}
                        />
                    </Box>
                    <span />
                </>
            )}
        </>
    );
};

function FormItemOneTimeCosts() {
    const { control } = useFormContext<CustomPartOfferFormValues>();
    const currency = useWatch({ control, name: 'currency' });

    const { fields, append, remove } = useFieldArray({ control, name: 'oneTimeCosts' });

    return (
        <FormItem label={t`One - time costs`}>
            <Box
                display={'grid'}
                alignItems="center"
                gridTemplateColumns={'1fr 1fr 1fr auto'}
                rowGap={'16px'}
                columnGap={'16px'}
            >
                <Typography color="textSecondary">
                    <Trans>Cost</Trans>*
                </Typography>
                <Typography color="textSecondary">
                    <Trans>Description</Trans>
                </Typography>
                <span />
                <span />
                {fields.map((item, index) => {
                    return (
                        <React.Fragment key={`${index} -${JSON.stringify(item)} `}>
                            <FieldNumericControlled
                                control={control}
                                name={`oneTimeCosts.${index}.amount`}
                                exclusiveMin={0}
                                required
                                FieldProps={{
                                    InputProps: {
                                        endAdornment: <InputAdornment position="end">{currency}</InputAdornment>,
                                    },
                                }}
                            />
                            <FieldTextControlled control={control} name={`oneTimeCosts.${index}.description`} />
                            <span />
                            <DestructiveTertiaryIconButton onClick={() => remove(index)} size="medium">
                                <Close fontSize="inherit" />
                            </DestructiveTertiaryIconButton>
                        </React.Fragment>
                    );
                })}
                <SecondaryButton
                    startIcon={<Add />}
                    onClick={() => {
                        append({
                            amount: String(1),
                            description: undefined,
                        });
                    }}
                    size="medium"
                >
                    <Trans>Add one-time cost</Trans>
                </SecondaryButton>
            </Box>
        </FormItem>
    );
}

function FormItemTotalOfferedQuantity() {
    const { control } = useFormContext<CustomPartOfferFormValues>();

    return (
        <FormItem label={t`Total offered quantity`}>
            <FieldNumericControlled
                control={control}
                name={`totalOfferedQuantity`}
                exclusiveMin={0}
                isInteger={true}
                FieldProps={{
                    placeholder: t`Total offered quantity`,
                }}
            />
        </FormItem>
    );
}

function FormItemSourcingBatchSize() {
    const { control } = useFormContext<CustomPartOfferFormValues>();

    return (
        <FormItem label={t`Sourcing batch size`}>
            <FieldNumericControlled
                control={control}
                name={`sourcingBatchSize`}
                exclusiveMin={0}
                isInteger={true}
                FieldProps={{
                    placeholder: t`Sourcing batch size`,
                }}
            />
        </FormItem>
    );
}

function FormItemValidUntilDate() {
    const { control } = useFormContext<CustomPartOfferFormValues>();
    const validUntilDate = useWatch({ control, name: 'validUntilDate' });

    return (
        <FormItem label={t`Valid until`}>
            <FieldDateControlled control={control} name={'validUntilDate'} />
            {validUntilDate && <Text variant="body">{formatRelativeTime(validUntilDate)}.</Text>}
        </FormItem>
    );
}

function FormItemNotes() {
    const { control } = useFormContext<CustomPartOfferFormValues>();
    return (
        <FormItem label={t`Notes`}>
            <FieldTextControlled control={control} name="notes" FieldProps={{ multiline: true, minRows: 2 }} />
        </FormItem>
    );
}

const FormItemFiles = () => {
    const { control, setValue } = useFormContext<CustomPartOfferFormValues>();
    const files = useWatch({ control, name: 'files' });

    const handleChangeDropZone = (newFiles: File[]) => {
        const filesWithUrl = newFiles.map(
            (file): AttachmentFile => ({
                file: file,
                url: URL.createObjectURL(file),
                name: file.name,
                state: 'PendingUpload',
            }),
        );
        setValue('files', [...files, ...filesWithUrl]);
    };

    const handleDelete = async (removedFile: AttachmentFile) => {
        setValue(
            'files',
            files.map((file) => {
                if (file.url === removedFile.url) {
                    if (file.file) {
                        file.state = 'RemovedLocal';
                    } else {
                        file.state = 'PendingRemoval';
                    }
                }
                return file;
            }),
        );
    };

    const renderFiles = files.filter((file) => file.state === 'StoredInAzure' || file.state === 'PendingUpload');
    return (
        <FormItem label={t`Files`}>
            <Dropzone
                accept={null}
                title={''}
                subHeader={t`You can upload additional files to your offer`}
                onDropAccepted={handleChangeDropZone}
                multiple={true}
                overrides={{ Container }}
                persistentFiles={renderFiles.map((file) => ({
                    name: file.name,
                    onClick: () => window.open(file.url, '_blank'),
                    onDelete: () => handleDelete(file),
                }))}
            />
        </FormItem>
    );
};

const Container = styled(Flexbox)({
    flexDirection: 'column',
});
