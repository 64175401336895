import { t } from '@lingui/macro';
import { isProductionEnvironment } from '@luminovo/commons';
import { BomScreeningSheet, ScreenerHandlerOutput } from '@luminovo/http-client';
import { useCallback } from 'react';
import {
    ColumnTagsSheetsAction,
    ColumnTagsSheetsState,
} from '../../DesignItemDetails/components/AutocompleteColumnTags/types';
import { ValidationError } from '../../DesignItemDetails/components/AutocompleteColumnTags/validation';
import { BomImporterDetails } from './BomImporterDetails';
import { SheetSelect } from './BomSheetSelect';
import { ColumnMappingGuidanceBanner } from './ColumnMappingGuidance/ColumnMappingGuidanceBanner';
import { ReusePartOptionsFromPreviousImport } from './ReusePartOptionsFromPreviousImport';

export function BomImporterSidebar({
    bomScreeningOutput,
    sheetIndex,
    validationErrors,
    tagsState,
    dispatch,
}: {
    bomScreeningOutput: ScreenerHandlerOutput;
    sheetIndex: number;
    validationErrors: ValidationError[];
    tagsState: ColumnTagsSheetsState;
    dispatch: React.Dispatch<ColumnTagsSheetsAction>;
}) {
    const bomScreeningSheet: BomScreeningSheet = bomScreeningOutput.sheets[sheetIndex];

    const assignSheet = useCallback(
        (sheetIndex: number | null, sheetType: 'BOM' | 'AML') => {
            if (sheetType === 'BOM') {
                sheetIndex !== null && dispatch({ type: 'set-bom-sheet-index', index: sheetIndex });
            } else {
                dispatch({ type: 'set-aml-sheet-index', index: sheetIndex });
            }
        },
        [dispatch],
    );

    return (
        <>
            <ColumnMappingGuidanceBanner />
            <SheetSelect
                sheetIndex={tagsState.bomSheetIndex}
                sheetType={'BOM'}
                title={t`Select the BOM sheet`}
                bomScreeningOutput={bomScreeningOutput}
                assignSheet={assignSheet}
                tagsState={tagsState}
            />
            {!isProductionEnvironment() && (
                <SheetSelect
                    sheetIndex={tagsState.amlSheetIndex}
                    sheetType={'AML'}
                    title={t`Select the AML sheet`}
                    bomScreeningOutput={bomScreeningOutput}
                    assignSheet={assignSheet}
                    tagsState={tagsState}
                />
            )}
            <ReusePartOptionsFromPreviousImport tagsState={tagsState} dispatch={dispatch} />
            <BomImporterDetails bomScreeningSheet={bomScreeningSheet} validationErrors={validationErrors} />
        </>
    );
}
