import { useDesignItemsFromSolutionConfigurationCalculation } from '@/resources/solutionConfiguration/solutionConfigurationHandler';
import { t } from '@lingui/macro';
import { Toolbar } from '@luminovo/design-system';
import { route } from '../../../../utils/routes';
import SourcingScenarioActionMenu from '../../../Sourcing/components/SourcingScenarios/SourcingScenarioActionMenu';
import { useIsSourcingScenarioPcbOnlyType } from '../../../Sourcing/utils/useIsSourcingScenarioPcbOnlyType';
import { ButtonAddManualOffer } from './ButtonAddManualOffer';
import { ButtonGoToBom } from './ButtonGoToBom';
import { SolutionManagerCommentsButton } from './SolutionManagerCommentsButton';

export function ToolbarSolutionManager({
    rfqId,
    sourcingScenarioName,
    sourcingScenarioId,
    solutionConfigId,
}: {
    rfqId: string;
    sourcingScenarioName: string;
    sourcingScenarioId: string;
    solutionConfigId: string;
}) {
    const { data: IsPcbOnlyType } = useIsSourcingScenarioPcbOnlyType(sourcingScenarioId);
    const { designItems } = useDesignItemsFromSolutionConfigurationCalculation(solutionConfigId);

    return (
        <Toolbar
            breadcrumbs={[
                {
                    title: t`Sourcing`,
                    href: route(`/rfqs/:rfqId/sourcing`, { rfqId }),
                },
                {
                    title: sourcingScenarioName,
                    href: route(`/rfqs/:rfqId/sourcing/scenarios/:sourcingScenarioId`, {
                        rfqId,
                        sourcingScenarioId,
                    }),
                },
                {
                    title: designItems?.map((item) => item.designator).join(', ') ?? '',
                },
            ]}
        >
            <SolutionManagerCommentsButton rfqId={rfqId} solutionConfigId={solutionConfigId} />
            {IsPcbOnlyType === true ? (
                <SourcingScenarioActionMenu sourcingScenarioId={sourcingScenarioId} />
            ) : (
                <ButtonGoToBom rfqId={rfqId} solutionConfigurationId={solutionConfigId} />
            )}
            <ButtonAddManualOffer rfqId={rfqId} solutionConfigurationId={solutionConfigId} />
        </Toolbar>
    );
}
