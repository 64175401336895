/* eslint-disable spellcheck/spell-checker */
import { useDialogContext } from '@/components/contexts/ModalContext';
import DialogBox from '@/components/dialogBox/DialogBox';
import { useHttpMutation } from '@/resources/mutation/useHttpMutation';
import { SupplierTermsWithSite } from '@/resources/supplierTerms/supplierTermsHandler';
import { t } from '@lingui/macro';
import { SupplierTermsUpdateDTO } from '@luminovo/http-client';
import { SupplierTermForm, SupplierTermFormState } from './SupplierTermForm';

export const useDialogEditSupplierTerm = (supplierTerm: SupplierTermsWithSite) => {
    const { setDialog, closeDialog } = useDialogContext();

    const { mutateAsync: updateSupplierTerms } = useHttpMutation('PATCH /supplier-terms/:supplierTermsId', {
        snackbarMessage: t`Terms edited successfully`,
        onSuccess: closeDialog,
    });

    const onSubmit = async (formValues: SupplierTermFormState) => {
        const requestBody: SupplierTermsUpdateDTO = {
            site:
                formValues.site === supplierTerm.site
                    ? undefined
                    : formValues.site && formValues.site.id !== 'all-sites'
                      ? formValues.site.id
                      : null,
            incoterms: formValues.incoterms === supplierTerm.incoterms ? undefined : formValues.incoterms,
            incoterms_named_place:
                formValues.named_place === supplierTerm.incoterms_named_place
                    ? undefined
                    : formValues.named_place && formValues.named_place !== ''
                      ? formValues.named_place
                      : null,
            net_payment_due_days:
                formValues.payment_terms.net_payment_due_days === supplierTerm.net_payment_due_days
                    ? undefined
                    : formValues.payment_terms.net_payment_due_days &&
                        formValues.payment_terms.net_payment_due_days !== 0
                      ? formValues.payment_terms.net_payment_due_days
                      : null,
            early_payment_due_days:
                formValues.payment_terms.early_payment_due_days === supplierTerm.early_payment_due_days
                    ? undefined
                    : formValues.payment_terms.early_payment_due_days &&
                        formValues.payment_terms.early_payment_due_days !== 0
                      ? formValues.payment_terms.early_payment_due_days
                      : null,
            early_payment_discount_percent:
                formValues.payment_terms.early_payment_discount === supplierTerm.early_payment_discount_percent
                    ? undefined
                    : formValues.payment_terms.early_payment_discount &&
                        formValues.payment_terms.early_payment_discount !== 0
                      ? formValues.payment_terms.early_payment_discount
                      : null,
        };

        await updateSupplierTerms({
            requestBody,
            pathParams: { supplierTermsId: supplierTerm.id },
        });
    };

    const prevState: SupplierTermFormState = {
        site: supplierTerm.site ?? null,
        incoterms: supplierTerm.incoterms,
        named_place: supplierTerm.incoterms_named_place ?? undefined,
        payment_terms: {
            net_payment_due_days: supplierTerm.net_payment_due_days ?? undefined,
            early_payment_due_days: supplierTerm.early_payment_due_days ?? undefined,
            early_payment_discount: supplierTerm.early_payment_discount_percent ?? undefined,
        },
    };

    const dialog = (
        <DialogBox maxWidth="sm" title={t`Edit supplier terms`} isDialogOpen={true} onReject={closeDialog}>
            <SupplierTermForm prevState={prevState} onSubmit={onSubmit} handleClose={closeDialog} />
        </DialogBox>
    );

    return {
        openDialog: () => setDialog(dialog),
    };
};
