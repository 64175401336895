import { t } from '@lingui/macro';
import type { ParseResult, ParsedValue, Parser } from '../types';

export interface ParseManufacturerOptions {
    /**
     * A map of manufacturer names to their parts count.
     */
    manufacturerMap: Map<string, ParsedValue<string> & { partsCount: number; value: string }>;

    /**
     * A list of alternative manufacturers.
     *
     * While this might seem like a duplicate of the manufacturers map because parsers are in the hot path, it is better to just have a precomputed list of alternatives
     * instead of deriving it from the manufacturer map.
     */
    alternatives: Array<ParsedValue<string> & { partsCount: number; value: string }>;

    /**
     * If true, only alphanumeric characters will be used when checking against the manufacturer names.
     *
     * **NOTE: Ensure that the keys in the manufacturer map are also cleaned of non-alphanumeric characters.**
     */
    useOnlyAlphanumeric?: boolean;

    /**
     * If true, the manufacturer name will be transformed to lowercase before being checked against the manufacturer map.
     *
     * **NOTE: Ensure that the keys in the manufacturer map are also transformed to lowercase.**
     */
    transformToLowerCase?: boolean;

    /**
     * If true, an error will be shown if the manufacturer name is not in the list of manufacturers given
     */
    errorOnUnknown?: boolean;

    /**
     * If set, a warning will be shown if the manufacturer has less than the given number of part options.
     */
    highQualityPartsCountThreshold?: number;
}

/**
 * Parses a manufacturer name and returns a ParsedResult
 *
 * It tries to parse the name and returns the value specified in the id of the manufacturer object
 */
export const parseManufacturer: Parser<string, ParseManufacturerOptions> = async function (
    [cell],
    opts,
    field,
): Promise<ParseResult<string>> {
    cell = cell.trim();

    if (cell.length === 0 && field.defaultValue) {
        return {
            status: 'done',
            value: field.defaultValue,
            message: t`Using default value`,
            alternatives: opts.alternatives,
        };
    }

    const cleanedCell = opts.useOnlyAlphanumeric ? cell.replace(/[^a-zA-Z0-9]/g, '') : cell;

    const found = opts.transformToLowerCase
        ? opts.manufacturerMap.get(cleanedCell.toLowerCase())
        : opts.manufacturerMap.get(cleanedCell);

    if (!found) {
        if (opts.errorOnUnknown) {
            return {
                status: 'error',
                message: t`Unknown manufacturer name`,
                alternatives: opts.alternatives,
            };
        }

        return {
            status: 'warning',
            value: {
                id: cleanedCell,
                label: cell,
            },
            alternatives: opts.alternatives,
            message: t`Unknown manufacturer name`,
        };
    }

    // If the manufacturer is found and is not high quality, show a warning
    if (found && opts.highQualityPartsCountThreshold && found.partsCount < opts.highQualityPartsCountThreshold) {
        return {
            status: 'warning',
            value: {
                ...found,
                label: cell,
            },
            alternatives: opts.alternatives,
            message: t`This manufacturer has ${found.partsCount} part options which is below the ${opts.highQualityPartsCountThreshold}+ threshold for high quality manufacturers.`,
        };
    }

    return {
        status: 'done',
        value: {
            ...found,
            label: cell,
        },
        message: t`Using manufacturer name with ${found.partsCount} part options`,
        alternatives: opts.alternatives,
    };
};
