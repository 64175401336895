import * as z from 'zod';
import { ApiIntegrationStatusRuntype } from './3rdPartyBackendTypes';

export interface SourcengineCredentialsDTO extends z.infer<typeof SourcengineCredentialsDTORuntype> {}
export const SourcengineCredentialsDTORuntype = z.object({
    api_key: z.string(),
});

export interface SourcengineCredentialsInputDTO extends z.infer<typeof SourcengineCredentialsInputDTORuntype> {}
export const SourcengineCredentialsInputDTORuntype = z.object({
    api_key: z.string().optional(),
});

export interface SourcengineResponseBodyDTO extends z.infer<typeof SourcengineResponseBodyDTORuntype> {}
export const SourcengineResponseBodyDTORuntype = z.object({
    data: SourcengineCredentialsDTORuntype.nullable(),
    status: ApiIntegrationStatusRuntype,
});
