import * as z from 'zod';
import { endpoint } from '../http/endpoint';
import {
    OnlineComponentsCredentialsInputDTORuntype,
    OnlineComponentsResponseBodyDTORuntype,
} from './onlineComponentsBackendTypes';

export const onlineComponentsEndpoints = {
    'GET /3rdparty/onlinecomponents/credentials': endpoint({
        description: 'Returns credentials for Online Components integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: OnlineComponentsResponseBodyDTORuntype,
    }),
    'POST /3rdparty/onlinecomponents/credentials': endpoint({
        description: 'Uploads credentials for Online Components API integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: OnlineComponentsCredentialsInputDTORuntype,
        responseBody: OnlineComponentsResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/onlinecomponents/credentials'],
    }),
    'PATCH /3rdparty/onlinecomponents/credentials': endpoint({
        description: 'Updates credentials for Online Components API integratoin',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: OnlineComponentsCredentialsInputDTORuntype,
        responseBody: OnlineComponentsResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/onlinecomponents/credentials'],
    }),
    'DELETE /3rdparty/onlinecomponents/credentials': endpoint({
        description: 'Deletes existing credentials for Online Components API integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({ deleted: z.number() }),
        invalidates: ['GET /3rdparty/onlinecomponents/credentials'],
    }),
};
