import { useNegotiation, useQuoteRequest } from '@/modules/Negotiations/hooks/negotiationHandlers';
import { useHttpMutation } from '@/resources/mutation/useHttpMutation';
import { t } from '@lingui/macro';
import { assertUnreachable, isPresent } from '@luminovo/commons';
import { Flexbox, SpinningIcon, TertiaryIconButton, Tooltip } from '@luminovo/design-system';
import {
    CustomComponentOfferDTO,
    CustomOptionOfferDTO,
    CustomPartOfferDTO,
    InternalPartNumberOfferDTO,
    NegotiationCategory,
    OfferOriginEnum,
    OffTheShelfOfferDTO,
    RfqContext,
    StandardPartOfferDTO,
} from '@luminovo/http-client';
import {
    HeaderItemCancellationWindow,
    HeaderItemCustomPartDescription,
    HeaderItemImportContext,
    HeaderItemIpn,
    HeaderItemItemclass,
    HeaderItemManufacturer,
    HeaderItemMpn,
    HeaderItemNcnr,
    HeaderItemNotes,
    HeaderItemOfferFiles,
    HeaderItemOfferNumber,
    HeaderItemPackage,
    HeaderItemPanelSpecification,
    HeaderItemPcbFiles,
    HeaderItemQuoteRequest,
    HeaderItemSku,
    HeaderItemSourcingBatchSize,
    HeaderItemTotalOfferedQuantity,
    HeaderItemUnitOfMeasurement,
    HeaderItemValidForCustomer,
    HeaderItemValidForRfq,
    HeaderItemValidFrom,
    HeaderItemValidUntil,
    isCustomComponentOffer,
    isCustomPartOffer,
    isInternalPartNumberOffer,
    isOffTheShelfPartOffer,
    Solution,
} from '@luminovo/sourcing-core';
import { Sync } from '@mui/icons-material';
import React from 'react';
import { useCustomer } from '../../../../../resources/customer/customerHandler';
import { useDownloadPcbOrderFiles } from '../../../../../resources/export/exportHandler';
import { useHttpQuery } from '../../../../../resources/http/useHttpQuery';
import { useCustomComponent, useCustomPart, useOtsFullPart } from '../../../../../resources/part/partHandler';
import { usePcb } from '../../../../../resources/pcb/pcbHandlers';
import { useRfQ } from '../../../../../resources/rfq/rfqHandler';
import { route } from '../../../../../utils/routes';
import { getPanelDimension } from '../../../../Pcb/PanelizationTab/utils/getPanelDimension';

function useOffTheSelfPartOfferFiles(offerId: string) {
    return useHttpQuery(
        'GET /offers/off-the-shelf/:id/additional-files',
        { pathParams: { id: offerId } },
        { select: (data) => data.items },
    );
}

function useCustomPartOfferFiles(offerId: string) {
    return useHttpQuery(
        'GET /offers/custom-part/:id/additional-files',
        { pathParams: { id: offerId } },
        { select: (data) => data.items },
    );
}

function useQuoteRequestByOffer(offer: StandardPartOfferDTO) {
    const quoteRequestId =
        offer.origin.origin === OfferOriginEnum.QuoteRequest ? offer.origin.quote_request_id : undefined;
    const negotiationId =
        offer.origin.origin === OfferOriginEnum.QuoteRequest ? offer.origin.negotiation_id : undefined;
    const { data: quoteRequest } = useQuoteRequest(quoteRequestId ?? undefined);
    const { data: negotiation } = useNegotiation(negotiationId ?? undefined);
    const getHref = () => {
        if (!isPresent(negotiationId) || !isPresent(quoteRequestId)) {
            return undefined;
        }
        if (negotiation?.category.kind === NegotiationCategory.Project) {
            return route('/rfqs/:rfqId/sourcing/negotiations/:negotiationId/quote-requests/:quoteRequestId', {
                rfqId: negotiation.category.rfq_id,
                negotiationId,
                quoteRequestId,
            });
        }
        if (negotiation?.category.kind === NegotiationCategory.Strategic) {
            return route('/negotiations/:negotiationId/quote-requests/:quoteRequestId', {
                negotiationId,
                quoteRequestId,
            });
        }
        return undefined;
    };
    return { quoteRequest, href: getHref() };
}

export const StandardPartInformation: React.FunctionComponent<{
    offer: StandardPartOfferDTO;
    rfqContext: RfqContext;
    solution?: Solution;
}> = ({ offer, solution, rfqContext }): JSX.Element => {
    if (isInternalPartNumberOffer(offer)) {
        return <InternalPartNumberInformation offer={offer} solution={solution} />;
    }

    if (isOffTheShelfPartOffer(offer)) {
        return <OffTheShelfInformation offer={offer} solution={solution} rfqContext={rfqContext} />;
    }

    assertUnreachable(offer);
};

const InternalPartNumberInformation: React.FunctionComponent<{
    offer: InternalPartNumberOfferDTO;
    solution?: Solution;
}> = ({ offer, solution }): JSX.Element => {
    const { data: rfqDTO } = useRfQ(offer.rfq ?? '', isPresent(offer.rfq));
    const { data: customerDTO } = useCustomer(offer.customer ?? undefined);
    const { quoteRequest, href } = useQuoteRequestByOffer(offer);

    return (
        <Flexbox flexDirection={'column'} gap={12}>
            <HeaderItemIpn
                id={offer.linked_part.id}
                href={route('/parts/components/ipn', {}, { ipnId: offer.linked_part.id, q: undefined })}
            />
            <HeaderItemOfferNumber offer={offer} />
            <HeaderItemSku offer={offer} />
            <HeaderItemPackage offer={offer} />
            <HeaderItemQuoteRequest quoteRequest={quoteRequest} href={href} />
            <HeaderItemValidForRfq
                solution={solution}
                validFor={rfqDTO}
                href={rfqDTO && route('/rfqs/:rfqId/dashboard', { rfqId: rfqDTO.id })}
            />
            <HeaderItemValidForCustomer
                solution={solution}
                validFor={customerDTO}
                href={customerDTO && route('/customers/:customerId', { customerId: customerDTO.id })}
            />
            <HeaderItemValidFrom offer={offer} />
            <HeaderItemValidUntil offer={offer} />
            <HeaderItemItemclass offer={offer} />
            <HeaderItemCancellationWindow offer={offer} />
            <HeaderItemUnitOfMeasurement offer={offer} />
            <HeaderItemNotes offer={offer} />
            <HeaderItemImportContext offer={offer} />
        </Flexbox>
    );
};

const OffTheShelfInformation: React.FunctionComponent<{
    offer: OffTheShelfOfferDTO;
    solution?: Solution;
    rfqContext: RfqContext;
}> = ({ offer, solution, rfqContext }): JSX.Element => {
    const { data: rfqDTO } = useRfQ(offer.rfq ?? '', isPresent(offer.rfq));
    const { data: customerDTO } = useCustomer(offer.customer ?? undefined);
    const { data: part } = useOtsFullPart({ partOptionId: offer.linked_part.id, rfqContext });
    const { data: files } = useOffTheSelfPartOfferFiles(offer.id);
    const { quoteRequest, href } = useQuoteRequestByOffer(offer);

    if (!isPresent(part)) {
        return <></>;
    }

    return (
        <Flexbox flexDirection={'column'} gap={12}>
            <HeaderItemMpn otsPart={part} href={route('/parts/details/:partId', { partId: part.id })} />
            <HeaderItemManufacturer otsPart={part} />
            <HeaderItemOfferNumber offer={offer} />
            <HeaderItemSku offer={offer} />
            <HeaderItemPackage offer={offer} />
            <HeaderItemQuoteRequest quoteRequest={quoteRequest} href={href} />
            <HeaderItemValidForRfq
                solution={solution}
                validFor={rfqDTO}
                href={rfqDTO && route('/rfqs/:rfqId/dashboard', { rfqId: rfqDTO.id })}
            />
            <HeaderItemValidForCustomer
                solution={solution}
                validFor={customerDTO}
                href={customerDTO && route('/customers/:customerId', { customerId: customerDTO.id })}
            />
            <HeaderItemValidFrom offer={offer} />
            <HeaderItemValidUntil offer={offer} />
            <HeaderItemItemclass offer={offer} />
            <HeaderItemCancellationWindow offer={offer} />
            <HeaderItemNcnr offer={offer} />
            <HeaderItemUnitOfMeasurement offer={offer} />
            <HeaderItemNotes offer={offer} />
            <HeaderItemImportContext offer={offer} />
            <HeaderItemOfferFiles files={files} />
        </Flexbox>
    );
};

export const LinkedCustomPartInformation: React.FunctionComponent<{
    offer: CustomOptionOfferDTO;
    sourcingScenarioId: string;
    rfqContext: RfqContext;
    isPcbCustomPartOffer?: boolean;
}> = ({ offer, sourcingScenarioId, rfqContext, isPcbCustomPartOffer }): JSX.Element => {
    if (isCustomPartOffer(offer)) {
        return (
            <CustomPartInformation
                offer={offer}
                sourcingScenarioId={sourcingScenarioId}
                isPcbCustomPartOffer={isPcbCustomPartOffer}
            />
        );
    }

    if (isCustomComponentOffer(offer)) {
        return <CustomComponentInformation offer={offer} rfqContext={rfqContext} />;
    }

    assertUnreachable(offer);
};

export const CustomPartInformation: React.FunctionComponent<{
    offer: CustomPartOfferDTO;
    sourcingScenarioId: string;
    isPcbCustomPartOffer?: boolean;
}> = ({ offer, sourcingScenarioId, isPcbCustomPartOffer = false }): JSX.Element => {
    const { mutateAsync, isPending: isLoading } = useDownloadPcbOrderFiles(offer.id, sourcingScenarioId);
    const { data: part } = useCustomPart(offer.linked_part.id);
    const { data: files } = useCustomPartOfferFiles(offer.id);

    if (!isPresent(part)) {
        return <></>;
    }

    return (
        <Flexbox flexDirection={'column'} gap={12}>
            <HeaderItemCustomPartDescription description={part.description ?? ''} />
            <HeaderItemSourcingBatchSize offer={offer} />
            <HeaderItemTotalOfferedQuantity offer={offer} />
            <HeaderItemOfferNumber offer={offer} />
            <HeaderItemPcbFiles offer={offer} part={part} onClick={() => mutateAsync()} isLoading={isLoading} />
            <HeaderItemValidUntil offer={offer} />
            <HeaderItemUnitOfMeasurement offer={offer} isPcbCustomPartOffer={isPcbCustomPartOffer} />
            <HeaderItemPanelSpecificationWrapper offerId={offer.id} />
            <HeaderItemOfferFiles files={files} />
            <HeaderItemImportContext offer={offer} />
        </Flexbox>
    );
};

export const CustomComponentInformation: React.FunctionComponent<{
    offer: CustomComponentOfferDTO;
    rfqContext: RfqContext;
}> = ({ offer, rfqContext }): JSX.Element => {
    const { data: customComponent } = useCustomComponent(offer.linked_part.id, rfqContext);

    if (!isPresent(customComponent)) {
        return <></>;
    }

    return (
        <Flexbox flexDirection={'column'} gap={12}>
            <HeaderItemIpn
                id={offer.linked_part.id}
                href={route('/parts/components/ipn', {}, { ipnId: offer.linked_part.id, q: undefined })}
            />
            <HeaderItemCustomPartDescription description={customComponent.description || ''} />
            <HeaderItemOfferNumber offer={offer} />
            <HeaderItemValidUntil offer={offer} />
            <HeaderItemUnitOfMeasurement offer={offer} />
            <HeaderItemNotes offer={offer} />
        </Flexbox>
    );
};

const HeaderItemPanelSpecificationWrapper = ({ offerId }: { offerId: string }) => {
    const { data: offer } = useHttpQuery(
        'GET /offers/custom-part/:id',
        {
            pathParams: { id: offerId },
        },
        {
            // @ts-ignore
            select: (data) => data.data as CustomPartOfferDTO,
        },
    );

    const { data: pcb } = usePcb(offer?.specification?.data.pcb);
    const panel = offer?.specification?.data.panel;
    const panelDimensions = pcb && panel ? getPanelDimension(panel, pcb) : undefined;

    if (!offer) {
        return <></>;
    }

    if (!panel) {
        return <HeaderItemNotes offer={offer} />;
    }

    return (
        <HeaderItemPanelSpecification panel={panel} panelDimensions={panelDimensions}>
            <UpdatePanelSpecificationButton offer={offer} />
        </HeaderItemPanelSpecification>
    );
};

const UpdatePanelSpecificationButton = ({ offer }: { offer: CustomPartOfferDTO }) => {
    const { mutateAsync, isPending: isLoading } = useHttpMutation(
        'PATCH /offers/custom-part/:id/copy-panel-from-design',
        {
            snackbarMessage: t`Panel specification updated`,
        },
    );

    const handleClick = () => {
        // Making a offer update request with missing specification field to update the panel specification
        mutateAsync({
            pathParams: { id: offer.id },
        });
    };

    return (
        <Tooltip title={t`Sync from design`}>
            <TertiaryIconButton
                size="small"
                onClick={(event) => {
                    event.stopPropagation();
                    handleClick();
                }}
                disabled={isLoading}
                style={{ padding: 0, width: 'unset', height: 'unset' }}
            >
                {isLoading ? <SpinningIcon fontSize="inherit" /> : <Sync fontSize="inherit" />}
            </TertiaryIconButton>
        </Tooltip>
    );
};
