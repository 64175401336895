import { assertUnreachable, transEnum } from '@luminovo/commons';
import { StatusChip } from '@luminovo/design-system';
import { SupplierPreference } from '@luminovo/http-client';
import React from 'react';
import { supplierPreferenceTranslations } from '../../i18n';

export const SupplierPreferenceChip: React.FunctionComponent<{
    status: SupplierPreference;
}> = ({ status }) => {
    const label = transEnum(status, supplierPreferenceTranslations);
    switch (status) {
        case SupplierPreference.Preferred:
            return <StatusChip color="primary" label={label} />;
        case SupplierPreference.Approved:
            return <StatusChip color="green" label={label} />;
        case SupplierPreference.NotApproved:
            return <StatusChip color="neutral" label={label} />;
        case SupplierPreference.Excluded:
            return <StatusChip color="red" label={label} />;
        default:
            assertUnreachable(status);
    }
};
