import * as z from 'zod';
import { MonetaryValueBackendRuntype } from '../backendTypes';
import { FixedCostCellRuntype } from '../calculation';
import { CountryAlpha2CodeRuntype } from '../organizationSettings';

const OrderSelectionOptionIdRuntype = z.string();

const TaxTypeRuntype = z.union([
    z.literal('AmusementTax'),
    z.literal('CommunicationsTax'),
    z.literal('GoodsAndServicesTax'),
    z.literal('HarmonizedSalesTax'),
    z.literal('IntegratedGoodsAndServicesTax'),
    z.literal('JapaneseConsumptionTax'),
    z.literal('ChicagoLeaseTax'),
    z.literal('ProvincialSalesTax'),
    z.literal('QuebecSalesTax'),
    z.literal('RetailSalesTax'),
    z.literal('SalesTax'),
    z.literal('ValueAddedTax'),
]);

export type TaxType = z.infer<typeof TaxTypeRuntype>;

const TaxBreakdownRuntype = z.object({
    amount: MonetaryValueBackendRuntype,
    country_code: CountryAlpha2CodeRuntype.nullable(),
    percentage: z.string(),
    state: z.string().nullable(),
    tax_type: TaxTypeRuntype.nullable(),
});

export type TaxBreakdownDTO = z.infer<typeof TaxBreakdownRuntype>;

const PriceIncludingTaxRuntype = z.object({
    type: z.literal('IncludingTax'),
    data: z.object({
        total_price_before_tax: FixedCostCellRuntype,
        total_price_after_tax: MonetaryValueBackendRuntype,
        tax_breakdowns: z.array(TaxBreakdownRuntype),
    }),
});

const PriceExcludingTaxRuntype = z.object({
    type: z.literal('ExcludingTax'),
    data: z.object({
        total_price_before_tax: FixedCostCellRuntype,
    }),
});

const OrderSelectionPriceRuntype = PriceExcludingTaxRuntype.or(PriceIncludingTaxRuntype);
export type OrderSelectionPriceDTO = z.infer<typeof OrderSelectionPriceRuntype>;

const OrderSelectionOptionRuntype = z.object({
    order_size: z.number(),
    batch_size: z.number(),
    lead_time_in_days: z.number().nullable(),
    price: OrderSelectionPriceRuntype,
});

export type OrderSelectionOptionDTO = z.infer<typeof OrderSelectionOptionRuntype>;

export const ManyOrderSelectionOptionsRuntype = z.object({
    data: z.record(OrderSelectionOptionIdRuntype, OrderSelectionOptionRuntype),
    valid_until: z.string(),
});

export const PaymentCollectionModeRuntype = z.enum(['ChargeImmediately', 'SendInvoice']);
export type PaymentCollectionModeDTO = z.infer<typeof PaymentCollectionModeRuntype>;
export const PaymentProcessorsRuntype = z.literal('Stripe');

const SalesOrderLineItemResponseRuntype = z.object({
    id: z.string(),
    sales_order_id: z.string(),
    assembly_id: z.string(),
    quantity: z.number(),
    total_price: MonetaryValueBackendRuntype,
});

export const SalesOrderResponseRuntype = z.object({
    id: z.string(),
    rfq_id: z.string(),
    ordered_by_user_id: z.string(),
    total_price: MonetaryValueBackendRuntype,
    estimated_lead_time_in_days: z.number().nullable(),
    shipping_address: z.string().nullable(),
    payment_processor: PaymentProcessorsRuntype.nullable(),
    payment_id: z.string().nullable(),
    batch_size: z.number(),
    order_size: z.number(),
    sales_order_line_items: z.array(SalesOrderLineItemResponseRuntype),
});

export const OrderOverviewRuntype = z.object({
    id: z.string(),
    customer_id: z.string(),
    customer_name: z.string(),
    customer_number: z.string().nullable(),
    rfq_number: z.string().nullable(),
    estimated_lead_time_in_days: z.number().nullable(),
    order_size: z.number(),
    total_price: MonetaryValueBackendRuntype,
});

export const InitializePaymentInputRuntype = z.object({
    rfq_id: z.string(),
    order_selection_option_id: OrderSelectionOptionIdRuntype,
    payment_collection_mode: PaymentCollectionModeRuntype.nullable(),
});

export type InitializePaymentInputDTO = z.infer<typeof InitializePaymentInputRuntype>;

export const CreateOrderInputRuntype = z.object({
    rfq_id: z.string(),
    order_selection_option_id: OrderSelectionOptionIdRuntype,
    payment_processor: PaymentProcessorsRuntype.nullable(),
    payment_id: z.string().nullable(),
    payment_collection_mode: PaymentCollectionModeRuntype.nullable(),
});

export type CreateOrderInputDTO = z.infer<typeof CreateOrderInputRuntype>;

export type ChargeImmediatelyResponseDTO = z.infer<typeof ChargeImmediatelyResponseRuntype>;

const ChargeImmediatelyResponseRuntype = z.object({
    order_selection_option_id: OrderSelectionOptionIdRuntype,
    tax: MonetaryValueBackendRuntype.nullable(),
    payment_processor: PaymentProcessorsRuntype,
    payment_id: z.string(),
    public_key: z.string(),
    client_secret: z.string(),
});

export const OrderDetailsChargeImmediatelyResponseRuntype = z.object({
    type: z.literal('ChargeImmediately'),
    data: ChargeImmediatelyResponseRuntype,
});

export const OrderDetailsSendInvoiceResponseRuntype = z.object({
    type: z.literal('SendInvoice'),
    data: z.object({
        data: SalesOrderResponseRuntype,
    }),
});

export const OrderDetailsResponseRuntype = OrderDetailsChargeImmediatelyResponseRuntype.or(
    OrderDetailsSendInvoiceResponseRuntype,
);

export const PaymentCredentialsRuntype = z.object({
    payment_processor: PaymentProcessorsRuntype,
    public_key: z.string(),
    private_key_hint: z.string(),
});

export const PatchPaymentCredentialsRuntype = z.object({
    payment_processor: PaymentProcessorsRuntype,
    public_key: z.string(),
    private_key: z.string(),
});
