import { isAuthorized, usePermissions } from '@luminovo/auth';
import * as Sentry from '@sentry/react';
import React from 'react';
import { useCurrentUserDetailsContext } from './components/contexts/CurrentUserDetailsContext';
import { ErrorFallback } from './components/errorHandlers/ErrorBoundary';
import PagesRouter from './components/routers/PagesRouter';
import { MANUFACTURING_PAID_PERMISSIONS } from './featureFlags';
import { BomImporterProvider } from './modules/BomImporter/BomImporterContext';
import { initializeAllAnalytics, useOnCookieConset } from './utils/analytics';

function AuthenticatedApp() {
    const { user, organization, customer } = useCurrentUserDetailsContext();
    const consentedCookies = useOnCookieConset();
    const { permissions } = usePermissions();

    React.useEffect(() => {
        initializeAllAnalytics({
            user,
            organization,
            customer,
            consentedCookies,

            hasFullManufacturingAccess: isAuthorized(permissions, MANUFACTURING_PAID_PERMISSIONS),
        });
        // re-run this effect only when the consented cookies change
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [consentedCookies]);

    return (
        <Sentry.ErrorBoundary fallback={ErrorFallback}>
            <BomImporterProvider>
                <PagesRouter />
            </BomImporterProvider>
        </Sentry.ErrorBoundary>
    );
}

export default Sentry.withProfiler(AuthenticatedApp);
