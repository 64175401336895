/* eslint-disable spellcheck/spell-checker */
import { Trans } from '@lingui/macro';
import { Flexbox, Text, Tooltip, TooltipProps } from '@luminovo/design-system';
import { LifecycleEnum, OTSPartOriginEnum } from '@luminovo/http-client';
import { Skeleton } from '@mui/material';
import { usePartVotes } from '../../resources/part/usePartVotes';
import { LifecycleChipWithTooltip } from './LifecycleChipWithTooltip';

export const OtsPartLifecycleStatusOriginsTooltip: React.FunctionComponent<{
    partId: string;
    lifecycleStatus: LifecycleEnum;
    children: TooltipProps['children'];
}> = ({ partId, lifecycleStatus, children }): JSX.Element => {
    const { votes, isLoading, triggerFetchVotes } = usePartVotes({
        partId,
        shouldFetchVotes: lifecycleStatus !== LifecycleEnum.Unknown,
    });

    if (isLoading) {
        return (
            <Tooltip
                arrow
                title={
                    <Flexbox flexDirection="column" gap="16px" width="200px" padding="4px">
                        <Text variant="h5">
                            <Trans>Lifecycle origins</Trans>
                        </Text>
                        <Skeleton width="75%" />
                    </Flexbox>
                }
                variant="white"
            >
                <span>{children}</span>
            </Tooltip>
        );
    }

    const showTooltip = votes && votes.some((vote) => vote.lifecycle && vote.lifecycle !== LifecycleEnum.Unknown);

    return (
        <Tooltip
            arrow
            onOpen={triggerFetchVotes}
            title={
                showTooltip ? (
                    <Flexbox
                        flexDirection="column"
                        gap="16px"
                        width="220px"
                        maxHeight="308px"
                        overflow="auto"
                        padding="4px"
                    >
                        <Text variant="h5">
                            <Trans>Lifecycle origins</Trans>
                        </Text>
                        <Flexbox flexDirection="column" gap="8px">
                            {votes.map((vote, i) => {
                                if (!vote.lifecycle || vote.lifecycle === LifecycleEnum.Unknown) {
                                    return null;
                                }

                                const label = otsPartOriginKeyWithLabelValue[vote.origin.type]
                                    ? otsPartOriginKeyWithLabelValue[vote.origin.type].label
                                    : vote.origin.type;

                                return (
                                    <Flexbox key={i} alignItems="center" justifyContent="space-between" gap="4px">
                                        <LifecycleChipWithTooltip status={vote.lifecycle} disabledTooltip={true} />
                                        <Text variant="body-small" showEllipsis>
                                            {label}
                                        </Text>
                                    </Flexbox>
                                );
                            })}
                        </Flexbox>
                    </Flexbox>
                ) : (
                    ''
                )
            }
            variant="white"
        >
            <span>{children}</span>
        </Tooltip>
    );
};

export const otsPartOriginKeyWithLabelValue: Record<OTSPartOriginEnum, { label: string }> = {
    [OTSPartOriginEnum.Manual]: { label: 'Manual' },
    [OTSPartOriginEnum.Import]: { label: 'Import' },
    [OTSPartOriginEnum.Aggregate]: { label: 'Aggregate' },
    [OTSPartOriginEnum.DescriptionExtraction]: { label: 'DescriptionExtraction' },
    // ----
    [OTSPartOriginEnum.ArrowAPI]: { label: 'Arrow' },
    [OTSPartOriginEnum.AvnetAPI]: { label: 'Avnet' },
    [OTSPartOriginEnum.AvnetUsaAPI]: { label: 'Avnet USA' },
    [OTSPartOriginEnum.BuerklinAPI]: { label: 'Bürklin' },
    [OTSPartOriginEnum.ChipAssistAPI]: { label: 'Chip Assist' },
    [OTSPartOriginEnum.DigikeyAPI]: { label: 'DigiKey' },
    [OTSPartOriginEnum.Element14API]: { label: 'element14' },
    [OTSPartOriginEnum.EveAPI]: { label: 'EVE' },
    [OTSPartOriginEnum.FarnellAPI]: { label: 'Farnell' },
    [OTSPartOriginEnum.FutureAPI]: { label: 'Future Electronics' },
    [OTSPartOriginEnum.GudecoAPI]: { label: 'Gudeco' },
    [OTSPartOriginEnum.HeilindAPI]: { label: 'Heilind' },
    [OTSPartOriginEnum.IHSImport]: { label: 'Accuris (IHS)' },
    [OTSPartOriginEnum.MasterAPI]: { label: 'Master Electronics' },
    [OTSPartOriginEnum.MouserAPI]: { label: 'Mouser' },
    [OTSPartOriginEnum.MyArrowAPI]: { label: 'MyArrow' },
    [OTSPartOriginEnum.LcscAPI]: { label: 'LCSC' },
    [OTSPartOriginEnum.NewarkAPI]: { label: 'Newark' },
    [OTSPartOriginEnum.OctopartAPI]: { label: 'Octopart' },
    [OTSPartOriginEnum.OnlineComponentsAPI]: { label: 'Online Components' },
    [OTSPartOriginEnum.RsComponentsAPI]: { label: 'RS Components' },
    [OTSPartOriginEnum.RochesterAPI]: { label: 'Rochester Electronics' },
    [OTSPartOriginEnum.QuestComponentsAPI]: { label: 'Quest Components' },
    [OTSPartOriginEnum.RutronikAPI]: { label: 'Rutronik' },
    [OTSPartOriginEnum.SchukatAPI]: { label: 'Schukat' },
    [OTSPartOriginEnum.SluiceboxAPI]: { label: 'Sluicebox' },
    [OTSPartOriginEnum.SosAPI]: { label: 'SOS Electronic' },
    [OTSPartOriginEnum.SourcengineAPI]: { label: 'Sourcengine' },
    [OTSPartOriginEnum.TiAPI]: { label: 'Texas Instruments' },
    [OTSPartOriginEnum.TtiAPI]: { label: 'TTI' },
    [OTSPartOriginEnum.TmeAPI]: { label: 'TME' },
    [OTSPartOriginEnum.TrustedPartsAPI]: { label: 'TrustedParts' },
    [OTSPartOriginEnum.VenkelAPI]: { label: 'Venkel' },
    [OTSPartOriginEnum.WeltronAPI]: { label: 'Weltron' },
    [OTSPartOriginEnum.WuerthEisosAPI]: { label: 'Würth Elektronik eiSos' },
    [OTSPartOriginEnum.CorestaffAPI]: { label: 'Corestaff' },
    [OTSPartOriginEnum.BlumeAPI]: { label: 'Blume' },
    [OTSPartOriginEnum.SamtecAPI]: { label: 'Samtec' },
    [OTSPartOriginEnum.AlElektronikAPI]: { label: 'Al Elektronik' },
    [OTSPartOriginEnum.AvnetApacAPI]: { label: 'Avnet Asia Pacific' },
    [OTSPartOriginEnum.ChipCartAPI]: { label: 'ChipCart' },
    [OTSPartOriginEnum.WinSourceAPI]: { label: 'WinSource' },
};
