import * as z from 'zod';
import { DriverStatusRuntype } from '../activityConfiguration';
import { AssemblyTypeRuntype } from '../assembly';
import { MonetaryValueBackendRuntype } from '../backendTypes';
import { DriverRuntype } from '../driver';
import { SourcingScenarioDTORuntype } from '../sourcingScenario';

export const ManufacturingAssemblyDetailsWithoutNotesAndIdRuntype = z.object({
    assembly_id: z.string(),
});

export const ManufacturingAssemblyDetailsNotesRuntype = z.object({
    notes: z.string().nullable(),
});

export const ManufacturingAssemblyDetailsRuntype = ManufacturingAssemblyDetailsWithoutNotesAndIdRuntype.extend({
    notes: z.string().nullable(),
    id: z.string(),
});

const ConfigurationCountRuntype = z.object({
    scenario_name: z.string(),
    entity_name: z.string(), // can be the activity or expense name
    driver_count: z.string(),
});

export interface ConfigurationCount extends z.infer<typeof ConfigurationCountRuntype> {}

const StatusRuntype = z.union([
    z.literal('Ok'),
    z.literal('Warning'),
    z.literal('Error'),
    // Status only used on the frontend to indicate activity with fixed time set to 0
    z.literal('ZeroFixedTime'),
]);
export type StatusDTO = z.infer<typeof StatusRuntype>;

const DriverCountRuntype = z.object({
    assembly_wide_count: z.string().nullable(),
    automatic_count: z.string().nullable(),
    is_linked_to_bom: z.boolean(),
    status: DriverStatusRuntype,
    assembly_to_configuration_driver_count_mismatch: z.array(ConfigurationCountRuntype),
});
export type DriverCountDTO = z.infer<typeof DriverCountRuntype>;

const BasicManufacturingAssemblyDriverCountRuntype = z.object({
    type: z.literal('Basic'),
    data: DriverCountRuntype,
});
export type BasicManufacturingAssemblyDriverCountDTO = z.infer<typeof BasicManufacturingAssemblyDriverCountRuntype>;

const SourcingScenarioResponseRuntype = z.record(z.string(), DriverCountRuntype);

const PerScenarioManufacturingAssemblyDriverCountRuntype = z.object({
    type: z.literal('PerScenario'),
    data: z.object({
        sourcing_scenarios_response: SourcingScenarioResponseRuntype,
        assembly_wide_count: z.string().nullable(),
        extra_statuses: z.array(DriverStatusRuntype),
    }),
});
export type PerScenarioManufacturingAssemblyDriverCountDTO = z.infer<
    typeof PerScenarioManufacturingAssemblyDriverCountRuntype
>;

const ManufacturingAssemblyDriverCountRuntype = BasicManufacturingAssemblyDriverCountRuntype.or(
    PerScenarioManufacturingAssemblyDriverCountRuntype,
);
export type ManufacturingAssemblyDriverCountDTO = z.infer<typeof ManufacturingAssemblyDriverCountRuntype>;

export const DriversOfManufacturingDetailsRuntype = z.object({
    driver: DriverRuntype,
    driver_count: ManufacturingAssemblyDriverCountRuntype,
    is_sourcing_driver: z.boolean(),
});

const CostOverviewRuntype = z.object({
    effective_cost: MonetaryValueBackendRuntype,
    project_cost: MonetaryValueBackendRuntype,
});

const ManufacturingScenarioOverviewRuntype = z.object({
    id: z.string(),
    name: z.string(),
    notes: z.string().nullable(),
    sites: z.array(z.string()),
    sourcing_scenario: SourcingScenarioDTORuntype.nullable(),
    batch_sizes: z.array(z.number()),
    activity_count: z.number(),
    expense_count: z.number(),
    costs_overview: z.record(z.string(), CostOverviewRuntype),
    driver_status_overview: z.object({
        ok_count: z.number(),
        warning_count: z.number(),
        error_count: z.number(),
    }),
});

export type ManufacturingScenarioOverviewDTO = z.infer<typeof ManufacturingScenarioOverviewRuntype>;

const AssemblyOverviewRuntype = z.object({
    id: z.string(),
    designator: z.string(),
    assembly_type: AssemblyTypeRuntype,
});

export const ManufacturingAssemblyDetailsOverviewRuntype = z.object({
    manufacturing_assembly_details: ManufacturingAssemblyDetailsRuntype,
    assembly: AssemblyOverviewRuntype,
    manufacturing_scenarios: z.array(ManufacturingScenarioOverviewRuntype),
    drivers: z.array(DriversOfManufacturingDetailsRuntype),
});

export type ManufacturingAssemblyDetailsOverviewDTO = z.infer<typeof ManufacturingAssemblyDetailsOverviewRuntype>;

export type DriversOfManufacturingDetailsDTO = z.infer<typeof DriversOfManufacturingDetailsRuntype>;

export interface ManufacturingAssemblyDetailsDTO extends z.infer<typeof ManufacturingAssemblyDetailsRuntype> {}
