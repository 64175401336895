import { formatError } from '@/modules/Error/formatError';
import { t } from '@lingui/macro';
import { getToken } from '@luminovo/auth';
import {
    AmlLineBuildingInput,
    BomFileDTO,
    BomImporterBackendInput,
    BomLineBuildingInput,
    BomLineBuildingOutput,
    ColumnMap,
    HeaderMetadata,
    http,
    PreviousLinesSearchColumns,
    PreviousLinesSearchVariant,
    SingleOriginalExcelRow,
    TaskAcceptedResponse,
} from '@luminovo/http-client';
import { useMutation, useQuery } from '@tanstack/react-query';
import { analytics } from '../../utils/analytics';
import { useHttpFileUpload } from '../http/useHttpFileUpload';

export const useUploadFileToBomImporter = () => {
    const { mutateAsync: uploadFailedBomImportToContainerMutateAsync } = useUploadFailedBomImportToContainer();
    return useMutation({
        mutationFn: ({
            file,
            assemblyId,
            openBOMErrorDialogWithMessage,
        }: {
            file: File;
            assemblyId: string;
            openBOMErrorDialogWithMessage: ({ message, assemblyId }: { message: string; assemblyId: string }) => void;
        }): Promise<TaskAcceptedResponse> => {
            const formData = new FormData();
            const reader = new FileReader();
            reader.readAsDataURL(file);
            formData.append('file', file);

            return http(
                'POST /bom-importer/screener',
                {
                    requestBody: formData,
                },
                getToken(),
            );
        },
        onError: async (error, variables) => {
            const { file, assemblyId, openBOMErrorDialogWithMessage } = variables;

            await uploadFailedBomImportToContainerMutateAsync({ files: file });

            analytics.track('could_not_upload_bom', {
                assembly_uuid: assemblyId,
                error_type: 'failed to upload file to bom importer',
            });

            let errorMessage = formatError(error);
            const isRunningOnWindows = navigator.userAgent.indexOf('Windows') > -1;
            if (isRunningOnWindows) {
                errorMessage = t`Failed to upload BOM. Please check if the file is opened by another program`;
            }
            return openBOMErrorDialogWithMessage({ message: errorMessage, assemblyId });
        },
    });
};

export const useUploadBomToAzure = ({ assemblyId }: { assemblyId: string }) => {
    return useHttpFileUpload('GET /bom-files/:bomFileId/upload', (res) => res.data.url, {
        snackbarMessage: null,
        onError: () => {
            analytics.track('could_not_upload_bom', {
                assembly_uuid: assemblyId,
                error_type: 'failed Azure upload',
            });
        },
    });
};

type PostToLineBuilderParams = {
    excelLines: SingleOriginalExcelRow[];
    columnMap: ColumnMap;
    fileName: string;
    sheetName: string;
    headerMetaData: HeaderMetadata | null;
    rfqId: string;
    token: string;
    importingAssembly: string;
    previousLinesSearchColumns: PreviousLinesSearchColumns;
    errorAndResetStateCallback: (message: string) => void;
    amlInput: AmlLineBuildingInput | null;
};

export const usePostToLineBuilder = () => {
    return useMutation({
        mutationFn: ({
            excelLines,
            columnMap,
            fileName,
            sheetName,
            headerMetaData,
            rfqId,
            importingAssembly,
            previousLinesSearchColumns,
            errorAndResetStateCallback,
            amlInput,
        }: PostToLineBuilderParams) => {
            const bomLineBuildingInput: BomLineBuildingInput = {
                relevant_excel_lines: excelLines,
                column_map: columnMap,
                file_name: fileName,
                sheet_name: sheetName,
                bom_header_metadata: headerMetaData,
                rfq_id: rfqId,
                importing_assembly: importingAssembly,
                previous_lines_search_columns: previousLinesSearchColumns,
                aml_input: amlInput,
            };
            return http(
                'POST /bom-importer/line-builder',
                {
                    requestBody: bomLineBuildingInput,
                },
                getToken(),
            );
        },
        onError: (_, variables) => {
            variables.errorAndResetStateCallback(t`Something went wrong during BOM import.`);
        },
    });
};

type BOMImporterLinesRequestData = {
    bomImporterLineBuildingOutput: BomLineBuildingOutput | null;
    headerMetadata: HeaderMetadata | null;
    importingAssemblyId: string;
    columnMap: ColumnMap;
};

export const postImportBom = async (
    bomLinesRawRequestData: BOMImporterLinesRequestData,
    acceptPreviousBomLines: boolean,
    previous_lines_search_variant: PreviousLinesSearchVariant,
) => {
    const { bomImporterLineBuildingOutput, headerMetadata, importingAssemblyId, columnMap } = bomLinesRawRequestData;

    if (bomImporterLineBuildingOutput !== null) {
        const bomImportedAssemblies = bomImporterLineBuildingOutput.assemblies;

        const linesPerAssembly = bomImporterLineBuildingOutput.lines_in_assembly;

        const bomImportInput: BomImporterBackendInput = {
            header_metadata: headerMetadata
                ? {
                      raw_header_line: headerMetadata.raw_header_row,
                      header_line_number: headerMetadata.header_row_number,
                  }
                : null,
            column_map: columnMap,
            importing_assembly: importingAssemblyId,
            accept_previous_bom_lines: acceptPreviousBomLines,
            assemblies: bomImportedAssemblies,
            lines_per_assembly: linesPerAssembly,
            previous_lines_search_variant: previous_lines_search_variant,
        };

        return http(
            'POST /design-items/bom-importer',
            {
                requestBody: bomImportInput,
            },
            getToken(),
        );
    }
};

export const useUploadFailedBomImportToContainer = () => {
    return useHttpFileUpload('GET /bom-files/upload-failed', (res) => res.data.url, { snackbarMessage: null });
};

export function useBomFiles(assemblyId: string) {
    return useQuery({
        queryKey: ['useBomFiles', assemblyId],
        queryFn: async ({ queryKey }): Promise<Array<BomFileDTO>> => {
            const assemblyId: string = queryKey[1];
            const result = await http(
                'GET /assemblies/:assemblyId/bom-files',
                { pathParams: { assemblyId } },
                getToken(),
            );
            return result.data;
        },
        staleTime: 60_000 /* 1 min */,
    });
}
