/* eslint-disable spellcheck/spell-checker */
import { isProductionEnvironment } from '@luminovo/commons';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import App from './App';
import { getCustomerPortalTenant } from './permissions/tenants';
import { runCookieConsent } from './utils/analytics';

window.document.title = getCustomerPortalTenant()?.name ?? 'Luminovo';

runCookieConsent();

const container = document.getElementById('root') as HTMLElement;

const REACT_18_HOSTNAMES = ['luminovo.luminovo.com', 'luminovo.staging.luminovo.com'];

const isReact18Host = REACT_18_HOSTNAMES.includes(window.location.hostname);
const shouldUseReact18 = isReact18Host || !isProductionEnvironment();

if (shouldUseReact18) {
    // eslint-disable-next-line no-console
    console.log('Running in React 18 mode');
    const root = createRoot(container);
    root.render(<App />);
} else {
    // Use legacy ReactDOM.render to run in React 17 mode
    ReactDOM.render(<App />, container);
}
