import * as z from 'zod';
import { endpoint } from '../http/endpoint';
import {
    AdditionalServicesGetResponseRuntype,
    AdditionalServicesPatchRequestRuntype,
    AdditionalServicesPatchResponseRuntype,
    AdditionalServicesPostRequestRuntype,
    AdditionalServicesPostResponseRuntype,
} from './additionalServicesBackendTypes';

export const additionalServicesEndpoints = {
    'POST /additional-services': endpoint({
        description: 'Create an additional service to the organization',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: AdditionalServicesPostRequestRuntype,
        responseBody: AdditionalServicesPostResponseRuntype,
        invalidates: ['GET /additional-services', 'GET /rfqs/:rfqId/additional-services'],
    }),
    'POST /additional-services/bulk': endpoint({
        description: 'Create an additional service to the organization',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.array(AdditionalServicesPostRequestRuntype),
        responseBody: z.object({ data: z.array(AdditionalServicesPostResponseRuntype) }),
        invalidates: ['GET /additional-services', 'GET /rfqs/:rfqId/additional-services'],
    }),
    'PATCH /additional-services/:additionalServiceId': endpoint({
        description: 'Create an additional service to the organization',
        pathParams: z.object({ additionalServiceId: z.string() }),
        queryParams: z.undefined(),
        requestBody: AdditionalServicesPatchRequestRuntype,
        responseBody: AdditionalServicesPatchResponseRuntype,
        invalidates: ['GET /additional-services', 'GET /rfqs/:rfqId/additional-services'],
    }),
    'GET /additional-services': endpoint({
        description: 'Gets all additional services of the organization.',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({
            data: z.array(AdditionalServicesGetResponseRuntype),
        }),
    }),
    'GET /rfqs/:rfqId/additional-services': endpoint({
        description: 'Gets all additional services of an rfq.',
        pathParams: z.object({ rfqId: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({
            data: z.array(AdditionalServicesGetResponseRuntype),
        }),
    }),
    'DELETE /additional-services/:additionalServiceId': endpoint({
        description: 'Delete an additional service.',
        pathParams: z.object({ additionalServiceId: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({ deleted: z.number() }),
        invalidates: ['GET /additional-services', 'GET /rfqs/:rfqId/additional-services'],
    }),
};
