import * as z from 'zod';
import { endpoint } from '../http/endpoint';
import { PCBCredentialsDTORuntype, UserPasswordCredentialsInputDTORuntype } from './pcbManufacturerBackendTypes';

export const pcbManufacturersEndpoints = {
    'GET /3rdparty/pcb-manufacturer/credentials/:manufacturerId': endpoint({
        description: 'Returns full credentials for the given PCB manufacturer API integration',
        pathParams: z.object({ manufacturerId: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({ data: PCBCredentialsDTORuntype.nullable() }),
    }),
    'POST /3rdparty/pcb-manufacturer/credentials/:manufacturerId': endpoint({
        description: 'Uploads credentials for the given PCB manufacturer API integration',
        pathParams: z.object({ manufacturerId: z.string() }),
        queryParams: z.undefined(),
        requestBody: UserPasswordCredentialsInputDTORuntype,
        responseBody: z.object({ data: PCBCredentialsDTORuntype.nullable() }),
    }),
    'PATCH /3rdparty/pcb-manufacturer/credentials/:manufacturerId': endpoint({
        description: 'Updates credentials for the given PCB manufacturer API integratoin',
        pathParams: z.object({ manufacturerId: z.string() }),
        queryParams: z.undefined(),
        requestBody: UserPasswordCredentialsInputDTORuntype,
        responseBody: z.object({ data: PCBCredentialsDTORuntype.nullable() }),
    }),
    'DELETE /3rdparty/pcb-manufacturer/credentials/:manufacturerId': endpoint({
        description: 'Deletes existing credentials for the given PCB manufacturer API integration',
        pathParams: z.object({ manufacturerId: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({ deleted: z.number() }),
    }),
};
