import { t } from '@lingui/macro';
import { Dialog, DialogTitle } from '@luminovo/design-system';
import { DialogContent } from '@mui/material';
import { useDialogContext } from '../../../../components/contexts/ModalContext';
import { AddManufacturingScenarioForm } from './AddManufacturingScenarioForm';

export const useAddManufacturingScenarioDialog = ({ rfqId, assemblyId }: { rfqId: string; assemblyId: string }) => {
    const { setDialog, closeDialog } = useDialogContext();

    const dialog = (
        <Dialog open={true} fullWidth fullScreen maxWidth="lg" style={{ margin: '24px' }}>
            <DialogTitle title={t`Create manufacturing scenario`} onClose={closeDialog} />
            <DialogContent>
                <AddManufacturingScenarioForm rfqId={rfqId} assemblyId={assemblyId} onSuccess={() => closeDialog()} />
            </DialogContent>
        </Dialog>
    );

    return {
        openDialog: () => setDialog(dialog),
    };
};
