import { formatCurrencyWithoutSymbol } from '@luminovo/commons';
import { Flexbox } from '@luminovo/design-system';
import { GridColumns } from '@visx/grid';
import { Group } from '@visx/group';
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale';
import { BarStackHorizontal } from '@visx/shape';
import React from 'react';
import { getDefaultKeyColor } from '../../color/getDefaultColor';
import { palette } from '../../color/palette';
import { BarChartAxisLeft } from './components/BarChartAxisLeft';
import { BarChartAxisTop } from './components/BarChartAxisTop';
import { Legend } from './components/Legend';
import { StackedBar } from './components/StackedBar';
import { BarContainers } from './components/StackedBarContainer';
import { noop } from './noop';
import { Datum, HorizontalStackedBarChartProps } from './types';
import { clearUnselectedKeys } from './util/clearUnselectedKeys';

const formatX = (date: string) => date;

export function HorizontalStackedBarChart<TKeys extends string, T extends Datum<TKeys>>(
    props: HorizontalStackedBarChartProps<TKeys, T>,
): JSX.Element {
    const {
        width,
        formatKey,
        formatValue = formatCurrencyWithoutSymbol,
        keys,
        data: inputData,
        getTooltip,
        getColor = getDefaultKeyColor(keys),
        onBarClick = noop,
        unstackedKey,
    } = props;

    const [selectedKeys, setSelectedKeys] = React.useState(keys);
    const data = clearUnselectedKeys(inputData, keys, selectedKeys);

    const barHeight = 24;
    const height = data.length * barHeight;
    // bounds
    const xMax = width;
    const yMax = height;

    // scales
    const yScale = scaleBand({
        domain: data.map((d) => d.label),
        range: [0, yMax],
        padding: 0.1,
    });

    let domainMax = Math.max(
        ...data.map((d) => {
            return selectedKeys.reduce((max, key) => max + (d[key] ?? 0), 0);
        }),
    );
    let domainMin = Math.min(
        ...data.map((d) => {
            return selectedKeys.reduce((min, key) => Math.min(min, d[key] ?? 0), 0);
        }),
    );

    domainMin = Math.min(0, domainMin);
    domainMax = Math.max(0, domainMax);

    const xScale = scaleLinear<number>({
        domain: [domainMin, domainMax],
        range: [0, xMax],
    });

    let domainZero = xScale(0) ?? 0;

    const colorScale = scaleOrdinal<string, string>({
        domain: keys,
        range: keys.map(getColor),
    });

    return (
        <Flexbox flexDirection={'column'} alignItems={'flex-end'} gap="40px" flexGrow={1}>
            {keys.length > 1 && (
                <Legend
                    keys={keys}
                    setSelectedKeys={setSelectedKeys}
                    selectedKeys={selectedKeys}
                    formatKey={formatKey}
                    getColor={getColor}
                    getTooltip={getTooltip}
                />
            )}
            <svg width={width} height={height} style={{ overflow: 'visible' }}>
                <BarContainers
                    barHeight={barHeight}
                    onBarClick={onBarClick}
                    data={data}
                    xScale={xScale}
                    yScale={yScale}
                />
                <Group top={0} left={0}>
                    <GridColumns
                        scale={xScale}
                        width={width}
                        height={height}
                        stroke={palette.gridLines}
                        strokeDasharray="4 1 2"
                    />

                    <BarStackHorizontal<T, TKeys>
                        data={data}
                        keys={keys}
                        height={height}
                        y={(d) => d.label}
                        yScale={yScale}
                        xScale={xScale}
                        color={colorScale}
                        offset="diverging"
                    >
                        {(barStacks) =>
                            barStacks
                                .sort((a, b) => {
                                    if (a.key === unstackedKey) {
                                        return -1;
                                    } else if (b.key === unstackedKey) {
                                        return 1;
                                    } else {
                                        return 0;
                                    }
                                })
                                .map((barStack) => {
                                    return barStack.bars.map((bar) => {
                                        return (
                                            <StackedBar
                                                key={`${barStack.index}-${bar.index}-${bar.key}`}
                                                bar={bar}
                                                barStack={barStack}
                                                unstackedKey={unstackedKey}
                                                domainZero={domainZero}
                                                {...props}
                                            />
                                        );
                                    });
                                })
                        }
                    </BarStackHorizontal>
                    <BarChartAxisLeft data={data} formatX={formatX} onBarClick={onBarClick} yScale={yScale} />

                    <BarChartAxisTop data={data} xScale={xScale} formatValue={formatValue} />
                </Group>
            </svg>
        </Flexbox>
    );
}
