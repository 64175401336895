import * as z from 'zod';

export enum AvailabilityType {
    Stock = 'Stock',
    OnOrder = 'OnOrder',
    OnOrderWithUnknownLeadTime = 'OnOrderWithUnknownLeadTime',
    LeadTime = 'LeadTimeDays',
}

const AvailabilityLeadTimeRuntype = z.object({
    type: z.literal(AvailabilityType.LeadTime),
    days: z.number(),
});

const AvailabilityOnOrderWithUnknownLeadTimeRuntype = z.object({
    type: z.literal(AvailabilityType.OnOrderWithUnknownLeadTime),
});

const AvailabilityOnOrderRuntype = z.object({
    type: z.literal(AvailabilityType.OnOrder),
    days: z.number(),
});

const AvailabilityStockRuntype = z.object({
    type: z.literal(AvailabilityType.Stock),
});

export const AggregatedAvailabilityRuntype = z.union([
    AvailabilityLeadTimeRuntype,
    AvailabilityOnOrderRuntype,
    AvailabilityOnOrderWithUnknownLeadTimeRuntype,
    AvailabilityStockRuntype,
]);
export type AggregatedAvailability = z.infer<typeof AggregatedAvailabilityRuntype>;

export type Availability = z.infer<typeof AvailabilityRuntype>;
export const AvailabilityRuntype = AggregatedAvailabilityRuntype.and(
    z.object({
        shipping_time_in_days: z.number().nullable().optional(),
    }),
);

export const PriceBreakAvailabilityRuntype = z.union([AvailabilityLeadTimeRuntype, AvailabilityStockRuntype]);
