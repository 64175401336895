import { FormSection } from '@luminovo/design-system';
import { Divider, Grid } from '@mui/material';
import { default as React } from 'react';
import { FormProvider } from 'react-hook-form';
import { FormOverallWrapper } from '../../../components/formLayouts/FormComponents';
import { useHttpQuery } from '../../../resources/http/useHttpQuery';
import { ManufacturingDatabaseBackAndSave } from '../shared/manufacturingComponents';
import { MultipleResourceSection } from './MultipleResourcesSection/MultipleResourceSections';
import { TimeCalculationSection } from './TimeCalculationSection/TimeCalculationSection';
import { ActivitySection } from './addActivityFormComponents';
import { UseActivityForm, useAddActivityForm } from './addActivityFormFunctions';

export const useActivityForm = () => {
    const { data: activities, isLoading: isActivitiesLoading } = useHttpQuery('GET /activities', { queryParams: {} });
    const { data: drivers, isLoading: isDriversLoading } = useHttpQuery('GET /user-drivers', {});
    const { data: resources, isLoading: isResourcesLoading } = useHttpQuery('GET /resources', {});

    return {
        isLoading: isActivitiesLoading || isDriversLoading || isResourcesLoading,
        activities: activities?.data ?? [],
        drivers: drivers?.data ?? [],
        resources: resources ?? [],
    };
};

export const ActivityForm = ({
    useActivityInputs,
    formTitle,
}: {
    useActivityInputs: UseActivityForm;
    formTitle: string;
}): JSX.Element => {
    const { formReturn, onSubmitPost, allResourceNameWithIds, onSubmitPatch } = useAddActivityForm(useActivityInputs);
    const { handleSubmit, formState, trigger } = formReturn;
    const { isValid, isSubmitting } = formState;

    React.useEffect(() => {
        // in the case of edit, trigger validation on the resourceId and driverId field, so in the case of an inactive resource / driver this is immediately shown
        if (useActivityInputs.onSubmitType === 'PATCH') {
            trigger('resourcesDetails');
            trigger('linearBatchCalculationType.driverId');
            trigger('linearUnitCalculationType.driverId');
            trigger('linearProjectCalculationType.driverId');
        }
    }, [trigger, useActivityInputs.onSubmitType]);

    return (
        <FormProvider {...formReturn}>
            <form onSubmit={handleSubmit(useActivityInputs.onSubmitType === 'PATCH' ? onSubmitPatch : onSubmitPost)}>
                <FormOverallWrapper>
                    <ActivitySection title={formTitle} />
                    <Divider />
                    <MultipleResourceSection
                        formReturn={formReturn}
                        allResourceNamesWithId={allResourceNameWithIds}
                        allResources={useActivityInputs.allResources}
                    />
                    <Divider />
                    <TimeCalculationSection />
                    <FormSection title={''}>
                        <Grid item>
                            <ManufacturingDatabaseBackAndSave
                                isSubmitButtonDisabled={!isValid}
                                isSubmitting={isSubmitting}
                            />
                        </Grid>
                    </FormSection>
                </FormOverallWrapper>
            </form>
        </FormProvider>
    );
};
