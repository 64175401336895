import * as z from 'zod';
import { endpoint } from '../http/endpoint';
import {
    SosElectronicCredentialsInputDTORuntype,
    SosElectronicResponseBodyDTORuntype,
} from './sosElectronicBackendTypes';

export const sosElectronicEndpoints = {
    'GET /3rdparty/sos-electronic/credentials': endpoint({
        description: 'Returns credentials for Sos Electronic integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: SosElectronicResponseBodyDTORuntype,
    }),
    'POST /3rdparty/sos-electronic/credentials': endpoint({
        description: 'Uploads credentials for Sos Electronic API integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: SosElectronicCredentialsInputDTORuntype,
        responseBody: SosElectronicResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/sos-electronic/credentials'],
    }),
    'PATCH /3rdparty/sos-electronic/credentials': endpoint({
        description: 'Updates credentials for Sos Electronic API integratoin',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: SosElectronicCredentialsInputDTORuntype,
        responseBody: SosElectronicResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/sos-electronic/credentials'],
    }),
    'DELETE /3rdparty/sos-electronic/credentials': endpoint({
        description: 'Deletes existing credentials for Sos Electronic API integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({ deleted: z.number() }),
        invalidates: ['GET /3rdparty/sos-electronic/credentials'],
    }),
};
