import * as z from 'zod';
import { endpoint } from '../http/endpoint';
import {
    SupplierTermsCreationDTORuntype,
    SupplierTermsDTORuntype,
    SupplierTermsUpdateDTORuntype,
} from './supplierTermsBackendTypes';

export const supplierTermsEndpoints = {
    'GET /supplier/:supplierId/terms': endpoint({
        description: 'Get supplier terms by supplier id',
        pathParams: z.object({
            supplierId: z.string(),
        }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({
            data: z.array(SupplierTermsDTORuntype),
        }),
    }),
    'POST /supplier/:supplierId/terms': endpoint({
        description: 'Add a new supplier terms',
        pathParams: z.object({
            supplierId: z.string(),
        }),
        queryParams: z.undefined(),
        requestBody: SupplierTermsCreationDTORuntype,
        responseBody: z.object({
            data: SupplierTermsDTORuntype,
        }),
        invalidates: ['GET /supplier/:supplierId/terms'],
    }),
    'PATCH /supplier-terms/:supplierTermsId': endpoint({
        description: 'Update a supplier terms',
        pathParams: z.object({
            supplierTermsId: z.string(),
        }),
        queryParams: z.undefined(),
        requestBody: SupplierTermsUpdateDTORuntype,
        responseBody: z.object({
            data: SupplierTermsDTORuntype,
        }),
        invalidates: ['GET /supplier/:supplierId/terms'],
    }),
    'DELETE /supplier-terms/:supplierTermsId': endpoint({
        description: 'Delete a supplier terms',
        pathParams: z.object({
            supplierTermsId: z.string(),
        }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.null(),
        invalidates: ['GET /supplier/:supplierId/terms'],
    }),
};
