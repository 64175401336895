import * as z from 'zod';
import { endpoint } from '../http/endpoint';
import { CalculationTemplatePostRuntype, CalculationTemplateRuntype } from './calculationTemplatesBackendTypes';

/**
 * Split the endpoints into smaller chunks to avoid TypeScript compiler serialization limits.
 * Each chunk gets its own explicit type definition.
 */
const getTemplatesEndpoint = {
    'GET /calculations/templates': endpoint({
        description: 'Fetches a list of calculation templates',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({
            data: z.array(CalculationTemplateRuntype),
        }),
    }),
};

const getSingleTemplateEndpoint = {
    'GET /calculations/templates/:templateId': endpoint({
        description: 'Fetches a single calculation template',
        pathParams: z.object({
            templateId: z.string(),
        }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({
            data: CalculationTemplateRuntype,
        }),
    }),
};

const deleteTemplateEndpoint = {
    'DELETE /calculations/templates/:templateId': endpoint({
        description: 'Deletes a calculation template',
        pathParams: z.object({ templateId: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({ deleted: z.number() }),
    }),
};

const createTemplateEndpoint = {
    'POST /calculations/templates': endpoint({
        description: 'Creates a new calculation template',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: CalculationTemplatePostRuntype,
        responseBody: z.object({
            data: CalculationTemplateRuntype,
        }),
    }),
};

const updateTemplateEndpoint = {
    'PATCH /calculations/templates/:templateId': endpoint({
        description: 'Edits a calculation template',
        pathParams: z.object({ templateId: z.string() }),
        queryParams: z.undefined(),
        requestBody: CalculationTemplatePostRuntype,
        responseBody: z.object({
            data: CalculationTemplateRuntype,
        }),
    }),
};

type GetTemplatesEndpoint = typeof getTemplatesEndpoint;
type GetSingleTemplateEndpoint = typeof getSingleTemplateEndpoint;
type DeleteTemplateEndpoint = typeof deleteTemplateEndpoint;
type CreateTemplateEndpoint = typeof createTemplateEndpoint;
type UpdateTemplateEndpoint = typeof updateTemplateEndpoint;

/**
 * Combined interface that extends all endpoint chunk types
 */
interface CalculationTemplatesEndpoints
    extends GetTemplatesEndpoint,
        GetSingleTemplateEndpoint,
        DeleteTemplateEndpoint,
        CreateTemplateEndpoint,
        UpdateTemplateEndpoint {}

/**
 * Export the combined endpoints with explicit type annotation
 */
export const calculationTemplatesEndpoints: CalculationTemplatesEndpoints = {
    ...getTemplatesEndpoint,
    ...getSingleTemplateEndpoint,
    ...deleteTemplateEndpoint,
    ...createTemplateEndpoint,
    ...updateTemplateEndpoint,
};
