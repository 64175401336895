import { Currency, assertUnreachable } from '@luminovo/commons';
import {
    AnnualDemandDTO,
    AssemblyInfoDTO,
    CurrentUserDetailsDTO,
    CustomerDTO,
    CustomerInfoDTO,
    RfqCreationDemandScenarioDTO,
    RfqCreationPrototypeDemandDTO,
    RfqCreationSeriesDemandDTO,
    RfqInfoDTO,
    RfqInputDTO,
} from '@luminovo/http-client';
import { formatNaiveDate } from '@luminovo/manufacturing-core';
import { CustomerRfqFormState } from '../../../components/RfQ/CustomerRfqForm';
import { OrderScenario } from '../../../components/RfQ/OrderScenariosFormItem';
import { ContributorOption } from '../../../components/RfQ/components/ContributorsFormUtils';
import { assertPresent } from '../../../utils/assertPresent';
import { assertStringIsNotEmpty } from '../../../utils/assertStringIsNotEmpty';
import { PrototypeDemandScenarioFormState, RfqCreationFormState } from '../RfqCreationContext';

const createCustomerInfo = (customer: CustomerDTO): CustomerInfoDTO => {
    return {
        customer: {
            type: 'ExistingCustomer',
            data: customer.id,
        },
    };
};

export const isEmptyDefaultScenario = (scenarios: OrderScenario[]): boolean => {
    return scenarios.length === 1 && !scenarios[0].date && !scenarios[0].quantity && !scenarios[0].id;
};

export const createRequestBodyForEms = (form: RfqCreationFormState): RfqInputDTO => {
    const selectedCustomer = assertPresent(form.customer);
    const customer: CustomerInfoDTO = createCustomerInfo(selectedCustomer);

    const assembly: AssemblyInfoDTO = {
        ...form.assembly,
        assemblies: form.assembly.assemblies.map((assembly) => ({
            name: assertPresent(assembly.name),
            assemblyType: {
                type: assembly.assemblyType,
            },
        })),
        industry: assertStringIsNotEmpty(form.assembly.industry),
    };

    const shouldHaveVolumeEstimate =
        form.rfq.volumeEstimate.isEnabled && (form.rfq.volumeEstimate.lower || form.rfq.volumeEstimate.upper);
    const volumeEstimate: RfqInfoDTO['volumeEstimate'] = shouldHaveVolumeEstimate
        ? {
              lower: form.rfq.volumeEstimate.lower?.toString() ?? null,
              upper: form.rfq.volumeEstimate.upper?.toString() ?? null,
              currency: form.rfq.currency,
          }
        : undefined;
    const rfq: RfqInfoDTO = {
        ...form.rfq,
        dueDate: form.rfq.dueDate ? formatNaiveDate(form.rfq.dueDate) : undefined,
        contributors: [
            ...form.rfq.internalContributors.map((c) => c.id),
            ...form.rfq.externalContributors.map((c) => c.id),
        ],
        volumeEstimate,
        comment: form.rfq.comment ?? null,
    };

    const demand_scenarios: RfqCreationDemandScenarioDTO[] = form.demand_scenarios.map(
        /* eslint-disable-next-line array-callback-return */
        (demand_scenario): RfqCreationDemandScenarioDTO => {
            switch (demand_scenario.type) {
                case 'Prototype':
                    return {
                        type: 'Prototype',
                        demands: demand_scenario.demands.map(
                            (demand, index): RfqCreationPrototypeDemandDTO => ({
                                assembly_designator: assembly.assemblies[index].name,
                                quantity: demand.quantity ?? 0,
                            }),
                        ),
                        target_date: demand_scenario.target_date
                            ? { type: 'Date', value: formatNaiveDate(demand_scenario.target_date) }
                            : { type: 'AsSoonAsPossible' },
                    };
                case 'Series':
                    return {
                        type: 'Series',
                        start_year: demand_scenario.start_year,
                        end_year: demand_scenario.end_year,
                        demands: demand_scenario.demands.map(
                            (demand, index): RfqCreationSeriesDemandDTO => ({
                                assembly_designator: assembly.assemblies[index].name,
                                annual_demands: demand.annual_demands.map(
                                    (annual_demand): AnnualDemandDTO => ({
                                        year: annual_demand.year,
                                        quantity: annual_demand.quantity ?? 0,
                                        delivery_batch_sizes: annual_demand.delivery_batch_sizes
                                            .filter((val) => val.quantity && val.quantity > 0)
                                            // to make typescript happy
                                            .map((val) => ({ quantity: val.quantity ?? 0 })),
                                    }),
                                ),
                            }),
                        ),
                    };
                default:
                    assertUnreachable(demand_scenario);
            }
        },
    );

    return {
        customer,
        assembly,
        rfq,

        additional_service_ids: form.additionalServiceIds,

        demand_scenarios,
    };
};

export const createRequestBodyForCustomer = (
    form: CustomerRfqFormState,
    currency: Currency,
    customer: CustomerDTO,
    currentUser: CurrentUserDetailsDTO,
): RfqInputDTO => {
    const defaultContactPerson: ContributorOption | undefined = customer.default_contact_person
        ? {
              id: customer.default_contact_person,
              userName: '',
          }
        : undefined;

    const contributors: ContributorOption[] = [
        {
            id: currentUser.user.id,
            userName: `${currentUser.user.first_name} ${currentUser.user.last_name}`,
        },
    ];
    if (defaultContactPerson) {
        contributors.push(defaultContactPerson);
    }

    const emsRfqCreationFormState: RfqCreationFormState = {
        rfq: {
            name: form.rfqName,
            emsInternalNumber: undefined,
            internalContributors: contributors,
            externalContributors: [],
            dueDate: undefined,
            currency: currency,
            volumeEstimate: {
                isEnabled: false,
                lower: undefined,
                upper: undefined,
            },
            comment: form.comment,
        },
        assembly: {
            industry: form.industry,
            assemblies: [
                {
                    name: form.rfqName,
                    assemblyType: form.assemblyType,
                },
            ],
        },
        customer,
        additionalServiceIds: form.additionalServiceIds,

        demand_scenarios: form.orders.map(
            (order): PrototypeDemandScenarioFormState => ({
                type: 'Prototype',
                target_date: order.date,
                demands: [{ quantity: order.quantity ?? 0 }],
            }),
        ),
    };

    return createRequestBodyForEms(emsRfqCreationFormState);
};
