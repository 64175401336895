import { isPresent } from '@luminovo/commons';
import { PCBIPC600Class } from '@luminovo/http-client';
import { turnKeywordsIntoRegexRespectingWordBoundaries } from '../../framework/regexHelper';
import { PcbAttribute } from '../PcbAttribute';
import { parseNo } from './parseYesNo';

const classRegex = '(?:Class|Klasse)';
const ipc600ClassKeywordKeywords = ['IPC-A-600', `IPC.?${classRegex}`];

const ipc600ClassKeywords: Record<Exclude<PCBIPC600Class, 'ipcnone'>, string[]> = {
    [PCBIPC600Class.IPC1]: [`${classRegex}.?(?:1|I)`],
    [PCBIPC600Class.IPC2]: [`${classRegex}.?(?:2|II)`],
    [PCBIPC600Class.IPC2Plus]: [`${classRegex}.?(?:2+|II+)`],
    [PCBIPC600Class.IPC3]: [`${classRegex}.?(?:3|III)`],
    [PCBIPC600Class.IPC3A]: [`${classRegex}.?(?:3a|IIIa)`],
};

export function parseIPC600Class(str: string): PcbAttribute | undefined {
    const ipcKeyword = turnKeywordsIntoRegexRespectingWordBoundaries(ipc600ClassKeywordKeywords).test(str);
    const no = parseNo(str);
    for (const [ipc600Class, keywords] of Object.entries(ipc600ClassKeywords)) {
        const regex = turnKeywordsIntoRegexRespectingWordBoundaries(keywords);
        if (regex.test(str)) {
            return {
                attr: 'ipc600Class',
                value: ipc600Class as PCBIPC600Class,
                confidence: isPresent(ipcKeyword) ? 1 : 0.5,
            };
        }
    }
    if (ipcKeyword && no) {
        return {
            attr: 'ipc600Class',
            value: 'ipcnone' as PCBIPC600Class,
            confidence: 0.75,
        };
    }
    return undefined;
}
