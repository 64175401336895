import { mapDesignItemResponsesDTONewToOld } from '../assembly/assemblyHandler';
import { useHttpQuery } from '../http/useHttpQuery';

export function useDesignItem(designItem: string, enabled: boolean = true) {
    return useHttpQuery(
        'POST /design-items/bulk',
        {
            requestBody: { ids: [designItem] },
        },
        {
            select: (res) => mapDesignItemResponsesDTONewToOld(res.items, res.excel_lines),
            enabled,
        },
    );
}

export const useDesignItems = (designItems: string[] = []) => {
    return useHttpQuery(
        'POST /design-items/bulk',
        {
            requestBody: { ids: designItems! },
        },
        {
            select: (res) => mapDesignItemResponsesDTONewToOld(res.items, res.excel_lines),
        },
    );
};

export const useDesignItemPartAlternatives = (designItemId: string | undefined, canSeePartAlternatives: boolean) => {
    /// Currently not using useBulkQuery here since it keeps on creating a new object each time,eg, when a user moves from 1 bom-item to another
    /// which results in the re-computation of the expensive function `createBomItems` function

    const { data, isLoading } = useHttpQuery(
        'GET /design-items/:designItemId/part-alternatives',
        {
            pathParams: { designItemId: designItemId ?? '' },
        },
        { enabled: !!designItemId && canSeePartAlternatives, select: (response) => response },
    );

    return {
        data: data ?? [],
        isLoading,
    };
};
