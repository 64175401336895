import { SolutionDTO } from '@luminovo/http-client';
import { Solution } from '../types';

export const parseSolution = (solution: SolutionDTO): Solution => {
    return {
        token: solution.token,
        solutionTags: solution.tags,
        availability: solution.availability,
        quantities: solution.quantities,
        cost: solution.cost,
        unit: solution.offer_price.offer_unit,
        quantity: solution.offer_price.offer_quantity,
        offerUnitPrice: solution.offer_price.offer_unit_price,
        offerId: solution.offer_id.offer,
        signature: solution.signature,
    };
};
