import * as z from 'zod';
import { endpoint } from '../http/endpoint';
import { OrbweaverLicenseDTORuntype } from './orbweaverBackendTypes';

export const orbweaverEndpoints = {
    'GET /3rdparty/orbweaver/is-configured': endpoint({
        description: 'Returns orbweaver license information',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({ data: OrbweaverLicenseDTORuntype }),
    }),
};
