import * as z from 'zod';
import { endpoint } from '../http/endpoint';
import {
    CalculationAssemblyPostRuntype,
    CalculationAssemblyResponseRuntype,
    RfqCalculationPDFExportResponseRuntype,
    ScenarioCombinationForCalculationRuntype,
    ScenarioCombinationForCalculationRuntypePatch,
    ScenarioCombinationForCalculationRuntypePatchResponse,
    SourcingCombinationCostsPostRuntype,
    SourcingCombinationCostsRuntype,
} from './calculationBackendTypes';
import { CostResponseRuntype, FrozenScenarioCostRuntype } from './costResponseBackendType';

/**
 * Split the calculationEndpoints into logical groups to prevent TypeScript from
 * trying to infer the entire object's type at once, which exceeds the maximum length
 * the compiler can serialize.
 */

// Group 1: Scenario combinations endpoints
const scenarioCombinationsEndpoints = {
    'GET /calculations/scenario-combinations': endpoint({
        description: 'Gets all the scenarios that have been submitted for calculations',
        pathParams: z.undefined(),
        queryParams: z.object({ assembly_id: z.string().optional(), sourcing_scenario_id: z.string().optional() }),
        requestBody: z.undefined(),
        responseBody: z.array(ScenarioCombinationForCalculationRuntype),
    }),

    'PATCH /calculations/scenario-combinations': endpoint({
        description: 'Updates all the scenarios that have been submitted for calculations',
        pathParams: z.undefined(),
        queryParams: z.object({ assembly_id: z.string() }),
        requestBody: z.array(ScenarioCombinationForCalculationRuntypePatch),
        responseBody: z.array(ScenarioCombinationForCalculationRuntypePatchResponse),
    }),
};

// Group 2: Calculation assembly costs GET endpoints
const calculationAssemblyCostsGetEndpoints = {
    'GET /calculations/calculation-assembly-costs': endpoint({
        description: 'Get the scenario combination rows',
        pathParams: z.undefined(),
        queryParams: z
            .object({
                assembly_id: z.string(),
            })
            .or(
                z.object({
                    calculation_assembly_details_id: z.string(),
                }),
            ),
        requestBody: z.undefined(),
        responseBody: z.object({
            data: z.array(CostResponseRuntype),
        }),
    }),

    'GET /calculations/calculation-assembly-costs/export-pdf-data': endpoint({
        description: 'Returns the data required to constuct the PDF-export page',
        pathParams: z.undefined(),
        queryParams: z.object({
            rfq_id: z.string(),
        }),
        requestBody: z.undefined(),
        responseBody: RfqCalculationPDFExportResponseRuntype,
    }),
};

// Group 3: Calculation assembly costs POST endpoints
const calculationAssemblyCostsPostEndpoints = {
    'POST /calculations/calculation-assembly-costs': endpoint({
        description: 'updates the scenario combination rows',
        pathParams: z.undefined(),
        queryParams: z.object({
            assembly_id: z.string(),
        }),
        requestBody: SourcingCombinationCostsPostRuntype,
        responseBody: z.object({
            data: z.array(SourcingCombinationCostsRuntype),
        }),
    }),
};

// Group 4: Calculation assembly costs PATCH endpoints
const calculationAssemblyCostsPatchEndpoints = {
    'PATCH /calculations/calculation-assembly-costs/:calculationAssemblyCostId': endpoint({
        description: 'updates the calculation assembly costs',
        pathParams: z.object({
            calculationAssemblyCostId: z.string(),
        }),
        queryParams: z.undefined(),
        requestBody: SourcingCombinationCostsPostRuntype,
        responseBody: z.object({
            data: SourcingCombinationCostsRuntype,
        }),
        invalidates: ['GET /calculations/calculation-assembly-costs'],
    }),
};

// Group 5: Calculation assembly details endpoints
const calculationAssemblyDetailsEndpoints = {
    'GET /calculations/calculation-assembly-details': endpoint({
        description: 'Gets the calculation assembly detail of an assembly',
        pathParams: z.undefined(),
        queryParams: z.object({
            assembly_id: z.string(),
        }),
        requestBody: z.undefined(),
        responseBody: z.object({
            data: z.array(CalculationAssemblyResponseRuntype),
        }),
    }),

    'POST /calculations/calculation-assembly-details': endpoint({
        description: 'Creates a calculation assembly detail',
        pathParams: z.undefined(),
        queryParams: z.object({
            create_from_calculation_template_id: z.string().optional(),
        }),
        requestBody: CalculationAssemblyPostRuntype,
        responseBody: z.object({
            data: CalculationAssemblyResponseRuntype,
        }),
    }),

    'PATCH /calculations/calculation-assembly-details/:calculationAssemblyDetailsId': endpoint({
        description: 'Update a calculation assembly detail',
        pathParams: z.object({
            calculationAssemblyDetailsId: z.string(),
        }),
        queryParams: z.undefined(),
        requestBody: CalculationAssemblyResponseRuntype,
        responseBody: z.object({
            data: CalculationAssemblyResponseRuntype,
        }),
        invalidates: ['GET /calculations/calculation-assembly-costs', 'GET /calculations/calculation-assembly-details'],
    }),

    'PATCH /calculations/calculation-assembly-details/:calculationAssemblyDetailsId/reset': endpoint({
        description: 'Resets the calculation assembly detail values',
        pathParams: z.object({
            calculationAssemblyDetailsId: z.string(),
        }),
        queryParams: z.union([z.object({ reset_to_template: z.string() }), z.object({})]),
        requestBody: z.undefined(),
        responseBody: z.object({
            data: CalculationAssemblyResponseRuntype,
        }),
        invalidates: ['GET /calculations/calculation-assembly-costs', 'GET /calculations/calculation-assembly-details'],
    }),
};

// Group 6: Frozen scenario cost endpoints
const frozenScenarioCostEndpoints = {
    'POST /calculations/frozen-scenario-cost': endpoint({
        description: 'Freezes a calculation',
        pathParams: z.undefined(),
        queryParams: z.union([z.object({ calculation_assembly_cost_id: z.string() }), z.object({})]),
        requestBody: z.undefined(),
        responseBody: z.object({
            data: FrozenScenarioCostRuntype,
        }),
        invalidates: ['GET /calculations/calculation-assembly-costs'],
    }),

    'DELETE /calculations/frozen-scenario-cost/:frozenId': endpoint({
        description: 'Unfreezes a calculation',
        pathParams: z.object({
            frozenId: z.string(),
        }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({
            deleted: z.number(),
        }),
        invalidates: ['GET /calculations/calculation-assembly-costs'],
    }),
};

// Define explicit types for each group
type ScenarioCombinationsEndpoints = typeof scenarioCombinationsEndpoints;
type CalculationAssemblyCostsGetEndpoints = typeof calculationAssemblyCostsGetEndpoints;
type CalculationAssemblyCostsPostEndpoints = typeof calculationAssemblyCostsPostEndpoints;
type CalculationAssemblyCostsPatchEndpoints = typeof calculationAssemblyCostsPatchEndpoints;
type CalculationAssemblyDetailsEndpoints = typeof calculationAssemblyDetailsEndpoints;
type FrozenScenarioCostEndpoints = typeof frozenScenarioCostEndpoints;

// Define a combined interface that extends all group types
interface CalculationEndpoints
    extends ScenarioCombinationsEndpoints,
        CalculationAssemblyCostsGetEndpoints,
        CalculationAssemblyCostsPostEndpoints,
        CalculationAssemblyCostsPatchEndpoints,
        CalculationAssemblyDetailsEndpoints,
        FrozenScenarioCostEndpoints {}

// Export the combined object with the explicit type annotation
export const calculationEndpoints: CalculationEndpoints = {
    ...scenarioCombinationsEndpoints,
    ...calculationAssemblyCostsGetEndpoints,
    ...calculationAssemblyCostsPostEndpoints,
    ...calculationAssemblyCostsPatchEndpoints,
    ...calculationAssemblyDetailsEndpoints,
    ...frozenScenarioCostEndpoints,
};
