import * as z from 'zod';

import { RegionsEnumRuntype } from '../supplierAndStockLocation';

export enum PriceRadarStatusKind {
    InvalidSpecification = 'InvalidSpecification',
    SupplierApiError = 'SupplierApiError',
    Pending = 'Pending',
    NeedsRefreshForOffers = 'NeedsRefreshForOffers',
    OffersAvailable = 'OffersAvailable',
    NoOffersReceived = 'NoOffersReceived',
    PanelError = 'PanelError',
    MissingCredentials = 'MissingCredentials',
    InvalidCredentials = 'InvalidCredentials',
    ApiNotSetUp = 'ApiNotSetUp',
    CustomStackup = 'CustomStackup',
    StackratePricingBreaks = 'StackratePricingBreaks',
}

export type InvalidSpecificationError = z.infer<typeof InvalidSpecificationErrorRuntype>;
const InvalidSpecificationErrorRuntype = z.array(
    z.object({
        name: z.string(),
        label: z.string(),
        error: z.string(),
    }),
);

const InvalidSpecificationRuntype = z.object({
    type: z.literal(PriceRadarStatusKind.InvalidSpecification),
    errors: InvalidSpecificationErrorRuntype,
});

const ApiErrorRuntype = z.object({
    type: z.literal(PriceRadarStatusKind.SupplierApiError),
});

const PendingRuntype = z.object({
    type: z.literal(PriceRadarStatusKind.Pending),
});

const NeedsRefreshForOffersRuntype = z.object({
    type: z.literal(PriceRadarStatusKind.NeedsRefreshForOffers),
});

const SuccessWithOffersStatusRuntype = z.object({
    type: z.literal(PriceRadarStatusKind.OffersAvailable),
});

const SuccessWithoutOffersStatusRuntype = z.object({
    type: z.literal(PriceRadarStatusKind.NoOffersReceived),
});

export enum PanelErrorKind {
    WidthExceedsDeliveryPanel = 'PcbWidthExceedsDeliveryPanel',
    HeightExceedsDeliveryPanel = 'PcbHeightExceedsDeliveryPanel',
    WidthBelowDeliveryPanelMinimum = 'PcbWidthBelowDeliveryPanelMinimum',
    HeightBelowDeliveryPanelMinimum = 'PcbHeightBelowDeliveryPanelMinimum',
    UnknownError = 'UnknownError',
}

const PanelErrorKindRuntype = z.object({
    type: z.nativeEnum(PanelErrorKind),
    message: z.string().optional(),
});

const PanelErrorStatusRuntype = z.object({
    type: z.literal(PriceRadarStatusKind.PanelError),
    error: PanelErrorKindRuntype,
});

const MissingCredentialsStatusRuntype = z.object({
    type: z.literal(PriceRadarStatusKind.MissingCredentials),
});

const InvalidCredentialsStatusRuntype = z.object({
    type: z.literal(PriceRadarStatusKind.InvalidCredentials),
});

export enum ApiNotSetupErrorKind {
    MissingGatemaCustomerId = 'MissingGatemaCustomerId',
}

const ApiNotSetUpRuntype = z.object({
    type: z.literal(PriceRadarStatusKind.ApiNotSetUp),
    error: z.object({
        type: z.nativeEnum(ApiNotSetupErrorKind),
    }),
});

const CustomStackupRuntype = z.object({
    type: z.literal(PriceRadarStatusKind.CustomStackup),
});

export type StackratePricingMessage = z.infer<typeof StackratePricingMessageRuntype>;
const StackratePricingMessageRuntype = z.object({
    name: z.string(),
    message: z.string(),
});

const StackratePricingBreaksRuntype = z.object({
    type: z.literal(PriceRadarStatusKind.StackratePricingBreaks),
    pricing_breaks: z.array(StackratePricingMessageRuntype),
});

export type CustomPartOfferStatuses = z.infer<typeof CustomPartOfferManufacturerStatusRuntype>;
const CustomPartOfferManufacturerStatusRuntype = z.union([
    PendingRuntype,
    SuccessWithOffersStatusRuntype,
    SuccessWithoutOffersStatusRuntype,
    NeedsRefreshForOffersRuntype,
    InvalidSpecificationRuntype,
    PanelErrorStatusRuntype,
    MissingCredentialsStatusRuntype,
    InvalidCredentialsStatusRuntype,
    ApiNotSetUpRuntype,
    ApiErrorRuntype,
    CustomStackupRuntype,
    StackratePricingBreaksRuntype,
]);

export type DetailedCustomPartOfferData = z.infer<typeof DetailedCustomPartOfferResultResponseRuntype>;
const DetailedCustomPartOfferResultResponseRuntype = z.object({
    supplier: z.object({
        id: z.string(),
        name: z.string(),
    }),
    stock_location: z.array(RegionsEnumRuntype),
    status: CustomPartOfferManufacturerStatusRuntype,
});

export enum CustomPartOfferResultType {
    NoSourcingScenarios = 'NoSourcingScenarios',
    AnalysisInProgress = 'AnalysisInProgress',
    NoSuppliersConfigured = 'NoSuppliersConfigured',
    NoDefinedQuantity = 'NoDefinedQuantity',
    NotApproved = 'NotApproved',
    Detailed = 'Detailed',
    Simple = 'Simple',
}

export type DetailedCustomPartOffers = z.infer<typeof CustomPartOfferResultArrayResponseRuntype>;
const CustomPartOfferResultArrayResponseRuntype = z.object({
    type: z.literal(CustomPartOfferResultType.Detailed),
    data: z.array(DetailedCustomPartOfferResultResponseRuntype),
    is_pending: z.boolean(),
});

export type SimpleOffersDTO = z.infer<typeof SimpleOfferResultResponseRuntype>;
const SimpleOfferResultResponseRuntype = z.object({
    type: z.literal(CustomPartOfferResultType.Simple),
    data: CustomPartOfferManufacturerStatusRuntype,
});

const NoSourcingScenariosRuntype = z.object({
    type: z.literal(CustomPartOfferResultType.NoSourcingScenarios),
});

const AnalysisInProgressRuntype = z.object({
    type: z.literal(CustomPartOfferResultType.AnalysisInProgress),
});

const NoSuppliersConfiguredRuntype = z.object({
    type: z.literal(CustomPartOfferResultType.NoSuppliersConfigured),
});

const NoDefinedQuantityRuntype = z.object({
    type: z.literal(CustomPartOfferResultType.NoDefinedQuantity),
});

export enum PcbWorkflowStep {
    FileManager = 'FileManager',
    DrillManager = 'DrillManager',
    Stackup = 'Stackup',
    Specification = 'Specification',
}

const NotApprovedRuntype = z.object({
    type: z.literal(CustomPartOfferResultType.NotApproved),
    missing_steps: z.array(z.nativeEnum(PcbWorkflowStep)),
});

export type CustomPartOfferResponse = z.infer<typeof CustomPartOfferResultResponseStatusRuntype>;
const CustomPartOfferResultResponseStatusRuntype = z.union([
    NoSourcingScenariosRuntype,
    AnalysisInProgressRuntype,
    NoSuppliersConfiguredRuntype,
    CustomPartOfferResultArrayResponseRuntype,
    SimpleOfferResultResponseRuntype,
    NotApprovedRuntype,
    NoDefinedQuantityRuntype,
]);

export const CustomPartOfferResultResponseRuntype = z.object({
    pcb_id: z.string(),
    status: CustomPartOfferResultResponseStatusRuntype,
});
