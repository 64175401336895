import * as z from 'zod';
import { endpoint } from '../http/endpoint';
import {
    Element14CredentialsDTORuntype,
    Element14CredentialsInputDTORuntype,
    Element14SupportedSettingDTORuntype,
} from './element14BackendTypes';

export const element14Endpoints = {
    'GET /3rdparty/element14/credentials': endpoint({
        description: 'Returns credentials for element14 integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({ data: Element14CredentialsDTORuntype }),
    }),
    'POST /3rdparty/element14/credentials': endpoint({
        description: 'Uploads credentials for element14 API integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: Element14CredentialsInputDTORuntype,
        responseBody: z.object({ data: Element14CredentialsDTORuntype }),
        invalidates: ['GET /3rdparty/element14/credentials'],
    }),
    'PATCH /3rdparty/element14/credentials': endpoint({
        description: 'Updates credentials for element14 API integratoin',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: Element14CredentialsInputDTORuntype,
        responseBody: z.object({ data: Element14CredentialsDTORuntype }),
        invalidates: ['GET /3rdparty/element14/credentials'],
    }),
    'DELETE /3rdparty/element14/credentials': endpoint({
        description: 'Deletes existing credentials for element14 API integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({ deleted: z.number() }),
        invalidates: ['GET /3rdparty/element14/credentials'],
    }),
    'GET /3rdparty/element14/supported-store-location-settings': endpoint({
        description: 'Returns supported store locations and currencies for element14 API integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.array(Element14SupportedSettingDTORuntype),
    }),
};
