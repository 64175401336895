import { Plural, t, Trans } from '@lingui/macro';
import { uniqBy } from '@luminovo/commons';
import {
    colorSystem,
    Dialog,
    DialogContent,
    FieldText,
    InteractiveCard,
    PrimaryButton,
    Tag,
    Text,
    Thumbnail,
} from '@luminovo/design-system';
import { PartLite, PartLiteTypes } from '@luminovo/http-client';
import { formatPart } from '@luminovo/sourcing-core';
import { CheckCircle, CircleOutlined } from '@mui/icons-material';
import { Box, List, ListItem, ListItemButton, ListItemText, Skeleton } from '@mui/material';
import React from 'react';
import { useHttpQuery } from '../../../resources/http/useHttpQuery';
import { useIpn } from '../../../resources/part/partHandler';
import useDebounce from '../../../useDebounce';
import { resolveOtsPartsRecursively } from '../model/resolveOtsPartsRecursively';

export function DialogSelectPart({
    ipn,
    requestedPart,
    description,
    value,
    onChange,
    onClose,
}: {
    ipn?: string;
    description?: string;
    requestedPart?: PartLite;
    value: PartLite | undefined;
    onChange: (value: PartLite | undefined) => void;
    onClose: () => void;
}) {
    const otsMatches = resolveOtsPartsRecursively(requestedPart);
    const [selectedPart, setSelectedPart] = React.useState<PartLite | undefined>(value ?? requestedPart);
    const [search, setSearch] = React.useState(value?.kind === PartLiteTypes.OffTheShelf && value.mpn ? value.mpn : '');
    const debouncedSearch = useDebounce(search, 500);

    const { partOptions, isLoading } = useFetchPartOptions({
        ipn,
        search: debouncedSearch,
        otsMatches,
    });

    return (
        <Dialog title={t`Select offered part`} open={true} onClose={onClose}>
            <DialogContent
                sx={{
                    overflow: 'hidden',
                }}
            >
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    <FieldText
                        placeholder={t`Search for a part`}
                        type="search"
                        fullWidth
                        value={search}
                        onChange={(value) => setSearch(value ?? '')}
                    />
                    <Box
                        sx={{
                            minHeight: '0px',
                            overflow: 'auto',
                            maxHeight: '600px',
                            gap: 1,
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        {isLoading ? (
                            <InteractiveCard selectable={false} sx={{ padding: 1 }}>
                                <Skeleton variant="text" />
                            </InteractiveCard>
                        ) : (
                            <Text color={colorSystem.neutral[6]}>
                                <Plural
                                    value={partOptions.length}
                                    zero={`No results found`}
                                    one={`Showing one result`}
                                    other={`Showing ${partOptions.length} results`}
                                />
                            </Text>
                        )}
                        {!isLoading && (
                            <List disablePadding sx={{ overflow: 'auto' }}>
                                {partOptions.map(({ part, id, image, label, description }) => (
                                    <PartListItem
                                        key={id}
                                        selected={id === selectedPart?.id}
                                        onClick={() => setSelectedPart(part)}
                                        primaryText={label}
                                        secondaryText={description ?? ''}
                                        image={image ?? ''}
                                        isSuggested={otsMatches?.some((x) => x.id === id)}
                                    />
                                ))}
                            </List>
                        )}
                    </Box>
                </Box>
            </DialogContent>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', padding: 3 }}>
                <PrimaryButton onClick={() => onChange(selectedPart)}>
                    <Trans>Select</Trans>
                </PrimaryButton>
            </Box>
        </Dialog>
    );
}

function PartListItem({
    selected,
    onClick,
    image,
    primaryText,
    secondaryText,
    isSuggested,
}: {
    selected: boolean;
    onClick: () => void;
    image?: string;
    primaryText: string;
    secondaryText: string;
    isSuggested?: boolean;
}) {
    return (
        <ListItem
            disablePadding
            onClick={onClick}
            sx={{
                position: selected ? 'sticky' : 'relative',
                top: selected ? 0 : 'auto',
                backgroundColor: 'white',
                zIndex: selected ? 1 : 'auto',
                border: selected ? `1px solid ${colorSystem.primary[5]}` : 'none',
                borderRadius: selected ? '4px' : '0px',
            }}
        >
            <ListItemButton selected={selected}>
                <Thumbnail
                    sx={{
                        border: `1px solid ${colorSystem.neutral[2]}`,
                        borderRadius: '4px',
                    }}
                    width={`64px`}
                    height={`64px`}
                    src={image ?? 'none.png'}
                />

                <Box sx={{ display: 'flex', flexDirection: 'column', width: '16px' }}></Box>
                <ListItemText
                    primary={primaryText}
                    secondary={secondaryText}
                    secondaryTypographyProps={{
                        fontWeight: 'normal',
                        fontSize: '12px',
                    }}
                />
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, marginLeft: '16px' }}>
                    {isSuggested && <Tag label={t`Suggested`} attention="low" color={'yellow'} />}
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {selected ? (
                            <CheckCircle style={{ color: colorSystem.primary[5] }} />
                        ) : (
                            <CircleOutlined style={{ color: colorSystem.neutral[4] }} />
                        )}
                    </Box>
                </Box>
            </ListItemButton>
        </ListItem>
    );
}

type PartOption = { id: string; part: PartLite; label: string; description: string; image: string | null };

export function useFetchPartOptions({
    ipn,
    search = '',
    otsMatches,
}: { ipn?: string; search?: string; otsMatches?: Array<PartLite & { kind: PartLiteTypes.OffTheShelf }> } = {}): {
    partOptions: Array<PartOption>;
    isLoading: boolean;
} {
    const { data: otsSearchResults = [], isLoading: isLoadingSearch } = useHttpQuery(
        'POST /parts/off-the-shelf/search/mpn/bulk',
        {
            requestBody: {
                items: [search],
                rfq_context: 'OutsideRfQ',
                search_external_apis: true,
                use_elastic: true,
            },
        },
        {
            enabled: search?.length > 2,
            select: (res) => Object.values(res.data).flatMap((x) => x),
        },
    );

    const { data: ipnPart } = useIpn(ipn ?? '', { type: 'OutsideRfQ' });

    const allIpnParts: Array<PartOption> = ipnPart
        ? [
              {
                  id: ipnPart.id,
                  part: { kind: PartLiteTypes.Ipn, id: ipnPart.id, matches: [] },
                  label: formatPart(ipnPart),
                  description: ipnPart.part_specifications.map((x) => x.description).join('; '),
                  image: null,
              },
          ]
        : [];

    const { data: otsParts = [], isLoading: isLoadingOts } = useHttpQuery(
        'POST /parts/off-the-shelf/bulk',
        {
            requestBody: {
                rfq_context: 'OutsideRfQ',
                ids: otsMatches?.map((x) => x.id) ?? [],
            },
        },
        { select: (res) => res.items },
    );

    const allOtsParts: Array<PartOption> = [...otsSearchResults, ...otsParts].map((otsPart) => {
        return {
            id: otsPart.id,
            part: { ...otsPart, kind: PartLiteTypes.OffTheShelf },
            label: formatPart(otsPart),
            description: otsPart.description ?? '',
            image: otsPart.image_url,
        };
    });

    return {
        partOptions: uniqBy([...allIpnParts, ...allOtsParts], (p) => p.id),
        isLoading: isLoadingSearch || isLoadingOts,
    };
}
