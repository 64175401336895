import { MessageDescriptor } from '@lingui/core';
import { defineMessage } from '@lingui/macro';
import { FormattingType } from '@luminovo/commons';
import { TableCellProps, TableRowProps } from '@mui/material';
import { FallbackRender } from '@sentry/react';
import { RankingInfo } from '@tanstack/match-sorter-utils';
import {
    Column,
    ColumnDef,
    ColumnFiltersState,
    ColumnMeta,
    ColumnOrderState,
    ColumnPinningState,
    ExpandedState,
    FilterFn,
    Row,
    RowData,
    RowSelectionState,
    SortingState,
    Table,
    TableMeta,
    TableOptions,
    VisibilityState,
} from '@tanstack/react-table';
import { TableComponents } from 'react-virtuoso';
import { CheckableChipProps } from '../Chip/CheckableChip';

export type RenderType = 'text' | 'ellipsisOnly' | 'number' | 'generic';

declare module '@tanstack/table-core' {
    interface FilterFns {
        buildFilter: FilterFn<unknown>;
    }
    interface FilterMeta {
        itemRank: RankingInfo;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    interface CellContext<TData extends RowData, TValue, TSharedContext = unknown> {
        sharedContext: TSharedContext;
        TableCellProps: TableCellProps;
    }

    interface ColumnMeta<TData, TValue, TShareContext = unknown> {
        /**
         * Renders the column label.
         *
         * @example
         *   label: () => t`Username`
         *
         */
        label: () => string;

        /**
         * Adds helper text to the column header.
         *
         * @example
         *   description: () => "This is the user's username"
         */
        description?: () => string;

        /**
         * When defined, the column will add a quick filter to the toolbar.
         *
         * @example
         *   quickFilters: [
         *     {
         *       label: () => t`With stock`,
         *       value: {
         *         filterFn: SupportedFilterFn.inNumberRange,
         *         value: [1, null],
         *       },
         *     },
         *   ]
         */
        quickFilters?: Array<QuickFilter<unknown>>;

        /**
         * When true, the column will show an advanced filter menu.
         *
         * @default true
         */
        enableAdvancedFilters?: boolean;

        /**
         * Transforms the filter value before it's send to the backend
         *
         * @example
         *   filterValueTransform: (value: Array<{ id: string }>) => value.map(item => item.id)
         */
        filterValueTransform?: (value: unknown) => unknown;

        /**
         * Defines the align of the column.
         *
         * @example
         *   align: 'center'
         */
        align?: 'left' | 'center' | 'right';

        /**
         * When set, the column will be pinned to the left or right.
         *
         * IMPORTANT: This option requires that the column has an `id`.
         *
         * @example
         *   initialPinning: 'right'
         *
         *   // instead of
         *
         *   const { table } = useTanStackTable({
         *       ...
         *       initialState: {
         *           columnPinning: {
         *               right: ['solutionTags'],
         *           },
         *       },
         *   });
         */
        initialPinning?: 'left' | 'right';

        /**
         * When true, the column will be hidden by default.
         *
         * IMPORTANT: This option requires that the column has an `id`.
         *
         * @example
         *   initialVisibility: false
         *
         *   // instead of
         *
         *   const { table } = useTanStackTable({
         *       ...
         *       initialState: {
         *           columnVisibility: {
         *               solutionTags: false,
         *           },
         *       },
         *   });
         */
        initialVisibility?: boolean;

        /**
         * When false, the row click will be deactivated for this column.
         * For the 'action' column type, this option is by default false.
         */
        enableOnRowClick?: boolean;

        /**
         * When true, the cell function will not be wrapped in a TableCell.
         * This is useful when you want to render a custom cell component.
         *
         *
         * @example
         *   disableTableCell: true,
         *   cell: ({TableCellProps}) => (
         *     <TableCell {...TableCellProps}>
         *       <CustomCellComponent />
         *     </TableCell>
         *   )
         */
        disableTableCell?: boolean;

        /**
         * Defines the active filter of the column.
         */
        filterConfig?: FilterConfig<TData, TValue, TShareContext>;

        /**
         * Overrides the default render type of the column. This is useful when you want to render a column as a specific type but apply different filters.
         */
        renderType?: RenderType | ((item: TData) => RenderType);

        /**
         * FOR INTERNAL USE ONLY
         *
         * Defines the type of content returned by the access function. This influences the sorting, filtering, and rendering of the cell.
         */
        dataType: 'text' | 'number' | 'enum' | 'array' | 'date' | 'monetaryValue' | 'generic';
    }

    interface TableMeta<TData, TSharedContext = unknown> {
        /**
         * Shared context that is accessible throughout the table. This can be used to pass down common data or functions that are needed by multiple components within the table.
         *
         * @example
         *     sharedContext: {openDeleteDialog},
         *
         *     cell: ({sharedContext}) => (
         *         <SecondaryIconButton
         *             onClick={() => {
         *                 sharedContext.openDeleteDialog(data.id);
         *             }}
         *         />
         *     )
         */
        sharedContext: TSharedContext;

        /**
         * Used to show the total count of rows in the table. This is useful when the table is paginated and the total count is not known upfront.
         */
        totalCount?: number;

        /**
         * Callback function that is triggered when a row is clicked.
         *
         * @example
         *   onRowClick: (row, table) => console.log('Row clicked:', row)
         */
        onRowClick?: (row: Row<TData>, table: Table<TData>) => void;

        /**
         * Returns the URL for a given row. When provided, clicking the row will navigate to this URL.
         * This supports native browser behavior like ctrl+click for new tab, etc.
         *
         * Takes precedence over onRowClick if both are provided.
         *
         * @example
         *   getRowRoute: (row) => `/users/${row.original.id}`
         */
        getRowRoute?: (row: Row<TData>) => string | null;

        /**
         * Enables row selection. Can be a boolean to enable/disable selection or an object to specify the column configuration for selection.
         *
         * const tableMetaWithColumn = {
         *   enableSelection: {
         *     enabled: true,
         *     getRowId: (row) => row.id,
         *     column: someColumnDefinition
         *   }
         * };
         */
        enableSelection?: {
            enabled: boolean;
            /**
             * Unique key to identify rows. This key is used in combination with the `enablePersistentRowSelection` options to persist the row selection across sessions.
             */
            getRowId: TableOptions<TData>['getRowId'];
            column?: ColumnDef<TData, any>;
            /**
             * Callback function that is called when the row selection changes.
             *
             * Please use a stable reference for the callback function.
             */
            onRowSelectionChange?: (value: RowSelectionState) => void;
        };

        /**
         * Unique key to identify columns. This key is used in combination with the `enablePersistentColumnOrder` and `enablePersistentColumnVisibility` options to persist the column order and visibility across sessions.
         *
         * @example
         * const tableMeta = {
         *   columnsKey: 'userTableColumns'
         * };
         */
        columnsKey: string;

        /**
         * Returns whether the table is in a loading state.
         *
         * INTERAL USE ONLY
         */
        getIsLoading: () => boolean;

        /**
         * Callback function that is triggered when the table fetches the next page. This function should fetch the next page of data and update the table data.
         */
        fetchNextPage?: () => void;

        /**
         * Indicates whether the table is fetching the next page. When true, the table displays a loading indicator at the bottom.
         */
        isFetchingNextPage: boolean;
        /**
         * Enables the ability to hide columns. When true, users can hide or show columns.
         *
         * @default false
         */
        enableColumnHiding: boolean;

        /**
         * Enables the ability to reorder columns. When true, users can drag and drop columns to reorder them.
         *
         * @default false
         */
        enableColumnOrdering: boolean;

        /**
         * Enables column filters that are stored in the URL for the current session. These filters are not persistent across sessions.
         *
         * When set to 'session', the filters are stored in the session storage.
         *
         * @default false
         */
        enablePersistentColumnFilters: boolean;

        /**
         * Enable persistent column filters and sorting that are stored in the session storage.
         * Be aware that reloading the page will reset the filters and sorting.
         *
         * You set the storage to "local" - I hope you know what you are doing because this can fuck up the app.
         */
        enableSaveAsDefault:
            | false
            | string
            | {
                  storage: 'local' | 'session';
                  key: string;
              };

        /**
         * Enables persistent column order across sessions. When true, the column order is saved and restored across sessions.
         *
         * @default true
         */
        enablePersistentColumnOrder: boolean;

        /**
         * Enables persistent column visibility across sessions. When true, the visibility state of columns is saved and restored across sessions.
         *
         * @default true
         */
        enablePersistentColumnVisibility: boolean;

        /**
         * Enables persistent row selection in the session storage.
         *
         * @default false
         */
        enablePersistentRowSelection: false | string;

        /**
         * Enables persistent row expandion in the session storage.
         *
         * @deprecated Don't use this option, I think it's broken.
         */
        enablePersistentExpanded: false | string;
        /**
         * Enables the global filter that is stored in the URL for the current session. The global filter state is not persistent across sessions.
         *
         * @default false
         */
        enablePersistentGlobalFilter: boolean;

        /**
         * Enables the scroll position to be stored in the URL for the current session. The scroll position is not persistent across sessions.
         *
         * @default false
         */
        enablePersistentScrollPosition: boolean;

        /**
         * Enables the export to Excel functionality. When true, users can export the table data to an Excel file.
         *
         * @default false
         */
        enableExcelExport: boolean;

        /**
         * Internal use only. Used to store the initial state of the table.
         */
        defaultInitialState: TableOptions<TData>['initialState'];

        /**
         * Internal use only. Used to store the session filters and sorting.
         * Used in combination wiht `enableSaveAsDefault`.
         */
        sessionFiltersAndSorting: {
            persistentFilters: ColumnFiltersState;
            setPersistentFilters: (
                newState: ColumnFiltersState | ((value: ColumnFiltersState) => ColumnFiltersState),
            ) => void;
            persistentSorting: SortingState;
            setPersistentSorting: (newState: SortingState | ((value: SortingState) => SortingState)) => void;
        } | null;
    }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export type ColumnDefWithSharedContext<TData extends RowData, TValue = unknown, TSharedContext = unknown> = ColumnDef<
    RowData,
    TValue
>;

export type TableSize = 'small' | 'medium' | 'large';

export type UseTanStackTableStateProps<TData> = Pick<TableMeta<TData, unknown>, 'columnsKey'> &
    Pick<
        UseTanStackTableProps<TData>,
        | 'initialState'
        | 'enableSelection'
        | 'enableColumnHiding'
        | 'enableColumnOrdering'
        | 'enablePersistentColumnFilters'
        | 'enablePersistentColumnOrder'
        | 'enablePersistentColumnVisibility'
        | 'enablePersistentGlobalFilter'
        | 'enablePersistentRowSelection'
        | 'enableSaveAsDefault'
        | 'enablePersistentExpanded'
    >;

export type TanStackTableState<TData> = {
    initialState: TableOptions<TData>['initialState'];

    columnFilters: ColumnFiltersState;
    setColumnFilters: TableOptions<TData>['onColumnFiltersChange'];

    columnOrder: ColumnOrderState;
    setColumnOrder: TableOptions<TData>['onColumnOrderChange'];

    columnPinning: ColumnPinningState;
    setColumnPinning: TableOptions<TData>['onColumnPinningChange'];

    columnVisibility: VisibilityState;
    setColumnVisibility: TableOptions<TData>['onColumnVisibilityChange'];

    globalFilter: string;
    setGlobalFilter: TableOptions<TData>['onGlobalFilterChange'];

    rowSelection: RowSelectionState;
    setRowSelection: TableOptions<TData>['onRowSelectionChange'];

    sorting: SortingState;
    setSorting: TableOptions<TData>['onSortingChange'];

    expanded: ExpandedState;
    setExpanded: TableOptions<TData>['onExpandedChange'];

    meta: Pick<
        TableMeta<TData>,
        | 'defaultInitialState'
        | 'columnsKey'
        | 'enableColumnHiding'
        | 'enableColumnOrdering'
        | 'enablePersistentColumnFilters'
        | 'enablePersistentColumnOrder'
        | 'enablePersistentColumnVisibility'
        | 'enablePersistentGlobalFilter'
        | 'enablePersistentRowSelection'
        | 'enableSaveAsDefault'
        | 'sessionFiltersAndSorting'
        | 'enablePersistentExpanded'
    >;
};

/**
 * Props for configuring a TanStack table.
 */
export type UseTanStackTableProps<TData, TSharedContext = unknown> = {
    /**
     * The data for the table to display. This array should match the type you provided to `createColumnHelper`.
     * Columns can access this data via string/index or a functional accessor. When the `data` option changes reference,
     * the table will reprocess the data.
     *
     * When the data is undefined the table will display an loading state.
     */
    data: TableOptions<TData>['data'] | undefined;

    /**
     * The array of column definitions to use for the table. You can use the `createColumnHelper` function to create column definitions easily.

     * @example
     *
     * const columnHelper = createColumnHelper<TData, TSharedContext>();
     *
     * const tableProps = {
     *   columns: [
     *     columnHelper.accessor('name', {
     *       header: 'Name',
     *       cell: info => info.getValue()
     *     }),
     *     columnHelper.accessor('age', {
     *       header: 'Age',
     *       cell: info => info.getValue()
     *     })
     *   ]
     * };
     */
    columns: ColumnDefWithSharedContext<TData, any, TSharedContext>[];

    /**
     * When true, the table will display a loading state.
     *
     * When undefined it will be inferred from the `data` option.
     */
    isLoading?: boolean;

    /**
     * Use this option to optionally pass initial state to the table. This state will be used when resetting various
     * table states either automatically by the table (e.g., `options.autoResetPageIndex`) or via functions like
     * `table.resetRowSelection()`.
     *
     * Table state will not be reset when this object changes, which also means that the initial state object does not
     * need to be stable.
     *
     * Note that there are also `intialVisibility` and `initialPinning` options for columns that can be set directly in the column
     *
     * @example
     * const tableProps = {
     *   initialState: {
     *     sorting: [{ id: 'name', desc: false }]
     *   }
     * };
     */
    initialState?: TableOptions<TData>['initialState'];

    /**
     * - Enables/disables row selection for all rows in the table OR
     * - A function that given a row, returns whether to enable/disable row selection for that row
     */
    enableRowSelection?: TableOptions<TData>['enableRowSelection'];

    /**
     * This optional function is used to access the sub rows for any given row. If you are using nested rows,
     * you will need to use this function to return the sub rows object (or undefined) from the row.
     */
    getSubRows?: TableOptions<TData>['getSubRows'];

    /**
     * When true, the table will filter the data from the leaf rows.
     */
    filterFromLeafRows?: TableOptions<TData>['filterFromLeafRows'];
} & Partial<Omit<TableMeta<TData, TSharedContext>, 'fetchNextPage' | 'isFetchingNextPage' | 'getIsLoading'>>;

export type UseTanStackTablePaginationProps<TData, TSharedContext = unknown> = {
    tableState: TanStackTableState<TData>;
} & Omit<
    UseTanStackTableProps<TData, TSharedContext>,
    | 'initialState'
    | 'defaultInitialState'
    | 'columnsKey'
    | 'enableColumnHiding'
    | 'enableColumnOrdering'
    | 'enablePersistentColumnFilters'
    | 'enablePersistentColumnOrder'
    | 'enablePersistentColumnVisibility'
    | 'enablePersistentGlobalFilter'
    | 'enablePersistentRowSelection'
> &
    Required<Pick<TableMeta<TData, TSharedContext>, 'fetchNextPage' | 'isFetchingNextPage'>>;

export type MenuBarVisibility =
    | {
          globalSearch?: boolean;
          quickFilters?: boolean;
          resultCount?: boolean;
          controlButtons?: boolean;
          exportExcelButton?: boolean;
          actionButton?: boolean;
      }
    | boolean;

type HideMenuBarFunction<TData = unknown, TSharedContext = unknown> = (arg: {
    table: Table<TData>;
    sharedContext: TSharedContext;
}) => MenuBarVisibility | boolean;

export type TanStackTableProps<TData, TSharedContext> = {
    table: Table<TData>;
    size?: TableSize;
    /**
     * The MenuBarTitle component is displayed on the left side of the MenuBar.
     */
    MenuBarTitle?: TanStackTableContext<TData, TSharedContext>['MenuBarTitle'];
    ActionButton?: TanStackTableContext<TData, TSharedContext>['ActionButton'];
    EmptyPlaceholder?: TanStackTableContext<TData, TSharedContext>['EmptyPlaceholder'];
    TableRow?: TanStackTableContext<TData, TSharedContext>['TableRow'];
    overrides?: Partial<TanStackTableOverrides<TData, TSharedContext>>;
    /**
     * Controls the visibility of menu bar elements in the table.
     *
     * Can be an object specifying visibility of specific elements:
     * - `globalSearch` (boolean): Show/hide global search input.
     * - `resultCount` (boolean): Show/hide result count text.
     * - `controlButtons` (boolean): Show/hide control buttons.
     *
     * Alternatively, a boolean to uniformly show/hide all elements, or a function that returns the visibility settings.
     */
    enableMenuBar?: HideMenuBarFunction<TData, TSharedContext> | MenuBarVisibility;
};

export type TanStackTableOverrides<TData, TSharedContext> = {
    FallbackComponent?: React.ComponentType<Parameters<FallbackRender>[0] & { onReset: () => void }>;
    Scroller: TableComponents<unknown, TanStackTableContext<TData, TSharedContext>>['Scroller'];
    Table: TableComponents<unknown, TanStackTableContext<TData, TSharedContext>>['Table'];
    OuterTableRow: TableComponents<unknown, TanStackTableContext<TData, TSharedContext>>['TableRow'];
    OuterEmptyPlaceholder: TableComponents<unknown, TanStackTableContext<TData, TSharedContext>>['EmptyPlaceholder'];
};

export type TanStackTableContext<TData = unknown, TSharedContext = unknown> = {
    table: Table<TData>;
    size: TableSize;
    isLoading: boolean;
    TableRow?: React.ComponentType<TableRowProps>;
    MenuBarTitle?: React.ComponentType<{ table: Table<TData>; sharedContext: TSharedContext }>;
    ActionButton?: React.ComponentType<{ table: Table<TData>; sharedContext: TSharedContext }>;
    EmptyPlaceholder?: React.ComponentType<{ table: Table<TData>; sharedContext: TSharedContext }>;
};

export enum SupportedFilterFn {
    includesString = 'includesString',
    inNumberRange = 'inNumberRange',
    inMonetaryValueRange = 'inMonetaryValueRange',
    inDateRange = 'inDateRange',
    equalsAny = 'equalsAny',
    equalsNone = 'equalsNone',
    arrIncludesSome = 'arrIncludesSome',
    arrIncludesAll = 'arrIncludesAll',
    arrExcludesSome = 'arrExcludesSome',
}
export type NumberRange = [number | null, number | null];
export type DateRange = [string | null, string | null];

export type FilterValue<TValue> =
    | { filterFn: SupportedFilterFn.includesString; value: string }
    | { filterFn: SupportedFilterFn.inNumberRange; value: NumberRange }
    | { filterFn: SupportedFilterFn.inMonetaryValueRange; value: NumberRange }
    | { filterFn: SupportedFilterFn.inDateRange; value: DateRange }
    | { filterFn: SupportedFilterFn.equalsAny; value: TValue }
    | { filterFn: SupportedFilterFn.equalsNone; value: TValue }
    | { filterFn: SupportedFilterFn.arrIncludesSome; value: TValue }
    | { filterFn: SupportedFilterFn.arrIncludesAll; value: TValue }
    | { filterFn: SupportedFilterFn.arrExcludesSome; value: TValue };

export type QuickFilter<TValue> = {
    showCount?: boolean;
    label: () => string;
    value: FilterValue<TValue>;
    /**
     * When true, this filter will replace all existing filters of this column.
     */
    replaceExistingFilters?: boolean;

    /**
     * Customize the CheckableChip component.
     */
    overrides?: CheckableChipProps['overrides'];
};

export type FilterConfig<TData = any, TValue = any, TShareContext = any> =
    | TextFilterConfig
    | NumberFilterConfig
    | MonetaryValueFilterConfig
    | DateFilterConfig
    | EnumFilterConfig<TData, TValue, TShareContext>
    | ArraryFilterConfig<TData, TValue, TShareContext>;

export type TextFilterConfig = {
    dataType: 'text';
    defaultFilterFn: SupportedFilterFn.includesString;
};
export type NumberFilterConfig = {
    dataType: 'number';
    defaultFilterFn: SupportedFilterFn.inNumberRange;
};
export type MonetaryValueFilterConfig = {
    dataType: 'monetaryValue';
    defaultFilterFn: SupportedFilterFn.inMonetaryValueRange;
    /**
     * Defines the format for monetary values used in placeholder text.
     */
    formatAs?: FormattingType;
};
export type DateFilterConfig = {
    dataType: 'date';
    defaultFilterFn: SupportedFilterFn.inDateRange;
};
export type EnumFilterConfig<TData = any, TValue = any, TShareContext = any> = {
    dataType: 'enum';
    defaultFilterFn: SupportedFilterFn.equalsAny;
    /**
     * By default the options are the unique values of the column also called `facetedValues`.
     *
     * @example
     * const columnMeta = {
     *   equalsAny: {
     *     options: ({ facetedValues }) => [OfferOriginEnum.Manual, ...facetedValues],
     *     getOptionLabel: (origin) => formatOfferOrigin({ origin }),
     *   }
     * };
     */
    options?:
        | Array<TValue>
        | ((props: {
              column: Column<TData, TValue>;
              facetedValues: Array<TValue>;
              sharedContext: TShareContext;
          }) => Array<TValue>);

    /**
     * Gets the label for a given option.
     *
     * @example
     *    getOptionLabel: (origin) => formatOfferOrigin({ origin })
     */
    getOptionLabel: (option: TValue) => string;

    /**
     * Renders a JSX element for a given option.
     *
     * @example
     *   renderOption: (origin) => (
     *    <Chip
     *     color={extractOfferOriginColor({ origin })}
     *     label={formatOfferOrigin({ origin })}
     *    />
     *   )
     */
    renderOption?: (option: TValue) => JSX.Element;

    /**
     * Gets the count of the option, which is displayed in the filter.
     *
     * Useful in combination with pagination.
     */
    getOptionCount?: (option: TValue, sharedContext: TShareContext) => number | undefined;

    /**
     * Gets the key of the option, which is used to show unique options in the filter.
     */
    getOptionKey?: (option: TValue) => string | number | boolean;
};
export type ArraryFilterConfig<TData = any, TValue = any, TShareContext = any> = {
    dataType: 'array';
    defaultFilterFn: SupportedFilterFn.arrIncludesSome;
    /**
     * By default the options are the unique values of the column also called `facetedValues`.
     *
     * @example
     *     options: ({ facetedValues }) =>
     *       [
     *         SolutionTag.Inventory,
     *         SolutionTag.SupplierPreferred,
     *         SolutionTag.SupplierApproved,
     *         ...SolutionErrorTags,
     *         ...SolutionWarningTags,
     *         ...SolutionNotificationTags,
     *       ].filter((tag) => facetedValues.includes(tag)),
     */
    options?:
        | TValue
        | ((props: { column: Column<TData, TValue>; facetedValues: TValue; sharedContext: TShareContext }) => TValue);

    /**
     * Gets the label for a given option.
     *
     * @example
     *     getOptionLabel: (tag) => transEnum(tag, solutionTagTranslations)
     */
    getOptionLabel: (option: TValue extends Array<infer U> ? U : never) => string;

    /**
     * Renders a JSX element for a given option.
     * @example
     *     renderOption: (tag) => (
     *       <Chip
     *         color={extractSolutionTagColor({ solutionTag: tag })}
     *         label={transEnum(tag, solutionTagTranslations)}
     *       />
     *     )
     */
    renderOption?: (option: TValue extends Array<infer U> ? U : never) => JSX.Element;

    /**
     * Gets the count of the option, which is displayed in the filter.
     *
     * Useful in combination with pagination.
     */
    getOptionCount?: (
        option: TValue extends Array<infer U> ? U : never,
        sharedContext: TShareContext,
    ) => number | undefined;

    /**
     * Gets the key of the option, which is used to show unique options in the filter.
     */
    getOptionKey?: (option: TValue extends Array<infer U> ? U : never) => string | number | boolean;
};

export const FilterFnByDataType: Record<ColumnMeta<unknown, unknown, unknown>['dataType'], SupportedFilterFn[]> = {
    text: [SupportedFilterFn.includesString],
    number: [SupportedFilterFn.inNumberRange],
    monetaryValue: [SupportedFilterFn.inMonetaryValueRange],
    date: [SupportedFilterFn.inDateRange],
    enum: [SupportedFilterFn.equalsAny, SupportedFilterFn.equalsNone],
    array: [SupportedFilterFn.arrIncludesSome, SupportedFilterFn.arrIncludesAll, SupportedFilterFn.arrExcludesSome],
    generic: [],
};

type Prettify<T> = {
    [K in keyof T]: T[K];
} & {};

export function getFilterConfig<
    TData,
    TValue,
    TShareContext,
    K extends FilterConfig<TData, TValue, TShareContext>['dataType'],
>(
    column: Column<TData, TValue>,
    dataType: K,
): Prettify<FilterConfig<TData, TValue, TShareContext> & { dataType: K }> | null {
    if (!column.getCanFilter() || column.columnDef.meta?.filterConfig?.dataType !== dataType) {
        return null;
    }

    return column.columnDef.meta.filterConfig as FilterConfig<TData, TValue, TShareContext> & { dataType: K };
}

export const FilterFnOptionTranslations: Record<SupportedFilterFn, MessageDescriptor> = {
    [SupportedFilterFn.includesString]: defineMessage({ message: 'contains' }),
    [SupportedFilterFn.inNumberRange]: defineMessage({ message: 'is between' }),
    [SupportedFilterFn.inMonetaryValueRange]: defineMessage({ message: 'is between' }),
    [SupportedFilterFn.inDateRange]: defineMessage({ message: 'is between' }),
    [SupportedFilterFn.equalsAny]: defineMessage({ message: 'is' }),
    [SupportedFilterFn.equalsNone]: defineMessage({ message: 'is not' }),
    [SupportedFilterFn.arrIncludesSome]: defineMessage({ message: 'contains' }),
    [SupportedFilterFn.arrIncludesAll]: defineMessage({ message: 'contains all' }),
    [SupportedFilterFn.arrExcludesSome]: defineMessage({ message: 'does not contain' }),
};
