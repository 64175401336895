import * as z from 'zod';
import { ApiIntegrationStatusRuntype } from './3rdPartyBackendTypes';

export interface AlElektronikCredentialsDTO extends z.infer<typeof AlElektronikCredentialsDTORuntype> {}
export const AlElektronikCredentialsDTORuntype = z.object({
    api_key: z.string(),
});

export interface AlElektronikCredentialsInputDTO extends z.infer<typeof AlElektronikCredentialsInputDTORuntype> {}
export const AlElektronikCredentialsInputDTORuntype = z.object({
    api_key: z.string().optional(),
});

export interface AlElektronikResponseBodyDTO extends z.infer<typeof AlElektronikResponseBodyDTORuntype> {}
export const AlElektronikResponseBodyDTORuntype = z.object({
    data: AlElektronikCredentialsDTORuntype.nullable(),
    status: ApiIntegrationStatusRuntype,
});
