import { range } from '@luminovo/commons';
import { ColumnMap, ExcelLineType, RowRecord } from '@luminovo/http-client';
import { typeSafeObjectKeys } from '../../utils/typingUtils';
import { Column } from '../DesignItemDetails/components/AutocompleteColumnTags/types';
import { parseRawOriginalLine } from '../DesignItemDetails/components/parseRawOriginalLine';

type Props = {
    rawHeaderRow: RowRecord | undefined;
    excelRows?: ExcelLineType;
    columnMap?: ColumnMap | null;
};

export function parseColumns({ rawHeaderRow, excelRows = [], columnMap = null }: Props): Column[] {
    const numberOfColumns = (parseRawOriginalLine(excelRows[0]?.raw_original_line) ?? []).length;
    const columnNames = getColumnNames(rawHeaderRow, columnMap);
    const columns: Column[] = [];
    const isHeaderRowEmpty = Object.keys(rawHeaderRow ?? {}).length === 0;
    for (let i = 0; i < Math.max(numberOfColumns, columnNames.length); i++) {
        // when we don't have any items in rawHeaderRow, we should default to an empty label
        const label = !isHeaderRowEmpty ? (columnNames[i] ?? '') : '';
        columns.push({ id: String(i), label });
    }

    return columns;
}

function getColumnNames(rawHeaderRow: RowRecord | undefined, columnMap: ColumnMap | null): string[] {
    const columnNamesFromRawHeader = parseRawOriginalLine(rawHeaderRow);
    if (columnNamesFromRawHeader && columnNamesFromRawHeader.length > 0) return columnNamesFromRawHeader;
    if (!columnMap) return [];
    return typeSafeObjectKeys(columnMap);
}

export function generateColumnsFromColumnMap(columnMap: ColumnMap): Column[] {
    const mappingOfIndexToColumnName: Record<number, string> = {};
    const result: Column[] = [];

    typeSafeObjectKeys(columnMap).forEach((key) => {
        const columns = columnMap[key] ?? [];
        columns.forEach((column) => {
            mappingOfIndexToColumnName[column] = key;
        });
    });
    // we need to know the largest column index, so that we can tell
    // how large the column-array should be.
    const lastIndexInColumnMapList = getFurthestPointOnColumnMap(columnMap);
    for (const i of range(0, lastIndexInColumnMapList + 1)) {
        const label = mappingOfIndexToColumnName[i] ?? '';
        result.push({ id: String(i), label });
    }

    return [{ id: 'lineNumberColumn', label: '' }].concat(result);
}

function getFurthestPointOnColumnMap(columnMap: ColumnMap): number {
    let furthestPoint = 0;
    typeSafeObjectKeys(columnMap).forEach((key) => {
        const columns = columnMap[key] ?? [];
        furthestPoint = Math.max(furthestPoint, ...columns);
    });
    return furthestPoint;
}
