import * as z from 'zod';
import { ApiIntegrationStatusRuntype } from './3rdPartyBackendTypes';

export interface RochesterCredentialsDTO extends z.infer<typeof RochesterCredentialsDTORuntype> {}
export const RochesterCredentialsDTORuntype = z.object({
    enabled: z.boolean(),
});

export interface RochesterCredentialsInputDTO extends z.infer<typeof RochesterCredentialsInputDTORuntype> {}
export const RochesterCredentialsInputDTORuntype = z.object({
    enabled: z.boolean().optional(),
});

export interface RochesterResponseBodyDTO extends z.infer<typeof RochesterResponseBodyDTORuntype> {}
export const RochesterResponseBodyDTORuntype = z.object({
    data: RochesterCredentialsDTORuntype.nullable(),
    status: ApiIntegrationStatusRuntype,
});
