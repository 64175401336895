import { t } from '@lingui/macro';
import { Dialog, DialogTitle } from '@luminovo/design-system';
import { DialogContent } from '@mui/material';
import { useDialogContext } from '../../../../components/contexts/ModalContext';
import { EditManufacturingScenarioTemplatesForm } from './EditManufacturingScenarioTemplateForm';

export const useEditManufacturingScenarioTemplatesDialog = ({
    manufacturingScenarioId,
}: {
    manufacturingScenarioId: string;
}) => {
    const { setDialog, closeDialog } = useDialogContext();

    const dialog = (
        <Dialog open={true} fullWidth fullScreen maxWidth="lg" style={{ margin: '24px' }}>
            <DialogTitle title={t`Append templates`} onClose={closeDialog} />
            <DialogContent>
                <EditManufacturingScenarioTemplatesForm
                    manufacturingScenarioId={manufacturingScenarioId}
                    onSuccess={() => closeDialog()}
                />
            </DialogContent>
        </Dialog>
    );

    return {
        openDialog: () => setDialog(dialog),
    };
};
