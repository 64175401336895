import { analytics } from '@/utils/analytics';
import { useHasCustomers } from '@/utils/featureFlags';
import { t, Trans } from '@lingui/macro';
import { isAuthorized, Permission, usePermissions } from '@luminovo/auth';
import { isPresent, isProductionEnvironment } from '@luminovo/commons';
import {
    colorSystem,
    CommandBar,
    CommandBarInput,
    Flexbox,
    NavbarHomeIcon,
    ReleaseTag,
    Tag,
    Text,
} from '@luminovo/design-system';
import { CustomerDTO, RfqDTO, UserType } from '@luminovo/http-client';
import { RfqLabel } from '@luminovo/manufacturing-core';
import { Box, styled, Typography } from '@mui/material';
import * as React from 'react';
import { Link, LinkProps, useLocation } from 'react-router-dom';
import {
    CALCULATION_PERMISSIONS,
    isAssemblyPortalEnabled,
    isCustomerRfQDisabled,
    useManufacturingModuleVariant,
} from '../../featureFlags';
import { Protected } from '../../permissions/Protected';
import { useCustomer } from '../../resources/customer/customerHandler';
import { usePageParams } from '../../resources/hooks';
import { useCalculationWithoutManufacturingEnabled } from '../../resources/organizationSettings/calculationWithoutManufacturingHandler';
import { useRfQ } from '../../resources/rfq/rfqHandler';
import { id } from '../../utils/ids';
import { route } from '../../utils/routes';
import { useCurrentUserDetailsContext, useUserType } from '../contexts/CurrentUserDetailsContext';
import { ShareRfqPopover } from '../RfQ/components/ShareRfqPopover';
import { StyledRfqStatusBox } from '../StyledComponents';
import { Avatar } from './Avatar';
import { useCommandBarTestingTools } from './commandBarHandlers';
import { defaultCommandBarContents, rfqSidebarContents } from './commandBarItems';
import { GlobalUpdateOffersButton } from './GlobalUpdateOffersButton';
import { useCustomFavicon } from './hooks/useCustomFavicon';
import { useCustomTitle } from './hooks/useCustomTitle';
import { NavbarIconDark, NavbarIconLight } from './NavbarIcon';

const LazyNotificationsButton = React.lazy(async () => {
    const { NotificationsButton } = await import('./NotificationsButton');
    return { default: NotificationsButton };
});

const navbarHeight = '48px';

const Filler = (): JSX.Element => <Box flexGrow={1} />;

const NavbarContainer: React.FunctionComponent = (props) => {
    return <>{props.children}</>;
};

const NavDark = styled('nav')({
    padding: '0 16px',
    maxWidth: '100%',
    display: 'flex',
    height: navbarHeight,
    minHeight: navbarHeight,
    flexDirection: 'row',
    boxSizing: 'border-box',
    background: colorSystem.neutral[9],
    alignItems: 'center',
    '& > a + a': {
        marginRight: 12,
        whiteSpace: 'nowrap',
    },
});

const NavLight = styled(NavDark)({
    background: colorSystem.neutral.white,
    borderBottom: `1px solid ${colorSystem.neutral[2]}`,
});

const StyledLinkDark = styled(Link)({
    color: colorSystem.neutral[6],
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '0 12px',
    textDecoration: 'none',
    '&:hover': {
        color: colorSystem.neutral[1],
        transition: 'all 0.5s ease',
    },
});

const StyledLinkLight = styled(StyledLinkDark)({
    color: colorSystem.neutral[6],
    '&:hover': {
        color: colorSystem.primary[7],
        transition: 'all 0.5s ease',
    },
});

const StyledCurrentLinkDark = styled(StyledLinkDark)({
    color: colorSystem.neutral[1],
    background: colorSystem.neutral[8],
});

const StyledCurrentLinkLight = styled(StyledLinkLight)({
    color: colorSystem.primary[7],
    background: colorSystem.primary[1],
    borderBottom: `2px solid ${colorSystem.primary[7]}`,
});

function isCurrentPage(currentPathname: string, linkHref: string): boolean {
    const linkHrefWithoutQueryParameters = linkHref.split('?')[0];
    return currentPathname.startsWith(linkHrefWithoutQueryParameters);
}

const NavbarLink = React.forwardRef<
    HTMLAnchorElement,
    React.PropsWithChildren<{
        id?: string;
        to: string;
        pathname: string;
        isActive?: boolean;
        overrides?: {
            ComponentLink: React.ComponentType<LinkProps>;
            ComponentLinkCurrent: React.ComponentType<LinkProps>;
        };
    }>
>(({ id, to, isActive, children, pathname, overrides = {} }, ref) => {
    const { ComponentLink = StyledLinkDark, ComponentLinkCurrent = StyledCurrentLinkDark } = overrides;
    const isCurrent: boolean = isActive ?? isCurrentPage(pathname, to);
    if (isCurrent) {
        return (
            <ComponentLinkCurrent ref={ref} id={id} to={to}>
                <Typography variant="h4">{children}</Typography>
            </ComponentLinkCurrent>
        );
    }
    return (
        <ComponentLink ref={ref} id={id} to={to}>
            <Typography variant="h4">{children}</Typography>
        </ComponentLink>
    );
});

export const HomeNavbar = React.memo(function HomeNavbar(): JSX.Element {
    useCustomFavicon();
    useCustomTitle();
    const { hasCustomers } = useHasCustomers();
    const { userType, hasAccessToSourceToContract } = useCurrentUserDetailsContext();
    const manufacturingModuleVariant = useManufacturingModuleVariant();
    const { pathname } = useLocation();
    const { permissions } = usePermissions();
    const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);

    const onOpen = () => {
        analytics.track(id('commandbar/open'));
        setIsSidebarOpen(true);
    };
    const onClose = () => setIsSidebarOpen(false);
    const testingTools = useCommandBarTestingTools();

    const commandbarItems = defaultCommandBarContents({
        hasAccessToSourceToContract,
        userType,
        permissions,
        testingTools,
    });
    return (
        <NavbarContainer>
            <CommandBar contents={commandbarItems} isOpen={isSidebarOpen} onClose={onClose} onOpen={onOpen} />

            <NavDark data-testid="NavDark">
                <Link to="/">
                    <NavbarIconLight />
                </Link>

                {(!isCustomerRfQDisabled() || userType !== UserType.Customer) && (
                    <NavbarLink to="/rfqs" pathname={pathname}>
                        <Trans>RfQs</Trans>
                    </NavbarLink>
                )}

                {/*TODO: remove this check when we have a more general solution to limit access to the assemblies page*/}

                {/* eslint-disable-next-line spellcheck/spell-checker */}
                {(isAssemblyPortalEnabled() || userType !== UserType.Customer) && (
                    <NavbarLink to="/assemblies" pathname={pathname}>
                        <Trans>Assemblies</Trans>
                    </NavbarLink>
                )}

                <Protected
                    requiredPermissions={() => {
                        // TODO(fhur) as a temporary measure, we have given customer users access to the part
                        // library (otherwise they don't have access to stock information in the BOM).
                        // to prevent them from using the part library, we've added this check
                        // here.
                        return userType === UserType.Internal;
                    }}
                >
                    <NavbarLink
                        to={route('/parts/off-the-shelf-part-search')}
                        pathname={pathname}
                        isActive={pathname.startsWith(route('/parts'))}
                    >
                        <Trans>Parts</Trans>
                    </NavbarLink>
                </Protected>

                <Protected requiredPermissions={['view:activity']}>
                    <NavbarLink
                        to={route('/manufacturing/activity')}
                        pathname={pathname}
                        isActive={pathname.startsWith('/manufacturing') || pathname.startsWith('/calculation')}
                        id={
                            manufacturingModuleVariant === 'lite'
                                ? id('home_navbar/link_manufacturing_lite')
                                : undefined
                        }
                    >
                        <CostingTemplateLink />
                    </NavbarLink>
                </Protected>
                <Protected requiredPermissions={['edit:customer']}>
                    <NavbarLink to={route('/customers')} pathname={pathname}>
                        {hasCustomers ? t`Customers` : t`Companies`}
                    </NavbarLink>
                </Protected>
                <Protected requiredPermissions={['view:supplier']}>
                    <NavbarLink to={route('/manufacturer')} pathname={pathname}>
                        <Trans>Manufacturers</Trans>
                    </NavbarLink>
                </Protected>
                <Protected requiredPermissions={['view:supplier']}>
                    <NavbarLink to={route('/suppliers')} pathname={pathname}>
                        <Trans>Suppliers</Trans>
                    </NavbarLink>
                </Protected>
                <Protected requiredPermissions={['view:purchase_order']}>
                    <NavbarLink to={route('/purchase-orders')} pathname={pathname}>
                        <Trans>Purchasing</Trans>
                    </NavbarLink>
                </Protected>
                <Protected
                    requiredPermissions={() =>
                        (!isProductionEnvironment() || hasAccessToSourceToContract) && userType === UserType.Internal
                    }
                >
                    <NavbarLink to={route('/negotiations')} pathname={pathname}>
                        <Trans>Negotiations</Trans>
                    </NavbarLink>
                </Protected>
                <Protected requiredPermissions={() => !isProductionEnvironment() && userType === UserType.Internal}>
                    <NavbarLink to={route('/supplier-portal')} pathname={pathname}>
                        <Trans>Supplier portal</Trans>{' '}
                        <span
                            style={{
                                marginLeft: 4,
                                background: colorSystem.primary[7],
                                color: colorSystem.neutral.white,
                                padding: ' 2px 4px',
                                borderRadius: '4px',
                            }}
                        >
                            dev
                        </span>
                    </NavbarLink>
                </Protected>
                {<Filler />}
                <Protected requiredPermissions={() => commandbarItems.length > 0}>
                    <CommandBarInput isOpen={isSidebarOpen} onOpen={onOpen} onClose={onClose} />
                </Protected>
                <React.Suspense fallback={null}>
                    <LazyNotificationsButton variant={'dark'} />
                </React.Suspense>
                <Avatar variant={'dark'} />
            </NavDark>
        </NavbarContainer>
    );
});

const DotSeparator = styled(Box)({
    background: colorSystem.neutral[5],
    width: 4,
    height: 4,
    marginLeft: 4,
    marginRight: 4,
    borderRadius: '100%',
});

function RfqSection({ rfq, customer }: { rfq: RfqDTO; customer: CustomerDTO }): JSX.Element {
    return (
        <>
            <Protected requiredPermissions={['view:rfq:details']}>
                <>
                    <Text variant={'body'}>{customer.name}</Text>
                    <DotSeparator />
                </>
            </Protected>

            <RfqLabel rfqName={rfq.name} emsInternalNumber={rfq.ems_internal_number ?? undefined} />

            <Flexbox gap={8} marginLeft={1}>
                <StyledRfqStatusBox rfqStatus={rfq.status} />
                {rfq.is_archived && <StyledRfqIsArchivedBox />}
            </Flexbox>
        </>
    );
}

const StyledRfqIsArchivedBox = (): JSX.Element => {
    return <Tag color="red" label={t`Archived`} />;
};

const CostingTemplateLink = (): JSX.Element => {
    return <Trans>Costing templates</Trans>;
};

const ManufacturingLink = (): JSX.Element => {
    const manufacturingModuleVariant = useManufacturingModuleVariant();

    if (manufacturingModuleVariant === 'full') {
        return <Trans>Manufacturing</Trans>;
    }

    return (
        <Flexbox flexDirection="row" gap={6}>
            <Trans>Manufacturing</Trans>
            <ReleaseTag color="primary" label="Lite" />
        </Flexbox>
    );
};

export const RfqNavbar = React.memo((): JSX.Element => {
    const overrides = { ComponentLink: StyledLinkLight, ComponentLinkCurrent: StyledCurrentLinkLight };

    useCustomTitle();
    useCustomFavicon();

    const { rfqId } = usePageParams<'/rfqs/:rfqId'>();
    const { pathname } = useLocation();
    const { data: rfq } = useRfQ(rfqId);
    const { data: customer } = useCustomer(rfq?.customer);
    const { hasAccessToSourceToContract } = useCurrentUserDetailsContext();
    const { data: isCalculationWithoutManufacturing } = useCalculationWithoutManufacturingEnabled();
    const manufacturingModuleVariant = useManufacturingModuleVariant();
    const { permissions } = usePermissions();
    const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);
    const onOpen = () => setIsSidebarOpen(true);
    const onClose = () => setIsSidebarOpen(false);

    const userType = useUserType();

    const checkPermissionsAndCalculationWithoutManufacturing = (requiredPermissions: Permission[]) => {
        return (
            isAuthorized(permissions, requiredPermissions) &&
            isPresent(isCalculationWithoutManufacturing) &&
            !isCalculationWithoutManufacturing.calculation_without_manufacturing_enabled
        );
    };
    const testingTools = useCommandBarTestingTools();

    const commandbarItems = rfqSidebarContents({
        rfqId,
        hasAccessToSourceToContract,
        userType,
        permissions,
        testingTools,
    });

    return (
        <>
            <CommandBar contents={commandbarItems} isOpen={isSidebarOpen} onClose={onClose} onOpen={onOpen} />

            <NavbarContainer>
                <NavLight data-testid="NavLight">
                    <Link to="/" style={{ display: 'flex', flexDirection: 'row' }}>
                        <NavbarHomeIcon style={{ color: colorSystem.neutral[9] }} />
                        <NavbarIconDark />
                    </Link>
                    <NavbarLink
                        id={id('navbar/button_dashboard')}
                        to={route('/rfqs/:rfqId/dashboard', { rfqId })}
                        pathname={pathname}
                        overrides={overrides}
                    >
                        <Trans>Dashboard</Trans>
                    </NavbarLink>
                    <NavbarLink
                        id={id('navbar/button_design')}
                        to={route(`/rfqs/:rfqId/bom`, { rfqId })}
                        pathname={pathname}
                        overrides={overrides}
                    >
                        <Trans>Design</Trans>
                    </NavbarLink>
                    <Protected requiredPermissions={['view:sourcing']}>
                        <NavbarLinkSourcing rfqId={rfqId} pathname={pathname} />
                    </Protected>
                    <Protected
                        /**
                         * TODO: this is a temporary hack, will be fixed by fhur
                         * This is a hack to disable the Manufacturing preview for OEM users.
                         * The hack will is needed because we're demoing the OEM portal to Zollner and others.
                         */
                        requiredPermissions={() =>
                            checkPermissionsAndCalculationWithoutManufacturing([
                                'view:sourcing',
                                'view:manufacturing_scenario',
                            ])
                        }
                    >
                        <NavbarLink
                            to={route('/rfqs/:rfqId/manufacturing', { rfqId }, { forwardIfSingleAssembly: 'true' })}
                            pathname={pathname}
                            overrides={overrides}
                            id={
                                manufacturingModuleVariant === 'full'
                                    ? id('navbar/link_manufacturing')
                                    : id('navbar/link_manufacturing_lite')
                            }
                        >
                            <ManufacturingLink />
                        </NavbarLink>
                    </Protected>
                    <Protected
                        requiredPermissions={CALCULATION_PERMISSIONS}
                        fallback={
                            /**
                             * TODO: this is a temporary hack, will be fixed by fhur
                             * This is a hack to disable the Manufacturing preview for OEM users.
                             * The hack will is needed because we're demoing the OEM portal to Zollner and others.
                             */
                            <Protected
                                requiredPermissions={() => {
                                    return (
                                        userType === UserType.Internal &&
                                        isAuthorized(permissions, ['view:sourcing', 'view:manufacturing_scenario'])
                                    );
                                }}
                            >
                                <NavbarLink
                                    to={route('/rfqs/:rfqId/calculation/teaser', { rfqId })}
                                    pathname={pathname}
                                    overrides={overrides}
                                >
                                    <Trans>Calculation</Trans>
                                </NavbarLink>
                            </Protected>
                        }
                    >
                        <NavbarLink
                            to={route('/rfqs/:rfqId/calculation', { rfqId })}
                            pathname={pathname}
                            overrides={overrides}
                        >
                            <Trans>Calculation</Trans>
                        </NavbarLink>
                    </Protected>
                    <Protected requiredPermissions={['view:quotation']}>
                        <NavbarLink
                            to={route(`/rfqs/:rfqId/quotation`, { rfqId })}
                            pathname={pathname}
                            overrides={overrides}
                        >
                            <Trans>Quotation</Trans>
                        </NavbarLink>
                    </Protected>
                    <Filler />

                    {rfq && customer && <RfqSection rfq={rfq} customer={customer} />}
                    <DotSeparator style={{ marginInline: '12px' }} />
                    {rfq && <ShareRfqPopover rfq={rfq} />}
                    <Protected requiredPermissions={['view:sourcing']}>
                        <GlobalUpdateOffersButton rfqId={rfqId} />
                    </Protected>
                    <React.Suspense fallback={null}>
                        <LazyNotificationsButton variant={'light'} />
                    </React.Suspense>
                    <Avatar variant={'light'} />
                </NavLight>
            </NavbarContainer>
        </>
    );
});

function NavbarLinkSourcing({ rfqId, pathname }: { rfqId: string; pathname: string }) {
    const overrides = { ComponentLink: StyledLinkLight, ComponentLinkCurrent: StyledCurrentLinkLight };

    return (
        <NavbarLink to={route(`/rfqs/:rfqId/sourcing`, { rfqId })} pathname={pathname} overrides={overrides}>
            <Trans>Sourcing</Trans>
        </NavbarLink>
    );
}
