import { ExtractRequestBody } from '../../../resources/http';

export const createCalculationAssemblyDetailsMock = (
    rfqId: string,
): ExtractRequestBody<'POST /calculations/calculation-assembly-details'> => {
    return {
        assembly_id: rfqId,
        notes: '',
        include_excess_material_in_material_cost: false,
    };
};
