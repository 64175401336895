import { t, Trans } from '@lingui/macro';
import { colorSystem, Flexbox, Text } from '@luminovo/design-system';
import { RichTextEditorInput } from '@luminovo/rich-text-editor';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { createVars } from '../createVars';
import { EmailManagerFormState } from '../types';

export const FormItemSubject = ({ index }: { index: number }): JSX.Element => {
    const { control, trigger } = useFormContext<EmailManagerFormState>();

    const templateId = useWatch({ control, name: `supplierEmails.${index}.email.template.id` });

    // We use this function to remove the HTML tags from text
    const stripHtml = (html: string) => {
        const tmp = document.createElement('DIV');
        tmp.innerHTML = html;
        return tmp.textContent || tmp.innerText || '';
    };

    return (
        <>
            <Text variant={'h4'} color={colorSystem.neutral[6]}>
                <Trans>Subject</Trans>
            </Text>
            <Controller
                control={control}
                name={`supplierEmails.${index}.email.subject`}
                rules={{
                    validate: (value) => {
                        const plainText = stripHtml(value);
                        if (plainText.length === 0) {
                            return t`Subject is required`;
                        }
                        return true;
                    },
                }}
                render={({ field, fieldState }) => {
                    return (
                        <Flexbox flexDirection="column" gap={2}>
                            <RichTextEditorInput
                                key={templateId}
                                namespace="quote-requests::subject"
                                vars={createVars()}
                                hideVariables
                                initialHtml={field.value}
                                onChange={(value) => {
                                    field.onChange(value);
                                    trigger(`supplierEmails.${index}.email.subject`);
                                }}
                                autosaveEveryMillis={200}
                            />
                            {fieldState.error && (
                                <Text variant="caption" color={colorSystem.red[7]}>
                                    {fieldState.error.message}
                                </Text>
                            )}
                        </Flexbox>
                    );
                }}
            />
        </>
    );
};
