import * as z from 'zod';
import { endpoint } from '../http/endpoint';
import {
    SupplierContactCreationDTORuntype,
    SupplierContactDTORuntype,
    SupplierContactUpdateDTORuntype,
} from './supplierContactBackendTypes';

export const supplierContactEndpoints = {
    'GET /supplier-contacts': endpoint({
        description: 'Get all supplier contacts',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({ items: z.array(SupplierContactDTORuntype) }),
    }),

    'PATCH /supplier-contacts/upsert': endpoint({
        description: 'Upserts supplier contacts in bulk',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.object({
            items: z.array(SupplierContactCreationDTORuntype),
        }),
        responseBody: z.object({
            items: z.array(
                z.object({
                    description: z.string(),
                    status: z.number(),
                    contact: z.union([SupplierContactDTORuntype, SupplierContactCreationDTORuntype]),
                }),
            ),
        }),
    }),

    'POST /supplier-contacts': endpoint({
        description: 'Insert a new contact for a supplier',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: SupplierContactCreationDTORuntype,
        responseBody: z.object({ data: SupplierContactDTORuntype }),
        invalidates: ['GET /supplier-contacts', 'GET /suppliers/:supplierId/supplier-contacts'],
    }),

    'PATCH /supplier-contacts/:supplierContactId': endpoint({
        description: 'Patch a supplier contact with an id',
        pathParams: z.object({
            supplierContactId: z.string(),
        }),
        queryParams: z.undefined(),
        requestBody: SupplierContactUpdateDTORuntype,
        responseBody: z.object({ data: SupplierContactDTORuntype }),
        invalidates: ['GET /supplier-contacts', 'GET /suppliers/:supplierId/supplier-contacts'],
    }),

    'DELETE /supplier-contacts/:supplierContactId': endpoint({
        description: 'Delete supplier contact with an id',
        pathParams: z.object({
            supplierContactId: z.string(),
        }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.unknown(),
        invalidates: ['GET /supplier-contacts', 'GET /suppliers/:supplierId/supplier-contacts'],
    }),

    'POST /supplier-contacts/bulk-delete': endpoint({
        description: 'Deletes multiple supplier contacts',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.object({
            ids: z.array(z.string()),
        }),
        responseBody: z.unknown(),
        invalidates: ['GET /supplier-contacts'],
    }),

    'GET /suppliers/:supplierId/supplier-contacts': endpoint({
        description: 'Get all contacts for a given supplier',
        pathParams: z.object({
            supplierId: z.string(),
        }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({ items: z.array(SupplierContactDTORuntype) }),
    }),
};
