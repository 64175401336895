import * as z from 'zod';
import { CurrencyRuntype, MonetaryValueBackendRuntype } from '../backendTypes';
import {
    AvailabilityRuntype,
    CustomOptionOfferDTORuntype,
    StandardPartInventoryOfferDTORuntype,
    StandardPartOfferDTORuntype,
} from '../offer';
import { CustomFullPartRuntype, OtsFullPartRuntype } from '../part';
import { SolutionConfigurationFullPartSourcingInformationResponseRuntype } from '../sourcingScenario';
import { SolutionPreferenceDTORuntype } from '../sourcingScenario/solutionConfigurationBackendTypes';
import { SupplierAndStockLocationDTORuntype } from '../supplierAndStockLocation';
import { RfqStatus } from './rfqBackendTypes';

export enum SnapshotTagType {
    Live = 'Live',
    Manual = 'Manual',
    Note = 'Note',
    RfqCreated = 'RfqCreated',
    RfqStatusChanged = 'RfqStatusChanged',
    SourcingScenarioName = 'SourcingScenarioName',
    SourcingScenarioId = 'SourcingScenarioId',
    CostedBomExported = 'CostedBomExported',
    MetadataTooLarge = 'MetadataTooLarge',
}

export type SnapshotTagDTO = z.infer<typeof SnapshotTagRuntype>;
export const SnapshotTagRuntype = z.union([
    z.object({
        type: z.literal(SnapshotTagType.Live),
    }),
    z.object({
        type: z.literal(SnapshotTagType.Manual),
    }),
    z.object({
        type: z.literal(SnapshotTagType.Note),
        value: z.string(),
    }),
    z.object({
        type: z.literal(SnapshotTagType.RfqCreated),
    }),
    z.object({
        type: z.literal(SnapshotTagType.RfqStatusChanged),
        value: z.nativeEnum(RfqStatus),
    }),
    z.object({
        type: z.literal(SnapshotTagType.SourcingScenarioName),
        value: z.string(),
    }),
    z.object({
        type: z.literal(SnapshotTagType.SourcingScenarioId),
        value: z.string(),
    }),
    z.object({
        type: z.literal(SnapshotTagType.CostedBomExported),
    }),
    z.object({
        type: z.literal(SnapshotTagType.MetadataTooLarge),
    }),
]);

export type SnapshotMetadataItemDTO = {
    id: string;
    created_at: string;
    rfq_id: string;
    tags: SnapshotTagDTO[];
};

type AssemblyQuantityDTO = {
    quantity: number;
    assembly: string;
};

type StatusCountDTO = {
    number_of_ok: number;
    number_of_warning: number;
    number_of_error: number;
};

type AggregatedDTO = {
    total_availability?: z.infer<typeof AvailabilityRuntype> | null;
    total_excess_material: z.infer<typeof MonetaryValueBackendRuntype> | null;
    total_unit_price: z.infer<typeof MonetaryValueBackendRuntype> | null;
    total_price: z.infer<typeof MonetaryValueBackendRuntype> | null;
    total_one_time_costs: z.infer<typeof MonetaryValueBackendRuntype> | null;
    total_scrap_costs: z.infer<typeof MonetaryValueBackendRuntype> | null;
    status_count: StatusCountDTO;
};

type SnapShotSourcingScenarioDTO = {
    id: string;
    name: string;
    assembly_quantities: {
        items: AssemblyQuantityDTO[];
    };
    aggregated: AggregatedDTO;
    solution_preference: z.infer<typeof SolutionPreferenceDTORuntype>;
    solution_configurations: z.infer<typeof SolutionConfigurationFullPartSourcingInformationResponseRuntype>[];
};

type CurrencySettingsDTO = {
    currency: z.infer<typeof CurrencyRuntype>;
    exchange_rates?: Record<string, string>;
};

export type SnapshotDTO = {
    off_the_shelf_offers: {
        items: z.infer<typeof StandardPartOfferDTORuntype>[];
    };
    inventory_offers: {
        items: z.infer<typeof StandardPartInventoryOfferDTORuntype>[];
    };
    custom_part_offers: {
        items: z.infer<typeof CustomOptionOfferDTORuntype>[];
    };
    ots_parts: z.infer<typeof OtsFullPartRuntype>[];
    custom_parts: z.infer<typeof CustomFullPartRuntype>[];
    suppliers: z.infer<typeof SupplierAndStockLocationDTORuntype>[];
    sourcing_scenarios: SnapShotSourcingScenarioDTO[];
    currency_settings: CurrencySettingsDTO;
};

export const SnapshotMetadataItemRuntype: z.ZodType<SnapshotMetadataItemDTO> = z.object({
    id: z.string(),
    created_at: z.string(),
    rfq_id: z.string(),
    tags: z.array(SnapshotTagRuntype),
});

const AssemblyQuantityRuntype: z.ZodType<AssemblyQuantityDTO> = z.object({
    quantity: z.number(),
    assembly: z.string(),
});

const StatusCountRuntype: z.ZodType<StatusCountDTO> = z.object({
    number_of_ok: z.number(),
    number_of_warning: z.number(),
    number_of_error: z.number(),
});

const AggregatedRuntype: z.ZodType<AggregatedDTO> = z.object({
    total_availability: AvailabilityRuntype.nullable(),
    total_excess_material: MonetaryValueBackendRuntype.nullable(),
    total_unit_price: MonetaryValueBackendRuntype.nullable(),
    total_price: MonetaryValueBackendRuntype.nullable(),
    total_one_time_costs: MonetaryValueBackendRuntype.nullable(),
    total_scrap_costs: MonetaryValueBackendRuntype.nullable(),
    status_count: StatusCountRuntype,
});

const SnapShotSourcingScenarioRuntype: z.ZodType<SnapShotSourcingScenarioDTO> = z.object({
    id: z.string(),
    name: z.string(),
    assembly_quantities: z.object({
        items: z.array(AssemblyQuantityRuntype),
    }),
    aggregated: AggregatedRuntype,
    solution_preference: SolutionPreferenceDTORuntype,
    solution_configurations: z.array(SolutionConfigurationFullPartSourcingInformationResponseRuntype),
});

const CurrencySettingsRuntype: z.ZodType<CurrencySettingsDTO> = z.object({
    currency: CurrencyRuntype,
    exchange_rates: z.record(z.string()).optional(),
});

export const SnapshotRuntype: z.ZodType<SnapshotDTO> = z.object({
    off_the_shelf_offers: z.object({
        items: z.array(StandardPartOfferDTORuntype),
    }),
    inventory_offers: z.object({
        items: z.array(StandardPartInventoryOfferDTORuntype),
    }),
    custom_part_offers: z.object({
        items: z.array(CustomOptionOfferDTORuntype),
    }),
    ots_parts: z.array(OtsFullPartRuntype),
    custom_parts: z.array(CustomFullPartRuntype),
    suppliers: z.array(SupplierAndStockLocationDTORuntype),
    sourcing_scenarios: z.array(SnapShotSourcingScenarioRuntype),
    currency_settings: CurrencySettingsRuntype,
});
