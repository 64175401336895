import { WritableAtom } from 'jotai';
import { z } from 'zod';
import { Cell, cell } from './cell';
import { Validated } from './validate';

export class FieldValidatorBuilder<TBaseSchema extends z.ZodType, TValidatedSchema extends z.ZodType> {
    constructor(
        private _base: TBaseSchema,
        private _validated: TValidatedSchema,
        private _warnIf?: (value: InferSchemaType<TValidatedSchema>) => string | undefined,
    ) {}

    validator<TValidatedSchema extends z.ZodType>(
        schema: TValidatedSchema | ((form: TBaseSchema) => TValidatedSchema),
    ): FieldValidatorBuilder<TBaseSchema, TValidatedSchema> {
        const newSchema: TValidatedSchema = typeof schema === 'function' ? schema(this._base) : schema;

        return new FieldValidatorBuilder<TBaseSchema, TValidatedSchema>(this._base, newSchema);
    }

    build(): Cell<InferSchemaType<TValidatedSchema>> {
        return this.cell({
            initialValue: undefined,
        });
    }

    cell({
        initialValue,
    }: {
        initialValue?: InferSchemaType<TValidatedSchema> | undefined;
    }): WritableAtom<
        Validated<InferSchemaType<TValidatedSchema>>,
        [update: InferSchemaType<TValidatedSchema> | undefined],
        void
    > {
        return cell<InferSchemaType<TValidatedSchema>, typeof this>({
            initialValue,
            schema: this,
        });
    }

    warnIf(
        predicate: (value: InferSchemaType<TValidatedSchema>) => string | undefined,
    ): FieldValidatorBuilder<TBaseSchema, TValidatedSchema> {
        return new FieldValidatorBuilder(this._base, this._validated, predicate);
    }

    validate(x: unknown): Validated<InferSchemaType<TValidatedSchema>> {
        // preconditions
        if (!this._validated) {
            throw new Error('Base or validated schema not set');
        }

        // first validated the base schema with null or undefined
        // transform nullables to undefined
        const baseResult = this._base
            .nullable()
            .optional()
            .transform((x) => x ?? undefined)
            .safeParse(x);
        if (!baseResult.success) {
            return {
                status: 'error',
                message: baseResult.error.errors[0].message,
            };
        }

        // at this point we know that x is of type T | undefined
        // so now we can run the validator
        const validatedResult = this._validated.safeParse(baseResult.data);
        if (!validatedResult.success) {
            return {
                status: 'error',
                message: validatedResult.error.errors[0].message,
                value: baseResult.data,
            };
        }

        const warning = this._warnIf?.(validatedResult.data);

        if (warning) {
            return {
                status: 'success',
                message: warning,
                value: validatedResult.data,
            };
        }

        return {
            status: 'success',
            value: validatedResult.data,
        };
    }
}

export function cellSchema<TBase extends z.ZodType>(base: TBase): FieldValidatorBuilder<TBase, TBase> {
    return new FieldValidatorBuilder(base, base);
}

type InferSchemaType<TSchema extends z.ZodType | undefined> = TSchema extends z.ZodType<infer T, any, any> ? T : never;
