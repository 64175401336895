import { t } from '@lingui/macro';
import { transEnum } from '@luminovo/commons';
import { useNavigate } from '@luminovo/design-system';
import { SupplierAndStockLocationDTO, SupplierPartType, SupplierTag, SupplierType } from '@luminovo/http-client';
import { supplierTypeTranslations } from '@luminovo/sourcing-core';
import { UniversalImporter, createIdentityImportHandlers } from '@luminovo/universal-importer';
import { useSnackbar } from 'notistack';
import { useSuspenseHttpQuery } from '../../../resources/http/useHttpQuery';
import { useHttpMutation } from '../../../resources/mutation/useHttpMutation';
import { UrlParams, route } from '../../../utils/routes';
import { errorCodeTranslations } from '../../Error/errorCodeTranslations';
export function SupplierImporterPage(_: UrlParams<'/supplier/importer'>) {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { data: suppliers = [] } = useSuspenseHttpQuery(
        'GET /suppliers-and-stock-locations',
        {},
        {
            refetchOnWindowFocus: true,
            select: (res) => res.data,
        },
    );

    const { mutateAsync: importSuppliers } = useHttpMutation('POST /suppliers/import', {
        snackbarMessage: null,
        disableOnError: true,
    });

    return (
        <UniversalImporter
            title={t`Suppliers importer`}
            onImportDone={() => {
                enqueueSnackbar(t`Suppliers imported successfully`, {
                    variant: 'success',
                    anchorOrigin: { vertical: 'top', horizontal: 'center' },
                });
                navigate(route('/suppliers'));
            }}
            batchSize={100}
            createImportHandlers={createIdentityImportHandlers(async (batch) => {
                const { items } = await importSuppliers({
                    requestBody: {
                        items: batch.map((item) => item.data),
                    },
                });
                return items.map((item) => {
                    if (item.success) {
                        return { success: true };
                    }
                    if (!item.error_code) {
                        return {
                            success: false,
                            message: t`An unknown error occurred`,
                        };
                    }
                    return {
                        success: item.success,
                        message: transEnum(item.error_code.code, errorCodeTranslations),
                    };
                });
            })}
            hrefBack={route('/suppliers')}
            config={{
                fields: [
                    {
                        id: 'supplier_number' as const,
                        label: t`Supplier number`,
                        description: t`The unique identifier of the supplier`,
                        unique: true,
                        required: true,
                        columnIndices: [],
                        parser: {
                            type: 'supplier.number',
                            options: {
                                suppliers: suppliers.filter((s) => s.supplier_number !== null).map(toParsedValue),
                            },
                        },
                    },
                    {
                        id: 'name' as const,
                        label: t`Supplier name`,
                        description: t`The supplier's name`,
                        required: true,
                        columnIndices: [],
                        parser: {
                            type: 'string',
                            options: { trim: true, minLength: 1 },
                        },
                    },
                    {
                        id: 'supplier_part_type' as const,
                        label: t`Part type`,
                        description: t`The type of parts the supplier provides`,
                        required: false,
                        defaultValue: {
                            id: 'OffTheShelf',
                            label: t`Off-the-shelf`,
                        },
                        columnIndices: [],
                        parser: {
                            type: 'enum',
                            options: {
                                caseSensitive: false,
                                trim: true,
                                cases: [
                                    {
                                        // eslint-disable-next-line spellcheck/spell-checker
                                        matches: ['Off-the-shelf', 'Standardteile', 'Standardteil'],
                                        value: { id: SupplierPartType.OffTheShelf, label: t`Off-the-shelf` },
                                    },
                                    {
                                        // eslint-disable-next-line spellcheck/spell-checker
                                        matches: ['Custom', 'Zeichnungsteile'],
                                        value: { id: SupplierPartType.Custom, label: t`Custom` },
                                    },
                                    // These two are not supported yet, but might be in the future
                                    // {
                                    //     matches: ['Pcb'],
                                    //     value: { id: SupplierPartType.Pcb, label: t`PCB` },
                                    // },
                                    // {
                                    //     matches: ['Assembly'],
                                    //     value: { id: SupplierPartType.Assembly, label: t`Assembly` },
                                    // },
                                ],
                            },
                        },
                    },
                    {
                        id: 'supplier_type' as const,
                        label: t`Supplier type`,
                        description: t`The type of supplier`,
                        required: false,
                        defaultValue: {
                            id: 'Distributor',
                            label: t`Distributor`,
                        },
                        columnIndices: [],
                        parser: {
                            type: 'enum',
                            options: {
                                caseSensitive: false,
                                trim: true,
                                cases: [
                                    {
                                        // eslint-disable-next-line spellcheck/spell-checker
                                        matches: ['Distributor', 'Distri'],
                                        value: {
                                            id: SupplierType.Distributor,
                                            label: transEnum(SupplierType.Distributor, supplierTypeTranslations),
                                        },
                                    },
                                    {
                                        // eslint-disable-next-line spellcheck/spell-checker
                                        matches: ['Manufacturer', 'Man', 'Man.', 'Hersteller'],
                                        value: {
                                            id: SupplierType.Manufacturer,
                                            label: transEnum(SupplierType.Manufacturer, supplierTypeTranslations),
                                        },
                                    },
                                    {
                                        matches: ['Ems'],
                                        value: {
                                            id: SupplierType.Ems,
                                            label: transEnum(SupplierType.Ems, supplierTypeTranslations),
                                        },
                                    },
                                ],
                            },
                        },
                    },
                ],
                skipRows: [],
            }}
        />
    );
}

function toParsedValue(supplier: SupplierAndStockLocationDTO) {
    return {
        id: supplier.supplier_number ?? '',
        label: String(supplier.supplier_number),
        description: supplier.supplier.name,
        existing: true,
        isSystem: supplier.tags.some((s) => s.tag === SupplierTag.System),
    };
}
