import * as z from 'zod';

export interface SignupFormState {
    first_name: string;
    last_name: string;
    email: string;
    clear_token: string;
    password: string;
    password_confirmation: string;
    phone_number?: number;
    country_code: number;
    customer_id: string | null;
    position: string;
    language: string;
    return_to?: string | null;
}

export const SignUpPayloadRuntype = z.object({
    email: z.string(),

    customer_id: z.string().nullable(),

    clear_token: z.string(),
    tenant: z.string(),

    first_name: z.string().optional(),

    last_name: z.string().optional(),
    language: z.string().optional(),
    return_to: z.string().nullable().optional(),
});

export interface SignUpPayload extends z.infer<typeof SignUpPayloadRuntype> {}
