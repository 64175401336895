import * as z from 'zod';
import { ApiIntegrationStatusRuntype } from './3rdPartyBackendTypes';

export interface BlumeCredentialsDTO extends z.infer<typeof BlumeCredentialsDTORuntype> {}
export const BlumeCredentialsDTORuntype = z.object({
    api_key: z.string(),
});

export interface BlumeCredentialsInputDTO extends z.infer<typeof BlumeCredentialsInputDTORuntype> {}
export const BlumeCredentialsInputDTORuntype = z.object({
    api_key: z.string().optional(),
});

export interface BlumeResponseBodyDTO extends z.infer<typeof BlumeResponseBodyDTORuntype> {}
export const BlumeResponseBodyDTORuntype = z.object({
    data: BlumeCredentialsDTORuntype.nullable(),
    status: ApiIntegrationStatusRuntype,
});
