import * as z from 'zod';
import { endpoint } from '../http/endpoint';
import {
    NewarkCredentialsDTORuntype,
    NewarkCredentialsInputDTORuntype,
    NewarkSupportedSettingDTORuntype,
} from './newarkBackendTypes';

export const newarkEndpoints = {
    'GET /3rdparty/newark/credentials': endpoint({
        description: 'Returns credentials for Newark integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({ data: NewarkCredentialsDTORuntype }),
    }),
    'POST /3rdparty/newark/credentials': endpoint({
        description: 'Uploads credentials for Newark API integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: NewarkCredentialsInputDTORuntype,
        responseBody: z.object({ data: NewarkCredentialsDTORuntype }),
        invalidates: ['GET /3rdparty/newark/credentials'],
    }),
    'PATCH /3rdparty/newark/credentials': endpoint({
        description: 'Updates credentials for Newark API integratoin',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: NewarkCredentialsInputDTORuntype,
        responseBody: z.object({ data: NewarkCredentialsDTORuntype }),
        invalidates: ['GET /3rdparty/newark/credentials'],
    }),
    'DELETE /3rdparty/newark/credentials': endpoint({
        description: 'Deletes existing credentials for Newark API integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({ deleted: z.number() }),
        invalidates: ['GET /3rdparty/newark/credentials'],
    }),
    'GET /3rdparty/newark/supported-store-location-settings': endpoint({
        description: 'Returns supported store locations and currencies for Newark API integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.array(NewarkSupportedSettingDTORuntype),
    }),
};
