import { t, Trans } from '@lingui/macro';
import { colorSystem, Flexbox, SecondaryButton, Text } from '@luminovo/design-system';
import { CalculationAssemblyDTO, CalculationTemplateResponseDTO } from '@luminovo/http-client';
import { CircularProgress, Tooltip } from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';
import { useHttpQuery } from '../../../resources/http/useHttpQuery';
import { useHttpMutation } from '../../../resources/mutation/useHttpMutation';
import { analytics } from '../../../utils/analytics';
import { CalculationTableForm } from '../CalculationTable/types/formTypes';
import { CalculationFrozenText } from '../CalculationTable/utils/calculationFrozenText';
import { ResetCalculationAssemblyTemplateDialog } from './components/ResetCalculationAssemblyTemplateDialog';
import {
    CalculationAssemblyTemplateFormInput,
    SwitchOrApplyCalculationAssemblyTemplateDialog,
} from './components/SwitchOrApplyCalculationAssemblyTemplateDialog';

const hasTemplateBeenUpdated = (lastTemplateUpdate: string, lastTemplateReset: string) => {
    return new Date(lastTemplateUpdate) > new Date(lastTemplateReset);
};

const useTemplateDetails = (calculationAssemblyDetails: CalculationAssemblyDTO) => {
    const { data, isLoading } = useHttpQuery('GET /calculations/templates', {});

    const templateDetails: CalculationTemplateResponseDTO | undefined =
        data !== undefined
            ? data.data.find(
                  (calculationTemplate) =>
                      calculationTemplate.id === calculationAssemblyDetails.created_from_calculation_template_id,
              )
            : undefined;
    return { templateDetails, isLoading: isLoading || data === undefined };
};

export const CalculationAssemblyDetailsReset = ({
    calculationAssemblyDetails,
    hasFormChanged,
}: {
    calculationAssemblyDetails: CalculationAssemblyDTO;
    hasFormChanged: boolean;
}) => {
    const { templateDetails, isLoading } = useTemplateDetails(calculationAssemblyDetails);

    const { mutateAsync } = useHttpMutation(
        'PATCH /calculations/calculation-assembly-details/:calculationAssemblyDetailsId/reset',
        //TODO: we should try to ensure that it updates without reloading the page
        { snackbarMessage: t`Reset to template` },
    );

    const handleSubmit = async (form: CalculationAssemblyTemplateFormInput) => {
        await mutateAsync({
            pathParams: { calculationAssemblyDetailsId: calculationAssemblyDetails.id },
            queryParams:
                form.calculationTemplateId !== undefined ? { reset_to_template: form.calculationTemplateId } : {},
        });
    };

    if (isLoading) {
        return <CircularProgress />;
    }

    return (
        <Flexbox alignItems={'center'} gap={16}>
            {templateDetails !== undefined && (
                <>
                    <Text>
                        <Flexbox width="max-content">
                            <div
                                style={{
                                    color: colorSystem.neutral[6],
                                    fontWeight: 'bold',
                                    marginRight: '4px',
                                }}
                            >
                                Template:
                            </div>
                            {templateDetails.name}
                        </Flexbox>
                    </Text>
                    <ResetCalculationAssemblyTemplateDialog
                        /**
                         * If theres a template reset time compare it against the template update time, else,
                         * compare it against template creation time
                         */
                        isTemplateEdited={
                            calculationAssemblyDetails.last_template_reset_at !== null
                                ? hasTemplateBeenUpdated(
                                      templateDetails.updated_at,
                                      calculationAssemblyDetails.last_template_reset_at,
                                  )
                                : hasTemplateBeenUpdated(templateDetails.updated_at, templateDetails.created_at)
                        }
                        onSubmit={handleSubmit}
                        hasFormChanged={hasFormChanged}
                    />
                </>
            )}
        </Flexbox>
    );
};

export const CalculationAssemblyDetailsSwitch = ({
    calculationAssemblyDetails,
    isDialogOpen,
    setIsDialogOpen,
    rfqId,
    assemblyId,
}: {
    calculationAssemblyDetails: CalculationAssemblyDTO;
    isDialogOpen: boolean;
    setIsDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
    rfqId: string;
    assemblyId: string;
}) => {
    const { templateDetails, isLoading } = useTemplateDetails(calculationAssemblyDetails);
    const isCalculationTemplateApplied = templateDetails?.id !== undefined;

    const { mutateAsync } = useHttpMutation(
        'PATCH /calculations/calculation-assembly-details/:calculationAssemblyDetailsId/reset',
        {
            snackbarMessage: t`Reset to template`,
            onSuccess: () => {
                if (isCalculationTemplateApplied === false) {
                    analytics.track('apply_calculation_template', {
                        rfq_id: rfqId,
                        assembly_id: assemblyId,
                    });
                }
            },
        },
    );

    const handleSubmit = async (form: CalculationAssemblyTemplateFormInput) => {
        await mutateAsync({
            pathParams: { calculationAssemblyDetailsId: calculationAssemblyDetails.id },
            queryParams:
                form.calculationTemplateId !== undefined ? { reset_to_template: form.calculationTemplateId } : {},
        });
    };

    if (isLoading) {
        return <CircularProgress />;
    }

    return (
        <Flexbox alignItems={'center'} gap={'8px'}>
            <SwitchOrApplyCalculationAssemblyTemplateDialog
                calculationTemplateId={templateDetails?.id}
                onSubmit={handleSubmit}
                isDialogShown={isDialogOpen}
                setIsDialogShown={setIsDialogOpen}
            />
        </Flexbox>
    );
};

export const CalculationAssemblyDetailsSwitchButton = ({
    calculationAssemblyDetails,
    setIsDialogOpen,
}: {
    calculationAssemblyDetails: CalculationAssemblyDTO;
    setIsDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
}): JSX.Element => {
    const { control } = useFormContext<CalculationTableForm>();
    const { templateDetails, isLoading } = useTemplateDetails(calculationAssemblyDetails);
    const isCalculationFrozen = useWatch({
        control,
        name: 'isCalculationFrozen',
    });

    if (isCalculationFrozen) {
        return (
            <Tooltip title={<CalculationFrozenText />}>
                {/* span required because button could be disabled 
                https://mui.com/material-ui/react-tooltip/#disabled-elements
                */}
                <span>
                    <SecondaryButton size="medium" disabled={true}>
                        <Trans>Switch template</Trans>
                    </SecondaryButton>
                </span>
            </Tooltip>
        );
    }

    if (isLoading) {
        return <CircularProgress />;
    }

    return (
        <SecondaryButton onClick={() => setIsDialogOpen(true)} size="medium">
            {templateDetails !== undefined ? t`Switch template` : t`Apply template`}
        </SecondaryButton>
    );
};
