import { useHttpFileUpload } from '@/resources/http/useHttpFileUpload';
import { analytics } from '@/utils/analytics';
import { UrlParams } from '@/utils/routes';
import { t } from '@lingui/macro';
import { id } from '@luminovo/commons';
import { PdfQuoteTrackingImporterDeprecated } from '@luminovo/offers';
import React from 'react';

/**
 * This is the PDF importer for "quote tracking" objects, not for quote requests,
 * therefore the DEPRECATED label.
 */
export function PageQuoteTrackingImporterDeprecated({
    pathParams,
    queryParams,
}: UrlParams<'/offers/pdf-offer-importer/:rfqId'>) {
    const { rfqId } = pathParams;
    const { returnTo, quoteTrackingId } = queryParams;
    React.useEffect(() => {
        analytics.track(id('pdf-offer-importer/start'), {
            rfqId,
        });
    }, [rfqId]);

    const { mutateAsync: mutateAsyncUpload } = useHttpFileUpload(
        'GET /quote-tracking/:id/additional-files/upload-link',
        (response) => response.data.url,
        {
            snackbarMessage: t`Files uploaded successfully`,
        },
    );
    return (
        <PdfQuoteTrackingImporterDeprecated
            onSuccess={({ quoteTrackingId, file }) => {
                analytics.track(id('pdf-offer-importer/success'), {
                    rfqId,
                });
                mutateAsyncUpload({
                    pathParams: {
                        id: quoteTrackingId,
                    },
                    files: file,
                });
            }}
            onError={() => {
                analytics.track(id('pdf-offer-importer/error'), {
                    rfqId,
                });
            }}
            returnTo={returnTo}
            rfqId={rfqId}
            quoteTrackingId={quoteTrackingId ?? undefined}
        />
    );
}
