import * as z from 'zod';

import { MonetaryValueBackendRuntype } from '../backendTypes';
import { PackagingRuntype, PriceTypeRuntype } from '../offer';

export enum LimitType {
    Never = 'Never',
    Days = 'Days',
}

const LimitTypeEnum = z.nativeEnum(LimitType);

const LimitRuntype = z.object({
    type: LimitTypeEnum,
    number: z.number().optional(),
});

export interface ExcessPreferenceDTO extends z.infer<typeof ExcessPreferenceDTORuntype> {}

const ExcessPreferenceDTORuntype = z.object({
    max_ratio_percent: z.number().nullable(),
    minimum_savings: MonetaryValueBackendRuntype.nullable(),
});

export interface OfferAgeLimitDTO extends z.infer<typeof OfferAgeLimitDTORuntype> {}
export const OfferAgeLimitDTORuntype = z.object({
    expiring_before_validity_date: LimitRuntype,
    expired_after_validity_date: LimitRuntype,
    old_after_update: LimitRuntype,
    outdated_after_update: LimitRuntype,
});

export enum SourcingPreferenceOptions {
    Fastest = 'Fastest',
    BestPrice = 'BestPrice',
    BestPriceBy = 'BestPriceBy',
}

const SourcingPreferenceOptionsRuntype = z.nativeEnum(SourcingPreferenceOptions);

export interface LeadTimePreference extends z.infer<typeof LeadTimePreferenceRuntype> {}

const LeadTimePreferenceRuntype = z.object({
    type: SourcingPreferenceOptionsRuntype,
    target: z.string().optional(),
});

export const InventoryPreferenceRuntype = z.enum(['PreferInventory', 'Neutral', 'PreferMarketOffers']);
export type InventoryPreferenceType = z.infer<typeof InventoryPreferenceRuntype>;

export const SolutionPreferenceDTORuntype = z.object({
    lead_time_preference: LeadTimePreferenceRuntype,
    preferred_suppliers_and_stock_locations: z.null().or(z.array(z.string())),
    approved_suppliers_and_stock_locations: z.null().or(z.array(z.string())),
    excluded_suppliers_and_stock_locations: z.null().or(z.array(z.string())),
    preferred_inventory_sites: z.null().or(z.array(z.string())),
    approved_inventory_sites: z.null().or(z.array(z.string())),
    excluded_inventory_sites: z.null().or(z.array(z.string())),
    excess_preference: ExcessPreferenceDTORuntype,
    offer_age_limit: OfferAgeLimitDTORuntype,
    low_stock_warning_factor: z.number(),
    inventory_preference: InventoryPreferenceRuntype,
    use_unit_price: z.boolean(),
    packaging_preference: z.array(PackagingRuntype),
    prefer_direct_api_integrations: z.boolean(),
    excluded_price_types: z.array(PriceTypeRuntype),
    exclude_mismatched_price_points: z.boolean(),
    prioritize_preferred_manufacturers: z.boolean(),
    prefer_customer_negotiated_prices: z.boolean(),
    is_tco_enabled: z.boolean(),
});
export interface SolutionPreferenceDTO extends z.infer<typeof SolutionPreferenceDTORuntype> {}
