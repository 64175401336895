import { assertPresent, isPresent, LanguageEnum } from '@luminovo/commons';
import {
    CountryAlpha2Code,
    CustomerDTO,
    CustomerPatchDTO,
    CustomerType,
    PostCustomerTypeDTO,
    VATIdentificationTypeDTO,
} from '@luminovo/http-client';
import { extractBusinessCustomerData } from '@luminovo/manufacturing-core';

export type CustomerFormState = {
    name: string;
    billingAddress:
        | {
              line1: string | undefined;
              line2: string | undefined;
              postalCode: string | undefined;
              city: string | undefined;
              countryCode: CountryAlpha2Code | undefined;
          }
        | undefined;
    defaultContactPerson: string;
    customerNumber: string | undefined;
    customerType:
        | { type: 'Private'; data: undefined }
        | {
              type: 'Business';
              data: {
                  vatId: string | undefined;
                  vatIdType: VATIdentificationTypeDTO | undefined;
                  commercialRegisterNumber: string;
              };
          };
    language: LanguageEnum;
};

export const getFormCustomerType = (customerType: CustomerType): CustomerFormState['customerType'] => {
    switch (customerType.type) {
        case 'Private':
            return { type: 'Private', data: undefined };
        case 'Business':
            const data = extractBusinessCustomerData(customerType);
            return {
                type: 'Business',
                data: {
                    vatId: data?.vatId ?? undefined,
                    vatIdType: data?.vatIdType,
                    commercialRegisterNumber: data?.commercialRegisterNumber ?? '',
                },
            };
    }
};

export const convertToFormState = (customer: CustomerDTO): CustomerFormState => {
    return {
        name: customer.name,
        language: customer.language,
        customerType: getFormCustomerType(customer.customer_type),
        defaultContactPerson: customer.default_contact_person ?? '',
        customerNumber: customer.customer_number ?? undefined,
        billingAddress: {
            line1: customer.billing_address?.line1 ?? '',
            line2: customer.billing_address?.line2 ?? '',
            city: customer.billing_address?.city ?? '',
            countryCode: customer.billing_address?.country_code ?? undefined,
            postalCode: customer.billing_address?.postal_code ?? '',
        },
    };
};

export const getDtoCustomerType = (customerType: CustomerFormState['customerType']): PostCustomerTypeDTO => {
    switch (customerType.type) {
        case 'Private':
            return { type: 'Private' };
        case 'Business':
            return {
                type: 'Business',
                data: {
                    vat_id:
                        isPresent(customerType.data.vatId) && isPresent(customerType.data.vatIdType)
                            ? {
                                  id: customerType.data.vatId,
                                  id_type: assertPresent(customerType.data.vatIdType),
                              }
                            : null,
                    commercial_register_number: customerType.data.commercialRegisterNumber || null,
                },
            };
    }
};

export const convertToDto = (form: CustomerFormState): CustomerPatchDTO => {
    return {
        name: form.name,
        language: form.language,
        default_contact_person: form.defaultContactPerson,
        customer_number: form.customerNumber || null,
        billing_address: {
            line1: form.billingAddress?.line1 || null,
            line2: form.billingAddress?.line2 || null,
            city: form.billingAddress?.city || null,
            country_code: form.billingAddress?.countryCode || null,
            postal_code: form.billingAddress?.postalCode || null,
        },
        customer_type: getDtoCustomerType(form.customerType),
    };
};
