import { t } from '@lingui/macro';
import { isProductionEnvironment, throwErrorUnlessProduction } from '@luminovo/commons';
import { printFormattedRuntypeError } from '@luminovo/http-client';
import * as z from 'zod';

// eslint-disable-next-line lingui/t-call-in-function
export const nonSpecificErrorMessage = t`Sorry, there was a problem. Please try again later or contact our customer support.`;

export const assertAgainstRuntype = (data: unknown, runtype: z.ZodTypeAny): void => {
    try {
        if (!isProductionEnvironment()) {
            runtype.parse(data);
        }
    } catch (error) {
        if (!isProductionEnvironment()) {
            printFormattedRuntypeError(error);
        }
        throwErrorUnlessProduction(error);
    }
};
