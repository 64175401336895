import { t, Trans } from '@lingui/macro';
import { isPresent } from '@luminovo/commons';
import {
    FieldNumericControlled,
    FieldRadioControlled,
    FieldSelectControlled,
    FieldTextControlled,
    Flexbox,
    FormItem,
    Text,
    TextField,
} from '@luminovo/design-system';
import {
    ApprovalStatus,
    DielectricCeramicEnum,
    DielectricTypes,
    GenericCapacitor,
    GenericFullPart,
    GenericPartTypes,
    RfqContext,
} from '@luminovo/http-client';
import { FieldPackageControlled } from '@luminovo/sourcing-core';
import { FormControl, InputAdornment, RadioGroup, Typography } from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';
import { CancelButton } from '../../../components/formLayouts/CancelButton';
import { FormContainer } from '../../../components/formLayouts/FormContainer';
import { SubmitButton } from '../../../components/formLayouts/SubmitButton';
import { GenericCapacitorForm } from '../../../resources/designItem/designItemFrontendTypes';
import { useHttpMutation } from '../../../resources/mutation/useHttpMutation';
import { SectionOfScreen } from '../../../resources/part/partFrontendTypes';
import { convertRfqContext, usePartPackages } from '../../../resources/part/partHandler';
import {
    capacitanceConverter,
    displayConvertedCapacitance,
    toleranceConverter,
    voltageConverter,
} from '../../../utils/converterUtils';
import { getEnumKeyByEnumValue } from '../../../utils/typingUtils';
import { convertEmptyStringToNull } from '../designItemFormFunctions';

const initialCapacitorFormValues: GenericCapacitorForm = {
    package: undefined,
    approvalStatus: ApprovalStatus.Approved,
    type: GenericPartTypes.Capacitor,
    capacitance: null,
    dielectricMaterial: {
        type: DielectricTypes.Ceramic,
        dielectric: null,
    },
    tolerance: null,
    voltageRating: null,
};

const LESS_THAN_OR_EQUAL_TO_SYMBOL = <span>&#8804;</span>;
const BIGGER_THAN_OR_EQUAL_TO_SYMBOL = <span>&#8805;</span>;

const FormInner = ({
    onCancel,
    submitButtonLabel,
}: {
    onCancel?: () => void;
    submitButtonLabel?: string | JSX.Element;
}) => {
    const { control } = useFormContext<GenericCapacitorForm>();
    const { data: partPackages = [] } = usePartPackages('generic-part-creatable');

    const packageDTO = useWatch({
        control,
        name: 'package',
    });

    const capacitance = useWatch({
        control,
        name: 'capacitance',
    });

    const convertedCapacitance = capacitanceConverter(capacitance ?? '');

    return (
        <Flexbox flexDirection={'column'} gap={16}>
            <FormItem label={t`Package`} required>
                <FieldPackageControlled name={'package'} control={control} required={true} options={partPackages} />
            </FormItem>
            <FormItem label={t`Mounting`} required>
                <FormControl>
                    {packageDTO ? (
                        <Typography>{packageDTO.mounting}</Typography>
                    ) : (
                        <TextField size="large" disabled={true} />
                    )}
                </FormControl>
            </FormItem>
            <FormItem label={t`Capacitance`} required>
                <Flexbox alignItems={'center'} gap={8}>
                    <FieldTextControlled
                        name={'capacitance'}
                        control={control}
                        required={true}
                        validate={(x) => {
                            if (typeof x === 'string') {
                                const capacitance = capacitanceConverter(x);
                                if (!isPresent(capacitance)) {
                                    return t`Invalid input`;
                                }
                                if (capacitance.lessThan(0)) {
                                    return t`Capacitance cannot be negative`;
                                }
                                if (capacitance.equals(0)) {
                                    return t`Capacitance cannot be zero`;
                                }
                            }
                        }}
                        FieldProps={{
                            InputProps: {
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Typography color={'textSecondary'}>F</Typography>
                                    </InputAdornment>
                                ),
                            },
                            size: 'large',
                        }}
                    />

                    {convertedCapacitance && (
                        <Typography variant={'body2'} color={'textSecondary'}>
                            {displayConvertedCapacitance(convertedCapacitance)}
                            {'F'}
                        </Typography>
                    )}
                </Flexbox>
            </FormItem>
            <FormItem label={t`Tolerance`}>
                <FieldNumericControlled
                    name={'tolerance'}
                    control={control}
                    FieldProps={{
                        InputProps: {
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Typography>{LESS_THAN_OR_EQUAL_TO_SYMBOL}</Typography>
                                </InputAdornment>
                            ),
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        },
                        size: 'large',
                    }}
                />
            </FormItem>
            <FormItem label={t`Dielectric material`}>
                <RadioGroup>
                    <Flexbox gap={8} alignItems={'center'}>
                        <FieldRadioControlled
                            name={'dielectricMaterial.type'}
                            control={control}
                            FieldProps={{
                                fieldValue: DielectricTypes.Ceramic,
                            }}
                        />
                        <Text variant={'body'}>
                            <Trans>Ceramic</Trans>
                        </Text>
                    </Flexbox>
                </RadioGroup>
            </FormItem>
            <FormItem label={t`Dielectric`}>
                <FieldSelectControlled
                    name={'dielectricMaterial.dielectric'}
                    control={control}
                    FieldProps={{
                        options: Object.values(DielectricCeramicEnum),
                    }}
                />
            </FormItem>
            <FormItem label={t`Voltage rating`}>
                <FieldNumericControlled
                    name={'voltageRating'}
                    control={control}
                    FieldProps={{
                        InputProps: {
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Typography>{BIGGER_THAN_OR_EQUAL_TO_SYMBOL}</Typography>
                                </InputAdornment>
                            ),
                            endAdornment: <InputAdornment position="end">V</InputAdornment>,
                        },
                        size: 'large',
                    }}
                />
            </FormItem>

            <Flexbox gap={8} justifyContent="flex-end" marginTop={'8px'}>
                <CancelButton onClick={onCancel} />
                <SubmitButton label={submitButtonLabel} />
            </Flexbox>
        </Flexbox>
    );
};

export const AddGenericCapacitorForm = ({
    rfqContext,
    initialValues = initialCapacitorFormValues,
    onGenericPartCreation,
    onSettled,
    submitButtonLabel,
}: {
    rfqContext: RfqContext;
    initialValues?: GenericCapacitorForm;
    onGenericPartCreation: (data: GenericFullPart, sectionOfScreen: SectionOfScreen) => void;
    onSettled: () => void;
    submitButtonLabel?: string | JSX.Element;
}) => {
    const { mutateAsync } = useHttpMutation('POST /parts/generic', {
        snackbarMessage: t`Generic capacitor created`,
    });

    const onSubmit = async (values: GenericCapacitorForm) => {
        const sectionOfScreen: SectionOfScreen =
            initialValues === initialCapacitorFormValues ? 'genericPartForm' : 'partialMatches';

        const input: GenericCapacitor = {
            type: GenericPartTypes.Capacitor,
            technical_parameters: {
                capacitance: convertEmptyStringToNull(
                    capacitanceConverter(values.capacitance ?? '')?.toString() ?? null,
                ),
                dielectric: {
                    dielectric:
                        values.dielectricMaterial.dielectric === 'NP0'
                            ? 'NP0'
                            : getEnumKeyByEnumValue(DielectricCeramicEnum, values.dielectricMaterial.dielectric),
                    type: values.dielectricMaterial.type,
                },
                tolerance: convertEmptyStringToNull(toleranceConverter(values.tolerance ?? '')?.toString() ?? null),
                voltage_rating: convertEmptyStringToNull(
                    voltageConverter(values.voltageRating ?? '')?.toString() ?? null,
                ),
                package_id: values.package?.id ?? null,
            },
        };

        const { data } = await mutateAsync({
            requestBody: { input, ...convertRfqContext(rfqContext), recompute_matches: true },
        });
        onGenericPartCreation(data, sectionOfScreen);
        onSettled();
    };

    return (
        <FormContainer defaultValues={initialValues} onSubmit={onSubmit}>
            <FormInner onCancel={onSettled} submitButtonLabel={submitButtonLabel} />
        </FormContainer>
    );
};
