/* eslint-disable spellcheck/spell-checker */
import { isAuthorized, RoleTest, usePermissions } from '@luminovo/auth';
import { CenteredLayout } from '@luminovo/design-system';
import { CircularProgress } from '@mui/material';
import * as Sentry from '@sentry/react';
import * as React from 'react';
// eslint-disable-next-line no-restricted-imports
import { Route, RouteProps, useLocation, useParams } from 'react-router-dom';
import { ErrorFallback } from '../components/errorHandlers/ErrorBoundary';
import { HomeNavbar, RfqNavbar } from '../components/Navbar';
import { PageLayout } from '../components/PageLayout';
import { LayoutSupplierPortal } from '../modules/Negotiations/components/LayoutSupplierPortal';
import { useQueryParams } from '../resources/hooks';
import { LumiQuoteRoute, PageParams, QueryParams, UrlParams } from '../utils/routes';
import { LumiQuoteRoutes } from '../utils/routes/routes';
import { NotAuthorizedPage } from './NotAuthorizedPage';

type Props<T extends LumiQuoteRoute> = Pick<RouteProps, 'exact' | 'strict'> & {
    path: T;
    requiredPermissions: RoleTest;
    render: (urlParams: UrlParams<T>) => React.ReactNode | undefined;
};

/**
 * Alternative component to Route which will only render the `Route` if all the permissions are met.
 */
export function ProtectedRoute<T extends LumiQuoteRoute>({
    requiredPermissions,
    render,
    ...props
}: Props<T>): JSX.Element {
    return (
        <Route
            {...props}
            render={() => (
                <Sentry.ErrorBoundary fallback={ErrorFallback}>
                    <PageWrapper requiredPermissions={requiredPermissions} render={render} />
                </Sentry.ErrorBoundary>
            )}
        />
    );
}

export function decodePathParams<T extends LumiQuoteRoute>(encodedParams: PageParams<T>): PageParams<T> {
    const decodedParams: Partial<PageParams<T>> = {};

    for (const [key, value] of Object.entries(encodedParams) as [keyof LumiQuoteRoutes[T]['path'], string][]) {
        decodedParams[key] = decodeURIComponent(value);
    }

    return decodedParams as PageParams<T>;
}

/**
 * Wraps the call to `render` so that we can call the two url parameter hooks without violating the Rules of Hooks,
 * and inside the corresponding <Route> node in the component tree. If `useParams` were called directly in
 * `ProtectedRoute` it would return an empty object.
 */
function PageWrapper<T extends LumiQuoteRoute>({
    requiredPermissions,
    render,
}: {
    requiredPermissions: RoleTest;
    render: (urlParams: UrlParams<T>) => React.ReactNode | undefined;
}): JSX.Element {
    const { permissions } = usePermissions();
    const encodedPathParams: PageParams<T> = useParams<PageParams<T>>();
    const pathParams = decodePathParams(encodedPathParams);

    const queryParams: QueryParams<T> = useQueryParams<T>();

    if (isAuthorized(permissions, requiredPermissions)) {
        return (
            <React.Suspense fallback={<GlobalSuspenseFallback />}>{render({ pathParams, queryParams })}</React.Suspense>
        );
    }
    return <NotAuthorizedPage />;
}

/**
 * This implementation is not ideal as it couples the fallback to the routes, and it's very error prone.
 *
 * However, a "bug" in the mapping of the
 */
function GlobalSuspenseFallback() {
    const { pathname } = useLocation();

    if (pathname.startsWith('/rfqs/')) {
        return (
            <PageLayout navbar={<RfqNavbar />} layout="centered">
                <CircularProgress />
            </PageLayout>
        );
    }

    if (pathname.startsWith('/supplier-portal')) {
        return (
            <LayoutSupplierPortal>
                <CenteredLayout>
                    <CircularProgress />
                </CenteredLayout>
            </LayoutSupplierPortal>
        );
    }

    const homeRoutes = [
        '/calculation',
        '/manufacturing',
        '/rfqs',
        '/parts',
        '/negotiations',
        '/customers',
        '/suppliers',
        '/purchase-orders',
    ];

    if (homeRoutes.some((route) => pathname.startsWith(route))) {
        return (
            <PageLayout navbar={<HomeNavbar />} layout="centered">
                <CircularProgress />
            </PageLayout>
        );
    }
    return (
        <CenteredLayout>
            <CircularProgress />
        </CenteredLayout>
    );
}
