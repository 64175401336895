import * as z from 'zod';

export const PnpFileInsertRuntype = z.object({
    assembly_id: z.string(),
    file_name: z.string(),
});

export const PnpUploadFailedURLResponseRuntype = z.object({
    url: z.string(),
});

export const PnpFileResponseRuntype = z.object({
    id: z.string(),
    url: z.string(),
});

export const PnpColumnRuntype = z.enum(['Side', 'Designator']);

export const PnpSideRuntype = z.enum(['Top', 'Bottom']);

export type PnpSideDTO = z.infer<typeof PnpSideRuntype>;

export const UnableToParseCellWarningRuntype = z.object({
    type: z.literal('UnableToParseCell'),
    data: z.object({
        column_type: PnpColumnRuntype,
        //0th index.
        row: z.number(),
        //0th index.
        col: z.number(),
    }),
});

export const DesignatorMissingWarningRuntype = z.object({
    type: z.literal('DesignatorMissing'),
    data: z.number(),
});

export const ColumnMissingWarningRuntype = z.object({
    type: z.literal('ColumnMissing'),
    data: PnpColumnRuntype,
});

export const DuplicateDesignatorDataWarningRuntype = z.object({
    type: z.literal('DuplicateDesignatorData'),
    data: z.string(),
});

export const VaryingColumnNumberWarningRuntype = z.object({
    type: z.literal('VaryingColumnNumber'),
    data: z.object({
        row: z.number(),
        col_count: z.number(),
        expected_col_count: z.number(),
    }),
});

export const WarningRuntype = z.union([
    UnableToParseCellWarningRuntype,
    DesignatorMissingWarningRuntype,
    ColumnMissingWarningRuntype,
    DuplicateDesignatorDataWarningRuntype,
    VaryingColumnNumberWarningRuntype,
]);

export const ColumnMappingRuntype = z.object({
    side: z.number().nullable(),
    designator: z.number().nullable(),
});

export const ColumnMappingAttemptNoHeaderRuntype = z.object({
    type: z.literal('NoHeader'),
});

export const ColumnMappingAttemptWithHeaderRuntype = z.object({
    type: z.literal('WithHeader'),
    column_mapping: ColumnMappingRuntype,
});

export const ColumnMappingAttemptRuntype = z.union([
    ColumnMappingAttemptNoHeaderRuntype,
    ColumnMappingAttemptWithHeaderRuntype,
]);
export type ColumnMappingAttemptDTO = z.infer<typeof ColumnMappingAttemptRuntype>;

export const ParsedPnpResponseRuntype = z.object({
    contents: z.array(z.array(z.string())),
    warnings: z.array(WarningRuntype),
    column_mapping: ColumnMappingAttemptRuntype,
});

export const ColumnMappingRequestRuntype = z.object({
    contents: z.array(z.array(z.string())),
    column_mapping: ColumnMappingRuntype,
});

export const PnpItemRuntype = z.object({
    id: z.string(),
    pnp_file: z.string(),
    designator: z.string(),
    side: PnpSideRuntype.nullable(),
    design_item: z.string().nullable(),
    mounting: z.string().nullable(),
});
export type PnpItemDTO = z.infer<typeof PnpItemRuntype>;

export const BulkPnpItemsResponseRuntype = z.object({
    warnings: z.array(WarningRuntype),
    items: z.array(PnpItemRuntype),
});
export type BulkPnpItemsResponseDTO = z.infer<typeof BulkPnpItemsResponseRuntype>;

export const NoPnpUploadedResponseRuntype = z.object({
    type: z.literal('PnpNotUploaded'),
});

export const ColumnMappingMissingResponseRuntype = z.object({
    type: z.literal('ColumnMappingNotSubmitted'),
});

export const PnpUploadedResponseRuntype = z.object({
    type: z.literal('PnpUploaded'),
    data: BulkPnpItemsResponseRuntype,
});

export const CheckedBulkPnpItemsResponseRuntype = z.union([
    NoPnpUploadedResponseRuntype,
    PnpUploadedResponseRuntype,
    ColumnMappingMissingResponseRuntype,
]);
export type CheckedBulkPnpItemsResponseDTO = z.infer<typeof CheckedBulkPnpItemsResponseRuntype>;

// Created: /pnp/upload-url called
// Uploaded: /pnp/parse called successfully
// Parsed: /pnp/column-mapping called successfully
export const PnpFileStateRuntype = z.enum(['Created', 'Uploaded', 'Parsed']);

export const PnpFileRuntype = z.object({
    id: z.string(),
    assembly_id: z.string(),
    file_name: z.string(),
    state: PnpFileStateRuntype,
    column_mapping: ColumnMappingRuntype.nullable(),
    url: z.string().nullable(),
});
