import { useHttpQuery } from '../../../../resources/http/useHttpQuery';

export const useIsTemplateUsedInCustomerPortalSettings = (templateId: string) => {
    const { data } = useHttpQuery(
        'GET /customer-portal-settings',
        {},
        {
            select: (data) => {
                return data.manufacturing_templates;
            },
        },
    );
    const templates = data ?? [];
    return {
        isTemplateUsedInCustomerPortalSettings: templates.includes(templateId),
    };
};
