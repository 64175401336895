import { t } from '@lingui/macro';
import { getToken } from '@luminovo/auth';
import { isPresent, sleep, throwErrorUnlessProduction } from '@luminovo/commons';
import { useNavigate } from '@luminovo/design-system';
import {
    BACKEND_HOST,
    DesignItemOriginTypes,
    DesignItemResponseDTO,
    PCBV2Update,
    SpecificationStatusEnum,
    UploadFileResponse,
    UploadFileResponseRuntype,
    http,
} from '@luminovo/http-client';
import { QueryClient, useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import React from 'react';
import { ViewContext } from '../../modules/Bom/components/ModuleTableData';
import { extractPcbWidthAndHeight } from '../../modules/Pcb/PanelizationTab/utils/extractPcbWidthAndHeight';
import { route } from '../../utils/routes';
import { useDescendants } from '../assembly/assemblyHandler';
import { useDebugErrorHandler } from '../http/debugErrorHandler';
import { httpQueryKey } from '../http/httpQueryKey';
import { invalidateAllQueriesForEndpoint, useHttpQuery } from '../http/useHttpQuery';
import { useHttpMutation } from '../mutation/useHttpMutation';
import { uploadPcbAnalytic } from './analytics/analytic';
import { isPcbAnalysisInProgress, isPcbAnalysisSuccess, isPcbSetupWithoutFiles } from './pcbFunctions';
export const UPLOAD_PCB_FILE_MUTATION_KEY = ['upload_pcb_file_mutation'];

// UUID string.
type UUID = string;

class FileNotAcceptedError extends Error {
    public readonly status: number;
    public readonly fileName: string;
    public readonly pcbId: UUID;

    constructor({
        status,
        statusText,
        fileName,
        pcbId,
    }: {
        status: number;
        statusText: string;
        fileName: string;
        pcbId: UUID;
    }) {
        const lines = [
            `Got ${status} ${statusText} when uploading files to pcbId: ${pcbId}`,
            `file name: ${fileName}`,
            '',
        ];
        super(lines.join('\n\n'));
        this.status = status;
        this.fileName = fileName;
        this.pcbId = pcbId;
    }
}

async function retry<T>(fn: () => Promise<T>, numRetries: number): Promise<T> {
    for (let retries = 0; retries < numRetries; retries++) {
        try {
            return await fn();
        } catch (_) {
            await new Promise((r) => setTimeout(r, retries * 5000));
        }
    }
    throw Error(`Gave up after retrying ${numRetries}`);
}

/**
 * Empty response(void) mean successfully uploaded the files
 */
async function uploadPcbFiles(pcbId: string, files: File[], token: string): Promise<void | FileNotAcceptedError> {
    const formData = new FormData();
    const fileNames = [];
    for (const file of files) {
        formData.append(file.name, file);
        fileNames.push(file.name);
    }
    const response = await fetch(BACKEND_HOST + `/files/ems/pcb/pcbs/${pcbId}/files`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
        method: 'POST',
        body: formData,
    });

    if (response.ok) {
        return;
    }

    // To avoid the retry loop will resolve this with an error object to catch in upper level.
    if (response.status === 406) {
        return Promise.resolve(
            new FileNotAcceptedError({
                status: response.status,
                statusText: response.statusText,
                fileName: fileNames.join(', '),
                pcbId,
            }),
        );
    }
    throw new Error(`Failed to upload PCB files for pcb=${pcbId}`);
}

async function uploadMissingFiles(pcbId: string, files: File[], token: string): Promise<UploadFileResponse> {
    const formData = new FormData();
    for (const file of files) {
        formData.append('', file, file.name);
    }
    const response = await fetch(BACKEND_HOST + `/api/pcb/${pcbId}/upload-files`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
        method: 'POST',
        body: formData,
    });

    if (!response.ok) {
        throw new Error(`Failed to upload PCB files for pcb=${pcbId}`);
    }

    const jsonData = await response.json();
    UploadFileResponseRuntype.parse(jsonData);

    return jsonData;
}

async function getOrCreatePcbId(
    assemblyId: string,
    pcbId: string | undefined,
    token: string,
    isWithoutFile: boolean = false,
): Promise<string> {
    if (pcbId) {
        // return early if there is already a PCB ID
        return pcbId;
    }

    const pcb = await http(
        'POST /assemblies/:id/pcb',

        { pathParams: { id: assemblyId }, queryParams: { without_files: isWithoutFile } },
        token,
    );
    return pcb.id;
}

export function useMutationUploadPcbFiles(assemblyId: string, rfqId: string, pcbId?: string) {
    const queryClient = useQueryClient();
    const snackbar = useSnackbar();
    const token = getToken();
    const onError = useDebugErrorHandler();

    const { mutateAsync } = useHttpMutation('DELETE /assemblies/:id/pcb', {
        snackbarMessage: null,
    });

    return useMutation({
        mutationKey: UPLOAD_PCB_FILE_MUTATION_KEY,
        mutationFn: async (files: File[]) => {
            const pcb = await getOrCreatePcbId(assemblyId, pcbId, token);
            const response = await retry(() => uploadPcbFiles(pcb, files, token), 5);

            if (response instanceof FileNotAcceptedError) {
                try {
                    await mutateAsync({ pathParams: { id: assemblyId } });
                } catch (error) {
                    // silently handle the error since user can recover from this
                    throwErrorUnlessProduction(error);
                }

                snackbar.enqueueSnackbar(t`We don't support the file format of ${response.fileName}.`, {
                    variant: 'error',
                });

                return;
            }

            return { pcbId: pcb };
        },
        onError: async (error) => {
            onError(error);
        },
        onSuccess: async (data) => {
            if (data && data.pcbId) {
                await Promise.allSettled([
                    queryClient.invalidateQueries({ queryKey: httpQueryKey('POST /assemblies/bulk') }),
                    queryClient.invalidateQueries({ queryKey: httpQueryKey('GET /ems/pcb/v2/pcbs/:pcbId') }),
                    // Invalidate descendants because of added component for the PCB
                    queryClient.invalidateQueries({
                        queryKey: httpQueryKey('GET /assemblies/:assemblyId/descendants'),
                    }),
                    queryClient.invalidateQueries({
                        queryKey: httpQueryKey('GET /assemblies/:assemblyId/descendants-summary'),
                    }),
                    queryClient.invalidateQueries({
                        queryKey: httpQueryKey('POST /assemblies/:id/pcb/:pcbId/offer-state'),
                    }),
                ]);

                // We need to wait a bit because stackrate will publish an event asynchronously
                // that triggers a search for new pcb offers
                await sleep(2000);
                await Promise.allSettled([
                    queryClient.invalidateQueries({ queryKey: httpQueryKey('GET /sourcing/progress/:rfqId') }),
                ]);

                uploadPcbAnalytic({
                    assemblyId,
                    rfqId,
                    pcbId: data.pcbId,
                });
            }
        },
    });
}

export function useMutationCreatePCBWithoutFile(
    viewContext: ViewContext,
    assemblyId: string,
    rfqId: string,
    pcbId?: string,
) {
    const queryClient = useQueryClient();
    const token = getToken();
    const onError = useDebugErrorHandler();
    const navigate = useNavigate();

    return useMutation({
        mutationKey: UPLOAD_PCB_FILE_MUTATION_KEY,
        mutationFn: async () => {
            const pcb = await getOrCreatePcbId(assemblyId, pcbId, token, true);

            return { pcbId: pcb };
        },
        onError: async (error) => {
            onError(error);
        },
        onSuccess: async (data) => {
            if (data && data.pcbId) {
                await Promise.allSettled([
                    queryClient.invalidateQueries({ queryKey: httpQueryKey('GET /ems/pcb/v2/pcbs/:pcbId') }),
                    // Invalidate descendants because of added component for the PCB
                    queryClient.invalidateQueries({
                        queryKey: httpQueryKey('GET /assemblies/:assemblyId/descendants'),
                    }),
                    queryClient.invalidateQueries({
                        queryKey: httpQueryKey('GET /assemblies/:assemblyId/descendants-summary'),
                    }),
                ]);

                uploadPcbAnalytic({
                    assemblyId,
                    rfqId,
                    pcbId: data.pcbId,
                });

                const specificationRoute =
                    viewContext.type === 'WithinRfQ'
                        ? route('/rfqs/:rfqId/bom/assembly/:assemblyId/pcb', {
                              rfqId,
                              assemblyId,
                          })
                        : route('/assemblies/:assemblyId/pcb', { assemblyId }, { rfqId });

                navigate(specificationRoute);
            }
        },
    });
}

export function useMutationUploadMissingPcbFiles({ pcbId }: { pcbId: string }) {
    const queryClient = useQueryClient();
    const snackbar = useSnackbar();
    const token = getToken();
    const onError = useDebugErrorHandler();

    return useMutation({
        mutationFn: async (files: File[]) => {
            return await uploadMissingFiles(pcbId, files, token);
        },
        onError: (error) => {
            snackbar.enqueueSnackbar(t`There was an error while uploading the missing PCB files.`, {
                variant: 'error',
            });
            onError(error);
        },
        onSuccess: async (data: UploadFileResponse) => {
            if (data.files && data.files.length > 0) {
                snackbar.enqueueSnackbar(t`Files were uploaded successfully`, { variant: 'success' });
            }

            if (data.warnings && data.warnings.length > 0) {
                data.warnings.forEach((warning: string) => {
                    snackbar.enqueueSnackbar(warning, { variant: 'warning' });
                });
            }

            await Promise.allSettled([
                queryClient.invalidateQueries({ queryKey: httpQueryKey('POST /assemblies/bulk') }),
                queryClient.invalidateQueries({ queryKey: httpQueryKey('GET /ems/pcb/v2/pcbs/:pcbId') }),
            ]);
        },
    });
}

/**
 * Finds a PCBs given an assembly ID.
 */
export function usePcbOfAssembly({ assemblyId }: { assemblyId: string | undefined }) {
    const queryClient = useQueryClient();

    const { pcbDesignItem } = usePCBDesignItemFromAssembly(assemblyId ?? '', isPresent(assemblyId));
    const pcbId = pcbDesignItem?.origin.type === DesignItemOriginTypes.PCB ? pcbDesignItem.origin.data : undefined;

    const result = useHttpQuery(
        'GET /ems/pcb/v2/pcbs/:pcbId',
        {
            pathParams: {
                pcbId: pcbId ?? '',
            },
            requestHeaders: {
                //@ts-ignore
                Accept: 'application/json;version=beta',
            },
        },
        {
            enabled: isPresent(pcbId),
            refetchInterval: ({ state }) => {
                if (!state.data || isPcbSetupWithoutFiles(state.data)) {
                    return Infinity;
                }

                if (isPcbAnalysisInProgress(state.data)) {
                    return 5000;
                }

                return Infinity;
            },
            refetchOnReconnect: true,
            keepPreviousData: true,
        },
    );

    React.useEffect(() => {
        if (result.data && isPcbAnalysisSuccess(result.data)) {
            queryClient.invalidateQueries({ queryKey: httpQueryKey('GET /ems/pcb/v2/pcbs/:pcbId/capabilities') });
        }
    }, [result.data, queryClient]);

    return result;
}

/*
 * Returns the design item corresponding to the pcb in passed assembly.
 *
 * If more than one pcb design item is present throws an error.
 */
export function usePCBDesignItemFromAssembly(
    assemblyId: string,
    enabled: boolean = true,
): {
    pcbDesignItem: DesignItemResponseDTO | undefined;
} {
    const { data: descendantsData } = useDescendants(assemblyId);
    const descendantDesignItems = descendantsData?.data.design_items ?? [];
    // Since design item descendants of assembly also include design items of
    // sub-assemblies we filter out design items that aren't part of the required assembly
    const assemblyDesignItems = descendantDesignItems.filter((designItem) => designItem.assembly === assemblyId);

    const pcbDesignItems = assemblyDesignItems.filter(
        (designItem) => designItem.origin.type === DesignItemOriginTypes.PCB,
    );
    if (pcbDesignItems.length > 1) {
        throwErrorUnlessProduction(
            new Error(
                `Found more than one PCB design item in passed design items. Design Item ids: ${pcbDesignItems.map(
                    (designItem) => designItem.id,
                )}`,
            ),
        );
    }

    return { pcbDesignItem: pcbDesignItems[0] };
}

export function useMutationUpdatePcbOffers() {
    const queryClient = useQueryClient();
    const onError = useDebugErrorHandler();
    const token = getToken();

    return useMutation({
        mutationFn: async (pcbId: string) => {
            await http(
                'POST /offers/pcb/:id/update',
                {
                    pathParams: { id: pcbId },
                },
                token,
            );
        },
        onSuccess: async () => {
            await invalidatePcbOffers(queryClient);
        },
        onError,
    });
}

async function invalidatePcbOffers(queryClient: QueryClient) {
    await Promise.allSettled([
        queryClient.invalidateQueries({ queryKey: httpQueryKey('POST /assemblies/:id/pcb/:pcbId/offer-state') }),
        queryClient.invalidateQueries({ queryKey: httpQueryKey('GET /sourcing/progress/:rfqId') }),
        queryClient.invalidateQueries({ queryKey: httpQueryKey('GET /offers/custom-part/:id') }),
        queryClient.invalidateQueries({ queryKey: httpQueryKey('GET /offers/custom-part') }),
        queryClient.invalidateQueries({ queryKey: httpQueryKey('GET /solutions') }),
    ]);
}

// TODO: This is a temporary fix to avoid sending undefined values to the backend.
// This should be removed once the backend is fixed and supports partial updates.
const cleanUpPcbUpdateRequestBody = (requestBody: PCBV2Update): PCBV2Update => {
    const result = {
        ...requestBody,
        settings: Object.fromEntries(
            Object.entries(requestBody.settings ?? {}).map(([key, value]) => {
                const valueEntries = Object.entries(value ?? {}).map(([subKey, subValue]) => {
                    if (typeof subValue === 'object' && 'value' in subValue && subValue.value === undefined) {
                        return [subKey, undefined];
                    }

                    return [subKey, subValue];
                });

                return [key, Object.fromEntries(valueEntries)];
            }),
        ),
    };

    // @ts-ignore
    return result as PCBV2Update;
};

export function useMutationUpdatePcbSpecification({
    pcbId,
    specificationId,
}: {
    pcbId: string;
    specificationId: string;
}) {
    const queryClient = useQueryClient();
    const token = getToken();

    return useMutation({
        mutationFn: async (requestBody: PCBV2Update) => {
            return http(
                'PUT /ems/pcb/v2/pcbs/:pcb/specifications/:specification',
                {
                    pathParams: { pcb: pcbId, specification: specificationId },
                    queryParams: { removeUndefined: true },
                    requestHeaders: {
                        //@ts-ignore
                        Accept: 'application/json;version=beta',
                    },
                    requestBody: cleanUpPcbUpdateRequestBody(requestBody),
                },
                token,
            );
        },
        onSuccess: async () => {
            await invalidatePcbOffers(queryClient);
            // invalidating capabilities since depend on the value field might vary
            await Promise.allSettled([
                queryClient.invalidateQueries({
                    queryKey: httpQueryKey('POST /pcb/manufacturers/instant-price-available'),
                }),
                queryClient.invalidateQueries({ queryKey: httpQueryKey('GET /ems/pcb/v2/pcbs/:pcbId/capabilities') }),
                queryClient.invalidateQueries({ queryKey: httpQueryKey('GET /ems/pcb/v2/pcbs/:pcbId') }),
                queryClient.invalidateQueries({ queryKey: httpQueryKey('GET /assemblies/:assemblyId/state') }),
                queryClient.invalidateQueries({ queryKey: httpQueryKey('POST /rfqs/:rfqId/customer-portal') }),
                queryClient.invalidateQueries({ queryKey: httpQueryKey('GET /custom-part-alerts/:partId') }),
                queryClient.invalidateQueries({ queryKey: httpQueryKey('GET /panels') }),
            ]);

            // We need to wait a bit because stackrate will publish an event asynchronously
            // that triggers a search for new pcb offers
            await sleep(500);
            await Promise.allSettled([
                queryClient.invalidateQueries({
                    queryKey: httpQueryKey('POST /assemblies/:id/pcb/:pcbId/offer-state'),
                }),
                queryClient.invalidateQueries({ queryKey: httpQueryKey('GET /sourcing/progress/:rfqId') }),
            ]);
        },
    });
}

export function useMutationUpdatePcbSpecificationStatus({
    pcbId,
    specificationId,
}: {
    pcbId: string;
    specificationId: string;
}) {
    const queryClient = useQueryClient();
    const token = getToken();

    return useMutation({
        mutationFn: async (status: SpecificationStatusEnum) => {
            return http(
                'PUT /ems/pcb/v2/pcbs/:pcb/specifications/:specification/status',
                {
                    pathParams: { pcb: pcbId, specification: specificationId },
                    requestBody: {
                        status,
                    },
                    requestHeaders: {
                        //@ts-ignore
                        Accept: 'application/json;version=beta',
                    },
                },
                token,
            );
        },
        onSuccess: async () => {
            await Promise.allSettled([
                queryClient.invalidateQueries({ queryKey: httpQueryKey('GET /ems/pcb/v2/pcbs/:pcbId') }),
            ]);
        },
    });
}

export function useMutationUpdatePCBPlacements({ assemblyId }: { assemblyId: string }) {
    const token = getToken();
    const queryClient = useQueryClient();
    const onError = useDebugErrorHandler();

    return useMutation({
        mutationFn: async (pcbSides: number) => {
            return http(
                'PATCH /pcb-sides',
                {
                    queryParams: { assembly_id: assemblyId },
                    requestBody: {
                        pcb_sides: pcbSides,
                    },
                },
                token,
            );
        },
        onSuccess: async () => {
            await invalidateAllQueriesForEndpoint('GET /pcb-sides', queryClient);
        },
        onError,
    });
}

/**
 * Finds a PCB given a PCB ID.
 * @param pcbId - The PCB ID.
 * @param enabled - Whether the query is enabled.
 * @returns
 */
export const usePcb = (pcbId: string | undefined, enabled: boolean = true) => {
    return useHttpQuery(
        'GET /ems/pcb/v2/pcbs/:pcbId',
        {
            pathParams: { pcbId: pcbId ?? '' },
            requestHeaders: {
                //@ts-ignore
                Accept: 'application/json;version=beta',
            },
        },
        { enabled: isPresent(pcbId) && enabled },
    );
};

export const usePcbValues = (pcbId?: string) => {
    const { data: pcb } = usePcb(pcbId ?? '', Boolean(pcbId));

    if (pcb && isPcbSetupWithoutFiles(pcb)) {
        const { width, height } = extractPcbWidthAndHeight(pcb);
        const specification = pcb.specifications[0]?.settings ?? {
            board: {},
            layerStack: {},
            mechanical: {},
        };
        return {
            pcb,
            frontPreviewUrl: new URL('', window.location.origin),
            rearPreviewUrl: new URL('', window.location.origin),
            width,
            height,
            numLayers: specification.layerStack.layercount,
            layerStackType: specification.layerStack.layerstackType,
        };
    }

    const path = pcb?.specifications[0]?.previews.front?.path ?? '';
    const key = pcb?.specifications[0]?.previews.front?.key ?? '';
    const frontPreviewUrl = new URL(path, window.location.origin);
    frontPreviewUrl.searchParams.set('k', key);

    const backPath = pcb?.specifications[0]?.previews.rear?.path ?? '';
    const backKey = pcb?.specifications[0]?.previews.rear?.key ?? '';
    const rearPreviewUrl = new URL(backPath, window.location.origin);
    rearPreviewUrl.searchParams.set('k', backKey);

    return {
        pcb,
        frontPreviewUrl,
        rearPreviewUrl,
        width: pcb?.properties.board.boardWidth?.value,
        height: pcb?.properties.board.boardHeight?.value,
        numLayers: pcb?.properties.layerStack.layercount,
        layerStackType: pcb?.properties.layerStack.layerstackType,
    };
};
