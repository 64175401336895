import * as z from 'zod';
import { ApiIntegrationStatusRuntype } from './3rdPartyBackendTypes';

export interface HeilindCredentialsDTO extends z.infer<typeof HeilindCredentialsDTORuntype> {}
export const HeilindCredentialsDTORuntype = z.object({
    customer_account_number: z.string(),
    division: z.string(),
});

export interface HeilindCredentialsInputDTO extends z.infer<typeof HeilindCredentialsInputDTORuntype> {}
export const HeilindCredentialsInputDTORuntype = z.object({
    customer_account_number: z.string().optional(),
    division: z.string().optional(),
});

export interface HeilindResponseBodyDTO extends z.infer<typeof HeilindResponseBodyDTORuntype> {}
export const HeilindResponseBodyDTORuntype = z.object({
    data: HeilindCredentialsDTORuntype.nullable(),
    status: ApiIntegrationStatusRuntype,
});
