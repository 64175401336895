import * as z from 'zod';
import { endpoint } from '../http/endpoint';
import { MouserCredentialsInputDTORuntype, MouserResponseBodyDTORuntype } from './mouserBackendTypes';

export const mouserEndpoints = {
    'GET /3rdparty/mouser/credentials': endpoint({
        description: 'Returns credentials for mouser integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: MouserResponseBodyDTORuntype,
    }),
    'POST /3rdparty/mouser/credentials': endpoint({
        description: 'Uploads credentials for mouser API integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: MouserCredentialsInputDTORuntype,
        responseBody: MouserResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/mouser/credentials'],
    }),
    'PATCH /3rdparty/mouser/credentials': endpoint({
        description: 'Updates credentials for mouser API integratoin',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: MouserCredentialsInputDTORuntype,
        responseBody: MouserResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/mouser/credentials'],
    }),
    'DELETE /3rdparty/mouser/credentials': endpoint({
        description: 'Deletes existing credentials for mouser API integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({ deleted: z.number() }),
        invalidates: ['GET /3rdparty/mouser/credentials'],
    }),
};
