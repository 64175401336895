import { t, Trans } from '@lingui/macro';
import { colorSystem, Flexbox, Link, ReleaseTag, Text } from '@luminovo/design-system';
import { RfqDTO } from '@luminovo/http-client';
import { Box } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useAllCustomerUsers, useContributors } from '../../../../../resources/user/userHandler';
import { Skeleton } from '../../../../Spinners';
import {
    ContributorOption,
    getCustomerPortalEducationLink,
    sortAndConvertToOptions,
    sortUsersByEmail,
} from '../../ContributorsFormUtils';
import { ContributorAutoCompleteFormInput } from './ContributorAutocompleteInput';
import { ContributorItem } from './ContributorItem';
import { ContributorSubmitButton } from './ContributorSubmitButton';
import { EmptyContributorsIcon } from './EmptyContributorsIcon';
import { useMutationUpdateContributors } from './useMutationUpdateContributors';

type ExternalContributorsFormState = {
    contributors: ContributorOption[];
};

const useExternalContributorsData = (rfq: RfqDTO) => {
    const { isLoading: isLoadingUsers, data: usersData = [] } = useAllCustomerUsers(rfq.customer);
    const userOptions = sortAndConvertToOptions(usersData ?? []);

    const { isLoading: isLoadingContributorsData, data: contributorsData } = useContributors(rfq.id);
    const contributors = sortUsersByEmail(contributorsData?.external ?? []);
    const invited = sortUsersByEmail(contributorsData?.invited ?? []);
    const contributorIds =
        [...(contributorsData?.external ?? []), ...(contributorsData?.internal ?? [])].map((c) => c.id) ?? [];

    return {
        isLoading: isLoadingUsers || isLoadingContributorsData,
        userOptions: userOptions.filter((user) => !contributorIds.includes(user.id)),
        contributors,
        invited,
        contributorIds,
    };
};

export const ExternalContributorsForm = ({
    rfq,
    onInviteExternalContributor,
}: {
    rfq: RfqDTO;
    onInviteExternalContributor: () => void;
}) => {
    const { contributors, invited, contributorIds, isLoading, userOptions } = useExternalContributorsData(rfq);
    const defaultValues: ExternalContributorsFormState = {
        contributors: [],
    };

    const formReturn = useForm<ExternalContributorsFormState>({
        reValidateMode: 'onChange',
        mode: 'onBlur',
        defaultValues,
    });

    const { handleSubmit, reset } = formReturn;

    const { mutateAsync, isPending: isSubmitting } = useMutationUpdateContributors(rfq.id);
    const onSubmit = async (form: ExternalContributorsFormState) => {
        await mutateAsync({
            contributorIds: Array.from(new Set(contributorIds.concat(form.contributors.map(({ id }) => id)))),
        });
        reset();
    };

    const handleRemove = async (contributorId: string) => {
        await mutateAsync({
            contributorIds: contributorIds.filter((id) => id !== contributorId),
        });
    };

    return (
        <Flexbox flexDirection={'column'} gap={'16px'}>
            <Flexbox flexDirection={'column'} gap={'4px'}>
                <Flexbox gap={'8px'} alignItems={'center'}>
                    <Text variant="h4" style={{ color: colorSystem.neutral[8] }}>
                        <Trans>External contributors</Trans>
                    </Text>
                    <ReleaseTag color="violet" label={t`NEW`} />
                </Flexbox>
                <Text color={colorSystem.neutral[6]}>
                    <Trans>
                        External contributors will receive notifications about public comments, mentions and when a new
                        quotation is uploaded in this RfQ. They can only see the Design module.{' '}
                        <Link href={getCustomerPortalEducationLink()} target="_blank" attention="high">
                            Learn more
                        </Link>
                        .
                    </Trans>
                </Text>
            </Flexbox>
            <FormProvider {...formReturn}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    {isLoading ? (
                        <Skeleton variant="rectangular" height="40px" />
                    ) : (
                        <Flexbox flexDirection={'column'} gap={'16px'}>
                            <Box gap={'12px'} display="grid" gridTemplateColumns="1fr auto">
                                <ContributorAutoCompleteFormInput
                                    options={userOptions}
                                    TextFieldProps={{ placeholder: t`Add or invite users` }}
                                    onAddNewUser={onInviteExternalContributor}
                                    AutocompleteProps={{ freeSolo: true }}
                                />
                                <ContributorSubmitButton isLoading={isSubmitting} />
                            </Box>
                            {contributors.length < 1 && invited.length < 1 ? (
                                <Flexbox alignItems={'center'} flexDirection={'column'} gap={8}>
                                    <EmptyContributorsIcon />
                                    <Text>
                                        <Trans>No external contributors</Trans>
                                    </Text>
                                </Flexbox>
                            ) : (
                                <Flexbox flexDirection={'column'} gap={'16px'}>
                                    {contributors.map((contributor, index) => (
                                        <ContributorItem
                                            disabled={isLoading}
                                            onRemove={handleRemove}
                                            contributor={contributor}
                                            key={index}
                                        />
                                    ))}

                                    {invited.map((invitedContributor, index) => (
                                        <ContributorItem contributor={invitedContributor} key={index} />
                                    ))}
                                </Flexbox>
                            )}
                        </Flexbox>
                    )}
                </form>
            </FormProvider>
        </Flexbox>
    );
};
