import { Trans, t } from '@lingui/macro';
import { Checkbox, Flexbox, Tag, Text } from '@luminovo/design-system';
import { styled } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { BomItemFormState } from '../../SpecificationTypeForms/BomItemFormState';

export const DnpController = ({ submitForm, isEditable }: { submitForm: () => void; isEditable: boolean }) => {
    const { control, setValue, getValues } = useFormContext<BomItemFormState>();

    if (!isEditable) {
        const isDnp = getValues('doNotPlace');
        if (!isDnp) {
            return null;
        }
        return <Tag color="neutral" attention="low" label={t`Do not place`} />;
    }

    return (
        <ControllerContainer>
            <Controller
                name="doNotPlace"
                control={control}
                render={({ field }): JSX.Element => {
                    return (
                        <Checkbox
                            {...field}
                            checked={Boolean(field.value)}
                            disabled={!isEditable}
                            size="small"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                                setValue('doNotPlace', checked, {
                                    shouldDirty: true,
                                    shouldValidate: true,
                                    shouldTouch: true,
                                });
                                if (checked && getValues('isConsigned')) {
                                    // If the BOM item is marked as do not place, it should not be consigned
                                    setValue('isConsigned', false, {
                                        shouldDirty: true,
                                        shouldValidate: true,
                                        shouldTouch: true,
                                    });
                                }
                                submitForm();
                            }}
                        />
                    );
                }}
            />
            <Text style={{ whiteSpace: 'nowrap' }}>
                <Trans>Do not place</Trans>
            </Text>
        </ControllerContainer>
    );
};

export const ControllerContainer = styled(Flexbox)({
    gap: '6px',
    alignItems: 'center',
});
