import * as z from 'zod';
import { PCBV2BoardPropertiesValuesRuntype } from './pcbBackendTypes';

const BoundingBoxRuntype = z.object({
    x: z.string(),
    y: z.string(),
    width: z.string(),
    height: z.string(),
});

export type ExtractedAttributeValueDTO = z.infer<typeof ExtractedAttributeValueRuntype>;
const ExtractedAttributeValueRuntype = z.union([
    z.object({ type: z.literal('Str'), data: PCBV2BoardPropertiesValuesRuntype }),
    z.object({ type: z.literal('Int'), data: PCBV2BoardPropertiesValuesRuntype }),
    z.object({ type: z.literal('Bool'), data: PCBV2BoardPropertiesValuesRuntype }),
    z.object({ type: z.literal('StrArray'), data: PCBV2BoardPropertiesValuesRuntype }),
    z.object({ type: z.literal('Float'), data: PCBV2BoardPropertiesValuesRuntype }),
]);

export type ExtractedAttributeDTO = z.infer<typeof ExtractedAttributeRuntype>;
const ExtractedAttributeRuntype = z.object({
    name: z.string(),
    value: ExtractedAttributeValueRuntype,
    confidence: z.string().optional(),
});

export type MergedResultDTO = z.infer<typeof MergedResultRuntype>;
const MergedResultRuntype = z.object({
    id: z.string(),
    page_number: z.number(),
    location_on_page: BoundingBoxRuntype,
    attributes: z.array(ExtractedAttributeRuntype),
});

export type ExtractionPCBV2File = z.infer<typeof ExtractionPCBV2FileRuntype>;
export const ExtractionPCBV2FileRuntype = z.object({
    id: z.string().optional(),
    name: z.string(),
});

export type ExtractionResultsDTO = z.infer<typeof ExtractionResultsRuntype>;
export const ExtractionResultsRuntype = z.object({
    id: z.string(),
    pcb: z.string(),
    merged_results: z.array(MergedResultRuntype),
    file: ExtractionPCBV2FileRuntype,
});

export const ExtractionResultsPostRuntype = z.object({
    pcb: z.string(),
    merged_results: z.array(MergedResultRuntype),
    file: ExtractionPCBV2FileRuntype,
});
