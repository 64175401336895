import { Trans, t } from '@lingui/macro';
import {
    ButtonGroup,
    ButtonGroupItem,
    Dialog,
    DialogTitle,
    StickyLayout,
    Text,
    body,
    colorSystem,
    h5,
} from '@luminovo/design-system';
import { Box, styled } from '@mui/material';
import { useCallback, useReducer, useState } from 'react';
import {
    Attribute,
    ComparePartsDialogAction,
    ComparePartsDialogProps,
    ComparePartsDialogState,
    DialogState,
    UseComparePartsDialogState,
} from './types';

export const MAX_COLUMN_WIDTH = '348px';

export function CompareItemsDialog<TData, TComparableData, TContext>({
    dialogState,
    isOpen,
    onClose,
}: ComparePartsDialogProps<TData, TComparableData, TContext>): JSX.Element {
    const { state, dispatch } = dialogState;
    return (
        <>
            <DefaultDialog open={isOpen} maxWidth="lg" fullWidth={true}>
                <StickyLayout boxSizing="border-box">
                    <DialogTitle
                        title={t`Part comparison`}
                        center={
                            <ButtonGroup size="small">
                                <ButtonGroupItem
                                    selected={state.viewMode === 'show-all'}
                                    onClick={() => dispatch({ type: 'setViewMode', viewMode: 'show-all' })}
                                >
                                    <Trans>Show all</Trans>
                                </ButtonGroupItem>
                                <ButtonGroupItem
                                    selected={state.viewMode === 'differences-only'}
                                    onClick={() => dispatch({ type: 'setViewMode', viewMode: 'differences-only' })}
                                >
                                    <Trans>Differences only</Trans>
                                </ButtonGroupItem>
                            </ButtonGroup>
                        }
                        onClose={() => onClose()}
                        overrides={{ TitleComponent }}
                        style={{ borderBottom: `1px solid ${colorSystem.neutral[2]}` }}
                    />
                </StickyLayout>
                <Box height="100%">
                    <ComparePartsTable dialogState={dialogState} />
                </Box>
            </DefaultDialog>
        </>
    );
}

export function useComparePartsDialogState<TData, TComparableData, TContext>({
    items,
    comparableItems,
    rows,
    sharedContext,
}: UseComparePartsDialogState<TData, TComparableData, TContext>): DialogState<TData, TComparableData, TContext> {
    const reducer = createReducer<TData>();
    const [state, dispatch] = useReducer(reducer, { selectedItem: items[0], viewMode: 'show-all' });

    return { state, dispatch, items, comparableItems, rows, sharedContext };
}

export const createReducer =
    <TData,>() =>
    (
        state: ComparePartsDialogState<TData>,
        action: ComparePartsDialogAction<TData>,
    ): ComparePartsDialogState<TData> => {
        switch (action.type) {
            case 'setSelectedItem':
                return { ...state, selectedItem: action.selectedItem };
            case 'setViewMode':
                return { ...state, viewMode: action.viewMode };
            default:
                return state;
        }
    };

function RenderAttribute<TData, TComparableData, TContext>({
    index,
    row,
    state,
    dispatch,
    comparableItems,
    sharedContext,
}: {
    index: string;
    row: Attribute<TData, TComparableData, TContext>;
    state: ComparePartsDialogState<TData>;
    dispatch: React.Dispatch<ComparePartsDialogAction<TData>>;
    comparableItems: TComparableData[];
    sharedContext: TContext;
}): JSX.Element | null {
    if (
        !row.isVisible(
            { index, data: state.selectedItem, comparableData: comparableItems, dialogState: state, dispatch },
            sharedContext,
        )
    ) {
        return null;
    }
    return <td>{row.label}</td>;
}

function RenderItem<TData, TComparableData, TContext>({
    index,
    row,
    state,
    dispatch,
    item,
    comparableItems,
    sharedContext,
}: {
    index: number;
    row: Attribute<TData, TComparableData, TContext>;
    state: ComparePartsDialogState<TData>;
    dispatch: React.Dispatch<ComparePartsDialogAction<TData>>;
    item: TData;
    comparableItems: TComparableData[];
    sharedContext: TContext;
}): JSX.Element | null {
    if (
        !row.isVisible(
            { data: state.selectedItem, comparableData: comparableItems, index, dialogState: state, dispatch },
            sharedContext,
        )
    ) {
        return null;
    }
    return (
        <td id={row.id} align="center">
            {row.renderItem(
                { data: item, comparableData: comparableItems, dialogState: state, dispatch, index },
                sharedContext,
            )}
        </td>
    );
}

function RenderComparableItem<TData, TComparableData, TContext = undefined>({
    index,
    rows,
    state,
    dispatch,
    comparableItem,
    comparableItems,
    sharedContext,
}: {
    index: number;
    rows: Attribute<TData, TComparableData, TContext>[];
    state: ComparePartsDialogState<TData>;
    dispatch: React.Dispatch<ComparePartsDialogAction<TData>>;
    comparableItem: TComparableData;
    comparableItems: TComparableData[];
    sharedContext: TContext;
}): JSX.Element {
    const [isColumnHovered, setColumnHovered] = useState(false);
    const handleMouseEnter = useCallback(() => {
        setColumnHovered(true);
    }, []);

    const handleMouseLeave = useCallback(() => {
        setColumnHovered(false);
    }, []);

    return (
        <tr
            style={{ width: MAX_COLUMN_WIDTH }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            key={index}
        >
            {rows.map((row, i) => {
                if (
                    !row.isVisible(
                        {
                            data: state.selectedItem,
                            comparableData: comparableItems,
                            index,
                            dialogState: state,
                            dispatch,
                        },
                        sharedContext,
                    )
                ) {
                    return null;
                }
                return (
                    <td key={i} align="center">
                        {isColumnHovered && row.renderComparableItemOnHover
                            ? row.renderComparableItemOnHover(comparableItem, sharedContext)
                            : row.renderComparableItem(comparableItem, sharedContext)}
                    </td>
                );
            })}
        </tr>
    );
}

function ComparePartsTable<TData, TComparableData, TContext>({
    dialogState,
}: {
    dialogState: DialogState<TData, TComparableData, TContext>;
}): JSX.Element {
    const { state, dispatch, comparableItems, rows, sharedContext } = dialogState;
    return (
        <DefaultTable>
            <thead>
                <tr />
                <tr style={{ textAlign: 'left', padding: '20px 24px' }}>
                    <th>
                        <Text variant="h3" style={{ textAlign: 'left' }}>
                            <Trans>Approved parts</Trans>
                        </Text>
                    </th>
                </tr>
                <tr style={{ textAlign: 'left', padding: '20px 24px' }}>
                    <th>
                        <Text variant="h3">
                            <Trans>Part alternatives</Trans>
                        </Text>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    {rows.map((row) => {
                        return (
                            <RenderAttribute
                                key={row.id}
                                index={row.id}
                                row={row}
                                state={state}
                                dispatch={dispatch}
                                comparableItems={comparableItems}
                                sharedContext={sharedContext}
                            />
                        );
                    })}
                </tr>
                <tr>
                    {rows.map((row, index) => {
                        return (
                            <RenderItem
                                key={row.id}
                                row={row}
                                state={state}
                                dispatch={dispatch}
                                item={state.selectedItem}
                                comparableItems={comparableItems}
                                sharedContext={sharedContext}
                                index={index}
                            />
                        );
                    })}
                </tr>
                {comparableItems.map((comparableItem, index) => {
                    return (
                        <RenderComparableItem
                            key={index}
                            index={index}
                            rows={rows}
                            state={state}
                            dispatch={dispatch}
                            comparableItem={comparableItem}
                            comparableItems={comparableItems}
                            sharedContext={sharedContext}
                        />
                    );
                })}
            </tbody>
        </DefaultTable>
    );
}

const TitleComponent: React.FunctionComponent<{ title: React.ReactNode }> = ({ title }) => {
    return <Text variant="h4">{title}</Text>;
};

const DefaultDialog = styled(Dialog)({
    '& .MuiDialog-container': {
        '& .MuiPaper-root': {
            height: '80%',
            width: '100%',
            maxWidth: '1600px',
            boxSizing: 'border-box',
        },
    },
});

const DefaultTable = styled('table')({
    display: 'table',
    borderCollapse: 'collapse',
    height: '100%',
    '& tr': {
        ...body,
        color: colorSystem.neutral[9],
        display: 'table-cell',
        maxWidth: MAX_COLUMN_WIDTH,
        borderTop: `1px solid transparent`,
        borderLeft: `1px solid transparent`,
        borderBottom: `1px solid transparent`,
        verticalAlign: 'top !important',
    },
    '& tr:last-of-type': { borderRight: `1px solid transparent` },
    '& tr:hover': {
        backgroundColor: colorSystem.neutral[0],
        border: `1px solid ${colorSystem.neutral[1]}`,
    },
    '& tr td': {
        display: 'block',
    },
    '& tr:nth-of-type(1)': {
        ...h5,
        textAlign: 'left',
        color: colorSystem.neutral[7],
        backgroundColor: colorSystem.neutral[1],
        width: '200px',
    },
    '& tr:nth-of-type(2)': {
        ...body,
        color: colorSystem.neutral[9],
        backgroundColor: colorSystem.neutral[1],
        width: MAX_COLUMN_WIDTH,
    },
    '& tr:nth-of-type(n+2)': { textAlign: 'center' },
    '& td': {
        boxSizing: 'border-box',
        verticalAlign: 'middle',
        height: '56px',
        padding: '20px 24px',
        maxWidth: MAX_COLUMN_WIDTH,
    },
    '& td:nth-of-type(1)': { height: '168px' },
    '& td:nth-of-type(4)': {
        height: '80px',
        maxWidth: MAX_COLUMN_WIDTH,
        /* eslint-disable spellcheck/spell-checker */
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 3,
        /* eslint-enable spellcheck/spell-checker */
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
});
