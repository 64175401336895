import { t, Trans } from '@lingui/macro';
import {
    colorSystem,
    Flexbox,
    StickyLayout,
    TertiaryIconButton,
    Text,
    usePersistedState,
} from '@luminovo/design-system';
import { PCBV2, UserType } from '@luminovo/http-client';
import { KeyboardTabRounded } from '@mui/icons-material';
import { useUserType } from '../../../../components/contexts/CurrentUserDetailsContext';
import {
    expandPcbSidebarAnalytics,
    pcbTabChangeAnalytics,
    PCBTabTypes,
} from '../../../../resources/pcb/analytics/analytic';
import { route } from '../../../../utils/routes';
import { ViewContext } from '../../../Bom/components/ModuleTableData';
import { formatRouteBasedOnViewContext } from '../../utils/formatRoutebasedOnViewContext';
import { SidebarLink } from './components/SidebarLink';
import { pcbSidebarLinks } from './utils/pcbSidebarLinks';

export function PcbSidebar({
    assemblyId,
    rfqId,
    pcb,
    viewContext,
}: {
    assemblyId: string;
    rfqId: string;
    pcb: PCBV2;
    viewContext: ViewContext;
}) {
    const [showPcbSidebar, setShowPcbSidebar] = usePersistedState<'true' | 'false'>(
        'pcb-side-bar',
        'false',
        localStorage,
    );

    const isOpen = showPcbSidebar === 'true';
    const isCustomer = useUserType() === UserType.Customer;
    const filteredPcbSidebarLinks = pcbSidebarLinks.filter((link) =>
        isCustomer ? link.enableForCustomers === true : true,
    );

    const handleTogglePcbSidebar = () => {
        if (isOpen) {
            setShowPcbSidebar('false');
            return;
        }

        setShowPcbSidebar('true');
        expandPcbSidebarAnalytics({ assemblyId, pcbId: pcb.id, rfqId });
    };

    const handleTrackAnalytics = (tab: PCBTabTypes) => {
        pcbTabChangeAnalytics({ pcbId: pcb.id, rfqId, assemblyId }, tab);
    };

    return (
        <StickyLayout
            id={'pcb-side-bar'}
            style={{
                borderRight: `1px solid ${colorSystem.neutral[2]}`,
                backgroundColor: 'white',
                zIndex: 3,
                height: '100%',
                paddingInline: '12px',
                width: isOpen ? '200px' : '64px',
                boxSizing: 'border-box',
                transition: 'all 0.2s ease-in',
                gap: '12px',
            }}
        >
            <Flexbox justifyContent={'space-between'} alignItems={'center'} style={{ marginBlockStart: '6px' }}>
                {isOpen && <Text variant={'h2'}>{t`PCB`}</Text>}
                <TertiaryIconButton onClick={handleTogglePcbSidebar}>
                    <KeyboardTabRounded
                        style={{ transform: isOpen ? 'rotate(180deg)' : undefined }}
                        fontSize="inherit"
                    />
                </TertiaryIconButton>
            </Flexbox>

            {isOpen && (
                <Text variant="body-small-semibold" style={{ marginBlockStart: '32px', color: colorSystem.neutral[8] }}>
                    <Trans>Workflow</Trans>
                </Text>
            )}
            {filteredPcbSidebarLinks
                .filter((link) => link.isWorkflow)
                .map((link) => (
                    <SidebarLink
                        pcbId={pcb.id}
                        assemblyId={assemblyId}
                        key={link.id}
                        link={link}
                        onClick={() => handleTrackAnalytics(link.type)}
                        to={route(formatRouteBasedOnViewContext(link.route, viewContext), { rfqId, assemblyId })}
                        viewContext={viewContext}
                        isOpen={isOpen}
                        showRouteStatus
                    />
                ))}

            {isOpen && (
                <Text variant="body-small-semibold" style={{ marginBlockStart: '40px', color: colorSystem.neutral[8] }}>
                    <Trans>Tools</Trans>
                </Text>
            )}
            {filteredPcbSidebarLinks
                .filter((link) => link.isWorkflow === false)
                .map((link) => (
                    <SidebarLink
                        key={link.id}
                        assemblyId={assemblyId}
                        pcbId={pcb.id}
                        link={link}
                        onClick={() => handleTrackAnalytics(link.type)}
                        to={route(formatRouteBasedOnViewContext(link.route, viewContext), { rfqId, assemblyId })}
                        viewContext={viewContext}
                        isOpen={isOpen}
                    />
                ))}
        </StickyLayout>
    );
}
