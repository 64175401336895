import * as z from 'zod';

import { CurrencyRuntype, MonetaryValueBackendRuntype, QuantityUnitDTORuntype } from '../backendTypes';
import { OfferTypeRuntype } from '../driver/driverCalculationBackendTypes';
import { PackagingRuntype } from '../offer/Packaging';
import { AvailabilityRuntype } from '../offer/availablityBackendTypes';
import { ConditionRuntype, ScrapRuntype } from '../scrap/scrapBackendTypes';

const SignatureRuntype = z.union([
    z.object({
        moq: z.number(),
        mpq: z.number(),
    }),
    z.object({
        availability: z.null().or(AvailabilityRuntype),
        total_offered_quantity: z.null().or(z.number()),
        moq: z.number(),
    }),
]);
export type SignatureDTO = z.infer<typeof SignatureRuntype>;

const OfferIdRuntype = z.object({
    offer_type: OfferTypeRuntype,
    offer: z.string(),
});

export const CostRuntype = z.object({ preferred: MonetaryValueBackendRuntype, original: MonetaryValueBackendRuntype });
export type CostDTO = z.infer<typeof CostRuntype>;

const CostFromRuleRuntype = z.object({
    total_cost_of_ownership_rule: z.string(),
    cost: CostRuntype,
});

export type CostFromRuleDTO = z.infer<typeof CostFromRuleRuntype>;

const CostsFromRulesRuntype = z.object({
    cost: CostRuntype,
    costs_from_rules: z.array(CostFromRuleRuntype),
});

const EvaluatedTcoCostAutomaticRuntype = z.object({
    type: z.literal('Automatic'),
    data: CostsFromRulesRuntype,
});

const ManualTcoCostRuntype = z.object({
    automatic: CostsFromRulesRuntype.nullable(),
    manual: CostRuntype,
});

const EvaluatedTcoCostManualRuntype = z.object({
    type: z.literal('Manual'),
    data: ManualTcoCostRuntype,
});

const EvaluatedTcoCostRuntype = z.union([EvaluatedTcoCostAutomaticRuntype, EvaluatedTcoCostManualRuntype]);

export type EvaluatedTcoCostDTO = z.infer<typeof EvaluatedTcoCostRuntype>;

const TcoCostBreakdownRuntype = z.object({
    packaging_cost: EvaluatedTcoCostRuntype.nullable(),
    discount: EvaluatedTcoCostRuntype.nullable(),
    shipping_cost: EvaluatedTcoCostRuntype.nullable(),
    customs_cost: EvaluatedTcoCostRuntype.nullable(),
    other_cost: EvaluatedTcoCostRuntype.nullable(),
});
export interface TcoCostBreakdownDTO extends z.infer<typeof TcoCostBreakdownRuntype> {}

const TcoCostRuntype = z.object({
    cost: CostRuntype.nullable(),
    breakdown: TcoCostBreakdownRuntype,
});

export type TcoCostDTO = z.infer<typeof TcoCostRuntype>;

export enum SolutionStatus {
    Good = 'Ok', // The backend uses `Ok` and the frontend we use `Good`
    Warning = 'Warning',
    Error = 'Error',
}
export const SolutionStatusRuntype = z.nativeEnum(SolutionStatus);

export enum ConversionRateTypeEnum {
    API = 'API',
    Manual = 'Manual',
}

const ConversionRateRuntype = z.object({
    original_currency: CurrencyRuntype,
    preferred_currency: CurrencyRuntype,
    exchange_rate: z.string(),
    update_date: z.null().or(z.string()),
    rate_type: z.nativeEnum(ConversionRateTypeEnum),
});

const OrderedOneTimeCostRuntype = z.object({
    position: z.number(),
    price: z.object({
        preferred_currency: MonetaryValueBackendRuntype,
        original_currencies: z.record(z.string(), z.string()),
    }),
    description: z.null().or(z.string()),
});

const EvaluatedDefaultFormulaRuntype = z.object({
    kind: z.literal('default'),
    scrap: ScrapRuntype,
});

const EvaluatedAppliedFormulaRuntype = z.object({
    kind: z.literal('applied'),
    scrap: ScrapRuntype,
    condition: ConditionRuntype,
});

const EvaluatedFormulaRuntype = z.union([EvaluatedDefaultFormulaRuntype, EvaluatedAppliedFormulaRuntype]);

const ManualScrapTypeRuntype = z.object({
    type: z.literal('manual'),
});

const NoneScrapTypeRuntype = z.object({
    type: z.literal('none'),
});

const CalculatedScrapTypeRuntype = z.object({
    type: z.literal('calculated'),
    formula: EvaluatedFormulaRuntype,
});

export interface CalculatedScrapType extends z.infer<typeof CalculatedScrapTypeRuntype> {}

const ScrapTypeRuntype = z.union([ManualScrapTypeRuntype, NoneScrapTypeRuntype, CalculatedScrapTypeRuntype]);

export type ScrapType = z.infer<typeof ScrapTypeRuntype>;

export const DerivedScrapQuantityDTORuntype = z.object({
    type: ScrapTypeRuntype,
    amount: z.number(),
});
export type DerivedScrapQuantityDTO = z.infer<typeof DerivedScrapQuantityDTORuntype>;

export enum SolutionTag {
    Customer = 'Customer',
    RfQ = 'RfQ',
    ManualOffer = 'ManualOffer',
    QuotePrice = 'QuotePrice',
    ListPrice = 'ListPrice',
    CustomerNegotiatedPrice = 'CustomerNegotiatedPrice',
    ContractPrice = 'ContractPrice',
    PurchasePrice = 'PurchasePrice',
    StandardPrice = 'StandardPrice',
    Expiring = 'Expiring',
    Expired = 'Expired',
    CreationDate = 'CreationDate',
    LongLeadTime = 'LongLeadTime',
    Old = 'Old',
    Outdated = 'Outdated',
    Unavailable = 'Unavailable',
    UnknownLeadTime = 'UnknownLeadTime',
    OnOrder = 'OnOrder',
    UnitMismatch = 'UnitMismatch',
    OnePoint = 'OnePoint',
    Extrapolated = 'Extrapolated',
    Interpolated = 'Interpolated',
    LowStock = 'LowStock',
    Excess = 'Excess',
    AutoSelected = 'AutoSelected',
    Selected = 'Selected',
    OutdatedManual = 'OutdatedManual',
    Converted = 'Converted',
    SupplierNotPreferred = 'SupplierNotPreferred',
    SupplierNotApproved = 'SupplierNotApproved',
    SupplierPreferred = 'SupplierPreferred',
    SupplierApproved = 'SupplierApproved',
    SupplierExcluded = 'SupplierExcluded',
    Inactive = 'Inactive',
    Inventory = 'Inventory',
    ExternalSupplier = 'ExternalSupplier',
    OneTimeCost = 'OneTimeCost',
    ManualOneTimeCost = 'ManualOneTimeCost',
    UnitCost = 'UnitCost',
    ManualUnitCost = 'ManualUnitCost',
    AggregatedQuantity = 'AggregatedQuantity',
    ScrapQuantity = 'ScrapQuantity',
    Packaging = 'Packaging',
    Consigned = 'Consigned',
    ThirdPartyOrigin = 'ThirdPartyOrigin',
    ExceedsOfferedQuantity = 'ExceedsOfferedQuantity',
    GreatlyExceedsOfferedQuantity = 'GreatlyExceedsOfferedQuantity',
    NoApprovedParts = 'NoApprovedParts',
    ManualStatus = 'ManualStatus',
    InvalidSpecification = 'InvalidSpecification',
    NonCancellableNonReturnable = 'NonCancellableNonReturnable',
    SourcingScenario = 'SourcingScenario',
    ShippingTime = 'ShippingTime',
    PanelMismatch = 'PanelMismatch',
    ManufacturerPreferred = 'ManufacturerPreferred',
}

export type Tag = z.infer<typeof TagRuntype>;
export const TagRuntype = z.union([
    z.object({ tag: z.literal(SolutionTag.ManualOffer) }),
    z.object({ tag: z.literal(SolutionTag.QuotePrice) }),
    z.object({ tag: z.literal(SolutionTag.ListPrice) }),
    z.object({ tag: z.literal(SolutionTag.ContractPrice) }),
    z.object({ tag: z.literal(SolutionTag.CustomerNegotiatedPrice) }),
    z.object({ tag: z.literal(SolutionTag.PurchasePrice) }),
    z.object({ tag: z.literal(SolutionTag.StandardPrice) }),
    z.object({ tag: z.literal(SolutionTag.Inactive) }),
    z.object({ tag: z.literal(SolutionTag.Unavailable) }),
    z.object({ tag: z.literal(SolutionTag.UnknownLeadTime) }),
    z.object({ tag: z.literal(SolutionTag.OnOrder) }),
    z.object({ tag: z.literal(SolutionTag.OnePoint) }),
    z.object({ tag: z.literal(SolutionTag.Extrapolated) }),
    z.object({ tag: z.literal(SolutionTag.Interpolated) }),
    z.object({ tag: z.literal(SolutionTag.AutoSelected) }),
    z.object({ tag: z.literal(SolutionTag.SupplierNotPreferred) }),
    z.object({ tag: z.literal(SolutionTag.SupplierNotApproved) }),
    z.object({ tag: z.literal(SolutionTag.SupplierPreferred) }),
    z.object({ tag: z.literal(SolutionTag.SupplierApproved) }),
    z.object({ tag: z.literal(SolutionTag.SupplierExcluded) }),
    z.object({ tag: z.literal(SolutionTag.Selected) }),
    z.object({ tag: z.literal(SolutionTag.Inventory) }),
    z.object({ tag: z.literal(SolutionTag.ExternalSupplier) }),
    z.object({ tag: z.literal(SolutionTag.Customer), content: z.string() }),
    z.object({ tag: z.literal(SolutionTag.RfQ), content: z.string() }),
    z.object({ tag: z.literal(SolutionTag.Expiring), content: z.string() }),
    z.object({ tag: z.literal(SolutionTag.Expired), content: z.string() }),
    z.object({ tag: z.literal(SolutionTag.CreationDate), content: z.string() }),
    z.object({ tag: z.literal(SolutionTag.LongLeadTime), content: z.string() }),
    z.object({ tag: z.literal(SolutionTag.UnitMismatch), content: z.string() }),
    z.object({ tag: z.literal(SolutionTag.Old), content: z.string() }),
    z.object({ tag: z.literal(SolutionTag.Outdated), content: z.string() }),
    z.object({ tag: z.literal(SolutionTag.OutdatedManual), content: z.array(OfferIdRuntype) }),
    z.object({ tag: z.literal(SolutionTag.LowStock), content: QuantityUnitDTORuntype }),
    z.object({ tag: z.literal(SolutionTag.Excess), content: QuantityUnitDTORuntype }),
    z.object({ tag: z.literal(SolutionTag.Converted), content: ConversionRateRuntype }),
    z.object({ tag: z.literal(SolutionTag.OneTimeCost), content: OrderedOneTimeCostRuntype }),
    z.object({ tag: z.literal(SolutionTag.ManualOneTimeCost), content: OrderedOneTimeCostRuntype }),
    z.object({ tag: z.literal(SolutionTag.UnitCost), content: OrderedOneTimeCostRuntype }),
    z.object({ tag: z.literal(SolutionTag.ManualUnitCost), content: OrderedOneTimeCostRuntype }),
    z.object({ tag: z.literal(SolutionTag.AggregatedQuantity), content: QuantityUnitDTORuntype }),
    z.object({ tag: z.literal(SolutionTag.Packaging), content: PackagingRuntype }),
    z.object({ tag: z.literal(SolutionTag.Consigned) }),
    z.object({ tag: z.literal(SolutionTag.ThirdPartyOrigin), content: z.string() }),
    z.object({ tag: z.literal(SolutionTag.ExceedsOfferedQuantity) }),
    z.object({ tag: z.literal(SolutionTag.GreatlyExceedsOfferedQuantity) }),
    z.object({ tag: z.literal(SolutionTag.NoApprovedParts) }), // This tag does only exist in the frontend
    z.object({ tag: z.literal(SolutionTag.ManualStatus), content: SolutionStatusRuntype }),
    z.object({ tag: z.literal(SolutionTag.InvalidSpecification) }),
    z.object({ tag: z.literal(SolutionTag.ScrapQuantity), content: DerivedScrapQuantityDTORuntype }),
    z.object({ tag: z.literal(SolutionTag.NonCancellableNonReturnable) }),
    z.object({ tag: z.literal(SolutionTag.SourcingScenario), content: z.string() }),
    z.object({ tag: z.literal(SolutionTag.ShippingTime), content: z.number() }),
    z.object({ tag: z.literal(SolutionTag.PanelMismatch) }),
    z.object({ tag: z.literal(SolutionTag.ManufacturerPreferred), content: z.string() }),
]);

export interface SolutionTotalPriceDTO extends z.infer<typeof SolutionTotalPriceDTORuntype> {}
const SolutionTotalPriceDTORuntype = z.object({
    original_total_price: MonetaryValueBackendRuntype,
    effective_total_price: MonetaryValueBackendRuntype,
    original_currency_total_price: MonetaryValueBackendRuntype,
});

const TcoCostSummaryBreakdownRuntype = z.object({
    packaging_cost: CostRuntype.nullable(),
    discount: CostRuntype.nullable(),
    shipping_cost: CostRuntype.nullable(),
    customs_cost: CostRuntype.nullable(),
    other_cost: CostRuntype.nullable(),
});
export type TcoCostSummaryBreakdownDTO = z.infer<typeof TcoCostSummaryBreakdownRuntype>;

const TcoCostItemRuntype = z.object({ cost: CostRuntype.nullable(), breakdown: TcoCostSummaryBreakdownRuntype });
export type TcoCostItemDTO = z.infer<typeof TcoCostItemRuntype>;

export const TcoCostSummaryRuntype = z.object({
    unit: TcoCostItemRuntype,
    aggregated: TcoCostItemRuntype,
    scrap: TcoCostItemRuntype,
    required: TcoCostItemRuntype,
    excess: TcoCostItemRuntype,
    total: TcoCostItemRuntype,
});
export type TcoCostSummaryDTO = z.infer<typeof TcoCostSummaryRuntype>;

export const CostSummaryRuntype = z.object({
    unit_price: CostRuntype.nullable(),
    tco_unit_price: CostRuntype.nullable(),
    scrap_cost: CostRuntype.nullable(),
    effective_unit_price: CostRuntype.nullable(),
    line_value: CostRuntype.nullable(),
    tco_costs: TcoCostSummaryRuntype.nullable(),
    one_time_costs: CostRuntype.nullable(),
    excess_price: CostRuntype.nullable(),
    scrap_price: CostRuntype.nullable(),
    base_material_cost: CostRuntype.nullable(),
    total_price: CostRuntype.nullable(),
    currency: CurrencyRuntype,
});
export type CostSummaryDTO = z.infer<typeof CostSummaryRuntype>;

export type SolutionQuantitiesDTO = z.infer<typeof SolutionQuantitiesDTORuntype>;
export const SolutionQuantitiesDTORuntype = z.object({
    total: QuantityUnitDTORuntype,
    aggregated: QuantityUnitDTORuntype,
    required: QuantityUnitDTORuntype,
    excess: QuantityUnitDTORuntype.nullable(),
    scrap: QuantityUnitDTORuntype,
});

const OneTimeCostOriginRuntype = z.enum(['Offer', 'Manual']);
export type OneTimeCostOrigin = z.infer<typeof OneTimeCostOriginRuntype>;

const OneTimeCostRuntype = z.object({
    cost: CostRuntype,
    description: z.string().nullable(),
    position: z.number(),
    origin: OneTimeCostOriginRuntype,
});

const SolutionOneTimeCostsRuntype = z.object({
    total_one_time_cost: CostRuntype.nullable(),
    total_offer_one_time_cost: CostRuntype.nullable(),
    total_manual_one_time_cost: CostRuntype.nullable(),
    costs: z.array(OneTimeCostRuntype),
});

export const SolutionCostRuntype = z.object({
    unit_price: CostRuntype.nullable(),
    tco_unit_price: CostRuntype.nullable(),
    scrap_cost: CostRuntype.nullable(),
    effective_unit_price: CostRuntype.nullable(),
    tco_costs: TcoCostRuntype.nullable(),
    one_time_costs: SolutionOneTimeCostsRuntype.nullable(),
    excess_price: CostRuntype.nullable(),
    scrap_price: CostRuntype.nullable(),
    base_material_cost: CostRuntype.nullable(),
    total_price: CostRuntype.nullable(),
    currency: CurrencyRuntype,
});
export interface SolutionCostDTO extends z.infer<typeof SolutionCostRuntype> {}

export interface SolutionDTO extends z.infer<typeof SolutionDTORuntype> {}
export const SolutionDTORuntype = z.object({
    cost: SolutionCostRuntype,
    offer_price: z.object({
        offer_unit: QuantityUnitDTORuntype,
        offer_quantity: z.number(),
        offer_unit_price: CostRuntype.nullable(),
    }),
    offer_id: OfferIdRuntype,
    quantities: SolutionQuantitiesDTORuntype,
    signature: SignatureRuntype.nullable(),
    tags: z.array(TagRuntype),
    token: z.string(),
    availability: AvailabilityRuntype.nullable(),
});
