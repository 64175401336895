import { useHttpMutation } from '@/resources/mutation/useHttpMutation';
import { t, Trans } from '@lingui/macro';
import {
    colorSystem,
    DestructivePrimaryButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuButton,
    MenuItem,
    SecondaryButton,
    Text,
    Tooltip,
} from '@luminovo/design-system';
import { SolutionConfigurationDTO } from '@luminovo/http-client';
import {
    getSolutionSelectionType,
    hasSolutionConfigurationManualCost,
    isConsignedSolution,
    Solution,
    SolutionSelectionType,
} from '@luminovo/sourcing-core';
import { AutoAwesome, InfoOutlined, LockOutlined } from '@mui/icons-material';
import { Box } from '@mui/material';
import React from 'react';
import { useDialogContext } from '../../../../components/contexts/ModalContext';

export function ButtonSolutionSelection({
    solutionConfigurationDTO,
    solution,
}: {
    solutionConfigurationDTO: SolutionConfigurationDTO;
    solution: Solution;
}): JSX.Element {
    const selectionType = getSolutionSelectionType(solution);

    const { mutateAsync, isPending } = useHttpMutation('PATCH /solution-configurations/:solutionConfigurationId', {
        snackbarMessage: t`Solution updated`,
    });

    const { handlePatchSolutionConfiguration } = useManualCostWarningWrapper();

    const updateSolutionToken = React.useCallback(async () => {
        const solutionToken =
            getSolutionSelectionType(solution) === SolutionSelectionType.AUTO_SELECTED ? solution.token : null;
        await mutateAsync({
            pathParams: { solutionConfigurationId: solutionConfigurationDTO.id },
            requestBody: {
                manually_selected_solution_token: solutionToken,
            },
        });
    }, [solution, mutateAsync, solutionConfigurationDTO.id]);

    const isSelectAutomaticallyDisabled = solution === undefined || isPending || isConsignedSolution(solution);
    const menuButtonLabel =
        selectionType === SolutionSelectionType.AUTO_SELECTED ? t`Auto-select solution` : t`Manual solution selection`;
    const buttonIcon = selectionType === SolutionSelectionType.AUTO_SELECTED ? <AutoAwesome /> : <LockOutlined />;

    return (
        <Box display="flex" alignItems="center">
            <Tooltip
                variant="white"
                title={t`When you choose Automatic, we'll pick the best solution for you based on your preferences. But, if the offers change, the best option may also change. If you want to stick with a certain option, switch to Manual.`}
            >
                <InfoOutlined fontSize="small" sx={{ mr: 1, color: colorSystem.neutral[5] }} />
            </Tooltip>
            <MenuButton
                appearance="secondary"
                size="small"
                label={menuButtonLabel}
                icon={buttonIcon}
                disabled={isSelectAutomaticallyDisabled}
                isLoading={isPending}
            >
                <MenuItem
                    label={t`Auto-select best solution`}
                    startIcon={<AutoAwesome />}
                    onClick={() => handlePatchSolutionConfiguration([solutionConfigurationDTO], updateSolutionToken)}
                />
                <MenuItem
                    label={t`Manual solution selection`}
                    startIcon={<LockOutlined />}
                    onClick={() => handlePatchSolutionConfiguration([solutionConfigurationDTO], updateSolutionToken)}
                />
            </MenuButton>
        </Box>
    );
}

export function useManualCostWarningWrapper() {
    const { setDialog, closeDialog } = useDialogContext();

    return {
        handlePatchSolutionConfiguration: (
            solutionConfigurations: SolutionConfigurationDTO[],
            onSubmit: () => void,
        ) => {
            if (solutionConfigurations.every((item) => !hasSolutionConfigurationManualCost(item))) {
                onSubmit();
            } else {
                setDialog(
                    <Dialog open={true} fullWidth maxWidth={'sm'} onClose={() => closeDialog()}>
                        <DialogTitle
                            title={t`Changing the selected solution will delete the manually added one-time costs and TCO costs`}
                            onClose={closeDialog}
                        />
                        <DialogContent>
                            <Text variant="body">
                                <Trans>
                                    There are some manually added one-time costs and TCO costs attached to the selected
                                    solution. If you select a different solution these will be deleted. Are you sure you
                                    want to proceed?
                                </Trans>
                            </Text>
                        </DialogContent>
                        <DialogActions>
                            <SecondaryButton onClick={closeDialog}>
                                <Trans>Keep the solution</Trans>
                            </SecondaryButton>
                            <DestructivePrimaryButton
                                onClick={() => {
                                    onSubmit();
                                    closeDialog();
                                }}
                            >
                                <Trans>Change and delete costs</Trans>
                            </DestructivePrimaryButton>
                        </DialogActions>
                    </Dialog>,
                );
            }
        },
    };
}
