import { colorSystem } from '@luminovo/design-system';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';

export function LayoutTwoPanels({ left, right }: { left?: React.ReactNode; right: React.ReactNode }): JSX.Element {
    if (!left) {
        return <>{right}</>;
    }

    return (
        <PanelGroup direction="horizontal">
            <Panel defaultSize={50}>{left}</Panel>
            <PanelResizeHandle style={{ borderLeft: `1px solid ${colorSystem.neutral[3]}`, minWidth: 1 }} />
            <Panel defaultSize={50}>{right}</Panel>
        </PanelGroup>
    );
}
