import { t } from '@lingui/macro';
import { useNavigate } from '@luminovo/design-system';
import { useCallback } from 'react';
import { useDialogContext } from '../../../components/contexts/ModalContext';
import { BomItem } from '../../../resources/designItem/bomItemFrontendTypes';
import { analytics } from '../../../utils/analytics';
import { QueryParams, route } from '../../../utils/routes';
import { newDesignItemId } from '../../Bom/components/ImportBomButton';
import { ViewContext } from '../../Bom/components/ModuleTableData';
import DeleteDesignItemsDialog from '../../DesignItemDetails/DeleteDesignItemDialog';
export function useDeleteBOMItem({
    bomItem,
    rfqId,
    assemblyId,
    viewContext,
    queryParams,
}: {
    bomItem?: BomItem;
    rfqId?: string;
    assemblyId: string;
    viewContext: ViewContext;
    queryParams: QueryParams<'/rfqs/:rfqId/bom/assembly/:assemblyId/details'>;
}) {
    const navigate = useNavigate();
    const { currentParentAssemblyId, designItemId } = queryParams;
    const { closeDialog, setDialog } = useDialogContext();

    function trackDeleteEvent() {
        if (!bomItem || !rfqId) {
            return;
        }

        analytics.track('delete_bom_item', {
            rfq_id: rfqId,
            assembly_uuid: assemblyId,
            bom_item_ids: bomItem.id,
        });
    }

    const onDeleteNewBomItem = useCallback(() => {
        const newRoute =
            viewContext.type === 'WithinRfQ'
                ? route(
                      '/rfqs/:rfqId/bom/assembly/:assemblyId/details',
                      { assemblyId, rfqId: viewContext.rfqId },
                      {
                          isReadonly: null,
                          designItemId: null,
                          filters: null,
                          bomTab: null,
                          dashboardFilters: null,
                          search: null,
                          currentParentAssemblyId,
                          onlyShowItemsWithManufacturingWarnings: null,
                      },
                  )
                : route(
                      '/assemblies/:assemblyId/details',
                      { assemblyId },
                      {
                          designItemId: undefined,
                          filters: undefined,
                          dashboardFilters: undefined,
                          search: undefined,
                      },
                  );

        navigate(newRoute);
    }, [assemblyId, currentParentAssemblyId, navigate, viewContext.rfqId, viewContext.type]);

    return {
        openDialog: () => {
            trackDeleteEvent();
            setDialog(
                bomItem ? (
                    <DeleteDesignItemsDialog
                        open={true}
                        closeDialog={closeDialog}
                        text={t`By default all the following design items will be deleted. If there is more than one, you can choose to delete only a subset by selecting or deselecting individual design items.`}
                        title={t`Delete BOM item`}
                        bomItem={bomItem}
                        onDeleteNewBomItem={onDeleteNewBomItem}
                        isNewBomItem={designItemId === newDesignItemId}
                    />
                ) : null,
            );
        },
    };
}
