import { getIncotermsFullDetails } from '@/modules/Suppliers/SupplierManagement/components/SupplierTermDialogs/SupplierTermForm';
import { Trans, t } from '@lingui/macro';
import {
    Currency,
    assertUnreachable,
    compareByString,
    displayCurrencySymbol,
    isPresent,
    transEnum,
    uniqueBy,
} from '@luminovo/commons';
import {
    DestructiveTertiaryIconButton,
    FieldNumericControlled,
    FieldRadioControlled,
    FieldSelectControlled,
    FieldTextControlled,
    Flexbox,
    FormItem,
    MenuButton,
    MenuItem,
    SecondaryIconButton,
    Text,
    ToggleButton,
    ToggleButtonItem,
    colorSystem,
} from '@luminovo/design-system';
import {
    Incoterms,
    Packaging,
    RegionsEnum,
    TotalCostOfOwnershipActionDTO,
    TotalCostOfOwnershipInputDTO,
    TotalCostOfOwnershipRuleCategory,
    TotalCostOfOwnershipRuleConditionType,
    TotalCostOfOwnershipRuleDTO,
    TotalCostOfOwnershipScaling,
} from '@luminovo/http-client';
import {
    formatRegionAsName,
    formatSupplier,
    packagingTranslations,
    totalCostOfOwnershipRuleCategoryTranslations,
    totalCostOfOwnershipRuleConditionTypeTranslations,
    totalCostOfOwnershipScalingTranslations,
} from '@luminovo/sourcing-core';
import { Add, Delete } from '@mui/icons-material';
import { InputAdornment } from '@mui/material';
import React from 'react';
import {
    Control,
    SubmitHandler,
    UseFieldArrayAppend,
    UseFieldArrayRemove,
    useFieldArray,
    useFormContext,
    useWatch,
} from 'react-hook-form';
import { CancelButton } from '../../../../../components/formLayouts/CancelButton';
import { FormContainer, ValidationErrors } from '../../../../../components/formLayouts/FormContainer';
import { SubmitButton } from '../../../../../components/formLayouts/SubmitButton';
import { inputCurrenciesPublicTranslations } from '../../../../../resources/currencyInputTypes';
import { useSuspenseHttpQuery } from '../../../../../resources/http/useHttpQuery';
import { useManufacturers } from '../../../../../resources/manufacturer/manufacturerHandler';
import { useSuppliers } from '../../../../../resources/supplier/supplierHandler';

export type TcoRuleFormState = TotalCostOfOwnershipInputDTO;

type ConditionProps = {
    index: number;
    control: Control<TcoRuleFormState>;
};

export function convertTcoRuleDto(rule: TotalCostOfOwnershipRuleDTO): TcoRuleFormState {
    return {
        name: rule.name,
        description: rule.description,
        category: rule.category,
        action: rule.action,
        scaling: rule.scaling,
        conditions: rule.conditions,
    };
}

export function convertTcoRuleFormState(form: TcoRuleFormState): TotalCostOfOwnershipInputDTO {
    return {
        name: form.name,
        description: form.description,
        category: form.category,
        action: form.action,
        scaling: form.scaling,
        conditions: form.conditions,
    };
}

const FormItemCategory = (): JSX.Element => {
    const { control } = useFormContext<TcoRuleFormState>();

    return (
        <FormItem
            label={t`Category`}
            variant="description-inlined"
            description={t`Determines at which stage of the total cost of ownership calculation the rule will be applied.`}
        >
            <Flexbox flexDirection="row" gap={16}>
                {Object.values(TotalCostOfOwnershipRuleCategory).map((category) => (
                    <Flexbox key={category} alignItems="center" gap={8}>
                        <FieldRadioControlled name="category" control={control} FieldProps={{ fieldValue: category }} />
                        <Text>{transEnum(category, totalCostOfOwnershipRuleCategoryTranslations)}</Text>
                    </Flexbox>
                ))}
            </Flexbox>
        </FormItem>
    );
};

const FormItemName = (): JSX.Element => {
    const { control } = useFormContext<TcoRuleFormState>();

    return (
        <FormItem label={t`Name`} required>
            <FieldTextControlled control={control} name={'name'} required />
        </FormItem>
    );
};

const FormItemDescription = (): JSX.Element => {
    const { control } = useFormContext<TcoRuleFormState>();

    return (
        <FormItem label={t`Description`}>
            <FieldTextControlled
                control={control}
                name={'description'}
                FieldProps={{
                    multiline: true,
                    rows: 2,
                }}
            />
        </FormItem>
    );
};

const FormItemConditions = (): JSX.Element => {
    const { control } = useFormContext<TcoRuleFormState>();
    const { fields: conditions, append, remove } = useFieldArray({ control, name: 'conditions' });

    const conditionTypes: TcoRuleFormState['conditions'] = [
        {
            condition_type: TotalCostOfOwnershipRuleConditionType.Packaging,
            value: Packaging.ReReel,
        },
        {
            condition_type: TotalCostOfOwnershipRuleConditionType.Supplier,
            value: null as unknown as string, // Hack: To make the field required
        },
        {
            condition_type: TotalCostOfOwnershipRuleConditionType.CountryOfOrigin,
            value: RegionsEnum.Unknown,
        },
        {
            condition_type: TotalCostOfOwnershipRuleConditionType.CustomsCode,
            value: {
                code_type: 'Hts',
                code: '',
            },
        },
        {
            condition_type: TotalCostOfOwnershipRuleConditionType.Incoterms,
            value: Incoterms.EXW,
        },
        {
            condition_type: TotalCostOfOwnershipRuleConditionType.Manufacturer,
            value: null as unknown as string, // Hack: To make the field required
        },
    ];

    return (
        <FormItem
            label={t`Conditions`}
            variant="description-inlined"
            description={t`Conditions of the same type are combined with OR logic, while different types use AND logic.`}
        >
            <Flexbox flexDirection="column" gap={12}>
                <Flexbox flexDirection="column" gap={12}>
                    {uniqueBy(conditions, (x) => x.condition_type).map((x) => (
                        <ConditionGroup
                            key={x.condition_type}
                            conditionType={x.condition_type}
                            conditions={conditions}
                            append={append}
                            remove={remove}
                        />
                    ))}
                </Flexbox>
                <Flexbox flexDirection="row" justifyContent={'space-between'} alignItems={'center'}></Flexbox>
            </Flexbox>
            <MenuButton
                appearance="primary"
                size="small"
                icon={<Add />}
                label={<Trans>Add another condition type</Trans>}
                style={{ width: 'fit-content' }}
            >
                {conditionTypes.map((props) => (
                    <MenuItem
                        key={props.condition_type}
                        disabled={conditions.some((x) => x.condition_type === props.condition_type)}
                        onClick={() => append(props)}
                        label={transEnum(props.condition_type, totalCostOfOwnershipRuleConditionTypeTranslations)}
                    />
                ))}
            </MenuButton>
        </FormItem>
    );
};

const ConditionGroup: React.FunctionComponent<{
    conditionType: TotalCostOfOwnershipRuleConditionType;
    conditions: TcoRuleFormState['conditions'];
    append: UseFieldArrayAppend<TotalCostOfOwnershipInputDTO, 'conditions'>;
    remove: UseFieldArrayRemove;
}> = ({ conditionType, conditions, append, remove }): JSX.Element => {
    const { control } = useFormContext<TcoRuleFormState>();

    const renderCondition = (index: number) => {
        switch (conditionType) {
            case TotalCostOfOwnershipRuleConditionType.Packaging:
                return <PackagingCondition index={index} control={control} />;
            case TotalCostOfOwnershipRuleConditionType.Supplier:
                return <SupplierCondition index={index} control={control} />;
            case TotalCostOfOwnershipRuleConditionType.CountryOfOrigin:
                return <CountryOfOriginCondition index={index} control={control} />;
            case TotalCostOfOwnershipRuleConditionType.CustomsCode:
                return <CustomsCodeCondition index={index} control={control} />;
            case TotalCostOfOwnershipRuleConditionType.Incoterms:
                return <IncotermsCondition index={index} control={control} />;
            case TotalCostOfOwnershipRuleConditionType.Manufacturer:
                return <ManufacturerCondition index={index} control={control} />;
            default:
                return null;
        }
    };

    const getMaxNumberOfOptions = (): number | undefined => {
        switch (conditionType) {
            case TotalCostOfOwnershipRuleConditionType.Packaging:
                return Object.values(Packaging).length;
            case TotalCostOfOwnershipRuleConditionType.Supplier:
            case TotalCostOfOwnershipRuleConditionType.CountryOfOrigin:
            case TotalCostOfOwnershipRuleConditionType.CustomsCode:
            case TotalCostOfOwnershipRuleConditionType.PartNumber:
            case TotalCostOfOwnershipRuleConditionType.Manufacturer:
                return undefined;
            case TotalCostOfOwnershipRuleConditionType.Incoterms:
                return undefined;
            default:
                assertUnreachable(conditionType);
        }
    };

    const handleAddCondition = () => {
        switch (conditionType) {
            case TotalCostOfOwnershipRuleConditionType.Packaging:
                append({
                    condition_type: TotalCostOfOwnershipRuleConditionType.Packaging,
                    value: Packaging.ReReel,
                });
                break;
            case TotalCostOfOwnershipRuleConditionType.Supplier:
                append({
                    condition_type: TotalCostOfOwnershipRuleConditionType.Supplier,
                    value: null as unknown as string, // Hack: To make the field required
                });
                break;
            case TotalCostOfOwnershipRuleConditionType.CountryOfOrigin:
                append({
                    condition_type: TotalCostOfOwnershipRuleConditionType.CountryOfOrigin,
                    value: RegionsEnum.Unknown,
                });
                break;
            case TotalCostOfOwnershipRuleConditionType.CustomsCode:
                append({
                    condition_type: TotalCostOfOwnershipRuleConditionType.CustomsCode,
                    value: {
                        code_type: 'Hts',
                        code: '',
                    },
                });
                break;
            case TotalCostOfOwnershipRuleConditionType.Incoterms:
                append({
                    condition_type: TotalCostOfOwnershipRuleConditionType.Incoterms,
                    value: Incoterms.EXW,
                });
                break;
            case TotalCostOfOwnershipRuleConditionType.Manufacturer:
                append({
                    condition_type: TotalCostOfOwnershipRuleConditionType.Manufacturer,
                    value: null as unknown as string, // Hack: To make the field required
                });
                break;
        }
    };

    const maxNumberOfOptions = getMaxNumberOfOptions();
    const canAddConditions = isPresent(maxNumberOfOptions) ? conditions.length < maxNumberOfOptions : true;

    return (
        <Flexbox flexDirection="column" border={`1px solid ${colorSystem.neutral[4]}`} borderRadius={'4px'}>
            <Flexbox
                flexDirection="row"
                justifyContent={'space-between'}
                bgcolor={colorSystem.neutral[0]}
                padding={'12px'}
                borderBottom={`1px solid ${colorSystem.neutral[4]}`}
                borderRadius={'4px 4px 0 0'}
            >
                <Text variant={'h4'} color={colorSystem.neutral[8]}>
                    {transEnum(conditionType, totalCostOfOwnershipRuleConditionTypeTranslations)}
                </Text>
                <SecondaryIconButton size={'small'} onClick={handleAddCondition} disabled={!canAddConditions}>
                    <Add fontSize="inherit" />
                </SecondaryIconButton>
            </Flexbox>
            <Flexbox flexDirection="row" gap={4} flexWrap={'wrap'}>
                {conditions.map((condition, index) => {
                    if (condition.condition_type !== conditionType) {
                        return null;
                    }
                    return (
                        <Flexbox key={index} flexDirection="row" gap={0} padding={'12px'} alignItems={'center'}>
                            {renderCondition(index)}
                            <DestructiveTertiaryIconButton size={'small'} onClick={() => remove(index)}>
                                <Delete fontSize="inherit" />
                            </DestructiveTertiaryIconButton>
                        </Flexbox>
                    );
                })}
            </Flexbox>
        </Flexbox>
    );
};

const PackagingCondition = ({ index, control }: ConditionProps) => (
    <FieldSelectControlled
        control={control}
        name={`conditions.${index}.value`}
        required
        displayErrorAsTooltip
        FieldProps={{
            size: 'small',
            disableClearable: true,
            style: { width: '200px' },
            options: Object.values(Packaging),
            getOptionLabel: (option) => transEnum(option, packagingTranslations),
        }}
    />
);

const SupplierCondition = ({ index, control }: ConditionProps) => {
    const { data = [] } = useSuppliers();
    data.sort((a, b) => compareByString(a.name, b.name));

    return (
        <FieldSelectControlled
            control={control}
            name={`conditions.${index}.value`}
            required
            displayErrorAsTooltip
            FieldProps={{
                size: 'small',
                disableClearable: true,
                style: { width: '360px' },
                options: data.map((supplier) => supplier.id),
                getOptionLabel: (option) => {
                    const supplier = data.find((supplier) => supplier.id === option);
                    if (isPresent(supplier)) {
                        return formatSupplier(supplier.name, supplier.supplier_number);
                    }
                    return '';
                },
            }}
        />
    );
};

const ManufacturerCondition = ({ index, control }: ConditionProps) => {
    const { data = [] } = useManufacturers();
    data.sort((a, b) => compareByString(a.name, b.name));

    // Create a dictionary for faster manufacturer lookups
    const manufacturerDict = React.useMemo(() => Object.fromEntries(data.map((m) => [m.id, m])), [data]);

    return (
        <FieldSelectControlled
            control={control}
            name={`conditions.${index}.value`}
            required
            displayErrorAsTooltip
            FieldProps={{
                size: 'small',
                disableClearable: true,
                style: { width: '360px' },
                options: data.map((manufacturer) => manufacturer.id),
                getOptionLabel: (option) => {
                    const manufacturer = manufacturerDict[option];
                    return manufacturer?.name ?? '';
                },
            }}
        />
    );
};

const CountryOfOriginCondition = ({ index, control }: ConditionProps) => (
    <FieldSelectControlled
        control={control}
        name={`conditions.${index}.value`}
        required
        displayErrorAsTooltip
        FieldProps={{
            size: 'small',
            disableClearable: true,
            style: { width: '300px' },
            options: Object.values(RegionsEnum),
            getOptionLabel: (option) => formatRegionAsName(option),
        }}
    />
);

const CustomsCodeCondition = ({ index, control }: ConditionProps) => (
    <Flexbox flexDirection="row" gap={8} alignItems={'center'}>
        <FieldSelectControlled
            control={control}
            name={`conditions.${index}.value.code_type`}
            required
            displayErrorAsTooltip
            FieldProps={{
                size: 'small',
                disableClearable: true,
                style: { width: '100px' },
                options: ['Hts', 'Taric'],
                getOptionLabel: (option) => option.toLocaleUpperCase(),
            }}
        />
        <FieldTextControlled
            control={control}
            name={`conditions.${index}.value.code`}
            required
            displayErrorAsTooltip
            FieldProps={{
                size: 'small',
                style: { width: '200px' },
            }}
        />
    </Flexbox>
);

const IncotermsCondition = ({ index, control }: ConditionProps) => (
    <FieldSelectControlled
        control={control}
        name={`conditions.${index}.value`}
        required
        displayErrorAsTooltip
        FieldProps={{
            size: 'small',
            disableClearable: true,
            style: { width: '200px' },
            options: Object.values(Incoterms),
            getOptionLabel: (incoterm) => getIncotermsFullDetails(incoterm),
        }}
    />
);

const FormItemAction = (): JSX.Element => {
    const { control, setValue } = useFormContext<TcoRuleFormState>();
    const action = useWatch({ control, name: 'action' });
    const actionType = action.type;

    const { data: defaultCurrency = Currency.EUR } = useSuspenseHttpQuery(
        'GET /organization-settings/organization-currency-settings',
        {},
        { select: (res) => res.data.currency },
    );

    let currency = action.type === 'Absolute' ? action.value.currency : defaultCurrency;

    const getDefaultAction = (type: TotalCostOfOwnershipActionDTO['type']): TotalCostOfOwnershipActionDTO => {
        switch (type) {
            case 'Absolute':
                return { type, value: { amount: '', currency } };
            case 'Relative':
                return { type, value: '' };
            default:
                assertUnreachable(type);
        }
    };

    return (
        <FormItem label={t`Additional cost`}>
            <Flexbox flexDirection="row" gap={16} alignItems="center">
                <ToggleButton value={actionType} style={{ height: '40px' }}>
                    <ToggleButtonItem
                        value={'Absolute'}
                        selected={actionType === 'Absolute'}
                        onClick={() => setValue('action', getDefaultAction('Absolute'))}
                    >
                        {displayCurrencySymbol(currency)}
                    </ToggleButtonItem>
                    <ToggleButtonItem
                        value={'Relative'}
                        selected={actionType === 'Relative'}
                        onClick={() => setValue('action', getDefaultAction('Relative'))}
                    >
                        %
                    </ToggleButtonItem>
                </ToggleButton>
                {actionType === 'Absolute' ? (
                    <Flexbox alignItems="center" gap={8}>
                        <FieldNumericControlled
                            control={control}
                            name={'action.value.amount'}
                            required
                            min={0}
                            FieldProps={{}}
                        />
                        <FieldSelectControlled
                            control={control}
                            name={`action.value.currency`}
                            required
                            FieldProps={{
                                options: Object.values(Currency),
                                getOptionLabel: (option) => transEnum(option, inputCurrenciesPublicTranslations),
                                disableClearable: true,
                            }}
                        />
                    </Flexbox>
                ) : (
                    <FieldNumericControlled
                        control={control}
                        name={'action.value'}
                        required
                        min={0}
                        FieldProps={{
                            style: { width: '260px' },
                            InputProps: {
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            },
                        }}
                    />
                )}
            </Flexbox>
        </FormItem>
    );
};

const FormItemScaling = (): JSX.Element => {
    const { control } = useFormContext<TcoRuleFormState>();

    return (
        <FormItem
            label={t`Application`}
            variant="description-inlined"
            description={t`Determines how the rule will be applied`}
        >
            <Flexbox flexDirection="row" gap={16}>
                {Object.values(TotalCostOfOwnershipScaling).map((scaling) => (
                    <Flexbox key={scaling} alignItems="center" gap={8}>
                        <FieldRadioControlled name="scaling" control={control} FieldProps={{ fieldValue: scaling }} />
                        <Text>{transEnum(scaling, totalCostOfOwnershipScalingTranslations)}</Text>
                    </Flexbox>
                ))}
            </Flexbox>
        </FormItem>
    );
};

export const TcoRuleForm = ({
    onClose,
    onSubmit,
    defaultValues,
}: {
    onClose: () => void;
    onSubmit: SubmitHandler<TcoRuleFormState>;
    defaultValues: TcoRuleFormState;
}): JSX.Element => {
    const validationErrors: ValidationErrors<TcoRuleFormState> = {
        'total_cost_of_ownership_rule.invalid_name': {
            fieldPath: 'name',
        },
        'total_cost_of_ownership_rule.conditions_cannot_be_empty': {
            fieldPath: 'root.serverError',
        },
        'total_cost_of_ownership_rule.absolute_cost_cannot_be_negative': {
            fieldPath: 'root.serverError',
        },
    };

    return (
        <FormContainer defaultValues={defaultValues} onSubmit={onSubmit} validationErrors={validationErrors}>
            <Flexbox flexDirection="column" gap={16}>
                <FormItemName />
                <FormItemDescription />
                <FormItemCategory />
                <FormItemConditions />
                <FormItemAction />
                <FormItemScaling />
            </Flexbox>
            <Flexbox justifyContent={'space-between'} marginTop="32px" width={'100%'}>
                <CancelButton onClick={onClose} />
                <SubmitButton />
            </Flexbox>
        </FormContainer>
    );
};
