import { getCustomerPortalTenant } from '@/permissions/tenants';
import { Trans, plural, t } from '@lingui/macro';
import { useAuth } from '@luminovo/auth';
import { compareByDate, formatRelativeTime, isPresent, uniq } from '@luminovo/commons';
import {
    ButtonFilter,
    CenteredLayout,
    Flexbox,
    MaxWidthLayout,
    NonIdealState,
    Tag,
    Text,
    Toolbar,
    chainComparators,
    colorSystem,
} from '@luminovo/design-system';
import { CustomPartTypeEnum, QuoteTrackingDTO, QuoteTrackingState, isCustomFullPart } from '@luminovo/http-client';
import {
    QuoteTrackingStateChip,
    formatQuoteTrackingState,
    formatSupplierAndStockLocationDTO,
} from '@luminovo/sourcing-core';
import { ExitToApp } from '@mui/icons-material';
import { Box, CircularProgress } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { Avatar } from '../../../components/Navbar/Avatar';
import { PageLayout } from '../../../components/PageLayout';
import { useCurrentUserDetailsContext } from '../../../components/contexts/CurrentUserDetailsContext';
import { useQuoteTrackings } from '../../../resources/quoteTracking/quoteTrackingHandler';
import { analytics } from '../../../utils/analytics';
import { route } from '../../../utils/routes';
import { PcbThumbnail } from './components/PcbThumbnail';
import { StandardPartThumbnail } from './components/StandardPartThumbnail';
import { UpsellBox } from './components/UpsellBox';
import { countQuotedLineItems } from './model/utils';

export function SupplierQuotesPage({ rfqId }: { rfqId: string }) {
    const { logout } = useAuth({
        auth0OrgId: getCustomerPortalTenant()?.auth0OrgId,
    });
    const { data: quoteTrackings, isLoading } = useQuoteTrackings(rfqId);
    const { organization, supplier } = useCurrentUserDetailsContext();
    const [selectedState, setStatus] = React.useState<QuoteTrackingState | undefined>(undefined);

    if (isLoading) {
        return (
            <CenteredLayout>
                <CircularProgress />
            </CenteredLayout>
        );
    }

    if (!isPresent(quoteTrackings)) {
        return (
            <CenteredLayout>
                <NonIdealState
                    title={t`Quote request not found`}
                    description={t`The quote request does not exist.`}
                    action={{
                        onClick: () => {
                            analytics.track('supplier_quote_request_not_found');
                            logout();
                        },
                        variant: 'outlined',
                        color: 'primary',
                        startIcon: <ExitToApp />,
                        children: t`Log out`,
                    }}
                />
            </CenteredLayout>
        );
    }

    const filtered = quoteTrackings
        .filter((s) => s.state !== QuoteTrackingState.Discarded && s.state !== QuoteTrackingState.FailedToSend)
        .filter((q) => q.state === selectedState || selectedState === undefined)
        .sort(
            chainComparators(
                compareByDate((q) => q.due_date ?? '2100-01-01', { type: 'ascending' }),
                compareByDate((q) => q.creation_date, { type: 'ascending' }),
            ),
        );

    const allStatuses: QuoteTrackingState[] = Array.from(new Set(filtered.map((q) => q.state)));

    return (
        <PageLayout
            navbar={
                <Toolbar
                    breadcrumbs={[
                        {
                            title: t`Quote portal`,
                            href: route('/quote-portal/rfq/:rfqId', { rfqId }),
                        },
                        {
                            title: organization.name,
                            href: route('/quote-portal/rfq/:rfqId', { rfqId }),
                        },
                    ]}
                >
                    <Avatar variant="light" style={{ margin: 0 }} />
                </Toolbar>
            }
        >
            <MaxWidthLayout innerMaxWidth={800}>
                <Flexbox flexDirection={'column'} gap="20px">
                    <Flexbox justifyContent="space-between">
                        <Text variant="h1">
                            <Trans>Quote portal</Trans>
                        </Text>
                        <ButtonFilter<QuoteTrackingState>
                            value={selectedState}
                            onChange={setStatus}
                            label={t`Filter status`}
                            options={allStatuses.map((value) => {
                                const label = formatQuoteTrackingState(value, supplier ? 'supplier' : 'ems');
                                return {
                                    label,
                                    value,
                                    selectedLabel: t`Filtering by ${label}`,
                                };
                            })}
                        />
                    </Flexbox>
                    <Text>
                        <Trans>Showing {filtered.length}</Trans>
                    </Text>
                    {filtered.map((quoteTracking) => (
                        <QuoteTrackingListItem key={quoteTracking.id} quoteTracking={quoteTracking} />
                    ))}

                    {filtered.length === 0 && (
                        <CenteredLayout minHeight={'50vh'}>
                            <NonIdealState title={t`No quotes`} description={t`No quotes match your filter`} />
                        </CenteredLayout>
                    )}
                    <UpsellBox />
                </Flexbox>
            </MaxWidthLayout>
        </PageLayout>
    );
}

function QuoteTrackingListItem({ quoteTracking }: { quoteTracking: QuoteTrackingDTO }) {
    const { quoted: partsQuoted, total } = countQuotedLineItems(quoteTracking);

    const requested = plural(total, {
        one: '1 line item',
        other: '# line items',
    });
    return (
        <Link
            to={route('/quote-portal/quote-tracking/:quoteTrackingId', {
                quoteTrackingId: quoteTracking.id,
            })}
            style={{
                textDecoration: 'none',
            }}
        >
            <Flexbox
                flexDirection={'row'}
                gap="20px"
                sx={{
                    borderRadius: '8px',
                    padding: '20px',
                    cursor: 'pointer',
                    border: `1px solid ${colorSystem.neutral[3]}`,
                    '&:hover': {
                        backgroundColor: colorSystem.neutral[0],
                    },
                }}
            >
                <QuoteThumbnail quoteTracking={quoteTracking} />
                <Flexbox flexDirection={'column'} width={'100%'} gap="8px">
                    <Flexbox justifyContent="space-between" alignItems={'center'} gap="8px">
                        <Text variant="h2">
                            {formatSupplierAndStockLocationDTO(quoteTracking.supplier_and_stock_location)}{' '}
                        </Text>
                        {quoteTracking.due_date && (
                            <Tag
                                color="yellow"
                                attention="low"
                                label={`Due ${formatRelativeTime(quoteTracking.due_date)}`}
                            />
                        )}
                        <span style={{ flexGrow: 1 }} />
                        <QuoteTrackingStateChip user="supplier" quoteTracking={quoteTracking} />
                    </Flexbox>
                    <span style={{ flexGrow: 1 }} />
                    {quoteTracking.creation_date && (
                        <Text>
                            <Trans comment="e.g. Requested 5 days ago">
                                Requested {formatRelativeTime(quoteTracking.creation_date)}
                            </Trans>
                        </Text>
                    )}

                    <Text>
                        <Trans>{requested} requested</Trans>, <Trans>{partsQuoted} quoted</Trans>
                    </Text>
                </Flexbox>
            </Flexbox>
        </Link>
    );
}

function QuoteThumbnail({ quoteTracking }: { quoteTracking: QuoteTrackingDTO }) {
    const parts = uniq([
        ...quoteTracking.request_standard_parts.map((p) => p.kind.id),
        ...quoteTracking.request_custom_parts.map((p) => p.kind.id),
    ]).length;

    let pcbId: string | null = null;
    for (const part of quoteTracking.request_custom_parts) {
        if (isCustomFullPart(part.kind) && part.kind.type.name === CustomPartTypeEnum.PCB) {
            pcbId = part.kind.type.content;
        }
    }

    if (isPresent(pcbId)) {
        return (
            <QuoteThumbnailPartCount parts={parts}>
                <PcbThumbnail pcbId={pcbId} size={100} />
            </QuoteThumbnailPartCount>
        );
    }

    return (
        <QuoteThumbnailPartCount parts={parts}>
            <StandardPartThumbnail parts={quoteTracking.request_standard_parts.map((p) => p.kind)} size={100} />
        </QuoteThumbnailPartCount>
    );
}

function QuoteThumbnailPartCount({ parts, children }: { parts: number; children: React.ReactNode }) {
    if (parts === 1) {
        return <>{children}</>;
    }
    return (
        <Box sx={{ position: 'relative' }}>
            {children}
            <Box
                sx={{
                    borderRadius: '100%',
                    boxSizing: 'border-box',
                    position: 'absolute',
                    bottom: '50%',
                    right: '50%',
                    transform: 'translate(50%, 50%)',
                    backgroundColor: colorSystem.neutral[8],
                    padding: 1,
                    minWidth: '36px',
                }}
            >
                <Text color={'white'}>+{parts - 1}</Text>
            </Box>
        </Box>
    );
}
