import { MultiProgressBar } from '@/modules/Negotiations/components/CardSummary';
import { t } from '@lingui/macro';
import { formatDecimal, throwErrorUnlessProduction } from '@luminovo/commons';
import { colorSystem, FilterChip, Flexbox, Switch, Text } from '@luminovo/design-system';
import { RfqListItemDTO, RfqStatus } from '@luminovo/http-client';
import { Box } from '@mui/material';
import React from 'react';
import { analytics } from '../../../utils/analytics';
import { calculateRfqMetrics } from '../utils/rfqMetrics';

interface RfqWinrateProps {
    data: RfqListItemDTO[];
}

type TimePeriod = '1d' | '3m' | '6m' | '12m' | 'all';

interface RfqFiltersProps {
    selectedPeriod: TimePeriod;
    setSelectedPeriod: (period: TimePeriod) => void;
    includeInProgress: boolean;
    setIncludeInProgress: (include: boolean) => void;
    includeArchived: boolean;
    setIncludeArchived: (include: boolean) => void;
}

function RfqFilters({
    selectedPeriod,
    setSelectedPeriod,
    includeInProgress,
    setIncludeInProgress,
    includeArchived,
    setIncludeArchived,
}: RfqFiltersProps) {
    return (
        <Flexbox gap={2} alignItems="center">
            <FilterChip
                label={t`All time`}
                onChange={() => setSelectedPeriod('all')}
                isSelected={selectedPeriod === 'all'}
                value="all"
            />
            <FilterChip
                label={t`Last 3 months`}
                onChange={() => setSelectedPeriod('3m')}
                isSelected={selectedPeriod === '3m'}
                value="3m"
            />
            <FilterChip
                label={t`Last 6 months`}
                onChange={() => setSelectedPeriod('6m')}
                isSelected={selectedPeriod === '6m'}
                value="6m"
            />
            <FilterChip
                label={t`Last 12 months`}
                onChange={() => setSelectedPeriod('12m')}
                isSelected={selectedPeriod === '12m'}
                value="12m"
            />
            <Flexbox gap={4} alignItems="center" marginLeft={1}>
                <Switch
                    checked={includeInProgress}
                    onChange={(e) => {
                        analytics.track('toggle_include_in_progress_rfqs', {
                            include_in_progress: e.target.checked,
                            time_period: selectedPeriod,
                        });
                        setIncludeInProgress(e.target.checked);
                    }}
                />
                <Text variant="body-small">{t`Include in progress`}</Text>
            </Flexbox>
            <Flexbox gap={4} alignItems="center" marginLeft={1}>
                <Switch
                    checked={includeArchived}
                    onChange={(e) => {
                        analytics.track('toggle_include_archived_rfqs', {
                            include_archived: e.target.checked,
                            time_period: selectedPeriod,
                        });
                        setIncludeArchived(e.target.checked);
                    }}
                />
                <Text variant="body-small">{t`Include archived RfQs`}</Text>
            </Flexbox>
        </Flexbox>
    );
}

interface RfqCountsProps {
    metrics: ReturnType<typeof calculateRfqMetrics>;
    inProgressCount: number;
    noBidCount: number;
    lostCount: number;
    wonCount: number;
}

function RfqCounts({ metrics, inProgressCount, noBidCount, lostCount, wonCount }: RfqCountsProps) {
    return (
        <Box display="flex" gap={2}>
            <Flexbox gap={'4px'} alignItems={'end'}>
                <Text variant="h1" color={colorSystem.neutral[9]}>
                    {formatDecimal(metrics.categories.in_progress.percent, { maximumFractionDigits: 0 })}%
                </Text>
                <Text variant="body" color={colorSystem.neutral[7]}>
                    {t`In progress`} ({inProgressCount})
                </Text>
            </Flexbox>
            <Flexbox gap={'4px'} alignItems={'end'}>
                <Text variant="h1" color={colorSystem.neutral[9]}>
                    {formatDecimal(metrics.categories.no_quotation.percent, { maximumFractionDigits: 0 })}%
                </Text>
                <Text variant="body" color={colorSystem.neutral[7]}>
                    {t`No quotation`} ({noBidCount})
                </Text>
            </Flexbox>
            <Flexbox gap={'4px'} alignItems={'end'}>
                <Text variant="h1" color={colorSystem.neutral[9]}>
                    {formatDecimal(metrics.categories.lost.percent, { maximumFractionDigits: 0 })}%
                </Text>
                <Text variant="body" color={colorSystem.neutral[7]}>
                    {t`Lost`} ({lostCount})
                </Text>
            </Flexbox>
            <Flexbox gap={'4px'} alignItems={'end'}>
                <Text variant="h1" color={colorSystem.neutral[9]}>
                    {formatDecimal(metrics.categories.won.percent, { maximumFractionDigits: 0 })}%
                </Text>
                <Text variant="body" color={colorSystem.neutral[7]}>
                    {t`Won`} ({wonCount})
                </Text>
            </Flexbox>
        </Box>
    );
}

export function RfqWinrate({ data }: RfqWinrateProps) {
    const [includeInProgress, setIncludeInProgress] = React.useState(false);
    const [includeArchived, setIncludeArchived] = React.useState(false);
    const [selectedPeriod, setSelectedPeriod] = React.useState<TimePeriod>('all');

    const filteredData = React.useMemo(() => {
        let result = data;

        if (!includeArchived) {
            result = result.filter((rfq) => !rfq.is_archived);
        }

        if (selectedPeriod !== 'all') {
            const now = new Date();
            const monthsAgo = new Date();
            const months = parseInt(selectedPeriod);
            monthsAgo.setMonth(now.getMonth() - months);

            result = result.filter((rfq) => {
                const creationDate = new Date(rfq.creation_date);
                return creationDate >= monthsAgo;
            });
        }

        if (!includeInProgress) {
            result = result.filter(
                (rfq) =>
                    rfq.status !== RfqStatus.RequestInDraft &&
                    rfq.status !== RfqStatus.QuotationInProgress &&
                    rfq.status !== RfqStatus.QuotationAvailable,
            );
        }

        return result;
    }, [data, selectedPeriod, includeInProgress, includeArchived]);

    const metrics = calculateRfqMetrics(filteredData);
    const wonCount = metrics.categories.won.count;
    const lostCount = metrics.categories.lost.count;
    const inProgressCount = metrics.categories.in_progress.count;
    const noBidCount = metrics.categories.no_quotation.count;

    const detailedStatusCounts = Object.values(RfqStatus).reduce(
        (acc, status) => {
            acc[status] = filteredData.filter((rfq) => rfq.status === status).length;
            return acc;
        },
        {} as Record<RfqStatus, number>,
    );

    const detailedProgressData = [
        // In Progress group (blue colors)
        {
            id: RfqStatus.RequestInDraft,
            label: t`Request in draft`,
            value: detailedStatusCounts[RfqStatus.RequestInDraft],
            color: colorSystem.blue[2],
        },
        {
            id: RfqStatus.QuotationInProgress,
            label: t`Quotation in progress`,
            value: detailedStatusCounts[RfqStatus.QuotationInProgress],
            color: colorSystem.blue[3],
        },
        {
            id: RfqStatus.QuotationAvailable,
            label: t`Quotation available`,
            value: detailedStatusCounts[RfqStatus.QuotationAvailable],
            color: colorSystem.blue[4],
        },
        // No Quotation group (neutral color)
        {
            id: RfqStatus.NoQuotation,
            label: t`No quotation`,
            value: detailedStatusCounts[RfqStatus.NoQuotation],
            color: colorSystem.neutral[4],
        },
        // Lost group (red color)
        {
            id: RfqStatus.NoOrder,
            label: t`No order`,
            value: detailedStatusCounts[RfqStatus.NoOrder],
            color: colorSystem.red[4],
        },
        {
            id: RfqStatus.PaymentFailed,
            label: t`Payment failed`,
            value: detailedStatusCounts[RfqStatus.PaymentFailed],
            color: colorSystem.red[3],
        },
        // Won group (green colors)
        {
            id: RfqStatus.OrderPlaced,
            label: t`Order placed`,
            value: detailedStatusCounts[RfqStatus.OrderPlaced],
            color: colorSystem.green[2],
        },
        {
            id: RfqStatus.OrderConfirmed,
            label: t`Order confirmed`,
            value: detailedStatusCounts[RfqStatus.OrderConfirmed],
            color: colorSystem.green[4],
        },
        {
            id: RfqStatus.OrderInProcurement,
            label: t`Order in procurement`,
            value: detailedStatusCounts[RfqStatus.OrderInProcurement],
            color: colorSystem.green[3],
        },
        {
            id: RfqStatus.OrderInProduction,
            label: t`Order in production`,
            value: detailedStatusCounts[RfqStatus.OrderInProduction],
            color: colorSystem.green[5],
        },
        {
            id: RfqStatus.OrderShipped,
            label: t`Order shipped`,
            value: detailedStatusCounts[RfqStatus.OrderShipped],
            color: colorSystem.green[6],
        },
    ];

    // Check that all RfqStatus values are included
    const allStatusesIncluded = Object.values(RfqStatus).every((status) =>
        detailedProgressData.some((data) => data.id === status),
    );
    if (!allStatusesIncluded) {
        throwErrorUnlessProduction(
            `detailedProgressData does not include all RfqStatus values got ${detailedProgressData.map(
                (data) => data.id,
            )}`,
        );
    }

    const filteredProgressData = includeInProgress
        ? detailedProgressData
        : detailedProgressData.filter((item) => !Object.values(colorSystem.blue).includes(item.color));

    return (
        <Box width="100%" display="flex" flexDirection="column" gap={3}>
            <Box display="flex" flexDirection="column" gap={2}>
                <RfqFilters
                    selectedPeriod={selectedPeriod}
                    setSelectedPeriod={setSelectedPeriod}
                    includeInProgress={includeInProgress}
                    setIncludeInProgress={setIncludeInProgress}
                    includeArchived={includeArchived}
                    setIncludeArchived={setIncludeArchived}
                />
                <RfqCounts
                    metrics={metrics}
                    inProgressCount={inProgressCount}
                    noBidCount={noBidCount}
                    lostCount={lostCount}
                    wonCount={wonCount}
                />
            </Box>
            <Box>
                <MultiProgressBar
                    data={filteredProgressData}
                    getColor={(data) => data.color}
                    getLabel={(data) => data.label}
                    getValue={(data) => data.value}
                    showPercentages={true}
                    legendType="vertical"
                />
            </Box>
        </Box>
    );
}
