import * as z from 'zod';
import { ApiIntegrationStatusRuntype } from './3rdPartyBackendTypes';

export interface RutronikCredentialsDTO extends z.infer<typeof RutronikCredentialsDTORuntype> {}
export const RutronikCredentialsDTORuntype = z.object({
    api_key: z.string(),
});

export interface RutronikCredentialsInputDTO extends z.infer<typeof RutronikCredentialsInputDTORuntype> {}
export const RutronikCredentialsInputDTORuntype = z.object({
    api_key: z.string().optional(),
});

export interface RutronikResponseBodyDTO extends z.infer<typeof RutronikResponseBodyDTORuntype> {}
export const RutronikResponseBodyDTORuntype = z.object({
    data: RutronikCredentialsDTORuntype.nullable(),
    status: ApiIntegrationStatusRuntype,
});
