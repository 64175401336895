import * as z from 'zod';
import { LanguageEnumRuntype } from '../../utils/i18n';
import { CountryAlpha2CodeRuntype } from '../organizationSettings';

export const CustomerBillingAddressRuntype = z.object({
    line1: z.string().nullable(),
    line2: z.string().nullable(),
    postal_code: z.string().nullable(),
    city: z.string().nullable(),
    /// Country code in ISO 3166-1 alpha-2 format.
    country_code: CountryAlpha2CodeRuntype.nullable(),
});

export const CustomerBillingAddressAllRequiredRuntype = z.object({
    line1: z.string(),
    line2: z.string(),
    postal_code: z.string(),
    city: z.string(),
    /// Country code in ISO 3166-1 alpha-2 format.
    country_code: CountryAlpha2CodeRuntype,
});

export interface CustomerBillingAddressAllRequiredDTO
    extends z.infer<typeof CustomerBillingAddressAllRequiredRuntype> {}

export interface CustomerBillingAddressDTO extends z.infer<typeof CustomerBillingAddressRuntype> {}

// please keep in sync with the values found in rust-workspace/api-docs/src/openapi.yaml
export const VATIdentificationTypeRuntype = z.union([
    z.literal('ad_nrt'),
    z.literal('ar_cuit'),
    z.literal('au_abn'),
    z.literal('au_arn'),
    z.literal('eu_vat'),
    z.literal('bo_tin'),
    z.literal('br_cnpj'),
    z.literal('br_cpf'),
    z.literal('bg_uic'),
    z.literal('ca_bn'),
    z.literal('ca_gst_hst'),
    z.literal('ca_pst_bc'),
    z.literal('ca_pst_mb'),
    z.literal('ca_pst_sk'),
    z.literal('ca_qst'),
    z.literal('cl_tin'),
    z.literal('cn_tin'),
    z.literal('co_nit'),
    z.literal('cr_tin'),
    z.literal('do_rcn'),
    z.literal('ec_ruc'),
    z.literal('eg_tin'),
    z.literal('sv_nit'),
    z.literal('eu_oss_vat'),
    z.literal('ge_vat'),
    z.literal('hk_br'),
    z.literal('hu_tin'),
    z.literal('is_vat'),
    z.literal('in_gst'),
    z.literal('id_npwp'),
    z.literal('il_vat'),
    z.literal('jp_cn'),
    z.literal('jp_rn'),
    z.literal('jp_trn'),
    z.literal('ke_pin'),
    z.literal('li_uid'),
    z.literal('my_frp'),
    z.literal('my_itn'),
    z.literal('my_sst'),
    z.literal('mx_rfc'),
    z.literal('nz_gst'),
    z.literal('no_vat'),
    z.literal('pe_ruc'),
    z.literal('ph_tin'),
    z.literal('ro_tin'),
    z.literal('ru_inn'),
    z.literal('ru_kpp'),
    z.literal('sa_vat'),
    z.literal('rs_pib'),
    z.literal('sg_gst'),
    z.literal('sg_uen'),
    z.literal('si_tin'),
    z.literal('za_vat'),
    z.literal('kr_brn'),
    z.literal('es_cif'),
    z.literal('ch_vat'),
    z.literal('tw_vat'),
    z.literal('th_vat'),
    z.literal('tr_tin'),
    z.literal('ua_vat'),
    z.literal('ae_trn'),
    z.literal('gb_vat'),
    z.literal('us_ein'),
    z.literal('uy_ruc'),
    z.literal('ve_rif'),
    z.literal('vn_tin'),
]);
export type VATIdentificationTypeDTO = z.infer<typeof VATIdentificationTypeRuntype>;

export const allVATIdentificationTypes = VATIdentificationTypeRuntype.options.map((alternative) => alternative.value);

const IncompleteVatIdRuntype = z.object({
    Incomplete: z.string(),
});

const CompleteVatIdRuntype = z.object({
    Complete: z.object({
        id: z.string(),
        id_type: VATIdentificationTypeRuntype,
    }),
});

const VatIdRuntype = IncompleteVatIdRuntype.or(CompleteVatIdRuntype);

const BusinessCustomerDataRuntype = z.object({
    vat_id: VatIdRuntype.nullable(),
    commercial_register_number: z.string().nullable(),
});

const PrivateCustomerTypeRuntype = z.object({
    type: z.literal('Private'),
});

const BusinessCustomerTypeRuntype = z.object({
    type: z.literal('Business'),
    data: BusinessCustomerDataRuntype,
});

const CustomerTypeRuntype = PrivateCustomerTypeRuntype.or(BusinessCustomerTypeRuntype);

export type CustomerType = z.infer<typeof CustomerTypeRuntype>;

type Equals<X, Y> = X extends Y ? (Y extends X ? true : false) : false;
type Nullable<T> = { [K in keyof T]: T[K] | null };
// This following checks that the CustomerBillingAddressAllRequiredDTO is equivalent
// to CustomerBillingAddressDTO in all ways exxcept that CustomerBillingAddressDTO has everything nullable.
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const nullableCheck: Equals<Nullable<CustomerBillingAddressAllRequiredDTO>, CustomerBillingAddressDTO> = true;

export const CustomerDTORuntype = z.object({
    id: z.string(),
    name: z.string(),
    billing_address: CustomerBillingAddressRuntype.nullable(),
    default_contact_person: z.string().nullable(),
    customer_number: z.string().nullable(),
    customer_type: CustomerTypeRuntype,
    language: LanguageEnumRuntype,
});
export interface CustomerDTO extends z.infer<typeof CustomerDTORuntype> {}

const PostBusinessCustomerTypeRuntype = z.object({
    type: z.literal('Business'),
    data: z.object({
        vat_id: z
            .object({
                id: z.string(),
                id_type: VATIdentificationTypeRuntype,
            })
            .nullable(),
        commercial_register_number: z.string().nullable(),
    }),
});

const PostCustomerTypeRuntype = PrivateCustomerTypeRuntype.or(PostBusinessCustomerTypeRuntype);
export type PostCustomerTypeDTO = z.infer<typeof PostCustomerTypeRuntype>;

export const CustomerDTOPostRuntype = z.object({
    name: z.string(),
    default_contact_person: z.string(),
    customer_number: z.string().nullable(),
    language: LanguageEnumRuntype,
    customer_type: PostCustomerTypeRuntype,
    billing_address: CustomerBillingAddressRuntype.nullable(),
});
export interface CustomerDTOPost extends z.infer<typeof CustomerDTOPostRuntype> {}
export type CustomerPatchDTO = CustomerDTOPost;
