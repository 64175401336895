import { LanguageEnum } from '@luminovo/commons';
import {
    FrenchFlagIcon,
    GermanFlagIcon,
    HalfUSHalfBritishFlagIcon,
    ItalianFlagIcon,
    SpanishFlagIcon,
} from '@luminovo/design-system';
import { assertUnreachable } from './typingUtils';

export function getIconForLanguage(language: LanguageEnum) {
    switch (language) {
        case 'en':
            return <HalfUSHalfBritishFlagIcon />;
        case 'de':
            return <GermanFlagIcon />;
        case 'fr':
            return <FrenchFlagIcon />;
        case 'es':
            return <SpanishFlagIcon />;
        case 'it':
            return <ItalianFlagIcon />;
        default:
            assertUnreachable(language);
    }
}
