import * as z from 'zod';

export enum PcbAlertTypeEnum {
    RenderingError = 'RenderingError',
    FileTimeout = 'FileTimeout',
    UnusualBoardSize = 'UnusualBoardSize',
    NoOutlineFile = 'NoOutlineFile',
    GeneratedOutline = 'GeneratedOutline',
    // Not used in the backend yet
    InconclusiveDimensions = 'InconclusiveDimensions',
    NoDrillFile = 'NoDrillFile',
    NoSoldermask = 'NoSoldermask',
    OddNumberOfCopperFiles = 'OddNumberOfCopperFiles',
    NoSilkscreen = 'NoSilkscreen',
    NoPlatedVias = 'NoPlatedVias',
}

const PcbAlertStatusRuntype = z.enum(['Active', 'Dismissed', 'Resolved']);

export type PcbAlertStatus = z.infer<typeof PcbAlertStatusRuntype>;

const PcbAlertTypeRuntype = z.union([
    z.object({
        type: z.literal(PcbAlertTypeEnum.RenderingError),
        file: z.string(),
    }),
    z.object({
        type: z.literal(PcbAlertTypeEnum.FileTimeout),
        file: z.string(),
    }),
    z.object({
        type: z.literal('Pcb'),
        pcb_alert_type: z.object({
            type: z
                .literal(PcbAlertTypeEnum.UnusualBoardSize)
                .or(z.literal(PcbAlertTypeEnum.NoOutlineFile))
                .or(z.literal(PcbAlertTypeEnum.GeneratedOutline))
                .or(z.literal(PcbAlertTypeEnum.InconclusiveDimensions))
                .or(z.literal(PcbAlertTypeEnum.NoDrillFile))
                .or(z.literal(PcbAlertTypeEnum.RenderingError))
                .or(z.literal(PcbAlertTypeEnum.FileTimeout))
                .or(z.literal(PcbAlertTypeEnum.NoSoldermask))
                .or(z.literal(PcbAlertTypeEnum.NoSilkscreen))
                .or(z.literal(PcbAlertTypeEnum.OddNumberOfCopperFiles))
                .or(z.literal(PcbAlertTypeEnum.NoPlatedVias)),
        }),
    }),
]);

export type PcbAlertTypeDTO = z.infer<typeof PcbAlertTypeRuntype>;

export const PcbAlertRuntype = z.object({
    id: z.string(),
    custom_part: z.string(),
    status: PcbAlertStatusRuntype,
    alert_type: PcbAlertTypeRuntype,
    creation_date: z.string(),
});

export type PcbAlertDTO = z.infer<typeof PcbAlertRuntype>;
