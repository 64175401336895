import * as z from 'zod';
import { websocketEndpoint } from '../websockets/websocketEndpoint';
import { PCBStreamMessageRuntype } from './pcbMessageBackendTypes';

export const pcbWebSocketEndpoints = {
    'GET /ems/pcb/v2/pcbs/:pcbId/updates': websocketEndpoint({
        description: 'PCB updates stream',
        pathParams: z.object({ pcbId: z.string() }),
        queryParams: z.object({ k: z.string() }),
        responseMessage: PCBStreamMessageRuntype,
    }),
};
