import * as z from 'zod';
import { parseRuntype } from '../../utils';

//These are the "known" integrations which we can append an intercom
// article and (if required) a label to.
export const OrbweaverIntegrationKnownRuntype = z.enum(['Avnet', 'RS Components']);

export type OrbweaverIntegrationKnownDTO = z.infer<typeof OrbweaverIntegrationKnownRuntype>;
export const OrbweaverIntegrationRuntype = OrbweaverIntegrationKnownRuntype.or(z.string());
export type OrbweaverIntegrationDTO = z.infer<typeof OrbweaverIntegrationRuntype>;

export const isOrbweaverIntegrationKnown = (value: string): value is OrbweaverIntegrationKnownDTO => {
    return parseRuntype(OrbweaverIntegrationKnownRuntype, value);
};

export interface OrbweaverLicenseDTO extends z.infer<typeof OrbweaverLicenseDTORuntype> {}
export const OrbweaverLicenseDTORuntype = z.object({
    configured: z.boolean(),
    active: z.array(OrbweaverIntegrationRuntype),
    inactive: z.array(OrbweaverIntegrationRuntype),
});
