import * as z from 'zod';
import { ApiIntegrationStatusRuntype } from './3rdPartyBackendTypes';

export type ArrowLocaleCurrencyDTO = z.infer<typeof ArrowLocaleCurrencyDTORuntype>;
export const ArrowLocaleCurrencyDTORuntype = z.enum([
    'USD',
    'GBP',
    'EUR',
    'JPY',
    'SGD',
    'AUD',
    'CAD',
    'HKD',
    'MYR',
    'KRW',
    'BRL',
    'CNY',
    'INR',
    'MXN',
    'TWD',
]);

export interface ArrowCredentialsDTO extends z.infer<typeof ArrowCredentialsDTORuntype> {}
export const ArrowCredentialsDTORuntype = z.object({
    login: z.string(),
    api_key: z.string(),
    currency: ArrowLocaleCurrencyDTORuntype,
});

export interface ArrowCredentialsInputDTO extends z.infer<typeof ArrowCredentialsInputDTORuntype> {}
export const ArrowCredentialsInputDTORuntype = z.object({
    login: z.string().optional(),
    api_key: z.string().optional(),
    currency: ArrowLocaleCurrencyDTORuntype.optional(),
});

export interface ArrowResponseBodyDTO extends z.infer<typeof ArrowResponseBodyDTORuntype> {}
export const ArrowResponseBodyDTORuntype = z.object({
    data: ArrowCredentialsDTORuntype.nullable(),
    status: ApiIntegrationStatusRuntype,
});
