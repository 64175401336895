import { isEqual } from '@luminovo/commons';
import {
    BomFileDTO,
    BomScreeningSheet,
    ColumnMap,
    DisplayableColumnIds,
    ExcelLineType,
    PreviousLinesSearchVariant,
    ScreenerHandlerOutput,
    SingleOriginalExcelRow,
} from '@luminovo/http-client';
import React from 'react';
import { transEnum } from '../../../../components/localization/TransEnum';
import { columnNameTranslations } from '../../../../resources/bomImporter/bomImporterBackendTypes';
import { typeSafeObjectKeys } from '../../../../utils/typingUtils';
import { parseColumns } from '../../../BomImporter/parseColumns';
import { reducer } from './reducer';
import { defaultTagsState, loadColumnMapping, saveColumnMapping } from './saveAndLoadColumnMapping';
import { loadPrevRawColumns, saveRawColumns } from './saveAndLoadRawColumns';
import { ColumnTag, ColumnTagsSheetsAction, ColumnTagsSheetsState, TaggedSheet } from './types';

const defaultColumnMapFieldsToExclude: Array<keyof ColumnMap> = ['generic_part_type', 'enumeration'];

export function createInitialTagsState(bomImporterScreeningMultiData: ScreenerHandlerOutput): ColumnTagsSheetsState {
    const prevColumnHeader = loadPrevRawColumns();

    const sheets: TaggedSheet[] = bomImporterScreeningMultiData.sheets.map(
        (bomImporterScreeningData: BomScreeningSheet, index: number) => {
            const excelRows: SingleOriginalExcelRow[] | undefined = bomImporterScreeningData?.excel_lines;
            const columns = parseColumns({
                rawHeaderRow: bomImporterScreeningData?.header_metadata?.raw_header_row,
                excelRows,
            });
            const columnMappings = bomImporterScreeningData.column_map;

            const rawColumnHeader = bomImporterScreeningData.header_metadata?.raw_header_row;
            const isSameColumnHeader = isEqual(rawColumnHeader, prevColumnHeader);

            if (isSameColumnHeader) {
                return loadColumnMapping().sheets[index];
            }

            const tags: ColumnTag[] = typeSafeObjectKeys(columnMappings ?? [])
                .filter((tagId) => !defaultColumnMapFieldsToExclude.includes(tagId))
                .map((tagId): ColumnTag => {
                    const mappings = columnMappings[tagId] ?? [];
                    const mappedColumnIds = mappings.map((id) => String(id));
                    const associatedTo = columns.filter((col) => {
                        return mappedColumnIds.includes(col.id);
                    });
                    return {
                        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                        id: tagId as DisplayableColumnIds,
                        label: transEnum(tagId, columnNameTranslations),
                        associatedTo,
                    };
                });

            return { tags };
        },
    );
    if (bomImporterScreeningMultiData.sheets[0].header_metadata?.raw_header_row) {
        saveRawColumns({
            headerData: bomImporterScreeningMultiData.sheets[0].header_metadata?.raw_header_row,
        });
    }

    const currentlyUsedTags = sheets[0].tags.filter((tag) => tag.associatedTo.length > 0).map((tag) => tag.id) ?? [];

    return {
        sheets,
        bomSheetIndex: 0,
        amlSheetIndex: null,
        activeTab: 'BOM',
        hasChanged: false,
        previousLinesSearch: {
            variant: PreviousLinesSearchVariant.AllColumns,
            columns: currentlyUsedTags,
        },
    };
}

export function createInitialTagsStateFromBomFile(
    bomFileData: BomFileDTO | undefined,
    excelRows: ExcelLineType,
): ColumnTagsSheetsState {
    const columnMap = bomFileData?.column_map;
    const columns = parseColumns({
        rawHeaderRow: bomFileData?.raw_header,
        excelRows,
        columnMap,
    });

    const columnMappings = bomFileData?.column_map ?? {};

    const initialTags: ColumnTag[] = typeSafeObjectKeys(columnMappings ?? [])
        .filter((tagId) => !defaultColumnMapFieldsToExclude.includes(tagId))
        .map((tagId): ColumnTag => {
            const mappings = columnMappings ? columnMappings[tagId] : [];
            const mappedColumnIds = mappings.map((id) => String(id));
            const associatedTo = columns.filter((col) => {
                return mappedColumnIds.includes(col.id);
            });
            return {
                // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                id: tagId as DisplayableColumnIds,
                label: transEnum(tagId, columnNameTranslations),
                associatedTo,
            };
        });
    const sheets: TaggedSheet[] = [{ tags: initialTags }];
    return {
        sheets,
        bomSheetIndex: 0,
        amlSheetIndex: null,
        activeTab: 'BOM',
        hasChanged: false,
        previousLinesSearch: {
            variant: PreviousLinesSearchVariant.AllColumns,
            columns: [],
        },
    };
}

export function useTagsState() {
    const initialState: ColumnTagsSheetsState = defaultTagsState;

    const reduceAndSave = React.useCallback((state: ColumnTagsSheetsState, action: ColumnTagsSheetsAction) => {
        const newState = reducer(state, action);

        saveColumnMapping({ tableState: newState });
        return newState;
    }, []);

    return React.useReducer(reduceAndSave, initialState);
}
