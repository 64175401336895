import * as z from 'zod';
import { ApprovalStatus } from '../backendTypes';
import {
    CustomComponentFull,
    CustomComponentFullRuntype,
    OtsComponentFull,
    OtsComponentFullRuntype,
} from '../internalPartNumber/internalPartNumberBackendTypes';
import { PartSpecificationTypes } from './PartSpecificationTypes';
import {
    CustomFullPart,
    CustomFullPartRuntype,
    CustomOptionDTORuntype,
    CustomOptionTypes,
    CustomPartTypeEnum,
    GenericFullPart,
    GenericFullPartRuntype,
    GenericPartTypes,
    IncompleteGenericFullPart,
    OtsFullPart,
    OtsFullPartRuntype,
    PartAlternativeSimilarityEnumRuntype,
    PartOptionDTORuntype,
    PartSuggestionOriginRuntype,
    StandardPartTypes,
    TechnicalParametersRuntype,
} from './partBackendTypes';

export interface CustomPartSpecification extends z.infer<typeof CustomPartSpecificationRuntype> {}
const CustomPartSpecificationRuntype = z.object({
    type: z.literal(PartSpecificationTypes.Custom),
    data: z.array(CustomOptionDTORuntype),
});

export interface OtsPartSpecification extends z.infer<typeof OtsPartSpecificationRuntype> {}
const OtsPartSpecificationRuntype = z.object({
    type: z.literal(PartSpecificationTypes.OffTheShelf),
    data: z.object({
        is_manufacturer_free: z.boolean(),
        part_options: z.array(PartOptionDTORuntype),
    }),
});

export type PartSpecification = z.infer<typeof PartSpecificationRuntype>;
export const PartSpecificationRuntype = z.union([CustomPartSpecificationRuntype, OtsPartSpecificationRuntype]);

const SuggestablePartRuntype = z.union([
    z.object({
        type: z.literal(StandardPartTypes.OffTheShelf),
        data: z.string(),
    }),
    z.object({
        type: z.literal(StandardPartTypes.Generic),
        data: z.string(),
    }),
    z.object({
        type: z.literal(StandardPartTypes.Ipn),
        data: z.string(),
    }),
    z.object({
        type: z.literal('IncompleteGeneric'),
        data: TechnicalParametersRuntype,
    }),
]);

export interface PartSuggestion extends z.infer<typeof PartSuggestionRuntype> {}
export const PartSuggestionRuntype = z.object({
    part: SuggestablePartRuntype,
    origin: PartSuggestionOriginRuntype.nullable(),
});

/**
 * A CustomPartSpecification or undefined if the given part is not a custom part
 */
export function customPartOrUndefined(part?: PartSpecification | null): CustomPartSpecification | undefined {
    if (!part) {
        return undefined;
    }
    if (part.type === PartSpecificationTypes.Custom) {
        return part;
    }
    return undefined;
}

/**
 * An OtsPartSpecification or undefined if the given part is not a custom part
 */
export function otsPartOrUndefined(part?: PartSpecification | null): OtsPartSpecification | undefined {
    if (!part) {
        return undefined;
    }
    if (part?.type === PartSpecificationTypes.OffTheShelf) {
        return part;
    }
    return undefined;
}

/**
 *
 * @param partSpecification The part specification to retrieve the off-the-shelf part option ids
 * @returns a list of part option ids that are approved and are of off-the-sheld standard part type
 */
export function getApprovedOtsPartIds(partSpecification: PartSpecification): string[] {
    if (partSpecification.type !== PartSpecificationTypes.OffTheShelf) {
        return [];
    }
    return partSpecification.data.part_options
        .filter((part) => {
            return part.approval_status === ApprovalStatus.Approved && part.part.type === StandardPartTypes.OffTheShelf;
        })
        .map((part) => {
            return part.part.data;
        });
}

/**
 *
 * @param partSpecification The part specification to retrieve the generic part option ids
 * @returns a list of part option ids that are approved and are of generic standard part type
 */
export function getApprovedGenericPartIds(partSpecification: PartSpecification): string[] {
    if (partSpecification.type !== PartSpecificationTypes.OffTheShelf) {
        return [];
    }
    return partSpecification.data.part_options
        .filter((part) => {
            return part.approval_status === ApprovalStatus.Approved && part.part.type === StandardPartTypes.Generic;
        })
        .map((part) => {
            return part.part.data;
        });
}

export function getApprovedCustomPartIds(partSpecification: PartSpecification): string[] {
    if (partSpecification.type !== PartSpecificationTypes.Custom || !partSpecification.data) {
        return [];
    }
    return partSpecification.data
        .filter((part) => {
            return part.approval_status === ApprovalStatus.Approved && part.part.type === CustomOptionTypes.CustomPart;
        })
        .map((part) => {
            return part.part.data;
        });
}

/**
 *
 * @param partSpecification The part specification to retrieve the IPN part option ids
 * @returns a list of part option ids that are approved and are of IPN standard part type
 */
export function getApprovedIpnIds(partSpecification: PartSpecification): string[] {
    if (partSpecification.type !== PartSpecificationTypes.OffTheShelf) {
        return [];
    }
    return partSpecification.data.part_options
        .filter((part) => {
            return part.approval_status === ApprovalStatus.Approved && part.part.type === StandardPartTypes.Ipn;
        })
        .map((part) => {
            return part.part.data;
        });
}

export function getCustomApprovedIpnIds(partSpecification: PartSpecification): string[] {
    if (partSpecification.type !== PartSpecificationTypes.Custom) {
        return [];
    }

    return partSpecification.data
        .filter((part) => {
            return (
                part.approval_status === ApprovalStatus.Approved && part.part.type === CustomOptionTypes.CustomComponent
            );
        })
        .map((part) => {
            return part.part.data;
        });
}

// -----

const FullPartRuntype = z.union([
    GenericFullPartRuntype,
    CustomFullPartRuntype,
    OtsFullPartRuntype,
    OtsComponentFullRuntype,
    CustomComponentFullRuntype,
]);
export type FullPart = z.infer<typeof FullPartRuntype>;

export function isPcbCustomFullPart(part?: FullPart | null): part is CustomFullPart {
    return isCustomFullPart(part) && part.type.name === CustomPartTypeEnum.PCB;
}

/**
 * Checks if a part is a CustomFullPart type.
 * PERFORMANCE: This function is called frequently when working with the BOM module. Any changes to the implementation
 * should be tested with large BOMs to ensure performance is not degraded, as slow type checks can
 * significantly impact overall UI responsiveness. Context: https://luminovo.slack.com/archives/C05KK5GDCRZ/p1741586944892149
 */
export function isCustomFullPart(part?: FullPart | null): part is CustomFullPart {
    return (
        !!part &&
        !isOtsFullPart(part) &&
        !isGenericFullPart(part) &&
        !isOtsComponentFull(part) &&
        !isCustomComponentFull(part)
    );
}

/**
 * Checks if a part is a OtsFullPart type.
 * PERFORMANCE: This function is called frequently when working with the BOM module. Any changes to the implementation
 * should be tested with large BOMs to ensure performance is not degraded, as slow type checks can
 * significantly impact overall UI responsiveness. Context: https://luminovo.slack.com/archives/C05KK5GDCRZ/p1741586944892149
 */
export function isOtsFullPart(part?: FullPart | IncompleteGenericFullPart | null): part is OtsFullPart {
    return !!part && 'id' in part && 'mpn' in part;
}

/**
 * Checks if a part is a GenericFullPart type.
 * PERFORMANCE: This function is called frequently when working with the BOM module. Any changes to the implementation
 * should be tested with large BOMs to ensure performance is not degraded, as slow type checks can
 * significantly impact overall UI responsiveness. Context: https://luminovo.slack.com/archives/C05KK5GDCRZ/p1741586944892149
 */
export function isGenericFullPart(part?: FullPart | IncompleteGenericFullPart | null): part is GenericFullPart {
    return (
        !!part &&
        'id' in part &&
        !isIncompleteGenericFullPart(part) &&
        'content' in part &&
        !isOtsFullPart(part) &&
        !isOtsComponentFull(part)
    );
}

/**
 * Checks if a part is a OtsComponentFull type.
 * PERFORMANCE: This function is called frequently when working with the BOM module. Any changes to the implementation
 * should be tested with large BOMs to ensure performance is not degraded, as slow type checks can
 * significantly impact overall UI responsiveness. Context: https://luminovo.slack.com/archives/C05KK5GDCRZ/p1741586944892149
 */
export function isOtsComponentFull(part?: FullPart | IncompleteGenericFullPart | null): part is OtsComponentFull {
    return !!part && 'id' in part && 'part_specifications' in part && 'matches' in part && 'state' in part;
}

/**
 * Checks if a part is a CustomComponentFull type.
 * PERFORMANCE: This function is called frequently when working with the BOM module. Any changes to the implementation
 * should be tested with large BOMs to ensure performance is not degraded, as slow type checks can
 * significantly impact overall UI responsiveness. Context: https://luminovo.slack.com/archives/C05KK5GDCRZ/p1741586944892149
 */
export function isCustomComponentFull(part?: FullPart | IncompleteGenericFullPart | null): part is CustomComponentFull {
    return !!part && 'id' in part && 'description' in part && 'matches' in part && 'state' in part;
}

/**
 * Checks if a part is a FullPart type.
 * PERFORMANCE: This function is called frequently when working with the BOM module. Any changes to the implementation
 * should be tested with large BOMs to ensure performance is not degraded, as slow type checks can
 * significantly impact overall UI responsiveness. Context: https://luminovo.slack.com/archives/C05KK5GDCRZ/p1741586944892149
 */
export function isFullPart(part: FullPart | IncompleteGenericFullPart): part is FullPart {
    return 'id' in part;
}

/**
 * Checks if a part is a IncompleteGenericFullPart type.
 * PERFORMANCE: This function is called frequently when working with the BOM module. Any changes to the implementation
 * should be tested with large BOMs to ensure performance is not degraded, as slow type checks can
 * significantly impact overall UI responsiveness. Context: https://luminovo.slack.com/archives/C05KK5GDCRZ/p1741586944892149
 */
export function isIncompleteGenericFullPart(
    part: FullPart | IncompleteGenericFullPart | string,
): part is IncompleteGenericFullPart {
    if (
        typeof part === 'object' &&
        part !== null &&
        'type' in part &&
        'technical_parameters' in part &&
        (part.type === GenericPartTypes.Resistor || part.type === GenericPartTypes.Capacitor)
    ) {
        if (part.type === GenericPartTypes.Resistor) {
            return part.technical_parameters.resistance === null || part.technical_parameters.package_id === null;
        } else if (part.type === GenericPartTypes.Capacitor) {
            return part.technical_parameters.capacitance === null || part.technical_parameters.package_id === null;
        }
    }
    return false;
}

export interface AddPartEvents extends z.infer<typeof AddPartEventsRuntype> {}
export const AddPartEventsRuntype = z.object({
    assembly_id: z.string(),
    design_item_ids: z.array(z.string()),
    alternative_origin: PartAlternativeSimilarityEnumRuntype.optional(),
    screen_section: z
        .union([
            z.literal('comparePartsModal'),
            z.literal('alternativeParts'),
            z.literal('partialMatches'),
            z.literal('ipnSearch'),
            z.literal('mpnSearch'),
            z.literal('otsPartForm'),
            z.literal('genericPartForm'),
            z.literal('customPartForm'),
        ])
        .optional(),
});
