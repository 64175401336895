import React from 'react';
import { PageLayout } from '../../components/PageLayout';

import { useRfQ } from '../../resources/rfq/rfqHandler';
import { UrlParams } from '../../utils/routes';
import { ViewContext } from '../Bom/components/ModuleTableData';
import { PcbSpecificationTab } from './PcbSpecificationTab';
import { ToolbarPcb } from './components/ToolbarPcb/ToolbarPcb';
import { useIsPcbEditable } from './utils/useIsPcbSectionEditable';

export const PcbSpecificationPage = ({
    pathParams,
}: UrlParams<'/rfqs/:rfqId/bom/assembly/:assemblyId/pcb/specification'>) => {
    const { assemblyId, rfqId } = pathParams;
    const { data: rfq } = useRfQ(rfqId);
    const { isRfqEditable } = useIsPcbEditable(rfq);

    const viewContext: ViewContext = React.useMemo(() => {
        return {
            type: 'WithinRfQ',
            rfqId,
        };
    }, [rfqId]);

    return (
        <PageLayout removeBottomPadding header={<ToolbarPcb assemblyId={assemblyId} rfqId={rfqId} />} layout="fragment">
            <PcbSpecificationTab
                assemblyId={assemblyId}
                rfqId={rfqId}
                isEditable={isRfqEditable}
                viewContext={viewContext}
            />
        </PageLayout>
    );
};
