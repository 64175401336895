import * as z from 'zod';
import { ApiIntegrationStatusRuntype } from './3rdPartyBackendTypes';

export interface CorestaffCredentialsDTO extends z.infer<typeof CorestaffCredentialsDTORuntype> {}
export const CorestaffCredentialsDTORuntype = z.object({
    api_key: z.string(),
});

export interface CorestaffCredentialsInputDTO extends z.infer<typeof CorestaffCredentialsInputDTORuntype> {}
export const CorestaffCredentialsInputDTORuntype = z.object({
    api_key: z.string().optional(),
});

export interface CorestaffResponseBodyDTO extends z.infer<typeof CorestaffResponseBodyDTORuntype> {}
export const CorestaffResponseBodyDTORuntype = z.object({
    data: CorestaffCredentialsDTORuntype.nullable(),
    status: ApiIntegrationStatusRuntype,
});
