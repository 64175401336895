import { t } from '@lingui/macro';
import { isPresent } from '@luminovo/commons';
import { colorSystem, Flexbox, Text, Tooltip } from '@luminovo/design-system';
import React from 'react';

export const NoValue: React.FunctionComponent = () => (
    <Tooltip title={t`Unknown`}>
        <Text variant="body" color={colorSystem.neutral[6]}>
            -
        </Text>
    </Tooltip>
);

export const ValueText: React.FunctionComponent<{
    value: string | number | null | undefined;
}> = ({ value }) => {
    return (
        <Text variant="body" color={colorSystem.neutral[9]} style={{ textAlign: 'end' }}>
            {isPresent(value) ? value : <NoValue />}
        </Text>
    );
};

export const DetailsSection: React.FunctionComponent<{
    label: string | JSX.Element;
}> = ({ label, children }) => {
    return (
        <Flexbox flexDirection={'column'} gap={12}>
            <Text variant="h4" color={colorSystem.neutral[7]}>
                {label}
            </Text>
            {children}
        </Flexbox>
    );
};

export const DetailsLabel: React.FunctionComponent<{
    label: string | JSX.Element;
}> = ({ label, children }) => {
    return (
        <Flexbox justifyContent={'space-between'} gap={24}>
            <Text variant="body" color={colorSystem.neutral[7]} style={{ whiteSpace: 'nowrap' }}>
                {label}
            </Text>
            {children}
        </Flexbox>
    );
};
