import { getToken } from '@luminovo/auth';
import { AzureExtractionResult, http, OtsFullPart, RfqContext } from '@luminovo/http-client';
import { processPdf, RegionNetwork } from '@luminovo/sourcing-core';
import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { useDebugErrorHandler } from '../http/debugErrorHandler';
import { useHttpQuery } from '../http/useHttpQuery';
import { convertRfqContext } from '../part/partHandler';

export function useOTSOffer({ offerId, rfqContext }: { offerId: string | undefined; rfqContext: RfqContext }) {
    return useHttpQuery(
        'POST /offers/off-the-shelf/bulk',
        {
            requestBody: { ids: [offerId!], ...convertRfqContext(rfqContext) },
        },
        {
            select: (res) => res.items[0],
            enabled: !!offerId,
        },
    );
}

export function useOTSOffers({ offerIds, rfqContext }: { offerIds: string[] | undefined; rfqContext: RfqContext }) {
    return useHttpQuery(
        'POST /offers/off-the-shelf/bulk',
        {
            requestBody: { ids: offerIds!, ...convertRfqContext(rfqContext) },
        },
        {
            select: (res) => res.items,
            enabled: !!offerIds,
        },
    );
}

export const useCustomPartOffer = (offerId: string) => {
    return useHttpQuery(
        'POST /offers/custom-part/bulk',
        {
            requestBody: { ids: [offerId] },
        },
        {
            select: (res) => res.items[0],
        },
    );
};

export function useMutationAnalyzePdf(): UseMutationResult<
    {
        pdfExtractedData: AzureExtractionResult;
        file: File;
        regionNetwork: RegionNetwork;
        uploadedUrl?: string;
    },
    unknown,
    { file: File; expectedParts: OtsFullPart[] }
> {
    const token = getToken();
    const onError = useDebugErrorHandler();
    return useMutation({
        onError,
        mutationFn: async ({ file, expectedParts }) => {
            const form = new FormData();
            form.append('file', file);

            const pdfImportPromise = http(
                'POST /analyze/pdf',
                {
                    queryParams: { type: 'Offer' },
                    requestBody: form,
                },
                token,
            );

            const uploadedUrl = await pdfImportPromise.then((res) => res.attachment);

            const azureExtractionResult: AzureExtractionResult = {
                analyzeResult: await pdfImportPromise,
            };

            const regionNetwork = processPdf(azureExtractionResult, { expectedParts });

            return {
                pdfExtractedData: azureExtractionResult,
                file,
                regionNetwork,
                uploadedUrl,
            };
        },
    });
}
