import * as z from 'zod';
import { endpoint } from '../http/endpoint';
import { CorestaffCredentialsInputDTORuntype, CorestaffResponseBodyDTORuntype } from './corestaffBackendTypes';

export const corestaffEndpoints = {
    'GET /3rdparty/corestaff/credentials': endpoint({
        description: 'Returns credentials for corestaff integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: CorestaffResponseBodyDTORuntype,
    }),
    'POST /3rdparty/corestaff/credentials': endpoint({
        description: 'Uploads credentials for corestaff API integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: CorestaffCredentialsInputDTORuntype,
        responseBody: CorestaffResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/corestaff/credentials'],
    }),
    'PATCH /3rdparty/corestaff/credentials': endpoint({
        description: 'Updates credentials for corestaff API integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: CorestaffCredentialsInputDTORuntype,
        responseBody: CorestaffResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/corestaff/credentials'],
    }),
    'DELETE /3rdparty/corestaff/credentials': endpoint({
        description: 'Deletes existing credentials for corestaff API integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({ deleted: z.number() }),
        invalidates: ['GET /3rdparty/corestaff/credentials'],
    }),
};
