import { isPresent } from '@luminovo/commons';
import { Close as CloseIcon } from '@mui/icons-material';
import { DialogTitle as MuiDialogTitle, DialogTitleProps as MuiDialogTitleProps, styled } from '@mui/material';
import React from 'react';
import { Flexbox } from '../Flexbox';
import { Text } from '../Text';
import { SecondaryIconButton } from '../buttons';

interface TitleProps {
    title: React.ReactNode | string;
}

export const DefaultCloseButton: React.FunctionComponent<{ onClick: React.MouseEventHandler<HTMLButtonElement> }> = ({
    onClick,
}) => {
    return (
        <SecondaryIconButton onClick={onClick} size="small">
            <CloseIcon fontSize="inherit" />
        </SecondaryIconButton>
    );
};

const DefaultTitleComponent: React.FunctionComponent<TitleProps> = ({ title }) => {
    return <Text variant="h2">{title}</Text>;
};

type DialogTitleProps = Omit<MuiDialogTitleProps, 'children' | 'title'> & {
    title: React.ReactNode | string;
    center?: React.ReactNode;
    sticky?: boolean;
    onClose?: React.MouseEventHandler<HTMLButtonElement>;
    overrides?: {
        TitleComponent?: React.ComponentType<TitleProps>;
        CloseButton?: React.ComponentType;
    };
};

const StyledDialogTitle = styled(MuiDialogTitle)(({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
    position: 'sticky',
    top: 0,
    zIndex: 1,
}));

export const DialogTitle = React.forwardRef<unknown, DialogTitleProps>(
    ({ title, onClose, overrides = {}, center, style, sticky, ...props }, _) => {
        const { TitleComponent = DefaultTitleComponent, CloseButton = DefaultCloseButton } = overrides;

        const DialogTitleComponent = sticky ? StyledDialogTitle : MuiDialogTitle;

        return (
            <DialogTitleComponent style={{ paddingBottom: '12px', ...style }} {...props}>
                <Flexbox alignItems="center" justifyContent={'space-between'}>
                    <TitleComponent title={title} />
                    {center}
                    {isPresent(onClose) && <CloseButton onClick={onClose} />}
                </Flexbox>
            </DialogTitleComponent>
        );
    },
);
