import { assertPresent, isPresent } from '@luminovo/commons';
import { TableRow as MuiTableRow, TableCell } from '@mui/material';
import { Cell, flexRender } from '@tanstack/react-table';
import React from 'react';
import { Link } from 'react-router-dom';
import { TableComponents } from 'react-virtuoso';
import { colorSystem } from '../../theme';
import { Text } from '../Text';
import { RenderType, TanStackTableContext } from './type';
import { getCommonPinningStyles, parseOptionsValuesOrFunc } from './utils';

export const DefaultTableRow: TableComponents<unknown, TanStackTableContext>['TableRow'] = ({ context, ...props }) => {
    const { table, TableRow = MuiTableRow } = assertPresent(context);
    const { rows } = table.getRowModel();

    const handelRowClick = table.options.meta?.onRowClick;
    const row = rows[props['data-index']];
    const href = table.options.meta?.getRowRoute?.(row) ?? null;

    const cells = row
        ?.getVisibleCells()
        .map((cell) => (
            <InnerTableCell key={cell.id} cell={cell} href={href} sharedContext={table.options.meta?.sharedContext} />
        ));

    return (
        <TableRow
            {...props}
            onClick={() => handelRowClick?.(row, table)}
            style={{ cursor: isPresent(handelRowClick) ? 'pointer' : 'default' }}
        >
            {cells}
        </TableRow>
    );
};

export function InnerTableCell<TData, TSharedContext>({
    cell,
    href,
    sharedContext,
}: {
    cell: Cell<TData, unknown>;
    href: string | null;
    sharedContext: TSharedContext;
}): JSX.Element {
    const disableRowClick = cell.column.columnDef.meta?.enableOnRowClick === false;
    const tableCellProps = {
        variant: 'body' as const,
        style: {
            ...getCommonPinningStyles(cell.column),
            textAlign: cell.column.columnDef.meta?.align,
            borderBottom: `1px solid ${colorSystem.neutral[2]}`,
        },
        onClick: (e: React.MouseEvent<HTMLDivElement>) => {
            if (disableRowClick) {
                e.stopPropagation();
            }
        },
    };

    if (cell.column.columnDef.meta?.disableTableCell) {
        return <ContentRenderer cell={cell} sharedContext={sharedContext} renderType="generic" />;
    }

    if (!isPresent(href) || disableRowClick) {
        return (
            <TableCell {...tableCellProps}>
                <ContentRenderer cell={cell} sharedContext={sharedContext} />
            </TableCell>
        );
    }

    return (
        // Hack the component doesn't match - but not sure how to fix it
        <TableCell
            {...tableCellProps}
            // @ts-expect-error
            component={Link}
            to={href}
            style={{ ...tableCellProps.style, cursor: 'pointer', textDecoration: 'none', color: 'inherit' }}
        >
            <ContentRenderer cell={cell} sharedContext={sharedContext} />
        </TableCell>
    );
}

export function ContentRenderer<TData, TSharedContext>({
    cell,
    sharedContext,
    renderType: explicitRenderType,
}: {
    cell: Cell<TData, unknown>;
    sharedContext: TSharedContext;
    renderType?: 'generic';
}): JSX.Element {
    const context = Object.assign(cell.getContext(), { sharedContext });

    const type: RenderType = determineRenderType({
        cell,
        explicitRenderType,
    });

    const commonTextProps = {
        variant: 'inherit' as const,
        style: { display: 'block' },
    };

    switch (type) {
        case 'text':
            return (
                <Text {...commonTextProps} showEllipsis={true}>
                    {flexRender(cell.column.columnDef.cell, context)}
                </Text>
            );
        case 'number':
            return (
                <Text {...commonTextProps} showEllipsis={true} tabularNums={true}>
                    {flexRender(cell.column.columnDef.cell, context)}
                </Text>
            );
        case 'ellipsisOnly':
            return (
                <Text {...commonTextProps} showEllipsis={'ellipsisOnly'}>
                    {flexRender(cell.column.columnDef.cell, context)}
                </Text>
            );
        case 'generic':
            return flexRender(cell.column.columnDef.cell, context) as JSX.Element;
    }
}

function determineRenderType<TData>({
    cell,
    explicitRenderType,
}: {
    cell: Cell<TData, unknown>;
    explicitRenderType?: 'generic';
}): RenderType {
    const columnMeta = assertPresent(cell.column.columnDef.meta);
    if (isPresent(explicitRenderType)) {
        return explicitRenderType;
    }

    if (isPresent(columnMeta.renderType)) {
        return parseOptionsValuesOrFunc(columnMeta.renderType, cell.row.original);
    }

    switch (columnMeta?.dataType) {
        case 'text':
            return 'text';
        case 'number':
        case 'date':
        case 'monetaryValue':
            return 'number';
        default:
            return 'generic';
    }
}
