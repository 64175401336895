import { CustomFullPart, CustomPartType, DesignItemResponseDTO } from '@luminovo/http-client';
import { useCallback } from 'react';
import { AddMultiplePartOptionsParams } from '../types';
import { AddCustomPart } from './AddCustomPart';
import { useCustomPartSuggestions } from './useCustomPartSuggestions';

export const AddCustomPartTab = ({
    assemblyId,
    individualDesignItems,
    handleAddMultiplePartOptions,
    selectedPartType,
}: {
    assemblyId: string;
    individualDesignItems: DesignItemResponseDTO[];
    handleAddMultiplePartOptions: (params: AddMultiplePartOptionsParams) => void;
    selectedPartType?: CustomPartType;
}) => {
    const descriptionSuggestions = useCustomPartSuggestions({ assemblyId, individualDesignItems });

    const handleAppendCustomPart = useCallback(
        (newPart: CustomFullPart) => {
            handleAddMultiplePartOptions({ data: [newPart], sectionOfScreen: 'customPartForm' });
        },
        [handleAddMultiplePartOptions],
    );

    return (
        <AddCustomPart
            descriptionSuggestions={descriptionSuggestions}
            handleAppendCustomPart={handleAppendCustomPart}
            selectedPartType={selectedPartType}
        />
    );
};
