import * as z from 'zod';

import { ExchangedMonetaryValueBackendConvertedMVOnlyRuntype, MonetaryValueBackendRuntype } from '../backendTypes';
import { ManufacturingEntityStatusRuntype } from '../sharedManufacturingBackendTypes';

export enum ResourceType {
    Machine = 'Machine',
    Person = 'Person',
}
const ResourceTypeRuntype = z.nativeEnum(ResourceType);

export interface ResourceDTO extends z.infer<typeof ResourceDTORuntype> {}
export const ResourceDTORuntype = z.object({
    id: z.string(),
    name: z.string(),
    internal_number: z.string().nullable(),
    type: ResourceTypeRuntype,
    cost_per_hour: ExchangedMonetaryValueBackendConvertedMVOnlyRuntype,
    description: z.string().nullable(),
    status: ManufacturingEntityStatusRuntype,
    site_id: z.string().nullable(),
});

export const ResourcePostDTORuntype = ResourceDTORuntype.omit({ id: true, status: true, cost_per_hour: true }).extend({
    cost_per_hour: MonetaryValueBackendRuntype,
});

export interface ResourcePostDTO extends z.infer<typeof ResourcePostDTORuntype> {}

export interface ResourcesDTO extends z.infer<typeof ResourcesDTORuntype> {}
export const ResourcesDTORuntype = z.array(ResourceDTORuntype);
