import { t } from '@lingui/macro';
import { isPresent } from '@luminovo/commons';
import { MenuButton, MenuItem } from '@luminovo/design-system';
import { ExitToApp } from '@mui/icons-material';
import React from 'react';
import { useDesignItemsFromSolutionConfigurationCalculation } from '../../../../resources/solutionConfiguration/solutionConfigurationHandler';
import { route } from '../../../../utils/routes';
import { DesignItemPath } from '../../../Sourcing/components/SolutionConfigurations/SolutionConfigurationTypes';

/**
 * Helper function to create a route to a BOM assembly with design items
 */
export function createBomRoute(rfqId: string, path: DesignItemPath): string {
    return route(
        '/rfqs/:rfqId/bom/assembly/:assemblyId/details',
        { rfqId, assemblyId: path.assemblyId },
        {
            designItemId: path.designItemIds.join(','),
            isReadonly: 'null',
            filters: null,
            bomTab: null,
            dashboardFilters: null,
            search: null,
            onlyShowItemsWithManufacturingWarnings: null,
            currentParentAssemblyId: null,
        },
    );
}

export const ButtonGoToBom = React.memo(function AddPartAlternativesButton({
    rfqId,
    solutionConfigurationId,
}: {
    rfqId: string;
    solutionConfigurationId: string;
}): JSX.Element | null {
    const { designItemPaths } = useDesignItemsFromSolutionConfigurationCalculation(solutionConfigurationId);

    return (
        <MenuButton
            appearance="secondary"
            disabled={!isPresent(designItemPaths) || designItemPaths.length === 0}
            size="medium"
            icon={<ExitToApp />}
            label={t`Go to BOM`}
        >
            {designItemPaths?.map((path) => (
                <MenuItem
                    key={path.path}
                    href={createBomRoute(rfqId, path)}
                    label={path.path + ' > ' + path.designators.join(',')}
                />
            ))}
        </MenuButton>
    );
});
