import { isPresent, omit } from '@luminovo/commons';
import { Add, ArrowDropDownRounded } from '@mui/icons-material';
import {
    Autocomplete,
    AutocompleteProps,
    AutocompleteRenderOptionState,
    createFilterOptions,
    Divider,
    Paper,
} from '@mui/material';
import * as React from 'react';
import { colorSystem } from '../../../theme';
import { TertiaryButton } from '../../buttons';
import { Flexbox } from '../../Flexbox';
import { Highlight } from '../../Highlight';
import { Text } from '../../Text';
import { generateVirtualizeProps } from '../FieldMultiSelect/VirtualizeComponents';
import { FieldText, FieldTextProps } from '../FieldText';

/**
 * `freeSolo` is not supported because it makes type inference difficult.
 */
export type FieldSelectProps<TValue> = Omit<
    AutocompleteProps<TValue, false, boolean | undefined, false>,
    'value' | 'options' | 'onChange' | 'renderInput' | 'size' | 'renderOption' | 'freeSolo'
> &
    Partial<Pick<FieldTextProps, 'error' | 'helperText' | 'size'>> & {
        value: TValue | null;

        options: TValue[];

        onChange: (value: TValue | null) => void;

        /**
         * Callback that is called after the value has changed.
         *
         * Do not use this to update the value, this is already done by `onChange`.
         */
        onValueChange?: (value: TValue | null) => void;

        /**
         * Show a secondary label below the main label.
         */
        getOptionSublabel?: (option: TValue) => string | null;

        /**
         * Show a badge next to the main label.
         */
        getOptionBadge?: (option: TValue) => React.ReactNode | null;

        /**
         * Render the option, use `getOptionLabel` by default.
         *
         * Note: This is a wrapper function for the orignal `renderOption` in MUI Autocomplete.
         *       We avoid exposing `renderOption` directly because we cannot guarantee
         *       that all required props are set at all times. See here: https://mui.com/material-ui/migration/v5-component-changes/#update-renderoption
         */
        renderOption?: (option: TValue, state: AutocompleteRenderOptionState) => React.ReactNode;

        /**
         * Show a button in the bottom of the dropdown.
         */
        action?: {
            label: string;
            onClick: () => void;
            disabled?: boolean;
        };

        autoFocus?: boolean;

        virtualized?: boolean;

        inputProps?: Omit<FieldTextProps['inputProps'], 'value' | 'onChange'>;
    };

function FieldSelectInner<TValue>(
    {
        value,
        options,
        onChange,
        onValueChange,
        renderOption,
        getOptionLabel,
        getOptionSublabel,
        getOptionBadge,
        action,
        placeholder,
        error,
        helperText,
        autoFocus,
        virtualized = false,
        size = 'large',
        inputProps,
        ...rest
    }: FieldSelectProps<TValue>,
    outerRef: React.ForwardedRef<unknown>,
): JSX.Element {
    const [userTyped, setUserTyped] = React.useState(false);

    const filterOptions = createFilterOptions<TValue>({
        stringify: (option) => `${getOptionLabel?.(option)} ${getOptionSublabel?.(option)}`,
    });

    // Virtualization is only supported for non-grouped options.
    const isVirtualized = rest.groupBy === undefined && virtualized;
    const virtualizedProps = isVirtualized ? generateVirtualizeProps() : {};

    return (
        <Autocomplete
            ref={outerRef}
            value={value}
            options={options}
            onChange={(_, value) => {
                onChange(value);
                onValueChange?.(value);
            }}
            renderInput={(props) => (
                // Hack: `value` and `onChange` are required but have no effect because
                // the FieldText internal values are overridden by `props.inputProps.value`.
                <FieldText
                    {...props}
                    inputProps={{
                        style: {
                            height: '20px',
                            padding: size === 'large' ? '4px' : '0px 8px',
                        },
                        ...props.inputProps,
                    }}
                    type="text"
                    autoFocus={autoFocus}
                    placeholder={placeholder}
                    value={null}
                    onChange={() => {}}
                    error={error}
                    helperText={helperText}
                    size={size}
                    InputProps={{ ...props.InputProps, ...inputProps }}
                />
            )}
            onInputChange={(_, inputValue, reason) => {
                setUserTyped(reason === 'input'); // Only set to true when user types
            }}
            filterOptions={filterOptions}
            renderOption={
                isPresent(renderOption)
                    ? (props, option, state) => <li {...props}>{renderOption(option, state)}</li>
                    : (props, option, { inputValue }) => {
                          const label = getOptionLabel?.(option) ?? JSON.stringify(option);
                          const subLabel = getOptionSublabel?.(option);
                          const badge = getOptionBadge?.(option);

                          return (
                              <li {...props}>
                                  {
                                      <Flexbox justifyContent="space-between" width={'100%'}>
                                          <Flexbox flexDirection="column" gap={2} width={'100%'}>
                                              {isPresent(label) && (
                                                  <Highlight
                                                      label={label}
                                                      matcher={userTyped ? [inputValue] : []}
                                                      overrides={{
                                                          Container: HighLightLabelContainer,
                                                      }}
                                                  />
                                              )}
                                              {isPresent(subLabel) && (
                                                  <Highlight
                                                      label={subLabel}
                                                      matcher={userTyped ? [inputValue] : []}
                                                      overrides={{
                                                          Container: HighLightSubLabelContainer,
                                                      }}
                                                  />
                                              )}
                                          </Flexbox>
                                          {badge}
                                      </Flexbox>
                                  }
                              </li>
                          );
                      }
            }
            slots={{
                paper: action
                    ? (props) => (
                          <Paper {...props} style={{ position: 'relative' }}>
                              {props.children}
                              <Divider />
                              <Flexbox
                                  justifyContent="space-between"
                                  width={'100%'}
                                  padding={'4px'}
                                  onMouseDown={(e) => e.preventDefault()}
                                  onClick={(e) => e.stopPropagation()}
                              >
                                  <TertiaryButton
                                      size="small"
                                      startIcon={<Add />}
                                      onClick={action?.onClick}
                                      disabled={action?.disabled}
                                  >
                                      {action?.label}
                                  </TertiaryButton>
                              </Flexbox>
                          </Paper>
                      )
                    : undefined,
            }}
            autoHighlight={true}
            popupIcon={<ArrowDropDownRounded />}
            getOptionLabel={getOptionLabel}
            {...virtualizedProps}
            {...omit(rest, 'inputRef')}
        />
    );
}

const HighLightLabelContainer = (props: React.PropsWithChildren<{}>) => (
    <Text variant={'body-small'} color={colorSystem.neutral[9]}>
        {props.children}
    </Text>
);
const HighLightSubLabelContainer = (props: React.PropsWithChildren<{}>) => (
    <Text variant={'caption'} color={colorSystem.neutral[6]} showEllipsis>
        {props.children}
    </Text>
);

export const FieldSelect = React.forwardRef(FieldSelectInner) as <TValue>(
    props: FieldSelectProps<TValue> & { ref?: React.ForwardedRef<unknown> },
) => JSX.Element;
