import { t, Trans } from '@lingui/macro';
import { assertUnreachable } from '@luminovo/commons';
import {
    chainComparators,
    colorSystem,
    Column,
    columnWidth,
    compareByNumber,
    DataTable,
    DestructiveTertiaryIconButton,
    MenuButton,
    NoResultsComponentProps,
    Row,
    StatusChip,
    Tag,
    Text,
    Tooltip,
    useDataTableState,
} from '@luminovo/design-system';
import { UserDTO, UserInviteDTO } from '@luminovo/http-client';
import { MoreHoriz, Warning } from '@mui/icons-material';
import { MenuItem, Skeleton, TableCell, Typography } from '@mui/material';
import { ErrorBoundary, withErrorBoundary } from '@sentry/react';
import React from 'react';
import { useCurrentUserDetailsContext } from '../../../components/contexts/CurrentUserDetailsContext';
import ConfirmationDialogBox from '../../../components/dialogBox/ConfirmationDialogBox';
import { withSuspense } from '../../../components/withSuspense';
import { useHasPermission } from '../../../permissions/useHasPermission';
import {
    useIsUserAnAdmin,
    useMutationDeleteUser,
    useMutationDeleteUserInvitation,
    useMutationUpdateUser,
    useMutationUpdateUserRole,
} from '../../../resources/user/userHandler';
import { DialogEditUser } from './DialogEditUser';

type UserOrInvite =
    // User type
    | ({ type: 'user' } & UserDTO)
    // Invite type
    | ({ type: 'invite' } & UserInviteDTO);

const filterAndSortUsersAndInvites = (users: UserDTO[], invites: UserInviteDTO[], query: string): UserOrInvite[] => {
    const usersAsUserOrInvite: UserOrInvite[] = users.map((user) => ({
        ...user,
        type: 'user',
    }));
    const invitesAsUserOrInvite: UserOrInvite[] = invites.map((invite) => ({
        ...invite,
        type: 'invite',
    }));

    const lowercasedQuery = query.toLowerCase();
    const filteredUsersAndInvites = usersAsUserOrInvite.concat(invitesAsUserOrInvite).filter((user) => {
        return (
            user.email.toLowerCase().includes(lowercasedQuery) ||
            user.first_name.toLowerCase().includes(lowercasedQuery) ||
            user.last_name.toLowerCase().includes(lowercasedQuery) ||
            (user.type === 'user' ? t`Registered` : t`Invited`).toLocaleLowerCase().includes(lowercasedQuery)
        );
    });

    return filteredUsersAndInvites.sort(userComparator);
};

export function CustomerUsersTable({
    customerId,
    users,
    invites,
    query = '',
    NoResultsComponent,
    isAllowedToEdit,
}: {
    customerId: string | undefined;
    users: UserDTO[];
    invites: UserInviteDTO[];
    query?: string;
    NoResultsComponent: React.ComponentType<NoResultsComponentProps>;
    isAllowedToEdit?: boolean;
}) {
    const items = filterAndSortUsersAndInvites(users, invites, query);

    const usersColumns: Column<UserOrInvite>[] = [
        columnCustomerUserName,
        columnInviteStatus,
        columnRoles,
        columnEmail,
        columnPhoneNumber,
        columnPosition,
        columnActions,
    ];

    const persistenceId = `customerUsersTable-${customerId}-v2`;
    const dataTableState = useDataTableState({
        persistenceId,
        query,
        items,
        columns: usersColumns,
        paginationOptions: {
            showPagination: false,
        },
    });

    return (
        <DataTable
            size="medium"
            tableState={dataTableState}
            key={persistenceId}
            stickyHeader={true}
            overrides={{ NoResultsComponent }}
        />
    );
}

export function OrganizationUsersTable({
    users,
    invites,
    query = '',
}: {
    users: UserDTO[];
    invites: UserInviteDTO[];
    showRoleColumn?: boolean;
    query?: string;
}) {
    const items = filterAndSortUsersAndInvites(users, invites, query);

    const usersColumns: Column<UserOrInvite>[] = [
        columnCustomerUserName,
        columnInviteStatus,
        columnRoles,
        columnEmail,
        columnPhoneNumber,
        columnPosition,
        columnActions,
    ];

    const persistenceId = `organization-users-table-v2`;
    const dataTableState = useDataTableState({
        persistenceId,
        query,
        items,
        columns: usersColumns,
        paginationOptions: {
            showPagination: true,
            defaultRowsPerPage: 10,
            rowsPerPageOptions: [10],
        },
    });

    return <DataTable size="medium" tableState={dataTableState} key={persistenceId} />;
}

const userComparator = chainComparators(
    compareByNumber((user: UserOrInvite) => {
        if (user.type === 'invite') {
            return 0;
        }
        return 1;
    }),
    (a, b) => {
        return a.email.localeCompare(b.email);
    },
);

const columnCustomerUserName: Column<UserOrInvite> = {
    label: <Trans>Name</Trans>,
    id: 'customerUserName',
    render: ({ data: user }: Row<UserOrInvite>): JSX.Element => {
        return (
            <TableCell>
                <Text>{`${user.first_name} ${user.last_name}`}</Text> <CurrentUserTag user={user} />
            </TableCell>
        );
    },
};

function CurrentUserTag({ user }: { user: UserOrInvite }) {
    const currentUser = useCurrentUserDetailsContext();
    const isCurrentUser = currentUser.user.id === user.id;
    if (!isCurrentUser) {
        return <></>;
    }
    return <Tag label={'You'} color={'primary'} />;
}

const columnEmail: Column<UserOrInvite> = {
    label: <Trans>Email</Trans>,
    id: 'customerUserEmail',
    render: ({ data: user }: Row<UserOrInvite>): JSX.Element => (
        <TableCell>
            <Typography>{`${user.email}`}</Typography>
        </TableCell>
    ),
};

const columnPhoneNumber: Column<UserOrInvite> = {
    label: <Trans>Phone number</Trans>,
    id: 'customerUserPhoneNumber',
    render: ({ data: user }: Row<UserOrInvite>): JSX.Element => (
        <TableCell>
            <Typography>{user.type === 'user' ? `${user.phone_number ?? '-'}` : '-'}</Typography>
        </TableCell>
    ),
};

const columnPosition: Column<UserOrInvite> = {
    label: <Trans>Position</Trans>,
    id: 'customerUserPosition',
    render: ({ data: user }: Row<UserOrInvite>): JSX.Element => (
        <TableCell>
            <Typography>{user.type === 'user' ? `${user.position ?? '-'}` : '-'}</Typography>
        </TableCell>
    ),
};

const columnInviteStatus: Column<UserOrInvite> = {
    label: <Trans>Status</Trans>,
    id: 'customerUserInviteStatus',
    render: ({ data: user }: Row<UserOrInvite>): JSX.Element => (
        <TableCell>
            <StatusChip
                color={user.type === 'user' ? 'green' : 'yellow'}
                label={user.type === 'user' ? t`Registered` : t`Invited`}
            />
        </TableCell>
    ),
};

const columnActions: Column<UserOrInvite> = {
    label: '',
    id: `customerUserActions`,
    render: ({ data: rowData }: Row<UserOrInvite>) => <UserActionsMenu user={rowData} />,
    width: '1px',
};

const columnRoles: Column<UserOrInvite> = {
    label: <Trans>Role</Trans>,
    id: `roles`,
    render: ({ data: rowData }: Row<UserOrInvite>) => {
        return (
            <TableCell>
                <React.Suspense fallback={<Skeleton width={'40px'} />}>
                    <ErrorBoundary
                        fallback={({ resetError }) => {
                            return (
                                <Tooltip title={t`Failed to load role`}>
                                    <DestructiveTertiaryIconButton
                                        onClick={() => {
                                            resetError();
                                        }}
                                    >
                                        <Warning fontSize="inherit" />
                                    </DestructiveTertiaryIconButton>
                                </Tooltip>
                            );
                        }}
                    >
                        <RoleCell user={rowData} />
                    </ErrorBoundary>
                </React.Suspense>
            </TableCell>
        );
    },
    width: columnWidth.small,
};

const AdminTag = () => <Tag color={'primary'} label={t`Admin`} />;
const MemberTag = () => <Tag color={'neutral'} label={t`Member`} />;

function RoleCell({ user }: { user: UserOrInvite }) {
    const isAdmin = useIsUserAnAdmin(user.type === 'invite' ? undefined : user.id);

    if (user.type === 'invite') {
        switch (user.user_role) {
            case 'Admin':
                return <AdminTag />;
            case 'Member':
                return <MemberTag />;
            default:
                assertUnreachable(user.user_role);
        }
    }

    // loading state
    if (isAdmin === undefined) {
        return <Skeleton width={'40px'} />;
    }

    return isAdmin ? <AdminTag /> : <MemberTag />;
}

const MenuItemUpdateRole = withErrorBoundary(
    withSuspense(
        React.forwardRef<HTMLLIElement, { user: UserOrInvite }>(({ user }: { user: UserOrInvite }, ref) => {
            const { mutateAsync: mutateAsyncUserRole } = useMutationUpdateUserRole(user.id);

            const currentUser = useCurrentUserDetailsContext();
            const isCurrentUser = currentUser.user.id === user.id;

            const isAdmin = useIsUserAnAdmin(user.type === 'invite' ? undefined : user.id);
            if (isCurrentUser || user.type === 'invite' || isAdmin === undefined) {
                return null;
            }
            return (
                <MenuItem
                    ref={ref}
                    onClick={(e) => {
                        mutateAsyncUserRole({ admin: !isAdmin });
                    }}
                >
                    {isAdmin ? <Trans>Make member</Trans> : <Trans>Make admin</Trans>}
                </MenuItem>
            );
        }),
        {
            fallback: (
                <MenuItem>
                    <Skeleton width="70px" />
                </MenuItem>
            ),
        },
    ),
    { fallback: <></> },
);

const UserActionsMenu = ({ user }: { user: UserOrInvite }): JSX.Element => {
    const [isConfirmationDialogDeleteUserOpen, setIsConfirmationDialogDeleteUserOpen] = React.useState<boolean>(false);
    const [isConfirmationDialogDeleteInvitationOpen, setIsConfirmationDialogDeleteInvitationOpen] =
        React.useState<boolean>(false);
    const [isEditDialogOpen, setIsEditDialogOpen] = React.useState<boolean>(false);

    const { mutateAsync: mutateAsyncDeleteUser } = useMutationDeleteUser(user.id);
    const { mutateAsync: mutateAsyncPatch } = useMutationUpdateUser(user.id);
    const { mutateAsync: mutateAsyncDeleteInvitation } = useMutationDeleteUserInvitation(user.id);

    const initialValues = {
        firstName: user.first_name,
        lastName: user.last_name,
        phoneNumber: user.type === 'user' ? user.phone_number : null,
        position: user.type === 'user' ? user.position : null,

        email: user.email,
    };

    const isAllowedToEdit = useHasPermission(
        user.customer ? ['edit:user:customer'] : ['edit:organization_settings', 'edit:user:organization'],
    );

    const isInvite = user.type === 'invite';
    const editMenuItem = (
        <MenuItem
            disabled={isInvite}
            onClick={(e) => {
                setIsEditDialogOpen(true);
            }}
        >
            <Trans>Edit</Trans>
        </MenuItem>
    );

    return (
        <TableCell>
            <MenuButton
                appearance="tertiary"
                icon={<MoreHoriz />}
                size={'small'}
                disabled={!isAllowedToEdit}
                style={{ margin: 0 }}
            >
                {isInvite ? (
                    <Tooltip title={t`Cannot edit a user which has an invite pending`} placement="right">
                        <span>{editMenuItem}</span>
                    </Tooltip>
                ) : (
                    editMenuItem
                )}
                <MenuItemUpdateRole user={user} />
                <MenuItem
                    onClick={() => {
                        user.type === 'user'
                            ? setIsConfirmationDialogDeleteUserOpen(true)
                            : setIsConfirmationDialogDeleteInvitationOpen(true);
                    }}
                    style={{ color: colorSystem.red[7] }}
                >
                    <Trans>Delete</Trans>
                </MenuItem>
            </MenuButton>

            <ConfirmationDialogBox
                text={t`If you continue you will permanently delete this user. Do you want to proceed?`}
                title={t`Delete user`}
                onConfirm={async () => await mutateAsyncDeleteUser()}
                onReject={() => setIsConfirmationDialogDeleteUserOpen(false)}
                isDialogOpen={isConfirmationDialogDeleteUserOpen}
                deleteText={t`Yes, delete`}
            />
            <ConfirmationDialogBox
                text={t`This user has already received an invitation by email. Deleting this user will prevent them from registering with the link provided. Do you want to proceed?`}
                title={t`Delete user`}
                onConfirm={async () => await mutateAsyncDeleteInvitation()}
                onReject={() => setIsConfirmationDialogDeleteInvitationOpen(false)}
                isDialogOpen={isConfirmationDialogDeleteInvitationOpen}
                deleteText={t`Yes, delete`}
            />
            <DialogEditUser
                open={isEditDialogOpen}
                onClose={() => setIsEditDialogOpen(false)}
                initialValues={initialValues}
                onSubmit={async (form) => {
                    await mutateAsyncPatch(form);
                }}
            />
        </TableCell>
    );
};
