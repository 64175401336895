import * as z from 'zod';

export function printFormattedRuntypeError(err: unknown): string {
    if (err instanceof z.ZodError) {
        return err.message;
    } else {
        return JSON.stringify(err, null, 2);
    }
}
/**
 * Validates data against a Zod schema and returns a boolean indicating if it matches the type
 * Acts as a type guard to ensure type safety in conditional blocks
 *
 * @param schema The Zod schema to validate against
 * @param data The data to validate
 * @returns True if data conforms to schema, false otherwise
 */
export function parseRuntype<T extends z.ZodType>(schema: T, data: unknown): data is z.infer<T> {
    const result = schema.safeParse(data);
    return result.success;
}
