import { t, Trans } from '@lingui/macro';
import {
    compareByString,
    formatDecimal,
    isEqual,
    isPresent,
    throwErrorUnlessProduction,
    uniqBy,
} from '@luminovo/commons';
import { List, ListItem } from '@mui/material';
import { Column } from '@tanstack/react-table';
import React from 'react';
import { Virtuoso } from 'react-virtuoso';
import { colorSystem } from '../../../theme';
import { Checkbox } from '../../Checkbox';
import { Chip } from '../../Chip';
import { Flexbox } from '../../Flexbox';
import { MenuItem } from '../../MenuItem';
import { SearchInput } from '../../SearchField';
import { Text } from '../../Text';
import { FilterValue, getFilterConfig } from '../type';
import { arrayToggleItem, parseOptionsValuesOrFunc } from '../utils';
import { createFilterValueUpdater, isFilterValue } from './createFilterValueUpdater';

function countNestedFacetedValues<TData, TValue>({
    column,
    getOptionKey,
}: {
    column: Column<TData, TValue>;
    getOptionKey: (x: TValue extends Array<infer U> ? U : never) => any;
}) {
    const facetedValues = column.getFacetedUniqueValues();
    let uniqueValues = new Map<TValue extends Array<infer U> ? U : never, number>();
    let flatArray: Array<TValue extends Array<infer U> ? U : never> = [];

    facetedValues.forEach((count, array) => {
        flatArray = flatArray.concat(Array.from({ length: count }).flatMap(() => array));
    });

    flatArray.forEach((value) => {
        const key = getOptionKey(value);
        uniqueValues.set(key, (getCountBy(uniqueValues, key, getOptionKey) ?? 0) + 1);
    });

    return uniqueValues;
}

function getCountBy<K>(
    map: Map<K, number>,
    key: K,
    getOptionKey: (x: K) => string | number | boolean,
): number | undefined {
    const totalCount = Array.from(map.entries()).reduce(
        (acc, [mapKey, value]) => (isEqual(getOptionKey(mapKey), getOptionKey(key)) ? acc + value : acc),
        0,
    );

    return totalCount || undefined;
}

function filterColumnOptions<TData, TValue, TShareContext>({
    column,
    query,
    sharedContext,
}: {
    column: Column<TData, TValue>;
    query: string;
    sharedContext: TShareContext;
}) {
    const emptyOptions = { options: [], totalCount: 0 };
    if (!isPresent(column.columnDef.meta)) {
        throwErrorUnlessProduction(new Error('Missing meta.equalsAny in column definition'));
        return emptyOptions;
    }

    const enumFilterConfig = getFilterConfig(column, 'enum');
    if (isPresent(enumFilterConfig)) {
        const facedUniqueValues = column.getFacetedUniqueValues();

        const getOptionKey = enumFilterConfig.getOptionKey ?? ((x) => x as string | number | boolean);
        const {
            options: rowOptions,
            getOptionLabel,
            renderOption = getOptionLabel,
            getOptionCount = (option) => getCountBy(facedUniqueValues, option, getOptionKey),
        } = enumFilterConfig;

        const facetedValues = Array.from(column.getFacetedUniqueValues().keys()) as TValue[];
        const rawValues = parseOptionsValuesOrFunc(rowOptions ?? facetedValues, {
            column,
            facetedValues,
            sharedContext,
        });
        const uniqueValues = uniqBy(rawValues, getOptionKey);
        const sortedValues = isPresent(rowOptions)
            ? uniqueValues
            : uniqueValues.sort((a, b) => compareByString(getOptionLabel(a), getOptionLabel(b)));
        const filterOptions = sortedValues.filter((value) =>
            getOptionLabel(value).toLowerCase().includes(query.toLowerCase()),
        );

        const options = filterOptions.map((value) => ({
            value: value,
            label: getOptionLabel(value),
            Element: renderOption(value),
            count: getOptionCount(value, sharedContext),
        }));

        return { options, totalCount: uniqueValues.length };
    }

    const arrayFilterConfig = getFilterConfig(column, 'array');
    if (arrayFilterConfig) {
        const { getOptionKey = (x) => x as string | number | boolean } = arrayFilterConfig;
        const facedUniqueValues = countNestedFacetedValues({ column, getOptionKey });

        const {
            options: rowOptions,
            getOptionLabel,
            renderOption = getOptionLabel,
            getOptionCount = (option) => getCountBy(facedUniqueValues, option, getOptionKey),
        } = arrayFilterConfig;

        // This is type safe because we are sure that the column is of type Column<TData, TValue extends Array>
        const facetedValues = Array.from(column.getFacetedUniqueValues().keys()).flat() as TValue;
        const rawValues = parseOptionsValuesOrFunc(rowOptions ?? facetedValues, {
            column,
            facetedValues,
            sharedContext,
        }) as Array<TValue extends Array<infer U> ? U : never>;

        const uniqueValues = uniqBy(rawValues, getOptionKey);
        const sortedValues = isPresent(rowOptions)
            ? uniqueValues
            : uniqueValues.sort((a, b) => compareByString(getOptionLabel(a), getOptionLabel(b)));
        const filterOptions = sortedValues.filter((value) =>
            getOptionLabel(value).toLowerCase().includes(query.toLowerCase()),
        );

        const options = filterOptions.map((value) => ({
            value: value,
            label: getOptionLabel(value),
            Element: renderOption(value),
            count: getOptionCount(value, sharedContext),
        }));

        return { options, totalCount: uniqueValues.length };
    }

    throwErrorUnlessProduction(new Error('Invalid filter value for ArrayFilterList filter'));
    return emptyOptions;
}

function ArrayFilterList<TData, TValue, TSharedContext>({
    column,
    sharedContext,
}: {
    column: Column<TData, TValue>;
    sharedContext: TSharedContext;
}): JSX.Element | null {
    const [query, setQuery] = React.useState<string>('');
    const filterConfig = getFilterConfig(column, 'enum') || getFilterConfig(column, 'array');

    if (!isPresent(filterConfig)) {
        return null;
    }

    const unsafeValue = column.getFilterValue();
    const filterValue =
        isFilterValue<Array<unknown>>(unsafeValue) && Array.isArray(unsafeValue.value) ? unsafeValue.value : [];

    const { options, totalCount } = filterColumnOptions({ column, query, sharedContext });

    return (
        <Flexbox flexDirection={'column'} gap={'8px'}>
            {totalCount > 5 && (
                <SearchInput
                    placeholder={t`Filter by...`}
                    debounceWait={50}
                    value={query}
                    onChange={(value) => setQuery(value)}
                    onClear={() => setQuery('')}
                    style={{ backgroundColor: colorSystem.neutral.white, width: '100%', minWidth: '300px' }}
                />
            )}
            <List style={{ width: '100%', maxHeight: '330px', overflow: 'auto', borderRadius: 4 }} disablePadding>
                <Virtuoso
                    style={{
                        height: 40 * Math.min(options.length, 6),
                        width: 330,
                        overflowX: 'hidden',
                        boxSizing: 'border-box',
                    }}
                    initialItemCount={
                        // This is a workaround for virtuoso not rendering the correct amount of items during testing
                        Math.min(options.length, 10)
                    }
                    overscan={200}
                    totalCount={options.length}
                    itemContent={(index) => {
                        const { value, count, Element } = options[index];
                        return (
                            <MenuItem
                                style={{ marginLeft: '0px', marginRight: '0px' }}
                                onClick={() => {
                                    column.setFilterValue(
                                        createFilterValueUpdater((old: FilterValue<unknown> | undefined) => {
                                            if (!isPresent(old) || !Array.isArray(old.value)) {
                                                return [value];
                                            }
                                            const newValue = arrayToggleItem(old.value, value);
                                            return newValue.length > 0 ? newValue : undefined;
                                        }, filterConfig.defaultFilterFn),
                                    );
                                }}
                                label={''}
                                overrides={{
                                    InnerItem: () => (
                                        <Flexbox alignItems={'center'} width={'100%'} gap={'8px'}>
                                            <Checkbox
                                                key={filterValue.length}
                                                size={'small'}
                                                checked={filterValue.some((v) => isEqual(v, value))}
                                            />
                                            {Element === '' ? (
                                                <Text variant={'body-small'} color={colorSystem.neutral[5]}>
                                                    <Trans>Empty</Trans>
                                                </Text>
                                            ) : (
                                                <Text variant={'body-small'}>{Element}</Text>
                                            )}

                                            <Flexbox flex={1} />
                                            <Text variant="body-small" color={colorSystem.neutral[6]}>
                                                {formatDecimal(count, { ifAbsent: '-', ifNaN: '' })}
                                            </Text>
                                        </Flexbox>
                                    ),
                                }}
                            />
                        );
                    }}
                />

                {options.length === 0 && (
                    <ListItem style={{ backgroundColor: colorSystem.neutral.white }}>
                        <Text variant={'body-small'} color={colorSystem.neutral[6]}>
                            <Trans>No results</Trans>
                        </Text>
                    </ListItem>
                )}
            </List>
        </Flexbox>
    );
}

function ArrayFilterField<TData, TShareContext>({
    column,
    sharedContext,
}: {
    column: Column<TData, unknown>;
    sharedContext: TShareContext;
}): JSX.Element | null {
    const filterConfig = getFilterConfig(column, 'enum') || getFilterConfig(column, 'array');

    if (!isPresent(filterConfig)) {
        return null;
    }

    const { options } = filterColumnOptions({ column, query: '', sharedContext });

    const unsafeValue = column.getFilterValue();
    const filterValue = isFilterValue(unsafeValue) && Array.isArray(unsafeValue.value) ? unsafeValue.value : [];

    return (
        <Flexbox gap={4} flexWrap={'wrap'}>
            {options
                .filter(({ value }) => filterValue.includes(value))
                .map(({ label }, idx) => (
                    <Chip key={idx} label={label} color={'neutral'} />
                ))}
        </Flexbox>
    );
}

export const FilterEnumEqualsAny = ArrayFilterList;
export const FilterEnumEqualsAnyField = ArrayFilterField;
export const FilterArrIncludesSome = ArrayFilterList;
export const FilterArrIncludesSomeField = ArrayFilterField;
