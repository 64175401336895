import { t } from '@lingui/macro';
import { PackageDTO } from '@luminovo/http-client';
import { assertPresent } from '../../../utils/assertPresent';

/**
 * This is the option to select all packages in the filter.
 **/
export const allPackagesPackageValue: PackageDTO = {
    // eslint-disable-next-line lingui/t-call-in-function
    name: t`All`,
    id: 'all with uuid 9ac07325-ec8c-4a73-8702-47ef1cc908a4',
    aliases: [],
    mounting: null,

    number_of_pins: null,
    tags: [],
};

export const allPackagesPackageValueName = assertPresent(allPackagesPackageValue.name);
