import * as z from 'zod';
import { endpoint } from '../http/endpoint';
import {
    CostedBomExportTemplateInputRuntype,
    CostedBomExportTemplateRuntype,
} from './costedBomExportTemplateBackendTypes';

export const costedBomExportTemplateEndpoints = {
    'GET /export/costed-bom/templates': endpoint({
        description: 'List all costed bom export templates',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({
            items: z.array(CostedBomExportTemplateRuntype),
        }),
    }),
    'DELETE /export/costed-bom/templates/:id': endpoint({
        description: 'Delete a costed bom export template',
        pathParams: z.object({
            id: z.string(),
        }),
        invalidates: ['GET /export/costed-bom/templates'],
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.unknown(),
    }),
    'POST /export/costed-bom/templates': endpoint({
        description: 'Create a costed bom export template',
        pathParams: z.undefined(),
        invalidates: ['GET /export/costed-bom/templates'],
        queryParams: z.undefined(),
        requestBody: CostedBomExportTemplateInputRuntype,
        responseBody: z.object({
            data: CostedBomExportTemplateRuntype,
        }),
    }),
    'PUT /export/costed-bom/templates/:id': endpoint({
        description: 'Update a costed bom export template',
        pathParams: z.object({
            id: z.string(),
        }),
        invalidates: ['GET /export/costed-bom/templates'],
        queryParams: z.undefined(),
        requestBody: CostedBomExportTemplateInputRuntype,
        responseBody: z.object({
            data: CostedBomExportTemplateRuntype,
        }),
    }),
};
