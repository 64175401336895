import { t } from '@lingui/macro';
import {
    colorSystem,
    Dialog,
    DialogContent,
    DialogTitle,
    FieldTextControlled,
    Flexbox,
    FormItem,
} from '@luminovo/design-system';
import { useFormContext } from 'react-hook-form';
import { useDialogContext } from '../../../../components/contexts/ModalContext';
import { FormContainer } from '../../../../components/formLayouts/FormContainer';
import { SubmitButton } from '../../../../components/formLayouts/SubmitButton';
import { useHttpMutation } from '../../../../resources/mutation/useHttpMutation';
import { useNegotiation } from '../../hooks/negotiationHandlers';

export function useDialogEditNegotiation({ negotiationId }: { negotiationId: number }) {
    const { setDialog, closeDialog } = useDialogContext();
    const { data: negotiation } = useNegotiation(negotiationId);

    const { mutateAsync } = useHttpMutation('PATCH /negotiation/:id', {
        snackbarMessage: t`Negotiation updated`,
    });

    const onSubmit = async (formValues: NegotiationFormState) => {
        if (formValues.name !== negotiation?.name) {
            await mutateAsync({
                pathParams: { id: negotiationId },
                requestBody: { name: formValues.name },
            });
        }
        closeDialog();
    };

    return {
        openDialog: () =>
            setDialog(
                <Dialog
                    open={true}
                    maxWidth={'sm'}
                    fullWidth={true}
                    transitionDuration={0}
                    onClose={() => closeDialog()}
                >
                    <DialogTitle
                        title={t`Edit negotiation`}
                        onClose={() => closeDialog()}
                        style={{ borderBottom: `1px solid ${colorSystem.neutral[2]}` }}
                    />
                    <DialogContent>
                        <NegotiationForm
                            defaultValues={{
                                name: negotiation?.name ?? '',
                            }}
                            onSubmit={onSubmit}
                        />
                    </DialogContent>
                </Dialog>,
            ),
    };
}

type NegotiationFormState = {
    name: string;
};

function NegotiationForm({
    defaultValues,
    onSubmit,
}: {
    defaultValues: NegotiationFormState;
    onSubmit: (formValues: NegotiationFormState) => void;
}) {
    return (
        <FormContainer defaultValues={defaultValues} onSubmit={onSubmit}>
            <Flexbox flexDirection={'column'} gap={20} paddingTop={'12px'}>
                <FormItemName />
                <Flexbox width={'100%'} justifyContent={'space-between'} flexDirection={'row-reverse'}>
                    <SubmitButton />
                </Flexbox>
            </Flexbox>
        </FormContainer>
    );
}

function FormItemName() {
    const { control } = useFormContext<NegotiationFormState>();

    return (
        <FormItem
            label={t`Name`}
            description={t`The name of the negotiation. Use a meaningful name to identify the negotiation.`}
            variant={'description-inlined'}
            required
        >
            <FieldTextControlled
                name={'name'}
                control={control}
                required
                FieldProps={{
                    placeholder: t`Enter negotiation name`,
                    style: { width: '100%' },
                }}
            />
        </FormItem>
    );
}
