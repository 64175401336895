import { t, Trans } from '@lingui/macro';
import {
    assertUnreachable,
    formatDecimal,
    formatLongDateTime,
    formatRelativeTime,
    isPresent,
    transEnum,
} from '@luminovo/commons';
import {
    chainComparators,
    Chip,
    colorSystem,
    compareByNumber,
    createColumnHelper,
    FieldNumeric,
    Flexbox,
    NonIdealState,
    PrimaryButton,
    SupportedFilterFn,
    Tag,
    TanStackTable,
    Text,
    useTanStackTable,
} from '@luminovo/design-system';
import {
    isOtsComponentFull,
    OfferOriginEnum,
    OtsComponentFull,
    OtsFullPart,
    Packaging,
    PriceType,
    RfqContext,
    SolutionTag,
    StandardPartOfferDTO,
    StandardPartOfferInputDTO,
    StandardPartOfferWithSolutionsDTO,
} from '@luminovo/http-client';
import {
    AvailabilityChip,
    extractMoqFromOffer,
    extractMpqFromOffer,
    extractSolutionTagColor,
    formatOfferOrigin,
    formatPackaging,
    formatPart,
    formatSupplierAndStockLocationDTO,
    hasMultipleOrGenericMatches,
    hasSolutionTag,
    InventorySiteChip,
    isStandardPartInventoryOffer,
    isStandardPartMarketOffer,
    LabelPart,
    leadTimeDaysExtractor,
    parseSolution,
    priceTypeTranslations,
    Solution,
    SolutionErrorTags,
    SolutionNotificationTags,
    solutionTagTranslations,
    SolutionWarningTags,
    SupplierAndStockLocationChip,
} from '@luminovo/sourcing-core';
import { AltRouteRounded, InfoOutlined } from '@mui/icons-material';
import { Box, Tooltip } from '@mui/material';
import React from 'react';
import { useHttpQuery } from '../../../../../resources/http/useHttpQuery';
import { convertRfqContext, useStandardPartsFromOffers } from '../../../../../resources/part/partHandler';
import useDebounce from '../../../../../useDebounce';
import { formatMonetaryValue } from '../../../../../utils/formatMonetaryValue';
import { route } from '../../../../../utils/routes';
import { OriginCell } from '../../../../SolutionManager/components/columns';
import { useOfferDrawer } from '../../../../SolutionManager/components/OfferDrawer';

export const QUANTITY_FOR_AVAILABILITY_INFO = 1;
export const QUANTITY_FOR_UNIT_PRICE = 10_000_000;

export type StandardPartOfferPartType = StandardPartOfferInputDTO['linked_part'];

export function extractStock(item: StandardPartOfferTableData): number {
    return item.offer.available_prices.stock ?? -Infinity;
}

export function extractUnitPrice(item: StandardPartOfferTableData): number {
    return Number(item.costSummary?.unit_price?.preferred.amount);
}

export function getAddStandardPartTypeLink(standardPart: StandardPartOfferPartType): string {
    return route('/parts/offers/add/:partId', { partId: standardPart.id }, { partType: standardPart.type });
}

export interface StandardPartOfferTableData {
    part: OtsFullPart | OtsComponentFull;
    offer: StandardPartOfferDTO;
    requiredQuantity: number | null;
    requiredQuantitySolution: Solution | undefined;
    costSummary: Solution['cost'];
    availability: Solution['availability'];
    solutionTags: Solution['solutionTags'];
    factoryLeadTime: number | null;
}

function createStandardPartOfferTableData({
    standardParts,
    offersWithSolutions,
    requiredQuantity,
}: {
    standardParts: Array<OtsFullPart | OtsComponentFull>;
    offersWithSolutions: StandardPartOfferWithSolutionsDTO[];
    requiredQuantity: number | null;
}): StandardPartOfferTableData[] {
    return offersWithSolutions.flatMap(({ offer, solutions }): StandardPartOfferTableData[] => {
        const part = standardParts.find((part) => part.id === offer.linked_part.id);

        if (!isPresent(part)) {
            return [];
        }

        const findAndParseFastestSolution = (quantity: number) => {
            const solution = solutions.find((item) => item.quantity === quantity)?.fastest;
            return solution ? parseSolution(solution) : undefined;
        };

        const lowestQuantitySolution = findAndParseFastestSolution(QUANTITY_FOR_AVAILABILITY_INFO);
        const requiredQuantitySolution = findAndParseFastestSolution(requiredQuantity ?? NaN);
        const highestQuantitySolution = findAndParseFastestSolution(QUANTITY_FOR_UNIT_PRICE);
        const requiredOrLowestSolution = requiredQuantitySolution ?? lowestQuantitySolution;
        const requiredOrHighestSolution = requiredQuantitySolution ?? highestQuantitySolution;

        if (!requiredOrLowestSolution || !requiredOrHighestSolution) {
            return [];
        }

        const costSummary = requiredOrLowestSolution.cost;
        const solutionTags = requiredOrLowestSolution.solutionTags;
        const availability = requiredOrLowestSolution.availability;

        if (solutionTags.some(({ tag }) => tag === SolutionTag.Expired)) {
            return [];
        }

        if (solutionTags.some(({ tag }) => tag === SolutionTag.Outdated)) {
            return [];
        }

        return [
            {
                part,
                offer,
                requiredQuantity,
                requiredQuantitySolution,
                costSummary,
                solutionTags,
                availability,
                factoryLeadTime: offer.available_prices.factory_lead_time_days,
            },
        ];
    });
}

/**
 * In case the `requiredQuantity` is not defined. We use the `unitPrice` from the highest price break, and the `availability`
 * information from an solution with a purchase quantity of one. We are aware that this combination of unit price and availability is not correct.
 * However, a default value for the required quantity is not practical from a UX point of view.
 */
export function useStandardPartOfferTableItems({
    standardPart,
    requiredQuantity,
    showAmbiguousStock,
    rfqContext,
}: {
    standardPart: StandardPartOfferPartType;
    requiredQuantity: number | null;
    showAmbiguousStock: boolean;
    rfqContext: RfqContext;
}) {
    const { data: offersWithSolutions } = useHttpQuery(
        'POST /offers/off-the-shelf/with-solutions',
        {
            requestBody: {
                part: standardPart.type === 'OffTheShelf' ? standardPart.id : standardPart.id,
                quantities: isPresent(requiredQuantity)
                    ? [requiredQuantity]
                    : [QUANTITY_FOR_AVAILABILITY_INFO, QUANTITY_FOR_UNIT_PRICE],
                ...convertRfqContext(rfqContext),
            },
        },
        { select: (res) => res.data },
    );

    const { data: standardParts } = useStandardPartsFromOffers(
        offersWithSolutions?.map(({ offer }) => offer),
        rfqContext,
    );

    const isLoading = !standardParts || !offersWithSolutions;

    const data = React.useMemo(() => {
        if (isLoading) {
            return undefined;
        }

        const items = createStandardPartOfferTableData({
            standardParts,
            offersWithSolutions,
            requiredQuantity,
        });
        return items
            .sort(
                chainComparators(
                    compareByNumber((x: StandardPartOfferTableData) =>
                        hasSolutionTag(x, SolutionTag.SupplierPreferred) ||
                        hasSolutionTag(x, SolutionTag.SupplierApproved)
                            ? 0
                            : 1,
                    ),
                    compareByNumber((x: StandardPartOfferTableData) => leadTimeDaysExtractor(x.availability)),
                    compareByNumber((x: StandardPartOfferTableData) => extractUnitPrice(x)),
                    compareByNumber((x: StandardPartOfferTableData) => -extractStock(x)),
                    (a, b) => a.offer.id.localeCompare(b.offer.id),
                ),
            )
            .filter((item) => {
                if (!isOtsComponentFull(item.part)) {
                    return true;
                }

                if (!showAmbiguousStock) {
                    return !hasMultipleOrGenericMatches(item.part);
                }
                return true;
            });
    }, [isLoading, standardParts, offersWithSolutions, requiredQuantity, showAmbiguousStock]);

    return { data, isLoading };
}

function RequiredQuantityInput({
    requiredQuantity,
    setRequiredQuantity,
}: {
    requiredQuantity: number | null;
    setRequiredQuantity: (value: number | null) => void;
}) {
    return (
        <Flexbox gap={8} alignItems={'center'}>
            <Tooltip
                title={
                    <Trans>
                        The required quantity is used to calculate pricing and availability for each offer. It helps
                        determine the most suitable price break and lead time for your needs.
                    </Trans>
                }
            >
                <InfoOutlined style={{ color: colorSystem.neutral[6], fontSize: '16px' }} />
            </Tooltip>

            <FieldNumeric
                size="small"
                value={requiredQuantity ?? null}
                onChange={(value) => {
                    if (isPresent(value) && value < 1) {
                        return setRequiredQuantity(1);
                    }
                    if (isPresent(value) && value > QUANTITY_FOR_UNIT_PRICE) {
                        return setRequiredQuantity(QUANTITY_FOR_UNIT_PRICE);
                    }
                    setRequiredQuantity(value);
                }}
                placeholder={t`Required quantity`}
            />
        </Flexbox>
    );
}

function EmptyPlaceholder({
    sharedContext: { standardPart },
}: {
    sharedContext: { standardPart: StandardPartOfferPartType };
}) {
    return (
        <NonIdealState
            title={t`No offers found`}
            action={{
                children: t`Add offer`,
                href: getAddStandardPartTypeLink(standardPart),
            }}
            overrides={{
                ActionButton: PrimaryButton,
            }}
            description={t`There were no offers found`}
        />
    );
}

export function StandardPartOfferTable({
    standardPart,
    showAmbiguousStock,
}: {
    standardPart: StandardPartOfferPartType;
    showAmbiguousStock: boolean;
}): JSX.Element {
    const [requiredQuantity, setRequiredQuantity] = React.useState<number | null>(null);
    const { openOfferDrawer } = useOfferDrawer();

    const { data } = useStandardPartOfferTableItems({
        standardPart,
        requiredQuantity: useDebounce(requiredQuantity, 500),
        showAmbiguousStock,
        rfqContext: { type: 'OutsideRfQ' },
    });
    const { table } = useTanStackTable({
        sharedContext: { standardPart },
        data,
        columns,
        enableColumnHiding: true,
        enableColumnOrdering: true,
        enableExcelExport: true,
        initialState: {
            columnFilters: [
                {
                    id: 'solutionTags',
                    value: {
                        filterFn: SupportedFilterFn.arrIncludesSome,
                        value: [SolutionTag.SupplierPreferred, SolutionTag.SupplierApproved],
                    },
                },
            ],
        },
        enableSaveAsDefault: {
            storage: 'local',
            key: `standard-part-offer-table-${standardPart.type}`,
        },
        onRowClick: (row) => {
            openOfferDrawer({
                offer: row.original.offer,
                rfqContext: { type: 'OutsideRfQ' },
                solution: row.original.requiredQuantitySolution,
                fallbackSolutionTags: row.original.solutionTags,
            });
        },
    });

    return (
        <Flexbox flexDirection={'column'} gap={12} height={'100%'}>
            <Flexbox justifyContent={'space-between'} alignItems={'center'}>
                <Text variant="h3">
                    <Trans>Offers</Trans>
                </Text>

                <RequiredQuantityInput requiredQuantity={requiredQuantity} setRequiredQuantity={setRequiredQuantity} />
            </Flexbox>
            <Box height={'100%'}>
                <TanStackTable table={table} EmptyPlaceholder={EmptyPlaceholder} />;
            </Box>
        </Flexbox>
    );
}

const columnHelper = createColumnHelper<StandardPartOfferTableData>();

const columns = [
    columnHelper.enum((row) => formatPart(row.part), {
        id: 'part',
        label: () => t`Part`,
        getOptionLabel: (option) => option ?? t`Unknown`,
        size: 180,
        cell: ({ row }) => (
            <Flexbox gap={4} alignItems={'center'}>
                <LabelPart part={row.original.part} />
                {isOtsComponentFull(row.original.part) && hasMultipleOrGenericMatches(row.original.part) && (
                    <Tooltip title={t`Offer with ambiguous stock`}>
                        <AltRouteRounded style={{ fontSize: 'small', color: colorSystem.blue[7], rotate: '90deg' }} />
                    </Tooltip>
                )}
            </Flexbox>
        ),
    }),

    columnHelper.enum(
        (row) => {
            if (isStandardPartInventoryOffer(row.offer)) {
                return row.offer.linked_location.name;
            }
            if (isStandardPartMarketOffer(row.offer)) {
                return formatSupplierAndStockLocationDTO(row.offer.linked_location);
            }
            assertUnreachable(row.offer);
        },
        {
            id: 'supplier',
            size: 150,
            label: () => t`Supplier`,
            getOptionLabel: (option) => option ?? t`Unknown`,
            cell: ({ row }) => {
                if (isStandardPartInventoryOffer(row.original.offer)) {
                    return (
                        <InventorySiteChip
                            isPreferred={hasSolutionTag(row.original, SolutionTag.SupplierPreferred)}
                            isApproved={hasSolutionTag(row.original, SolutionTag.SupplierApproved)}
                            site={row.original.offer.linked_location}
                            displaySiteName={true}
                        />
                    );
                }

                if (isStandardPartMarketOffer(row.original.offer)) {
                    return (
                        <SupplierAndStockLocationChip
                            isPreferred={hasSolutionTag(row.original, SolutionTag.SupplierPreferred)}
                            isApproved={hasSolutionTag(row.original, SolutionTag.SupplierApproved)}
                            supplier={row.original.offer.linked_location}
                        />
                    );
                }
                return <></>;
            },
        },
    ),

    columnHelper.enum((row) => row.offer.packaging, {
        id: 'packaging',
        label: () => t`Packaging`,
        size: 90,
        options: [...Object.values(Packaging), null],
        getOptionLabel: (option) => formatPackaging(option),
        cell: (item) => {
            if (!isPresent(item.getValue())) {
                return (
                    <Text variant="body-small" color={colorSystem.neutral[6]}>
                        <Trans>Unknown</Trans>
                    </Text>
                );
            }
            return <Tag color={'neutral'} attention={'low'} label={formatPackaging(item.getValue())} />;
        },
    }),
    columnHelper.number((row) => extractMoqFromOffer(row.costSummary.unit_price?.original ?? null, row.offer), {
        id: 'moq',
        label: () => t`MOQ`,
        size: 60,
        cell: (item) => formatDecimal(item.getValue(), { ifAbsent: '-' }),
    }),
    columnHelper.number((row) => leadTimeDaysExtractor(row.availability), {
        id: 'leadTime',
        label: () => t`Lead time`,
        size: 90,
        align: 'center',
        cell: ({ row }) => <AvailabilityChip solution={row.original} />,
    }),

    columnHelper.number('offer.available_prices.stock', {
        id: 'stock',
        label: () => t`Stock`,
        size: 80,
        cell: (item) => formatDecimal(item.getValue(), { ifAbsent: '-' }),
        quickFilters: [
            {
                label: () => t`In stock`,
                value: {
                    filterFn: SupportedFilterFn.inNumberRange,
                    value: [1, null],
                },
            },
        ],
    }),

    columnHelper.monetaryValue((row) => row.costSummary.unit_price?.original, {
        id: 'unitPrice',
        label: () => t`Unit price`,
        size: 90,
        formatAs: 'unit-price',
        cell: (item) => formatMonetaryValue(item.getValue(), 'unit-price'),
    }),

    columnHelper.monetaryValue((row) => row.costSummary.total_price?.original, {
        id: 'totalPrice',
        label: () => t`Total price`,
        size: 100,
        cell: ({ row, getValue }) => {
            if (!isPresent(row.original.requiredQuantity)) {
                return (
                    <Tooltip title={t`No required quantity`}>
                        <div>
                            <Text>-</Text>
                        </div>
                    </Tooltip>
                );
            }
            return formatMonetaryValue(getValue());
        },
    }),

    columnHelper.enum((row) => formatOfferOrigin(row.offer.origin), {
        id: 'origin',
        size: 120,
        label: () => t`Origin`,
        getOptionLabel: (option) => option,
        cell: ({ row }) => <OriginCell key={Math.random()} offer={row.original.offer} />,
    }),

    columnHelper.date('offer.creation_date', {
        id: 'updated',
        size: 100,
        label: () => t`Updated`,
        cell: (item) => (
            <Tooltip title={formatLongDateTime(item.getValue())}>
                <Text variant="body-small" color={colorSystem.neutral[6]}>
                    {formatRelativeTime(item.getValue())}
                </Text>
            </Tooltip>
        ),
    }),

    columnHelper.text('offer.supplier_part_number', {
        id: 'sku',
        label: () => t`SKU`,
        size: 100,
        initialVisibility: false,
    }),

    columnHelper.number((row) => extractMpqFromOffer(row.costSummary.unit_price?.original ?? null, row.offer), {
        id: 'mpq',
        label: () => t`MPQ`,
        size: 60,
        initialVisibility: false,
        cell: (item) => formatDecimal(item.getValue(), { ifAbsent: '-' }),
    }),
    columnHelper.enum('offer.price_type', {
        id: 'priceType',
        label: () => t`Price type`,
        size: 90,
        initialVisibility: false,
        options: Object.values(PriceType),
        getOptionLabel: (option) => transEnum(option, priceTypeTranslations),
        cell: (item) => (
            <Tag color="neutral" attention="low" label={transEnum(item.getValue(), priceTypeTranslations)} />
        ),
    }),
    columnHelper.enum((row) => row.costSummary.unit_price?.original.currency, {
        id: 'currency',
        label: () => t`Currency`,
        size: 90,
        initialVisibility: false,
        getOptionLabel: (opt) => opt ?? t`Unknown`,
        cell: (item) => <Tag color={'neutral'} attention={'low'} label={item.getValue() ?? t`Unknown`} />,
    }),
    columnHelper.monetaryValue((row) => row.costSummary.unit_price?.original, {
        id: 'unitPriceOriginalCurrency',
        label: () => t`Unit price (original currency)`,
        size: 130,
        initialVisibility: false,
        formatAs: 'unit-price',
        cell: (item) => formatMonetaryValue(item.getValue(), 'unit-price'),
    }),

    columnHelper.array((row) => row.solutionTags.map((t) => t.tag), {
        id: 'solutionTags',
        label: () => t`Solution tags`,
        size: 100,
        enableHiding: false,
        initialVisibility: false,
        options: ({ facetedValues }) =>
            [SolutionTag.SupplierPreferred, SolutionTag.SupplierApproved].concat(
                [...SolutionErrorTags, ...SolutionWarningTags, ...SolutionNotificationTags].filter((tag) =>
                    facetedValues.includes(tag),
                ),
            ),
        renderOption: (tag) => (
            <Chip
                color={extractSolutionTagColor({ solutionTag: tag })}
                label={transEnum(tag, solutionTagTranslations)}
            />
        ),
        getOptionLabel: (tag) => transEnum(tag, solutionTagTranslations),
        cell: () => {
            throw new Error('Not implemented');
        },
        quickFilters: [
            {
                label: () => t`Only approved suppliers`,
                value: {
                    filterFn: SupportedFilterFn.arrIncludesSome,
                    value: [SolutionTag.SupplierPreferred, SolutionTag.SupplierApproved],
                },
            },
        ],
    }),

    columnHelper.enum((row) => row.offer.origin.origin === OfferOriginEnum.Manual, {
        id: 'manualOffer',
        label: () => t`Manual offer`,
        size: 100,
        options: [true, false],
        getOptionLabel: (option) => (option ? t`Yes` : t`No`),
        initialVisibility: false,
        enableHiding: false,
        quickFilters: [
            {
                label: () => t`Only manual offers`,
                value: {
                    filterFn: SupportedFilterFn.equalsAny,
                    value: [true],
                },
            },
        ],
    }),

    columnHelper.enum((row) => isStandardPartInventoryOffer(row.offer), {
        id: 'inventorOffer',
        label: () => t`Inventory offer`,
        size: 100,
        options: [true, false],
        getOptionLabel: (option) => (option ? t`Yes` : t`No`),
        initialVisibility: false,
        enableHiding: false,
        quickFilters: [
            {
                label: () => t`Only inventory offers`,
                value: {
                    filterFn: SupportedFilterFn.equalsAny,
                    value: [true],
                },
            },
        ],
    }),
];
