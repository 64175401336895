import { t } from '@lingui/macro';
import { id } from '@luminovo/commons';
import { Flexbox, Tab, Tabs } from '@luminovo/design-system';
import { AssemblyIndustry, PartAlternative, PartOptionDTO, PartSuggestionFull } from '@luminovo/http-client';
import { CircularProgress } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { BomItem } from '../../../../resources/designItem/bomItemFrontendTypes';
import { useDesignItemPartAlternatives } from '../../../../resources/designItem/designItemHandler';
import { getAndSortAlternatives } from '../../../../resources/designItem/findNotAppliedPartSuggestions';
import { SPACING_MEDIUM } from '../../../../themes';
import { AddMultiplePartOptionsParams, AddSinglePartParams } from '../SpecificationTypeForms/types';
import { AddManualParts } from './AddManualParts';
import { PartSearch } from './PartSearch';
import { PartSuggestions } from './PartSuggestions';

export const AddParts = React.memo(function AddParts({
    rfqId,
    assemblyId,
    bomItem,
    partOptions,
    isEditable,
    handleAddPart,
    handleAddMultiplePartOptions,
    handleRemovePartOption,
    suggestions,
    isSuggestionsLoading,
    assemblyIndustry,
}: {
    rfqId: string;
    assemblyId: string;
    bomItem: BomItem;
    partOptions: PartOptionDTO[];
    isEditable: boolean;
    handleAddPart: (params: AddSinglePartParams) => void;
    handleAddMultiplePartOptions: (params: AddMultiplePartOptionsParams) => void;
    handleRemovePartOption: (partId: string) => void;
    suggestions: PartSuggestionFull[];
    isSuggestionsLoading: boolean;
    assemblyIndustry?: AssemblyIndustry;
}) {
    const { data: partAlternatives, isLoading: isDesignItemsPartAlternativesLoading } = useDesignItemPartAlternatives(
        bomItem.id[0],
        bomItem.showPartAlternatives,
    );

    const sortedPartAlternatives = useMemo(() => {
        return getAndSortAlternatives({ parts: bomItem.parts, partAlternatives });
    }, [partAlternatives, bomItem.parts]);

    if (!isEditable) {
        return null;
    }

    if (isDesignItemsPartAlternativesLoading) {
        return (
            <Flexbox justifyContent={'center'} style={{ minHeight: '40px' }} alignItems={'center'}>
                <CircularProgress size={SPACING_MEDIUM} />
            </Flexbox>
        );
    }

    const suggestionsCount = suggestions.length + sortedPartAlternatives.length;

    return (
        <AddPartsInner
            suggestionsCount={suggestionsCount}
            rfqId={rfqId}
            assemblyId={assemblyId}
            bomItem={bomItem}
            partOptions={partOptions}
            handleAddPart={handleAddPart}
            handleAddMultiplePartOptions={handleAddMultiplePartOptions}
            handleRemovePartOption={handleRemovePartOption}
            partialMatches={suggestions}
            isSuggestionsLoading={isSuggestionsLoading}
            assemblyIndustry={assemblyIndustry}
            partAlternatives={sortedPartAlternatives}
        />
    );
});

const AddPartsInner = React.memo(function AddPartsInner({
    rfqId,
    assemblyId,
    bomItem,
    partOptions,
    handleAddPart,
    handleAddMultiplePartOptions,
    handleRemovePartOption,
    partialMatches,
    assemblyIndustry,
    suggestionsCount,
    isSuggestionsLoading,
    partAlternatives,
}: {
    suggestionsCount: number;
    partAlternatives: PartAlternative[];
    rfqId: string;
    assemblyId: string;
    bomItem: BomItem;
    partOptions: PartOptionDTO[];
    handleAddPart: (params: AddSinglePartParams) => void;
    handleAddMultiplePartOptions: (params: AddMultiplePartOptionsParams) => void;
    handleRemovePartOption: (partId: string) => void;
    partialMatches: PartSuggestionFull[];
    isSuggestionsLoading: boolean;
    assemblyIndustry?: AssemblyIndustry;
}) {
    const existingPartIds = useMemo(() => {
        return partOptions.map((p) => p.part.data);
    }, [partOptions]);

    const [selectedTab, setSelectedTab] = useState<'suggestions' | 'search'>(
        suggestionsCount === 0 && !isSuggestionsLoading ? 'search' : 'suggestions',
    );

    return (
        <>
            <Tabs
                size="large"
                value={selectedTab}
                onChange={(_, newValue) => {
                    setSelectedTab(newValue);
                }}
                style={{ position: 'relative' }}
            >
                <Tab
                    value={'suggestions'}
                    label={t`Suggestions`}
                    count={suggestionsCount}
                    id={id('design/button_suggestions')}
                />
                <Tab value={'search'} label={t`Search`} id={id('design/button_search')} />
            </Tabs>
            <span style={{ position: 'absolute', top: '24px', right: '12px' }}>
                <AddManualParts rfqId={rfqId} addPart={handleAddPart} />
            </span>
            {selectedTab === 'suggestions' && (
                <PartSuggestions
                    assemblyId={assemblyId}
                    rfqId={rfqId}
                    bomItem={bomItem}
                    handleAddPart={handleAddPart}
                    handleAddMultiplePartOptions={handleAddMultiplePartOptions}
                    partialMatches={partialMatches}
                    isSuggestionsLoading={isSuggestionsLoading}
                    partAlternatives={partAlternatives}
                    assemblyIndustry={assemblyIndustry}
                />
            )}
            {selectedTab === 'search' && (
                <PartSearch
                    rfqId={rfqId}
                    bomItem={bomItem}
                    assemblyId={assemblyId}
                    handleAddMultiplePartOptions={handleAddMultiplePartOptions}
                    onRemovePart={handleRemovePartOption}
                    existingPartIds={existingPartIds}
                    assemblyIndustry={assemblyIndustry}
                />
            )}
        </>
    );
});
