/* eslint-disable no-console */
import { t, Trans } from '@lingui/macro';
import { getLocale } from '@luminovo/commons';
import { Dialog, DialogTitle, PrimaryButton, useNavigate } from '@luminovo/design-system';
import { ChargeImmediatelyResponseDTO, ExtractRequestBody, http } from '@luminovo/http-client';
import { DialogContent } from '@mui/material';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, Stripe, StripeElementsOptions } from '@stripe/stripe-js';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { LoadingText } from '../../../components/Spinners';
import { useToken } from '../../../resources/hooks';
import { useDebugErrorHandler } from '../../../resources/http/debugErrorHandler';
import { httpQueryKey } from '../../../resources/http/httpQueryKey';
import { useHttpQuery } from '../../../resources/http/useHttpQuery';
import { route } from '../../../utils/routes';
import { OrderFormState } from '../types';
import { useMutationInitializeOrder } from '../utils/useMutationInitializeOrder';
import { useOrderSelectionOptionsOfRfq } from '../utils/useOrderSelectionOptionsOfRfq';
import { OrderCheckoutForm } from './OrderCheckoutForm';

type OrderPostDTO = ExtractRequestBody<'POST /order-management/orders'>;

const useMutateOrder = (backToDashboard: () => void, rfqId: string) => {
    const { token } = useToken();
    const queryClient = useQueryClient();
    const debugErrorHandler = useDebugErrorHandler();

    return useMutation({
        mutationFn: async (values: OrderPostDTO) => {
            await http('POST /order-management/orders', { requestBody: values }, token);
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: httpQueryKey('GET /rfqs/:rfqId', { pathParams: { rfqId } }),
            });
            backToDashboard();
        },
        onError: (error) => {
            debugErrorHandler(error);
        },
    });
};

const OrderCheckout = ({ orderDetails, rfqId }: { orderDetails: ChargeImmediatelyResponseDTO; rfqId: string }) => {
    const [stripePromise, setStripePromise] = React.useState<Promise<Stripe | null> | undefined>(undefined);

    //useEffect required to avoid double loading in case the component re-renders
    React.useEffect(() => {
        setStripePromise(loadStripe(orderDetails.public_key));
    }, [orderDetails.public_key]);

    const options: StripeElementsOptions = {
        clientSecret: orderDetails.client_secret,
        locale: getLocale(),
    };

    if (!stripePromise) {
        return null;
    }
    return (
        <Elements stripe={stripePromise} options={options}>
            <OrderCheckoutForm orderDetails={orderDetails} rfqId={rfqId} />
        </Elements>
    );
};

export const OrderButton = ({ disabled, rfqId }: { disabled: boolean; rfqId: string }) => {
    const navigate = useNavigate();
    const backToDashboard = () => navigate(route('/rfqs/:rfqId/dashboard', { rfqId }));
    const { mutateAsync, isPending: isLoadingOrder } = useMutateOrder(backToDashboard, rfqId);
    const { data } = useHttpQuery('GET /customer-portal-settings/payment-settings', {});
    const [isPaymentDialogOpen, setIsPaymentDialogOpen] = React.useState(false);
    const [orderDetails, setOrderDetails] = React.useState<ChargeImmediatelyResponseDTO>();

    const { watch } = useFormContext<OrderFormState>();
    const selectedOrderOptionId = watch('selectedOrderOptionId');

    const { isExpired } = useOrderSelectionOptionsOfRfq(rfqId);

    useEffect(() => {
        if (isExpired && isPaymentDialogOpen) {
            setIsPaymentDialogOpen(false);
        }
    }, [isExpired, isPaymentDialogOpen, setIsPaymentDialogOpen]);

    const { mutateAsync: initializeOrder, isPending: isLoading } = useMutationInitializeOrder();
    if (!data) {
        return <LoadingText />;
    }

    const handleClickForPaymentsChargeImmediately = async () => {
        const data = await initializeOrder({
            values: {
                rfq_id: rfqId,
                order_selection_option_id: selectedOrderOptionId,
                payment_collection_mode: 'ChargeImmediately',
            },
        });
        //TODO: no type assertions
        setOrderDetails(data.data as ChargeImmediatelyResponseDTO);

        setIsPaymentDialogOpen(true);
    };

    const handleClickForPaymentsSendInvoice = async () => {
        await initializeOrder({
            values: {
                rfq_id: rfqId,
                order_selection_option_id: selectedOrderOptionId,
                payment_collection_mode: 'SendInvoice',
            },
        }).then(() => backToDashboard());
    };

    const handleClickForNoPayments = async () => {
        await mutateAsync({
            order_selection_option_id: selectedOrderOptionId,
            rfq_id: rfqId,
            payment_id: null,
            payment_processor: null,
            payment_collection_mode: null,
        });
    };

    const handleClose = () => setIsPaymentDialogOpen(false);
    const paymentMode = watch('paymentMode');
    return (
        <>
            <PrimaryButton
                fullWidth
                style={{ marginBottom: 12 }}
                onClick={() => {
                    if (data.is_payment_processor_setup === true) {
                        if (paymentMode === 'ChargeImmediately') {
                            handleClickForPaymentsChargeImmediately();
                        } else {
                            handleClickForPaymentsSendInvoice();
                        }
                    } else {
                        handleClickForNoPayments();
                    }
                }}
                disabled={disabled}
                isLoading={isLoading || isLoadingOrder}
            >
                <Trans>Order with costs</Trans>
            </PrimaryButton>

            {orderDetails !== undefined && (
                <Dialog
                    open={isPaymentDialogOpen}
                    maxWidth={'xs'}
                    disableAutoFocus
                    disableEnforceFocus
                    disableRestoreFocus
                >
                    <DialogTitle title={t`Complete payment`} onClose={handleClose} />
                    <DialogContent>
                        <OrderCheckout orderDetails={orderDetails} rfqId={rfqId} />
                    </DialogContent>
                </Dialog>
            )}
        </>
    );
};
