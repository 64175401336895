import * as z from 'zod';
import { ApiIntegrationStatusRuntype } from './3rdPartyBackendTypes';

export interface AvnetUsaCredentialsDTO extends z.infer<typeof AvnetUsaCredentialsDTORuntype> {}
export const AvnetUsaCredentialsDTORuntype = z.object({
    client_id: z.string(),
    client_secret: z.string(),
    subscription_key: z.string(),
    scope: z.string(),
});

export interface AvnetUsaCredentialsInputDTO extends z.infer<typeof AvnetUsaCredentialsInputDTORuntype> {}
export const AvnetUsaCredentialsInputDTORuntype = z.object({
    client_id: z.string().optional(),
    client_secret: z.string().optional(),
    subscription_key: z.string().optional(),
    scope: z.string().optional(),
});

export interface AvnetUsaResponseBodyDTO extends z.infer<typeof AvnetUsaResponseBodyDTORuntype> {}
export const AvnetUsaResponseBodyDTORuntype = z.object({
    data: AvnetUsaCredentialsDTORuntype.nullable(),
    status: ApiIntegrationStatusRuntype,
});
