import * as z from 'zod';
import { endpoint } from '../http/endpoint';
import { AvnetAbacusCredentialsInputDTORuntype, AvnetAbacusResponseBodyDTORuntype } from './avnetAbacusBackendTypes';

export const avnetAbacusEndpoints = {
    'GET /3rdparty/avnetabacus/credentials': endpoint({
        description: 'Returns credentials for avnetabacus integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: AvnetAbacusResponseBodyDTORuntype,
    }),
    'POST /3rdparty/avnetabacus/credentials': endpoint({
        description: 'Uploads credentials for avnetabacus API integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: AvnetAbacusCredentialsInputDTORuntype,
        responseBody: AvnetAbacusResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/avnetabacus/credentials'],
    }),
    'PATCH /3rdparty/avnetabacus/credentials': endpoint({
        description: 'Updates credentials for avnetabacus API integratoin',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: AvnetAbacusCredentialsInputDTORuntype,
        responseBody: AvnetAbacusResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/avnetabacus/credentials'],
    }),
    'DELETE /3rdparty/avnetabacus/credentials': endpoint({
        description: 'Deletes existing credentials for avnetabacus API integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({ deleted: z.number() }),
        invalidates: ['GET /3rdparty/avnetabacus/credentials'],
    }),
};
