import { t } from '@lingui/macro';
import { assertUnreachable, isPresent, uniqueBy } from '@luminovo/commons';
import { Flexbox, StatusChip, Tag, Text, Tooltip, colorSystem } from '@luminovo/design-system';
import {
    FullPart,
    OtsComponentFull,
    OtsFullPart,
    PartMatchTypeFullResponse,
    PreferenceStatusEnum,
    isCustomComponentFull,
    isCustomFullPart,
    isGenericFullPart,
    isOtsComponentFull,
    isOtsFullPart,
} from '@luminovo/http-client';
import { ManufacturerStatusChip, ManufacturerStatusChipProps } from '../ManufacturerStatusChip';
import { findRawSpecsNotLinked } from '../OtsComponents/findRawSpecsNotLinked';
import { UnlinkedIcon } from '../OtsComponents/UnlinkedIcon';
import { TableCell } from './TableCell';

export const RenderManufacturer = ({ part, onClick }: { part: FullPart; onClick?: () => void }): JSX.Element => {
    if (isOtsFullPart(part)) {
        return (
            <TableCell onClick={onClick}>
                <RenderOtsPartManufacturer part={part} />
            </TableCell>
        );
    }
    if (isGenericFullPart(part)) {
        return (
            <TableCell onClick={onClick}>
                <RenderGenericPartManufacturer />
            </TableCell>
        );
    }
    if (isOtsComponentFull(part)) {
        return (
            <TableCell isComponentRemoved={part.state === 'Removed'} onClick={onClick}>
                <RenderOtsComponentManufacturerMatches component={part} />
            </TableCell>
        );
    }
    if (isCustomFullPart(part)) {
        return <TableCell onClick={onClick} />;
    }
    if (isCustomComponentFull(part)) {
        return (
            <TableCell onClick={onClick} isComponentRemoved={part.state === 'Removed'}>
                -
            </TableCell>
        );
    }
    assertUnreachable(part);
};

const RenderOtsPartManufacturer = ({ part, state = 'Active' }: { part: OtsFullPart; state?: 'Active' | 'Removed' }) => {
    return (
        <ManufacturerStatusChip manufacturerName={part.manufacturer.name} preferenceStatus={part.preference_status} />
    );
};

const RenderGenericPartManufacturer = ({ state = 'Active' }: { state?: 'Active' | 'Removed' }) => {
    return (
        <Tag
            color="neutral"
            attention="low"
            label={t`Generic`}
            style={{ color: state === 'Removed' ? colorSystem.neutral[6] : colorSystem.neutral[8] }}
        />
    );
};

const RenderOtsComponentManufacturerMatch = ({
    manufFromLinkedParts,
    otsComponentState,
}: {
    manufFromLinkedParts: ManufacturerStatusChipProps[];
    otsComponentState?: 'Active' | 'Removed';
}) => {
    const { manufacturerName, preferenceStatus } = manufFromLinkedParts[0];
    if (manufacturerName === 'Generic') {
        return (
            <Tag
                color="neutral"
                attention="low"
                label={t`Generic`}
                style={{ color: otsComponentState === 'Removed' ? colorSystem.neutral[6] : colorSystem.neutral[8] }}
            />
        );
    }
    return (
        <ManufacturerStatusChip
            manufacturerName={manufacturerName}
            preferenceStatus={preferenceStatus}
            disabled={otsComponentState === 'Removed'}
        />
    );
};

const RenderSingleOtsComponentManufacturer = ({
    component,
    manufFromLinkedParts,
    manufFromRawSpecsToDisplay,
}: {
    component: OtsComponentFull;
    manufFromLinkedParts: ManufacturerStatusChipProps[];
    manufFromRawSpecsToDisplay: string[];
}): JSX.Element => {
    if (manufFromLinkedParts.length === 1) {
        return (
            <RenderOtsComponentManufacturerMatch
                manufFromLinkedParts={manufFromLinkedParts}
                otsComponentState={component.state}
            />
        );
    }
    if (manufFromRawSpecsToDisplay.length === 1) {
        return (
            <RenderRawSpecificationManufacturer manufacturer={manufFromRawSpecsToDisplay[0]} state={component.state} />
        );
    }
    return <Text variant="body-small">-</Text>;
};

const RenderOtsComponentManufacturerMatches = ({ component }: { component: OtsComponentFull }) => {
    const { matches, state } = component;
    const rawSpecsNotLinkedWithManufacturer = findRawSpecsNotLinked({ component }).filter(
        (spec) => !!spec.manufacturer,
    );

    if (matches.length === 0 && rawSpecsNotLinkedWithManufacturer.length === 0) {
        return (
            <Tag
                color="yellow"
                label={t`No linked parts`}
                attention="low"
                style={{ color: state === 'Removed' ? colorSystem.neutral[6] : colorSystem.yellow[7] }}
            />
        );
    }

    const extractManufacturerPreferencesFromMatch = (
        match: PartMatchTypeFullResponse,
    ): ManufacturerStatusChipProps[] => {
        if (match.type === 'Generic') {
            return [{ manufacturerName: t`Generic`, preferenceStatus: null }];
        }
        if (match.type === 'OffTheShelf') {
            return [
                {
                    manufacturerName: match.data.manufacturer.name,
                    manufacturerAliases: match.data.manufacturer.alternative_names,
                    partCategory: match.data.part_category?.part_category.name,
                    preferenceStatus: match.data.preference_status,
                },
            ];
        }
        return [];
    };

    const groupPreferencesByManufacturer = (
        preferences: ManufacturerStatusChipProps[],
    ): ManufacturerStatusChipProps[] => {
        const uniqueManufacturerPreferences = uniqueBy(preferences, (data) => {
            const { manufacturerName, partCategory, preferenceStatus } = data;
            const parts = [manufacturerName];
            if (partCategory) parts.push(partCategory);
            if (preferenceStatus) parts.push(preferenceStatus.toString());
            return parts.join('- ');
        });
        return uniqueManufacturerPreferences;
    };

    const manufacturerPreferences = groupPreferencesByManufacturer(
        matches.flatMap((match) => extractManufacturerPreferencesFromMatch(match.part)),
    );
    const manufFromLinkedParts = manufacturerPreferences.map((p) => p.manufacturerName);
    const manufacturersAndAliasesFromLinkedParts = manufacturerPreferences
        .flatMap((p) => [p.manufacturerName, ...(p.manufacturerAliases ?? [])])
        .map((manufacturer) => manufacturer.toLowerCase());

    const manufFromRawSpecsToDisplay = rawSpecsNotLinkedWithManufacturer
        .map((r) => r.manufacturer)
        .filter((manufacturer) => !manufacturersAndAliasesFromLinkedParts.includes((manufacturer ?? '').toLowerCase()))
        .filter(isPresent);

    if (manufFromLinkedParts.length + manufFromRawSpecsToDisplay.length === 1) {
        return (
            <RenderSingleOtsComponentManufacturer
                component={component}
                manufFromLinkedParts={manufacturerPreferences}
                manufFromRawSpecsToDisplay={manufFromRawSpecsToDisplay}
            />
        );
    }

    const manufacturersCount =
        manufacturerPreferences.filter((p) => p.manufacturerName !== 'Generic').length +
        manufFromRawSpecsToDisplay.length;
    return (
        <Tooltip
            variant="white"
            arrow
            title={
                <Flexbox flexDirection="column" padding="12px" gap="8px" justifyItems="center">
                    {manufacturerPreferences.map((pref, i) =>
                        pref.manufacturerName === 'Generic' ? (
                            <></>
                        ) : (
                            <Flexbox key={i} justifyContent="space-between" gap="16px">
                                <Text variant="body-small" style={{ whiteSpace: 'nowrap' }}>
                                    {pref.manufacturerName}
                                </Text>
                                <StatusChip label={pref.partCategory} color={getColor(pref.preferenceStatus)} />
                            </Flexbox>
                        ),
                    )}
                    {manufFromRawSpecsToDisplay.map((manufacturer, i) => (
                        <RenderRawSpecificationManufacturer key={i} manufacturer={manufacturer} />
                    ))}
                </Flexbox>
            }
        >
            <span>
                <Flexbox alignItems="center" gap="4px">
                    <StatusChip
                        label={t`${manufacturersCount} manufacturers`}
                        color={getColor(component.manufacturer_preference_status)}
                        enableHoverEffect={true}
                    />
                    {rawSpecsNotLinkedWithManufacturer.length > 0 && <UnlinkedIcon />}
                </Flexbox>
            </span>
        </Tooltip>
    );
};

function getColor(manufacturerPreferenceStatus: PreferenceStatusEnum | null) {
    switch (manufacturerPreferenceStatus) {
        case PreferenceStatusEnum.Blocked:
            return 'red';
        case PreferenceStatusEnum.Preferred:
            return 'primary';
        default:
            return 'green';
    }
}

const RenderRawSpecificationManufacturer = ({
    manufacturer,
    state,
}: {
    manufacturer: string;
    state?: 'Active' | 'Removed';
}): JSX.Element => {
    return (
        <Flexbox gap="8px" alignItems="center">
            <Text
                variant="body-small"
                style={{ whiteSpace: 'nowrap' }}
                color={state === 'Removed' ? colorSystem.neutral[6] : colorSystem.neutral[8]}
            >
                {manufacturer}
            </Text>
            <UnlinkedIcon />
        </Flexbox>
    );
};
