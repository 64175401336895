import * as z from 'zod';
import { endpoint } from '../http/endpoint';
import { AlertDTORuntype, CreateAlertRequestBodyRuntype, UpdateAlertRequestBodyRuntype } from './alertBackendTypes';

export const alertsEndpoints = {
    'GET /alerts/stock-level': endpoint({
        description: 'Returns all alerts',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({
            items: z.array(AlertDTORuntype),
        }),
    }),

    'POST /alerts/stock-level': endpoint({
        description: 'Creates a new alert',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: CreateAlertRequestBodyRuntype,
        responseBody: z.object({
            data: AlertDTORuntype,
        }),
        invalidates: ['GET /alerts/stock-level'],
    }),

    'PATCH /alerts/stock-level/:id': endpoint({
        description: 'Update an existing alert',
        pathParams: z.object({ id: z.string() }),
        queryParams: z.undefined(),
        requestBody: UpdateAlertRequestBodyRuntype,
        responseBody: z.object({
            data: AlertDTORuntype,
        }),
        invalidates: ['GET /alerts/stock-level', 'GET /alerts/stock-level/:id'],
    }),

    'GET /alerts/stock-level/:id': endpoint({
        description: 'Returns an alert',
        pathParams: z.object({ id: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({
            data: AlertDTORuntype,
        }),
    }),

    'DELETE /alerts/stock-level/:id': endpoint({
        description: 'Delete an existing alert',
        pathParams: z.object({ id: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({
            deleted: z.number(),
        }),
        invalidates: ['GET /alerts/stock-level'],
        removes: ['GET /alerts/stock-level/:id'],
    }),
};
