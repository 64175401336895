import { useQuoteTrackings } from '@/resources/quoteTracking/quoteTrackingHandler';
import { t } from '@lingui/macro';
import { formatDecimal, isPresent, uniq } from '@luminovo/commons';
import { Flexbox, Text, colorSystem } from '@luminovo/design-system';
import { QuoteTrackingDTO } from '@luminovo/http-client';
import { Box, Skeleton } from '@mui/material';
import React from 'react';

function extractRequestedPartIds(quoteTracking: QuoteTrackingDTO): string[] {
    return [
        ...quoteTracking.request_standard_parts.map((p) => p.kind.id),
        ...quoteTracking.request_custom_parts.map((p) => p.kind.id),
    ];
}

function extractQuotedPartIds(quoteTracking: QuoteTrackingDTO): string[] {
    return [
        ...quoteTracking.standard_part_offers.map((offer) => offer.linked_part.id),
        ...quoteTracking.custom_part_offers.map((offer) => offer.linked_part.id),
    ];
}

function calculateTotalParts(
    quoteTrackings: QuoteTrackingDTO | QuoteTrackingDTO[] | undefined,
    partExtractor: (quoteTracking: QuoteTrackingDTO) => string[],
): number | undefined {
    if (!isPresent(quoteTrackings)) {
        return undefined;
    }
    const quoteTrackingsArray = Array.isArray(quoteTrackings) ? quoteTrackings : [quoteTrackings];

    const allPartIds = quoteTrackingsArray.flatMap(partExtractor);
    const uniquePartIds = uniq(allPartIds);
    return uniquePartIds.length;
}

const DataPointDisplay: React.FunctionComponent<{
    title: string;
    value: number | undefined;
    loading?: boolean;
}> = ({ title, value, loading }) => (
    <Flexbox flexDirection={'column'}>
        <Text variant="h5" color={colorSystem.neutral[6]}>
            {title}
        </Text>
        <Text variant="h3" color={colorSystem.neutral[9]} style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
            {!loading ? formatDecimal(value, { ifAbsent: '-' }) : <Skeleton width="20px" />}
        </Text>
    </Flexbox>
);

export const QuoteTrackingSingeMetricCard: React.FunctionComponent<{
    quoteTrackingDTO: QuoteTrackingDTO;
}> = ({ quoteTrackingDTO }) => {
    const totalRequestedParts = calculateTotalParts(quoteTrackingDTO, extractRequestedPartIds);
    const totalQuotedParts = calculateTotalParts(quoteTrackingDTO, extractQuotedPartIds);

    return (
        <Flexbox flexDirection={'column'}>
            <Box
                display="grid"
                gridTemplateColumns="1fr 1fr"
                border={`1px solid ${colorSystem.neutral[2]}`}
                borderRadius={'8px 8px 0px 0px'}
                padding={'20px'}
                gap={'28px'}
            >
                <DataPointDisplay title={t`Requested parts`} value={totalRequestedParts} />
                <DataPointDisplay title={t`Quoted parts`} value={totalQuotedParts} />
            </Box>
        </Flexbox>
    );
};

export const QuoteTrackingTotalMetricCard: React.FunctionComponent<{
    rfqId: string;
}> = ({ rfqId }) => {
    const { data: quoteTrackingDTOs, isLoading } = useQuoteTrackings(rfqId);

    const totalRequestedParts = calculateTotalParts(quoteTrackingDTOs, extractRequestedPartIds);
    const totalQuotedParts = calculateTotalParts(quoteTrackingDTOs, extractQuotedPartIds);

    return (
        <Flexbox flexDirection={'column'}>
            <Box
                display="grid"
                gridTemplateColumns="1fr 1fr 20px"
                border={`1px solid ${colorSystem.neutral[2]}`}
                borderRadius={'8px 8px 0px 0px'}
                padding={'20px'}
                gap={'32px'}
            >
                <DataPointDisplay title={t`Requested parts`} value={totalRequestedParts} loading={isLoading} />
                <DataPointDisplay title={t`Quoted parts`} value={totalQuotedParts} loading={isLoading} />
            </Box>
        </Flexbox>
    );
};
