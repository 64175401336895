import * as z from 'zod';
import { endpoint } from '../http/endpoint';
import {
    FarnellCredentialsDTORuntype,
    FarnellCredentialsInputDTORuntype,
    FarnellSupportedSettingDTORuntype,
} from './farnellBackendTypes';

export const farnellEndpoints = {
    'GET /3rdparty/farnell/credentials': endpoint({
        description: 'Returns credentials for Farnell integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({ data: FarnellCredentialsDTORuntype }),
    }),
    'POST /3rdparty/farnell/credentials': endpoint({
        description: 'Uploads credentials for Farnell API integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: FarnellCredentialsInputDTORuntype,
        responseBody: z.object({ data: FarnellCredentialsDTORuntype }),
        invalidates: ['GET /3rdparty/farnell/credentials'],
    }),
    'PATCH /3rdparty/farnell/credentials': endpoint({
        description: 'Updates credentials for Farnell API integratoin',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: FarnellCredentialsInputDTORuntype,
        responseBody: z.object({ data: FarnellCredentialsDTORuntype }),
        invalidates: ['GET /3rdparty/farnell/credentials'],
    }),
    'DELETE /3rdparty/farnell/credentials': endpoint({
        description: 'Deletes existing credentials for Farnell API integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({ deleted: z.number() }),
        removes: ['GET /3rdparty/farnell/credentials'],
    }),
    'GET /3rdparty/farnell/supported-store-location-settings': endpoint({
        description: 'Returns supported store locations and currencies for Farnell API integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.array(FarnellSupportedSettingDTORuntype),
    }),
};
