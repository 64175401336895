export function parseEndpoint(endpoint: string): { method: string; pathTemplate: string } {
    const [method, pathTemplate, ...rest] = endpoint.split(' ');
    const httpMethods = ['GET', 'HEAD', 'POST', 'PUT', 'DELETE', 'CONNECT', 'OPTIONS', 'TRACE', 'PATCH'];
    if (rest.length > 0 || !httpMethods.includes(method)) {
        throw new Error(
            `Malformed endpoint: ${endpoint}. \nEndpoints must begin with an HTTP method followed by a single space and a path template. Example: 'GET /path/to/resource'`,
        );
    }

    return {
        method,
        pathTemplate,
    };
}
