import { isPresent } from '@luminovo/commons';
import { PartDTO, SupportedPartsDTO } from '@luminovo/http-client';
import { UseQueryResult } from '@tanstack/react-query';
import { useHttpQuery, useSuspenseHttpQuery } from '../http/useHttpQuery';

export function useSuppliers() {
    return useSuspenseHttpQuery('GET /suppliers', {}, { staleTime: Infinity, select: (res) => res.items });
}

export function useSupplier(id: string = '') {
    return useHttpQuery(
        'GET /suppliers',
        {},
        {
            staleTime: Infinity,
            enabled: Boolean(id),
            select: (res) => res.items.find((supplier) => supplier.id === id),
        },
    );
}

export function useSupportedPartsOfSupplier(
    rfqId: string,
    partIds: PartDTO[] | undefined,
    supplierIds: string[] | undefined,
): UseQueryResult<SupportedPartsDTO[]> {
    return useHttpQuery(
        'POST /suppliers/supported-parts',
        {
            requestBody: {
                rfq_id: rfqId,
                part_ids: Array.from((partIds ?? []).map((p) => p.data)).sort(),
                supplier_ids: Array.from(supplierIds ?? []).sort(),
            },
        },
        {
            enabled: isPresent(partIds) && isPresent(supplierIds),
            select: (res) => res.items,
        },
    );
}
