import * as z from 'zod';
import { ApprovalStatusRuntype, MonetaryValueBackendRuntype, QuantityUnitDTORuntype } from '../backendTypes';
import { DemandPostDTORuntype } from '../demand/demandBackendTypes';
import { CustomComponentFullRuntype, IpnWithFullPartMatchesRuntype } from '../internalPartNumber';
import { DemandDTORuntype } from '../negotiation';
import { PackagingRuntype } from '../offer';
import { AvailabilityRuntype } from '../offer/availablityBackendTypes';
import { AllOriginRuntype, PriceTypeRuntype, UnitOfMeasurementDTORuntype } from '../offer/offerBackendTypes';
import { PartSpecificationTypes } from '../part';
import {
    CustomFullPartRuntype,
    GenericPartWithIdAndFullPackageRuntype,
    OffTheShelfPartWithFullPackageRuntype,
    PartLiteRuntype,
    PartOptionOriginRuntype,
    StandardPartTypes,
} from '../part/partBackendTypes';
import {
    CostSummaryRuntype,
    DerivedScrapQuantityDTORuntype,
    SolutionDTORuntype,
    SolutionStatusRuntype,
    TagRuntype,
    TcoCostSummaryRuntype,
} from '../solution/solutionBackendTypes';
import { SupplierAndStockLocationDTORuntype } from '../supplierAndStockLocation';
import { SolutionPreferenceDTORuntype } from './solutionConfigurationBackendTypes';

const AggregatedBreadcrumbsDTORuntype = z.object({
    breadcrumbs: z.object({
        items: z.array(
            z.object({
                design_item_id: z.string(),
                designator: z.string(),
                parent_assemblies: z.array(z.object({ id: z.string(), name: z.string() })),
            }),
        ),
    }),
});

const PartOptionFullRuntype = z.object({
    approval_status: ApprovalStatusRuntype,
    origin: PartOptionOriginRuntype.nullable(),
    part: z.union([
        z.object({ type: z.literal(StandardPartTypes.OffTheShelf), data: OffTheShelfPartWithFullPackageRuntype }),
        z.object({ type: z.literal(StandardPartTypes.Generic), data: GenericPartWithIdAndFullPackageRuntype }),
        z.object({ type: z.literal(StandardPartTypes.Ipn), data: IpnWithFullPartMatchesRuntype }),
    ]),
});

export interface PartSpecificationOffTheShelfFull extends z.infer<typeof PartSpecificationOffTheShelfFullRuntype> {}
export const PartSpecificationOffTheShelfFullRuntype = z.object({
    type: z.literal(PartSpecificationTypes.OffTheShelf),
    data: z.object({ is_manufacturer_free: z.boolean(), part_options: z.array(PartOptionFullRuntype) }),
});

const CustomOptionVariantFullRuntype = z.object({
    type: z.literal(PartSpecificationTypes.Custom),
    data: z.array(
        z.object({
            part: z.union([
                z.object({ type: z.literal('CustomPart'), data: CustomFullPartRuntype }),
                z.object({ type: z.literal('CustomComponent'), data: CustomComponentFullRuntype }),
            ]),
            approval_status: ApprovalStatusRuntype,
        }),
    ),
});

export const PartSpecificationFullRuntype = z.union([
    PartSpecificationOffTheShelfFullRuntype,
    CustomOptionVariantFullRuntype,
]);

export interface SolutionConfigurationFullPartSourcingInformationResponse
    extends z.infer<typeof SolutionConfigurationFullPartSourcingInformationResponseRuntype> {}
export const SolutionConfigurationFullPartSourcingInformationResponseRuntype = z.object({
    id: z.string(),
    effective_solution: SolutionDTORuntype.nullable(),
    aggregated_quantity: QuantityUnitDTORuntype,
    scrap_quantity: z.null().or(DerivedScrapQuantityDTORuntype),
    specification: PartSpecificationFullRuntype.nullable(),
    aggregated_breadcrumbs: AggregatedBreadcrumbsDTORuntype,
    is_consigned: z.boolean(),
    notes: z.string().nullable(),
});

const SolutionStatusCountsDTORuntype = z.object({
    number_of_error: z.number(),
    number_of_ok: z.number(),
    number_of_warning: z.number(),
});

export type AssemblySourcingSummaryDTO = z.infer<typeof AssemblySourcingSummaryRuntype>;
const AssemblySourcingSummaryRuntype = z.object({
    unit_price: MonetaryValueBackendRuntype.nullable(),
    total_tco_costs: TcoCostSummaryRuntype,
    total_price: MonetaryValueBackendRuntype.nullable(),
    total_scrap_costs: MonetaryValueBackendRuntype.nullable(),
    total_excess_material: MonetaryValueBackendRuntype.nullable(),
    total_one_time_costs: MonetaryValueBackendRuntype.nullable(),
    availability: AvailabilityRuntype.nullable(),
    has_missing_solutions: z.boolean(),
});

export interface ItemObject extends z.infer<typeof ItemObjectRuntype> {}
const ItemObjectRuntype = z.object({ assembly: z.string(), quantity: z.number() });

export interface AssemblyQuantities extends z.infer<typeof AssemblyQuantitiesRuntype> {}
const AssemblyQuantitiesRuntype = z.object({ items: z.array(ItemObjectRuntype) });

export interface SourcingScenarioDTO extends z.infer<typeof SourcingScenarioDTORuntype> {}
export const SourcingScenarioDTORuntype = z.object({
    id: z.string(),
    rfq: z.string(),
    name: z.string(),
    solution_preference: SolutionPreferenceDTORuntype,
    assembly_quantities: AssemblyQuantitiesRuntype, // @deprecated - use assembly_demands instead
    assembly_demands: z.array(DemandDTORuntype),
});

export interface SourcingScenarioPostDTO extends z.infer<typeof SourcingScenarioPostDTORuntype> {}
export const SourcingScenarioPostDTORuntype = z.object({
    rfq: z.string(),
    name: z.string(),
    solution_preference: SolutionPreferenceDTORuntype,
    assembly_demands: z.array(
        z.union([
            z.object({ kind: z.literal('existing'), data: z.object({ id: z.string() }) }),
            z.object({ kind: z.literal('new'), data: DemandPostDTORuntype }),
        ]),
    ),
});

export interface SourcingScenarioPatchDTO extends z.infer<typeof SourcingScenarioPatchDTORuntype> {}
export const SourcingScenarioPatchDTORuntype = SourcingScenarioPostDTORuntype.omit({ rfq: true }).partial();

export interface SupplierSolutionDTO extends z.infer<typeof SupplierSolutionRuntype> {}
export const SupplierSolutionRuntype = z.object({
    linked_location: SupplierAndStockLocationDTORuntype,
    sourcing_scenario_solutions: z.array(
        z.object({
            solutions: z.array(SolutionDTORuntype),
            solution_configuration_id: z.string(),
            sourcing_scenario: SourcingScenarioDTORuntype,
        }),
    ),
});

export enum SolutionSelection {
    Automatic = 'Automatic',
    Manual = 'Manual',
    Unknown = 'Unknown',
}
export const SolutionSelectionRuntype = z.nativeEnum(SolutionSelection);

const SolutionConfigurationStatusRuntype = z.union([
    z.object({ type: z.literal('NoOffer') }),
    z.object({ type: z.literal('NoApprovedParts') }),
    z.object({
        type: z.literal('Status'),
        data: z.object({ status: SolutionStatusRuntype, tags: z.array(TagRuntype) }),
    }),
]);
export type SolutionConfigurationStatusDTO = z.infer<typeof SolutionConfigurationStatusRuntype>;

export const SolutionConfigurationSummaryRuntype = z.object({
    selection: SolutionSelectionRuntype,
    status: SolutionConfigurationStatusRuntype,
    manual_status: SolutionStatusRuntype.nullable(),
    design_items: z.array(z.string()),
    notes: z.string().nullable(),
    assemblies: z.array(z.string()),
    aggregated_quantity: QuantityUnitDTORuntype,
    scrap_quantity: DerivedScrapQuantityDTORuntype.nullable(),
});

const OffTheShelfOfferDataRuntype = z.object({
    id: z.string(),
    packaging: PackagingRuntype.nullable(),
    origin: AllOriginRuntype,
    factory_lead_time: z.number().nullable(),
    unit_of_measurement: UnitOfMeasurementDTORuntype,
    price_type: PriceTypeRuntype,
    supplier_part_number: z.string().nullable(),
    moq: z.number().nullable(),
    mpq: z.number().nullable(),
    lead_time: AvailabilityRuntype.nullable(),
    supplier_and_stock_location: z.string(),
    offer_url: z.string().nullable(),
});
export type OffTheShelfOfferDataDTO = z.infer<typeof OffTheShelfOfferDataRuntype>;

const CustomPartOfferDataRuntype = z.object({
    id: z.string(),
    origin: AllOriginRuntype,
    unit_of_measurement: UnitOfMeasurementDTORuntype,
    price_type: PriceTypeRuntype.nullable(),
    lead_time: AvailabilityRuntype.nullable(),
    supplier_and_stock_location: z.string(),
    offer_url: z.string().nullable(),
});
export type CustomPartOfferDataDTO = z.infer<typeof CustomPartOfferDataRuntype>;

const InventoryOfferDataRuntype = z.object({
    id: z.string(),
    packaging: PackagingRuntype.nullable(),
    origin: AllOriginRuntype,
    factory_lead_time: z.number().nullable(),
    unit_of_measurement: UnitOfMeasurementDTORuntype,
    price_type: PriceTypeRuntype,
    supplier_part_number: z.string().nullable(),
    inventory_site: z.string(),
    lead_time: AvailabilityRuntype.nullable(),
});
export type InventoryDataDTO = z.infer<typeof InventoryOfferDataRuntype>;

const OfferDataRuntype = z.union([
    z.object({ type: z.literal('OffTheShelf'), data: OffTheShelfOfferDataRuntype }),
    z.object({ type: z.literal('CustomPart'), data: CustomPartOfferDataRuntype }),
    z.object({ type: z.literal('Inventory'), data: InventoryOfferDataRuntype }),
]);

export const OfferSummaryRuntype = z.union([
    z.object({ type: z.literal('NoOffer') }),
    z.object({ type: z.literal('Consigned') }),
    z.object({ type: z.literal('Offer'), data: OfferDataRuntype }),
]);
export type OfferSummaryDTO = z.infer<typeof OfferSummaryRuntype>;

export const PartSummaryRuntype = z.object({
    selected_part: PartLiteRuntype.nullable(),
    selected_part_option: PartLiteRuntype.nullable(),
    approved_part_options: z.array(PartLiteRuntype),
});
export type PartSummaryDTO = z.infer<typeof PartSummaryRuntype>;

export const BomSummaryRuntype = z.object({
    bom_ipns: z.array(z.string()),
    bom_cpns: z.array(z.string()),
    bom_notes: z.array(z.string()),
});
export type BomSummaryDTO = z.infer<typeof BomSummaryRuntype>;

export const SolutionConfigurationCalculationRuntype = z.object({
    id: z.string(),
    solution_configuration_summary: SolutionConfigurationSummaryRuntype,
    offer_summary: OfferSummaryRuntype,
    part_summary: PartSummaryRuntype,
    cost_summary: CostSummaryRuntype,
    bom_summary: BomSummaryRuntype,
    token: z.string().nullable(),
});
export type SolutionConfigurationCalculationDTO = z.infer<typeof SolutionConfigurationCalculationRuntype>;

const AssemblySourcingDataRuntype = z.object({
    id: z.string(),
    designator: z.string(),
    paths: z.array(z.array(z.string())),
});

const DesignItemSourcingDataRuntype = z.object({
    id: z.string(),
    assembly: z.string(),
    designator: z.string().nullable(),
});

export const SourcingCalculationRuntype = z.object({
    sourcing_scenario_id: z.string(),
    ipn_to_cpns: z.record(z.string(), z.array(z.string())),
    assemblies: z.record(z.string(), AssemblySourcingDataRuntype),
    design_items: z.record(z.string(), DesignItemSourcingDataRuntype),
    solution_configurations: z.array(SolutionConfigurationCalculationRuntype),
    aggregated_top_level_assembly_information: z.record(z.string(), AssemblySourcingSummaryRuntype),
    total_excess_material: MonetaryValueBackendRuntype.nullable(),
    total_unit_price: MonetaryValueBackendRuntype.nullable(),
    total_tco_costs: TcoCostSummaryRuntype,
    total_one_time_costs: MonetaryValueBackendRuntype.nullable(),
    total_scrap_costs: MonetaryValueBackendRuntype.nullable(),
    total_price: MonetaryValueBackendRuntype.nullable(),
    total_availability: AvailabilityRuntype.nullable(),
    status_count: SolutionStatusCountsDTORuntype,
});
export type SourcingCalculationDTO = z.infer<typeof SourcingCalculationRuntype>;

export type SourcingCalculationResponseDTO = z.infer<typeof SourcingCalculationResponseRuntype>;
export const SourcingCalculationResponseRuntype: z.ZodType<{ items: Array<SourcingCalculationDTO> }> = z.object({
    items: z.array(SourcingCalculationRuntype),
});
