import * as z from 'zod';
import { SystemDriverNameRuntype } from './driverBackendTypes';

export const UserDriverIdRuntype = z.object({
    type: z.literal('User'),
    value: z.string(),
});

export const SystemDriverIdRuntype = z.object({
    type: z.literal('System'),
    value: SystemDriverNameRuntype,
});

export type UserDriverIdDTO = z.infer<typeof UserDriverIdRuntype>;
export type SystemDriverIdDTO = z.infer<typeof SystemDriverIdRuntype>;

export const DriverIdRuntype = z.union([UserDriverIdRuntype, SystemDriverIdRuntype]);
export type DriverIdDTO = z.infer<typeof DriverIdRuntype>;

export const DriverIdParameterRuntype = z.string().regex(/^\{.*\}$/);
export type DriverIdParameter = z.infer<typeof DriverIdParameterRuntype>;

export const DriverIdPathRuntype = z.string().regex(/^(user\||system\|).*$/);
export type DriverIdPath = z.infer<typeof DriverIdPathRuntype>;
