import { HttpError } from '@luminovo/http-client';
import { ImportHandlers, ImportRecord, ImportStatus, ImporterConfig, ImporterTable } from '../types';
import { iterateBatches } from './iterateBatches';

/**
 * Runs the importer on the given table.
 *
 * Splits the table into batches, then calls the onImport function for each batch.
 * Updates the table with the results of the import.
 *
 * The generator yields the table after each batch has been processed.
 *
 * @param table The table that will be imported.
 * @param onImportBatch A function that will be called for each batch of records that are ready for import.
 */
export async function* runImporter<TConfig extends ImporterConfig, THandlerRecord>({
    table,
    importHandlers,
    batchSize,
}: {
    table: ImporterTable;
    importHandlers: ImportHandlers<TConfig, THandlerRecord>;
    batchSize: number;
}): AsyncGenerator<ImporterTable> {
    let finalTable = table;

    const rowsWithImportRecords = table.filterByLinkStatus(null).flatMap((row) => {
        const record = row.record;
        if (record !== undefined && record.action !== 'skipped') {
            return [{ row, tableRecord: record as ImportRecord<TConfig> }];
        }

        return [];
    });

    const rowsWithHandlerRecords = importHandlers.postprocessRows(rowsWithImportRecords);

    const gen = iterateBatches(rowsWithHandlerRecords, {
        batchSize,
        mapBatch: async ({ items }) => {
            const handlerRecords = items.map(({ handlerRecord }) => handlerRecord);
            const importResults = await importHandlers
                .handleBatch(handlerRecords)
                // If the import fails, we return an array of import statuses with the error message
                .catch((err): ImportStatus[] => {
                    return handlerRecords.map(() => ({
                        success: false,
                        message: err instanceof HttpError ? err.code : String(err),
                    }));
                });
            if (importResults.length !== items.length) {
                throw new Error(
                    `Illegal state: expected one result per record. results=${importResults.length}, records=${handlerRecords.length}`,
                );
            }
            return zip(items, importResults).flatMap(([{ rows }, result]) =>
                rows.map((row) => ({
                    ...row,
                    import: result,
                })),
            );
        },
    });

    for await (const rows of gen) {
        finalTable = finalTable.updateRows(rows);
        yield finalTable;
    }
}

function zip<T, U>(a: T[], b: U[]): Array<[T, U]> {
    return a.map((v, i) => [v, b[i]]);
}
