import { colorSystem, Flexbox, Text } from '@luminovo/design-system';
import { CheckCircleRounded, WarningRounded } from '@mui/icons-material';
import { styled, SvgIconProps, Tooltip } from '@mui/material';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { findRouteId } from '../../../../../utils/routes';
import { ViewContext } from '../../../../Bom/components/ModuleTableData';
import { formatRouteBasedOnViewContext } from '../../../utils/formatRoutebasedOnViewContext';
import { usePcbApprovalState } from '../../../utils/usePcbApprovalState';
import { PCBRouteSuffix, PcbSidebarLinkProps } from '../utils/pcbSidebarLinks';

const StyledLink = styled(Link)({
    textDecoration: 'none',
    color: colorSystem.neutral[6],
    '&:hover': {
        color: colorSystem.primary[6],
    },
});

const StyledActiveLink = styled(StyledLink)({
    color: colorSystem.primary[6],
});

export interface SidebarLinkProps extends React.ComponentProps<typeof Link> {
    isOpen: boolean;
    link: PcbSidebarLinkProps;
    disabled?: boolean;
    viewContext: ViewContext;
    assemblyId: string;
    pcbId: string;
    showRouteStatus?: boolean;
}

const LinkBody = ({
    Icon,
    text,
    isOpen,
    style,
    routeStatus,
}: {
    Icon: React.ComponentType<SvgIconProps>;
    text: string;
    isOpen: boolean;
    style?: React.CSSProperties;
    routeStatus?: 'approved' | 'pending' | 'warning' | undefined;
}) => {
    return (
        <Flexbox
            flexDirection={'column'}
            style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '8px',
                alignItems: 'center',
                justifyContent: 'flex-start',
                padding: '10px',
                borderRadius: '8px',
                position: 'relative',
                ...style,
            }}
        >
            <Tooltip title={text} disableHoverListener={isOpen}>
                <Icon style={{ fontSize: '20px' }} />
            </Tooltip>
            {isOpen && (
                <>
                    <Text variant="h5" style={{ color: 'inherit', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                        {text}
                    </Text>
                    {routeStatus !== undefined && (
                        <span
                            style={{
                                width: '16px',
                                height: '16px',
                                borderRadius: '50%',
                                alignSelf: 'start',
                                marginLeft: 'auto',
                                border:
                                    routeStatus !== 'warning'
                                        ? `1px solid ${routeStatus === 'pending' ? colorSystem.neutral[5] : 'transparent'}`
                                        : '0',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            {routeStatus === 'approved' && (
                                <CheckCircleRounded fontSize="small" style={{ color: colorSystem.green[5] }} />
                            )}
                            {routeStatus === 'warning' && (
                                <WarningRounded fontSize="small" style={{ color: colorSystem.yellow[6] }} />
                            )}
                        </span>
                    )}
                </>
            )}
        </Flexbox>
    );
};

const useRouteStatus = ({
    pcbId,
    currentRoute,
}: {
    pcbId: string;
    currentRoute: PCBRouteSuffix | undefined;
}): {
    status: 'approved' | 'pending' | 'warning';
} => {
    const { isRouteApproved } = usePcbApprovalState({
        pcbId,
        currentRoute,
    });

    if (isRouteApproved === true) {
        return { status: 'approved' };
    }

    return { status: 'pending' };
};

export function SidebarLink({
    disabled = false,
    onClick,
    link,
    viewContext,
    isOpen,
    showRouteStatus,
    pcbId,
    ...props
}: SidebarLinkProps) {
    const { pathname } = useLocation();
    const { status } = useRouteStatus({
        pcbId,
        currentRoute: link.route,
    });

    const routeId = findRouteId(pathname);
    const linkRoute = formatRouteBasedOnViewContext(link.route, viewContext);
    const routeStatus = showRouteStatus ? status : undefined;

    const isSelected = routeId === linkRoute;

    if (disabled) {
        return (
            <LinkBody
                Icon={link.Icon}
                text={link.text()}
                isOpen={isOpen}
                routeStatus={routeStatus}
                style={{
                    color: colorSystem.neutral[4],
                    borderRight: `1px solid transparent`,
                    width: '100%',
                }}
            />
        );
    }

    if (isSelected) {
        return (
            <StyledActiveLink onClick={onClick} id={link.id} {...props}>
                <LinkBody
                    Icon={link.Icon}
                    text={link.text()}
                    isOpen={isOpen}
                    routeStatus={routeStatus}
                    style={{
                        backgroundColor: colorSystem.primary[1],
                    }}
                />
            </StyledActiveLink>
        );
    }

    return (
        <StyledLink onClick={onClick} id={link.id} {...props}>
            <LinkBody Icon={link.Icon} text={link.text()} isOpen={isOpen} routeStatus={routeStatus} />
        </StyledLink>
    );
}
