import { DataTable, useDataTableState } from '@luminovo/design-system';
import {
    ManufacturingScenarioBatchSizeBreakdownsDTO,
    ManufacturingScenarioFullSummaryDTO,
} from '@luminovo/http-client';
import {
    BatchSizeBreakdownWithBatchSize,
    generateManufacturingScenarioCostBreakdownColumn,
    generateManufacturingScenarioTimeBreakdownColumn,
} from './manufacturingScenarioBreakdownTableColumns';

const convertBatchSizeDTO = (
    batchSizeBreakdown: ManufacturingScenarioBatchSizeBreakdownsDTO,
): BatchSizeBreakdownWithBatchSize[] => {
    return Object.entries(batchSizeBreakdown).map(([key, value]) => ({
        ...value,
        batch_size: key,
    }));
};

export const ManufacturingScenarioCostBreakdownTable = ({
    manufacturingScenarioFullSummary,
}: {
    manufacturingScenarioFullSummary: ManufacturingScenarioFullSummaryDTO;
}): JSX.Element => {
    const columns = generateManufacturingScenarioCostBreakdownColumn();
    const items: BatchSizeBreakdownWithBatchSize[] = convertBatchSizeDTO(
        manufacturingScenarioFullSummary.breakdown_per_batch_size,
    );

    const tableState = useDataTableState({
        columns: columns,
        items,
        persistenceId: 'manufacturingScenarioCostBreakdownId',
        paginationOptions: { showPagination: false },
    });

    return <DataTable key="manufacturing-scenario-cost-breakdown-table" tableState={tableState} size="small" />;
};

export const ManufacturingScenarioTimeBreakdownTable = ({
    manufacturingScenarioFullSummary,
}: {
    manufacturingScenarioFullSummary: ManufacturingScenarioFullSummaryDTO;
}): JSX.Element => {
    const columns = generateManufacturingScenarioTimeBreakdownColumn();

    const items: BatchSizeBreakdownWithBatchSize[] = convertBatchSizeDTO(
        manufacturingScenarioFullSummary.breakdown_per_batch_size,
    );

    const tableState = useDataTableState({
        columns: columns,
        items,
        persistenceId: 'manufacturingScenarioTimeBreakdownId',
        paginationOptions: { showPagination: false },
    });

    return <DataTable key="manufacturing-scenario-time-breakdown-table" tableState={tableState} size="small" />;
};
