import { t } from '@lingui/macro';
import { isPresent } from '@luminovo/commons';
import { Chip, colorSystem, Flexbox, Text } from '@luminovo/design-system';
import { Functions, Lock } from '@mui/icons-material';
import { Box, TableCell, Tooltip } from '@mui/material';
import { UseFieldArrayInsert, UseFieldArrayRemove, UseFieldArrayUpdate } from 'react-hook-form';
import { RedHighlightOffRoundedIcon, YellowHelpOutlineRoundedIcon } from '../../../../components/Icons/icons';
import { RowActionMenu } from '../RowActionMenu';
import { CostTypeFormula, DynamicCostCell } from '../types/cellTypes';
import { CalculationTableForm } from '../types/formTypes';
import { Row, Style } from '../types/rowTypes';
import { CELL_HEIGHT } from './cells';
import { ToggleRow } from './ToggleRow';

const defaultRowHeaderCellStyle: React.CSSProperties = {
    borderRight: `8px solid ${colorSystem.neutral[1]}`,
    borderTop: `0px solid ${colorSystem.neutral[1]}`,
    borderBottom: `1px solid ${colorSystem.neutral[1]}`,
    height: CELL_HEIGHT,
};

const breakdownRowHeaderCellStyle: React.CSSProperties = {
    ...defaultRowHeaderCellStyle,
    paddingLeft: '50px',
};

const greyRowHeaderCellStyle: React.CSSProperties = {
    ...defaultRowHeaderCellStyle,
};

const getRowHeaderCellStyle = (rowType: Style): React.CSSProperties => {
    switch (rowType) {
        case 'breakdown':
            return breakdownRowHeaderCellStyle;
        case 'grey':
            return greyRowHeaderCellStyle;
        default:
            return defaultRowHeaderCellStyle;
    }
};

const FormulaChip = ({ error, warning }: { error: boolean; warning: boolean }) => {
    if (error) {
        return (
            <Tooltip title={t`This formula contains an error. Please fix this in the template.`}>
                {/* Span required here otherwise tooltip does not show */}
                <span>
                    <Chip
                        label={t`Formula`}
                        color="red"
                        startIcon={<RedHighlightOffRoundedIcon fontSize="inherit" />}
                    />
                </span>
            </Tooltip>
        );
    }

    if (warning) {
        return (
            <Flexbox gap={4} alignItems={'center'}>
                <Tooltip title={t`Some formula inputs require information that is not present`}>
                    <YellowHelpOutlineRoundedIcon />
                </Tooltip>
                <Chip label={t`Formula`} color="neutral" startIcon={<Functions fontSize="inherit" />} />
            </Flexbox>
        );
    }

    return <Chip label={t`Formula`} color="neutral" startIcon={<Functions fontSize="inherit" />} />;
};

export const RowHeaderCell = ({
    index,
    insert,
    remove,
    update,
    data,
    isCalculationFrozen,
}: {
    index: number;
    insert: UseFieldArrayInsert<CalculationTableForm, 'rows'>;
    remove: UseFieldArrayRemove;
    update: UseFieldArrayUpdate<CalculationTableForm, 'rows'>;
    data: Row;
    isCalculationFrozen: boolean;
}) => {
    const rowStyle = data.style;
    const formulaCells: CostTypeFormula[] =
        data.type === 'dynamic'
            ? data.cells
                  .map((c: DynamicCostCell) => {
                      if (
                          c.costSpecification.type === 'formula-fraction' ||
                          c.costSpecification.type === 'formula-fixed'
                      ) {
                          return c.costSpecification;
                      }
                      return undefined;
                  })
                  .filter(isPresent)
            : [];
    const isFormulaRow = formulaCells.length > 0;
    const formulaContainsError = formulaCells.some((c) => c.result !== 'Ok');
    const costHasWarning = formulaCells.some((c) => c.statuses.some((s) => s.status.type !== 'Ok'));
    return (
        <TableCell style={getRowHeaderCellStyle(data.style)}>
            <Flexbox justifyContent={'space-between'} alignItems={'center'} gap={8}>
                <Flexbox>
                    <Text variant="h4" color={rowStyle === 'blue' ? colorSystem.neutral[8] : colorSystem.neutral[6]}>
                        {data.label}
                    </Text>
                    <Flexbox alignItems="center" gap={12} marginLeft={2}>
                        {data.type === 'dynamic' &&
                            data.cells.map((c: DynamicCostCell) => c.isLocked).includes(true) === true && (
                                <Flexbox>
                                    <Tooltip title={t`This row is locked. It can only be changed in the template.`}>
                                        <Lock style={{ color: colorSystem.neutral[6], fontSize: '18px' }} />
                                    </Tooltip>
                                </Flexbox>
                            )}
                    </Flexbox>
                </Flexbox>

                <Flexbox alignItems="center" gap={12}>
                    {data.type === 'dynamic' &&
                        //hides the toggle if the row contains a formula
                        !isFormulaRow &&
                        !isCalculationFrozen &&
                        data.cells.every((c) => c.isLocked === false) && (
                            //This marginRight is here to make the toggleRow button align correctly with the rest
                            <Box marginRight={data.rowActions.length > 0 ? '0px' : '52px'}>
                                <ToggleRow cells={data.cells} rowIndex={index} />
                            </Box>
                        )}
                    {isFormulaRow ? (
                        //This marginRight is here to make the toggleRow button align correctly with the rest
                        <Box marginRight={data.rowActions.length > 0 ? '0px' : '52px'}>
                            <FormulaChip warning={costHasWarning} error={formulaContainsError} />
                        </Box>
                    ) : undefined}
                    {data.rowActions.length > 0 && !isCalculationFrozen && (
                        <RowActionMenu
                            insert={insert}
                            remove={remove}
                            actions={data.rowActions}
                            update={update}
                            index={index}
                            data={data}
                        />
                    )}
                </Flexbox>
            </Flexbox>
        </TableCell>
    );
};
