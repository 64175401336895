import { Trans, t } from '@lingui/macro';
import { Currency, compareByString, transEnum, typeSafeObjectKeys } from '@luminovo/commons';
import {
    DestructiveTertiaryIconButton,
    FieldMultiSelectControlled,
    FieldNumericControlled,
    FieldSelectControlled,
    Flexbox,
    TertiaryButton,
    Text,
} from '@luminovo/design-system';
import { AttributeReference, PackageMountingEnum, Packaging } from '@luminovo/http-client';
import { packageMountingEnumTranslations, packagingTranslations } from '@luminovo/sourcing-core';
import { Add, Delete } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { inputCurrenciesPublicTranslations } from '../../../../resources/currencyInputTypes';
import {
    attributeReferencePublicTranslations,
    conditionTranslations,
    enumFilterOperatorsPublicTranslations,
    numberFilterOperatorsPublicTranslations,
    offerTypePublicTranslations,
    partTypePublicTranslations,
    sideOfPlacementPublicTranslations,
} from '../../../../resources/driver/driverBackendTypes';
import { assertUnreachable } from '../../../../utils/typingUtils';
import {
    AddDriverFormInputs,
    EnumOperator,
    FilterInput,
    NumberOperator,
    PackageOptions,
    PackageValue,
} from '../AddDriverFormTypes';
import { AdvancedPackageFilterMenu } from './AdvancedPackageFilter';

const getDefaultFilterInput = (attribute: AttributeReference): FilterInput => {
    switch (attribute) {
        case 'CustomPartType':
            return {
                operator: {
                    type: 'enum',
                    value: 'Is',
                },
                type: attribute,
                value: 'PCB',
            };
        case 'MaterialPrice':
            return {
                operator: {
                    type: 'number',
                    value: 'Greater',
                },
                type: attribute,
                value: {
                    amount: 0,
                    currency: Currency.EUR,
                },
            };
        case 'OfferType':
            return {
                operator: {
                    type: 'enum',
                    value: 'Is',
                },
                type: attribute,
                value: 'CustomPart',
            };
        case 'PartNumberOfPins':
            return {
                operator: {
                    type: 'number',
                    value: 'Greater',
                },
                type: attribute,
                value: 0,
            };
        case 'NumberOfApprovedPartOptionsWithIpns':
            return {
                operator: {
                    type: 'number',
                    value: 'Equal',
                },
                type: attribute,
                value: 0,
            };
        case 'PnpSide':
            return {
                operator: {
                    type: 'enum',
                    value: 'Is',
                },
                type: attribute,
                value: 'Top',
            };
        case 'Package':
            return {
                operator: {
                    type: 'enum',
                    value: 'Is',
                },
                type: attribute,
                value: {
                    mounting: PackageMountingEnum.SMT,
                    packages: [],
                },
            };
        case 'Packaging':
            return {
                operator: {
                    type: 'enum',
                    value: 'Is',
                },
                type: attribute,
                value: Packaging.AmmoPack,
            };
        default:
            assertUnreachable(attribute);
    }
};

export const DriverFilterFormComponent = ({ packages }: { packages: PackageOptions }): JSX.Element => {
    const { control } = useFormContext<AddDriverFormInputs>();
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'driverDetails.filterFormula',
    });

    const filterFormula = useWatch({ control, name: 'driverDetails.filterFormula' });

    const handleAddFilter = () => {
        append({
            joinWithPrevious: 'And',
            filter: {
                type: 'Package',
                operator: {
                    type: 'enum',
                    value: 'Is',
                },
                value: {
                    mounting: PackageMountingEnum.SMT,
                    packages: [],
                },
            },
        });
    };

    return (
        <Flexbox flexDirection="column" gap={16}>
            {fields.map((field, i) => {
                // We're using the attribute (CustomPartType, MaterialPrice etc.) as key for the
                // operator and filter value components. If the attribute and the default operator
                // and filter values change, the key will lead to the required rerender of the
                // operator and filter value form components.
                const attributeType = filterFormula ? filterFormula[i]?.filter.type : '';
                return (
                    <Grid container spacing={2} key={`${field.id}-${i}`}>
                        <Grid item xs={1}>
                            <JoinWithPreviousFormComponent index={i} />
                        </Grid>
                        <Grid item xs={2}>
                            <FilterAttributeFormComponent index={i} />
                        </Grid>
                        <Grid item xs={2} key={`operator-${i}-${attributeType}`}>
                            <FilterOperatorFormComponent index={i} />
                        </Grid>
                        <Grid item xs={5} key={`filterValue-${i}-${attributeType}`}>
                            <FilterValueFormComponent index={i} packages={packages} />
                        </Grid>
                        <Grid item xs={1}>
                            <DestructiveTertiaryIconButton
                                children={<Delete fontSize="inherit" />}
                                onClick={() => remove(i)}
                            />
                        </Grid>
                    </Grid>
                );
            })}

            <Flexbox>
                <TertiaryButton startIcon={<Add fontSize="inherit" />} onClick={handleAddFilter}>
                    <Trans>Add filter</Trans>
                </TertiaryButton>
            </Flexbox>
        </Flexbox>
    );
};

const JoinWithPreviousFormComponent = ({ index }: { index: number }): JSX.Element => {
    const { control } = useFormContext<AddDriverFormInputs>();

    if (index === 0) {
        return (
            <Flexbox height="100%" alignItems="top" paddingTop={'12px'}>
                <Text variant="body-semibold">
                    <Trans>Where</Trans>
                </Text>
            </Flexbox>
        );
    }

    return (
        <FieldSelectControlled
            required
            control={control}
            name={`driverDetails.filterFormula.${index}.joinWithPrevious`}
            FieldProps={{
                disableClearable: true,
                options: typeSafeObjectKeys(conditionTranslations),
                getOptionLabel: (option) => transEnum(option, conditionTranslations),
            }}
        />
    );
};

const FilterAttributeFormComponent = ({ index }: { index: number }): JSX.Element => {
    const { control } = useFormContext<AddDriverFormInputs>();

    // These are default options
    const options = typeSafeObjectKeys(attributeReferencePublicTranslations).map(getDefaultFilterInput);

    return (
        <FieldSelectControlled
            required
            control={control}
            name={`driverDetails.filterFormula.${index}.filter`}
            FieldProps={{
                disableClearable: true,
                options,
                getOptionLabel: (option) => transEnum(option.type, attributeReferencePublicTranslations),
                isOptionEqualToValue: (option, value) => option.type === value.type,
            }}
        />
    );
};

const FilterOperatorFormComponent = ({ index }: { index: number }): JSX.Element => {
    const { control } = useFormContext<AddDriverFormInputs>();
    const operatorType = useWatch({ control, name: `driverDetails.filterFormula.${index}.filter.operator.type` });

    switch (operatorType) {
        case 'enum':
            return (
                <FieldSelectControlled
                    required
                    control={control}
                    name={`driverDetails.filterFormula.${index}.filter.operator`}
                    FieldProps={{
                        disableClearable: true,
                        options: typeSafeObjectKeys(enumFilterOperatorsPublicTranslations).map(
                            (value): EnumOperator => ({ type: 'enum', value: value }),
                        ),
                        getOptionLabel: (option) => transEnum(option.value, enumFilterOperatorsPublicTranslations),
                        isOptionEqualToValue: (option, value) =>
                            option.type === value.type && option.value === value.value,
                    }}
                />
            );
        case 'number':
            return (
                <FieldSelectControlled
                    required
                    control={control}
                    name={`driverDetails.filterFormula.${index}.filter.operator`}
                    FieldProps={{
                        disableClearable: true,
                        options: typeSafeObjectKeys(numberFilterOperatorsPublicTranslations).map(
                            (value): NumberOperator => ({ type: 'number', value: value }),
                        ),
                        getOptionLabel: (option) => transEnum(option.value, numberFilterOperatorsPublicTranslations),
                        isOptionEqualToValue: (option, value) =>
                            option.type === value.type && option.value === value.value,
                    }}
                />
            );
        default:
            assertUnreachable(operatorType);
    }
};

const NumericFilterValueFormComponent = ({ index }: { index: number }): JSX.Element => {
    const { control } = useFormContext<AddDriverFormInputs>();

    return (
        <Grid container spacing={2}>
            <Grid item xs={4}>
                <FieldNumericControlled
                    required
                    min={0}
                    control={control}
                    name={`driverDetails.filterFormula.${index}.filter.value`}
                    FieldProps={{
                        fullWidth: true,
                    }}
                />
            </Grid>
        </Grid>
    );
};

const FilterValueFormComponent = ({ index, packages }: { index: number; packages: PackageOptions }): JSX.Element => {
    const { control } = useFormContext<AddDriverFormInputs>();
    const { type: valueType, value } = useWatch({ control, name: `driverDetails.filterFormula.${index}.filter` });

    switch (valueType) {
        case 'CustomPartType':
            return (
                <FieldSelectControlled
                    required
                    control={control}
                    name={`driverDetails.filterFormula.${index}.filter.value`}
                    FieldProps={{
                        disableClearable: true,
                        options: typeSafeObjectKeys(partTypePublicTranslations),
                        getOptionLabel: (option) => transEnum(option, partTypePublicTranslations),
                    }}
                />
            );
        case 'MaterialPrice':
            return (
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <FieldSelectControlled
                            required
                            control={control}
                            name={`driverDetails.filterFormula.${index}.filter.value.currency`}
                            FieldProps={{
                                disableClearable: true,
                                options: typeSafeObjectKeys(inputCurrenciesPublicTranslations),
                                getOptionLabel: (option) => transEnum(option, inputCurrenciesPublicTranslations),
                            }}
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <FieldNumericControlled
                            required
                            min={0}
                            control={control}
                            name={`driverDetails.filterFormula.${index}.filter.value.amount`}
                            FieldProps={{ fullWidth: true }}
                        />
                    </Grid>
                </Grid>
            );
        case 'OfferType':
            return (
                <FieldSelectControlled
                    required
                    control={control}
                    name={`driverDetails.filterFormula.${index}.filter.value`}
                    FieldProps={{
                        disableClearable: true,
                        options: typeSafeObjectKeys(offerTypePublicTranslations),
                        getOptionLabel: (option) => transEnum(option, offerTypePublicTranslations),
                    }}
                />
            );
        case 'Package':
            return (
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <FieldSelectControlled
                            required
                            control={control}
                            name={`driverDetails.filterFormula.${index}.filter.value`}
                            FieldProps={{
                                disableClearable: true,
                                options: typeSafeObjectKeys(packageMountingEnumTranslations).map(
                                    (mounting): PackageValue => ({
                                        mounting: mounting,
                                        packages: [],
                                    }),
                                ),
                                getOptionLabel: (option) => transEnum(option.mounting, packageMountingEnumTranslations),
                                isOptionEqualToValue: (option, value) => option.mounting === value.mounting,
                            }}
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <PackageFormComponent
                            index={index}
                            packages={packages}
                            currentValue={value}
                            key={`mounting-${index}-${value.mounting}`}
                        />
                    </Grid>
                </Grid>
            );
        case 'PartNumberOfPins':
            return <NumericFilterValueFormComponent index={index} />;
        case 'NumberOfApprovedPartOptionsWithIpns':
            return <NumericFilterValueFormComponent index={index} />;
        case 'PnpSide':
            return (
                <FieldSelectControlled
                    required
                    control={control}
                    name={`driverDetails.filterFormula.${index}.filter.value`}
                    FieldProps={{
                        autoComplete: false,
                        disableClearable: true,
                        options: typeSafeObjectKeys(sideOfPlacementPublicTranslations),
                        getOptionLabel: (option) => transEnum(option, sideOfPlacementPublicTranslations),
                    }}
                />
            );
        case 'Packaging':
            return (
                <>
                    <FieldSelectControlled
                        required
                        control={control}
                        name={`driverDetails.filterFormula.${index}.filter.value`}
                        FieldProps={{
                            autoComplete: false,
                            disableClearable: true,
                            options: typeSafeObjectKeys(packagingTranslations),
                            getOptionLabel: (option) => packagingTranslations[option]?.message ?? option,
                        }}
                    />
                </>
            );
        default:
            assertUnreachable(valueType);
    }
};

const PackageFormComponent = ({
    index,
    packages,
    currentValue,
}: {
    index: number;
    packages: PackageOptions;
    currentValue: PackageValue;
}): JSX.Element => {
    const { control } = useFormContext<AddDriverFormInputs>();
    const packageOptions = packages[currentValue.mounting];
    const sorted = packageOptions.sort(compareByString);
    if (packageOptions.length === 0) {
        return <></>;
    }

    return (
        <Flexbox gap={4} flexDirection={'column'}>
            <FieldMultiSelectControlled
                name={`driverDetails.filterFormula.${index}.filter.value.packages`}
                control={control}
                FieldProps={{
                    virtualized: true,
                    disableCloseOnSelect: true,
                    options: packageOptions,
                    getOptionLabel: (option) => option,
                    getOptionKey: (option) => option,
                    placeholder: currentValue.packages.length === 0 ? t`All` : undefined,
                    limitTags: 10,
                }}
            />
            <AdvancedPackageFilterMenu options={sorted} index={index} />
        </Flexbox>
    );
};
