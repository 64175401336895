import * as z from 'zod';
import { endpoint } from '../http/endpoint';
import { LcscCredentialsInputDTORuntype, LcscResponseBodyDTORuntype } from './lcscBackendTypes';

export const lcscEndpoints = {
    'GET /3rdparty/lcsc/credentials': endpoint({
        description: 'Returns credentials for lcsc integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: LcscResponseBodyDTORuntype,
    }),
    'POST /3rdparty/lcsc/credentials': endpoint({
        description: 'Uploads credentials for lcsc API integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: LcscCredentialsInputDTORuntype,
        responseBody: LcscResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/lcsc/credentials'],
    }),
    'PATCH /3rdparty/lcsc/credentials': endpoint({
        description: 'Updates credentials for lcsc API integratoin',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: LcscCredentialsInputDTORuntype,
        responseBody: LcscResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/lcsc/credentials'],
    }),
    'DELETE /3rdparty/lcsc/credentials': endpoint({
        description: 'Deletes existing credentials for lcsc API integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({ deleted: z.number() }),
        invalidates: ['GET /3rdparty/lcsc/credentials'],
    }),
};
