import * as z from 'zod';
import { CustomerDTORuntype } from '../customer';
import { UserDTORuntype } from '../user';

export enum UserType {
    Customer = 'Customer',
    Supplier = 'Supplier',
    Internal = 'Internal',
}

const ClientTypeRuntype = z.enum(['customer', 'test', 'trial', 'inactive']);

export interface OrganizationDTO extends z.infer<typeof OrganizationDTORuntype> {}
export const OrganizationDTORuntype = z.object({
    id: z.string(),
    name: z.string(),
    billing_address: z.string().nullable(),
    client_type: ClientTypeRuntype.nullable(),
    has_access_to_customer_portal: z.boolean(),
    has_access_to_source_to_contract: z.boolean(),
    has_full_manufacturing_access: z.boolean().optional(),
    has_customers: z.boolean(),
    is_closed_beta_tester: z.boolean(),
});

export interface CurrentUserDetailsDTO extends z.infer<typeof CurrentUserDetailsDTORuntype> {}
export const CurrentUserDetailsDTORuntype = z.object({
    user: UserDTORuntype,
    tenant: z.string(),
    all_tenants_for_user: z.array(z.string()),
    organization: OrganizationDTORuntype,
    customer: CustomerDTORuntype.nullable(),
    supplier: z.unknown().nullable(),
});

export interface VersionInformationDTO extends z.infer<typeof VersionInformationDTORuntype> {}
export const VersionInformationDTORuntype = z.object({
    epibator_sha: z.string(),
    luminovo_version: z.string(),
});
