import { ColumnTagsSheetsState } from '@/modules/DesignItemDetails/components/AutocompleteColumnTags/types';
import { plural, t } from '@lingui/macro';
import { throwErrorUnlessProduction } from '@luminovo/commons';
import { colorSystem, Select, Text } from '@luminovo/design-system';
import { BomScreeningSheet, ScreenerHandlerOutput } from '@luminovo/http-client';
import { MenuItem, SelectChangeEvent } from '@mui/material';
import { useCallback } from 'react';
import { BomImporterSidebarContainer } from './BomImporterSidebarContainer';
export const SheetSelect = ({
    sheetIndex,
    sheetType,
    title,
    bomScreeningOutput,
    tagsState,
    assignSheet,
}: {
    sheetIndex: number | null;
    sheetType: 'BOM' | 'AML';
    title: string;
    tagsState: ColumnTagsSheetsState;
    bomScreeningOutput: ScreenerHandlerOutput;
    assignSheet: (sheetIndex: number | null, sheetType: 'BOM' | 'AML') => void;
}): JSX.Element => {
    const sheets = bomScreeningOutput.sheets.map((sheet) => sheet.sheet_name);
    const bomScreeningSheet: BomScreeningSheet | null =
        sheetIndex !== null ? bomScreeningOutput.sheets[sheetIndex] : null;
    const excelLines = bomScreeningSheet?.excel_lines ?? [];
    const numberOfLines = excelLines.length;

    const handleChange = (event: SelectChangeEvent<unknown>) => {
        const value = event.target.value;

        // Handle unselection (only for AML)
        if (value === '') {
            if (sheetType !== 'AML') {
                return throwErrorUnlessProduction('Only AML sheet can be deselected');
            }
            assignSheet(null, sheetType);
            return;
        }

        if (typeof value !== 'number') {
            return throwErrorUnlessProduction('selected sheet index should be a number');
        }

        assignSheet(value, sheetType);
    };

    const isOptionDisabled = useCallback(
        (sheetType: 'BOM' | 'AML', sheetIndex: number) => {
            // A sheet can't be both BOM and AML at the same time
            // So we check if the sheet is already assigned to the other type
            return (
                (sheetType === 'BOM' && sheetIndex === tagsState.amlSheetIndex) ||
                (sheetType === 'AML' && sheetIndex === tagsState.bomSheetIndex)
            );
        },
        [tagsState.amlSheetIndex, tagsState.bomSheetIndex],
    );

    if (sheets.length === 1) {
        return (
            <Text>
                {plural(numberOfLines, {
                    one: `We found ${numberOfLines} line in the Excel file`,
                    other: `We found ${numberOfLines} lines in the Excel file`,
                })}
            </Text>
        );
    }
    if (sheets.length > 1) {
        return (
            <BomImporterSidebarContainer>
                <Text variant="h5" color={colorSystem.neutral[9]}>
                    {title}
                </Text>
                <Select id="sheetSelect" onChange={handleChange} value={sheetIndex ?? ''} size="small">
                    {sheetType === 'AML' && (
                        <MenuItem value="">
                            <Text>{t`No sheet selected`}</Text>
                        </MenuItem>
                    )}
                    {sheets.map((sheet: string, i: number) => {
                        return (
                            <MenuItem key={i} value={i} disabled={isOptionDisabled(sheetType, i)}>
                                {sheet}
                            </MenuItem>
                        );
                    })}
                </Select>
                <Text variant="body-small">
                    {plural(numberOfLines, {
                        one: `We found ${numberOfLines} line`,
                        other: `We found ${numberOfLines} lines`,
                    })}
                </Text>
            </BomImporterSidebarContainer>
        );
    }
    return <></>;
};
