import * as z from 'zod';
import { endpoint } from '../http/endpoint';
import {
    DemandScenarioCreationRuntype,
    DemandScenarioRuntype,
    DemandScenarioUpdateRuntype,
} from './demandScenarioBackendTypes';

export const demandScenarioEndpoints = {
    'PATCH /demand-scenarios/:demandScenarioId': endpoint({
        description: 'Updates a single demand scenario',
        pathParams: z.object({ demandScenarioId: z.string() }),
        queryParams: z.undefined(),
        requestBody: DemandScenarioUpdateRuntype,
        responseBody: z.object({
            data: DemandScenarioRuntype,
        }),
        invalidates: ['GET /rfqs', 'GET /rfqs/:rfqId'],
    }),
    'DELETE /demand-scenarios/:demandScenarioId': endpoint({
        description: 'Deletes a single demand scenario',
        pathParams: z.object({ demandScenarioId: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({
            deleted: z.number(),
        }),
        invalidates: ['GET /rfqs', 'GET /rfqs/:rfqId'],
    }),
    'POST /demand-scenarios': endpoint({
        description: 'Creates a single demand scenario',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: DemandScenarioCreationRuntype,
        responseBody: z.object({ data: DemandScenarioRuntype }),
        invalidates: ['GET /rfqs', 'GET /rfqs/:rfqId'],
    }),
};
