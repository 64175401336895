import * as z from 'zod';
import { EndpointRegistry } from '../http';
import { endpoint } from '../http/endpoint';
import { QuoteTrackingDTORuntype, QuoteTrackingStateRuntype } from './quoteTrackingBackendTypes';

// Group 1: Quote Tracking List endpoints
type QuoteTrackingListEndpoints = {
    'GET /rfqs/:rfqId/quote-tracking': {
        description: string;
        pathParams: z.ZodObject<{ rfqId: z.ZodString }>;
        queryParams: z.ZodUndefined;
        requestBody: z.ZodUndefined;
        responseBody: z.ZodObject<{ items: z.ZodArray<typeof QuoteTrackingDTORuntype> }>;
        invalidates?: (keyof EndpointRegistry)[];
        removes?: (keyof EndpointRegistry)[];
        rootUrl?: string;
        handleResponse?: (
            response: Response,
            registeredEndpoint: string,
        ) => Promise<z.infer<z.ZodObject<{ items: z.ZodArray<typeof QuoteTrackingDTORuntype> }>>>;
    };
};

const quoteTrackingListEndpoints: QuoteTrackingListEndpoints = {
    'GET /rfqs/:rfqId/quote-tracking': endpoint({
        description: 'Returns a list of quote tracking items of the RfQ',
        pathParams: z.object({ rfqId: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({ items: z.array(QuoteTrackingDTORuntype) }),
    }),
};

// Group 2: Quote Tracking Item endpoints
type QuoteTrackingItemEndpoints = {
    'GET /quote-tracking/:id': {
        description: string;
        pathParams: z.ZodObject<{ id: z.ZodString }>;
        queryParams: z.ZodUndefined;
        requestBody: z.ZodUndefined;
        responseBody: z.ZodObject<{ data: typeof QuoteTrackingDTORuntype }>;
        invalidates?: (keyof EndpointRegistry)[];
        removes?: (keyof EndpointRegistry)[];
        rootUrl?: string;
        handleResponse?: (
            response: Response,
            registeredEndpoint: string,
        ) => Promise<z.infer<z.ZodObject<{ data: typeof QuoteTrackingDTORuntype }>>>;
    };
};

const quoteTrackingItemEndpoints: QuoteTrackingItemEndpoints = {
    'GET /quote-tracking/:id': endpoint({
        description: 'Returns a single quote tracking item',
        pathParams: z.object({ id: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({ data: QuoteTrackingDTORuntype }),
    }),
};

// Group 2.2: Quote Tracking Item endpoints
const quoteTrackinnPatchEndpoints = {
    'PATCH /quote-tracking/:quoteTrackingId': endpoint({
        description: 'Updates a quote tracking',
        pathParams: z.object({ quoteTrackingId: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.object({ state: QuoteTrackingStateRuntype }),
        responseBody: z.unknown(),
        invalidates: ['GET /rfqs/:rfqId/quote-tracking', 'GET /quote-tracking/:id'],
    }),
};

// Group 3: Quote Tracking Offers endpoints
const quoteTrackingOffersEndpoints = {
    'DELETE /quote-tracking/:quoteTrackingId/offers': endpoint({
        description: 'Deletes all offers connected to a quote tracking item.',
        pathParams: z.object({ quoteTrackingId: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({ deleted: z.number() }),
        invalidates: ['GET /rfqs/:rfqId/quote-tracking', 'GET /quote-tracking/:id'],
    }),
};

// Group 4: Quote Tracking Files endpoints
const quoteTrackingFilesEndpoints = {
    'GET /quote-tracking/:id/additional-files/upload-link': endpoint({
        description: 'Returns an Azure url to upload the additional files given an quote tracking',
        pathParams: z.object({ id: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({
            data: z.object({
                url: z.string(),
            }),
        }),
        invalidates: ['GET /rfqs/:rfqId/quote-tracking', 'GET /quote-tracking/:id'],
    }),

    'DELETE /quote-tracking/:id/additional-files': endpoint({
        description: 'Deletes a additional file given its file name and quote tracking id',
        pathParams: z.object({ id: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.object({ file_name: z.string() }),
        responseBody: z.string(),
        invalidates: ['GET /rfqs/:rfqId/quote-tracking', 'GET /quote-tracking/:id'],
    }),
};

// Define types for each endpoint group
type QuoteTrackingPatchEndpoints = typeof quoteTrackinnPatchEndpoints;
type QuoteTrackingOffersEndpoints = typeof quoteTrackingOffersEndpoints;
type QuoteTrackingFilesEndpoints = typeof quoteTrackingFilesEndpoints;

// Create an interface that extends all endpoint types
interface QuoteTrackingEndpointsRegistry
    extends QuoteTrackingListEndpoints,
        QuoteTrackingItemEndpoints,
        QuoteTrackingPatchEndpoints,
        QuoteTrackingOffersEndpoints,
        QuoteTrackingFilesEndpoints {}

// Merge all endpoint objects into a single object
export const quoteTrackingEndpoints: QuoteTrackingEndpointsRegistry = {
    ...quoteTrackingListEndpoints,
    ...quoteTrackingItemEndpoints,
    ...quoteTrackinnPatchEndpoints,
    ...quoteTrackingOffersEndpoints,
    ...quoteTrackingFilesEndpoints,
};
