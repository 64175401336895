import { Trans, t } from '@lingui/macro';
import { isPresent } from '@luminovo/commons';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    FieldTextControlled,
    Flexbox,
    Message,
    Text,
    useNavigate,
} from '@luminovo/design-system';
import { RfqLabel } from '@luminovo/manufacturing-core';
import { useFormContext } from 'react-hook-form';
import { useDialogContext } from '../../../components/contexts/ModalContext';
import { FormContainer } from '../../../components/formLayouts/FormContainer';
import { SubmitButton } from '../../../components/formLayouts/SubmitButton';
import { useHttpQuery } from '../../../resources/http/useHttpQuery';
import { useHttpMutation } from '../../../resources/mutation/useHttpMutation';
import { route } from '../../../utils/routes';
import { requiredErrorMessage } from '../../../utils/yupValidationUtils';
const SalesOrdersInRfq = ({ rfqId }: { rfqId: string }) => {
    const { data } = useHttpQuery('GET /order-management/orders', {
        queryParams: {
            rfq_id: rfqId,
        },
    });
    if (!data?.data) {
        return null;
    }
    return (
        <Message
            attention="high"
            variant="yellow"
            size="small"
            message={t`There is a sales order associated with this RfQ. Deleting this RfQ will also delete the associated sales order.`}
        />
    );
};

export const useDeleteRfQDialog = () => {
    const { setDialog, closeDialog } = useDialogContext();

    return {
        openDialog: (rfqId: string) => {
            setDialog(
                <Dialog open={true} maxWidth="xs" style={{ margin: '24px' }}>
                    <DialogTitle title={t`Delete RfQ`} onClose={closeDialog} />
                    <DialogContent>
                        <DeleteRfqForm rfqId={rfqId} />
                    </DialogContent>
                </Dialog>,
            );
        },
        closeDialog,
    };
};

interface DeleteRfqFormProps {
    typedRfqName: string | null;
}

export const DeleteRfqForm = ({ rfqId }: { rfqId: string }): JSX.Element => {
    const navigate = useNavigate();

    const { mutateAsync } = useHttpMutation('DELETE /rfqs/:rfqId', {
        enableInvalidateAllQueries: false,
        snackbarMessage: t`RfQ deleted successfully`,
        onSuccess: () => navigate(route('/rfqs')),
    });

    const { data: rfq } = useHttpQuery('GET /rfqs/:rfqId', { pathParams: { rfqId } });

    const defaultValues = {
        typedRfqName: '',
    };

    const onSubmit = async (_: DeleteRfqFormProps) => {
        return await mutateAsync({ pathParams: { rfqId: rfqId } });
    };
    if (!rfq) {
        return <></>;
    }
    return (
        <FormContainer defaultValues={defaultValues} onSubmit={onSubmit}>
            <Flexbox flexDirection="column" height="100%" gap="24px">
                <SalesOrdersInRfq rfqId={rfqId} />
                <DeleteRfqFormInner rfqName={rfq.data.name} emsInternalNumber={rfq.data.ems_internal_number} />
                <Flexbox flexDirection="row" justifyContent="end">
                    <SubmitButton label={t`Yes, delete`} submitButtonVariant="destructive" />
                </Flexbox>
            </Flexbox>
        </FormContainer>
    );
};

const DeleteRfqFormInner = ({
    rfqName,
    emsInternalNumber,
}: {
    rfqName: string;
    emsInternalNumber: string | null;
}): JSX.Element => {
    const { control } = useFormContext<DeleteRfqFormProps>();
    return (
        <Flexbox flexDirection={'column'} gap={28}>
            <Text variant="body">
                <Trans>You are about to delete RfQ:</Trans>
                &nbsp;
                <RfqLabel rfqName={rfqName} emsInternalNumber={emsInternalNumber ?? undefined} />
            </Text>
            <Flexbox flexDirection={'column'}>
                <Text variant="body-semibold">
                    {/* May not be rendered correctly when using start:vite due to the quotations. It should be safe for production though https://gitlab.com/luminovo/product/epibator/-/merge_requests/1422#note_621737213   */}
                    <Trans>Please type the following in the box below to confirm:</Trans>
                </Text>
                <Text variant="code" style={{ whiteSpace: 'pre-wrap' }}>
                    {rfqName}
                </Text>
            </Flexbox>
            <FieldTextControlled
                required
                name={'typedRfqName'}
                control={control}
                validate={(value: unknown) => {
                    if (!isPresent(value)) {
                        return requiredErrorMessage();
                    }
                    if (value !== rfqName) {
                        return t`The input does not match with the RfQ name`;
                    }
                }}
            />
        </Flexbox>
    );
};
