import * as z from 'zod';

/**
 * A Driver calculation follows this format
 * Condition: Attribute Reference, operator, Attribute reference option
 */

// Condition
export const ConditionValuesRuntype = z.union([z.literal('Or'), z.literal('And')]);
export type ConditionValues = z.infer<typeof ConditionValuesRuntype>;

// Attribute references
export const NumberAttributeRuntype = z.union([
    z.literal('PartNumberOfPins'),
    z.literal('NumberOfApprovedPartOptionsWithIpns'),
]);
export const MonetaryAttributeRuntype = z.literal('MaterialPrice');
export const EnumAttributeReferenceRuntype = z.union([
    z.literal('Package'),
    z.literal('CustomPartType'),
    z.literal('OfferType'),
    z.literal('PnpSide'),
    z.literal('Packaging'),
]);

// Attribute reference
export const AttributeReferenceRuntype =
    NumberAttributeRuntype.or(MonetaryAttributeRuntype).or(EnumAttributeReferenceRuntype);

export type AttributeReference = z.infer<typeof AttributeReferenceRuntype>;
export const AttributeReferenceForWarningsRuntype = z.union([
    z.literal('PartPackageMounting'),
    z.literal('CustomPartType'),
    z.literal('PartNumberOfPins'),
    z.literal('NumberOfApprovedPartOptionsWithIpns'),
    z.literal('PartPackageName'),
    z.literal('PartPackage'),
]);
export type AttributeReferenceForWarnings = z.infer<typeof AttributeReferenceForWarningsRuntype>;

// Operator
export const EnumFilterOperatorRuntype = z.union([z.literal('Is'), z.literal('IsNot')]);
export const NumberFilterOperatorRuntype = z.union([
    z.literal('Lesser'),
    z.literal('LesserOrEqual'),
    z.literal('Equal'),
    z.literal('NotEqual'),
    z.literal('GreaterOrEqual'),
    z.literal('Greater'),
]);
export type EnumFilterOperator = z.infer<typeof EnumFilterOperatorRuntype>;

export type NumberFilterOperator = z.infer<typeof NumberFilterOperatorRuntype>;

// Attribute reference options
export const MountingPublicRuntype = z.union([z.literal('SMT'), z.literal('THT'), z.literal('Other')]);
export type MountingPublic = z.infer<typeof MountingPublicRuntype>;

export const PartTypeRuntype = z.union([
    z.literal('PCB'),
    z.literal('Mechanical'),
    z.literal('Cable'),
    z.literal('Label'),
    z.literal('Plastic'),
    z.literal('Packaging'),
    z.literal('Other'),
]);
export type PartTypePublic = z.infer<typeof PartTypeRuntype>;

export const OfferTypeRuntype = z.union([
    z.literal('Inventory'),
    z.literal('CustomPart'),
    z.literal('Consigned'),
    z.literal('Other'),
]);
export type OfferTypePublic = z.infer<typeof OfferTypeRuntype>;
