import { assertUnreachable } from '@luminovo/commons';
import { DetailedCustomPartOfferData, PriceRadarStatusKind } from '@luminovo/http-client';
import { convertOfferErrorToText } from './convertOfferErrorToText';
import { PcbSupplierOffer } from './pcbSupplierOfferType';

const getOfferStatus = (offer: DetailedCustomPartOfferData): 'green' | 'yellow' | 'red' | 'neutral' => {
    switch (offer.status.type) {
        case PriceRadarStatusKind.OffersAvailable:
            return 'green';
        case PriceRadarStatusKind.NoOffersReceived:
        case PriceRadarStatusKind.CustomStackup:
        case PriceRadarStatusKind.StackratePricingBreaks:
            return 'yellow';
        case PriceRadarStatusKind.NeedsRefreshForOffers:
            return 'neutral';
        case PriceRadarStatusKind.Pending:
        case PriceRadarStatusKind.MissingCredentials:
        case PriceRadarStatusKind.InvalidCredentials:
        case PriceRadarStatusKind.ApiNotSetUp:
        case PriceRadarStatusKind.InvalidSpecification:
        case PriceRadarStatusKind.PanelError:
        case PriceRadarStatusKind.SupplierApiError:
            return 'red';
        default:
            assertUnreachable(offer.status);
    }
};

export const convertOffersToSupplierData = (
    offers: DetailedCustomPartOfferData[],
    approvedSupplierIds: string[],
): PcbSupplierOffer[] => {
    return offers.map((offer) => {
        return {
            name: offer.supplier.name,
            id: offer.supplier.id,
            status: getOfferStatus(offer),
            region: offer.stock_location,
            error: convertOfferErrorToText(offer.status),
            approved: approvedSupplierIds.includes(offer.supplier.id),
        };
    });
};
