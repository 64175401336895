import { t, Trans } from '@lingui/macro';
import { isEqual } from '@luminovo/commons';
import { colorSystem, Flexbox, Tag, Text, Tooltip } from '@luminovo/design-system';
import { BomItemApprovalStatus, BomItemIssue, DesignItemOriginTypes } from '@luminovo/http-client';
import { Box, CircularProgress, styled, Typography } from '@mui/material';
import { useIsMutating } from '@tanstack/react-query';
import * as React from 'react';
import { iconForStatus } from '../../../components/Icons/icons';
import { BomItem } from '../../../resources/designItem/bomItemFrontendTypes';
import { isPcbDesignItem } from '../../../resources/designItem/designItemFunctions';
import { themeLuminovo } from '../../../themes';
import { alphaNumericalStringSort, capitalizeFirstLetter } from '../../../utils/stringFunctions';
import { assertUnreachable } from '../../../utils/typingUtils';
import { AssemblyTableData, BomItemTableData, ModuleTableData } from '../../Bom/components/ModuleTableData';
import { getActiveWarnings } from '../hasActiveWarnings';
import { getWarningsLabelForTooltip } from './getActiveWarningsLabelForTooltip';
import { updateDesignItemsQueryKey } from './updateDesignItemsQueryKey';

const BomLinePreviewContainer = styled(Box)({
    cursor: 'pointer',
    boxSizing: 'border-box',
    border: `1px solid transparent`,
    '&:hover': {
        background: colorSystem.neutral[0],
    },
    background: themeLuminovo.palette.background.paper,
    width: '100%',
    height: '100%',
    display: 'grid',
    gap: 8,
    gridTemplateColumns: 'auto 1fr',
    padding: 8,
});

function getBomLinePreviewStyles({
    isSelected,
    isMutating,
    style,
}: {
    isSelected: boolean;
    isMutating: boolean;
    style?: React.CSSProperties;
}) {
    switch (isSelected) {
        case true:
            return {
                border: `2px solid ${colorSystem.primary[4]}`,
                borderRadius: 4,
                ...style,
            };
        case false:
            return {
                cursor: isMutating ? 'progress' : 'pointer',
                ...style,
            };
    }
}

function BomItemExcelCpns({ bomItem }: { bomItem: BomItem }) {
    const bomCpns = bomItem.bomCpns;
    const maximumCpnsToShow = 1;

    return (
        <Flexbox maxWidth="100px" alignItems="center">
            <Text
                variant="body-small"
                color={colorSystem.neutral[6]}
                style={{
                    whiteSpace: 'nowrap',
                }}
                showEllipsis
            >
                {bomCpns.length > 0 ? bomCpns.slice(0, maximumCpnsToShow).join(', ') : '-'}
            </Text>
            {bomCpns.length > maximumCpnsToShow && (
                <Tooltip
                    variant="white"
                    title={
                        <Flexbox flexDirection="column" gap="8px" padding="4px">
                            {bomCpns.map((cpn, i) => (
                                <Text variant="body-small" key={i}>
                                    {cpn}
                                </Text>
                            ))}
                        </Flexbox>
                    }
                >
                    <Text variant="body-small" style={{ marginLeft: 4 }} color={colorSystem.neutral[8]}>
                        +{bomCpns.length - maximumCpnsToShow}
                    </Text>
                </Tooltip>
            )}
        </Flexbox>
    );
}

function WarningTags({ issues }: { issues: BomItemIssue[] }) {
    const warnings = getActiveWarnings({ issues });
    const tooltipLabel = capitalizeFirstLetter(getWarningsLabelForTooltip({ issues }));

    if (warnings.length === 0) {
        return null;
    }
    if (warnings.length === 1) {
        return <Tag color={'yellow'} label={capitalizeFirstLetter(tooltipLabel)} attention="low" />;
    }

    return (
        <Tooltip title={tooltipLabel}>
            <Tag color="yellow" label={t`${warnings.length} Warnings`} attention="low" />
        </Tooltip>
    );
}

const ApprovalStatus = React.memo(({ isMutating, status }: { isMutating: boolean; status: BomItemApprovalStatus }) => {
    return isMutating ? <CircularProgress size={'20px'} /> : iconForStatus({ status });
});

function LargeBomItemPreview({
    selectedBomItemId,
    bomItem,
    style,
    onClick,
}: {
    selectedBomItemId?: string[] | string;
    bomItem: BomItemTableData;
    style?: React.CSSProperties;
    onClick: (module: BomItemTableData) => void;
}) {
    const designators = bomItem.designator;
    const isSelected = isEqual(selectedBomItemId, bomItem.id);
    const isMutating = useIsMutating({ mutationKey: updateDesignItemsQueryKey(bomItem) }) > 0;

    const handleItemClick = React.useCallback(() => {
        if (!isMutating) {
            onClick(bomItem);
        }
    }, [isMutating, onClick, bomItem]);

    return (
        <Flexbox
            padding="8px"
            gap="4px"
            onClick={handleItemClick}
            flexDirection="column"
            style={getBomLinePreviewStyles({ isSelected, isMutating, style })}
        >
            <Flexbox justifyContent="space-between">
                <Flexbox gap="8px">
                    <ApprovalStatus isMutating={isMutating} status={bomItem.approvalStatus} />
                    <BomItemExcelCpns bomItem={bomItem} />
                </Flexbox>
                <TagsContainer bomItem={bomItem} />
            </Flexbox>
            <Flexbox justifyContent="space-between">
                <DesignatorsContainer designators={designators} maxWidth="200px" paddingLeft="24px" />
            </Flexbox>
        </Flexbox>
    );
}

function SmallBomItemPreview({
    selectedBomItemId,
    bomItem,
    style,
    onClick,
}: {
    selectedBomItemId?: string[] | string;
    bomItem: BomItemTableData;
    style?: React.CSSProperties;
    onClick: (module: BomItemTableData) => void;
}) {
    const designators = bomItem.designator;
    const isSelected = isEqual(selectedBomItemId, bomItem.id);
    const isMutating = useIsMutating({ mutationKey: updateDesignItemsQueryKey(bomItem) }) > 0;

    const handleItemClick = React.useCallback(() => {
        if (!isMutating) {
            onClick(bomItem);
        }
    }, [isMutating, onClick, bomItem]);

    return (
        <Flexbox
            padding="8px"
            gap="4px"
            alignItems="center"
            onClick={handleItemClick}
            style={getBomLinePreviewStyles({ isSelected, isMutating, style })}
        >
            <ApprovalStatus isMutating={isMutating} status={bomItem.approvalStatus} />
            <Flexbox width="100%" justifyContent="space-between" flexWrap="nowrap" alignItems="center">
                <DesignatorsContainer designators={designators} />
                <TagsContainer bomItem={bomItem} />
            </Flexbox>
        </Flexbox>
    );
}

function SubAssemblyPreview({
    subAssembly,
    selectedModuleId,
    style,
    onClick,
}: {
    subAssembly: AssemblyTableData;
    selectedModuleId: string[] | string | undefined;
    style?: React.CSSProperties;
    onClick: (module: AssemblyTableData) => void;
}) {
    const subAssemblyType = subAssembly.type;
    const isSelected = selectedModuleId === subAssembly.id;

    return (
        <BomLinePreviewContainer
            onClick={() => onClick(subAssembly)}
            style={
                isSelected
                    ? {
                          border: `2px solid ${colorSystem.primary[3]}`,
                          borderRadius: 4,
                          ...style,
                      }
                    : { cursor: 'pointer', ...style }
            }
        >
            {iconForStatus({ status: subAssembly.approvalStatus })}

            <Typography variant={'h4'} style={{ color: colorSystem.neutral[6] }}>
                {subAssemblyType.toUpperCase()}
            </Typography>
            <span />

            <Typography variant={'subtitle1'} style={{ color: colorSystem.neutral[6] }}>
                {subAssembly.designator}
            </Typography>
        </BomLinePreviewContainer>
    );
}

export function BomLinePreview({
    module,
    selectedModuleId,
    style,
    onClick,
    hasAnyBomItemWithCpnsFromBom,
}: {
    module: ModuleTableData;
    selectedModuleId?: ModuleTableData['id'];
    style?: React.CSSProperties;
    onClick: (module: ModuleTableData) => void;
    hasAnyBomItemWithCpnsFromBom: boolean;
}) {
    if (module.moduleType === 'assembly') {
        return (
            <SubAssemblyPreview
                onClick={onClick}
                style={style}
                subAssembly={module}
                selectedModuleId={selectedModuleId}
            />
        );
    }
    if (module.moduleType === 'bomItem') {
        if (hasAnyBomItemWithCpnsFromBom) {
            return (
                <LargeBomItemPreview
                    onClick={onClick}
                    style={style}
                    bomItem={module}
                    selectedBomItemId={selectedModuleId}
                />
            );
        }
        return (
            <SmallBomItemPreview
                onClick={onClick}
                style={style}
                bomItem={module}
                selectedBomItemId={selectedModuleId}
            />
        );
    }
    assertUnreachable(module);
}

const DesignatorsContainer = ({
    designators,
    maxWidth = '112px',
    paddingLeft,
}: {
    designators: string[];
    maxWidth?: string;
    paddingLeft?: string;
}) => {
    return (
        <Flexbox paddingLeft={paddingLeft}>
            <Flexbox maxWidth={maxWidth}>
                <Text variant="h5" showEllipsis>
                    {Array.from(designators).sort(alphaNumericalStringSort).join(', ')}
                </Text>
            </Flexbox>

            {designators.length === 0 && (
                <Text variant="h5">
                    <Trans>No designators</Trans>
                </Text>
            )}
        </Flexbox>
    );
};

const TagsContainer = ({ bomItem }: { bomItem: BomItem }) => {
    const isPcb = isPcbDesignItem(bomItem);
    const isManual = bomItem.origin.type === DesignItemOriginTypes.Manual;

    return (
        <Flexbox
            maxWidth="100px"
            alignItems="center"
            flexWrap="nowrap"
            justifyContent="flex-end"
            gap={2}
            overflow="hidden"
        >
            {isPcb && (
                <Tooltip title={t`This design item was automatically generated for you from the uploaded PCB.`}>
                    <Tag color="green" label={t`PCB`} attention="low" />
                </Tooltip>
            )}
            {isManual && (
                <Tooltip title={t`Bom item manually added`}>
                    <Tag color="neutral" label={t`M`} attention="low" />
                </Tooltip>
            )}
            {bomItem.isConsigned && <Tag color="green" label={t`Consigned`} attention="low" showEllipsis />}
            {!bomItem.doNotPlace && <WarningTags issues={bomItem.issues} />}
        </Flexbox>
    );
};
