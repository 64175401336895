import { http } from '../../../resources/http';

export const getPartIdForMPN = async ({ mpn, token }: { mpn: string; token: string }): Promise<string> => {
    const response = await http(
        'POST /parts/library/search',
        {
            requestBody: {
                global_filter: mpn,
                page_size: 50,
                filters: [],
                sorts: [],
                search_external_apis: true,
                rfq_context: 'OutsideRfQ',
                page_params: undefined,
            },
        },
        token,
    );
    const firstPart = response.page[0];
    if (!firstPart) {
        throw new Error('No part found for mpn: ' + mpn);
    }
    return firstPart.id;
};
