import { useCommunicationsDrawer, ViewCommentsButton } from '@/components/CommentsDrawer';
import { useDrawerContext } from '@/components/contexts/ModalContext';
import { useDownloadQuoteRequestExcel } from '@/resources/export/exportHandler';
import { useHttpQuery } from '@/resources/http/useHttpQuery';
import { useHttpMutation } from '@/resources/mutation/useHttpMutation';
import { t, Trans } from '@lingui/macro';
import { formatRelativeTime, isPresent } from '@luminovo/commons';
import {
    MenuButton,
    MenuItem,
    PrimaryButton,
    RightBoxDrawer,
    SecondaryButton,
    SecondaryIconButton,
    Text,
    Toolbar,
    Tooltip,
    useBackNavigation,
    useConfirmationDialog,
    useNavigate,
    XlsIcon,
} from '@luminovo/design-system';
import { EmailEventStatus, QuoteRequestStatus } from '@luminovo/http-client';
import { RichTextEditor } from '@luminovo/rich-text-editor';
import * as icons from '@mui/icons-material';
import { Delete, NotificationsActiveRounded, UploadFileRounded } from '@mui/icons-material';
import { Box, Collapse, Divider } from '@mui/material';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { PageLayoutCollapsibleSidebar } from '../../../../../components/PageLayoutCollapsibleSidebar';
import { useHasAccessToPdfImporter } from '../../../../../utils/featureFlags';
import { route, UrlParams } from '../../../../../utils/routes';
import {
    useQuoteRequestLineItemsByQuoteRequest,
    useSuspendedQuoteRequest,
} from '../../../../Negotiations/hooks/negotiationHandlers';
import { NavigationSidebarSourcing } from '../../NavigationSidebarSourcing';
import { QuoteRequestDraftView } from './QuoteRequestDraftView';
import { QuoteRequestSubmittedView } from './QuoteRequestSubmittedView';

function ButtonComments({ quoteRequestId }: { quoteRequestId: string }) {
    const { openDrawer } = useCommunicationsDrawer({
        threads: [
            {
                commentType: 'QuoteRequest',
                category: 'Internal',
                typeIds: quoteRequestId,
            },
            {
                commentType: 'QuoteRequest',
                category: 'Public',
                typeIds: quoteRequestId,
            },
        ],
    });

    return (
        <ViewCommentsButton
            size={'medium'}
            eventEntity={{ type: 'QuoteRequest', data: quoteRequestId }}
            onClick={() => openDrawer()}
        />
    );
}

function ButtonDeleteQuoteRequest({
    quoteRequestId,
    rfqId,
    negotiationId,
}: {
    quoteRequestId: string;
    rfqId: string;
    negotiationId: number;
}) {
    const { data: quoteRequest } = useSuspendedQuoteRequest(quoteRequestId);
    const { navigateBack } = useBackNavigation();

    const { mutateAsync, isPending } = useHttpMutation('DELETE /quote-request/:id', {
        snackbarMessage: t`Quote request deleted`,
        onSuccess: () =>
            navigateBack({
                defaultPath: route(`/rfqs/:rfqId/sourcing/negotiations/:negotiationId/quote-requests`, {
                    rfqId,
                    negotiationId,
                }),
            }),
    });

    const { modal, setOpen } = useConfirmationDialog({
        title: t`Delete quote request`,
        description: t`Are you sure you want to delete this quote request? This cannot be undone.`,
        onConfirmation: () => mutateAsync({ pathParams: { id: quoteRequestId } }),
    });

    const shouldShow: Record<QuoteRequestStatus, boolean> = {
        [QuoteRequestStatus.NotSent]: true,
        [QuoteRequestStatus.Sent]: false,
        [QuoteRequestStatus.Received]: false,
        [QuoteRequestStatus.Overdue]: false,
        [QuoteRequestStatus.Discarded]: false,
    };

    return (
        <>
            {modal}
            <Tooltip title={t`Delete this quote request`} placement="left">
                <MenuItem
                    variant="destructive"
                    label={t`Delete quote request`}
                    disabled={!shouldShow[quoteRequest.status]}
                    startIcon={<Delete />}
                    isLoading={isPending}
                    onClick={() => setOpen(true)}
                />
            </Tooltip>
        </>
    );
}

function ButtonDeleteAllOffers({ quoteRequestId }: { quoteRequestId: string }) {
    const { mutateAsync, isPending } = useHttpMutation('DELETE /quote-request/:id/offers', {
        snackbarMessage: t`Offers deleted`,
    });

    const { modal, setOpen } = useConfirmationDialog({
        title: t`Delete all offers`,
        description: t`Are you sure you want to delete all offers from this quote request? This cannot be undone.`,
        onConfirmation: () => mutateAsync({ pathParams: { id: quoteRequestId } }),
    });

    return (
        <>
            {modal}
            <Tooltip title={t`Delete all offers from this quote request without changing its status`}>
                <MenuItem
                    label={t`Delete all offers`}
                    startIcon={<Delete />}
                    isLoading={isPending}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setOpen(true);
                    }}
                />
            </Tooltip>
        </>
    );
}

function ButtonDownloadQuoteRequestExcel({ quoteRequestId }: { quoteRequestId: string }) {
    const { data: quoteRequest } = useSuspendedQuoteRequest(quoteRequestId);
    const { mutateAsync, isLoading } = useDownloadQuoteRequestExcel(quoteRequestId);

    const shouldShow: Record<QuoteRequestStatus, boolean> = {
        [QuoteRequestStatus.NotSent]: true,
        [QuoteRequestStatus.Sent]: true,
        [QuoteRequestStatus.Received]: true,
        [QuoteRequestStatus.Overdue]: true,
        [QuoteRequestStatus.Discarded]: true,
    };

    if (!shouldShow[quoteRequest.status]) {
        return null;
    }

    return (
        <Tooltip title={t`Download the quote request as an Excel file`}>
            <SecondaryButton
                size="medium"
                startIcon={<XlsIcon />}
                isLoading={isLoading}
                onClick={async () => {
                    await mutateAsync();
                }}
            >
                {t`Download quote request`}
            </SecondaryButton>
        </Tooltip>
    );
}

function ButtonImportQuoteRequests({ quoteRequestId, rfqId }: { quoteRequestId: string; rfqId: string }) {
    const navigate = useNavigate();
    const { data: quoteRequest } = useSuspendedQuoteRequest(quoteRequestId);
    const { data: quoteRequestLineItems } = useQuoteRequestLineItemsByQuoteRequest(quoteRequestId);
    const hasOffers = quoteRequestLineItems.some((lineItem) => isPresent(lineItem.received_offer));

    const { mutateAsync, isPending } = useHttpMutation('DELETE /quote-request/:id/offers', {
        snackbarMessage: t`Offers deleted`,
        onSuccess: () => navigate(route(`/rfqs/:rfqId/sourcing/negotiations/quote-requests/importer`, { rfqId })),
    });

    const { modal, setOpen } = useConfirmationDialog({
        title: t`Delete all offers before import`,
        description: t`To import new offers, all existing offers must be deleted first. This action cannot be undone. Would you like to continue?`,
        onConfirmation: () => mutateAsync({ pathParams: { id: quoteRequestId } }),
    });

    const shouldShow: Record<QuoteRequestStatus, boolean> = {
        [QuoteRequestStatus.NotSent]: true,
        [QuoteRequestStatus.Sent]: true,
        [QuoteRequestStatus.Received]: true,
        [QuoteRequestStatus.Overdue]: true,
        [QuoteRequestStatus.Discarded]: true,
    };

    if (!shouldShow[quoteRequest.status]) {
        return null;
    }

    return (
        <>
            {modal}
            <Tooltip title={t`Import quote requests by uploading your completed Excel file`}>
                <SecondaryButton
                    startIcon={<UploadFileRounded />}
                    size="medium"
                    isLoading={isPending}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        hasOffers
                            ? setOpen(true)
                            : navigate(route(`/rfqs/:rfqId/sourcing/negotiations/quote-requests/importer`, { rfqId }));
                    }}
                >
                    {t`Import quote requests`}
                </SecondaryButton>
            </Tooltip>
        </>
    );
}

function ButtonSendQuoteRequests({
    quoteRequestId,
    negotiationId,
    rfqId,
}: {
    quoteRequestId: string;
    negotiationId: number;
    rfqId: string;
}) {
    const { data: quoteRequest } = useSuspendedQuoteRequest(quoteRequestId);

    const shouldShow: Record<QuoteRequestStatus, boolean> = {
        [QuoteRequestStatus.NotSent]: true,
        [QuoteRequestStatus.Sent]: false,
        [QuoteRequestStatus.Received]: false,
        [QuoteRequestStatus.Overdue]: false,
        [QuoteRequestStatus.Discarded]: false,
    };

    if (!shouldShow[quoteRequest.status]) {
        return null;
    }

    return (
        <Tooltip title={t`Continue by sending the quote request to the supplier`}>
            <PrimaryButton
                size="medium"
                href={route(
                    '/rfqs/:rfqId/sourcing/negotiations/:negotiationId/quote-requests/preview-email',
                    {
                        rfqId,
                        negotiationId,
                    },
                    {
                        quoteRequestNumbers: quoteRequest.number.toString(),
                    },
                )}
            >
                {t`Send request`}
            </PrimaryButton>
        </Tooltip>
    );
}

function ButtonSendQuoteRequestReminder({ quoteRequestId }: { quoteRequestId: string }) {
    const { data: quoteRequest } = useSuspendedQuoteRequest(quoteRequestId);
    const { mutateAsync, isPending } = useHttpMutation('POST /quote-request/send-reminders', {
        snackbarMessage: t`Email reminder sent`,
    });

    const isDisabled = Boolean(quoteRequest.received_date);

    return (
        <Tooltip title={t`Send email reminder to the supplier`}>
            <MenuItem
                label={t`Send reminder`}
                disabled={isDisabled}
                startIcon={<NotificationsActiveRounded />}
                isLoading={isPending}
                onClick={() => mutateAsync({ requestBody: { ids: [quoteRequestId] } })}
            />
        </Tooltip>
    );
}

function ToolbarInner({
    rfqId,
    negotiationId,
    quoteRequestId,
}: {
    rfqId: string;
    negotiationId: number;
    quoteRequestId: string;
}) {
    const breadcrumbs = [
        {
            title: t`Negotiation`,
            href: route(`/rfqs/:rfqId/sourcing/negotiations/:negotiationId/quote-requests`, {
                rfqId,
                negotiationId,
            }),
        },
        {
            title: t`Quote request`,
        },
    ];
    const location = useLocation();
    const returnTo = location.pathname + location.search;

    const hasAccessToPdfImporter = useHasAccessToPdfImporter();

    const { setDrawer, closeDrawer } = useDrawerContext();

    return (
        <Toolbar breadcrumbs={breadcrumbs}>
            <ButtonComments quoteRequestId={quoteRequestId} />
            <ButtonDownloadQuoteRequestExcel quoteRequestId={quoteRequestId} />
            <ButtonImportQuoteRequests rfqId={rfqId} quoteRequestId={quoteRequestId} />
            <ButtonSendQuoteRequests rfqId={rfqId} negotiationId={negotiationId} quoteRequestId={quoteRequestId} />
            <MenuButton appearance="secondary" icon={<icons.MoreVert fontSize="inherit" />} size="medium">
                <ButtonSendQuoteRequestReminder quoteRequestId={quoteRequestId} />
                <ButtonDeleteQuoteRequest quoteRequestId={quoteRequestId} rfqId={rfqId} negotiationId={negotiationId} />
                <ButtonDeleteAllOffers quoteRequestId={quoteRequestId} />
                <MenuItem
                    disabled={!hasAccessToPdfImporter}
                    label={t`Import from PDF`}
                    startIcon={<icons.FileUpload />}
                    href={route(`/offers/pdf-offer-importer`, {}, { quoteRequestId, returnTo })}
                />
                <MenuItem
                    label={t`Email history`}
                    startIcon={<icons.Email />}
                    onClick={() => {
                        setDrawer(<EmailHistory onClose={closeDrawer} quoteRequestId={quoteRequestId} />);
                    }}
                />
            </MenuButton>
        </Toolbar>
    );
}

function Content({ quoteRequestId }: { quoteRequestId: string }) {
    const { data: quoteRequest } = useSuspendedQuoteRequest(quoteRequestId);

    switch (quoteRequest.status) {
        case QuoteRequestStatus.NotSent:
            return <QuoteRequestDraftView quoteRequestId={quoteRequestId} />;
        case QuoteRequestStatus.Sent:
        case QuoteRequestStatus.Received:
        case QuoteRequestStatus.Overdue:
        case QuoteRequestStatus.Discarded:
            return <QuoteRequestSubmittedView quoteRequestId={quoteRequestId} />;
    }
}

export function SourcingNegotiationQuoteRequestDetailsPage({
    pathParams,
}: UrlParams<'/rfqs/:rfqId/sourcing/negotiations/:negotiationId/quote-requests/:quoteRequestId'>) {
    const { rfqId } = pathParams;
    const negotiationId = Number(pathParams.negotiationId);
    const quoteRequestId = pathParams.quoteRequestId;
    return (
        <PageLayoutCollapsibleSidebar
            sidebar={<NavigationSidebarSourcing rfqId={rfqId} />}
            header={<ToolbarInner rfqId={rfqId} negotiationId={negotiationId} quoteRequestId={quoteRequestId} />}
            layoutVariant="fullWidth"
        >
            <Content quoteRequestId={quoteRequestId} />
        </PageLayoutCollapsibleSidebar>
    );
}

function EmailHistory({ quoteRequestId, onClose }: { quoteRequestId: string; onClose: () => void }) {
    const { data: emailLog = [], isLoading } = useHttpQuery('GET /quote-request/:id/email-logs', {
        pathParams: { id: quoteRequestId },
    });

    const [expandedIndex, setExpandedIndex] = useState<number | null>(null);

    return (
        <RightBoxDrawer title={t`Email history`} onClose={onClose}>
            <Box sx={{ padding: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Text variant="h4">
                        <Trans>Email history</Trans>
                    </Text>
                    <span style={{ flexGrow: 1 }} />
                    <Tooltip title={t`Close`}>
                        <SecondaryIconButton size="medium" onClick={onClose}>
                            <icons.Close fontSize="inherit" />
                        </SecondaryIconButton>
                    </Tooltip>
                </Box>
            </Box>
            <Divider />
            {isLoading && <Text variant="body">{t`Loading...`}</Text>}
            {!isLoading && emailLog.length === 0 && (
                <Text style={{ padding: 16 }} variant="body">{t`No email history found`}</Text>
            )}
            {Array.from(emailLog)
                .sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
                .map((email, index) => {
                    const timeAgo = formatRelativeTime(email.created_at);
                    return (
                        <Box
                            key={index}
                            onClick={() => setExpandedIndex(expandedIndex === index ? null : index)}
                            sx={{
                                padding: 2,
                                borderBottom: '1px solid #e0e0e0',
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 1,
                                cursor: 'pointer',
                                minWidth: 400,
                                maxWidth: 1000,
                            }}
                        >
                            <Text variant="body-semibold">{email.subject}</Text>
                            <Text variant="body-small">
                                <Trans comment="Sent {5 days ago}">Sent {timeAgo}</Trans>
                            </Text>
                            <ExpandableText text={email.content} expanded={expandedIndex === index} />
                            {email.statuses
                                .filter((r) => r.status !== 'Processed')
                                .map((status, statusIndex) => (
                                    <Tooltip
                                        key={statusIndex}
                                        title={formatEmailStatusDescription(status.status)}
                                        placement="left"
                                    >
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                            <Text variant="body-semibold">{formatEmailStatus(status.status)}</Text>

                                            <div style={{ flexGrow: 1 }} />
                                            <Text variant="body">{formatRelativeTime(status.created_at)}</Text>
                                        </Box>
                                    </Tooltip>
                                ))}
                        </Box>
                    );
                })}
        </RightBoxDrawer>
    );
}

function formatEmailStatus(status: EmailEventStatus) {
    switch (status) {
        case 'Queued':
            return t`Queued`;
        case 'Bounce':
            return t`Bounce`;
        case 'Click':
            return t`Click`;
        case 'Deferred':
            return t`Deferred`;
        case 'Delivered':
            return t`Delivered`;
        case 'Drop':
            return t`Drop`;
        case 'Open':
            return t`Open`;
        case 'Processed':
            return t`Processed`;
        case 'SpamReport':
            return t`Spam Report`;
        case 'Unknown':
            return t`Unknown`;
        case 'Unsubscribe':
            return t`Unsubscribe`;
    }
}

function formatEmailStatusDescription(status: EmailEventStatus) {
    switch (status) {
        case 'Queued':
            return t`The email is waiting to be sent. Sometimes due to high load, the email might take a while to be sent.`;
        case 'Bounce':
            return t`The email could not be delivered to the recipient's inbox.`;
        case 'Click':
            return t`The recipient clicked on a link within the email.`;
        case 'Deferred':
            return t`The email delivery was delayed but will be retried.`;
        case 'Delivered':
            return t`The email was successfully delivered to the recipient's inbox.`;
        case 'Drop':
            return t`The email was not sent due to a suppression list or other reasons.`;
        case 'Open':
            return t`The recipient opened the email.`;
        case 'Processed':
            return t`The email has been processed and is ready for delivery.`;
        case 'SpamReport':
            return t`The recipient marked the email as spam.`;
        case 'Unknown':
            return t`The status of the email is unknown.`;
        case 'Unsubscribe':
            return t`The recipient unsubscribed from the email list.`;
    }
}

function ExpandableText({ text, expanded }: { text: string; expanded: boolean }) {
    const showExpanded = expanded || text.length < 100;

    return (
        <>
            <Collapse in={showExpanded}>
                <RichTextEditor
                    editable={false}
                    initialHtml={text}
                    onChange={() => {}}
                    autoFocus={false}
                    hideToolbar
                    hideVariables
                />
            </Collapse>
            <Collapse in={!showExpanded}>
                <RichTextEditor
                    editable={false}
                    initialHtml={text.slice(0, 100) + '...'}
                    onChange={() => {}}
                    autoFocus={false}
                    hideToolbar
                    hideVariables
                />
            </Collapse>
        </>
    );
}
