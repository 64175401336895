import * as z from 'zod';
import { CustomerBillingAddressAllRequiredRuntype, VATIdentificationTypeRuntype } from '../customer';

export const CustomerSignupUserRuntype = z.object({
    first_name: z.string(),
    last_name: z.string(),
    email: z.string(),
    language: z.string(),
});

export const BusinessCustomerSignupPostRuntype = z.object({
    type: z.literal('Business'),
    data: z.object({
        tenant: z.string(),
        user: CustomerSignupUserRuntype,
        company_name: z.string(),
        vat_identification_number: z.string(),
        vat_identification_type: VATIdentificationTypeRuntype,
        commercial_register_number: z.string(),
        billing_address: CustomerBillingAddressAllRequiredRuntype,
    }),
});

export const PrivateCustomerSignupPostRuntype = z.object({
    type: z.literal('Private'),
    data: z.object({
        tenant: z.string(),
        user: CustomerSignupUserRuntype,
        billing_address: CustomerBillingAddressAllRequiredRuntype,
    }),
});

export const CustomerSignupPostRuntype = PrivateCustomerSignupPostRuntype.or(BusinessCustomerSignupPostRuntype);
export type CustomerSignupPostDTO = z.infer<typeof CustomerSignupPostRuntype>;
