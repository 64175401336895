import { FormContainer } from '@/components/formLayouts/FormContainer';
import { SubmitButton } from '@/components/formLayouts/SubmitButton';
import { t } from '@lingui/macro';
import { isPresent } from '@luminovo/commons';
import {
    CenteredLayout,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    useNavigate,
} from '@luminovo/design-system';
import { SpinnerWithBackdrop } from '../../../components/Spinners';
import { useDialogContext } from '../../../components/contexts/ModalContext';
import { CancelButton } from '../../../components/formLayouts/CancelButton';
import { useHttpMutation } from '../../../resources/mutation/useHttpMutation';
import {
    useSourcingScenario,
    useSourcingScenarioSuspense,
} from '../../../resources/sourcingScenario/sourcingScenarioHandlers';
import { UrlParams } from '../../../utils/routes';
import { SourcingScenarioForm, SourcingScenarioQuickFormInner } from './SourcingScenarioForm';
import {
    SourcingScenarioFormValues,
    convertExtractedValuesToRequestBody,
    convertToSourcingScenarioFormValue,
} from './converters';

export function EditSourcingScenarioForm({
    pathParams,
}: UrlParams<'/rfqs/:rfqId/sourcing/scenarios/:sourcingScenarioId/edit'>): JSX.Element {
    const { sourcingScenarioId } = pathParams;
    const navigate = useNavigate();
    const { data: sourcingScenario } = useSourcingScenario(sourcingScenarioId);

    const { mutateAsync } = useHttpMutation('PATCH /sourcing-scenarios/:sourcingScenarioId', {
        snackbarMessage: t`Sourcing scenario updated`,
        onSuccess: () => navigate(-1),
    });

    const submitHanlder = async (formValues: SourcingScenarioFormValues) => {
        await mutateAsync({
            pathParams: { sourcingScenarioId },
            requestBody: convertExtractedValuesToRequestBody(formValues),
        });
    };

    if (!isPresent(sourcingScenario)) {
        return (
            <CenteredLayout>
                <SpinnerWithBackdrop noBackdrop={true} />
            </CenteredLayout>
        );
    }

    const defaultValues = convertToSourcingScenarioFormValue(sourcingScenario);

    return <SourcingScenarioForm onSubmit={submitHanlder} defaultValues={defaultValues} />;
}

export function useSourcingScenarioQuickForm({ sourcingScenarioId }: { sourcingScenarioId: string }) {
    const { setDialog, closeDialog } = useDialogContext();
    const { data: sourcingScenario } = useSourcingScenarioSuspense(sourcingScenarioId);

    const { mutateAsync } = useHttpMutation('PATCH /sourcing-scenarios/:sourcingScenarioId', {
        snackbarMessage: t`Sourcing scenario updated`,
        onSuccess: closeDialog,
    });

    const submitHandler = async (formValues: SourcingScenarioFormValues) => {
        await mutateAsync({
            pathParams: { sourcingScenarioId },
            requestBody: convertExtractedValuesToRequestBody(formValues),
        });
    };

    const defaultValues = convertToSourcingScenarioFormValue(sourcingScenario);

    const openDialog = () => {
        setDialog(
            <Dialog open={true} maxWidth="md" fullWidth={true} onClose={closeDialog}>
                <DialogTitle title={t`Edit sourcing preference`} onClose={closeDialog} sticky={true} />
                <FormContainer onSubmit={submitHandler} defaultValues={defaultValues}>
                    <DialogContent sx={{ overflow: 'auto' }}>
                        <SourcingScenarioQuickFormInner />
                    </DialogContent>
                    <DialogActions sticky={true}>
                        <CancelButton onClick={closeDialog} />
                        <SubmitButton />
                    </DialogActions>
                </FormContainer>
            </Dialog>,
        );
    };

    return { openDialog };
}
