import * as z from 'zod';
import { ApiIntegrationStatusRuntype } from './3rdPartyBackendTypes';

export interface TtiCredentialsDTO extends z.infer<typeof TtiCredentialsDTORuntype> {}
export const TtiCredentialsDTORuntype = z.object({
    api_key: z.string(),
    customer_account_number: z.string(),
    region: z.enum(['EU', 'NA', 'AS']),
});

export interface TtiCredentialsInputDTO extends z.infer<typeof TtiCredentialsInputDTORuntype> {}
export const TtiCredentialsInputDTORuntype = z.object({
    api_key: z.string().optional(),
    customer_account_number: z.string().optional(),
    region: z.enum(['EU', 'NA', 'AS']),
});

export interface TtiResponseBodyDTO extends z.infer<typeof TtiResponseBodyDTORuntype> {}
export const TtiResponseBodyDTORuntype = z.object({
    data: TtiCredentialsDTORuntype.nullable(),
    status: ApiIntegrationStatusRuntype,
});
