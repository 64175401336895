import {
    formatCapacitance,
    formatDielectricMaterial,
    formatPowerRating,
    formatResistance,
    formatTemperatureCoefficient,
    formatTolerance,
    formatVoltageRating,
} from '@/utils/converterUtils';
import { t, Trans } from '@lingui/macro';
import { assertUnreachable } from '@luminovo/commons';
import { colorSystem, Flexbox, Text, Tooltip } from '@luminovo/design-system';
import {
    EmsPartNumberPartMatchFullPart,
    EmsPartNumberPartSpecification,
    GenericPart,
    OtsComponentFull,
} from '@luminovo/http-client';
import { formatPart } from '@luminovo/sourcing-core';
import { LinkOffRounded } from '@mui/icons-material';
import { Box } from '@mui/material';

const TitleWithText = ({
    label,
    text,
    isLinked,
    linkedPartsTooltip,
}: {
    label: string;
    text: string;
    isLinked?: boolean;
    linkedPartsTooltip?: JSX.Element;
}): JSX.Element => {
    return (
        <Box display="grid" gridTemplateColumns={'120px auto auto'}>
            <Text variant="h5" color={colorSystem.neutral[6]}>
                {label}
            </Text>
            <Text variant="body-small">{text}</Text>
            <Flexbox justifyContent="flex-end">
                {isLinked === true && (
                    <Tooltip title={linkedPartsTooltip ?? ''}>
                        <Text variant="body-small" color={colorSystem.green[8]}>
                            <Trans>Linked</Trans>
                        </Text>
                    </Tooltip>
                )}
                {isLinked === false && (
                    <Tooltip title={linkedPartsTooltip ?? ''}>
                        <Flexbox alignItems="center" gap={4} justifyContent="flex-end">
                            <LinkOffRounded fontSize="inherit" style={{ color: colorSystem.yellow[8] }} />
                            <Text variant="body-small" color={colorSystem.yellow[8]}>
                                <Trans>No linked parts</Trans>
                            </Text>
                        </Flexbox>
                    </Tooltip>
                )}
            </Flexbox>
        </Box>
    );
};

const RenderGenericTechParams = ({ genericPart }: { genericPart: GenericPart }): JSX.Element => {
    if (genericPart.type === 'Resistor') {
        const {
            resistance,
            tolerance,
            power_rating: powerRating,
            voltage_rating: voltageRating,
            temperature_coefficient: temperatureCoefficient,
        } = genericPart.technical_parameters;

        return (
            <Text variant="body-small" color={colorSystem.neutral.white}>
                {genericPart.type}
                {resistance && `, ${formatResistance(resistance)}`}
                {voltageRating && `, ${formatVoltageRating(voltageRating)}`}
                {powerRating && `, ${formatPowerRating(powerRating)}`}
                {tolerance && `, ${formatTolerance(tolerance)}`}
                {temperatureCoefficient && `, ${formatTemperatureCoefficient(temperatureCoefficient)}`}
            </Text>
        );
    }
    if (genericPart.type === 'Capacitor') {
        const {
            dielectric: dielectricMaterial,
            capacitance,
            tolerance,
            voltage_rating: voltageRating,
        } = genericPart.technical_parameters;
        return (
            <Text variant="body-small" color={colorSystem.neutral.white}>
                {genericPart.type}
                {capacitance && `, ${formatCapacitance(capacitance)}`}
                {voltageRating && `, ${formatVoltageRating(voltageRating)}`}
                {tolerance && `, ${formatTolerance(tolerance)}`}
                {dielectricMaterial && `, ${formatDielectricMaterial(dielectricMaterial)}`}
            </Text>
        );
    }
    assertUnreachable(genericPart);
};

const LinkedPartsTooltip = ({ partsLinked }: { partsLinked: EmsPartNumberPartMatchFullPart[] }): JSX.Element => {
    return (
        <Flexbox flexDirection="column" gap="8px">
            {partsLinked.map((part) => {
                const partType = part.part.type;
                if (partType === 'OffTheShelf') {
                    return (
                        <Text key={part.part.data.id} variant="body-small" color={colorSystem.neutral.white}>
                            {formatPart(part.part.data)}
                        </Text>
                    );
                }
                if (partType === 'Generic') {
                    return <RenderGenericTechParams key={part.part.data.id} genericPart={part.part.data.content} />;
                }
                return <></>;
            })}
        </Flexbox>
    );
};

export const RawSpecificationDetails = ({
    partSpecification,
    component,
}: {
    partSpecification: EmsPartNumberPartSpecification;
    component: OtsComponentFull;
}): JSX.Element => {
    const { mpn, manufacturer, description, part_type, package: packageName } = partSpecification;
    const partsLinked = component.matches.filter((match) => match.matching_reason.reason?.id === partSpecification.id);
    const linkedPartsTooltip = <LinkedPartsTooltip partsLinked={partsLinked} />;
    return (
        <Flexbox flexDirection={'column'} gap={8}>
            <TitleWithText
                label={t`MPN`}
                text={mpn ?? '-'}
                isLinked={partsLinked.length > 0}
                linkedPartsTooltip={linkedPartsTooltip}
            />
            <TitleWithText label={t`Manufacturer`} text={manufacturer ?? '-'} />
            <TitleWithText label={t`Description`} text={description ?? '-'} />
            <TitleWithText label={t`Package`} text={packageName ?? '-'} />
            <TitleWithText label={t`Part type`} text={part_type ?? '-'} />
        </Flexbox>
    );
};
