import { Trans } from '@lingui/macro';
import { isPresent } from '@luminovo/commons';
import { CenteredLayout, colorSystem, DestructiveTertiaryButton, Flexbox, Text } from '@luminovo/design-system';
import RefreshIcon from '@mui/icons-material/Refresh';
import { CircularProgress, Skeleton } from '@mui/material';
import * as Sentry from '@sentry/react';
import * as React from 'react';
import { ErrorFallback } from './errorHandlers/ErrorBoundary';

function ActionErrorFallback({ resetError }: { resetError: () => void }): JSX.Element {
    return (
        <DestructiveTertiaryButton onClick={resetError} startIcon={<RefreshIcon />} size="medium">
            <Trans>Oops, an error occurred</Trans>
        </DestructiveTertiaryButton>
    );
}

export function LayoutCard({
    id,
    title,
    actions,
    children,
    style,
}: React.PropsWithChildren<{
    title?: string | JSX.Element | undefined;
    actions?: JSX.Element;
    id?: string;
    style?: React.CSSProperties;
}>): JSX.Element {
    return (
        <Sentry.ErrorBoundary fallback={ErrorFallback}>
            <Flexbox
                id={id}
                flexDirection="column"
                gap={'20px'}
                padding="20px"
                borderRadius="8px"
                sx={{ boxSizing: 'border-box' }}
                style={{ background: colorSystem.neutral.white, ...style }}
            >
                {isPresent(title) && (
                    <Flexbox justifyContent={'space-between'} alignItems={'center'}>
                        {typeof title === 'string' ? <Text variant="h2">{title}</Text> : title}
                        <Flexbox gap={8} alignItems={'center'}>
                            <Sentry.ErrorBoundary fallback={ActionErrorFallback}>
                                <React.Suspense fallback={<Skeleton variant="text" width={'100px'} height={'24px'} />}>
                                    {actions}
                                </React.Suspense>
                            </Sentry.ErrorBoundary>
                        </Flexbox>
                    </Flexbox>
                )}
                <React.Suspense
                    fallback={
                        <CenteredLayout>
                            <CircularProgress />
                        </CenteredLayout>
                    }
                >
                    {children}
                </React.Suspense>
            </Flexbox>
        </Sentry.ErrorBoundary>
    );
}
