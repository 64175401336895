import { t } from '@lingui/macro';
import { CenteredLayout, Column, NonIdealState } from '@luminovo/design-system';
import { ActivityDTO, IdWithIndex } from '@luminovo/http-client';
import { AccessAlarmsOutlined } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import { useHttpQuery } from '../../../../../resources/http/useHttpQuery';
import { useMutationUpdateTemplate } from '../../../../../resources/manufacturingScenarioTemplates/manufacturingScenarioTemplatesHandler';
import { levelColumnTagsOnOneRow } from '../../../ActivitiesView/activitiesDatabaseTableColumns';
import { SortableEntityTable } from '../../../shared/Entities/SortableEntityTable';
import { categoryColumn, nameColumn, processColumn } from '../../../shared/sharedManufacturingColumns';
import { useActivitySelectionDialog } from './ActivitySelectionDialog';

export const SortableActivitiesTable = ({
    manufacturingScenarioTemplateId,
    orderedActivityIds,
}: {
    manufacturingScenarioTemplateId: string;
    orderedActivityIds: IdWithIndex[];
}): JSX.Element => {
    const onOrderUpdate = useOrderUpdate(manufacturingScenarioTemplateId);
    const { data: activitiesData, isLoading: isLoadingActivities } = useHttpQuery('GET /activities', {
        queryParams: {},
    });

    const { openDialog: openActivityDialog } = useActivitySelectionDialog({
        orderedActivityIds,
        manufacturingScenarioTemplateId,
    });

    if (isLoadingActivities || !activitiesData) {
        return (
            <CenteredLayout height={'30vh'}>
                <CircularProgress />;
            </CenteredLayout>
        );
    }

    const activities = activitiesData.data;
    const columns: Column<ActivityDTO>[] = [nameColumn, processColumn, categoryColumn, levelColumnTagsOnOneRow];
    const persistenceId = `SortableActivitiesTable-${manufacturingScenarioTemplateId}`;

    return (
        <SortableEntityTable
            entities={activities}
            order={orderedActivityIds}
            persistenceId={persistenceId}
            columns={columns}
            onOrderUpdate={onOrderUpdate}
            NoResultsComponent={() => (
                <CenteredLayout>
                    <NonIdealState
                        Icon={AccessAlarmsOutlined}
                        title={t`No activities selected`}
                        description={t`There are currently no activities selected for this template`}
                        action={{
                            onClick: () => openActivityDialog(),
                            children: t`Select activities`,
                        }}
                    />
                </CenteredLayout>
            )}
        />
    );
};

const useOrderUpdate = (templateId: string) => {
    const { mutateAsync } = useMutationUpdateTemplate({ templateId, disableSnackbar: true });

    return async (orderedItems: ActivityDTO[]) => {
        await mutateAsync({
            ordered_activities: orderedItems.map((item, index) => ({ id: item.id, index })),
        });
    };
};
