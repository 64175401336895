import * as z from 'zod';
import { ApiIntegrationStatusRuntype } from './3rdPartyBackendTypes';

export const OnlineComponentsPriceTypeDTORuntype = z.enum(['ListPrice', 'ContractPrice']);

export interface OnlineComponentsCredentialsDTO extends z.infer<typeof OnlineComponentsCredentialsDTORuntype> {}
export const OnlineComponentsCredentialsDTORuntype = z.object({
    api_key: z.string(),
    price_type: OnlineComponentsPriceTypeDTORuntype,
});

export interface OnlineComponentsCredentialsInputDTO
    extends z.infer<typeof OnlineComponentsCredentialsInputDTORuntype> {}
export const OnlineComponentsCredentialsInputDTORuntype = z.object({
    api_key: z.string().optional(),
    price_type: OnlineComponentsPriceTypeDTORuntype.optional(),
});

export interface OnlineComponentsResponseBodyDTO extends z.infer<typeof OnlineComponentsResponseBodyDTORuntype> {}
export const OnlineComponentsResponseBodyDTORuntype = z.object({
    data: OnlineComponentsCredentialsDTORuntype.nullable(),
    status: ApiIntegrationStatusRuntype,
});
