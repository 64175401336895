import * as z from 'zod';

import { AdditionalServicesGetResponseRuntype } from '../additionalServices';
import {
    AssemblyIndustryRuntype,
    AssemblyTypeRuntype,
    BomItemApprovalStatus,
    BomItemIssue,
} from '../assembly/assemblyBackendTypes';
import { CurrencyRuntype, MonetaryValueBackendRuntype } from '../backendTypes';
import { DemandScenarioRuntype, RfqCreationDemandScenarioRuntype } from '../demandScenario/demandScenarioBackendTypes';
import { UserDTORuntype } from '../user';

export enum RfqStatus {
    /**
     * The initial state.
     */
    RequestInDraft = 'RequestInDraft',
    /**
     * When a quotation is requested by the OEM.
     */
    QuotationInProgress = 'QuotationInProgress',
    /**
     * When the EMS uploads a quotation.
     */
    QuotationAvailable = 'QuotationAvailable',
    /**
     * When the customer has placed an order.
     */
    OrderPlaced = 'OrderPlaced',
    /**
     * When the EMS "won" the RfQ.
     */
    OrderConfirmed = 'OrderConfirmed',
    /**
     * When the payment of an order has failed.
     */
    PaymentFailed = 'PaymentFailed',
    /**
     * When the EMS is in the process of procuring the parts from the suppliers.
     */
    OrderInProcurement = 'OrderInProcurement',
    /**
     * When the EMS has the assembly in production.
     */
    OrderInProduction = 'OrderInProduction',
    /**
     * Terminal state.
     * When the EMS has shipped the assembly.
     */
    OrderShipped = 'OrderShipped',
    /**
     * Terminal state. No bid.
     */
    NoQuotation = 'NoQuotation',
    /**
     * Terminal state.
     * When the EMS "lost" the RfQ.
     */
    NoOrder = 'NoOrder',
}
export const RfqStatusRuntype = z.nativeEnum(RfqStatus);

/**
 * Returns true if the given status indicates that work needs to be done on the RfQ.
 *
 * This is used to determine if the RfQ should be included in the "work in progress" chart.
 */
export function isWorkInProgress(status: RfqStatus): boolean {
    return (
        status === RfqStatus.RequestInDraft ||
        status === RfqStatus.QuotationInProgress ||
        status === RfqStatus.QuotationAvailable ||
        status === RfqStatus.OrderPlaced ||
        status === RfqStatus.OrderInProduction ||
        status === RfqStatus.OrderInProcurement
    );
}

export const WorkflowTypeRuntype = z.union([z.literal('Automatic'), z.literal('Manual'), z.literal('DontShow')]);
export type WorkflowType = z.infer<typeof WorkflowTypeRuntype>;

export const PaymentMethodRuntype = z.union([z.literal('InstantOnly'), z.literal('InvoiceOnly'), z.literal('Both')]);
export type PaymentMethodType = z.infer<typeof PaymentMethodRuntype>;

export interface VolumeEstimate extends z.infer<typeof VolumeEstimateRuntype> {}
const VolumeEstimateRuntype = z.object({
    lower: z.string().nullable(),
    upper: z.string().nullable(),
    currency: CurrencyRuntype,
});

export interface EMSRfqDetails extends z.infer<typeof EMSRfqDetailsRuntype> {}
const EMSRfqDetailsRuntype = z.object({
    volume_estimate: z.null().or(VolumeEstimateRuntype),
});

export const EmsRfqUpdateRuntype = z.object({
    name: z.string().optional(),
    internal_number: z.string().nullable().optional(),
    due_date: z.string().nullable().optional(),
    currency: CurrencyRuntype.optional(),
    volume_estimate: VolumeEstimateRuntype.optional(),
    status: RfqStatusRuntype.optional(),
    shipping_tracking_link: z.string().nullable().optional(),
    notes: z.string().nullable().optional(),
});

const ExistingOrderSizeRuntype = z.object({
    sourcing_scenario_id: z.string(),
    quantity: z.number(),
    desired_date: z.string().nullable(),
});

const NewOrderSizeRuntype = z.object({
    quantity: z.number(),
    desired_date: z.string().nullable(),
});

export const CustomerOrderSizeRuntype = z
    .object({
        type: z.literal('Existing'),
        data: ExistingOrderSizeRuntype,
    })
    .or(
        z.object({
            type: z.literal('New'),
            data: NewOrderSizeRuntype,
        }),
    );
export type CustomerOrderSizeDTO = z.infer<typeof CustomerOrderSizeRuntype>;

export const CustomerRfqUpdateRuntype = z.object({
    name: z.string().optional(),
    industry: AssemblyIndustryRuntype.optional(),
    order_sizes: z.array(CustomerOrderSizeRuntype).optional(),
    additional_services: z.array(z.string()),
});

export const RfqUpdateRuntype = z
    .object({
        type: z.literal('Ems'),
        data: EmsRfqUpdateRuntype,
    })
    .or(
        z.object({
            type: z.literal('Customer'),
            data: CustomerRfqUpdateRuntype,
        }),
    );

export interface CustomerRfqUpdateDTO extends z.infer<typeof CustomerRfqUpdateRuntype> {}
export interface EmsRfqUpdateDTO extends z.infer<typeof EmsRfqUpdateRuntype> {}

export const SalesOrderOverviewRuntype = z.object({
    id: z.string(),
    estimated_lead_time_in_days: z.number().nullable(),
    order_size: z.number(),
    total_price: MonetaryValueBackendRuntype,
});

export interface RfqListItemDTO extends z.infer<typeof RfqListItemRuntype> {}
export const RfqListItemRuntype = z.object({
    id: z.string(),
    name: z.string(),
    customer_name: z.string(),
    customer_number: z.string().nullable(),
    default_contact_person: UserDTORuntype.nullable(),
    ems_internal_number: z.string().nullable(),
    status: RfqStatusRuntype,
    creation_date: z.string(),
    due_date: z.string().nullable(),
    ems_rfq_details: EMSRfqDetailsRuntype,
    created_by: UserDTORuntype.nullable(),
    contributors: z.array(
        z.object({
            name: z.string(),
            email: z.string(),
        }),
    ),
    notes: z.string().nullable(),
    assembly_types: z.array(AssemblyTypeRuntype),
    is_archived: z.boolean(),
    currency: CurrencyRuntype,
    additional_services: z.array(AdditionalServicesGetResponseRuntype.pick({ id: true, name_en: true, name_de: true })),
    sales_order: SalesOrderOverviewRuntype.nullable(),
});

export interface RfqAssemblyDTO extends z.infer<typeof RfqAssemblyRuntype> {}
export const RfqAssemblyRuntype = z.object({
    id: z.string(),
    designator: z.string(),
    assembly_type: AssemblyTypeRuntype,
});

export interface RfqDTO extends z.infer<typeof RfqDTORuntype> {}

export const RfqDTORuntype = z.object({
    creation_date: z.string(),
    due_date: z.string().nullable(),
    ems_rfq_details: EMSRfqDetailsRuntype,
    id: z.string(),
    name: z.string(),
    customer: z.string(),
    top_level_assemblies: z.array(RfqAssemblyRuntype),
    sourcing_scenarios: z.array(z.string()),
    demand_scenarios: z.array(DemandScenarioRuntype),
    status: RfqStatusRuntype,
    shipping_tracking_link: z.string().nullable().optional(),
    ems_internal_number: z.string().nullable(),
    design_items_count: z.number(),
    assemblies_count: z.number(),
    assemblies_types: z.array(AssemblyTypeRuntype),
    created_by: z.string().nullable(),
    currency: CurrencyRuntype,
    is_archived: z.boolean(),
    workflow_type: WorkflowTypeRuntype,
    industry: AssemblyIndustryRuntype,
    notes: z.string().nullable(),
});

export const CustomerIdInfoRuntype = z.object({
    type: z.literal('ExistingCustomer').or(z.literal('NewCustomer')),
    data: z.string(),
});

export type CustomerInfoDTO = z.infer<typeof CustomerInfoRuntype>;
export const CustomerInfoRuntype = z.object({
    customer: CustomerIdInfoRuntype,
});

export const AssembliesRuntype = z.array(
    z.object({
        name: z.string(),
        assemblyType: AssemblyTypeRuntype,
    }),
);

export type AssemblyInfoDTO = z.infer<typeof AssemblyInfoRuntype>;
export const AssemblyInfoRuntype = z.object({
    industry: AssemblyIndustryRuntype,
    assemblies: AssembliesRuntype,
});

export type RfqInfoDTO = z.infer<typeof RfqInfoRuntype>;
export const RfqInfoRuntype = z.object({
    name: z.string(),
    emsInternalNumber: z.string().or(z.undefined()).nullable(),
    contributors: z.array(z.string()),
    dueDate: z.string().or(z.undefined()).nullable(),
    currency: CurrencyRuntype,
    volumeEstimate: VolumeEstimateRuntype.or(z.undefined()).nullable(),
    comment: z.string().nullable(),
});

export type RfqInputDTO = z.infer<typeof RfqInputDTORuntype>;
export const RfqInputDTORuntype = z.object({
    customer: CustomerInfoRuntype,
    assembly: AssemblyInfoRuntype,
    rfq: RfqInfoRuntype,
    additional_service_ids: z.array(z.string()),
    demand_scenarios: z.array(RfqCreationDemandScenarioRuntype),
});

export type UsedInAssemblyData = z.infer<typeof UsedInAssemblyDataRuntype>;
const UsedInAssemblyDataRuntype = z.object({
    id: z.string(),
    designator: z.string(),
    rfq: z
        .object({
            id: z.string(),
            name: z.string(),
            status: RfqStatusRuntype,
            creation_date: z.string(),
        })
        .nullable()
        .optional(),
    customer: z.null().or(z.object({ id: z.string(), name: z.string(), number: z.string().optional() })),
    initial_matched_bom_item: z.object({ ids: z.array(z.string()) }),
    creation_date: z.string(),
});

export const UsedInResponseRuntype = z.record(z.string(), z.array(UsedInAssemblyDataRuntype));

const BomIssueInfoRuntype = z.object({
    approval_status: z.nativeEnum(BomItemApprovalStatus),
    design_item_ids: z.array(z.string()),
    count: z.number(),
});
const BomIssuesRuntype = z.record(z.nativeEnum(BomItemIssue), BomIssueInfoRuntype);
export type BomIssues = z.infer<typeof BomIssuesRuntype>;

const PendingStatusRuntype = z.object({ type: z.literal('Pending') });
const DoneStatusRuntype = z.object({ type: z.literal('Done') });
const BomProgressRuntype = z.object({
    type: z.literal('InProgress'),
    issues: BomIssuesRuntype,
});

const BomStatusRuntype = PendingStatusRuntype.or(DoneStatusRuntype).or(BomProgressRuntype);
const RequirementRuntype = z.literal('Required').or(z.literal('Optional')).or(z.literal('DontShow'));
export type CustomerPortalRequirement = z.infer<typeof RequirementRuntype>;
const BomStateRuntype = z.object({
    status: BomStatusRuntype,
    requirement: RequirementRuntype,
});
export type BomState = z.infer<typeof BomStateRuntype>;

const PcbAnalysisInProgressIssueRuntype = z.object({
    type: z.literal('IsAnalyzing'),
    progress: z.number(),
});

const OtherPcbIssuesRuntype = z.object({
    type: z.literal('NoOffer').or(z.literal('NotApproved')).or(z.literal('AnalysisError')).or(z.literal('NoFiles')),
});

const PcbIssueRuntype = PcbAnalysisInProgressIssueRuntype.or(OtherPcbIssuesRuntype);
export type PcbIssue = z.infer<typeof PcbIssueRuntype>;

const PcbIssuesRuntype = z.array(PcbIssueRuntype);
const PcbProgressRuntype = z.object({
    type: z.literal('InProgress'),
    issues: PcbIssuesRuntype,
});
const PcbStatusRuntype = PendingStatusRuntype.or(DoneStatusRuntype).or(PcbProgressRuntype);
const PcbStateRuntype = z.object({
    status: PcbStatusRuntype,
    requirement: RequirementRuntype,
});
export type PcbState = z.infer<typeof PcbStateRuntype>;

const SourcingProgressRuntype = z.object({ type: z.literal('InProgress') });
const SourcingStatusRuntype = PendingStatusRuntype.or(DoneStatusRuntype).or(SourcingProgressRuntype);
const SourcingStateRuntype = z.object({
    status: SourcingStatusRuntype,
    requirement: z.literal('Required'),
});

const FileUploadProgressRuntype = z.object({ type: z.literal('InProgress') });
const FileUploadStatusRuntype = PendingStatusRuntype.or(DoneStatusRuntype).or(FileUploadProgressRuntype);
const FileUploadRuntype = z.object({
    status: FileUploadStatusRuntype,
    requirement: RequirementRuntype,
});
export type CadState = z.infer<typeof FileUploadRuntype>;
export type ManufacturingState = z.infer<typeof FileUploadRuntype>;
export type PnpState = z.infer<typeof FileUploadRuntype>;

export const CustomerPortalAssemblyStateRuntype = z.object({
    bom: BomStateRuntype,
    pcb: PcbStateRuntype,
    cad: FileUploadRuntype,
    manufacturing: FileUploadRuntype,
    pnp: FileUploadRuntype,
});

export type CustomerPortalAssemblyState = z.infer<typeof CustomerPortalAssemblyStateRuntype>;

const AssemblyIdRuntype = z.string();

export const CustomerPortalStateRuntype = z.object({
    sourcing_state: SourcingStateRuntype,
    breadcrumbs: z.record(AssemblyIdRuntype, z.array(z.string())),
    assembly_states: z.record(AssemblyIdRuntype, CustomerPortalAssemblyStateRuntype),
});

export type CustomerPortalState = z.infer<typeof CustomerPortalStateRuntype>;

export const AssemblyStateRuntype = z.object({
    is_bom_done: z.boolean(),
    is_pcb_done: z.boolean(),
    is_cad_done: z.boolean(),
    is_manufacturing_done: z.boolean(),
    is_pnp_done: z.boolean(),
});

const QuotationWarningsOk = z.object({
    type: z.literal('Ok'),
});

const QuotationWarningsCalculationMissing = z.object({
    type: z.literal('CalculationMissing'),
    data: z.object({
        assembly_designators: z.array(z.string()),
    }),
});

const QuotationWarningsQuotationDocumentMissing = z.object({
    type: z.literal('QuotationDocumentMissing'),
});

export const QuotationWarningsRuntype = QuotationWarningsOk.or(QuotationWarningsCalculationMissing).or(
    QuotationWarningsQuotationDocumentMissing,
);
export type QuotationWarningsDTO = z.infer<typeof QuotationWarningsRuntype>;

export const RfqStatusHistoryRuntype = z.object({
    rfq_id: z.string(),
    previous_status: RfqStatusRuntype.optional(),
    next_status: RfqStatusRuntype,
    updated_at: z.string(),
    updated_by: z.string().optional(),
});
