import * as z from 'zod';

import { ParentsDTORuntype } from '../backendTypes';
import { BomFileDTORuntype } from '../bomImporter';
import { HistoryRecordResponseRuntype } from '../changeHistory';
import { endpoint } from '../http/endpoint';
import { CustomPartOfferResultResponseRuntype, PCBV2Runtype } from '../pcb';
import { AssemblyStateRuntype } from '../rfq';
import { RfQContextQueryParamsRuntype } from '../rfqContext';
import { UserDTORuntype } from '../user';
import {
    AssemblyDTORuntype,
    AssemblyDataDTORuntype,
    AssemblyFormRequest,
    AssemblyImportRuntype,
    AssemblyMonitoringRuntype,
    AssemblyOverviewFilterRequestRuntype,
    AssemblyOverviewResponseRuntype,
    AssemblyResourcesRuntype,
    AssemblyResponseRuntype,
    DescendantsDTONewRuntype,
    DescendantsSummaryDTORuntype,
    UploadedFileType,
} from './assemblyBackendTypes';

export const assemblyEndpoints = {
    'POST /assemblies/paginated': endpoint({
        description:
            'Returns a paginated list of assemblies. The assemblies are listed in descending order of creation date',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.object({
            page_size: z.number(),
            page_params: z.unknown().or(z.undefined()),
            filters: z.array(AssemblyOverviewFilterRequestRuntype),
        }),
        responseBody: AssemblyOverviewResponseRuntype,
    }),
    'GET /assemblies/:assemblyId/data': endpoint({
        description: 'Returns assembly data (cheap endpoint if you dont need anything else)',
        pathParams: z.object({ assemblyId: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: AssemblyDataDTORuntype,
    }),
    'GET /assemblies/:assemblyId/assembly-descendants': endpoint({
        description:
            'Returns assembly descendants of a given assembly. (cheap endpoint if you only need the assembly data)',
        pathParams: z.object({ assemblyId: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({
            items: z.array(AssemblyDataDTORuntype),
        }),
    }),

    'GET /assemblies/:assemblyId/descendants': endpoint({
        description: 'Returns all the descendants of the given assembly (e.g. the design items and sub-assemblies)',
        pathParams: z.object({ assemblyId: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({ data: DescendantsDTONewRuntype }),
    }),

    'POST /assemblies/bulk': endpoint({
        description:
            'Fetches a list of assemblies given their ID. The with_aggregations flag determines whether just the assembly data is returned, or whether its returned together with aggregated data from its BOM Items and subassemblies',
        pathParams: z.undefined(),
        queryParams: z.object({
            with_aggregations: z.boolean().optional(),
        }),
        requestBody: z.object({ ids: z.array(z.string()) }),
        responseBody: z.object({ items: z.array(AssemblyResponseRuntype) }),
    }),

    'GET /assemblies/:assemblyId/descendants-summary': endpoint({
        description: 'Returns a summary of the descendants of a given assembly',
        pathParams: z.object({ assemblyId: z.string() }),
        queryParams: z.object({ viewed_in_assembly_overview: z.boolean() }),
        requestBody: z.undefined(),
        responseBody: z.object({ data: DescendantsSummaryDTORuntype }),
    }),

    'GET /assemblies/:id/descendants-part-ids-without-emission-data': endpoint({
        description: 'Returns OTS part IDs and generic part IDs, for which we request emission data',
        pathParams: z.object({ id: z.string() }),
        queryParams: RfQContextQueryParamsRuntype,
        requestBody: z.undefined(),
        responseBody: z.object({
            ots_part_ids: z.array(z.string()),
            generic_part_ids: z.array(z.string()),
        }),
    }),

    'POST /assemblies/:id/pcb': endpoint({
        description: 'Creates PCB and links it to an assembly.',
        pathParams: z.object({ id: z.string() }),
        queryParams: z.object({ without_files: z.boolean().optional() }),
        requestBody: z.undefined(),
        responseBody: PCBV2Runtype,
    }),

    'POST /assemblies/:id/pcb/:pcbId/offer-state': endpoint({
        description: 'pcb price radar',
        pathParams: z.object({ id: z.string(), pcbId: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: CustomPartOfferResultResponseRuntype,
    }),

    'GET /assemblies/:assemblyId/resources': endpoint({
        description: 'Returns the resources (both CAD and additional files) of a given assembly',
        pathParams: z.object({ assemblyId: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: AssemblyResourcesRuntype,
    }),

    'GET /assemblies/:assemblyId/upload/cad': endpoint({
        description: 'Returns an Azure url to upload the CAD file given an assembly',
        pathParams: z.object({ assemblyId: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({
            data: z.object({
                url: z.string(),
            }),
        }),
        invalidates: ['GET /assemblies/:assemblyId/resources', 'POST /rfqs/:rfqId/customer-portal'],
    }),

    'GET /assemblies/:assemblyId/upload/other': endpoint({
        description: 'Returns an Azure url to upload the additional files given an assembly',
        pathParams: z.object({ assemblyId: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({
            data: z.object({
                url: z.string(),
            }),
        }),
        invalidates: ['GET /assemblies/:assemblyId/resources', 'POST /rfqs/:rfqId/customer-portal'],
    }),

    'DELETE /assemblies/:assemblyId/delete/cad': endpoint({
        description: 'Deletes a CAD file given its file name and assembly',
        pathParams: z.object({ assemblyId: z.string() }),
        queryParams: z.object({ blob_name: z.string() }),
        requestBody: z.undefined(),
        responseBody: z.string(),
    }),

    'DELETE /assemblies/:assemblyId/delete/other': endpoint({
        description: 'Deletes an additional file given its file name and assembly',
        pathParams: z.object({ assemblyId: z.string() }),
        queryParams: z.object({ blob_name: z.string() }),
        requestBody: z.undefined(),
        responseBody: z.string(),
    }),
    'POST /assemblies': endpoint({
        description: 'Creates an assembly',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: AssemblyFormRequest,
        responseBody: z.object({ data: AssemblyDTORuntype }),
        invalidates: [
            'GET /rfqs',
            'GET /rfqs/:rfqId',
            'GET /assemblies/:assemblyId/descendants',
            'GET /assemblies/:assemblyId/descendants-summary',
            'GET /assemblies/:assemblyId/resources',
            'POST /design-items/bulk',
            'GET /assemblies/:assemblyId/parents',
            'GET /assemblies/:assemblyId/aggregate-quantity',
        ],
    }),

    'DELETE /assemblies/:id/pcb': endpoint({
        description: 'Delete PCB for given assembly id.',
        pathParams: z.object({ id: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.unknown(),
        removes: [
            'GET /ems/pcb/v2/pcbs/:pcbId',
            'GET /assemblies/:assemblyId/descendants',
            'POST /assemblies/:id/pcb/:pcbId/offer-state',
        ],
    }),
    'DELETE /assemblies/:id/bom-design-items': endpoint({
        description: 'Delete BOM design items in assembly',
        pathParams: z.object({ id: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({
            deleted: z.number(),
        }),
        invalidates: [
            'GET /assemblies/:assemblyId/descendants',
            'GET /assemblies/:assemblyId/descendants-summary',
            'GET /assemblies/:id/history',
            'POST /sourcing-scenarios/bulk',
            'POST /sourcing/calculation',

            'GET /assemblies/:id/bom-file/resources',
            'POST /design-items/part-alternatives/bulk',
            'POST /design-items/many',
            'GET /assemblies/:assemblyId/state',
            'POST /design-items/history',
        ],
    }),

    'POST /assemblies/:id/duplicate': endpoint({
        // Its supposed to duplicate (top-level) assembly and its subassemblies and other sub entities
        // when we move away from "Assembly belongs-to Rfq" design
        description: 'Duplicates rfq',
        pathParams: z.object({ id: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.object({ new_rfq_name: z.string().optional() }),
        responseBody: z.object({
            rfq_id: z.string(),
            assemblies: z.array(z.string()),
            warning: z.literal('CouldNotDuplicateAllPcbs').optional(),
        }),
    }),

    'GET /assemblies/:id/bom-file/resources': endpoint({
        description: 'Returns a string of resources for a BOM file of a given assembly',
        pathParams: z.object({ id: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({
            items: z.array(z.string()),
        }),
    }),
    'GET /assemblies/:id/history': endpoint({
        description: 'Returns a list of assembly history events',
        pathParams: z.object({ id: z.string() }),
        queryParams: z.object({
            include_design_items: z.boolean(),
        }),
        requestBody: z.undefined(),
        responseBody: z.array(HistoryRecordResponseRuntype),
    }),
    'POST /assemblies/:id/history/file-uploaded': endpoint({
        description: 'Creates an event on the assembly when a file is uploaded',
        pathParams: z.object({ id: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.object({
            file_type: z.nativeEnum(UploadedFileType),
            file_name: z.string(),
        }),
        responseBody: z.unknown(),
    }),
    'PATCH /assemblies/:assemblyId': endpoint({
        description: 'Updates an assembly',
        pathParams: z.object({ assemblyId: z.string() }),
        queryParams: z.undefined(),
        requestBody: AssemblyFormRequest,
        responseBody: z.object({
            data: AssemblyDTORuntype,
        }),
        invalidates: [
            'GET /rfqs',
            'GET /rfqs/:rfqId',
            'GET /assemblies/:assemblyId/descendants',
            'GET /assemblies/:assemblyId/descendants-summary',
            'GET /assemblies/:assemblyId/resources',
            'POST /design-items/bulk',
            'GET /assemblies/:assemblyId/parents',
            'GET /assemblies/:assemblyId/aggregate-quantity',
            'POST /assemblies/bulk',
        ],
    }),

    'GET /assemblies/:assemblyId/monitoring': endpoint({
        description: 'Returns the monitoring data of a given assembly',
        pathParams: z.object({ assemblyId: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: AssemblyMonitoringRuntype,
    }),

    'POST /assemblies/:assemblyId/monitoring': endpoint({
        description: 'Updates the monitoring data of a given assembly',
        pathParams: z.object({ assemblyId: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.object({
            frequency: z.enum(['Daily', 'Weekly', 'Monthly', 'Inactive']),
            interests: z.object({
                lifecycle: z.boolean(),
                compliance: z.boolean(),
            }),
            users: z.array(z.string()),
        }),
        responseBody: AssemblyMonitoringRuntype,
        invalidates: ['GET /assemblies/:assemblyId/monitoring'],
    }),

    'POST /assemblies/:assemblyId/monitoring/test': endpoint({
        description: 'Sends out a test assembly parts changes email to the current user',
        pathParams: z.object({ assemblyId: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.null(),
    }),

    'GET /assemblies/:assemblyId/tree': endpoint({
        description: 'Returns entire BOM tree of assembly identifiers, which the assembly is part of',
        pathParams: z.object({ assemblyId: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({
            items: z.array(z.string()),
        }),
    }),
    'GET /assemblies/:assemblyId/parents': endpoint({
        description: 'Returns all parent assemblies of a given assembly',
        pathParams: z.object({ assemblyId: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: ParentsDTORuntype,
    }),
    'DELETE /assemblies/:assemblyId': endpoint({
        description: 'Deletes an assembly',
        pathParams: z.object({ assemblyId: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({
            deleted: z.number(),
        }),
    }),

    'GET /assemblies/:assemblyId/state': endpoint({
        description: 'Get the state of an assembly as an EMS',
        pathParams: z.object({ assemblyId: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: AssemblyStateRuntype,
    }),

    'GET /assemblies/:assemblyId/aggregate-quantity': endpoint({
        description: 'Get the total quantity of an Assembly inside a Sourcing Scenario',
        pathParams: z.object({ assemblyId: z.string() }),
        queryParams: z.object({ rfq_id: z.string() }),
        requestBody: z.undefined(),
        responseBody: z.object({
            // string is sourcingScenarioId, number is the quantity
            quantities: z.record(z.string(), z.number()),
        }),
    }),

    'GET /assemblies/:assemblyId/commentators': endpoint({
        description: 'Get the list of users who can comment on an assembly',
        pathParams: z.object({ assemblyId: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({ data: z.array(UserDTORuntype) }),
    }),

    'POST /assemblies/import': endpoint({
        description: 'Imports a list of assemblies',
        pathParams: z.undefined(),
        queryParams: z.object({ skip_missing_ipns: z.boolean().optional() }),
        requestBody: AssemblyImportRuntype,
        responseBody: z.unknown(),
    }),

    'GET /assemblies/:assemblyId/bom-files': endpoint({
        description: 'Get the list of bom-files for a given assembly',
        pathParams: z.object({ assemblyId: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({ data: z.array(BomFileDTORuntype) }),
    }),
};
