import { t, Trans } from '@lingui/macro';
import {
    colorSystem,
    Dialog,
    DialogContent,
    DialogTitle,
    EmptySourcingScenariosIcon,
    Flexbox,
    PrimaryButton,
    SecondaryButton,
    Text,
} from '@luminovo/design-system';
import { CircularProgress } from '@mui/material';
import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { useParams } from 'react-router-dom';
import { ErrorText, SpinnerWithBackdrop } from '../../../../../../../components/Spinners';
import { useAssemblyAggregateSourcingQuantity } from '../../../../../../../resources/assembly/assemblyHandler';
import { useHttpQuery } from '../../../../../../../resources/http/useHttpQuery';
import { useSourcingScenariosOfRfq } from '../../../../../../../resources/sourcingScenario/sourcingScenarioHandlers';
import { ScenarioCombinationForCalculationForm } from './ScenarioCombinationForCalculationForm';

const TitleComponent: React.FunctionComponent<{ title: React.ReactNode }> = ({ title }) => {
    return (
        <Text variant="h2" style={{ flexGrow: 1, textAlign: 'center' }}>
            {title}
        </Text>
    );
};

const EmptySourcingScenario = ({ onClose }: { onClose: () => void }) => {
    return (
        <Flexbox flexDirection={'column'} alignItems={'center'} gap={'16px'}>
            <EmptySourcingScenariosIcon />
            <Text variant="h2">
                <Trans>Unable to submit for calculation</Trans>
            </Text>
            <Text color={colorSystem.neutral[7]}>
                <Trans>We couldn’t find any sourcing scenario. Create one to submit for calculation.</Trans>
            </Text>
            <SecondaryButton onClick={onClose}>
                <Trans>Cancel</Trans>
            </SecondaryButton>
        </Flexbox>
    );
};

export const ScenarioCombinationForCalculationDialog = ({ assemblyId }: { assemblyId: string }) => {
    const [isOpen, setIsOpen] = React.useState<boolean>(false);

    const { rfqId, manufacturingAssemblyDetailsId } = useParams<{
        rfqId: string;
        manufacturingAssemblyDetailsId: string;
    }>();

    const { isLoading: isLoadingSourcingScenarios, data: sourcingScenarios = [] } = useSourcingScenariosOfRfq(rfqId);
    const { isLoading: isLoadingManufacturingScenarios, data: manufacturingScenarios } = useHttpQuery(
        'GET /manufacturing-scenarios',

        { queryParams: { manufacturing_assembly_details_id: manufacturingAssemblyDetailsId } },
    );

    const {
        isLoading: isLoadingScenarioCombinations,
        data: scenarioCombinations,
        isError,
    } = useHttpQuery('GET /calculations/scenario-combinations', {
        queryParams: { assembly_id: assemblyId },
    });

    const { isLoading: isAssemblyQuantitiesLoading, assemblyQuantities } = useAssemblyAggregateSourcingQuantity({
        assemblyId,
        rfqId,
        enabled: true,
    });

    const isLoading =
        isLoadingSourcingScenarios ||
        isLoadingManufacturingScenarios ||
        isLoadingScenarioCombinations ||
        isAssemblyQuantitiesLoading;
    const hasSourcingScenarios = sourcingScenarios.length > 0 && !isLoading;

    return (
        <>
            {isLoading ? (
                <SpinnerWithBackdrop noBackdrop={true} />
            ) : (
                <>
                    {isError ? (
                        <ErrorText />
                    ) : (
                        <PrimaryButton type="button" size="medium" onClick={() => setIsOpen(true)}>
                            <>
                                {scenarioCombinations?.length !== undefined && scenarioCombinations.length === 0 && (
                                    <Trans>Submit for calculation</Trans>
                                )}
                                {scenarioCombinations?.length !== undefined && scenarioCombinations.length > 0 && (
                                    <Trans>Edit submission for calculation</Trans>
                                )}
                            </>
                        </PrimaryButton>
                    )}
                </>
            )}

            <Dialog open={isOpen} maxWidth={hasSourcingScenarios ? 'md' : 'sm'}>
                <DialogTitle
                    onClose={() => setIsOpen(false)}
                    title={hasSourcingScenarios ? t`Submit for calculation` : ''}
                    style={{ marginTop: '32px', paddingTop: 0 }}
                    overrides={{ TitleComponent }}
                />
                <DialogContent style={{ marginBottom: '32px' }}>
                    {isLoading ? (
                        <CircularProgress size={'20px'} />
                    ) : (
                        <>
                            {hasSourcingScenarios && scenarioCombinations && assemblyQuantities ? (
                                <ScenarioCombinationForCalculationForm
                                    sourcingScenarios={sourcingScenarios}
                                    manufacturingScenarios={manufacturingScenarios?.data ?? []}
                                    existingScenarioCombinations={scenarioCombinations}
                                    assemblyId={assemblyId}
                                    assemblyQuantities={assemblyQuantities}
                                    onSubmitCallback={() => setIsOpen(false)}
                                />
                            ) : (
                                <EmptySourcingScenario onClose={() => setIsOpen(false)} />
                            )}
                        </>
                    )}
                </DialogContent>
            </Dialog>
        </>
    );
};
