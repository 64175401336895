import * as z from 'zod';
import { endpoint } from '../http/endpoint';
import { TtiCredentialsInputDTORuntype, TtiResponseBodyDTORuntype } from './ttiBackendTypes';

export const ttiEndpoints = {
    'GET /3rdparty/tti/credentials': endpoint({
        description: 'Returns credentials for tti integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: TtiResponseBodyDTORuntype,
    }),
    'POST /3rdparty/tti/credentials': endpoint({
        description: 'Uploads credentials for tti API integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: TtiCredentialsInputDTORuntype,
        responseBody: TtiResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/tti/credentials'],
    }),
    'PATCH /3rdparty/tti/credentials': endpoint({
        description: 'Updates credentials for tti API integratoin',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: TtiCredentialsInputDTORuntype,
        responseBody: TtiResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/tti/credentials'],
    }),
    'DELETE /3rdparty/tti/credentials': endpoint({
        description: 'Deletes existing credentials for tti API integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({ deleted: z.number() }),
        invalidates: ['GET /3rdparty/tti/credentials'],
    }),
};
