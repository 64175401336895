import { i18n, I18n } from '@lingui/core';
import { activateLocaleCommons, detectLocale, LocaleConfig, locales as localesCommons } from '@luminovo/commons';
import { locales as localesDesignSystem } from '@luminovo/design-system';
import { locales as localesManufacturingCore } from '@luminovo/manufacturing-core';
import { locales as localesSourcingCore } from '@luminovo/sourcing-core';
import { setTag } from '@sentry/react';
import * as deMessages from '../locales/de/merged';
import * as enMessages from '../locales/en/merged';
import * as esMessages from '../locales/es/merged';
import * as frMessages from '../locales/fr/merged';
import * as itMessages from '../locales/it/merged';

import { setLanguageCookieConsent } from './analytics/cookieConsent';

export { languageTranslations } from '@luminovo/commons';

const enLocale: LocaleConfig = {
    locale: 'en',
    messages: {
        ...enMessages.messages,
        ...localesCommons.en,
        ...localesDesignSystem.en,
        ...localesSourcingCore.en,
        ...localesManufacturingCore.en,
    },
};

const deLocale: LocaleConfig = {
    // eslint-disable-next-line spellcheck/spell-checker
    locale: 'de',
    messages: {
        ...deMessages.messages,
        ...localesCommons.de,
        ...localesDesignSystem.de,
        ...localesSourcingCore.de,
        ...localesManufacturingCore.de,
    },
};

const esLocale: LocaleConfig = {
    locale: 'es',
    messages: {
        ...esMessages.messages,
        ...localesCommons.es,
        ...localesDesignSystem.es,
        ...localesSourcingCore.es,
        ...localesManufacturingCore.es,
    },
};

const frLocale: LocaleConfig = {
    // eslint-disable-next-line spellcheck/spell-checker
    locale: 'fr',
    messages: {
        ...frMessages.messages,
        ...localesCommons.fr,
        ...localesDesignSystem.fr,
        ...localesSourcingCore.fr,
        ...localesManufacturingCore.fr,
    },
};

const itLocale: LocaleConfig = {
    locale: 'it',
    messages: {
        ...itMessages.messages,
        ...localesCommons.it,
        ...localesDesignSystem.it,
        ...localesSourcingCore.it,
        ...localesManufacturingCore.it,
    },
};

const determineLocaleConfig = (anyLocaleString: string): LocaleConfig => {
    if (anyLocaleString.startsWith('en')) {
        return enLocale;
    }
    // eslint-disable-next-line spellcheck/spell-checker
    if (anyLocaleString.startsWith('de')) {
        return deLocale;
    }
    if (anyLocaleString.startsWith('fr')) {
        return frLocale;
    }
    if (anyLocaleString.startsWith('es')) {
        return esLocale;
    }
    if (anyLocaleString.startsWith('it')) {
        return itLocale;
    }
    return enLocale;
};

/**
 * Loads & sets the given locale, thus translating the whole react tree.
 * If the locale is not known will attempt to approximate to one of the known locales.
 */
export const loadLocale = (locale: string): I18n => {
    const localeConfig = determineLocaleConfig(locale);
    activateLocale(localeConfig);
    activateLocaleCommons(localeConfig);
    setLanguageCookieConsent(localeConfig);
    return i18n;
};

const activateLocale = ({ locale, messages }: LocaleConfig) => {
    i18n.load(locale, messages);
    i18n.activate(locale);
};

/**
 * Initializes the i18n object by loading the locale based on the detected language.
 */
export const initializeI18n = (): I18n => {
    const locale = detectLocale();
    setTag('detected_locale', locale);
    return loadLocale(locale);
};

/**
 * Sort values by translation
 */
export type ValueWithTranslation<T> = { value: T; translation: string };

export function sortValuesByTranslation<T extends { translation: string }>(a: T, b: T) {
    return a.translation.localeCompare(b.translation);
}
