/**
 * Package Name Selection Components
 *
 * @important This file contains components for handling package name selection with enforced filtering behavior.
 * Always use BasePackageNameSelect or BasePackageNameSelectCreatable instead of FieldSelect/FieldSelectCreatable directly
 * when dealing with package names to maintain consistent filtering behavior
 *
 * Key features:
 * - Enforces packageNameFilterOptions for consistent filtering
 * - Filter options are configured to:
 *   - Trim whitespace
 *   - Match from the start of the string
 *
 * @example
 * <BasePackageNameSelect
 *   value={packageName}
 *   options={packageOptions}
 *   onChange={handleChange}
 * />
 */
import { t } from '@lingui/macro';
import { assertUnreachable, compareByString, uniq, uniqBy } from '@luminovo/commons';
import {
    FieldSelect,
    FieldSelectCreatable,
    FieldSelectCreatableProps,
    FieldSelectProps,
    Row,
    Tag,
    Tooltip,
} from '@luminovo/design-system';
import { PackageDTO, PackageMountingEnum } from '@luminovo/http-client';
import { createFilterOptions, Skeleton, TableCell } from '@mui/material';
import { useEffect, useState } from 'react';
import { useHasPermission } from '../../../../../permissions/useHasPermission';
import { FullPartWithApprovalStatus } from '../../../../../resources/part/partFrontendTypes';
import { ManufacturingDataContext } from '../ManufacturingDataTypes';
import { getManufacturingPartsFormValues } from '../getManufacturingPartsFormValues';

const packageNameFilterOptions = createFilterOptions<string | null>({
    trim: true,
    matchFrom: 'start',
});

/**
 * Props for BasePackageNameSelect component.
 * Explicitly removes the filterOptions prop to prevent users from overriding the default packageNameFilterOptions.
 * This ensures all package name selections use the same filtering behavior.
 */
interface FieldSelectPackageNameProps extends Omit<FieldSelectProps<string | null>, 'filterOptions'> {}

const BasePackageNameSelect = (props: FieldSelectPackageNameProps) => {
    return <FieldSelect {...props} filterOptions={packageNameFilterOptions} />;
};

/**
 * Props for BasePackageNameSelectCreatable component.
 * Explicitly removes the filterOptions prop to prevent users from overriding the default packageNameFilterOptions.
 * This ensures all package name selections use the same filtering behavior.
 */
interface FieldSelectPackageNameCreatableProps
    extends Omit<FieldSelectCreatableProps<string | null>, 'filterOptions'> {}

const BasePackageNameSelectCreatable = (props: FieldSelectPackageNameCreatableProps) => {
    return <FieldSelectCreatable {...props} filterOptions={packageNameFilterOptions} />;
};

const MIN_WIDTH = '180px';

export const RenderPackageName = (
    { data }: Row<FullPartWithApprovalStatus>,
    {
        isRfqEditable,
        packageData,
        editOtsPartTooltip,
        editCustomPartTooltip,
        editComponentToolTip,
        canEditComponents,
        canEditOtsParts,
        canEditCustomParts,
        onChangeOtsPart,
        onChangeCustomPart,
        isManufacturingDataMutating,
        onChangeComponent,
    }: ManufacturingDataContext,
): JSX.Element => {
    const value = getManufacturingPartsFormValues(data, packageData);
    const { mounting, package_name: packageName, number_of_pins: numberOfPins } = value;
    const isAdmin = useHasPermission(['edit:organization_settings']);
    const sortedPackageOptions = packageData.sort((a, b) => compareByString(a.name ?? '', b.name ?? ''));
    const [filteredPackageNameOptions, setFilteredPackageNameOptions] = useState<(string | null)[]>(
        uniq(sortedPackageOptions.filter((p) => p.mounting === mounting).map((p) => p.name)),
    );

    useEffect(() => {
        setFilteredPackageNameOptions(
            uniq(sortedPackageOptions.filter((p) => p.mounting === mounting).map((p) => p.name)),
        );
    }, [mounting, sortedPackageOptions]);

    if (isManufacturingDataMutating) {
        return (
            <TableCell>
                <Skeleton variant="text" width={148} height={24} />
            </TableCell>
        );
    }
    if (!isRfqEditable) {
        return <TableCell>{packageName ?? t`Unknown`}</TableCell>;
    }

    const partType = data.type;

    if (partType === 'Generic') {
        return (
            <TableCell>
                <Tag color="neutral" label={packageName ?? '-'} />
            </TableCell>
        );
    }

    const { id, canEdit, tooltip, onChange } = (() => {
        if (partType === 'Ipn' || partType === 'CustomComponent') {
            return {
                id: 'component_package_name',
                canEdit: canEditComponents,
                tooltip: editComponentToolTip,
                onChange: (packageName: string | null) =>
                    onChangeComponent({
                        partId: data.part.id,
                        mounting,
                        name: packageName,
                        number_of_pins: numberOfPins,
                    }),
            };
        }

        if (partType === 'OffTheShelf') {
            return {
                id: 'ots_package_name',
                canEdit: canEditOtsParts,
                tooltip: editOtsPartTooltip,
                onChange: (packageName: string | null) =>
                    onChangeOtsPart({
                        partId: data.part.id,
                        mounting,
                        name: packageName,
                        number_of_pins: numberOfPins,
                    }),
            };
        }

        if (partType === 'Custom') {
            return {
                id: 'custom_part_package_name',
                canEdit: canEditCustomParts,
                tooltip: editCustomPartTooltip,
                onChange: (packageName: string | null) =>
                    onChangeCustomPart({
                        partId: data.part.id,
                        mounting,
                        name: packageName,
                        number_of_pins: numberOfPins,
                    }),
            };
        }

        assertUnreachable(partType);
    })();

    return (
        <TableCell style={{ minWidth: MIN_WIDTH }}>
            <Tooltip title={tooltip}>
                <div>
                    {isAdmin ? (
                        <RenderPackageNameSelectCreatable
                            id={id}
                            packageName={packageName}
                            isEditable={canEdit}
                            filteredPackageNameOptions={filteredPackageNameOptions}
                            setFilteredPackageNameOptions={setFilteredPackageNameOptions}
                            onChange={onChange}
                        />
                    ) : (
                        <>
                            {mounting !== PackageMountingEnum.Other && (
                                <BasePackageNameSelect
                                    id={id}
                                    value={packageName ?? null}
                                    options={uniq(
                                        sortedPackageOptions.filter((p) => p.mounting === mounting).map((p) => p.name),
                                    )}
                                    getOptionLabel={(option: string | null) => option ?? t`Unknown`}
                                    disabled={!canEdit}
                                    placeholder={t`Unknown`}
                                    renderOption={(packageName: string | null) =>
                                        packageName ? <span data-testid={'package-name'}>{packageName}</span> : null
                                    }
                                    onChange={onChange}
                                    size="small"
                                />
                            )}
                            {mounting === PackageMountingEnum.Other && (
                                <RenderPackageForOtherMountingOpt
                                    id={id}
                                    packageName={packageName}
                                    isEditable={canEdit}
                                    sortedPackageOptions={sortedPackageOptions}
                                    onChange={onChange}
                                />
                            )}
                        </>
                    )}
                </div>
            </Tooltip>
        </TableCell>
    );
};

const RenderPackageForOtherMountingOpt = ({
    packageName,
    isEditable,
    sortedPackageOptions,
    onChange,
    id,
}: {
    packageName: string | null;
    isEditable: boolean;
    sortedPackageOptions: PackageDTO[];
    onChange: (packageName: string | null) => void;
    id: string;
}) => {
    // Special handling for the "Other" mounting option, because it allows creating new packages.
    const [packageNameOptionsForOthers, setPackageNameOptionsForOthers] = useState(() =>
        uniqBy(
            sortedPackageOptions.filter((p) => p.mounting === PackageMountingEnum.Other),
            (p) => p.name,
        ).map((p) => p.name),
    );

    return (
        <BasePackageNameSelectCreatable
            id={id}
            value={packageName ?? null}
            options={packageNameOptionsForOthers}
            getOptionLabel={(option: string | null) => option ?? t`Unknown`}
            placeholder={t`Unknown`}
            disabled={!isEditable}
            action={{
                label: t`Create`,
                onClick: (value) => {
                    setPackageNameOptionsForOthers((prev) => [...prev, value]);
                    onChange(value);
                },
            }}
            onChange={(value) => onChange(value)}
            size="small"
            style={{ minWidth: '120px' }}
        />
    );
};

function RenderPackageNameSelectCreatable({
    id,
    packageName,
    isEditable,
    filteredPackageNameOptions,
    setFilteredPackageNameOptions,
    onChange,
}: {
    id: string;
    packageName: string | null;
    isEditable: boolean;
    filteredPackageNameOptions: (string | null)[];
    setFilteredPackageNameOptions: React.Dispatch<React.SetStateAction<(string | null)[]>>;
    onChange: (packageName: string | null) => void;
}) {
    return (
        <BasePackageNameSelectCreatable
            id={id}
            value={packageName ?? null}
            options={filteredPackageNameOptions}
            getOptionLabel={(option: string | null) => option ?? t`Unknown`}
            placeholder={t`Unknown`}
            disabled={!isEditable}
            action={{
                label: t`Create`,
                onClick: (value) => {
                    setFilteredPackageNameOptions((prev) => [...prev, value]);
                    onChange(value);
                },
            }}
            onChange={(value) => onChange(value)}
            size="small"
            style={{ minWidth: '120px' }}
        />
    );
}
