import * as z from 'zod';
import { ApiIntegrationStatusRuntype } from './3rdPartyBackendTypes';

export interface MouserCredentialsDTO extends z.infer<typeof MouserCredentialsDTORuntype> {}
export const MouserCredentialsDTORuntype = z.object({
    api_key: z.string(),
});

export interface MouserCredentialsInputDTO extends z.infer<typeof MouserCredentialsInputDTORuntype> {}
export const MouserCredentialsInputDTORuntype = z.object({
    api_key: z.string().optional(),
});

export interface MouserResponseBodyDTO extends z.infer<typeof MouserResponseBodyDTORuntype> {}
export const MouserResponseBodyDTORuntype = z.object({
    data: MouserCredentialsDTORuntype.nullable(),
    status: ApiIntegrationStatusRuntype,
});
