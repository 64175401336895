import * as z from 'zod';
import { endpoint } from '../http/endpoint';
import { BomExportTemplateInputRuntype, BomExportTemplateRuntype } from './bomExportTemplateBackendTypes';

export const bomExportTemplateEndpoints = {
    'GET /export/bom/templates': endpoint({
        description: 'List all BOM export templates',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({
            items: z.array(BomExportTemplateRuntype),
        }),
    }),
    'POST /export/bom/templates': endpoint({
        description: 'Create a BOM export template',
        pathParams: z.undefined(),
        invalidates: ['GET /export/bom/templates'],
        queryParams: z.undefined(),
        requestBody: BomExportTemplateInputRuntype,
        responseBody: BomExportTemplateRuntype,
    }),
    'DELETE /export/bom/templates/:id': endpoint({
        description: 'Delete a BOM export template',
        pathParams: z.object({
            id: z.string(),
        }),
        invalidates: ['GET /export/bom/templates'],
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.unknown(),
    }),
    'PUT /export/bom/templates/:id': endpoint({
        description: 'Update a BOM export template',
        pathParams: z.object({
            id: z.string(),
        }),
        invalidates: ['GET /export/bom/templates'],
        queryParams: z.undefined(),
        requestBody: BomExportTemplateInputRuntype,
        responseBody: BomExportTemplateRuntype,
    }),
};
