import React from 'react';
import { PageLayout } from '../../components/PageLayout';
import { useRfQ } from '../../resources/rfq/rfqHandler';
import { UrlParams } from '../../utils/routes';
import { ViewContext } from '../Bom/components/ModuleTableData';
import { StackUpTab } from './StackUpTab';
import { ToolbarPcb } from './components/ToolbarPcb/ToolbarPcb';
import { useIsPcbEditable } from './utils/useIsPcbSectionEditable';

export const PcbStackUpPage = ({ pathParams }: UrlParams<'/rfqs/:rfqId/bom/assembly/:assemblyId/pcb/stackup'>) => {
    const { assemblyId, rfqId } = pathParams;
    const { data: rfq } = useRfQ(rfqId);
    const { isRfqEditable } = useIsPcbEditable(rfq);

    const viewContext: ViewContext = React.useMemo(() => {
        return {
            type: 'WithinRfQ',
            rfqId,
        };
    }, [rfqId]);

    return (
        <PageLayout header={<ToolbarPcb assemblyId={assemblyId} rfqId={rfqId} />} removeBottomPadding layout="fragment">
            <StackUpTab assemblyId={assemblyId} rfqId={rfqId} isEditable={isRfqEditable} viewContext={viewContext} />
        </PageLayout>
    );
};
