import { Trans } from '@lingui/macro';
import { getLocale, id } from '@luminovo/commons';
import { Flexbox, TertiaryButton, Text, colorSystem } from '@luminovo/design-system';
import { RfqDTO } from '@luminovo/http-client';
import { AutoAwesomeRounded } from '@mui/icons-material';
import { Box, Popover } from '@mui/material';
import React from 'react';
import {
    CustomerUserInviteFormState,
    UserInviteForm,
} from '../../../../modules/Customers/CustomerUserManagement/UserInviteForm';
import { useCustomer } from '../../../../resources/customer/customerHandler';
import { useMutationInviteExternalContributorUsers } from '../../../../resources/user/userHandler';
import { assertPresent } from '../../../../utils/assertPresent';
import { useHasAccessToCustomerPortal } from '../../../../utils/featureFlags';
import { nthValueInArray } from '../../../../utils/typingUtils';
import { useIsCustomer } from '../../../contexts/CurrentUserDetailsContext';
import { getCustomerPortalEducationLink } from '../ContributorsFormUtils';
import { ContributorsForm } from './components/ContributorsForm';
import { ExternalContributorsForm } from './components/ExternalContributorsForm';
import { InternalContributorsForm } from './components/InternalContributorsForm';

export const ShareRfqPopover = ({ rfq }: { rfq: RfqDTO }) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const { hasAccessToCustomerPortal } = useHasAccessToCustomerPortal();
    const isCustomer = useIsCustomer();
    const { data: customer } = useCustomer(rfq.customer);

    const [step, setStep] = React.useState<'add' | 'invite'>('add');

    const handleClick: React.MouseEventHandler<HTMLSpanElement> = (event) => {
        setStep('add');
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <TertiaryButton
                size="small"
                style={{ fontSize: '14px' }}
                onClick={handleClick}
                id={id('navbar/button_share')}
            >
                <Trans>Share</Trans>
            </TertiaryButton>

            <Popover
                elevation={2}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                PaperProps={{
                    style: {
                        background: colorSystem.neutral.white,
                        marginTop: '3px',
                        maxWidth: '500px',
                        maxHeight: '700px',
                        borderRadius: '0px 0px 8px 8px',
                        padding: '24px',
                    },
                }}
            >
                {step === 'add' && (
                    <Flexbox flexDirection={'column'} gap={'24px'}>
                        <Text variant="h3">
                            <Trans>Share {rfq.name}</Trans>
                        </Text>

                        {isCustomer ? (
                            <ContributorsForm rfq={rfq} />
                        ) : (
                            <>
                                <InternalContributorsForm rfq={rfq} />
                                <ExternalContributorsForm
                                    rfq={rfq}
                                    onInviteExternalContributor={() => setStep('invite')}
                                />
                            </>
                        )}
                    </Flexbox>
                )}

                {step === 'invite' && (
                    <Flexbox flexDirection={'column'} gap={'24px'}>
                        <Text variant="h3">
                            <Trans>Invite new user</Trans>
                        </Text>
                        {customer && (
                            <Text>
                                <Trans>
                                    The new external user will receive an invite via Email. They will be able to see all
                                    RfQs from {customer.name} and to view and comment on the Design module, as well as
                                    download any available Quotation.
                                </Trans>
                            </Text>
                        )}

                        {!hasAccessToCustomerPortal && (
                            <Box
                                display={'grid'}
                                gridTemplateColumns={'20px 1fr'}
                                gap={'16px'}
                                padding={'12px'}
                                style={{
                                    border: `1px solid ${colorSystem.primary[2]}`,
                                    backgroundColor: colorSystem.primary[1],
                                    borderRadius: '8px',
                                }}
                            >
                                <AutoAwesomeRounded style={{ color: colorSystem.primary[7], fontSize: '20px' }} />
                                <Flexbox flexDirection={'column'} alignItems={'flex-start'}>
                                    <Text>
                                        <Trans>
                                            Upgrade to the customer portal to allow external users to edit the BOM and
                                            submit new RfQs themselves.
                                        </Trans>
                                    </Text>
                                    <TertiaryButton
                                        onClick={() => window.open(getCustomerPortalEducationLink(), '_blank')}
                                        style={{ paddingInline: 0, paddingBottom: 0, height: 'unset' }}
                                    >
                                        <Trans>Learn more</Trans>
                                    </TertiaryButton>
                                </Flexbox>
                            </Box>
                        )}

                        <InviteForm rfq={rfq} onClose={() => setStep('add')} />
                    </Flexbox>
                )}
            </Popover>
        </>
    );
};

const InviteForm = ({ onClose, rfq }: { onClose: () => void; rfq: RfqDTO }) => {
    const initialValues: CustomerUserInviteFormState = {
        data: [{ first_name: '', last_name: '', email: '', language: getLocale(), user_role: 'Member' }],
    };
    const { mutateAsync } = useMutationInviteExternalContributorUsers(rfq.id);
    const handleSubmit = async (form: CustomerUserInviteFormState) => {
        await mutateAsync(assertPresent(nthValueInArray(form.data, 0)));
        onClose();
    };

    return <UserInviteForm initialValues={initialValues} onClose={() => onClose()} onSubmit={handleSubmit} />;
};
