import * as z from 'zod';
import { ApiIntegrationStatusRuntype } from './3rdPartyBackendTypes';

export interface TrustedPartsCredentialsDTO extends z.infer<typeof TrustedPartsCredentialsDTORuntype> {}
export const TrustedPartsCredentialsDTORuntype = z.object({
    company_id: z.string(),
    api_key: z.string(),
    locale: z.object({
        country: z.string(),
        currency: z.string(),
    }),
});

export interface TrustedPartsCredentialsInputDTO extends z.infer<typeof TrustedPartsCredentialsInputDTORuntype> {}
export const TrustedPartsCredentialsInputDTORuntype = z.object({
    company_id: z.string().optional(),
    api_key: z.string().optional(),
    locale: z
        .object({
            country: z.string(),
            currency: z.string(),
        })
        .optional(),
});

export interface TrustedPartsResponseBodyDTO extends z.infer<typeof TrustedPartsResponseBodyDTORuntype> {}
export const TrustedPartsResponseBodyDTORuntype = z.object({
    data: TrustedPartsCredentialsDTORuntype.nullable(),
    status: ApiIntegrationStatusRuntype,
});

export interface TrustedPartsSupportedSettingDTO extends z.infer<typeof TrustedPartsSupportedSettingDTORuntype> {}
export const TrustedPartsSupportedSettingDTORuntype = z.object({
    country: z.string(),
    currencies: z.array(z.string()),
});
