import { t } from '@lingui/macro';
import { Dialog, DialogContent, DialogTitle } from '@luminovo/design-system';
import { RfqContext } from '@luminovo/http-client';
import { useDialogContext } from '../../../../../components/contexts/ModalContext';
import { OtsPartEditForm } from './Forms/OtsPartEditForm';

export function useOtsPartEditDialog() {
    const { setDialog, closeDialog } = useDialogContext();

    return {
        openDialog: ({ otsPartId, rfqContext }: { otsPartId: string; rfqContext: RfqContext }) =>
            setDialog(
                <Dialog open={true} maxWidth={'md'} fullWidth={true} onClose={() => closeDialog()}>
                    <DialogTitle title={t`Edit off-the-shelf part`} onClose={() => closeDialog()} sticky={true} />

                    <DialogContent style={{ paddingBottom: '24px' }}>
                        <OtsPartEditForm otsPartId={otsPartId} rfqContext={rfqContext} onClose={closeDialog} />
                    </DialogContent>
                </Dialog>,
            ),
    };
}
