import { t } from '@lingui/macro';
import { Tag, Tooltip } from '@luminovo/design-system';
import { Controller, useFormContext } from 'react-hook-form';
import { ConsignmentButton } from '../../ConsignmentButton';
import { BomItemFormState } from '../../SpecificationTypeForms/BomItemFormState';
import { useEmsAndOemNames } from './useEmsAndOemNames';

export const SourcedByController = ({
    submitForm,
    isEditable,
    customerId,
}: {
    submitForm: () => void;
    isEditable: boolean;
    customerId?: string;
}) => {
    const { control, setValue, getValues } = useFormContext<BomItemFormState>();

    const isDnp = getValues('doNotPlace');

    return (
        <>
            {isEditable ? (
                <Tooltip title={isDnp ? t`BOM item is marked as do not place.` : ''}>
                    <span>
                        <Controller
                            name={'isConsigned'}
                            control={control}
                            render={({ field }): JSX.Element => {
                                return (
                                    <ConsignmentButton
                                        isConsigned={field.value}
                                        setValue={setValue}
                                        submitForm={submitForm}
                                        customerId={customerId}
                                        disabled={isDnp}
                                    />
                                );
                            }}
                        />
                    </span>
                </Tooltip>
            ) : (
                <SourcedByViewMode isConsigned={getValues('isConsigned')} customerId={customerId} />
            )}
        </>
    );
};

const SourcedByViewMode = ({ isConsigned, customerId }: { isConsigned: boolean; customerId?: string }) => {
    const { emsName, oemName } = useEmsAndOemNames({ customerId });

    return <Tag color="neutral" attention="low" label={t`Sourced by ${isConsigned ? oemName : emsName}`} />;
};
