import * as z from 'zod';
import { endpoint } from '../http/endpoint';
import { AvnetSilicaCredentialsInputDTORuntype, AvnetSilicaResponseBodyDTORuntype } from './avnetSilicaBackendTypes';

export const avnetSilicaEndpoints = {
    'GET /3rdparty/avnetsilica/credentials': endpoint({
        description: 'Returns credentials for avnetsilica integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: AvnetSilicaResponseBodyDTORuntype,
    }),
    'POST /3rdparty/avnetsilica/credentials': endpoint({
        description: 'Uploads credentials for avnetsilica API integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: AvnetSilicaCredentialsInputDTORuntype,
        responseBody: AvnetSilicaResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/avnetsilica/credentials'],
    }),
    'PATCH /3rdparty/avnetsilica/credentials': endpoint({
        description: 'Updates credentials for avnetsilica API integratoin',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: AvnetSilicaCredentialsInputDTORuntype,
        responseBody: AvnetSilicaResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/avnetsilica/credentials'],
    }),
    'DELETE /3rdparty/avnetsilica/credentials': endpoint({
        description: 'Deletes existing credentials for avnetsilica API integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({ deleted: z.number() }),
        invalidates: ['GET /3rdparty/avnetsilica/credentials'],
    }),
};
