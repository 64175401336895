import { extractDesignItems } from '@/modules/Sourcing/components/SolutionConfigurations/SolutionConfigurationTypes';
import * as React from 'react';
import { useHttpQuery } from '../http/useHttpQuery';
import { useSourcingCalculation } from '../sourcingScenario/sourcingScenarioHandlers';

export function useSolutionConfiguration(id: string | undefined) {
    return useHttpQuery(
        'GET /solution-configurations/:solutionConfigurationId',
        { pathParams: { solutionConfigurationId: id ?? '' } },
        {
            enabled: Boolean(id),
            select: (res) => res.data,
            staleTime: 10_000,
        },
    );
}

export function useDesignItemsFromSolutionConfigurationCalculation(solutionConfigurationId: string | undefined) {
    const { data: solutionConfiguration } = useSolutionConfiguration(solutionConfigurationId);
    const { data: sourcingCalculation } = useSourcingCalculation(solutionConfiguration?.sourcing_scenario);

    return React.useMemo(() => {
        if (!solutionConfiguration || !sourcingCalculation) {
            return { designItems: undefined, designItemPaths: undefined };
        }

        const solutionConfigurationSourcing = sourcingCalculation.solution_configurations.find(
            (item) => item.id === solutionConfigurationId,
        );

        if (!solutionConfigurationSourcing) {
            return { designItems: undefined, designItemPaths: undefined };
        }

        return extractDesignItems(
            solutionConfigurationSourcing.solution_configuration_summary.design_items,
            sourcingCalculation,
        );
    }, [solutionConfiguration, sourcingCalculation, solutionConfigurationId]);
}
