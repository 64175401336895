import * as z from 'zod';

// please keep in sync with `rust-workspace/epibator/src/routes/organization_settings/site/mod.rs` on the BE
export const CountryAlpha2CodeRuntype = z.union([
    z.literal('AF'),
    z.literal('AX'),
    z.literal('AL'),
    z.literal('DZ'),
    z.literal('AS'),
    z.literal('AD'),
    z.literal('AO'),
    z.literal('AI'),
    z.literal('AQ'),
    z.literal('AG'),
    z.literal('AR'),
    z.literal('AM'),
    z.literal('AW'),
    z.literal('AU'),
    z.literal('AT'),
    z.literal('AZ'),
    z.literal('BS'),
    z.literal('BH'),
    z.literal('BD'),
    z.literal('BB'),
    z.literal('BY'),
    z.literal('BE'),
    z.literal('BZ'),
    z.literal('BJ'),
    z.literal('BM'),
    z.literal('BT'),
    z.literal('BO'),
    z.literal('BQ'),
    z.literal('BA'),
    z.literal('BW'),
    z.literal('BV'),
    z.literal('BR'),
    z.literal('IO'),
    z.literal('BN'),
    z.literal('BG'),
    z.literal('BF'),
    z.literal('BI'),
    z.literal('CV'),
    z.literal('KH'),
    z.literal('CM'),
    z.literal('CA'),
    z.literal('KY'),
    z.literal('CF'),
    z.literal('TD'),
    z.literal('CL'),
    z.literal('CN'),
    z.literal('CX'),
    z.literal('CC'),
    z.literal('CO'),
    z.literal('KM'),
    z.literal('CG'),
    z.literal('CD'),
    z.literal('CK'),
    z.literal('CR'),
    z.literal('CI'),
    z.literal('HR'),
    z.literal('CU'),
    z.literal('CW'),
    z.literal('CY'),
    z.literal('CZ'),
    z.literal('DK'),
    z.literal('DJ'),
    z.literal('DM'),
    z.literal('DO'),
    z.literal('EC'),
    z.literal('EG'),
    z.literal('SV'),
    z.literal('GQ'),
    z.literal('ER'),
    z.literal('EE'),
    z.literal('ET'),
    z.literal('FK'),
    z.literal('FO'),
    z.literal('FJ'),
    z.literal('FI'),
    z.literal('FR'),
    z.literal('GF'),
    z.literal('PF'),
    z.literal('TF'),
    z.literal('GA'),
    z.literal('GM'),
    z.literal('GE'),
    z.literal('DE'),
    z.literal('GH'),
    z.literal('GI'),
    z.literal('GR'),
    z.literal('GL'),
    z.literal('GD'),
    z.literal('GP'),
    z.literal('GU'),
    z.literal('GT'),
    z.literal('GG'),
    z.literal('GN'),
    z.literal('GW'),
    z.literal('GY'),
    z.literal('HT'),
    z.literal('HM'),
    z.literal('VA'),
    z.literal('HN'),
    z.literal('HK'),
    z.literal('HU'),
    z.literal('IS'),
    z.literal('IN'),
    z.literal('ID'),
    z.literal('IR'),
    z.literal('IQ'),
    z.literal('IE'),
    z.literal('IM'),
    z.literal('IL'),
    z.literal('IT'),
    z.literal('JM'),
    z.literal('JP'),
    z.literal('JE'),
    z.literal('JO'),
    z.literal('KZ'),
    z.literal('KE'),
    z.literal('KI'),
    z.literal('KP'),
    z.literal('KR'),
    z.literal('KW'),
    z.literal('KG'),
    z.literal('LA'),
    z.literal('LV'),
    z.literal('LB'),
    z.literal('LS'),
    z.literal('LR'),
    z.literal('LY'),
    z.literal('LI'),
    z.literal('LT'),
    z.literal('LU'),
    z.literal('MO'),
    z.literal('MK'),
    z.literal('MG'),
    z.literal('MW'),
    z.literal('MY'),
    z.literal('MV'),
    z.literal('ML'),
    z.literal('MT'),
    z.literal('MH'),
    z.literal('MQ'),
    z.literal('MR'),
    z.literal('MU'),
    z.literal('YT'),
    z.literal('MX'),
    z.literal('FM'),
    z.literal('MD'),
    z.literal('MC'),
    z.literal('MN'),
    z.literal('ME'),
    z.literal('MS'),
    z.literal('MA'),
    z.literal('MZ'),
    z.literal('MM'),
    z.literal('NA'),
    z.literal('NR'),
    z.literal('NP'),
    z.literal('NL'),
    z.literal('NC'),
    z.literal('NZ'),
    z.literal('NI'),
    z.literal('NE'),
    z.literal('NG'),
    z.literal('NU'),
    z.literal('NF'),
    z.literal('MP'),
    z.literal('NO'),
    z.literal('OM'),
    z.literal('PK'),
    z.literal('PW'),
    z.literal('PS'),
    z.literal('PA'),
    z.literal('PG'),
    z.literal('PY'),
    z.literal('PE'),
    z.literal('PH'),
    z.literal('PN'),
    z.literal('PL'),
    z.literal('PT'),
    z.literal('PR'),
    z.literal('QA'),
    z.literal('RE'),
    z.literal('RO'),
    z.literal('RU'),
    z.literal('RW'),
    z.literal('BL'),
    z.literal('SH'),
    z.literal('KN'),
    z.literal('LC'),
    z.literal('MF'),
    z.literal('PM'),
    z.literal('VC'),
    z.literal('WS'),
    z.literal('SM'),
    z.literal('ST'),
    z.literal('SA'),
    z.literal('SN'),
    z.literal('RS'),
    z.literal('SC'),
    z.literal('SL'),
    z.literal('SG'),
    z.literal('SX'),
    z.literal('SK'),
    z.literal('SI'),
    z.literal('SB'),
    z.literal('SO'),
    z.literal('ZA'),
    z.literal('GS'),
    z.literal('SS'),
    z.literal('ES'),
    z.literal('LK'),
    z.literal('SD'),
    z.literal('SR'),
    z.literal('SJ'),
    z.literal('SZ'),
    z.literal('SE'),
    z.literal('CH'),
    z.literal('SY'),
    z.literal('TW'),
    z.literal('TJ'),
    z.literal('TZ'),
    z.literal('TH'),
    z.literal('TL'),
    z.literal('TG'),
    z.literal('TK'),
    z.literal('TO'),
    z.literal('TT'),
    z.literal('TN'),
    z.literal('TR'),
    z.literal('TM'),
    z.literal('TC'),
    z.literal('TV'),
    z.literal('UG'),
    z.literal('UA'),
    z.literal('AE'),
    z.literal('GB'),
    z.literal('UM'),
    z.literal('US'),
    z.literal('UY'),
    z.literal('UZ'),
    z.literal('VU'),
    z.literal('VE'),
    z.literal('VN'),
    z.literal('VG'),
    z.literal('VI'),
    z.literal('WF'),
    z.literal('EH'),
    z.literal('YE'),
    z.literal('ZM'),
    z.literal('ZW'),
]);

export const allCountryCodes = CountryAlpha2CodeRuntype.options.map((alternative) => alternative.value);

export type CountryAlpha2Code = z.infer<typeof CountryAlpha2CodeRuntype>;

const AddressRuntype = z.object({
    streetAddress: z.string().nullable(),
    city: z.string().nullable(),
    country: CountryAlpha2CodeRuntype.nullable(),
    postcode: z.string().nullable(),
});

export type SiteType = z.infer<typeof SiteTypeRuntype>;
export const SiteTypeRuntype = z.enum(['Manufacturing', 'Inventory', 'Sourcing']);

export type SiteDTO = z.infer<typeof SiteRuntype>;
export const SiteRuntype = z.object({
    id: z.string(),
    name: z.string(),
    address: AddressRuntype.nullable(),
    notes: z.string().nullable(),
    site_number: z.string().nullable(),
    is_default_for: z.array(SiteTypeRuntype),
    tags: z.array(SiteTypeRuntype),
});

export type SitePostDTO = z.infer<typeof SitePostRuntype>;
export const SitePostRuntype = SiteRuntype.omit({ id: true });
