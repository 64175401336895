import { http, ScenarioCombinationForCalculationPatchDTO } from '@luminovo/http-client';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { SubmitHandler } from 'react-hook-form';
import { useToken } from '../../../../../../../../resources/hooks';
import { useDebugErrorHandler } from '../../../../../../../../resources/http/debugErrorHandler';
import { invalidateQuery } from '../../../../../../../../resources/http/useHttpQuery';
import {
    createParsedData,
    ScenarioCombinationForCalculationFormInputs,
} from './scenarioCombinationForCalculationFormUtils';

export const useScenarioCombinationForCalculationFormFunction = (assemblyId: string, onSuccessCallback: () => void) => {
    const { token } = useToken();
    const queryClient = useQueryClient();
    const debugErrorHandler = useDebugErrorHandler();

    const { mutateAsync } = useMutation({
        mutationFn: (info: { values: ScenarioCombinationForCalculationPatchDTO[] }) => {
            return http(
                'PATCH /calculations/scenario-combinations',

                { requestBody: info.values, queryParams: { assembly_id: assemblyId } },
                token,
            );
        },
        onSuccess: async () => {
            await invalidateQuery(
                'GET /calculations/scenario-combinations',

                { queryParams: { assembly_id: assemblyId } },
                queryClient,
            );
            await invalidateQuery(
                'GET /calculations/calculation-assembly-costs',

                { queryParams: { assembly_id: assemblyId } },
                queryClient,
            );
            onSuccessCallback();
        },
        onError: debugErrorHandler,
    });

    const onSubmit: SubmitHandler<ScenarioCombinationForCalculationFormInputs> = (data) => {
        const parsedData = createParsedData(data);
        return mutateAsync({ values: parsedData });
    };

    return {
        onSubmit,
    };
};
