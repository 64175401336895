import { t, Trans } from '@lingui/macro';
import { id } from '@luminovo/commons';
import {
    colorSystem,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    PrimaryButton,
    SecondaryButton,
    TertiaryButton,
    Text,
} from '@luminovo/design-system';
import { styled } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useCurrentUserDetailsContext } from '../../../../../components/contexts/CurrentUserDetailsContext';
import { useDialogContext } from '../../../../../components/contexts/ModalContext';
import { useRequestTechnicalSupport } from '../../../../../resources/rfq/rfqHandler';

const DarkModeTertiaryButton = styled(TertiaryButton)({
    color: colorSystem.neutral.white,
    '&:hover': {
        background: 'none',
        color: colorSystem.primary[7],
    },
});

export const TechnicalSupportButton = ({ rfqId }: { rfqId: string }): JSX.Element => {
    const { openDialog: openRequestTechnicalSupportDialog } = useRequestTechnicalSupportDialog({ rfqId });

    return (
        <DarkModeTertiaryButton
            id={id('oem_dashboard/button_request_technical_support')}
            style={{ paddingLeft: 0, paddingRight: 0 }}
            onClick={() => openRequestTechnicalSupportDialog()}
        >
            {t`Or click here for technical support`}
        </DarkModeTertiaryButton>
    );
};

function useRequestTechnicalSupportDialog({ rfqId }: { rfqId: string }) {
    const { organization } = useCurrentUserDetailsContext();
    const { setDialog, closeDialog } = useDialogContext();
    const { mutateAsync } = useRequestTechnicalSupport(rfqId);
    const { enqueueSnackbar } = useSnackbar();

    const organizationName = organization.name;

    return {
        openDialog: () =>
            setDialog(
                <Dialog
                    open={true}
                    maxWidth={'sm'}
                    fullWidth={true}
                    transitionDuration={0}
                    onClose={() => closeDialog()}
                >
                    <DialogTitle
                        title={t`Request technical support?`}
                        style={{
                            paddingTop: '12px',
                            marginTop: '12px',
                        }}
                        onClose={() => closeDialog()}
                    />
                    <DialogContent>
                        <Text variant="body">
                            <p>
                                {t`${organizationName} will contact you in the customer portal and help you solve your remaining issues. Please try to resolve any outstanding issues yourself first, and only if you get stuck, contact technical support.`}
                            </p>
                            {t`Are you sure you want to notify ${organizationName}?`}
                        </Text>
                    </DialogContent>
                    <DialogActions>
                        <SecondaryButton onClick={() => closeDialog()}>
                            <Trans>No</Trans>
                        </SecondaryButton>
                        <PrimaryButton
                            onClick={async () => {
                                await mutateAsync();
                                closeDialog();
                                enqueueSnackbar(t`Requested technical support from ${organizationName}`, {
                                    variant: 'success',
                                });
                            }}
                        >
                            <Trans>Yes</Trans>
                        </PrimaryButton>
                    </DialogActions>
                </Dialog>,
            ),
    };
}
