import { thirdPartyEndpoints } from '../3rdParty';
import { activitiesEndpoints } from '../activity/activityEndpoints';
import { activityConfigurationEndpoints } from '../activityConfiguration/activityConfigurationEndpoints';
import { additionalServicesEndpoints } from '../additionalServices/additionalServicesEndpoints';
import { alertsEndpoints } from '../alerts/alertsEndpoints';
import { assemblyEndpoints } from '../assembly/assemblyEndpoints';
import { authEndpoints } from '../auth/authEndpoints';
import { bomExportTemplateEndpoints } from '../bomExportTemplate';
import { bomImporterEndpoints } from '../bomImporter/bomImporterEndpoints';
import { bomItemEndpoints } from '../bomItem/bomItemEndpoints';
import { calculationEndpoints } from '../calculation/calculationEndpoints';
import { calculationTemplatesEndpoints } from '../calculationTemplates/calculationTemplatesEndpoints';
import { commentsEndpoints } from '../comments/commentsEndpoints';
import { contributorEndpoints } from '../contributors/contributorEndpoints';
import { costedBomExportTemplateEndpoints } from '../costedBomExportTemplate';
import { customerEndpoints } from '../customer/customerEndpoints';
import { customerPortalSettingsEndpoints } from '../customerPortalSettings/customerPortalSettingsEndpoints';
import { demandEndpoints } from '../demand/demandEndpoints';
import { demandScenarioEndpoints } from '../demandScenario/demandScenarioEndpoints';
import { designItemEndpoints } from '../designItem/designItemEndpoints';
import { driversEndpoints } from '../driver/driversEndpoints';
import { emailTemplateEndpoints } from '../emailTemplates/emailTemplateEndpoints';
import { expenseConfigurationEndpoints } from '../expenseConfiguration/expenseConfigurationEndpoints';
import { expensesEndpoints } from '../expenses/expensesEndpoints';
import { exportEndpoints } from '../export/exportEndpoints';
import { formulaEndpoints } from '../formula/formulaEndpoints';
import { internalPartNumberEndpoints } from '../internalPartNumber/internalPartNumberEndpoint';
import { manufacturerEndpoints } from '../manufacturer/manufacturerEndpoints';
import { manufacturingAssemblyDetailsEndpoints } from '../manufacturingAssemblyDetails/manufacturingAssemblyDetailsEndpoints';
import { manufacturingSourcingCacheEndpoints } from '../manufacturingCache/manufacturingSourcingCacheEndpoints';
import { manufacturingScenarioTemplatesEndpoints } from '../manufacturingScenarioTemplates/manufacturingScenarioTemplates';
import { manufacturingScenarioEndpoints } from '../manufacturingScenarios/manufacturingScenarioEndpoints';
import { negotiationEndpoints } from '../negotiation/negotiationEndpoints';
import { offerEndpoints } from '../offer/offerEndpoints';
import { orderEndpoints } from '../orders';
import { organizationSettingsEndpoints } from '../organizationSettings/organizationSettingsEndpoints';
import { partEndpoints } from '../part/partEndpoints';
import { pcbEndpoints } from '../pcb/pcbEndpoints';
import { pcbFilesEndpoints } from '../pcb/pcbFilesEndpoints';
import { pcbPanelPreferenceEndpoints } from '../pcb/pcbPanelPreferenceEndpoints';
import { pnpEndpoints } from '../pnp/pnpEndpoints';
import { purchaseOrderEndpoints } from '../purchaseOrder/purchaseOrderEndpoints';
import { quotationEndpoints } from '../quotation/quotationEndpoints';
import { quoteTrackingEndpoints } from '../quoteTracking/quoteTrackingEndpoints';
import { resourceEndpoints } from '../resource/resourceEndpoints';
import { rfqEndpoints } from '../rfq/rfqEndpoints';
import { scrapEndpoints } from '../scrap/scrapEndpoints';
import { solutionEndpoints } from '../solution/solutionEndpoints';
import { solutionConfigurationEndpoints } from '../solutionConfiguration/solutionConfigurationEndpoints';
import { sourcingApisEndpoints } from '../sourcingApis/sourcingApisBackendEndpoints';
import { sourcingScenarioEndpoints } from '../sourcingScenario/sourcingScenarioEndpoints';
import { supplierEndpoints } from '../supplier/supplierEndpoints';
import { supplierAndStockLocationEndpoints } from '../supplierAndStockLocation/supplierAndStockLocationEndpoints';
import { supplierContactEndpoints } from '../supplierContact/supplierContactEndpoints';
import { supplierTermsEndpoints } from '../supplierTerms';
import { sustainabilityEndpoints } from '../sustainability/sustainabilityEndpoints';
import { tenantEndpoints } from '../tenant/tenantEndpoints';
import { totalCostOfOwnershipRulesEndpoints } from '../totalCostOfOwnershipRules/totalCostOfOwnershipRulesEndpoints';
import { userEndpoints } from '../user/userEndpoints';
import { userBasedEndpoints } from '../userBased/userBasedEndpoints';

/**
 * Required because the inferred type of `endpointRegistry` exceeded the maximum length the compiler could serialize.
 * So, an explicit type annotation was needed.
 */
type InnerActivitiesEndpoints = typeof activitiesEndpoints;
type InnerActivityConfigurationEndpoints = typeof activityConfigurationEndpoints;
type InnerAdditionalServicesEndpoints = typeof additionalServicesEndpoints;
type InnerAlertsEndpoints = typeof alertsEndpoints;
type InnerAssemblyEndpoints = typeof assemblyEndpoints;
type InnerAuthEndpoints = typeof authEndpoints;
type InnerBomExportTemplateEndpoints = typeof bomExportTemplateEndpoints;
type InnerBomImporterEndpoints = typeof bomImporterEndpoints;
type InnerBomItemEndpoints = typeof bomItemEndpoints;
type InnerCalculationEndpoints = typeof calculationEndpoints;
type InnerCalculationTemplatesEndpoints = typeof calculationTemplatesEndpoints;
type InnerCommentsEndpoints = typeof commentsEndpoints;
type InnerContributorEndpoints = typeof contributorEndpoints;
type InnerCostedBomExportTemplateEndpoints = typeof costedBomExportTemplateEndpoints;
type InnerCustomerEndpoints = typeof customerEndpoints;
type InnerCustomerPortalSettingsEndpoints = typeof customerPortalSettingsEndpoints;
type InnerDemandEndpoints = typeof demandEndpoints;
type InnerDemandScenarioEndpoints = typeof demandScenarioEndpoints;
type InnerDesignItemEndpoints = typeof designItemEndpoints;
type InnerDriversEndpoints = typeof driversEndpoints;
type InnerEmailTemplateEndpoints = typeof emailTemplateEndpoints;
type InnerExpenseConfigurationEndpoints = typeof expenseConfigurationEndpoints;
type InnerExpensesEndpoints = typeof expensesEndpoints;
type InnerExportEndpoints = typeof exportEndpoints;
type InnerFormulaEndpoints = typeof formulaEndpoints;
type InnerInternalPartNumberEndpoints = typeof internalPartNumberEndpoints;
type InnerManufacturerEndpoints = typeof manufacturerEndpoints;
type InnerManufacturingAssemblyDetailsEndpoints = typeof manufacturingAssemblyDetailsEndpoints;
type InnerManufacturingScenarioEndpoints = typeof manufacturingScenarioEndpoints;
type InnerManufacturingScenarioTemplatesEndpoints = typeof manufacturingScenarioTemplatesEndpoints;
type InnerManufacturingSourcingCacheEndpoints = typeof manufacturingSourcingCacheEndpoints;
type InnerNegotiationEndpoints = typeof negotiationEndpoints;
type InnerOfferEndpoints = typeof offerEndpoints;
type InnerOrderEndpoints = typeof orderEndpoints;
type InnerOrganizationSettingsEndpoints = typeof organizationSettingsEndpoints;
type InnerPartEndpoints = typeof partEndpoints;
type InnerPcbEndpoints = typeof pcbEndpoints;
type InnerPcbFilesEndpoints = typeof pcbFilesEndpoints;
type InnerPcbPanelPreferenceEndpoints = typeof pcbPanelPreferenceEndpoints;
type InnerPnpEndpoints = typeof pnpEndpoints;
type InnerPurchaseOrderEndpoints = typeof purchaseOrderEndpoints;
type InnerQuotationEndpoints = typeof quotationEndpoints;
type InnerQuoteTrackingEndpoints = typeof quoteTrackingEndpoints;
type InnerResourceEndpoints = typeof resourceEndpoints;
type InnerRfqEndpoints = typeof rfqEndpoints;
type InnerScrapEndpoints = typeof scrapEndpoints;
type InnerSolutionConfigurationEndpoints = typeof solutionConfigurationEndpoints;
type InnerSolutionEndpoints = typeof solutionEndpoints;
type InnerSourcingApisEndpoints = typeof sourcingApisEndpoints;
type InnerSourcingScenarioEndpoints = typeof sourcingScenarioEndpoints;
type InnerSupplierAndStockLocationEndpoints = typeof supplierAndStockLocationEndpoints;
type InnerSupplierContactPersonEndpoints = typeof supplierContactEndpoints;
type InnerSupplierEndpoints = typeof supplierEndpoints;
type InnerSupplierTermsEndpoints = typeof supplierTermsEndpoints;
type InnerSustainabilityEndpoints = typeof sustainabilityEndpoints;
type InnerTenantEndpoints = typeof tenantEndpoints;
type InnerThirdPartyEndpoints = typeof thirdPartyEndpoints;
type InnerTotalCostOfOwnershipRulesEndpoints = typeof totalCostOfOwnershipRulesEndpoints;
type InnerUserBasedEndpoints = typeof userBasedEndpoints;
type InnerUserEndpoints = typeof userEndpoints;

interface EndpointRegistry
    extends InnerAuthEndpoints,
        InnerActivitiesEndpoints,
        InnerActivityConfigurationEndpoints,
        InnerAdditionalServicesEndpoints,
        InnerAlertsEndpoints,
        InnerAssemblyEndpoints,
        InnerBomExportTemplateEndpoints,
        InnerBomImporterEndpoints,
        InnerBomItemEndpoints,
        InnerCalculationEndpoints,
        InnerCalculationTemplatesEndpoints,
        InnerCommentsEndpoints,
        InnerContributorEndpoints,
        InnerCostedBomExportTemplateEndpoints,
        InnerCustomerEndpoints,
        InnerCustomerPortalSettingsEndpoints,
        InnerDemandEndpoints,
        InnerDemandScenarioEndpoints,
        InnerDesignItemEndpoints,
        InnerDriversEndpoints,
        InnerEmailTemplateEndpoints,
        InnerExpenseConfigurationEndpoints,
        InnerExpensesEndpoints,
        InnerExportEndpoints,
        InnerFormulaEndpoints,
        InnerInternalPartNumberEndpoints,
        InnerManufacturerEndpoints,
        InnerManufacturingAssemblyDetailsEndpoints,
        InnerManufacturingScenarioEndpoints,
        InnerManufacturingScenarioTemplatesEndpoints,
        InnerManufacturingSourcingCacheEndpoints,
        InnerNegotiationEndpoints,
        InnerOfferEndpoints,
        InnerOrderEndpoints,
        InnerOrganizationSettingsEndpoints,
        InnerPartEndpoints,
        InnerPcbEndpoints,
        InnerPcbFilesEndpoints,
        InnerPcbPanelPreferenceEndpoints,
        InnerPnpEndpoints,
        InnerPurchaseOrderEndpoints,
        InnerPurchaseOrderEndpoints,
        InnerQuotationEndpoints,
        InnerQuoteTrackingEndpoints,
        InnerResourceEndpoints,
        InnerRfqEndpoints,
        InnerScrapEndpoints,
        InnerSolutionConfigurationEndpoints,
        InnerSolutionEndpoints,
        InnerSourcingApisEndpoints,
        InnerSourcingScenarioEndpoints,
        InnerSupplierAndStockLocationEndpoints,
        InnerSupplierContactPersonEndpoints,
        InnerSupplierEndpoints,
        InnerSupplierTermsEndpoints,
        InnerSustainabilityEndpoints,
        InnerTenantEndpoints,
        InnerThirdPartyEndpoints,
        InnerTotalCostOfOwnershipRulesEndpoints,
        InnerUserBasedEndpoints,
        InnerUserEndpoints {}

export const endpointRegistry: EndpointRegistry = {
    // Please keep this enumeration sorted alphabetically. Thanks.
    ...activitiesEndpoints,
    ...activityConfigurationEndpoints,
    ...additionalServicesEndpoints,
    ...alertsEndpoints,
    ...assemblyEndpoints,
    ...authEndpoints,
    ...bomExportTemplateEndpoints,
    ...bomImporterEndpoints,
    ...bomItemEndpoints,
    ...calculationEndpoints,
    ...calculationTemplatesEndpoints,
    ...commentsEndpoints,
    ...contributorEndpoints,
    ...costedBomExportTemplateEndpoints,
    ...customerEndpoints,
    ...customerPortalSettingsEndpoints,
    ...demandEndpoints,
    ...demandScenarioEndpoints,
    ...designItemEndpoints,
    ...driversEndpoints,
    ...emailTemplateEndpoints,
    ...expenseConfigurationEndpoints,
    ...expensesEndpoints,
    ...exportEndpoints,
    ...formulaEndpoints,
    ...internalPartNumberEndpoints,
    ...manufacturerEndpoints,
    ...manufacturingAssemblyDetailsEndpoints,
    ...manufacturingScenarioEndpoints,
    ...manufacturingScenarioTemplatesEndpoints,
    ...manufacturingSourcingCacheEndpoints,
    ...negotiationEndpoints,
    ...offerEndpoints,
    ...orderEndpoints,
    ...organizationSettingsEndpoints,
    ...partEndpoints,
    ...pcbEndpoints,
    ...pcbFilesEndpoints,
    ...pcbPanelPreferenceEndpoints,
    ...demandEndpoints,
    ...pnpEndpoints,
    ...purchaseOrderEndpoints,
    ...purchaseOrderEndpoints,
    ...quotationEndpoints,
    ...quoteTrackingEndpoints,
    ...resourceEndpoints,
    ...rfqEndpoints,
    ...scrapEndpoints,
    ...solutionConfigurationEndpoints,
    ...solutionEndpoints,
    ...sourcingApisEndpoints,
    ...sourcingScenarioEndpoints,
    ...supplierAndStockLocationEndpoints,
    ...supplierContactEndpoints,
    ...supplierEndpoints,
    ...supplierTermsEndpoints,
    ...sustainabilityEndpoints,
    ...tenantEndpoints,
    ...thirdPartyEndpoints,
    ...totalCostOfOwnershipRulesEndpoints,
    ...userBasedEndpoints,
    ...userEndpoints,
};
