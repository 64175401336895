import { AuthorizationParams, useAuth0 } from '@auth0/auth0-react';
import { usePersistedState } from '@luminovo/design-system';
import { useQueryClient } from '@tanstack/react-query';

/**
 * Currently used only for development purposes. We're only setting this manually in the dev console.
 */
export const AUTH0_CONNECTION_TYPE_SESSION_KEY = 'luminovo.auth0.connectionType';

export enum ConnectionType {
    // These are the actual values used by Auth0. Changing these values will break the login flow.
    passwordless = 'email',
    password = 'Username-Password-Authentication',
}

function deprecated_getConnectionType(): ConnectionType | undefined {
    const connectionType = localStorage.getItem(AUTH0_CONNECTION_TYPE_SESSION_KEY);
    if (connectionType === ConnectionType.passwordless) {
        return ConnectionType.passwordless;
    }
    if (connectionType === ConnectionType.password) {
        return ConnectionType.password;
    }
    return undefined;
}

export const AUTH0_AUTHORIZATION_PARAMS: AuthorizationParams = {
    audience: 'https://luminovo.ai/api',
    redirect_uri: window.location.origin + '/callback', // callback configured at https://manage.auth0.com/dashboard/eu/luminovo/applications/49APfyeNsIPNBAb2nMCUnmsh4Lu6epkM/settings
    connection: deprecated_getConnectionType(),
};

function setConnectionType(connectionType: ConnectionType | undefined): void {
    localStorage.setItem(AUTH0_CONNECTION_TYPE_SESSION_KEY, connectionType ?? '');
}

/**
 * A single hook that encapsulates all authentication logic in Luminovo.
 *
 * This hook can be safely called from a non-authenticated context.
 *
 */
export function useAuth({ auth0OrgId }: { auth0OrgId: string | undefined }): {
    loginWithRedirect: ({
        email,
        returnTo,
        connectionType,
    }: {
        email?: string;
        returnTo?: string;
        connectionType?: ConnectionType;
    }) => Promise<void>;
    logout: () => Promise<void>;
    isLoading: boolean;
    isAuthenticated: boolean;
    error: Error | undefined;
    email: string;
    getAccessTokenSilently: () => Promise<string>;
} {
    const auth0 = useAuth0();
    const [email, setEmail] = usePersistedState<string>('luminovo.email', auth0.user?.email ?? '', localStorage);
    const queryClient = useQueryClient();
    const authorizationParams: AuthorizationParams = {
        ...AUTH0_AUTHORIZATION_PARAMS,
        login_hint: email,
        organization: auth0OrgId,
        connection_: deprecated_getConnectionType(),
    };

    return {
        getAccessTokenSilently: () => auth0.getAccessTokenSilently({ authorizationParams }),
        isLoading: auth0.isLoading,
        isAuthenticated: auth0.isAuthenticated,
        error: auth0.error,
        email,
        loginWithRedirect: ({
            email,
            returnTo,
            connectionType,
        }: {
            email?: string;
            returnTo?: string;
            connectionType?: ConnectionType;
        }) => {
            return auth0
                .loginWithRedirect({
                    authorizationParams: {
                        ...authorizationParams,
                        login_hint: email,
                        connection: connectionType,
                    },
                    appState: {
                        returnTo: returnTo ?? window.location.pathname,
                    },
                })
                .then(() => {
                    setConnectionType(connectionType);
                    setEmail(email ?? '');
                });
        },
        logout: async () => {
            // Clear the connection type in case this is causing any issues.
            setConnectionType(undefined);
            setEmail('');
            await auth0.logout({ logoutParams: { returnTo: window.location.origin } });
            await queryClient.invalidateQueries();
        },
    };
}
