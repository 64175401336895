import { t, Trans } from '@lingui/macro';
import { isPresent } from '@luminovo/commons';
import { Flexbox, Message, SecondaryButton } from '@luminovo/design-system';
import { AssemblyFormDTO, AssemblyResponseDTO } from '@luminovo/http-client';
import React from 'react';
import { useHttpMutation } from '../../../../resources/mutation/useHttpMutation';
import { useRfQ } from '../../../../resources/rfq/rfqHandler';
import { ViewContext } from '../../../Bom/components/ModuleTableData';

export const BomImportAssemblyIssues = ({
    currentAssembly,
    viewContext,
}: {
    currentAssembly: AssemblyResponseDTO;
    viewContext: ViewContext;
}) => {
    const rfqId = viewContext.rfqId;

    const bomImportedAssemblyIssues = React.useMemo(() => {
        if (currentAssembly.origin.type !== 'BomImport' || !currentAssembly) return [];

        return currentAssembly.origin.value.issues;
    }, [currentAssembly]);

    const { mutateAsync } = useHttpMutation('PATCH /assemblies/:assemblyId', {
        snackbarMessage: t`Assembly successfully updated`,
    });

    const { isAutomaticallyGenerated, containsDuplicateCPNIssue, containsDuplicateIpnIssue } = React.useMemo(() => {
        let containsDuplicateIpnIssue = false;
        let containsDuplicateCPNIssue = false;
        let isAutomaticallyGenerated = false;
        for (const issue of bomImportedAssemblyIssues) {
            if (issue.issue_type === 'AutomaticallyGenerated') {
                isAutomaticallyGenerated = true;
            }
            if (issue.issue_type === 'ConflictingCpnIdentifier') {
                containsDuplicateCPNIssue = true;
            }
            if (issue.issue_type === 'ConflictingIpnIdentifier') {
                containsDuplicateIpnIssue = true;
            }
        }

        return {
            isAutomaticallyGenerated,
            containsDuplicateCPNIssue,
            containsDuplicateIpnIssue,
        };
    }, [bomImportedAssemblyIssues]);

    const containsBomImportIssues = isAutomaticallyGenerated || containsDuplicateCPNIssue || containsDuplicateIpnIssue;

    const { data: rfq } = useRfQ(rfqId ?? '', Boolean(rfqId));

    if (!containsBomImportIssues || !isPresent(rfq)) return null;

    const defaultFormForResolvingAutomaticallyGeneratedIssue: AssemblyFormDTO = {
        rfq: rfq.id,
        type: currentAssembly.type,
        notes: currentAssembly.notes ?? '',
        designator: currentAssembly.designator,
        customer: rfq.customer,
        industry: currentAssembly.industry,
        parents: Object.keys(currentAssembly.parents),
        sub_assembly_quantities: currentAssembly.subassemblies.items,
        ipn_value: currentAssembly.ipn?.value ?? '',
        ipn_revision: currentAssembly.ipn?.revision ?? '',
        // we just want to resolve the AutomaticallyGenerated issue
        bom_imported_issues_to_resolve: ['AutomaticallyGenerated'],
    };

    const handleSubmitAutomaticallyGeneratedIssue = () => {
        mutateAsync({
            pathParams: { assemblyId: currentAssembly.id },
            requestBody: defaultFormForResolvingAutomaticallyGeneratedIssue,
        });
    };

    return (
        <Flexbox gap="20px" flexDirection="column" justifyContent="space-between" padding="0px 24px 24px 24px">
            {isAutomaticallyGenerated && (
                <Message
                    attention={'high'}
                    variant={'yellow'}
                    size={'small'}
                    message={t`An assembly line was missing in your uploaded Excel file. We have automatically generated this assembly for you. Please review the generated assembly to ensure accuracy.`}
                    overrides={{
                        ActionButton: () => (
                            <SecondaryButton size={'small'} onClick={handleSubmitAutomaticallyGeneratedIssue}>
                                <Trans>Got it</Trans>
                            </SecondaryButton>
                        ),
                    }}
                />
            )}
            {(containsDuplicateCPNIssue || containsDuplicateIpnIssue) && (
                <Message
                    attention={'high'}
                    variant={'yellow'}
                    size={'small'}
                    message={t`There is another assembly with the same ${[
                        containsDuplicateCPNIssue && 'CPN',
                        containsDuplicateIpnIssue && 'IPN',
                    ]
                        .filter(Boolean)
                        .join(
                            ' and ',
                        )} but different content in the uploaded BOM. Please review these assemblies to ensure the data is correct. You can delete one of the duplicate assemblies, or adjust and reimport the original BOM.`}
                />
            )}
        </Flexbox>
    );
};
