import * as z from 'zod';
import { endpoint } from '../http/endpoint';
import {
    QuestComponentsCredentialsInputDTORuntype,
    QuestComponentsResponseBodyDTORuntype,
} from './questComponentsBackendTypes';

export const questComponentsEndpoints = {
    'GET /3rdparty/questcomponents/credentials': endpoint({
        description: 'Returns credentials for quest components integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: QuestComponentsResponseBodyDTORuntype,
    }),
    'POST /3rdparty/questcomponents/credentials': endpoint({
        description: 'Uploads credentials for quest components API integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: QuestComponentsCredentialsInputDTORuntype,
        responseBody: QuestComponentsResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/questcomponents/credentials'],
    }),
    'PATCH /3rdparty/questcomponents/credentials': endpoint({
        description: 'Updates credentials for quest components API integratoin',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: QuestComponentsCredentialsInputDTORuntype,
        responseBody: QuestComponentsResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/questcomponents/credentials'],
    }),
    'DELETE /3rdparty/questcomponents/credentials': endpoint({
        description: 'Deletes existing credentials for quest components API integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({ deleted: z.number() }),
        invalidates: ['GET /3rdparty/questcomponents/credentials'],
    }),
};
