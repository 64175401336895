import { compareByStringKey } from '@luminovo/commons';
import { chainComparators, compareByNumber } from '@luminovo/design-system';
import {
    SupplierAndStockLocationDTO,
    SupplierAndStockLocationWithPreferenceDTO,
    SupplierPreference,
} from '@luminovo/http-client';
import { formatSupplierAndStockLocationDTO } from '@luminovo/sourcing-core';
import React from 'react';
import { useSuspenseHttpQuery } from '../http/useHttpQuery';
import { useOrganizationSolutionPreferences } from '../organizationSettings/organizationSolutionPreferenceHandler';

function determinePreference(
    supplierAndStockLocation: SupplierAndStockLocationDTO,
    preferredSuppliers: Set<string>,
    approvedSuppliers: Set<string>,
    excludedSuppliers: Set<string>,
): SupplierPreference {
    if (preferredSuppliers.has(supplierAndStockLocation.id)) {
        return SupplierPreference.Preferred;
    }
    if (approvedSuppliers.has(supplierAndStockLocation.id)) {
        return SupplierPreference.Approved;
    }
    if (excludedSuppliers.has(supplierAndStockLocation.id)) {
        return SupplierPreference.Excluded;
    }
    return SupplierPreference.NotApproved;
}

// Preference to number mapping for sorting
const PREFERENCE_SORT_ORDER: Record<SupplierPreference, number> = {
    [SupplierPreference.Preferred]: 0,
    [SupplierPreference.Approved]: 1,
    [SupplierPreference.NotApproved]: 2,
    [SupplierPreference.Excluded]: 3,
};

function getSortedSuppliersWithPreferences(
    suppliers: SupplierAndStockLocationDTO[],
    preferredSuppliers: string[] = [],
    approvedSuppliers: string[] = [],
    excludedSuppliers: string[] = [],
): SupplierAndStockLocationWithPreferenceDTO[] {
    const preferredSet = new Set(preferredSuppliers);
    const approvedSet = new Set(approvedSuppliers);
    const excludedSet = new Set(excludedSuppliers);

    // Create memoized comparator
    const comparator = chainComparators<SupplierAndStockLocationDTO & { preference: SupplierPreference }>(
        compareByNumber((x) => PREFERENCE_SORT_ORDER[x.preference]),
        compareByStringKey((x) => formatSupplierAndStockLocationDTO(x)),
    );

    // Single pass: map and sort in one go
    return suppliers
        .map((supplierAndStockLocation) => ({
            ...supplierAndStockLocation,
            preference: determinePreference(supplierAndStockLocation, preferredSet, approvedSet, excludedSet),
        }))
        .sort(comparator);
}

export function useSupplierAndStockLocations() {
    return useSuspenseHttpQuery(
        'GET /suppliers-and-stock-locations',
        {},
        {
            select: (res) => Array.from(res.data).sort(compareByStringKey((x) => formatSupplierAndStockLocationDTO(x))),
        },
    );
}

export function useSortedSupplierAndStockLocationsByPreference() {
    const { data: organizationSolutionPreferencesDTO } = useOrganizationSolutionPreferences();
    const { data: suppliersAndStockLocations } = useSupplierAndStockLocations();

    return React.useMemo(() => {
        const preferredSuppliers = organizationSolutionPreferencesDTO.preferred_suppliers_and_stock_locations ?? [];
        const approvedSuppliers = organizationSolutionPreferencesDTO.approved_suppliers_and_stock_locations ?? [];
        const excludedSuppliers = organizationSolutionPreferencesDTO.excluded_suppliers_and_stock_locations ?? [];

        const data = getSortedSuppliersWithPreferences(
            Array.from(suppliersAndStockLocations),
            preferredSuppliers,
            approvedSuppliers,
            excludedSuppliers,
        );

        return { data };
    }, [suppliersAndStockLocations, organizationSolutionPreferencesDTO]);
}

export function useSupplierAndStockLocationsBySupplierId(supplierId: string) {
    const { data } = useSortedSupplierAndStockLocationsByPreference();
    return React.useMemo(() => ({ data: data.filter((s) => s.supplier.id === supplierId) }), [data, supplierId]);
}

export function useSupplierAndStockLocation(id?: string | null) {
    const { data } = useSortedSupplierAndStockLocationsByPreference();
    return React.useMemo(() => ({ data: data.find((s) => s.id === id) }), [data, id]);
}

export function useNonExcludedSupplierAndStockLocations() {
    const { data } = useSortedSupplierAndStockLocationsByPreference();
    return React.useMemo(() => ({ data: data.filter((s) => s.preference !== SupplierPreference.Excluded) }), [data]);
}
