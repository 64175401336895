import { LayoutCard } from '@/components/LayoutCard';
import { PageLayoutCollapsibleSidebar } from '@/components/PageLayoutCollapsibleSidebar';
import { useHttpMutation } from '@/resources/mutation/useHttpMutation';
import { useSortedSupplierAndStockLocationsByPreference } from '@/resources/supplierAndStockLocation/supplierAndStockLocationHandler';
import { useSupplierContacts } from '@/resources/supplierContact/supplierContactHandler';
import { t, Trans } from '@lingui/macro';
import { compareByString, languageTranslations, transEnum } from '@luminovo/commons';
import {
    colorSystem,
    createColumnHelper,
    DestructiveSecondaryButton,
    Flexbox,
    MenuButton,
    MenuItem,
    SecondaryButton,
    Table,
    TanStackTable,
    Tooltip,
    useConfirmationDialog,
    useTanStackTable,
} from '@luminovo/design-system';
import {
    SupplierAndStockLocationWithPreferenceDTO,
    SupplierContactDTO,
    SupplierPreference,
} from '@luminovo/http-client';
import { formatSupplierAndStockLocationDTO, SupplierAndStockLocationChip } from '@luminovo/sourcing-core';
import { Add, Delete, EditRounded, MoreHoriz } from '@mui/icons-material';
import { Box } from '@mui/material';
import React from 'react';
import { route } from '../../../utils/routes';
import { NavigationSidebarSupplier } from '../NavigationSidebarSupplier';
import { useDialogDeleteSupplierContact } from '../SupplierManagement/components/SupplierContactDialogs/DeleteSupplierContactDialog';
import { useDialogEditSupplierContact } from '../SupplierManagement/components/SupplierContactDialogs/EditSupplierContactDialog';

type SupplierContactFull = {
    supplierContact: SupplierContactDTO;
    supplierAndStockLocation: SupplierAndStockLocationWithPreferenceDTO;
};

function RowActionButton({ supplierContact }: { supplierContact: SupplierContactDTO }): JSX.Element {
    const { is_public } = supplierContact;

    const { openDialog: openEditDialog } = useDialogEditSupplierContact({
        supplierContact,
    });
    const { openDialog: openDeleteDialog } = useDialogDeleteSupplierContact({
        supplierContact,
    });

    return (
        <MenuButton appearance="tertiary" icon={<MoreHoriz />} size={'small'} style={{ margin: 0 }}>
            <Tooltip title={is_public ? t`This contact is public and cannot be edited` : ''} arrow={false}>
                <span>
                    <MenuItem
                        label={t`Edit contact`}
                        onClick={() => openEditDialog()}
                        startIcon={<EditRounded />}
                        disabled={is_public}
                    />

                    <MenuItem
                        label={t`Delete contact`}
                        onClick={() => openDeleteDialog()}
                        variant={'destructive'}
                        startIcon={<Delete />}
                        disabled={is_public}
                    />
                </span>
            </Tooltip>
        </MenuButton>
    );
}

const columnHelper = createColumnHelper<SupplierContactFull>();

const columns = [
    columnHelper.text('supplierContact.first_name', {
        size: 180,
        label: () => t`First name`,
        cell: (item) => item.getValue() ?? '-',
    }),

    columnHelper.text('supplierContact.last_name', {
        size: 180,
        label: () => t`Last name`,
        cell: (item) => item.getValue() ?? '-',
    }),

    columnHelper.text('supplierContact.email', {
        size: 200,
        label: () => t`Email`,
        cell: (item) => item.getValue() ?? '-',
    }),

    columnHelper.enum('supplierContact.user_language', {
        size: 100,
        label: () => t`Language`,
        getOptionLabel: (option) => transEnum(option, languageTranslations),
        cell: (item) => transEnum(item.getValue(), languageTranslations),
        initialVisibility: false,
    }),

    columnHelper.text((row) => formatSupplierAndStockLocationDTO(row.supplierAndStockLocation), {
        id: 'supplier.name',
        size: 200,
        label: () => t`Supplier`,
        cell: ({ row }) => (
            <SupplierAndStockLocationChip
                supplier={row.original.supplierAndStockLocation}
                isApproved={row.original.supplierAndStockLocation.preference === SupplierPreference.Approved}
                isPreferred={row.original.supplierAndStockLocation.preference === SupplierPreference.Preferred}
            />
        ),
    }),

    columnHelper.text('supplierAndStockLocation.supplier_number', {
        size: 100,
        label: () => t`Supplier number`,
        cell: (item) => item.getValue() ?? '-',
    }),

    columnHelper.action({
        id: 'actionMenu',
        size: 48,
        cell: ({ row }) => <RowActionButton supplierContact={row.original.supplierContact} />,
    }),
];

function useSupplierContactsTableData(): { data: SupplierContactFull[] | undefined } {
    const { data: suppliers } = useSortedSupplierAndStockLocationsByPreference();
    const { data: supplierContacts } = useSupplierContacts();

    const data = React.useMemo(
        () =>
            supplierContacts
                ?.flatMap((supplierContact) => {
                    const supplierAndStockLocation = suppliers?.find((s) => s.supplier.id === supplierContact.supplier);

                    if (!supplierAndStockLocation) {
                        return [];
                    }

                    return [
                        {
                            supplierContact,
                            supplierAndStockLocation,
                        },
                    ];
                })
                .sort((a, b) =>
                    compareByString(
                        formatSupplierAndStockLocationDTO(a.supplierAndStockLocation),
                        formatSupplierAndStockLocationDTO(b.supplierAndStockLocation),
                    ),
                ),
        [suppliers, supplierContacts],
    );

    return { data };
}

function ActionButton({ table }: { table: Table<SupplierContactFull> }) {
    const selectedSupplierContacts = table.getSelectedRowModel().rows.map((row) => row.original);

    const { mutateAsync, isPending: isLoading } = useHttpMutation('POST /supplier-contacts/bulk-delete', {
        snackbarMessage: t`Supplier contacts deleted`,
        onSuccess: () => {
            table.resetRowSelection();
        },
    });

    const { modal, setOpen } = useConfirmationDialog({
        title: t`Delete supplier contacts`,
        description: t`Are you sure you want to delete the selected supplier contacts?`,
        onConfirmation: () => {
            mutateAsync({ requestBody: { ids: selectedSupplierContacts.map((d) => d.supplierContact.id) } });
        },
    });

    return (
        <Flexbox gap={8}>
            {modal}
            {selectedSupplierContacts.length > 0 && (
                <DestructiveSecondaryButton
                    startIcon={<Delete />}
                    size="medium"
                    isLoading={isLoading}
                    onClick={() => {
                        setOpen(true);
                    }}
                >
                    <Trans>Delete supplier contacts</Trans>
                </DestructiveSecondaryButton>
            )}
            <SecondaryButton
                startIcon={<Add />}
                size="medium"
                href={route('/suppliers/supplier-contacts-importer/bulk')}
            >
                <Trans>Add supplier contacts</Trans>
            </SecondaryButton>
        </Flexbox>
    );
}

function SupplierContactsTable() {
    const { data } = useSupplierContactsTableData();

    const { table } = useTanStackTable({
        data,
        columns,
        enableColumnOrdering: true,
        enableColumnHiding: true,
        enablePersistentColumnFilters: true,
        enablePersistentGlobalFilter: true,
        enableExcelExport: true,
        enableSelection: {
            enabled: true,
            getRowId: (row) => row.supplierContact.id,
        },
        enableRowSelection: (row) => !row.original.supplierContact.is_public,
    });

    return <TanStackTable table={table} size={'medium'} ActionButton={ActionButton} />;
}

export function SupplierContactsPage() {
    return (
        <PageLayoutCollapsibleSidebar
            sidebar={<NavigationSidebarSupplier />}
            layoutVariant="fullWidth"
            style={{ background: colorSystem.neutral[1] }}
        >
            <LayoutCard title={t`Supplier contacts`} style={{ height: '100%' }}>
                <Box height={'calc(100% - 48px)'}>
                    <SupplierContactsTable />
                </Box>
            </LayoutCard>
        </PageLayoutCollapsibleSidebar>
    );
}
