import * as z from 'zod';
import { endpoint } from '../http/endpoint';
import { generalOfferEndpointsToInvalidate } from '../offer/offerEndpoints';
import {
    SingelSolutionConfigurationPatchResponseDTORuntype,
    SolutionConfigurationDTORuntype,
    SolutionConfigurationPatchDTORuntype,
    SolutionConfigurationsBulkPatchDTORuntype,
} from './solutionConfigurationBackendTypes';

export const solutionConfigurationEndpoints = {
    'GET /solution-configurations/:solutionConfigurationId': endpoint({
        description: 'GET a single solution configuration',
        pathParams: z.object({ solutionConfigurationId: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({
            data: SolutionConfigurationDTORuntype,
        }),
    }),

    'GET /solution-configurations': endpoint({
        description: 'Returns an array of solution configurations from a sourcing scenario',
        pathParams: z.undefined(),
        queryParams: z.object({
            sourcing_scenario: z.string(),
        }),
        requestBody: z.undefined(),
        responseBody: z.object({
            items: z.array(SolutionConfigurationDTORuntype),
        }),
    }),

    'PATCH /solution-configurations/:solutionConfigurationId': endpoint({
        description: 'Updates a solution configuration',
        pathParams: z.object({ solutionConfigurationId: z.string() }),
        queryParams: z.undefined(),
        requestBody: SolutionConfigurationPatchDTORuntype,
        responseBody: z.object({
            data: SingelSolutionConfigurationPatchResponseDTORuntype,
        }),
        invalidates: generalOfferEndpointsToInvalidate,
    }),

    'PATCH /solution-configurations': endpoint({
        description: 'Updates multiple solution configurations',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: SolutionConfigurationsBulkPatchDTORuntype,
        responseBody: z.object({
            items: z.array(SingelSolutionConfigurationPatchResponseDTORuntype),
        }),
        invalidates: [
            ...generalOfferEndpointsToInvalidate,
            'POST /sourcing/calculation',
            'GET /sourcing/progress/:rfqId', // If the scrap quantity is updated, we will trigger pcb offer fetching (if applicable)
        ],
    }),
};
