import { assertPresent } from '@luminovo/commons';
import { CenteredLayout, colorSystem } from '@luminovo/design-system';
import { PCBV2, PanelPostDTO, PanelizationFormModeDTO } from '@luminovo/http-client';
import { Box, CircularProgress } from '@mui/material';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import useDebounce from '../../../../useDebounce';
import { getSvgComponent, getSvgDimension } from '../../components/SVGPreview/StackUpPreviewer/utils/parseSvgString';
import { SvgImageRenderer } from '../../components/SVGPreview/SvgImageRenderer';
import { usePaddedViewBox } from '../../utils/usePaddedViewBox';
import { extractPcbWidthAndHeight } from '../utils/extractPcbWidthAndHeight';
import { PanelFormState } from '../utils/types';
import { usePanelDetailsImage } from '../utils/usePanelDetailsImage';
import { ExistingPanelRenderer } from './ExistingPanelRenderer';

export const EmptyPanelRenderer: React.FunctionComponent<{ children?: React.ReactNode }> = ({ children }) => {
    return (
        <Box
            height={'100%'}
            width={'100%'}
            style={{
                background: 'white',
                backgroundImage: `radial-gradient(${colorSystem.neutral[4]} 1px, transparent 0)`,
                backgroundSize: '15px 15px',
            }}
        >
            {children}
        </Box>
    );
};

export const PanelRenderer = ({
    activeTab,
    currentlyDisplayedPanelField,
    pcb,
}: {
    activeTab: PanelizationFormModeDTO;
    currentlyDisplayedPanelField: number;
    pcb: PCBV2;
}) => {
    const { control } = useFormContext<PanelFormState>();

    const data = useWatch({
        control,
        name: `data`,
    });

    const scope = useWatch({
        control,
        name: `scope`,
    });
    const panelDetails = Array.isArray(data) ? data[currentlyDisplayedPanelField] : null;

    const memoisedPanelDto = React.useMemo(() => {
        if (!panelDetails) return null;
        const data: PanelPostDTO =
            scope === 'PerPcb'
                ? {
                      type: 'PerPcb',
                      data: {
                          pcb: pcb.id,
                          panel_details: panelDetails,
                      },
                  }
                : {
                      type: 'PerSourcingScenario',
                      data: {
                          pcb: pcb.id,
                          sourcing_scenario: assertPresent(panelDetails.sourcingScenarioId),
                          panel_details: panelDetails,
                      },
                  };
        return data;
    }, [panelDetails, pcb.id, scope]);

    if (activeTab === 'Existing') {
        return <ExistingPanelRenderer />;
    }

    const { width, height } = extractPcbWidthAndHeight(pcb);

    if (!memoisedPanelDto || !width || !height) return <EmptyPanelRenderer />;

    return <DefaultPanelRenderer pcbWidth={width} pcbHeight={height} panelDetails={memoisedPanelDto} />;
};

const DefaultPanelRenderer = ({
    panelDetails,
    pcbHeight,
    pcbWidth,
}: {
    panelDetails: PanelPostDTO;
    pcbWidth: number;
    pcbHeight: number;
}) => {
    const svgString = usePanelDetailsImage({
        panelDetails: useDebounce(panelDetails, 1000),
        pcbWidth,
        pcbHeight,
    });

    if (!svgString || svgString === 'OK') {
        return (
            <EmptyPanelRenderer>
                <CenteredLayout>
                    <CircularProgress />
                </CenteredLayout>
            </EmptyPanelRenderer>
        );
    }

    return <PanelImageRenderer svgString={svgString} />;
};

const PanelImageRenderer: React.FunctionComponent<{ svgString: string }> = React.memo(function PanelImageRenderer({
    svgString,
}) {
    const { SvgComponent } = getSvgComponent(svgString);
    const componentDimensions = getSvgDimension(svgString);
    const { outerViewBox, innerViewBox } = usePaddedViewBox(componentDimensions);

    return (
        <SvgImageRenderer
            svgProps={{
                style: {
                    boxSizing: 'border-box',
                    width: '100%',
                    minWidth: '570px',
                    height: '100%',
                },
            }}
            viewBox={outerViewBox}
            controlOptions={['center', 'zoomIn', 'zoomOut']}
        >
            <SvgComponent viewBox={innerViewBox} />
        </SvgImageRenderer>
    );
});
