import { t } from '@lingui/macro';
import { ResourceType } from '@luminovo/http-client';
import * as Yup from 'yup';
import {
    isValueAlreadyUsed,
    mustBeANumberErrorMessage,
    mustBePositiveErrorMessage,
    requiredErrorMessage,
} from '../../../../utils/yupValidationUtils';

export const createResourceSchema = (existingNames: string[]): Yup.ObjectSchema => {
    return Yup.object().shape({
        name: Yup.string()
            .required(requiredErrorMessage())
            .test(
                'resource-name-used',
                t`A resource with this name already exists`,
                (value) => !isValueAlreadyUsed(value || '', existingNames, undefined),
            ),
        internalNumber: Yup.string().nullable(),
        type: Yup.mixed().oneOf(Object.values(ResourceType)).required(requiredErrorMessage),
        costPerHour: Yup.number()
            .typeError(mustBeANumberErrorMessage())
            .required(requiredErrorMessage)
            .positive(mustBePositiveErrorMessage),
        description: Yup.string().nullable(),
    });
};
