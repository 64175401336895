import * as z from 'zod';
import { BACKEND_HOST } from '../../const';
import { endpoint } from '../http/endpoint';
import { PublicTenantRuntype } from './tenantBackendTypes';

export const tenantEndpoints = {
    'GET /tenant/details': endpoint({
        description: 'Public endpoint to get details of a tenant',
        pathParams: z.undefined(),
        queryParams: z.object({
            tenant: z.string(),
        }),
        responseBody: PublicTenantRuntype,
        requestBody: z.undefined(),
        rootUrl: BACKEND_HOST + '/public',
    }),
};
