import { t } from '@lingui/macro';
import { assertUnreachable, throwErrorUnlessProduction, transEnum } from '@luminovo/commons';
import { colorSystem, CopyToClipboardTooltip, Flexbox, Tag, Text } from '@luminovo/design-system';
import {
    CustomPartTypeEnum,
    FullPart,
    isCustomComponentFull,
    isCustomFullPart,
    isGenericFullPart,
    isOtsComponentFull,
    isOtsFullPart,
    PartLite,
    PartLiteTypes,
} from '@luminovo/http-client';
import { formatPart } from '../../formatters';
import { customPartTypeTranslations, partSpecificationTypeTranslations } from '../../i18n';

export const LabelPart: React.FunctionComponent<{
    part: FullPart | undefined;
    assemblyNameForCustomPart?: string;
    enableCopyToClipboard?: boolean;
}> = ({ part, enableCopyToClipboard = false, assemblyNameForCustomPart: assemblyName }): JSX.Element => {
    if (!part) {
        return <></>;
    }

    if (isOtsFullPart(part)) {
        return <>{formatPart(part)}</>;
    }

    if (isOtsComponentFull(part) || isCustomComponentFull(part)) {
        return (
            <Flexbox gap={4} alignItems={'baseline'}>
                <Tag attention={'low'} color={'blue'} label={t`IPN`} />
                <Text variant="inherit" color={'inherit'} style={{ whiteSpace: 'nowrap' }}>
                    {part.id}
                </Text>
            </Flexbox>
        );
    }

    if (isGenericFullPart(part)) {
        return (
            <Flexbox gap={4} alignItems={'baseline'}>
                <Tag attention={'low'} color={'green'} label={t`Generic`} />
                <Text variant="inherit" color={'inherit'} style={{ whiteSpace: 'nowrap' }}>
                    {transEnum(part.content.type, partSpecificationTypeTranslations)}
                </Text>
            </Flexbox>
        );
    }

    if (isCustomFullPart(part)) {
        if (part.type.name === CustomPartTypeEnum.PCB) {
            return (
                <Flexbox gap={4} alignItems={'baseline'}>
                    <Tag attention={'low'} color={'green'} label={t`PCB`} />
                    {assemblyName && (
                        <Text variant="inherit" color={'inherit'} style={{ whiteSpace: 'nowrap' }}>
                            {assemblyName}
                        </Text>
                    )}
                </Flexbox>
            );
        }

        return (
            <Flexbox gap={4} alignItems={'baseline'}>
                <Tag attention={'low'} color={'blue'} label={t`Custom`} />{' '}
                <Text variant="inherit" color={'inherit'} style={{ whiteSpace: 'nowrap' }}>
                    {transEnum(part.type.name, customPartTypeTranslations)}
                </Text>{' '}
                <CopyToClipboardTooltip
                    value={enableCopyToClipboard && part.description ? part.description : undefined}
                >
                    <Text
                        variant="body-small"
                        color={colorSystem.neutral[6]}
                        style={{ whiteSpace: 'nowrap' }}
                    >{`${part.description}`}</Text>
                </CopyToClipboardTooltip>
            </Flexbox>
        );
    }

    assertUnreachable(part);
};

export const LabelPartLite = ({
    part,
    formattedPart,
    pcbAssemblyName,
    enableCopyToClipboard = false,
}: {
    part: PartLite;
    formattedPart: string;
    pcbAssemblyName?: string;
    enableCopyToClipboard?: boolean;
}): JSX.Element => {
    const kind = part.kind;
    switch (kind) {
        case PartLiteTypes.OffTheShelf:
            return <>{formattedPart}</>;
        case PartLiteTypes.Generic:
            return (
                <Flexbox gap={4} alignItems={'baseline'}>
                    <Tag attention={'low'} color={'green'} label={t`Generic`} />
                    <Text variant="inherit" color={'inherit'} style={{ whiteSpace: 'nowrap' }}>
                        {transEnum(part.content.type, partSpecificationTypeTranslations)}
                    </Text>
                </Flexbox>
            );
        case PartLiteTypes.Ipn:
        case PartLiteTypes.CustomComponent:
            return (
                <Flexbox gap={4} alignItems={'baseline'}>
                    <Tag attention={'low'} color={'blue'} label={t`IPN`} />
                    <Text variant="inherit" color={'inherit'} style={{ whiteSpace: 'nowrap' }}>
                        {part.id}
                    </Text>
                </Flexbox>
            );
        case PartLiteTypes.Custom:
            if (part.type.name === CustomPartTypeEnum.PCB) {
                return (
                    <Flexbox gap={4} alignItems={'baseline'}>
                        <Tag attention={'low'} color={'green'} label={t`PCB`} />
                        {pcbAssemblyName && (
                            <Text variant="inherit" color={'inherit'} style={{ whiteSpace: 'nowrap' }}>
                                {pcbAssemblyName}
                            </Text>
                        )}
                    </Flexbox>
                );
            }
            return (
                <Flexbox gap={4} alignItems={'baseline'}>
                    <Tag attention={'low'} color={'blue'} label={t`Custom`} />
                    <Text variant="inherit" color={'inherit'} showEllipsis={'ellipsisOnly'}>
                        {transEnum(part.type.name, customPartTypeTranslations)}
                    </Text>
                    <CopyToClipboardTooltip
                        value={enableCopyToClipboard && part.description ? part.description : undefined}
                    >
                        <Text
                            variant="body-small"
                            color={colorSystem.neutral[6]}
                            showEllipsis={'ellipsisOnly'}
                        >{`${part.description}`}</Text>
                    </CopyToClipboardTooltip>
                </Flexbox>
            );
        case PartLiteTypes.RawSpecification:
        case PartLiteTypes.Unknown:
            // Unknown should not be displayed as a label
            throwErrorUnlessProduction(new Error(`Encountered part lite of type "Unknown": ${part}`));
            return <>{formattedPart}</>;
        default:
            assertUnreachable(kind);
    }
};
