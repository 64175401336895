import { t } from '@lingui/macro';
import { CircularProgress, Drawer, DrawerProps } from '@mui/material';
import { ErrorBoundary, FallbackRender } from '@sentry/react';
import React from 'react';
import { CenteredLayout } from '../layout/CenteredLayout';
import { NonIdealState } from '../NonIdealState';

type RightBoxDrawerProps = Omit<DrawerProps, 'onClose' | 'open'> & {
    onClose: () => void;
    FallbackComponent?: FallbackRender;
};

const Fallback: React.FunctionComponent = () => {
    return (
        <CenteredLayout width={'400px'} height={'100%'}>
            <CircularProgress size={40} color="primary" />
        </CenteredLayout>
    );
};

const ErrorFallback: FallbackRender = ({ error }) => {
    // Handle error safely, as it's of type unknown
    const errorMessage =
        error instanceof Error ? error.message : t`An unexpected error occurred. Please try again or reload the page.`;

    return (
        <CenteredLayout width={'400px'} height={'100%'}>
            <NonIdealState title="Error" description={errorMessage} />
        </CenteredLayout>
    );
};

export const RightBoxDrawer = React.forwardRef<unknown, RightBoxDrawerProps>(
    ({ onClose, children, FallbackComponent, ...props }, _) => {
        return (
            <Drawer
                anchor={'right'}
                open={true}
                onClose={onClose}
                ModalProps={{ BackdropProps: { invisible: true } }}
                PaperProps={{ style: { boxShadow: '-4px 0px 20px 0px rgba(42, 49, 71, 0.1)' } }}
                // The stop propagation below is required => otherwise the onClick handler of component containing the drawer is triggered.
                onClick={(event): void => event.stopPropagation()}
                {...props}
            >
                <ErrorBoundary fallback={FallbackComponent ?? ErrorFallback}>
                    <React.Suspense fallback={<Fallback />}>{children}</React.Suspense>
                </ErrorBoundary>
            </Drawer>
        );
    },
);
