import { Trans, t } from '@lingui/macro';
import {
    Flexbox,
    SecondaryButton,
    StickyLayout,
    Text,
    Tooltip,
    colorSystem,
    useNavigate,
} from '@luminovo/design-system';
import { AssemblyResponseDTO, BomItemApprovalStatus } from '@luminovo/http-client';
import { FilterListRounded, InfoRounded } from '@mui/icons-material';
import { useCallback, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { SearchInput } from '../../../../components/SearchInput';
import { id } from '../../../../utils/ids';
import { QueryParams, route } from '../../../../utils/routes';
import { ModuleTableData, ViewContext, isAssemblyTableData } from '../../../Bom/components/ModuleTableData';
import { useBomFilters } from '../../../Bom/components/ModuleTableData/filters';
import { WarningSubCategoryBomItemIssues } from '../../countWarningsAcrossBomItems';
import { Direction, useKeyUpDown } from '../../useKeyUpDown';
import { BomLinePreviews } from '../BomLinePreviews';
import { BomSummary } from '../BomSummary/BomSummary';
import { FilterIssuesInBomForm, useTrackFiltersEvents } from '../FilterIssuesInBomForm';
import { StatusFilters } from '../StatusFilters';
import { WarningsAccordion } from '../WarningsAccordion';
import { AssemblyStructure } from './AssemblyStructure';
import { getNextSelectedBomItemId } from './getNextSelectedBomItemId';
import { useDashboardFilters } from './useDashboardFilters';
import { useFilterBomTableData } from './useFilterBomModules';

export const BomDetailsSidebar = ({
    assemblyId,
    assembly,
    queryParams,
    viewContext,
    moduleData,
    selectedModuleId,
    selectedBomItem,
}: {
    assemblyId: string;
    assembly: AssemblyResponseDTO | undefined;
    queryParams: QueryParams<'/rfqs/:rfqId/bom/assembly/:assemblyId/details'>;
    viewContext: ViewContext;
    moduleData: ModuleTableData[];
    selectedModuleId: string[] | undefined;
    selectedBomItem: ModuleTableData | undefined;
}) => {
    const bomItemsTableData = useMemo(() => moduleData.filter((m) => m.moduleType !== 'assembly'), [moduleData]);
    const hasAnyBomItemWithCpnsFromBom = useMemo(() => {
        return bomItemsTableData.some((m) => m.bomCpns.length > 0);
    }, [bomItemsTableData]);
    const { currentParentAssemblyId } = queryParams;
    const location = useLocation();
    const navigate = useNavigate();
    const [selectedStatus, setSelectedStatus] = useState<BomItemApprovalStatus | undefined>();
    const [warningSubCategoryIssues, setWarningSubCategoryIssues] = useState<Set<WarningSubCategoryBomItemIssues>>(
        new Set(),
    ); // for filtering issues within each status, i.e., compliance, lifecycle etc. within the warning status
    const [searchedText, setSearchedText] = useState<string>(queryParams.search ?? '');
    const [isFiltersOpen, setIsFiltersOpen] = useState<boolean>(false);
    const [appliedFilters, setAppliedFilters] = useBomFilters(queryParams.filters);

    useTrackFiltersEvents(isFiltersOpen, appliedFilters);

    const filteredByDashboardFilters = useDashboardFilters({
        urlSearch: location.search,
        modules: bomItemsTableData,
        assemblyId,
        viewContext,
    });
    const onlyShowItemsWithManufacturingWarnings = queryParams.onlyShowItemsWithManufacturingWarnings === 'true';
    const filteredModules = useFilterBomTableData({
        modules: bomItemsTableData,
        filters: {
            selectedStatus,
            searchedText,
            appliedFilters,
            warningSubCategoryIssues,
            onlyShowItemsWithManufacturingWarnings,
        },
        filteredByDashboardFilters,
    });

    const handleSelectBomItem = useCallback(
        (module: ModuleTableData) => {
            if (viewContext.type === 'WithinRfQ')
                return navigate(
                    route(
                        '/rfqs/:rfqId/bom/assembly/:assemblyId/details',
                        {
                            rfqId: viewContext.rfqId,
                            assemblyId,
                        },
                        {
                            isReadonly: queryParams.isReadonly,
                            currentParentAssemblyId,
                            designItemId: isAssemblyTableData(module) ? module.id : module.id.join(','),
                            filters: queryParams.filters,
                            bomTab: queryParams.bomTab,
                            dashboardFilters: queryParams.dashboardFilters,
                            search: null,
                            onlyShowItemsWithManufacturingWarnings: queryParams.onlyShowItemsWithManufacturingWarnings,
                        },
                    ),
                    { replace: true },
                );
            return navigate(
                route(
                    '/assemblies/:assemblyId/details',
                    { assemblyId },
                    {
                        designItemId: isAssemblyTableData(module) ? module.id : module.id.join(','),
                        filters: queryParams.filters,
                        dashboardFilters: queryParams.dashboardFilters,
                        search: null,
                    },
                ),
                { replace: true },
            );
        },
        [
            viewContext.type,
            viewContext.rfqId,
            navigate,
            assemblyId,
            queryParams.isReadonly,
            queryParams.filters,
            queryParams.bomTab,
            queryParams.dashboardFilters,
            queryParams.onlyShowItemsWithManufacturingWarnings,
            currentParentAssemblyId,
        ],
    );

    const handleSelectNextModule = useCallback(
        (direction: Direction = 'ArrowDown') => {
            const nextSelectedModuleId = getNextSelectedBomItemId({
                direction,
                selectedModuleId,
                filteredModules,
            });

            if (viewContext.type === 'WithinRfQ')
                return navigate(
                    route(
                        '/rfqs/:rfqId/bom/assembly/:assemblyId/details',
                        { rfqId: viewContext.rfqId, assemblyId },
                        {
                            isReadonly: queryParams.isReadonly,
                            designItemId: Array.isArray(nextSelectedModuleId)
                                ? nextSelectedModuleId.join(',')
                                : (nextSelectedModuleId ?? null),
                            currentParentAssemblyId,
                            filters: queryParams.filters,
                            bomTab: queryParams.bomTab,
                            dashboardFilters: queryParams.dashboardFilters,
                            search: null,
                            onlyShowItemsWithManufacturingWarnings: queryParams.onlyShowItemsWithManufacturingWarnings,
                        },
                    ),
                    { replace: true },
                );

            return navigate(
                route(
                    '/assemblies/:assemblyId/details',
                    { assemblyId },
                    {
                        designItemId: Array.isArray(nextSelectedModuleId)
                            ? nextSelectedModuleId.join(',')
                            : (nextSelectedModuleId ?? null),
                        filters: queryParams.filters,
                        dashboardFilters: queryParams.dashboardFilters,
                        search: null,
                    },
                ),
                { replace: true },
            );
        },
        [
            selectedModuleId,
            filteredModules,
            viewContext.type,
            viewContext.rfqId,
            navigate,
            assemblyId,
            queryParams.isReadonly,
            queryParams.bomTab,
            queryParams.filters,
            queryParams.dashboardFilters,
            queryParams.onlyShowItemsWithManufacturingWarnings,
            currentParentAssemblyId,
        ],
    );

    useKeyUpDown(handleSelectNextModule);

    const handleSelectStatus = useCallback(
        (newStatus: BomItemApprovalStatus | undefined) => {
            setAppliedFilters(new Set());
            setSelectedStatus(newStatus);
            setWarningSubCategoryIssues(new Set());
            // when the user selects status 'all', display the BomImportSummary
            if (!newStatus) {
                if (viewContext.type === 'WithinRfQ')
                    return navigate(
                        route(
                            '/rfqs/:rfqId/bom/assembly/:assemblyId/details',
                            { assemblyId, rfqId: viewContext.rfqId },
                            {
                                isReadonly: null,
                                designItemId: null,
                                currentParentAssemblyId,
                                filters: null,
                                bomTab: null,
                                dashboardFilters: null,
                                search: null,
                                onlyShowItemsWithManufacturingWarnings: null,
                            },
                        ),
                    );
                return navigate(route('/assemblies/:assemblyId/details', { assemblyId }));
            }
        },
        [
            setAppliedFilters,
            viewContext.type,
            viewContext.rfqId,
            navigate,
            assemblyId,
            currentParentAssemblyId,
            setSelectedStatus,
            setWarningSubCategoryIssues,
        ],
    );

    const clearAppliedFilters = useCallback(() => {
        setAppliedFilters(new Set());
    }, [setAppliedFilters]);

    const handleSearchChange = useCallback(
        (newString: string) => {
            setAppliedFilters(new Set());
            setSearchedText(newString);
        },
        [setAppliedFilters, setSearchedText],
    );

    const clearSelectedWarningSubStatus = () => {
        setWarningSubCategoryIssues(new Set());
    };

    const filtersStyle =
        isFiltersOpen || appliedFilters.size > 0
            ? {
                  background: colorSystem.primary[2],
                  color: colorSystem.primary[7],
                  border: `1px solid ${colorSystem.primary[3]}`,
              }
            : // eslint-disable-next-line spellcheck/spell-checker
              {};

    return (
        <>
            <StickyLayout
                id={id('design/left_sidebar')}
                maxHeight="calc(100vh - 132px)"
                borderRight={`1px solid ${colorSystem.neutral[2]}`}
                gap="0px"
                zIndex={3}
            >
                <AssemblyStructure assemblyId={assemblyId} viewContext={viewContext} />
                <Flexbox flexDirection="column" gap="12px" padding="12px 12px 0px 12px">
                    <Flexbox
                        alignItems="center"
                        justifyContent="space-between"
                        position="relative"
                        id="bom-items-header"
                    >
                        <Flexbox gap="4px" alignItems="center">
                            <Text variant="h5">
                                <Trans>BOM items</Trans>
                            </Text>
                            <Tooltip
                                title={t`An item on a BOM that represents an aggregated set of components with identical part options.`}
                            >
                                <InfoRounded fontSize="inherit" style={{ color: colorSystem.neutral[5] }} />
                            </Tooltip>
                        </Flexbox>
                        <SecondaryButton
                            id="filters-button"
                            style={filtersStyle}
                            onClick={() => setIsFiltersOpen((prev) => !prev)}
                            startIcon={<FilterListRounded />}
                            size="small"
                        >
                            <Trans>Filters</Trans>
                        </SecondaryButton>
                        <FilterIssuesInBomForm
                            isFiltersOpen={isFiltersOpen}
                            setIsFiltersOpen={setIsFiltersOpen}
                            appliedFilters={appliedFilters}
                            setAppliedFilters={setAppliedFilters}
                            setSearchedText={setSearchedText}
                            setSelectedStatus={setSelectedStatus}
                            clearSelectedWarningSubStatus={clearSelectedWarningSubStatus}
                            modulesData={bomItemsTableData}
                            dashboardFiltersCount={filteredByDashboardFilters.length}
                        />
                    </Flexbox>
                    <SearchInput
                        value={searchedText}
                        onChange={handleSearchChange}
                        autoFocus
                        style={{ width: '100%' }}
                    />
                    <StatusFilters
                        items={bomItemsTableData}
                        selectedStatus={selectedStatus}
                        onSelectStatus={handleSelectStatus}
                    />
                    <WarningsAccordion
                        moduleTableData={bomItemsTableData}
                        setSelectedStatus={setSelectedStatus}
                        setWarningSubCategoryIssues={setWarningSubCategoryIssues}
                        clearAppliedFilters={clearAppliedFilters}
                        warningSubCategoryIssues={warningSubCategoryIssues}
                        setSearchedText={setSearchedText}
                    />
                </Flexbox>
                <BomLinePreviews
                    onClick={handleSelectBomItem}
                    filteredModules={filteredModules.filter((m) => m.moduleType !== 'assembly')}
                    selectedModule={selectedBomItem}
                    bomItemsCount={bomItemsTableData.length}
                    hasAnyBomItemWithCpnsFromBom={hasAnyBomItemWithCpnsFromBom}
                />
            </StickyLayout>
            {!selectedModuleId && (
                <BomSummary
                    viewContext={viewContext}
                    moduleTableData={moduleData}
                    selectedStatus={selectedStatus}
                    setSelectedStatus={setSelectedStatus}
                    setWarningSubCategoryIssues={setWarningSubCategoryIssues}
                    clearAppliedFilters={clearAppliedFilters}
                    warningSubCategoryIssues={warningSubCategoryIssues}
                    setSearchedText={setSearchedText}
                    appliedFilters={appliedFilters}
                    setAppliedFilters={setAppliedFilters}
                    currentAssembly={assembly}
                />
            )}
        </>
    );
};
