import { isPresent, omit, throwErrorUnlessProduction } from '@luminovo/commons';
import { Add, ArrowDropDownRounded } from '@mui/icons-material';
import { Autocomplete, Divider, Paper, styled } from '@mui/material';
import * as React from 'react';
import { colorSystem } from '../../../theme';
import { Checkbox } from '../../Checkbox';
import { Chip } from '../../Chip';
import { Flexbox } from '../../Flexbox';
import { Highlight } from '../../Highlight';
import { Text } from '../../Text';
import { TertiaryButton } from '../../buttons';
import { FieldText } from '../FieldText';
import { VirualizedGroupContainer, generateVirtualizeProps } from './VirtualizeComponents';
import { FieldMultiSelectProps } from './types';

const GroupHeader = styled('div')({
    position: 'sticky',
    top: '-8px',
    backgroundColor: colorSystem.neutral.white,
    zIndex: 1,
});

const GroupItems = styled('ul')({
    padding: 0,
});

function FieldMultiSelectInner<TValue>(
    {
        value,
        options,
        virtualized = false,
        onChange,
        onAfterChange,
        getOptionKey,
        getOptionLabel,
        getOptionSublabel,
        getOptionBadge,
        getOptionColor = () => 'neutral',
        renderOption,
        placeholder,
        error,
        helperText,
        action,
        renderGroupLabel,
        ...rest
    }: FieldMultiSelectProps<TValue>,
    outerRef: React.ForwardedRef<unknown>,
): JSX.Element {
    assertUniqueOptionLables({ options, getOptionLabel, getOptionKey });
    const [userTyped, setUserTyped] = React.useState(false);

    const virtualizeProps = virtualized ? generateVirtualizeProps() : {};

    return (
        <Autocomplete
            multiple={true}
            autoSelect={true}
            ref={outerRef}
            // TODO: Our type system doesn't detect when TValue is `null`, we need to fix this.
            value={value ?? []}
            options={options}
            onChange={(event, value) => {
                // Ignores "blur" events caused by disableCloseOnSelect to not change the selections unintended.
                if (event.type === 'blur') {
                    /* no-op */
                } else {
                    onChange(value);
                    onAfterChange?.(value);
                }
            }}
            getOptionLabel={getOptionLabel}
            onInputChange={(_, inputValue, reason) => {
                setUserTyped(reason === 'input'); // Only set to true when user types
            }}
            isOptionEqualToValue={(option, value) => getOptionKey(option) === getOptionKey(value)}
            renderTags={(value, getTagProps) =>
                value.map((item, index) => (
                    <Chip
                        label={getOptionLabel(item)}
                        color={getOptionColor(item)}
                        style={{ marginLeft: '4px', marginRight: '4px', marginTop: '2px', marginBottom: '2px' }}
                        {...getTagProps({ index })}
                    />
                ))
            }
            renderOption={
                isPresent(renderOption)
                    ? (props, option, state) => <li {...props}>{renderOption(option, state)}</li>
                    : (props, option, { inputValue, selected }) => {
                          const label = getOptionLabel?.(option) ?? JSON.stringify(option);
                          const subLabel = getOptionSublabel?.(option);
                          const badge = getOptionBadge?.(option);

                          return (
                              <li {...props}>
                                  <Flexbox justifyContent="space-between" width={'100%'} marginLeft={'-8px'}>
                                      <Flexbox key={getOptionKey(option)} gap={8} alignItems={'top'}>
                                          <Checkbox checked={selected} size={'small'} />
                                          <Flexbox flexDirection="column" gap={2} width={'100%'}>
                                              {isPresent(label) && (
                                                  <Highlight
                                                      label={label}
                                                      matcher={userTyped ? [inputValue] : []}
                                                      overrides={{
                                                          Container: HighLightLabelContainer,
                                                      }}
                                                  />
                                              )}

                                              {isPresent(subLabel) && (
                                                  <Highlight
                                                      label={subLabel}
                                                      matcher={userTyped ? [inputValue] : []}
                                                      overrides={{
                                                          Container: HighLightSubLabelContainer,
                                                      }}
                                                  />
                                              )}
                                          </Flexbox>
                                      </Flexbox>
                                      {badge}
                                  </Flexbox>
                              </li>
                          );
                      }
            }
            renderInput={(props) => (
                // Hack: `value` and `onChange` are requried but have no effect because
                // the FieldText internal values are overridden by `props.inputProps.value`.
                <FieldText
                    {...props}
                    type="text"
                    placeholder={placeholder}
                    value={null}
                    onChange={() => {}}
                    error={error}
                    helperText={helperText}
                />
            )}
            renderGroup={
                isPresent(renderGroupLabel) && virtualized
                    ? (params) => (
                          <VirualizedGroupContainer innerChildren={params.children} label={renderGroupLabel(params)} />
                      )
                    : (params) => (
                          <li key={params.key}>
                              <GroupHeader>
                                  <Text variant="h5" color={colorSystem.neutral[8]}>
                                      {params.group}
                                  </Text>
                              </GroupHeader>
                              <GroupItems>{params.children}</GroupItems>
                          </li>
                      )
            }
            slots={{
                paper: action
                    ? (props) => (
                          <Paper {...props} style={{ position: 'relative' }}>
                              {props.children}
                              <Divider />
                              <Flexbox
                                  justifyContent="space-between"
                                  width={'100%'}
                                  padding={'4px'}
                                  onMouseDown={(e) => e.preventDefault()}
                                  onClick={(e) => e.stopPropagation()}
                              >
                                  <TertiaryButton
                                      size="small"
                                      startIcon={<Add />}
                                      onClick={action?.onClick}
                                      disabled={action?.disabled}
                                  >
                                      {action?.label}
                                  </TertiaryButton>
                              </Flexbox>
                          </Paper>
                      )
                    : undefined,
            }}
            autoHighlight={true}
            popupIcon={<ArrowDropDownRounded />}
            {...virtualizeProps}
            {...omit(rest, 'inputRef')}
        />
    );
}

const HighLightLabelContainer = (props: React.PropsWithChildren<{}>) => (
    <Text variant={'body-small'} color={colorSystem.neutral[9]}>
        {props.children}
    </Text>
);
const HighLightSubLabelContainer = (props: React.PropsWithChildren<{}>) => (
    <Text variant={'caption'} color={colorSystem.neutral[6]} showEllipsis>
        {props.children}
    </Text>
);

/**
 * Asserts that the labels of the options are unique. If a `getOptionKey` function is provided, it asserts that the keys are unique.
 * The label needs to be unique because the `getOptionLabel` function is used to generate the react key for the options.
 */
function assertUniqueOptionLables<TValue>({
    options,
    getOptionLabel,
    getOptionKey,
}: {
    options: TValue[];
    getOptionLabel?: (option: TValue) => string;
    getOptionKey?: (option: TValue) => string | number;
}): void {
    const keySet = new Set<string | number>();
    const labelSet = new Set<string>();

    options.forEach((option) => {
        if (isPresent(getOptionKey)) {
            const key = getOptionKey(option);
            if (keySet.has(key)) {
                throwErrorUnlessProduction(`FieldSelect: Duplicate keys found in options. Keys must be unique`, {
                    extra: { key },
                });
            } else {
                keySet.add(key);
            }
        }

        if (!isPresent(getOptionKey) && isPresent(getOptionLabel)) {
            const label = getOptionLabel(option);
            if (labelSet.has(label)) {
                throwErrorUnlessProduction(
                    'FieldSelect: Duplicate lable found in options. Label must be unique or provide a getOptionKey function',
                    { extra: { label } },
                );
            } else {
                labelSet.add(label);
            }
        }
    });
}

export const FieldMultiSelect = React.forwardRef(FieldMultiSelectInner) as <TValue>(
    props: FieldMultiSelectProps<TValue> & { ref?: React.ForwardedRef<unknown> },
) => JSX.Element;
