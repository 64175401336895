import * as z from 'zod';
import { HttpEndpoint } from '../http/HttpEndpoint';
import { endpoint } from '../http/endpoint';
import {
    AllOriginRuntype,
    CustomOptionOfferDTORuntype,
    CustomPartOfferInputDTORuntype,
    CustomPartOfferUpdateDTORuntype,
    ImportOffersRequestBodyRuntype,
    PartOfferUpdateStatusRuntype,
    PdfAnalyzeResponseRuntype,
    QuoteImportEventRuntype,
    QuoteImporterResponseRuntype,
    StandardPartOfferBulkInputDTORuntype,
    StandardPartOfferDTORuntype,
    StandardPartOfferInputDTORuntype,
    StandardPartOfferUpdateDTORuntype,
    StandardPartOfferWithSolutionsDTO,
    StandardPartOfferWithSolutionsDTORuntype,
} from './offerBackendTypes';

// Whenever a change is made to the offer endpoints, the following endpoints should be invalidated.
export const generalOfferEndpointsToInvalidate: HttpEndpoint[] = [
    // Offers
    'GET /offers/custom-part',
    'GET /offers/off-the-shelf',
    'POST /offers/off-the-shelf/bulk',
    'POST /offers/off-the-shelf/with-solutions',
    // Sourcing scenarios / Solutions
    'POST /sourcing-scenarios/bulk',
    'GET /solution-configurations',
    'GET /solutions',
    'POST /sourcing/calculation',
    'POST /solutions/custom-part/bulk',
    'GET /solution-configurations/:solutionConfigurationId',
];

const offTheShelfOfferBasicEndpoints = {
    'GET /offers/off-the-shelf': endpoint({
        description: 'Fetches all the offers for the given OTS part',
        pathParams: z.undefined(),
        queryParams: z.union([
            z.object({
                part: z.string(),
                rfq_context: z.literal('WithinRfQ'),
                rfq_id: z.string(),
            }),
            z.object({
                part: z.string(),
                rfq_context: z.literal('OutsideRfQ'),
            }),
        ]),
        requestBody: z.undefined(),
        responseBody: z.object({
            data: z.array(StandardPartOfferDTORuntype),
        }),
    }),
};

// Group 1B: Off-the-shelf offer additional endpoints
const offTheShelfOfferAdditionalEndpoints = {
    'GET /offers/off-the-shelf/by-ipn': endpoint({
        description: 'Fetches all the offers for the given IPN',
        pathParams: z.undefined(),
        queryParams: z.union([
            z.object({
                ipn: z.string(),
                source: z.union([z.literal('Inventory'), z.literal('Supplier')]),
                rfq_context: z.literal('WithinRfQ'),
                rfq_id: z.string(),
            }),
            z.object({
                ipn: z.string(),
                source: z.union([z.literal('Inventory'), z.literal('Supplier')]),
                rfq_context: z.literal('OutsideRfQ'),
            }),
        ]),
        requestBody: z.undefined(),
        responseBody: z.object({
            items: z.array(StandardPartOfferDTORuntype),
        }),
    }),

    'POST /offer-update': endpoint({
        description: 'Pushes a single offer update task on the top of the queue',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.object({ api: AllOriginRuntype, sourceable_part: z.string() }),
        responseBody: z.object({ status: PartOfferUpdateStatusRuntype }),
    }),
};

const offTheShelfOfferSolutionsResponseRuntype: z.ZodType<{ data: Array<StandardPartOfferWithSolutionsDTO> }> =
    z.object({
        data: z.array(StandardPartOfferWithSolutionsDTORuntype),
    });

// Group 1C: Off-the-shelf offer solutions endpoints
const offTheShelfOfferSolutionsEndpoints = {
    'POST /offers/off-the-shelf/with-solutions': endpoint({
        description: 'Fetches all the offers with the fastest and best-price solutions for the given OTS part',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.union([
            z.object({
                part: z.string(),
                quantities: z.array(z.number()),
                rfq_context: z.literal('WithinRfQ'),
                rfq_id: z.string(),
            }),
            z.object({
                part: z.string(),
                quantities: z.array(z.number()),
                rfq_context: z.literal('OutsideRfQ'),
            }),
        ]),
        responseBody: offTheShelfOfferSolutionsResponseRuntype,
    }),
};

// Group 1D: Off-the-shelf offer bulk endpoints
const offTheShelfOfferBulkNormalEndpoints = {
    'POST /offers/off-the-shelf/bulk': endpoint({
        description: 'Returns a list of offers given their IDs',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.union([
            z.object({
                ids: z.array(z.string()),
                rfq_context: z.literal('WithinRfQ'),
                rfq_id: z.string(),
            }),
            z.object({
                ids: z.array(z.string()),
                rfq_context: z.literal('OutsideRfQ'),
            }),
        ]),
        responseBody: z.object({ items: z.array(StandardPartOfferDTORuntype) }),
    }),
};

// Group 2A: PDF analysis endpoints
const pdfAnalysisEndpoints = {
    'POST /analyze/pdf': endpoint({
        description: 'Extracts key-value pairs, named entities and other values from a PDF',
        pathParams: z.undefined(),
        queryParams: z.object({
            type: z.union([z.literal('Offer'), z.literal('General')]),
        }),
        requestBody: z.instanceof(FormData),
        responseBody: PdfAnalyzeResponseRuntype,
    }),
};

// Group 2B: Import endpoints
const importEndpoints = {
    'POST /offers/import': endpoint({
        description: 'Imports offers',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.array(ImportOffersRequestBodyRuntype),
        responseBody: z.array(
            z.object({
                status: z.number(),
                part: z
                    .object({
                        internal_part_number: z.string().optional(),
                        manufacturer_part_number: z.string().optional(),
                        manufacturer: z.string().optional(),
                    })
                    .nullable()
                    .optional(),
                description: z.string().optional(),
                customer: z
                    .object({
                        name: z.string().optional(),
                        number: z.string(),
                    })
                    .nullable()
                    .optional(),
            }),
        ),
        invalidates: [
            ...generalOfferEndpointsToInvalidate,
            'GET /rfqs/:rfqId/quote-tracking',
            'GET /quote-tracking/:id',
            'GET /offers/custom-part',
            'GET /offers/off-the-shelf',
            'GET /solution-configurations',
            'GET /solutions',
            'POST /offers/off-the-shelf/bulk',
            'POST /offers/off-the-shelf/with-solutions',
            'POST /sourcing-scenarios/bulk',
        ],
    }),
};

// Group 2C: Bulk import endpoints
const bulkImportEndpoints = {
    'POST /offers/off-the-shelf/bulk/import': endpoint({
        description: 'Creates offers from a quote import for pdf and excel',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.object({
            rfq_id: z.string(),
            supplier_and_stock_location: z.string(),
            quote_tracking_id: z.string().optional(),
            inputs: z.array(StandardPartOfferBulkInputDTORuntype),
            event_metadata: QuoteImportEventRuntype,
        }),
        responseBody: QuoteImporterResponseRuntype,
        invalidates: [
            ...generalOfferEndpointsToInvalidate,
            'GET /rfqs/:rfqId/quote-tracking',
            'GET /quote-tracking/:id',
            'GET /offers/custom-part',
            'GET /offers/off-the-shelf',
            'GET /solution-configurations',
            'GET /solutions',
            'POST /offers/off-the-shelf/bulk',
            'POST /offers/off-the-shelf/with-solutions',
            'POST /sourcing-scenarios/bulk',
        ],
    }),

    'POST /offers/custom-part/bulk/import': endpoint({
        description: 'Creates custom part offers from a quote import excel',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.object({
            rfq_id: z.string(),
            supplier_and_stock_location_id: z.string(),
            quote_tracking_id: z.string().optional(),
            custom_part_offers: z.array(CustomPartOfferInputDTORuntype),
            event_metadata: QuoteImportEventRuntype,
        }),
        responseBody: z.object({ quote_tracking_id: z.string(), items: z.array(CustomOptionOfferDTORuntype) }),
        invalidates: [
            ...generalOfferEndpointsToInvalidate,
            'GET /rfqs/:rfqId/quote-tracking',
            'GET /quote-tracking/:id',
            'GET /offers/custom-part',
            'GET /offers/off-the-shelf',
            'GET /solution-configurations',
            'GET /solutions',
            'POST /offers/off-the-shelf/bulk',
            'POST /offers/off-the-shelf/with-solutions',
            'POST /sourcing-scenarios/bulk',
            'POST /sourcing/calculation',
        ],
    }),
};

// Group 3: Off-the-shelf offer CRUD operations
const offTheShelfOfferCrudEndpoints = {
    'PATCH /offers/off-the-shelf/:id': endpoint({
        description: 'Updates an offer',
        pathParams: z.object({ id: z.string() }),
        queryParams: z.undefined(),
        requestBody: StandardPartOfferUpdateDTORuntype,
        responseBody: z.unknown(),
        invalidates: [
            ...generalOfferEndpointsToInvalidate,
            'GET /offers/custom-part',
            'GET /offers/off-the-shelf',
            'GET /solution-configurations',
            'GET /solutions',
            'POST /offers/off-the-shelf/bulk',
            'POST /offers/off-the-shelf/with-solutions',
            'POST /sourcing-scenarios/bulk',
            'POST /sourcing/calculation',
        ],
    }),

    'DELETE /offers/off-the-shelf/:id': endpoint({
        description: 'Deletes off-the-shelf offer',
        pathParams: z.object({ id: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({ deleted: z.number() }),
        invalidates: [
            ...generalOfferEndpointsToInvalidate,
            'GET /offers/custom-part',
            'GET /offers/off-the-shelf',
            'GET /solution-configurations',
            'GET /solutions',
            'POST /offers/off-the-shelf/bulk',
            'POST /offers/off-the-shelf/with-solutions',
            'POST /sourcing-scenarios/bulk',
        ],
        removes: [
            'GET /offers/off-the-shelf',
            'GET /solution-configurations',
            'GET /solutions',
            'POST /offers/off-the-shelf/bulk',
            'POST /offers/off-the-shelf/with-solutions',
            'POST /sourcing-scenarios/bulk',
        ],
    }),

    'POST /offers/off-the-shelf': endpoint({
        description: 'Creates an off-the-shelf offer',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: StandardPartOfferInputDTORuntype,
        responseBody: z.object({
            data: StandardPartOfferDTORuntype,
        }),
        invalidates: [
            ...generalOfferEndpointsToInvalidate,
            'GET /offers/custom-part/:id',
            'GET /offers/custom-part',
            'GET /offers/off-the-shelf',
            'GET /solution-configurations',
            'GET /solutions',
            'POST /offers/off-the-shelf/bulk',
            'POST /offers/off-the-shelf/with-solutions',
            'POST /sourcing-scenarios/bulk',
        ],
    }),
};

// Group 4A: Custom part offer read endpoints
const customPartOfferReadEndpoints = {
    'GET /offers/custom-part/:id': endpoint({
        description: 'Fetches a custom offer by id',
        pathParams: z.object({ id: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({ data: CustomOptionOfferDTORuntype }),
    }),

    'GET /offers/custom-part': endpoint({
        description: 'Fetches all offers for a given custom part',
        pathParams: z.undefined(),
        queryParams: z.object({ part: z.string() }),
        requestBody: z.undefined(),
        responseBody: z.object({ data: z.array(CustomOptionOfferDTORuntype) }),
    }),
};

// Group 4B: Custom part offer file endpoints
const customPartOfferFileEndpoints = {
    'POST /offers/custom-part/:id/additional-files/duplicate': endpoint({
        description: 'Duplicates an additional file for a custom part offer',
        pathParams: z.object({ id: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.object({ file_names: z.array(z.string()), new_offer_id: z.string() }),
        responseBody: z.string(),
    }),

    'GET /offers/custom-part/:id/additional-files/upload-link': endpoint({
        description: 'Returns a link to upload additional files to a custom part offer',
        pathParams: z.object({ id: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({
            data: z.object({
                url: z.string(),
            }),
        }),
    }),

    'GET /offers/custom-part/:id/additional-files': endpoint({
        description: 'Returns a list of additional files for a custom part offer',
        pathParams: z.object({ id: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({ items: z.array(z.string()) }),
    }),

    'DELETE /offers/custom-part/:id/additional-files': endpoint({
        description: 'Deletes an additional file for a custom part offer',
        pathParams: z.object({ id: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.object({ file_name: z.string() }),
        responseBody: z.string(),
        invalidates: ['GET /offers/custom-part/:id/additional-files'],
    }),
};

// Group 4C: Custom part offer bulk endpoints
const customPartOfferBulkEndpoints = {
    'POST /offers/custom-part/bulk': endpoint({
        description: 'Returns a list of offers given their IDs',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.object({ ids: z.array(z.string()) }),
        responseBody: z.object({ items: z.array(CustomOptionOfferDTORuntype) }),
    }),
};

// Group 5A: Custom part offer create endpoints
const customPartOfferCreateEndpoints = {
    'POST /offers/custom-part': endpoint({
        description: 'Creates a custom-part offer',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: CustomPartOfferInputDTORuntype,
        responseBody: z.object({ data: CustomOptionOfferDTORuntype }),
        invalidates: [
            ...generalOfferEndpointsToInvalidate,
            'POST /offers/custom-part/bulk',
            'GET /offers/custom-part/:id',
            'GET /offers/custom-part',
            'GET /offers/off-the-shelf',
            'GET /solution-configurations',
            'GET /solutions',
            'POST /offers/off-the-shelf/bulk',
            'POST /offers/off-the-shelf/with-solutions',
            'POST /sourcing-scenarios/bulk',
        ],
    }),
};

// Group 5B: Custom part offer update endpoints
const customPartOfferUpdateEndpoints = {
    'PATCH /offers/custom-part/:id': endpoint({
        description: 'Updates a custom-part offer',
        pathParams: z.object({ id: z.string() }),
        queryParams: z.undefined(),
        requestBody: CustomPartOfferUpdateDTORuntype,
        responseBody: z.object({ data: CustomOptionOfferDTORuntype }),
        invalidates: [
            ...generalOfferEndpointsToInvalidate,
            'POST /offers/custom-part/bulk',
            'GET /offers/custom-part/:id',
            'GET /offers/custom-part',
            'GET /offers/off-the-shelf',
            'GET /solution-configurations',
            'GET /solutions',
            'POST /offers/off-the-shelf/bulk',
            'POST /offers/off-the-shelf/with-solutions',
            'POST /sourcing-scenarios/bulk',
        ],
    }),

    'PATCH /offers/custom-part/:id/copy-panel-from-design': endpoint({
        description: 'Copies panel from design to custom part offer',
        pathParams: z.object({ id: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.null(),
        invalidates: [
            ...generalOfferEndpointsToInvalidate,
            'POST /offers/custom-part/bulk',
            'GET /offers/custom-part/:id',
            'GET /offers/custom-part',
            'GET /offers/off-the-shelf',
            'GET /solution-configurations',
            'GET /solutions',
            'POST /offers/off-the-shelf/bulk',
            'POST /offers/off-the-shelf/with-solutions',
            'POST /sourcing-scenarios/bulk',
        ],
    }),
};

// Group 5C: PCB and delete endpoints
const pcbAndDeleteEndpoints = {
    'POST /offers/pcb/:id/update': endpoint({
        description: 'Updates offers for a pcb',
        pathParams: z.object({ id: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.unknown(),
        invalidates: [
            ...generalOfferEndpointsToInvalidate,
            'POST /assemblies/:id/pcb/:pcbId/offer-state',
            'POST /offers/custom-part/bulk',
            'GET /offers/custom-part/:id',
            'GET /offers/custom-part',
            'GET /offers/off-the-shelf',
            'GET /solution-configurations',
            'GET /solutions',
            'POST /offers/off-the-shelf/bulk',
            'POST /offers/off-the-shelf/with-solutions',
            'POST /sourcing-scenarios/bulk',

            'GET /panels',
        ],
    }),

    'DELETE /offers/custom-part/:id': endpoint({
        description: 'Deletes custom offer',
        pathParams: z.object({ id: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({ deleted: z.number() }),
        invalidates: [
            ...generalOfferEndpointsToInvalidate,
            'POST /offers/custom-part/bulk',
            'GET /offers/custom-part',
            'GET /offers/off-the-shelf',
            'GET /solution-configurations',
            'GET /solutions',
            'POST /offers/off-the-shelf/bulk',
            'POST /offers/off-the-shelf/with-solutions',
            'POST /sourcing-scenarios/bulk',
        ],
        removes: ['GET /offers/custom-part', 'POST /offers/custom-part/bulk'],
    }),
};

// Group 6A: Off-the-shelf offer attachment endpoints
const offTheShelfAttachmentEndpoints = {
    'GET /offers/off-the-shelf/:id/attachment': endpoint({
        description: 'Fetches the attachment for the given offer',
        pathParams: z.object({ id: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({ url: z.string() }),
        handleResponse: async (response) => {
            if (response.ok) {
                return response.json();
            }
            if (response.status === 404) {
                return { data: null };
            }
            throw Error(`Failed to GET attachment. Status code: ${response.status}`);
        },
    }),

    'GET /offers/off-the-shelf/:id/additional-files/upload-link': endpoint({
        description: 'Returns a link to upload additional files to a off the shelf offer',
        pathParams: z.object({ id: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({
            data: z.object({
                url: z.string(),
            }),
        }),
    }),
};

// Group 6B: Off-the-shelf offer file management endpoints
const offTheShelfFileManagementEndpoints = {
    'POST /offers/off-the-shelf/:id/additional-files/duplicate': endpoint({
        description: 'Duplicates an additional file for a off the shelf offer',
        pathParams: z.object({ id: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.object({ file_names: z.array(z.string()), new_offer_id: z.string() }),
        responseBody: z.string(),
    }),

    'GET /offers/off-the-shelf/:id/additional-files': endpoint({
        description: 'Returns a list of additional files for a off the shelf offer',
        pathParams: z.object({ id: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({ items: z.array(z.string()) }),
    }),

    'DELETE /offers/off-the-shelf/:id/additional-files': endpoint({
        description: 'Deletes an additional file for a off the shelf offer',
        pathParams: z.object({ id: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.object({ file_name: z.string() }),
        responseBody: z.string(),
        invalidates: ['GET /offers/off-the-shelf/:id/additional-files'],
    }),
};

// Define types for each group
type OffTheShelfOfferBasicEndpoints = typeof offTheShelfOfferBasicEndpoints;
type OffTheShelfOfferAdditionalEndpoints = typeof offTheShelfOfferAdditionalEndpoints;
type OffTheShelfOfferSolutionsEndpoints = typeof offTheShelfOfferSolutionsEndpoints;
type OffTheShelfOfferBulkNormalEndpoints = typeof offTheShelfOfferBulkNormalEndpoints;
type PdfAnalysisEndpoints = typeof pdfAnalysisEndpoints;
type ImportEndpoints = typeof importEndpoints;
type BulkImportEndpoints = typeof bulkImportEndpoints;
type OffTheShelfOfferCrudEndpoints = typeof offTheShelfOfferCrudEndpoints;
type CustomPartOfferReadEndpoints = typeof customPartOfferReadEndpoints;
type CustomPartOfferFileEndpoints = typeof customPartOfferFileEndpoints;
type CustomPartOfferBulkEndpoints = typeof customPartOfferBulkEndpoints;
type CustomPartOfferCreateEndpoints = typeof customPartOfferCreateEndpoints;
type CustomPartOfferUpdateEndpoints = typeof customPartOfferUpdateEndpoints;
type PcbAndDeleteEndpoints = typeof pcbAndDeleteEndpoints;
type OffTheShelfAttachmentEndpoints = typeof offTheShelfAttachmentEndpoints;
type OffTheShelfFileManagementEndpoints = typeof offTheShelfFileManagementEndpoints;

// Define a combined interface that extends all group types
interface OfferEndpoints
    extends OffTheShelfOfferBasicEndpoints,
        OffTheShelfOfferAdditionalEndpoints,
        OffTheShelfOfferSolutionsEndpoints,
        OffTheShelfOfferBulkNormalEndpoints,
        PdfAnalysisEndpoints,
        ImportEndpoints,
        BulkImportEndpoints,
        OffTheShelfOfferCrudEndpoints,
        CustomPartOfferReadEndpoints,
        CustomPartOfferFileEndpoints,
        CustomPartOfferBulkEndpoints,
        CustomPartOfferCreateEndpoints,
        CustomPartOfferUpdateEndpoints,
        PcbAndDeleteEndpoints,
        OffTheShelfAttachmentEndpoints,
        OffTheShelfFileManagementEndpoints {}

// Merge the chunks with the explicit type annotation
export const offerEndpoints: OfferEndpoints = {
    ...offTheShelfOfferBasicEndpoints,
    ...offTheShelfOfferAdditionalEndpoints,
    ...offTheShelfOfferSolutionsEndpoints,
    ...offTheShelfOfferBulkNormalEndpoints,
    ...pdfAnalysisEndpoints,
    ...importEndpoints,
    ...bulkImportEndpoints,
    ...offTheShelfOfferCrudEndpoints,
    ...customPartOfferReadEndpoints,
    ...customPartOfferFileEndpoints,
    ...customPartOfferBulkEndpoints,
    ...customPartOfferCreateEndpoints,
    ...customPartOfferUpdateEndpoints,
    ...pcbAndDeleteEndpoints,
    ...offTheShelfAttachmentEndpoints,
    ...offTheShelfFileManagementEndpoints,
};
