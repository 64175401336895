import * as z from 'zod';
import { endpoint } from '../http/endpoint';
import {
    ActivityConfigurationRuntype,
    ActivityConfigurationsPostRuntype,
    ConfigurationsNotesPatchRuntype,
    ConfigurationsPatchRuntype,
} from './activityConfigurationBackendTypes';

export const activityConfigurationEndpoints = {
    'PATCH /activity-configurations/:activityConfigurationId': endpoint({
        description: 'Edit activity configuration. If driver_count is null the count is reset.',
        pathParams: z.object({ activityConfigurationId: z.string() }),
        queryParams: z.object({
            apply_to_all_activities_and_expenses: z.boolean(),
        }),
        requestBody: ConfigurationsPatchRuntype,
        responseBody: z.object({
            data: ActivityConfigurationRuntype,
        }),
        invalidates: ['GET /manufacturing-scenarios/:manufacturingScenarioId/full-summary'],
    }),
    'PATCH /activity-configurations/:activityConfigurationId/notes': endpoint({
        description: 'Edits the notes only of a specific activity configuration',
        pathParams: z.object({ activityConfigurationId: z.string() }),
        queryParams: z.object({}),
        requestBody: ConfigurationsNotesPatchRuntype,
        responseBody: z.object({
            data: ActivityConfigurationRuntype,
        }),
        invalidates: ['GET /manufacturing-scenarios/:manufacturingScenarioId/full-summary'],
    }),
    'POST /activity-configurations/bulk': endpoint({
        description: 'Add activities to a manufacturing scenario',
        pathParams: z.object({}),
        queryParams: z.object({}),
        requestBody: ActivityConfigurationsPostRuntype,
        responseBody: z.object({
            data: z.array(ActivityConfigurationRuntype),
        }),
        invalidates: ['GET /manufacturing-scenarios'],
    }),
    'DELETE /activity-configurations/bulk': endpoint({
        description: 'Deletes activity configurations from a manufacturing scenario',
        pathParams: z.undefined(),
        queryParams: z.object({
            ids: z.string(),
        }),
        requestBody: z.undefined(),
        responseBody: z.object({ deleted: z.number() }),
    }),

    'PATCH /activity-configurations/:activityConfigurationId/rank': endpoint({
        description: 'Updates the rank of an activity configuration',
        pathParams: z.object({ activityConfigurationId: z.string() }),
        queryParams: z.object({}),
        requestBody: z.object({ rank: z.number() }),
        responseBody: z.object({
            data: ActivityConfigurationRuntype,
        }),
        invalidates: ['GET /manufacturing-scenarios/:manufacturingScenarioId/full-summary'],
    }),
};
