import { AppState, Auth0Provider, User } from '@auth0/auth0-react';
import { CenteredLayout } from '@luminovo/design-system';
import { CircularProgress } from '@mui/material';
import React from 'react';
import { AUTH0_AUTHORIZATION_PARAMS } from '../hooks/useAuth';
// Auth0 parameters are passed as environment variables and are placed in window
// https://jakobzanker.de/blog/inject-environment-variables-into-a-react-app-docker-on-runtime/
declare global {
    interface Window {
        AUTH0_AUTHORITY: string;
        AUTH0_CLIENT_ID: string;
    }
}

export function AuthProvider({
    children,
    auth0OrgId,
    history,
    onRedirectCallback: handleRedirectCallback = () => {},
}: {
    children: React.ReactNode;
    auth0OrgId: string | undefined;
    history: {
        replace: (path: string) => void;
    };
    onRedirectCallback: (appState?: AppState, user?: User) => void;
}): JSX.Element {
    // A function that routes the user to the right place
    // after login
    const onRedirectCallback = (appState?: AppState, user?: User) => {
        handleRedirectCallback(appState, user);
        // If using a Hash Router, you need to use window.history.replaceState to
        // remove the `code` and `state` query parameters from the callback url.
        // window.history.replaceState({}, document.title, window.location.pathname);
        history.replace((appState && appState.returnTo) || window.location.pathname);
    };
    return (
        <React.Suspense
            fallback={
                <CenteredLayout>
                    <CircularProgress />
                </CenteredLayout>
            }
        >
            <Auth0Provider
                domain={window.AUTH0_AUTHORITY}
                clientId={window.AUTH0_CLIENT_ID}
                onRedirectCallback={onRedirectCallback}
                authorizationParams={{ ...AUTH0_AUTHORIZATION_PARAMS, organization: auth0OrgId }}
                cacheLocation="localstorage"
            >
                {children}
            </Auth0Provider>
        </React.Suspense>
    );
}
