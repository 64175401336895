import { t } from '@lingui/macro';
import { assertUnreachable, compareByStringKey } from '@luminovo/commons';
import {
    ColumnBuilder,
    DataGrid,
    DataGridColumn,
    DataGridQuickFilters,
    DataGridReadonlyContextProvider,
    DataGridSearch,
    getVisibleRows,
} from '@luminovo/data-grid';
import { Checkbox, colorSystem, MenuButton, Text, Tooltip } from '@luminovo/design-system';
import { CheckCircleRounded, Error, MoreVert, Warning } from '@mui/icons-material';
import { Box } from '@mui/material';
import { formatParseStatus } from '../../../../model/formatParseStatus';
import { ImporterRow, ParseStatus } from '../../../../types';
import { useUniversalImporter } from '../../context';
import { ButtonExcludeErrors } from './ButtonExcludeErrors';
import { MenuItemExcludeWarnings } from './ButtonExcludeWarnings';
import { ButtonRefresh } from './ButtonRefresh';
import { EditableCell } from './EditableCell';
import { MenuItemDownloadIssues } from './MenuItemDowloadIssues';

export function TableCheckRows({ rows }: { rows: ImporterRow[] }): JSX.Element {
    const { state } = useUniversalImporter();
    const { config } = state;

    const fields = config.fields;

    const columnDefs = fields.map((field, columnIndex): DataGridColumn<ImporterRow, ImporterRow> => {
        const backgroundColor = (row: ImporterRow | undefined) => {
            if (!row) {
                return colorSystem.neutral.white;
            }
            const cell = row.cells[columnIndex];
            const status = cell.status.status;
            const skipped = row.status === 'skipped';
            if (status === 'pending' || status === undefined || skipped) {
                return colorSystem.neutral.white;
            }
            if (skipped) {
                return colorSystem.neutral[1];
            }
            if (status === 'error') {
                return colorSystem.red[1];
            }
            if (status === 'done') {
                return colorSystem.neutral.white;
            }
            if (status === 'warning') {
                return colorSystem.yellow[1];
            }
            assertUnreachable(status);
        };
        return {
            colId: field.id,
            editable: false,
            headerName: field.label ?? field.id,
            valueGetter: ({ data }) => data,
            valueFormatter: ({ value }) => value?.cells[columnIndex]?.text ?? '-',
            filterValueGetter: ({ data }) => data?.cells[columnIndex]?.text ?? '',
            comparator: compareByStringKey((item) => item?.cells[columnIndex]?.text ?? ''),
            cellStyle: ({ data: row }) => {
                return {
                    backgroundColor: backgroundColor(row),
                };
            },
            cellRenderer: ({ value: row }) => {
                if (!row) {
                    return '-';
                }
                const cell = row.cells[columnIndex];
                return <EditableCell cell={cell} columnIndex={columnIndex} rowIndex={row?.index} row={row} />;
            },
        };
    });

    const allColumnDefs: DataGridColumn<ImporterRow>[] = [columnSelection(), ...columnDefs, statusColumn()];

    return (
        <DataGridReadonlyContextProvider rowData={rows}>
            <Box
                sx={{
                    display: 'flex',
                    gap: 1,
                }}
            >
                <DataGridSearch />
                <DataGridQuickFilters />

                <div style={{ flex: 1 }} />
                <MenuButton size="medium" icon={<MoreVert />} appearance="secondary">
                    <MenuItemExcludeWarnings />
                    <MenuItemDownloadIssues />
                </MenuButton>
                <ButtonRefresh />
                <ButtonExcludeErrors />
            </Box>
            <DataGrid themeId={'universalImporter'} columnDefs={allColumnDefs} />
        </DataGridReadonlyContextProvider>
    );
}

const columnBuilder = new ColumnBuilder<ImporterRow>();

const statuses: ParseStatus[] = ['done', 'error', 'skipped', 'warning', 'duplicate-error'];
const statusColumn = (): DataGridColumn<ImporterRow, ParseStatus> =>
    columnBuilder.enum<ParseStatus>({
        colId: 'status',
        label: () => t`Status`,
        editable: false,
        pinned: 'right',
        resizable: false,
        width: 120,
        cellStyle: {
            backgroundColor: colorSystem.neutral.white,
        },
        quickFilters: statuses.map((status) => ({
            label: () => formatParseStatus(status),
            value: status,
        })),
        comparator: compareByStringKey((item) => item ?? ''),
        valueGetter: ({ data }) => data?.status,
        valueFormatter: ({ value }) => formatParseStatus(value),
        filterValueGetter: ({ data }) => formatParseStatus(data?.status),
        cellRenderer: ({ value: status }) => {
            const statusIcon = () => {
                if (status === 'skipped') {
                    return <></>;
                }
                if (status === 'pending') {
                    return <Text>...</Text>;
                }
                if (status === 'error') {
                    return <Error style={{ color: colorSystem.red[6], height: 14 }} />;
                }
                if (status === 'duplicate-error') {
                    return (
                        <Tooltip title={t`This row has duplicate values.`}>
                            <Error style={{ color: colorSystem.red[6], height: 14 }} />
                        </Tooltip>
                    );
                }
                if (status === 'done') {
                    return <CheckCircleRounded style={{ color: colorSystem.green[6], height: 14 }} />;
                }
                if (status === 'warning') {
                    return <Warning style={{ color: colorSystem.yellow[6], height: 14 }} />;
                }

                if (status === undefined || status === null) {
                    return <></>;
                }
                assertUnreachable(status);
            };

            return <>{statusIcon()}</>;
        },
    });

const columnSelection = (): DataGridColumn<ImporterRow, boolean> => ({
    colId: 'Include',
    valueFormatter: ({ value }) => (value ? t`Include` : t`Skip`),
    valueGetter: ({ data }) => data?.status === 'skipped',
    pinned: 'left',
    cellEditor: false,
    width: 40,
    maxWidth: 40,
    minWidth: 40,
    resizable: false,
    cellStyle: {
        backgroundColor: colorSystem.neutral.white,
    },
    cellRenderer: function Render({ data: row }) {
        const index = row.index;
        const { dispatch, state } = useUniversalImporter();
        const isSkipped = row.status === 'skipped';

        return (
            <Checkbox
                size="small"
                checked={!isSkipped}
                onChange={() => {
                    const table = state.importerTable?.skipRow(index);
                    dispatch({ type: 'setImporterTable', table });
                }}
            />
        );
    },
    headerComponent: function Render({ api }) {
        const { state, dispatch } = useUniversalImporter();

        const allIncluded = state.importerTable?.countIncluded() ?? 0;
        const allRows = state.importerTable?.getSize() ?? 0;

        const checked = allIncluded === allRows;

        const indices = getVisibleRows(api).map((row) => row.index);

        return (
            <Checkbox
                size="small"
                checked={checked}
                indeterminate={allIncluded > 0 && allIncluded < allRows}
                onClick={() => {
                    dispatch({ type: 'setImporterTable', table: state.importerTable?.excludeAll(checked, indices) });
                }}
            />
        );
    },
});
