type Stringable = string | number | boolean;

type Optionality = 'optional' | 'required';

export interface RouteDefinition {
    path: Record<string, 'required'>;
    query: Record<string, Optionality>;
}

/**
 * Utility function to define a route that conforms to the RouteDefinition type.
 */
export function defineRoute<T extends RouteDefinition>(r: T): T {
    return r;
}

/* eslint-enable spellcheck/spell-checker */

/**
 * Define all routes in LumiQuote here.
 *
 * - The key is the route template in LumiQuote
 * - The value describes the arguments that the template takes.
 */
export const routes = {
    '/': defineRoute({
        path: {},
        query: {},
    }),
    '/3rdparty/digikey/callback': defineRoute({
        path: {},
        query: {
            code: 'optional',
        },
    }),
    '/assemblies': defineRoute({
        path: {},
        query: {
            filters: 'optional',
        },
    }),
    '/assemblies/:assemblyId/dashboard': defineRoute({
        path: {
            assemblyId: 'required',
        },
        query: {
            rfqId: 'optional',
            tab: 'optional',
            isMonitoringOpen: 'optional',
        },
    }),
    '/assemblies/:assemblyId/dashboard/bom-importer/page-1': defineRoute({
        path: {
            assemblyId: 'required',
        },
        query: {
            rfqId: 'required',
        },
    }),
    '/assemblies/:assemblyId/dashboard/bom-importer/page-2': defineRoute({
        path: {
            assemblyId: 'required',
        },
        query: {
            rfqId: 'required',
        },
    }),
    '/assemblies/:assemblyId/details': defineRoute({
        path: {
            assemblyId: 'required',
        },
        query: {
            designItemId: 'optional',
            filters: 'optional',
            dashboardFilters: 'optional',
            search: 'optional',
        },
    }),
    '/assemblies/:assemblyId/edit': defineRoute({
        path: {
            assemblyId: 'required',
        },
        query: {
            rfqId: 'required',
        },
    }),
    '/assemblies/:assemblyId/pcb': defineRoute({
        path: {
            assemblyId: 'required',
        },
        query: {
            rfqId: 'required',
        },
    }),
    '/assemblies/:assemblyId/pcb/cad-viewer': defineRoute({
        path: {
            assemblyId: 'required',
        },
        query: {
            rfqId: 'required',
        },
    }),
    '/assemblies/:assemblyId/pcb/drills': defineRoute({
        path: {
            assemblyId: 'required',
        },
        query: {
            rfqId: 'required',
        },
    }),
    '/assemblies/:assemblyId/pcb/files': defineRoute({
        path: {
            assemblyId: 'required',
        },
        query: {
            rfqId: 'required',
        },
    }),
    '/assemblies/:assemblyId/pcb/panelization': defineRoute({
        path: {
            assemblyId: 'required',
        },
        query: {
            rfqId: 'required',
        },
    }),
    '/assemblies/:assemblyId/pcb/specification': defineRoute({
        path: {
            assemblyId: 'required',
        },
        query: {
            rfqId: 'required',
        },
    }),
    '/assemblies/:assemblyId/pcb/stackup': defineRoute({
        path: {
            assemblyId: 'required',
        },
        query: {
            rfqId: 'required',
        },
    }),
    '/assemblies/add-assembly': defineRoute({
        path: {},
        query: {
            assemblyId: 'required',
            rfqId: 'required',
        },
    }),
    '/auth/enter-email': defineRoute({
        path: {},
        query: {
            redirectTo: 'optional',
        },
    }),
    '/auth/signup/customer': defineRoute({
        path: {},
        query: {
            payload: 'optional',
        },
    }),
    '/auth/signup/invite': defineRoute({
        path: {},
        query: {
            payload: 'optional',
        },
    }),
    '/calculation/templates': defineRoute({
        query: {},
        path: {},
    }),
    '/calculation/templates/:templateId/edit': defineRoute({
        path: {
            templateId: 'required',
        },
        query: {},
    }),
    '/calculation/templates/add-template': defineRoute({
        query: {},
        path: {},
    }),
    '/customers': defineRoute({
        path: {},
        query: {},
    }),
    '/customers/:customerId': defineRoute({
        path: {
            customerId: 'required',
        },
        query: {},
    }),
    '/customers/importer': defineRoute({
        path: {},
        query: {},
    }),
    '/manufacturer': defineRoute({
        path: {},
        query: {},
    }),
    '/manufacturer/:manufacturerId': defineRoute({
        path: {
            manufacturerId: 'required',
        },
        query: {},
    }),
    '/manufacturing/activity': defineRoute({
        path: {},
        query: {},
    }),
    '/manufacturing/activity/:activityId/duplicate': defineRoute({
        path: {
            activityId: 'required',
        },
        query: {},
    }),
    '/manufacturing/activity/:activityId/edit': defineRoute({
        path: {
            activityId: 'required',
        },
        query: {},
    }),
    '/manufacturing/add-activity': defineRoute({
        path: {},
        query: {},
    }),
    '/manufacturing/add-driver': defineRoute({
        path: {},
        query: {},
    }),
    '/manufacturing/add-expense': defineRoute({
        path: {},
        query: {},
    }),
    '/manufacturing/add-resource': defineRoute({
        path: {},
        query: {},
    }),
    '/manufacturing/driver': defineRoute({
        path: {},
        query: {},
    }),
    '/manufacturing/driver/:driverId/duplicate': defineRoute({
        path: {
            driverId: 'required',
        },
        query: {},
    }),
    '/manufacturing/driver/:driverId/edit': defineRoute({
        path: {
            driverId: 'required',
        },
        query: {},
    }),
    '/manufacturing/expense': defineRoute({
        path: {},
        query: {},
    }),
    '/manufacturing/expense/:expenseId/duplicate': defineRoute({
        path: {
            expenseId: 'required',
        },
        query: {},
    }),
    '/manufacturing/expense/:expenseId/edit': defineRoute({
        path: {
            expenseId: 'required',
        },
        query: {},
    }),
    '/manufacturing/resource': defineRoute({
        query: {},
        path: {},
    }),
    '/manufacturing/resource/:resourceId/duplicate': defineRoute({
        path: {
            resourceId: 'required',
        },
        query: {},
    }),
    '/manufacturing/resource/:resourceId/edit': defineRoute({
        path: {
            resourceId: 'required',
        },
        query: {},
    }),
    '/manufacturing/template': defineRoute({
        path: {},
        query: {},
    }),
    '/manufacturing/template/:templateId': defineRoute({
        path: {
            templateId: 'required',
        },
        query: {},
    }),
    '/manufacturing/template/edit': defineRoute({
        path: {},
        query: {},
    }),
    '/negotiations': defineRoute({
        path: {},
        query: {},
    }),
    '/negotiations/:negotiationId/categories': defineRoute({
        path: {
            negotiationId: 'required',
        },
        query: {},
    }),
    '/negotiations/:negotiationId/demands': defineRoute({
        path: {
            negotiationId: 'required',
        },
        query: {},
    }),
    '/negotiations/:negotiationId/line-items/:lineItemId': defineRoute({
        path: {
            negotiationId: 'required',
            lineItemId: 'required',
        },
        query: {},
    }),
    '/negotiations/:negotiationId/manufacturers': defineRoute({
        path: {
            negotiationId: 'required',
        },
        query: {},
    }),
    '/negotiations/:negotiationId/quote-importer': defineRoute({
        path: {
            negotiationId: 'required',
        },
        query: {},
    }),
    '/negotiations/:negotiationId/quote-requests': defineRoute({
        path: {
            negotiationId: 'required',
        },
        query: {},
    }),
    '/negotiations/:negotiationId/quote-requests/:quoteRequestId': defineRoute({
        path: {
            negotiationId: 'required',
            quoteRequestId: 'required',
        },
        query: {
            highlightNegotiationLineItemId: 'optional',
        },
    }),
    '/negotiations/:negotiationId/quote-requests/assign': defineRoute({
        path: {
            negotiationId: 'required',
        },
        query: {
            lineItems: 'optional',
        },
    }),
    '/negotiations/:negotiationId/quote-requests/preview-email': defineRoute({
        path: {
            negotiationId: 'required',
        },
        query: {
            quoteRequestNumbers: 'required',
        },
    }),
    '/negotiations/:negotiationId/scenarios': defineRoute({
        path: {
            negotiationId: 'required',
        },
        query: {},
    }),
    '/negotiations/:negotiationId/summary': defineRoute({
        path: {
            negotiationId: 'required',
        },
        query: {},
    }),
    '/negotiations/:negotiationId/summary/categories': defineRoute({
        path: {
            negotiationId: 'required',
        },
        query: {},
    }),
    '/negotiations/:negotiationId/summary/line-items': defineRoute({
        path: {
            negotiationId: 'required',
        },
        query: {},
    }),
    '/negotiations/:negotiationId/summary/manufacturers': defineRoute({
        path: {
            negotiationId: 'required',
        },
        query: {},
    }),
    '/negotiations/:negotiationId/summary/potential-volume-by-manufacturer': defineRoute({
        path: {
            negotiationId: 'required',
        },
        query: {},
    }),
    '/negotiations/:negotiationId/summary/suppliers': defineRoute({
        path: {
            negotiationId: 'required',
        },
        query: {},
    }),
    '/negotiations/:negotiationId/suppliers': defineRoute({
        path: {
            negotiationId: 'required',
        },
        query: {},
    }),
    '/negotiations/create': defineRoute({
        path: {},
        query: {},
    }),
    '/notFound': defineRoute({
        path: {},
        query: {},
    }),
    '/offers/mpn-offer-importer': defineRoute({
        path: {},
        query: {},
    }),
    '/offers/pdf-offer-importer/:rfqId': defineRoute({
        path: {
            rfqId: 'required',
        },
        query: {
            returnTo: 'required',
            quoteTrackingId: 'optional',
        },
    }),
    '/offers/pdf-offer-importer': defineRoute({
        path: {},
        query: {
            returnTo: 'required',
            quoteRequestId: 'required',
        },
    }),
    '/parts': defineRoute({
        path: {},
        query: {},
    }),
    '/parts/alerts': defineRoute({
        path: {},
        query: {},
    }),
    '/parts/components': defineRoute({
        path: {},
        query: {
            q: 'optional',
        },
    }),
    '/parts/components/component-importer': defineRoute({
        path: {},
        query: {},
    }),
    '/parts/components/ipn': defineRoute({
        path: {},
        query: {
            ipnId: 'required',
            q: 'optional',
        },
    }),
    '/parts/components/ipn/cpns': defineRoute({
        path: {},
        query: {
            ipnId: 'required',
            q: 'optional',
        },
    }),
    '/parts/components/ipn/demand': defineRoute({
        path: {},
        query: {
            ipnId: 'required',
            q: 'optional',
        },
    }),
    '/parts/components/ipn/linking': defineRoute({
        path: {},
        query: {
            ipnId: 'required',
            q: 'optional',
            rawSpecId: 'optional',
        },
    }),
    '/parts/components/ipn/offers': defineRoute({
        path: {},
        query: {
            ipnId: 'required',
            q: 'optional',
        },
    }),
    '/parts/components/ipn/used-in': defineRoute({
        path: {},
        query: {
            ipnId: 'required',
            q: 'optional',
        },
    }),
    '/parts/demand/demand-importer': defineRoute({
        path: {},
        query: {},
    }),
    '/parts/demands': defineRoute({
        path: {},
        query: {},
    }),
    '/parts/details/:partId': defineRoute({
        path: {
            partId: 'required',
        },
        query: {
            taskId: 'optional',
        },
    }),
    '/parts/details/:partId/compliance': defineRoute({
        path: {
            partId: 'required',
        },
        query: {
            taskId: 'optional',
        },
    }),
    '/parts/details/:partId/offers': defineRoute({
        path: {
            partId: 'required',
        },
        query: {
            taskId: 'optional',
        },
    }),
    '/parts/details/:partId/part-alternatives': defineRoute({
        path: {
            partId: 'required',
        },
        query: {
            taskId: 'optional',
        },
    }),
    '/parts/details/:partId/used-in': defineRoute({
        path: {
            partId: 'required',
        },
        query: {
            taskId: 'optional',
        },
    }),
    '/parts/off-the-shelf-part-search': defineRoute({
        path: {},
        query: {},
    }),
    '/parts/offers/:offerId/duplicate': defineRoute({
        path: {
            offerId: 'required',
        },
        query: {},
    }),
    '/parts/offers/:offerId/edit': defineRoute({
        path: {
            offerId: 'required',
        },
        query: {},
    }),
    '/parts/offers/add/:partId': defineRoute({
        path: {
            partId: 'required',
        },
        query: {
            partType: 'required',
        },
    }),
    '/parts/source-list-importer': defineRoute({
        path: {},
        query: {},
    }),
    '/parts/quote-requests': defineRoute({
        path: {},
        query: {},
    }),
    '/parts/quote-requests/importer': defineRoute({
        path: {},
        query: {},
    }),
    '/purchase-orders': defineRoute({
        path: {},
        query: {},
    }),
    '/purchase-orders/:id': defineRoute({
        path: {
            id: 'required',
        },
        query: {},
    }),
    '/quote-portal': defineRoute({
        path: {},
        query: {},
    }),
    '/quote-portal/quote-tracking/:quoteTrackingId': defineRoute({
        path: {
            quoteTrackingId: 'required',
        },
        query: {},
    }),
    '/quote-portal/rfq/:rfqId': defineRoute({
        path: {
            rfqId: 'required',
        },
        query: {},
    }),
    '/rfq_creation': defineRoute({
        path: {},
        query: {},
    }),
    '/rfqs': defineRoute({
        path: {},
        query: {},
    }),
    '/rfqs/:rfqId': defineRoute({
        path: {
            rfqId: 'required',
        },
        query: {},
    }),
    '/rfqs/:rfqId/bom': defineRoute({
        path: {
            rfqId: 'required',
        },
        query: {
            hideTopLevelAssembly: 'optional',
        },
    }),
    '/rfqs/:rfqId/bom/assembly/:assemblyId': defineRoute({
        path: {
            rfqId: 'required',
            assemblyId: 'required',
        },
        query: {
            tab: 'optional',
            monitoring: 'optional',
            currentParentAssemblyId: 'optional',
        },
    }),
    '/rfqs/:rfqId/bom/assembly/:assemblyId/bom-importer/page-1': defineRoute({
        path: {
            rfqId: 'required',
            assemblyId: 'required',
        },
        query: {},
    }),
    '/rfqs/:rfqId/bom/assembly/:assemblyId/bom-importer/page-2': defineRoute({
        path: {
            rfqId: 'required',
            assemblyId: 'required',
        },
        query: {},
    }),
    '/rfqs/:rfqId/bom/assembly/:assemblyId/details': defineRoute({
        path: {
            rfqId: 'required',
            assemblyId: 'required',
        },
        query: {
            currentParentAssemblyId: 'optional',
            isReadonly: 'optional',
            designItemId: 'optional',
            filters: 'optional',
            bomTab: 'optional',
            dashboardFilters: 'optional',
            search: 'optional',
            // This is relevant only for Manufacturing,
            // when a user clicks on Resolve in Bom in the Driver, they
            // should only see the bom-items that have the issues leading to warnings.
            onlyShowItemsWithManufacturingWarnings: 'optional',
        },
    }),
    '/rfqs/:rfqId/bom/assembly/:assemblyId/edit': defineRoute({
        path: {
            rfqId: 'required',
            assemblyId: 'required',
        },
        query: {
            currentParentAssemblyId: 'optional',
        },
    }),
    '/rfqs/:rfqId/bom/assembly/:assemblyId/pcb': defineRoute({
        path: {
            rfqId: 'required',
            assemblyId: 'required',
        },
        query: {
            isReadonly: 'optional',
            designItemId: 'optional',
            currentParentAssemblyId: 'optional',
        },
    }),
    '/rfqs/:rfqId/bom/assembly/:assemblyId/pcb/cad-viewer': defineRoute({
        path: {
            rfqId: 'required',
            assemblyId: 'required',
        },
        query: {
            currentParentAssemblyId: 'optional',
        },
    }),
    '/rfqs/:rfqId/bom/assembly/:assemblyId/pcb/drills': defineRoute({
        path: {
            rfqId: 'required',
            assemblyId: 'required',
        },
        query: {},
    }),
    '/rfqs/:rfqId/bom/assembly/:assemblyId/pcb/files': defineRoute({
        path: {
            rfqId: 'required',
            assemblyId: 'required',
        },
        query: {},
    }),
    '/rfqs/:rfqId/bom/assembly/:assemblyId/pcb/panelization': defineRoute({
        path: {
            rfqId: 'required',
            assemblyId: 'required',
        },
        query: {},
    }),
    '/rfqs/:rfqId/bom/assembly/:assemblyId/pcb/specification': defineRoute({
        path: {
            rfqId: 'required',
            assemblyId: 'required',
        },
        query: {},
    }),
    '/rfqs/:rfqId/bom/assembly/:assemblyId/pcb/stackup': defineRoute({
        path: {
            rfqId: 'required',
            assemblyId: 'required',
        },
        query: {},
    }),
    '/rfqs/:rfqId/bom/assembly/:assemblyId/pnp-importer': defineRoute({
        path: {
            rfqId: 'required',
            assemblyId: 'required',
        },
        query: {
            currentParentAssemblyId: 'optional',
        },
    }),
    '/rfqs/:rfqId/bom/assembly/add-assembly': defineRoute({
        path: {
            rfqId: 'required',
        },
        query: {
            assemblyId: 'required',
        },
    }),
    '/rfqs/:rfqId/calculation': defineRoute({
        path: {
            rfqId: 'required',
        },
        query: {},
    }),
    '/rfqs/:rfqId/calculation/:assemblyId/calculation': defineRoute({
        path: {
            assemblyId: 'required',
            rfqId: 'required',
        },
        query: {},
    }),
    '/rfqs/:rfqId/calculation/print': defineRoute({
        path: {
            rfqId: 'required',
        },
        query: {
            validUntil: 'required',
            customText: 'optional',
            additionalLeadTimeDays: 'required',
            quotationNumber: 'required',
            footerRight: 'optional',
            footerLeft: 'optional',
            footerCenter: 'optional',
        },
    }),
    '/rfqs/:rfqId/calculation/teaser': defineRoute({
        path: {
            rfqId: 'required',
        },
        query: {},
    }),
    '/rfqs/:rfqId/dashboard': defineRoute({
        path: {
            rfqId: 'required',
        },
        query: {
            showOemDashboard: 'optional',
        },
    }),
    '/rfqs/:rfqId/edit': defineRoute({
        path: {
            rfqId: 'required',
        },
        query: {},
    }),
    '/rfqs/:rfqId/manufacturing': defineRoute({
        path: {
            rfqId: 'required',
        },
        query: {
            forwardIfSingleAssembly: 'optional',
        },
    }),
    '/rfqs/:rfqId/manufacturing/:manufacturingAssemblyDetailsId': defineRoute({
        path: {
            rfqId: 'required',
            manufacturingAssemblyDetailsId: 'required',
        },
        query: {},
    }),
    '/rfqs/:rfqId/manufacturing/:manufacturingAssemblyDetailsId/:manufacturingScenarioId/activity': defineRoute({
        path: {
            rfqId: 'required',
            manufacturingAssemblyDetailsId: 'required',
            manufacturingScenarioId: 'required',
        },
        query: {},
    }),
    '/rfqs/:rfqId/manufacturing/:manufacturingAssemblyDetailsId/:manufacturingScenarioId/expense': defineRoute({
        path: {
            rfqId: 'required',
            manufacturingAssemblyDetailsId: 'required',
            manufacturingScenarioId: 'required',
        },
        query: {},
    }),
    '/rfqs/:rfqId/manufacturing/:manufacturingAssemblyDetailsId/activity': defineRoute({
        path: {
            rfqId: 'required',
            manufacturingAssemblyDetailsId: 'required',
        },
        query: {},
    }),
    '/rfqs/:rfqId/manufacturing/activity': defineRoute({
        path: {
            rfqId: 'required',
        },
        query: {},
    }),
    '/rfqs/:rfqId/manufacturing/expense': defineRoute({
        path: {
            rfqId: 'required',
        },
        query: {},
    }),
    '/rfqs/:rfqId/manufacturing/teaser': defineRoute({
        path: {
            rfqId: 'required',
        },
        query: {},
    }),
    '/rfqs/:rfqId/order': defineRoute({
        path: {
            rfqId: 'required',
        },
        query: {},
    }),
    '/rfqs/:rfqId/order/confirmation': defineRoute({
        path: {
            rfqId: 'required',
        },
        query: {
            // Parameters provided by Stripe

            payment_intent_client_secret: 'optional',
            payment_intent_id: 'optional',
            redirect_status: 'optional',

            // Parameters provided by LumiQuote
            orderSelectionOptionId: 'required',
        },
    }),
    '/rfqs/:rfqId/quotation': defineRoute({
        path: {
            rfqId: 'required',
        },
        query: {},
    }),
    '/rfqs/:rfqId/sourcing': defineRoute({
        path: {
            rfqId: 'required',
        },
        query: {
            sourcingScenarioId: 'optional',
        },
    }),
    '/rfqs/:rfqId/sourcing/:solutionConfigurationId': defineRoute({
        path: {
            rfqId: 'required',
            solutionConfigurationId: 'required',
        },
        query: {},
    }),
    '/rfqs/:rfqId/sourcing/assembly/:assemblyId': defineRoute({
        path: {
            rfqId: 'required',
            assemblyId: 'required',
        },
        query: {},
    }),
    '/rfqs/:rfqId/sourcing/negotiations': defineRoute({
        path: {
            rfqId: 'required',
        },
        query: {},
    }),
    '/rfqs/:rfqId/sourcing/negotiations/:negotiationId/line-items': defineRoute({
        path: {
            rfqId: 'required',
            negotiationId: 'required',
        },
        query: {},
    }),
    '/rfqs/:rfqId/sourcing/negotiations/:negotiationId/quote-requests': defineRoute({
        path: {
            rfqId: 'required',
            negotiationId: 'required',
        },
        query: {},
    }),
    '/rfqs/:rfqId/sourcing/negotiations/:negotiationId/quote-requests/:quoteRequestId': defineRoute({
        path: {
            rfqId: 'required',
            negotiationId: 'required',
            quoteRequestId: 'required',
        },
        query: {},
    }),
    '/rfqs/:rfqId/sourcing/negotiations/quote-requests/importer': defineRoute({
        path: {
            rfqId: 'required',
        },
        query: {},
    }),
    '/rfqs/:rfqId/sourcing/negotiations/:negotiationId/quote-requests/assign': defineRoute({
        path: {
            rfqId: 'required',
            negotiationId: 'required',
        },
        query: {
            lineItems: 'optional',
        },
    }),
    '/rfqs/:rfqId/sourcing/negotiations/:negotiationId/quote-requests/preview-email': defineRoute({
        path: {
            rfqId: 'required',
            negotiationId: 'required',
        },
        query: {
            quoteRequestNumbers: 'required',
        },
    }),
    '/rfqs/:rfqId/sourcing/offer/custom-component/:offerId/edit': defineRoute({
        path: {
            rfqId: 'required',
            offerId: 'required',
        },
        query: {},
    }),
    '/rfqs/:rfqId/sourcing/offer/custom-component/add/:partId': defineRoute({
        path: {
            rfqId: 'required',
            partId: 'required',
        },
        query: {
            solutionConfigurationId: 'required',
        },
    }),

    '/rfqs/:rfqId/sourcing/offer/custom-part/:offerId/duplicate': defineRoute({
        path: {
            rfqId: 'required',
            offerId: 'required',
        },
        query: {},
    }),
    '/rfqs/:rfqId/sourcing/offer/custom-part/:offerId/edit': defineRoute({
        path: {
            rfqId: 'required',
            offerId: 'required',
        },
        query: {},
    }),
    '/rfqs/:rfqId/sourcing/offer/custom-part/add/:partId': defineRoute({
        path: {
            rfqId: 'required',
            partId: 'required',
        },
        query: {},
    }),
    '/rfqs/:rfqId/sourcing/offer/internal-part-number/:offerId/duplicate': defineRoute({
        path: {
            rfqId: 'required',
            offerId: 'required',
        },
        query: {},
    }),
    '/rfqs/:rfqId/sourcing/offer/internal-part-number/:offerId/edit': defineRoute({
        path: {
            rfqId: 'required',
            offerId: 'required',
        },
        query: {},
    }),
    '/rfqs/:rfqId/sourcing/offer/internal-part-number/add/:partId': defineRoute({
        path: {
            rfqId: 'required',
            partId: 'required',
        },
        query: {
            solutionConfigurationId: 'required',
        },
    }),
    '/rfqs/:rfqId/sourcing/offer/off-the-shelf/:offerId/duplicate': defineRoute({
        path: {
            rfqId: 'required',
            offerId: 'required',
        },
        query: {},
    }),
    '/rfqs/:rfqId/sourcing/offer/off-the-shelf/:offerId/edit': defineRoute({
        path: {
            rfqId: 'required',
            offerId: 'required',
        },
        query: {},
    }),
    '/rfqs/:rfqId/sourcing/offer/off-the-shelf/add/:partId': defineRoute({
        path: {
            rfqId: 'required',
            partId: 'required',
        },
        query: {
            solutionConfigurationId: 'optional',
        },
    }),
    '/rfqs/:rfqId/sourcing/quote-importer': defineRoute({
        path: {
            rfqId: 'required',
        },
        query: {},
    }),
    '/rfqs/:rfqId/sourcing/quote-tracking': defineRoute({
        path: {
            rfqId: 'required',
        },
        query: {
            quoteTrackingId: 'required',
        },
    }),
    '/rfqs/:rfqId/sourcing/scenarios/:sourcingScenarioId': defineRoute({
        path: {
            rfqId: 'required',
            sourcingScenarioId: 'required',
        },
        query: {
            assemblyId: 'optional',
        },
    }),
    '/rfqs/:rfqId/sourcing/scenarios/:sourcingScenarioId/edit': defineRoute({
        path: {
            rfqId: 'required',
            sourcingScenarioId: 'required',
        },
        query: {},
    }),
    '/rfqs/:rfqId/sourcing/scenarios/:sourcingScenarioId/purchase-orders': defineRoute({
        path: {
            rfqId: 'required',
            sourcingScenarioId: 'required',
        },
        query: {},
    }),
    '/rfqs/:rfqId/sourcing/scenarios/:sourcingScenarioId/purchase-orders/:purchaseOrderId': defineRoute({
        path: {
            rfqId: 'required',
            sourcingScenarioId: 'required',
            purchaseOrderId: 'required',
        },
        query: {},
    }),
    '/rfqs/:rfqId/sourcing/scenarios/new': defineRoute({
        path: {
            rfqId: 'required',
        },
        query: {},
    }),
    '/settings/company': defineRoute({
        path: {},
        query: {},
    }),
    '/settings/organization': defineRoute({
        path: {},
        query: {},
    }),
    '/settings/organization/api-settings': defineRoute({
        path: {},
        query: {},
    }),
    '/settings/organization/assembly-settings': defineRoute({
        path: {},
        query: {},
    }),
    '/settings/organization/automatic-rfq-number': defineRoute({
        path: {},
        query: {},
    }),
    '/settings/organization/branding': defineRoute({
        path: {},
        query: {},
    }),
    '/settings/organization/carbon-footprints': defineRoute({
        path: {},
        query: {},
    }),
    '/settings/organization/currency': defineRoute({
        path: {},
        query: {},
    }),
    '/settings/organization/customer-portal': defineRoute({
        path: {},
        query: {},
    }),
    '/settings/organization/customer-portal/additional-services': defineRoute({
        path: {},
        query: {},
    }),
    '/settings/organization/customer-portal/customer-specific-settings': defineRoute({
        path: {},
        query: {},
    }),
    '/settings/organization/customer-portal/payment-credentials': defineRoute({
        path: {},
        query: {},
    }),
    '/settings/organization/customer-portal/checkout-conditions': defineRoute({
        path: {},
        query: {},
    }),
    '/settings/organization/email-templates': defineRoute({
        path: {},
        query: {},
    }),
    '/settings/organization/email-templates/:id/update': defineRoute({
        path: {
            id: 'required',
        },
        query: {},
    }),
    '/settings/organization/email-templates/create': defineRoute({
        path: {},
        query: {},
    }),
    '/settings/organization/export-templates': defineRoute({
        path: {},
        query: {},
    }),
    '/settings/organization/manufacturer-preferences': defineRoute({
        path: {},
        query: {},
    }),
    '/settings/organization/mock-data': defineRoute({
        path: {},
        query: {},
    }),
    '/settings/organization/pcb-panel-preferences': defineRoute({
        path: {},
        query: {},
    }),
    '/settings/organization/pcb-panel-preferences/:panelPreferenceId': defineRoute({
        path: {
            panelPreferenceId: 'required',
        },
        query: {},
    }),
    '/settings/organization/pcb-panel-preferences/add-preference': defineRoute({
        path: {},
        query: {},
    }),
    '/settings/organization/scrap-calculation': defineRoute({
        path: {},
        query: {},
    }),
    '/settings/organization/scrap-calculation/formula-builder/default-formula/edit': defineRoute({
        path: {},
        query: {},
    }),
    '/settings/organization/scrap-calculation/formula-builder/formula/:formulaIndex/edit': defineRoute({
        path: {
            formulaIndex: 'required',
        },
        query: {},
    }),
    '/settings/organization/sites': defineRoute({
        path: {},
        query: {},
    }),
    '/settings/organization/source-list': defineRoute({
        path: {},
        query: {},
    }),
    '/settings/organization/supplier-api-integrations': defineRoute({
        path: {},
        query: {},
    }),
    '/settings/organization/supplier-portal': defineRoute({
        path: {},
        query: {},
    }),
    '/settings/organization/total-cost-of-ownership-rules': defineRoute({
        path: {},
        query: {},
    }),
    '/settings/organization/users': defineRoute({
        path: {},
        query: {},
    }),
    '/settings/organization/users/importer': defineRoute({
        path: {},
        query: {},
    }),

    '/settings/user/account-security': defineRoute({
        path: {},
        query: {},
    }),
    '/snapshots/:fromRfqId/:fromSnapshotId/:fromSourcingScenarioId/compare/:toRfqId/:toSnapshotId/:toSourcingScenarioId':
        defineRoute({
            path: {
                fromSnapshotId: 'required',
                toSnapshotId: 'required',
                fromSourcingScenarioId: 'required',
                fromRfqId: 'required',
                toRfqId: 'required',
                toSourcingScenarioId: 'required',
            },
            query: {},
        }),
    '/snapshots/:fromRfqId/:fromSnapshotId/:fromSourcingScenarioId/compare/:toRfqId/:toSnapshotId/:toSourcingScenarioId/currencies':
        defineRoute({
            path: {
                fromSnapshotId: 'required',
                toSnapshotId: 'required',
                fromSourcingScenarioId: 'required',
                fromRfqId: 'required',
                toRfqId: 'required',
                toSourcingScenarioId: 'required',
            },
            query: {},
        }),
    '/snapshots/:fromRfqId/:fromSnapshotId/:fromSourcingScenarioId/compare/:toRfqId/:toSnapshotId/:toSourcingScenarioId/excess-material':
        defineRoute({
            path: {
                fromSnapshotId: 'required',
                toSnapshotId: 'required',
                fromSourcingScenarioId: 'required',
                fromRfqId: 'required',
                toRfqId: 'required',
                toSourcingScenarioId: 'required',
            },
            query: {},
        }),
    '/snapshots/:fromRfqId/:fromSnapshotId/:fromSourcingScenarioId/compare/:toRfqId/:toSnapshotId/:toSourcingScenarioId/lead-time':
        defineRoute({
            path: {
                fromSnapshotId: 'required',
                toSnapshotId: 'required',
                fromSourcingScenarioId: 'required',
                fromRfqId: 'required',
                toRfqId: 'required',
                toSourcingScenarioId: 'required',
            },
            query: {},
        }),
    '/snapshots/:fromRfqId/:fromSnapshotId/:fromSourcingScenarioId/compare/:toRfqId/:toSnapshotId/:toSourcingScenarioId/one-time-cost':
        defineRoute({
            path: {
                fromSnapshotId: 'required',
                toSnapshotId: 'required',
                fromSourcingScenarioId: 'required',
                fromRfqId: 'required',
                toRfqId: 'required',
                toSourcingScenarioId: 'required',
            },
            query: {},
        }),
    '/snapshots/:fromRfqId/:fromSnapshotId/:fromSourcingScenarioId/compare/:toRfqId/:toSnapshotId/:toSourcingScenarioId/order-size':
        defineRoute({
            path: {
                fromSnapshotId: 'required',
                toSnapshotId: 'required',
                fromSourcingScenarioId: 'required',
                fromRfqId: 'required',
                toRfqId: 'required',
                toSourcingScenarioId: 'required',
            },
            query: {},
        }),
    '/snapshots/:fromRfqId/:fromSnapshotId/:fromSourcingScenarioId/compare/:toRfqId/:toSnapshotId/:toSourcingScenarioId/preferences':
        defineRoute({
            path: {
                fromSnapshotId: 'required',
                toSnapshotId: 'required',
                fromSourcingScenarioId: 'required',
                fromRfqId: 'required',
                toRfqId: 'required',
                toSourcingScenarioId: 'required',
            },
            query: {},
        }),
    '/snapshots/:fromRfqId/:fromSnapshotId/:fromSourcingScenarioId/compare/:toRfqId/:toSnapshotId/:toSourcingScenarioId/scrap-cost':
        defineRoute({
            path: {
                fromSnapshotId: 'required',
                toSnapshotId: 'required',
                fromSourcingScenarioId: 'required',
                fromRfqId: 'required',
                toRfqId: 'required',
                toSourcingScenarioId: 'required',
            },
            query: {},
        }),
    '/snapshots/:fromRfqId/:fromSnapshotId/:fromSourcingScenarioId/compare/:toRfqId/:toSnapshotId/:toSourcingScenarioId/suppliers':
        defineRoute({
            path: {
                fromSnapshotId: 'required',
                toSnapshotId: 'required',
                fromSourcingScenarioId: 'required',
                fromRfqId: 'required',
                toRfqId: 'required',
                toSourcingScenarioId: 'required',
            },
            query: {},
        }),
    '/snapshots/:fromRfqId/:fromSnapshotId/:fromSourcingScenarioId/compare/:toRfqId/:toSnapshotId/:toSourcingScenarioId/total-cost':
        defineRoute({
            path: {
                fromSnapshotId: 'required',
                toSnapshotId: 'required',
                fromSourcingScenarioId: 'required',
                fromRfqId: 'required',
                toRfqId: 'required',
                toSourcingScenarioId: 'required',
            },
            query: {},
        }),
    '/snapshots/:fromRfqId/:fromSnapshotId/:fromSourcingScenarioId/compare/:toRfqId/:toSnapshotId/:toSourcingScenarioId/unit-price':
        defineRoute({
            path: {
                fromSnapshotId: 'required',
                toSnapshotId: 'required',
                fromSourcingScenarioId: 'required',
                fromRfqId: 'required',
                toRfqId: 'required',
                toSourcingScenarioId: 'required',
            },
            query: {},
        }),
    '/suppliers': defineRoute({
        path: {},
        query: {},
    }),
    '/suppliers/supplier-contacts': defineRoute({
        path: {},
        query: {},
    }),
    '/suppliers/line-card-rules/importer': defineRoute({
        path: {},
        query: {},
    }),
    '/supplier-portal': defineRoute({
        path: {},
        query: {
            filter: 'optional',
        },
    }),
    '/supplier-portal/line-card': defineRoute({
        path: {},
        query: {},
    }),
    '/supplier-portal/quote-requests/:quoteRequestId': defineRoute({
        path: {
            quoteRequestId: 'required',
        },
        query: {},
    }),
    '/supplier-portal/quote-requests/:quoteRequestId/import': defineRoute({
        path: {
            quoteRequestId: 'required',
        },
        query: {},
    }),
    '/suppliers/:supplierAndStockLocationId': defineRoute({
        path: {
            supplierAndStockLocationId: 'required',
        },
        query: {
            tab: 'optional',
        },
    }),
    '/suppliers/:supplierId/supplier-contacts-importer': defineRoute({
        path: {
            supplierId: 'required',
        },
        query: {},
    }),
    '/supplier/importer': defineRoute({
        path: {},
        query: {},
    }),
    '/suppliers/supplier-contacts-importer/bulk': defineRoute({
        path: {},
        query: {},
    }),
} as const;

/**
 * Type describing the route config object in LumiQuote
 */
export type LumiQuoteRoutes = typeof routes;

/**
 * Type describing all routes in LumiQuote
 */
// eslint-disable-next-line import/no-unused-modules
export type LumiQuoteRoute = keyof LumiQuoteRoutes;

/**
 * Use this type along with useParams() to get the types params given a route:
 *
 * Example:
 *
 * ```ts
 * const { rfqId } = useParams<PageParams<"/rfqs/:rfqId">>()
 * ```
 * The compiler will know what params are available based on the route
 * passed to PageParams
 * ```
 */
// eslint-disable-next-line import/no-unused-modules
export type PageParams<T extends LumiQuoteRoute> = { [k in keyof LumiQuoteRoutes[T]['path']]: string };

// eslint-disable-next-line import/no-unused-modules
export type QueryParams<T extends LumiQuoteRoute> = {
    [k in keyof LumiQuoteRoutes[T]['query']]: LumiQuoteRoutes[T]['query'][k] extends 'required'
        ? string
        : string | null | undefined;
};

// eslint-disable-next-line import/no-unused-modules
export type PathParams<T extends LumiQuoteRoute> = {
    [k in keyof LumiQuoteRoutes[T]['path']]: Stringable;
};

export type UrlParams<T extends LumiQuoteRoute> = {
    pathParams: PageParams<T>;
    queryParams: QueryParams<T>;
};
