function clientConfig() {
    const BACKEND_HOST = process.env.BACKEND_API_HOST || window.origin;
    const HOST_NAME = window.location.host;
    const BACKEND_BASE = BACKEND_HOST + '/api';
    const WEBSOCKET_BASE = BACKEND_BASE.replace(/^http/, 'ws');

    return {
        BACKEND_HOST,
        HOST_NAME,
        BACKEND_BASE,
        WEBSOCKET_BASE,
    };
}

function devServerConfig() {
    const BACKEND_HOST = process.env.BACKEND_API_HOST || 'https://ci-e2e.dev.luminovo.ai';
    const HOST_NAME = new URL(BACKEND_HOST).host;
    const BACKEND_BASE = BACKEND_HOST + '/api';
    const WEBSOCKET_BASE = BACKEND_BASE.replace(/^http/, 'ws');

    return {
        BACKEND_HOST,
        HOST_NAME,
        BACKEND_BASE,
        WEBSOCKET_BASE,
    };
}

// change to get preview environment up
function serverConfig() {
    const BACKEND_HOST = 'http://backend-core:5000/';
    const HOST_NAME = 'localhost:8080';
    const BACKEND_BASE = BACKEND_HOST + '/api';
    const WEBSOCKET_BASE = BACKEND_BASE.replace(/^http/, 'ws');

    return {
        BACKEND_HOST,
        HOST_NAME,
        BACKEND_BASE,
        WEBSOCKET_BASE,
    };
}

export const config = (() => {
    if (process.env.SERVICE_NAME === 'gateway') {
        return process.env.NODE_ENV === 'development' ? devServerConfig() : serverConfig();
    }
    // For nodejs environments
    if (typeof window === 'undefined') {
        // For e2e-tests and other Node.js environments
        return devServerConfig();
    }
    return clientConfig();
})();
