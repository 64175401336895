import * as z from 'zod';

import { endpoint } from '../http/endpoint';
import { SupportedPartsDTORuntype } from '../part/partBackendTypes';
import {
    SupplierDTORuntype,
    SupplierLineCardDTORuntype,
    SupplierLineCardPatchDTORuntype,
    SupplierLineCardRuleImportResponseRuntype,
    SupplierLineCardRulesImportDTORunType as SupplierLineCardRulesImportDTORuntype,
    SupplierPartType,
    SupplierType,
} from './supplierBackendTypes';

export const supplierEndpoints = {
    'GET /suppliers': endpoint({
        description: 'Returns all suppliers',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({ items: z.array(SupplierDTORuntype) }),
    }),

    'DELETE /suppliers/:id': endpoint({
        description: 'Deletes a supplier',
        pathParams: z.object({ id: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.unknown(),
        invalidates: [
            'GET /suppliers',
            'GET /suppliers-and-stock-locations',
            'POST /suppliers-and-stock-locations/bulk',
            'GET /organization-settings/organization-solution-preference',
            'GET /email-template',
        ],
    }),

    'POST /suppliers/import': endpoint({
        description: 'Imports suppliers',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.object({
            items: z.array(
                z.object({
                    supplier_number: z.string(),
                    name: z.string(),
                    supplier_part_type: z.nativeEnum(SupplierPartType),
                    supplier_type: z.nativeEnum(SupplierType),
                }),
            ),
        }),
        responseBody: z.object({
            items: z.array(
                z.object({
                    success: z.boolean(),
                    error_code: z
                        .object({
                            code: z.string(),
                            message: z.string(),
                        })
                        .optional(),
                }),
            ),
        }),
        invalidates: ['GET /email-template'],
    }),

    'POST /suppliers/supported-parts': endpoint({
        description: 'Returns a list of supported parts given the supplier IDs',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.object({
            rfq_id: z.string(),
            supplier_ids: z.array(z.string()),
            part_ids: z.array(z.string()),
        }),
        responseBody: z.object({ items: z.array(SupportedPartsDTORuntype) }),
    }),

    'GET /suppliers/line-card': endpoint({
        description: 'Returns the supplier line cards for multiple suppliers',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.array(SupplierLineCardDTORuntype),
    }),

    'GET /suppliers/line-card-rules': endpoint({
        description: 'Returns the supplier line cards for multiple suppliers',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.array(SupplierLineCardDTORuntype),
    }),

    'POST /suppliers/line-card-rules/import': endpoint({
        description: 'Import the supplier line card rules',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: SupplierLineCardRulesImportDTORuntype,
        responseBody: SupplierLineCardRuleImportResponseRuntype,
        invalidates: ['GET /suppliers/line-card-rules'],
    }),

    'GET /suppliers/:id/line-card': endpoint({
        description: 'Returns the supplier line card',
        pathParams: z.object({ id: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: SupplierLineCardDTORuntype,
    }),

    'PATCH /suppliers/:id/reset-line-card': endpoint({
        description: 'Resets the supplier line card to the default line card',
        pathParams: z.object({ id: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.null(),
        invalidates: ['GET /suppliers/line-card', 'GET /suppliers/:id/line-card', 'GET /suppliers/:id/line-card-rules'],
    }),

    'GET /suppliers/:id/line-card-rules': endpoint({
        description: 'Returns the supplier line card rules',
        pathParams: z.object({ id: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: SupplierLineCardDTORuntype,
    }),

    'PATCH /suppliers/:id/line-card': endpoint({
        description: 'Returns the supplier line card',
        pathParams: z.object({ id: z.string() }),
        queryParams: z.undefined(),
        requestBody: SupplierLineCardPatchDTORuntype,
        responseBody: SupplierLineCardDTORuntype,
        invalidates: ['GET /suppliers/line-card', 'GET /suppliers/:id/line-card', 'GET /suppliers/:id/line-card-rules'],
    }),

    'PATCH /suppliers/:id/line-card-rules': endpoint({
        description: 'Updates the approved vendor list',
        pathParams: z.object({ id: z.string() }),
        queryParams: z.undefined(),
        requestBody: SupplierLineCardPatchDTORuntype,
        responseBody: SupplierLineCardDTORuntype,
        invalidates: ['GET /suppliers/:id/line-card-rules', 'GET /suppliers/:id/line-card', 'GET /suppliers/line-card'],
    }),
};
