import { t } from '@lingui/macro';
import { isPresent } from '@luminovo/commons';
import React, { useState } from 'react';
import { Tooltip, TooltipProps } from '../Tooltip';

export const CopyToClipboardTooltip: React.FunctionComponent<{
    value: string | undefined;
    variant?: TooltipProps['variant'];
    children: TooltipProps['children'];
}> = ({ value, variant, children }) => {
    const initialState = isPresent(value) ? 'idle' : 'disabled';
    const [state, setState] = useState<'idle' | 'copied' | 'failed' | 'disabled'>(initialState);

    const handleCopyClick = async (e: React.MouseEvent) => {
        e.stopPropagation();
        if (!isPresent(value)) {
            return;
        }

        try {
            await navigator.clipboard.writeText(value);
            setState('copied');
        } catch {
            setState('failed');
        }
    };

    const title = {
        idle: t`Copy to Clipboard`,
        copied: t`Copied`,
        failed: t`Failed to Copy`,
        disabled: '',
    };

    return (
        <Tooltip
            variant={variant}
            title={title[state]}
            onClick={handleCopyClick}
            onClose={() => setState(initialState)}
        >
            {children}
        </Tooltip>
    );
};
