import * as z from 'zod';
import { endpoint } from '../http/endpoint';
import {
    UserDriverDetailsBulkPatchDTORuntype,
    UserDriverDetailsDTOPatchRuntype,
    UserDriverDetailsDTOPostRuntype,
    UserDriverDetailsRuntype,
} from './driverDetailsBackendTypes';

export const driversEndpoints = {
    'POST /user-drivers': endpoint({
        description: 'Creates a driver',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: UserDriverDetailsDTOPostRuntype,
        responseBody: z.object({
            data: UserDriverDetailsRuntype,
        }),
        invalidates: ['GET /user-drivers'],
    }),

    'GET /user-drivers': endpoint({
        description: 'Returns an array of all drivers',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({
            data: z.array(UserDriverDetailsRuntype),
        }),
    }),

    'PATCH /user-drivers': endpoint({
        description: 'Updates multiple drivers',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: UserDriverDetailsBulkPatchDTORuntype,
        responseBody: z.object({
            data: z.array(UserDriverDetailsRuntype),
        }),
        invalidates: ['GET /user-drivers'],
    }),

    'GET /user-drivers/:driverId': endpoint({
        description: 'Returns a driver details',
        pathParams: z.object({ driverId: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({
            data: UserDriverDetailsRuntype,
        }),
    }),

    'PATCH /user-drivers/:driverId': endpoint({
        description: 'Updates a driver',
        pathParams: z.object({ driverId: z.string() }),
        queryParams: z.undefined(),
        requestBody: UserDriverDetailsDTOPatchRuntype,
        responseBody: z.object({
            data: UserDriverDetailsRuntype,
        }),
    }),

    'DELETE /user-drivers/:driverId': endpoint({
        description: 'Deletes a driver',
        pathParams: z.object({ driverId: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({ deleted: z.number() }),
    }),
};
