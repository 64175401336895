import * as z from 'zod';
import { endpoint } from '../http/endpoint';
import { DemandDTORuntype } from '../negotiation';
import {
    DemandCreationRequestRuntype,
    DemandPostDTORuntype,
    DemandsExportRequestBodyRuntype,
    DemandsExportResponseRuntype,
    MultiStatusRuntype,
    SourceListImportArrayRuntype,
    SourceListImportResponseRuntype,
} from './demandBackendTypes';
import { DemandSummaryDemandsRequestRuntype, DemandSummaryResponseRuntype } from './demandSummaryBackendTypes';

export const demandEndpoints = {
    'POST /demand/create-sourcing-negotiation': endpoint({
        description: 'Creates a sourcing negotiation demand',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: DemandPostDTORuntype,
        responseBody: DemandDTORuntype,
    }),
    'POST /demand/import': endpoint({
        description: 'Posts demands',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: DemandCreationRequestRuntype,
        responseBody: z.array(MultiStatusRuntype),
    }),
    'POST /demand/export': endpoint({
        description: 'Fetches demands',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: DemandsExportRequestBodyRuntype,
        responseBody: DemandsExportResponseRuntype,
    }),
    'POST /demand/export-summary-demands': endpoint({
        description: 'Fetches demands along with a demand summary',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: DemandSummaryDemandsRequestRuntype,
        responseBody: DemandSummaryResponseRuntype,
    }),
    'POST /demand/export-csv': endpoint({
        description: 'Fetches as a CSV',
        pathParams: z.undefined(),
        queryParams: z.object({
            target_date: z.string(),
        }),
        requestBody: DemandsExportRequestBodyRuntype,
        //csv string
        responseBody: z.string(),
    }),
    'POST /source-list/import': endpoint({
        description: 'Imports a source list',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: SourceListImportArrayRuntype,
        responseBody: SourceListImportResponseRuntype,
    }),
};
