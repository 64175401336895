import { useHttpMutation } from '@/resources/mutation/useHttpMutation';
import { useSourcingScenario } from '@/resources/sourcingScenario/sourcingScenarioHandlers';
import { Trans, t } from '@lingui/macro';
import { Protected } from '@luminovo/auth';
import {
    DestructiveSecondaryIconButton,
    PrimaryButton,
    SecondaryButton,
    Toolbar,
    Tooltip,
    useConfirmationDialog,
    useNavigate,
} from '@luminovo/design-system';
import { DeleteRounded, Edit } from '@mui/icons-material';
import React from 'react';
import { useHttpQuery } from '../../../../resources/http/useHttpQuery';
import { route } from '../../../../utils/routes';

export function ToolbarSourcingScenario({ rfqId, sourcingScenarioId }: { rfqId: string; sourcingScenarioId: string }) {
    const { data: sourcingScenarioDTO } = useSourcingScenario(sourcingScenarioId);

    return (
        <Toolbar
            breadcrumbs={[
                {
                    title: t`Sourcing`,
                    href: route(`/rfqs/:rfqId/sourcing`, { rfqId }),
                },
                {
                    title: sourcingScenarioDTO?.name ?? '',
                },
            ]}
        >
            <ButtonSourcingScenarioDelete rfqId={rfqId} sourcingScenarioId={sourcingScenarioId} />
            <ButtonSourcingScenarioEdit rfqId={rfqId} sourcingScenarioId={sourcingScenarioId} />
            <ButtonPurchase rfqId={rfqId} sourcingScenarioId={sourcingScenarioId} />
        </Toolbar>
    );
}

const ButtonSourcingScenarioEdit: React.FunctionComponent<{
    rfqId: string;
    sourcingScenarioId: string;
}> = ({ rfqId, sourcingScenarioId }): JSX.Element => {
    return (
        <SecondaryButton
            size="medium"
            startIcon={<Edit />}
            href={route(`/rfqs/:rfqId/sourcing/scenarios/:sourcingScenarioId/edit`, {
                rfqId,
                sourcingScenarioId,
            })}
        >
            <Trans>Edit</Trans>
        </SecondaryButton>
    );
};

const ButtonSourcingScenarioDelete: React.FunctionComponent<{
    rfqId: string;
    sourcingScenarioId: string;
}> = ({ rfqId, sourcingScenarioId }): JSX.Element => {
    const navigate = useNavigate();

    const { mutateAsync } = useHttpMutation('DELETE /sourcing-scenarios/:sourcingScenarioId', {
        enableInvalidateAllQueries: false,
        snackbarMessage: t`Sourcing scenario deleted`,
        onSuccess: () => navigate(route('/rfqs/:rfqId/sourcing', { rfqId })),
    });

    const { modal, setOpen } = useConfirmationDialog({
        title: t`Delete sourcing scenario`,
        description: t`Are you sure you want to delete this sourcing scenario? This cannot be undone.`,
        onConfirmation: () => mutateAsync({ pathParams: { sourcingScenarioId } }),
    });

    return (
        <>
            {modal}
            <DestructiveSecondaryIconButton size="medium" onClick={() => setOpen(true)}>
                <DeleteRounded fontSize="inherit" />
            </DestructiveSecondaryIconButton>
        </>
    );
};

function ButtonPurchase({ rfqId, sourcingScenarioId }: { rfqId: string; sourcingScenarioId: string }) {
    const { data: count = 0 } = useHttpQuery(
        'GET /purchase-orders/:rfqId',
        {
            pathParams: {
                rfqId,
            },
            queryParams: {
                sourcing_scenario_id: sourcingScenarioId,
            },
        },
        { select: (res) => res.pending.length + res.created.length },
    );

    const isDisabled = count === 0;
    const title = count === 0 ? '' : count === 1 ? t`1 purchase order` : t`${count} purchase orders`;

    return (
        <Protected requiredPermissions={['create:purchase_order', 'view:purchase_order']}>
            <Tooltip title={title}>
                <PrimaryButton
                    disabled={isDisabled}
                    href={route('/rfqs/:rfqId/sourcing/scenarios/:sourcingScenarioId/purchase-orders', {
                        rfqId,
                        sourcingScenarioId,
                    })}
                    size="medium"
                >
                    <Trans>Purchase</Trans>
                </PrimaryButton>
            </Tooltip>
        </Protected>
    );
}
