import { Currency } from '@luminovo/commons';
import { Cell, cellSchema, reduceValidated, validateArray, Validated } from '@luminovo/fields';
import { ComplianceStatus, OtsFullPart, Packaging, PdfAnalyzeResponse, ValidFor } from '@luminovo/http-client';
import { differenceInDays } from 'date-fns';
import { Atom, atom, WritableAtom } from 'jotai';
import { z } from 'zod';
import {
    FormFieldsConfiguration,
    OfferLineItem,
    PartialOfferLineItem,
    PdfOfferLineItem,
    ValidatedFormState,
} from '../../types';
import { LeadTimeUnit } from '../PdfViewer';
import { QuoteRequest } from './types';

const $rawRows = atom<PartialOfferLineItem[]>([]);

const $rows = atom(
    (get): PdfOfferLineItem[] => {
        const rawRows = get($rawRows);
        return rawRows.map((row): PdfOfferLineItem => {
            // First, create validated fields for each property
            const validatedFields = {
                currency: cellSchema(z.nativeEnum(Currency).optional()).validate(row.currency),

                unitPrice: cellSchema(z.number())
                    .validator((z) => z.max(1_000_000).gt(0))
                    .validate(row.unitPrice),

                moq: cellSchema(z.number())
                    .validator((z) => z.min(1).max(1_000_000_000_000))
                    .validate(row.moq),

                mpq: cellSchema(z.number())
                    .validator((z) => z.min(1).max(1_000_000_000_000))
                    .validate(row.mpq),

                packaging: cellSchema(z.nativeEnum(Packaging).optional()).validate(row.packaging),

                standardFactoryLeadTime: cellSchema(
                    z
                        .object({
                            unit: z.nativeEnum(LeadTimeUnit),
                            value: z.number(),
                        })
                        .optional(),
                )
                    .validator(() => {
                        return z
                            .object({
                                unit: z.nativeEnum(LeadTimeUnit),
                                value: z.number().min(0).finite(),
                            })
                            .optional();
                    })
                    .validate(row.standardFactoryLeadTime),

                notes: cellSchema(z.string())
                    .validator((z) => z.optional().transform((x) => x ?? ''))
                    .validator((z) => z.max(200))
                    .validate(row.notes),

                stock: cellSchema(z.number())
                    .validator((z) => z.min(0).finite().optional())
                    .validate(row.stock),

                ncnr: cellSchema(z.boolean().optional()).validate(row.ncnr),

                part: cellSchema(z.custom<OtsFullPart>((x) => Boolean(x))).validate(row.part),

                reach: cellSchema(z.nativeEnum(ComplianceStatus))
                    .validator((z) => z.optional().transform((x) => x ?? ComplianceStatus.Unknown))
                    .validate(row.reach),

                rohs: cellSchema(z.nativeEnum(ComplianceStatus))
                    .validator((z) => z.optional().transform((x) => x ?? ComplianceStatus.Unknown))
                    .validate(row.rohs),

                supplierPartNumber: cellSchema(z.string())
                    .validator((z) => z.trim().max(200).optional())
                    .validate(row.supplierPartNumber),
            };

            const validatedRow: Validated<OfferLineItem> = reduceValidated({
                rowId: { status: 'success', value: row.rowId },
                source: { status: 'success', value: row.source },
                ...validatedFields,
            });

            return {
                rowId: row.rowId,
                source: row.source,
                selected: false,
                ...validatedFields,
                row: validatedRow,
            };
        });
    },
    (get, set, action: RowAction) => {
        switch (action.type) {
            case 'setRows':
                set($rawRows, action.rows);
                break;
            case 'duplicateRow': {
                const rawRows = get($rawRows);
                const rowIndex = rawRows.findIndex((row) => row.rowId === action.rowId);
                if (rowIndex >= 0) {
                    const rowToDuplicate = rawRows[rowIndex];
                    const newRow = {
                        ...rowToDuplicate,
                        rowId: crypto.randomUUID(),
                    };
                    const newRows = [...rawRows.slice(0, rowIndex + 1), newRow, ...rawRows.slice(rowIndex + 1)];
                    set($rawRows, newRows);
                }
                break;
            }
            case 'deleteRow': {
                const rawRows = get($rawRows);
                const filteredRows = rawRows.filter((row) => row.rowId !== action.rowId);
                set($rawRows, filteredRows);
                break;
            }
            case 'addEmptyRowBelow': {
                const rawRows = get($rawRows);
                const rowIndex = action.rowId
                    ? rawRows.findIndex((row) => row.rowId === action.rowId)
                    : rawRows.length - 1;

                const newRow: PartialOfferLineItem = {
                    rowId: crypto.randomUUID(),
                    currency: undefined,
                    unitPrice: 0,
                    moq: 1,
                    mpq: 1,
                    packaging: undefined,
                    standardFactoryLeadTime: undefined,
                    notes: '',
                    stock: undefined,
                    ncnr: false,
                    part: undefined,
                    reach: ComplianceStatus.Unknown,
                    rohs: ComplianceStatus.Unknown,
                };

                const newRows =
                    rowIndex >= 0
                        ? [...rawRows.slice(0, rowIndex + 1), newRow, ...rawRows.slice(rowIndex + 1)]
                        : [...rawRows, newRow];

                set($rawRows, newRows);
                break;
            }
            case 'setAttribute': {
                const rawRows = get($rawRows);

                const newRows: PartialOfferLineItem[] = rawRows.map((row): PartialOfferLineItem => {
                    if (row.rowId !== action.rowId) {
                        return row;
                    }
                    return {
                        ...row,
                        [action.attribute]: action.value ?? undefined,
                    };
                });
                set($rawRows, newRows);
                break;
            }
        }
    },
);

type SetAttributeAction<TKey extends keyof OfferLineItem> = {
    type: 'setAttribute';
    rowId: string;
    attribute: TKey;
    value: OfferLineItem[TKey] | undefined;
};

export type RowAction =
    | {
          type: 'setRows';
          rows: PartialOfferLineItem[];
      }
    | {
          type: 'duplicateRow';
          // duplicates the row with the given rowId. The new row will be appended right after the original row.
          rowId: string;
      }
    | {
          type: 'deleteRow';
          // deletes the row with the given rowId, if not provided, no changes are made
          rowId: string;
      }
    | {
          type: 'addEmptyRowBelow';
          // if rowId is provided, the new row will be inserted below the row with the given rowId
          // otherwise, the new row will be appended to the end of the list
          //
          // For the empty row, generate a new rowId, set the source to an empty object, and set all other attributes to undefined or
          // a sane default.
          rowId?: string;
      }
    | SetAttributeAction<'currency'>
    | SetAttributeAction<'unitPrice'>
    | SetAttributeAction<'moq'>
    | SetAttributeAction<'mpq'>
    | SetAttributeAction<'packaging'>
    | SetAttributeAction<'standardFactoryLeadTime'>
    | SetAttributeAction<'notes'>
    | SetAttributeAction<'stock'>
    | SetAttributeAction<'ncnr'>
    | SetAttributeAction<'part'>
    | SetAttributeAction<'reach'>
    | SetAttributeAction<'rohs'>
    | SetAttributeAction<'supplierPartNumber'>;

export interface FormState {
    $formState: Atom<Validated<ValidatedFormState>>;

    $formFieldsConfiguration: Atom<FormFieldsConfiguration>;
    $quoteRequests: Atom<QuoteRequest[]>;
    $stepIndex: WritableAtom<number, [update: number | 'next' | 'prev'], void>;
    $validDays: Atom<number | undefined>;

    fields: {
        $file: Cell<File | undefined>;
        $analyzeResult: Cell<PdfAnalyzeResponse | undefined>;
        $defaultCurrency: Cell<Currency>;
        $offerNumber: Cell<string>;
        $quoteRequest: Cell<QuoteRequest>;
        $validFrom: Cell<string>;
        $validUntil: Cell<string | undefined>;
        $validFor: Cell<ValidFor>;
        $attachment: Cell<string | undefined>;
        $rows: WritableAtom<PdfOfferLineItem[], [action: RowAction], void>;
    };
}

export function createFormState({
    quoteRequest,
    quoteRequests,
    initialFieldConfiguration,
}: {
    quoteRequest?: QuoteRequest;
    quoteRequests: QuoteRequest[];
    initialFieldConfiguration: FormFieldsConfiguration;
}): FormState {
    // Plain state
    const $innerStepIndex = atom(0);
    const $stepIndex = atom(
        (get) => get($innerStepIndex),
        (get, set, update: number | 'next' | 'prev') => {
            const maxStep = 3;
            const minStep = 0;
            if (update === 'next') {
                set($innerStepIndex, Math.min(get($innerStepIndex) + 1, maxStep));
            } else if (update === 'prev') {
                set($innerStepIndex, Math.max(get($innerStepIndex) - 1, minStep));
            } else {
                set($innerStepIndex, update);
            }
        },
    );

    const $formFieldsConfiguration = atom<FormFieldsConfiguration>(initialFieldConfiguration);
    const $quoteRequests = atom<QuoteRequest[]>(quoteRequests);
    const $quoteRequest = cellSchema(z.custom<QuoteRequest>((x) => Boolean(x))).cell({ initialValue: quoteRequest });

    const $file = cellSchema(z.instanceof(File)).build();
    const $analyzeResult = cellSchema(z.custom<PdfAnalyzeResponse>().optional()).build();
    const $defaultCurrency = cellSchema(z.nativeEnum(Currency)).build();
    const $offerNumber = cellSchema(z.string())
        .validator((z) => z.trim().max(100))
        .build();
    const $validFrom = cellSchema(z.string())
        .validator((z) => z.date())
        .build();
    const $validUntil = cellSchema(z.string())
        .validator((z) => z.date().optional())
        .build();
    const $validFor = cellSchema(z.nativeEnum(ValidFor))
        .validator((z) => z.optional().transform((x) => x ?? ValidFor.EveryCustomer))
        .build();
    const $attachment = cellSchema(z.string().optional()).build();
    const $validDays: Atom<number | undefined> = atom((get) => {
        const validFrom = get($validFrom);
        const validUntil = get($validUntil);
        if (validFrom.status !== 'success' || validUntil.status !== 'success') {
            return undefined;
        }
        if (validUntil.value === undefined) {
            return undefined;
        }
        return differenceInDays(new Date(validUntil.value), new Date(validFrom.value));
    });

    const $formState = atom((get) => {
        const file = get($file);
        const analyzeResult = get($analyzeResult);
        const defaultCurrency = get($defaultCurrency);
        const offerNumber = get($offerNumber);
        const quoteRequest = get($quoteRequest);
        const validFrom = get($validFrom);
        const validUntil = get($validUntil);
        const attachment = get($attachment);
        const rows: Validated<OfferLineItem[]> = validateArray(get($rows).map((row: PdfOfferLineItem) => row.row));
        const validFor = get($validFor);
        const validatedForm: Validated<ValidatedFormState> = reduceValidated({
            file,
            analyzeResult,
            defaultCurrency,
            offerNumber,
            quoteRequest,
            validFrom,
            validUntil,
            validFor,
            attachment,
            rows,
        });

        return validatedForm;
    });

    return {
        $formState,
        $formFieldsConfiguration,
        $quoteRequests,
        $stepIndex,
        $validDays,

        fields: {
            $file,
            $analyzeResult,
            $defaultCurrency,
            $offerNumber,
            $quoteRequest,
            $validFrom,
            $validUntil,
            $validFor,
            $attachment,
            $rows,
        },
    };
}
