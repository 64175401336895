import { MessageDescriptor } from '@lingui/core';
import { defineMessage } from '@lingui/macro';
import { ActivityLevel, DurationUnit } from '@luminovo/http-client';

export const levelEnumPublicTranslations: Record<ActivityLevel | ActivityLevel, MessageDescriptor> = {
    Unit: defineMessage({ id: 'unit.level', message: 'Unit' }),
    Batch: defineMessage({ message: 'Batch' }),
    Project: defineMessage({ message: 'Project' }),
};

export const activityCreationLevelEnumPublicTranslations: Record<ActivityLevel, MessageDescriptor> = {
    Unit: levelEnumPublicTranslations['Unit'],
    Batch: levelEnumPublicTranslations['Batch'],
    Project: levelEnumPublicTranslations['Project'],
};

export const durationUnitPublicTranslations: Record<DurationUnit, MessageDescriptor> = {
    Seconds: defineMessage({ message: 'Seconds' }),
    Minutes: defineMessage({ message: 'Minutes' }),
    Hours: defineMessage({ message: 'Hours' }),
};
