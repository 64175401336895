import * as z from 'zod';
import { PCBV2FileRuntype, PCBV2Properties } from './pcbBackendTypes';

export const ViewBoxCoordinateRuntype = z.object({
    x: z.number(),
    y: z.number(),
    width: z.number(),
    height: z.number(),
});
export type ViewBoxCoordinateType = z.infer<typeof ViewBoxCoordinateRuntype>;

const PCBV2CadFileRuntype = PCBV2FileRuntype.extend({
    id: z.string(),
    fileColor: z.string(),
    isVisible: z.boolean(),
    boundingBox: ViewBoxCoordinateRuntype.optional(),
});

export type PCBV2CadFile = z.infer<typeof PCBV2CadFileRuntype>;
export const PCBV2CadFilesRuntype = z.array(PCBV2CadFileRuntype);

export type PcbSpecificationProperties = PCBV2Properties;
