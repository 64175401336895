import { assertUnreachable } from '@luminovo/commons';
import { Argument, Operator, ParameterOption, ParametricSearchState } from '../types';

/**
 * Given the current context, returns all the parameters that the user is able to select.
 *
 * The list of allowed parameters should:
 *
 * 1. Restrict items based on the current query.
 * 2. Included items allowed based on the current context.
 * 3. Not-included already selected items.
 * 4. Not be hidden.
 */

export function findAllowedParameters<T, TAutocompleteState>(
    state: ParametricSearchState<T, TAutocompleteState>,
    autocompleteState?: TAutocompleteState,
): ParameterOption<T, TAutocompleteState>[] {
    const { selectedBlocks: filters, config, query } = state;

    const allowedFilters = config.parameters
        // if a predicate is present, restrict items based on the current state
        .filter((filter) => {
            const predicate = filter.requires;
            if (!predicate) {
                return true;
            }
            return predicate(state);
        })
        // skip parameters that don't match the query
        .filter((filter) => {
            return filter.field.toLowerCase().includes(query.toLowerCase());
        })
        // skip selected parameters
        .filter((filter) => {
            // we want to allow multiple "not in"/"is not" parameters
            return !filters.some((f) => f.field === filter.field && f.op !== 'is not' && f.op !== 'not in');
        })
        // skip hidden parameters
        .filter((filter) => !filter.hidden)
        // skip parameters that don't have any options
        .filter((filter) => filter.ops.some((op) => hasOptions(op, autocompleteState)));

    return allowedFilters;
}

function hasOptions<T, TAutocompleteState>(
    operator: Operator<T, TAutocompleteState, Argument>,
    autocompleteState?: TAutocompleteState,
) {
    const { op } = operator;
    switch (op) {
        case '<':
        case '>':
        case '=':
        case '≤':
        case '≥':
        case 'like':
        case 'starts-with':
            return true;
        case 'is':
        case 'in':
            const opts = operator.options(autocompleteState);
            if (opts.length === 0) {
                return false;
            }
            if (opts.length === 1 && opts[0].count !== undefined) {
                return false;
            }
            return true;
        case 'is not':
        case 'not in':
            if (operator.options(autocompleteState).length === 0) {
                return false;
            }
            return true;
        default:
            assertUnreachable(op);
    }
}
