import { t, Trans } from '@lingui/macro';
import { id } from '@luminovo/commons';
import {
    colorSystem,
    Flexbox,
    PrimaryButton,
    SecondaryIconButton,
    Tag,
    Text,
    Tooltip,
    usePersistedState,
    WarningIcon,
} from '@luminovo/design-system';
import { CloseRounded } from '@mui/icons-material';
import { useCallback, useState } from 'react';
import ConfirmationDialogBox from '../../../components/dialogBox/ConfirmationDialogBox';
import { useMultiLevelBetaInfoDialog } from './MultiLevelBetaInfoDialog';

export const BomImporterNavbar = ({
    fileName,
    onClose,
    handleImportBom,
    isReusedColumnMapping,
    isImportDisabled,
    isLoadingSpinnerShown,
    hasLevelTagAssigned,
}: {
    fileName: string;
    onClose: () => void;
    handleImportBom: () => void;
    isReusedColumnMapping: boolean;
    isImportDisabled: boolean;
    isLoadingSpinnerShown: boolean;
    hasLevelTagAssigned: boolean;
}) => {
    return (
        <Flexbox
            justifyContent="space-between"
            padding="10px 16px"
            borderBottom={`1px solid ${colorSystem.neutral[2]}`}
            boxSizing="border-box"
            style={{ backgroundColor: colorSystem.neutral.white }}
        >
            <Flexbox alignItems="center" gap={8}>
                <CloseButton onClose={onClose} />
                <Text variant="h3">{fileName.length === 0 ? t`Column mapping` : fileName}</Text>
                {isReusedColumnMapping && (
                    <Tooltip
                        title={t`The column mapping was automatically inferred from the previous upload of your company were a BOM with the identical Excel header was imported.`}
                    >
                        <span>
                            <Tag color="primary" label={t`Inferred from previous upload`} />
                        </span>
                    </Tooltip>
                )}
            </Flexbox>
            <Flexbox alignItems="center" gap={8}>
                {isImportDisabled && (
                    <Flexbox alignItems="center" gap={8} marginRight="16px">
                        <WarningIcon />
                        <Text color={colorSystem.red[7]}>
                            <Trans>You cannot edit or import the BOM because it contains errors.</Trans>
                        </Text>
                    </Flexbox>
                )}
                <ButtonImportBom
                    isImportDisabled={isImportDisabled}
                    isLoadingSpinnerShown={isLoadingSpinnerShown}
                    handleImportBom={handleImportBom}
                    hasLevelTagAssigned={hasLevelTagAssigned}
                />
            </Flexbox>
        </Flexbox>
    );
};

const CloseButton = ({ onClose }: { onClose: () => void }) => {
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

    function openDialog() {
        setIsDialogOpen(true);
    }

    function closeDialog() {
        setIsDialogOpen(false);
    }
    return (
        <>
            <SecondaryIconButton size="small" onClick={openDialog}>
                <CloseRounded fontSize="inherit" />
            </SecondaryIconButton>
            <ConfirmationDialogBox
                text={t`Are you sure you want to exit the BOM importer? All progress will be lost.`}
                title={t`Exit BOM Importer`}
                onConfirm={onClose}
                onReject={closeDialog}
                isDialogOpen={isDialogOpen}
                deleteText={t`Yes, exit`}
            />
        </>
    );
};

const ButtonImportBom = ({
    isImportDisabled,
    isLoadingSpinnerShown,
    handleImportBom,
    hasLevelTagAssigned,
}: {
    isImportDisabled: boolean;
    isLoadingSpinnerShown: boolean;
    handleImportBom: () => void;
    hasLevelTagAssigned: boolean;
}) => {
    const { openDialog } = useMultiLevelBetaInfoDialog({ handleImportBom });
    const [isDoNotShowAgain, setDoNotShowAgain] = usePersistedState<'true' | 'false'>(
        'luminovo.multi-level-bom-beta-dialog',
        'false',
        localStorage,
    );

    const handleClick = useCallback(() => {
        if (hasLevelTagAssigned && isDoNotShowAgain === 'false') {
            return openDialog({ setDoNotShowAgain });
        }
        handleImportBom();
    }, [handleImportBom, hasLevelTagAssigned, openDialog, isDoNotShowAgain, setDoNotShowAgain]);

    return (
        <>
            <PrimaryButton
                id={id('bom_importer/button_import')}
                size="medium"
                data-trackingid="biCompleteColumnMappingButton"
                onClick={handleClick}
                disabled={isImportDisabled || isLoadingSpinnerShown}
                isLoading={isLoadingSpinnerShown}
            >
                <Trans>Import</Trans>
            </PrimaryButton>
        </>
    );
};
