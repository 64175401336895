import { Column } from 'ag-grid-community';
import { DataGridColumn } from '../types';

export function getColumnLabel(column: Column): string {
    const originalColDef = column.getUserProvidedColDef() as DataGridColumn<any, any>;

    if (originalColDef.label) {
        return originalColDef.label();
    }

    return '';
}
