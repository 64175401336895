import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { PageLayout } from '../../components/PageLayout';
import { LumiQuoteRoute, UrlParams, route } from '../../utils/routes';
import { typed } from '../../utils/typingUtils';
import { ViewContext } from '../Bom/components/ModuleTableData';
import { ToolbarPcb } from './components/ToolbarPcb/ToolbarPcb';

export const PcbRedirectPage = ({
    pathParams,
    queryParams,
}: UrlParams<'/rfqs/:rfqId/bom/assembly/:assemblyId/pcb'>) => {
    const { assemblyId, rfqId } = pathParams;
    const { currentParentAssemblyId } = queryParams;
    const viewContext: ViewContext = React.useMemo(() => {
        return {
            type: 'WithinRfQ',
            rfqId,
        };
    }, [rfqId]);
    return (
        <PageLayout
            removeBottomPadding
            header={
                <ToolbarPcb assemblyId={assemblyId} rfqId={rfqId} currentParentAssemblyId={currentParentAssemblyId} />
            }
            layout="fragment"
        >
            <PcbRedirectTab
                assemblyId={assemblyId}
                rfqId={rfqId}
                currentParentAssemblyId={currentParentAssemblyId}
                viewContext={viewContext}
            />
        </PageLayout>
    );
};

const PCB_ROUTES = [
    typed<LumiQuoteRoute>('/rfqs/:rfqId/bom/assembly/:assemblyId/pcb'),
    typed<LumiQuoteRoute>('/assemblies/:assemblyId/pcb'),
];

export const PcbRedirectTab = ({
    rfqId,
    assemblyId,
    currentParentAssemblyId,
    viewContext,
}: {
    assemblyId: string;
    rfqId: string;
    currentParentAssemblyId: string | null | undefined;
    viewContext: ViewContext;
}) => {
    return (
        <Switch>
            {PCB_ROUTES.map((pcbRoute) => (
                <Route key={pcbRoute} exact path={pcbRoute}>
                    <Redirect
                        to={
                            viewContext.type === 'AssemblyOverview'
                                ? route('/assemblies/:assemblyId/pcb/files', { assemblyId }, { rfqId })
                                : route(
                                      '/rfqs/:rfqId/bom/assembly/:assemblyId/pcb/files',
                                      { rfqId, assemblyId },
                                      { currentParentAssemblyId },
                                  )
                        }
                    />
                </Route>
            ))}
        </Switch>
    );
};
