import * as z from 'zod';
import { UserDTORuntype, UserInviteDTORuntype, UserInviteInsertDTORuntype } from '../user';

export const InviteExternalUserRuntype = UserInviteInsertDTORuntype;

export type ContributorsDTO = z.infer<typeof ContributorsRuntype>;
export const ContributorsRuntype = z.object({
    external: z.array(UserDTORuntype),
    internal: z.array(UserDTORuntype),
    invited: z.array(UserInviteDTORuntype),
});
