import { t } from '@lingui/macro';
import { Currency, formatToIso8601Date, groupBy, omit, sortBy } from '@luminovo/commons';
import { useNavigate } from '@luminovo/design-system';
import { ExtractRequestBody, PriceType, SupplierAndStockLocationDTO, SupplierTag } from '@luminovo/http-client';
import { ImporterConfig, UniversalImporter } from '@luminovo/universal-importer';
import { ImportHandlersCreator } from '@luminovo/universal-importer/src/types';
import { useSnackbar } from 'notistack';
import { useSuspenseHttpQuery } from '../../resources/http/useHttpQuery';
import { useHttpMutation } from '../../resources/mutation/useHttpMutation';
import { route } from '../../utils/routes';
import { formatError } from '../Error/formatError';

export function OfferImporter() {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const createImportHandlers = useCreateImportHandlers();

    const { data: manufacturers } = useManufacturerNames();

    const { data: suppliers = [] } = useSuspenseHttpQuery(
        'GET /suppliers-and-stock-locations',
        {},
        { select: (res) => res.data.filter((supplier) => Boolean(supplier.supplier_number)) },
    );

    const { data: defaultCurrency = Currency.EUR } = useSuspenseHttpQuery(
        'GET /organization-settings/organization-currency-settings',
        {},
        { select: (res) => res.data.currency },
    );

    const decimalSeparator = '.';

    return (
        <UniversalImporter
            title={t`Offer importer`}
            batchSize={Infinity}
            hrefBack={route('/parts/components/ipn')}
            onImportDone={() => {
                enqueueSnackbar(t`Offers imported successfully`, {
                    variant: 'success',
                    anchorOrigin: {
                        horizontal: 'center',
                        vertical: 'top',
                    },
                });
                navigate(-1);
            }}
            createImportHandlers={createImportHandlers}
            config={createImporterConfig({
                manufacturers: manufacturers?.manufacturers ?? [],
                manufacturerNames: manufacturers?.manufacturerNames ?? new Set(),
                decimalSeparator,
                defaultCurrency,
                suppliers,
            })}
        />
    );
}

type OfferImporterConfig = ReturnType<typeof createImporterConfig>;
type Offer = ExtractRequestBody<'POST /offers/import'>[0];
type Part = ExtractRequestBody<'POST /offers/import'>[0]['part'];

function useCreateImportHandlers(): ImportHandlersCreator<OfferImporterConfig, Offer> {
    const { mutateAsync: importOffers } = useHttpMutation('POST /offers/import', { snackbarMessage: null });

    return (globalFields) => ({
        postprocessRows: (rows) => {
            const rowsWithOffers = rows.map(({ row, tableRecord: { data } }) => {
                const customerName = data.customerName;
                const customerNumber = data.customerNumber;
                const customer =
                    customerNumber && customerNumber.length > 0
                        ? { name: customerName, number: customerNumber }
                        : undefined;

                const internal_part_number: string | undefined = data.ipn ?? undefined;
                const manufacturer = data.manufacturer;
                const manufacturer_part_number = data.mpn;
                const part: Part =
                    manufacturer && manufacturer_part_number
                        ? { manufacturer, manufacturer_part_number, internal_part_number }
                        : { internal_part_number: internal_part_number! };

                const offer: Offer = {
                    part,
                    prices: [
                        {
                            unit_price: {
                                amount: String(data.unitPrice / data.pricePer),
                                currency: data.currency,
                            },
                            moq: data.moq,
                            mpq: data.mpq,
                        },
                    ],
                    price_type:
                        globalFields && globalFields.length > 0 && globalFields[0].value?.id
                            ? (globalFields[0].value.id as PriceType)
                            : undefined,
                    availability: {
                        available_stock: data.availableStock,
                        total_stock: data.totalStock,
                        lead_time: data.leadTimeWeeks * 7,
                    },
                    notes: data.notes,
                    packaging: data.packaging,
                    supplier:
                        data.supplierNumber && data.supplierNumber.length > 0
                            ? {
                                  type: 'External',
                                  supplier_number: data.supplierNumber,
                              }
                            : {
                                  type: 'Internal',
                              },
                    customer,
                    valid_from: data.validFrom ? formatToIso8601Date(data.validFrom) : undefined,
                    valid_until: data.validUntil ? formatToIso8601Date(data.validUntil) : undefined,
                };

                return { row, offer };
            });

            // It is safe to use results of JSON.stringify here, because the offer objects are explicitly initialized above,
            // so the field order is the same between different objects,
            // so JSON.stringify will produce the same results when underlying data is the same.
            const rowsWithOffersByPart = groupBy(rowsWithOffers, ({ offer }) => JSON.stringify(omit(offer, 'prices')));

            return Object.entries(rowsWithOffersByPart).map(([, items]) => ({
                rows: items.map(({ row }) => row),
                handlerRecord: {
                    // It's safe to do non-null assertion here because `items` array is guaranteed to be non-empty,
                    // by construction of `rowsWithOffersByPart` (`groupBy` returns non-empty groups)
                    ...items[0]!.offer,
                    prices: sortBy(
                        items.flatMap(({ offer }) => offer.prices),
                        (price) => price.moq,
                    ),
                },
            }));
        },
        handleBatch: async (handlerRecords) =>
            importOffers({ requestBody: handlerRecords })
                .then((results) =>
                    handlerRecords.map((offer, index) => {
                        const resultItem = results.find((result) => {
                            const resultPart = result.part;
                            const offerPart = offer.part;

                            const ipnStrictMatch =
                                offerPart.internal_part_number &&
                                resultPart?.internal_part_number === offerPart?.internal_part_number;
                            const mpnStrictMatch =
                                'manufacturer_part_number' in offerPart &&
                                offerPart.manufacturer_part_number &&
                                resultPart?.manufacturer_part_number === offerPart?.manufacturer_part_number;
                            const manufacturerMatch =
                                'manufacturer' in offerPart && resultPart?.manufacturer === offerPart?.manufacturer;
                            return ipnStrictMatch || (mpnStrictMatch && manufacturerMatch);
                        });

                        if (!resultItem) {
                            return {
                                success: false as const,
                                message: t`Unknown error`,
                            };
                        }

                        if (resultItem.status > 299) {
                            return {
                                success: false as const,
                                message: resultItem.description ?? t`Unknown error`,
                            };
                        }

                        return {
                            success: true as const,
                            message: resultItem.description,
                        };
                    }),
                )
                .catch((error) =>
                    handlerRecords.map(() => ({
                        success: false as const,
                        message: formatError(error),
                    })),
                ),
    });
}

const createImporterConfig = ({
    manufacturers,
    manufacturerNames,
    decimalSeparator,
    defaultCurrency,
    suppliers,
}: {
    manufacturers: Array<{ id: string; label: string; description: string }>;
    manufacturerNames: Set<string>;
    decimalSeparator: '.' | ',';
    defaultCurrency: Currency;
    suppliers: SupplierAndStockLocationDTO[];
}) => {
    const config = {
        globalFields: [
            {
                id: 'priceType' as const,
                label: t`Price type`,
                description: t`The price type of the offer`,
                required: true,
                parser: { type: 'priceType', options: { trim: true } },
            },
        ],
        fields: [
            {
                id: 'mpn' as const,
                columnIndices: [],
                required: { color: 'green', label: t`For MPN offers` },
                parser: { type: 'string', options: { trim: true, minLength: 1 } },
                label: t`MPN`,
                description: t`The manufacturer part number. Only required for MPN offers.`,
                defaultValue: { id: null, label: '' },
            },
            {
                id: 'manufacturer' as const,
                columnIndices: [],
                required: { color: 'green', label: t`For MPN offers` },
                parser: { type: 'manufacturer.name', options: { manufacturers, manufacturerNames } },
                label: t`Manufacturer`,
                description: t`The name of the manufacturer. Only required for MPN offers.`,
                defaultValue: { id: null, label: '' },
            },
            {
                id: 'ipn' as const,
                columnIndices: [],
                required: { color: 'violet', label: t`For IPN offers` },
                parser: { type: 'ipn', options: { ipns: [] } },
                label: t`IPN`,
                description: t`The internal part number. Only required for IPN offers.`,
                defaultValue: { id: null, label: '' },
            },
            {
                id: 'unitPrice' as const,
                columnIndices: [],
                required: true,
                parser: {
                    type: 'number',
                    options: {
                        decimalSeparator,
                    },
                },
                label: t`Unit price`,
                description: t`The price of a single unit.`,
            },
            {
                id: 'pricePer' as const,
                columnIndices: [],
                required: false,
                parser: { type: 'number', options: { min: 1 } },
                label: t`Price per`,
                defaultValue: { id: 1, label: `1`, description: '' },
                description: t`Is the unit pricer per 1s, 10s, 100s, 1000s, etc.`,
            },
            {
                id: 'currency' as const,
                columnIndices: [],
                required: false,
                parser: { type: 'currency', options: {} },
                label: t`Currency`,
                defaultValue: { id: defaultCurrency, label: defaultCurrency },
                description: t`The unit price's currency.`,
            },
            {
                id: 'moq' as const,
                columnIndices: [],
                required: false,
                parser: { type: 'number', options: { min: 0, decimalSeparator } },
                label: t`MOQ`,
                defaultValue: { id: 1, label: `1`, description: '' },
                description: t`The minimum order quantity.`,
            },
            {
                id: 'mpq' as const,
                columnIndices: [],
                required: false,
                parser: { type: 'number', options: { min: 1, decimalSeparator } },
                label: t`MPQ`,
                defaultValue: { id: 1, label: `1`, description: '' },
                description: t`The minimum package quantity.`,
            },
            {
                id: 'packaging' as const,
                columnIndices: [],
                required: false,
                parser: { type: 'packaging', options: {} },
                label: t`Packaging`,
                defaultValue: { id: null, label: t`Unknown` },
                description: t`The packaging in which the part is delivered (e.g. reel, tape, etc.).`,
            },
            {
                id: 'availableStock' as const,
                columnIndices: [],
                required: false,
                defaultValue: { id: 0, label: `0` },
                parser: { type: 'number', options: { min: 0, decimalSeparator } },
                label: t`Available stock`,
                description: t`The available stock.`,
            },
            {
                id: 'totalStock' as const,
                columnIndices: [],
                required: false,
                defaultValue: { id: null, label: t`Unknown` },
                parser: { type: 'number', options: { min: 0, decimalSeparator } },
                label: t`Total stock`,
                description: t`The total amount of stock. Some of it might be reserved or not available for use.`,
            },
            {
                id: 'leadTimeWeeks' as const,
                columnIndices: [],
                required: false,
                label: t`Lead time (weeks)`,
                parser: { type: 'leadTimeWeeks', options: { decimalSeparator } },
                description: t`The standard manufacturer lead time.`,
                defaultValue: { id: null, label: t`Unknown`, description: '' },
            },
            {
                id: 'supplierNumber' as const,
                columnIndices: [],
                required: false,
                parser: {
                    type: 'supplier.number',
                    options: { suppliers: suppliers.map(toParsedValue), warnOnSystemSupplier: false },
                },
                label: t`Supplier number`,
                description: t`The number that identifies the supplier of this offer. Defaults to 'inventory', meaning the offer is for internal stock.`,
                defaultValue: { id: null, label: t`Inventory` },
            },
            {
                id: 'notes' as const,
                columnIndices: [],
                required: false,
                defaultValue: { id: '', label: ``, description: '' },
                parser: { type: 'notes', options: { min: 0 } },
                label: t`Notes`,
                description: t`Any additional information you want to add.`,
            },
            {
                id: 'customerName' as const,
                columnIndices: [],
                required: false,
                parser: { type: 'string', options: { trim: false } },
                label: t`Customer name`,
                description: t`The name of the customer this offer is for. Leave blank if the offer is not linked to a specific customer.`,
            },
            {
                id: 'customerNumber' as const,
                columnIndices: [],
                required: false,
                parser: { type: 'string', options: { trim: false } },
                label: t`Customer number`,
                description: t`The number of the customer this offer is for. Leave blank if the offer is not linked to a specific customer.`,
            },
            {
                id: 'validFrom' as const,
                columnIndices: [],
                required: false,
                parser: { type: 'date', options: { parseEmptyAsNull: true } },
                label: t`Valid from`,
                description: t`The date from which the offer is valid.`,
                defaultValue: { id: undefined, label: t`Unknown` },
            },
            {
                id: 'validUntil' as const,
                columnIndices: [],
                required: false,
                parser: { type: 'date', options: { parseEmptyAsNull: true } },
                label: t`Valid until`,
                description: t`The date until which the offer is valid.`,
                defaultValue: { id: undefined, label: t`Unknown` },
            },
        ],
    } satisfies ImporterConfig;
    return config;
};

function useManufacturerNames() {
    return useSuspenseHttpQuery(
        'GET /manufacturers',
        { queryParams: { high_quality: false } },
        {
            select: (res) => {
                const manufacturersWithAlternatives = res.data.flatMap((man) => {
                    const preferredName = man.name;
                    const alternativeNames = man.alternative_names;

                    return [preferredName, ...alternativeNames].map((name) => {
                        return {
                            // Note that we purposely treat the name as the id as this is used for the
                            // manufacturer NAME parser, in which the id is actually the name.
                            id: name,
                            label: name,
                            description: '',
                        };
                    });
                });

                const manufacturerNames = new Set(manufacturersWithAlternatives.map((man) => man.id.toLowerCase()));
                return {
                    manufacturers: manufacturersWithAlternatives,
                    manufacturerNames,
                };
            },
        },
    );
}

function toParsedValue(supplier: SupplierAndStockLocationDTO) {
    return {
        id: supplier.supplier_number ?? '',
        label: String(supplier.supplier_number),
        description: supplier.supplier.name,
        existing: true,
        isSystem: supplier.tags.some((s) => s.tag === SupplierTag.System),
    };
}
