import { t, Trans } from '@lingui/macro';
import { assertUnreachable, isPresent } from '@luminovo/commons';
import {
    colorSystem,
    FieldCheckboxControlled,
    FieldRadioControlled,
    Flexbox,
    FontVariant,
    Text,
} from '@luminovo/design-system';
import { OrderSelectionPriceDTO, TaxBreakdownDTO } from '@luminovo/http-client';
import { formatTax } from '@luminovo/manufacturing-core';
import { CircularProgress, Divider, RadioGroup } from '@mui/material';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useCurrentUserDetailsContext } from '../../../components/contexts/CurrentUserDetailsContext';
import { useHttpQuery } from '../../../resources/http/useHttpQuery';
import { formatCurrency } from '../../../utils/currencyUtils';
import { isEmpty } from '../../../utils/isEmpty';
import { OrderFormState, OrderSelectionOptionWithId } from '../types';
import { OrderButton } from './OrderButton';

const PriceDetails = ({
    title,
    children,
    color,
    variant,
}: {
    title: string;
    children: React.ReactNode;
    color?: string;
    variant?: FontVariant;
}) => {
    return (
        <Flexbox width="100%" justifyContent="space-between" padding="12px 0" alignItems="center">
            <Text variant="h4" color={colorSystem.neutral[6]}>
                {title}
            </Text>
            <Text variant={variant ?? 'h4'} color={color ?? colorSystem.neutral[6]}>
                {children}
            </Text>
        </Flexbox>
    );
};

const TaxBreakdown = ({ taxBreakdown }: { taxBreakdown: TaxBreakdownDTO }): JSX.Element => {
    const {
        amount: { amount, currency },
    } = taxBreakdown;

    const title = formatTax(taxBreakdown);

    return <PriceDetails title={title}>+ {formatCurrency(amount, currency)}</PriceDetails>;
};

const PriceBreakdown = ({ price }: { price: OrderSelectionPriceDTO }): JSX.Element => {
    const { type, data } = price;

    switch (type) {
        case 'ExcludingTax':
            return (
                <PriceDetails title={t`Price (excl. VAT)`} color={colorSystem.primary[7]}>
                    {formatCurrency(
                        data.total_price_before_tax.total_cost.amount,
                        data.total_price_before_tax.total_cost.currency,
                    )}
                </PriceDetails>
            );
        case 'IncludingTax':
            return (
                <Flexbox width="100%" flexDirection="column">
                    <PriceDetails title={t`Price (excl. tax)`}>
                        {formatCurrency(
                            data.total_price_before_tax.total_cost.amount,
                            data.total_price_before_tax.total_cost.currency,
                        )}
                    </PriceDetails>
                    {data.tax_breakdowns.map((taxBreakdown, i) => (
                        <TaxBreakdown taxBreakdown={taxBreakdown} key={`${i}-${taxBreakdown.percentage}`} />
                    ))}
                    <PriceDetails title={t`Total price (incl. tax)`} color={colorSystem.primary[7]} variant="h3">
                        {formatCurrency(data.total_price_after_tax.amount, data.total_price_after_tax.currency)}
                    </PriceDetails>
                </Flexbox>
            );
        default:
            assertUnreachable(type);
    }
};

const CheckMark = ({
    children,
    name,
}: {
    children: React.ReactNode;
    name: 'areTermsChecked' | 'areSpecsChecked';
}): JSX.Element => {
    const { control } = useFormContext<OrderFormState>();

    return (
        <Flexbox flexDirection="row" gap="8px">
            <FieldCheckboxControlled
                name={name}
                control={control}
                FieldProps={{ size: 'small', style: { verticalAlign: 'top' } }}
            />
            {children}
        </Flexbox>
    );
};

const TermsAndConditions = (): JSX.Element => {
    const orgName = useCurrentUserDetailsContext().organization.name;
    const { data: customerPortalSettings } = useHttpQuery('GET /customer-portal-settings', {});

    const link: string | undefined = customerPortalSettings?.terms_and_conditions_link;
    const showLink = !isEmpty(link);

    return (
        <Text variant="body-small">
            {showLink ? (
                <Trans>
                    I agree to the{' '}
                    <span
                        onClick={() => {
                            window.open(link, '_blank');
                        }}
                        style={{ cursor: 'pointer' }}
                    >
                        <u>Terms and Conditions</u>
                    </span>{' '}
                    of {orgName}
                </Trans>
            ) : (
                <Trans>I agree to the Terms and Conditions of {orgName}</Trans>
            )}
        </Text>
    );
};

export const OrderOverviewCard = ({ orders, rfqId }: { orders: OrderSelectionOptionWithId[]; rfqId: string }) => {
    const { control, watch } = useFormContext<OrderFormState>();
    const selectedOrderId = watch(`selectedOrderOptionId`);
    const selectedOrder = orders.find((order) => order.id === selectedOrderId);
    const { data: paymentSettings } = useHttpQuery('GET /customer-portal-settings/payment-settings', {});
    const { data: customerPortalSettings } = useHttpQuery('GET /customer-portal-settings', {});
    const checkoutConditions = watch(`checkoutConditions`);
    const areAllCheckoutConditionsChecked = watch(`checkoutConditions`).every((condition) => condition.checked);

    const isPaymentProcessorSetup = paymentSettings?.is_payment_processor_setup ?? false;
    const isSubmitDisabled =
        !isPaymentProcessorSetup ||
        !watch(`areSpecsChecked`) ||
        !watch(`areTermsChecked`) ||
        !areAllCheckoutConditionsChecked ||
        !watch(`selectedOrderOptionId`) ||
        parseFloat(selectedOrder?.price.data.total_price_before_tax.total_cost.amount ?? '0') === 0;

    return (
        <Flexbox
            style={{
                backgroundColor: colorSystem.neutral.white,
                flexDirection: 'column',
                padding: '24px',
                borderRadius: '8px',
            }}
            gap="32px"
        >
            <Text variant="h3">
                <Trans>Order overview</Trans>
            </Text>

            {selectedOrder && (
                <Flexbox flexDirection="column">
                    <PriceDetails title={t`Order size`}>
                        <Text variant="h3" color={colorSystem.neutral[9]}>
                            {selectedOrder.order_size}
                        </Text>
                    </PriceDetails>
                    <Divider />
                    <PriceBreakdown price={selectedOrder.price} />
                    <Divider />
                </Flexbox>
            )}

            <Flexbox flexDirection="column" gap="16px">
                <CheckMark name="areTermsChecked">
                    <TermsAndConditions />
                </CheckMark>
                <CheckMark name="areSpecsChecked">
                    <Text variant="body-small">
                        <Trans>
                            I have checked the specifications available in the customer portal on which the offer is
                            based and accept them
                        </Trans>
                    </Text>
                </CheckMark>

                {checkoutConditions.map((field, index) => (
                    <Flexbox key={index} alignItems="center" gap="8px">
                        <FieldCheckboxControlled
                            name={`checkoutConditions.${index}.checked`}
                            control={control}
                            FieldProps={{ size: 'small' }}
                        />
                        <Text variant="body-small">{field.text}</Text>
                    </Flexbox>
                ))}
            </Flexbox>

            <RadioGroup>
                <Flexbox gap={12} flexDirection={'column'}>
                    <Text variant="h4">
                        <Trans>Payment mode</Trans>
                    </Text>
                    {isPresent(customerPortalSettings) ? (
                        <>
                            {customerPortalSettings.payment_methods !== 'InvoiceOnly' && (
                                <Flexbox alignItems={'center'} gap={8}>
                                    <FieldRadioControlled
                                        control={control}
                                        name="paymentMode"
                                        FieldProps={{ fieldValue: 'ChargeImmediately', size: 'small' }}
                                    />
                                    <Text>
                                        <Trans>Instant payment</Trans>
                                    </Text>
                                </Flexbox>
                            )}
                            {customerPortalSettings.payment_methods !== 'InstantOnly' && (
                                <Flexbox alignItems={'start'} gap={8}>
                                    <FieldRadioControlled
                                        control={control}
                                        name="paymentMode"
                                        FieldProps={{ fieldValue: 'SendInvoice', size: 'small' }}
                                    />
                                    <Flexbox flexDirection="column" gap="4px">
                                        <Text variant="body">
                                            <Trans>Payment by invoice</Trans>
                                        </Text>
                                    </Flexbox>
                                </Flexbox>
                            )}
                        </>
                    ) : (
                        <CircularProgress />
                    )}
                </Flexbox>
            </RadioGroup>

            <OrderButton disabled={isSubmitDisabled} rfqId={rfqId} />
        </Flexbox>
    );
};
