import { useHttpQuery } from '@/resources/http/useHttpQuery';
import { useHttpMutation } from '@/resources/mutation/useHttpMutation';
import { t } from '@lingui/macro';
import { formatDecimal, formatMonetaryValue, formatToLongDate, isPresent, transEnum } from '@luminovo/commons';
import {
    ChangeIcon,
    colorSystem,
    createColumnHelper,
    DestructiveTertiaryIconButton,
    Flexbox,
    FormItem,
    Link,
    SupportedFilterFn,
    Tag,
    TanStackTable,
    Text,
    useTanStackTable,
    WarningCircleIcon,
} from '@luminovo/design-system';
import {
    QuoteRequestDTO,
    QuoteRequestLineItemDTO,
    QuoteRequestLineItemStatus,
    StandardPartOfferDTO,
} from '@luminovo/http-client';
import { formatQuantity, formatSupplierDTO } from '@luminovo/sourcing-core';
import { Delete, DescriptionOutlined } from '@mui/icons-material';
import { Box, Divider, Tooltip } from '@mui/material';
import React from 'react';
import { LayoutCard } from '../../../../../components/LayoutCard';
import { useOTSOffers } from '../../../../../resources/offer/offerHandler';
import { MultiProgressPieChart } from '../../../../Negotiations/components/MultiProgressPieChart';
import { NegotiationIpnLabel } from '../../../../Negotiations/components/NegotiationIpnLabel';
import { PartLiteLabel } from '../../../../Negotiations/components/PartLiteLabel';
import {
    getQuoteRequestLineItemStatusColor,
    QuoteRequestLineItemStatusChip,
} from '../../../../Negotiations/components/QuoteRequestLineItemStatusChip';
import { WarningComponent } from '../../../../Negotiations/components/TableWarningWrapper';
import {
    useQuoteRequestLineItemsByQuoteRequest,
    useSuspendedQuoteRequest,
} from '../../../../Negotiations/hooks/negotiationHandlers';
import { quoteRequestLineItemStatusTranslations } from '../../../../Negotiations/i18n';
import { formatPartLite } from '../../../../Negotiations/model/formatPartLite';
import { formatQuoteRequestLineItemWarning } from '../../../../Negotiations/model/formatQuoteRequestLineItemWarnings';
import {
    doesUnitPriceHaveWarning,
    getRowWarnings,
    getUnitPriceToTargetPriceScale,
    hasMoqWarning,
    hasPartChanged,
    QuoteRequestLineItemWarning,
} from '../../../../Negotiations/model/getQuoteRequestLineItemWarnings';
import { useOfferDrawer } from '../../../../SolutionManager/components/OfferDrawer';
import { extractManufacturer } from '../QuoteRequestAssignPage/TableManufacturers';

type RowData = QuoteRequestLineItemDTO & {
    offer: StandardPartOfferDTO | undefined;
};

const columnHelper = createColumnHelper<RowData>();

const columns = [
    columnHelper.text('component_origin', {
        id: 'ipn',
        label: () => t`IPN`,
        size: 150,
        cell: ({ row }) => <NegotiationIpnLabel ipn={row.original.component_origin} />,
    }),
    columnHelper.text((row) => formatPartLite(row.requested_part), {
        id: 'requestedPart',
        label: () => t`Requested part`,
        size: 240,
        cell: ({ row }) => <PartLiteLabel variant={'body-small'} part={row.original.requested_part} />,
    }),
    columnHelper.text((row) => extractManufacturer(row.requested_part).name, {
        id: 'manufacturer',
        label: () => t`Manufacturer`,
        size: 100,
        initialVisibility: false,
    }),
    columnHelper.text((row) => row.description ?? '-', {
        id: 'description',
        label: () => t`Description`,
        size: 200,
    }),
    columnHelper.number((row) => row.required_quantity.quantity, {
        id: 'requiredQuantity',
        label: () => t`Qty`,
        size: 100,
        cell: ({ row }) => formatQuantity(row.original.required_quantity, { showPiecesUnit: false }),
    }),
    columnHelper.text((row) => formatPartLite(row.received_offer?.part), {
        id: 'offeredPart',
        label: () => t`Offered part`,
        size: 200,
        cell: ({ row }) => (
            <WarningComponent
                tooltipText="The offered part is different from the requested part"
                warning={hasPartChanged(row.original)}
                startIcon={<ChangeIcon fill={colorSystem.yellow[7]} />}
            >
                <PartLiteLabel
                    variant={'body-small'}
                    part={row.original.received_offer?.part}
                    color={hasPartChanged(row.original) ? 'inherit' : undefined}
                />
            </WarningComponent>
        ),
    }),
    columnHelper.monetaryValue((row) => row.received_offer?.unit_price, {
        id: 'unitPrice',
        label: () => t`Unit price`,
        size: 100,
        renderType: 'generic',
        cell: ({ row, getValue }) => {
            const unitPriceWarning = doesUnitPriceHaveWarning(row.original);
            const unitPriceScale = getUnitPriceToTargetPriceScale(row.original);
            return (
                <WarningComponent
                    startIcon={<WarningCircleIcon />}
                    warning={unitPriceWarning}
                    tooltipText={`Unit price is ${unitPriceScale}x of the target price`}
                >
                    {formatMonetaryValue(getValue(), 'unit-price', { ifAbsent: '-' })}
                </WarningComponent>
            );
        },
    }),
    columnHelper.number((row) => row.received_offer?.moq, {
        id: 'moq',
        label: () => t`MOQ`,
        size: 100,
        cell: ({ row, getValue }) => {
            // warn if the MOQ is 50% or more of the required quantity
            const moq = getValue();
            if (!isPresent(moq)) {
                return <>-</>;
            }
            const requiredQuantity = row.original.required_quantity.quantity;
            const showWarning = hasMoqWarning(row.original);
            if (showWarning) {
                return (
                    <Tooltip
                        title={`Large MOQ: ${formatDecimal(moq / requiredQuantity, { maximumFractionDigits: 1 })}x the requested quantity`}
                    >
                        <span>
                            <Tag attention="low" color="yellow" label={formatDecimal(getValue(), { ifAbsent: '-' })} />
                        </span>
                    </Tooltip>
                );
            }
            return <>{formatDecimal(getValue(), { ifAbsent: '-' })}</>;
        },
    }),
    columnHelper.number((row) => row.received_offer?.mpq, {
        id: 'mpq',
        label: () => t`MPQ`,
        size: 100,
        cell: ({ getValue }) => formatDecimal(getValue(), { ifAbsent: '-' }),
    }),
    columnHelper.number((row) => row.offer?.available_prices.stock, {
        id: 'stock',
        label: () => t`Stock`,
        size: 100,
        cell: ({ getValue }) => formatDecimal(getValue(), { ifAbsent: '-' }),
    }),
    columnHelper.text((row) => row.offer?.packaging, {
        id: 'packaging',
        label: () => t`Packaging`,
        size: 100,
        cell: ({ getValue }) => getValue() ?? '-',
    }),
    columnHelper.text((row) => row.offer?.ncnr, {
        id: 'ncnr',
        label: () => t`NCNR`,
        size: 100,
        cell: ({ getValue }) => getValue() ?? '-',
    }),
    columnHelper.text((row) => row.offer?.notes, {
        id: 'notes',
        label: () => t`Notes`,
        size: 100,
        cell: ({ getValue }) => getValue() ?? '-',
    }),
    columnHelper.enum((row) => row.status, {
        id: 'status',
        label: () => t`Status`,
        size: 100,
        getOptionLabel: (opt) => transEnum(opt, quoteRequestLineItemStatusTranslations),
        initialPinning: 'right',
        cell: (item) => <QuoteRequestLineItemStatusChip quoteRequestLineItemStatus={item.getValue()} />,
        quickFilters: [
            {
                label: () => 'Received',
                value: { filterFn: SupportedFilterFn.equalsAny, value: [QuoteRequestLineItemStatus.Received] },
                showCount: true,
            },
        ],
    }),
    columnHelper.array(getRowWarnings, {
        id: 'warning',
        getOptionLabel: formatQuoteRequestLineItemWarning,
        quickFilters: [
            {
                label: () => t`With differing offered part`,
                value: {
                    filterFn: SupportedFilterFn.arrIncludesSome,
                    value: [QuoteRequestLineItemWarning.PartChanged],
                },
                showCount: true,
            },
            {
                label: () => t`With large MOQ`,
                value: { filterFn: SupportedFilterFn.arrIncludesSome, value: [QuoteRequestLineItemWarning.LargeMOQ] },
                showCount: true,
            },
        ],
        initialVisibility: false,
        enableHiding: false,
        label: () => t`Warning`,
        size: 100,
    }),
];

export function TableQuoteRequestLineItems({
    quoteRequestLineItems,
}: {
    quoteRequestLineItems: QuoteRequestLineItemDTO[] | undefined;
}) {
    const { openOfferDrawer } = useOfferDrawer();

    const offerIds = quoteRequestLineItems?.flatMap((lineItem) => {
        if (!isPresent(lineItem.received_offer) || lineItem.received_offer?.offer_id.kind !== 'OffTheShelf') {
            return [];
        }
        return [lineItem.received_offer.offer_id.id];
    });
    // TODO here we need the rfqContext
    const { data: offers } = useOTSOffers({ offerIds, rfqContext: { type: 'OutsideRfQ' } });

    const data: Array<RowData> | undefined = React.useMemo(() => {
        return quoteRequestLineItems?.map((lineItem) => ({
            ...lineItem,
            offer: offers?.find((offer) => offer.id === lineItem.received_offer?.offer_id.id),
        }));
    }, [quoteRequestLineItems, offers]);

    const { table } = useTanStackTable({
        data,
        columns,
        enableColumnOrdering: true,
        enableColumnHiding: true,
        onRowClick: (row) => {
            if (!isPresent(row.original.offer)) {
                return;
            }
            openOfferDrawer({
                offer: row.original.offer,
                rfqContext: { type: 'OutsideRfQ' },
            });
        },
    });

    return <TanStackTable table={table} />;
}

function QuoteRequestLineItemsStatusPieChart({
    quoteRequestLineItems,
}: {
    quoteRequestLineItems: QuoteRequestLineItemDTO[];
}) {
    const quoteRequestLineItemStatuses = quoteRequestLineItems.map((li) => li.status);

    const lineItemData = [
        {
            label: transEnum(QuoteRequestLineItemStatus.Awarded, quoteRequestLineItemStatusTranslations),
            value: quoteRequestLineItemStatuses.filter((x) => x === QuoteRequestLineItemStatus.Awarded).length,
            color: colorSystem[getQuoteRequestLineItemStatusColor(QuoteRequestLineItemStatus.Awarded)][5],
        },
        {
            label: transEnum(QuoteRequestLineItemStatus.Sent, quoteRequestLineItemStatusTranslations),
            value: quoteRequestLineItemStatuses.filter((x) => x === QuoteRequestLineItemStatus.Sent).length,
            color: colorSystem[getQuoteRequestLineItemStatusColor(QuoteRequestLineItemStatus.Sent)][5],
        },
        {
            label: transEnum(QuoteRequestLineItemStatus.Received, quoteRequestLineItemStatusTranslations),
            value: quoteRequestLineItemStatuses.filter((x) => x === QuoteRequestLineItemStatus.Received).length,
            color: colorSystem[getQuoteRequestLineItemStatusColor(QuoteRequestLineItemStatus.Received)][5],
        },
        {
            label: transEnum(QuoteRequestLineItemStatus.NoBid, quoteRequestLineItemStatusTranslations),
            value: quoteRequestLineItemStatuses.filter((x) => x === QuoteRequestLineItemStatus.NoBid).length,
            color: colorSystem[getQuoteRequestLineItemStatusColor(QuoteRequestLineItemStatus.NoBid)][5],
        },
    ];

    if (quoteRequestLineItems.length === 0) {
        return null;
    }
    return <MultiProgressPieChart data={lineItemData} margin="30px" width={120} height={120} />;
}

function FormItemNotes({ quoteRequest }: { quoteRequest: QuoteRequestDTO }) {
    return (
        <FormItem label={t`Notes`}>
            <Text
                style={{
                    whiteSpace: 'pre-wrap',
                    display: 'inline-block',
                    overflow: 'hidden',
                    maxWidth: '200px',
                    textOverflow: 'ellipsis',
                }}
            >
                {quoteRequest.notes || `-`}
            </Text>
        </FormItem>
    );
}

function FormItemRecievedOn({ quoteRequest }: { quoteRequest: QuoteRequestDTO }) {
    return (
        <FormItem label={t`Submitted on`}>
            <Text>{formatToLongDate(quoteRequest.received_date, { ifAbsent: '-' })}</Text>
        </FormItem>
    );
}

function FormItemSentOn({ quoteRequest }: { quoteRequest: QuoteRequestDTO }) {
    return (
        <FormItem label={t`Sent on`}>
            <Text>{formatToLongDate(quoteRequest.sent_date)}</Text>
        </FormItem>
    );
}

function FormItemSentBy({ quoteRequest }: { quoteRequest: QuoteRequestDTO }) {
    return (
        <FormItem label={t`Sent by`}>
            <Text>
                {quoteRequest.sent_by?.first_name} {quoteRequest.sent_by?.last_name}
            </Text>
        </FormItem>
    );
}

function FormItemDueDate({ quoteRequest }: { quoteRequest: QuoteRequestDTO }) {
    return (
        <FormItem label={t`Due date`}>
            <Text>{formatToLongDate(quoteRequest.due_date)}</Text>
        </FormItem>
    );
}

function FormItemSentTo({ quoteRequest }: { quoteRequest: QuoteRequestDTO }) {
    return (
        <FormItem label={t`Sent to`}>
            <Flexbox maxHeight={200} overflow="auto" flexDirection="column" rowGap={1}>
                {quoteRequest.sent_to.map((reciepient) => (
                    <Text key={reciepient.id} variant="body">
                        {reciepient.email}
                    </Text>
                ))}
            </Flexbox>
        </FormItem>
    );
}

function FormItemAdditionalFiles({ quoteRequest }: { quoteRequest: QuoteRequestDTO }) {
    const { data: additionalFiles } = useHttpQuery('GET /quote-request/:id/additional-files', {
        pathParams: { id: quoteRequest.id },
    });

    const { mutateAsync, isPending } = useHttpMutation('DELETE /quote-request/:id/additional-files', {
        snackbarMessage: t`File deleted successfully`,
    });

    return (
        <FormItem label={t`Additional files`}>
            <Flexbox flexDirection={'column'} gap={'12px'} sx={{ marginTop: 1 }}>
                {additionalFiles?.length === 0 && (
                    <Text variant="body-small" color={colorSystem.neutral[5]}>
                        {t`No files uploaded`}
                    </Text>
                )}

                {additionalFiles?.map(({ name, url }) => (
                    <Flexbox key={name} gap={'20px'} alignItems={'center'} justifyContent={'space-between'}>
                        <Link
                            startIcon={<DescriptionOutlined />}
                            attention="low"
                            onClick={() => window.open(url, '_blank')}
                            textStyle={{ overflowWrap: 'anywhere' }}
                        >
                            {name}
                        </Link>
                        <DestructiveTertiaryIconButton
                            disabled={isPending}
                            size={'small'}
                            onClick={() =>
                                mutateAsync({ requestBody: { file_name: name }, pathParams: { id: quoteRequest.id } })
                            }
                        >
                            <Delete fontSize="inherit" />
                        </DestructiveTertiaryIconButton>
                    </Flexbox>
                ))}
            </Flexbox>
        </FormItem>
    );
}

export function QuoteRequestSubmittedView({ quoteRequestId }: { quoteRequestId: string }) {
    const { data: quoteRequest } = useSuspendedQuoteRequest(quoteRequestId);
    const { data: quoteRequestLineItems } = useQuoteRequestLineItemsByQuoteRequest(quoteRequest.id);

    return (
        <Box display="grid" gridTemplateColumns="300px 5fr" gap={'16px'} height={'100%'}>
            <Flexbox flexDirection="column" gap="16px">
                <LayoutCard>
                    <Flexbox justifyContent="space-between" gap={8}>
                        <Text variant="h3" showEllipsis>
                            {formatSupplierDTO(quoteRequest.supplier)}
                        </Text>
                        <Tag attention="low" color={'neutral'} label={`#${quoteRequest.number}`} />
                    </Flexbox>
                    <QuoteRequestLineItemsStatusPieChart quoteRequestLineItems={quoteRequestLineItems} />
                    <Divider />
                    <FormItemSentOn quoteRequest={quoteRequest} />
                    <FormItemSentBy quoteRequest={quoteRequest} />
                    <FormItemSentTo quoteRequest={quoteRequest} />
                    <FormItemRecievedOn quoteRequest={quoteRequest} />
                    <FormItemDueDate quoteRequest={quoteRequest} />
                    <FormItemNotes quoteRequest={quoteRequest} />
                    <FormItemAdditionalFiles quoteRequest={quoteRequest} />
                </LayoutCard>
            </Flexbox>

            <LayoutCard style={{ height: '80vh' }}>
                <TableQuoteRequestLineItems quoteRequestLineItems={quoteRequestLineItems} />
            </LayoutCard>
        </Box>
    );
}
