import { Plural, t, Trans } from '@lingui/macro';
import { formatDecimal, formatMonetaryValue } from '@luminovo/commons';
import {
    PrimaryButton,
    PropertiesTable,
    Text,
    Tooltip,
    useConfirmationDialog,
    useNavigate,
} from '@luminovo/design-system';
import { QuoteRequestStatus } from '@luminovo/http-client';
import { CheckCircle, Info, Warning } from '@mui/icons-material';
import { Box, Skeleton } from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';
import { useSuspendedQuoteRequest } from '../hooks/negotiationHandlers';
import { getQuoteRequestLineItemPrice } from '../model/getQuoteRequestLineItemPrice';
import { monetaryValue } from '../model/monetaryValueMath';
import { scrollToEditableCell } from '../pages/supplier-portal/SupplierPortalQuoteRequestPage/model';
import {
    FormValuesQuoteRequest,
    QUOTE_REQUEST_FORM_ID,
} from '../pages/supplier-portal/SupplierPortalQuoteRequestPage/types';
import { useRemainingHoursUntilQuoteFrozen } from '../pages/supplier-portal/SupplierPortalQuoteRequestPage/useIsQuoteRequestFrozen';
import { validateLineItem } from '../pages/supplier-portal/SupplierPortalQuoteRequestPage/validateLineItem';

export function ButtonSubmitQuoteRequest({
    quoteRequestId,
    redirectOnSubmit,
}: {
    quoteRequestId: string;
    redirectOnSubmit?: string;
}): JSX.Element {
    const navigate = useNavigate();
    const { data: quoteRequest } = useSuspendedQuoteRequest(quoteRequestId);
    const { formState } = useFormContext<FormValuesQuoteRequest>();
    const { isSubmitting } = formState;
    const { modal, setOpen, open } = useConfirmSendQuote({
        quoteRequestId,
        onConfirmation: () => {
            submitForm();
            if (redirectOnSubmit) {
                navigate(redirectOnSubmit);
            }
        },
    });
    const hasErrors = Object.values(formState.errors).some((error) => Boolean(error.message));

    const tooltip = () => {
        if (open) {
            return '';
        }
        if (hasErrors) {
            return t`Please fix the errors before submitting`;
        }
        return '';
    };

    if (!quoteRequest) {
        return <></>;
    }

    const canSubmit = !hasErrors && formState.isValid;
    const label = quoteRequest.status === QuoteRequestStatus.Sent ? t`Submit quote` : t`Update quote`;

    return (
        <Tooltip title={tooltip()}>
            <div>
                {modal}
                <PrimaryButton
                    onClick={() => {
                        if (canSubmit) {
                            setOpen(true);
                        } else {
                            // As the form is not valid, this will trigger validation
                            // and the user will be able to see the errors.
                            // In the worst case, the form will be submitted without the user
                            // seeing the confirmation dialog.
                            submitForm();
                            scrollToEditableCell('error');
                        }
                    }}
                    isLoading={isSubmitting}
                    type={'button'}
                    size="medium"
                >
                    {label}
                </PrimaryButton>
            </div>
        </Tooltip>
    );
}

function submitForm() {
    Array.from(document.forms)
        .find((form) => form.id === QUOTE_REQUEST_FORM_ID)
        ?.dispatchEvent(new Event('submit', { bubbles: true, cancelable: true }));
}

function useConfirmSendQuote({
    quoteRequestId,
    onConfirmation,
}: {
    quoteRequestId: string;
    onConfirmation: () => void;
}) {
    const { control } = useFormContext<FormValuesQuoteRequest>();
    const rows = useWatch({ control, name: 'rows' });
    const selectedRows = rows.filter((row) => row.selected);
    const bidItems = selectedRows.filter((row) => row.bid).length;
    const nonBidItems = selectedRows.filter((row) => !row.bid).length;
    const warning = selectedRows.filter((row) => validateLineItem(row).status === 'warning').length;

    const quotedVolumes = monetaryValue.sumMixed(rows.map((row) => getQuoteRequestLineItemPrice(row)));

    const targetVolume = monetaryValue.sum(
        rows.map(({ targetPrice, requiredQuantity, bid, selected }) =>
            getQuoteRequestLineItemPrice({ unitPrice: targetPrice, pricePer: 1, requiredQuantity, bid, selected }),
        ),
    );
    const skipped = rows.length - selectedRows.length;
    const showTargetVolume = !monetaryValue.isZero(targetVolume);

    const properties = {
        [t`Bid items`]: formatDecimal(bidItems),
        [t`No-bid items`]: formatDecimal(nonBidItems),
        [t`Warnings`]: (
            <span>
                {formatDecimal(warning)} <StatusIcon status={warning > 0 ? 'warning' : 'success'} />
            </span>
        ),
        [t`Skipped line items`]: (
            <span>
                {t`${skipped} of ${rows.length} `} <StatusIcon status={skipped === 0 ? 'success' : 'info'} />
            </span>
        ),
        [t`Quoted volume`]: quotedVolumes.map((value) => formatMonetaryValue(value)).join(' + '),
        [t`Target volume`]: showTargetVolume ? formatMonetaryValue(targetVolume) : undefined,
    };

    return useConfirmationDialog({
        title: t`Submit quote`,
        description: (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                <Text>
                    <Trans>Are you sure you want to submit the quote?</Trans>
                    <LabelHoursUntilFrozen quoteRequestId={quoteRequestId} />
                </Text>

                <PropertiesTable variant="side-by-side" properties={properties} />
            </Box>
        ),
        onConfirmation,
        variant: 'primary',
    });
}

function StatusIcon({ status }: { status: 'warning' | 'success' | 'info' }) {
    if (status === 'warning') {
        return <Warning fontSize="inherit" style={{ marginBottom: -2 }} color="warning" />;
    }
    if (status === 'success') {
        return <CheckCircle fontSize="inherit" style={{ marginBottom: -2 }} color="success" />;
    }
    return <Info fontSize="inherit" style={{ marginBottom: -2 }} color="info" />;
}

function LabelHoursUntilFrozen({ quoteRequestId }: { quoteRequestId: string }) {
    const response = useRemainingHoursUntilQuoteFrozen(quoteRequestId);
    if (!response) {
        return <Skeleton width={100} />;
    }
    if (response.isFrozen) {
        return (
            <Text>
                <Trans>This quote request is frozen. You can no longer make changes to it.</Trans>
            </Text>
        );
    }
    if (response.remainingHours === undefined) {
        return null;
    }
    return (
        <Text>
            <Trans>
                You can still edit the quote for{' '}
                <b>
                    {' '}
                    <Plural value={response.remainingHours} one="# more hour" other="# more hours" />
                </b>
                .
            </Trans>
        </Text>
    );
}
