import { StatusChip } from '@luminovo/design-system';
import { PreferenceStatusEnum } from '@luminovo/http-client';

export type ManufacturerStatusChipProps = {
    manufacturerName: string;
    preferenceStatus: PreferenceStatusEnum | null;
    manufacturerAliases?: string[];
    partCategory?: string;
    disabled?: boolean;
};

export function ManufacturerStatusChip({ manufacturerName, preferenceStatus, disabled }: ManufacturerStatusChipProps) {
    if (!preferenceStatus) {
        return <StatusChip label={manufacturerName} color="green" disabled={disabled} />;
    }

    return (
        <StatusChip
            label={manufacturerName}
            color={preferenceStatus === PreferenceStatusEnum.Blocked ? 'red' : 'primary'}
            disabled={disabled}
        />
    );
}
