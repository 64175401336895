import { Trans } from '@lingui/macro';
import { Column, columnWidth, Row } from '@luminovo/design-system';
import { TextCell } from '../../ActivitiesTable/activitiesTableComponents';

interface RowWithInternalNumberNullable {
    internal_number: string | null;
}

export function getInternalNumberColumn<C>(): Column<RowWithInternalNumberNullable, C> {
    return {
        label: <Trans>NR.</Trans>,
        id: 'internalNumber',
        render: ({ data }: Row<RowWithInternalNumberNullable>) => <TextCell firstRow={data.internal_number ?? '-'} />,
        width: columnWidth.medium,
        searchable: { searchBy: (rowData): string => rowData.internal_number ?? '' },
    };
}
