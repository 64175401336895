import {
    CandidateCpnRuntype,
    ColumnName,
    parseRuntype,
    PartOptionOrigin,
    PartSuggestionFull,
    PartSuggestionOrigin,
} from '@luminovo/http-client';
import { assertUnreachable } from '../../utils/typingUtils';
import { getPartOptions } from '../part/getPartOptions';
import { BomItem } from './bomItemFrontendTypes';

/**
 * @returns unique candidate MPNs, IPNs and CPNs coming both from the part suggestions AND the part specification
 */
export function getAllCandidates(bomItem: BomItem, suggestions: PartSuggestionFull[]): string[] {
    const allCandidatesFromPartSuggestions: string[] = getCandidatesSuggestions(suggestions);

    const allCandidatesFromPartOptions: string[] = getPartOptions(bomItem.specification).reduce((arr: string[], p) => {
        return arr.concat(getAllCandidatesFromOrigin(p.origin));
    }, []);

    return Array.from(new Set(allCandidatesFromPartSuggestions.concat(allCandidatesFromPartOptions)));
}

/**
 * @returns unique candidate MPNs, IPNs and CPNs  from the part suggestions
 */
function getCandidatesSuggestions(suggestions: PartSuggestionFull[]): string[] {
    return suggestions.flatMap((sugg) => (sugg.origin ? getAllCandidatesFromOrigin(sugg.origin) : []));
}

function getAllCandidatesFromOrigin(origin: PartOptionOrigin | PartSuggestionOrigin | null): string[] {
    if (!origin || origin.column === ColumnName.TechSpecs) {
        return [];
    }
    const originCandidate = 'candidate' in origin ? origin.candidate : undefined;
    if (!originCandidate) {
        return [];
    }
    if (typeof originCandidate === 'string') {
        return [originCandidate];
    }
    if (parseRuntype(CandidateCpnRuntype, originCandidate)) {
        return [originCandidate.value, originCandidate.revision ?? ''];
    }
    assertUnreachable(originCandidate);
}
