import { useHttpFileUpload } from '@/resources/http/useHttpFileUpload';
import { analytics } from '@/utils/analytics';
import { UrlParams } from '@/utils/routes';
import { t } from '@lingui/macro';
import { id } from '@luminovo/commons';
import { NonIdealState } from '@luminovo/design-system';
import { PdfQuoteRequestImporter } from '@luminovo/offers';
import React from 'react';

/**
 * @see
 */
export function PagePdfQuoteRequestImporter({ queryParams }: UrlParams<'/offers/pdf-offer-importer'>) {
    const { returnTo } = queryParams;
    const quoteRequestId = queryParams.quoteRequestId ?? undefined;
    const rfqId = 'quote-request';
    React.useEffect(() => {
        analytics.track(id('pdf-offer-importer/start'), {
            rfqId,
        });
    }, [rfqId]);

    const { mutateAsync: mutateAsyncUpload } = useHttpFileUpload(
        'GET /quote-request/:id/additional-files/upload-link',
        (response) => response,
        {
            snackbarMessage: t`PDF attached to quote request`,
        },
    );

    if (!quoteRequestId) {
        // TODO: for now the quote request ID is mandatory. In the future we will allow it to be optional,
        // in which case we will prompt the user to enter the quote request ID.
        return (
            <NonIdealState
                title={t`No quote request ID provided`}
                description={t`Please check the quote request ID and try again.`}
            />
        );
    }

    return (
        <PdfQuoteRequestImporter
            onSuccess={({ quoteRequestId, file }) => {
                analytics.track(id('pdf-offer-importer/success'), {
                    rfqId,
                });
                mutateAsyncUpload({
                    pathParams: {
                        id: quoteRequestId,
                    },
                    files: file,
                });
            }}
            onError={() => {
                analytics.track(id('pdf-offer-importer/error'), {
                    rfqId,
                });
            }}
            returnTo={returnTo}
            quoteRequestId={quoteRequestId}
        />
    );
}
