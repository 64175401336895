import { useDialogContext } from '@/components/contexts/ModalContext';
import { isTotalCostOfOwnershipEnabled } from '@/featureFlags';
import { useTopLevelAssemblies } from '@/resources/assembly/assemblyHandler';
import { assemblyTypeTranslations } from '@/resources/rfq/i18n';
import {
    DemandWithSourcingScenarios,
    useDemandsWithSourcingScenarios,
} from '@/resources/sourcingScenario/sourcingScenarioHandlers';
import { t, Trans } from '@lingui/macro';
import {
    formatRelativeTime,
    formatToLongDate,
    getIntercomArticleURL,
    isPresent,
    transEnum,
    uniqBy,
} from '@luminovo/commons';
import {
    Checkbox,
    colorSystem,
    createColumnHelper,
    DestructiveSecondaryIconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FieldCheckboxControlled,
    FieldDateControlled,
    FieldMultiSelectControlled,
    FieldNumericControlled,
    FieldRadioControlled,
    FieldSelectControlled,
    FieldTextControlled,
    Flexbox,
    FormItem,
    FormSection,
    Link,
    Message,
    PrimaryButton,
    SecondaryButton,
    SecondaryIconButton,
    Tag,
    TagGroup,
    TanStackTable,
    Text,
    Tooltip,
    useNavigate,
    useTanStackTable,
} from '@luminovo/design-system';
import { Packaging, PriceType, SourcingPreferenceOptions } from '@luminovo/http-client';
import { formatQuantity, packagingTranslations, sourcingPreferenceTranslations } from '@luminovo/sourcing-core';
import { Add, Close, EditRounded, Launch, LockOutlined } from '@mui/icons-material';
import { Box, Divider, InputAdornment } from '@mui/material';
import * as React from 'react';
import { Controller, useFieldArray, useFormContext, UseFormReturn, useWatch } from 'react-hook-form';
import { FormContainer, ValidationErrors } from '../../../components/formLayouts/FormContainer';
import { SubmitButton } from '../../../components/formLayouts/SubmitButton';
import { route } from '../../../utils/routes';
import { SourcingScenarioFormValues } from './converters';
import { createDemandFormStateDefaultValues } from './DemandDialog/DemandFormItems';
import { useEditDemandDialog } from './DemandDialog/useEditDemandDialog';
import { InventorySitePreferenceSection } from './InventorySitePreferenceSection';
import { SupplierPreferenceSection } from './SupplierPreferenceSection';

export function SourcingScenarioForm({
    onSubmit,
    defaultValues,
}: {
    onSubmit: (form: SourcingScenarioFormValues) => Promise<void>;
    defaultValues: SourcingScenarioFormValues;
}) {
    const validationErrors: ValidationErrors<SourcingScenarioFormValues> = {
        'sourcing_scenario.not_found': {
            fieldPath: 'root.serverError',
        },
        'sourcing_scenario.name_already_exists': {
            fieldPath: 'name',
        },
    };

    return (
        <FormContainer defaultValues={defaultValues} onSubmit={onSubmit} validationErrors={validationErrors}>
            <Flexbox gap={32} flexDirection="column">
                <FormSection title={t`General information`}>
                    <FormItemName />
                    <FormItemDemands />
                </FormSection>
                <Divider />
                <FormSection title={t`Solution preference`}>
                    <FormItemLeadTimePreference />
                    <FormItemExcludeOffers />
                    <FormItemPackaging />
                    <FormItemApiAndPricePreferences />
                    <FormItemPreferences />
                    {isTotalCostOfOwnershipEnabled() && <FormItemIsTcoEnabled />}
                </FormSection>
                <Divider />
                <FormSection title={t`Supplier preference`}>
                    <SupplierPreferenceSection disabled={false} />
                </FormSection>
                <FormSection title={t`Inventory preference`}>
                    <FormItemPreferredInventorySites />
                    <InventoryPreferenceSections />
                </FormSection>
                <Divider />
                <Flexbox flexDirection="row" gap={8} justifyContent="flex-end">
                    <CancelButton />
                    <SubmitButton />
                </Flexbox>
            </Flexbox>
        </FormContainer>
    );
}

export function SourcingScenarioQuickFormInner() {
    return (
        <Flexbox gap={'12px'} flexDirection="column">
            <FormItemLeadTimePreference />
            <FormItemExcludeOffers />
            <FormItemPackaging />
            <FormItemApiAndPricePreferences />
            <FormItemPreferences />
            {isTotalCostOfOwnershipEnabled() && <FormItemIsTcoEnabled />}
            <InventoryPreferenceSections />
        </Flexbox>
    );
}

function CancelButton() {
    const navigate = useNavigate();
    const handleClose = React.useCallback(() => {
        navigate(-1);
    }, [navigate]);
    return (
        <SecondaryButton onClick={handleClose}>
            <Trans>Cancel</Trans>
        </SecondaryButton>
    );
}

function FormItemName() {
    const { control } = useFormContext<SourcingScenarioFormValues>();
    return (
        <FormItem label={t`Sourcing scenario name`} required>
            <FieldTextControlled
                control={control}
                name="name"
                required
                FieldProps={{ style: { width: '40%' }, placeholder: t`Sourcing scenario name` }}
            />
        </FormItem>
    );
}

function FormItemDemandsError() {
    const context = useFormContext<SourcingScenarioFormValues>();
    return (
        <Controller
            control={context.control}
            name={'demands'}
            rules={{
                validate: (value) => {
                    if (value.length === 0) {
                        return t`At least one demand must be selected`;
                    }
                },
            }}
            render={({ fieldState }) => {
                if (isPresent(fieldState.error?.root)) {
                    return (
                        <Text variant="body" color={colorSystem.red[7]}>
                            <Trans>Please select at least one demand to create a sourcing scenario.</Trans>
                        </Text>
                    );
                }
                return <></>;
            }}
        />
    );
}

function FormItemDemands() {
    const context = useFormContext<SourcingScenarioFormValues>();
    const { control, setValue, watch, trigger } = context;
    const { openDialog: openEditDemandDialog } = useEditDemandDialog();
    const { openDialog: openSelectDemandsFromOtherScenariosDialog } = useDialogSelectDemandsFromOtherScenarios();
    const rfqId = useWatch({ control, name: 'rfq' });
    const { data: assemblies, isLoading } = useTopLevelAssemblies(rfqId);

    /*
     * We need both useFieldArray and watch to manage the demands state due to two react-hook-form limitations:
     * 1. useFieldArray fields don't automatically re-render when the form updates
     * 2. Using watch alone for rendering causes input fields to lose focus
     * This dual approach ensures both proper updates and maintained input focus
     */
    const watchDemands = watch('demands');
    const { fields: demandsFields, append, remove } = useFieldArray({ control, name: 'demands' });

    return (
        <FormItem
            label={t`Demands`}
            description={t`Add demands by creating new ones or selecting from existing sourcing scenarios. These demands will used to calculate the quantities for quote requests.`}
            variant="description-inlined"
        >
            <Box
                display={'grid'}
                alignItems="center"
                gridTemplateColumns={'2fr 4fr 2fr 2fr'}
                rowGap={'16px'}
                columnGap={'16px'}
            >
                <Text variant="h4" color={colorSystem.neutral[7]}>
                    <Trans>Quantity</Trans>*
                </Text>
                <Text variant="h4" color={colorSystem.neutral[7]}>
                    <Trans>Assembly</Trans>*
                </Text>
                <span />
                <span />

                {demandsFields.map((demand, index) => {
                    const watchDemand = watchDemands[index];
                    if (demand.kind === 'existing' && watchDemand.kind === 'existing') {
                        if (!isPresent(watchDemand.data.assembly)) {
                            return null;
                        }

                        return (
                            <React.Fragment key={`${index}-${JSON.stringify(watchDemand.data)}`}>
                                <Text variant="body">
                                    {formatQuantity(watchDemand.data.quantity, { showPiecesUnit: false })}
                                </Text>
                                <Text variant="body" showEllipsis>
                                    {watchDemand.data.assembly.name}
                                </Text>
                                <Tag
                                    attention="low"
                                    color={'neutral'}
                                    label={transEnum(
                                        watchDemand.data.assembly.assembly_type.type,
                                        assemblyTypeTranslations,
                                    )}
                                />
                                <Flexbox gap={8} alignItems="center">
                                    <Tooltip
                                        title={t`This demand cannot be modified as it is already used in another sourcing scenario`}
                                    >
                                        <LockOutlined fontSize="inherit" style={{ margin: '4px' }} />
                                    </Tooltip>
                                    <Tooltip title={t`Remove demand`}>
                                        <DestructiveSecondaryIconButton size={'small'} onClick={() => remove(index)}>
                                            <Close fontSize="inherit" />
                                        </DestructiveSecondaryIconButton>
                                    </Tooltip>
                                </Flexbox>
                            </React.Fragment>
                        );
                    }

                    if (demand.kind === 'new' && watchDemand.kind === 'new') {
                        return (
                            <React.Fragment key={`${index}-${watchDemand.data.assembly?.id}`}>
                                <FieldNumericControlled
                                    control={control}
                                    name={`demands.${index}.data.quantity.quantity`}
                                    required
                                    exclusiveMin={0}
                                    max={10_000_000}
                                    FieldProps={{
                                        size: 'small',
                                        style: { width: '100%' },
                                    }}
                                />
                                <FieldSelectControlled
                                    control={control}
                                    required
                                    name={`demands.${index}.data.assembly`}
                                    FieldProps={{
                                        size: 'small',
                                        placeholder: t`Select assembly`,
                                        options: assemblies ?? [],
                                        disabled: isLoading,
                                        disableClearable: true,
                                        getOptionKey: (opt) => opt.id,
                                        getOptionLabel: (opt) => opt.designator,
                                    }}
                                />
                                {isPresent(watchDemand.data.assembly) && (
                                    <Tag
                                        attention="low"
                                        color={'neutral'}
                                        label={transEnum(watchDemand.data.assembly.type.type, assemblyTypeTranslations)}
                                    />
                                )}
                                <Flexbox gap={8}>
                                    <Tooltip title={t`Edit demand`}>
                                        <SecondaryIconButton
                                            size={'small'}
                                            onClick={() => {
                                                openEditDemandDialog({
                                                    defaultValues: watchDemand.data,
                                                    onSubmit: (values) => {
                                                        setValue(`demands.${index}`, { kind: 'new', data: values });
                                                        trigger();
                                                    },
                                                });
                                            }}
                                        >
                                            <EditRounded fontSize="inherit" />
                                        </SecondaryIconButton>
                                    </Tooltip>
                                    <Tooltip title={t`Remove demand`}>
                                        <DestructiveSecondaryIconButton size={'small'} onClick={() => remove(index)}>
                                            <Close fontSize="inherit" />
                                        </DestructiveSecondaryIconButton>
                                    </Tooltip>
                                </Flexbox>
                            </React.Fragment>
                        );
                    }

                    return null;
                })}
            </Box>
            <FormItemDemandsError />
            <Flexbox gap={8} flexDirection="row" paddingTop={'12px'}>
                <PrimaryButton
                    size="medium"
                    startIcon={<Add />}
                    style={{ whiteSpace: 'nowrap' }}
                    onClick={() => {
                        if (isPresent(assemblies) && assemblies.length > 0) {
                            append({
                                kind: 'new',
                                data: createDemandFormStateDefaultValues(rfqId, { assembly: assemblies[0] }),
                            });
                        }
                    }}
                >
                    <Trans>Add demand</Trans>
                </PrimaryButton>
                <SecondaryButton
                    size="medium"
                    onClick={() => openSelectDemandsFromOtherScenariosDialog(context)}
                    style={{ whiteSpace: 'nowrap' }}
                >
                    <Trans>Add from existing scenarios</Trans>
                </SecondaryButton>
            </Flexbox>
        </FormItem>
    );
}

export function useDialogSelectDemandsFromOtherScenarios() {
    const { setDialog, closeDialog } = useDialogContext();

    return {
        openDialog: (context: UseFormReturn<SourcingScenarioFormValues>) =>
            setDialog(
                <Dialog maxWidth="lg" fullWidth open={true} onClose={closeDialog}>
                    <DialogTitle title={'Select demands'} onClose={closeDialog} />
                    <DialogContent>
                        <InnerSelectDemandsFromOtherScenarios context={context} />
                    </DialogContent>
                    <DialogActions>
                        <SecondaryButton onClick={closeDialog}>
                            <Trans>Close</Trans>
                        </SecondaryButton>
                    </DialogActions>
                </Dialog>,
            ),
    };
}

function InnerSelectDemandsFromOtherScenarios({ context }: { context: UseFormReturn<SourcingScenarioFormValues> }) {
    const { control, setValue, trigger } = context;
    const rfqId = useWatch({ control, name: 'rfq' });
    const demands = useWatch({ control, name: 'demands' });
    const { data } = useDemandsWithSourcingScenarios(rfqId);

    const initialRowSelection = React.useMemo(() => {
        return demands
            .filter((demand) => demand.kind === 'existing')
            .reduce(
                (acc, demand) => {
                    acc[String(demand.data.id)] = true;
                    return acc;
                },
                {} as Record<string, boolean>,
            );
    }, [demands]);

    const handleRowSelectionChange = React.useCallback(
        (value: Record<string, boolean>) => {
            const selectedDemands: SourcingScenarioFormValues['demands'] =
                data
                    ?.filter((row) => value[String(row.id)])
                    .map((demand) => ({ kind: 'existing' as const, data: demand })) ?? [];
            const newDemands = uniqBy([...selectedDemands, ...demands], (demand) => JSON.stringify(demand)).filter(
                (demand) => (demand.kind === 'existing' && selectedDemands.includes(demand)) || demand.kind === 'new',
            );
            setValue('demands', newDemands, { shouldValidate: true });
            trigger('demands');
        },
        [data, demands, setValue, trigger],
    );

    const { table } = useTanStackTable({
        data,
        columns,
        enableColumnHiding: true,
        enableColumnOrdering: true,
        enableSelection: {
            enabled: true,
            getRowId: (row) => row.id,
            onRowSelectionChange: handleRowSelectionChange,
        },
        initialState: {
            rowSelection: initialRowSelection,
        },
    });

    return (
        <Box height={400}>
            <TanStackTable table={table} size={'small'} />
        </Box>
    );
}

const columnHelper = createColumnHelper<DemandWithSourcingScenarios>();
const columns = [
    columnHelper.text((row) => row.assembly?.name ?? '-', {
        id: 'assembly',
        size: 180,
        label: () => t`Assembly`,
        cell: ({ row }) => {
            const { assembly } = row.original;

            if (!assembly) {
                return '-';
            }

            return (
                <Link
                    to={route('/assemblies/:assemblyId/dashboard', { assemblyId: assembly.id })}
                    attention="high"
                    startIcon={<Launch fontSize="small" />}
                >
                    {assembly?.name}
                </Link>
            );
        },
    }),

    columnHelper.number((row) => row.quantity.quantity, {
        id: 'quantity',
        size: 100,
        label: () => t`Quantity`,
        cell: ({ row }) => formatQuantity(row.original.quantity, { showPiecesUnit: false }),
    }),

    columnHelper.array('sourcingScenarios', {
        size: 100,
        label: () => t`Sourcing scenarios`,
        getOptionLabel: (value) => value.name,
        cell: (item) => (
            <TagGroup color="neutral" options={item.getValue()} getOptionLabel={(opt) => opt.name} limit={1} />
        ),
    }),

    columnHelper.date('delivery_start_date', {
        size: 100,
        label: () => t`Delivery start date`,
        cell: ({ row }) => formatToLongDate(row.original.delivery_start_date, { ifAbsent: '-' }),
    }),

    columnHelper.date('delivery_end_date', {
        size: 100,
        label: () => t`Delivery end date`,
        cell: ({ row }) => formatToLongDate(row.original.delivery_end_date, { ifAbsent: '-' }),
    }),

    columnHelper.enum('context', {
        size: 160,
        getOptionLabel: (value) => value ?? '-',
        label: () => t`Context`,
        renderType: 'text',
        initialVisibility: false,
        cell: (item) => item.getValue() ?? '-',
    }),
];

function FormItemLeadTimePreference() {
    const { control, setValue, watch } = useFormContext<SourcingScenarioFormValues>();

    const leadTimeDate = watch('solution_preference.lead_time_preference.target');
    const leadTimePrefType = watch('solution_preference.lead_time_preference.type');

    React.useEffect(() => {
        if (leadTimePrefType !== SourcingPreferenceOptions.BestPriceBy) {
            setValue('solution_preference.lead_time_preference.target', undefined, { shouldTouch: true });
        }
    }, [setValue, leadTimePrefType]);

    return (
        <FormItem label={t`Lead time preference`}>
            <FieldSelectControlled
                control={control}
                name="solution_preference.lead_time_preference.type"
                FieldProps={{
                    style: { width: '40%' },
                    options: Object.values(SourcingPreferenceOptions),
                    getOptionLabel: (option) => transEnum(option, sourcingPreferenceTranslations),
                }}
            />
            {leadTimePrefType === SourcingPreferenceOptions.BestPriceBy && (
                <FieldDateControlled
                    control={control}
                    name="solution_preference.lead_time_preference.target"
                    required={true}
                    inFuture={true}
                    FieldProps={{
                        style: { width: '40%' },
                        InputProps: {
                            endAdornment: isPresent(leadTimeDate) && (
                                <InputAdornment position="end">{formatRelativeTime(leadTimeDate)}</InputAdornment>
                            ),
                        },
                    }}
                />
            )}
            <Flexbox alignItems={'center'} gap={8}>
                <FieldCheckboxControlled
                    control={control}
                    name="solution_preference.use_unit_price"
                    FieldProps={{ size: 'small' }}
                />
                <Text variant="body">
                    <Trans>Optimize for unit price instead of total price of the solution.</Trans>
                </Text>
            </Flexbox>
        </FormItem>
    );
}

function FormItemExcludeOffers() {
    return (
        <FormItem
            label={t`Exclude offers with`}
            variant="description-inlined"
            description={
                <Trans>
                    Please see our{' '}
                    <Link
                        variant="body-small"
                        href={getIntercomArticleURL(84955)}
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        help page article
                    </Link>{' '}
                    for the price types definition.
                </Trans>
            }
        >
            <Flexbox flexDirection={'column'} gap={8}>
                <Flexbox alignItems={'center'} gap={8}>
                    <PriceTypeController priceType={PriceType.ListPrice} />
                    <Text variant="body">
                        <Trans>List prices</Trans>
                    </Text>
                </Flexbox>
                <Flexbox alignItems={'center'} gap={8}>
                    <PriceTypeController priceType={PriceType.ContractPrice} />
                    <Text variant="body">
                        <Trans>Contract prices</Trans>
                    </Text>
                </Flexbox>
                <Flexbox alignItems={'center'} gap={8}>
                    <PriceTypeController priceType={PriceType.QuotePrice} />
                    <Text variant="body">
                        <Trans>Quote prices</Trans>
                    </Text>
                </Flexbox>
                <Flexbox alignItems={'center'} gap={8}>
                    <PriceTypeController priceType={PriceType.CustomerNegotiatedPrice} />
                    <Text variant="body">
                        <Trans>Customer negotiated prices</Trans>
                    </Text>
                </Flexbox>
                <Flexbox alignItems={'center'} gap={8}>
                    <PriceTypeController priceType={PriceType.PurchasePrice} />
                    <Text variant="body">
                        <Trans>Purchase prices</Trans>
                    </Text>
                </Flexbox>
                <Flexbox alignItems={'center'} gap={8}>
                    <PriceTypeController priceType={PriceType.StandardPrice} />
                    <Text variant="body">
                        <Trans>Standard prices</Trans>
                    </Text>
                </Flexbox>
                <Flexbox alignItems={'center'} gap={8}>
                    <PriceTypeController priceType={PriceType.TargetPrice} />
                    <Text variant="body">
                        <Trans>Target prices</Trans>
                    </Text>
                </Flexbox>
            </Flexbox>
        </FormItem>
    );
}

function PriceTypeController({ priceType }: { priceType: PriceType }) {
    const { control } = useFormContext<SourcingScenarioFormValues>();
    return (
        <Controller
            control={control}
            name="solution_preference.excluded_price_types"
            render={({ field }) => {
                return (
                    <Checkbox
                        size="small"
                        onBlur={() => field.onBlur()}
                        onChange={(_, checked) => {
                            const newValues = !checked
                                ? field.value.filter((p) => p !== priceType)
                                : [...field.value, priceType];
                            field.onChange(newValues);
                        }}
                        checked={field.value.includes(priceType)}
                    />
                );
            }}
        />
    );
}

function FormItemPackaging() {
    const { control } = useFormContext<SourcingScenarioFormValues>();

    return (
        <FormItem
            label={t`Packaging preference`}
            variant="description-inlined"
            description={t`Select which packaging types you would like to prefer. If no specific packaging should be preferred, leave the selection empty.`}
        >
            <FieldMultiSelectControlled
                control={control}
                name="solution_preference.packaging_preference"
                FieldProps={{
                    getOptionKey: (option) => option,
                    options: Object.values(Packaging),
                    getOptionLabel: (option) => transEnum(option, packagingTranslations),
                }}
            />
        </FormItem>
    );
}

function FormItemApiAndPricePreferences() {
    const { control } = useFormContext<SourcingScenarioFormValues>();
    return (
        <FormItem label={t`API and price preferences`}>
            <Flexbox flexDirection="column" gap={8}>
                <Flexbox alignItems="start" gap={8}>
                    <FieldCheckboxControlled
                        control={control}
                        name="solution_preference.prefer_direct_api_integrations"
                        FieldProps={{ size: 'small' }}
                    />
                    <Text variant="body">
                        <Trans>Prefer direct supplier API integrations over third-party sources (e.g. Octopart).</Trans>
                    </Text>
                </Flexbox>
                <Flexbox alignItems="start" gap={8}>
                    <FieldCheckboxControlled
                        control={control}
                        name="solution_preference.prefer_customer_negotiated_prices"
                        FieldProps={{ size: 'small' }}
                    />
                    <Text variant="body">
                        <Trans>Prioritize offers with customer negotiated prices</Trans>
                    </Text>
                </Flexbox>
            </Flexbox>
        </FormItem>
    );
}

function FormItemPreferences() {
    const { control } = useFormContext<SourcingScenarioFormValues>();
    return (
        <FormItem label={t`Additional preferences`}>
            <Flexbox flexDirection="column" gap={8}>
                <Flexbox alignItems="center" gap={8}>
                    <FieldCheckboxControlled
                        name="solution_preference.exclude_mismatched_price_points"
                        control={control}
                        FieldProps={{ size: 'small' }}
                    />
                    <Text>
                        <Trans>
                            Exclude custom part offers where total offered quantity does not match the scenarios
                            quantity
                        </Trans>
                    </Text>
                </Flexbox>
                <Flexbox alignItems="center" gap={8}>
                    <FieldCheckboxControlled
                        name="solution_preference.prioritize_preferred_manufacturers"
                        control={control}
                        FieldProps={{ size: 'small' }}
                    />
                    <Text>
                        <Trans>Prioritize offers from preferred manufacturers</Trans>
                    </Text>
                </Flexbox>
            </Flexbox>
        </FormItem>
    );
}

function FormItemIsTcoEnabled() {
    const { control } = useFormContext<SourcingScenarioFormValues>();

    return (
        <FormItem label={t`Enable total cost of ownership`}>
            <Flexbox alignItems={'start'} gap={8}>
                <FieldCheckboxControlled
                    control={control}
                    name="solution_preference.is_tco_enabled"
                    FieldProps={{ size: 'small' }}
                />
                <Text variant="body">
                    <Trans>Enable total cost of ownership rules.</Trans>
                </Text>
            </Flexbox>
        </FormItem>
    );
}

function InventoryPreferenceSections() {
    const { control } = useFormContext<SourcingScenarioFormValues>();

    return (
        <Flexbox flexDirection="column" gap={8}>
            <FormItem label={t`Which offers should be prioritized?`} width={'800px'}>
                <Message
                    size="small"
                    attention="low"
                    variant="blue"
                    message={t`Given similar offers from your own inventory and the external market, you can choose here which one has more weight. If Neutral is selected, both inventory and market offers will be treated equally. If Market offers is selected, inventory will only be chosen if all other offers are red.`}
                />
                <Flexbox alignItems="center" gap={8}>
                    <FieldRadioControlled
                        name="solution_preference.inventory_preference"
                        control={control}
                        FieldProps={{ fieldValue: 'PreferInventory', size: 'small' }}
                    />
                    <Text>
                        <Trans>Inventory</Trans>
                    </Text>
                </Flexbox>
                <Flexbox alignItems="center" gap={8}>
                    <FieldRadioControlled
                        name="solution_preference.inventory_preference"
                        control={control}
                        FieldProps={{ fieldValue: 'Neutral', size: 'small' }}
                    />
                    <Text>
                        <Trans>No prioritization</Trans>
                    </Text>
                </Flexbox>
                <Flexbox alignItems="center" gap={8}>
                    <FieldRadioControlled
                        name="solution_preference.inventory_preference"
                        control={control}
                        FieldProps={{ fieldValue: 'PreferMarketOffers', size: 'small' }}
                    />
                    <Text>
                        <Trans>Market offers</Trans>
                    </Text>
                </Flexbox>
            </FormItem>
        </Flexbox>
    );
}

function FormItemPreferredInventorySites() {
    return <InventorySitePreferenceSection disabled={false} />;
}
