import { DigiKeySettingDTO, http } from '@luminovo/http-client';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useToken } from '../hooks';
import { useDebugErrorHandler } from '../http/debugErrorHandler';
import { invalidateAllQueriesForEndpoint, useHttpQuery } from '../http/useHttpQuery';

export function useDigiKeyAuthenticated() {
    return useHttpQuery('GET /3rdparty/digikey/is-authenticated', {}, {});
}

export function useDigiKeyAuthUrl(enabled: boolean) {
    return useHttpQuery('GET /3rdparty/digikey/auth-redirect-uri', {}, { enabled });
}

export function useDigikeyAcquireAccessToken(code: string, enabled: boolean = true) {
    return useHttpQuery(
        'GET /3rdparty/digikey/acquire-access-token-from-code',

        { queryParams: { auth_code: code } },
        { enabled },
    );
}

export function useDigiKeySupportedSettings() {
    return useHttpQuery('GET /3rdparty/digikey/store-locations', {}, { staleTime: Infinity });
}

export function useDigiKeySetting() {
    return useHttpQuery('GET /3rdparty/digikey/settings', {}, {});
}

export const useMutationDigiKeySetting = () => {
    const { token } = useToken();
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async (requestBody: DigiKeySettingDTO) => {
            return http('PATCH /3rdparty/digikey/settings', { requestBody }, token);
        },
        onSuccess: async () => {
            await Promise.allSettled([
                invalidateAllQueriesForEndpoint('GET /3rdparty/digikey/is-authenticated', queryClient),
                invalidateAllQueriesForEndpoint('GET /3rdparty/digikey/auth-redirect-uri', queryClient),
                invalidateAllQueriesForEndpoint('GET /3rdparty/digikey/acquire-access-token-from-code', queryClient),
                invalidateAllQueriesForEndpoint('GET /3rdparty/digikey/settings', queryClient),
            ]);
        },
        onError: () => {
            /**
             * Errors can be safely ignored, since the FormContainer takes care of all errors
             */
        },
    });
};

export const useDigikeyDeleteTokenMutation = () => {
    const { token } = useToken();
    const queryClient = useQueryClient();
    const onError = useDebugErrorHandler();
    return useMutation({
        mutationFn: async () => {
            return http('DELETE /3rdparty/digikey/delete-token', {}, token);
        },
        onSuccess: async () => {
            await Promise.allSettled([
                invalidateAllQueriesForEndpoint('GET /3rdparty/digikey/is-authenticated', queryClient),
                invalidateAllQueriesForEndpoint('GET /3rdparty/digikey/auth-redirect-uri', queryClient),
                invalidateAllQueriesForEndpoint('GET /3rdparty/digikey/acquire-access-token-from-code', queryClient),
                invalidateAllQueriesForEndpoint('GET /3rdparty/digikey/settings', queryClient),
            ]);
        },
        onError,
    });
};
