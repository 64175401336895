import * as z from 'zod';
import {
    DurationRuntype,
    ResourceDetailsDTORuntype,
    TimeCalculationRuntype,
    TimeComponentsRuntype,
} from '../activity/activityBackendTypes';
import { ExchangedMonetaryValueBackendRuntype } from '../backendTypes';
import { DriverIdRuntype, DriverRuntype } from '../driver';
import { CategoryRuntype, ManufacturingEntityStatusRuntype, ProcessRuntype } from '../sharedManufacturingBackendTypes';
import { CalculationResult } from './calculationErrorBackendTypes';
import { DriverStatusDetailsRuntype } from './configurationDriverStatusRuntypes';

export const ActivityConfigurationRuntype = z.object({
    id: z.string(),
    activity_id: z.string(),
});

export const ActivityConfigurationsPostRuntype = z.object({
    manufacturing_scenario_id: z.string(),
    manufacturing_scenario_template_instance_id: z.string().nullable(),
    activities: z.array(z.string()),
});

export type ActivityConfigurationsPostDTO = z.infer<typeof ActivityConfigurationsPostRuntype>;

export const ConfigurationsPatchRuntype = z.object({
    driver_id: DriverIdRuntype,
    manual_driver_count: z.number().nullable().optional(),
});
export type ConfigurationsPatchDTO = z.infer<typeof ConfigurationsPatchRuntype>;

export const ConfigurationsNotesPatchRuntype = z.object({
    notes: z.string().nullable().optional(),
});

const DriverCountTuple = z.tuple([DriverIdRuntype, z.null().or(z.string())]);

export const DriverCountsRuntype = z.object({
    counts: z.array(DriverCountTuple),
});

const CostUnitRuntype = z.object({
    batch_cost: CalculationResult(ExchangedMonetaryValueBackendRuntype.nullable()),
    effective_batch_cost: ExchangedMonetaryValueBackendRuntype.nullable(),
    effective_unit_cost: ExchangedMonetaryValueBackendRuntype.nullable(),
    unit_cost: CalculationResult(ExchangedMonetaryValueBackendRuntype.nullable()),
});

const CostBatchRuntype = z.object({
    batch_cost: CalculationResult(ExchangedMonetaryValueBackendRuntype.nullable()),
    effective_batch_cost: ExchangedMonetaryValueBackendRuntype.nullable(),
});

const CostProjectRuntype = z.object({
    project_cost: CalculationResult(ExchangedMonetaryValueBackendRuntype.nullable()),
});

const UnitTimeRuntype = z.object({
    batch_time: CalculationResult(DurationRuntype.nullable()),
    unit_time: CalculationResult(DurationRuntype.nullable()).optional(),
});

const BatchTimeRuntype = z.object({
    batch_time: CalculationResult(DurationRuntype.nullable()),
});

const ProjectTimeRuntype = z.object({
    project_time: CalculationResult(DurationRuntype.nullable()),
});

export type CostComponentUnit = z.infer<typeof CostComponentUnitRuntype>;
const CostComponentUnitRuntype = z.object({
    level: z.literal('Unit'),
    details: z.object({
        automatic_driver_count: DriverCountsRuntype,
        batch_time_components: TimeComponentsRuntype.nullable(),
        costs_per_batch_size: z.record(z.string(), CostUnitRuntype),
        driver_count: DriverCountsRuntype,
        times_per_batch_size: z.record(z.string(), UnitTimeRuntype),
        unit_time_components: TimeComponentsRuntype,
        driver_statuses: z.array(DriverStatusDetailsRuntype),
    }),
});

export type CostComponentBatch = z.infer<typeof CostComponentBatchRuntype>;
const CostComponentBatchRuntype = z.object({
    level: z.literal('Batch'),
    details: z.object({
        automatic_driver_count: DriverCountsRuntype,
        costs_per_batch_size: z.record(z.string(), CostBatchRuntype),
        driver_count: DriverCountsRuntype,
        time_components: TimeComponentsRuntype,
        times_per_batch_size: z.record(z.string(), BatchTimeRuntype),
        driver_statuses: z.array(DriverStatusDetailsRuntype),
    }),
});

const CostComponentProjectRuntype = z.object({
    level: z.literal('Project'),
    details: z.object({
        automatic_driver_count: DriverCountsRuntype,
        costs_per_batch_size: z.record(z.string(), CostProjectRuntype),
        driver_count: DriverCountsRuntype,
        time_components: TimeComponentsRuntype,
        times_per_batch_size: z.record(z.string(), ProjectTimeRuntype),
        driver_statuses: z.array(DriverStatusDetailsRuntype),
    }),
});

const CostComponentsRuntype = CostComponentUnitRuntype.or(CostComponentBatchRuntype).or(CostComponentProjectRuntype);

export type CostComponentsActivities = z.infer<typeof CostComponentsRuntype>;

export const ActivityConfigurationSummaryRuntype = z.object({
    activity_configuration_details: z.object({
        activity_configuration_id: z.string(),
        activity_configuration_notes: z.string().nullable(),
        activity_id: z.string(),
        activity_name: z.string(),
        activity_process: ProcessRuntype,
        activity_internal_number: z.string().nullable(),
        activity_category: CategoryRuntype,
        activity_is_per_panel: z.boolean(),
        cost_components: CostComponentsRuntype,
        activity_description: z.string().nullable(),
        activity_status: ManufacturingEntityStatusRuntype,
        activity_time_calculation: TimeCalculationRuntype,
        activity_is_manual_overwrite: z.boolean(),
        template_instance_id: z.string().nullable(),
        rank: z.number(),
    }),
    resources_details: z.array(ResourceDetailsDTORuntype),
    unit_drivers: z.array(DriverRuntype),
    batch_drivers: z.array(DriverRuntype),
    project_drivers: z.array(DriverRuntype),
});

export type ActivityConfigurationSummaryDTO = z.infer<typeof ActivityConfigurationSummaryRuntype>;
