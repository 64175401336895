import { OptionsObject } from 'notistack';
import { MouseEventHandler } from 'react';
import { SecondaryButton } from '../buttons';
import { SnackbarText } from './SnackbarText';

interface ActionButtonOptions {
    onClick: MouseEventHandler<HTMLButtonElement>;
    buttonText: string;
}

const defaultSnackbarOptions: Partial<OptionsObject> = {
    variant: 'info',
    preventDuplicate: true,
    anchorOrigin: { horizontal: 'left', vertical: 'bottom' },
};

const createSnackbarOptions = (
    actionButtonOptions: ActionButtonOptions | undefined,
    snackbarOptions?: Partial<OptionsObject>,
): OptionsObject => ({
    ...defaultSnackbarOptions,
    action: actionButtonOptions
        ? () => (
              <SecondaryButton
                  size="small"
                  onClick={actionButtonOptions.onClick}
                  style={{ marginLeft: '48px', marginRight: '8px' }}
              >
                  {actionButtonOptions.buttonText}
              </SecondaryButton>
          )
        : undefined,
    ...snackbarOptions,
});

export const createSnackbar = ({
    title,
    message,
    actionButtonOptions,
    overrides,
}: {
    title: string;
    message: string;
    actionButtonOptions?: ActionButtonOptions;
    overrides?: {
        notistackSnackbarOptions?: Partial<OptionsObject>;
    };
}): [JSX.Element, OptionsObject] => {
    const snackbarText = <SnackbarText title={title} message={message} />;
    const options = createSnackbarOptions(actionButtonOptions, overrides?.notistackSnackbarOptions);

    return [snackbarText, options];
};
