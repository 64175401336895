import { http } from '../../resources/http';
import { createRfqHttpCallWithMock } from '../resources';
import { createBomItemMock } from '../resources/bomItem/bomItemMocks';
import { createCalculationAssemblyDetailsMock } from '../resources/calculation/calculationAssemblyDetailsMocks';
import { createCustomerMock } from '../resources/customer/customerMocks';
import { createManufacturingScenarioMock, createScenarioCombinationMock } from '../resources/manufacturingScenarios';
import { createOffTheShelfOfferMock } from '../resources/offer/offerMocks';
import { getPartIdForMPN } from '../resources/part/partMocks';
import { getFirstOTSApprovedSupplierAndStockLocation } from '../resources/supplier/supplierMocks';
import { getFirstUser } from '../resources/user/userMocks';

interface RfQSetup {
    token: string;
    customerId: string;
    rfqId: string;
    rfqName: string;
    sourcingScenarioId: string;
    topLevelAssemblyId: string;
    solutionConfigurationId: string;
    manufacturingScenarioId: string;
    sourcingCombinationId: string;
}

interface RfQSetupParams {
    batchSizes: number[];
    unitPrice: string;
    customerNamePrefix: string;
    moq?: number;
    mpq?: number;
    manufacturingScenarioTemplates?: string[];
    token: string;
}

export async function createRfQWithCalculation({
    batchSizes,
    unitPrice,
    customerNamePrefix,
    moq = 1,
    mpq = 1,
    manufacturingScenarioTemplates = [],
    token,
}: RfQSetupParams): Promise<RfQSetup> {
    const userId = await getFirstUser(token);
    const partId = await getPartIdForMPN({ mpn: 'CPF0805B10KE1', token });

    // Create customer
    const customerResponse = await http(
        'POST /customers',
        {
            requestBody: createCustomerMock({
                defaultContactPersonId: userId,
                name: `${customerNamePrefix} ${new Date().toISOString()}`,
                customerNumber: `customer-number-create-rfq-with-calculation-${new Date().toISOString()}`,
            }),
        },
        token,
    );
    const customerId = customerResponse.data.id;

    // Create RFQ
    const postRfQResponse = await createRfqHttpCallWithMock({ customerId, token });
    const rfqId = postRfQResponse.data.id;
    const rfqName = postRfQResponse.data.name;
    const getRfqResponse = await http('GET /rfqs/:rfqId', { pathParams: { rfqId } }, token);
    const sourcingScenarioId = getRfqResponse.data.sourcing_scenarios[0];
    const topLevelAssemblyId = getRfqResponse.data.top_level_assemblies[0].id;

    // Setup design items and offers
    await http(
        'POST /design-items/many',
        { requestBody: createBomItemMock({ assemblyId: topLevelAssemblyId, quantity: 1, partOptionId: partId }) },
        token,
    );

    const supplierAndStockLocation = await getFirstOTSApprovedSupplierAndStockLocation(token);

    const offTheShelfOfferResponse = await http(
        'POST /offers/off-the-shelf',
        {
            requestBody: createOffTheShelfOfferMock({
                linkedPartId: partId,
                unitPrice,
                priceBreakInputs: [{ moq, mpq, unit_price: parseFloat(unitPrice) }],
                supplierAndStockLocation: supplierAndStockLocation.id,
            }),
        },
        token,
    );
    const offTheShelfOfferId = offTheShelfOfferResponse.data.id;

    // Setup sourcing configuration
    const getSourcingScenarioResponse = await http(
        'GET /solution-configurations',
        { queryParams: { sourcing_scenario: sourcingScenarioId } },
        token,
    );
    const solutionConfigurationId = getSourcingScenarioResponse.items[0].id;

    const solutionsResponse = await http(
        'GET /solutions',
        { queryParams: { solution_conf: solutionConfigurationId, quantity: 1, unit: 'Pieces' } },
        token,
    );
    const manualSolutionToken = solutionsResponse.data.find(
        (solution) => solution.offer_id.offer === offTheShelfOfferId,
    )!.token;

    // Setup manufacturing scenario
    const manufacturingScenarioResponse = await http(
        'POST /manufacturing-scenarios',
        {
            requestBody: createManufacturingScenarioMock(topLevelAssemblyId, sourcingScenarioId, batchSizes),
            queryParams: { from_templates: manufacturingScenarioTemplates },
        },
        token,
    );

    await http(
        'PATCH /solution-configurations/:solutionConfigurationId',
        {
            requestBody: { manually_selected_solution_token: manualSolutionToken },
            pathParams: { solutionConfigurationId },
        },
        token,
    );

    const manufacturingScenarioId = manufacturingScenarioResponse.data.id;
    const scenarioCombinationResponse = await http(
        'PATCH /calculations/scenario-combinations',
        {
            requestBody: createScenarioCombinationMock(manufacturingScenarioId, sourcingScenarioId, batchSizes),
            queryParams: { assembly_id: topLevelAssemblyId },
        },
        token,
    );
    const sourcingCombinationId = scenarioCombinationResponse[0].id;

    await http(
        'POST /calculations/calculation-assembly-details',
        {
            requestBody: createCalculationAssemblyDetailsMock(topLevelAssemblyId),
            queryParams: {},
        },
        token,
    );

    await http(
        'POST /manufacturing-cache/sourcing/refresh/:assemblyId',
        { pathParams: { assemblyId: topLevelAssemblyId } },
        token,
    );

    return {
        token,
        customerId,
        rfqId,
        rfqName,
        sourcingScenarioId,
        topLevelAssemblyId,
        solutionConfigurationId,
        manufacturingScenarioId,
        sourcingCombinationId,
    };
}
