import { getToken } from '@luminovo/auth';
import {
    ComplianceStatus,
    ExtractArguments,
    ExtractResponseBody,
    LifecycleEnum,
    OffTheShelfPartSearchFilterRequest,
    RemoveByValue,
    RfQContextQueryParams,
    http,
} from '@luminovo/http-client';
import { useInfiniteQuery } from '@tanstack/react-query';
import { httpQueryKey } from '../../../resources/http/httpQueryKey';
import { SearchField, UsePartSearchStateResult } from './PartSearchInput';

export function useSearchParts({
    rfqContext,
    searchState,
}: {
    rfqContext: RfQContextQueryParams;
    searchState: UsePartSearchStateResult;
}) {
    const body = createSearchRequest(searchState.state.selectedBlocks, rfqContext);

    return useInfiniteQuery({
        queryKey: httpQueryKey('POST /parts/library/search', body),
        queryFn: async ({ pageParam }): Promise<SearchResultsPage> => {
            const result = await http(
                'POST /parts/library/search',
                {
                    requestBody: {
                        ...body.requestBody,
                        page_size: 100,
                        page_params: pageParam,
                    },
                },
                getToken(),
            );

            const mpnFieldTerms = searchState.state.selectedBlocks
                // The fuzzy term is also added as an MPN field for the search so we
                // add it to the MPN terms here as well for highlighting
                .filter((b) => b.field === SearchField.mpn || b.field === SearchField.fuzzy)
                .map((b) => String(b.parameter));
            return {
                ...result,
                mpnTerms: mpnFieldTerms,
            };
        },
        initialPageParam: undefined as unknown | undefined,
        getNextPageParam: (data) => data.page_params,
        staleTime: Infinity,
    });
}

export type SearchResultsPage = ExtractResponseBody<'POST /parts/library/search'> & {
    mpnTerms: string[];
};

function ensureArray(value: any): Array<any> {
    return Array.isArray(value) ? value : [value];
}

export function createSearchRequest(
    selectedBlocks: UsePartSearchStateResult['state']['selectedBlocks'],
    rfqContext: RfQContextQueryParams,
): RemoveByValue<ExtractArguments<'POST /parts/library/search'>, undefined> {
    const filters = selectedBlocks.flatMap((block): Array<OffTheShelfPartSearchFilterRequest> => {
        switch (block.field) {
            case SearchField.manufacturer:
                return [
                    {
                        field: 'manufacturer',
                        operator: 'equalsAny',
                        parameter: ensureArray(block.parameter) as Array<string>,
                    },
                ];

            case SearchField.mpn:
                return [
                    {
                        field: 'mpn',
                        operator: 'includesString',
                        parameter: ensureArray(block.parameter).map(String) as Array<string>,
                    },
                ];
            case SearchField.description:
                return [
                    {
                        field: 'description',
                        operator: 'includesString',
                        parameter: ensureArray(block.parameter).map(String) as Array<string>,
                    },
                ];
            case SearchField.lifecycle:
                return [
                    {
                        field: 'lifecycle',
                        operator: 'equalsAny',
                        parameter: ensureArray(block.parameter) as Array<LifecycleEnum>,
                    },
                ];
            case SearchField.reachCompliant:
                return [
                    {
                        field: 'reach-compliance' as const,
                        operator: 'equalsAny' as const,
                        parameter: ensureArray(block.parameter) as Array<ComplianceStatus>,
                    },
                ];
            case SearchField.rohsCompliant:
                return [
                    {
                        field: 'rohs-compliance',
                        operator: 'equalsAny',
                        parameter: ensureArray(block.parameter),
                    },
                ];
            default:
                return [];
        }
    });

    const fuzzyTerms = selectedBlocks
        .filter((b) => b.field === SearchField.fuzzy)
        .map((b) => String(b.parameter).trim())
        .filter((x) => x.length > 0);

    const body: RemoveByValue<ExtractArguments<'POST /parts/library/search'>, undefined> = {
        requestBody: {
            page_size: 100,
            page_params: undefined,
            global_filter: fuzzyTerms.join(' '),
            filters,
            sorts: [],
            ...rfqContext,
        },
    };

    return body;
}
