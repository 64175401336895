import { atom, WritableAtom } from 'jotai';
import { Validated } from './validate';

export type Cell<TValidated> = WritableAtom<Validated<TValidated>, [update: TValidated | undefined], void>;

interface Validator<TValidated> {
    validate(value: TValidated | undefined): Validated<TValidated>;
}

export function cell<TValidated, TSchema extends Validator<TValidated>>({
    initialValue,
    schema,
}: {
    initialValue: TValidated | undefined;
    schema: TSchema;
}): Cell<TValidated> {
    const $raw = atom(initialValue);
    const $cell = atom(
        (get) => {
            const raw = get($raw);
            return schema.validate(raw);
        },
        (_, set, update: TValidated | undefined) => {
            set($raw, update);
        },
    );
    return $cell;
}
