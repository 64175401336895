import * as z from 'zod';
import { MonetaryValueBackendRuntype, QuantityUnitDTORuntype } from '../backendTypes';
import { OneTimeCostDTORuntype } from '../offer';
import { DerivedScrapQuantityDTORuntype, SolutionStatusRuntype } from '../solution/solutionBackendTypes';
import { TotalCostOfOwnershipScalingRuntype } from '../totalCostOfOwnershipRules';

const ManualTcoCostItemRuntype = z.object({
    cost: MonetaryValueBackendRuntype,
    scaling: TotalCostOfOwnershipScalingRuntype,
});

const ManualTcoCostRuntype = z.object({
    packaging_cost: ManualTcoCostItemRuntype.nullable(),
    discount: ManualTcoCostItemRuntype.nullable(),
    shipping_cost: ManualTcoCostItemRuntype.nullable(),
    customs_cost: ManualTcoCostItemRuntype.nullable(),
    other_cost: ManualTcoCostItemRuntype.nullable(),
});

export type ManualAdditionalSolutionCostItemDTO = z.infer<typeof ManualTcoCostItemRuntype>;
export type ManualAdditionalSolutionCostDTO = z.infer<typeof ManualTcoCostRuntype>;

export interface SolutionConfigurationDTO extends z.infer<typeof SolutionConfigurationDTORuntype> {}
export const SolutionConfigurationDTORuntype = z.object({
    id: z.string(),
    aggregated_quantity: QuantityUnitDTORuntype,
    scrap_quantity: z.null().or(DerivedScrapQuantityDTORuntype),
    bom_items: z.object({
        design_items: z.object({
            items: z.array(z.string()),
        }),
    }),
    sourcing_scenario: z.string(),
    is_consigned: z.boolean(),
    notes: z.string().nullable(),
    manual_unit_costs: z.array(OneTimeCostDTORuntype).nullable(),
    manual_one_time_costs: z.array(OneTimeCostDTORuntype).nullable(),
    manual_total_cost_of_ownership: z.optional(ManualTcoCostRuntype.nullable()),
});

export interface SolutionConfigurationPatchDTO extends z.infer<typeof SolutionConfigurationPatchDTORuntype> {}
export const SolutionConfigurationPatchDTORuntype = z.object({
    manually_selected_solution_token: z.optional(z.null().or(z.string())),
    manual_scrap_quantity: z.optional(z.null().or(z.number())),
    notes: z.optional(z.string().nullable()),
    manual_solution_status: z.optional(SolutionStatusRuntype.nullable()),
    manual_one_time_costs: z.optional(z.array(OneTimeCostDTORuntype)).nullable(),
    manual_total_cost_of_ownership: z.optional(ManualTcoCostRuntype.nullable()),
});

export interface SolutionConfigurationsBulkPatchDTO extends z.infer<typeof SolutionConfigurationsBulkPatchDTORuntype> {}
export const SolutionConfigurationsBulkPatchDTORuntype = z.object({
    items: z.array(
        z.object({
            id: z.string(),
            update: SolutionConfigurationPatchDTORuntype,
        }),
    ),
});
export interface SingelSolutionConfigurationPatchResponseDTO
    extends z.infer<typeof SingelSolutionConfigurationPatchResponseDTORuntype> {}
export const SingelSolutionConfigurationPatchResponseDTORuntype = z.object({
    id: z.string(),
    manually_selected_solution: z.null().or(
        z.object({
            token: z.null().or(z.string()),
        }),
    ),
    manual_scrap_quantity: z.null().or(z.number()),
    manual_total_cost_of_ownership: z.optional(ManualTcoCostRuntype.nullable()),
});
