import * as z from 'zod';
import { ApiIntegrationStatusRuntype } from './3rdPartyBackendTypes';

export type AvnetAbacusLocaleCurrencyDTO = z.infer<typeof AvnetAbacusLocaleCurrencyDTORuntype>;
export const AvnetAbacusLocaleCurrencyDTORuntype = z.enum(['EUR', 'USD', 'GBP', 'JPY']);

export interface AvnetAbacusCredentialsDTO extends z.infer<typeof AvnetAbacusCredentialsDTORuntype> {}
export const AvnetAbacusCredentialsDTORuntype = z.object({
    username: z.string(),
    password: z.string(),
    currency: AvnetAbacusLocaleCurrencyDTORuntype,
});

export interface AvnetAbacusCredentialsInputDTO extends z.infer<typeof AvnetAbacusCredentialsInputDTORuntype> {}
export const AvnetAbacusCredentialsInputDTORuntype = z.object({
    username: z.string().optional(),
    password: z.string().optional(),
    currency: AvnetAbacusLocaleCurrencyDTORuntype.optional(),
});

export interface AvnetAbacusResponseBodyDTO extends z.infer<typeof AvnetAbacusResponseBodyDTORuntype> {}
export const AvnetAbacusResponseBodyDTORuntype = z.object({
    data: AvnetAbacusCredentialsDTORuntype.nullable(),
    status: ApiIntegrationStatusRuntype,
});
