import { PartSuggestionFull } from '@luminovo/http-client';
import { useHttpQuery } from '../../../../resources/http/useHttpQuery';
import { isPresent } from '../../../../utils/isPresent';
export function useSuggestions(
    assemblyId?: string,
    aggregationKey?: string,
    maxComponents?: number,
): { suggestions: PartSuggestionFull[]; isLoading: boolean } {
    const { data: newSuggestions, isLoading } = useHttpQuery(
        'GET /bom-items/:aggregationKey/suggestions',
        {
            pathParams: { aggregationKey: aggregationKey ?? '' },
            queryParams: { assembly_id: assemblyId ?? '', max_components: maxComponents ? maxComponents : 10 },
        },
        {
            enabled: isPresent(aggregationKey) && isPresent(assemblyId),
            select: (data) => {
                return data.items;
            },
        },
    );
    const suggestions = newSuggestions ?? [];

    return { suggestions, isLoading };
}
