import { t, Trans } from '@lingui/macro';
import { FormattingType } from '@luminovo/commons';
import { Chip, colorSystem, Column, Flexbox, Text } from '@luminovo/design-system';
import { Availability, MonetaryValueBackend } from '@luminovo/http-client';
import { formatAvailability, formatAvailabilityWithExtraDays } from '@luminovo/sourcing-core';
import { Equalizer, WatchLater } from '@mui/icons-material';
import { TableCell, Tooltip } from '@mui/material';
import React from 'react';
import { assertPresent } from '../../../utils/assertPresent';
import { assertUnreachable } from '../../../utils/typingUtils';
import { BatchCell, blankCellStyle, BreakdownCell, bufferCellStyle, FixedCell, hiddenCellStyle } from './cells/cells';
import { Cell, CellBlank, CellBreakdown, CellBuffer } from './types/cellTypes';
import { Row, Style } from './types/rowTypes';

export interface BatchColumn {
    cellIndex: number;
    orderSize: number;
    batchSize: number;
    leadTime: Availability | undefined;
    manufacturingLeadTime: number | undefined;
    formattingType: FormattingType;
}

const OrderSizeLeadTimeLabel = ({
    orderSize,
    leadTime,
    manufacturingLeadTime,
}: {
    orderSize: number;
    leadTime: Availability | undefined;
    manufacturingLeadTime: number | undefined;
}): JSX.Element => {
    return (
        <Flexbox flexDirection={'column'} gap={24}>
            <Flexbox gap={16}>
                <Tooltip title={t`Order size`}>
                    <Text variant="h1" style={{ color: colorSystem.neutral[8] }}>
                        <Equalizer fontSize="inherit" style={{ marginBottom: -2 }} /> {orderSize}
                    </Text>
                </Tooltip>

                <Tooltip
                    title={
                        <Flexbox flexDirection={'column'}>
                            <Text
                                style={{
                                    color: colorSystem.neutral[2],
                                }}
                                variant={'caption'}
                            >
                                <Trans>Sourcing lead time: </Trans> {formatAvailability(leadTime ?? null)}
                            </Text>
                            {manufacturingLeadTime !== undefined && (
                                <Text
                                    style={{
                                        color: colorSystem.neutral[2],
                                    }}
                                    variant={'caption'}
                                >
                                    <Trans>Manufacturing lead time: </Trans> {manufacturingLeadTime}{' '}
                                    <Trans>days </Trans>
                                </Text>
                            )}
                        </Flexbox>
                    }
                >
                    <Text style={{ color: colorSystem.neutral[8] }}>
                        <WatchLater fontSize="inherit" style={{ marginBottom: -2 }} />{' '}
                        {formatAvailabilityWithExtraDays({
                            availability: leadTime ?? undefined,
                            additionalLeadTimeDays: undefined,
                            manufacturingLeadTime: manufacturingLeadTime,
                        })}
                    </Text>
                </Tooltip>
            </Flexbox>
            <Text variant={'h4'} style={{ color: colorSystem.neutral[8] }}>
                <Trans>Per unit</Trans>
            </Text>
        </Flexbox>
    );
};

const BatchSizeTotalCostLabel = ({ batchSize }: { batchSize: number }): JSX.Element => {
    return (
        <Flexbox flexDirection={'column'} gap={24}>
            <Flexbox gap={8}>
                <Text style={{ color: colorSystem.neutral[8] }}>
                    <Trans>Batch size</Trans>
                </Text>
                <Chip color="green" label={batchSize.toString()} />
            </Flexbox>
            <Text variant={'h4'} style={{ color: colorSystem.neutral[8] }}>
                <Trans>Total cost</Trans>
            </Text>
        </Flexbox>
    );
};

const getTotalCostFromCell = (cell: Exclude<Cell, CellBlank | CellBuffer | CellBreakdown>): MonetaryValueBackend => {
    const type = cell.type;
    switch (type) {
        case 'fixed':
            return cell.totalCost;
        case 'fixed-percentage':
            return cell.totalCost;
        case 'dynamic':
            return cell.totalCostValue;
        default:
            assertUnreachable(type);
    }
};

const generateTextStyle = (style: Style): React.CSSProperties => {
    switch (style) {
        case 'breakdown':
        case 'grey':
            return { color: colorSystem.neutral[6] };
        case 'blue':
            return { color: colorSystem.primary[7], fontWeight: 600 };
        case 'bold':
            return { fontWeight: 600 };
        case 'header':
        case 'normal':
        case 'hidden':
        case 'buffer':
            return {};
        default:
            assertUnreachable(style);
    }
};

function hashObject(obj: unknown): string {
    const jsonString = JSON.stringify(obj);
    let hash = 0;

    for (let i = 0; i < jsonString.length; i++) {
        const char = jsonString.charCodeAt(i);
        hash = (hash << 5) - hash + char;
    }

    return `${hash}`;
}

export function generateBatchColumns({
    cellIndex,
    leadTime,
    batchSize,
    orderSize,
    manufacturingLeadTime,
    formattingType,
}: BatchColumn): Column<Row>[] {
    const leadTimeString = hashObject(leadTime ?? 'undefined');
    return [
        {
            id: `unit-${orderSize}-${batchSize}-${leadTimeString}`,
            label: (
                <OrderSizeLeadTimeLabel
                    orderSize={orderSize}
                    leadTime={leadTime}
                    manufacturingLeadTime={manufacturingLeadTime}
                />
            ),
            render: ({ data, index: rowIndex }) => {
                if (data.style === 'hidden') {
                    return <TableCell style={hiddenCellStyle} />;
                }
                if (data.type === 'buffer') {
                    return <TableCell style={bufferCellStyle} />;
                }
                return (
                    <BatchCell
                        formattingType={formattingType}
                        rowIndex={rowIndex}
                        cellIndex={cellIndex}
                        cell={assertPresent(data.cells[cellIndex])}
                        textStyle={generateTextStyle(data.style)}
                    />
                );
            },
        },
        {
            id: `totalCost-${orderSize}-${batchSize}-${leadTimeString}`,
            label: <BatchSizeTotalCostLabel batchSize={batchSize} />,
            render: ({ data }) => {
                if (data.style === 'hidden') {
                    return <TableCell style={hiddenCellStyle} />;
                }
                if (data.type === 'header') {
                    return <TableCell style={blankCellStyle} />;
                }
                if (data.type === 'buffer') {
                    return <TableCell style={bufferCellStyle} />;
                }
                if (data.type === 'breakdown') {
                    const cell = assertPresent(data.cells[cellIndex]);
                    return <BreakdownCell cost={cell.cost?.totalCost} formattingType={formattingType} />;
                }
                const cell = assertPresent(data.cells[cellIndex]);

                return (
                    <FixedCell
                        formattingType={formattingType}
                        cost={getTotalCostFromCell(cell)}
                        textStyle={{ ...generateTextStyle(data.style) }}
                    />
                );
            },
        },
    ];
}
