import { Trans } from '@lingui/macro';
import { colorSystem } from '@luminovo/design-system';
import { ActorResponseRuntypeDTO, HistoryOperation, HistoryRecordFlagDTO } from '@luminovo/http-client';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import RemoveCircleOutlineRounded from '@mui/icons-material/RemoveCircleOutlineRounded';
import { isActorDefined } from '../utils';
import { ActionPerformedBy, StyledTimelineDot, TimelineContentContainer, TimelineText } from './Utils';

type Props = {
    flag: HistoryRecordFlagDTO;
    operation: HistoryOperation;
    actor: ActorResponseRuntypeDTO;
};
export default function DoNotPlaceContent({ flag, operation, actor }: Props) {
    if (!isActorDefined(actor)) return null;
    if (operation === HistoryOperation.AutoAdd) return <AutoDNPMarking />;
    return flag.marked ? <MarkedAsDNP actor={actor} /> : <DNPMarkingRemoved actor={actor} />;
}

type DNPProps = {
    actor: ActorResponseRuntypeDTO;
};

function AutoDNPMarking() {
    return (
        <TimelineContentContainer>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <DNPIcon />{' '}
                <TimelineText>
                    <Trans>
                        <strong>DNP</strong> marking added automatically
                    </Trans>
                </TimelineText>
            </div>
        </TimelineContentContainer>
    );
}

function DNPMarkingRemoved({ actor }: DNPProps) {
    return (
        <TimelineContentContainer>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <DNPIcon paddingLeft={0} />{' '}
                <TimelineText>
                    <Trans>
                        <strong>DNP</strong> marking removed
                    </Trans>{' '}
                    <ActionPerformedBy actor={actor} />
                </TimelineText>
            </div>
        </TimelineContentContainer>
    );
}

function MarkedAsDNP({ actor }: DNPProps) {
    return (
        <TimelineContentContainer>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <TimelineText>
                    <Trans>Marked as</Trans>
                </TimelineText>{' '}
                <DNPIcon /> {'  '}
                <TimelineText>
                    <strong>
                        <Trans>DNP</Trans>
                    </strong>{' '}
                    <ActionPerformedBy actor={actor} />
                </TimelineText>
            </div>
        </TimelineContentContainer>
    );
}

function DNPIcon({ paddingLeft = 1.5 }: { paddingLeft?: number }) {
    return (
        <RemoveCircleOutlineRounded
            style={{
                color: colorSystem.neutral[7],
                paddingRight: 1.5,
                paddingLeft,
            }}
            fontSize={'small'}
            width={'13px'}
            height={'13px'}
        />
    );
}

export function DoNotPlaceTimeLineDot() {
    return (
        <StyledTimelineDot
            style={{
                backgroundColor: colorSystem.neutral[2],
            }}
        >
            <RemoveCircleOutlineIcon style={{ width: '20px', height: '20px', color: colorSystem.neutral[7] }} />
        </StyledTimelineDot>
    );
}
