import { useDialogContext } from '@/components/contexts/ModalContext';
import { errorCodeTranslations } from '@/modules/Error/errorCodeTranslations';
import { t, Trans } from '@lingui/macro';
import { isPresent, transEnum } from '@luminovo/commons';
import {
    createColumnHelper,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Flexbox,
    PrimaryButton,
    StatusChip,
    TanStackTable,
    Text,
    useTanStackTable,
} from '@luminovo/design-system';
import { ErrorCode, QuoteRequestDTO } from '@luminovo/http-client';
import { Box } from '@mui/material';
import React from 'react';
import { useQuoteRequests } from '../../hooks/negotiationHandlers';
import { formatQuoteRequest } from '../../model/formatQuoteRequest';

type QuoteRequestEmailStatus = {
    quoteRequest: QuoteRequestDTO;
    error: ErrorCode | null;
};

const columnHelper = createColumnHelper<QuoteRequestEmailStatus>();

const columns = [
    columnHelper.text((row) => formatQuoteRequest(row.quoteRequest), {
        id: 'quoteRequest',
        label: () => t`Quote request`,
        size: 100,
    }),
    columnHelper.enum('error', {
        id: 'status',
        label: () => t`Status`,
        size: 200,
        getOptionLabel: (option) => (option ? transEnum(option, errorCodeTranslations) : t`Success`),
        cell: ({ getValue }) => {
            const status = getValue();
            return (
                <StatusChip
                    label={status ? transEnum(status, errorCodeTranslations) : t`Success`}
                    color={status ? 'red' : 'green'}
                />
            );
        },
    }),
];

function QuoteRequestEmailPreviewErrorTable({
    statuses,
}: {
    statuses: { quoteRequestId: string; error: ErrorCode | null }[];
}) {
    const { data: quoteRequests } = useQuoteRequests(statuses.map((s) => s.quoteRequestId));

    const data = React.useMemo(() => {
        if (!isPresent(quoteRequests)) {
            return undefined;
        }
        return statuses
            .map((status) => ({
                quoteRequest: quoteRequests.find((qr) => qr.id === status.quoteRequestId),
                error: status.error,
            }))
            .sort((a, b) => {
                if (a.error === null && b.error === null) return 0;
                if (a.error === null) return 1;
                if (b.error === null) return -1;
                return a.error.localeCompare(b.error);
            });
    }, [statuses, quoteRequests]);

    const { table } = useTanStackTable({
        data,
        columns,
    });

    return (
        <Box height={200}>
            <TanStackTable enableMenuBar={false} table={table} size="small" />
        </Box>
    );
}

function QuoteRequestEmailPreviewErrorDialog({
    statuses,
    closeDialog,
}: {
    statuses: { quoteRequestId: string; error: ErrorCode | null }[];
    closeDialog: VoidFunction;
}) {
    return (
        <Dialog open={true} onClose={closeDialog}>
            <DialogTitle title={t`Quote request email errors`} />
            <DialogContent>
                <Flexbox flexDirection="column" rowGap={2}>
                    <Text variant="body-small">
                        <Trans>
                            There were some errors when trying to send out some quote requests, please try again later.
                            If the issue persists, please contact support.
                        </Trans>
                    </Text>
                    <QuoteRequestEmailPreviewErrorTable statuses={statuses} />
                </Flexbox>
            </DialogContent>
            <DialogActions>
                <PrimaryButton onClick={closeDialog}>Close</PrimaryButton>
            </DialogActions>
        </Dialog>
    );
}

export function useQuoteRequestEmailErrorDialog() {
    const { setDialog, closeDialog } = useDialogContext();

    return {
        openDialog: ({
            statuses,
            onClose,
        }: {
            statuses: { quoteRequestId: string; error: ErrorCode | null }[];
            onClose: VoidFunction;
        }) => {
            setDialog(
                <QuoteRequestEmailPreviewErrorDialog
                    statuses={statuses}
                    closeDialog={() => {
                        closeDialog();
                        onClose();
                    }}
                />,
            );
        },
    };
}
