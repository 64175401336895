import React from 'react';
import { PageLayout } from '../../components/PageLayout';
import { useRfQ } from '../../resources/rfq/rfqHandler';
import { UrlParams } from '../../utils/routes';
import { ViewContext } from '../Bom/components/ModuleTableData';
import { CadViewerTab } from './CadViewerTab';
import { ToolbarPcb } from './components/ToolbarPcb/ToolbarPcb';
import { useIsPcbEditable } from './utils/useIsPcbSectionEditable';
export const PcbCadViewerPage = ({
    pathParams,
    queryParams,
}: UrlParams<'/rfqs/:rfqId/bom/assembly/:assemblyId/pcb/cad-viewer'>) => {
    const { assemblyId, rfqId } = pathParams;
    const { currentParentAssemblyId } = queryParams;
    const { data: rfq } = useRfQ(rfqId);
    const { isRfqEditable } = useIsPcbEditable(rfq);

    const viewContext: ViewContext = React.useMemo(() => {
        return {
            type: 'WithinRfQ',
            rfqId,
        };
    }, [rfqId]);

    return (
        <PageLayout
            removeBottomPadding
            header={
                <ToolbarPcb assemblyId={assemblyId} rfqId={rfqId} currentParentAssemblyId={currentParentAssemblyId} />
            }
            layout="fragment"
        >
            <CadViewerTab assemblyId={assemblyId} rfqId={rfqId} isEditable={isRfqEditable} viewContext={viewContext} />
        </PageLayout>
    );
};
