import * as z from 'zod';
import { QuantityUnitDTORuntype } from '../backendTypes';
import { DemandResponseRuntype } from './demandBackendTypes';

const MonthAndYearRuntype = z.object({
    month: z.number(),
    year: z.number(),
});
export type MonthAndYearDTO = z.infer<typeof MonthAndYearRuntype>;

const MonthlyDemandRuntype = z.object({
    month_and_year: MonthAndYearRuntype,
    gross_quantity: QuantityUnitDTORuntype,
    net_quantity: QuantityUnitDTORuntype,
});
export type MonthlyDemandDTO = z.infer<typeof MonthlyDemandRuntype>;

const YearlyDemandRuntype = z.object({
    year: z.number(),
    gross_quantity: QuantityUnitDTORuntype,
    net_quantity: QuantityUnitDTORuntype,
});
export type YearlyDemandDTO = z.infer<typeof YearlyDemandRuntype>;

const ParticipantDemandSummaryRuntype = z.object({
    participant_number: z.string().nullable().optional(),
    participant_name: z.string(),
    total_gross_demand: QuantityUnitDTORuntype,
    total_net_demand: QuantityUnitDTORuntype,
    monthly_demands: z.array(MonthlyDemandRuntype),
    yearly_demands: z.array(YearlyDemandRuntype),
});
export type ParticipantDemandSummaryDTO = z.infer<typeof ParticipantDemandSummaryRuntype>;

const DemandSummaryRuntype = z.object({
    total_gross_demand: QuantityUnitDTORuntype,
    total_net_demand: QuantityUnitDTORuntype,
    supplier_demands: z.array(ParticipantDemandSummaryRuntype),
    ship_to_site_demands: z.array(ParticipantDemandSummaryRuntype),
    start_date: z.string(),
    end_date: z.string(),
});
export type DemandSummaryDTO = z.infer<typeof DemandSummaryRuntype>;

export const DemandSummaryResponseRuntype = z.object({
    summary: DemandSummaryRuntype,
    demands: z.array(DemandResponseRuntype),
});
export type DemandsSummaryResponseDTO = z.infer<typeof DemandSummaryResponseRuntype>;

const DemandSummaryForRuntype = z.union([
    z.object({
        type: z.literal('Assembly'),
        assembly_id: z.string(),
    }),
    z.object({
        type: z.literal('Component'),
        component_ipn_id: z.string(),
    }),
]);

export const DemandSummaryDemandsRequestRuntype = z.object({
    demand_ids: z.array(z.string()).optional(),
    summary_for: DemandSummaryForRuntype,
});
