import {
    closestCenter,
    DndContext,
    DragEndEvent,
    DragOverlay,
    DragStartEvent,
    MeasuringStrategy,
    UniqueIdentifier,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { Action, colorSystem, Column, DataTable, DataTableProps, PersistentTableState } from '@luminovo/design-system';
import { Table, TableBody } from '@mui/material';
import React from 'react';
import { createPortal } from 'react-dom';
import { SortableDataTableRow } from './SortableDataTableRow';
import { SortableDataTableState } from './useSortableDataTable';

function ActiveDataTableRow<T>({
    item,
    index,
    columns,
    tableState,
    dispatch,
    rowId,
}: {
    item: T | undefined;
    index: number;
    rowId: string;
    columns: Column<T>[];
    tableState: PersistentTableState;
    dispatch: React.Dispatch<Action>;
}) {
    return createPortal(
        <>
            <DragOverlay dropAnimation={null} wrapperElement={'table'}>
                {item && (
                    <TableBody>
                        <SortableDataTableRow
                            rowId={rowId}
                            index={index}
                            item={item}
                            columns={columns}
                            tableState={tableState}
                            onItemClick={undefined}
                            dispatch={dispatch}
                            sharedContext={undefined}
                            state="default"
                            style={{
                                cursor: 'grabbing',
                                backgroundColor: colorSystem.neutral.white,
                            }}
                        />
                    </TableBody>
                )}
            </DragOverlay>
        </>,
        document.body,
    );
}

interface ItemWithUniqueId {
    id: UniqueIdentifier;
}

type OnDragEndHandler<T> = (updatedItems: T[], oldIndex: number, newIndex: number) => Promise<void>;

export function SortableDataTable<T extends ItemWithUniqueId>({
    idExtractor,
    dataTableProps,
    onDragEndCallback,
    sortableTableState,
}: {
    idExtractor: (item: T) => string;
    dataTableProps?: Omit<DataTableProps<T>, 'tableState'>;
    onDragEndCallback?: OnDragEndHandler<T>;
    sortableTableState: SortableDataTableState<T>;
}): JSX.Element {
    const [activeId, setActiveId] = React.useState<UniqueIdentifier | null>(null);
    const { tableState, columns, items, setCurrentUpdate, currentUpdate } = sortableTableState;
    const getItemIndex = (id: UniqueIdentifier): number => {
        return items.findIndex((item) => {
            const rowId = idExtractor(item);
            return rowId === id;
        });
    };

    const getCurrentlyDraggedItem = () => {
        return items.find((item) => {
            const rowId = idExtractor(item);
            return rowId === activeId;
        });
    };

    const handleDragEnd = async (event: DragEndEvent) => {
        const { active, over } = event;
        if (over === null) {
            return;
        }

        const activeId = getItemIndex(active.id);
        const overId = getItemIndex(over.id);

        if (activeId !== overId && onDragEndCallback) {
            const updatedItems = arrayMove(items, activeId, overId);
            setCurrentUpdate({ updatedItems, updatedItemId: active.id });
            await onDragEndCallback(updatedItems, activeId, overId);
            setCurrentUpdate(undefined);
        }

        setActiveId(null);
    };

    const handleDragStart = (event: DragStartEvent) => {
        setActiveId(event.active.id);
    };

    return (
        <DndContext
            modifiers={[restrictToVerticalAxis]}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
            onDragStart={handleDragStart}
            measuring={{
                droppable: {
                    strategy: MeasuringStrategy.Always,
                },
            }}
        >
            <SortableContext items={items.map((item) => idExtractor(item))} strategy={verticalListSortingStrategy}>
                <DataTable
                    size={'medium'}
                    {...dataTableProps}
                    tableState={tableState}
                    overrides={{
                        ...dataTableProps?.overrides,
                        TableRow: ({ item, ...tableRowProps }) => {
                            const rowId = idExtractor(item);
                            const state = currentUpdate
                                ? rowId === currentUpdate.updatedItemId
                                    ? 'updating'
                                    : 'disabled'
                                : 'default';
                            return SortableDataTableRow({
                                ...tableRowProps,
                                item,
                                rowId,
                                state,
                            });
                        },
                    }}
                />
            </SortableContext>

            {activeId && (
                <Table>
                    <ActiveDataTableRow
                        rowId={activeId.toString()}
                        index={Number(activeId)}
                        item={getCurrentlyDraggedItem()}
                        tableState={tableState.state}
                        dispatch={tableState.dispatch}
                        columns={columns}
                    />
                </Table>
            )}
        </DndContext>
    );
}
