import { t } from '@lingui/macro';
import { assertUnreachable, transEnum } from '@luminovo/commons';
import { CustomPartOfferStatuses, PriceRadarStatusKind } from '@luminovo/http-client';
import {
    apiNotSetupErrorTranslations,
    panelErrorTranslations,
    priceRadarErrorsTranslations,
} from '../../../../../resources/pcb/i18n';

export type PriceRadarViolation =
    | {
          name: string;
          label?: string;
          message: string;
          type: 'capability';
      }
    | {
          message: string;
          type: 'price_break';
      }
    | {
          message: string;
          type: 'error';
      };

export const convertOfferErrorToText = (offer: CustomPartOfferStatuses): PriceRadarViolation[] | undefined => {
    switch (offer.type) {
        case PriceRadarStatusKind.OffersAvailable:
            return undefined;

        case PriceRadarStatusKind.NoOffersReceived:
            return [
                {
                    message: t`No offers received`,
                    type: 'error',
                },
            ];

        case PriceRadarStatusKind.NeedsRefreshForOffers:
            return [
                {
                    message: t`Refresh offers to get prices for this supplier`,
                    type: 'error',
                },
            ];

        case PriceRadarStatusKind.MissingCredentials:
            return [
                {
                    message: t`Missing credentials`,
                    type: 'error',
                },
            ];

        case PriceRadarStatusKind.InvalidCredentials:
            return [
                {
                    message: t`Invalid credentials`,
                    type: 'error',
                },
            ];

        case PriceRadarStatusKind.Pending:
            return [
                {
                    message: t`Offer is pending`,
                    type: 'error',
                },
            ];

        case PriceRadarStatusKind.InvalidSpecification:
            return offer.errors.map((error) => ({
                name: transEnum(error.name, priceRadarErrorsTranslations),
                label: error.label,
                message: error.error,
                type: 'capability',
            }));

        case PriceRadarStatusKind.PanelError:
            return [
                {
                    message: transEnum(offer.error.type, panelErrorTranslations),
                    type: 'error',
                },
            ];

        case PriceRadarStatusKind.SupplierApiError:
            return [
                {
                    // eslint-disable-next-line spellcheck/spell-checker
                    message: t`We’re having issues fetching instant offers from this supplier. Please try again later. If the issue persists, contact support.`,
                    type: 'error',
                },
            ];

        case PriceRadarStatusKind.ApiNotSetUp:
            return [
                {
                    message: transEnum(offer.error.type, apiNotSetupErrorTranslations),
                    type: 'error',
                },
            ];

        case PriceRadarStatusKind.CustomStackup:
            return [
                {
                    // eslint-disable-next-line spellcheck/spell-checker
                    message: t`PCBs with custom stackups require manual quote requests`,
                    type: 'error',
                },
            ];

        case PriceRadarStatusKind.StackratePricingBreaks:
            return offer.pricing_breaks.map((error) => ({
                message: error.message,
                type: 'price_break',
            }));

        default:
            assertUnreachable(offer);
    }
};
