import { useShowWinRate } from '@/featureFlags';
import { t } from '@lingui/macro';
import { formatDecimal, isPresent } from '@luminovo/commons';
import { colorSystem, Flexbox, InteractiveCard, Table, Text } from '@luminovo/design-system';
import { EqualizerRounded, InfoOutlined, TableChartOutlined } from '@mui/icons-material';
import { Box, Skeleton, Tooltip, Typography } from '@mui/material';
import { analytics } from '../../../utils/analytics';
import { calculateRfqMetrics, calculateWinRate } from '../utils/rfqMetrics';
import { ViewMode } from './ChartRfq';
import { RfqOverviewTableItem } from './RfqOverviewTable';

const IndicatorHeader: React.FunctionComponent<{
    title: string;
    icon?: 'graph' | 'table';
}> = ({ title, icon }): JSX.Element => {
    return (
        <Flexbox justifyContent={'space-between'} alignItems={'baseline'}>
            <Text variant={'h4'} color={colorSystem.neutral[7]}>
                {title}
            </Text>
            <Flexbox gap={4} alignItems={'center'}>
                <Text variant={'body'} color={colorSystem.neutral[6]}>
                    {icon === 'graph' ? t`Graph` : t`Table`}
                </Text>
                {icon === 'graph' ? (
                    <EqualizerRounded fontSize="small" style={{ color: colorSystem.neutral[6] }} />
                ) : (
                    <TableChartOutlined fontSize="small" style={{ color: colorSystem.neutral[6] }} />
                )}
            </Flexbox>
        </Flexbox>
    );
};

function IndicatorBox({
    title,
    indicators,
    icon,
    viewMode,
    selectedViewMode,
    setViewMode,
}: React.PropsWithChildren<{
    title: string;
    indicators: Array<{
        value?: string | JSX.Element;
        secondary?: JSX.Element;
    }>;
    icon: 'table' | 'graph';
    viewMode: ViewMode;
    selectedViewMode: ViewMode;
    setViewMode: (graphId: ViewMode) => void;
}>): JSX.Element {
    const isSelected = viewMode === selectedViewMode;

    return (
        <InteractiveCard
            display={'flex'}
            flexDirection={'column'}
            style={{ gap: 8 }}
            selected={isSelected}
            onClick={() => setViewMode(isSelected ? 'table' : viewMode)}
        >
            <IndicatorHeader title={title} icon={icon} />
            <Flexbox gap={16} alignItems="flex-start" style={{ width: '100%' }}>
                {indicators.map((indicator, index) => (
                    <Flexbox key={index} alignItems={'end'} gap={'4px'} style={{ flexWrap: 'nowrap' }}>
                        {!isPresent(indicator.value) && <Skeleton width={'100%'} height={'30px'} />}
                        {isPresent(indicator.value) && typeof indicator.value === 'string' ? (
                            <Typography variant="h2" noWrap style={{ flexShrink: 0 }}>
                                {indicator.value}
                            </Typography>
                        ) : (
                            indicator.value
                        )}
                        {indicator.secondary}
                    </Flexbox>
                ))}
            </Flexbox>
        </InteractiveCard>
    );
}

export const RfqSummaryBox: React.FunctionComponent<{
    viewMode: ViewMode;
    setViewMode: (viewMode: ViewMode) => void;
    table: Table<RfqOverviewTableItem>;
}> = ({ viewMode, setViewMode, table }): JSX.Element => {
    const data = table.getCoreRowModel().rows.map((row) => row.original);
    const metrics = calculateRfqMetrics(data);
    const { showWinRate } = useShowWinRate();
    const { winRate } = calculateWinRate(data);
    return (
        <Box
            display={'grid'}
            columnGap="16px"
            gridTemplateColumns={showWinRate ? 'repeat(4,1fr)' : 'repeat(3,1fr)'}
            width={'100%'}
        >
            <IndicatorBox
                viewMode="table"
                icon="table"
                title={t`Active RfQs`}
                indicators={[
                    {
                        value: formatDecimal(data.filter((rfq) => !rfq.is_archived).length),
                        secondary: <Text color={colorSystem.neutral[6]}>{t`of ${formatDecimal(data.length)}`}</Text>,
                    },
                ]}
                selectedViewMode={viewMode}
                setViewMode={setViewMode}
            />

            <IndicatorBox
                viewMode="rfqsByCreatedDate"
                icon="graph"
                title={t`RfQ creation trends`}
                indicators={[
                    {
                        value: formatDecimal(metrics.total),
                        secondary: <Text color={colorSystem.neutral[6]}>{t`total`}</Text>,
                    },
                ]}
                selectedViewMode={viewMode}
                setViewMode={setViewMode}
            />

            <IndicatorBox
                viewMode="rfqsByStatus"
                icon="graph"
                title={t`RFQ status distribution`}
                indicators={[
                    {
                        value: formatDecimal(metrics.categories.in_progress.count),
                        secondary: <Text color={colorSystem.neutral[6]}>{t`in progress`}</Text>,
                    },
                ]}
                selectedViewMode={viewMode}
                setViewMode={setViewMode}
            />
            {showWinRate && (
                <IndicatorBox
                    viewMode="winRate"
                    icon="graph"
                    title={t`Win rate`}
                    indicators={[
                        {
                            value: `${formatDecimal(winRate, { maximumFractionDigits: 0 })}%`,
                            secondary: (
                                <Flexbox alignItems="center" gap={1}>
                                    <Text color={colorSystem.neutral[6]}>{t`Win rate`}</Text>
                                    <Tooltip
                                        title={t`RfQs won vs. total RfQs received (excludes in progress and archived RfQs)`}
                                    >
                                        <InfoOutlined
                                            fontSize="small"
                                            sx={{ color: colorSystem.neutral[6], fontSize: 16 }}
                                        />
                                    </Tooltip>
                                </Flexbox>
                            ),
                        },
                    ]}
                    selectedViewMode={viewMode}
                    setViewMode={(mode) => {
                        analytics.track('click_win_rate_indicator', {
                            previous_view_mode: viewMode,
                            new_view_mode: mode,
                        });
                        setViewMode(mode);
                    }}
                />
            )}
        </Box>
    );
};
