import * as z from 'zod';
import { DriverIdRuntype } from '../driver/driverIdBackendTypes';
import { ResourceDTORuntype } from '../resource/resourceBackendTypes';
import { CategoryRuntype, ManufacturingEntityStatusRuntype, ProcessRuntype } from '../sharedManufacturingBackendTypes';

const PanelDefaultSettingsRuntype = z.enum(['AsSingleAssembly', 'WithinWholePanel']);

export const DurationUnitRuntypes = z.enum(['Seconds', 'Minutes', 'Hours']);
export type DurationUnit = z.infer<typeof DurationUnitRuntypes>;

export interface Duration extends z.infer<typeof DurationRuntype> {}
export const DurationRuntype = z.object({
    amount: z.string(), //this is a decimal so should be a string
    unit: DurationUnitRuntypes,
});

export type VariableTime = z.infer<typeof VariableTimeRuntype>;
const VariableTimeRuntype = z.object({
    variable_unit: DurationRuntype,
    driver: DriverIdRuntype,
});

const TimeCalculationFixedDetails = z.object({
    type: z.literal('Fixed'),
    details: z.object({
        fixed_time: DurationRuntype,
    }),
});

const TimeCalculationLinearDetails = z.object({
    type: z.literal('Linear'),
    details: z.object({
        fixed_time: DurationRuntype.nullable(),
        variable_time: VariableTimeRuntype,
    }),
});

const FormulaDetailsRuntype = z.object({
    formula: z.string(),
    unit: DurationUnitRuntypes,
});

export type FormulaDetails = z.infer<typeof FormulaDetailsRuntype>;

const TimeCalculationFormulaDetails = z.object({
    type: z.literal('Formula'),
    details: FormulaDetailsRuntype,
});

export type TimeCalculationFormulaDetailsType = z.infer<typeof TimeCalculationFormulaDetails>;

const TimeCalculationDetails =
    TimeCalculationFixedDetails.or(TimeCalculationLinearDetails).or(TimeCalculationFormulaDetails);

export const TimeComponentsRuntype = TimeCalculationDetails;
export type TimeComponents = z.infer<typeof TimeComponentsRuntype>;

const TimeCalculationBatchRuntype = z.object({
    level: z.literal('Batch'),
    details: z.object({
        batch_time_components: TimeComponentsRuntype,
    }),
});
export type TimeCalculationBatch = z.infer<typeof TimeCalculationBatchRuntype>;

const TimeCalculationProjectRuntype = z.object({
    level: z.literal('Project'),
    details: z.object({
        project_time_components: TimeComponentsRuntype,
    }),
});
export type TimeCalculationProject = z.infer<typeof TimeCalculationProjectRuntype>;

export interface TimeCalculationUnit extends z.infer<typeof TimeCalculationUnitRuntype> {}
const TimeCalculationUnitRuntype = z.object({
    level: z.literal('Unit'),
    details: z.object({
        panel_default_settings: PanelDefaultSettingsRuntype,
        unit_time_components: TimeCalculationDetails,
        batch_time_components: TimeCalculationDetails.nullable(),
    }),
});

export const TimeCalculationRuntype =
    TimeCalculationUnitRuntype.or(TimeCalculationBatchRuntype).or(TimeCalculationProjectRuntype);
export type TimeCalculation = z.infer<typeof TimeCalculationRuntype>;

export type ResourceDetails = z.infer<typeof ResourceDetailsRuntype>;
const ResourceDetailsRuntype = z.object({
    resource_id: z.string(),
    multiplier: z.string(),
});

export type ResourceDetailsDTO = z.infer<typeof ResourceDetailsDTORuntype>;
export const ResourceDetailsDTORuntype = z.object({
    resource: ResourceDTORuntype,
    multiplier: z.string(),
});

export const ActivityDTORuntype = z.object({
    id: z.string(),
    name: z.string(),
    internal_number: z.string().nullable(),
    description: z.string().nullable(),
    process: ProcessRuntype,
    category: CategoryRuntype,
    resources_details: z.array(ResourceDetailsRuntype),
    time_calculation: TimeCalculationRuntype,
    status: ManufacturingEntityStatusRuntype,
    sites: z.array(z.string()),
});

export interface ActivityDTO extends z.infer<typeof ActivityDTORuntype> {}

export const ActivitiesDTORuntype = z.array(ActivityDTORuntype);

export interface ActivitiesDTO extends z.infer<typeof ActivitiesDTORuntype> {}

export const ActivityPostDTORuntype = ActivityDTORuntype.omit({ id: true, status: true, sites: true });
export interface ActivityPostDTO extends z.infer<typeof ActivityPostDTORuntype> {}

export const ActivityOverwriteDTORuntype = z.object({
    id: z.string(),
    activity: z.string(),
    manufacturing_scenario: z.string(),
    time_calculation: TimeCalculationRuntype,
});
export const ActivityOverwritePatchDTORuntype = z.object({
    manufacturing_scenario: z.string(),
    time_calculation: TimeCalculationRuntype,
});

export const ActivityOverwritePostDTORuntype = ActivityOverwriteDTORuntype.omit({ id: true, activity: true });
export interface ActivityOverwritePostDTO extends z.infer<typeof ActivityOverwritePostDTORuntype> {}
