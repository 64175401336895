import { z } from 'zod';
import { endpoint } from '../http/endpoint';
import {
    CustomerPortalPaymentSettingsRuntype,
    CustomerPortalSettingsRuntype,
    CustomerPortalSettingsUpdateRuntype,
} from './customerPortalSettingsBackendTypes';
import {
    CustomerSpecificSettingsAggregateDTORuntype,
    CustomerSpecificSettingsAggregateUpdateRequestDTORuntype,
} from './customerSpecificSettingsBackendTypes';

export const customerPortalSettingsEndpoints = {
    'GET /customer-portal-settings': endpoint({
        description: 'Fetch customer portal settings',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: CustomerPortalSettingsRuntype,
    }),

    'PATCH /customer-portal-settings': endpoint({
        description: 'Update customer portal settings',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: CustomerPortalSettingsUpdateRuntype,
        responseBody: z.unknown(),
        invalidates: ['GET /customer-portal-settings'],
    }),

    'GET /customer-portal-settings/customer-specific-settings/aggregate': endpoint({
        description: 'Fetch customer-specific settings aggregated by setting',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.array(CustomerSpecificSettingsAggregateDTORuntype),
    }),

    'PATCH /customer-portal-settings/customer-specific-settings/aggregate': endpoint({
        description: 'Clear and update customer-specific settings for a specific setting',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: CustomerSpecificSettingsAggregateUpdateRequestDTORuntype,
        responseBody: z.unknown(),
        invalidates: ['GET /customer-portal-settings/customer-specific-settings/aggregate'],
    }),

    'GET /customer-portal-settings/payment-settings': endpoint({
        description: 'Fetch customer portal payment settings',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: CustomerPortalPaymentSettingsRuntype,
    }),
};
