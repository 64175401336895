/* eslint-disable no-use-before-define */
import { t } from '@lingui/macro';
import { FieldSelectControlled } from '@luminovo/design-system';
import { CustomerDTO } from '@luminovo/http-client';
import { useFormContext } from 'react-hook-form';
import { useCustomers } from '../../../../resources/customer/customerHandler';
import { useHasCustomers } from '../../../../utils/featureFlags';
import { useAddCustomerDialog } from '../../../Customers/CustomerUserManagement/ButtonAddCustomer';
import { RfqCreationFormState } from '../../RfqCreationContext';

export const CustomerInput = (): JSX.Element => {
    const { control, setValue } = useFormContext<RfqCreationFormState>();

    const { openDialog } = useAddCustomerDialog({
        onSuccess: (newCustomer: CustomerDTO) => {
            setValue('customer', newCustomer, { shouldValidate: true });
        },
    });

    const { hasCustomers } = useHasCustomers();
    const { data: options } = useCustomers();

    return (
        <FieldSelectControlled
            name="customer"
            control={control}
            required
            FieldProps={{
                options,
                getOptionKey: (option) => option.id,
                getOptionLabel: (option) => option.name,
                getOptionSublabel: (option) => option.customer_number,
                isOptionEqualToValue: (option, value) => option.id === value.id,
                placeholder: hasCustomers ? t`Search for or add a new customer` : t`Search for or add a new company`,
                action: {
                    label: hasCustomers ? t`Add new customer` : t`Add new company`,
                    onClick: () => openDialog(),
                },
            }}
        />
    );
};
