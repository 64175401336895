import * as z from 'zod';

export type QuoteImporterProblem = z.infer<typeof QuoteImporterProblemsRuntype>;
const QuoteImporterProblemRuntype = z.object({
    cell: z.string(),
    line: z.number(),
});
export const QuoteImporterProblemsRuntype = z.union([
    z.object({
        StringExtractionProblem: QuoteImporterProblemRuntype,
    }),
    z.object({
        FloatExtractionProblem: QuoteImporterProblemRuntype,
    }),
    z.object({
        GeneralError: QuoteImporterProblemRuntype,
    }),
    z.object({
        NegativeValueError: QuoteImporterProblemRuntype,
    }),
    z.object({
        ZeroUnitPriceError: QuoteImporterProblemRuntype,
    }),
]);

export const ErrorCodeRuntype = z.enum([
    'additional_service.english_name_cannot_be_empty',
    'additional_service.german_name_cannot_be_empty',
    'assembly.bom_file_not_found',
    'assembly.cannot_add_top_level_assembly_as_customer_user',
    'assembly.designator_already_exists_among_siblings',
    'assembly.not_found',
    'assembly.ipn_already_exists',
    'auto_rfq_number_settings.cant_increment_manual_rfq_number',
    'auto_rfq_number_settings.invalid_auto_rfq_number_range',
    'bom_export.cannot_delete_default_template',
    'bom_export.cannot_delete_last_template',
    'bom_export.cannot_update_default_status_of_default_template',
    'bom_export.should_contain_at_least_one_column',
    'bom_export.template_with_name_already_exists',
    'bom_import.cannot_read_file',
    'bom_import.bom_file_too_big',
    'bom_import.duplicate_designators',
    'costed_bom_template.cannot_delete_default_template',
    'costed_bom_template.cannot_delete_last_template',
    'costed_bom_template.cannot_update_default_status_of_default_template',
    'costed_bom_template.should_contain_at_least_one_column',
    'costed_bom_template.template_with_name_already_exists',
    'customer_portal_settings.calculation_template_required',
    'customer_portal_settings.invalid_auto_rfq_number_range',
    'customer_portal_settings.manufacturing_template_required',
    'customer.billing_address_incomplete',
    'customer.name_unavailable',
    'customer.number_already_exists',
    'customer.payload_vat_identification_number_missing',
    'customer.payload_vat_identification_type_invalid',
    'customer.vat_id_incomplete',
    'customer.not_found',
    'customer.has_referenced_demands',
    'demand.ambiguous_participant_site_number',
    'demand.delivery_batch_size_out_of_bounds',
    'demand.invalid_annual_demand',
    'demand.invalid_assembly_id',
    'demand.invalid_dates',
    'demand.invalid_delivery_dates',
    'demand.invalid_demand_scenario_end_year',
    'demand.invalid_demand_scenario_year',
    'demand.invalid_prototype_demand_quantity',
    'demand.missing_annual_demand',
    'demand.more_than_three_delivery_batch_sizes',
    'demand.multiple_annual_demands_for_one_year',
    'demand.no_annual_demands',
    'demand.only_customer_number_or_customer_id_required',
    'demand.only_site_number_or_site_id_required',
    'demand.prototype_demand_scenario_date_not_in_future',
    'design_item.belong_to_different_rfq',
    'design_item.not_found',
    'design_item.rfq_not_found',
    'driver.first_char_must_be_letter',
    'driver.invalid_lexorank',
    'driver.invalid_package_name',
    'driver.name_already_in_use',
    'driver.name_is_reserved_keyword',
    'driver.name_is_system_driver',
    'driver.name_must_be_alphanumeric',
    'driver.name_must_not_be_empty',
    'email_template.name.already_exists',
    'email_template.suppliers.conflict',
    'email.other_error',
    'email.payload_too_large',
    'frozen_scenario_cost.already_frozen',
    'integration.error',
    'integration.invalid_api_key',
    'integration.invalid_credentials',
    'integration.invalid_settings',
    'integration.rate_limited',
    'invite.email_already_exists',
    'invite.invalid_user_invite',
    'invite.invite_already_sent',
    'manufacturing_assembly_details.driver_does_not_use_sourcing_field',
    'manufacturing_assembly_details.panel_factor_less_than_one',
    'manufacturing_scenario_template.already_exists',
    'manufacturing_scenario.name_already_exists',
    'not_found',
    'off_the_shelf.duplicated_mpn',
    'off_the_shelf.mpn_length_violation',
    'off_the_shelf.package_violation',
    'off_the_shelf.part_already_exists',
    'order.tax_id_invalid',
    'order.payment_method_not_allowed',
    'order.not_found',
    'order.sourcing_offers_pending',
    'panel.generation.pcb_height_exceeds_panel',
    'panel.generation.pcb_width_exceeds_panel',
    'panel.generation.unknown_error',
    'payload.customer_company_name_cannot_be_empty',
    'payload.email_cannot_be_empty',
    'payload.first_name_cannot_be_empty',
    'payload.last_name_cannot_be_empty',
    'payload.password_cannot_contain_personal_data',
    'payload.password_mismatch',
    'payload.vat_identification_number_cannot_be_empty',
    'payload.weak_password',
    'payment.already_scheduled_for_refunded',
    'payment.amount_mismatch',
    'payment.invalid_currency',
    'payment.no_configured_payment_processor',
    'payment.not_found',
    'payment.refunded',
    'payment.scheduled_for_refunded',
    'pnp_file.not_found',
    'pnp_importer.column_missing',
    'pnp_importer.csv',
    'pnp_importer.invalid_mapping',
    'pnp_importer.invalid_rfq_state',
    'pnp_importer.multiple_mapping',
    'pnp_item.not_found',
    'purchase_order.conflict',
    'quote_importer.extraction',
    'quote_importer.general',
    'quote_importer.invalid_factory_lead_time',
    'quote_importer.invalid_lead_time',
    'quote_importer.invalid_moq',
    'quote_importer.invalid_mpq',
    'quote_importer.invalid_unit_of_measurement',
    'quote_importer.negative_factory_quantity',
    'quote_importer.negative_stock',
    'quote_importer.negative_unit_price',
    'quote_importer.no_supplier',
    'quote_importer.part_not_found',
    'quote_importer.suppliers_not_equal',
    'quote_importer.unsupported_part_type',
    'quote_request.contacts_with_different_suppliers',
    'quote_request.negotiation_line_items_not_found',
    'quote_request.invalid_data',
    'quote_request.missing_user',
    'quote_request.excel_generation_failure',
    'quote_request.cannot_send_in_current_status',
    'quote_request.no_email_recipients',
    'quote_request.multiple_emails_for_quote_request',
    'quote_request.not_found',
    'quote_request.multiple_quote_requests_not_sent_for_supplier',
    'quote_request.file_not_found',
    'rfq.cannot_create_customer_as_customer_user',
    'rfq.cannot_create_rfq_for_different_customer',
    'rfq.cannot_update_archived',
    'rfq.customer_does_not_exist',
    'rfq.customer_user_has_no_customer',
    'rfq.delete_rfq_with_sales_order_prohibited',
    'rfq.duplicate_assembly_names',
    'rfq.duplicate_order_sizes',
    'rfq.invalid_assembly_name',
    'rfq.invalid_customer_name',
    'rfq.invalid_name',
    'rfq.invalid_order_date',
    'rfq.invalid_order_size',
    'rfq.invalid_rfq_number',
    'rfq.invalid_volume_estimate_bounds',
    'rfq.new_customer_name_unavailable',
    'rfq.not_found',
    'rfq.request_in_draft_only_allowed_if_customer_portal_is_enabled',
    'rfq.unauthorized',
    'rfq.workflow_changes_only_allowed_by_ems',
    'self_signup_settings.contact_person_must_be_from_ems',
    'self_signup_settings.contact_person_not_found',
    'self_signup_settings.invalid_color_scheme',
    'self_signup_settings.invalid_organization_logo',
    'site.cannot_delete_default_inventory_site',
    'site.cannot_delete_inventory_site_with_linked_inventory_offers',
    'site.cannot_remove_default_setting_from_last_inventory_site',
    'site.invalid_default_site_setting',
    'site.must_have_at_least_one_tag',
    'site.name_already_exists',
    'site.site_number_already_exists',
    'solution_configuration.not_found',
    'solution_configuration.invalid_offers',
    'solution_configuration.invalid_scrap_quantity',
    'solution_configuration.no_design_items',
    'solution_configuration.no_part_options',
    'solution_configuration.solution_not_found',
    'sourcing_scenario.name_already_exists',
    'sourcing_scenario.not_found',
    'supplier_and_stock_location.already_exists',
    'supplier_contact.cannot_edit_invited_supplier_contact',
    'supplier_contact.email_already_exists_as_user',
    'supplier.name.already_exists',
    'supplier.name.empty',
    'supplier.not_found',
    'supplier.supplier_number.already_exists',
    'supplier.supplier_number.empty',
    'supplier_terms.already_exists',
    'supplier_terms.not_found',
    'supplier_terms.payment_due_days_constraint_violation',
    'total_cost_of_ownership_rule.absolute_cost_cannot_be_negative',
    'total_cost_of_ownership_rule.conditions_cannot_be_empty',
    'total_cost_of_ownership_rule.invalid_name',
    'award_scenario.name_conflict',
    'unauthorized',
    'unprocessable_entity',
    'unknown',
    'supplier_contact.contact_has_associated_user',
    'user.has_made_a_payment',
    'frontend_error.export.generic_error',
]);

export type ErrorCode = z.infer<typeof ErrorCodeRuntype>;
