import { t, Trans } from '@lingui/macro';
import { assertUnreachable, formatCurrency } from '@luminovo/commons';
import { chainComparators, compareByNumber } from '@luminovo/design-system';
import { HorizontalStackedBarChart, palette } from '@luminovo/viz';
import { useRfQ } from '../../../../resources/rfq/rfqHandler';
import { ChartSpec, defaultGroupingFunctions } from './ChartSpec';

type Keys =
    | 'discount'
    | 'baseMaterialCost'
    | 'aggregatedTcoCost'
    | 'scrapCost'
    | 'oneTimeCost'
    | 'excessMaterial'
    | 'consigned'
    | 'noOffers';
type Datum = {
    id: string[];
    label: string;
    discount: number;
    baseMaterialCost: number;
    aggregatedTcoCost: number;
    scrapCost: number;
    oneTimeCost: number;
    excessMaterial: number;
    totalPrice: number;
    consigned: number;
    noOffers: number;
};
export const chartSpecTotalPrice: ChartSpec<Keys, Datum> = {
    id: 'effectiveTotalPrice',
    title: <Trans>Total price by</Trans>,
    keys: [
        'discount',
        'baseMaterialCost',
        'aggregatedTcoCost',
        'scrapCost',
        'oneTimeCost',
        'excessMaterial',
        'consigned',
        'noOffers',
    ],
    aggregate(a, b) {
        let discount = a.discount + b.discount;
        let aggregatedTcoCost = a.aggregatedTcoCost + b.aggregatedTcoCost;

        if (Math.abs(discount) === Math.abs(aggregatedTcoCost)) {
            discount = 0;
            aggregatedTcoCost = 0;
        } else if (Math.abs(discount) > Math.abs(aggregatedTcoCost)) {
            discount = aggregatedTcoCost - discount;
            aggregatedTcoCost = 0;
        } else if (Math.abs(discount) < Math.abs(aggregatedTcoCost)) {
            discount = 0;
            aggregatedTcoCost = aggregatedTcoCost - discount;
        }

        return {
            id: a.id.concat(b.id),
            label: a.label,
            consigned: Math.max(a.consigned, b.consigned),
            discount,
            aggregatedTcoCost,
            baseMaterialCost: a.baseMaterialCost + b.baseMaterialCost,
            noOffers: Math.max(a.noOffers, b.noOffers),
            scrapCost: a.scrapCost + b.scrapCost,
            oneTimeCost: a.oneTimeCost + b.oneTimeCost,
            excessMaterial: a.excessMaterial + b.excessMaterial,
            totalPrice: a.totalPrice + b.totalPrice,
        };
    },
    groupBy: defaultGroupingFunctions,
    map(datum, extractLabel) {
        const id = [datum.id];
        const label = extractLabel(datum);

        const offerSummaryType = datum.offerSummary.type;
        switch (offerSummaryType) {
            case 'Consigned':
                return {
                    id,
                    label,
                    scrapCost: 0,
                    baseMaterialCost: 0,
                    discount: 0,
                    aggregatedTcoCost: 0,
                    oneTimeCost: 0,
                    excessMaterial: 0,
                    totalPrice: 0,
                    consigned: 1,
                    noOffers: 0,
                };
            case 'NoOffer':
                return {
                    id,
                    label,
                    scrapCost: 0,
                    baseMaterialCost: 0,
                    discount: 0,
                    aggregatedTcoCost: 0,
                    oneTimeCost: 0,
                    excessMaterial: 0,
                    totalPrice: 0,
                    consigned: 0,
                    noOffers: 1,
                };
            case 'Offer':
                const costSummary = datum.costSummary;
                const scrapCost = Number(costSummary.scrap_price?.preferred.amount ?? null);
                const totalPrice = Number(costSummary.total_price?.preferred.amount ?? null);
                const oneTimeCost = Number(costSummary.one_time_costs?.preferred.amount ?? null);
                const excessMaterial = Number(costSummary.excess_price?.preferred.amount ?? null);
                const baseMaterialCost = Number(costSummary.base_material_cost?.preferred.amount ?? null);

                let aggregatedTcoCost = Number(costSummary.tco_costs?.aggregated.cost?.preferred.amount ?? null);
                let discount = 0;
                if (aggregatedTcoCost < 0) {
                    discount = aggregatedTcoCost;
                    aggregatedTcoCost = 0;
                }

                return {
                    id,
                    label,
                    scrapCost,
                    baseMaterialCost,
                    discount,
                    aggregatedTcoCost,
                    oneTimeCost,
                    excessMaterial,
                    totalPrice,
                    consigned: 0,
                    noOffers: 0,
                };
            default:
                assertUnreachable(offerSummaryType);
        }
    },
    orderBy: chainComparators(
        compareByNumber((a) => -a.totalPrice),
        compareByNumber((d) => d.noOffers),
        compareByNumber((a) => a.consigned),
    ),
    render: ChartTotalPrice,
};

export function ChartTotalPrice({
    rfqId,
    data,
    keys,
    width = 800,
    onSelectDatum,
}: {
    rfqId: string;
    data: Datum[];
    keys: Keys[];
    width?: number;
    onSelectDatum(datum: Datum): void;
}) {
    const { data: rfq } = useRfQ(rfqId);
    const currency = rfq?.currency;

    if (!currency) {
        return null;
    }

    return (
        <HorizontalStackedBarChart
            keys={keys}
            data={data}
            isMissingData={(datum, key) => {
                if (key === 'noOffers' && datum.noOffers) {
                    return true;
                }
                if (key === 'consigned' && datum.consigned) {
                    return true;
                }
                return false;
            }}
            getColor={(key) => {
                if (key === 'noOffers') {
                    return palette.error.high;
                }
                if (key === 'consigned') {
                    return palette.ok.high;
                }
                if (key === 'baseMaterialCost') {
                    return palette.default[0];
                }
                if (key === 'discount') {
                    return palette.default[3];
                }
                if (key === 'aggregatedTcoCost') {
                    return palette.default[3];
                }
                if (key === 'scrapCost') {
                    return palette.default[2];
                }
                if (key === 'oneTimeCost') {
                    return palette.other[0];
                }
                if (key === 'excessMaterial') {
                    return palette.other[1];
                }
                assertUnreachable(key);
            }}
            formatValue={(x) => formatCurrency(x, currency)}
            formatKey={formatKey}
            onBarClick={onSelectDatum}
            width={width}
        />
    );
}

function formatKey(key: Keys) {
    if (key === 'scrapCost') {
        return t`Scrap`;
    }
    if (key === 'baseMaterialCost') {
        return t`Material cost`;
    }
    if (key === 'aggregatedTcoCost') {
        return t`TCO cost`;
    }
    if (key === 'discount') {
        return t`Discount`;
    }
    if (key === 'oneTimeCost') {
        return t`One-time cost`;
    }
    if (key === 'excessMaterial') {
        return t`Excess`;
    }
    if (key === 'consigned') {
        return t`Consigned`;
    }
    if (key === 'noOffers') {
        return t`No offers`;
    }
    assertUnreachable(key);
}
