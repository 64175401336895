import * as z from 'zod';
import { ApiIntegrationStatusRuntype } from './3rdPartyBackendTypes';

export const MasterElectronicsPriceTypeDTORuntype = z.enum(['ListPrice', 'ContractPrice']);

export interface MasterElectronicsCredentialsDTO extends z.infer<typeof MasterElectronicsCredentialsDTORuntype> {}
export const MasterElectronicsCredentialsDTORuntype = z.object({
    api_key: z.string(),
    price_type: MasterElectronicsPriceTypeDTORuntype,
});

export interface MasterElectronicsCredentialsInputDTO
    extends z.infer<typeof MasterElectronicsCredentialsInputDTORuntype> {}
export const MasterElectronicsCredentialsInputDTORuntype = z.object({
    api_key: z.string().optional(),
    price_type: MasterElectronicsPriceTypeDTORuntype.optional(),
});

export interface MasterElectronicsResponseBodyDTO extends z.infer<typeof MasterElectronicsResponseBodyDTORuntype> {}
export const MasterElectronicsResponseBodyDTORuntype = z.object({
    data: MasterElectronicsCredentialsDTORuntype.nullable(),
    status: ApiIntegrationStatusRuntype,
});
