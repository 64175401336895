import { t, Trans } from '@lingui/macro';
import { colorSystem, Flexbox, Text } from '@luminovo/design-system';
import { PCBFileTypes, PCBV2 } from '@luminovo/http-client';
import { Box } from '@mui/material';
import React from 'react';
import {
    Capability,
    STACK_UP_ADVANCED_VALUE_FIELDS,
    STACK_UP_VALUE_FIELDS,
} from '../../../../resources/pcb/pcbFunctions';
import { CollapsibleSection } from '../../components/CollapsibleSection';
import { PcbFormItem } from '../../components/PcbFormItem';
import { PcbFormToggleButton, PcbFormToggleButtonGroup } from '../../components/PcbFormToggleButton';
import { PdfExtractionMessageBox } from '../../components/PcbPdfExtraction/PcbExtractionMessage';
import { StackUpViewMode } from '../utils/types';
import { CustomStackUpForm } from './CustomStackUpForm';
import { DeletePcbFileModal } from './DeleteStackUpFileModal';
import { StackUpMessageBox } from './StackUpMessageBox';
import { StackupAdvancedFormSection } from './StackupAdvancedFormSection';

const usePcbCapabilitiesFormValues = (
    fields: Capability[],
): {
    stackUpValues: Capability[];
    advancedStackUpValues: Capability[];
} => {
    return React.useMemo(() => {
        const stackUpValues = [];
        const advancedStackUpValues = [];
        for (const capability of fields) {
            /* eslint-disable spellcheck/spell-checker */
            // Hacky solution
            // We skip numberOfPrepregs and numberOfLaminationCycles because we only want to show these fields
            // in the UI if the user "enables" them with the checkbox (hasPrepregs and hasLaminationCycles)
            //
            // These two (hasPrepregs and hasLaminationCycles) are not part of the stack-up model. They exist
            // only in the frontend to make the UI work.
            if (
                capability.capabilityName === 'numberOfPrepregs' ||
                capability.capabilityName === 'numberOfLaminationCycles'
            ) {
                continue;
            }
            /* eslint-enable spellcheck/spell-checker */

            if (STACK_UP_VALUE_FIELDS.includes(capability.capabilityName)) {
                stackUpValues.push(capability);
            } else if (STACK_UP_ADVANCED_VALUE_FIELDS.includes(capability.capabilityName)) {
                advancedStackUpValues.push(capability);
            }
        }
        return {
            stackUpValues,
            advancedStackUpValues,
        };
    }, [fields]);
};

export const StackUpForm = ({
    pcb,
    pcbCapabilities,
    extractedPcbCapabilities,
    isEditing,
    activeTab,
    setActiveTab,
}: {
    pcb: PCBV2;
    pcbCapabilities: Capability[];
    extractedPcbCapabilities: Capability[];
    isEditing: boolean;
    activeTab: StackUpViewMode;
    setActiveTab: React.Dispatch<React.SetStateAction<StackUpViewMode>>;
}) => {
    const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = React.useState<boolean>(false);

    // Used for the pdf extraction message
    const { advancedStackUpValues, stackUpValues } = usePcbCapabilitiesFormValues(pcbCapabilities);

    const extractedValue = React.useCallback(
        (capability: Capability) => {
            // @ts-ignore
            return pcb.properties[capability.section][capability.capabilityName];
        },
        [pcb],
    );

    const stackUpFiles = pcb.files?.filter((file) => file.fileType.fileType === PCBFileTypes.STACK_UP) ?? [];
    const hasStackUpFiles = stackUpFiles.length > 0;

    const handleTabChange = (value: StackUpViewMode | null) => {
        if (value === null) return;

        if (value === 'default' && hasStackUpFiles) {
            setIsConfirmationDialogOpen(true);
            return;
        }

        setActiveTab(value);
    };

    return (
        <Box
            style={{
                height: '100%',
                overflowX: 'hidden',
                overflowY: 'auto',
                position: 'relative',
                borderInlineEnd: `1px solid ${colorSystem.neutral[2]}`,
                padding: '24px 16px',
                backgroundColor: colorSystem.neutral[1],
            }}
        >
            <Flexbox flexDirection={'column'} paddingBottom={'48px'} gap={'16px'}>
                <Box
                    style={{
                        borderRadius: '8px',
                        backgroundColor: colorSystem.neutral.white,
                    }}
                >
                    <CollapsibleSection id={'stack-up-type-fields'} label={t`Stackup type`} isOpenByDefault>
                        {isEditing ? (
                            <PcbFormToggleButtonGroup
                                value={activeTab}
                                exclusive
                                defaultValue={'default'}
                                onChange={(_, value) => handleTabChange(value)}
                                size="small"
                                fullWidth
                                disabled={!isEditing}
                            >
                                <PcbFormToggleButton value="default">
                                    <Text variant="body-small">
                                        <Trans>Default stackup</Trans>
                                    </Text>
                                </PcbFormToggleButton>
                                <PcbFormToggleButton value="custom">
                                    <Text variant="body-small">
                                        <Trans>Custom stackup</Trans>
                                    </Text>
                                </PcbFormToggleButton>
                            </PcbFormToggleButtonGroup>
                        ) : (
                            <Text>{activeTab === 'default' ? t`Default stackup` : t`Custom stackup`}</Text>
                        )}
                    </CollapsibleSection>
                </Box>

                {activeTab === 'default' && (
                    <>
                        <PdfExtractionMessageBox capabilities={extractedPcbCapabilities} pcbId={pcb.id} />
                        <Box
                            style={{
                                borderRadius: '8px',
                                backgroundColor: colorSystem.neutral.white,
                            }}
                        >
                            <CollapsibleSection
                                id={'stack-up-fields'}
                                label={t`Parameters`}
                                isOpenByDefault={true}
                                shouldCollapseButtonBeShown={true}
                            >
                                <StackUpMessageBox
                                    title={t`Default values`}
                                    id="default-stack-up-announcement-box"
                                    text={t`We set some values by default. Check them before saving the specification.`}
                                />
                                <Box style={{ display: 'grid', gridTemplateColumns: '1fr', gap: 24, width: '100%' }}>
                                    {stackUpValues.map((capability, i) => (
                                        <PcbFormItem
                                            key={i}
                                            capability={capability}
                                            disabled={!isEditing}
                                            extractedValue={extractedValue(capability)}
                                        />
                                    ))}
                                </Box>
                            </CollapsibleSection>
                        </Box>

                        <StackupAdvancedFormSection
                            pcb={pcb}
                            pcbCapabilities={pcbCapabilities}
                            advancedStackUpValues={advancedStackUpValues}
                            isEditing={isEditing}
                            extractedValue={extractedValue}
                        />
                    </>
                )}
                {activeTab === 'custom' && <CustomStackUpForm pcb={pcb} isEditing={isEditing} />}
            </Flexbox>
            <DeletePcbFileModal
                title={t`Delete PCB specification`}
                text={t`Are you sure you want to delete the PCB specification?`}
                fileNames={stackUpFiles.map((file) => file.name)}
                pcbId={pcb.id}
                onDeleteConfirmation={() => {
                    setIsConfirmationDialogOpen(false);
                    setActiveTab('default');
                }}
                isConfirmationDialogOpen={isConfirmationDialogOpen}
                setIsConfirmationDialogOpen={setIsConfirmationDialogOpen}
            />
        </Box>
    );
};
