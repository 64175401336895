import { t } from '@lingui/macro';
import { ExpenseDTO, IdWithIndex } from '@luminovo/http-client';
import { SubmitHandler } from 'react-hook-form';
import { useDialogContext } from '../../../../../components/contexts/ModalContext';
import { useHttpQuery } from '../../../../../resources/http/useHttpQuery';
import { useMutationUpdateTemplate } from '../../../../../resources/manufacturingScenarioTemplates/manufacturingScenarioTemplatesHandler';
import { EntityFormState, EntitySelectionDialogForm } from '../../../shared/Entities/EntitySelectionDialogForm';
import { useExpenseColumns } from '../../../shared/Entities/columns';
import { createOrderedIdsFromSelectedIds } from '../../utils/createOrderedIds';

interface ExpenseSelectionDialogProps {
    orderedExpenseIds: IdWithIndex[];
    manufacturingScenarioTemplateId: string;
    closeDialog: () => void;
}

const ExpenseSelectionDialog = ({
    orderedExpenseIds,
    manufacturingScenarioTemplateId,
    closeDialog,
}: ExpenseSelectionDialogProps): JSX.Element => {
    const { allExpenses, inactiveExpenses } = useSelectableExpenses();
    const columns = useExpenseColumns();
    const onSelectionUpdate = useSelectionUpdate(manufacturingScenarioTemplateId, orderedExpenseIds, inactiveExpenses);
    const onSubmit: SubmitHandler<EntityFormState> = async (values: EntityFormState) => {
        await onSelectionUpdate(values.selectedIds);
        closeDialog();
    };
    const defaultValues: EntityFormState = {
        selectedIds: orderedExpenseIds.map((expense) => expense.id),
    };
    return (
        <EntitySelectionDialogForm
            title={t`Select expenses`}
            closeDialog={closeDialog}
            entities={allExpenses.filter((expense) => expense.status === 'Active')}
            columns={columns}
            persistenceId={`expense-selection-dialog-${manufacturingScenarioTemplateId}`}
            onSubmit={onSubmit}
            defaultValues={defaultValues}
            defaultSelectedRowIds={defaultValues.selectedIds}
        />
    );
};

const useSelectableExpenses = (): { allExpenses: ExpenseDTO[]; inactiveExpenses: ExpenseDTO[] } => {
    const { data, isLoading } = useHttpQuery('GET /expenses', { queryParams: {} });

    if (isLoading || !data) {
        return { allExpenses: [], inactiveExpenses: [] };
    }

    const allExpenses = data.data;
    const inactiveExpenses = data.data.filter((expense) => expense.status === 'Inactive');

    return { allExpenses, inactiveExpenses };
};

const useSelectionUpdate = (
    manufacturingScenarioTemplateId: string,
    orderedExpenseIds: IdWithIndex[],
    inactviteExpenses: ExpenseDTO[],
) => {
    const { mutateAsync } = useMutationUpdateTemplate({ templateId: manufacturingScenarioTemplateId });
    const inactiveExpenseIds = new Set(inactviteExpenses.map((expense) => expense.id));

    return async (selectedIds: string[]) => {
        const updatedOrderedExpenseIds = createOrderedIdsFromSelectedIds(orderedExpenseIds, selectedIds).filter(
            (expenseId) => !inactiveExpenseIds.has(expenseId.id),
        );

        await mutateAsync({
            ordered_expenses: updatedOrderedExpenseIds,
        });
    };
};

export const useExpenseSelectionDialog = ({
    orderedExpenseIds,
    manufacturingScenarioTemplateId,
}: {
    orderedExpenseIds: IdWithIndex[];
    manufacturingScenarioTemplateId: string;
}) => {
    const { setDialog, closeDialog } = useDialogContext();

    return {
        openDialog: () =>
            setDialog(
                <ExpenseSelectionDialog
                    orderedExpenseIds={orderedExpenseIds}
                    manufacturingScenarioTemplateId={manufacturingScenarioTemplateId}
                    closeDialog={closeDialog}
                />,
            ),
        closeDialog,
    };
};
