import { t, Trans } from '@lingui/macro';
import { Protected } from '@luminovo/auth';
import { formatDecimal, isPresent, transEnum } from '@luminovo/commons';
import {
    CenteredLayout,
    colorSystem,
    Flexbox,
    Link,
    RightBoxDrawer,
    SecondaryIconButton,
    Text,
    Tooltip,
} from '@luminovo/design-system';
import { CustomFullPart, CustomPartTypeEnum } from '@luminovo/http-client';
import { ComplianceStatusChip, customPartTypeTranslations } from '@luminovo/sourcing-core';
import { DescriptionOutlined, Edit } from '@mui/icons-material';
import { CircularProgress, Skeleton, Typography, TypographyProps } from '@mui/material';
import React from 'react';
import { DetailsLabel, DetailsSection, ValueText } from '../../../components/PartDetails/PartDetailsComponents';
import { useCustomPartEditDialog } from '../../../modules/PartLibrary/PartDetailsPage/components/CustomPartDialogs/useCustomPartEditDialog';
import { useCustomPart, useCustomPartResources } from '../../../resources/part/partHandler';
import { getFileNameFromResourcesString } from '../../../utils/stringFunctions';
import { CloseDrawerButton, useDrawerContext } from '../../contexts/ModalContext';
import { ErrorFallback } from '../../errorHandlers/ErrorBoundary';
import { PartCardLayout } from '../PartCardLayout';
import { DetailsButton } from '../PartSpecificationCardFields';
import { PcbDrawer } from './PcbDrawer';

interface CustomPartCardProps {
    partId: string;
    cardStyle?: React.CSSProperties;
    isTruncated?: boolean;
}

export function useCustomPartCardDrawer() {
    const { closeDrawer, isOpen, setDrawer } = useDrawerContext();

    return {
        closeDrawer,
        isOpen,
        openDrawer: ({ partId, canEditPart = true }: { partId: string; canEditPart?: boolean }) => {
            setDrawer(
                <RightBoxDrawer onClose={closeDrawer} FallbackComponent={ErrorFallback}>
                    <Flexbox flexDirection={'column'} style={{ width: '400px' }}>
                        <CustomPartCardDetails
                            partId={partId}
                            BackButton={() => <CloseDrawerButton disableMargin />}
                            canEditPart={canEditPart}
                        />
                    </Flexbox>
                </RightBoxDrawer>,
            );
        },
    };
}

const EditButton: React.FunctionComponent<{
    customPartId: string;
    canEditPart?: boolean;
}> = ({ customPartId, canEditPart = true }) => {
    const { openDialog } = useCustomPartEditDialog();

    return (
        <Protected requiredPermissions={['edit:custom:part']}>
            <Tooltip title={t`Edit part`} placement="top" arrow>
                <SecondaryIconButton size="small" onClick={() => openDialog({ customPartId })} disabled={!canEditPart}>
                    <Edit fontSize="inherit" />
                </SecondaryIconButton>
            </Tooltip>
        </Protected>
    );
};

export const CustomPartCardDetails: React.FunctionComponent<{
    partId: string;
    BackButton: React.ComponentType;
    canEditPart?: boolean;
}> = ({ partId, BackButton, canEditPart = true }): JSX.Element => {
    const { data: part } = useCustomPart(partId);
    const { data: resources = [], isLoading: isLoadingResources } = useCustomPartResources(partId);

    if (!isPresent(part)) {
        return (
            <CenteredLayout sx={{ minHeight: '400px' }}>
                <CircularProgress />
            </CenteredLayout>
        );
    }

    if (part.type.name === CustomPartTypeEnum.PCB && part.type.content) {
        return <PcbDrawer BackButton={BackButton} EditButton={EditButton} partId={partId} />;
    }

    return (
        <Flexbox flexDirection={'column'} gap={24} paddingX={'16px'} paddingY={'12px'}>
            <Flexbox justifyContent={'space-between'} alignItems={'center'}>
                <BackButton />
                <EditButton customPartId={part.id} canEditPart={canEditPart} />
            </Flexbox>

            <Flexbox flexDirection={'column'} gap={16}>
                <Text variant="h2" color={colorSystem.neutral[8]}>
                    <Trans>Custom part</Trans>
                </Text>
            </Flexbox>

            <DetailsSection label={t`Part information`}>
                <DetailsLabel label={t`Type`}>
                    <ValueText value={transEnum(part.type.name, customPartTypeTranslations)} />
                </DetailsLabel>
                <DetailsLabel label={t`RoHS compliance`}>
                    <ComplianceStatusChip status={part.rohs_compliant} />
                </DetailsLabel>
                <DetailsLabel label={t`REACH compliance`}>
                    <ComplianceStatusChip status={part.reach_compliant} />
                </DetailsLabel>
            </DetailsSection>

            <DetailsSection label={t`Files`}>
                {isLoadingResources ? (
                    <Skeleton />
                ) : (
                    <>
                        {resources.length === 0 && (
                            <Text color={colorSystem.neutral[7]}>
                                <Trans>No files added</Trans>
                            </Text>
                        )}
                        <Flexbox flexDirection={'column'} gap={8}>
                            {resources.map((resource, index) => (
                                <Link
                                    href={resource}
                                    download={resource}
                                    key={index}
                                    attention="high"
                                    startIcon={<DescriptionOutlined />}
                                >
                                    {getFileNameFromResourcesString(resource)}
                                </Link>
                            ))}
                        </Flexbox>
                    </>
                )}
            </DetailsSection>

            <DetailsSection label={t`Description`}>
                {part.description ? (
                    <CustomPartDescription part={part} />
                ) : (
                    <Text color={colorSystem.neutral[7]}>
                        <Trans>No description added</Trans>
                    </Text>
                )}
            </DetailsSection>

            <DetailsSection label={t`Part information`}>
                <DetailsLabel label={t`Mounting`}>
                    <ValueText value={part.package?.mounting} />
                </DetailsLabel>
                <DetailsLabel label={t`Package`}>
                    <ValueText value={part.package?.name} />
                </DetailsLabel>
                <DetailsLabel label={t`Number of pins`}>
                    <ValueText value={part.package?.number_of_pins} />
                </DetailsLabel>
            </DetailsSection>
        </Flexbox>
    );
};

const CustomPartDescription: React.FunctionComponent<{ part: CustomFullPart } & TypographyProps> = ({
    part,
    ...rest
}) => (
    <Typography {...rest}>
        {part.type.name === CustomPartTypeEnum.PCB && !part.description ? (
            <Trans>Automatically created</Trans>
        ) : (
            part.description
        )}
    </Typography>
);

export const CustomPartCard = ({ partId, cardStyle }: CustomPartCardProps): JSX.Element => {
    const { data: resources = [], isLoading: isLoadingResources } = useCustomPartResources(partId);
    const { data: part } = useCustomPart(partId);
    const { openDrawer } = useCustomPartCardDrawer();

    if (!isPresent(part)) {
        return (
            <CenteredLayout>
                <CircularProgress />
            </CenteredLayout>
        );
    }
    return (
        <PartCardLayout
            headerSection={
                <>
                    <Flexbox alignItems={'center'} gap={8}>
                        <Text variant="h5" color={colorSystem.neutral[5]}>
                            <Trans>Custom</Trans>
                        </Text>
                        <Text variant="h5">{transEnum(part.type.name, customPartTypeTranslations)}</Text>
                    </Flexbox>
                    <Flexbox gap={8} alignItems="center">
                        <Text variant="h5" color={colorSystem.neutral[5]}>
                            <Trans>Files</Trans>
                        </Text>
                        {isLoadingResources ? (
                            <Skeleton width={20} />
                        ) : (
                            <Typography variant="h5">{formatDecimal(resources.length)}</Typography>
                        )}
                    </Flexbox>
                </>
            }
            headerHover={<DetailsButton handleClick={() => openDrawer({ partId })} />}
            footerSection={<CustomPartDescription part={part} variant="subtitle1" color="textSecondary" noWrap />}
            cardStyle={cardStyle}
        />
    );
};
