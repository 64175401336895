import { assertPresent } from '@luminovo/commons';
import { CustomerSignupPostDTO, http } from '@luminovo/http-client';
import { useMutation } from '@tanstack/react-query';
import { getTenantId } from '../../../../permissions/tenants';
import { useDebugErrorHandler } from '../../../../resources/http/debugErrorHandler';
import { CustomerSignupFormState } from './types';

function convertToDto(form: CustomerSignupFormState): CustomerSignupPostDTO {
    const { type, data } = form;

    const user = {
        first_name: data.user.first_name,
        last_name: data.user.last_name,
        email: data.user.email,
        language: data.user.language,
    };

    const billing_address = {
        line1: data.customer.billing_address.line1,
        line2: data.customer.billing_address.line2,
        city: data.customer.billing_address.city,
        postal_code: data.customer.billing_address.postal_code,
        country_code: assertPresent(data.customer.billing_address.country_code),
    };

    const tenant = getTenantId();

    switch (type) {
        case 'Private':
            return {
                type,
                data: {
                    tenant,
                    user,
                    billing_address,
                },
            };
        case 'Business':
            return {
                type,
                data: {
                    tenant,
                    user,
                    billing_address,
                    company_name: data.customer.company_name,
                    vat_identification_number: data.customer.vat_identification_number,
                    vat_identification_type: assertPresent(data.customer.vat_identification_type),
                    commercial_register_number: data.customer.commercial_register_number,
                },
            };
    }
}

export function useMutationSignupCustomer({ onSuccess }: { onSuccess: () => void }) {
    const onError = useDebugErrorHandler();

    return useMutation({
        onError,
        mutationFn: (form: CustomerSignupFormState) => {
            const requestBody = convertToDto(form);
            return http(
                'POST /auth/customers/signup',
                { requestBody },
                // We send an empty token because this endpoint is not authenticated.
                '',
            );
        },
        onSuccess: () => {
            onSuccess();
        },
    });
}
