import { CustomPartOfferDTO, MonetaryValueBackend } from '@luminovo/http-client';

export function extractAccumulatedOneTimeCostsFromOffer({
    one_time_costs,
}: {
    one_time_costs: CustomPartOfferDTO['one_time_costs'];
}): MonetaryValueBackend | undefined {
    if (one_time_costs.length === 0) {
        return undefined;
    }

    const accumulatedOneTimeCosts = one_time_costs.reduce(
        (acc, curr) => {
            return {
                amount: acc.amount + Number(curr.price.amount),
                currency: acc.currency,
            };
        },
        { amount: 0, currency: one_time_costs[0].price.currency },
    );

    return {
        amount: accumulatedOneTimeCosts.amount.toString(),
        currency: accumulatedOneTimeCosts.currency,
    };
}
