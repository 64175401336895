import * as z from 'zod';
import { CalculationResultNullValue, DriverStatusWithDriverIdRuntype } from '../activityConfiguration';
import { CurrencyRuntype, MonetaryValueBackendRuntype } from '../backendTypes';
import { AggregatedAvailabilityRuntype } from '../offer';
export * from './calculationAssemblyDetailsBackendTypes';
export * from './calculationScenarioCombinationBackendTypes';

export const FixedCostRuntype = z.object({
    type: z.literal('Fixed'),
    data: MonetaryValueBackendRuntype,
});

export type FixedCostDTO = z.infer<typeof FixedCostRuntype>;

export const FractionCostRuntype = z.object({
    type: z.literal('Fraction'),
    data: z.string(),
});

export type FractionCostDTO = z.infer<typeof FractionCostRuntype>;

export const FixedFormulaRuntype = z.object({
    type: z.literal('Fixed'),
    data: z.object({
        script: z.string(),
        currency: CurrencyRuntype,
    }),
});
export type FixedFormulaDTO = z.infer<typeof FixedFormulaRuntype>;

export const FractionFormulaRuntype = z.object({
    type: z.literal('Fraction'),
    data: z.object({ script: z.string() }),
});
export type FractionFormulaDTO = z.infer<typeof FractionFormulaRuntype>;

export const NotOverwrittenFormulaRuntype = z.object({
    type: z.literal('Evaluated'),
    data: z.object({
        calculated: z.union([FixedFormulaRuntype, FractionFormulaRuntype]),
    }),
});

export const OverwrittenFormulaRuntype = z.object({
    type: z.literal('Overridden'),
    data: z.object({
        manually_overridden: z.string(),
        calculated: z.union([FixedFormulaRuntype, FractionFormulaRuntype]),
    }),
});

export const OverridableFormulaRuntype = z.union([NotOverwrittenFormulaRuntype, OverwrittenFormulaRuntype]);

const UnknownIdentifierRuntype = z.object({
    result: z.literal('UnknownIdentifier'),
    data: z.string(),
});

const ScriptErrorRuntype = z.object({
    result: z.literal('ScriptError'),
});
const ErrorResponseRuntype = z.union([UnknownIdentifierRuntype, ScriptErrorRuntype, CalculationResultNullValue]);

const OkResponseRuntype = z.object({
    result: z.literal('Ok'),
    data: z.string(),
});

const FormulaResponseRuntype = ErrorResponseRuntype.or(OkResponseRuntype);

const FormulaRuntype = z.object({
    formula: OverridableFormulaRuntype,
    statuses: z.array(DriverStatusWithDriverIdRuntype),
    result: FormulaResponseRuntype,
});

export type FormulaDTO = z.infer<typeof FormulaRuntype>;

const FormulaCostRuntype = z.object({
    type: z.literal('Formula'),
    data: FormulaRuntype,
});

export type FormulaCostDTO = z.infer<typeof FormulaCostRuntype>;

export type UnitCostDTO = FixedCostDTO | FractionCostDTO | FormulaCostDTO;

const CostCellRuntype = z.object({
    cost_specification: z.union([FixedCostRuntype, FractionCostRuntype, FormulaCostRuntype]),
    unit_cost_value: MonetaryValueBackendRuntype,
    unit_cost_fraction: z.string(),
    total_cost_value: MonetaryValueBackendRuntype,
    is_locked: z.boolean(),
});
export type CostCellDTO = z.infer<typeof CostCellRuntype>;

const ExtraCostRuntype = z.object({
    name: z.string(),
    cost: CostCellRuntype,
});
export type ExtraCostDTO = z.infer<typeof ExtraCostRuntype>;

export const CustomCostRuntype = z.object({
    extra_costs: z.array(ExtraCostRuntype),
    profit: CostCellRuntype.nullable(),
    discount: CostCellRuntype.nullable(),
});

export type CustomCostDTO = z.infer<typeof CustomCostRuntype>;

// GET Runtypes

export const FixedCostCellRuntype = z.object({
    unit_cost: MonetaryValueBackendRuntype,
    total_cost: MonetaryValueBackendRuntype,
});

const ManufacturingCostRuntype = z.object({
    cost: FixedCostCellRuntype,
    base_cost: FixedCostCellRuntype,
    custom_cost: CustomCostRuntype,
    price: FixedCostCellRuntype,
    sub_total_cost: FixedCostCellRuntype,
});

const MaterialCostRuntype = z.object({
    cost: FixedCostCellRuntype,
    base_cost: FixedCostCellRuntype,
    excess_material_cost: FixedCostCellRuntype,
    custom_cost: CustomCostRuntype,
    price: FixedCostCellRuntype,
    sub_total_cost: FixedCostCellRuntype,
});

export const AdditionalCostRuntype = z.object({
    profit: CostCellRuntype.nullable(),
    discount: CostCellRuntype.nullable(),
    other_costs: z.array(ExtraCostRuntype),
    post_profit_costs: z.array(ExtraCostRuntype),
    sub_total_cost: FixedCostCellRuntype,
});

export const ProjectEntityCostCellRuntype = z.object({
    id: z.string(),
    origin_id: z.string().nullable(),
    name: z.string(),
    rank: z.number(),
    cost: FixedCostCellRuntype,
});

export type ProjectEntityCostCellDTO = z.infer<typeof ProjectEntityCostCellRuntype>;

export const ProjectCostRuntype = z.object({
    cost: FixedCostCellRuntype,
    profit: CostCellRuntype.nullable(),
    price: FixedCostCellRuntype,
    activity_costs: z.array(ProjectEntityCostCellRuntype),
    expense_costs: z.array(ProjectEntityCostCellRuntype),
    one_time_costs: FixedCostCellRuntype.nullable(),
});

export const BatchCostRuntype = z.object({
    material_cost: MaterialCostRuntype,
    manufacturing_cost: ManufacturingCostRuntype,
    additional_cost: AdditionalCostRuntype,
    project_cost: ProjectCostRuntype,
    summarized_cost: z.object({
        total_price: FixedCostCellRuntype,
        total_cost: FixedCostCellRuntype,
        total_profit: FixedCostCellRuntype,
    }),
    checkout_cost: z.object({
        total_price: FixedCostCellRuntype,
        total_cost: FixedCostCellRuntype,
        total_profit: FixedCostCellRuntype,
    }),
});

const BatchRuntype = z.object({
    batch_size: z.number(),
    cost: BatchCostRuntype,
});

export type BatchDTO = z.infer<typeof BatchRuntype>;

export const ScenarioCostRuntype = z.object({
    sourcing_combination_id: z.string(),
    order_size: z.number(),
    sourcing_total_availability: AggregatedAvailabilityRuntype.nullable(),
    manufacturing_lead_time: z.number().nullable(),
    batch_size_costs: z.array(BatchRuntype),
});
export type ScenarioCostDTO = z.infer<typeof ScenarioCostRuntype>;

export const SourcingCombinationCostsRuntype = z.object({
    id: z.string(),
    calculation_assembly_details: z.string(),
    costs: z.array(ScenarioCostRuntype),
    created_at: z.string(),
    updated_at: z.string().nullable(),
});

// POST. POST is different from the GET request, because it can only contain some of the costs and not the total_costs

const FixedCostCellPostRuntype = z.object({
    unit_cost: MonetaryValueBackendRuntype,
});

export type FixedCostCellPostDTO = z.infer<typeof FixedCostCellPostRuntype>;

export const FormulaCostPostRuntype = z.object({
    type: z.literal('Formula'),
    data: OverridableFormulaRuntype,
});

export const CostCellPostRuntype = z.object({
    unit_cost: z.union([FixedCostRuntype, FractionCostRuntype, FormulaCostPostRuntype]),
    is_locked: z.boolean(),
});

type FormulaCostPostDTO = z.infer<typeof FormulaCostPostRuntype>;

export type UnitCostPostDTO = FixedCostDTO | FractionCostDTO | FormulaCostPostDTO;

export type CostCellPostDTO = z.infer<typeof CostCellPostRuntype>;

const ExtraCostPostRuntype = z.object({
    name: z.string(),
    cost: CostCellPostRuntype,
});
export const CustomCostPostRuntype = z.object({
    extra_costs: z.array(ExtraCostPostRuntype),
    profit: CostCellPostRuntype.nullable(),
    discount: CostCellPostRuntype.nullable(),
});

const ProjectCostPostRuntype = z.object({
    cost: FixedCostCellPostRuntype,
    profit: CostCellPostRuntype.nullable(),
    price: FixedCostCellPostRuntype,
});

export const AdditionalCostPostRuntype = z.object({
    profit: CostCellPostRuntype.nullable(),
    discount: CostCellPostRuntype.nullable(),
    other_costs: z.array(ExtraCostPostRuntype),
    post_profit_costs: z.array(ExtraCostPostRuntype),
});

const MaterialCostPostRuntype = z.object({
    custom_cost: CustomCostPostRuntype,
});

const ManufacturingCostPostRuntype = z.object({
    custom_cost: CustomCostPostRuntype,
});

export const BatchCostPostRuntype = z.object({
    material_cost: MaterialCostPostRuntype,
    manufacturing_cost: ManufacturingCostPostRuntype,
    additional_cost: AdditionalCostPostRuntype,
    project_cost: ProjectCostPostRuntype,
});

const BatchPostRuntype = z.object({
    batch_size: z.number(),
    cost: BatchCostPostRuntype,
});

export type BatchPostDTO = z.infer<typeof BatchPostRuntype>;

export const ScenarioCostPostRuntype = z.object({
    sourcing_combination_id: z.string(),
    batch_size_costs: z.array(BatchPostRuntype),
});

export type ScenarioCostPostDTO = z.infer<typeof ScenarioCostPostRuntype>;

export const SourcingCombinationCostsPostRuntype = z.object({
    costs: z.array(ScenarioCostPostRuntype),
});

const OrderCalculationRuntype = z.object({
    quantity: z.number(),
    sourcing_lead_time_availability: AggregatedAvailabilityRuntype.nullable(),
    manufacturing_lead_time: z.number().nullable(),
    total_price: MonetaryValueBackendRuntype,
    total_project_cost: MonetaryValueBackendRuntype,
    unit_price: MonetaryValueBackendRuntype,
});

const RfqExportCalculationPdfExportAssemblyRuntype = z.object({
    name: z.string(),
    order_calculations: z.array(OrderCalculationRuntype),
});

export type RfqExportCalculationPdfExportAssemblyDTO = z.infer<typeof RfqExportCalculationPdfExportAssemblyRuntype>;

export const RfqCalculationPDFExportResponseRuntype = z.object({
    rfq_name: z.string(),
    rfq_ems_internal_number: z.string().nullable(),
    assemblies: z.array(RfqExportCalculationPdfExportAssemblyRuntype),
});
