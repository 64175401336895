import { Box } from '@mui/material';
import * as React from 'react';
import { colorSystem } from '../../theme';
import { Flexbox } from '../Flexbox';
import { Text } from '../Text';

type Variant = 'vertical' | 'side-by-side';

export function PropertiesTable({
    variant = 'vertical',
    properties,
}: {
    variant?: Variant;
    properties: Record<string, undefined | string | JSX.Element>;
}): JSX.Element {
    const entries = Object.entries(properties).filter(([, value]) => value !== undefined);

    if (variant === 'side-by-side') {
        return (
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateColumns: 'auto 1fr',
                    border: `1px solid ${colorSystem.neutral[2]}`,
                    borderRadius: '4px',
                }}
            >
                {entries.map(([label, value], index) => (
                    <React.Fragment key={label}>
                        <Text
                            style={{
                                backgroundColor: index % 2 === 1 ? colorSystem.neutral[1] : 'transparent',
                                padding: '4px 8px',
                            }}
                        >
                            {label}
                        </Text>
                        <Text
                            style={{
                                backgroundColor: index % 2 === 1 ? colorSystem.neutral[1] : 'transparent',
                                padding: '4px 8px',
                            }}
                        >
                            {value}
                        </Text>
                    </React.Fragment>
                ))}
            </Box>
        );
    }

    return (
        <Flexbox flexDirection="column" gap="20px">
            {entries.map(([label, value]) => (
                <PropertyLabel key={label} label={label} value={value} />
            ))}
        </Flexbox>
    );
}

function PropertyLabel({ label, value }: { label: string; value?: string | JSX.Element }) {
    return (
        <Flexbox flexDirection="column" gap="4px">
            <Text variant="body-small" color={colorSystem.neutral[8]}>
                {label}
            </Text>
            <Text variant="h4" color={colorSystem.neutral[9]}>
                {value}
            </Text>
        </Flexbox>
    );
}
