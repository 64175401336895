import { plural, t } from '@lingui/macro';
import { formatDecimal, groupBy, isPresent, maxBy, transEnum, uniq } from '@luminovo/commons';
import {
    colorSystem,
    createColumnHelper,
    DestructiveSecondaryIconButton,
    Flexbox,
    SupportedFilterFn,
    Tag,
    TagGroup,
    Text,
    Tooltip,
    useTanStackTable,
} from '@luminovo/design-system';
import {
    CustomPartTypeEnum,
    GenericPartTypes,
    PartLite,
    PartLiteTypes,
    PartOptionRequestSummaryDTO,
    QuoteRequestDTO,
    QuoteRequestLineItemStatus,
    QuoteRequestLineItemSummaryDTO,
    QuoteRequestStatus,
} from '@luminovo/http-client';
import { formatSupplierDTO } from '@luminovo/sourcing-core';
import { Delete } from '@mui/icons-material';
import React from 'react';
import { useSuspenseHttpQuery } from '../../../../../resources/http/useHttpQuery';
import { useHttpMutation } from '../../../../../resources/mutation/useHttpMutation';
import { QuoteRequestStatusChip } from '../../../../Negotiations/components/QuoteRequestStatusChip';
import {
    quoteRequestLineItemAssignmentReasonTranslations,
    quoteRequestStatusTranslations,
} from '../../../../Negotiations/i18n';

type RowItem = {
    type: 'row';
    manufacturer: { id: string; name: string };
    items: PartOptionRequestSummaryDTO[];
    subRows: SubRowItem[];
};

type SubRowItem = {
    type: 'subRow';
    manufacturer: { id: string; name: string };
    quoteRequest: QuoteRequestDTO;
    quoteRequestLineItems: QuoteRequestLineItemSummaryDTO[];
};

export function extractManufacturer(part: PartLite | undefined | null): { id: string; name: string } {
    if (!isPresent(part)) {
        return { id: 'unknown', name: t`Unknown` };
    }

    switch (part.kind) {
        case PartLiteTypes.OffTheShelf:
            return part.manufacturer;

        case PartLiteTypes.Generic:
            switch (part.content.type) {
                case GenericPartTypes.Capacitor:
                    return { id: 'generic-capacitor', name: t`Generic Capacitor` };
                case GenericPartTypes.Resistor:
                    return { id: 'generic-resistor', name: t`Generic Resistor` };
                default:
                    return { id: 'generic-unknown', name: t`Generic Unknown` };
            }
        case PartLiteTypes.RawSpecification:
            if (isPresent(part.manufacturer.id) && isPresent(part.manufacturer.name)) {
                return { id: part.manufacturer.id, name: part.manufacturer.name };
            }
            if (isPresent(part.manufacturer.name)) {
                return { id: 'unknown-part.manufacturer.name', name: part.manufacturer.name };
            }
            return { id: 'unknown', name: t`Unknown` };
        case PartLiteTypes.Ipn:
            return { id: 'ipn', name: t`IPNs` };
        case PartLiteTypes.Custom:
            switch (part.type.name) {
                case CustomPartTypeEnum.PCB:
                    return { id: 'custom-pcb', name: t`PCB` };
                case CustomPartTypeEnum.Mechanical:
                    return { id: 'custom-mechanical', name: t`Custom Mechanical` };
                case CustomPartTypeEnum.Plastic:
                    return { id: 'custom-plastic', name: t`Custom Plastic` };
                case CustomPartTypeEnum.Cable:
                    return { id: 'custom-cable', name: t`Custom Cable` };
                case CustomPartTypeEnum.Packaging:
                    return { id: 'custom-packaging', name: t`Custom Packaging` };
                case CustomPartTypeEnum.Label:
                    return { id: 'custom-label', name: t`Custom Label` };
                case CustomPartTypeEnum.Other:
                    return { id: 'custom-other', name: t`Custom Other` };
                default:
                    return { id: 'custom', name: t`Custom` };
            }
        case PartLiteTypes.CustomComponent:
            return { id: 'custom-component', name: t`Custom Component` };
        case PartLiteTypes.Unknown:
            return { id: 'unknown', name: t`Unknown` };
        default:
            return { id: 'unknown', name: t`Unknown` };
    }
}

function extractSubRows(items: PartOptionRequestSummaryDTO[], quoteRequests: QuoteRequestDTO[]): SubRowItem[] {
    const quoteRequestIds = uniq(
        items.flatMap((item) => item.quote_request_line_items.map((lineItem) => lineItem.quote_request_id)),
    );

    return quoteRequestIds.flatMap((id) => {
        const quoteRequest = quoteRequests.find((qr) => qr.id === id);

        if (!isPresent(quoteRequest)) {
            return [];
        }

        return [
            {
                type: 'subRow',
                quoteRequest,
                manufacturer: extractManufacturer(items[0].part),
                quoteRequestLineItems: items
                    .flatMap((item) => item.quote_request_line_items)
                    .filter((lineItem) => lineItem.quote_request_id === id),
            },
        ];
    });
}

const columnHelper = createColumnHelper<RowItem | SubRowItem>();

const columns = [
    columnHelper.text((row) => (row.type === 'row' ? row.manufacturer.name : row.manufacturer.name), {
        id: 'manufacturer',
        label: () => t`Manufacturer`,
        size: 200,
        cell: ({ row }) => {
            switch (row.original.type) {
                case 'row':
                    return <Text>{row.original.manufacturer.name}</Text>;
                case 'subRow':
                    return <></>;
            }
        },
    }),

    columnHelper.number(
        (row) =>
            row.type === 'row'
                ? (maxBy(row.subRows, (subRow) => subRow.quoteRequestLineItems.length)?.quoteRequestLineItems.length ??
                  0)
                : row.quoteRequestLineItems.length,
        {
            id: 'partOptions',
            label: () => t`# Part options`,
            size: 180,
            cell: (item) => formatDecimal(item.getValue()),
        },
    ),

    columnHelper.enum(
        (row) =>
            row.type === 'row'
                ? row.subRows.length === 0
                    ? t`No suppliers`
                    : ''
                : formatSupplierDTO(row.quoteRequest.supplier),
        {
            id: 'suppliers',
            label: () => t`Suppliers`,
            size: 200,
            getOptionLabel: (opt) => opt,
            cell: ({ row }) => {
                switch (row.original.type) {
                    case 'row':
                        if (row.original.subRows.length === 0) {
                            return <Text variant="inherit" color={colorSystem.yellow[8]}>{t`No suppliers`}</Text>;
                        }

                        return plural(row.original.subRows.length, {
                            one: `${row.original.subRows.length} supplier`,
                            other: `${row.original.subRows.length} suppliers`,
                        });
                    case 'subRow':
                        if (row.original.quoteRequest.status === QuoteRequestStatus.NotSent) {
                            return (
                                <Text variant="inherit">{formatSupplierDTO(row.original.quoteRequest.supplier)}</Text>
                            );
                        } else {
                            return (
                                <Text variant="inherit" color={colorSystem.neutral[6]}>
                                    {formatSupplierDTO(row.original.quoteRequest.supplier)}
                                </Text>
                            );
                        }
                }
            },
            quickFilters: [
                {
                    label: () => t`No suppliers`,
                    value: { filterFn: SupportedFilterFn.equalsAny, value: ['No suppliers'] },
                    showCount: true,
                },
            ],
        },
    ),
    columnHelper.array((row) => (row.type === 'row' ? [] : [row.quoteRequest.status]), {
        id: 'status',
        label: () => t`Status`,
        size: 140,
        getOptionLabel: (status) => transEnum(status, quoteRequestStatusTranslations),
        cell: ({ row }) => {
            switch (row.original.type) {
                case 'row':
                    return <></>;
                case 'subRow':
                    return (
                        <Flexbox gap={4}>
                            <Tag attention="low" color={'neutral'} label={`#${row.original.quoteRequest.number}`} />
                            <QuoteRequestStatusChip status={row.original.quoteRequest.status} />
                        </Flexbox>
                    );
            }
        },
    }),
    columnHelper.array(
        (row) => (row.type === 'row' ? [] : row.quoteRequestLineItems.map((item) => item.assignment_reason)),
        {
            id: 'assignmentReason',
            label: () => t`Assignment reason`,
            size: 170,
            getOptionLabel: (status) => transEnum(status, quoteRequestLineItemAssignmentReasonTranslations),
            cell: ({ row }) => {
                switch (row.original.type) {
                    case 'row':
                        return <></>;
                    case 'subRow':
                        return (
                            <TagGroup
                                color="blue"
                                options={uniq(row.original.quoteRequestLineItems.map((item) => item.assignment_reason))}
                                getOptionLabel={(reason) =>
                                    transEnum(reason, quoteRequestLineItemAssignmentReasonTranslations)
                                }
                                limit={1}
                            />
                        );
                }
            },
        },
    ),
    columnHelper.action({
        id: 'actions',
        size: 80,
        cell: function Cell({ row }) {
            const { original } = row;
            const { mutateAsync, isPending: isLoading } = useHttpMutation('POST /quote-request/line-item/delete', {
                snackbarMessage: t`Deleted quote request line items`,
            });

            if (original.type !== 'subRow') {
                return null;
            }

            return (
                <Tooltip title={t`Remove quote request line items`}>
                    <DestructiveSecondaryIconButton
                        disabled={
                            original.quoteRequestLineItems.every(
                                (item) => item.status !== QuoteRequestLineItemStatus.NotSent,
                            ) || isLoading
                        }
                        onClick={() =>
                            mutateAsync({ requestBody: { ids: original.quoteRequestLineItems.map((item) => item.id) } })
                        }
                        size="small"
                    >
                        <Delete fontSize="inherit" />
                    </DestructiveSecondaryIconButton>
                </Tooltip>
            );
        },
    }),
];

function useManufacturersTableData(
    negotiationId: number,
    negotiationLineItemIds: number[],
): Array<RowItem | SubRowItem> | undefined {
    const { data: quoteRequests } = useSuspenseHttpQuery(
        'POST /quote-request/find',
        {
            requestBody: { negotiation_id: negotiationId },
        },
        {
            select: (data) => data.items,
        },
    );

    const { data } = useSuspenseHttpQuery('POST /negotiation/line-items/part-options-with-quote-request-line-items', {
        requestBody: {
            negotiation_line_item_ids: negotiationLineItemIds,
        },
    });

    return React.useMemo(() => {
        const filteredData = data.filter(
            (item) => item.part.kind !== PartLiteTypes.Custom && item.part.kind !== PartLiteTypes.CustomComponent,
        );
        const groupedManufacturers = groupBy(filteredData, (item) => extractManufacturer(item.part).id);

        return Object.entries(groupedManufacturers).map(([manufacturer, items]) => ({
            type: 'row',
            manufacturer: extractManufacturer(items[0].part),
            items,
            subRows: extractSubRows(items, quoteRequests),
        }));
    }, [data, quoteRequests]);
}

export function useManufacturersTable(negotiationId: number, negotiationLineItemIds: number[]) {
    const data = useManufacturersTableData(negotiationId, negotiationLineItemIds);

    const rowSelection = React.useMemo(
        () =>
            data?.reduce(
                (acc, row) => {
                    if (row.type === 'row') {
                        acc[row.manufacturer.id] = true;
                    }
                    return acc;
                },
                {} as Record<string, boolean>,
            ) ?? {},
        [data],
    );

    const { table } = useTanStackTable({
        data,
        columns,
        filterFromLeafRows: true,
        enableSelection: {
            enabled: true,
            getRowId: (row) => (row.type === 'row' ? String(row.manufacturer.id) : ''),
        },
        getSubRows: (row) => (row.type === 'row' ? row.subRows : []),
        initialState: { rowSelection },
    });

    return { table, isLoading: !isPresent(data) };
}
