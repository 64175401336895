import * as z from 'zod';

export enum CostedBomColumn {
    Designator = 'Designator',
    Quantity = 'Quantity',
    ApprovedParts = 'ApprovedParts',
    PendingParts = 'PendingParts',
    ManufacturerFree = 'ManufacturerFree',
    InternalPartNumber = 'InternalPartNumber',
    Manufacturer = 'Manufacturer',
    Mpn = 'Mpn',
    PartType = 'PartType',
    Description = 'Description',
    Package = 'Package',
    Mounting = 'Mounting',
    Pins = 'Pins',
    PartWarnings = 'PartWarnings',
    AggregatedQuantity = 'AggregatedQuantity',
    ScrapQuantity = 'ScrapQuantity',
    PurchaseQuantity = 'PurchaseQuantity',
    ExcessMaterialQuantity = 'ExcessMaterialQuantity',
    Consignment = 'Consignment',
    Supplier = 'Supplier',
    SupplierNumber = 'SupplierNumber',
    SupplierPartNumber = 'SupplierPartNumber',
    CustomerPartNumber = 'CustomerPartNumber',
    UnitPrice = 'UnitPrice',
    LineValue = 'LineValue',
    UnitPriceOriginalCurrency = 'UnitPriceOriginalCurrency',
    OneTimeCosts = 'OneTimeCosts',
    OneTimeCostsOriginalCurrency = 'OneTimeCostsOriginalCurrency',
    PurchasePrice = 'PurchasePrice',
    OriginalPurchasePrice = 'OriginalPurchasePrice',
    ExcessMaterialCosts = 'ExcessMaterialCosts',
    OriginalCurrency = 'OriginalCurrency',
    CurrencyExchangeRate = 'CurrencyExchangeRate',
    LeadTimeDays = 'LeadTimeDays',
    Stock = 'Stock',
    OnOrder = 'OnOrder',
    StdFactoryLeadTimeDays = 'StdFactoryLeadTimeDays',
    FactoryQuantity = 'FactoryQuantity',
    Packaging = 'Packaging',
    Moq = 'Moq',
    Mpq = 'Mpq',
    OfferNumber = 'OfferNumber',
    OfferNotes = 'OfferNotes',
    SolutionWarnings = 'SolutionWarnings',
    PriceType = 'PriceType',
    Validity = 'Validity',
    Origin = 'Origin',
    Notes = 'Notes',
    SourcingNotes = 'SourcingNotes',
    LastUpdated = 'LastUpdated',
    Ncnr = 'Ncnr',
    CountryOfOrigin = 'CountryOfOrigin',
    Eccn = 'Eccn',
    HtsCode = 'HtsCode',
    ChinaRohs = 'ChinaRohs',
    Prop65 = 'Prop65',
    ConflictMineralStatus = 'ConflictMineralStatus',
    ReachCompliant = 'ReachCompliant',
    RohsCompliant = 'RohsCompliant',
    RohsVersion = 'RohsVersion',
    LatestDatasheetUrl = 'LatestDatasheetUrl',
    LifecycleYTEOL = 'LifecycleYTEOL',
    LifecycleYTEOLRange = 'LifecycleYTEOLRange',
    LastBuyDate = 'LastBuyDate',
    LastDeliveryDate = 'LastDeliveryDate',
    SvhcCasNumber = 'SvhcCasNumber',
    OriginSetMethod = 'OriginSetMethod',
    BomIpn = 'BomIpn',
    BomCpn = 'BomCpn',
    BomMpn = 'BomMpn',
    AssemblyQuantity = 'AssemblyQuantity',
    AssemblyExcessMaterialQuantity = 'AssemblyExcessMaterialQuantity',
    AssemblyDesignator = 'AssemblyDesignator',
    PartEmissionData = 'PartEmissionData',
    LifecycleStatus = 'LifecycleStatus',
    UnitOfMeasurementQuantity = 'UnitOfMeasurementQuantity',
    UnitOfMeasurementUnit = 'UnitOfMeasurementUnit',
    ItemClass = 'ItemClass',
    CancellationWindowInDays = 'CancellationWindowInDays',
    TcoCostShipping = 'TcoCostShipping',
    TcoCostDiscount = 'TcoCostDiscount',
    TcoCostPackaging = 'TcoCostPackaging',
    TcoCostCustoms = 'TcoCostCustoms',
    TcoCostOther = 'TcoCostOther',
    TcoCostTotal = 'TcoCostTotal',
    TcoUnitPrice = 'TcoUnitPrice',
    EuPopCompliant = 'EuPopCompliant',
    EuPopCasNumbers = 'EuPopCasNumbers',
    PfasCompliant = 'PfasCompliant',
    PfasCasNumbers = 'PfasCasNumbers',
}

export const CostedBomExportTemplateRuntype = z.object({
    id: z.string(),
    name: z.string(),
    description: z.string().nullable(),
    is_default: z.boolean(),
    columns: z.array(z.nativeEnum(CostedBomColumn)),
    last_modified: z.string(),
    include_summary_sheet: z.boolean(),
});

export type CostedBomExportTemplate = z.infer<typeof CostedBomExportTemplateRuntype>;

export const CostedBomExportTemplateInputRuntype = z.object({
    name: z.string(),
    description: z.string().nullable(),
    is_default: z.boolean(),
    included_columns: z.array(z.nativeEnum(CostedBomColumn)),
    include_summary_sheet: z.boolean(),
});
