import * as z from 'zod';
import { endpoint } from '../http/endpoint';

export const quotationEndpoints = {
    'GET /rfqs/:rfqId/quotation': endpoint({
        description: 'Returns the quotation file of a given rfq',
        pathParams: z.object({ rfqId: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({
            data: z
                .object({
                    url: z.string(),
                })
                .nullable(),
        }),
    }),
    'GET /rfqs/:rfqId/quotation/upload': endpoint({
        description: 'Returns an Azure url to upload the quotation file given an rfq',
        pathParams: z.object({ rfqId: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({
            data: z.object({
                url: z.string(),
            }),
        }),
        invalidates: ['GET /rfqs/:rfqId/quotation'],
    }),
    'DELETE /rfqs/:rfqId/quotation': endpoint({
        description: 'Deletes the quotation file given its file name and rfqId',
        pathParams: z.object({ rfqId: z.string() }),
        queryParams: z.object({ blob_name: z.string() }),
        requestBody: z.undefined(),
        responseBody: z.string(),
        removes: ['GET /rfqs/:rfqId/quotation'],
    }),
};
