import { t, Trans } from '@lingui/macro';
import {
    colorSystem,
    Dialog,
    DialogContent,
    DialogTitle,
    Flexbox,
    SecondaryButton,
    Text,
} from '@luminovo/design-system';
import { InfoOutlined as InfoIcon } from '@mui/icons-material';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { FormContainer } from '../../../../components/formLayouts/FormContainer';
import { SubmitButton } from '../../../../components/formLayouts/SubmitButton';
import { CalculationTemplateSelectionInput } from '../../../Manufacturing/AssemblyOverview/AssemblyOverviewCard';

export type CalculationAssemblyTemplateFormInput = { calculationTemplateId: string | undefined };

const CalculationTemplateSelectionForm = ({
    submitButtonText,
    onClose,
}: {
    submitButtonText: string;
    onClose: () => void;
}) => {
    const { watch, setValue } = useFormContext<CalculationAssemblyTemplateFormInput>();

    const setSelectedTemplate = (templateId: string | undefined) => {
        setValue('calculationTemplateId', templateId, { shouldDirty: true });
    };
    const selectedTemplateId = watch(`calculationTemplateId`);
    return (
        <>
            <CalculationTemplateSelectionInput
                onSelectTemplateId={setSelectedTemplate}
                templateId={selectedTemplateId}
            />

            <Flexbox justifyContent={'space-between'} marginY={'24px'} width={'100%'}>
                <SecondaryButton onClick={onClose}>
                    <Trans>Cancel</Trans>
                </SecondaryButton>
                <SubmitButton disabled={selectedTemplateId === undefined} label={submitButtonText} />
            </Flexbox>
        </>
    );
};

export const SwitchOrApplyCalculationAssemblyTemplateDialog = ({
    calculationTemplateId,
    onSubmit,
    isDialogShown,
    setIsDialogShown,
}: {
    calculationTemplateId: string | undefined;
    onSubmit: (form: CalculationAssemblyTemplateFormInput) => Promise<void>;
    isDialogShown: boolean;
    setIsDialogShown: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    const isCalculationTemplateApplied = calculationTemplateId !== undefined;
    const handleClose = () => {
        setIsDialogShown(false);
    };

    const handleSubmit = async (form: CalculationAssemblyTemplateFormInput) => {
        await onSubmit(form);
        handleClose();
    };

    const defaultValues: CalculationAssemblyTemplateFormInput = {
        calculationTemplateId,
    };

    return (
        <>
            <Dialog open={isDialogShown} fullWidth maxWidth={'md'}>
                <DialogTitle
                    title={isCalculationTemplateApplied ? t`Switch template` : t`Apply template`}
                    onClose={handleClose}
                />
                <FormContainer defaultValues={defaultValues} onSubmit={handleSubmit}>
                    <DialogContent>
                        <Flexbox gap={'16px'} flexDirection={'column'}>
                            {isCalculationTemplateApplied && (
                                <Flexbox alignItems={'center'} gap={'4px'}>
                                    <InfoIcon fontSize={'small'} style={{ color: colorSystem.neutral[6] }} />
                                    <Text variant={'body'}>
                                        <Trans>
                                            Switching the template will reset your current calculation to the new
                                            template.
                                        </Trans>
                                    </Text>
                                </Flexbox>
                            )}

                            <CalculationTemplateSelectionForm
                                onClose={handleClose}
                                submitButtonText={
                                    isCalculationTemplateApplied ? t`Switch and reload` : t`Apply and reload`
                                }
                            />
                        </Flexbox>
                    </DialogContent>
                </FormContainer>
            </Dialog>
        </>
    );
};
