import { t } from '@lingui/macro';
import { HttpError } from '@luminovo/http-client';
import { createIdentityImportHandlers } from '../../../model/util';
import { ImportBatchHandler, ImportHandlersCreator, ImportRecord, ImportStatus, ImporterConfig } from '../../../types';

/**
 * This is a helper function that can be used to import records using individual mutations.
 *
 * This is not recommended for large imports, as it will make a request for each record.
 */
function onImportUsingIndividualMutations<TConfig extends ImporterConfig>({
    updateRecord,
    insertRecord,
    formatError,
}: {
    updateRecord: (record: ImportRecord<TConfig>) => Promise<unknown>;
    insertRecord: (record: ImportRecord<TConfig>) => Promise<unknown>;
    formatError(httpError: HttpError): string;
}): ImportBatchHandler<TConfig> {
    return async (records) => {
        const result: ImportStatus[] = [];

        for await (const record of records) {
            if (record.action === 'insert') {
                const newStatus: ImportStatus = await insertRecord(record)
                    .then((): ImportStatus => {
                        return { success: true };
                    })
                    .catch((error): ImportStatus => {
                        if (error instanceof HttpError) {
                            return { success: false, message: formatError(error) };
                        } else {
                            return { success: false, message: t`Failed to import: ` + String(error) };
                        }
                    });
                result.push(newStatus);
            } else if (record.action === 'update') {
                const newStatus: ImportStatus = await updateRecord(record)
                    .then((): ImportStatus => {
                        return { success: true };
                    })
                    .catch((error): ImportStatus => {
                        if (error instanceof HttpError) {
                            return { success: false, message: formatError(error) };
                        } else {
                            return { success: false, message: t`Failed to import: ` + String(error) };
                        }
                    });
                result.push(newStatus);
            } else if (record.action === 'skipped') {
                result.push({ success: true });
            }
        }

        return result;
    };
}

/**
 * This is a helper function that can be used to import records using individual mutations.
 *
 * This is not recommended for large imports, as it will make a request for each record.
 */
export function createIndividualMutationsImportHandlers<TConfig extends ImporterConfig>(params: {
    updateRecord: (record: ImportRecord<TConfig>) => Promise<unknown>;
    insertRecord: (record: ImportRecord<TConfig>) => Promise<unknown>;
    formatError(httpError: HttpError): string;
}): ImportHandlersCreator<TConfig, ImportRecord<TConfig>> {
    return createIdentityImportHandlers(onImportUsingIndividualMutations(params));
}
