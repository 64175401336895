import { ErrorCode, ErrorCodeRuntype } from '../errorCodes';
import { composeRuntypeAssertionErrorMessage } from './composeRuntypeAssertionErrorMessage';
import { logToExternalErrorHandlers } from './logToExternalErrorHandlers';
import { parseEndpoint } from './parseEndpoint';

/**
 * Returns the received error code if it is known, otherwise returns 'unknown'.
 * If the received error code does not conform to the type specification and an runtype error is being logged.
 */
export async function validateErrorCode(response: Response, endpoint: string): Promise<{ code: ErrorCode }> {
    const unchecked = await response
        .json()
        .then((body) => {
            if (typeof body.code === 'string') {
                return { code: body.code, context: body.data };
            }

            return { code: 'unknown' };
        })
        .catch(() => ({ code: 'unknown', context: undefined }));

    try {
        return { code: ErrorCodeRuntype.parse(unchecked?.code) };
    } catch (runtypeError) {
        const url = response.url;
        const { method } = parseEndpoint(endpoint);
        const responseBody = await response.json().catch(() => undefined);
        const msg = composeRuntypeAssertionErrorMessage({
            endpoint,
            url,
            method,
            requestBody: undefined,
            responseBody,
            runtypeError,
        });
        const ourError = new Error(msg);
        // Throwing a runtype error is not necessary because the failed request will always throw an HttpError.
        logToExternalErrorHandlers(ourError, { extra: { endpoint }, fingerprint: ['RUNTYPE_ERROR', endpoint] });
        return { code: 'unknown' };
    }
}
