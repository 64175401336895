import { get } from '@luminovo/commons';
import { PCBV2Properties } from '@luminovo/http-client';
import { FieldNamesMarkedBoolean } from 'react-hook-form';
import { PanelFormState, ScopeType } from '../../../modules/Pcb/PanelizationTab/utils/types';
import { analytics } from '../../../utils/analytics';
import { PCBAnalyticCommonData, PCBUpdateAnalytic, TrackField } from './pcbAnalyticTypes';

//TODO: Move this to common file
const PCB_UPLOADED_EVENT = 'pcb_uploaded';
const PCB_SPECIFICATION_UPDATED_EVENT = 'update_pcb_specification';
const PCB_DELETE_EVENT = 'delete_pcb';
const PCB_DOWNLOAD_FILES_EVENT = 'download_pcb_files';
const PCB_DOWNLOAD_PDF_EVENT = 'download_pcb_pdf';
const PCB_TAB_CHANGE = 'change_pcb_tab';

// Utils
export const convertDataForPcbAnalytics = (data: PCBAnalyticCommonData) => ({
    pcb_id: data.pcbId,
    rfq_id: data.rfqId,
    assembly_uuid: data.assemblyId,
});

// PCB specification analytics
const TRACKED_FIELD_LIST: Array<TrackField> = [
    {
        fieldPath: 'board.boardHeight',
        name: 'Board height',
    },
    {
        fieldPath: 'board.boardWidth',
        name: 'Board Width ',
    },
    {
        fieldPath: 'layerStack.layerstackType',
        name: 'Layerstack type',
    },
    {
        // eslint-disable-next-line spellcheck/spell-checker
        fieldPath: 'layerStack.layercount',
        name: 'Number of layers',
    },
    {
        fieldPath: 'layerStack.minOuterLayerStructure',
        name: 'Min outer layer structure',
    },
    {
        fieldPath: 'layerStack.minInnerLayerStructure',
        name: 'Min inner layer structure',
    },
    {
        fieldPath: 'mechanical.minViaDiameter',
        name: 'Minimum via diameter',
    },
];

export const deletePcbAnalytic = (data: PCBAnalyticCommonData) => {
    const payload = convertDataForPcbAnalytics(data);

    analytics.track(PCB_DELETE_EVENT, payload);
};

export const downloadPcbFilesAnalytic = (data: PCBAnalyticCommonData) => {
    const payload = convertDataForPcbAnalytics(data);

    analytics.track(PCB_DOWNLOAD_FILES_EVENT, payload);
};

export const downloadPcbPDFAnalytic = (data: PCBAnalyticCommonData) => {
    const payload = convertDataForPcbAnalytics(data);

    analytics.track(PCB_DOWNLOAD_PDF_EVENT, payload);
};

export const uploadPcbAnalytic = (data: PCBAnalyticCommonData) => {
    const payload = convertDataForPcbAnalytics(data);

    analytics.track(PCB_UPLOADED_EVENT, payload);
};

export const updatePcbAnalytic = (data: PCBUpdateAnalytic) => {
    const payload = {
        ...convertDataForPcbAnalytics(data),
        fields: data.fields,
    };
    // only if one of fields change will trigger the track
    if (data.fields.length > 0) {
        analytics.track(PCB_SPECIFICATION_UPDATED_EVENT, payload);
    }

    if (data.fields.includes('Layerstack type')) {
        analytics.track('change_pcb_type', payload);
    }
};

// PCB sidebar analytics
export enum PCBTabTypes {
    Specification = 'specification',
    LayerStack = 'layer_stack',
    Drills = 'drills',
    StackUp = 'stack_up',
    CADViewer = 'cad_viewer',
    Panelization = 'panelization',
}

export const pcbTabChangeAnalytics = (data: PCBAnalyticCommonData, pcbTab: PCBTabTypes) => {
    const payload = {
        ...convertDataForPcbAnalytics(data),
        tab: pcbTab,
    };

    analytics.track(PCB_TAB_CHANGE, payload);
};

export const expandPcbSidebarAnalytics = (data: PCBAnalyticCommonData) => {
    const payload = convertDataForPcbAnalytics(data);

    analytics.track('expand_pcb_sidebar', payload);
};

export const extractChangedFieldNames = (pcb: FieldNamesMarkedBoolean<PCBV2Properties>) => {
    return TRACKED_FIELD_LIST.filter((field) => get(pcb, field.fieldPath, false)).map((field) => field.name);
};

// Shipping panel module analytics
export const editPcbPanelAnalytics = (data: PCBAnalyticCommonData) => {
    const payload = convertDataForPcbAnalytics(data);
    analytics.track('edit_pcb_panel', payload);
};

export const updatePcbPanelAnalytics = (
    props: PCBAnalyticCommonData & {
        fields: FieldNamesMarkedBoolean<PanelFormState>;
        scope: ScopeType;
    },
) => {
    const { fields, scope, ...data } = props;
    const payload = convertDataForPcbAnalytics(data);
    analytics.track('save_pcb_panel', payload);

    // Tracking the default panel values
    if (fields) {
        if ('data' in fields) {
            const data = fields.data ?? [];
            const flatData = (Array.isArray(data) ? data : []).flatMap((data) => Object.keys(data));

            if (flatData.includes('max_x_outs')) {
                // eslint-disable-next-line spellcheck/spell-checker
                analytics.track('edit_pcb_panel_limit_xouts', payload);
            }
        }

        if ('scope' in fields) {
            if (scope === 'PerPcb') {
                analytics.track('edit_pcb_panel_universal', payload);
            } else {
                analytics.track('edit_pcb_panel_per_scenario', payload);
            }
        }
    }
};
