import * as z from 'zod';
import { ApiIntegrationStatusRuntype } from './3rdPartyBackendTypes';

export interface ChipCartCredentialsDTO extends z.infer<typeof ChipCartCredentialsDTORuntype> {}
export const ChipCartCredentialsDTORuntype = z.object({
    enabled: z.boolean(),
});

export interface ChipCartCredentialsInputDTO extends z.infer<typeof ChipCartCredentialsInputDTORuntype> {}
export const ChipCartCredentialsInputDTORuntype = z.object({
    enabled: z.boolean().optional(),
});

export interface ChipCartResponseBodyDTO extends z.infer<typeof ChipCartResponseBodyDTORuntype> {}
export const ChipCartResponseBodyDTORuntype = z.object({
    data: ChipCartCredentialsDTORuntype.nullable(),
    status: ApiIntegrationStatusRuntype,
});
