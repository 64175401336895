import { httpQueryKey } from '@/resources/http/httpQueryKey';
import { useIsMutating } from '@tanstack/react-query';

export const useIsManufacturingDataMutating = () => {
    const isOtsPartMutating = useIsMutating({ mutationKey: httpQueryKey('PATCH /parts/off-the-shelf/:partId') }) > 0;
    const isCustomPartMutating = useIsMutating({ mutationKey: httpQueryKey('PATCH /parts/custom/:partId') }) > 0;
    const isComponentMutating = useIsMutating({ mutationKey: httpQueryKey('PATCH /ipns/:id/form-and-fit') }) > 0;

    return isOtsPartMutating || isCustomPartMutating || isComponentMutating;
};
