import * as z from 'zod';

import { Currency } from '../currencyBackendTypes';
import { SupplierDTORuntype, SupplierPartType, SupplierType } from '../supplier/supplierBackendTypes';

export enum RegionsEnum {
    Unknown = 'Unknown',
    World = 'World',
    Africa = 'Africa',
    NorthernAfrica = 'NorthernAfrica',
    SubSaharanAfrica = 'SubSaharanAfrica',
    WesternAfrica = 'WesternAfrica',
    EasternAfrica = 'EasternAfrica',
    MiddleAfrica = 'MiddleAfrica',
    SouthernAfrica = 'SouthernAfrica',
    Americas = 'Americas',
    NorthernAmerica = 'NorthernAmerica',
    LatinAmericaAndTheCaribbean = 'LatinAmericaAndTheCaribbean',
    SouthAmerica = 'SouthAmerica',
    CentralAmerica = 'CentralAmerica',
    Caribbean = 'Caribbean',
    Asia = 'Asia',
    EasternAsia = 'EasternAsia',
    SouthernAsia = 'SouthernAsia',
    SouthEasternAsia = 'SouthEasternAsia',
    CentralAsia = 'CentralAsia',
    WesternAsia = 'WesternAsia',
    Europe = 'Europe',
    SouthernEurope = 'SouthernEurope',
    EasternEurope = 'EasternEurope',
    NorthernEurope = 'NorthernEurope',
    ChannelIslands = 'ChannelIslands',
    WesternEurope = 'WesternEurope',
    Oceania = 'Oceania',
    AustraliaAndNewZealand = 'AustraliaAndNewZealand',
    Melanesia = 'Melanesia',
    Micronesia = 'Micronesia',
    Polynesia = 'Polynesia',
    Algeria = 'Algeria',
    Egypt = 'Egypt',
    Libya = 'Libya',
    Morocco = 'Morocco',
    Sudan = 'Sudan',
    Tunisia = 'Tunisia',
    WesternSahara = 'WesternSahara',
    BritishIndianOceanTerritory = 'BritishIndianOceanTerritory',
    Burundi = 'Burundi',
    Comoros = 'Comoros',
    Djibouti = 'Djibouti',
    Eritrea = 'Eritrea',
    Ethiopia = 'Ethiopia',
    FrenchSouthernTerritories = 'FrenchSouthernTerritories',
    Kenya = 'Kenya',
    Madagascar = 'Madagascar',
    Malawi = 'Malawi',
    Mauritius = 'Mauritius',
    Mayotte = 'Mayotte',
    Mozambique = 'Mozambique',
    Reunion = 'Reunion',
    Rwanda = 'Rwanda',
    Seychelles = 'Seychelles',
    Somalia = 'Somalia',
    SouthSudan = 'SouthSudan',
    Uganda = 'Uganda',
    UnitedRepublicOfTanzania = 'UnitedRepublicOfTanzania',
    Zambia = 'Zambia',
    Zimbabwe = 'Zimbabwe',
    Angola = 'Angola',
    Cameroon = 'Cameroon',
    CentralAfricanRepublic = 'CentralAfricanRepublic',
    Chad = 'Chad',
    Congo = 'Congo',
    DemocraticRepublicOfTheCongo = 'DemocraticRepublicOfTheCongo',
    EquatorialGuinea = 'EquatorialGuinea',
    Gabon = 'Gabon',
    SaoTomeAndPrincipe = 'SaoTomeAndPrincipe',
    Botswana = 'Botswana',
    Eswatini = 'Eswatini',
    Lesotho = 'Lesotho',
    Namibia = 'Namibia',
    SouthAfrica = 'SouthAfrica',
    Benin = 'Benin',
    BurkinaFaso = 'BurkinaFaso',
    CaboVerde = 'CaboVerde',
    CoteDIvore = 'CoteDIvore',
    Gambia = 'Gambia',
    Ghana = 'Ghana',
    Guinea = 'Guinea',
    GuineaBissau = 'GuineaBissau',
    Liberia = 'Liberia',
    Mali = 'Mali',
    Mauritania = 'Mauritania',
    Niger = 'Niger',
    Nigeria = 'Nigeria',
    SaintHelena = 'SaintHelena',
    Senegal = 'Senegal',
    SierraLeone = 'SierraLeone',
    Togo = 'Togo',
    Anguilla = 'Anguilla',
    AntiguaAndBarbuda = 'AntiguaAndBarbuda',
    Aruba = 'Aruba',
    Bahamas = 'Bahamas',
    Barbados = 'Barbados',
    BonaireAndSintEustatiusAndSaba = 'BonaireAndSintEustatiusAndSaba',
    BritishVirginIslands = 'BritishVirginIslands',
    CaymanIslands = 'CaymanIslands',
    Cuba = 'Cuba',
    Curacao = 'Curacao',
    Dominica = 'Dominica',
    DominicanRepublic = 'DominicanRepublic',
    Grenada = 'Grenada',
    Guadeloupe = 'Guadeloupe',
    Haiti = 'Haiti',
    Jamaica = 'Jamaica',
    Martinique = 'Martinique',
    Montserrat = 'Montserrat',
    PuertoRico = 'PuertoRico',
    SaintBarthelemy = 'SaintBarthelemy',
    SaintKittsAndNevis = 'SaintKittsAndNevis',
    SaintLucia = 'SaintLucia',
    SaintMartin = 'SaintMartin',
    SaintVincentAndTheGrenadines = 'SaintVincentAndTheGrenadines',
    SintMaarten = 'SintMaarten',
    TrinidadAndTobago = 'TrinidadAndTobago',
    TurksAndCaicosIslands = 'TurksAndCaicosIslands',
    UnitedStatesVirginIslands = 'UnitedStatesVirginIslands',
    Belize = 'Belize',
    CostaRica = 'CostaRica',
    ElSalvador = 'ElSalvador',
    Guatemala = 'Guatemala',
    Honduras = 'Honduras',
    Mexico = 'Mexico',
    Nicaragua = 'Nicaragua',
    Panama = 'Panama',
    Argentina = 'Argentina',
    Bolivia = 'Bolivia',
    BouvetIsland = 'BouvetIsland',
    Brazil = 'Brazil',
    Chile = 'Chile',
    Colombia = 'Colombia',
    Ecuador = 'Ecuador',
    FalklandIslands = 'FalklandIslands',
    FrenchGuiana = 'FrenchGuiana',
    Guyana = 'Guyana',
    Paraguay = 'Paraguay',
    Peru = 'Peru',
    SouthGeorgiaAndTheSouthSandwichIslands = 'SouthGeorgiaAndTheSouthSandwichIslands',
    Suriname = 'Suriname',
    Uruguay = 'Uruguay',
    Venezuela = 'Venezuela',
    Bermuda = 'Bermuda',
    Canada = 'Canada',
    Greenland = 'Greenland',
    SaintPierreAndMiquelon = 'SaintPierreAndMiquelon',
    UnitedStatesOfAmerica = 'UnitedStatesOfAmerica',
    Antarctica = 'Antarctica',
    Kazakhstan = 'Kazakhstan',
    Kyrgyzstan = 'Kyrgyzstan',
    Tajikistan = 'Tajikistan',
    Turkmenistan = 'Turkmenistan',
    Uzbekistan = 'Uzbekistan',
    China = 'China',
    Taiwan = 'Taiwan',
    HongKong = 'HongKong',
    Macao = 'Macao',
    DemocraticPeoplesRepublicOfKorea = 'DemocraticPeoplesRepublicOfKorea',
    Japan = 'Japan',
    Mongolia = 'Mongolia',
    RepublicOfKorea = 'RepublicOfKorea',
    BruneiDarussalam = 'BruneiDarussalam',
    Cambodia = 'Cambodia',
    Indonesia = 'Indonesia',
    LaoPeoplesDemocraticRepublic = 'LaoPeoplesDemocraticRepublic',
    Malaysia = 'Malaysia',
    Myanmar = 'Myanmar',
    Philippines = 'Philippines',
    Singapore = 'Singapore',
    Thailand = 'Thailand',
    TimorLeste = 'TimorLeste',
    VietNam = 'VietNam',
    Afghanistan = 'Afghanistan',
    Bangladesh = 'Bangladesh',
    Bhutan = 'Bhutan',
    India = 'India',
    Iran = 'Iran',
    Maldives = 'Maldives',
    Nepal = 'Nepal',
    Pakistan = 'Pakistan',
    SriLanka = 'SriLanka',
    Armenia = 'Armenia',
    Azerbaijan = 'Azerbaijan',
    Bahrain = 'Bahrain',
    Cyprus = 'Cyprus',
    Georgia = 'Georgia',
    Iraq = 'Iraq',
    Israel = 'Israel',
    Jordan = 'Jordan',
    Kuwait = 'Kuwait',
    Lebanon = 'Lebanon',
    Oman = 'Oman',
    Qatar = 'Qatar',
    SaudiArabia = 'SaudiArabia',
    StateOfPalestine = 'StateOfPalestine',
    SyrianArabRepublic = 'SyrianArabRepublic',
    Turkey = 'Turkey',
    UnitedArabEmirates = 'UnitedArabEmirates',
    Yemen = 'Yemen',
    Belarus = 'Belarus',
    Bulgaria = 'Bulgaria',
    Czechia = 'Czechia',
    Hungary = 'Hungary',
    Poland = 'Poland',
    RepublicOfMoldova = 'RepublicOfMoldova',
    Romania = 'Romania',
    RussianFederation = 'RussianFederation',
    Slovakia = 'Slovakia',
    Ukraine = 'Ukraine',
    AlandIslands = 'AlandIslands',
    Guernsey = 'Guernsey',
    Jersey = 'Jersey',
    Sark = 'Sark',
    Denmark = 'Denmark',
    Estonia = 'Estonia',
    FaroeIslands = 'FaroeIslands',
    Finland = 'Finland',
    Iceland = 'Iceland',
    Ireland = 'Ireland',
    IsleOfMan = 'IsleOfMan',
    Latvia = 'Latvia',
    Lithuania = 'Lithuania',
    Norway = 'Norway',
    SvalbardAndJanMayenIslands = 'SvalbardAndJanMayenIslands',
    Sweden = 'Sweden',
    UnitedKingdomOfGreatBritainAndNorthernIreland = 'UnitedKingdomOfGreatBritainAndNorthernIreland',
    Albania = 'Albania',
    Andorra = 'Andorra',
    BosniaAndHerzegovina = 'BosniaAndHerzegovina',
    Croatia = 'Croatia',
    Gibraltar = 'Gibraltar',
    Greece = 'Greece',
    HolySee = 'HolySee',
    Italy = 'Italy',
    Malta = 'Malta',
    Montenegro = 'Montenegro',
    NorthMacedonia = 'NorthMacedonia',
    Portugal = 'Portugal',
    SanMarino = 'SanMarino',
    Serbia = 'Serbia',
    Slovenia = 'Slovenia',
    Spain = 'Spain',
    Austria = 'Austria',
    Belgium = 'Belgium',
    France = 'France',
    Germany = 'Germany',
    Liechtenstein = 'Liechtenstein',
    Luxembourg = 'Luxembourg',
    Monaco = 'Monaco',
    Netherlands = 'Netherlands',
    Switzerland = 'Switzerland',
    Australia = 'Australia',
    ChristmasIsland = 'ChristmasIsland',
    CocosKeelingIslands = 'CocosKeelingIslands',
    HeardIslandAndMcDonaldIslands = 'HeardIslandAndMcDonaldIslands',
    NewZealand = 'NewZealand',
    NorfolkIsland = 'NorfolkIsland',
    Fiji = 'Fiji',
    NewCaledonia = 'NewCaledonia',
    PapuaNewGuinea = 'PapuaNewGuinea',
    SolomonIslands = 'SolomonIslands',
    Vanuatu = 'Vanuatu',
    Guam = 'Guam',
    Kiribati = 'Kiribati',
    MarshallIslands = 'MarshallIslands',
    FederatedStatesOfMicronesia = 'FederatedStatesOfMicronesia',
    Nauru = 'Nauru',
    NorthernMarianaIslands = 'NorthernMarianaIslands',
    Palau = 'Palau',
    UnitedStatesMinorOutlyingIslands = 'UnitedStatesMinorOutlyingIslands',
    AmericanSamoa = 'AmericanSamoa',
    CookIslands = 'CookIslands',
    FrenchPolynesia = 'FrenchPolynesia',
    Niue = 'Niue',
    Pitcairn = 'Pitcairn',
    Samoa = 'Samoa',
    Tokelau = 'Tokelau',
    Tonga = 'Tonga',
    Tuvalu = 'Tuvalu',
    WallisAndFutunaIslands = 'WallisAndFutunaIslands',
}
export const RegionsEnumRuntype = z.nativeEnum(RegionsEnum);

export enum SupplierTag {
    QuotePartner = 'QuotePartner',
    ManuallyAdded = 'ManuallyAdded',
    OffTheShelfPartSupplier = 'OffTheShelfPartSupplier',
    PcbSupplier = 'PcbSupplier',
    CustomPartSupplier = 'CustomPartSupplier',
    AssemblySupplier = 'AssemblySupplier',
    Distributor = 'Distributor',
    Manufacturer = 'Manufacturer',
    Ems = 'Ems',
    System = 'System',
    Erp = 'Erp',
    Manual = 'Manual',
}

export enum SupplierPreference {
    Preferred = 'Preferred',
    Approved = 'Approved',
    NotApproved = 'NotApproved',
    Excluded = 'Excluded',
}

const TagRuntype = z.object({
    tag: z.nativeEnum(SupplierTag),
});

export const SupplierPreferenceRuntype = z.nativeEnum(SupplierPreference);

export interface SupplierAndStockLocationDTO extends z.infer<typeof SupplierAndStockLocationDTORuntype> {}
export const SupplierAndStockLocationDTORuntype = z.object({
    id: z.string(),
    supplier: SupplierDTORuntype,
    stock_location: RegionsEnumRuntype,
    supplier_number: z.string().nullable(),
    tags: z.array(TagRuntype),
    shipping_time_in_days: z.number().nullable(),
});

export interface SupplierAndStockLocationWithPreferenceDTO extends SupplierAndStockLocationDTO {
    preference: SupplierPreference;
}

export interface SupplierAndStockLocationPostDTO extends z.infer<typeof SupplierAndStockLocationPostDTORuntype> {}
export const SupplierAndStockLocationPostDTORuntype = z.object({
    name: z.string(),
    stock_location: RegionsEnumRuntype,
    supplier_number: z.string().nullable(),
    supplier_type: z.nativeEnum(SupplierType),
    supplier_part_type: z.nativeEnum(SupplierPartType),
    supplier_preference: z.optional(SupplierPreferenceRuntype),
    shipping_time_in_days: z.number().nullable(),
    default_currency: z.nativeEnum(Currency).optional(),
});

export interface SupplierAndStockLocationPatchDTO extends z.infer<typeof SupplierAndStockLocationPatchDTORuntype> {}
export const SupplierAndStockLocationPatchDTORuntype = z.object({
    name: z.string().optional(),
    supplier_number: z.string().nullable(),
    supplier_type: z.nativeEnum(SupplierType).optional(),
    supplier_part_type: z.nativeEnum(SupplierPartType).optional(),
    supplier_preference: z.optional(SupplierPreferenceRuntype),
    shipping_time_in_days: z.number().nullable().optional(),
    default_currency: z.nativeEnum(Currency).optional(),
});
