import * as z from 'zod';
import { DriverIdRuntype } from '../driver';

const BasicDriverCountRuntype = z.object({
    type: z.literal('Basic'),
    data: z.string(),
});

export const PerScenarioDriverCountDataRuntype = z.object({
    scenario_counts: z.record(z.string(), z.string()),
    count: z.string().nullable(),
});

const PerScenarioDriverCountRuntype = z.object({
    type: z.literal('PerScenario'),
    data: PerScenarioDriverCountDataRuntype,
});

export const AssemblyWideManuallyOverwrittenDriverCountRuntype = z.object({
    id: z.string(),
    manufacturing_assembly_details: z.string(),
    driver: DriverIdRuntype,
    manual_driver_count: BasicDriverCountRuntype.or(PerScenarioDriverCountRuntype),
});
