import { plural, t } from '@lingui/macro';
import { assertUnreachable } from '@luminovo/commons';
import { Flexbox, HighlightMatcher, Tag, Text, Tooltip, colorSystem } from '@luminovo/design-system';
import {
    FullPart,
    OtsComponentFull,
    OtsFullPart,
    PartMatchTypeFullResponse,
    RfqContext,
    isCustomComponentFull,
    isCustomFullPart,
    isGenericFullPart,
    isOtsComponentFull,
    isOtsFullPart,
} from '@luminovo/http-client';
import { findRawSpecsNotLinked } from '../OtsComponents/findRawSpecsNotLinked';
import { OtsComponentPartOptionsTooltip } from '../OtsComponents/OtsComponentPartOptionsTooltip';
import { UnlinkedIcon } from '../OtsComponents/UnlinkedIcon';
import { HighlightedMpn } from '../partSpecificationCards/OTSPart/OTSPartCard';
import { TableCell } from './TableCell';

const RenderOtsComponentMpnMatch = ({
    match,
    otsComponentState,
    candidateMpns,
}: {
    match: PartMatchTypeFullResponse;
    otsComponentState?: 'Active' | 'Removed';
    candidateMpns?: HighlightMatcher;
}) => {
    const { type, data: partData } = match;
    if (type === 'Generic') {
        const matchesCount = partData.matches.length;
        return (
            <Text
                variant="body-small"
                style={{ color: otsComponentState === 'Removed' ? colorSystem.neutral[6] : colorSystem.neutral[9] }}
            >
                {plural(matchesCount, {
                    one: `Generic part with 1 match`,
                    other: `Generic part with ${matchesCount} matches`,
                })}
            </Text>
        );
    }
    if (type === 'OffTheShelf') {
        return <HighlightedMpn mpns={[partData.mpn, ...partData.mpn_aliases]} matcher={candidateMpns} />;
    }
    assertUnreachable(type);
};

const RenderOtsComponentMpnMatches = ({
    component,
    candidateMpns,
    openOtsDrawer,
    openGenericPartDrawer,
    rfqContext = { type: 'OutsideRfQ' },
}: {
    component: OtsComponentFull;
    candidateMpns?: HighlightMatcher;
    openOtsDrawer?: (args: { part: OtsFullPart; rfqContext: RfqContext }) => void;
    openGenericPartDrawer?: (args: { genericPartId: string; rfqContext: RfqContext }) => void;
    rfqContext?: RfqContext;
}) => {
    const { state, matches } = component;
    const rawSpecsNotLinked = findRawSpecsNotLinked({ component });
    if (matches.length === 0 && rawSpecsNotLinked.length === 0) {
        return (
            <Tag
                color="yellow"
                label={t`No linked parts`}
                attention="low"
                style={{ color: state === 'Removed' ? colorSystem.neutral[6] : colorSystem.yellow[7] }}
            />
        );
    }

    if (matches.length + rawSpecsNotLinked.length === 1) {
        if (matches.length === 1) {
            return (
                <RenderOtsComponentMpnMatch
                    match={matches[0].part}
                    otsComponentState={state}
                    candidateMpns={candidateMpns}
                />
            );
        }
        if (rawSpecsNotLinked.length === 1) {
            return (
                <Flexbox alignItems="center" gap="4px">
                    <HighlightedMpn mpns={[rawSpecsNotLinked[0].mpn ?? '']} matcher={candidateMpns} />
                    <UnlinkedIcon />
                </Flexbox>
            );
        }
    }

    return (
        <Tooltip
            variant="white"
            arrow
            title={
                <OtsComponentPartOptionsTooltip
                    linkedParts={matches.map((match) => match.part.data)}
                    rawSpecsNotLinked={rawSpecsNotLinked}
                    openOtsDrawer={openOtsDrawer}
                    openGenericPartDrawer={openGenericPartDrawer}
                    rfqContext={rfqContext}
                    candidateMpns={candidateMpns}
                />
            }
        >
            <span>
                <Flexbox alignItems="center" gap="4px">
                    <Tag
                        color="neutral"
                        attention="low"
                        label={t`${matches.length + rawSpecsNotLinked.length} MPNs`}
                        style={{ color: state === 'Removed' ? colorSystem.neutral[6] : colorSystem.neutral[8] }}
                    />
                    {rawSpecsNotLinked.length > 0 && <UnlinkedIcon />}
                </Flexbox>
            </span>
        </Tooltip>
    );
};

export const RenderMpn = ({
    part,
    rfqContext,
    openOtsDrawer,
    openGenericPartDrawer,
    candidateMpns = [],
    onClick,
}: {
    part: FullPart;
    rfqContext?: RfqContext;
    openOtsDrawer?: (args: { part: OtsFullPart; rfqContext: RfqContext }) => void;
    openGenericPartDrawer?: (args: { genericPartId: string; rfqContext: RfqContext }) => void;
    candidateMpns?: HighlightMatcher;
    onClick?: () => void;
}): JSX.Element => {
    if (isOtsFullPart(part)) {
        return (
            <TableCell onClick={onClick}>
                <HighlightedMpn mpns={[part.mpn, ...part.mpn_aliases]} matcher={candidateMpns} />
            </TableCell>
        );
    }
    if (isGenericFullPart(part)) {
        const matches = part.matches;
        if (matches.length === 0) {
            return (
                <TableCell onClick={onClick}>
                    <Tag color="red" label={t`No matches`} attention="low" />
                </TableCell>
            );
        }
        return (
            <TableCell onClick={onClick}>
                {plural(matches.length, { one: `${matches.length} match`, other: `${matches.length} matches` })}
            </TableCell>
        );
    }
    if (isOtsComponentFull(part)) {
        return (
            <TableCell onClick={onClick} isComponentRemoved={part.state === 'Removed'}>
                <RenderOtsComponentMpnMatches
                    component={part}
                    candidateMpns={candidateMpns}
                    openOtsDrawer={openOtsDrawer}
                    openGenericPartDrawer={openGenericPartDrawer}
                    rfqContext={rfqContext}
                />
            </TableCell>
        );
    }
    if (isCustomFullPart(part)) {
        return <TableCell onClick={onClick} />;
    }
    if (isCustomComponentFull(part)) {
        return (
            <TableCell onClick={onClick} isComponentRemoved={part.state === 'Removed'}>
                -
            </TableCell>
        );
    }
    assertUnreachable(part);
};
