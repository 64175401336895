import * as z from 'zod';
import { endpoint } from '../http/endpoint';
import { AlElektronikCredentialsInputDTORuntype, AlElektronikResponseBodyDTORuntype } from './alElektronikBackendTypes';

export const alElektronikEndpoints = {
    'GET /3rdparty/alelektronik/credentials': endpoint({
        description: 'Returns credentials for AL Elektronik integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: AlElektronikResponseBodyDTORuntype,
    }),
    'POST /3rdparty/alelektronik/credentials': endpoint({
        description: 'Uploads credentials for Al Elektronik API integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: AlElektronikCredentialsInputDTORuntype,
        responseBody: AlElektronikResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/alelektronik/credentials'],
    }),
    'PATCH /3rdparty/alelektronik/credentials': endpoint({
        description: 'Updates credentials for Al Elektronik API integratoin',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: AlElektronikCredentialsInputDTORuntype,
        responseBody: AlElektronikResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/alelektronik/credentials'],
    }),
    'DELETE /3rdparty/alelektronik/credentials': endpoint({
        description: 'Deletes existing credentials for Al Elektronik API integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({ deleted: z.number() }),
        invalidates: ['GET /3rdparty/alelektronik/credentials'],
    }),
};
