import * as z from 'zod';
import { endpoint } from '../http/endpoint';
import { SourcengineCredentialsInputDTORuntype, SourcengineResponseBodyDTORuntype } from './sourcengineBackendTypes';

export const sourcengineEndpoints = {
    'GET /3rdparty/sourcengine/credentials': endpoint({
        description: 'Returns credentials for Sourcengine integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: SourcengineResponseBodyDTORuntype,
    }),
    'POST /3rdparty/sourcengine/credentials': endpoint({
        description: 'Uploads credentials for Sourcengine API integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: SourcengineCredentialsInputDTORuntype,
        responseBody: SourcengineResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/sourcengine/credentials'],
    }),
    'PATCH /3rdparty/sourcengine/credentials': endpoint({
        description: 'Updates credentials for Sourcengine API integratoin',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: SourcengineCredentialsInputDTORuntype,
        responseBody: SourcengineResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/sourcengine/credentials'],
    }),
    'DELETE /3rdparty/sourcengine/credentials': endpoint({
        description: 'Deletes existing credentials for Sourcengine API integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({ deleted: z.number() }),
        invalidates: ['GET /3rdparty/sourcengine/credentials'],
    }),
};
