import * as z from 'zod';
import { ApiIntegrationStatusRuntype } from './3rdPartyBackendTypes';

export interface SchukatCredentialsDTO extends z.infer<typeof SchukatCredentialsDTORuntype> {}
export const SchukatCredentialsDTORuntype = z.object({
    api_key: z.string(),
    username: z.string(),
    password: z.string(),
});

export interface SchukatCredentialsInputDTO extends z.infer<typeof SchukatCredentialsInputDTORuntype> {}
export const SchukatCredentialsInputDTORuntype = z.object({
    api_key: z.string().optional(),
    username: z.string().optional(),
    password: z.string().optional(),
});

export interface SchukatResponseBodyDTO extends z.infer<typeof SchukatResponseBodyDTORuntype> {}
export const SchukatResponseBodyDTORuntype = z.object({
    data: SchukatCredentialsDTORuntype.nullable(),
    status: ApiIntegrationStatusRuntype,
});
