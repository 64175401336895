import { assertUnreachable } from '@luminovo/commons';
import {
    CustomComponentFull,
    CustomFullPart,
    GenericFullPart,
    OtsComponentFull,
    OtsFullPart,
    PartDTO,
    PartIdTypes,
    PartLite,
    PartLiteTypes,
    isCustomComponentFull,
    isCustomFullPart,
    isGenericFullPart,
    isOtsComponentFull,
    isOtsFullPart,
} from '@luminovo/http-client';

export function extractPartDTO(
    part: GenericFullPart | OtsComponentFull | OtsFullPart | CustomFullPart | CustomComponentFull | PartLite,
): PartDTO {
    if ('kind' in part) {
        if (part.kind === PartLiteTypes.Generic) {
            return {
                type: PartIdTypes.Generic,
                data: part.id,
            };
        }

        if (part.kind === PartLiteTypes.OffTheShelf) {
            return {
                type: PartIdTypes.OffTheShelf,
                data: part.id,
            };
        }

        if (part.kind === PartLiteTypes.Ipn) {
            return {
                type: PartIdTypes.Ipn,
                data: part.id,
            };
        }

        if (part.kind === PartLiteTypes.Custom) {
            return {
                type: PartIdTypes.Custom,
                data: part.id,
            };
        }

        if (part.kind === PartLiteTypes.CustomComponent) {
            return {
                type: PartIdTypes.CustomComponent,
                data: part.id,
            };
        }

        if (part.kind === PartLiteTypes.RawSpecification) {
            throw new Error('RawSpecification is not supported');
        }

        if (part.kind === PartLiteTypes.Unknown) {
            throw new Error('Unknown part type');
        }
    }

    if (isGenericFullPart(part)) {
        return {
            type: PartIdTypes.Generic,
            data: part.id,
        };
    }
    if (isOtsComponentFull(part)) {
        return {
            type: PartIdTypes.Ipn,
            data: part.id,
        };
    }

    if (isOtsFullPart(part)) {
        return {
            type: PartIdTypes.OffTheShelf,
            data: part.id,
        };
    }

    if (isCustomFullPart(part)) {
        return {
            type: PartIdTypes.Custom,
            data: part.id,
        };
    }

    if (isCustomComponentFull(part)) {
        return {
            type: PartIdTypes.CustomComponent,
            data: part.id,
        };
    }

    assertUnreachable(part);
}
