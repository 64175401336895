import { useSites } from '@/resources/organizationSettings/sitesHandler';
import { compareByDate, isPresent, uniq } from '@luminovo/commons';
import { compareByNumber } from '@luminovo/design-system';
import {
    DemandDTO,
    NegotiationLineItem,
    NegotiationLineItemDTO,
    SiteDTO,
    StandardPartOfferLinkedLocationType,
    SupplierAndStockLocationDTO,
} from '@luminovo/http-client';
import React from 'react';
import { useHttpQuery, useSuspenseHttpQuery } from '../../../resources/http/useHttpQuery';
import { useSupplierAndStockLocations } from '../../../resources/supplierAndStockLocation/supplierAndStockLocationHandler';
import { resolveOtsPartsRecursively } from '../model/resolveOtsPartsRecursively';

function inlineSuppliersOrSiteIntoNegotiationLineItem({
    lineItem,
    supplierAndStockLocations,
    sites,
    demands,
}: {
    lineItem: NegotiationLineItemDTO;
    supplierAndStockLocations: SupplierAndStockLocationDTO[];
    sites: SiteDTO[];
    demands: DemandDTO[];
}): NegotiationLineItem {
    return {
        ...lineItem,
        awarded_offers: lineItem.awarded_offers.flatMap((offer) => {
            const { type, id } = offer.awarded_solution.linked_location_id;

            if (type === StandardPartOfferLinkedLocationType.InventorySite) {
                const linkedLocation = sites.find((site) => site.id === id);
                if (!isPresent(linkedLocation)) {
                    return [];
                }

                return {
                    ...offer,
                    awarded_solution: {
                        ...offer.awarded_solution,
                        linked_location: { type: StandardPartOfferLinkedLocationType.InventorySite, ...linkedLocation },
                    },
                };
            }

            if (type === StandardPartOfferLinkedLocationType.SupplierAndStockLocation) {
                const linkedLocation = supplierAndStockLocations.find((supplier) => supplier.id === id);
                if (!isPresent(linkedLocation)) {
                    return [];
                }

                return {
                    ...offer,
                    awarded_solution: {
                        ...offer.awarded_solution,
                        linked_location: {
                            type: StandardPartOfferLinkedLocationType.SupplierAndStockLocation,
                            ...linkedLocation,
                        },
                    },
                };
            }

            return [];
        }),
        demands: demands.filter((demand) => lineItem.demands.includes(demand.id)),
    };
}

export function useNegotiations() {
    return useHttpQuery(
        'GET /negotiation',
        {},
        {
            select: (data) => Array.from(data.items).sort(compareByDate((n) => n.created_at)),
        },
    );
}

export function useNegotiation(negotiationId: number | undefined) {
    return useHttpQuery(
        'GET /negotiation/:id',
        {
            pathParams: {
                id: negotiationId ?? NaN,
            },
        },
        {
            select: (data) => data,
            enabled: isPresent(negotiationId),
        },
    );
}

export function useNegotiationLineItems(negotiationId: number | undefined): {
    data: NegotiationLineItem[] | undefined;
    isLoading: boolean;
} {
    const { data: supplierAndStockLocations } = useSupplierAndStockLocations();
    const { data: sites } = useSites();
    const { data: demands } = useDemandsInNegotiation(negotiationId);
    const { data: negotiontionLineItems } = useHttpQuery(
        'GET /negotiation/:id/line-items',
        {
            pathParams: {
                id: negotiationId ?? NaN,
            },
        },
        {
            enabled: isPresent(negotiationId),
        },
    );

    return React.useMemo(() => {
        if (
            !isPresent(negotiontionLineItems) ||
            !isPresent(supplierAndStockLocations) ||
            !isPresent(sites) ||
            !isPresent(demands)
        ) {
            return { data: undefined, isLoading: true };
        }

        return {
            isLoading: false,
            data: negotiontionLineItems.map((lineItem) =>
                inlineSuppliersOrSiteIntoNegotiationLineItem({
                    sites,
                    demands,
                    supplierAndStockLocations,
                    lineItem,
                }),
            ),
        };
    }, [negotiontionLineItems, supplierAndStockLocations, sites, demands]);
}

export function useQuoteRequest(quoteRequestId: string | undefined) {
    return useHttpQuery(
        'POST /quote-request/find',
        {
            requestBody: {
                quote_request_ids: isPresent(quoteRequestId) ? [quoteRequestId] : [],
            },
        },
        {
            enabled: isPresent(quoteRequestId),
            select: (data) => data.items[0],
        },
    );
}

/**
 * Unlike {@link useQuoteRequest}, fetches all quote requests and selects the one with the given `quoteRequestId`.
 * There is an implicity performance tradeoff here, the assumption being that you will often want to jump between quote requests.
 */
export function useSuspendedQuoteRequest(quoteRequestId: string) {
    return useSuspenseHttpQuery(
        'GET /quote-request/:id',
        {
            pathParams: {
                id: quoteRequestId,
            },
        },
        {
            select: (data) => data,
        },
    );
}

export function useQuoteRequests(quoteRequestIds: string[] | undefined) {
    return useHttpQuery(
        'POST /quote-request/find',
        {
            requestBody: {
                quote_request_ids: quoteRequestIds ?? [],
            },
        },
        {
            select: (data) => data.items,
            enabled: isPresent(quoteRequestIds),
        },
    );
}

export function useQuoteRequestsByNumber(quoteRequestNumbers: number[] | undefined) {
    return useHttpQuery(
        'POST /quote-request/find',
        {
            requestBody: {
                quote_request_numbers: quoteRequestNumbers ?? [],
            },
        },
        {
            select: (data) => data.items.sort(compareByNumber((n) => n.number)),
            enabled: isPresent(quoteRequestNumbers),
        },
    );
}

export function useNegotiationQuoteRequests(negotiationId: number | undefined) {
    return useHttpQuery(
        'POST /quote-request/find',
        {
            requestBody: {
                negotiation_id: negotiationId ?? NaN,
            },
        },
        {
            select: (data) => Array.from(data.items).sort(compareByNumber((n) => n.number)),
            enabled: isPresent(negotiationId),
        },
    );
}

export function useAwardScenarios(negotiationId: number | undefined) {
    return useHttpQuery(
        'POST /award-scenario/find',
        {
            requestBody: {
                negotiation_id: negotiationId ?? NaN,
            },
        },
        {
            select: (data) =>
                Array.from(data.items).sort(
                    compareByNumber((scenario) => {
                        if (scenario.kind.tag === 'Awarded') {
                            return -1;
                        }
                        if (
                            scenario.kind.tag === 'BestPriceAcrossAllQuotes' ||
                            scenario.kind.tag === 'LastPurchasePrice' ||
                            scenario.kind.tag === 'LastStandardPrice' ||
                            scenario.kind.tag === 'LastTargetPrice'
                        ) {
                            return 0;
                        }
                        if (scenario.kind.tag === 'Manual') {
                            return 1;
                        }
                        if (scenario.kind.tag === 'QuoteRequest') {
                            return 2;
                        }
                        // Default to first position
                        return 0;
                    }),
                ),
            enabled: isPresent(negotiationId),
        },
    );
}

export function useQuoteRequestLineItemsByQuoteRequest(quoteRequestId: string) {
    return useSuspenseHttpQuery(
        'GET /quote-request/:id/line-items',
        {
            pathParams: { id: quoteRequestId },
        },
        {
            select: (data) => data.items,
        },
    );
}

export function useQuoteRequestLineItemUniqueOtsParts(quoteRequestId: string | undefined) {
    const { data } = useHttpQuery(
        'GET /quote-request/:id/line-items',
        {
            pathParams: {
                id: quoteRequestId ?? '',
            },
        },
        {
            select: (data) => data.items,
            enabled: isPresent(quoteRequestId),
        },
    );

    return uniq(data?.map((part) => resolveOtsPartsRecursively(part.requested_part)).flatMap((parts) => parts));
}

export function useQuoteRequestLineItems(negotiationLineItemIds: number[] | undefined) {
    return useHttpQuery(
        'POST /quote-request/line-items/find',
        {
            requestBody: {
                negotiation_line_item_ids: negotiationLineItemIds ?? [],
            },
        },
        {
            select: (data) => data.items,
            enabled: isPresent(negotiationLineItemIds),
        },
    );
}

export function useNegotiationAggregatedStatuses(negotiationId: number | undefined) {
    return useHttpQuery(
        'GET /negotiation/:id/aggregated-statuses',
        {
            pathParams: {
                id: negotiationId ?? NaN,
            },
        },
        {
            enabled: isPresent(negotiationId),
        },
    );
}

export function useDemands() {
    return useHttpQuery(
        'GET /demand',
        {},
        {
            select: (data) => Array.from(data).sort(compareByDate((n) => n.created_at)),
        },
    );
}

export function useDemandsInNegotiation(negotiationId: number | undefined) {
    return useHttpQuery(
        'GET /negotiation/:id/demands',
        {
            pathParams: {
                id: negotiationId ?? NaN,
            },
        },
        {
            enabled: isPresent(negotiationId),
        },
    );
}
