import { isEqual, isPresent, uniqWith } from '@luminovo/commons';
import {
    CandidateCpn,
    ColumnName,
    PartOptionOrigin,
    PartSuggestionFull,
    PartSuggestionOrigin,
    isCandidateCpn,
} from '@luminovo/http-client';
import { getPartOptions } from '../part/getPartOptions';
import { BomItem } from './bomItemFrontendTypes';

/**
 * @returns unique candidate CPNs coming both from the part suggestions AND the part specification
 */
export function getCandidateCpns(bomItem: BomItem, suggestions: PartSuggestionFull[]): CandidateCpn[] {
    const cpnCandidatesFromPartSuggestions: CandidateCpn[] = getCandidateCpnsSuggestions(suggestions);

    const cpnCandidatesFromPartOptions: CandidateCpn[] = getPartOptions(bomItem.specification).reduce(
        (arr: CandidateCpn[], p) => {
            return arr.concat(getCandidateCpnsFromOrigin(p.origin));
        },
        [],
    );

    return uniqWith(Array.from(cpnCandidatesFromPartSuggestions.concat(cpnCandidatesFromPartOptions)), isEqual);
}

/**
 * @returns unique candidate CPNs from the part suggestions
 */
function getCandidateCpnsSuggestions(suggestions: PartSuggestionFull[]): CandidateCpn[] {
    return suggestions
        .reduce((arr: CandidateCpn[], p) => {
            return arr.concat(getCandidateCpnsFromOrigin(p.origin));
        }, [])
        .filter(isPresent);
}

function getCandidateCpnsFromOrigin(
    origin: PartOptionOrigin | PartSuggestionOrigin | null | undefined,
): CandidateCpn[] {
    if (!origin || origin.column === ColumnName.TechSpecs) {
        return [];
    }
    const originCandidate = 'candidate' in origin ? origin.candidate : undefined;
    if (!originCandidate) {
        return [];
    }
    if (isCandidateCpn(originCandidate)) {
        return [originCandidate];
    }
    return [];
}
