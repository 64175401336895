import * as z from 'zod';
import { DepanelizationRuntype } from './pcbPanelPreferenceBackendTypes';

// POST
export type PanelDetailsPostDTO = z.infer<typeof PanelDetailsPostRuntype>;
export const PanelDetailsPostRuntype = z.object({
    row_count: z.number(),
    column_count: z.number(),
    horizontal_spacing_in_mm: z.string(),
    vertical_spacing_in_mm: z.string(),
    min_milling_distance_in_mm: z.string(),
    max_x_outs: z.number().nullable().optional(),
    panel_preference: z.string().nullable().optional(),
    padding: z.object({
        top_in_mm: z.string(),
        bottom_in_mm: z.string(),
        left_in_mm: z.string(),
        right_in_mm: z.string(),
    }),
    depanelization: DepanelizationRuntype,
    pcb_is_rotated: z.boolean(),
});

export type PerPcbPanelPostDTO = z.infer<typeof PerPcbPanelPostRuntype>;
export const PerPcbPanelPostRuntype = z.object({
    type: z.literal('PerPcb'),
    data: z.object({
        pcb: z.string(),
        panel_details: PanelDetailsPostRuntype,
    }),
});

export type PerSourcingScenarioPanelPostDTO = z.infer<typeof PerSourcingScenarioPanelPostRuntype>;
export const PerSourcingScenarioPanelPostRuntype = z.object({
    type: z.literal('PerSourcingScenario'),
    data: z.object({
        sourcing_scenario: z.string(),
        pcb: z.string(),
        panel_details: PanelDetailsPostRuntype,
    }),
});

export type ExistingPanelContentDTO = z.infer<typeof ExistingPanelContentRuntype>;
export const ExistingPanelContentRuntype = z.object({
    pcb: z.string(),
    number_of_pcbs: z.number(),
    panel_width: z.string(),
    panel_height: z.string(),
    depanelization: DepanelizationRuntype,
    pcb_is_rotated: z.boolean(),
    id: z.string().optional(),
});

export type ExistingPanelPostDTO = z.infer<typeof ExistingPanelPostRuntype>;
export const ExistingPanelPostRuntype = z.object({
    type: z.literal('Existing'),
    data: ExistingPanelContentRuntype,
});

export type PanelPostDTO = z.infer<typeof PanelPostRuntype>;
export const PanelPostRuntype = PerPcbPanelPostRuntype.or(PerSourcingScenarioPanelPostRuntype).or(
    ExistingPanelPostRuntype,
);

// GET, differentiated because the ids are included in the response
export type PerPcbPanelDTO = z.infer<typeof PerPcbPanelRuntype>;
const PerPcbPanelRuntype = z.object({
    type: z.literal('PerPcb'),
    data: z.object({
        pcb: z.string(),
        panel_details: PanelDetailsPostRuntype.extend({
            id: z.string(),
        }),
    }),
});

export type PerSourcingScenarioPanelDTO = z.infer<typeof PerSourcingScenarioPanelRuntype>;
const PerSourcingScenarioPanelRuntype = z.object({
    type: z.literal('PerSourcingScenario'),
    data: z.object({
        sourcing_scenario: z.string(),
        pcb: z.string(),
        panel_details: PanelDetailsPostRuntype.extend({
            id: z.string(),
        }),
    }),
});

export type ExistingPanelDTO = z.infer<typeof ExistingPanelRuntype>;
const ExistingPanelRuntype = z.object({
    type: z.literal('Existing'),
    data: ExistingPanelContentRuntype.extend({
        id: z.string(),
    }),
});

export type PanelDTO = z.infer<typeof PanelRuntype>;
export const PanelRuntype = PerPcbPanelRuntype.or(PerSourcingScenarioPanelRuntype).or(ExistingPanelRuntype);

export const PanelDetailsRuntype = PanelDetailsPostRuntype.extend({ id: z.string() });

// PATCH
export type PanelizationFormModeDTO = z.infer<typeof PanelizationFormModeRuntype>;
const PanelizationFormModeRuntype = z.literal('Existing').or(z.literal('Details'));

export const PanelPatchRuntype = z
    .object({
        type: z.literal('Details'),
        data: PanelDetailsRuntype,
    })
    .or(ExistingPanelRuntype);

export type CustomPartOfferPcbPanelSpecification = z.infer<typeof CustomPartOfferPcbPanelSpecificationRuntype>;
export const CustomPartOfferPcbPanelSpecificationRuntype = z.union([
    z.object({
        type: z.literal('PanelDetails'),
        data: PanelDetailsPostRuntype,
    }),
    z.object({
        type: z.literal('Existing'),
        data: ExistingPanelContentRuntype,
    }),
]);

// Offer Panel Specification

export const PcbPanelSpecificationRuntype = z.object({
    type: z.literal('Pcb'),
    data: z.object({
        pcb: z.string(),
        panel: CustomPartOfferPcbPanelSpecificationRuntype,
    }),
});

export const PcbPanelUpdateSpecificationDTORuntype = z.object({
    type: z.literal('Pcb'),
    data: CustomPartOfferPcbPanelSpecificationRuntype,
});
