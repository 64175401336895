import { t } from '@lingui/macro';
import { Dialog, DialogContent, DialogTitle, TertiaryIconButton } from '@luminovo/design-system';
import { DemandScenarioDTO, RfqDTO } from '@luminovo/http-client';
import { Edit } from '@mui/icons-material';
import { useState } from 'react';
import { EditDemandScenarioFormInner } from './DemandScenarioForm';

const DialogEditDemandScenario = ({
    open,
    onClose,
    onSuccess,
    index,
    demandScenario,
    rfq,
}: {
    open: boolean;
    onClose: () => void;
    onSuccess: () => void;
    index: number;
    demandScenario: DemandScenarioDTO;
    rfq: RfqDTO;
}) => {
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle title={t`Edit demand scenario ${index + 1}`} onClose={onClose} />
            <DialogContent>
                <EditDemandScenarioFormInner
                    demandScenario={demandScenario}
                    onSuccess={onSuccess}
                    rfq={rfq}
                    onCancel={onClose}
                />
            </DialogContent>
        </Dialog>
    );
};

export const EditDemandScenarioButton = ({
    demandScenario,
    index,
    rfq,
    onSuccess,
}: {
    demandScenario: DemandScenarioDTO;
    index: number;
    rfq: RfqDTO;
    onSuccess: () => void;
}) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    return (
        <>
            <TertiaryIconButton
                onClick={() => setIsDialogOpen(true)}
                children={<Edit fontSize="small" />}
                size="medium"
            />
            <DialogEditDemandScenario
                open={isDialogOpen}
                onClose={() => setIsDialogOpen(false)}
                onSuccess={() => {
                    setIsDialogOpen(false);
                    onSuccess();
                }}
                index={index}
                demandScenario={demandScenario}
                rfq={rfq}
            />
        </>
    );
};
