import { t } from '@lingui/macro';
import { assertUnreachable } from '@luminovo/commons';
import { Tag, Tooltip } from '@luminovo/design-system';
import { OriginSetMethod, PartOptionOrigin } from '@luminovo/http-client';
import { getFormattedReasons } from '../utils/partOptionPartSuggestionReasons';

export const PartOptionOriginTag: React.FunctionComponent<{
    origin: PartOptionOrigin;
}> = ({ origin }) => {
    const method = origin.set_method;
    const candidate = 'candidate' in origin ? origin.candidate : undefined;
    const formattedReasons = getFormattedReasons(origin.reasons, candidate);
    const detailedReasons = formattedReasons.length > 0 ? formattedReasons : '';

    switch (method) {
        case OriginSetMethod.Automatic:
            return (
                <Tooltip
                    title={
                        <div style={{ whiteSpace: 'pre-wrap' }}>
                            {t`Automatically extracted part option${detailedReasons}`}
                        </div>
                    }
                >
                    <Tag attention={'low'} color={'blue'} label={'A'} />
                </Tooltip>
            );
        case OriginSetMethod.Manual:
            return (
                <Tooltip
                    title={
                        <div style={{ whiteSpace: 'pre-wrap' }}>
                            {t`Manually added or changed part option${detailedReasons}`}
                        </div>
                    }
                >
                    <Tag attention={'low'} color={'neutral'} label={'M'} />
                </Tooltip>
            );
        case OriginSetMethod.Previous:
            return (
                <Tooltip
                    title={
                        <div style={{ whiteSpace: 'pre-wrap' }}>
                            {t`Manually added in previous BOM import with identical line values.${detailedReasons}`}
                        </div>
                    }
                >
                    <Tag attention={'low'} color={'neutral'} label={'P'} />
                </Tooltip>
            );
        case null:
            return <></>;
        default:
            assertUnreachable(method);
    }
};
