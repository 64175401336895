import { Trans, t } from '@lingui/macro';
import { getLocale } from '@luminovo/commons';
import { CenteredLayout, Dropzone, Link, Message } from '@luminovo/design-system';
import { useSnackbar } from 'notistack';
import { usePnpImporterDialog } from '../PnpImportDialog';
import { convertCSVContentsToCSVLines } from '../types/typeConversions';
import { useSuccessMutation } from './useSuccessMutation';
function isCSVFile(file: File) {
    return file.type === 'text/csv' || file.type === 'application/vnd.ms-excel' || file.type === 'text/plain';
}

function isFileEmpty(file: File) {
    return file.size === 0;
}

const UnauthorizedErrorCode = 'UnauthorizedBlobOverwrite';

const isErrorUnauthorizedBlobOverwrite = (error: unknown) => {
    return typeof error === 'object' && error !== null && 'code' in error && error.code === UnauthorizedErrorCode;
};

export function PnpUploadDropzone({ assemblyId, isRfqEditable }: { assemblyId: string; isRfqEditable: boolean }) {
    const { enqueueSnackbar } = useSnackbar();
    const { openDialog } = usePnpImporterDialog();

    const handleImport = (files: File[]) => {
        if (files.length === 0) {
            return;
        }
        if (isFileEmpty(files[0])) {
            handleEmptyFileError();
            return;
        }
        if (!isCSVFile(files[0])) {
            handleError();
        } else {
            handleSuccess(files[0]);
        }
    };
    const {
        mutateAsync: successMutation,
        isPending: isLoading,
        isError,
        error,
    } = useSuccessMutation({
        assemblyId,
        onSuccess: (pnpInformation) => {
            openDialog({
                csvResult: convertCSVContentsToCSVLines(pnpInformation.contents, pnpInformation.column_mapping),
                fileName: pnpInformation.fileName,
                uploadId: pnpInformation.uploadId,

                columnMappingAttempt: pnpInformation.column_mapping,
            });
        },
    });

    const handleError = () => {
        enqueueSnackbar(t`Cannot upload unsupported file type`, { variant: 'error' });
    };

    const handleEmptyFileError = () => {
        enqueueSnackbar(t`Cannot upload empty file`, { variant: 'error' });
    };

    const handleSuccess = async (pnpFile: File) => {
        await successMutation({ pnpFile });
    };

    return (
        <CenteredLayout height={'100%'}>
            <Dropzone
                title={t`Pick and Place`}
                disabled={!isRfqEditable}
                subHeader={t`Upload your pick and place files here`}
                onDropAccepted={(files) => handleImport(files)}
                multiple={false}
                isLoading={isLoading}
                accept={{
                    'text/csv': ['.csv'],
                    // eslint-disable-next-line spellcheck/spell-checker
                    'text/plain': ['.txt'],
                }}
                extraContent={
                    <>
                        {isError && isErrorUnauthorizedBlobOverwrite(error) && (
                            <Message
                                size={'small'}
                                attention={'high'}
                                variant={'red'}
                                title={''}
                                message={t`A file with the same name already exists. Please rename your file and try again`}
                            />
                        )}
                        {isError && !isErrorUnauthorizedBlobOverwrite(error) && (
                            <Message
                                size={'small'}
                                attention={'high'}
                                variant={'red'}
                                title={''}
                                message={t`There was an error uploading your file. Please try again`}
                            />
                        )}
                        <Message
                            size={'small'}
                            attention={'high'}
                            variant={'primary'}
                            title=""
                            message={
                                <Trans>
                                    Not sure about the PnP file requirements?{' '}
                                    <Link onClick={() => window.open(getPnpFileFormatLink(), '_blank')}>
                                        Click here
                                    </Link>{' '}
                                    for guidance.
                                </Trans>
                            }
                        />
                    </>
                }
            />
        </CenteredLayout>
    );
}

const getPnpFileFormatLink = (): string => {
    const locale = getLocale();
    if (locale === 'de') {
        return 'https://help.luminovo.com/de/articles/108030';
    } else {
        return 'https://help.luminovo.com/en/articles/108030';
    }
};
