import * as z from 'zod';
import { CustomerDTORuntype } from '../customer/customerBackendTypes';
import { WorkflowTypeRuntype } from '../rfq';

export const CustomerSpecificSettingsAggregateDTORuntype = z.union([
    z.object({
        setting: z.literal('OrderConfirmationWorkflow'),
        value: WorkflowTypeRuntype,
        customers: z.array(z.string()),
    }),
    z.object({
        setting: z.literal('ManufacturingTemplates'),
        value: z.array(z.string()),
        customers: z.array(z.string()),
    }),
    z.object({
        setting: z.literal('CalculationTemplate'),
        value: z.string(),
        customers: z.array(z.string()),
    }),
]);
export type CustomerSpecificSettingsAggregateDTO = z.infer<typeof CustomerSpecificSettingsAggregateDTORuntype>;

export const CustomerSpecificSettingsAggregateFormRuntype = z.union([
    z.object({
        setting: z.literal('OrderConfirmationWorkflow'),
        value: WorkflowTypeRuntype,
        customers: z.array(CustomerDTORuntype),
    }),
    z.object({
        setting: z.literal('ManufacturingTemplates'),
        value: z.array(z.string()),
        customers: z.array(CustomerDTORuntype),
    }),
    z.object({
        setting: z.literal('CalculationTemplate'),
        value: z.string(),
        customers: z.array(CustomerDTORuntype),
    }),
]);
export type CustomerSpecificSettingsAggregateForm = z.infer<typeof CustomerSpecificSettingsAggregateFormRuntype>;

export const CustomerSpecificSettingsAggregateUpdateRequestDTORuntype = z.object({
    add: z.array(CustomerSpecificSettingsAggregateDTORuntype).optional(),
    replace: z.array(CustomerSpecificSettingsAggregateDTORuntype).optional(),
});
