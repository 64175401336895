import { ExtractRequestBody } from '../../../resources/http';

export const createManufacturingScenarioMock = (
    rfqId: string,
    sourcingScenarioId: string,
    batchSizes: number[],
): ExtractRequestBody<'POST /manufacturing-scenarios'> => {
    return {
        name: 'Manufacturing Scenario 1',
        assembly: rfqId,
        batch_sizes: batchSizes,
        sourcing_scenario: sourcingScenarioId,
        notes: null,
    };
};

export const createScenarioCombinationMock = (
    manufacturingScenarioId: string,
    sourcingScenarioId: string,
    batchSizes: number[],
): ExtractRequestBody<'PATCH /calculations/scenario-combinations'> => {
    return [
        {
            batch_sizes: batchSizes,
            manufacturing_scenario_id: manufacturingScenarioId,
            sourcing_scenario_id: sourcingScenarioId,
        },
    ];
};
