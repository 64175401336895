import * as z from 'zod';

export enum BomExportColumn {
    Designator = 'Designator',
    Quantity = 'Quantity',
    Unit = 'Unit',
    Dnp = 'Dnp',
    Consignment = 'Consignment',
    InternalPartNumber = 'InternalPartNumber',
    IpnDescription = 'IpnDescription',
    CustomerPartNumber = 'CustomerPartNumber',
    Mpn = 'Mpn',
    Manufacturer = 'Manufacturer',
    ManufacturerFree = 'ManufacturerFree',
    PartType = 'PartType',
    Description = 'Description',
    Package = 'Package',
    Mounting = 'Mounting',
    Pins = 'Pins',
    Qualifications = 'Qualifications',
    RohsCompliance = 'RohsCompliance',
    RohsVersion = 'RohsVersion',
    ReachCompliance = 'ReachCompliance',
    LifecycleStatus = 'LifecycleStatus',
    LifecycleYteol = 'LifecycleYteol',
    LifecycleYteolRange = 'LifecycleYteolRange',
    Notes = 'Notes',
    Origin = 'Origin',
    CountryOfOrigin = 'CountryOfOrigin',
    Eccn = 'Eccn',
    HtsCode = 'HtsCode',
    ChinaRohs = 'ChinaRohs',
    Prop65 = 'Prop65',
    ConflictMineralStatus = 'ConflictMineralStatus',
    LatestDatasheetUrl = 'LatestDatasheetUrl',
    LastTimeBuyDate = 'LastTimeBuyDate',
    LastTimeDeliveryDate = 'LastTimeDeliveryDate',
    SvhcCasNumbers = 'SvhcCasNumbers',
    CopyFromOriginalBom = 'CopyFromOriginalBom',
    BomIpn = 'BomIpn',
    BomCpn = 'BomCpn',
    ApprovalStatus = 'ApprovalStatus',
    PartEmissionData = 'PartEmissionData',
    EuPopCompliant = 'EuPopCompliant',
    EuPopCasNumbers = 'EuPopCasNumbers',
    PfasCompliant = 'PfasCompliant',
    PfasCasNumbers = 'PfasCasNumbers',
}

export const BomExportTemplateRuntype = z.object({
    id: z.string(),
    name: z.string(),
    description: z.string().nullable(),
    is_default: z.boolean(),
    included_columns: z.array(z.nativeEnum(BomExportColumn)),
    last_modified: z.string(),
    aggregate: z.boolean(),
    include_summary_sheet: z.boolean(),
});

export type BomExportTemplate = z.infer<typeof BomExportTemplateRuntype>;

export const BomExportTemplateInputRuntype = z.object({
    name: z.string(),
    description: z.string().nullable(),
    is_default: z.boolean(),
    included_columns: z.array(z.nativeEnum(BomExportColumn)),
    aggregate: z.boolean(),
    include_summary_sheet: z.boolean(),
});
