import * as z from 'zod';
import { ApiIntegrationStatusRuntype } from './3rdPartyBackendTypes';

export interface TmeCredentialsDTO extends z.infer<typeof TmeCredentialsDTORuntype> {}
export const TmeCredentialsDTORuntype = z.object({
    app_token: z.string(),
    app_secret: z.string(),
});

export interface TmeCredentialsInputDTO extends z.infer<typeof TmeCredentialsInputDTORuntype> {}
export const TmeCredentialsInputDTORuntype = z.object({
    app_token: z.string().optional(),
    app_secret: z.string().optional(),
});

export interface TmeResponseBodyDTO extends z.infer<typeof TmeResponseBodyDTORuntype> {}
export const TmeResponseBodyDTORuntype = z.object({
    data: TmeCredentialsDTORuntype.nullable(),
    status: ApiIntegrationStatusRuntype,
});
