import * as z from 'zod';
import { endpoint } from '../http/endpoint';
import { GudecoCredentialsInputDTORuntype, GudecoResponseBodyDTORuntype } from './gudecoBackendTypes';

export const gudecoEndpoints = {
    'GET /3rdparty/gudeco/credentials': endpoint({
        description: 'Returns credentials for gudeco integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: GudecoResponseBodyDTORuntype,
    }),
    'POST /3rdparty/gudeco/credentials': endpoint({
        description: 'Uploads credentials for gudeco API integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: GudecoCredentialsInputDTORuntype,
        responseBody: GudecoResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/gudeco/credentials'],
    }),
    'PATCH /3rdparty/gudeco/credentials': endpoint({
        description: 'Updates credentials for gudeco API integratoin',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: GudecoCredentialsInputDTORuntype,
        responseBody: GudecoResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/gudeco/credentials'],
    }),
    'DELETE /3rdparty/gudeco/credentials': endpoint({
        description: 'Deletes existing credentials for gudeco API integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({ deleted: z.number() }),
        invalidates: ['GET /3rdparty/gudeco/credentials'],
    }),
};
