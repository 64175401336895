import * as z from 'zod';

interface Version extends z.infer<typeof VersionRuntype> {}
const VersionRuntype = z.object({
    creation_date: z.string(),
    path: z.string(),
});

export interface QuotationProcessDTO {
    id: string;
    name: string;
    order_confirmations: Version[];
    orders: Version[];
    quotations: QuotationDTO[];
    rfq: string;
}

interface QuotationDTO {
    valid_until: string;
    version: Version;
}
