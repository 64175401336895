import * as z from 'zod';
import { endpoint } from '../http/endpoint';
import {
    MyArrowCredentialsInputDTORuntype,
    MyArrowLocaleCurrencyDTORuntype,
    MyArrowResponseBodyDTORuntype,
} from './myArrowBackendTypes';

export const myArrowEndpoints = {
    'GET /3rdparty/myarrow/credentials': endpoint({
        description: 'Returns credentials for myArrow integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: MyArrowResponseBodyDTORuntype,
    }),
    'POST /3rdparty/myarrow/credentials': endpoint({
        description: 'Uploads credentials for myArrow API integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: MyArrowCredentialsInputDTORuntype,
        responseBody: MyArrowResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/myarrow/credentials'],
    }),
    'PATCH /3rdparty/myarrow/credentials': endpoint({
        description: 'Updates credentials for myArrow API integratoin',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: MyArrowCredentialsInputDTORuntype,
        responseBody: MyArrowResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/myarrow/credentials'],
    }),
    'DELETE /3rdparty/myarrow/credentials': endpoint({
        description: 'Deletes existing credentials for myArrow API integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({ deleted: z.number() }),
        invalidates: ['GET /3rdparty/myarrow/credentials'],
    }),
    'GET /3rdparty/myarrow/supported-currencies': endpoint({
        description: 'Returns possible currencies for myArrow',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({ currencies: z.array(MyArrowLocaleCurrencyDTORuntype) }),
    }),
};
