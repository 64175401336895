import { isPresent } from '@luminovo/commons';
import { SolutionConfigurationDTO, SolutionTag } from '@luminovo/http-client';
import { Solution } from '../types';
import { hasSolutionTag } from './hasSolutionTag';

export function hasSolutionConfigurationManualCost({
    manual_one_time_costs,
    manual_total_cost_of_ownership,
}: Pick<SolutionConfigurationDTO, 'manual_one_time_costs' | 'manual_total_cost_of_ownership'>): boolean {
    return (
        (isPresent(manual_one_time_costs) && manual_one_time_costs.length > 0) ||
        isPresent(manual_total_cost_of_ownership)
    );
}

export enum SolutionSelectionType {
    AUTO_SELECTED = 'AUTO_SELECTED',
    MANUALLY_SELECTED = 'MANUALLY_SELECTED',
}

export function getSolutionSelectionType(solution: Solution | undefined): SolutionSelectionType {
    if (!isPresent(solution)) {
        return SolutionSelectionType.AUTO_SELECTED;
    }

    if (hasSolutionTag(solution, SolutionTag.AutoSelected) && !hasSolutionTag(solution, SolutionTag.Selected)) {
        return SolutionSelectionType.AUTO_SELECTED;
    }

    if (hasSolutionTag(solution, SolutionTag.Selected)) {
        return SolutionSelectionType.MANUALLY_SELECTED;
    }

    return SolutionSelectionType.AUTO_SELECTED;
}
