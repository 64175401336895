import { arrayMove } from '@dnd-kit/sortable';
import { assertUnreachable, isPresent } from '@luminovo/commons';
import { colorSystem } from '@luminovo/design-system';
import { PCBV2CadFile, PCBV2CadFilesRuntype, PCBV2File } from '@luminovo/http-client';
import React from 'react';
import { isDrillFileType } from '../../../../resources/pcb/pcbFunctions';
import { StateFileType, getInitialItemState } from '../../components/LayerAssignment/utils/layerAssignmentsUtils';
import { CadViewerDropdownColors } from '../components/CadFileColorSelector';
import { CadViewerAction, CadViewerState } from './cadViewerTypes';
import { compareCadFiles } from './compareCadFiles';

const hashStringToColor = (fileName: string): string => {
    const colorsExceptWhite = CadViewerDropdownColors.filter((color) => color !== colorSystem.neutral.white);
    const index = fileName
        .split('')
        .map((s) => s.charCodeAt(0))
        .reduce((a, b) => a + b, 0);
    return colorsExceptWhite[index % colorsExceptWhite.length];
};

const sortCadFiles = (files: PCBV2File[]): PCBV2File[] => {
    const sortedFileTypes = [
        StateFileType.MechanicalFiles,
        StateFileType.PasteTop,
        StateFileType.SilkscreenTop,
        StateFileType.SoldermaskTop,
        StateFileType.Copper,
        StateFileType.SoldermaskBottom,
        StateFileType.SilkscreenBottom,
        StateFileType.PasteBottom,
    ];
    const containerFiles = getInitialItemState(files);
    return sortedFileTypes
        .map((fileType) => {
            return containerFiles[fileType] !== undefined ? containerFiles[fileType].files : [];
        })
        .flat();
};

const getCadFiles = (pcbFiles: PCBV2File[]): PCBV2CadFile[] => {
    const cadFiles: PCBV2File[] = pcbFiles.filter((file) => {
        return (
            isDrillFileType(file.fileType.fileType) === false &&
            (file.fileType.mimeType === 'text/gerber' || file.fileType.mimeType?.startsWith('text/odb'))
        );
    });
    const sortedFiles = sortCadFiles(cadFiles);

    return sortedFiles.map((file, index) => ({
        ...file,
        id: file.name,
        isVisible: index === 0,
        fileColor: hashStringToColor(file.name),
        boundingBox: undefined,
    }));
};

const cadFileReducer = (state: CadViewerState, action: CadViewerAction) => {
    switch (action.type) {
        case 'LOAD_CAD_FILES':
            const { files } = loadInitialState(action.payload);

            if (compareCadFiles(state.files, files)) {
                return state;
            }

            return {
                ...state,
                files,
            };

        case 'UPDATE_CAD_COLOR':
            return {
                ...state,
                files: state.files.map((file) => {
                    if (file.id === action.payload.id) {
                        return {
                            ...file,
                            fileColor: action.payload.fileColor,
                        };
                    }
                    return file;
                }),
            };

        case 'TOGGLE_CAD_VISIBILITY':
            return {
                ...state,
                files: state.files.map((file) => {
                    if (file.id === action.payload.id) {
                        return {
                            ...file,
                            isVisible: action.payload.isVisible,
                        };
                    }
                    return file;
                }),
            };

        case 'UPDATE_CAD_MEASUREMENTS':
            const updatedFiles = state.files.map((file) => {
                if (file.id === action.payload.id) {
                    return {
                        ...file,
                        boundingBox: action.payload.viewBox,
                    };
                }
                return file;
            });

            const maxViewBox = updatedFiles
                .filter((file) => file.isVisible)
                .reduce((maxViewBox, { boundingBox }) => {
                    if (!isPresent(boundingBox)) {
                        return maxViewBox;
                    }
                    const fileViewBoxArea = Math.abs(boundingBox.width * boundingBox.height);
                    const accViewBoxArea = Math.abs(maxViewBox.width * maxViewBox.height);

                    return fileViewBoxArea > accViewBoxArea ? boundingBox : maxViewBox;
                }, state.maxViewBox);

            const maxViewBoxArea = Math.abs(maxViewBox.width * maxViewBox.height);
            const newViewBoxArea = Math.abs(action.payload.viewBox.width * action.payload.viewBox.height);

            return {
                ...state,
                files: updatedFiles,
                maxViewBox: maxViewBoxArea > newViewBoxArea ? maxViewBox : action.payload.viewBox,
            };

        case 'MOVE_CAD_FILE':
            return {
                ...state,
                files: arrayMove(state.files, action.payload.activeId, action.payload.overId),
            };

        case 'TOGGLE_ALL_LAYER_VISIBILITY':
            const isAllLayerVisible = state.files.every((file) => file.isVisible);

            return {
                ...state,
                files: state.files.map((file) => {
                    return {
                        ...file,
                        isVisible: !isAllLayerVisible,
                    };
                }),
            };

        case 'RESET_CAD_MEASUREMENTS':
            return {
                ...state,
                files: state.files.map((file) => {
                    if (file.id === action.payload.id) {
                        return {
                            ...file,
                            boundingBox: undefined,
                        };
                    }
                    return file;
                }),
            };

        default:
            assertUnreachable(action);
    }
};

export const getAssemblyCadStorageKey = (pcbId: string) => {
    return `pcb-cad-${pcbId}`;
};

const loadInitialState = ({ pcbFiles, assemblyId }: { pcbFiles: PCBV2File[]; assemblyId: string }): CadViewerState => {
    const result = localStorage.getItem(getAssemblyCadStorageKey(assemblyId));
    // If there is nothing saved in storage, return the default state
    if (typeof result === 'string') {
        try {
            const parsedFiles = JSON.parse(result);
            if (PCBV2CadFilesRuntype.parse(parsedFiles))
                return {
                    files: parsedFiles,
                    maxViewBox: {
                        x: 0,
                        y: 0,
                        width: 0,
                        height: 0,
                    },
                };
        } catch {}
    }

    return {
        files: getCadFiles(pcbFiles),
        maxViewBox: {
            x: 0,
            y: 0,
            width: 0,
            height: 0,
        },
    };
};

export function useCadFileState({ pcbFiles, assemblyId }: { pcbFiles: PCBV2File[]; assemblyId: string }): {
    state: CadViewerState;
    dispatch: React.Dispatch<CadViewerAction>;
} {
    const reduceAndSaveState = React.useCallback(
        (state: CadViewerState, action: CadViewerAction) => {
            const newState = cadFileReducer(state, action);
            // if (action.type !== 'LOAD_CAD_FILES') {
            //     localStorage.setItem(getAssemblyCadStorageKey(assemblyId), JSON.stringify(newState.files));
            // }
            return newState;
        },
        [], //  [assemblyId],
    );

    const [state, dispatch] = React.useReducer(reduceAndSaveState, { pcbFiles, assemblyId }, loadInitialState);

    return {
        state,
        dispatch,
    };
}
