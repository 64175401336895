import * as z from 'zod';
import { endpoint } from '../http/endpoint';
import { CurrentUserUpdateDTORuntype, UserDTORuntype } from '../user/userBackendTypes';
import { CurrentUserDetailsDTORuntype, VersionInformationDTORuntype } from './userBasedBackendTypes';

export const userBasedEndpoints = {
    'GET /my/details': endpoint({
        description: 'Fetches the details of the logged-in user',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({ data: CurrentUserDetailsDTORuntype }),
    }),

    'GET /my/settings/account-security': endpoint({
        description: 'Fetches the account security settings of the logged-in user',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({ data: z.object({ use_mfa: z.boolean() }) }),
    }),

    'GET /my/version': endpoint({
        description: 'Fetches the current version of the backend',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({ data: VersionInformationDTORuntype }),
    }),

    'GET /my/magicbell': endpoint({
        description: '',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({
            data: z.object({
                external_id: z.string(),
                hmac: z.string(),
            }),
        }),
    }),

    'GET /my/intercom': endpoint({
        description: '',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({
            data: z.object({
                hmac: z.string(),
                user_id: z.string(),
            }),
        }),
    }),

    'PATCH /my/user': endpoint({
        description: 'Updates the details of the logged-in user',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: CurrentUserUpdateDTORuntype,
        responseBody: z.object({ data: UserDTORuntype }),
    }),

    'PATCH /my/settings/account-security': endpoint({
        description: 'Updates the account security settings of the logged-in user',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.object({ use_mfa: z.boolean() }),
        responseBody: z.object({ data: z.object({ use_mfa: z.boolean() }) }),
        invalidates: ['GET /my/settings/account-security'],
    }),
};
