import { CostConfig } from '../../types/conversionTypes';
import { Row } from '../../types/rowTypes';
import { convertScenarioCostsToColumns } from './convertScenarioCostToTableColumns';
import { convertTableColumnsToRows } from './convertTableColumnsToRows';

export const convertCostsToRows = ({
    costs,
    calculationAssemblyDetails,
    preferredCurrency,
    isCalculationWithoutManufacturing,
    projectCostBreakdownConfig,
    excessMaterialBreakdownConfig,
}: CostConfig): Row[] => {
    // First sort by order_size and convert to columns (with batch sizes)
    const unsortedColumns = costs
        .sort((costA, costB) => costA.order_size - costB.order_size)
        .map((body) => convertScenarioCostsToColumns(body, preferredCurrency, calculationAssemblyDetails))
        .flat();

    // Sort the flattened columns by orderSize first, then batchSize
    const columns = unsortedColumns.sort((a, b) => {
        // First sort by orderSize
        if (a.orderSize !== b.orderSize) {
            return a.orderSize - b.orderSize;
        }
        // Then by batchSize if orderSize is the same
        if (a.batchSize !== b.batchSize) {
            return a.batchSize - b.batchSize;
        }
        // Then by sourcingCombinationId if orderSize and batchSize are the same
        return a.sourcingCombinationId.localeCompare(b.sourcingCombinationId);
    });

    return convertTableColumnsToRows({
        columns,
        calculationAssemblyDetails,
        preferredCurrency,
        isCalculationWithoutManufacturing,
        projectCostBreakdownConfig,
        excessMaterialBreakdownConfig,
    });
};
