import { compareByString } from '@luminovo/commons';

export interface InternalNumberComparable {
    internal_number: null | string;
    name: string;
}

export function compareByInternalNumber<T extends InternalNumberComparable>(r1: T, r2: T): number {
    // compares by internal number first if available and by name second
    // resources with no internal number are sorted to the end
    if (r1.internal_number === null && r2.internal_number === null) {
        return compareByString(r1.name, r2.name);
    } else if (r1.internal_number === null) {
        return 1;
    } else if (r2.internal_number === null) {
        return -1;
    } else {
        const internalNumberComparison = compareByString(r1.internal_number, r2.internal_number);
        if (internalNumberComparison === 0) {
            return compareByString(r1.name, r2.name);
        } else {
            return internalNumberComparison;
        }
    }
}
