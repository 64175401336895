import * as z from 'zod';
import { DriverIdParameterRuntype } from '../driver';
import { endpoint } from '../http/endpoint';
import { ManufacturingEntityStatusRuntype } from '../sharedManufacturingBackendTypes';
import {
    ActivitiesDTORuntype,
    ActivityDTORuntype,
    ActivityOverwriteDTORuntype,
    ActivityOverwritePatchDTORuntype,
    ActivityOverwritePostDTORuntype,
    ActivityPostDTORuntype,
} from './activityBackendTypes';

export const activitiesEndpoints = {
    'POST /activities': endpoint({
        description: 'Creates an activity',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: ActivityPostDTORuntype,
        responseBody: z.object({
            data: ActivityPostDTORuntype,
        }),
    }),

    'GET /activities': endpoint({
        description: 'Returns an array of activities',
        pathParams: z.undefined(),
        queryParams: z.object({
            active: z.boolean().optional(),
            resource_id: z.string().optional(),
            driver_id: DriverIdParameterRuntype.optional(),
        }),
        requestBody: z.undefined(),
        responseBody: z.object({
            data: ActivitiesDTORuntype,
        }),
    }),
    'GET /activities/:activityId': endpoint({
        description: 'Returns a single activity',
        pathParams: z.object({ activityId: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({
            data: ActivityDTORuntype,
        }),
    }),
    'PATCH /activities/:activityId': endpoint({
        description: 'Updates an activity',
        pathParams: z.object({ activityId: z.string() }),
        queryParams: z.undefined(),
        requestBody: ActivityPostDTORuntype.or(z.object({ status: ManufacturingEntityStatusRuntype })),
        responseBody: z.object({
            data: ActivityPostDTORuntype,
        }),
    }),

    'DELETE /activities/:activityId': endpoint({
        description: 'Deletes an activity',
        pathParams: z.object({ activityId: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({ deleted: z.number() }),
    }),

    'POST /activities/:activityId/overwrites': endpoint({
        description: 'Creates an activity overwrite for a specific manufacturing scenario',
        pathParams: z.object({
            activityId: z.string(),
        }),
        queryParams: z.undefined(),
        requestBody: ActivityOverwritePostDTORuntype,
        responseBody: z.object({
            data: ActivityOverwriteDTORuntype,
        }),
        invalidates: ['GET /manufacturing-scenarios/:manufacturingScenarioId/full-summary'],
    }),

    'PATCH /activities/:activityId/overwrites/:activityOverwriteId': endpoint({
        description: 'Updates an activity overwrite for a specific manufacturing scenario',
        pathParams: z.object({
            activityId: z.string(),
            activityOverwriteId: z.string(),
        }),
        queryParams: z.undefined(),
        requestBody: ActivityOverwritePatchDTORuntype,
        responseBody: z.object({
            data: ActivityOverwriteDTORuntype,
        }),
        invalidates: ['GET /manufacturing-scenarios/:manufacturingScenarioId/full-summary'],
    }),

    'DELETE /activities/:activityId/overwrites': endpoint({
        description: 'Deletes an activity overwrite',
        pathParams: z.object({ activityId: z.string() }),
        queryParams: z.object({ manufacturing_scenario: z.string() }),
        requestBody: z.undefined(),
        responseBody: z.object({ deleted: z.number() }),
        invalidates: ['GET /activities', 'GET /manufacturing-scenarios/:manufacturingScenarioId/full-summary'],
    }),

    'GET /activities/:activityId/overwrites': endpoint({
        description: 'Fetch activity overwrite for activity manufacturing scenario combination',
        pathParams: z.object({
            activityId: z.string(),
        }),
        queryParams: z.object({ manufacturing_scenario: z.string() }),
        requestBody: z.undefined(),
        responseBody: z.object({
            data: ActivityOverwriteDTORuntype,
        }),
    }),
};
