/* eslint-disable import/no-unused-modules */
import * as z from 'zod';

const PCBAnalysisStreamMessageRuntype = z.object({
    t: z.literal('analysis'),
});

const PCBLifecycleStreamMessageRuntype = z.object({
    t: z.literal('lifecycle'),
    m: z.object({
        lifecycle: z.array(
            z.object({
                name: z.string(),
                status: z.object({
                    name: z.string(),
                }),
            }),
        ),
    }),
});

const PCBFilesStreamMessageRuntype = z.object({
    t: z.literal('file'),
});

const PCBPingStreamMessageRuntype = z.object({
    t: z.literal('ping'),
});

export type PCBStreamMessage = z.infer<typeof PCBStreamMessageRuntype>;
export const PCBStreamMessageRuntype = z.union([
    PCBAnalysisStreamMessageRuntype,
    PCBLifecycleStreamMessageRuntype,
    PCBFilesStreamMessageRuntype,
    PCBPingStreamMessageRuntype,
]);
