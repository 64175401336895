import * as z from 'zod';
import { endpoint } from '../http/endpoint';
import { RochesterCredentialsInputDTORuntype, RochesterResponseBodyDTORuntype } from './rochesterBackendTypes';

export const rochesterEndpoints = {
    'GET /3rdparty/rochester/credentials': endpoint({
        description: 'Returns credentials for rochester integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: RochesterResponseBodyDTORuntype,
    }),
    'POST /3rdparty/rochester/credentials': endpoint({
        description: 'Uploads credentials for rochester API integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: RochesterCredentialsInputDTORuntype,
        responseBody: RochesterResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/rochester/credentials'],
    }),
    'PATCH /3rdparty/rochester/credentials': endpoint({
        description: 'Updates credentials for rochester API integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: RochesterCredentialsInputDTORuntype,
        responseBody: RochesterResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/rochester/credentials'],
    }),
};
