/* eslint-disable spellcheck/spell-checker */
import { plural, t, Trans } from '@lingui/macro';
import { getToken } from '@luminovo/auth';
import {
    CenteredLayout,
    colorSystem,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Flexbox,
    PrimaryButton,
    SecondaryButton,
    Text,
} from '@luminovo/design-system';
import {
    BomImporterErrorResponseRuntype,
    BomImporterLine,
    BomLineBuildingOutput,
    BomScreeningSheet,
    ColumnMap,
    ColumnName,
    IssueNameEnum,
    parseRuntype,
    PartSuggestionReasonEnum,
} from '@luminovo/http-client';
import { WarningRounded } from '@mui/icons-material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { useHistory } from 'react-router-dom';
import { SpinnerWithBackdrop } from '../../components/Spinners';
import { postImportBom, useUploadBomToAzure } from '../../resources/bomImporter/bomImporterHandler';
import { Level } from '../../resources/bomImporter/bomImporterIssuesEnum';
import { httpQueryKey } from '../../resources/http/httpQueryKey';
import { invalidateAllQueriesForEndpoint } from '../../resources/http/useHttpQuery';
import { route } from '../../utils/routes';
import { ViewContext } from '../Bom/components/ModuleTableData';
import {
    ColumnTagsSheetsAction,
    ColumnTagsSheetsState,
} from '../DesignItemDetails/components/AutocompleteColumnTags/types';
import { BomTable } from '../DesignItemDetails/components/BomTable/BomTable';
import { BomIssueItem } from './components/bomImporterDialogComponents';
import { BomImporterNavbar } from './components/BomImporterNavbar';
import { BomImporterScreeningLayout } from './components/BomImporterScreeningLayout';
import { Transition } from './StyledDialog';

export function BomImporterLinesDialog({
    open,
    bomImporterLineBuildingOutput,
    bomScreeningSheet,
    assemblyId,
    bomImporterFile,
    onClose,
    onBack,
    errorAndResetBomStateCallback,
    rfqId,
    columnMap,
    viewContext,
    tagsState,
    dispatch,
    fileName,
}: {
    bomImporterLineBuildingOutput: BomLineBuildingOutput;
    bomScreeningSheet: BomScreeningSheet;
    open: boolean;
    assemblyId: string;
    bomImporterFile: File;
    onClose: () => void;
    onBack: () => void;
    rfqId: string;
    errorAndResetBomStateCallback: (message: string) => void;
    columnMap: ColumnMap;
    viewContext: ViewContext;
    tagsState: ColumnTagsSheetsState;
    dispatch: React.Dispatch<ColumnTagsSheetsAction>;
    fileName: string;
}) {
    const queryClient = useQueryClient();
    const history = useHistory();
    const token = getToken();

    const { mutateAsync: uploadBomToAzure } = useUploadBomToAzure({ assemblyId });

    const allLines: BomImporterLine[] = React.useMemo(() => {
        return Object.values(bomImporterLineBuildingOutput.lines_in_assembly).flat();
    }, [bomImporterLineBuildingOutput]);

    const filteredLinesWithErrors = React.useMemo(() => {
        return allLines.filter((line) => line.issues.some((issue) => issue.level === Level.Error));
    }, [allLines]);
    const hasAtLeastOneErrorInBom = filteredLinesWithErrors.length > 0;

    const countBomItemsWithChangesFromPrevBomImport = React.useMemo(() => {
        return allLines.reduce((count, line) => {
            return (
                count +
                (line.bom_item?.part_suggestions.some((suggestion) => suggestion.origin?.column === ColumnName.Previous)
                    ? 1
                    : 0)
            );
        }, 0);
    }, [allLines]);

    const countSuggestionsFromPrevBomImport = React.useMemo(() => {
        return allLines.reduce((acc, currLine) => {
            let count = 0;
            currLine.bom_item?.part_suggestions.forEach((suggestion) => {
                // We don't want to consider removed part options here as they would not be automatically added
                if (
                    suggestion.origin?.column === ColumnName.Previous &&
                    !suggestion.origin?.reasons.some(
                        (reason) => reason.name === PartSuggestionReasonEnum.PreviousImportRemovedPartOption,
                    )
                ) {
                    count++;
                }
            });
            return acc + count;
        }, 0);
    }, [allLines]);
    const hasSuggestionsFromPrevBomImport = countBomItemsWithChangesFromPrevBomImport > 0;

    const [isErrorsInBomWarningModalOpen, setIsErrorsInBomWarningModalOpen] =
        React.useState<boolean>(hasAtLeastOneErrorInBom);

    React.useEffect(() => {
        if (hasAtLeastOneErrorInBom) {
            setIsErrorsInBomWarningModalOpen(true);
        }
    }, [hasAtLeastOneErrorInBom]);

    const { mutateAsync, isPending } = useMutation({
        mutationFn: ({ acceptPrevBomLines }: { acceptPrevBomLines: boolean }) =>
            postImportBom(
                {
                    bomImporterLineBuildingOutput,
                    headerMetadata: bomScreeningSheet.header_metadata,
                    importingAssemblyId: assemblyId,
                    columnMap,
                },
                acceptPrevBomLines,
                tagsState.previousLinesSearch.variant,
            ),
        onSuccess: async (data) => {
            if (data) {
                const bomFileId = data.data.bom_file_id;
                await uploadBomToAzure({ pathParams: { bomFileId }, files: bomImporterFile });
            }
            await Promise.allSettled([
                queryClient.invalidateQueries({ queryKey: httpQueryKey('POST /parts/off-the-shelf/bulk') }),
                queryClient.invalidateQueries({ queryKey: httpQueryKey('POST /design-items/bulk') }),
                queryClient.invalidateQueries({ queryKey: httpQueryKey('POST /parts/generic/bulk') }),
                queryClient.invalidateQueries({ queryKey: httpQueryKey('POST /ipns/bulk') }),
                invalidateAllQueriesForEndpoint('GET /assemblies/:assemblyId/descendants', queryClient),
                invalidateAllQueriesForEndpoint('GET /assemblies/:assemblyId/descendants-summary', queryClient),
            ]);
            const importEventState: BomImportEventState = { imported: true, columnMap: columnMap };
            const detailsPage =
                viewContext.type === 'WithinRfQ'
                    ? route('/rfqs/:rfqId/bom/assembly/:assemblyId/details', { assemblyId, rfqId })
                    : route('/assemblies/:assemblyId/details', { assemblyId });
            history.push({
                pathname: detailsPage,
                state: importEventState,
            });
        },
        onError: async (error) => {
            let errorMessage = t`Could not upload BOM.`;

            if (parseRuntype(BomImporterErrorResponseRuntype, error)) {
                if (error.code === 'bom_import.duplicate_designators') {
                    errorMessage +=
                        ' ' +
                        t`The BOM you are trying to import has a conflicting designator. To fix this, remove the ${error.detail} designator in Luminovo and import again.`;
                } else {
                    errorMessage += error.detail;
                }
            }

            errorAndResetBomStateCallback(errorMessage);
        },
    });

    function closeErrorsInBomWarningModal() {
        setIsErrorsInBomWarningModalOpen(false);
    }

    // Hack: In strict mode useEffect runs twice, but we only want to run the import once,
    // so we use a ref to track if the effect has already run. This should be fixed soon.
    const effectRan = React.useRef(false);
    React.useEffect(() => {
        if (effectRan.current) {
            return;
        }

        const importBomCallback = async () => {
            if (hasAtLeastOneErrorInBom) {
                return;
            }
            if (hasSuggestionsFromPrevBomImport) {
                return;
            }
            try {
                // Mark effect as having been run
                effectRan.current = true;
                await mutateAsync({ acceptPrevBomLines: false });
                // eslint-disable-next-line no-empty
            } catch (error) {}
        };
        importBomCallback();
    }, [token, hasAtLeastOneErrorInBom, hasSuggestionsFromPrevBomImport, mutateAsync]);

    return (
        <Dialog
            fullScreen
            maxWidth={'md'}
            open={open}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    onClose();
                }
            }}
            TransitionComponent={Transition}
            disableEscapeKeyDown={true}
            sx={{
                paperFullScreen: {
                    backgroundColor: colorSystem.neutral[1],
                    overflow: 'hidden',
                },
            }}
        >
            <BomImporterScreeningLayout
                navbar={
                    <BomImporterNavbar
                        onClose={onClose}
                        handleImportBom={() => {}}
                        isReusedColumnMapping={bomScreeningSheet.reused_column_map}
                        isImportDisabled={hasAtLeastOneErrorInBom}
                        isLoadingSpinnerShown={isPending}
                        fileName={fileName}
                        hasLevelTagAssigned={bomScreeningSheet.global_issues_response.some(
                            (issue) => issue.name === IssueNameEnum.MULTI_LEVEL_BOM_STRUCTURE_DETECTED,
                        )}
                    />
                }
                badgeCount={0}
            >
                {isPending && (
                    <CenteredLayout>
                        <SpinnerWithBackdrop noBackdrop={true} />
                    </CenteredLayout>
                )}
                <ErrorsInBomDialog
                    isOpen={isErrorsInBomWarningModalOpen}
                    onClose={closeErrorsInBomWarningModal}
                    filteredLinesWithErrors={filteredLinesWithErrors}
                />
                {hasAtLeastOneErrorInBom && (
                    <BomTable
                        excelRows={allLines.flatMap((line) =>
                            line.excel_origins.map((excelOrigin) => ({
                                ...excelOrigin,
                                issues: line.issues,
                            })),
                        )}
                        columnTags={{ tagsState, dispatch }}
                        headerRowJson={bomScreeningSheet.header_metadata?.raw_header_row}
                    />
                )}

                <Dialog
                    open={hasSuggestionsFromPrevBomImport}
                    PaperProps={{ style: { padding: '12px', maxWidth: '624px' } }}
                >
                    <DialogTitle title={t`Reuse part options from previous import`} />
                    <DialogContent style={{ paddingBottom: 0 }}>
                        <Flexbox>
                            <Text style={{ whiteSpace: 'pre-line' }}>
                                {plural(countBomItemsWithChangesFromPrevBomImport, {
                                    one: `Would you like to add ${countSuggestionsFromPrevBomImport} additional part option in ${countBomItemsWithChangesFromPrevBomImport} BOM item for matching lines from a previous import? 
                                {br}
                                After the import you can filter for BOM items that match lines from a previous import and have had part options manually added, removed, or modified.
                                {br}
                                We automatically sync the approval status or remove part options based on previous imports in any case.`,
                                    other: `Would you like to add ${countSuggestionsFromPrevBomImport} additional part options in ${countBomItemsWithChangesFromPrevBomImport} BOM items for matching lines from a previous import?
                                {br}
                                After the import you can filter for BOM items that match lines from a previous import and have had part options manually added, removed, or modified.
                                {br}
                                We automatically sync the approval status or remove part options based on previous imports in any case.`,
                                })}
                            </Text>
                        </Flexbox>
                    </DialogContent>
                    <DialogActions>
                        <SecondaryButton
                            onClick={() => mutateAsync({ acceptPrevBomLines: false })}
                            size="medium"
                            disabled={isPending}
                        >
                            <Trans>No, keep in suggestions</Trans>
                        </SecondaryButton>
                        <PrimaryButton
                            onClick={() => mutateAsync({ acceptPrevBomLines: true })}
                            size="medium"
                            disabled={isPending}
                        >
                            <Trans>Add parts</Trans>
                        </PrimaryButton>
                    </DialogActions>
                </Dialog>
            </BomImporterScreeningLayout>
        </Dialog>
    );
}

// TODO: @Rene thinks this can be removed but it not 100% sure
interface BomImportEventState {
    imported: true;
    columnMap: ColumnMap;
}

const ErrorsInBomDialog = ({
    isOpen,
    onClose,
    filteredLinesWithErrors,
}: {
    isOpen: boolean;
    onClose: () => void;
    filteredLinesWithErrors: BomImporterLine[];
}) => {
    return (
        <Dialog open={isOpen}>
            <DialogTitle
                title={
                    <Flexbox alignItems="center">
                        <WarningRounded style={{ color: colorSystem.red[6], marginRight: 8 }} />
                        <Trans>BOM contains errors</Trans>
                    </Flexbox>
                }
            />
            <DialogContent>
                <Flexbox flexDirection="column" gap={8}>
                    <Text>
                        <Trans>
                            You cannot edit or import the BOM because it contains errors. Please fix the original file
                            and upload it again.
                        </Trans>
                    </Text>

                    {filteredLinesWithErrors.map((line, i) =>
                        line.issues.map((issue) => {
                            if (issue.level === Level.Error) {
                                return (
                                    <Flexbox key={i} alignItems="center">
                                        <BomIssueItem
                                            issue={issue}
                                            lineNumber={
                                                line.excel_origins.length > 0
                                                    ? line.excel_origins[0].line_number
                                                    : undefined
                                            }
                                        />
                                    </Flexbox>
                                );
                            }
                            return <></>;
                        }),
                    )}
                </Flexbox>
            </DialogContent>
            <DialogActions>
                <SecondaryButton size="medium" onClick={onClose}>
                    <Trans>OK</Trans>
                </SecondaryButton>
            </DialogActions>
        </Dialog>
    );
};
