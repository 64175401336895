import { t } from '@lingui/macro';
import { isPresent } from '@luminovo/commons';
import { FieldNumericControlled, FieldTextControlled, Flexbox, FormItem, TextField } from '@luminovo/design-system';
import { ApprovalStatus, GenericFullPart, GenericPartTypes, GenericResistor, RfqContext } from '@luminovo/http-client';
import { FieldPackageControlled } from '@luminovo/sourcing-core';
import { FormControl, InputAdornment, Typography } from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';
import { CancelButton } from '../../../components/formLayouts/CancelButton';
import { FormContainer } from '../../../components/formLayouts/FormContainer';
import { SubmitButton } from '../../../components/formLayouts/SubmitButton';
import { GenericResistorForm } from '../../../resources/designItem/designItemFrontendTypes';
import { useHttpMutation } from '../../../resources/mutation/useHttpMutation';
import { SectionOfScreen } from '../../../resources/part/partFrontendTypes';
import { convertRfqContext, usePartPackages } from '../../../resources/part/partHandler';
import {
    formatResistance,
    powerRatingConverter,
    resistanceConverter,
    toleranceConverter,
    voltageConverter,
} from '../../../utils/converterUtils';
import { convertEmptyStringToNull } from '../designItemFormFunctions';

const initialResistorFormValues: GenericResistorForm = {
    package: undefined,
    approvalStatus: ApprovalStatus.Approved,
    type: GenericPartTypes.Resistor,
    resistance: null,
    powerRating: null,
    tolerance: null,
    temperatureCoefficient: null,
    voltageRating: null,
};

const OHM_SYMBOL = <span>&#8486;</span>;
const LESS_THAN_OR_EQUAL_TO_SYMBOL = <span>&#8804;</span>;
const BIGGER_THAN_OR_EQUAL_TO_SYMBOL = <span>&#8805;</span>;
const PLUS_OR_MINUS_SYMBOL = <span>&#177;</span>;

const FormInner = ({
    onCancel,
    submitButtonLabel,
}: {
    onCancel?: () => void;
    submitButtonLabel?: string | JSX.Element;
}) => {
    const { control } = useFormContext<GenericResistorForm>();
    const { data: partPackages = [] } = usePartPackages('generic-part-creatable');

    const packageDTO = useWatch({
        control,
        name: 'package',
    });

    const resistance = useWatch({
        control,
        name: 'resistance',
    });

    const convertedResistance = resistanceConverter(resistance ?? '');

    return (
        <Flexbox flexDirection={'column'} gap={16}>
            <FormItem label={t`Package`} required>
                <FieldPackageControlled name={'package'} control={control} required={true} options={partPackages} />
            </FormItem>
            <FormItem label={t`Mounting`} required>
                <FormControl>
                    {packageDTO ? (
                        <Typography>{packageDTO.mounting}</Typography>
                    ) : (
                        <TextField size="large" disabled={true} />
                    )}
                </FormControl>
            </FormItem>
            <FormItem label={t`Resistance`} required description={t`We support BSI notation. Try typing 1k2.`}>
                <Flexbox alignItems={'center'} gap={8}>
                    <FieldTextControlled
                        name={'resistance'}
                        control={control}
                        required={true}
                        validate={(x) => {
                            if (typeof x === 'string' && !isPresent(resistanceConverter(x))) {
                                return t`Invalid input`;
                            }
                        }}
                        FieldProps={{
                            InputProps: {
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Typography>{OHM_SYMBOL}</Typography>
                                    </InputAdornment>
                                ),
                            },
                            size: 'large',
                        }}
                    />

                    {convertedResistance && (
                        <Typography variant={'body2'} color={'textSecondary'}>
                            {formatResistance(convertedResistance)}
                        </Typography>
                    )}
                </Flexbox>
            </FormItem>
            <FormItem label={t`Tolerance`}>
                <FieldNumericControlled
                    name={'tolerance'}
                    control={control}
                    FieldProps={{
                        InputProps: {
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Typography>{LESS_THAN_OR_EQUAL_TO_SYMBOL}</Typography>
                                </InputAdornment>
                            ),
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        },
                        size: 'large',
                    }}
                />
            </FormItem>
            <FormItem label={t`Power rating`}>
                <FieldNumericControlled
                    name={'powerRating'}
                    control={control}
                    FieldProps={{
                        InputProps: {
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Typography>{BIGGER_THAN_OR_EQUAL_TO_SYMBOL}</Typography>
                                </InputAdornment>
                            ),
                            endAdornment: <InputAdornment position="end">W</InputAdornment>,
                        },
                        size: 'large',
                    }}
                />
            </FormItem>
            <FormItem label={t`Voltage rating`}>
                <FieldNumericControlled
                    name={'voltageRating'}
                    control={control}
                    FieldProps={{
                        InputProps: {
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Typography>{BIGGER_THAN_OR_EQUAL_TO_SYMBOL}</Typography>
                                </InputAdornment>
                            ),
                            endAdornment: <InputAdornment position="end">V</InputAdornment>,
                        },
                        size: 'large',
                    }}
                />
            </FormItem>
            <FormItem label={t`Temperature coefficient`}>
                <FieldNumericControlled
                    name={'temperatureCoefficient'}
                    control={control}
                    FieldProps={{
                        InputProps: {
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Typography>
                                        {LESS_THAN_OR_EQUAL_TO_SYMBOL} {PLUS_OR_MINUS_SYMBOL}
                                    </Typography>
                                </InputAdornment>
                            ),
                            endAdornment: <InputAdornment position="end">ppm/K</InputAdornment>,
                        },
                        size: 'large',
                    }}
                />
            </FormItem>
            <Flexbox gap={8} justifyContent="flex-end" marginTop={'8px'}>
                <CancelButton onClick={onCancel} />
                <SubmitButton label={submitButtonLabel} />
            </Flexbox>
        </Flexbox>
    );
};

export const AddGenericResistorForm = ({
    rfqContext,
    initialValues = initialResistorFormValues,
    onGenericPartCreation,
    onSettled,
    submitButtonLabel,
}: {
    rfqContext: RfqContext;
    initialValues?: GenericResistorForm;
    onGenericPartCreation: (data: GenericFullPart, sectionOfScreen: SectionOfScreen) => void;
    onSettled: () => void;
    submitButtonLabel?: string | JSX.Element;
}) => {
    const { mutateAsync } = useHttpMutation('POST /parts/generic', {
        snackbarMessage: t`Generic resistor created`,
    });

    const onSubmit = async (values: GenericResistorForm) => {
        const sectionOfScreen: SectionOfScreen =
            initialValues === initialResistorFormValues ? 'genericPartForm' : 'partialMatches';

        const input: GenericResistor = {
            type: GenericPartTypes.Resistor,
            technical_parameters: {
                resistance: convertEmptyStringToNull(resistanceConverter(values.resistance ?? '')?.toString() ?? null),
                power_rating: convertEmptyStringToNull(
                    powerRatingConverter(values.powerRating ?? '')?.toString() ?? null,
                ),
                tolerance: convertEmptyStringToNull(toleranceConverter(values.tolerance ?? '')?.toString() ?? null),
                temperature_coefficient: convertEmptyStringToNull(values.temperatureCoefficient),
                voltage_rating: convertEmptyStringToNull(
                    voltageConverter(values.voltageRating ?? '')?.toString() ?? null,
                ),
                package_id: values.package?.id ?? null,
            },
        };

        const { data } = await mutateAsync({
            requestBody: { input, ...convertRfqContext(rfqContext), recompute_matches: true },
        });
        onGenericPartCreation(data, sectionOfScreen);
        onSettled();
    };

    return (
        <FormContainer defaultValues={initialValues} onSubmit={onSubmit}>
            <FormInner onCancel={onSettled} submitButtonLabel={submitButtonLabel} />
        </FormContainer>
    );
};
