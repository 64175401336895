import * as z from 'zod';
import { CurrencyRuntype } from '../backendTypes';
import { ApiIntegrationStatusRuntype } from './3rdPartyBackendTypes';

export type Element14LocaleSiteDTO = z.infer<typeof Element14LocaleSiteDTORuntype>;
export const Element14LocaleSiteDTORuntype = z.enum([
    'AU',
    'CN',
    'HK',
    'IN',
    'KR',
    'MY',
    'NZ',
    'PH',
    'SG',
    'TH',
    'TW',
    'VN',
]);

export interface Element14CredentialsDTO extends z.infer<typeof Element14CredentialsDTORuntype> {}
export const Element14CredentialsDTORuntype = z.object({
    api_key: z.string().optional(),
    customer_id: z.string().nullable().optional(),
    secret_key: z.string().nullable().optional(),
    store_location: Element14LocaleSiteDTORuntype,
    list_price_currency: CurrencyRuntype,
    contract_price_currency: CurrencyRuntype,
    status: ApiIntegrationStatusRuntype,
});

export interface Element14CredentialsInputDTO extends z.infer<typeof Element14CredentialsInputDTORuntype> {}
export const Element14CredentialsInputDTORuntype = z.object({
    api_key: z.string(),
    customer_id: z.string().nullable(),
    secret_key: z.string().nullable(),
    store_location: Element14LocaleSiteDTORuntype,
    list_price_currency: CurrencyRuntype,
    contract_price_currency: CurrencyRuntype,
});

export interface Element14SupportedSettingDTO extends z.infer<typeof Element14SupportedSettingDTORuntype> {}
export const Element14SupportedSettingDTORuntype = z.object({
    store_location: Element14LocaleSiteDTORuntype,
    list_price_currency: z.array(CurrencyRuntype),
    contract_price_currency: z.array(CurrencyRuntype),
});
