import * as z from 'zod';
import { HttpError } from '../http/HttpError';
import { endpoint } from '../http/endpoint';
import { RegionsEnumRuntype } from '../supplierAndStockLocation';
import {
    PendingPurchaseOrderDraftRuntype,
    PendingPurchaseOrderRuntype,
    PurchaseOrderRuntype,
} from './purchaseOrderTypes';

export const purchaseOrderEndpoints = {
    'GET /purchase-orders/:rfqId': endpoint({
        description: 'Gets all the purchase orders that are pending approval',
        pathParams: z.object({
            rfqId: z.string(),
        }),
        requestBody: z.undefined(),
        queryParams: z.object({
            sourcing_scenario_id: z.string(),
        }),
        responseBody: z.object({
            pending: z.array(PendingPurchaseOrderDraftRuntype),
            created: z.array(PurchaseOrderRuntype),
        }),
    }),
    'POST /purchase-orders/find': endpoint({
        description: 'Gets all the purchase orders',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.object({
            sourcing_scenario_ids: z.array(z.string()).optional(),
            ids: z.array(z.string()).optional(),
            offset: z.number().optional(),
            isSent: z.boolean().optional(),
            is_partially_received: z.boolean().optional(),
            is_received: z.boolean().optional(),
            is_expired_delivery_date: z.boolean().optional(),
            rfq_ids: z.array(z.string()).optional(),
            is_expected_delivery_date_not_set: z.boolean().optional(),
            text: z.string().optional(),
            supplier_and_stock_location_id: z.string().optional(),
        }),
        responseBody: z.object({
            purchase_orders: z.array(PurchaseOrderRuntype),
        }),
    }),
    'POST /purchase-orders/parametric-search-info': endpoint({
        description: 'Gets the parametric search info',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.object({}),
        responseBody: z.object({
            rfqs: z.array(
                z.object({
                    rfq_id: z.string(),
                    rfq_name: z.string(),
                    rfq_ems_internal_number: z.string().nullable(),
                    purchase_orders: z.number(),
                }),
            ),
            suppliers: z.array(
                z.object({
                    id: z.string(),
                    name: z.string(),
                    region: RegionsEnumRuntype,
                    purchase_orders: z.number(),
                }),
            ),
        }),
    }),
    'DELETE /purchase-orders/delete': endpoint({
        description: 'Deletes a purchase order.',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.object({
            id: z.string(),
        }),
        responseBody: z.unknown(),
        invalidates: ['GET /purchase-orders/:rfqId', 'POST /purchase-orders/find'],
    }),
    'POST /purchase-orders/:rfqId': endpoint({
        description: 'Creates a purchase order',
        pathParams: z.object({
            rfqId: z.string(),
        }),
        queryParams: z.object({
            sourcing_scenario_id: z.string(),
        }),
        requestBody: z.object({
            purchase_order: PendingPurchaseOrderRuntype,
        }),
        responseBody: z.unknown(),
        invalidates: [
            'GET /purchase-orders/:rfqId',
            'POST /purchase-orders/find',
            'POST /purchase-orders/parametric-search-info',
        ],
        handleResponse(response, registeredEndpoint) {
            if (response.ok) {
                return response.json();
            }
            if (response.status === 409) {
                throw new HttpError({
                    code: 'purchase_order.conflict',
                    endpoint: registeredEndpoint,
                    status: response.status,
                    statusText: response.statusText,
                    method: 'POST',
                });
            }
            throw new HttpError({
                code: 'unknown',
                endpoint: registeredEndpoint,
                status: response.status,
                statusText: response.statusText,
                method: 'POST',
            });
        },
    }),
    'POST /purchase-orders/send-test-email': endpoint({
        description: 'Send test email',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.object({
            purchase_order: PendingPurchaseOrderRuntype,
            sourcing_scenario_id: z.string(),
            rfq_id: z.string(),
        }),
        responseBody: z.unknown(),
    }),
    'PATCH /purchase-orders/update': endpoint({
        description: 'Creates a purchase order',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.object({
            purchase_orders: z.array(PurchaseOrderRuntype),
        }),
        responseBody: z.object({}),
        invalidates: ['GET /purchase-orders/:rfqId', 'POST /purchase-orders/find'],
        handleResponse(response, registeredEndpoint) {
            if (response.ok) {
                return response.json();
            }
            if (response.status === 409) {
                throw new HttpError({
                    code: 'purchase_order.conflict',
                    endpoint: registeredEndpoint,
                    status: response.status,
                    statusText: response.statusText,
                    method: 'POST',
                });
            }
            throw new HttpError({
                code: 'unknown',
                endpoint: registeredEndpoint,
                status: response.status,
                statusText: response.statusText,
                method: 'POST',
            });
        },
    }),
};
