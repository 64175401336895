import { colorSystem, Flexbox, StatusChip, Tooltip } from '@luminovo/design-system';

type Content = {
    color: keyof typeof colorSystem;
    variant: string;
    description: string;
};

const content: Content[] = [
    {
        color: 'green',
        variant: 'Approved',
        description:
            'Manufacturers are approved by default for all part categories unless you change the status to preferred or blocked.',
    },
    {
        color: 'blue',
        variant: 'Preferred',
        description:
            'If manufacturers are preferred, part options from those manufacturers will be sorted to the top. Preferences defined for part categories are taken into account for generic part matches. Preference changes only affect newly created generic parts. Offers from preferred manufacturers can be prioritised in the solution preferences.',
    },
    {
        color: 'red',
        variant: 'Blocked',
        description:
            "If manufacturers are blocked, they won't be considered for part matches and will also be excluded from generic part matches. Preference changes only affect newly created generic parts and newly imported BOMs. Part options from blocked manufacturers won't be approved automatically. You can manually add them from suggestions.",
    },
];

export function ManufacturerTooltip({ children }: { children: React.ReactElement }) {
    return (
        <Tooltip
            variant="white"
            arrow
            title={
                <Flexbox flexDirection={'column'} gap={12} padding="12px">
                    {content.map((item) => (
                        <Flexbox key={item.color} flexDirection={'column'} gap={8}>
                            <StatusChip color={item.color} label={item.variant} />
                            {item.description}
                        </Flexbox>
                    ))}
                </Flexbox>
            }
        >
            {children}
        </Tooltip>
    );
}
