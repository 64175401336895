import { ExtractRequestBody, ExtractResponseBody, http } from '../../../resources/http';

interface CreateCustomerMockParams {
    customerNumber: string;
    defaultContactPersonId: string;
    name: string;
}

export const createCustomerMock = ({
    customerNumber,
    defaultContactPersonId,
    name,
}: CreateCustomerMockParams): ExtractRequestBody<'POST /customers'> => {
    return {
        customer_number: customerNumber,
        default_contact_person: defaultContactPersonId,
        name,
        language: 'en',
        billing_address: {
            line1: '123 Main St',
            line2: null,
            postal_code: '12345',
            city: 'Anytown',
            country_code: 'US',
        },
        customer_type: {
            type: 'Business',
            data: {
                commercial_register_number: null,
                vat_id: null,
            },
        },
    };
};

export const createCustomerHttpCallWithMock = async ({
    customerNumber,
    defaultContactPersonId,
    name,
    token,
}: CreateCustomerMockParams & { token: string }): Promise<ExtractResponseBody<'POST /customers'>> => {
    return await http(
        'POST /customers',
        { requestBody: createCustomerMock({ customerNumber, defaultContactPersonId, name }) },
        token,
    );
};
