import { Currency, LanguageEnum } from '@luminovo/commons';
import { MonetaryValueBackend, Packaging, PriceType } from '@luminovo/http-client';
import type { AllParserConfigs, ParserConfig } from './parsers/parsers';

export type Row = string[];
export type Table = Array<Row>;

// The duplicate-error status is required for a tooltip to be shown on the icon in the status column.
// Please change if you can think of a better way to handle this.
export type ParseStatus = 'error' | 'warning' | 'done' | 'pending' | 'skipped' | 'duplicate-error';

export type PromiseValue<T> = (
    | { status: 'error' }
    | { status: 'warning'; value: T }
    | { status: 'done'; value: T }
    | { status: 'pending' }
) & { alternatives?: T[]; message?: string };

export type PopupState = {
    text: string;
    value?: ParsedValue<unknown>;
    applyToAll: boolean;
};

export type LinkStatus = 'skipped' | 'insert' | 'update';

export type ImportStatus =
    | {
          success: true;
      }
    | {
          success: false;
          message: string;
      };

export type ImportBatchHandler<TConfig extends ImporterConfig> = (
    records: ImportRecord<TConfig>[],
    globalFields?: Array<GlobalField>,
) => Promise<ImportStatus[]>;

export type TransformRecordActionHandler<TConfig extends ImporterConfig> = (
    records: ImportRecord<TConfig>,
) => ImportRecord<TConfig>['action'];

export type ImportHandlers<TConfig extends ImporterConfig, THandlerRecord> = {
    postprocessRows(
        rows: { row: ImporterRow; tableRecord: ImportRecord<TConfig> }[],
    ): { rows: ImporterRow[]; handlerRecord: THandlerRecord }[];
    handleBatch(handlerRecords: THandlerRecord[]): Promise<ImportStatus[]>;
};

export type ImportHandlersCreator<TConfig extends ImporterConfig, TRecord> = (
    globalFields: GlobalField[] | undefined,
) => ImportHandlers<TConfig, TRecord>;

export interface ImportBatchItem {
    type: Exclude<LinkStatus, 'excluded'>;
    item: unknown;
}

export interface ImporterCell {
    field: ImporterField;
    text: string;
    status: PromiseValue<ParsedValue<unknown>>;
}

export interface ImporterRow {
    index: number;
    skip?: boolean;
    status: ParseStatus;
    cells: ImporterCell[];
    /**
     * When parsing is done, the record contains the data that will be imported.
     */
    record?: ImportRecord<ImporterConfig>;

    import?: ImportStatus;
}

export interface ImporterTable {
    getRows(): ImporterRow[];
    getRowsWithDuplicates(): ImporterRow[];
    /**
     * Updates a cell at a given position
     */
    setCell(position: Position, cell: ImporterCell): ImporterTable;

    /**
     * Updates all of the given rows. Rows are matched by the {@link ImporterRow#index}.
     */
    updateRows(rows: ImporterRow[]): ImporterTable;

    /**
     * Counts duplicates in columns with unique fields.
     */
    getUniqueColumnsWithDuplicates(): { columnIndex: number; duplicates: number; text: string }[];

    /**
     * Updates all cells that match the given position and text with the given value.
     */
    setMatching(position: Pick<Position, 'column'>, text: string, newCell: ImporterCell): ImporterTable;

    /**
     * Get a cell at a given position
     */
    getCell(position: Position): ImporterCell | undefined;

    /**
     * Returns true if the table is ready for the preview stage, meaning all rows have been parsed
     * and all parsing errors have been resolved.
     *
     * @see getReadyForPreviewPercentage
     */
    isReadyForPreview(): boolean;

    /**
     * Returns true if the table is ready to be imported.
     */
    isReadyForImport(): boolean;

    /**
     * Returns the percentage of rows that are ready for the preview stage.
     *
     * @see isReadyForPreview
     */
    getReadyForPreviewPercentage(): number;

    /**
     * Filter rows by parse status. If status is null, all rows are returned.
     */
    filterByParseStatus(status: ParseStatus | null): ImporterRow[];

    /**
     * Filter rows by link status. If status is null, all rows are returned.
     */
    filterByLinkStatus(status: LinkStatus | null): ImporterRow[];

    /**
     * Get a row at a given index
     */
    getRow(index: number): ImporterRow | undefined;

    /**
     * Get the number of rows in the table
     */
    getSize(): number;

    /**
     * A count of the number of rows in each parse status
     */
    getParseStatusCount(): Record<ParseStatus, number>;

    /**
     * A count of the number of rows in each link status
     */
    getLinkStatusCount(): Record<LinkStatus, number>;

    getImportStatusCount(): Record<'done' | 'error', number>;

    /**
     * Counts the number of identical matches in a column.
     *
     * @param colIndex the index of the column
     * @param text the text to match
     */
    countIdenticalMatches(colIndex: number, text: string): number;

    /**
     * Count the number of rows that are included.
     */
    countIncluded(): number;

    /**
     * Runs the parser on all the cells that match the given range.
     */
    applyParsers(_: {
        row: { from: number; to: number };
        column: { from: number; to: number };
    }): Promise<ImporterTable>;

    /**
     * Exclude all rows with errors.
     */
    excludeErrors(): ImporterTable;

    /**
     * Exclude all rows with warnings.
     */
    excludeWarnings(): ImporterTable;

    /**
     * @param exclude if true, exclude all rows with the given indices. If false, include all rows with the given indices.
     */
    excludeAll(exclude: boolean, indices: number[]): ImporterTable;

    /**
     * Skip a row at a given index
     */
    skipRow(index: number): ImporterTable;

    /**
     * Get the items that are ready for import.
     *
     * Exclude all rows that are skipped.
     */
    getImportRecords(): ImportRecord<ImporterConfig>[];
}

/**
 * A ParsedValue represents a value that has been parsed from a string, along with a way to compare and render it.
 *
 * For example, a supplier could be parsed from a supplier ID. We would then compare by the supplier ID and render the supplier name.
 *
 * -The ID is a unique identifier for the value and can be used to test for equality.
 * - The render function returns a JSX element that represents the value.
 * - The value is the actual parsed value.
 */
// TODO: in the future we want to add a `value` property to the ParsedValue type of type T.
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export interface ParsedValue<T = unknown> {
    /**
     * The ID of the value. This is used to compare values.
     */
    id: T;
    /**
     * A small, human-readable representation of the value.
     *
     * In case of a supplier, this could be the supplier's name.
     * In case of a supplier contact, this could be the contact's name.
     */
    label: string;
    /**
     * A more detailed description of the value.
     */
    description?: string;
    /**
     * A link to more information about the value.
     *
     * In case of suppliers, this could be a link to the supplier's page.
     * In case of supplier contacts, this could be a link to the contact's page.
     * In case of an mpn, this could be a link to the part's page.
     */
    href?: string;
    /**
     * Determines if the value is new or existing. This is used to determine if the value should be created or updated.
     */
    existing?: boolean;
}

export type ParseResult<T> = PromiseValue<ParsedValue<T>>;

export type Parser<T = unknown, Opts = {}> = (
    cells: string[],
    opts: Opts,
    field: ImporterField<T>,
) => Promise<ParseResult<T>>;

export interface ImporterField<T = unknown> {
    id: string;
    label?: string;
    description?: string;
    allowMultipleColumns?: boolean;
    /**
     * If unique is true, all cells in this field must be unique.
     */
    unique?: boolean;
    /**
     * A required field must be mapped to a column.
     */
    required: boolean | { color: 'green' | 'violet'; label: string };
    /**
     * The default value for this field.
     */
    defaultValue?: ParsedValue<T>;
    /**
     * The parser
     */
    parser: AllParserConfigs;
    columnIndices: number[];
}

export type GlobalField = Omit<ImporterField<string>, 'columnIndices' | 'unique'> & {
    value?: ParsedValue<string>;
    parser: ParserConfig<'string' | 'priceType'>;
};

/**
 * A record that describes the action that will be taken on a row.
 */
export type ImportRecord<TConfig extends { fields: ImporterField[] } = ImporterConfig> = SimplifyType<{
    action: 'insert' | 'update' | 'skipped';
    data: ImporterRowType<TConfig>;
}>;

export interface ImporterConfig {
    fields: ImporterField[];

    globalFields?: Array<GlobalField>;
}

export interface ParsedRow<T> {
    parserResults: Array<{
        field: string;
        parser: AllParserConfigs;
        /**
         * The indices of the columns that were used to parse the value.
         *
         * We currently only support one column per field, but in the future we may support multiple columns.
         */
        columnIndices: Array<number>;
        result: ParseResult<T>;
    }>;
    value?: T;
}

export interface Position {
    column: number;
    row: number;
}

export interface PositionRange {
    row: { from: number; to: number };
    column: { from: number; to: number };
}

type EnumType<T extends ImporterField> = T['parser'] extends { type: 'enum' }
    ? T['parser']['options']['cases'][number]['value']['id']
    : string;

/**
 * We need to manually convert the parser type to a TypeScript type.
 *
 * This means in theory we could have incorrect types here.
 *
 * IMPORTANT: Make sure this is in sync with the `parserRegistry` in [parsers.ts](./parsers/parsers.ts).
 */
type FieldType<T extends ImporterField> = T['parser']['type'] extends 'boolean'
    ? boolean
    : T['parser']['type'] extends 'identifier'
      ? string
      : T['parser']['type'] extends 'currency'
        ? Currency
        : T['parser']['type'] extends 'customer.number-or-name'
          ? string
          : T['parser']['type'] extends 'site.number-or-name'
            ? string
            : T['parser']['type'] extends 'supplier.number'
              ? string
              : T['parser']['type'] extends 'date'
                ? string | null
                : T['parser']['type'] extends 'email'
                  ? string
                  : T['parser']['type'] extends 'enum'
                    ? EnumType<T>
                    : T['parser']['type'] extends 'ipn'
                      ? string
                      : T['parser']['type'] extends 'language'
                        ? LanguageEnum
                        : T['parser']['type'] extends 'leadTimeWeeks'
                          ? number
                          : T['parser']['type'] extends 'monetaryValue'
                            ? MonetaryValueBackend
                            : T['parser']['type'] extends 'notes'
                              ? string
                              : T['parser']['type'] extends 'number'
                                ? number
                                : T['parser']['type'] extends 'packaging'
                                  ? Packaging
                                  : T['parser']['type'] extends 'string'
                                    ? string
                                    : T['parser']['type'] extends 'priceType'
                                      ? PriceType
                                      : T['parser']['type'] extends 'manufacturer.name'
                                        ? string
                                        : T['parser']['type'] extends 'quantity'
                                          ? number
                                          : `Unsupported type: ${T['parser']['type']}`;

type ExtractFieldId<T extends ImporterField> = T['id'];

export type ImporterRowType<T extends ImporterConfig> = SimplifyType<{
    [k in ExtractFieldId<T['fields'][number]>]: FieldType<T['fields'][number] & { id: k }>;
}>;

type SimplifyType<T> = {
    [k in keyof T]: T[k];
} & {};

/** Supported file encodings for import */
export const encoding = ['UTF-8', 'ISO-8859-1'] as const;
export type Encoding = (typeof encoding)[number];
