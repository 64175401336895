import { t, Trans } from '@lingui/macro';
import { Flexbox, Message, PrimaryButton, SecondaryButton, StickyLayout } from '@luminovo/design-system';
import { DesignItemOriginTypes } from '@luminovo/http-client';
import { BomItem } from '../../../resources/designItem/bomItemFrontendTypes';
import { analytics } from '../../../utils/analytics';

function getOriginalExcelHeight() {
    return document.getElementById('original_excel')?.clientHeight ?? 0;
}

export const ComparePartsMessage = ({
    isCompareEnabled,
    countOfSelectedParts,
    setPartSelectionActive,
    clearSelection,
    setIsDialogOpen,
    bomItem,
}: {
    isCompareEnabled: boolean;
    countOfSelectedParts: number;
    setPartSelectionActive: React.Dispatch<React.SetStateAction<boolean>>;
    clearSelection: () => void;
    setIsDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
    bomItem: BomItem;
}) => {
    const isOriginalExcelShown = bomItem.individualDesignItems.some(
        (c): boolean => c.origin.type === DesignItemOriginTypes.ExcelFile,
    );

    return (
        <StickyLayout top={isOriginalExcelShown ? `calc(${getOriginalExcelHeight()}px + 60px)` : '8px'} zIndex={1}>
            <Message
                attention="low"
                size="small"
                variant="primary"
                action={{
                    label: t`Compare`,
                    onClick: () => setIsDialogOpen(true),
                    disabled: !isCompareEnabled,
                }}
                overrides={{
                    ActionButton: () => (
                        <Flexbox gap={8}>
                            <SecondaryButton
                                size="small"
                                onClick={() => {
                                    setPartSelectionActive(false);
                                    clearSelection();
                                }}
                            >
                                <Trans>Cancel</Trans>
                            </SecondaryButton>
                            <PrimaryButton
                                size="small"
                                onClick={() => {
                                    analytics.track('compare_parts', {
                                        number_of_selected_parts: countOfSelectedParts,
                                    });

                                    setIsDialogOpen(true);
                                }}
                                disabled={countOfSelectedParts === 0}
                            >
                                <Trans>Compare</Trans>
                            </PrimaryButton>
                        </Flexbox>
                    ),
                }}
                title={t`Comparison mode.`}
                message={t`Select up to three parts and hit compare`}
            />
        </StickyLayout>
    );
};
