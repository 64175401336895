import * as z from 'zod';
import { endpoint } from '../http/endpoint';
import { WeltronCredentialsInputDTORuntype, WeltronResponseBodyDTORuntype } from './weltronBackendTypes';

export const weltronEndpoints = {
    'GET /3rdparty/weltron/credentials': endpoint({
        description: 'Returns credentials for weltron integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: WeltronResponseBodyDTORuntype,
    }),
    'POST /3rdparty/weltron/credentials': endpoint({
        description: 'Uploads credentials for weltron API integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: WeltronCredentialsInputDTORuntype,
        responseBody: WeltronResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/weltron/credentials'],
    }),
    'PATCH /3rdparty/weltron/credentials': endpoint({
        description: 'Updates credentials for weltron API integratoin',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: WeltronCredentialsInputDTORuntype,
        responseBody: WeltronResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/weltron/credentials'],
    }),
    'DELETE /3rdparty/weltron/credentials': endpoint({
        description: 'Deletes existing credentials for weltron API integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({ deleted: z.number() }),
        invalidates: ['GET /3rdparty/weltron/credentials'],
    }),
};
