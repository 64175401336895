import * as z from 'zod';
import { endpoint } from '../http/endpoint';
import { VenkelCredentialsInputDTORuntype, VenkelResponseBodyDTORuntype } from './venkelBackendTypes';

export const venkelEndpoints = {
    'GET /3rdparty/venkel/credentials': endpoint({
        description: 'Returns credentials for venkel integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: VenkelResponseBodyDTORuntype,
    }),
    'POST /3rdparty/venkel/credentials': endpoint({
        description: 'Uploads credentials for venkel API integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: VenkelCredentialsInputDTORuntype,
        responseBody: VenkelResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/venkel/credentials'],
    }),
    'PATCH /3rdparty/venkel/credentials': endpoint({
        description: 'Updates credentials for venkel API integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: VenkelCredentialsInputDTORuntype,
        responseBody: VenkelResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/venkel/credentials'],
    }),
    'DELETE /3rdparty/venkel/credentials': endpoint({
        description: 'Deletes existing credentials for venkel API integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({ deleted: z.number() }),
        invalidates: ['GET /3rdparty/venkel/credentials'],
    }),
};
