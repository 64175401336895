/* eslint-disable spellcheck/spell-checker */
import { useDialogContext } from '@/components/contexts/ModalContext';
import ConfirmationDialogBox from '@/components/dialogBox/ConfirmationDialogBox';
import { useHttpMutation } from '@/resources/mutation/useHttpMutation';
import { SupplierTermsWithSite } from '@/resources/supplierTerms/supplierTermsHandler';
import { t } from '@lingui/macro';

export const useDeleteSupplierTermDialog = (supplierTerm: SupplierTermsWithSite) => {
    const { setDialog, closeDialog } = useDialogContext();
    const { mutateAsync } = useHttpMutation('DELETE /supplier-terms/:supplierTermsId', {
        onSuccess: closeDialog,
        snackbarMessage: t`Supplier terms deleted`,
    });

    const dialog = (
        <ConfirmationDialogBox
            title={t`Delete supplier terms`}
            text={`Are you sure to you want to delete the supplier terms for the site ${supplierTerm.site?.name ?? 'All sites'}?  This action cannot be undone.`}
            isDialogOpen={true}
            onReject={closeDialog}
            onConfirm={() => mutateAsync({ pathParams: { supplierTermsId: supplierTerm.id } })}
            deleteText={`Yes, delete`}
        />
    );
    return { openDialog: () => setDialog(dialog) };
};
