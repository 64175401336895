import { FilterList } from '@mui/icons-material';
import { Menu, MenuItem, styled } from '@mui/material';
import React, { MouseEvent } from 'react';
import { colorSystem } from '../../theme';
import { Text } from '../Text';
import { SecondaryButton } from '../buttons';

export interface ButtonFilterProps<T> {
    value: T | undefined;
    onChange: (value: T | undefined) => void;
    label: string;
    options: { value: T; label: string; selectedLabel: string }[];
    Icon?: React.ElementType;
}

export function ButtonFilter<T>({
    label,
    value,
    options,
    onChange,
    Icon = FilterList,
}: ButtonFilterProps<T>): JSX.Element {
    const isSelected = value !== undefined;
    const [anchor, setAnchor] = React.useState<HTMLElement | null>(null);

    const buttonLabel = isSelected ? options.find((opt) => opt.value === value)?.selectedLabel : label;

    const icon = value ? <Icon style={{ color: colorSystem.primary[6] }} /> : <Icon />;

    const onClear = () => {
        setAnchor(null);
        onChange(undefined);
    };

    return (
        <>
            <Menu onClose={onClear} open={!!anchor} anchorEl={anchor} variant="selectedMenu">
                <MenuItem>
                    <Text variant="body-semibold">{label}</Text>
                </MenuItem>
                {options.map(({ value: optValue, label }) => (
                    <MenuItem
                        key={label}
                        selected={optValue === value}
                        onClick={() => {
                            onChange(optValue);
                            setAnchor(null);
                        }}
                    >
                        {label}
                    </MenuItem>
                ))}

                {isSelected && (
                    <StyledMenuItem
                        style={{ position: 'sticky', bottom: 0, borderTop: `1px solid ${colorSystem.neutral[2]}` }}
                        disabled={!isSelected}
                        onClick={onClear}
                    >
                        Clear selection
                    </StyledMenuItem>
                )}
            </Menu>

            <SecondaryButton
                style={{
                    // don't break the line
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                }}
                onClick={(e: MouseEvent<HTMLButtonElement>) => {
                    setAnchor(e.currentTarget);
                }}
                startIcon={icon}
                size="small"
            >
                {buttonLabel}
            </SecondaryButton>
        </>
    );
}

const StyledMenuItem = styled(MenuItem)({
    zIndex: 1,
    '&:hover': {
        background: colorSystem.neutral[1],
    },
});
