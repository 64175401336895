import {
    MonetaryValue,
    compareByString,
    extractAmountFromMonetaryValue,
    isEqual,
    isPresent,
    throwErrorUnlessProduction,
} from '@luminovo/commons';
import { rankItem, rankings } from '@tanstack/match-sorter-utils';
import { Column, ColumnDef, FilterFn, SortingFn, Table } from '@tanstack/react-table';
import { colorSystem } from '../../../theme';
import { compareByNumber } from '../../DataTable';
import { DateRange, FilterValue, NumberRange, SupportedFilterFn, UseTanStackTableStateProps } from '../type';
import { URLStorage } from './useTanStackPersistedState';

export function createFuzzyFilter<TData>(columns: ColumnDef<TData, any>[]): FilterFn<TData> {
    return (row, columnId, filterValue) => {
        const columnDef = columns.find((col) => col.id === columnId);

        if (!columnDef) {
            throwErrorUnlessProduction(`Column with id ${columnId} not found in the columns array.`);
            return false;
        }

        const rawValue = row.getValue(columnId);

        let itemRank = undefined;
        switch (columnDef.meta?.dataType) {
            case 'text':
                itemRank = rankItem(rawValue, filterValue, { threshold: rankings.CONTAINS });
                break;
            case 'number':
                itemRank = rankItem(rawValue, filterValue, { threshold: rankings.CONTAINS });
                break;
            case 'enum':
                if (columnDef.meta?.filterConfig?.dataType === 'enum') {
                    itemRank = rankItem(columnDef.meta.filterConfig.getOptionLabel(rawValue), filterValue, {
                        threshold: rankings.CONTAINS,
                    });
                }
                break;

            case 'array':
                if (!Array.isArray(rawValue)) {
                    return false;
                }

                itemRank = rankItem(
                    rawValue.map((value) => {
                        if (columnDef.meta?.filterConfig?.dataType === 'array') {
                            return columnDef.meta.filterConfig.getOptionLabel(value);
                        } else {
                            return '';
                        }
                    }),
                    filterValue,
                    {
                        threshold: rankings.CONTAINS,
                    },
                );
                break;
        }

        if (!isPresent(itemRank)) {
            return false;
        }

        // Store the itemRank info
        row.columnFiltersMeta[columnId] = {
            itemRank,
        };

        // Return if the item should be filtered in/out
        return itemRank.passed;
    };
}

/**
 * 2024-03-14 TANSTACK
 * https://tanstack.com/table/v8/docs/framework/react/examples/column-pinning-sticky
 */
//These are the important styles to make sticky column pinning work!
//Apply styles like this using your CSS strategy of choice with this kind of logic to head cells, data cells, footer cells, etc.
//View the index.css file for more needed styles such as border-collapse: separate
export function getCommonPinningStyles<TData>(column: Column<TData>): React.CSSProperties {
    const isPinned = column.getIsPinned();
    const isLastLeftPinnedColumn = isPinned === 'left' && column.getIsLastColumn('left');
    const isFirstRightPinnedColumn = isPinned === 'right' && column.getIsFirstColumn('right');

    return {
        boxShadow: isLastLeftPinnedColumn
            ? `-2px 0 4px -4px ${colorSystem.neutral[6]} inset`
            : isFirstRightPinnedColumn
              ? `2px 0 4px -4px ${colorSystem.neutral[6]} inset`
              : undefined,
        left: isPinned === 'left' ? `${column.getStart('left')}px` : undefined,
        right: isPinned === 'right' ? `${column.getAfter('right')}px` : undefined,
        opacity: 1,
        position: isPinned ? 'sticky' : 'relative',
        width: column.getSize(),
        zIndex: isPinned ? 1 : 0,
        backgroundColor: isPinned ? colorSystem.neutral.white : undefined,
    };
}

function narrowFilterValue(unSafeFilterValue: unknown): FilterValue<unknown> | null {
    if (!isPresent(unSafeFilterValue)) {
        throwErrorUnlessProduction(`Filter value is not present`);
        return null;
    }

    if (typeof unSafeFilterValue !== 'object' || unSafeFilterValue === null) {
        throwErrorUnlessProduction(`Filter value is not an object`);
        return null;
    }

    if (!('filterFn' in unSafeFilterValue) || !('value' in unSafeFilterValue)) {
        throwErrorUnlessProduction(`Filter value is missing filterFn or value`);
        return null;
    }

    // Info: Add here new filter functions as needed
    switch ((unSafeFilterValue as FilterValue<unknown>).filterFn) {
        case SupportedFilterFn.includesString:
            return { filterFn: SupportedFilterFn.includesString, value: String(unSafeFilterValue.value) };
        case SupportedFilterFn.inNumberRange:
            return { filterFn: SupportedFilterFn.inNumberRange, value: unSafeFilterValue.value as NumberRange };
        case SupportedFilterFn.inMonetaryValueRange:
            return { filterFn: SupportedFilterFn.inMonetaryValueRange, value: unSafeFilterValue.value as NumberRange };
        case SupportedFilterFn.inDateRange:
            return { filterFn: SupportedFilterFn.inDateRange, value: unSafeFilterValue.value as DateRange };
        case SupportedFilterFn.equalsAny:
            return { filterFn: SupportedFilterFn.equalsAny, value: unSafeFilterValue.value as Array<unknown> };
        case SupportedFilterFn.equalsNone:
            return { filterFn: SupportedFilterFn.equalsNone, value: unSafeFilterValue.value as Array<unknown> };
        case SupportedFilterFn.arrIncludesSome:
            return { filterFn: SupportedFilterFn.arrIncludesSome, value: unSafeFilterValue.value as Array<unknown> };
        case SupportedFilterFn.arrIncludesAll:
            return { filterFn: SupportedFilterFn.arrIncludesAll, value: unSafeFilterValue.value as Array<unknown> };
        case SupportedFilterFn.arrExcludesSome:
            return { filterFn: SupportedFilterFn.arrExcludesSome, value: unSafeFilterValue.value as Array<unknown> };
        default:
            throwErrorUnlessProduction(`Unknown filter function`);
            return null;
    }
}

// eslint-disable-next-line max-params
export const buildFilter: FilterFn<any> = (row, columnId, unSafeFilterValue, filterMeta) => {
    const filterValue = narrowFilterValue(unSafeFilterValue);

    if (!isPresent(filterValue)) {
        return true;
    }

    switch (filterValue.filterFn) {
        case SupportedFilterFn.includesString:
            return includesString(row, columnId, filterValue.value, filterMeta);
        case SupportedFilterFn.inNumberRange:
            return inNumberRange(row, columnId, filterValue.value, filterMeta);
        case SupportedFilterFn.inMonetaryValueRange:
            return inMonetaryValueRangeFilter(row, columnId, filterValue.value, filterMeta);
        case SupportedFilterFn.inDateRange:
            return inDateRange(row, columnId, filterValue.value, filterMeta);
        case SupportedFilterFn.equalsAny:
            return equalsAnyFilter(row, columnId, filterValue.value, filterMeta);
        case SupportedFilterFn.equalsNone:
            return equalsNoneFilter(row, columnId, filterValue.value, filterMeta);
        case SupportedFilterFn.arrIncludesSome:
            return arrIncludesSome(row, columnId, filterValue.value, filterMeta);
        case SupportedFilterFn.arrIncludesAll:
            return arrIncludesAll(row, columnId, filterValue.value, filterMeta);
        case SupportedFilterFn.arrExcludesSome:
            return arrExcludesSome(row, columnId, filterValue.value, filterMeta);
        default:
            throwErrorUnlessProduction(`Unknown filter function`);
            return true;
    }
};

const includesString: FilterFn<any> = (row, columnId, filterValue) => {
    const search = filterValue.toLowerCase();
    return Boolean(row.getValue<string | null>(columnId)?.toString()?.toLowerCase()?.includes(search));
};

const inNumberRange: FilterFn<any> = (row, columnId, filterValue) => {
    let [min, max] = filterValue;

    const rowValue = row.getValue<number>(columnId);
    return rowValue >= (min ?? -Infinity) && rowValue <= (max ?? Infinity);
};

const inMonetaryValueRangeFilter: FilterFn<any> = (row, columnId, filterValue) => {
    let [min, max] = filterValue;

    const rowValue = extractAmountFromMonetaryValue(row.getValue<MonetaryValue | null | undefined>(columnId));
    return rowValue >= (min ?? -Infinity) && rowValue <= (max ?? Infinity);
};

const inDateRange: FilterFn<any> = (row, columnId, filterValue) => {
    const [min, max] = filterValue;
    const rowValue = row.getValue<string | null | undefined>(columnId);

    // Helper function to parse date strings
    const parseDate = (dateString: string | null | undefined, option: { ifAbsent: number }): number => {
        const d = new Date(Date.parse(dateString ?? ''));
        if (isNaN(d.getTime())) {
            return option.ifAbsent;
        }
        return d.getTime();
    };

    const rowDate = parseDate(rowValue, { ifAbsent: NaN });
    const minDate = parseDate(min, { ifAbsent: -Infinity });
    const maxDate = parseDate(max, { ifAbsent: Infinity });

    return rowDate >= minDate && rowDate <= maxDate;
};
const equalsAnyFilter: FilterFn<any> = (row, columnId, filterValue) => {
    return filterValue.some((val: any) => isEqual(val, row.getValue(columnId)));
};

const equalsNoneFilter: FilterFn<any> = (row, columnId, filterValue) => {
    return filterValue.every((val: any) => !isEqual(val, row.getValue(columnId)));
};

const arrIncludesSome: FilterFn<any> = (row, columnId: string, filterValue: unknown[]) => {
    return filterValue.some((val) => row.getValue<unknown[]>(columnId)?.some((item) => isEqual(item, val)));
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const arrIncludesAll: FilterFn<any> = (row, columnId: string, filterValue: unknown[]) => {
    return filterValue.every((val) => row.getValue<unknown[]>(columnId)?.some((item) => isEqual(item, val)));
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const arrExcludesSome: FilterFn<any> = (row, columnId: string, filterValue: unknown[]) => {
    return filterValue.every((val) => !row.getValue<unknown[]>(columnId)?.some((item) => isEqual(item, val)));
};

export const monetaryValueSortingFn: SortingFn<MonetaryValue | null | undefined> = (rowA, rowB, columnId) => {
    return compareByNumber(extractAmountFromMonetaryValue)(rowA.getValue(columnId), rowB.getValue(columnId));
};

export const createPositionBasedSortFunction = <T>({
    options,
    getOptionLabel,
}: {
    options: T[] | undefined | Function;
    getOptionLabel: (opt: T) => string;
}): SortingFn<T> => {
    if (Array.isArray(options)) {
        return (rowA, rowB, columnId) => {
            const a = rowA.getValue(columnId) as T;
            const b = rowB.getValue(columnId) as T;

            return options.indexOf(a) - options.indexOf(b);
        };
    } else {
        return (rowA, rowB, columnId) => {
            const a = rowA.getValue(columnId) as T;
            const b = rowB.getValue(columnId) as T;

            return compareByString(getOptionLabel(a), getOptionLabel(b));
        };
    }
};

export const parseOptionsValuesOrFunc = <T, U>(fn: ((arg: U) => T) | T, arg: U): T =>
    fn instanceof Function ? fn(arg) : fn;

export function resetTable<TData>(table: Table<TData>): void {
    const {
        columnFilters = [],
        columnOrder = [],
        columnPinning = {},
        columnVisibility = {},
        globalFilter = '',
        rowSelection = {},
        sorting = [],
    } = table.options.meta?.defaultInitialState ?? {};

    table.setColumnFilters(columnFilters);
    table.setColumnOrder(columnOrder);
    table.setColumnPinning(columnPinning);
    table.setColumnVisibility(columnVisibility);
    table.setGlobalFilter(globalFilter);
    table.setRowSelection(rowSelection);
    table.setSorting(sorting);

    new URLStorage().clear();

    Object.keys(sessionStorage).forEach((key) => {
        const { enablePersistentRowSelection, enableSaveAsDefault } = table.options.meta ?? {};

        if (isPresent(table.options.meta) && key.includes(table.options.meta.columnsKey)) {
            sessionStorage.removeItem(key);
        }

        if (enablePersistentRowSelection && key.includes(enablePersistentRowSelection)) {
            sessionStorage.removeItem(key);
        }

        if (enableSaveAsDefault && key.includes(getSaveAsDefaultConfig(enableSaveAsDefault).key)) {
            sessionStorage.removeItem(key);
        }
    });

    Object.keys(localStorage).forEach((key) => {
        const { enablePersistentRowSelection, enableSaveAsDefault } = table.options.meta ?? {};

        if (isPresent(table.options.meta) && key.includes(table.options.meta.columnsKey)) {
            localStorage.removeItem(key);
        }

        if (enablePersistentRowSelection && key.includes(enablePersistentRowSelection)) {
            localStorage.removeItem(key);
        }

        if (enableSaveAsDefault && key.includes(getSaveAsDefaultConfig(enableSaveAsDefault).key)) {
            localStorage.removeItem(key);
        }
    });
}

export function getSaveAsDefaultConfig(
    enableSaveAsDefault: UseTanStackTableStateProps<unknown>['enableSaveAsDefault'],
): {
    storage: Storage;
    key: string;
    enabled: boolean;
} {
    if (typeof enableSaveAsDefault === 'object' && enableSaveAsDefault !== null) {
        return {
            storage: enableSaveAsDefault.storage === 'local' ? localStorage : sessionStorage,
            key: enableSaveAsDefault.key,
            enabled: true,
        };
    } else if (typeof enableSaveAsDefault === 'string') {
        return {
            storage: sessionStorage,
            key: enableSaveAsDefault,
            enabled: true,
        };
    } else {
        return {
            storage: sessionStorage,
            key: '',
            enabled: false,
        };
    }
}
