import { useDialogContext } from '@/components/contexts/ModalContext';
import { useHttpQuery } from '@/resources/http/useHttpQuery';
import { useHttpMutation } from '@/resources/mutation/useHttpMutation';
import { t } from '@lingui/macro';
import { isPresent } from '@luminovo/commons';
import { CenteredLayout, Dialog, DialogContent, DialogTitle, colorSystem } from '@luminovo/design-system';
import { CircularProgress } from '@mui/material';
import { SupplierLineCardForm, SupplierLineCardFormState } from './SupplierLineCardForm';

export function useDialogEditApprovedVendorList() {
    const { setDialog, closeDialog } = useDialogContext();

    return {
        openDialog: (supplierId: string) =>
            setDialog(
                <Dialog
                    open={true}
                    maxWidth={'md'}
                    fullWidth={true}
                    transitionDuration={0}
                    onClose={() => closeDialog()}
                >
                    <DialogTitle
                        title={t`Edit approved vendor list`}
                        onClose={() => closeDialog()}
                        style={{ borderBottom: `1px solid ${colorSystem.neutral[2]}` }}
                    />
                    <InnerAVLForm supplierId={supplierId} closeDialog={() => closeDialog()} />
                </Dialog>,
            ),
    };
}

const InnerAVLForm: React.FunctionComponent<{ supplierId: string; closeDialog: () => void }> = ({
    supplierId,
    closeDialog,
}) => {
    const { data: approvedVendorList } = useHttpQuery('GET /suppliers/:id/line-card-rules', {
        pathParams: { id: supplierId },
    });

    const defaultValues: SupplierLineCardFormState = {
        selected: new Set(approvedVendorList?.manufacturers.map((m) => m.id) ?? []),
    };

    const { mutateAsync } = useHttpMutation('PATCH /suppliers/:id/line-card-rules', {
        snackbarMessage: t`Approved vendor list updated`,
        onSuccess: () => closeDialog(),
    });

    const onSubmit = async (formValues: SupplierLineCardFormState) => {
        await mutateAsync({
            pathParams: { id: supplierId },
            requestBody: { manufacturers: Array.from(formValues.selected) },
        });
    };

    return (
        <DialogContent>
            {!isPresent(approvedVendorList) ? (
                <CenteredLayout height={'30vh'}>
                    <CircularProgress />
                </CenteredLayout>
            ) : (
                <SupplierLineCardForm
                    defaultValues={defaultValues}
                    onSubmit={onSubmit}
                    supplierId={null}
                    onClose={closeDialog}
                />
            )}
        </DialogContent>
    );
};
