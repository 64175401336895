import * as z from 'zod';
import { endpoint } from '../http/endpoint';
import { SamtecCredentialsInputDTORuntype, SamtecResponseBodyDTORuntype } from './samtecBackendTypes';

export const samtecEndpoints = {
    'GET /3rdparty/samtec/credentials': endpoint({
        description: 'Returns credentials for samtec integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: SamtecResponseBodyDTORuntype,
    }),
    'POST /3rdparty/samtec/credentials': endpoint({
        description: 'Uploads credentials for samtec API integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: SamtecCredentialsInputDTORuntype,
        responseBody: SamtecResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/samtec/credentials'],
    }),
    'PATCH /3rdparty/samtec/credentials': endpoint({
        description: 'Updates credentials for samtec API integratoin',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: SamtecCredentialsInputDTORuntype,
        responseBody: SamtecResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/samtec/credentials'],
    }),
    'DELETE /3rdparty/samtec/credentials': endpoint({
        description: 'Deletes existing credentials for samtec API integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({ deleted: z.number() }),
        invalidates: ['GET /3rdparty/samtec/credentials'],
    }),
};
