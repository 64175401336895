import { t } from '@lingui/macro';
import { FieldSelectControlled } from '@luminovo/design-system';
import { useFormContext } from 'react-hook-form';
import { useManufacturers } from '../../../../../../../resources/manufacturer/manufacturerHandler';
import { OtsFormValues } from './formValues';

export const ManufacturerAutocomplete = (): JSX.Element => {
    const { control } = useFormContext<OtsFormValues>();
    const { data: manufacturers } = useManufacturers();

    return (
        <FieldSelectControlled
            control={control}
            name="manufacturerDto"
            required={true}
            FieldProps={{
                options: manufacturers,
                getOptionLabel: (option) => option.name,
                isOptionEqualToValue: (opt, value) => opt.id === value.id,
                getOptionSublabel: (option) => option.alternative_names.join(', '),
                virtualized: true,
                placeholder: t`Search for manufacturer`,
                noOptionsText: t`No results. Please contact support@luminovo.com if you want us to add a new manufacturer.`,
                size: 'large',
            }}
        />
    );
};
