import { t, Trans } from '@lingui/macro';
import { Checkbox, colorSystem, Tag, Text } from '@luminovo/design-system';
import { otsPartOrUndefined, PartSpecification, StandardPartTypes } from '@luminovo/http-client';
import { Controller, useFormContext } from 'react-hook-form';
import { BomItemFormState } from './SpecificationTypeForms/BomItemFormState';

export const ManufacturerFreeField = ({
    isEditable,
    specification,
    submitForm,
}: {
    isEditable: boolean;
    specification: PartSpecification | null;
    submitForm: () => void;
}) => {
    const { control, watch, setValue } = useFormContext<BomItemFormState>();
    const partOptions = watch('otsPart.data.part_options');
    const countOfExistingGenericPartOptions = partOptions.filter(
        (option) => option.part.type === StandardPartTypes.Generic,
    ).length;
    const isManufacturerFreeFieldEditable = isEditable && countOfExistingGenericPartOptions === 0;

    if (isManufacturerFreeFieldEditable) {
        return (
            <>
                <Controller
                    name={'otsPart.data.is_manufacturer_free'}
                    control={control}
                    render={({ field }): JSX.Element => {
                        return (
                            <Checkbox
                                {...field}
                                size="small"
                                checked={field.value}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                                    setValue('otsPart.data.is_manufacturer_free', checked, {
                                        shouldDirty: true,
                                        shouldValidate: true,
                                        shouldTouch: true,
                                    });
                                    submitForm();
                                }}
                            />
                        );
                    }}
                />
                <Text style={{ marginTop: '2px' }} color={colorSystem.neutral[7]}>
                    <Trans>Manufacturer free</Trans>
                </Text>
            </>
        );
    }

    if (otsPartOrUndefined(specification)?.data.is_manufacturer_free || countOfExistingGenericPartOptions > 0) {
        return <Tag label={t`Manufacturer Free`} color="neutral" />;
    }

    return null;
};
