import { route } from '@/utils/routes';
import { Trans } from '@lingui/macro';
import { colorSystem, Flexbox, TertiaryIconButton, Text, Tooltip } from '@luminovo/design-system';
import { Launch, LinkOffRounded } from '@mui/icons-material';

export const UnlinkedIcon = ({ ipnId, rawSpecId }: { ipnId?: string; rawSpecId?: string }): JSX.Element => {
    return (
        <Tooltip
            variant="white"
            title={
                ipnId && rawSpecId ? (
                    <Flexbox padding="8px" flexDirection="column" gap={4} alignItems="flex-start">
                        <Text color={colorSystem.neutral[8]} variant="body-small">
                            <Trans>
                                There are specifications from the ERP data that are not linked to any part in the
                                component. Go to the component details page to link them.
                            </Trans>
                            <TertiaryIconButton
                                size="small"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    window.open(
                                        route('/parts/components/ipn/linking', {}, { ipnId, q: undefined, rawSpecId }),
                                        '_blank',
                                        'noopener noreferrer',
                                    );
                                }}
                                style={{
                                    padding: 0,
                                    height: 'unset',
                                    minHeight: 'unset',
                                    paddingBottom: '2px',
                                }}
                            >
                                <Launch color="inherit" style={{ fontSize: '12px' }} />
                            </TertiaryIconButton>
                        </Text>
                    </Flexbox>
                ) : (
                    ''
                )
            }
        >
            <LinkOffRounded style={{ color: colorSystem.yellow[8], fontSize: '16px' }} />
        </Tooltip>
    );
};
