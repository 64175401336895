import { t } from '@lingui/macro';
import { isPresent } from '@luminovo/commons';
import { colorSystem, FieldCheckbox, Flexbox, Text } from '@luminovo/design-system';
import { PCBV2 } from '@luminovo/http-client';
import { Box } from '@mui/material';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Capability } from '../../../../resources/pcb/pcbFunctions';
import { CollapsibleSection } from '../../components/CollapsibleSection';
import { PcbFormItem } from '../../components/PcbFormItem';

interface StackupAdvancedFormSectionProps {
    pcb: PCBV2;
    pcbCapabilities: Capability[];
    advancedStackUpValues: Capability[];
    isEditing: boolean;
    extractedValue: (capability: Capability) => any;
}

export const StackupAdvancedFormSection: React.FC<StackupAdvancedFormSectionProps> = ({
    pcb,
    pcbCapabilities,
    advancedStackUpValues,
    isEditing,
    extractedValue,
}) => {
    const specificationValue = React.useCallback(
        (capability: Capability) => {
            const properties = pcb.properties;
            const specification = pcb.specifications[0]?.settings;

            const specificationValue = specification
                ? // @ts-ignore
                  specification[capability.section][capability.capabilityName]
                : undefined;

            if (isPresent(specificationValue)) {
                return specificationValue;
            }

            // @ts-ignore
            return properties[capability.section][capability.capabilityName];
        },
        [pcb],
    );

    const numberOfPrepregsCapability = pcbCapabilities.find(
        /* eslint-disable-next-line spellcheck/spell-checker */
        (capability) => capability.capabilityName === 'numberOfPrepregs',
    );
    const numberOfLaminationCyclesCapability = pcbCapabilities.find(
        (capability) => capability.capabilityName === 'numberOfLaminationCycles',
    );

    return (
        <Box
            style={{
                borderRadius: '8px',
                backgroundColor: colorSystem.neutral.white,
            }}
        >
            <CollapsibleSection
                id={'advanced-stack-up-fields'}
                label={t`Advanced options`}
                isOpenByDefault={true}
                shouldCollapseButtonBeShown={true}
            >
                <Box style={{ display: 'grid', gridTemplateColumns: '1fr', gap: 24, width: '100%' }}>
                    {numberOfPrepregsCapability && (
                        <CheckboxEnabledField
                            label={t`Specify the number of prepregs`}
                            capability={numberOfPrepregsCapability}
                            isEditing={isEditing}
                            specificationValue={specificationValue}
                            extractedValue={extractedValue}
                        />
                    )}

                    {numberOfLaminationCyclesCapability && (
                        <CheckboxEnabledField
                            label={t`Specify the number of lamination cycles`}
                            capability={numberOfLaminationCyclesCapability}
                            isEditing={isEditing}
                            specificationValue={specificationValue}
                            extractedValue={extractedValue}
                        />
                    )}

                    {advancedStackUpValues.map((capability, i) => (
                        <PcbFormItem
                            key={i}
                            capability={capability}
                            disabled={!isEditing}
                            extractedValue={extractedValue(capability)}
                        />
                    ))}
                </Box>
            </CollapsibleSection>
        </Box>
    );
};

type CheckboxEnabledFieldProps = {
    capability: Capability;
    isEditing: boolean;
    label: string;
    specificationValue: (capability: Capability) => any;
    extractedValue: (capability: Capability) => any;
};

const CheckboxEnabledField: React.FC<CheckboxEnabledFieldProps> = ({
    capability,
    label,
    isEditing,
    specificationValue,
    extractedValue,
}) => {
    const { setValue, resetField } = useFormContext<PCBV2['properties']>();

    const [showField, setShowField] = React.useState(Boolean(specificationValue(capability)));
    const handleCheckboxChange = React.useCallback(
        (change) => {
            setShowField(change);
            if (!change) {
                resetField(capability.fieldName);
                setValue(capability.fieldName, undefined);
            } else {
                setValue(capability.fieldName, capability.restrictions.default);
            }
        },
        [setValue, resetField, capability],
    );

    return (
        <>
            <Flexbox gap={'4px'} alignItems={'center'}>
                <label
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '4px',
                        alignItems: 'center',
                    }}
                >
                    <FieldCheckbox disabled={!isEditing} value={showField} onChange={handleCheckboxChange} />
                    <Text variant="h5">{label}</Text>
                </label>
            </Flexbox>
            {showField && (
                <PcbFormItem
                    capability={capability}
                    disabled={!isEditing}
                    extractedValue={extractedValue(capability)}
                />
            )}
        </>
    );
};
