import { t } from '@lingui/macro';
import { colorSystem, StatusChip, Text, Tooltip } from '@luminovo/design-system';
import { Box } from '@mui/material';
import { HeaderComponent } from '../components/HeaderComponent';
import { DataGridColumn, DataGridRow } from '../types';
import { formatColumnValue } from './getColumnValue';

type Color = keyof typeof colorSystem;

export type ColumnStatusChipProps = {
    label: string;
    color: Color;
    description?: string;
};

export function columnStatus<TRow extends DataGridRow>({
    label = () => t`Status`,
    ...props
}: DataGridColumn<TRow, ColumnStatusChipProps> & { label?: () => string }): DataGridColumn<
    TRow,
    ColumnStatusChipProps
> {
    return {
        headerName: label(),
        valueFormatter: (params) => params.value?.label ?? '-',
        filterValueGetter: ({ data, column }) =>
            // @ts-ignore
            formatColumnValue(data, column),
        comparator: (a, b) => (a?.label ?? '').localeCompare(b?.label ?? ''),
        headerComponent: HeaderComponent,
        editable: false,
        cellRenderer: ({ value }) => {
            if (!value) return '-';
            const size = 10;
            return (
                <Tooltip enterDelay={0} placement="left" title={value.description ?? ''}>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                            height: '100%',
                        }}
                    >
                        <Box
                            sx={{
                                width: size,
                                height: size,
                                minWidth: size,
                                minHeight: size,
                                borderRadius: '16px',
                                backgroundColor: colorSystem[value.color][4],
                                border: `1px solid ${colorSystem[value.color][6]}`,
                            }}
                        />
                        <Text variant="body-small" color={colorSystem[value.color][8]}>
                            {value.label}
                        </Text>
                    </Box>
                </Tooltip>
            );
        },
        ...props,
    };
}

export function columnStatusChip<TRow extends DataGridRow>(
    props: DataGridColumn<TRow, ColumnStatusChipProps>,
): DataGridColumn<TRow, ColumnStatusChipProps> {
    return {
        ...columnStatus(props),
        cellRenderer: ({ value }) => {
            if (!value) {
                return '-';
            }
            return <StatusChip label={value.label} color={value.color} />;
        },
        ...props,
    };
}
