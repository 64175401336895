import { INTER } from '@luminovo/design-system';
import { ViewBoxCoordinateType } from '@luminovo/http-client';
import { AddRounded as AddIcon } from '@mui/icons-material';
import React from 'react';
import { getCurrentZoom, LineMeasureType } from '../../utils/useSVGImageEventHandler';
import {
    adjustPixelToZoomLevel,
    calculateAngleBetweenPoints,
    calculateDistanceBetweenTwoPoints,
    calculateMidPoint,
    formatPixelsToDistance,
    getTextSize,
    SVG_RULER_BG_COLOR,
    SVG_RULER_TEXT_COLOR,
} from './SvgImageRulerUtils';

const CrossHairIcon = ({ width, x, y }: { width: number; x: number; y: number }) => {
    return (
        <svg x={x - width / 2} y={y - width / 2}>
            <AddIcon width={width} height={width} style={{ color: SVG_RULER_BG_COLOR }} />
        </svg>
    );
};

const SvgImageRulerBase = ({
    start,
    end,
    currentCursorPoint,
    svgElement,
    viewBox,
}: {
    start: DOMPoint;
    currentCursorPoint: DOMPoint;
    end: DOMPoint | undefined;
    svgElement: SVGSVGElement;
    viewBox: ViewBoxCoordinateType;
}) => {
    const textElement = React.useRef<SVGTextElement>(null);
    const endPoint = end ? end : currentCursorPoint;

    const zoomScale = getCurrentZoom(svgElement, viewBox.width);
    const distanceBetweenPoints = calculateDistanceBetweenTwoPoints(start, endPoint);
    const midPoint = calculateMidPoint(start, endPoint);
    const lineAngle = calculateAngleBetweenPoints(start, endPoint);

    // Stroke properties
    const strokeWidth = adjustPixelToZoomLevel(0.5, zoomScale);
    const strokeDashArray = `${adjustPixelToZoomLevel(4, zoomScale)} ${adjustPixelToZoomLevel(4, zoomScale)}`;

    // Cursor properties
    const cursorWidth = adjustPixelToZoomLevel(12, zoomScale);

    // Text properties
    const isTextFlipped = lineAngle > 90 && lineAngle < 270;
    const textSize = adjustPixelToZoomLevel(getTextSize(svgElement), zoomScale);
    const textAngle = isTextFlipped ? 180 + lineAngle : lineAngle;

    // Text rectangle properties
    // TODO - fix useRef
    const rectWidth = (textElement.current?.getBBox().width || 0) + textSize / 2;
    // TODO - fix useRef
    const rectHeight = (textElement.current?.getBBox().height || 0) + textSize / 2;

    return (
        <svg id="svg-image-ruler" style={{ overflow: 'visible' }}>
            <g>
                <line
                    style={{ opacity: 1 }}
                    x1={start.x}
                    y1={start.y}
                    x2={endPoint.x}
                    y2={endPoint.y}
                    stroke={SVG_RULER_BG_COLOR}
                    strokeWidth={strokeWidth}
                    strokeDasharray={strokeDashArray}
                />

                {distanceBetweenPoints > 0 && (
                    <g>
                        <rect
                            x={midPoint.x - rectWidth / 2}
                            y={midPoint.y - rectHeight / 2 - rectHeight * 0.75}
                            width={rectWidth}
                            height={rectHeight}
                            fill={SVG_RULER_BG_COLOR}
                            rx={rectWidth * 0.05}
                            ry={rectWidth * 0.05}
                            transform={`rotate(${textAngle}, ${midPoint.x}, ${midPoint.y})`}
                        />

                        <text
                            ref={textElement}
                            x={midPoint.x}
                            y={midPoint.y}
                            fontSize={textSize}
                            fontWeight={'500'}
                            textAnchor="middle"
                            fill={SVG_RULER_TEXT_COLOR}
                            fontFamily={INTER}
                            dominantBaseline={'middle'}
                            transform={`rotate(${textAngle}, ${midPoint.x}, ${midPoint.y})`}
                            dy={'-1.5em'}
                        >
                            {formatPixelsToDistance(distanceBetweenPoints)}
                        </text>
                    </g>
                )}
                <CrossHairIcon width={cursorWidth} x={start.x} y={start.y} />
                {end && <CrossHairIcon width={cursorWidth} x={end.x} y={end.y} />}
            </g>
        </svg>
    );
};

export const SvgImageRuler = ({
    linePoints,
    viewBox,
    svgRef,
}: {
    linePoints: LineMeasureType;
    viewBox: ViewBoxCoordinateType;
    svgRef: React.RefObject<SVGSVGElement>;
    initialViewBox: ViewBoxCoordinateType;
}) => {
    const svgElement = svgRef.current;
    const { start, end, currentCursorPoint } = linePoints;

    if (!svgElement || !start || !currentCursorPoint) return null;

    return (
        <SvgImageRulerBase
            start={start}
            end={end}
            currentCursorPoint={currentCursorPoint}
            svgElement={svgElement}
            viewBox={viewBox}
        />
    );
};
