/* eslint-disable import/no-unused-modules */

import * as z from 'zod';
import { MonetaryValueBackendRuntype } from '../../resources/backendTypes';
import { PackagingRuntype } from '../../resources/offer/Packaging';
import { PackageFamilyRuntype, PartCategoryDTORuntype } from '../part';
import { MountingRuntype } from '../part/Mounting';

export const ScrapRuntype = z.object({
    leader: z.number(),
    attrition: z.number(),
    minimum: z.optional(z.number()),
    maximum: z.optional(z.number()),
});

const UnitPriceFieldRuntype = z.object({
    lhs: z.literal('UnitPrice'),
    rhs: MonetaryValueBackendRuntype,
    value: z.optional(MonetaryValueBackendRuntype),
});

const PackageFieldRuntype = z.object({
    lhs: z.literal('Package'),
    rhs: z.array(PackageFamilyRuntype),
    value: z.optional(z.array(PackageFamilyRuntype)),
});

const PartTypeFieldRuntype = z.object({
    lhs: z.literal('PartType'),
    rhs: z.string(),
    value: z.optional(z.string()),
});

const PartCategoryFieldRuntype = z.object({
    lhs: z.literal('PartCategory'),
    rhs: PartCategoryDTORuntype,
    value: z.optional(z.array(z.number())),
});

const PackagingFieldRuntype = z.object({
    lhs: z.literal('Packaging'),
    rhs: PackagingRuntype,
    value: z.optional(PackagingRuntype),
});

const AggregatedQuantityFieldRuntype = z.object({
    lhs: z.literal('AggregatedQuantity'),
    rhs: z.number(),
    value: z.optional(z.number()),
});

const OrderSizeFieldRuntype = z.object({
    lhs: z.literal('OrderSize'),
    rhs: z.number(),
    value: z.optional(z.number()),
});

const MountingFieldRuntype = z.object({
    lhs: z.literal('Mounting'),
    rhs: MountingRuntype,
    value: MountingRuntype.optional(),
});

const FieldRuntype = z.union([
    UnitPriceFieldRuntype,
    PackagingFieldRuntype,
    AggregatedQuantityFieldRuntype,
    OrderSizeFieldRuntype,
    PackageFieldRuntype,
    MountingFieldRuntype,
    PartTypeFieldRuntype,
    PartCategoryFieldRuntype,
]);

const OperatorEqRuntype = z
    .object({
        op: z.literal('Eq'),
    })
    .and(FieldRuntype);

const OperatorGtRuntype = z
    .object({
        op: z.literal('Gt'),
    })
    .and(FieldRuntype);

const OperatorGteRuntype = z
    .object({
        op: z.literal('Gte'),
    })
    .and(FieldRuntype);

const OperatorLtRuntype = z
    .object({
        op: z.literal('Lt'),
    })
    .and(FieldRuntype);

const OperatorLteRuntype = z
    .object({
        op: z.literal('Lte'),
    })
    .and(FieldRuntype);

const OperatorInRuntype = z
    .object({
        op: z.literal('In'),
    })
    .and(PackageFieldRuntype);

// TODO: uncomment when implementing ranges
// const OperatorInRangeRuntype = z
//     .object({
//         op: z.literal('InRange'),
//     })
//     .and(RangeFieldRuntype);

const OperatorRuntype = z.union([
    OperatorEqRuntype,
    OperatorGtRuntype,
    OperatorGteRuntype,
    OperatorLtRuntype,
    OperatorLteRuntype,
    OperatorInRuntype,
    // TODO: uncomment when implementing ranges
    // OperatorInRangeRuntype,
]);

export type Operator = z.infer<typeof OperatorRuntype>;

export type OperatorId = Operator['op'] | Condition['op'];

export type OperatorField = z.infer<typeof FieldRuntype>['lhs'];

const OperatorOrRuntype = z.object({
    op: z.literal('Or'),
    args: z.array(OperatorRuntype),
});

const OperatorAndRuntype = z.object({
    op: z.literal('And'),
    args: z.array(OperatorRuntype),
});

export const ConditionRuntype = z.union([OperatorOrRuntype, OperatorAndRuntype]);

export type Condition = z.infer<typeof ConditionRuntype>;

const FormulaRuntype = z.object({
    name: z.string(),
    condition: ConditionRuntype,
    scrap_formula: ScrapRuntype,
});

export interface Formula extends z.infer<typeof FormulaRuntype> {}

export const ScrapRulesDTORuntype = z.object({
    formulae: z.array(FormulaRuntype),
    default_formula: ScrapRuntype,
});

export interface ScrapRulesDTO extends z.infer<typeof ScrapRulesDTORuntype> {}
