import { z } from 'zod';
import { StepEnumRuntype } from '../organizationSettings/organizationSettingsBackendTypes';
import { PaymentMethodRuntype, WorkflowTypeRuntype } from '../rfq/rfqBackendTypes';

export const CustomerPortalSettingsRuntype = z.object({
    manufacturing_templates: z.array(z.string()),
    calculation_template: z.string().nullable(),
    minimum_quotations_lead_time_days: z.number().nullable(),
    shipping_information_text: z.string(),
    shipping_information_link: z.string(),
    payment_information_text: z.string(),
    payment_information_link: z.string(),
    order_confirmation_workflow: WorkflowTypeRuntype,
    invoice_days_before_due: z.number(),
    upload_bom: StepEnumRuntype,
    upload_pcb: StepEnumRuntype,
    upload_cad_files: StepEnumRuntype,
    upload_production_files: StepEnumRuntype,
    upload_pnp_files: StepEnumRuntype,
    cad_files_guidance_text: z.string(),
    manufacturing_files_guidance_text: z.string(),
    imprint_link: z.string(),
    statement_link: z.string(),
    terms_and_conditions_link: z.string(),
    default_sourcing_lead_time_preference: z.number().nullable(),
    show_part_data_warnings: z.boolean(),
    payment_methods: PaymentMethodRuntype,
    use_stripe_for_invoice: z.boolean(),
    custom_conditions_text: z.array(z.string()),
    disable_delivery_date_option: z.boolean(),
});
export type CustomerPortalSettings = z.infer<typeof CustomerPortalSettingsRuntype>;

export const CustomerPortalSettingsUpdateRuntype = z.object({
    manufacturing_templates: z.array(z.string()).optional(),
    calculation_template: z.string().nullable().optional(),
    minimum_quotations_lead_time_days: z.number().nullable().optional(),
    shipping_information_text: z.string().optional(),
    shipping_information_link: z.string().optional(),
    payment_information_text: z.string().optional(),
    payment_information_link: z.string().optional(),
    order_confirmation_workflow: WorkflowTypeRuntype.optional(),
    invoice_days_before_due: z.number().optional(),
    upload_bom: StepEnumRuntype.optional(),
    upload_pcb: StepEnumRuntype.optional(),
    upload_cad_files: StepEnumRuntype.optional(),
    upload_production_files: StepEnumRuntype.optional(),
    upload_pnp_files: StepEnumRuntype.optional(),
    cad_files_guidance_text: z.string().optional(),
    manufacturing_files_guidance_text: z.string().optional(),
    imprint_link: z.string().optional(),
    statement_link: z.string().optional(),
    terms_and_conditions_link: z.string().optional(),
    default_sourcing_lead_time_preference: z.number().nullable().optional(),
    show_part_data_warnings: z.boolean().optional(),
    payment_methods: PaymentMethodRuntype.optional(),
    use_stripe_for_invoice: z.boolean().optional(),
    custom_conditions_text: z.array(z.string()).optional(),
    disable_delivery_date_option: z.boolean().optional(),
});
export type CustomerPortalSettingsUpdate = z.infer<typeof CustomerPortalSettingsUpdateRuntype>;

export const CustomerPortalPaymentSettingsRuntype = z.object({
    is_payment_processor_setup: z.boolean(),
});
export type CustomerPortalPaymentSettings = z.infer<typeof CustomerPortalPaymentSettingsRuntype>;
