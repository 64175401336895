import { uniqueBy } from '@luminovo/commons';
import { DisplayableColumnIds } from '@luminovo/http-client';
import { assertUnreachable } from '../../../../utils/typingUtils';
import { ColumnTag, ColumnTagsSheetsAction, ColumnTagsSheetsState, TaggedSheet } from './types';

const uniquelyAssignableTags: DisplayableColumnIds[] = ['quantity', 'designators', 'level'];

function updateSheetTags(
    sheets: TaggedSheet[],
    sheetIndex: number,
    label: string,
    mapper: (tag: ColumnTag) => ColumnTag,
): TaggedSheet[] {
    const updatedSheets = [...sheets];
    updatedSheets[sheetIndex].tags = sheets[sheetIndex].tags.map((tag) => {
        if (tag.label !== label) {
            return tag;
        }

        return mapper(tag);
    });
    return updatedSheets;
}

export function isTagAssignedToColumn(tag: ColumnTag, columnId: string) {
    return tag.associatedTo.some((col) => col.id === columnId);
}

export function isTagSelectedByOtherColumn(tag: ColumnTag, columnId: string) {
    if (!uniquelyAssignableTags.includes(tag.id)) {
        return undefined;
    }
    return tag.associatedTo.find((col) => col.id !== columnId);
}
export function getSelectedOptions(state: ColumnTagsSheetsState, columnId: string, sheetIndex: number) {
    return state.sheets[sheetIndex].tags.filter((tag) => isTagAssignedToColumn(tag, columnId));
}

export function reducer(state: ColumnTagsSheetsState, action: ColumnTagsSheetsAction): ColumnTagsSheetsState {
    if (action.type === 'assign-tag') {
        const incomingTag = action.tag;
        const sheetIndex = state.activeTab === 'AML' && state.amlSheetIndex ? state.amlSheetIndex : state.bomSheetIndex;
        const sheets = updateSheetTags(state.sheets, sheetIndex, incomingTag.label, (tag) => {
            return {
                ...tag,
                associatedTo: uniqueBy([...tag.associatedTo, action.column], (column) => column.id),
            };
        });

        return { ...state, sheets, hasChanged: true };
    }
    if (action.type === 'remove-tag') {
        const incomingTag = action.tag;
        const sheetIndex = state.activeTab === 'AML' && state.amlSheetIndex ? state.amlSheetIndex : state.bomSheetIndex;
        const sheets = updateSheetTags(state.sheets, sheetIndex, incomingTag.label, (tag) => {
            return {
                ...tag,
                associatedTo: tag.associatedTo.filter((col) => col.id !== action.column.id),
            };
        });

        const isTagStillUsed = sheets[sheetIndex].tags.some((t) => t.id === action.tag.id && t.associatedTo.length > 0);

        const selectedColumns = isTagStillUsed
            ? state.previousLinesSearch.columns
            : state.previousLinesSearch.columns.filter((col) => col !== action.tag.id);

        return {
            ...state,
            sheets,
            hasChanged: true,
            previousLinesSearch: { ...state.previousLinesSearch, columns: selectedColumns },
        };
    }
    if (action.type === 'set-initial-state') {
        return action.state;
    }
    if (action.type === 'set-active-tab') {
        return { ...state, activeTab: action.tab };
    }
    if (action.type === 'set-bom-sheet-index') {
        return {
            ...state,
            bomSheetIndex: action.index,
            hasChanged: true,
        };
    }
    if (action.type === 'set-aml-sheet-index') {
        return {
            ...state,
            amlSheetIndex: action.index,
            hasChanged: true,
        };
    }
    if (action.type === 'display-column-mapping') {
        return state;
    }
    if (action.type === 'set-previous-lines-search-variant') {
        return { ...state, previousLinesSearch: { ...state.previousLinesSearch, variant: action.variant } };
    }
    if (action.type === 'assign-previous-lines-search-columns') {
        return { ...state, previousLinesSearch: { ...state.previousLinesSearch, columns: action.columns } };
    }
    if (action.type === 'remove-previous-lines-search-column') {
        return {
            ...state,
            previousLinesSearch: {
                ...state.previousLinesSearch,
                columns: state.previousLinesSearch.columns.filter((col) => col !== action.column),
            },
        };
    }
    assertUnreachable(action);
}
