import * as z from 'zod';
import { endpoint } from '../http/endpoint';
import { EmailTemplateDTORuntype } from './emailTemplateTypes';

export const emailTemplateEndpoints = {
    'GET /email-template': endpoint({
        description: 'Get email templates',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({
            items: z.array(EmailTemplateDTORuntype),
        }),
    }),
    'POST /email-template': endpoint({
        description: 'Create email template',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: EmailTemplateDTORuntype.omit({ id: true, updated_at: true }),
        responseBody: z.unknown(),
        invalidates: ['GET /email-template'],
    }),

    'DELETE /email-template/:id': endpoint({
        description: 'Delete an email template',
        pathParams: z.object({
            id: z.string(),
        }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.unknown(),
        invalidates: ['GET /email-template'],
    }),

    'PATCH /email-template/:id': endpoint({
        description: 'Update email template',
        pathParams: z.object({
            id: z.string(),
        }),
        queryParams: z.undefined(),
        requestBody: EmailTemplateDTORuntype.omit({ id: true, updated_at: true }).or(
            z.object({ is_default: z.boolean() }),
        ),
        responseBody: z.unknown(),
        invalidates: ['GET /email-template'],
    }),
};
