import { useHttpQuery } from '@/resources/http/useHttpQuery';
import { usePCBDesignItemFromAssembly } from '@/resources/pcb/pcbHandlers';
import { t } from '@lingui/macro';
import { throwErrorUnlessProduction } from '@luminovo/commons';
import { CustomOptionDTO, PartSpecificationTypes, PcbAlertDTO, PcbAlertTypeEnum } from '@luminovo/http-client';
import React from 'react';
import { PCBRouteSuffix } from '../../PcbSidebar/utils/pcbSidebarLinks';

export type CustomPartAlert = {
    alert: PcbAlertDTO;
    title: string;
    message: string;
    severity: 'warning' | 'error';
    action: {
        label: string;
        route: PCBRouteSuffix;
    };
};

const convertCustomPartAlertToAlert = (alert: PcbAlertDTO, type: PcbAlertTypeEnum): CustomPartAlert => {
    const file = alert.alert_type.type !== 'Pcb' ? alert.alert_type.file : undefined;

    switch (type) {
        case PcbAlertTypeEnum.FileTimeout:
            if (!file) {
                throwErrorUnlessProduction('File timeout alert has no file');
            }
            return {
                alert,
                title: t`File timeout`,
                message: t`The file ${file} had a timeout during processing. Please contact support.`,
                severity: 'error',
                action: {
                    label: t`Open File manager`,
                    route: 'files',
                },
            };
        case PcbAlertTypeEnum.RenderingError:
            if (!file) {
                throwErrorUnlessProduction('Rendering error alert has no file');
            }
            return {
                alert,
                title: t`Rendering error`,
                message: t`The file ${file} had an error during processing. Please contact support`,
                severity: 'error',
                action: {
                    label: t`Open File manager`,
                    route: 'files',
                },
            };
        case PcbAlertTypeEnum.UnusualBoardSize:
            return {
                alert,
                title: t`Unusual dimensions`,
                message: t`The board has an unusual size. Please double check the outline and the dimensions.`,
                severity: 'warning',
                action: {
                    label: t`Open PCB specification`,
                    route: 'specification',
                },
            };
        case PcbAlertTypeEnum.NoOutlineFile:
            return {
                alert,
                title: t`No outline file`,
                message: t`We could not find a file for the outline. Please consider checking the file assignments or upload an outline.`,
                severity: 'warning',
                action: {
                    label: t`Open File manager`,
                    route: 'files',
                },
            };
        case PcbAlertTypeEnum.GeneratedOutline:
            return {
                alert,
                title: t`Generated outline`,
                message: t`The board is using an automatically generated outline. Please double check the board dimensions, or consider uploading a file for the outline.`,
                severity: 'warning',
                action: {
                    label: t`Open File manager`,
                    route: 'files',
                },
            };

        case PcbAlertTypeEnum.InconclusiveDimensions:
            return {
                alert,
                title: t`Inconclusive board dimensions`,
                message: t`The size of the board outline and the specified board dimensions are different. Please consider double checking if this is intentional.`,
                severity: 'warning',
                action: {
                    label: t`Open File manager`,
                    route: 'files',
                },
            };

        case PcbAlertTypeEnum.NoDrillFile:
            return {
                alert,
                title: t`Drill files are missing`,
                message: t`We did not find any drill files. Please consider double checking your file assignments or upload missing files.`,
                severity: 'error',
                action: {
                    label: t`Open File manager`,
                    route: 'files',
                },
            };

        case PcbAlertTypeEnum.NoSoldermask:
            return {
                alert,
                title: t`No soldermask`,
                message: t`We did not find a Soldermask. Please consider double checking your file assignments or upload missing files.`,
                severity: 'warning',
                action: {
                    label: t`Open File manager`,
                    route: 'files',
                },
            };

        case PcbAlertTypeEnum.OddNumberOfCopperFiles:
            return {
                alert,
                title: t`Odd number of copper files`,
                message: t`We found an odd number of copper files. Please consider double checking your file assignments.`,
                severity: 'warning',
                action: {
                    label: t`Open File manager`,
                    route: 'files',
                },
            };

        case PcbAlertTypeEnum.NoSilkscreen:
            return {
                alert,
                title: t`No silkscreen`,
                message: t`We did not find a Silkscreen. Please consider double checking your file assignments or upload missing files.`,
                severity: 'warning',
                action: {
                    label: t`Open File manager`,
                    route: 'files',
                },
            };
        case PcbAlertTypeEnum.NoPlatedVias:
            return {
                alert,
                title: t`No plated vias`,
                message: t`We did not find any plated vias. Please consider double checking your file assignments or upload missing files.`,
                severity: 'warning',
                action: {
                    label: t`Open File manager`,
                    route: 'files',
                },
            };

        default:
            throw new Error(`Unknown PCB alert type: ${type}`);
    }
};

const convertCustomPartAlertsToAlerts = (data: PcbAlertDTO[] | undefined): CustomPartAlert[] => {
    if (!data) return [];

    const alerts = data.map((alert): CustomPartAlert => {
        const type = alert.alert_type.type === 'Pcb' ? alert.alert_type.pcb_alert_type.type : alert.alert_type.type;
        return convertCustomPartAlertToAlert(alert, type);
    });

    return alerts.filter((alert) => alert.alert.status === 'Active').sort((a, b) => (a.severity === 'error' ? -1 : 1));
};

export const useCustomPartOfPcb = ({ assemblyId }: { assemblyId: string }): CustomOptionDTO | undefined => {
    const { pcbDesignItem } = usePCBDesignItemFromAssembly(assemblyId);

    const customParts = React.useMemo(() => {
        if (!pcbDesignItem) return undefined;
        const partSpecification = pcbDesignItem.part_specification;
        if (partSpecification && partSpecification.type === PartSpecificationTypes.Custom && partSpecification.data) {
            return partSpecification.data;
        }
    }, [pcbDesignItem]);

    if (!customParts) return undefined;

    if (customParts.length > 1) {
        throwErrorUnlessProduction(
            new Error(`Found more than one custom part ${customParts.map((part) => part.part.data)}`),
        );
    }

    return customParts[0];
};

export const usePcbAlerts = ({ pcbId, assemblyId }: { pcbId: string; assemblyId: string }) => {
    const customPart = useCustomPartOfPcb({ assemblyId });
    const customPartId = customPart?.part.data;
    return useHttpQuery(
        'GET /custom-part-alerts/:partId',
        { pathParams: { partId: customPartId ?? '' } },
        {
            enabled: !!customPartId,
            select(data) {
                return data ? convertCustomPartAlertsToAlerts(data) : undefined;
            },
            refetchInterval: ({ state }) => {
                if (state.data) {
                    return 10_000;
                }
                return Infinity;
            },
        },
    );
};
