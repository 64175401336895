import * as z from 'zod';
import { endpoint } from '../http/endpoint';
import {
    ManufacturingScenarioTemplateDTORuntype,
    ManufacturingScenarioTemplatePatchDTORuntype,
    ManufacturingScenarioTemplatePostDTORuntype,
} from './manufacturingScenarioTemplatesBackendTypes';

export const manufacturingScenarioTemplatesEndpoints = {
    'GET /manufacturing-scenario-templates': endpoint({
        description: 'Fetches a list of templates',
        pathParams: z.undefined(),
        queryParams: z.object({
            expense_ids: z.array(z.string()).optional(),
            activity_ids: z.array(z.string()).optional(),
        }),
        requestBody: z.undefined(),
        responseBody: z.object({ data: z.array(ManufacturingScenarioTemplateDTORuntype) }),
    }),

    'POST /manufacturing-scenario-templates': endpoint({
        description: 'Creates a new template',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: ManufacturingScenarioTemplatePostDTORuntype,
        responseBody: z.object({ data: ManufacturingScenarioTemplateDTORuntype }),
        invalidates: ['GET /manufacturing-scenario-templates', 'GET /manufacturing-scenario-templates/:templateId'],
    }),

    'GET /manufacturing-scenario-templates/:templateId': endpoint({
        description: 'Fetches a single template by id',
        pathParams: z.object({ templateId: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({ data: ManufacturingScenarioTemplateDTORuntype }),
    }),

    'PATCH /manufacturing-scenario-templates/:templateId': endpoint({
        description: 'Updates a template',
        pathParams: z.object({ templateId: z.string() }),
        queryParams: z.undefined(),
        requestBody: ManufacturingScenarioTemplatePatchDTORuntype,
        responseBody: z.object({ data: ManufacturingScenarioTemplateDTORuntype }),
        invalidates: ['GET /manufacturing-scenario-templates', 'GET /manufacturing-scenario-templates/:templateId'],
    }),

    'DELETE /manufacturing-scenario-templates/:templateId': endpoint({
        description: 'Deletes a template',
        pathParams: z.object({ templateId: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({ deleted: z.number() }),
    }),
};
