import * as z from 'zod';
import { FormulaScriptErrorRuntype } from './formulaScriptErrorBackendTypes';

const ErrorResponseRuntype = z.object({
    result: z.literal('Error'),
    details: FormulaScriptErrorRuntype,
});

const OkResponseRuntype = z.object({
    result: z.literal('Ok'),
    details: z.object({
        identifiers: z.array(z.string()),
    }),
});
export type FormulaOKResponseDTO = z.infer<typeof OkResponseRuntype>;

export const FormulaResponseRuntype = ErrorResponseRuntype.or(OkResponseRuntype);

export type FormulaResponseDTO = z.infer<typeof FormulaResponseRuntype>;

export const FormulaPostRuntype = z.object({
    formula: z.string(),
});

export const FormulaTestPostRuntype = z.object({
    formula: z.string(),
    inputs: z.record(z.string(), z.string()),
});

const OkTestResponseRuntype = z.object({
    result: z.literal('Ok'),
    details: z.object({
        return_value: z.string(),
    }),
});

export const FormulaTestResponseRuntype = ErrorResponseRuntype.or(OkTestResponseRuntype);
export type FormulaTestResponseDTO = z.infer<typeof FormulaTestResponseRuntype>;
