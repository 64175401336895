import { t } from '@lingui/macro';
import { isPresent } from '@luminovo/commons';
import { MenuItem, useConfirmationDialog } from '@luminovo/design-system';
import { Menu } from '@mui/material';
import { Column, GridApi } from 'ag-grid-community';
import { useState } from 'react';
import { formatValue } from '../columns/getColumnValue';

export const MenuItemSetAllValues = <T extends string | boolean | number>({
    column,
    api,
    options,
    onClose,
}: {
    column: Column<T>;
    api: GridApi<Record<string, T>>;
    options: Array<T>;
    onClose: () => void;
}): JSX.Element => {
    const [anchorEl, setAnchorEl] = useState<null | Element>(null);
    const [replacementValue, setReplacementValue] = useState<T | null>(null);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl((value) => (isPresent(value) ? null : event.currentTarget));
    };

    const { modal, setOpen } = useConfirmationDialog({
        title: t`Fill all cells with ${formatValue(replacementValue, column)}`,
        description: t`Are you sure you want to fill all cells in this column with ${formatValue(replacementValue, column)}?`,
        onConfirmation: () => {
            api.forEachNode((node) => {
                node.setDataValue(column.getColId(), replacementValue);
            });
            handleClose();
            onClose();
        },
    });

    if (column.getColDef().editable === false) {
        return <></>;
    }

    return (
        <>
            {modal}
            <MenuItem label={t`Fill all cells`} onClick={handleOpen} />
            <Menu
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                elevation={1}
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {options.map((option) => (
                    <MenuItem
                        key={String(option)}
                        label={formatValue(option, column)}
                        onClick={() => {
                            setReplacementValue(option);
                            setOpen(true);
                        }}
                    />
                ))}
            </Menu>
        </>
    );
};
