import { t, Trans } from '@lingui/macro';
import { assertUnreachable, isPresent } from '@luminovo/commons';
import { Comparable } from '@luminovo/design-system';
import { PartLiteTypes } from '@luminovo/http-client';
import React from 'react';
import { formatPartLite } from '../../../Negotiations/model/formatPartLite';
import { extractDisplayableSupplierOrInventoryName } from '../SolutionConfigurations/SolutionConfigurationTable';
import { SolutionConfigurationTableData } from '../SolutionConfigurations/SolutionConfigurationTypes';

export type FieldId = 'supplierName' | 'leadTime' | 'unitPrice' | 'effectiveTotalPrice';

export interface ChartSpec<TKeys, TDatum> {
    id: FieldId;
    title: JSX.Element;
    keys: TKeys[];
    groupBy: Array<{
        extractor: (data: SolutionConfigurationTableData) => string;
        label: React.ReactNode;
        tooltip?: string;
    }>;
    map: (
        data: SolutionConfigurationTableData,
        extractLabel: (item: SolutionConfigurationTableData) => string,
    ) => TDatum;
    aggregate: (a: TDatum, b: TDatum) => TDatum;
    orderBy: Comparable<TDatum>;
    render: (props: {
        rfqId: string;
        data: TDatum[];
        keys: TKeys[];
        onSelectDatum: (datum: TDatum) => void;
    }) => JSX.Element | null;
}

export function extractLabelSupplier(data: SolutionConfigurationTableData): string {
    return extractDisplayableSupplierOrInventoryName(data);
}

function extractLabelDesignator(data: SolutionConfigurationTableData): string {
    return `${data.solutionConfigurationSummary.designItems.map((designItem) => designItem.designator).join(', ')}`;
}

function extractLabelManufacturer(row: SolutionConfigurationTableData): string {
    const selectedPart = row.partSummary.selected_part;
    if (!isPresent(selectedPart)) {
        return t`Unknown`;
    }

    const kind = selectedPart.kind;
    switch (kind) {
        case PartLiteTypes.OffTheShelf:
        case PartLiteTypes.RawSpecification:
        case PartLiteTypes.Unknown:
            return selectedPart.manufacturer.name ?? t`Unknown`;
        case PartLiteTypes.Custom:
            return t`Custom`;
        case PartLiteTypes.Generic:
            return t`Generic`;
        case PartLiteTypes.CustomComponent:
            return t`Custom component`;
        case PartLiteTypes.Ipn:
            return 'IPN';
        default:
            assertUnreachable(kind);
    }
}

function extractLabelPart(data: SolutionConfigurationTableData): string {
    const selectedPart = data.partSummary.selected_part;
    if (!isPresent(selectedPart)) {
        return t`Unknown`;
    }
    return formatPartLite(selectedPart);
}

export const defaultGroupingFunctions: ChartSpec<unknown, unknown>['groupBy'] = [
    { label: 'Designator', extractor: extractLabelDesignator },
    { label: <Trans>Manufacturer</Trans>, extractor: extractLabelManufacturer },
    { label: <Trans>Part</Trans>, extractor: extractLabelPart },
    { label: <Trans>Supplier</Trans>, extractor: extractLabelSupplier },
];
