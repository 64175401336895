import * as z from 'zod';

export const AdditionalServiceTargetRuntype = z.enum(['TopLevelAssembly', 'AllAssemblies']);
export type AdditionalServiceTarget = z.infer<typeof AdditionalServiceTargetRuntype>;

export const AdditionalServicesPostRequestRuntype = z.object({
    name_en: z.string(),
    name_de: z.string(),
    manufacturing_scenario_template_id: z.string().nullable(),
    applies_to: AdditionalServiceTargetRuntype,
});

export const AdditionalServicesPatchRequestRuntype = AdditionalServicesPostRequestRuntype;

export const AdditionalServicesGetResponseRuntype = z.object({
    id: z.string(),
    name_en: z.string(),
    name_de: z.string(),
    manufacturing_scenario_template_id: z.string().nullable(),
    applies_to: AdditionalServiceTargetRuntype,
    created_at: z.string(),
    updated_at: z.string(),
});

export const AdditionalServicesPostResponseRuntype = AdditionalServicesGetResponseRuntype;
export const AdditionalServicesPatchResponseRuntype = AdditionalServicesGetResponseRuntype;

export type AdditionalServicesGetResponseDTO = z.infer<typeof AdditionalServicesGetResponseRuntype>;
