/* eslint-disable spellcheck/spell-checker */
import { FormContainer, ValidationErrors } from '@/components/formLayouts/FormContainer';
import { SubmitButton } from '@/components/formLayouts/SubmitButton';
import { useSites } from '@/resources/organizationSettings/sitesHandler';
import { t } from '@lingui/macro';
import {
    colorSystem,
    FieldNumeric,
    FieldNumericControlled,
    FieldSelect,
    FieldSelectControlled,
    FieldTextControlled,
    Flexbox,
    FormItem,
    Message,
    SecondaryButton,
    Text,
} from '@luminovo/design-system';
import { Incoterms, SiteDTO } from '@luminovo/http-client';
import { Box, InputAdornment } from '@mui/material';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

export interface SupplierTermFormState {
    site: SiteDTO | null;
    incoterms: Incoterms | null;
    named_place?: string;
    payment_terms: {
        net_payment_due_days?: number;
        early_payment_due_days?: number;
        early_payment_discount?: number;
    };
}

export const SupplierTermForm = ({
    prevState,
    onSubmit,
    handleClose,
}: {
    prevState?: SupplierTermFormState;
    onSubmit: (values: SupplierTermFormState) => void;
    handleClose: VoidFunction;
}) => {
    const { data: sites = [] } = useSites();

    const defaultSite: SiteDTO = {
        id: 'all-sites',
        name: 'All sites',
        address: null,
        notes: null,
        site_number: null,
        is_default_for: [],
        tags: [],
    };

    const defaultValue: SupplierTermFormState = {
        site: prevState?.site ?? defaultSite,
        incoterms: prevState?.incoterms ?? null,
        named_place: prevState?.named_place ?? '',
        payment_terms: {
            net_payment_due_days: prevState?.payment_terms.net_payment_due_days ?? 0,
            early_payment_due_days: prevState?.payment_terms.early_payment_due_days ?? 0,
            early_payment_discount: prevState?.payment_terms.early_payment_discount ?? 0,
        },
    };
    const validationErrors: ValidationErrors<SupplierTermFormState> = {
        'supplier_terms.already_exists': {
            fieldPath: 'site',
        },
        'supplier_terms.payment_due_days_constraint_violation': {
            fieldPath: 'payment_terms.net_payment_due_days',
        },
    };
    return (
        <FormContainer defaultValues={defaultValue} onSubmit={onSubmit} validationErrors={validationErrors}>
            <Flexbox flexDirection={'column'} rowGap={4}>
                <FormItemSite sites={[defaultSite, ...sites]} />
                <FormItemShippingTerms />
                <FormItemPaymentTerms />
                <Flexbox justifyContent="space-between">
                    <SecondaryButton onClick={handleClose}>{t`Cancel`}</SecondaryButton>
                    <SubmitButton label={prevState ? t`Save` : t`Add`} />
                </Flexbox>
            </Flexbox>
        </FormContainer>
    );
};

const FormItemContainer = ({
    children,
    label,
    required = false,
}: {
    children: React.ReactNode;
    label: string;
    required?: boolean;
}) => {
    return (
        <FormItem
            required={required}
            flex={1}
            label={label}
            LabelProps={{ variant: 'h4', color: colorSystem.neutral[7] }}
        >
            {children}
        </FormItem>
    );
};

const FormSection = ({ children, label }: { children: React.ReactNode; label: string }) => {
    return (
        <Flexbox flexDirection={'column'} rowGap={2}>
            <Text variant="h4" color={colorSystem.neutral[9]}>
                {label}
            </Text>
            {children}
        </Flexbox>
    );
};

const FormItemShippingTerms = () => {
    return (
        <FormSection label={t`Shipping terms`}>
            <FormItemIncoterms />
            <FormItemNamedPlace />
        </FormSection>
    );
};

const FormItemSite = ({ sites }: { sites: SiteDTO[] }) => {
    const { control, clearErrors } = useFormContext<SupplierTermFormState>();
    return (
        <FormItemContainer required label={t`Site`}>
            <Controller
                name="site"
                control={control}
                rules={{
                    required: true,
                }}
                render={({ field, fieldState }) => (
                    <React.Fragment>
                        <FieldSelect
                            options={sites}
                            getOptionKey={(site) => site.id}
                            getOptionLabel={(site) => site.name}
                            // Remove error when we stopped focusing on item
                            onBlur={() => clearErrors('site')}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            value={field.value}
                            onChange={(site) => field.onChange(site)}
                            autoComplete={false}
                        />
                        {fieldState.error && (
                            <Message variant="red" size="small" attention="high" message={fieldState.error.message} />
                        )}
                    </React.Fragment>
                )}
            />
        </FormItemContainer>
    );
};

export const getIncotermsFullDetails = (incoterms: Incoterms): string => {
    switch (incoterms) {
        case Incoterms.EXW:
            return 'Ex Works';
        case Incoterms.FCA:
            return 'Free Carrier';
        case Incoterms.CPT:
            return 'Carriage Paid To';
        case Incoterms.CIP:
            return 'Carriage and Insurance Paid To';
        case Incoterms.DAP:
            return 'Delivered At Place';
        case Incoterms.DPU:
            return 'Delivered at Place Unloaded';
        case Incoterms.DDP:
            return 'Delivered Duty Paid';
        case Incoterms.FAS:
            return 'Free Alongside Ship';
        case Incoterms.FOB:
            return 'Free On Board';
        case Incoterms.CFR:
            return 'Cost and Freight';
        case Incoterms.CIF:
            return 'Cost, Insurance and Freight';
        default:
            return '';
    }
};
const FormItemIncoterms = () => {
    const { control } = useFormContext<SupplierTermFormState>();
    return (
        <FormItemContainer label={t`Incoterms`}>
            <FieldSelectControlled
                control={control}
                name="incoterms"
                FieldProps={{
                    options: [null, ...Object.values(Incoterms)],
                    getOptionKey: (incoterm) => incoterm ?? 'no-incoterm',
                    getOptionLabel: (incoterm) =>
                        incoterm ? `${incoterm} (${getIncotermsFullDetails(incoterm)})` : `No incoterm`,
                    placeholder: t`Select incoterms`,
                }}
            />
        </FormItemContainer>
    );
};

const FormItemNamedPlace = () => {
    const { control } = useFormContext<SupplierTermFormState>();
    return (
        <FormItemContainer label={t`Named place`}>
            <FieldTextControlled
                FieldProps={{ placeholder: t`Enter incoterms named place` }}
                control={control}
                name="named_place"
            />
        </FormItemContainer>
    );
};

const FormItemNetPaymentDueDays = () => {
    const { control, clearErrors } = useFormContext<SupplierTermFormState>();
    return (
        <FormItemContainer label={t`Net payment due days`}>
            <Controller
                name="payment_terms.net_payment_due_days"
                control={control}
                render={({ field, fieldState }) => (
                    <React.Fragment>
                        <FieldNumeric
                            InputProps={{
                                endAdornment: <InputAdornment position="end">{t`days`}</InputAdornment>,
                            }}
                            onBlur={() => clearErrors('payment_terms.net_payment_due_days')}
                            value={field.value ?? null}
                            onChange={(netPaymentDueDays) => field.onChange(netPaymentDueDays)}
                        />
                        {fieldState.error && (
                            <Box sx={{ columnSpan: 2 }}>
                                <Message
                                    variant="red"
                                    size="small"
                                    attention="high"
                                    message={fieldState.error.message}
                                />
                            </Box>
                        )}
                    </React.Fragment>
                )}
            />
        </FormItemContainer>
    );
};

const FormItemPaymentTerms = () => {
    const { control } = useFormContext<SupplierTermFormState>();
    return (
        <FormSection label={t`Payment terms`}>
            <Box display="grid" gridTemplateColumns="1fr 1fr" columnGap={6} rowGap={2}>
                <FormItemNetPaymentDueDays />
                <Box />
                <FormItemContainer label={t`Early payment due days`}>
                    <FieldNumericControlled
                        control={control}
                        name="payment_terms.early_payment_due_days"
                        FieldProps={{
                            style: { textAlign: 'right' },
                            InputProps: {
                                style: { textAlign: 'right' },
                                endAdornment: <InputAdornment position="end">{t`days`}</InputAdornment>,
                            },
                        }}
                    />
                </FormItemContainer>
                <FormItemContainer label={t`Early payment discount`}>
                    <FieldNumericControlled
                        control={control}
                        min={0}
                        max={100}
                        name="payment_terms.early_payment_discount"
                        FieldProps={{
                            InputProps: {
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            },
                        }}
                    />
                </FormItemContainer>
            </Box>
        </FormSection>
    );
};
