import * as z from 'zod';
import { endpoint } from '../http/endpoint';
import { HeilindCredentialsInputDTORuntype, HeilindResponseBodyDTORuntype } from './heilindBackendTypes';

export const heilindEndpoints = {
    'GET /3rdparty/heilind/credentials': endpoint({
        description: 'Returns credentials for heilind integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: HeilindResponseBodyDTORuntype,
    }),
    'POST /3rdparty/heilind/credentials': endpoint({
        description: 'Uploads credentials for heilind API integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: HeilindCredentialsInputDTORuntype,
        responseBody: HeilindResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/heilind/credentials'],
    }),
    'PATCH /3rdparty/heilind/credentials': endpoint({
        description: 'Updates credentials for heilind API integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: HeilindCredentialsInputDTORuntype,
        responseBody: HeilindResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/heilind/credentials'],
    }),
    'DELETE /3rdparty/heilind/credentials': endpoint({
        description: 'Deletes existing credentials for heilind API integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({ deleted: z.number() }),
        invalidates: ['GET /3rdparty/heilind/credentials'],
    }),
};
