import * as z from 'zod';
import { endpoint } from '../http/endpoint';
import { CustomerBillingAddressRuntype, CustomerDTOPostRuntype, CustomerDTORuntype } from './customerBackendTypes';

export const customerEndpoints = {
    'GET /customers': endpoint({
        description: 'Returns all customer organizations of the tenant EMS organization',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({
            data: z.array(CustomerDTORuntype),
        }),
    }),

    'POST /customers': endpoint({
        description: 'Create a new customer for the tenant EMS organization',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: CustomerDTOPostRuntype,
        responseBody: z.object({
            data: CustomerDTORuntype,
        }),
        invalidates: ['GET /customers', 'GET /customers/:customerId'],
    }),

    'GET /customers/:customerId': endpoint({
        description: 'Fetch a particular customer',
        pathParams: z.object({ customerId: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({
            data: CustomerDTORuntype,
        }),
    }),

    'PATCH /customers/:customerId': endpoint({
        description: 'Update a customer as an EMS',
        pathParams: z.object({ customerId: z.string() }),
        queryParams: z.undefined(),
        requestBody: CustomerDTOPostRuntype,
        responseBody: z.object({
            data: CustomerDTORuntype,
        }),
        invalidates: ['GET /customers', 'GET /customers/:customerId'],
    }),

    'PATCH /customers/:customerId/billing-address': endpoint({
        description: 'Update a customer billing address',
        pathParams: z.object({ customerId: z.string() }),
        queryParams: z.undefined(),
        requestBody: CustomerBillingAddressRuntype,
        responseBody: z.object({
            data: CustomerBillingAddressRuntype,
        }),
        invalidates: ['GET /customers', 'GET /customers/:customerId', 'GET /my/details'],
    }),

    'DELETE /customers/:customerId': endpoint({
        description: 'Delete a customer as an EMS',
        pathParams: z.object({ customerId: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.unknown(),
        invalidates: [],
        removes: ['GET /customers/:customerId', 'GET /customers'],
    }),
};
