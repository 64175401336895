import * as z from 'zod';

export interface QuotePriceRequestBodyDTO extends z.infer<typeof QuotePriceRequestBodyDTORuntype> {}
export const QuotePriceRequestBodyDTORuntype = z.object({
    rfq: z.string(),
    part_ids: z.array(z.string()),
});

export interface QuotePriceBulkRequestBodyDTO extends z.infer<typeof QuotePriceBulkRequestBodyDTORuntype> {}
export const QuotePriceBulkRequestBodyDTORuntype = z.object({
    rfq: z.string(),
    part_ids: z.array(z.string()),
    should_filter_by_line_card: z.boolean(),
    generate_all_quantity_combinations: z.boolean(),
    supplier_ids: z.array(z.string()),
    sourcing_scenario_ids: z.array(z.string()),
    should_include_pcb_specification: z.boolean(),
    should_include_shipping_panel_specification: z.boolean(),
});

export interface SendQuotePriceRequestBodyDTO extends z.infer<typeof SendQuotePriceRequestBodyDTORuntype> {}
export const SendQuotePriceRequestBodyDTORuntype = z.object({
    rfq: z.string(),
    part_ids: z.array(z.string()),
    should_filter_by_line_card: z.boolean(),
    generate_all_quantity_combinations: z.boolean(),
    should_include_pcb_specification: z.boolean(),
    should_include_shipping_panel_specification: z.boolean(),
    notes: z.optional(z.string()),
    sourcing_scenario_ids: z.array(z.string()),
    due_date: z.optional(z.string()),
    mail_data: z.array(
        z.object({
            supplier_id: z.string(),
            supplier_contacts: z.array(z.string()),
            cc: z.array(z.string()),
            email: z.object({ subject: z.string(), body: z.string() }),
        }),
    ),
    should_include_mail_attachments: z.boolean(),
});
