import { assertUnreachable, isPresent, MonetaryValue } from '@luminovo/commons';
import {
    EvaluatedTcoCostDTO,
    ManualAdditionalSolutionCostItemDTO,
    SolutionConfigurationDTO,
    TcoCostDTO,
} from '@luminovo/http-client';
import { Solution } from '../../types';

export type CostType = 'packaging' | 'discount' | 'shipping' | 'customs' | 'other';

export type CostName = 'packaging_cost' | 'discount' | 'shipping_cost' | 'customs_cost' | 'other_cost';

export const getCostName = (costType: CostType): CostName => {
    switch (costType) {
        case 'packaging':
            return 'packaging_cost';
        case 'discount':
            return 'discount';
        case 'shipping':
            return 'shipping_cost';
        case 'customs':
            return 'customs_cost';
        case 'other':
            return 'other_cost';
        default:
            assertUnreachable(costType);
    }
};

export function extractEvaluatedCostForType(
    costType: CostType,
    tcoCosts: TcoCostDTO | undefined,
): EvaluatedTcoCostDTO | null {
    const unitTcoCostCategory = tcoCosts?.breakdown[getCostName(costType)];
    if (!isPresent(unitTcoCostCategory)) {
        return null;
    }

    return unitTcoCostCategory;
}

export function extractAdditionalCostForType(
    costType: CostType,
    solution: Pick<Solution, 'cost'>,
    {
        mode,
    }: {
        mode?: 'effective' | 'automatic';
    } = {},
): MonetaryValue | null {
    const unitTcoCost = solution.cost.tco_costs;
    const tcoCostCategory = unitTcoCost?.breakdown[getCostName(costType)];
    if (!isPresent(tcoCostCategory)) {
        return null;
    }

    const { type, data } = tcoCostCategory;
    switch (type) {
        case 'Automatic':
            return data.cost.preferred;
        case 'Manual':
            if (mode === 'automatic') {
                return data.automatic?.cost.preferred ?? null;
            } else {
                return data.manual.preferred;
            }
        default:
            assertUnreachable(type);
    }
}

export function extractManualAdditionalCostForType(
    costType: CostType,
    solutionConfiguration: SolutionConfigurationDTO,
): ManualAdditionalSolutionCostItemDTO | null {
    const manualAdditionalCost = solutionConfiguration.manual_total_cost_of_ownership;
    return manualAdditionalCost?.[getCostName(costType)] ?? null;
}

export function isManualCostForType(costType: CostType, solution: Solution): boolean {
    const unitTcoCost = solution.cost.tco_costs;
    const unitTcoCostItem = unitTcoCost?.breakdown[getCostName(costType)];
    return unitTcoCostItem?.type === 'Manual';
}

export function hasTcoCost(solution: Solution): boolean {
    const tcoCostBreakdown = solution.cost.tco_costs?.breakdown;
    return (
        isPresent(tcoCostBreakdown?.packaging_cost) ||
        isPresent(tcoCostBreakdown?.discount) ||
        isPresent(tcoCostBreakdown?.shipping_cost) ||
        isPresent(tcoCostBreakdown?.customs_cost) ||
        isPresent(tcoCostBreakdown?.other_cost)
    );
}
