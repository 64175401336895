import { styled } from '@mui/material';
import { colorSystem } from '../../theme';
import { BaseIconButton } from './BaseIconButton';

export const TertiaryIconButton = styled(BaseIconButton)({
    color: colorSystem.neutral[7],
    '&:hover': {
        background: 'none',
        color: colorSystem.primary[6],
    },
    '&:active': {
        background: 'none',
        color: colorSystem.primary[7],
    },
    '&:disabled': {
        color: colorSystem.neutral[5],
    },
});
