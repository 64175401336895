import * as z from 'zod';
import { endpoint } from '../http/endpoint';
import {
    CustomComponentFullRuntype,
    EmsPartNumberPartMatchRequestRuntype,
    EmsPartNumberPartMatchResponseRuntype,
    IpnFullPartMatchRuntype,
    OtsComponentFullRuntype,
    OtsOrCustomComponentRuntype,
} from '../internalPartNumber/internalPartNumberBackendTypes';
import { UsedInResponseRuntype } from '../rfq';
import { RfQContextQueryParamsRuntype } from '../rfqContext';
import {
    BackendPartOfferSummaryDTO,
    CustomFullPartRuntype,
    CustomPartInputRuntype,
    CustomPartTypeEnumRuntype,
    CustomPartUpdateInputRuntype,
    EmissionDataRequestDTORuntype,
    FileUrlRuntype,
    GenericFullPartRuntype,
    IpnInventoryRuntype,
    LoadingOffersProgressDTORuntype,
    MpnMatchesRuntype,
    OffTheShelfPartSearchFilterRequestRuntype,
    OffTheShelfPartSearchResponseRuntype,
    OffTheShelfPartSearchSortRequestRuntype,
    OffTheShelfPartVoteRuntype,
    OtsFormPatchValuesRuntype,
    OtsFormPostValuesRuntype,
    OtsFullPartRuntype,
    PackageDTORuntype,
    PackageFamilyRuntype,
    PackageTagRuntype,
    PartAlternativeRuntype,
    PartAvailabilityRuntype,
    PartCategoryDTORuntype,
    PartDTORuntype,
    PartLiteRuntype,
    RFQContextualizedBulkIdsRuntype,
    StandardPartDTORuntype,
    TechnicalParametersRuntype,
} from './partBackendTypes';

// Split the endpoints into smaller groups to avoid TypeScript serialization limits
const partEndpointsGroup1 = {
    'POST /parts/lite': endpoint({
        description: 'Returns lite versions of parts for the given list of part IDs',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.object({
            ids: z.array(z.union([PartDTORuntype, z.object({ type: z.literal('Unknown'), data: z.string() })])),
        }),
        responseBody: z.object({
            items: z.array(PartLiteRuntype),
        }),
    }),

    'GET /parts/off-the-shelf-types': endpoint({
        description: 'Fetches all OTS part types',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({
            items: z.array(z.string()),
        }),
    }),

    'POST /parts/off-the-shelf': endpoint({
        description: 'Creates an off-the-shelf part',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: OtsFormPostValuesRuntype,
        responseBody: z.object({
            data: OtsFullPartRuntype,
        }),
        invalidates: ['POST /parts/off-the-shelf/bulk', 'POST /ipns/bulk'],
    }),
};

const partEndpointsGroup2 = {
    'POST /parts/off-the-shelf/search/mpn/bulk': endpoint({
        description: 'Searches via mpns for multiple parts',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.union([
            z.object({
                items: z.array(z.string()),
                search_external_apis: z.boolean(),
                use_elastic: z.boolean(),
                rfq_context: z.literal('WithinRfQ'),
                rfq_id: z.string(),
                size: z.number().optional(),
            }),
            z.object({
                items: z.array(z.string()),
                search_external_apis: z.boolean(),
                use_elastic: z.boolean(),
                rfq_context: z.literal('OutsideRfQ'),
                size: z.number().optional(),
            }),
        ]),
        responseBody: z.object({
            data: z.record(z.string(), z.array(OtsFullPartRuntype)),
        }),
    }),

    'POST /parts/library/search': endpoint({
        description: 'Searches for parts in the library',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z
            .object({
                page_size: z.number(),
                page_params: z.unknown().or(z.undefined()),
                filters: z.array(OffTheShelfPartSearchFilterRequestRuntype),
                sorts: z.array(OffTheShelfPartSearchSortRequestRuntype),
                global_filter: z.string(),
                include_aggregations: z.boolean().optional(),
                search_external_apis: z.boolean().optional(),
            })
            .and(RfQContextQueryParamsRuntype),
        responseBody: OffTheShelfPartSearchResponseRuntype,
    }),
};

const partEndpointsGroup3 = {
    'PATCH /parts/off-the-shelf/:partId': endpoint({
        description: 'Updates an off-the-shelf part',
        pathParams: z.object({ partId: z.string() }),
        queryParams: RfQContextQueryParamsRuntype,
        requestBody: OtsFormPatchValuesRuntype,
        responseBody: z.object({
            data: OtsFullPartRuntype,
        }),
        invalidates: [
            'GET /assemblies/:assemblyId/descendants',
            'POST /ipns/bulk',
            'POST /parts/availability',
            'POST /parts/off-the-shelf/bulk',
            'POST /parts/off-the-shelf/search/generic/bulk',
            'POST /components/bulk',
            'GET /parts/off-the-shelf/:partId/votes',
        ],
    }),

    'POST /parts/off-the-shelf/bulk': endpoint({
        description: 'Returns all off-the-shelf parts for the given list of OTS part IDs',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: RFQContextualizedBulkIdsRuntype,
        responseBody: z.object({
            items: z.array(OtsFullPartRuntype),
        }),
    }),

    'POST /parts/availability': endpoint({
        description:
            'Returns the availability of all the given parts. The response is a map from part IDs to availability information',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.object({
            ids: z.array(StandardPartDTORuntype),
            assembly_id: z.string(),
        }),
        responseBody: z.object({
            items: z.array(PartAvailabilityRuntype),
        }),
    }),
};

const partEndpointsGroup4 = {
    'POST /parts/emission-data-requests': endpoint({
        description: 'Sends emission data requests for off-the-shelf part ids and generic part ids',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: EmissionDataRequestDTORuntype,
        responseBody: z.string(),
        invalidates: [
            'GET /assemblies/:assemblyId/descendants',
            'POST /parts/off-the-shelf/bulk',
            'GET /assemblies/:assemblyId/descendants-summary',
            'GET /assemblies/:id/descendants-part-ids-without-emission-data',
            'GET /organization/emission-request-quota',
        ],
    }),

    'POST /ipns/bulk': endpoint({
        description: 'Returns all IPNs for the given list of IPN IDs',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.union([
            z.object({
                ids: z.array(z.string()),
                rfq_context: z.literal('WithinRfQ'),
                rfq_id: z.string(),
            }),
            z.object({
                ids: z.array(z.string()),
                rfq_context: z.literal('OutsideRfQ'),
            }),
        ]),
        responseBody: z.object({
            items: z.array(OtsComponentFullRuntype),
        }),
    }),
};

const partEndpointsGroup5 = {
    'POST /components/bulk': endpoint({
        description: 'Returns all components for the given list of component IDs',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.union([
            z.object({
                ids: z.array(z.string()),
                rfq_context: z.literal('WithinRfQ'),
                rfq_id: z.string(),
            }),
            z.object({
                ids: z.array(z.string()),
                rfq_context: z.literal('OutsideRfQ'),
            }),
        ]),
        responseBody: z.object({
            items: z.array(OtsOrCustomComponentRuntype),
        }),
    }),

    'PATCH /components/custom/:componentId': endpoint({
        description: 'Updates custom components',
        pathParams: z.object({ componentId: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.array(CustomFullPartRuntype),
        responseBody: CustomComponentFullRuntype,
        invalidates: ['POST /components/bulk', 'POST /components/custom/bulk', 'POST /components/search'],
    }),

    'GET /components/custom/:componentId': endpoint({
        description: 'Gets custom components',
        pathParams: z.object({ componentId: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: CustomComponentFullRuntype,
        invalidates: ['POST /components/bulk', 'POST /components/custom/bulk', 'POST /components/search'],
    }),
};

const partEndpointsGroup6 = {
    'GET /parts/custom/:partId': endpoint({
        description: 'Returns a custom part for a given part ID',
        pathParams: z.object({ partId: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({
            data: CustomFullPartRuntype,
        }),
    }),

    'GET /parts/custom/:partId/upload': endpoint({
        description: 'Returns URL for uploading file to Azure',
        pathParams: z.object({ partId: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({
            data: FileUrlRuntype,
        }),
    }),

    'POST /parts/custom/bulk': endpoint({
        description: 'Returns all custom parts for the given list of part IDs',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.object({ ids: z.array(z.string()) }),
        responseBody: z.object({
            items: z.array(CustomFullPartRuntype),
        }),
    }),
};

const partEndpointsGroup7 = {
    'GET /parts/custom-types': endpoint({
        description: 'Returns all existing types for custom parts',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({
            items: z.array(CustomPartTypeEnumRuntype),
        }),
    }),

    'GET /parts/custom/:partId/resources': endpoint({
        description: 'Returns a string of resources for a given part',
        pathParams: z.object({ partId: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({
            items: z.array(z.string()),
        }),
    }),

    'GET /parts/library/search/progress/:taskId': endpoint({
        description: 'Returns the number of in-progress offers for the given taskID',
        pathParams: z.object({ taskId: z.string() }),
        queryParams: z.object({
            get_part_offer_status: z.boolean().optional(),
        }),
        requestBody: z.undefined(),
        responseBody: LoadingOffersProgressDTORuntype,
    }),
};

const partEndpointsGroup8 = {
    'POST /parts/library/sourcing': endpoint({
        description: 'Finds stock information for all the given part IDs.',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.union([
            z.object({
                part_ids: z.array(z.string()),
                rfq_context: z.literal('WithinRfQ'),
                rfq_id: z.string(),
            }),
            z.object({
                part_ids: z.array(z.string()),
                rfq_context: z.literal('OutsideRfQ'),
            }),
        ]),
        responseBody: z.object({ items: z.array(BackendPartOfferSummaryDTO) }),
    }),

    'POST /parts/generic': endpoint({
        description: 'Creates a generic part',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.union([
            z.object({
                input: TechnicalParametersRuntype,
                rfq_context: z.literal('WithinRfQ'),
                rfq_id: z.string(),
                recompute_matches: z.optional(z.boolean()),
            }),
            z.object({
                input: TechnicalParametersRuntype,
                rfq_context: z.literal('OutsideRfQ'),
                recompute_matches: z.optional(z.boolean()),
            }),
        ]),
        responseBody: z.object({
            data: GenericFullPartRuntype,
        }),
        invalidates: [
            'POST /parts/availability',
            'POST /parts/generic/bulk',
            'POST /parts/off-the-shelf/search/generic/bulk',
            'POST /parts/off-the-shelf/search/generic/bulk',
        ],
    }),
};

const partEndpointsGroup9 = {
    'POST /parts/generic/bulk': endpoint({
        description: 'Returns all the generic parts for the IDs specified in the request body',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.union([
            z.object({
                ids: z.array(z.string()),
                rfq_context: z.literal('WithinRfQ'),
                rfq_id: z.string(),
            }),
            z.object({
                ids: z.array(z.string()),
                rfq_context: z.literal('OutsideRfQ'),
            }),
        ]),
        responseBody: z.object({
            items: z.array(GenericFullPartRuntype),
        }),
    }),

    'POST /parts/off-the-shelf/search/generic/bulk': endpoint({
        description:
            'Returns all OTS parts that match the generic parts parameters for the given list of generic part IDs',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.union([
            z.object({
                ids: z.array(z.string()),
                rfq_context: z.literal('WithinRfQ'),
                rfq_id: z.string(),
            }),
            z.object({
                ids: z.array(z.string()),
                rfq_context: z.literal('OutsideRfQ'),
            }),
        ]),
        responseBody: z.object({
            data: MpnMatchesRuntype,
        }),
    }),
};

const partEndpointsGroup10 = {
    'GET /inventory/:id': endpoint({
        description: 'Returns the inventory for the part id specified in the path params',
        pathParams: z.object({ id: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({
            data: IpnInventoryRuntype.nullable(),
        }),
        handleResponse: async (response, _) => {
            if (response.ok) {
                return response.json();
            }
            if (response.status === 404) {
                return { data: null };
            }
            throw Error(`Failed to GET inventory by id. Status code: ${response.status}`);
        },
    }),

    'PATCH /ipns/matches': endpoint({
        description: 'Updates the linked parts of the IPN specified in the request body',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: EmsPartNumberPartMatchRequestRuntype,
        responseBody: z.object({
            items: z.array(EmsPartNumberPartMatchResponseRuntype),
        }),
        invalidates: [
            'POST /components/search',
            'POST /components/bulk',
            'POST /ipns/search/linked-parts/bulk',
            'POST /ipns/bulk',
        ],
    }),
};

const partEndpointsGroup11 = {
    'PATCH /components/:ipn/specifications/matches': endpoint({
        description: 'Updates the linking of a part to a raw specification',
        pathParams: z.object({ ipn: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.object({
            raw_specification_id: z.string().nullable(),
            generic_part_ids: z.array(z.string()),
            ots_part_ids: z.array(z.string()),
        }),
        responseBody: z.object({
            items: z.array(IpnFullPartMatchRuntype),
        }),
        invalidates: ['POST /components/bulk', 'POST /components/search', 'GET /negotiation/:id/line-items'],
    }),

    'GET /parts/packages': endpoint({
        description: 'Get all the packages',
        pathParams: z.undefined(),
        queryParams: z.object({
            tags: PackageTagRuntype.optional(),
        }),
        requestBody: z.undefined(),
        responseBody: z.object({
            items: z.array(PackageDTORuntype),
        }),
    }),
};

const partEndpointsGroup12 = {
    'GET /parts/package-families': endpoint({
        description: 'Get all the package families. A package family represents a group of packages.',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({
            items: z.array(PackageFamilyRuntype),
        }),
    }),

    'POST /parts/custom': endpoint({
        description: 'Creates a custom part',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: CustomPartInputRuntype,
        responseBody: z.object({ data: CustomFullPartRuntype }),
    }),

    'PATCH /parts/custom/:partId': endpoint({
        description: 'Updates a custom part',
        pathParams: z.object({ partId: z.string() }),
        queryParams: z.undefined(),
        requestBody: CustomPartUpdateInputRuntype,
        responseBody: z.object({ data: CustomFullPartRuntype }),
        invalidates: ['POST /components/bulk', 'POST /parts/custom/bulk'],
    }),
};

const partEndpointsGroup13 = {
    'DELETE /parts/custom/:partId/files': endpoint({
        description: "Deletes custom part's file from Azure",
        pathParams: z.object({ partId: z.string() }),
        queryParams: z.object({ filename: z.string() }),
        requestBody: z.undefined(),
        responseBody: z.unknown(),
        invalidates: ['POST /parts/custom/bulk'],
        removes: ['POST /parts/custom/bulk'],
    }),

    'POST /parts/off-the-shelf/assemblies/bulk': endpoint({
        description: 'Returns an array of assemblies in which the specified ots parts have been used',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.object({ ids: z.array(z.string()), rfq_context: z.literal('OutsideRfQ') }),
        responseBody: z.object({
            data: UsedInResponseRuntype,
        }),
    }),

    'GET /parts/part-categories': endpoint({
        description: 'Returns all part categories',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({
            items: z.array(PartCategoryDTORuntype),
        }),
    }),
};

const partEndpointsGroup14 = {
    'POST /parts/off-the-shelf/alternatives/bulk': endpoint({
        description: 'Returns all the alternatives for the given list of OTS part IDs',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: RFQContextualizedBulkIdsRuntype,
        responseBody: z.object({
            data: z.record(z.string(), z.array(PartAlternativeRuntype)),
            show_part_alternatives: z.boolean(),
        }),
    }),

    'POST /components/custom/bulk': endpoint({
        description: 'Returns all custom components for the given list of IPNs',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.union([
            z.object({
                ids: z.array(z.string()),
                rfq_context: z.literal('WithinRfQ'),
                rfq_id: z.string(),
            }),
            z.object({
                ids: z.array(z.string()),
                rfq_context: z.literal('OutsideRfQ'),
            }),
        ]),
        responseBody: z.object({
            items: z.array(CustomComponentFullRuntype),
        }),
    }),

    'GET /parts/off-the-shelf/:partId/votes': endpoint({
        description: 'Returns the votes for a specific off-the-shelf part',
        pathParams: z.object({ partId: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({
            items: z.array(OffTheShelfPartVoteRuntype),
        }),
    }),
};

// Define the type for each group
type PartEndpointsGroup1 = typeof partEndpointsGroup1;
type PartEndpointsGroup2 = typeof partEndpointsGroup2;
type PartEndpointsGroup3 = typeof partEndpointsGroup3;
type PartEndpointsGroup4 = typeof partEndpointsGroup4;
type PartEndpointsGroup5 = typeof partEndpointsGroup5;
type PartEndpointsGroup6 = typeof partEndpointsGroup6;
type PartEndpointsGroup7 = typeof partEndpointsGroup7;
type PartEndpointsGroup8 = typeof partEndpointsGroup8;
type PartEndpointsGroup9 = typeof partEndpointsGroup9;
type PartEndpointsGroup10 = typeof partEndpointsGroup10;
type PartEndpointsGroup11 = typeof partEndpointsGroup11;
type PartEndpointsGroup12 = typeof partEndpointsGroup12;
type PartEndpointsGroup13 = typeof partEndpointsGroup13;
type PartEndpointsGroup14 = typeof partEndpointsGroup14;

// Define the combined type
interface PartEndpoints
    extends PartEndpointsGroup1,
        PartEndpointsGroup2,
        PartEndpointsGroup3,
        PartEndpointsGroup4,
        PartEndpointsGroup5,
        PartEndpointsGroup6,
        PartEndpointsGroup7,
        PartEndpointsGroup8,
        PartEndpointsGroup9,
        PartEndpointsGroup10,
        PartEndpointsGroup11,
        PartEndpointsGroup12,
        PartEndpointsGroup13,
        PartEndpointsGroup14 {}

// Merge all endpoint groups with an explicit type annotation
export const partEndpoints: PartEndpoints = {
    ...partEndpointsGroup1,
    ...partEndpointsGroup2,
    ...partEndpointsGroup3,
    ...partEndpointsGroup4,
    ...partEndpointsGroup5,
    ...partEndpointsGroup6,
    ...partEndpointsGroup7,
    ...partEndpointsGroup8,
    ...partEndpointsGroup9,
    ...partEndpointsGroup10,
    ...partEndpointsGroup11,
    ...partEndpointsGroup12,
    ...partEndpointsGroup13,
    ...partEndpointsGroup14,
};
