import { t, Trans } from '@lingui/macro';
import { CenteredLayout, colorSystem, Flexbox, Message, Text } from '@luminovo/design-system';
import { DemandDTO } from '@luminovo/http-client';
import { Box, CircularProgress } from '@mui/material';
import { useAssembly } from '../../../resources/assembly/assemblyHandler';
import { useHttpQuery } from '../../../resources/http/useHttpQuery';
import { DemandSummary } from '../../Demand/DemandSummary';
import { useDemands } from '../../Negotiations/hooks/negotiationHandlers';
import { DemandTable } from '../../PartLibrary/IpnDetailsPage/components/DemandTab/DemandTable';
import { useDemandTabsInner } from '../../PartLibrary/IpnDetailsPage/components/DemandTab/useDemandTab';

const DemandTabInner = ({ demands, assemblyId }: { demands: DemandDTO[]; assemblyId: string }): JSX.Element => {
    const { buttonGroups, setDialog, selectedDemandIds } = useDemandTabsInner({ demands, initialDemandType: 'gross' });
    const isFiltered = selectedDemandIds.length !== demands.length;
    const { data: demandData } = useHttpQuery('POST /demand/export-summary-demands', {
        requestBody: {
            demand_ids: selectedDemandIds,
            summary_for: {
                type: 'Assembly',
                assembly_id: assemblyId,
            },
        },
    });
    return (
        <Flexbox sx={{ background: colorSystem.neutral[1] }} flexDirection={'column'} height="100%">
            <Box alignSelf="end" marginBottom={2}>
                <Flexbox gap={8} flexDirection={'row'}></Flexbox>
            </Box>
            <DemandSummary
                buttonGroups={{
                    ...buttonGroups,
                    demandType: 'gross',
                    handleDemandTypeClick: undefined,
                }}
                demandSummary={demandData?.summary}
                onClickFilterDemandButton={setDialog}
                isFiltered={isFiltered}
            />
            <Box height="16px" />
            <Flexbox
                sx={{
                    backgroundColor: colorSystem.neutral.white,
                    paddingY: '24px',
                    paddingX: '20px',
                    height: '100%',
                }}
                flexDirection={'column'}
                gap={'16px'}
            >
                <Text color={colorSystem.neutral[8]} variant="h3">
                    {buttonGroups.demandType === 'net' && <Trans>Net demand</Trans>}
                    {buttonGroups.demandType === 'gross' && <Trans>Gross demand</Trans>}
                </Text>
                <Box
                    sx={{
                        height: '100%',
                        minHeight: '200px',
                    }}
                >
                    <DemandTable demandData={demandData} demandType={buttonGroups.demandType} />
                </Box>
            </Flexbox>
        </Flexbox>
    );
};

export const DemandTab = ({ assemblyId }: { assemblyId: string }): JSX.Element => {
    const { data: demands = [], isLoading } = useDemands();
    const { data: assembly } = useAssembly(assemblyId, { with_aggregations: true, enabled: true });

    if (isLoading || !assembly) {
        return (
            <CenteredLayout>
                <CircularProgress />
            </CenteredLayout>
        );
    }

    const assemblyParentsWithoutBOMWrapper = Object.entries(assembly.parents)
        .filter(([assemblyId]) => {
            if (!assembly.rfq) return true;
            return assemblyId !== assembly.rfq;
        })
        .reduce(
            (acc, [key, value]) => {
                acc[key] = value;
                return acc;
            },
            {} as { [key: string]: number },
        );
    const isSubAssembly = Object.keys(assemblyParentsWithoutBOMWrapper).length > 0;
    if (isSubAssembly) {
        return (
            <Message
                message={t`We currently do not support demand for sub-assemblies. Please select a different assembly.`}
                variant="blue"
                attention="high"
                size="small"
            />
        );
    }

    return <DemandTabInner demands={demands} assemblyId={assemblyId} />;
};
