import { t, Trans } from '@lingui/macro';
import { assertUnreachable, formatDecimal, formatMonetaryValue, isEqual, isPresent } from '@luminovo/commons';
import { Chip, colorSystem, Flexbox, FontVariant, TertiaryButton, Text, Tooltip } from '@luminovo/design-system';
import { EvaluatedTcoCostDTO, MonetaryValueBackend, SolutionTag } from '@luminovo/http-client';
import { ExpandMore, InfoRounded } from '@mui/icons-material';
import { Collapse, Divider } from '@mui/material';
import React from 'react';
import { formatQuantity } from '../../../../formatters';
import { Solution } from '../../../../types';

function extractExchangeRate(solution: Solution) {
    const convertedTag = solution.solutionTags.find((sol) => sol.tag === SolutionTag.Converted);
    if (convertedTag?.tag !== SolutionTag.Converted) {
        return undefined;
    }

    return {
        originalCurrency: convertedTag.content.original_currency,
        preferredCurrency: convertedTag.content.preferred_currency,
        exchangeRate: 1 / Number(convertedTag.content.exchange_rate),
    };
}

const CostItem: React.FunctionComponent<{
    label: string;
    value: string;
    valuePrefix?: 'x' | '+' | '-' | '(' | '=';
    valueSuffix?: ')';
    labelChip?: string;
    tooltip?: string;
    variant?: 'default' | 'small';
}> = ({ label, value, valuePrefix, valueSuffix, labelChip, tooltip, variant = 'default' }): JSX.Element => {
    const textVariant: FontVariant = variant === 'default' ? 'body' : 'body-small';

    return (
        <Flexbox justifyContent={'space-between'} paddingLeft={variant === 'default' ? undefined : 2}>
            <Flexbox gap={4} alignItems="center">
                <Text variant={textVariant} color={colorSystem.neutral[7]}>
                    {label}
                </Text>
                {labelChip && <Chip color={'neutral'} label={labelChip} />}
                {isPresent(tooltip) ? (
                    <Tooltip title={tooltip}>
                        <InfoRounded
                            style={{
                                cursor: 'pointer',
                                color: colorSystem.neutral[5],
                                fontSize: '16px',
                            }}
                        />
                    </Tooltip>
                ) : null}
            </Flexbox>

            <Flexbox gap={4}>
                <Text variant={textVariant} color={colorSystem.neutral[6]}>
                    {valuePrefix}
                </Text>
                <Text variant={textVariant} color={colorSystem.neutral[9]} tabularNums>
                    {value}
                </Text>
                <Text variant={textVariant} color={colorSystem.neutral[6]}>
                    {valueSuffix}
                </Text>
            </Flexbox>
        </Flexbox>
    );
};

const extractTcoCost = (tcoCost: EvaluatedTcoCostDTO | undefined | null): MonetaryValueBackend | undefined => {
    if (!isPresent(tcoCost)) {
        return undefined;
    }

    const { type, data } = tcoCost;
    switch (type) {
        case 'Automatic':
            return data.cost.preferred;
        case 'Manual':
            return data.manual.preferred;
        default:
            assertUnreachable(type);
    }
};

const UnitPrices = ({
    solution,
    isPcbCustomPartOffer,
}: {
    solution: Solution;
    isPcbCustomPartOffer?: boolean;
}): JSX.Element => {
    const offerUnit = solution.unit;
    const solutionUnit = { quantity: 1, unit: solution.quantities.aggregated.unit };

    const offerUnitPrice = solution.offerUnitPrice;
    const originalUnitPrice = solution.cost.unit_price?.original;
    const preferredUnitPrice = solution.cost.unit_price?.preferred;
    const exchangeRate = extractExchangeRate(solution)?.exchangeRate;

    const isSameCurrency = originalUnitPrice?.currency === preferredUnitPrice?.currency;
    const isSameUnit = isEqual(offerUnit, solutionUnit);

    let offerUnitPriceItem = null;
    if (!isSameUnit) {
        const offerUnitPriceLabel = isPcbCustomPartOffer ? t`Offer panel price` : t`Offer unit price`;
        offerUnitPriceItem = (
            <CostItem
                label={offerUnitPriceLabel}
                labelChip={formatQuantity(offerUnit, { isPcb: isPcbCustomPartOffer })}
                value={formatMonetaryValue(offerUnitPrice?.original, 'unit-price')}
            />
        );
    }

    let originalUnitPriceItem = null;
    if (!isSameCurrency) {
        const originalUnitPriceLabel = isPcbCustomPartOffer ? t`Panel price` : t`Unit price`;
        originalUnitPriceItem = (
            <>
                <CostItem
                    label={originalUnitPriceLabel}
                    labelChip={offerUnitPrice?.original.currency}
                    value={formatMonetaryValue(originalUnitPrice, 'unit-price')}
                />
                {exchangeRate && (
                    <CostItem
                        label={t`Exchange rate`}
                        labelChip={`${originalUnitPrice?.currency} > ${preferredUnitPrice?.currency}`}
                        valuePrefix="x"
                        value={formatDecimal(exchangeRate, { maximumFractionDigits: 4 })}
                        variant="small"
                    />
                )}
            </>
        );
    }

    const preferredLabelChip = isSameUnit ? undefined : formatQuantity(solutionUnit, { isPcb: isPcbCustomPartOffer });
    const preferredUnitPriceItem = (
        <CostItem
            label={t`Unit price`}
            labelChip={preferredLabelChip}
            value={formatMonetaryValue(preferredUnitPrice, 'unit-price')}
        />
    );

    return (
        <>
            {offerUnitPriceItem}
            {originalUnitPriceItem}
            {(isPresent(offerUnitPriceItem) || isPresent(originalUnitPriceItem)) && <Divider />}
            {preferredUnitPriceItem}
        </>
    );
};

const TcoCostItems = ({ solution }: { solution: Solution }): JSX.Element | null => {
    const tcoBreakdown = solution.cost.tco_costs?.breakdown;
    const packagingCost = extractTcoCost(tcoBreakdown?.packaging_cost);
    const discount = extractTcoCost(tcoBreakdown?.discount);
    const shippingCost = extractTcoCost(tcoBreakdown?.shipping_cost);
    const customsCost = extractTcoCost(tcoBreakdown?.customs_cost);
    const otherCost = extractTcoCost(tcoBreakdown?.other_cost);

    if (!isPresent(solution.cost.tco_costs)) {
        return null;
    }

    return (
        <>
            <CostItem
                label={t`Unit TCO cost`}
                valuePrefix="+"
                value={formatMonetaryValue(solution.cost.tco_costs?.cost?.preferred, 'unit-price')}
            />

            {packagingCost && (
                <CostItem
                    label={t`Packaging`}
                    valuePrefix="+"
                    value={formatMonetaryValue(packagingCost, 'unit-price')}
                    variant="small"
                />
            )}

            {discount && (
                <CostItem
                    label={t`Discount`}
                    valuePrefix="-"
                    value={formatMonetaryValue(discount, 'unit-price')}
                    variant="small"
                />
            )}

            {shippingCost && (
                <CostItem
                    label={t`Shipping`}
                    valuePrefix="+"
                    value={formatMonetaryValue(shippingCost, 'unit-price')}
                    variant="small"
                />
            )}

            {customsCost && (
                <CostItem
                    label={t`Customs`}
                    valuePrefix="+"
                    value={formatMonetaryValue(customsCost, 'unit-price')}
                    variant="small"
                />
            )}

            {otherCost && (
                <CostItem
                    label={t`Other`}
                    valuePrefix="+"
                    value={formatMonetaryValue(otherCost, 'unit-price')}
                    variant="small"
                />
            )}
        </>
    );
};

const QuantityItems = ({ solution }: { solution: Solution }): JSX.Element => {
    const aggregatedQuantity = solution.quantities.aggregated;
    const scrapQuantity = solution.quantities.scrap;
    const excessMaterial = solution.quantities.excess;
    const totalQuantity = solution.quantities.total;

    return (
        <>
            <CostItem label={t`Quantity`} valuePrefix="x" value={formatDecimal(totalQuantity.quantity)} />
            <CostItem label={t`Required`} value={formatDecimal(aggregatedQuantity.quantity)} variant="small" />
            <CostItem label={t`Scrap`} value={formatDecimal(scrapQuantity.quantity)} variant="small" />
            {excessMaterial && (
                <CostItem label={t`Excess`} value={formatDecimal(excessMaterial.quantity)} variant="small" />
            )}
        </>
    );
};

const OneTimeCostItems = ({ solution }: { solution: Solution }): JSX.Element | null => {
    const allOneTimeCosts = solution.cost.one_time_costs?.costs ?? [];

    const totalOneTimeCost = solution.cost.one_time_costs?.total_one_time_cost;
    if (!isPresent(totalOneTimeCost)) {
        return null;
    }

    return (
        <>
            <CostItem
                label={t`One-time costs`}
                valuePrefix="+"
                value={formatMonetaryValue(totalOneTimeCost.preferred, 'unit-price')}
            />
            {allOneTimeCosts.map((oneTimeCost) => {
                const origin = oneTimeCost.origin;
                const labelFallback = origin === 'Offer' ? t`Offer one-time cost` : t`Manual one-time cost`;
                const labelChip = origin === 'Offer' ? t`Offer` : t`Manual`;

                return (
                    <CostItem
                        label={oneTimeCost.description ?? labelFallback}
                        labelChip={labelChip}
                        value={formatMonetaryValue(oneTimeCost.cost.preferred, 'unit-price')}
                        variant="small"
                    />
                );
            })}
        </>
    );
};

export const OfferCostBreakdown: React.FunctionComponent<{
    solution: Solution;
    isAlwaysExpanded?: boolean;
    isTotalCostOfOwnershipEnabled: boolean;
    isPcbCustomPartOffer?: boolean;
}> = ({
    solution,
    isAlwaysExpanded = true,
    isTotalCostOfOwnershipEnabled = false,
    isPcbCustomPartOffer = false,
}): JSX.Element => {
    const totalPrice = solution.cost.total_price?.preferred;
    const isTcoUnitPriceVisible = isPresent(solution.cost.tco_costs);

    return (
        <Flexbox flexDirection={'column'}>
            <Expandable isAlwaysExpanded={isAlwaysExpanded}>
                <Flexbox
                    flexDirection={'column'}
                    justifyContent={'space-between'}
                    padding={'12px 16px 8px'}
                    gap={12}
                    bgcolor={colorSystem.neutral[0]}
                    style={isAlwaysExpanded ? { borderTop: `1px solid ${colorSystem.neutral[2]}` } : {}}
                >
                    <UnitPrices solution={solution} isPcbCustomPartOffer={isPcbCustomPartOffer} />

                    {isTotalCostOfOwnershipEnabled && (
                        <>
                            <TcoCostItems solution={solution} />
                            <Divider />
                            {isTcoUnitPriceVisible && (
                                <CostItem
                                    label={t`TCO unit price`}
                                    valuePrefix="="
                                    value={formatMonetaryValue(solution.cost.tco_unit_price?.preferred, 'unit-price')}
                                />
                            )}
                        </>
                    )}

                    <QuantityItems solution={solution} />

                    <OneTimeCostItems solution={solution} />
                </Flexbox>
            </Expandable>
            <Flexbox
                justifyContent={'space-between'}
                padding={'16px'}
                paddingRight={'20px'}
                bgcolor={colorSystem.primary[1]}
                style={{ borderTop: `1px solid ${colorSystem.primary[2]}` }}
            >
                <Text variant="h4" color={colorSystem.primary[7]}>
                    <Trans>Total price</Trans>
                </Text>
                <Text variant="h3" color={colorSystem.primary[7]} tabularNums>
                    {formatMonetaryValue(totalPrice)}
                </Text>
            </Flexbox>
        </Flexbox>
    );
};

function Expandable({
    children,
    isAlwaysExpanded: alwaysExpanded,
}: {
    children: React.ReactNode;
    isAlwaysExpanded?: boolean;
}) {
    const [isExpanded, setExpanded] = React.useState(alwaysExpanded);

    if (alwaysExpanded) {
        return <>{children}</>;
    }

    return (
        <>
            <TertiaryButton
                onClick={() => setExpanded((x) => !x)}
                style={{
                    background: colorSystem.neutral[0],
                    justifyContent: 'space-between',
                    padding: '8px 16px',
                    paddingRight: 24,
                }}
                endIcon={
                    <ExpandMore
                        style={{
                            transform: isExpanded ? `rotate(-90deg)` : `rotate(0deg)`,
                            transition: 'transform 0.1s linear',
                        }}
                    />
                }
            >
                <Trans>View cost breakdown</Trans>
            </TertiaryButton>

            <Collapse
                style={{ background: colorSystem.neutral[0], paddingRight: 4 }}
                in={isExpanded}
                timeout="auto"
                unmountOnExit
            >
                {children}
            </Collapse>
        </>
    );
}
