import * as z from 'zod';
import { endpoint } from '../http/endpoint';
import {
    SourcingCalculationResponseRuntype,
    SourcingScenarioDTORuntype,
    SourcingScenarioPatchDTORuntype,
    SourcingScenarioPostDTORuntype,
} from './sourcingScenarioBackendTypes';

export const sourcingScenarioEndpoints = {
    'GET /sourcing-scenarios': endpoint({
        description: 'Returns a list of all sourcing scenarios',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({ items: z.array(SourcingScenarioDTORuntype) }),
    }),

    'POST /sourcing-scenarios/bulk': endpoint({
        description: 'Returns a list of sourcing scenarios given their ID',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.object({ ids: z.array(z.string()) }),
        responseBody: z.object({ items: z.array(SourcingScenarioDTORuntype) }),
    }),

    'POST /sourcing/calculation': endpoint({
        description: 'Returns the sourcing calculation',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.union([
            z.object({
                type: z.literal('Rfq'),
                data: z.string(),
            }),
            z.object({
                type: z.literal('SourcingScenarios'),
                data: z.array(z.string()),
            }),
        ]),
        responseBody: SourcingCalculationResponseRuntype,
    }),

    'POST /sourcing-scenarios': endpoint({
        description: 'Creates a new sourcing scenario',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: SourcingScenarioPostDTORuntype,
        responseBody: z.object({ data: SourcingScenarioDTORuntype }),
        invalidates: [
            'GET /rfqs/:rfqId',
            'POST /sourcing-scenarios/bulk',

            'GET /calculations/scenario-combinations',
            'GET /calculations/calculation-assembly-costs',
            'GET /assemblies/:assemblyId/aggregate-quantity',
            'GET /panels',
            'POST /sourcing/calculation',
        ],
        removes: ['POST /sourcing-scenarios/bulk'],
    }),

    'PATCH /sourcing-scenarios/:sourcingScenarioId': endpoint({
        description: 'Updates a sourcing scenario',
        pathParams: z.object({ sourcingScenarioId: z.string() }),
        queryParams: z.undefined(),
        requestBody: SourcingScenarioPatchDTORuntype,
        responseBody: z.object({ data: SourcingScenarioDTORuntype }),
        invalidates: [
            'GET /rfqs/:rfqId',
            'POST /sourcing-scenarios/bulk',

            'GET /calculations/scenario-combinations',
            'GET /calculations/calculation-assembly-costs',
            'GET /assemblies/:assemblyId/aggregate-quantity',
            'GET /panels',
            'POST /sourcing/calculation',
        ],
    }),

    'DELETE /sourcing-scenarios/:sourcingScenarioId': endpoint({
        description: 'Delete a sourcing scenario',
        pathParams: z.object({ sourcingScenarioId: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.string(),
        invalidates: [
            'GET /rfqs/:rfqId',
            'POST /sourcing-scenarios/bulk',

            'GET /calculations/calculation-assembly-costs',
            'GET /assemblies/:assemblyId/aggregate-quantity',
            'GET /panels',
            'POST /sourcing/calculation',
        ],
        removes: [
            'POST /sourcing-scenarios/bulk',

            'GET /calculations/scenario-combinations',
            'POST /sourcing/calculation',
        ],
    }),
};
