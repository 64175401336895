import * as z from 'zod';
import { ApiIntegrationStatusRuntype } from './3rdPartyBackendTypes';

export type BuerklinLanguageDTO = z.infer<typeof BuerklinLanguageDTORuntype>;
export const BuerklinLanguageDTORuntype = z.enum(['en', 'de']);

export interface BuerklinCredentialsDTO extends z.infer<typeof BuerklinCredentialsDTORuntype> {}
export const BuerklinCredentialsDTORuntype = z.object({
    username: z.string(),
    password: z.string(),
    language: BuerklinLanguageDTORuntype,
});

export interface BuerklinCredentialsInputDTO extends z.infer<typeof BuerklinCredentialsInputDTORuntype> {}
export const BuerklinCredentialsInputDTORuntype = z.object({
    username: z.string().optional(),
    password: z.string().optional(),
    language: BuerklinLanguageDTORuntype.optional(),
});

export interface BuerklinResponseBodyDTO extends z.infer<typeof BuerklinResponseBodyDTORuntype> {}
export const BuerklinResponseBodyDTORuntype = z.object({
    data: BuerklinCredentialsDTORuntype,
    status: ApiIntegrationStatusRuntype,
});
