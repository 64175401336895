import { t, Trans } from '@lingui/macro';
import { Dialog, DialogContent, DialogTitle, Flexbox, TertiaryIconButton } from '@luminovo/design-system';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { UseFieldArrayInsert, UseFieldArrayRemove, UseFieldArrayUpdate } from 'react-hook-form';
import { useDialogContext } from '../../../components/contexts/ModalContext';
import { FormContainer } from '../../../components/formLayouts/FormContainer';
import { TextFieldController } from '../../../components/formLayouts/reactHookFormComponents/reactHookFormComponents';
import { SubmitButton } from '../../../components/formLayouts/SubmitButton';
import { ItemType, NestedTableMenuWithItems } from '../../../components/NestedTableMenuWithItems';
import { isPresent } from '../../../utils/isPresent';
import { CalculationTableForm } from './types/formTypes';
import { Row, RowAction, RowActionAdd, RowActionToggleExpansion, RowActionUpdate } from './types/rowTypes';

function validateExistingCostNames(value: unknown, existingCostNames: string[]): string | undefined {
    if (
        value &&
        (typeof value === 'string' || typeof value === 'number') &&
        existingCostNames.filter((costName) => costName === value.toString()).length > 0
    ) {
        return t`A cost with this name already exists`;
    }
    return;
}

function useDialogNameOfRow(
    dialogTitle: string,
    defaultName: string,
    onSubmit: (nameOfRow: string) => void,
    existingNames: string[],
) {
    const { setDialog, closeDialog } = useDialogContext();
    const dialog = (
        <Dialog open={true} maxWidth={'xs'} onClose={() => closeDialog()}>
            <DialogTitle title={dialogTitle} onClose={() => closeDialog()} />
            <DialogContent>
                <FormContainer
                    defaultValues={{ name: defaultName }}
                    onSubmit={(v) => {
                        onSubmit(v.name);
                        closeDialog();
                    }}
                >
                    <Flexbox gap={'12px'} flexDirection={'column'}>
                        <TextFieldController
                            name={'name'}
                            rules={{
                                required: t`Required`,
                                minLength: 1,
                                validate: (value) => validateExistingCostNames(value, existingNames),
                            }}
                        />
                        <SubmitButton />
                    </Flexbox>
                </FormContainer>
            </DialogContent>
        </Dialog>
    );

    return {
        openDialog: () => {
            setDialog(dialog);
        },
    };
}

const isRowActionAdd = (rowAction: RowAction): rowAction is RowActionAdd => rowAction.type === 'add';

const isRowActionUpdate = (rowAction: RowAction): rowAction is RowActionUpdate => rowAction.type === 'update';

const isRowActionToggleExpansion = (action: RowAction): action is RowActionToggleExpansion =>
    action.type === 'toggleExpansion';

const getExistingNamesFromRowActions = (rowActions: RowAction[], type: 'add' | 'update'): string[] => {
    const addAction = rowActions.find((a) => a.type === type);
    if (!addAction || addAction?.type !== type) {
        return [];
    }
    return addAction.existingNames;
};

export const RowActionMenu = ({
    index,
    insert,
    remove,
    update,
    data,
    button,
    actions,
}: {
    index: number;
    insert: UseFieldArrayInsert<CalculationTableForm, 'rows'>;
    remove: UseFieldArrayRemove;
    update: UseFieldArrayUpdate<CalculationTableForm, 'rows'>;
    actions: RowAction[];
    data: Row;
    button?: React.ReactNode;
}): JSX.Element => {
    const { openDialog: openNewRowDialog } = useDialogNameOfRow(
        t`Add new row`,
        '',
        (nameOfRow: string) => {
            const addAction = data.rowActions.find(isRowActionAdd);
            if (!addAction) {
                return;
            }
            insert(index + 1, addAction.insertRow(nameOfRow));
        },
        getExistingNamesFromRowActions(data.rowActions, 'add'),
    );

    const { openDialog: openRenameRowDialog } = useDialogNameOfRow(
        t`Rename row`,
        data.label,
        (nameOfRow: string) => {
            const updateAction = data.rowActions.find(isRowActionUpdate);
            if (!updateAction) {
                return;
            }
            update(index, updateAction.updateRow(nameOfRow, data.label));
        },
        getExistingNamesFromRowActions(data.rowActions, 'update'),
    );

    const expansionAction = actions.find(isRowActionToggleExpansion);

    return (
        <Flexbox gap="4px">
            {isPresent(expansionAction) && (
                <TertiaryIconButton onClick={expansionAction.onToggle}>
                    {expansionAction.isExpanded ? <ExpandLess /> : <ExpandMore />}
                </TertiaryIconButton>
            )}
            {actions.length > 0 && !(actions.length === 1 && isPresent(expansionAction)) && (
                <NestedTableMenuWithItems
                    button={button}
                    items={[
                        actions.find((a) => a.type === 'add') !== undefined
                            ? {
                                  label: <Trans>Add row below</Trans>,
                                  onClick: () => openNewRowDialog(),
                                  type: ItemType.Normal,
                              }
                            : undefined,
                        actions.find((a) => a.type === 'update') !== undefined
                            ? {
                                  label: <Trans>Rename row</Trans>,
                                  onClick: () => openRenameRowDialog(),
                                  type: ItemType.Normal,
                              }
                            : undefined,
                        actions.find((a) => a.type === 'remove') !== undefined
                            ? {
                                  label: <Trans>Delete row</Trans>,
                                  onClick: () => remove(index),
                                  type: ItemType.Delete,
                              }
                            : undefined,
                    ].filter(isPresent)}
                />
            )}
        </Flexbox>
    );
};
