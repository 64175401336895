import * as z from 'zod';
import { AssemblyWideDriverCountPatchRuntype } from '../assemblyWideDriverCount/assemblyWideDriverCountBackendTypes';
import { DriverIdPathRuntype } from '../driver';
import { HttpEndpoint } from '../http/HttpEndpoint';
import { endpoint } from '../http/endpoint';
import { AssemblyWideManuallyOverwrittenDriverCountRuntype } from './assemblyWideManuallyOverwrittenDriverCountBackendTypes';
import {
    ManufacturingAssemblyDetailsNotesRuntype,
    ManufacturingAssemblyDetailsOverviewRuntype,
    ManufacturingAssemblyDetailsRuntype,
    ManufacturingAssemblyDetailsWithoutNotesAndIdRuntype,
} from './manufacturingAssemblyDetailsBackendTypes';

const invalidates: HttpEndpoint[] = [
    'GET /manufacturing-assembly-details/:manufacturingAssemblyDetailsId/overview',
    'GET /manufacturing-assembly-details/:manufacturingAssemblyDetailsId',
];

export const manufacturingAssemblyDetailsEndpoints = {
    'GET /manufacturing-assembly-details/:duplicateRouteHackAskChrisAssemblyDetailsId': endpoint({
        description: 'Get manufacturing assembly details',
        pathParams: z.object({ duplicateRouteHackAskChrisAssemblyDetailsId: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({
            data: ManufacturingAssemblyDetailsRuntype,
        }),
    }),
    'GET /manufacturing-assembly-details/:manufacturingAssemblyDetailsId': endpoint({
        description: 'Get manufacturing assembly details',
        pathParams: z.object({ manufacturingAssemblyDetailsId: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({
            data: ManufacturingAssemblyDetailsRuntype,
        }),
    }),
    'GET /manufacturing-assembly-details/:manufacturingAssemblyDetailsId/overview': endpoint({
        description: 'Get manufacturing assembly details',
        pathParams: z.object({ manufacturingAssemblyDetailsId: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: ManufacturingAssemblyDetailsOverviewRuntype,
    }),
    'DELETE /manufacturing-assembly-details/:manufacturingAssemblyDetailsId': endpoint({
        description: 'Delete a manufacturing assembly details',
        pathParams: z.object({ manufacturingAssemblyDetailsId: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({ deleted: z.number() }),
        invalidates,
    }),
    'DELETE /manufacturing-assembly-details/:assemblyDetailsId/assembly-wide-driver-count/:driverId': endpoint({
        description: 'Delete manufacturing assembly details count from a basic driver', //TODO: change reference of basic driver to single-count https://www.notion.so/luminovo/Rename-basic-driver-to-single-count-a4786e2993a64d48bbb139874736730b
        pathParams: z.object({ assemblyDetailsId: z.string(), driverId: DriverIdPathRuntype }),
        queryParams: z.object({ apply_to_all_activities_and_expenses: z.boolean().optional() }),
        requestBody: z.undefined(),
        responseBody: z.object({ deleted: z.number() }),
        invalidates,
    }),
    'DELETE /manufacturing-assembly-details/:assemblyDetailsId/assembly-wide-driver-count/:driverId/sourcing-scenarios/:sourcingScenarioId':
        endpoint({
            description: 'Delete manufacturing assembly details count from a sourcing driver',
            pathParams: z.object({
                assemblyDetailsId: z.string(),
                driverId: DriverIdPathRuntype,
                sourcingScenarioId: z.string(),
            }),
            queryParams: z.object({ apply_to_all_activities_and_expenses: z.boolean().optional() }),
            requestBody: z.undefined(),
            responseBody: z.object({ deleted: z.number() }),
            invalidates,
        }),
    'POST /manufacturing-assembly-details': endpoint({
        description: 'Create a manufacturing assembly details',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: ManufacturingAssemblyDetailsWithoutNotesAndIdRuntype,
        responseBody: z.object({
            data: ManufacturingAssemblyDetailsRuntype,
        }),
    }),
    'GET /manufacturing-assembly-details': endpoint({
        description: 'Get manufacturing assembly details of an rfq',
        pathParams: z.undefined(),
        queryParams: z.union([z.object({ rfq_id: z.string() }), z.object({ assembly_id: z.string() })]),
        requestBody: z.undefined(),
        responseBody: z.object({
            data: z.array(ManufacturingAssemblyDetailsRuntype),
        }),
    }),
    'PATCH /manufacturing-assembly-details/:assemblyDetailsId/assembly-wide-driver-count/:driverId': endpoint({
        description: 'edits the driver count of a manufacturing assembly driver',
        pathParams: z.object({ assemblyDetailsId: z.string(), driverId: z.string() }),
        queryParams: z.object({ apply_to_all_activities_and_expenses: z.boolean().optional() }),
        requestBody: AssemblyWideDriverCountPatchRuntype,
        responseBody: z.object({ data: AssemblyWideManuallyOverwrittenDriverCountRuntype }),
        invalidates,
    }),

    'PATCH /manufacturing-assembly-details/:assemblyDetailsId/assembly-wide-driver-count/:driverId/sourcing-scenarios/:sourcingScenarioId':
        endpoint({
            description: 'edits the driver count of a manufacturing assembly driver',
            pathParams: z.object({
                assemblyDetailsId: z.string(),
                driverId: z.string(),
                sourcingScenarioId: z.string(),
            }),
            queryParams: z.object({ apply_to_all_activities_and_expenses: z.boolean().optional() }),
            requestBody: AssemblyWideDriverCountPatchRuntype,
            responseBody: z.object({ data: AssemblyWideManuallyOverwrittenDriverCountRuntype }),
            invalidates,
        }),

    'PATCH /manufacturing-assembly-details/:manufacturingAssemblyDetailsId': endpoint({
        description: 'Updates manufacturing assembly details',
        pathParams: z.object({ manufacturingAssemblyDetailsId: z.string() }),
        queryParams: z.undefined(),
        requestBody: ManufacturingAssemblyDetailsNotesRuntype,
        responseBody: z.object({ data: ManufacturingAssemblyDetailsRuntype }),
        invalidates,
    }),
};
