import { LayoutCard } from '@/components/LayoutCard';
import { PageLayoutCollapsibleSidebar } from '@/components/PageLayoutCollapsibleSidebar';
import { useHttpQuery } from '@/resources/http/useHttpQuery';
import { useHttpMutation } from '@/resources/mutation/useHttpMutation';
import { useSortedSupplierAndStockLocationsByPreference } from '@/resources/supplierAndStockLocation/supplierAndStockLocationHandler';
import { useSupplierContacts } from '@/resources/supplierContact/supplierContactHandler';
import { t, Trans } from '@lingui/macro';
import { useHasPermission } from '@luminovo/auth';
import { compareByString, formatDays, formatDecimal, transEnum } from '@luminovo/commons';
import {
    colorSystem,
    createColumnHelper,
    DestructiveSecondaryButton,
    Flexbox,
    SecondaryButton,
    SupportedFilterFn,
    Table,
    Tag,
    TanStackTable,
    Text,
    Tooltip,
    useConfirmationDialog,
    useTanStackTable,
} from '@luminovo/design-system';
import {
    SupplierAndStockLocationWithPreferenceDTO,
    SupplierContactDTO,
    SupplierLineCardDTO,
    SupplierPartType,
    SupplierPreference,
    SupplierTag,
} from '@luminovo/http-client';
import {
    formatSupplierAndStockLocationDTO,
    hasSupplierTag,
    supplierOriginTranslations,
    supplierPartTypeTranslations,
    SupplierPreferenceChip,
    supplierPreferenceTranslations,
    supplierTypeTranslations,
} from '@luminovo/sourcing-core';
import { Add, Delete, PersonRounded, PrecisionManufacturing } from '@mui/icons-material';
import { Box } from '@mui/material';
import React from 'react';
import { route } from '../../../utils/routes';
import { NavigationSidebarSupplier } from '../NavigationSidebarSupplier';
import { ButtonAddSupplier } from './components/ButtonAddSupplier';
import { ButtonAddSupplierContacts } from './components/ButtonAddSupplierContacts';
import { ButtonEditSupplier } from './components/ButtonEditSupplier';

type SupplierFull = {
    supplierAndStockLocation: SupplierAndStockLocationWithPreferenceDTO;
    supplierContacts: SupplierContactDTO[];
    supplierLineCard?: SupplierLineCardDTO;
};

function ActionButton({ table }: { table: Table<SupplierFull> }) {
    const selectedSuppliers = table.getSelectedRowModel().rows.map((row) => row.original);

    const { mutateAsync, isPending } = useHttpMutation('POST /suppliers-and-stock-locations/bulk-delete', {
        snackbarMessage: t`Supplier deleted`,
        onSuccess: () => {
            table.resetRowSelection();
        },
    });

    const { modal, setOpen } = useConfirmationDialog({
        title: t`Delete suppliers`,
        description: t`By deleting this manually created supplier, you will remove it from your supplier database and loose any information about the line card and contacts. Are you sure you want to proceed?`,
        onConfirmation: () => {
            mutateAsync({ requestBody: { ids: selectedSuppliers.map((d) => d.supplierAndStockLocation.id) } });
        },
    });

    // We use the `edit:organization_settings` permission to check if the user is an admin
    const is_admin = useHasPermission(['edit:organization_settings']);

    return (
        <Flexbox alignItems="center" gap={4}>
            {modal}
            {selectedSuppliers.length > 0 && (
                <DestructiveSecondaryButton
                    startIcon={<Delete />}
                    size="medium"
                    isLoading={isPending}
                    onClick={() => {
                        setOpen(true);
                    }}
                >
                    <Trans>Delete suppliers</Trans>
                </DestructiveSecondaryButton>
            )}
            <ButtonAddSupplier />
            {is_admin && (
                <SecondaryButton startIcon={<Add />} size="medium" href={route('/suppliers/line-card-rules/importer')}>
                    <Trans>Import approved vendor list</Trans>
                </SecondaryButton>
            )}
        </Flexbox>
    );
}

const columnHelper = createColumnHelper<SupplierFull>();

const columns = [
    columnHelper.text((row) => formatSupplierAndStockLocationDTO(row.supplierAndStockLocation), {
        id: 'supplier.name',
        size: 250,
        label: () => t`Supplier name`,
    }),

    columnHelper.text('supplierAndStockLocation.supplier_number', {
        size: 100,
        label: () => t`Supplier number`,
        cell: (item) => item.getValue() ?? '-',
    }),

    columnHelper.enum('supplierAndStockLocation.preference', {
        size: 160,
        label: () => t`Preference`,
        options: [
            SupplierPreference.Preferred,
            SupplierPreference.Approved,
            SupplierPreference.NotApproved,
            SupplierPreference.Excluded,
        ],
        getOptionLabel: (option) => transEnum(option, supplierPreferenceTranslations),
        cell: (item) => <SupplierPreferenceChip status={item.getValue()} />,
        quickFilters: [
            {
                label: () => t`Preferred or approved`,
                value: {
                    filterFn: SupportedFilterFn.equalsAny,
                    value: [SupplierPreference.Preferred, SupplierPreference.Approved],
                },
            },
        ],
    }),
    columnHelper.enum('supplierAndStockLocation.supplier.supplier_part_type', {
        size: 160,
        label: () => t`Part type`,
        options: [SupplierPartType.OffTheShelf, SupplierPartType.Custom, SupplierPartType.Pcb],
        getOptionLabel: (option) => transEnum(option, supplierPartTypeTranslations),
        cell: (item) => (
            <Tag attention="low" color={'neutral'} label={transEnum(item.getValue(), supplierPartTypeTranslations)} />
        ),
    }),
    columnHelper.enum('supplierAndStockLocation.supplier.origin', {
        size: 80,
        label: () => t`Origin`,
        getOptionLabel: (option) => transEnum(option, supplierOriginTranslations),
        cell: (item) => (
            <Tag attention="low" color={'neutral'} label={transEnum(item.getValue(), supplierOriginTranslations)} />
        ),
    }),
    columnHelper.enum((row) => hasSupplierTag(row.supplierAndStockLocation, SupplierTag.QuotePartner), {
        id: 'quotePartner',
        size: 80,
        label: () => t`Quote partner`,
        initialVisibility: false,
        description: () =>
            t`Quote partner are pre-qualified distributors that are not connected via API, but easy to request quotes`,
        options: [true, false],
        getOptionLabel: (option) => (option ? t`Yes` : t`No`),
        cell: (item) => (item.getValue() ? <Tag attention="low" color={'teal'} label={t`Quote partner`} /> : null),
        quickFilters: [
            {
                label: () => t`Quote partner`,
                value: {
                    filterFn: SupportedFilterFn.equalsAny,
                    value: [true],
                },
            },
        ],
    }),
    columnHelper.enum('supplierAndStockLocation.supplier.supplier_type', {
        size: 160,
        label: () => t`Supplier type`,
        getOptionLabel: (option) => transEnum(option, supplierTypeTranslations),
        cell: (item) => (
            <Tag attention="low" color={'neutral'} label={transEnum(item.getValue(), supplierTypeTranslations)} />
        ),
    }),
    columnHelper.text((row) => row.supplierContacts.map((c) => `${c.first_name} ${c.last_name} ${c.email}`).join(' '), {
        id: 'supplierContacts',
        size: 100,
        label: () => t`Contacts`,
        enableGlobalFilter: false,
        enableSorting: false,
        cell: ({ row }) => (
            <Tooltip
                variant={'white'}
                title={
                    <>
                        {row.original.supplierContacts.length === 0 && (
                            <Text variant="body-small">{t`No supplier contacts`}</Text>
                        )}
                        {row.original.supplierContacts.length > 0 && (
                            <Flexbox flexDirection={'column'} gap={4}>
                                <Text variant="body-small">{t`Supplier contacts`}</Text>
                                <ul style={{ margin: 0, paddingLeft: 18, maxHeight: 200, overflowY: 'auto' }}>
                                    {row.original.supplierContacts.map((p) => (
                                        <li key={p.id}>{`${p.first_name} ${p.last_name}`}</li>
                                    ))}
                                </ul>
                            </Flexbox>
                        )}
                    </>
                }
            >
                <span>
                    <Flexbox gap={4} alignItems={'center'}>
                        <PersonRounded fontSize={'small'} style={{ color: colorSystem.neutral[4] }} />
                        {formatDecimal(row.original.supplierContacts.length)}
                    </Flexbox>
                </span>
            </Tooltip>
        ),
    }),
    columnHelper.number((row) => row.supplierLineCard?.manufacturers.length ?? 0, {
        id: 'manufacturerCount',
        size: 80,
        label: () => t`Manufacturers`,
        cell: (item) => (
            <Flexbox gap={4} alignItems={'center'}>
                <PrecisionManufacturing fontSize={'small'} style={{ color: colorSystem.neutral[4] }} />
                {formatDecimal(item.getValue())}
            </Flexbox>
        ),
    }),
    columnHelper.action({
        id: 'actionMenu',
        size: 80,
        cell: ({ row }) => (
            <Flexbox gap={4}>
                <ButtonEditSupplier supplier={row.original.supplierAndStockLocation} />
                <ButtonAddSupplierContacts supplierId={row.original.supplierAndStockLocation.supplier.id} />
            </Flexbox>
        ),
    }),
    columnHelper.number('supplierAndStockLocation.shipping_time_in_days', {
        id: 'shippingTime',
        size: 80,
        label: () => t`Shipping time`,
        cell: (item) => {
            const value = item.getValue();
            return value ? formatDays(value) : '-';
        },
        initialVisibility: false,
    }),
];

function useSupplierManagementTableData(): { data: SupplierFull[] | undefined } {
    const { data: supplierAndStockLocations } = useSortedSupplierAndStockLocationsByPreference();
    const { data: supplierContacts = [] } = useSupplierContacts();

    const { data: supplierLineCards = [] } = useHttpQuery('GET /suppliers/line-card', {});

    const data = React.useMemo(
        () =>
            supplierAndStockLocations
                ?.map((supplierAndStockLocation) => {
                    return {
                        supplierAndStockLocation,
                        supplierContacts: supplierContacts.filter(
                            (c) => c.supplier === supplierAndStockLocation.supplier.id,
                        ),
                        supplierLineCard: supplierLineCards.find(
                            (l) => l.supplier === supplierAndStockLocation.supplier.id,
                        ),
                    };
                })
                .sort((a, b) =>
                    compareByString(
                        formatSupplierAndStockLocationDTO(a.supplierAndStockLocation),
                        formatSupplierAndStockLocationDTO(b.supplierAndStockLocation),
                    ),
                ),
        [supplierAndStockLocations, supplierContacts, supplierLineCards],
    );

    return { data };
}

function SupplierOverviewTable() {
    const { data } = useSupplierManagementTableData();

    const { table } = useTanStackTable({
        data,
        columns,
        enableColumnOrdering: true,
        enableColumnHiding: true,
        enablePersistentColumnFilters: true,
        enablePersistentGlobalFilter: true,
        enableExcelExport: true,
        initialState: {
            columnFilters: [
                {
                    id: 'supplierAndStockLocation.preference',
                    value: {
                        filterFn: SupportedFilterFn.equalsAny,
                        value: [SupplierPreference.Preferred, SupplierPreference.Approved],
                    },
                },
            ],
        },
        enableSelection: {
            enabled: true,
            getRowId: (row) => row.supplierAndStockLocation.id,
        },
        enableRowSelection: (row) => hasSupplierTag(row.original.supplierAndStockLocation, SupplierTag.ManuallyAdded),
        getRowRoute: (item) =>
            route('/suppliers/:supplierAndStockLocationId', {
                supplierAndStockLocationId: item.original.supplierAndStockLocation.id,
            }),
    });

    return <TanStackTable table={table} size={'medium'} ActionButton={ActionButton} />;
}

export function SupplierManagementPage() {
    return (
        <PageLayoutCollapsibleSidebar
            sidebar={<NavigationSidebarSupplier />}
            layoutVariant="fullWidth"
            style={{ background: colorSystem.neutral[1] }}
        >
            <LayoutCard title={t`Suppliers`} style={{ height: '100%' }}>
                <Box height={'calc(100% - 48px)'}>
                    <SupplierOverviewTable />
                </Box>
            </LayoutCard>
        </PageLayoutCollapsibleSidebar>
    );
}
