import { ExpandMoreRounded } from '@mui/icons-material';
import { Menu, Tooltip } from '@mui/material';
import ButtonGroup from '@mui/material/ButtonGroup';
import MenuItem from '@mui/material/MenuItem';
import React from 'react';
import { colorSystem } from '../../theme';
import { ButtonSize } from './BaseButton';
import { PrimaryButton } from './PrimaryButton';

export type SplitButtonOption = {
    id: string;
    label: string | JSX.Element;
    overrides?: { MenuItem?: React.ComponentType };
    onClick: () => void;
    setOption?: (option: SplitButtonOption) => void;
    hidden?: boolean;
    tooltip?: string;
};
interface SplitButtonProps {
    options: SplitButtonOption[];
    selectedKey?: string;
    overrides?: {
        button?: { Component?: React.ComponentType; size?: ButtonSize };
        menu?: { Component?: React.ComponentType<{ open: boolean }> };
    };
    disabled?: boolean;
    pinnedSelectedOption?: SplitButtonOption;
    dataTrackingId?: string;
    isLoading?: boolean;
}

export const SplitButton = ({
    options,
    overrides,
    disabled,
    selectedKey,
    pinnedSelectedOption,
    dataTrackingId,
    isLoading,
}: SplitButtonProps): JSX.Element => {
    const [isOpen, setIsOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLDivElement>(null);
    const visibleOptions = options.filter((o) => !o.hidden);
    const selectedOption =
        pinnedSelectedOption ??
        (visibleOptions.find((option) => {
            return option.id === selectedKey;
        }) ||
            visibleOptions[0]);

    const handleToggle = () => {
        setIsOpen((prevOpen) => !prevOpen);
    };

    const CustomButton = overrides?.button?.Component ?? PrimaryButton;
    const size = overrides?.button?.size ?? 'large';

    const CustomMenu = overrides?.menu?.Component ?? Menu;

    return (
        <>
            <ButtonGroup variant="contained" ref={anchorRef} color={'inherit'} style={{ boxShadow: 'none' }}>
                <Tooltip title={selectedOption.tooltip} placement="top">
                    <CustomButton
                        id={selectedOption.id}
                        size={size}
                        onClick={selectedOption.onClick}
                        disableElevation
                        disabled={disabled}
                        isLoading={isLoading}
                        style={{
                            whiteSpace: 'nowrap',
                            borderRight: `1px solid ${colorSystem.neutral[3]}`,
                        }}
                        data-trackingid={dataTrackingId}
                    >
                        {selectedOption.label}
                    </CustomButton>
                </Tooltip>
                {visibleOptions.length > 1 && (
                    <CustomButton
                        aria-controls={isOpen ? 'split-button-menu' : undefined}
                        aria-expanded={isOpen ? 'true' : undefined}
                        aria-haspopup="menu"
                        onClick={handleToggle}
                        size={size}
                        style={{ minWidth: '40px', margin: 0, padding: 0, borderLeft: '0px' }}
                        disableElevation
                        disabled={disabled}
                    >
                        <ExpandMoreRounded />
                    </CustomButton>
                )}
            </ButtonGroup>

            <CustomMenu open={isOpen} anchorEl={anchorRef.current} role={undefined} onClose={handleToggle}>
                {visibleOptions.map((option) => {
                    const Item = option.overrides?.MenuItem ?? MenuItem;
                    return (
                        <Item
                            id={option.id}
                            key={option.id}
                            selected={selectedOption.id === option.id}
                            onClick={() => {
                                option.setOption ? option.setOption(option) : option.onClick();
                                setIsOpen(false);
                            }}
                        >
                            {option.label}
                        </Item>
                    );
                })}
            </CustomMenu>
        </>
    );
};
