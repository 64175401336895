/* eslint-disable spellcheck/spell-checker */
import { typeSafeObjectKeys } from '@luminovo/commons';
import {
    PCBV2,
    PCBV2BoardProperties,
    PCBV2File,
    PCBV2LayerStackProperties,
    PCBV2MechanicalProperties,
    PCBV2SpecificationCapabilities,
} from '@luminovo/http-client';
import { PcbAttribute, Region } from '@luminovo/pdf-extractor';
import React from 'react';
import { useHttpQuery } from '../../http/useHttpQuery';

type Sections = keyof PCBV2SpecificationCapabilities;

type CapabilityValue<
    TSection extends Sections,
    TCapability extends keyof PCBV2SpecificationCapabilities[TSection],
> = PCBV2SpecificationCapabilities[TSection][TCapability] extends { default: infer T }
    ? PCBV2SpecificationCapabilities[TSection][TCapability] extends { defaultUnit: infer U }
        ? { value: T; unit: U }
        : T
    : never;

type PCBV2Properties = PCBV2BoardProperties | PCBV2LayerStackProperties | PCBV2MechanicalProperties;

export type PCBV2FieldName<
    TSection extends Sections,
    TCapability extends keyof PCBV2SpecificationCapabilities[TSection] & string,
> = TCapability extends keyof PCBV2Properties
    ? PCBV2Properties[TCapability] extends { value: any; unit: any }
        ? `${TSection}.${TCapability}.value`
        : `${TSection}.${TCapability}`
    : `${TSection}.${TCapability}`;

interface AbstractCapability<
    TSection extends Sections,
    TCapability extends keyof PCBV2SpecificationCapabilities[TSection] & string,
> {
    /**
     * The section of the capability on the Pcb specification form.
     */
    section: TSection;
    /**
     * The name of the capability, under the section.
     */
    capabilityName: TCapability;
    /**
     * The full name of the capability, including the section and the capability name.
     * Used as the field name in the PCB form item.
     */
    name: `${TSection}.${TCapability}`;
    /**
     * The name of the capability.
     * If the capability is a unit value, the name will be `board.${capabilityName}.value`.
     */
    fieldName: PCBV2FieldName<TSection, TCapability>;
    /**
     * The runtype validator of the capability.
     * For example, `boardHeight` would have a runtype validator of `UnitWithValueRuntype`
     * which would validate an object with `unit` and `value` fields.
     */
    fieldType: 'checkbox' | 'number' | 'select' | 'color-select' | 'button-select';
    /**
     * The restrictions of the capability. mostly what gets sent from the backend.
     */
    restrictions: PCBV2SpecificationCapabilities[TSection][TCapability];
    /**
     * If the value was extracted from the pdf, this field will be set.
     */
    extractedFromPdf?: {
        /**
         * The actual extracted value.
         */
        value: CapabilityValue<TSection, TCapability>;
        /**
         * A URL pointing to the pdf file from which the value was extracted.
         */
        file: PCBV2File;
        /**
         * The region in the pdf from which the value was extracted.
         */
        regions: Region<PcbAttribute>[];
    };
}

type BoardCapability = AbstractCapability<'board', keyof PCBV2SpecificationCapabilities['board']>;
type LayerStackCapability = AbstractCapability<'layerStack', keyof PCBV2SpecificationCapabilities['layerStack']>;
type MechanicalCapability = AbstractCapability<'mechanical', keyof PCBV2SpecificationCapabilities['mechanical']>;
type ReportsCapability = AbstractCapability<'reports', keyof PCBV2SpecificationCapabilities['reports']>;
type MarkingsCapability = AbstractCapability<'markings', keyof PCBV2SpecificationCapabilities['markings']>;
type MiscellaneousCapability = AbstractCapability<
    'miscellaneous',
    keyof PCBV2SpecificationCapabilities['miscellaneous']
>;

export type Capability =
    | BoardCapability
    | LayerStackCapability
    | MechanicalCapability
    | ReportsCapability
    | MarkingsCapability
    | MiscellaneousCapability;

export type CapabilityWithExtractedValue = Omit<Capability, 'extractedFromPdf'> & {
    extractedFromPdf: NonNullable<Capability['extractedFromPdf']>;
};

// Define field type mapping
type FieldType = 'checkbox' | 'number' | 'select' | 'color-select' | 'button-select';

// Capability type mappings
const CapabilityTypeMapping: Record<string, FieldType> = {
    // Board properties
    boardHeight: 'number',
    boardWidth: 'number',
    silkscreenColor: 'color-select',
    silkscreenSide: 'select',
    surfaceFinish: 'select',
    enigThickness: 'number',
    applicationType: 'select',
    hardGold: 'checkbox',
    hardGoldArea: 'number',
    hardGoldThickness: 'number',
    exposedCopperArea: 'number',
    exposedCopperAreaTop: 'number',
    exposedCopperAreaBottom: 'number',
    traceWidth: 'number',
    copperClearance: 'number',
    soldermaskColor: 'color-select',
    soldermaskSide: 'select',
    soldermaskDam: 'number',
    soldermaskClearance: 'number',
    ipc600Class: 'select',
    eTest: 'checkbox',
    pressFit: 'checkbox',
    pressFitTechnology: 'select',
    impedanceTested: 'checkbox',
    impedanceTolerance: 'number',
    peelableMask: 'select',
    captonTape: 'select',
    reports: 'select',
    itar: 'checkbox',
    carbonPrint: 'checkbox',
    edgeMetalization: 'checkbox',
    flammabilityRating: 'select',
    ctiClass: 'select',
    maxXOutsAllowed: 'number',
    halogenFree: 'checkbox',
    placementSide: 'select',
    ecobond: 'checkbox',
    halfCutPlatedVias: 'checkbox',
    carbonPrintSide: 'select',
    numberOfLines: 'number',
    // Layer stack properties
    layerstackType: 'select',
    layerstackThicknessTolerance: 'number',
    ulLayerStack: 'checkbox',
    ulMarkingType: 'select',
    layercount: 'number',
    finalThickness: 'number',
    baseMaterial: 'select',
    outerCopperThickness: 'number',
    innerCopperThickness: 'number',
    minOuterLayerStructure: 'number',
    minInnerLayerStructure: 'number',
    rohsCompilant: 'checkbox',
    tgValue: 'number',
    numberOfPrepregs: 'number',
    numberOfLaminationCycles: 'number',
    // Mechanical properties
    minViaDiameter: 'number',
    viaFilling: 'select',
    blindVias: 'checkbox',
    buriedVias: 'checkbox',
    chamfering: 'select',
    aspectRatio: 'number',
    totalDrillCount: 'number',
    microVias: 'checkbox',
    phCount: 'number',
    phToolCount: 'number',
    nphCount: 'number',
    phMinSize: 'number',
    nphMaxSize: 'number',
    phMaxSize: 'number',
    nphToolCount: 'number',
    phAnnularRing: 'number',
    nphMinSize: 'number',
    phSurfaceArea: 'number',
    // Reports properties
    crossSection: 'checkbox',
    xRayMeasurement: 'checkbox',
    xRayMeasurementPoints: 'number',
    firstArticleInspection: 'checkbox',
    certificateOfConformance: 'checkbox',
    // Markings properties
    dateCode: 'select',
    fabricatorLogo: 'checkbox',
    datamatrixCode: 'checkbox',
    // Miscellaneous properties
    manufacturingInformation: 'select',
};

function flattenCapabilities(pcbCapabilities: PCBV2SpecificationCapabilities | undefined): Capability[] {
    if (!pcbCapabilities) {
        return [];
    }
    const { board, layerStack, mechanical, reports, markings, miscellaneous } = pcbCapabilities;

    const boardCapabilities: BoardCapability[] = typeSafeObjectKeys(board).map((capabilityName) => {
        return {
            capabilityName,
            name: `board.${capabilityName}`,
            section: 'board',
            restrictions: board[capabilityName],
            fieldType: CapabilityTypeMapping[capabilityName],
            fieldName: ('defaultUnit' in board[capabilityName]
                ? `board.${capabilityName}.value`
                : `board.${capabilityName}`) as PCBV2FieldName<'board', typeof capabilityName>,
        } as const;
    });

    const layerStackCapabilities: LayerStackCapability[] = typeSafeObjectKeys(layerStack).map((capabilityName) => {
        return {
            capabilityName,
            name: `layerStack.${capabilityName}`,
            section: 'layerStack',
            restrictions: layerStack[capabilityName],
            fieldType: CapabilityTypeMapping[capabilityName],
            fieldName: ('defaultUnit' in layerStack[capabilityName]
                ? `layerStack.${capabilityName}.value`
                : `layerStack.${capabilityName}`) as PCBV2FieldName<'layerStack', typeof capabilityName>,
        } as const;
    });

    const mechanicalCapabilities: MechanicalCapability[] = typeSafeObjectKeys(mechanical).map((capabilityName) => {
        return {
            capabilityName,
            name: `mechanical.${capabilityName}`,
            section: 'mechanical',
            restrictions: mechanical[capabilityName],
            fieldType: CapabilityTypeMapping[capabilityName],
            fieldName: ('defaultUnit' in mechanical[capabilityName]
                ? `mechanical.${capabilityName}.value`
                : `mechanical.${capabilityName}`) as PCBV2FieldName<'mechanical', typeof capabilityName>,
        } as const;
    });

    const reportsCapabilities: ReportsCapability[] = typeSafeObjectKeys(reports).map((capabilityName) => {
        return {
            capabilityName,
            name: `reports.${capabilityName}`,
            section: 'reports',
            restrictions: reports[capabilityName],
            fieldType: CapabilityTypeMapping[capabilityName],
            fieldName: ('defaultUnit' in reports[capabilityName]
                ? `reports.${capabilityName}.value`
                : `reports.${capabilityName}`) as PCBV2FieldName<'reports', typeof capabilityName>,
        } as const;
    });

    const markingCapabilities: MarkingsCapability[] = typeSafeObjectKeys(markings).map((capabilityName) => {
        return {
            capabilityName,
            name: `markings.${capabilityName}`,
            section: 'markings',
            restrictions: markings[capabilityName],
            fieldType: CapabilityTypeMapping[capabilityName],
            fieldName: ('defaultUnit' in markings[capabilityName]
                ? `markings.${capabilityName}.value`
                : `markings.${capabilityName}`) as PCBV2FieldName<'markings', typeof capabilityName>,
        } as const;
    });

    const miscellaneousCapabilities: MiscellaneousCapability[] = typeSafeObjectKeys(miscellaneous).map(
        (capabilityName) => {
            return {
                capabilityName,
                name: `miscellaneous.${capabilityName}`,
                section: 'miscellaneous',
                restrictions: miscellaneous[capabilityName],
                fieldType: CapabilityTypeMapping[capabilityName],
                fieldName: ('defaultUnit' in miscellaneous[capabilityName]
                    ? `miscellaneous.${capabilityName}.value`
                    : `miscellaneous.${capabilityName}`) as PCBV2FieldName<'miscellaneous', typeof capabilityName>,
            } as const;
        },
    );
    const capabilities = [
        ...boardCapabilities,
        ...layerStackCapabilities,
        ...mechanicalCapabilities,
        ...reportsCapabilities,
        ...markingCapabilities,
        ...miscellaneousCapabilities,
    ];

    return sortedFields.flatMap((field) => {
        const capability = capabilities.find((c) => c.capabilityName === field);
        return capability ? [capability] : [];
    });
}

export const usePcbCapabilities = ({ pcb }: { pcb: PCBV2 }): Capability[] => {
    const { data } = useHttpQuery('GET /ems/pcb/v2/pcbs/:pcbId/capabilities', {
        pathParams: {
            pcbId: pcb.id,
        },
        requestHeaders: {
            //@ts-ignore
            Accept: 'application/json;version=beta',
        },
    });

    return React.useMemo(() => {
        return flattenCapabilities(data);
    }, [data]);
};

export const STACK_UP_VALUE_FIELDS: Capability['capabilityName'][] = [
    'outerCopperThickness',
    'innerCopperThickness',
    'baseMaterial',
    'tgValue',
    'finalThickness',
];

export const STACK_UP_ADVANCED_VALUE_FIELDS: Capability['capabilityName'][] = [
    'numberOfPrepregs',
    'numberOfLaminationCycles',
    'ulLayerStack',
    'ulMarkingType',
    'ctiClass',
    'viaFilling',
];

// This logic should move to backend.
export const REQUIRED_PCB_FIELDS: Capability['capabilityName'][] = [
    'layercount',
    'minViaDiameter',
    'boardHeight',
    'boardWidth',
    'outerCopperThickness',
    'innerCopperThickness',
    'minOuterLayerStructure',
    'minInnerLayerStructure',
    'soldermaskSide',
    'soldermaskColor',
    'silkscreenSide',
    'silkscreenColor',
    'surfaceFinish',
    'ipc600Class',
    'baseMaterial',
    'finalThickness',
    'placementSide',
];

// PCB Specification form section and value fields
export const PCB_TYPE_VALUE_FIELDS: Capability['capabilityName'][] = ['layerstackType'];

export const PLACEMENT_SELECTION_FIELDS: Capability['capabilityName'][] = ['placementSide'];

export const CHARACTERISTICS_VALUE_FIELDS: Capability['capabilityName'][] = [
    'layercount',
    'minViaDiameter',
    'boardHeight',
    'boardWidth',
    'minOuterLayerStructure',
    'minInnerLayerStructure',
    'blindVias',
    'buriedVias',
];

export const APPEARANCE_FIELDS: Capability['capabilityName'][] = [
    'soldermaskSide',
    'soldermaskColor',
    'silkscreenSide',
    'silkscreenColor',
    'surfaceFinish',
    'enigThickness',
    'ipc600Class',
    'eTest',
];

export const ADVANCED_FIELDS: Capability['capabilityName'][] = [
    'ecobond',
    'numberOfLines',
    'edgeMetalization',
    'halfCutPlatedVias',
    'halogenFree',
    'hardGold',
    'hardGoldArea',
    'hardGoldThickness',
    'impedanceTested',
    'impedanceTolerance',
    'pressFit',
    'pressFitTechnology',
    'captonTape',
    'carbonPrintSide',
    'chamfering',
    'peelableMask',
];

export const REPORTS_FIELDS: Capability['capabilityName'][] = [
    'certificateOfConformance',
    'crossSection',
    'firstArticleInspection',
    'xRayMeasurement',
    'xRayMeasurementPoints',
];

// Export the capability type mapping for use elsewhere
export const PCB_CAPABILITY_TYPE_MAPPING = CapabilityTypeMapping;
export const MARKINGS_FIELDS: Capability['capabilityName'][] = ['dateCode', 'datamatrixCode', 'fabricatorLogo'];

const sortedFields: Capability['capabilityName'][] = [
    ...PCB_TYPE_VALUE_FIELDS,
    ...PLACEMENT_SELECTION_FIELDS,
    ...CHARACTERISTICS_VALUE_FIELDS,
    ...APPEARANCE_FIELDS,
    ...ADVANCED_FIELDS,
    ...REPORTS_FIELDS,
    ...MARKINGS_FIELDS,
    ...STACK_UP_VALUE_FIELDS,
    ...STACK_UP_ADVANCED_VALUE_FIELDS,
];
