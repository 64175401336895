import { Trans, t } from '@lingui/macro';
import {
    DataTable,
    DataTableRow,
    DataTableRowProps,
    DestructivePrimaryButton,
    Flexbox,
    SecondaryButton,
    TableState,
    colorSystem,
    useDataTableState,
} from '@luminovo/design-system';
import {
    AssemblyIndustry,
    CandidateCpn,
    GenericFullPart,
    PartOptionDTO,
    RfqContext,
    isGenericFullPart,
    isOtsComponentFull,
    isOtsFullPart,
} from '@luminovo/http-client';
import { memo, useCallback, useMemo, useState } from 'react';
import { useFormState } from 'react-hook-form';
import { hasPartsWithCpns } from '../../../../components/partColumns/hasPartsWithCpns';
import { isComponentRemoved } from '../../../../components/partColumns/isComponentRemoved';
import { useGenericPartDetailsDrawer } from '../../../../components/partSpecificationCards/GenericPart/GenericPartCardDetails';
import { useIpnDetailsDrawer } from '../../../../components/partSpecificationCards/Ipn/useIpnDetailsDrawer';
import { useOtsPartDetailsDrawer } from '../../../../components/partSpecificationCards/OTSPart/useOtsPartDetailsDrawer';
import { BomItem } from '../../../../resources/designItem/bomItemFrontendTypes';
import {
    PartOptionWithFullPart,
    getAndSortPartOptionsWithFullParts,
} from '../../../../resources/designItem/sortPartOptionsAndSuggestions';
import { convertToPartOption } from '../../../../resources/part/convertToPartOption';
import { FullPartWithApprovalStatus } from '../../../../resources/part/partFrontendTypes';
import { ViewContext } from '../../../Bom/components/ModuleTableData';
import { TableContainer } from '../AddParts/TableContainer';
import { getPartOptionsColumns } from './columns';
import { PartOptionsContext } from './types';

function updatePartOption(partOptions: PartOptionDTO[], newPart: PartOptionDTO) {
    return partOptions.map((existingPart) => {
        if (existingPart.part.data === newPart.part.data) {
            return newPart;
        }
        return existingPart;
    });
}

const idExtractor = (rowData: PartOptionWithFullPart): string => {
    return rowData.fullPart.id;
};

export const PartOptions = memo(function PartOptions({
    viewContext,
    bomItem,
    isEditable: isRfqEditable,
    partOptions,
    assemblyId,
    fullPartsWithApprovalStatus,
    candidates,
    handleRemovePartOption,
    assemblyIndustry,
    setPartOptions,
    handleRemoveMultiplePartOptions,
}: {
    viewContext: ViewContext;
    bomItem: BomItem;
    assemblyId: string;
    isEditable: boolean;
    partOptions: PartOptionDTO[];
    fullPartsWithApprovalStatus: FullPartWithApprovalStatus[];
    candidates: { candidateMpns: string[]; candidateCpns: CandidateCpn[] };
    handleRemovePartOption: (partId: string) => void;
    assemblyIndustry?: AssemblyIndustry;
    setPartOptions: (fn: (existingPats: PartOptionDTO[]) => PartOptionDTO[]) => void;
    handleRemoveMultiplePartOptions: (partIds: string[]) => void;
}) {
    const [isPartSelectionActive, setPartSelectionActive] = useState(false);
    const { openDrawer: openOtsDrawer } = useOtsPartDetailsDrawer();
    const { openDrawer: openIpnDrawer } = useIpnDetailsDrawer();
    const { openDrawer: openGenericPartDrawer } = useGenericPartDetailsDrawer();
    const { isSubmitting } = useFormState();
    const { candidateMpns, candidateCpns } = candidates;

    const partOptionsWithFullParts = useMemo(() => {
        const fullParts = fullPartsWithApprovalStatus.map((data) => data.part);
        return getAndSortPartOptionsWithFullParts(partOptions, fullParts, candidateMpns);
    }, [candidateMpns, fullPartsWithApprovalStatus, partOptions]);

    const handleUpdatePartOption = useCallback(
        (part: PartOptionDTO) => {
            setPartOptions((parts) => updatePartOption(parts, part));
        },
        [setPartOptions],
    );

    // adds and removes different part options simultaneously
    // used for editing generic parts where we're not updating the part but we create a new part and remove the previous one
    const handleAddAndRemovePartOption = useCallback(
        (partToRemove?: string) => (partToAdd: GenericFullPart) => {
            const partOpt = convertToPartOption(partToAdd);
            if (partOpt) {
                setPartOptions((parts) => {
                    return [...parts.filter((p) => p.part.data !== partToRemove), partOpt];
                });
            }
        },
        [setPartOptions],
    );

    const rfqId = viewContext.rfqId;

    const rfqContext: RfqContext = useMemo(() => {
        if (rfqId) return { type: 'WithinRfQ', rfq_id: rfqId };
        return { type: 'OutsideRfQ' };
    }, [rfqId]);

    const sharedContext: PartOptionsContext = {
        rfqId,
        bomItem,
        assemblyId,
        candidateMpns,
        candidateCpns,
        isRfqEditable,
        rfqContext,
        isSubmitting,
        handleAddAndRemovePartOption: handleAddAndRemovePartOption(),
        handleUpdatePartOption,
        handleRemovePartOption,
        assemblyIndustry,
        openOtsDrawer,
        openGenericPartDrawer,
    };

    const hasPartOptionsWithCpns = hasPartsWithCpns({
        parts: partOptionsWithFullParts.map((partOpt) => partOpt.fullPart),
    });

    const columns = useMemo(
        () =>
            getPartOptionsColumns({
                hasPartOptionsWithCpns,
                isPartSelectionActive,
            }),
        [hasPartOptionsWithCpns, isPartSelectionActive],
    );
    const tableState: TableState<PartOptionWithFullPart, PartOptionsContext> = useDataTableState({
        columns,
        items: partOptionsWithFullParts,
        persistenceId: `part-options`,
        paginationOptions: { showPagination: false },
        sharedContext,
        selectionOptions: { idExtractor },
    });

    const selectedPartOptionIds = tableState.state.selectedIds;

    const handleRowClick = useCallback(
        (part: PartOptionWithFullPart) => {
            const fullPart = part.fullPart;
            if (isOtsFullPart(fullPart)) {
                return openOtsDrawer({ part: fullPart, rfqContext });
            }
            if (isOtsComponentFull(fullPart)) {
                return openIpnDrawer({ ipnId: fullPart.id, rfqContext });
            }
            if (isGenericFullPart(fullPart)) {
                return openGenericPartDrawer({
                    genericPartId: fullPart.id,
                    genericPart: fullPart.content,
                    mpnMatches: fullPart.matches,
                    isEditEnable: isRfqEditable,
                    onAddAlsoRemove: handleAddAndRemovePartOption(fullPart.id),
                    rfqContext,
                });
            }
        },
        [handleAddAndRemovePartOption, isRfqEditable, openGenericPartDrawer, openIpnDrawer, openOtsDrawer, rfqContext],
    );

    const dispatch = tableState.dispatch;
    const handleDeleteMultiplePartOptions = useCallback(() => {
        handleRemoveMultiplePartOptions(selectedPartOptionIds);
        setPartSelectionActive(false);
        dispatch({ type: 'clear-selected-items' });
    }, [handleRemoveMultiplePartOptions, selectedPartOptionIds, setPartSelectionActive, dispatch]);

    if (partOptionsWithFullParts.length === 0) {
        return null;
    }

    return (
        <>
            {isRfqEditable && (
                <Flexbox justifyContent="flex-end" marginBottom="8px" position="absolute" top="-48px" right="20px">
                    {isPartSelectionActive ? (
                        <Flexbox gap="8px">
                            <SecondaryButton
                                size="medium"
                                onClick={() => {
                                    tableState.dispatch({ type: 'clear-selected-items' });
                                    setPartSelectionActive(false);
                                }}
                            >
                                <Trans>Cancel</Trans>
                            </SecondaryButton>
                            <DestructivePrimaryButton
                                size="medium"
                                disabled={selectedPartOptionIds.length === 0}
                                onClick={handleDeleteMultiplePartOptions}
                            >
                                <Trans>Delete</Trans>
                            </DestructivePrimaryButton>
                        </Flexbox>
                    ) : (
                        <SecondaryButton
                            size="medium"
                            onClick={() => {
                                setPartSelectionActive(true);
                            }}
                        >
                            <Trans>Delete parts</Trans>
                        </SecondaryButton>
                    )}
                </Flexbox>
            )}
            <DataTable
                size="medium"
                tableState={tableState}
                stickyHeader={false}
                onItemClick={handleRowClick}
                overrides={{
                    Container: TableContainer,
                    TableRow: StyledTableRow,
                }}
            />
        </>
    );
});

const StyledTableRow = ({ item, ...tableRowProps }: DataTableRowProps<PartOptionWithFullPart, PartOptionsContext>) => {
    const isRemoved = isComponentRemoved(item.fullPart);
    return (
        <DataTableRow
            {...tableRowProps}
            tooltip={isRemoved ? t`Removed IPN` : ''}
            item={item}
            style={{
                ...tableRowProps.style,
                background: isRemoved ? colorSystem.neutral[0] : 'white',
            }}
        />
    );
};
