import { t } from '@lingui/macro';
import { isPresent } from '@luminovo/commons';
import { MaxWidthLayout, colorSystem, useNavigate } from '@luminovo/design-system';
import { AssemblyDTO } from '@luminovo/http-client';
import { Box, CircularProgress } from '@mui/material';
import { PageLayout } from '../../../../components/PageLayout';
import { useAssembly } from '../../../../resources/assembly/assemblyHandler';
import { useHttpQuery } from '../../../../resources/http/useHttpQuery';
import { usePcbOfAssembly } from '../../../../resources/pcb/pcbHandlers';
import { UrlParams } from '../../../../utils/routes';
import { NotFoundCard } from '../../../Error/NotFoundCard';
import { AssemblySourcingHeader } from './components/AssemblySourcingHeader';
import { AssemblySourcingSidebar } from './components/AssemblySourcingSidebar';
import { AssemblySourcingSolutions } from './components/AssemblySourcingSolutions';
export const AssemblySourcingPage = (urlParams: UrlParams<'/rfqs/:rfqId/sourcing/assembly/:assemblyId'>) => {
    const navigate = useNavigate();
    const { assemblyId, rfqId } = urlParams.pathParams;
    const { data: assembly, isLoading: isLoadingAssembly } = useAssembly(assemblyId, {
        enabled: true,
        with_aggregations: true,
    });
    const assemblyData = assembly as AssemblyDTO | undefined;

    const { data: customPartOfferSolutionsData } = useHttpQuery(
        'POST /solutions/custom-part/bulk',
        {
            requestBody: {
                rfq_id: rfqId,
                assembly_id: assemblyData?.id ?? '',
            },
        },
        { enabled: isPresent(assemblyData) },
    );
    const { data: pcb, isLoading: isLoadingPcb } = usePcbOfAssembly({
        assemblyId: assemblyData?.id,
    });

    if (isLoadingAssembly || isLoadingPcb || !isPresent(assemblyData) || !isPresent(customPartOfferSolutionsData)) {
        return (
            <PageLayout layout="centered">
                <CircularProgress />
            </PageLayout>
        );
    }

    if (!isPresent(pcb)) {
        return (
            <PageLayout layout="centered">
                <NotFoundCard
                    title={t`No PCB uploaded`}
                    description={t`There was no PCB uploaded for ${assemblyData.designator}`}
                    cta={t`Back to sourcing`}
                    onClick={() => {
                        navigate(-1);
                    }}
                />
            </PageLayout>
        );
    }

    return (
        <PageLayout
            layout="fragment"
            removeBottomPadding
            style={{ backgroundColor: colorSystem.neutral[1] }}
            header={<AssemblySourcingHeader assembly={assemblyData} rfqId={rfqId} />}
        >
            <MaxWidthLayout padding={'32px'}>
                <Box display="grid" gridTemplateColumns="340px auto" gap={'12px'}>
                    <AssemblySourcingSidebar
                        assembly={assemblyData}
                        pcb={pcb}
                        rfqId={rfqId}
                        customPartOfferSolution={customPartOfferSolutionsData}
                    />
                    <AssemblySourcingSolutions customPartOfferSolution={customPartOfferSolutionsData} />
                </Box>
            </MaxWidthLayout>
        </PageLayout>
    );
};
