import * as z from 'zod';
import { ApiIntegrationStatusRuntype } from './3rdPartyBackendTypes';

export interface WeltronCredentialsDTO extends z.infer<typeof WeltronCredentialsDTORuntype> {}
export const WeltronCredentialsDTORuntype = z.object({
    api_key: z.string(),
});

export interface WeltronCredentialsInputDTO extends z.infer<typeof WeltronCredentialsInputDTORuntype> {}
export const WeltronCredentialsInputDTORuntype = z.object({
    api_key: z.string().optional(),
});

export interface WeltronResponseBodyDTO extends z.infer<typeof WeltronResponseBodyDTORuntype> {}
export const WeltronResponseBodyDTORuntype = z.object({
    data: WeltronCredentialsDTORuntype.nullable(),
    status: ApiIntegrationStatusRuntype,
});
