import { t } from '@lingui/macro';
import { colorSystem, Dialog, DialogContent, DialogTitle } from '@luminovo/design-system';
import { useDialogContext } from '../../../../../components/contexts/ModalContext';
import { CustomPartEditForm } from './CustomPartEditForm';

export function useCustomPartEditDialog() {
    const { setDialog, closeDialog } = useDialogContext();

    return {
        openDialog: ({ customPartId }: { customPartId: string }) =>
            setDialog(
                <Dialog open={true} maxWidth={'md'} fullWidth={true} onClose={() => closeDialog()}>
                    <DialogTitle
                        title={t`Edit custom part`}
                        onClose={() => closeDialog()}
                        style={{ borderBottom: `1px solid ${colorSystem.neutral[1]}` }}
                    />

                    <DialogContent style={{ paddingBottom: '24px' }}>
                        <CustomPartEditForm customPartId={customPartId} onClose={closeDialog} />
                    </DialogContent>
                </Dialog>,
            ),
    };
}
