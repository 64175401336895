import { Trans, t } from '@lingui/macro';
import { assertUnreachable, id } from '@luminovo/commons';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Flexbox,
    PrimaryButton,
    StepPendingIcon,
    Tag,
    TertiaryButton,
    Text,
    colorSystem,
} from '@luminovo/design-system';
import { CustomerPortalRequirement } from '@luminovo/http-client';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import React, { ReactNode } from 'react';
import { route } from '../../../../../utils/routes';
import { FilesSection } from '../../../../Bom/components/FilesSection';
import { UploadButton } from '../UploadButton';

type Status = 'Pending' | 'Done' | 'InProgress';

export type StepType = 'bom' | 'pcb' | 'cad' | 'manufacturing' | 'pnp';

export const isUploadModalEnabled = (step: StepType): boolean => {
    switch (step) {
        case 'bom':
        case 'pcb':
        case 'pnp':
            return false;
        case 'cad':
        case 'manufacturing':
            return true;
        default:
            assertUnreachable(step);
    }
};

const getLink = ({
    step,
    rfqId,
    assemblyId,
    currentParentAssemblyId,
}: {
    step: StepType;
    rfqId: string;
    assemblyId: string;
    currentParentAssemblyId: string;
}): string => {
    switch (step) {
        case 'bom':
            return route(
                '/rfqs/:rfqId/bom/assembly/:assemblyId',
                { rfqId, assemblyId },
                { tab: 'Bom', monitoring: null, currentParentAssemblyId },
            );
        case 'pcb':
            return route(
                '/rfqs/:rfqId/bom/assembly/:assemblyId/pcb',
                { rfqId, assemblyId },
                { currentParentAssemblyId, isReadonly: null, designItemId: null },
            );
        case 'cad':
            return route(
                '/rfqs/:rfqId/bom/assembly/:assemblyId',
                { rfqId, assemblyId },
                { tab: 'Cad', monitoring: null, currentParentAssemblyId },
            );
        case 'manufacturing':
            return route(
                '/rfqs/:rfqId/bom/assembly/:assemblyId',
                { rfqId, assemblyId },
                { tab: 'Manufacturing', monitoring: null, currentParentAssemblyId },
            );
        case 'pnp':
            return route(
                '/rfqs/:rfqId/bom/assembly/:assemblyId/pnp-importer',
                { rfqId, assemblyId },
                { monitoring: null, currentParentAssemblyId },
            );
        default:
            assertUnreachable(step);
    }
};

export const useUploadButtonAndDialog = (
    type: StepType,
    rfqId: string,
    assemblyId: string,
    isRfqEditable: boolean,
): { uploadButton: React.ReactChild; modal: React.ReactChild | undefined } => {
    const [showModal, setShowModal] = React.useState<StepType | undefined>(undefined);

    const isModalEnabled = isUploadModalEnabled(type);
    const link = getLink({ step: type, rfqId, assemblyId, currentParentAssemblyId: rfqId });

    const uploadButton = (
        <UploadButton
            id={id(`oem_dashboard/button_upload_${type}`)}
            isEditable={isRfqEditable}
            onClick={isModalEnabled ? () => setShowModal(type) : undefined}
            link={link}
            text={t`Upload`}
        />
    );

    const modal =
        isModalEnabled && (showModal === 'cad' || showModal === 'manufacturing') ? (
            <Dialog fullWidth={false} open={showModal === type}>
                <DialogTitle onClose={() => setShowModal(undefined)} title={t`Upload files`} />

                <DialogContent>
                    <FilesSection
                        tab={showModal === 'cad' ? 'Cad' : 'Manufacturing'}
                        isEditable={isRfqEditable}
                        assemblyId={assemblyId}
                        rfqId={rfqId}
                    />
                </DialogContent>
                <DialogActions style={{ padding: '12px 24px 24px' }}>
                    <PrimaryButton onClick={() => setShowModal(undefined)}>{t`Continue`}</PrimaryButton>
                </DialogActions>
            </Dialog>
        ) : undefined;

    return { uploadButton, modal };
};

const StatusIcon = ({ status }: { status: Status }): JSX.Element => {
    switch (status) {
        case 'Pending':
            return (
                <Flexbox width="20px">
                    <StepPendingIcon />
                </Flexbox>
            );
        case 'InProgress':
            return <ErrorRoundedIcon color="warning" fontSize="small" />;
        case 'Done':
            return <CheckCircleOutlineRoundedIcon color="success" fontSize="small" />;
        default:
            assertUnreachable(status);
    }
};

export const StepContainer = ({
    status,
    label,
    type,
    isRfqEditable,
    rfqId,
    assemblyId,
    requirement,
    children,
}: {
    status: Status;
    label: string;
    type: StepType;
    isRfqEditable: boolean;
    assemblyId: string;
    rfqId: string;
    requirement: CustomerPortalRequirement;
    children?: ReactNode;
}): JSX.Element => {
    const { uploadButton, modal } = useUploadButtonAndDialog(type, rfqId, assemblyId, isRfqEditable);

    return (
        <Flexbox flexDirection="column" gap={12} style={{ padding: '12px 0 12px 0' }}>
            <Flexbox flexDirection="row" alignItems="center" justifyContent="space-between">
                <Flexbox gap={8} alignItems="center">
                    <StatusIcon status={status} />

                    <Flexbox gap={4} alignItems="baseline">
                        <Text variant="h4" style={{ color: colorSystem.neutral[8] }}>
                            <Trans>Upload {label}</Trans>
                        </Text>

                        <Text variant="body-small" style={{ color: colorSystem.neutral[6] }}>
                            {requirement === 'Required' && t`Required`}
                            {requirement === 'Optional' && t`Optional`}
                        </Text>
                    </Flexbox>

                    {status === 'InProgress' && <Tag attention="low" color="yellow" label={t`Issues`} />}
                    <Flexbox flexGrow={1} />
                </Flexbox>

                {status === 'Pending' && uploadButton}
                {status === 'Done' && (
                    <TertiaryButton
                        href={getLink({ step: type, rfqId, assemblyId, currentParentAssemblyId: rfqId })}
                        size="small"
                    >
                        <Trans>View {label}</Trans>
                    </TertiaryButton>
                )}
            </Flexbox>

            {children && <Flexbox>{children}</Flexbox>}

            {modal}
        </Flexbox>
    );
};
