import { FormattingType } from '@luminovo/commons';
import { Column } from '@luminovo/design-system';
import { assertPresent } from '../../../utils/assertPresent';
import { BatchColumn, generateBatchColumns } from './generateBatchColumns';
import { Row } from './types/rowTypes';

const getOrderSizeBatchSizeCombinations = (calculation: Row, formattingType: FormattingType): BatchColumn[] => {
    return calculation.cells.map((cell, cellIndex) => {
        return {
            orderSize: cell.orderSize,
            batchSize: cell.batchSize,
            leadTime: cell.sourcingTotalAvailability,
            manufacturingLeadTime: cell.manufacturingLeadTime,
            cellIndex,
            formattingType,
        };
    });
};

export const generateCalculationColumns = (calculations: Row[], formattingType: FormattingType): Column<Row>[] => {
    const allCalculations = calculations.map((calculation) =>
        getOrderSizeBatchSizeCombinations(calculation, formattingType),
    );
    //safe because we are asserting that all items in allCalculations are equal. We will find the bug quickly if this is not the case
    return assertPresent(allCalculations[0]).flatMap((batchColumn) =>
        generateBatchColumns({ ...batchColumn, formattingType }),
    );
};
