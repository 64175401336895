import { getLocale } from '../i18n';

const config = {
    month: 'short',
    year: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
} as const;
const enLongDateTimeFormatter = new Intl.DateTimeFormat('en-GB', config);
const deLongDateTimeFormatter = new Intl.DateTimeFormat('de', config);
const esLongDateTimeFormatter = new Intl.DateTimeFormat('es', config);
const frLongDateTimeFormatter = new Intl.DateTimeFormat('fr', config);
const itLongDateTimeFormatter = new Intl.DateTimeFormat('it', config);
function getLongDateTimeFormatter(): Intl.DateTimeFormat {
    switch (getLocale()) {
        case 'en':
            return enLongDateTimeFormatter;
        case 'de':
            return deLongDateTimeFormatter;
        case 'es':
            return esLongDateTimeFormatter;
        case 'fr':
            return frLongDateTimeFormatter;
        case 'it':
            return itLongDateTimeFormatter;
    }
}

/**
 * Examples:
 * - en-GB: 28 May 2021, 08:24
 * - de-DE: 28. Mai 2021, 08:24
 */
export const formatLongDateTime = (date: string): string => {
    return getLongDateTimeFormatter().format(new Date(date));
};
