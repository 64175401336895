import { Currency } from '@luminovo/commons';
import { Availability, DriverStatusWithDriverIdDTO, MonetaryValueBackend } from '@luminovo/http-client';

type CostTypeFixed = {
    type: 'fixed';
    value: MonetaryValueBackend;
};

type CostTypeFraction = {
    type: 'fraction';
    value: string; //decimal number
};

export type CostTypeFormulaFixed = {
    type: 'formula-fixed';
    script: string;
    result: FormulaResult;
    statuses: DriverStatusWithDriverIdDTO[];
    value: string;
    currency: Currency;
    isOverwritten: boolean;
};

export type CostTypeFormulaFraction = {
    type: 'formula-fraction';
    script: string;
    result: FormulaResult;
    statuses: DriverStatusWithDriverIdDTO[];
    value: string;
    isOverwritten: boolean;
};

type FormulaResult = 'Ok' | 'ScriptError' | 'UnknownIdentifier' | 'NullValue';

export type CostTypeFormula = CostTypeFormulaFixed | CostTypeFormulaFraction;

export interface BaseCellProperties {
    batchSize: number;
    orderSize: number;
    sourcingTotalAvailability: Availability | undefined;
    manufacturingLeadTime: number | undefined;
    sourcingCombinationId: string;
    preferredCurrency: Currency;
}

export interface FixedCostCell extends BaseCellProperties {
    type: 'fixed';
    unitCost: MonetaryValueBackend;
    totalCost: MonetaryValueBackend;
}

const printCellErrorMessage = (
    expected: 'cost' | 'fraction' | 'cost or fraction' | 'cost, fraction, or sum' | 'header',
    cell: Cell | undefined,
): string => {
    return `
    expected cell to have type ${expected} but received ${cell?.type}
    cell was ${JSON.stringify(cell, null, 2)}`;
};

export const assertCellFixedCost = (cell: Cell): FixedCostCell => {
    if (cell.type !== 'fixed') {
        throw new Error(printCellErrorMessage('cost, fraction, or sum', cell));
    }
    return cell;
};

export const assertCellFixedPercentageCost = (cell: Cell): FixedPercentageCell => {
    if (cell.type !== 'fixed-percentage') {
        throw new Error(printCellErrorMessage('cost, fraction, or sum', cell));
    }
    return cell;
};

export const assertCellIsDynamicCost = (cell: Cell): DynamicCostCell => {
    if (cell.type !== 'dynamic') {
        throw new Error(printCellErrorMessage('cost, fraction, or sum', cell));
    }
    return cell;
};

export const assertCellBreakdown = (cell: Cell): CellBreakdown => {
    if (cell.type !== 'breakdown') {
        throw new Error(printCellErrorMessage('cost, fraction, or sum', cell));
    }
    return cell;
};

export interface FixedPercentageCell extends BaseCellProperties {
    type: 'fixed-percentage';
    denominatorValue: MonetaryValueBackend; // this is to show a percentage of something in a fixed cell (e.g. for total profit)
    unitCost: MonetaryValueBackend;
    totalCost: MonetaryValueBackend;
}
export type CellRule = (input: string) => { result: 'Ok' } | { result: 'Error'; message: string };

export interface DynamicCostCell extends BaseCellProperties {
    type: 'dynamic';
    costSpecification: CostTypeFixed | CostTypeFormula | CostTypeFraction;
    unitCostValue: MonetaryValueBackend;
    totalCostValue: MonetaryValueBackend;
    costFraction: string; //decimal number;
    isLocked: boolean;
    rule: CellRule;
    shouldHideCell?: boolean;
}

export type CostSpecification = DynamicCostCell['costSpecification'];

export interface CellBlank extends BaseCellProperties {
    type: 'blank';
}
/* CellBuffer is a type used to add padding between cells in a table or grid. 
It provides  empty space between neighboring cells. This can be useful for creating visual 
separation. 
*/
export interface CellBuffer extends BaseCellProperties {
    type: 'buffer';
}

export interface CellBreakdown extends BaseCellProperties {
    type: 'breakdown';
    shouldHideCell?: boolean; // Used for one-time cost value to hide it if there are no costs
    cost:
        | {
              unitCost: MonetaryValueBackend | undefined;
              totalCost: MonetaryValueBackend | undefined;
          }
        | undefined;
}

export type Cell = FixedCostCell | FixedPercentageCell | DynamicCostCell | CellBlank | CellBuffer | CellBreakdown;
