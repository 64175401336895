import * as z from 'zod';

export enum Incoterms {
    EXW = 'EXW',
    FCA = 'FCA',
    CPT = 'CPT',
    CIP = 'CIP',
    DAP = 'DAP',
    DPU = 'DPU',
    DDP = 'DDP',
    FAS = 'FAS',
    FOB = 'FOB',
    CFR = 'CFR',
    CIF = 'CIF',
}

export const IncotermsRuntype = z.nativeEnum(Incoterms);

export interface SupplierTermsDTO extends z.infer<typeof SupplierTermsDTORuntype> {}
export const SupplierTermsDTORuntype = z.object({
    id: z.string(),
    supplier: z.string(),
    site: z.string().nullable(),
    incoterms: z.nativeEnum(Incoterms).nullable(),
    incoterms_named_place: z.string().nullable(),
    net_payment_due_days: z.number().nullable(),
    early_payment_due_days: z.number().nullable(),
    early_payment_discount_percent: z.number().nullable(),
});

export interface SupplierTermsCreationDTO extends z.infer<typeof SupplierTermsCreationDTORuntype> {}
export const SupplierTermsCreationDTORuntype = SupplierTermsDTORuntype.omit({
    id: true,
    supplier: true,
});

export interface SupplierTermsUpdateDTO extends z.infer<typeof SupplierTermsUpdateDTORuntype> {}
export const SupplierTermsUpdateDTORuntype = SupplierTermsDTORuntype.omit({
    id: true,
    supplier: true,
}).partial();
