import { colorSystem, Row } from '@luminovo/design-system';
import { styled, TableCell, TableCellProps } from '@mui/material';
import React from 'react';

const Container = styled(TableCell)({
    whiteSpace: 'nowrap',
    maxWidth: 100,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    position: 'relative',
    padding: 0,
    background: 'inherit',
    '&:hover': {
        overflow: 'visible',
        textOverflow: 'inherit',
        zIndex: 1,
    },
});

export const Cell = <T,>({
    children,
    ...rest
}: React.PropsWithChildren<{ row: Row<T> } & TableCellProps>): JSX.Element => {
    const ref = React.useRef<HTMLDivElement>(null);

    return (
        <Container {...rest}>
            <InnerContainer
                tabIndex={0}
                onClick={() => {
                    ref.current?.focus();
                }}
                ref={ref}
            >
                {children}
            </InnerContainer>
        </Container>
    );
};

const InnerContainer = styled('div')({
    '&:hover': {
        border: `1px solid ${colorSystem.neutral[6]}`,
        minWidth: 'fit-content',
    },
    '&:focus': {
        outlineOffset: -2,
        outline: `2px solid ${colorSystem.primary[6]}`,
    },
    borderRadius: 4,
    background: 'inherit',
    border: '1px solid transparent',
    cursor: 'pointer',
    boxSizing: 'border-box',
    padding: 16,
    overflow: 'inherit',
    textOverflow: 'inherit',
    height: '100%',
});
