import * as z from 'zod';

import { ComplianceStatus, ElasticTotalHitsDTORuntype } from '../backendTypes';
import {
    CustomFullPartRuntype,
    DielectricCeramicEnum,
    DielectricRuntype,
    GenericFullPartRuntype,
    LifecycleEnum,
    OffTheShelfPartWithFullPackageRuntype,
    OtsFullPartRuntype,
    PackageDTORuntype,
    PackageUserInputRuntype,
    PreferenceStatusEnumRuntype,
    StandardPartTypes,
    TechnicalParametersRuntype,
} from '../part/partBackendTypes';

export type PartMatchTypeFullResponse = z.infer<typeof PartMatchTypeFullResponseRuntype>;
export const PartMatchTypeFullResponseRuntype = z.union([
    z.object({
        type: z.literal('OffTheShelf'),
        data: OtsFullPartRuntype,
    }),
    z.object({
        type: z.literal('Generic'),
        data: GenericFullPartRuntype,
    }),
]);

export type EmsPartNumberPartSpecification = z.infer<typeof EmsPartNumberPartSpecificationRuntype>;
const EmsPartNumberPartSpecificationRuntype = z.object({
    id: z.string(),
    mpn: z.string().nullable().optional(),
    manufacturer: z.string().nullable().optional(),
    description: z.string().nullable().optional(),
    package: z.string().nullable().optional(),
    part_type: z.string().nullable().optional(),
});

const MatchingTypeRuntype = z.object({
    type: z.string(),
    id: z.string().optional(),
});

export type MatchingReason = z.infer<typeof MatchingReasonRuntype>;
export const MatchingReasonRuntype = z.object({
    matching_type: z.union([z.literal('Automatic'), z.literal('Manual')]),
    reason: MatchingTypeRuntype.nullable().optional(),
});

export interface EmsPartNumberPartMatchFullPart extends z.infer<typeof EmsPartNumberPartMatchFullPartRuntype> {}
const EmsPartNumberPartMatchFullPartRuntype = z.object({
    ipn: z.string(),
    part: PartMatchTypeFullResponseRuntype,
    matching_reason: MatchingReasonRuntype,
});

export type IpnSuggestableIncompleteGenericFullPart = Extract<
    IpnSuggestablePartFullResponse,
    { type: 'IncompleteGeneric' }
>;
export type IpnSuggestablePartFullResponse = z.infer<typeof IpnSuggestablePartFullResponseRuntype>;
const IpnSuggestablePartFullResponseRuntype = z.union([
    z.object({
        type: z.literal(StandardPartTypes.Generic),
        data: GenericFullPartRuntype,
    }),
    z.object({
        type: z.literal('IncompleteGeneric'),
        data: TechnicalParametersRuntype,
    }),
    z.object({
        type: z.literal(StandardPartTypes.OffTheShelf),
        data: OtsFullPartRuntype,
    }),
]);

const IpnSuggestionReasonRuntype = z.object({
    type: z.union([
        z.literal('Description'),
        z.literal('Mpn'),
        z.literal('DescriptionMpn'),
        z.literal('DescriptionSpn'),
    ]),
    id: z.string(),
});

export interface IpnSuggestionFullPart extends z.infer<typeof IpnSuggestionFullPartRuntype> {}
const IpnSuggestionFullPartRuntype = z.object({
    ipn: z.string(),
    part: IpnSuggestablePartFullResponseRuntype,
    matching_reason: IpnSuggestionReasonRuntype,
});

export interface CustomerPartNumber extends z.infer<typeof CustomerPartNumberRuntype> {}
const CustomerPartNumberRuntype = z.object({
    id: z.string(),
    value: z.string(),
    customer: z.string(),
    revision: z.string().nullable(),
    created_at: z.string(),
    updated_at: z.string(),
});

export interface SupplierPartNumber extends z.infer<typeof SupplierPartNumberRuntype> {}
const SupplierPartNumberRuntype = z.object({
    supplier_part_number: z.string().optional(),
    supplier: z.string().nullable(),
});

const CapacitorFunctionRuntype = z.object({
    type: z.literal('Capacitor'),
    technical_parameters: z.object({
        capacitance: z.string().nullable(), // Strings because these values are decimal
        dielectric: DielectricRuntype.nullable(),
        tolerance: z.string().nullable(),
        voltage_rating: z.string().nullable(),
    }),
});

const ResistorFunctionRuntype = z.object({
    type: z.literal('Resistor'),
    technical_parameters: z.object({
        resistance: z.string().nullable(), // Strings because these values are decimal
        power_rating: z.string().nullable(),
        tolerance: z.string().nullable(),
        temperature_coefficient: z.string().nullable(),
        voltage_rating: z.string().nullable(),
    }),
});

const UnsupportedFunctionRuntype = z.object({
    type: z.literal('Unsupported'),
    technical_parameters: z.object({
        part_type: z.string().nullable(),
    }),
});

export type ComponentFunction = z.infer<typeof FunctionSpecificationRuntype>;
const FunctionSpecificationRuntype = z.union([
    CapacitorFunctionRuntype,
    ResistorFunctionRuntype,
    UnsupportedFunctionRuntype,
]);

export interface ComponentSpecification extends z.infer<typeof ComponentSpecificationRuntype> {}
export const ComponentSpecificationRuntype = z.object({
    form_and_fit: z.string().nullable(),
    function_specification: FunctionSpecificationRuntype.nullable(),
});

export interface ComponentSpecificationWithFullPackage
    extends z.infer<typeof ComponentSpecificationWithFullPackageRuntype> {}
export const ComponentSpecificationWithFullPackageRuntype = z.object({
    form_and_fit: PackageDTORuntype.nullable(),
    function_specification: FunctionSpecificationRuntype.nullable(),
});

export interface SpecificationsConflict extends z.infer<typeof SpecificationsConflictRuntype> {}
export const SpecificationsConflictRuntype = z.object({
    form_and_fit: z.boolean(),
    function_specification_type: z.boolean(),
    function_specification_keys: z.array(z.string()),
});

export enum ComponentTypeEnum {
    Custom = 'Custom',
    OffTheShelf = 'OffTheShelf',
}

export enum ErpDataChangesFilter {
    ReviewRequired = 'ReviewRequired',
    NoReviewRequired = 'NoReviewRequired',
}

export enum SuggestionsFilter {
    Available = 'Available',
    NotAvailable = 'NotAvailable',
}

export enum LinkedPartsFilter {
    LinkedParts = 'LinkedParts',
    NoLinkedParts = 'NoLinkedParts',
    IncompleteLinkedParts = 'IncompleteLinkedParts',
}

export enum IpnLastChanged {
    Today = 'Today',
    Yesterday = 'Yesterday',
    Last7Days = 'Last7Days',
    MoreThan7DaysAgo = 'MoreThan7DaysAgo',
}

const PartMappedToIpnWithFullPackageRuntype = z.object({
    OffTheShelf: OffTheShelfPartWithFullPackageRuntype.optional(),
    Generic: GenericFullPartRuntype.optional(),
});

export const IpnFullPartMatchRuntype = z.object({
    id: z.string(),
    ipn: z.string(),
    part: PartMappedToIpnWithFullPackageRuntype,
    matching_reason: MatchingReasonRuntype,
});

export interface IpnRawSpecification extends z.infer<typeof IpnRawSpecificationRuntype> {}
export const IpnRawSpecificationRuntype = z.object({
    id: z.string(),
    mpn: z.string().nullable(),
    manufacturer: z.string().nullable(),
    description: z.string().nullable(),
    package: z.string().nullable(),
    part_type: z.string().nullable(),
    manufacturer_id: z.string().nullable(),
});

export interface IpnWithFullPartMatches extends z.infer<typeof IpnWithFullPartMatchesRuntype> {}
export const IpnWithFullPartMatchesRuntype = z.object({
    id: z.string(),
    state: z.union([z.literal('Active'), z.literal('Removed')]),
    part_specifications: z.array(IpnRawSpecificationRuntype),
    matches: z.array(IpnFullPartMatchRuntype),
});

export interface OtsComponentFull extends z.infer<typeof OtsComponentFullRuntype> {}
export const OtsComponentFullRuntype = z.object({
    id: z.string(),
    is_preferred: z.boolean(),
    part_specifications: z.array(EmsPartNumberPartSpecificationRuntype),
    matches: z.array(EmsPartNumberPartMatchFullPartRuntype),
    suggestions: z.array(IpnSuggestionFullPartRuntype),
    state: z.union([z.literal('Active'), z.literal('Removed')]),
    updated_at: z.string(),
    last_imported_at: z.string(),
    cpns: z.array(CustomerPartNumberRuntype),
    has_unreviewed_erp_changes: z.boolean(),
    is_restricted_to_customers: z.boolean(),
    reach_compliant: z.nativeEnum(ComplianceStatus),
    rohs_compliant: z.nativeEnum(ComplianceStatus),
    aecq_compliant: z.nativeEnum(ComplianceStatus),
    lifecycle_status: z.nativeEnum(LifecycleEnum),
    lifecycle_yteol: z.number().nullable(),
    manufacturer_preference_status: PreferenceStatusEnumRuntype.nullable(),
    component_specification: ComponentSpecificationWithFullPackageRuntype.optional(),
    component_specifications: z
        .object({
            edited: ComponentSpecificationWithFullPackageRuntype.optional(),
            imported: ComponentSpecificationWithFullPackageRuntype.optional(),
            inferred: ComponentSpecificationWithFullPackageRuntype.optional(),
            conflicts: SpecificationsConflictRuntype.optional(),
        })
        .optional(),
    spns: z.array(SupplierPartNumberRuntype),
    linked_parts_filter: z.nativeEnum(LinkedPartsFilter),
    std_factory_lead_time: z.number().nullable(),
    category: z.string().nullable().optional(),
});

const PartMatchTypeResponseRuntype = z.union([
    z.object({
        type: z.literal(StandardPartTypes.OffTheShelf),
        id: z.string(),
    }),
    z.object({
        type: z.literal(StandardPartTypes.Generic),
        id: z.string(),
    }),
]);

export interface EmsPartNumberPartMatchResponse extends z.infer<typeof EmsPartNumberPartMatchResponseRuntype> {}
export const EmsPartNumberPartMatchResponseRuntype = z.object({
    ipn: z.string(),
    part: PartMatchTypeResponseRuntype,
    matching_reason: MatchingReasonRuntype,
});

export interface EmsPartNumberPartMatchRequest extends z.infer<typeof EmsPartNumberPartMatchRequestRuntype> {}
export const EmsPartNumberPartMatchRequestRuntype = z.object({
    ipn: z.string(),
    // With autosaving we're only sending one part id per request
    // ideally we'd have a raw_specification_id per part_match_id
    raw_specification_id: z.string().optional(),
    part_matches: z.object({
        off_the_shelf: z.array(z.string()),
        generic: z.array(z.string()),
    }),
});

export type CustomComponentFull = z.infer<typeof CustomComponentFullRuntype>;
export const CustomComponentFullRuntype = z.object({
    id: z.string(),
    is_preferred: z.boolean(),
    state: z.union([z.literal('Active'), z.literal('Removed')]),
    updated_at: z.string(),
    description: z.string().nullable(),
    last_imported_at: z.string(),
    cpns: z.array(CustomerPartNumberRuntype),
    is_restricted_to_customers: z.boolean(),
    matches: z.array(CustomFullPartRuntype),
    component_specification: PackageDTORuntype.nullable(),
    component_specifications: z
        .object({
            edited_form_and_fit: PackageDTORuntype.nullable(),
            imported_form_and_fit: PackageDTORuntype.nullable(),
            inferred_form_and_fit: PackageDTORuntype.nullable(),
        })
        .nullable(),
    category: z.string().nullable().optional(),
});

export type OtsOrCustomComponent = z.infer<typeof OtsOrCustomComponentRuntype>;
export const OtsOrCustomComponentRuntype = z.union([
    z.object({
        type: z.literal('OffTheShelf'),
        data: OtsComponentFullRuntype,
    }),
    z.object({
        type: z.literal('Custom'),
        data: CustomComponentFullRuntype,
    }),
]);

const NumericOptionRuntype = z.object({
    count: z.number(),
    value: z.number(),
});

const StringOptionRuntype = z.object({
    count: z.number(),
    value: z.string(),
});

export type ComponentSearchSortRequest = z.infer<typeof ComponentSearchSortRequestRuntype>;
export const ComponentSearchSortRequestRuntype = z.union([
    z.object({
        field: z.literal('std-factory-lead-time'),
        direction: z.union([z.literal('asc'), z.literal('desc')]),
    }),
    z.object({
        field: z.literal('last-changed'),
        direction: z.union([z.literal('asc'), z.literal('desc')]),
    }),
]);

export type ComponentSearchFilterRequest = z.infer<typeof ComponentSearchFilterRequestRuntype>;
export const ComponentSearchFilterRequestRuntype = z.union([
    z.object({
        field: z.literal('capacitance'),
        operator: z.literal('inNumberRange'),
        parameter: z.array(z.union([z.number(), z.null()])),
    }),
    z.object({
        field: z.literal('resistance'),
        operator: z.literal('inNumberRange'),
        parameter: z.array(z.union([z.number(), z.null()])),
    }),
    z.object({
        field: z.literal('power-rating'),
        operator: z.literal('inNumberRange'),
        parameter: z.array(z.union([z.number(), z.null()])),
    }),
    z.object({
        field: z.literal('tolerance'),
        operator: z.literal('inNumberRange'),
        parameter: z.array(z.union([z.number(), z.null()])),
    }),
    z.object({
        field: z.literal('temperature-coefficient'),
        operator: z.literal('inNumberRange'),
        parameter: z.array(z.union([z.number(), z.null()])),
    }),
    z.object({
        field: z.literal('voltage-rating'),
        operator: z.literal('inNumberRange'),
        parameter: z.array(z.union([z.number(), z.null()])),
    }),
    z.object({
        field: z.literal('dielectric-dielectric'),
        operator: z.literal('equalsAny'),
        parameter: z.array(z.nativeEnum(DielectricCeramicEnum)),
    }),
    z.object({
        field: z.literal('manufacturer'),
        operator: z.literal('equalsAny'),
        parameter: z.array(z.string()),
    }),
    z.object({
        field: z.literal('package'),
        operator: z.literal('equalsAny'),
        parameter: z.array(z.string()),
    }),
    z.object({
        field: z.literal('lifecycle'),
        operator: z.literal('equalsAny'),
        parameter: z.array(z.nativeEnum(LifecycleEnum)),
    }),
    z.object({
        field: z.literal('component-type'),
        operator: z.literal('equalsAny'),
        parameter: z.array(z.nativeEnum(ComponentTypeEnum)),
    }),
    z.object({
        field: z.literal('ipn'),
        operator: z.literal('includesString'),
        parameter: z.array(z.string()),
    }),
    z.object({
        field: z.literal('cpn'),
        operator: z.literal('includesString'),
        parameter: z.array(z.string()),
    }),
    z.object({
        field: z.literal('mpn'),
        operator: z.literal('includesString'),
        parameter: z.array(z.string()),
    }),
    z.object({
        field: z.literal('spn'),
        operator: z.literal('includesString'),
        parameter: z.array(z.string()),
    }),
    z.object({
        field: z.literal('linked-parts'),
        operator: z.literal('equalsAny'),
        parameter: z.array(z.nativeEnum(LinkedPartsFilter)),
    }),
    z.object({
        field: z.literal('last-changed'),
        operator: z.literal('equalsAny'),
        parameter: z.array(z.nativeEnum(IpnLastChanged)),
    }),
    z.object({
        field: z.literal('description'),
        operator: z.literal('includesString'),
        parameter: z.array(z.string()),
    }),
    z.object({
        field: z.literal('linked-parts'),
        operator: z.literal('includesString'),
        parameter: z.array(z.string()),
    }),
    z.object({
        field: z.literal('category'),
        operator: z.literal('includesString'),
        parameter: z.array(z.string()),
    }),
    z.object({
        field: z.literal('erp-data-changes'),
        operator: z.literal('arrIncludesSome'),
        parameter: z.array(z.nativeEnum(ErpDataChangesFilter)),
    }),
]);

export type ComponentSearchAggregationDTO = z.infer<typeof AggregationsRuntype>;
const AggregationsRuntype = z.object({
    capacitance: z.object({ field: z.literal('capacitance'), options: z.array(NumericOptionRuntype) }),
    dielectric: z.array(z.object({ field: z.string(), options: z.array(StringOptionRuntype) })),
    has_unreviewed_erp_changes: z.object({
        field: z.literal('has_unreviewed_erp_changes'),
        options: z.array(StringOptionRuntype),
    }),
    ipn_state: z.object({
        field: z.literal('ipn_state'),
        options: z.array(
            z.object({
                count: z.number(),
                value: z.union([z.literal('Active'), z.literal('Removed')]),
            }),
        ),
    }),
    last_changed: z.object({
        field: z.literal('last_changed'),
        options: z.array(
            z.object({
                count: z.number(),
                value: z.nativeEnum(IpnLastChanged),
            }),
        ),
    }),
    last_imported_at: z.object({ field: z.literal('last_imported_at'), value: z.number().nullable() }),
    linked_parts_filter: z.object({
        field: z.literal('linked_parts_filter'),
        options: z.array(
            z.object({
                count: z.number(),
                value: z.nativeEnum(LinkedPartsFilter),
            }),
        ),
    }),
    manufacturers: z.object({ field: z.literal('manufacturers'), options: z.array(StringOptionRuntype) }),
    packages: z.object({ field: z.literal('packages'), options: z.array(StringOptionRuntype) }),
    'part-types': z.object({ field: z.literal('part-types'), options: z.array(StringOptionRuntype) }),
    category: z.object({ field: z.literal('category'), options: z.array(StringOptionRuntype) }),
    power_rating: z.object({ field: z.literal('power_rating'), options: z.array(NumericOptionRuntype) }),
    resistance: z.object({ field: z.literal('resistance'), options: z.array(NumericOptionRuntype) }),
    temperature_coefficient: z.object({
        field: z.literal('temperature_coefficient'),
        options: z.array(NumericOptionRuntype),
    }),
    tolerance: z.object({ field: z.literal('tolerance'), options: z.array(NumericOptionRuntype) }),
    voltage_rating: z.object({ field: z.literal('voltage_rating'), options: z.array(NumericOptionRuntype) }),
    suggestions: z.object({
        field: z.literal('suggestions'),
        options: z.array(StringOptionRuntype),
    }),
    lifecycle: z.object({
        field: z.literal('lifecycle'),
        options: z.array(
            z.object({
                count: z.number(),
                value: z.nativeEnum(LifecycleEnum),
            }),
        ),
    }),
});

export const ComponentsSearchResponseDTORuntype = z.object({
    ipns: z.array(OtsOrCustomComponentRuntype),
    total_hits: ElasticTotalHitsDTORuntype,
    highlights: z.record(z.string(), z.array(z.string())).optional(),
    aggregations: AggregationsRuntype,
    page_params: z.number().or(z.undefined()).or(z.null()),
});

export interface IpnFormAndFitFormPatchValues extends z.infer<typeof IpnFormAndFitFormPatchValuesRuntype> {}
export const IpnFormAndFitFormPatchValuesRuntype = z.object({
    form_and_fit: PackageUserInputRuntype.optional(),
    rfq_context: z.literal('WithinRfQ'),
    rfq_id: z.string(),
    origin: z.object({ type: z.literal('Manual') }),
});
