import { formatMonetaryValue } from '@luminovo/commons';
import { colorSystem, columnWidth, Flexbox, Text } from '@luminovo/design-system';
import {
    ActivityConfigurationSummaryDTO,
    Duration as DurationType,
    ExchangedMonetaryValueBackend,
    MonetaryValueBackend,
    ResourceDetails,
    ResourceType,
} from '@luminovo/http-client';
import { Duration, ResourceChip, ResourceChipProps } from '@luminovo/manufacturing-core';
import { Box, Grid, styled, TableCell } from '@mui/material';
import { FlexboxProps } from '@mui/system';
import React from 'react';
import { Skeleton } from '../../../components/Spinners';
import { useHttpQuery } from '../../../resources/http/useHttpQuery';
import { themeLuminovo } from '../../../themes';
import { assertUnreachable, getValueOf } from '../../../utils/typingUtils';

const StyledText = styled(Text)({
    color: colorSystem.neutral[9],
    textwrap: 'noWrap',
});

const StyledTableCell = styled(TableCell)({
    verticalAlign: 'center',
    borderStyle: 'none none solid none',
    maxWidth: columnWidth.large,
    minHeight: themeLuminovo.typography.body1.lineHeight,
    paddingTop: 0,
    paddingBottom: 0,
});

export const TextCell = ({
    firstRow,
    secondRow,
    gap = 2,
    minWidth,
}: {
    firstRow: string | React.ReactElement;
    secondRow?: string | JSX.Element;
    minWidth?: number;
    gap?: number;
}): JSX.Element => {
    return (
        <TableCell
            style={{
                verticalAlign: 'center',
                maxWidth: columnWidth.large,
                minWidth: minWidth,
                minHeight: themeLuminovo.typography.body1.lineHeight,
            }}
        >
            {typeof firstRow === 'string' && firstRow.trim() === '' ? (
                <br /> //this is required to ensure that the cell keeps the height when it is a blank text
            ) : (
                <>{typeof firstRow === 'string' ? <StyledText>{firstRow}</StyledText> : firstRow}</>
            )}
            {secondRow && (
                <Box mt={gap}>{typeof secondRow === 'string' ? <StyledText>{secondRow}</StyledText> : secondRow}</Box>
            )}
        </TableCell>
    );
};

export const renderCurrency = ({
    monetaryValue,
    shouldAmountBePrecise,
    nullPlaceholder,
}: {
    monetaryValue: MonetaryValueBackend | null;
    shouldAmountBePrecise: boolean;
    nullPlaceholder: '' | '-'; // see missing values logic here https://www.notion.so/luminovo/Manufacturing-DB-v1-5f52ef486a5245e5aa69d515c44d2466#d06d135bfa5140108d9fafd5c4651572}
}): string => {
    return monetaryValue === null
        ? nullPlaceholder //cost is precise to fix rounding issues with the costs in the header.
        : formatMonetaryValue(monetaryValue, shouldAmountBePrecise === true ? 'unit-price' : 'default');
};

const TimeDetailsComponent = ({ time }: { time: DurationType | undefined }) => {
    return (
        <Text>
            <Duration duration={time} />
        </Text>
    );
};

const CostDetailsComponent = ({ cost }: { cost: ExchangedMonetaryValueBackend | null }) => {
    return (
        <Text>
            {renderCurrency({
                monetaryValue: cost?.converted_mv ?? null,
                shouldAmountBePrecise: true,
                nullPlaceholder: '-',
            })}
        </Text>
    );
};

const rowStyle = {
    border: `1px solid ${colorSystem.neutral[2]}`,
    borderRadius: 4,
    padding: 4,
};

const TimeCostRows = ({ children }: { children: React.ReactNode }) => (
    <Flexbox flexDirection="column" gap={1} style={rowStyle}>
        {children}
    </Flexbox>
);

const itemStyle = { width: 100, margin: 3 };

const RowItems = ({ children, style }: { children: React.ReactNode; style?: React.CSSProperties }) => (
    <Flexbox flexDirection={'row'} gap={0} flexWrap="nowrap" justifyContent={'space-between'} style={style}>
        {children}
    </Flexbox>
);

export const renderTimeAndCost = (rowData: ActivityConfigurationSummaryDTO, selectedBatchSize: number): JSX.Element => {
    const costComponent = rowData.activity_configuration_details.cost_components;
    switch (costComponent.level) {
        case 'Project': {
            const selectedBatchSizeTime = getValueOf(
                costComponent.details.times_per_batch_size,
                selectedBatchSize.toString(),
            );
            const selectedBatchSizeCost = getValueOf(
                costComponent.details.costs_per_batch_size,
                selectedBatchSize.toString(),
            );
            return (
                <StyledTableCell>
                    <TimeCostRows>
                        <RowItems>
                            <Grid item style={itemStyle}>
                                {selectedBatchSizeTime && selectedBatchSizeTime.project_time.result === 'Ok' ? (
                                    <TimeDetailsComponent time={selectedBatchSizeTime.project_time.data ?? undefined} />
                                ) : (
                                    <Text>-</Text>
                                )}
                            </Grid>
                            <Grid item style={itemStyle}>
                                {selectedBatchSizeCost && selectedBatchSizeCost.project_cost.result === 'Ok' ? (
                                    <CostDetailsComponent cost={selectedBatchSizeCost.project_cost.data} />
                                ) : (
                                    <Text>-</Text>
                                )}
                            </Grid>
                        </RowItems>
                    </TimeCostRows>
                </StyledTableCell>
            );
        }
        case 'Batch': {
            const selectedBatchSizeTime = getValueOf(
                costComponent.details.times_per_batch_size,
                selectedBatchSize.toString(),
            );
            const selectedBatchSizeCost = getValueOf(
                costComponent.details.costs_per_batch_size,
                selectedBatchSize.toString(),
            );
            return (
                <StyledTableCell>
                    <TimeCostRows>
                        <RowItems>
                            <Grid item style={itemStyle}>
                                {selectedBatchSizeTime && selectedBatchSizeTime.batch_time.result === 'Ok' ? (
                                    <TimeDetailsComponent time={selectedBatchSizeTime.batch_time.data ?? undefined} />
                                ) : (
                                    <Text>-</Text>
                                )}
                            </Grid>
                            <Grid item style={itemStyle}>
                                {selectedBatchSizeCost && selectedBatchSizeCost.batch_cost.result === 'Ok' ? (
                                    <CostDetailsComponent cost={selectedBatchSizeCost.batch_cost.data} />
                                ) : (
                                    <Text>-</Text>
                                )}
                            </Grid>
                        </RowItems>
                    </TimeCostRows>
                </StyledTableCell>
            );
        }
        case 'Unit': {
            const selectedBatchSizeTime = getValueOf(
                costComponent.details.times_per_batch_size,
                selectedBatchSize.toString(),
            );
            const selectedBatchSizeCost = getValueOf(
                costComponent.details.costs_per_batch_size,
                selectedBatchSize.toString(),
            );
            return (
                <StyledTableCell>
                    <TimeCostRows>
                        <RowItems>
                            <Grid item style={itemStyle}>
                                {selectedBatchSizeTime && selectedBatchSizeTime.unit_time?.result === 'Ok' ? (
                                    <TimeDetailsComponent time={selectedBatchSizeTime.unit_time?.data ?? undefined} />
                                ) : (
                                    <Text>-</Text>
                                )}
                            </Grid>
                            <Grid item style={itemStyle}>
                                {selectedBatchSizeCost && selectedBatchSizeCost.unit_cost.result === 'Ok' ? (
                                    <CostDetailsComponent cost={selectedBatchSizeCost.unit_cost.data} />
                                ) : (
                                    <Text>-</Text>
                                )}
                            </Grid>
                        </RowItems>
                        {doesUnitActivityConfigurationHaveBatchTimeCalculation(rowData) && (
                            <RowItems style={{ borderTop: `1px solid ${colorSystem.neutral[2]}` }}>
                                <Grid item style={itemStyle}>
                                    {selectedBatchSizeTime && selectedBatchSizeTime.batch_time?.result === 'Ok' ? (
                                        <TimeDetailsComponent
                                            time={selectedBatchSizeTime.batch_time?.data ?? undefined}
                                        />
                                    ) : (
                                        <Text>-</Text>
                                    )}
                                </Grid>
                                <Grid item style={itemStyle}>
                                    {selectedBatchSizeCost && selectedBatchSizeCost.batch_cost.result === 'Ok' ? (
                                        <CostDetailsComponent cost={selectedBatchSizeCost.batch_cost.data} />
                                    ) : (
                                        <Text>-</Text>
                                    )}
                                </Grid>
                            </RowItems>
                        )}
                    </TimeCostRows>
                </StyledTableCell>
            );
        }

        default:
            assertUnreachable(costComponent);
    }
};

export const doesUnitActivityConfigurationHaveBatchTimeCalculation = (
    activity: ActivityConfigurationSummaryDTO,
): boolean => {
    if (activity.activity_configuration_details.cost_components.level !== 'Unit') return false;
    return activity.activity_configuration_details.cost_components.details.batch_time_components !== null;
};

export const ResourcesCell = ({
    resourcesDetails,
    flexProps,
}: {
    resourcesDetails: ResourceDetails[];
    flexProps?: FlexboxProps;
}): JSX.Element => {
    const { data: resources, isLoading } = useHttpQuery('GET /resources', {});

    const resourcesProps: Array<ResourceChipProps & { id: string }> = resourcesDetails.map((resource) => {
        return {
            id: resource.resource_id,
            name: resources?.find((r) => resource.resource_id === r.id)?.name ?? '-',
            type: resources?.find((r) => resource.resource_id === r.id)?.type ?? ResourceType.Machine,
            multiplier: resource.multiplier,
        };
    });

    return (
        <>
            {isLoading && <Skeleton />}
            <Flexbox gap={2} {...flexProps}>
                {resourcesProps.map((props) => {
                    return (
                        <ResourceChip
                            key={`resource-${props.id}`}
                            name={props.name}
                            type={props.type}
                            multiplier={props.multiplier}
                        />
                    );
                })}
            </Flexbox>
        </>
    );
};
