import * as z from 'zod';
import { LanguageEnumRuntype } from '../../utils/i18n';

export interface SupplierContactDTO extends z.infer<typeof SupplierContactDTORuntype> {}
export const SupplierContactDTORuntype = z.object({
    id: z.string(),
    supplier: z.string(),
    first_name: z.string(),
    last_name: z.string(),
    email: z.string(),
    user_language: LanguageEnumRuntype,
    position: z.string().nullable(),
    is_main_contact: z.boolean(),
    is_public: z.boolean(),
});

export interface SupplierContactCreationDTO extends z.infer<typeof SupplierContactCreationDTORuntype> {}
export const SupplierContactCreationDTORuntype = SupplierContactDTORuntype.omit({
    id: true,
    is_public: true,
});

export interface SupplierContactUpdateDTO extends z.infer<typeof SupplierContactUpdateDTORuntype> {}
export const SupplierContactUpdateDTORuntype = SupplierContactDTORuntype.omit({
    id: true,
    is_public: true,
    supplier: true,
    email: true,
}).partial();
