import { TEXT_SEARCH_FIELD_ID } from '../components/constants';
import { AutocompleteOptions, SearchBlock } from '../types';

export function parseInitialSelectedBlocks<T, TAutocompleteState>(
    config: Pick<AutocompleteOptions<T, TAutocompleteState>, 'history'>,
    search: string,
): SearchBlock<T>[] {
    if (!config.history) {
        return [];
    }
    if (!config.history.queryParameter) {
        return [];
    }
    const queryParams = new URLSearchParams(search);
    const paramValue = queryParams.get(config.history.queryParameter);
    if (!paramValue) {
        return [];
    }
    try {
        const searchBlocks = JSON.parse(paramValue);
        if (!Array.isArray(searchBlocks)) {
            return [];
        }
        return searchBlocks;
    } catch {
        // if JSON parsing fails, try to parse as a text search
        if (paramValue) {
            return [{ field: TEXT_SEARCH_FIELD_ID, op: 'like', parameter: paramValue }];
        }
        return [];
    }
}
