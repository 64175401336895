import * as z from 'zod';
import { BACKEND_HOST } from '../../const';
import { endpoint } from '../http/endpoint';
import { CustomerSignupPostRuntype } from './authBackendTypes';

export const authEndpoints = {
    'POST /auth/accept-invite': endpoint({
        description: 'Registers a previously invited user.',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        responseBody: z.unknown(),
        requestBody: z.object({
            email: z.string(),
            first_name: z.string(),
            last_name: z.string(),
            token: z.string(),
            password: z.string(),
            customer_id: z.string().nullable(),
            password_confirmation: z.string(),
            phone_number: z.string(),
            language: z.string(),
            position: z.string(),
            tenant: z.string(),
        }),
        rootUrl: BACKEND_HOST + '/public',
    }),
    'POST /auth/customers/signup': endpoint({
        description: 'Signs up a customer',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        responseBody: z.unknown(),
        requestBody: CustomerSignupPostRuntype,
        rootUrl: BACKEND_HOST + '/public',
    }),
};
