import * as z from 'zod';
import { endpoint } from '../http/endpoint';

export const sustainabilityEndpoints = {
    'GET /organization/emission-request-quota': endpoint({
        description: 'Returns the total and already used quota to fetch emissions data for MPNs ',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({
            total_quota: z.number(),
            already_used_quota: z.number(),
        }),
    }),
};
