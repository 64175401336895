import { t } from '@lingui/macro';
import { LeadTimeUnit } from './LeadTime';

export function formatLeadTimeUnit(unit: LeadTimeUnit): string {
    switch (unit) {
        case LeadTimeUnit.Days:
            return t`Days`;
        case LeadTimeUnit.Weeks:
            return t`Weeks`;
        case LeadTimeUnit.Months:
            return t`Months`;
    }
}
