import { plural } from '@lingui/macro';
import { assertUnreachable, isPresent } from '@luminovo/commons';
import { Flexbox, HighlightMatcher, Link, Text } from '@luminovo/design-system';
import {
    EmsPartNumberPartSpecification,
    GenericFullPart,
    isGenericFullPart,
    isOtsFullPart,
    OtsFullPart,
    RfqContext,
} from '@luminovo/http-client';
import { useCallback } from 'react';
import { HighlightedMpn } from '../partSpecificationCards/OTSPart/OTSPartCard';
import { UnlinkedIcon } from './UnlinkedIcon';

export const OtsComponentPartOptionsTooltip = ({
    linkedParts,
    rawSpecsNotLinked,
    rfqContext,
    openOtsDrawer,
    openGenericPartDrawer,
    candidateMpns,
}: {
    linkedParts: (OtsFullPart | GenericFullPart)[];
    rawSpecsNotLinked: EmsPartNumberPartSpecification[];
    rfqContext: RfqContext;
    openOtsDrawer?: (args: { part: OtsFullPart; rfqContext: RfqContext }) => void;
    openGenericPartDrawer?: (args: { genericPartId: string; rfqContext: RfqContext }) => void;
    candidateMpns?: HighlightMatcher;
}): JSX.Element => {
    const handlePartClick = useCallback(
        (e: React.MouseEvent, part: OtsFullPart | GenericFullPart) => {
            e.stopPropagation();

            if (isOtsFullPart(part)) {
                return openOtsDrawer?.({ part, rfqContext });
            }

            if (isGenericFullPart(part)) {
                return openGenericPartDrawer?.({ genericPartId: part.id, rfqContext });
            }
        },
        [openOtsDrawer, openGenericPartDrawer, rfqContext],
    );

    return (
        <Flexbox flexDirection="column" maxHeight={200} padding="12px" gap="8px" style={{ overflowY: 'auto' }}>
            {linkedParts.length > 0 && (
                <Flexbox flexDirection="column" gap="4px">
                    {linkedParts.map((part) => (
                        <Link
                            key={part.id}
                            attention="high"
                            onClick={(e) => {
                                handlePartClick(e, part);
                            }}
                        >
                            <Text variant="body-small" color="inherit" showEllipsis>
                                <LabelPart part={part} candidateMpns={candidateMpns} />
                            </Text>
                        </Link>
                    ))}
                </Flexbox>
            )}
            {rawSpecsNotLinked.length > 0 && (
                <Flexbox flexDirection="column" gap="4px">
                    {rawSpecsNotLinked.map((part) => (
                        <Flexbox key={part.id} gap="4px">
                            <RenderRawSpecification key={part.id} rawSpecification={part} /> <UnlinkedIcon />
                        </Flexbox>
                    ))}
                </Flexbox>
            )}
        </Flexbox>
    );
};

export function formatRawSpecification(rawSpecification: EmsPartNumberPartSpecification) {
    const mpn = rawSpecification.mpn;
    const manufacturer = rawSpecification.manufacturer;
    const description = rawSpecification.description;
    if (mpn || manufacturer) {
        return [mpn, manufacturer].filter(isPresent).join(', ');
    }
    if (description) {
        return description;
    }
    return undefined;
}

export const RenderRawSpecification = ({ rawSpecification }: { rawSpecification: EmsPartNumberPartSpecification }) => {
    return (
        <Text variant="body-small" showEllipsis>
            {formatRawSpecification(rawSpecification) ?? '-'}
        </Text>
    );
};

const LabelPart = ({
    part,
    candidateMpns,
}: {
    part: OtsFullPart | GenericFullPart;
    candidateMpns?: HighlightMatcher;
}) => {
    if (isGenericFullPart(part)) {
        const matchesCount = part.matches.length;
        return (
            <Text variant="body-small" color="inherit">
                {plural(matchesCount, {
                    one: `Generic part with 1 match`,
                    other: `Generic part with ${matchesCount} matches`,
                })}
            </Text>
        );
    }
    if (isOtsFullPart(part)) {
        return (
            <>
                <HighlightedMpn
                    mpns={[part.mpn, ...part.mpn_aliases]}
                    matcher={candidateMpns}
                    nonHighlightedStyles={{ color: 'inherit' }}
                />
                <Text variant="body-small" color="inherit">
                    , {part.manufacturer.name}
                </Text>
            </>
        );
    }
    assertUnreachable(part);
};
