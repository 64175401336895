import * as z from 'zod';
import { assertSuccessfulResponseGenericErrorCode } from '../http/assertSuccessfulResponseGenericErrorCode';
import { endpoint } from '../http/endpoint';
import {
    QuotePriceBulkRequestBodyDTORuntype,
    QuotePriceRequestBodyDTORuntype,
    SendQuotePriceRequestBodyDTORuntype,
} from './exportBackendTypes';
import { getNormalizedFileName } from './exportFileName';

export const exportEndpoints = {
    'GET /export/costed-bom-xlsx': endpoint({
        description: 'Returns an excel file with all of the costed BOM',
        pathParams: z.undefined(),
        queryParams: z.object({
            rfq: z.string(),
            template_id: z.string().optional(),
        }),
        requestBody: z.undefined(),
        invalidates: ['GET /rfqs/snapshots', 'GET /rfqs/:rfqId/snapshot'],
        responseBody: z.object({ fileName: z.string(), blob: z.instanceof(Blob) }),
        handleResponse: async (response, endpoint) => {
            await assertSuccessfulResponseGenericErrorCode(response, endpoint);
            return { fileName: getNormalizedFileName(response), blob: await response.blob() };
        },
    }),

    'POST /export/quote-price-xlsx': endpoint({
        description: 'Returns an quote-price templates',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: QuotePriceRequestBodyDTORuntype,
        responseBody: z.object({ fileName: z.string(), blob: z.instanceof(Blob) }),
        handleResponse: async (response, endpoint) => {
            await assertSuccessfulResponseGenericErrorCode(response, endpoint);
            return { fileName: getNormalizedFileName(response), blob: await response.blob() };
        },
    }),

    'POST /export/quote-price-xlsx-bulk': endpoint({
        description: 'Returns a zip file containing supplier specific quote-price templates',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: QuotePriceBulkRequestBodyDTORuntype,
        responseBody: z.object({ fileName: z.string(), blob: z.instanceof(Blob) }),
        handleResponse: async (response, endpoint) => {
            await assertSuccessfulResponseGenericErrorCode(response, endpoint);
            return { fileName: getNormalizedFileName(response), blob: await response.blob() };
        },
    }),

    'GET /export/bom-xlsx': endpoint({
        description: 'Returns an excel file with all of the BOM items in a given assembly',
        pathParams: z.undefined(),
        queryParams: z.object({ assemblyId: z.string(), templateId: z.string().optional() }),
        requestBody: z.undefined(),
        responseBody: z.object({ fileName: z.string(), blob: z.instanceof(Blob) }),
        handleResponse: async (response, endpoint) => {
            await assertSuccessfulResponseGenericErrorCode(response, endpoint);
            return { fileName: getNormalizedFileName(response), blob: await response.blob() };
        },
    }),
    'GET /export/calculation-cost-xlsx': endpoint({
        description: 'Returns an excel file with all of the calculation cost items in a given assembly',
        pathParams: z.undefined(),
        queryParams: z.object({ calculation_assembly_details_id: z.string() }),
        requestBody: z.undefined(),
        responseBody: z.object({ fileName: z.string(), blob: z.instanceof(Blob) }),
        handleResponse: async (response, endpoint) => {
            await assertSuccessfulResponseGenericErrorCode(response, endpoint);
            return { fileName: getNormalizedFileName(response), blob: await response.blob() };
        },
    }),

    'GET /export/manufacturing-xlsx': endpoint({
        description: 'Returns an excel file with all of the manufacturing items in a given assembly',
        pathParams: z.undefined(),
        queryParams: z.object({ manufacturing_assembly_details_id: z.string() }),
        requestBody: z.undefined(),
        responseBody: z.object({ fileName: z.string(), blob: z.instanceof(Blob) }),
        handleResponse: async (response, endpoint) => {
            await assertSuccessfulResponseGenericErrorCode(response, endpoint);
            return { fileName: getNormalizedFileName(response), blob: await response.blob() };
        },
    }),

    'GET /rfqs/:rfqId/snapshot/:snapshotId/costed-bom-xlsx': endpoint({
        description: 'Download a costed BOM as an Excel file',
        pathParams: z.object({
            rfqId: z.string(),
            snapshotId: z.string(),
        }),
        queryParams: z.object({
            template_id: z.string().optional(),
        }),
        requestBody: z.undefined(),
        responseBody: z.object({ fileName: z.string(), blob: z.instanceof(Blob) }),
        handleResponse: async (response, endpoint) => {
            await assertSuccessfulResponseGenericErrorCode(response, endpoint);
            return { fileName: getNormalizedFileName(response), blob: await response.blob() };
        },
    }),

    'POST /export/send-quote-price-mail': endpoint({
        description: 'Sends multiple emails with upplier specific quote-price-xlsx attached',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: SendQuotePriceRequestBodyDTORuntype,
        responseBody: z.unknown(),
        invalidates: [
            'GET /rfqs/:rfqId/quote-tracking',
            'GET /quote-tracking/:id',
            'GET /offers/custom-part',
            'GET /offers/off-the-shelf',
            'GET /solution-configurations',
            'GET /solutions',
            'POST /offers/off-the-shelf/bulk',
            'POST /offers/off-the-shelf/with-solutions',
            'POST /sourcing-scenarios/bulk',
            'POST /sourcing/calculation',
        ],
    }),
};
