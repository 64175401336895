import { Messages } from '@lingui/core';
import { LanguageEnum } from './i18n';
import { messages as de } from './locales/de/messages';
import { messages as en } from './locales/en/messages';
import { messages as es } from './locales/es/messages';
import { messages as fr } from './locales/fr/messages';
import { messages as it } from './locales/it/messages';
export * from './analytics';
export * from './arrays';
export * from './comparators';
export * from './errors';
export * from './formatters';
export * from './hooks';
export * from './i18n';
export * from './regex';
export * from './sorting';
export * from './typingUtils';
export * from './utils';

export const locales = { de, en, es, fr, it } satisfies Record<LanguageEnum, Messages>;
