import { t, Trans } from '@lingui/macro';
import { formatDecimal, id } from '@luminovo/commons';
import {
    createColumnHelper,
    NonIdealState,
    RequestViaMailIcon,
    SecondaryButton,
    TanStackTable,
    useTanStackTable,
} from '@luminovo/design-system';
import { QuoteTrackingDTO, QuoteTrackingState } from '@luminovo/http-client';
import { formatSupplierAndStockLocationDTO } from '@luminovo/sourcing-core';
import { Box } from '@mui/material';
import React from 'react';
import { LayoutCard } from '../../../../components/LayoutCard';
import { useQuoteTrackings } from '../../../../resources/quoteTracking/quoteTrackingHandler';
import { analytics } from '../../../../utils/analytics';
import { route } from '../../../../utils/routes';
import { useRequestConfigurationDialog } from '../QuoteExporter/RequestConfigurationDialog';
import { QuoteTrackingStateChip } from '../QuoteTracking/QuoteTrackingList';
type QuoteOverviewSharedContext = {
    rfqId: string;
};

const columnHelper = createColumnHelper<QuoteTrackingDTO, QuoteOverviewSharedContext>();

const columns = [
    columnHelper.enum((row) => row.supplier_and_stock_location, {
        id: 'supplier_and_stock_location',
        size: 160,
        label: () => t`Supplier`,
        getOptionLabel: (opt) => formatSupplierAndStockLocationDTO(opt),
        getOptionKey: (opt) => opt.id,
        renderType: 'text',
        cell: (item) => formatSupplierAndStockLocationDTO(item.getValue()),
    }),
    columnHelper.text((row) => row.number, {
        id: 'requestNumber',
        size: 100,
        label: () => t`Request number`,
    }),
    columnHelper.number((row) => row.request_custom_parts.length + row.request_standard_parts.length, {
        id: 'partRequests',
        size: 100,
        label: () => t`Requested parts`,
        cell: (item) => `${formatDecimal(item.getValue())} parts`,
    }),
    columnHelper.enum((row) => row.state, {
        id: 'State',
        size: 100,
        options: Object.values(QuoteTrackingState),
        label: () => t`State`,
        align: 'right',
        getOptionLabel: (opt) => {
            switch (opt) {
                case 'Received':
                    return t`Received`;
                case 'Pending':
                    return t({ id: 'quotetracking.status.pending', message: 'Pending' });
                case 'Overdue':
                    return t`Overdue`;
                case 'Discarded':
                    return t`Discarded`;
                case 'FailedToSend':
                    return t`Failed to send`;
                default:
                    return t`Unknown`;
            }
        },
        renderOption: (opt) => <QuoteTrackingStateChip quoteTracking={{ state: opt }} />,
        cell: ({ row }) => <QuoteTrackingStateChip quoteTracking={row.original} />,
    }),
];

function RequestQuoteButton({ rfqId }: { rfqId: string }): JSX.Element {
    const { openDialog } = useRequestConfigurationDialog({ rfqId });

    return (
        <SecondaryButton
            size="small"
            onClick={() => {
                openDialog();
                analytics.track(id('sourcing/button_request_quote'));
            }}
            startIcon={<RequestViaMailIcon />}
        >
            {t({ id: 'request.quote.cta', message: 'Request quote' })}
        </SecondaryButton>
    );
}
const EmptyPlaceholder: React.FunctionComponent<{ sharedContext: QuoteOverviewSharedContext }> = ({
    sharedContext,
}) => {
    const ActionButton = React.useMemo(
        () => () => <RequestQuoteButton rfqId={sharedContext.rfqId} />,
        [sharedContext.rfqId],
    );
    return (
        <NonIdealState
            Icon={RequestViaMailIcon}
            title={t`No quote requests`}
            description={t`You have not sent any quote requests for this RfQ.`}
            overrides={{ ActionButton }}
            style={{ padding: '40px 40px' }}
        />
    );
};

export function QuoteRequestCard({ rfqId }: { rfqId: string }): JSX.Element {
    const { data } = useQuoteTrackings(rfqId);

    const { table } = useTanStackTable({
        data,
        columns,
        sharedContext: {
            rfqId,
        },
        getRowRoute: (row) =>
            route(
                '/rfqs/:rfqId/sourcing/quote-tracking',
                {
                    rfqId: row.original.rfq,
                },
                {
                    quoteTrackingId: row.original.id,
                },
            ),
    });

    return (
        <LayoutCard
            title={t`Quote overview`}
            style={{ width: '100%' }}
            actions={
                <>
                    <SecondaryButton size={'small'} href={route('/rfqs/:rfqId/sourcing/quote-importer', { rfqId })}>
                        <Trans>Import Excel</Trans>
                    </SecondaryButton>
                    <RequestQuoteButton rfqId={rfqId} />
                </>
            }
        >
            <Box height={'calc(100% - 48px)'} data-testid="quote-tracking-table">
                <TanStackTable table={table} size={'small'} enableMenuBar={false} EmptyPlaceholder={EmptyPlaceholder} />
            </Box>
        </LayoutCard>
    );
}
