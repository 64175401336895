import { t } from '@lingui/macro';
import { getToken } from '@luminovo/auth';
import {
    ComplianceStatus,
    CustomPartInput,
    CustomPartTypeEnum,
    CustomPartUpdateInput,
    http,
} from '@luminovo/http-client';
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { FileWithUrlAndName } from '../../../../resources/assembly/assemblyFrontendTypes';
import { useDebugErrorHandler } from '../../../../resources/http/debugErrorHandler';
import { useHttpFileUpload } from '../../../../resources/http/useHttpFileUpload';
import { AddCustomPartState } from './AddCustomPart/reducer';

export function useCreateCustomPartMutation() {
    const { enqueueSnackbar } = useSnackbar();
    const debugErrorHandler = useDebugErrorHandler();
    const { mutateAsync: uploadFiles } = useHttpFileUpload('GET /parts/custom/:partId/upload', (res) => res.data.url, {
        snackbarMessage: null,
    });
    return useMutation({
        mutationFn: async (values: AddCustomPartState) => {
            if (values.partType === CustomPartTypeEnum.PCB) {
                throw new Error('Trying to create a PCB as a custom part');
            }
            const body: CustomPartInput = {
                description: values.description?.trim() ?? null,
                type: { name: values.partType ?? CustomPartTypeEnum.Other },
                reach_compliant: ComplianceStatus.Unknown,
                rohs_compliant: ComplianceStatus.Unknown,
            };

            const { data: customPart } = await http('POST /parts/custom', { requestBody: body }, getToken());
            await uploadFiles({
                pathParams: { partId: customPart.id },
                files: values.uploadFiles.map(({ file }) => file),
            });

            return customPart.id;
        },
        onSuccess: () => {
            enqueueSnackbar(t`Custom part created`, { variant: 'success' });
        },
        onError: debugErrorHandler,
    });
}

export type UpdateCustomPartMutationProps = {
    values: CustomPartUpdateInput;
    files?: FileWithUrlAndName[];
};
