export type LeadTime = {
    value: number;
    unit: LeadTimeUnit;
};

export enum LeadTimeUnit {
    Days = 'days',
    Weeks = 'weeks',
    Months = 'months',
}

export function convertLeadTimeUnitToDays(unit?: LeadTimeUnit): number | undefined {
    if (!unit) {
        return undefined;
    }
    switch (unit) {
        case LeadTimeUnit.Days:
            return 1;
        case LeadTimeUnit.Weeks:
            return 7;
        case LeadTimeUnit.Months:
            return 31;
    }
}

export function convertLeadTimeToDays(leadTime?: LeadTime): number | undefined {
    if (!leadTime) {
        return undefined;
    }

    const days = leadTime.value * (convertLeadTimeUnitToDays(leadTime.unit) ?? 1);

    // the backend doesn't support 0 days
    if (days === 0) {
        return undefined;
    }

    return days;
}
