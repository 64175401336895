import * as z from 'zod';

export type ApiIntegrationStatus = z.infer<typeof ApiIntegrationStatusRuntype>;
export const ApiIntegrationStatusRuntype = z.enum([
    'Active',
    'ActiveByDefault',
    'Inactive',
    'MissingApiKey',
    'InvalidCredentials',
    'InvalidApiKey',
    'InvalidSettings',
    'RateLimited',
    'Error',
]);
