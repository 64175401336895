import { ChargeImmediatelyResponseDTO } from '@luminovo/http-client';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
// eslint-disable-next-line no-restricted-imports
import { useParams } from 'react-router-dom';
import { QueryParams, route } from '../../../utils/routes';

const generateReturnUrl = (orderDetails: ChargeImmediatelyResponseDTO, rfqId: string) => {
    const orderConfirmationQueryParameters: QueryParams<'/rfqs/:rfqId/order/confirmation'> = {
        orderSelectionOptionId: orderDetails.order_selection_option_id,
        payment_intent_client_secret: undefined,
        payment_intent_id: undefined,
        redirect_status: undefined,
    };

    const returnUrl = `${window.location.origin}${route(
        '/rfqs/:rfqId/order/confirmation',
        {
            rfqId,
        },
        orderConfirmationQueryParameters,
    )}`;
    return returnUrl;
};

export function useStripeCheckoutFunction(orderDetails: ChargeImmediatelyResponseDTO) {
    const stripe = useStripe();
    const elements = useElements();
    const { enqueueSnackbar } = useSnackbar();
    const { rfqId } = useParams<{ rfqId: string }>();

    const handlePayment = async () => {
        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }
        const response = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: generateReturnUrl(orderDetails, rfqId),
            },
        });
        if (response.error) {
            throw new Error(response.error.message);
        }
        return response;
    };

    return useMutation({
        mutationFn: handlePayment,
        onError: (error: Error) => {
            enqueueSnackbar(error.message, { variant: 'error' });
        },
    });
}
