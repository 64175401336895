import { t } from '@lingui/macro';
import { FieldNumeric } from '@luminovo/design-system';
import { MenuItem as MuiMenuItem } from '@mui/material';
import { Column, GridApi } from 'ag-grid-community';
import { useColumnFilter } from './DataGridContextProvider';

export function MenuItemFilterNumeric<T>({ column, api }: { column: Column<T>; api: GridApi<T> }): JSX.Element {
    const filter = useColumnFilter<T>(column.getColId());

    const filterFrom = filter?.filterType === 'number' && 'filter' in filter ? filter.filter : null;
    const filterTo = filter?.filterType === 'number' && 'filterTo' in filter ? filter.filterTo : null;
    return (
        <>
            <MuiMenuItem
                disableGutters
                disableRipple
                sx={{
                    padding: '0 8px',

                    backgroundColor: 'white !important',
                }}
            >
                <FieldNumeric
                    size="small"
                    fullWidth
                    value={filterFrom}
                    onKeyDown={(event) => {
                        event.stopPropagation();
                    }}
                    onChange={(num) => {
                        if (num === null) {
                            api.setFilterModel({
                                ...api.getFilterModel(),
                                [column.getColId()]: null,
                            });
                        } else if (filterFrom !== null) {
                            api.setFilterModel({
                                ...api.getFilterModel(),
                                [column.getColId()]: {
                                    ...filter,
                                    filter: num,
                                },
                            });
                        } else {
                            api.setFilterModel({
                                ...api.getFilterModel(),
                                [column.getColId()]: {
                                    filterType: 'number',
                                    type: 'greaterThanOrEqual',
                                    filter: num,
                                },
                            });
                        }
                    }}
                    type="search"
                    placeholder={t`From`}
                />
            </MuiMenuItem>
            <MuiMenuItem
                disableGutters
                disableRipple
                sx={{
                    marginTop: 1,
                    padding: '0 8px',

                    backgroundColor: 'white !important',
                }}
            >
                <FieldNumeric
                    size="small"
                    fullWidth
                    value={filterTo}
                    onKeyDown={(event) => {
                        event.stopPropagation();
                    }}
                    onChange={(newFilterTo) => {
                        if (newFilterTo === null) {
                            api.setFilterModel({
                                ...api.getFilterModel(),
                                [column.getColId()]: null,
                            });
                        } else if (filterFrom !== null) {
                            api.setFilterModel({
                                ...api.getFilterModel(),
                                [column.getColId()]: {
                                    filterType: 'number',
                                    type: 'inRange',
                                    filter: filterFrom,
                                    filterTo: newFilterTo,
                                },
                            });
                        } else {
                            api.setFilterModel({
                                ...api.getFilterModel(),
                                [column.getColId()]: {
                                    filterType: 'number',
                                    type: 'lessThanOrEqual',
                                    filter: newFilterTo,
                                },
                            });
                        }
                    }}
                    type="search"
                    placeholder={t`To`}
                />
            </MuiMenuItem>
        </>
    );
}
