import { isPresent } from '@luminovo/commons';
import { RfqContext } from '@luminovo/http-client';
import { useHttpQuery } from '../../resources/http/useHttpQuery';
import { assertUnreachable } from '../../utils/typingUtils';

function convertRfqContext(rfqContext: RfqContext) {
    if (rfqContext.type === 'WithinRfQ') {
        return {
            rfq_context: rfqContext.type,
            rfq_id: rfqContext.rfq_id,
        };
    }
    if (rfqContext.type === 'OutsideRfQ') {
        return {
            rfq_context: rfqContext.type,
        };
    }
    assertUnreachable(rfqContext);
}

export function useMpnMatches(genericPartId: string | undefined, rfqContext: RfqContext) {
    const { isLoading, data } = useHttpQuery(
        'POST /parts/off-the-shelf/search/generic/bulk',
        {
            requestBody: { ids: [genericPartId!], ...convertRfqContext(rfqContext) },
        },
        {
            select: (res) =>
                Object.entries(res.data).map(([genericPartId, otsParts]) => {
                    return {
                        genericPartId,
                        otsParts,
                    };
                })[0],
            enabled: isPresent(genericPartId),
        },
    );

    return {
        isLoading,
        mpnMatches: data?.otsParts ?? [],
    };
}
