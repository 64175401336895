import { useSourcingScenarioQuickForm } from '@/modules/SourcingScenarioForm';
import { t, Trans } from '@lingui/macro';
import { formatDecimal, isPresent } from '@luminovo/commons';
import { ChipGroup, colorSystem, Flexbox, SecondaryButton, Text } from '@luminovo/design-system';
import { SourcingCalculationDTO, SourcingScenarioDTO } from '@luminovo/http-client';
import { isSolutionInNeedOfUpdate, visibleSolutionPreferences } from '@luminovo/sourcing-core';
import { Sync, TuneRounded } from '@mui/icons-material';
import { Box, Tooltip } from '@mui/material';
import React from 'react';
import { AlertLoadingRfqOffers } from '../../../../components/Alerts';
import { useAssemblies } from '../../../../resources/assembly/assemblyHandler';
import { useForcedOfferUpdate } from '../../../../resources/rfq/rfqHandler';

const NotUpToDateOfferInformation: React.FunctionComponent<{
    rfqId: string;
    sourcingCalculationDTO: SourcingCalculationDTO | undefined;
}> = ({ rfqId, sourcingCalculationDTO }): JSX.Element => {
    const { mutateAsync, isPending: isLoading } = useForcedOfferUpdate(rfqId);

    const solutionConfigurations =
        sourcingCalculationDTO?.solution_configurations.flatMap((solutionConfig) => {
            const status = solutionConfig.solution_configuration_summary.status;
            if (status.type === 'Status' && 'tags' in status.data) {
                return [status.data.tags];
            }
            return [];
        }) ?? [];

    const count = solutionConfigurations.filter((tags) => isSolutionInNeedOfUpdate({ solutionTags: tags })).length;

    if (!isPresent(sourcingCalculationDTO) || count === 0) {
        return <></>;
    }

    return (
        <Flexbox gap={8} alignItems={'center'} justifyContent={'flex-end'}>
            <Text variant="body" color={colorSystem.neutral[7]}>
                {t`${formatDecimal(count)} of your selected solutions are not up to date.`}
            </Text>
            <Tooltip title={t`Update API offers`}>
                <SecondaryButton size="small" onClick={() => mutateAsync()} isLoading={isLoading} startIcon={<Sync />}>
                    <Trans>Update offers</Trans>
                </SecondaryButton>
            </Tooltip>
        </Flexbox>
    );
};

export const SourcingConfigurationContentHeader: React.FunctionComponent<{
    rfqId: string;
    sourcingScenarioDTO: SourcingScenarioDTO;
    sourcingCalculationDTO: SourcingCalculationDTO | undefined;
}> = ({ rfqId, sourcingScenarioDTO, sourcingCalculationDTO }): JSX.Element => {
    const solutionPreferences = visibleSolutionPreferences(sourcingScenarioDTO.solution_preference);
    const { data: assemblyDetails = [] } = useAssemblies(
        sourcingScenarioDTO.assembly_quantities.items.map((item) => item.assembly),
    );

    const { openDialog } = useSourcingScenarioQuickForm({
        sourcingScenarioId: sourcingScenarioDTO.id,
    });
    return (
        <Flexbox gap={40} justifyContent={'space-between'} alignItems={'center'}>
            <Flexbox flexDirection={'column'} gap={10}>
                <Flexbox gap={12} alignItems={'center'} flexWrap={'wrap'}>
                    <Text variant="h3">{sourcingScenarioDTO.name}</Text>
                    <ChipGroup
                        options={assemblyDetails}
                        getOptionLabel={(option) => {
                            const quantity = sourcingScenarioDTO.assembly_quantities.items?.find(
                                (a) => a.assembly === option.id,
                            )?.quantity;
                            return `${formatDecimal(quantity)}x ${option.designator}`;
                        }}
                    />
                </Flexbox>
                <Flexbox gap={12} alignItems={'center'}>
                    <Tooltip title={t`Adjust your sourcing preferences`}>
                        <SecondaryButton size="small" onClick={openDialog} startIcon={<TuneRounded />}>
                            <Trans>Edit</Trans>
                        </SecondaryButton>
                    </Tooltip>
                    <Text variant="body" color={colorSystem.neutral[7]} showEllipsis>
                        {solutionPreferences.join(' • ')}
                    </Text>
                </Flexbox>
            </Flexbox>
            <Box width={'50%'}>
                <AlertLoadingRfqOffers
                    rfqId={rfqId}
                    partIds={null}
                    Placeholder={() => (
                        <NotUpToDateOfferInformation rfqId={rfqId} sourcingCalculationDTO={sourcingCalculationDTO} />
                    )}
                />
            </Box>
        </Flexbox>
    );
};
