import * as z from 'zod';
import { ApiIntegrationStatusRuntype } from './3rdPartyBackendTypes';

export interface SamtecCredentialsDTO extends z.infer<typeof SamtecCredentialsDTORuntype> {}
export const SamtecCredentialsDTORuntype = z.object({
    token: z.string(),
});

export interface SamtecCredentialsInputDTO extends z.infer<typeof SamtecCredentialsInputDTORuntype> {}
export const SamtecCredentialsInputDTORuntype = z.object({
    token: z.string().optional(),
});

export interface SamtecResponseBodyDTO extends z.infer<typeof SamtecResponseBodyDTORuntype> {}
export const SamtecResponseBodyDTORuntype = z.object({
    data: SamtecCredentialsDTORuntype.nullable(),
    status: ApiIntegrationStatusRuntype,
});
