import * as z from 'zod';
import { endpoint } from '../http/endpoint';
import { ContributorsRuntype, InviteExternalUserRuntype } from './contributorsBackendTypes';

export const contributorEndpoints = {
    'PATCH /contributors/rfqs/:rfqId': endpoint({
        description: 'Updates all the contributors that are part of a given RfQ',
        pathParams: z.object({ rfqId: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.array(z.string()),
        responseBody: z.unknown(),
    }),

    'GET /contributors/rfqs/:rfqId': endpoint({
        description: 'Returns all contributors associated to RfQ',
        pathParams: z.object({ rfqId: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: ContributorsRuntype,
    }),

    'POST /contributors/rfqs/bulk': endpoint({
        description:
            ' Returns all contributors with the given IDs. The request body is a list of RfQ IDs. The response body is a map from RfQ ID to a list of contributors in the given RfQ',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.array(z.string()),
        responseBody: z.record(z.string(), z.array(z.string())),
    }),

    'POST /contributors/rfqs/:rfqId/invite': endpoint({
        description: 'Sends an invite to an external user',
        pathParams: z.object({ rfqId: z.string() }),
        queryParams: z.undefined(),
        requestBody: InviteExternalUserRuntype,
        responseBody: z.unknown(),
    }),
};
