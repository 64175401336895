import * as z from 'zod';
import { CurrencyRuntype } from '../backendTypes';
import { ApiIntegrationStatusRuntype } from './3rdPartyBackendTypes';

export type FarnellLocaleSiteDTO = z.infer<typeof FarnellLocaleSiteDTORuntype>;
export const FarnellLocaleSiteDTORuntype = z.enum([
    'AT',
    'BE',
    'BG',
    'CH',
    'CZ',
    'DE',
    'DK',
    'EE',
    'ES',
    'FI',
    'FR',
    'HU',
    'IE',
    'IL',
    'IT',
    'LT',
    'LV',
    'NL',
    'NO',
    'PL',
    'PT',
    'RO',
    'RU',
    'SE',
    'SI',
    'SK',
    'TR',
    'UK',
    'EXPORT',
]);

export interface FarnellCredentialsDTO extends z.infer<typeof FarnellCredentialsDTORuntype> {}
export const FarnellCredentialsDTORuntype = z.object({
    api_key: z.string().optional(),
    customer_id: z.string().nullable().optional(),
    secret_key: z.string().nullable().optional(),
    store_location: FarnellLocaleSiteDTORuntype,
    list_price_currency: CurrencyRuntype,
    contract_price_currency: CurrencyRuntype,
    status: ApiIntegrationStatusRuntype,
});

export interface FarnellCredentialsInputDTO extends z.infer<typeof FarnellCredentialsInputDTORuntype> {}
export const FarnellCredentialsInputDTORuntype = z.object({
    api_key: z.string(),
    customer_id: z.string().nullable(),
    secret_key: z.string().nullable(),
    store_location: FarnellLocaleSiteDTORuntype,
    list_price_currency: CurrencyRuntype,
    contract_price_currency: CurrencyRuntype,
});

export interface FarnellSupportedSettingDTO extends z.infer<typeof FarnellSupportedSettingDTORuntype> {}
export const FarnellSupportedSettingDTORuntype = z.object({
    store_location: FarnellLocaleSiteDTORuntype,
    list_price_currency: z.array(CurrencyRuntype),
    contract_price_currency: z.array(CurrencyRuntype),
});
