import { getToken } from '@luminovo/auth';
import { isPresent } from '@luminovo/commons';
import {
    EndpointRegistry,
    extractEndpointsToBeInvalidated,
    extractEndpointsToBeRemoved,
    http,
    HttpOptions,
    RegisteredHttpEndpoint,
} from '@luminovo/http-client';
import { useMutation, UseMutationOptions, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import * as z from 'zod';
import { useDebugErrorHandler } from '../http/debugErrorHandler';
import { httpQueryKey } from '../http/httpQueryKey';

/**
 * `useMutation` adapted for making type-safe HTTP mutation requests that also handle invalidating and removing queries from the query client.
 *
 * Do not forget to define the `invalidate` and `remove` array in the endpoint definition.
 */
export function useHttpMutation<T extends RegisteredHttpEndpoint>(
    endpoint: T,
    options: Pick<
        UseMutationOptions<z.infer<EndpointRegistry[T]['responseBody']>, unknown, HttpOptions<T>, unknown>,
        'retry' | 'onSuccess'
    > & {
        /**
         * A message that will be shown in a snackbar after a successful mutation.
         */
        snackbarMessage:
            | ((response: { responseBody: z.infer<EndpointRegistry[T]['responseBody']> }) => {
                  message: string;
                  variant: 'success' | 'error' | 'info';
              })
            | string
            | null;
        /**
         * If true, errors will not be handled by the default error handler.
         *
         * Usually used in combination with the FormContainer to suppress the snackbar.
         */
        disableOnError?: boolean;
        /**
         * When set to true we automaticaly invalidate all queries, and when the endpoint is a deletion we also remove all inactive queries.
         *
         * @default true
         */
        enableInvalidateAllQueries?: boolean;
        /**
         * Delay the mutation in milliseconds. This is useful when the invalidation should needs to be delayed because the backend doesn't update immediately.
         *
         * @default false
         */
        delay?: number;
    },
): UseMutationResult<z.infer<EndpointRegistry[T]['responseBody']>, unknown, HttpOptions<T>, unknown> {
    const {
        snackbarMessage,
        onSuccess = () => {},
        disableOnError = false,
        enableInvalidateAllQueries = true,
        delay,
        ...rest
    } = options;
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();
    const onError = useDebugErrorHandler();

    return useMutation({
        mutationKey: httpQueryKey(endpoint),
        mutationFn: (variables: HttpOptions<T>) => http(endpoint, variables, getToken()),
        onSuccess: async (data, variables, context) => {
            if (isPresent(delay)) {
                await new Promise((resolve) => setTimeout(resolve, delay));
            }

            /**
            if (!isProductionEnvironment() && enableInvalidateAllQueries) {
                queryClient.invalidateQueries();
            }
            */

            // In the case of an deletion we aggressively invalidate all queries.
            // In the case of an deletion we aggressively invalidate all queries.
            // Additionally, we remove inactive queries as they are not automatically refetched.
            if (endpoint.startsWith('DELETE /')) {
                await queryClient.removeQueries({ type: 'inactive' });
                if (enableInvalidateAllQueries) {
                    queryClient.invalidateQueries();
                }
            }

            await Promise.allSettled(
                extractEndpointsToBeRemoved(endpoint).map((endpoint) =>
                    queryClient.removeQueries({
                        queryKey: [endpoint],
                    }),
                ),
            );

            await Promise.allSettled(
                extractEndpointsToBeInvalidated(endpoint).map((endpoint) =>
                    queryClient.invalidateQueries({
                        queryKey: [endpoint],
                    }),
                ),
            );

            const message =
                typeof snackbarMessage === 'function'
                    ? snackbarMessage({ responseBody: data })
                    : typeof snackbarMessage === 'string'
                      ? { message: snackbarMessage, variant: 'success' as const }
                      : null;

            if (message) {
                enqueueSnackbar(message.message, {
                    key: message.message,
                    variant: message.variant,
                    anchorOrigin: { vertical: 'top', horizontal: 'center' },
                    autoHideDuration: 2000,
                    preventDuplicate: true,
                });
            }

            return onSuccess(data, variables, context);
        },
        onError: disableOnError ? undefined : onError,
        ...rest,
    });
}
