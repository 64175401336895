import * as z from 'zod';
import { endpoint } from '../http/endpoint';
import { DemandDTORuntype } from '../negotiation';
import { LoadingOffersProgressDTORuntype } from '../part/partBackendTypes';
import { UserDTORuntype } from '../user';
import {
    CustomerPortalStateRuntype,
    QuotationWarningsRuntype,
    RfqDTORuntype,
    RfqInputDTORuntype,
    RfqListItemRuntype,
    RfqStatusHistoryRuntype,
    RfqUpdateRuntype,
} from './rfqBackendTypes';
import { SnapshotMetadataItemRuntype, SnapshotRuntype, SnapshotTagRuntype } from './snapshotRuntype';
// This is needed for some hack type script type issues
// eslint-disable-next-line @typescript-eslint/no-unused-vars

// Split the endpoints into multiple smaller objects to manage type size
const rfqListEndpoints = {
    'GET /rfqs': endpoint({
        description: 'GET multiple RfQs',
        pathParams: z.undefined(),
        queryParams: z.union([
            z.object({ activity_id: z.string() }),
            z.object({}),
            z.object({ expense_id: z.string() }),
            z.object({ filter: z.enum(['all', 'archived', 'not-archived']) }),
        ]),
        requestBody: z.undefined(),
        responseBody: z.object({
            data: z.array(RfqListItemRuntype),
            total_pages: z.number(),
        }),
    }),

    'POST /rfqs': endpoint({
        description: 'Create an RfQ',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: RfqInputDTORuntype,
        responseBody: z.object({ data: RfqDTORuntype }),
        invalidates: ['GET /rfqs', 'GET /rfqs/:rfqId', 'GET /organization-settings/auto-rfq-number/next'],
    }),

    'GET /rfqs/:rfqId': endpoint({
        description: 'GET a single RfQ',
        pathParams: z.object({ rfqId: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({
            data: RfqDTORuntype,
        }),
    }),
};

const rfqSnapshotEndpoints = {
    'GET /rfqs/snapshots': endpoint({
        description: 'Fetch a list of snapshot metadata items for all RfQs',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({
            items: z.array(SnapshotMetadataItemRuntype),
        }),
    }),

    'GET /rfqs/:rfqId/snapshot': endpoint({
        description: 'Fetch a list of snapshot metadata items',
        pathParams: z.object({
            rfqId: z.string(),
        }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({
            items: z.array(SnapshotMetadataItemRuntype),
        }),
    }),

    'GET /rfqs/:rfqId/demands': endpoint({
        description: 'Fetch a list of demands for a single RfQ',
        pathParams: z.object({
            rfqId: z.string(),
        }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({
            items: z.array(DemandDTORuntype),
        }),
    }),

    'POST /rfqs/:rfqId/snapshot/trigger': endpoint({
        description: 'Create a sourcing snapshot',
        pathParams: z.object({
            rfqId: z.string(),
        }),
        queryParams: z.undefined(),
        requestBody: z.object({
            tags: z.array(z.unknown()),
        }),
        responseBody: z.unknown(),
        invalidates: ['GET /rfqs/:rfqId/snapshot/:snapshotId', 'GET /rfqs/:rfqId/snapshot', 'GET /rfqs/snapshots'],
    }),

    'GET /rfqs/:rfqId/snapshot/:snapshotId': endpoint({
        description: 'Fetch a snapshot',
        pathParams: z.object({
            rfqId: z.string(),
            snapshotId: z.string(),
        }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: SnapshotRuntype,
    }),

    'PATCH /rfqs/:rfqId/snapshot/:snapshotId': endpoint({
        description: 'Update a snapshot',
        pathParams: z.object({
            rfqId: z.string(),
            snapshotId: z.string(),
        }),
        queryParams: z.undefined(),
        requestBody: z.object({
            tags: z.array(SnapshotTagRuntype),
        }),
        responseBody: z.unknown(),
        invalidates: ['GET /rfqs/:rfqId/snapshot/:snapshotId', 'GET /rfqs/:rfqId/snapshot', 'GET /rfqs/snapshots'],
    }),
};

const rfqUpdateEndpoints = {
    'POST /rfqs/:rfqId/update-offers': endpoint({
        description: 'Updates all the offers in an RfQ',
        pathParams: z.object({ rfqId: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.object({ since: z.string() }),
        responseBody: z.object({ since: z.string() }),
        invalidates: ['GET /panels'],
    }),

    'GET /sourcing/progress/:rfqId': endpoint({
        description: 'Fetches the number of orders still being updated',
        pathParams: z.object({ rfqId: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: LoadingOffersProgressDTORuntype,
    }),

    'PATCH /rfqs/:rfqId': endpoint({
        description: 'PATCH a single RfQ',
        pathParams: z.object({ rfqId: z.string() }),
        queryParams: z.undefined(),
        requestBody: RfqUpdateRuntype,
        responseBody: z.object({}),
        invalidates: ['GET /rfqs', 'GET /rfqs/:rfqId', 'GET /order-management/order-selection-options'],
    }),

    'PATCH /rfqs/:rfqId/archive': endpoint({
        description: 'Archive or unarchive a single RfQ',
        pathParams: z.object({ rfqId: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({}),
        invalidates: ['GET /rfqs', 'GET /rfqs/:rfqId'],
    }),

    'PATCH /rfqs/:rfqId/unarchive': endpoint({
        description: 'Archive or unarchive a single RfQ',
        pathParams: z.object({ rfqId: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({}),
        invalidates: ['GET /rfqs', 'GET /rfqs/:rfqId'],
    }),

    'DELETE /rfqs/:rfqId': endpoint({
        description: 'Deletes a single RfQ',
        pathParams: z.object({ rfqId: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({
            deleted: z.number(),
        }),
        invalidates: ['GET /rfqs'],
        removes: ['GET /rfqs/:rfqId'],
    }),
};

const rfqContributorEndpoints = {
    'GET /rfqs/:rfqId/possible-contributors': endpoint({
        description: 'Returns a list of all possible contributors on a single RFQ',
        pathParams: z.object({ rfqId: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({ data: z.array(UserDTORuntype) }),
    }),

    'POST /rfqs/:rfqId/technical-support-request': endpoint({
        description: 'Requests technical support from the EMS',
        pathParams: z.object({ rfqId: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({}),
    }),
};

const rfqQuotationEndpoints = {
    'POST /rfqs/:rfqId/edit-quotation-request': endpoint({
        description: 'Requests to edit quotation from the EMS',
        pathParams: z.object({ rfqId: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({}),
    }),

    'PATCH /rfqs/:rfqId/request-quotation': endpoint({
        description: 'As a customer request a quotation from the EMS',
        pathParams: z.object({ rfqId: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({}),
        invalidates: ['GET /rfqs', 'GET /rfqs/:rfqId'],
    }),

    'POST /rfqs/:rfqId/customer-portal': endpoint({
        description:
            'Get the state of the customer portal as a customer. Potentially sets the RfQ status to QuotationAvailable when the workflow is automatic and the RfQ is ready for quotation.',
        pathParams: z.object({ rfqId: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: CustomerPortalStateRuntype,
        invalidates: ['GET /rfqs', 'GET /rfqs/:rfqId'],
    }),

    'GET /rfqs/:rfqId/quotation/warnings': endpoint({
        description: 'Get warnings indicating if it is okay for an EMS to update the RfQ status to QuotationAvailable',
        pathParams: z.object({ rfqId: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: QuotationWarningsRuntype,
    }),

    'GET /rfqs/status-history': endpoint({
        description: 'Get the status history of an RfQ',
        pathParams: z.object({ rfqId: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.array(RfqStatusHistoryRuntype),
    }),
};

const gatewayEndpoints = {
    'GET /gateway/test': endpoint({
        description: 'Gateway',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.unknown(),
    }),
};

// Define types for each endpoint group
type InnerRfqListEndpoints = typeof rfqListEndpoints;
type InnerRfqSnapshotEndpoints = typeof rfqSnapshotEndpoints;
type InnerRfqUpdateEndpoints = typeof rfqUpdateEndpoints;
type InnerRfqContributorEndpoints = typeof rfqContributorEndpoints;
type InnerRfqQuotationEndpoints = typeof rfqQuotationEndpoints;
type InnerGatewayEndpoints = typeof gatewayEndpoints;

// Create an interface that extends all endpoint types
interface RfqEndpointsRegistry
    extends InnerRfqListEndpoints,
        InnerRfqSnapshotEndpoints,
        InnerRfqUpdateEndpoints,
        InnerRfqContributorEndpoints,
        InnerRfqQuotationEndpoints,
        InnerGatewayEndpoints {}

// Merge all endpoint objects into a single object
export const rfqEndpoints: RfqEndpointsRegistry = {
    ...rfqListEndpoints,
    ...rfqSnapshotEndpoints,
    ...rfqUpdateEndpoints,
    ...rfqContributorEndpoints,
    ...rfqQuotationEndpoints,
    ...gatewayEndpoints,
};
