import { colorSystem, Flexbox } from '@luminovo/design-system';
import { Box, CircularProgress } from '@mui/material';
import { useCurrentUserDetailsContext } from '../../../components/contexts/CurrentUserDetailsContext';
import { PageLayout } from '../../../components/PageLayout';
import { useTopLevelAssemblies } from '../../../resources/assembly/assemblyHandler';
import { useCustomerPortalState, useRfQ } from '../../../resources/rfq/rfqHandler';
import { useIsRfqEditable } from '../../../utils/rfqUtils';
import { UrlParams } from '../../../utils/routes';
import { ToolbarOemDashboard } from '../components/ToolbarOemDashboard';
import { CustomerDashboardBanner } from './components/CustomerDashboardBanner';
import { CustomerDashboardRfqCard } from './components/CustomerDashboardRfqCard';
import { CustomerDashboardSteps } from './components/CustomerDashboardSteps';

export const CustomerDashboardPage = (props: UrlParams<'/rfqs/:rfqId/dashboard'>): JSX.Element => {
    const { rfqId } = props.pathParams;
    const { organization } = useCurrentUserDetailsContext();

    const { data: assemblies, isLoading } = useTopLevelAssemblies(rfqId);
    const { data: rfq } = useRfQ(rfqId);
    const { data: customerPortalState, isFetching: isFetchingCustomerPortalState } = useCustomerPortalState(rfqId);

    const { isRfqEditable } = useIsRfqEditable(rfq?.status, rfq?.is_archived, rfq?.workflow_type);

    if (!customerPortalState || isFetchingCustomerPortalState || !rfq || !assemblies || isLoading) {
        return (
            <PageLayout layout="centered">
                <CircularProgress />
            </PageLayout>
        );
    }

    // RfQs created by a customer always have a top-level assembly
    const firstAssemblyId = assemblies[0].id;

    return (
        <PageLayout layout="fragment" removeBottomPadding header={<ToolbarOemDashboard rfqId={rfqId} />}>
            <Box
                display={'grid'}
                style={{
                    alignItems: 'start',
                    padding: 32,
                    background: colorSystem.neutral[1],
                    flexGrow: 1,
                    gridTemplateColumns: 'minmax(auto, 800px) minmax(auto, 512px)',
                    justifyContent: 'center',
                }}
                columnGap="40px"
            >
                <Flexbox flexDirection={'column'} gap={24}>
                    <CustomerDashboardBanner
                        org={organization}
                        rfq={rfq}
                        assemblyId={firstAssemblyId}
                        customerPortalState={customerPortalState}
                    />

                    <CustomerDashboardSteps
                        customerPortalState={customerPortalState}
                        rfqId={rfqId}
                        isRfqEditable={isRfqEditable}
                    />
                </Flexbox>

                <CustomerDashboardRfqCard rfqId={rfqId} />
            </Box>
        </PageLayout>
    );
};
