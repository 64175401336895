import * as z from 'zod';
import { endpoint } from '../http/endpoint';
import {
    UserDTORuntype,
    UserInviteDTORuntype,
    UserInviteInsertDTORuntype,
    UserRolesDTORuntype,
    UserRolesUpdateDTORuntype,
    UserUpdateDTORuntype,
} from './userBackendTypes';

export const userEndpoints = {
    'GET /users/:userId': endpoint({
        description: 'Fetches a single user by ID',
        pathParams: z.object({ userId: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({ data: UserDTORuntype }),
    }),

    'DELETE /users/:userId': endpoint({
        description: 'Deletes a single user by ID',
        pathParams: z.object({ userId: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.unknown(),
    }),

    'PATCH /users/:userId': endpoint({
        description: 'Updates a single user by ID',
        pathParams: z.object({ userId: z.string() }),
        queryParams: z.undefined(),
        requestBody: UserUpdateDTORuntype,
        responseBody: z.object({ data: UserDTORuntype }),
    }),

    'GET /users/:userId/roles': endpoint({
        description: 'Fetches roles for a single user by ID',
        pathParams: z.object({ userId: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({ data: UserRolesDTORuntype }),
    }),

    'PATCH /users/:userId/roles': endpoint({
        description: 'Updates roles for a single user',
        pathParams: z.object({ userId: z.string() }),
        queryParams: z.undefined(),
        requestBody: UserRolesUpdateDTORuntype,
        responseBody: z.object({ data: UserRolesDTORuntype }),
    }),

    'POST /users/roles/bulk': endpoint({
        description: 'Fetches a list of user roles given their IDs',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.object({ ids: z.array(z.string()) }),
        responseBody: z.object({ data: z.array(UserRolesDTORuntype) }),
    }),

    'GET /users/organization': endpoint({
        description: 'Fetches all the EMS users in the given organization',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({ data: z.array(UserDTORuntype) }),
    }),

    'GET /users/organization/invites': endpoint({
        description: 'Gets all organization user invites',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({ data: z.array(UserInviteDTORuntype) }),
    }),

    'POST /users/organization/invites': endpoint({
        description: 'Sends many organization user invites',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.array(UserInviteInsertDTORuntype),
        responseBody: z.unknown(),
    }),

    'POST /users/organization/support-user': endpoint({
        description: 'Adds the support user to the organization',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.unknown(),
    }),

    'DELETE /users/organization/support-user': endpoint({
        description: 'Removes the support user to the organization',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.unknown(),
    }),

    'GET /users/customers/:customerId': endpoint({
        description: 'Fetches all the users of the given customer',
        pathParams: z.object({ customerId: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({ data: z.array(UserDTORuntype) }),
    }),

    'GET /users/customers/:customerId/invites': endpoint({
        description: 'Gets all user invites for a customer',
        pathParams: z.object({ customerId: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({ data: z.array(UserInviteDTORuntype) }),
    }),

    'POST /users/customers/:customerId/invites': endpoint({
        description: 'Sends many customer user invites',
        pathParams: z.object({ customerId: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.array(UserInviteInsertDTORuntype),
        responseBody: z.unknown(),
    }),

    'DELETE /users/invites/:inviteId': endpoint({
        description: 'Deletes a single user invitation by id',
        pathParams: z.object({ inviteId: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.unknown(),
    }),
};
