import * as z from 'zod';

import { Currency } from '../currencyBackendTypes';
import { ManufacturerDTORuntype, ManufacturerWithPartCountDTORuntype } from '../manufacturer';
import { UserDTORuntype } from '../user';

export enum SupplierOrigin {
    System = 'System',
    Erp = 'Erp',
    Manual = 'Manual',
}

export enum SupplierType {
    Distributor = 'Distributor',
    Manufacturer = 'Manufacturer',
    Ems = 'Ems',
}

export enum SupplierPartType {
    OffTheShelf = 'OffTheShelf',
    Custom = 'Custom',
    Pcb = 'Pcb',
    Assembly = 'Assembly',
}

export interface SupplierDTO extends z.infer<typeof SupplierDTORuntype> {}
export const SupplierDTORuntype = z.object({
    id: z.string(),
    name: z.string(),
    alternative_names: z.array(z.string()),
    origin: z.nativeEnum(SupplierOrigin),
    supplier_type: z.nativeEnum(SupplierType),
    supplier_number: z.string().nullable().optional(),
    supplier_part_type: z.nativeEnum(SupplierPartType),
    default_currency: z.nativeEnum(Currency).nullable(),
});

export interface LineCardModificationDTO extends z.infer<typeof LineCardModificationDTORuntype> {}
export const LineCardModificationDTORuntype = z.object({
    user: UserDTORuntype.pick({ id: true, first_name: true, last_name: true, email: true }).optional(),
    manufacturer: ManufacturerDTORuntype,
    action: z.enum(['Add', 'Remove']),
    updated_at: z.string(),
    created_at: z.string(),
});

export type SupplierLineCardItemSourceDTO = z.infer<typeof SupplierLineCardItemSourceRuntype>;
export const SupplierLineCardItemSourceRuntype = z.union([
    z.object({
        type: z.literal('System'),
    }),
    z.object({
        type: z.literal('Tenant'),
        value: UserDTORuntype.pick({ id: true, first_name: true, last_name: true, email: true }).nullable(),
    }),
]);

export type SupplierLineCardManufacturerDTO = z.infer<typeof SupplierLineCardManufacturerRuntype>;
export const SupplierLineCardManufacturerRuntype = ManufacturerWithPartCountDTORuntype.extend({
    source: SupplierLineCardItemSourceRuntype,
});

export interface SupplierLineCardDTO extends z.infer<typeof SupplierLineCardDTORuntype> {}
export const SupplierLineCardDTORuntype = z.object({
    supplier: z.string(),
    manufacturers: z.array(SupplierLineCardManufacturerRuntype),
    last_updated_at: z.string().nullable(),
    last_updated_by: SupplierLineCardItemSourceRuntype.nullable(),
});

export const SupplierLineCardPatchDTORuntype = z.object({
    manufacturers: z.array(z.string()),
});

export const SupplierLineCardRulesImportDTORunType = z.object({
    items: z.array(
        z.object({
            supplier_id: z.string(),
            manufacturer_id: z.string(),
        }),
    ),
});

export type SupplierLineCardRuleImportResponseDTO = z.infer<typeof SupplierLineCardRuleImportResponseRuntype>;
export const SupplierLineCardRuleImportResponseRuntype = z.object({
    items: z.array(
        z.object({
            status: z.number(),
            message: z.string(),
            data: z.union([
                SupplierLineCardDTORuntype,
                z.object({
                    supplier_id: z.string(),
                    manufacturer_id: z.string(),
                }),
            ]),
        }),
    ),
});
