import * as z from 'zod';
import { ConfigurationsNotesPatchRuntype, ConfigurationsPatchRuntype } from '../activityConfiguration';
import { ExpenseConfigurationRuntype, ExpenseConfigurationsPostRuntype } from '../expenses';
import { endpoint } from '../http/endpoint';

export const expenseConfigurationEndpoints = {
    'PATCH /expense-configurations/:expenseConfigurationId': endpoint({
        description: 'Edit expense configuration. If driver_count is null the count is reset.',
        pathParams: z.object({ expenseConfigurationId: z.string() }),
        queryParams: z.object({
            apply_to_all_activities_and_expenses: z.boolean(),
        }),
        requestBody: ConfigurationsPatchRuntype,
        responseBody: z.object({
            data: ExpenseConfigurationRuntype,
        }),
        invalidates: ['GET /manufacturing-scenarios/:manufacturingScenarioId/full-summary'],
    }),
    'POST /expense-configurations/bulk': endpoint({
        description: 'Add expenses to a manufacturing scenario',
        pathParams: z.object({}),
        queryParams: z.object({}),
        requestBody: ExpenseConfigurationsPostRuntype,
        responseBody: z.object({
            data: z.array(ExpenseConfigurationRuntype),
        }),
        invalidates: ['GET /manufacturing-scenarios'],
    }),
    'PATCH /expense-configurations/:expenseConfigurationId/notes': endpoint({
        description: 'Edits the notes only of a specific expense configuration',
        pathParams: z.object({ expenseConfigurationId: z.string() }),
        queryParams: z.object({}),
        requestBody: ConfigurationsNotesPatchRuntype,
        responseBody: z.object({
            data: ExpenseConfigurationRuntype,
        }),
        invalidates: ['GET /manufacturing-scenarios/:manufacturingScenarioId/full-summary'],
    }),

    'DELETE /expense-configurations/bulk': endpoint({
        description: 'Deletes expense configurations from a manufacturing scenario',
        pathParams: z.undefined(),
        queryParams: z.object({
            ids: z.string(),
        }),
        requestBody: z.undefined(),
        responseBody: z.object({ deleted: z.number() }),
    }),

    'PATCH /expense-configurations/:expenseConfigurationId/rank': endpoint({
        description: 'Updates the rank of an expense configuration',
        pathParams: z.object({ expenseConfigurationId: z.string() }),
        queryParams: z.object({}),
        requestBody: z.object({ rank: z.number() }),
        responseBody: z.object({
            data: ExpenseConfigurationRuntype,
        }),
        invalidates: ['GET /manufacturing-scenarios/:manufacturingScenarioId/full-summary'],
    }),
};
