import { DialogActions as MuiDialogActions, DialogActionsProps as MuiDialogActionsProps, styled } from '@mui/material';

interface DialogActionsProps extends MuiDialogActionsProps {
    sticky?: boolean;
}

const StyledDialogActions = styled(MuiDialogActions)(({ theme }) => ({
    borderTop: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
    position: 'sticky',
    bottom: 0,
}));

export function DialogActions({ sticky = false, ...props }: DialogActionsProps): JSX.Element {
    if (sticky) {
        return <StyledDialogActions {...props} />;
    }

    return <MuiDialogActions {...props} />;
}
