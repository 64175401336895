import { FormContainer } from '@/components/formLayouts/FormContainer';
import { getCustomerPortalTenant } from '@/permissions/tenants';
import { useQueryParams } from '@/resources/hooks';
import { analytics } from '@/utils/analytics';
import { t, Trans } from '@lingui/macro';
import { ConnectionType, useAuth } from '@luminovo/auth';
import { id, throwErrorUnlessProduction } from '@luminovo/commons';
import { colorSystem, FieldTextControlled, PrimaryButton, Text } from '@luminovo/design-system';
import { Box, darken, lighten, ThemeProvider, useTheme } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

type FormValues = {
    email: string;
};

const logo = '/logo.png';

export function PageEnterEmail() {
    const { redirectTo } = useQueryParams<'/auth/enter-email'>();
    const { loginWithRedirect, email } = useAuth({
        auth0OrgId: getCustomerPortalTenant()?.auth0OrgId,
    });

    // Redirect from luminovo.ai to luminovo.com
    useEffect(() => {
        const currentOrigin = window.location.origin;
        // dev domains are not redirected
        if (currentOrigin.endsWith('luminovo.ai') && !currentOrigin.endsWith('.dev.luminovo.ai')) {
            const path = window.location.href.replace('luminovo.ai', 'luminovo.com');
            window.location.href = path;
        }

        analytics.track(id('auth/email_form_rendered'));
    }, []);

    const defaultValues: FormValues = { email };
    const { mutateAsync, isPending } = useMutation({
        mutationFn: async ({ email }: FormValues) => {
            analytics.track(id('auth/email_form_submitted'));
            const shouldUsePassword = await fetch('/login-flow?email=' + encodeURIComponent(email))
                .then((res) => res.json())
                .then((res) => res.flow === 'password')
                .catch((err) => {
                    analytics.track(id('auth/login_hint_error'), { error: err.message });
                    throwErrorUnlessProduction(err);
                    return true;
                });

            const connectionType = shouldUsePassword ? ConnectionType.password : ConnectionType.passwordless;

            await loginWithRedirect({
                email,
                returnTo: redirectTo ?? undefined,
                connectionType,
            });
            analytics.track(id('auth/login_with_redirect_success'), {
                connectionType,
            });
        },
    });

    return (
        <FormContainer
            defaultValues={defaultValues}
            onSubmit={(x) => {
                return mutateAsync(x);
            }}
            reValidateMode="onSubmit"
        >
            <PageEnterEmailForm isPending={isPending} />
        </FormContainer>
    );
}

function PageEnterEmailForm({ isPending }: { isPending: boolean }) {
    const { control } = useFormContext<FormValues>();

    const theme = getCustomerPortalTenant()?.theme;

    const expandedTheme = theme
        ? {
              primaryColor: `${theme.primaryColor} !important`,
              buttonBackgroundColor: `${theme.primaryColor} !important`,
              buttonBackgroundColorHover: `${darken(theme.primaryColor, 0.1)} !important`,
              buttonBackgroundColorActive: `${darken(theme.primaryColor, 0.2)} !important`,
              buttonBorderColor: `${darken(theme.primaryColor, 0.1)} !important`,
              outlineColor: `${lighten(theme.primaryColor, 0.3)} !important`,
          }
        : undefined;

    const currentTheme = useTheme();

    return (
        <ThemeProvider
            theme={{
                ...currentTheme,
                palette: {
                    ...currentTheme.palette,
                    primary: {
                        main: theme?.primaryColor ?? currentTheme.palette.primary.main,
                        light: theme?.primaryColor ?? currentTheme.palette.primary.light,
                        dark: theme?.primaryColor ?? currentTheme.palette.primary.dark,
                    },
                },
            }}
        >
            <Box
                sx={{
                    display: 'grid',
                    placeItems: 'center',
                    width: '100vw',
                    minHeight: '100vh',
                    backgroundColor: theme?.pageBackgroundColor ?? '#F7F8FA',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        paddingX: 5,
                        paddingY: 8,
                        paddingBottom: 12,
                        boxSizing: 'border-box',
                        gap: 2,
                        width: 400,
                        // minHeight: 540,
                        border: `1px solid ${colorSystem.neutral[3]}`,
                        backgroundColor: `${colorSystem.neutral.white}`,
                        borderRadius: 1,
                    }}
                >
                    <Box
                        sx={{
                            maxWidth: '100%',
                            maxHeight: 65,
                            '& img': {
                                width: '100%',
                                height: '100%',
                                objectFit: 'contain',
                            },
                        }}
                        component="img"
                        src={theme?.logoUrl ?? logo}
                        alt="Luminovo Logo"
                    />
                    <Text variant="h1">
                        <Trans>Welcome</Trans>
                    </Text>
                    <Text variant="body">
                        <Trans>Enter your email to continue</Trans>
                    </Text>

                    <FieldTextControlled
                        control={control}
                        name="email"
                        validate={(x) => {
                            if (typeof x !== 'string') {
                                return t`Email is required`;
                            }
                            // Super light validation. The goal is just to catch the most obvious mistakes. Follows the principle:
                            // Be lenient in what you accept, strict in what you send.
                            if (!x.includes('@')) {
                                return t`Invalid email address`;
                            }
                            return undefined;
                        }}
                        FieldProps={{
                            label: t`Email address`,
                            size: 'large',
                            fullWidth: true,
                            autoFocus: true,
                            sx: {
                                '&:hover fieldset, &:focus-within fieldset': {
                                    borderColor: expandedTheme?.primaryColor,
                                },
                            },
                            onKeyDown: (e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                    const submitButton = document.querySelector('button[type="submit"]');
                                    if (submitButton instanceof HTMLButtonElement) {
                                        submitButton.click();
                                    }
                                }
                            },
                        }}
                    />
                    <PrimaryButton
                        disabled={isPending}
                        sx={{
                            backgroundColor: expandedTheme?.buttonBackgroundColor,
                            borderColor: expandedTheme?.buttonBorderColor,
                            '&:hover': {
                                backgroundColor: expandedTheme?.buttonBackgroundColorHover,
                            },
                            '&:active': {
                                backgroundColor: expandedTheme?.buttonBackgroundColorActive,
                            },
                        }}
                        fullWidth
                        type="submit"
                        size="large"
                    >
                        <Trans>Continue</Trans>
                    </PrimaryButton>
                </Box>
            </Box>
        </ThemeProvider>
    );
}
