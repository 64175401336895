import * as z from 'zod';

import { CurrencyRuntype } from '../backendTypes';
import { PackagingRuntype, PriceTypeRuntype } from '../offer';
import { PreferenceStatusEnumRuntype } from '../part';
import {
    InventoryPreferenceRuntype,
    OfferAgeLimitDTORuntype,
} from '../sourcingScenario/solutionConfigurationBackendTypes';

const BackendExchangeRatesDTORuntype = z.null().or(z.record(z.string(), z.string()));

export interface GlobalCurrencySettingsDTO extends z.infer<typeof GlobalCurrencySettingsDTORuntype> {}
export const GlobalCurrencySettingsDTORuntype = z.object({
    currency: CurrencyRuntype,
    exchange_rates: BackendExchangeRatesDTORuntype,
});

export interface PublicExchangeRateSummaryDTO extends z.infer<typeof PublicExchangeRateSummaryDTORuntype> {}
export const PublicExchangeRateSummaryDTORuntype = z.object({
    last_updated: z.string().nullable(),
    base_currency: z.string(),
    rates: z.object({
        base: z.string(),
        rates: z.record(z.string(), z.string()),
    }),
});

export interface OrganizationSolutionPreferenceDTO extends z.infer<typeof OrganizationSolutionPreferenceDTORuntype> {}
export const OrganizationSolutionPreferenceDTORuntype = z.object({
    preferred_suppliers_and_stock_locations: z.array(z.string()).nullable(),
    approved_suppliers_and_stock_locations: z.array(z.string()).nullable(),
    excluded_suppliers_and_stock_locations: z.array(z.string()).nullable(),
    preferred_inventory_sites: z.array(z.string()).nullable(),
    approved_inventory_sites: z.array(z.string()).nullable(),
    excluded_inventory_sites: z.array(z.string()).nullable(),
    inventory_preference: InventoryPreferenceRuntype,
    use_unit_price: z.boolean(),
    packaging_preference: z.array(PackagingRuntype),
    prefer_direct_api_integrations: z.boolean(),
    exclude_mismatched_price_points: z.boolean(),
    prioritize_preferred_manufacturers: z.boolean(),
    offer_age_limit: OfferAgeLimitDTORuntype,
    excluded_price_types: z.array(PriceTypeRuntype),
    prefer_customer_negotiated_prices: z.boolean(),
    is_tco_enabled: z.boolean(),
});

export enum StepEnum {
    required = 'required',
    optional = 'optional',
    dontShow = 'dont_show',
}

export const StepEnumRuntype = z.nativeEnum(StepEnum);

export const ManualRfqNumberRuntype = z.object({
    type: z.literal('Manual'),
});

export const AutomaticRfqNumberDataRuntype = z.object({
    start: z.number(),
    end: z.number(),
    current: z.number(),
    prefix: z.string().nullable(),
});
export const AutomaticRfqNumberRuntype = z.object({
    type: z.literal('Automatic'),
    data: AutomaticRfqNumberDataRuntype,
});
export const PatchAutomaticRfqNumberRuntype = z.object({
    type: z.literal('Automatic'),
    data: AutomaticRfqNumberDataRuntype.omit({ current: true }),
});

export const AutomaticRfqNumberSettingsRuntype = ManualRfqNumberRuntype.or(AutomaticRfqNumberRuntype);
export const PatchAutomaticRfqNumberSettingsRuntype = ManualRfqNumberRuntype.or(PatchAutomaticRfqNumberRuntype);

export type PatchAutomaticRfqNumberSettings = z.infer<typeof PatchAutomaticRfqNumberSettingsRuntype>;

export enum WarningStatusEnum {
    warning = 'warning',
    noWarning = 'no_warning',
}

export const WarningStatusRuntype = z.nativeEnum(WarningStatusEnum);

export interface BomWarningsConfiguration extends z.infer<typeof BomWarningsConfigurationRuntype> {}

export const BomWarningsConfigurationRuntype = z.object({
    availability: WarningStatusRuntype,
    lifecycle: WarningStatusRuntype,
    compliance: WarningStatusRuntype,
    part_data: WarningStatusRuntype,
});

export const SelfSignupColorsRuntype = z.object({
    primary_color: z.string(),
    primary_dark_color: z.string(),
    background_color: z.string(),
});

export const SelfSignupSettingsRuntype = z.object({
    is_self_signup_enabled: z.boolean(),
    is_customer_user_management_enabled: z.boolean(),
    are_private_customers_allowed: z.boolean(),
    default_contact_person: z.string().nullable(),
    organization_logo: z.string().nullable(),
    colors: SelfSignupColorsRuntype,
});

export type SelfSignupSettingsDTO = z.infer<typeof SelfSignupSettingsRuntype>;

export const PatchSelfSignupSettingsRuntype = z.object({
    default_contact_person: z.string().nullable().optional(),
    is_self_signup_enabled: z.boolean().optional(),
    are_private_customers_allowed: z.boolean().optional(),
    is_customer_user_management_enabled: z.boolean().optional(),
    organization_logo: z.string().nullable().optional(),
    colors: SelfSignupColorsRuntype.optional(),
});

export const ManufacturerPreferenceInputRuntype = z.object({
    part_category_id: z.number(),
    manufacturers: z.array(z.string()),
    preference_status: PreferenceStatusEnumRuntype,
});

export const ManufacturerPreferenceUpdateRuntype = z.object({
    manufacturers: z.array(z.string()),
    preference_status: PreferenceStatusEnumRuntype,
});

export const ManufacturerPreferenceDTORuntype = z.object({
    part_category: z.object({
        id: z.number(),
        name: z.string(),
    }),
    manufacturers: z.array(
        z.object({
            id: z.string(),
            name: z.string(),
        }),
    ),
    preference_status: PreferenceStatusEnumRuntype,
});

export const CalculationWithoutManufacturingSettingsRuntype = z.object({
    calculation_without_manufacturing_enabled: z.boolean(),
});
