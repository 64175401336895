import * as z from 'zod';
import { OtsComponentFullRuntype } from '../internalPartNumber';
import {
    GenericFullPartRuntype,
    OtsFullPartRuntype,
    PartSuggestionOriginRuntype,
    TechnicalParametersRuntype,
} from '../part';

const OtsComponentFullWithBetterPropertiesRuntype = OtsComponentFullRuntype.extend({
    better_technical_properties: z.array(z.string()),
});

export type PartSuggestionFull = z.infer<typeof PartSuggestionFullRuntype>;
export const PartSuggestionFullRuntype = z.union([
    z.object({
        type: z.literal('Generic'),
        part: GenericFullPartRuntype,
        origin: PartSuggestionOriginRuntype.nullable().optional(),
    }),
    z.object({
        type: z.literal('OffTheShelf'),
        part: OtsFullPartRuntype,
        origin: PartSuggestionOriginRuntype.nullable().optional(),
    }),
    z.object({
        type: z.literal('Component'),
        part: OtsComponentFullWithBetterPropertiesRuntype,
        origin: PartSuggestionOriginRuntype.nullable().optional(),
    }),
    z.object({
        type: z.literal('IncompleteGeneric'),
        part: TechnicalParametersRuntype,
        origin: PartSuggestionOriginRuntype.nullable().optional(),
    }),
]);
