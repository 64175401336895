import * as z from 'zod';
import { ApiIntegrationStatusRuntype } from './3rdPartyBackendTypes';

export type TiLocaleCurrencyDTO = z.infer<typeof TiLocaleCurrencyDTORuntype>;
export const TiLocaleCurrencyDTORuntype = z.enum(['USD', 'EUR', 'GBP', 'JPY', 'AUD', 'CNY', 'CHF']);

export interface TiCredentialsDTO extends z.infer<typeof TiCredentialsDTORuntype> {}
export const TiCredentialsDTORuntype = z.object({
    client_id: z.string(),
    client_secret: z.string(),
    currency: TiLocaleCurrencyDTORuntype,
});

export interface TiCredentialsInputDTO extends z.infer<typeof TiCredentialsInputDTORuntype> {}
export const TiCredentialsInputDTORuntype = z.object({
    client_id: z.string().optional(),
    client_secret: z.string().optional(),
    currency: TiLocaleCurrencyDTORuntype.optional(),
});

export interface TiResponseBodyDTO extends z.infer<typeof TiResponseBodyDTORuntype> {}
export const TiResponseBodyDTORuntype = z.object({
    data: TiCredentialsDTORuntype.nullable(),
    status: ApiIntegrationStatusRuntype,
});
