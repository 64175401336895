import * as z from 'zod';
import { endpoint } from '../http/endpoint';
import {
    MasterElectronicsCredentialsInputDTORuntype,
    MasterElectronicsResponseBodyDTORuntype,
} from './masterElectronicsBackendTypes';

export const masterElectronicsEndpoints = {
    'GET /3rdparty/master/credentials': endpoint({
        description: 'Returns credentials for Master Online integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: MasterElectronicsResponseBodyDTORuntype,
    }),
    'POST /3rdparty/master/credentials': endpoint({
        description: 'Uploads credentials for Master Online API integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: MasterElectronicsCredentialsInputDTORuntype,
        responseBody: MasterElectronicsResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/master/credentials'],
    }),
    'PATCH /3rdparty/master/credentials': endpoint({
        description: 'Updates credentials for Master Online API integratoin',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: MasterElectronicsCredentialsInputDTORuntype,
        responseBody: MasterElectronicsResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/master/credentials'],
    }),
    'DELETE /3rdparty/master/credentials': endpoint({
        description: 'Deletes existing credentials for Master Online API integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({ deleted: z.number() }),
        invalidates: ['GET /3rdparty/master/credentials'],
    }),
};
