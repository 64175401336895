import { Trans } from '@lingui/macro';
import { Flexbox, TertiaryButton, TertiaryIconButton, Text, Tooltip } from '@luminovo/design-system';
import { Add, Edit } from '@mui/icons-material';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useImportedAssemblyNotEditableTooltipText } from '../../../../../../components/AssemblyMenuButton';
import { ViewContext } from '../../../../../Bom/components/ModuleTableData';
import { BomItemFormState } from '../../../SpecificationTypeForms/BomItemFormState';
import { useRfqNotEditableTooltip } from '../../../ToolbarBomEditPage';
import { EditNotes } from './EditNotes';

export const Notes = ({
    isRfqEditable,
    submitForm,
    viewContext,
}: {
    isRfqEditable: boolean;
    submitForm: () => void;
    viewContext: ViewContext;
}) => {
    const rfqNotEditableTooltip = useRfqNotEditableTooltip();
    const { watch } = useFormContext<BomItemFormState>();
    const notes = watch('notes');
    const hasNotes = notes.length > 0;
    const [isEditingNotes, setIsEditingNotes] = useState(false);

    const importedAssemblyDisabledTooltip = useImportedAssemblyNotEditableTooltipText();

    // when the rfqId is not defined, it means the user is looking at an imported assembly which at the moment are not linked to an rfq.
    const toolTip = viewContext.rfqId ? rfqNotEditableTooltip : importedAssemblyDisabledTooltip;

    if (isEditingNotes && isRfqEditable) {
        return <EditNotes setIsEditingNotes={setIsEditingNotes} submitForm={submitForm} />;
    }

    if (!hasNotes) {
        return (
            <Flexbox gap="8px">
                <Tooltip title={!isRfqEditable ? toolTip : ''}>
                    <span style={{ lineHeight: '16px' }}>
                        <TertiaryButton
                            startIcon={<Add />}
                            size="small"
                            disabled={!isRfqEditable}
                            onClick={() => setIsEditingNotes(true)}
                        >
                            <Trans>Add notes</Trans>
                        </TertiaryButton>
                    </span>
                </Tooltip>
            </Flexbox>
        );
    }

    return (
        <Flexbox flexDirection="column" gap="8px" width="100%">
            <Flexbox gap="8px">
                <Text variant="h5">
                    <Trans>Notes</Trans>
                </Text>
                <Tooltip title={!isRfqEditable ? toolTip : ''}>
                    <span style={{ lineHeight: '16px' }}>
                        <TertiaryIconButton
                            size="medium"
                            disabled={!isRfqEditable}
                            onClick={() => setIsEditingNotes(true)}
                            style={{ padding: 0, height: 'unset', width: 'unset' }}
                        >
                            <Edit fontSize="inherit" />
                        </TertiaryIconButton>
                    </span>
                </Tooltip>
            </Flexbox>
            <Text variant="body-small" showEllipsis>
                {notes}
            </Text>
        </Flexbox>
    );
};
