import { createIdentityImportHandlers, UniversalImporter } from '@luminovo/universal-importer';

import { t } from '@lingui/macro';
import { isPresent, throwErrorUnlessProduction } from '@luminovo/commons';
import {
    parseRuntype,
    SupplierAndStockLocationDTO,
    SupplierLineCardDTO,
    SupplierLineCardDTORuntype,
    SupplierLineCardRuleImportResponseDTO,
    SupplierTag,
} from '@luminovo/http-client';
import { ImportStatus } from '@luminovo/universal-importer/src/types';
import { useSnackbar } from 'notistack';
import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { useHistory } from 'react-router';
import { useHttpQuery } from '../../../resources/http/useHttpQuery';
import { useManufacturers } from '../../../resources/manufacturer/manufacturerHandler';
import { useHttpMutation } from '../../../resources/mutation/useHttpMutation';
import { useSupplierAndStockLocations } from '../../../resources/supplierAndStockLocation/supplierAndStockLocationHandler';
import { route, UrlParams } from '../../../utils/routes';

function toParsedSupplierValue(supplier: SupplierAndStockLocationDTO) {
    return {
        id: supplier.supplier_number ?? '',
        label: String(supplier.supplier_number),
        description: supplier.supplier.name,
        existing: false,
        isSystem: supplier.tags.some((s) => s.tag === SupplierTag.System),
    };
}

// This function transforms the manufacturer name to lowercase and removes all non-alphanumeric characters
function cleanManufacturerName(name: string): string {
    return name.toLowerCase().replace(/[^a-z0-9]/g, '');
}

// This function generates all possible keys for the response
function generateKey(value: SupplierLineCardRuleImportResponseDTO['items'][number]['data']): string[] {
    if (parseRuntype(SupplierLineCardDTORuntype, value)) {
        return value.manufacturers.map((man) => `${value.supplier};${man.id}`);
    }
    return [`${value.supplier_id};${value.manufacturer_id}`];
}

function transformResponseToImportStatusRecord(
    response: SupplierLineCardRuleImportResponseDTO,
): Record<string, ImportStatus> {
    return response.items.reduce(
        (acc, item) => {
            const keys = generateKey(item.data);
            keys.forEach((key) => {
                if (item.status < 300) {
                    acc[key] = { success: true as const };
                } else {
                    acc[key] = { success: false as const, message: item.message };
                }
            });
            return acc;
        },
        {} as Record<string, ImportStatus>,
    );
}

export function BulkApprovedVendorListImporter(params: UrlParams<'/suppliers/line-card-rules/importer'>) {
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const { data: supplierAndStockLocations = [] } = useSupplierAndStockLocations();
    const { data: allManufacturers } = useManufacturers();
    const { data: supplierLineCardRules = [] } = useHttpQuery('GET /suppliers/line-card-rules', {});

    /* eslint-disable-next-line spellcheck/spell-checker */
    const { mutateAsync: importSupplierLineCardRule } = useHttpMutation('POST /suppliers/line-card-rules/import', {
        snackbarMessage: null,
        disableOnError: true,
    });

    const { manufacturerMap, manufacturerAlternatives } = React.useMemo(() => {
        const manufacturerMap = allManufacturers
            .flatMap((man) => {
                const preferredName = man.name;
                const alternativeNames = man.alternative_names;
                return [preferredName, ...alternativeNames].map((name) => ({
                    id: man.id,
                    label: name,
                    value: cleanManufacturerName(name),
                    description: `${man.part_count} part options`,
                    partsCount: man.part_count,
                }));
            })
            .reduce(
                (acc, man) => {
                    acc.set(man.value, man);
                    return acc;
                },
                new Map<
                    string,
                    {
                        id: string;
                        label: string;
                        value: string;
                        description: string;
                        partsCount: number;
                    }
                >(),
            );

        // Create a list of all manufacturer name
        const manufacturerAlternatives = allManufacturers.map((man) => {
            return {
                id: man.id,
                label: man.name,
                value: cleanManufacturerName(man.name),
                description: `${man.part_count} part options`,
                partsCount: man.part_count,
            };
        });
        return { manufacturerMap, manufacturerAlternatives };
    }, [allManufacturers]);

    const supplierLineCardMap = React.useMemo(() => {
        return supplierLineCardRules.reduce(
            (acc, item) => {
                const keys = generateKey(item);
                keys.forEach((key) => {
                    acc[key] = item;
                });
                return acc;
            },
            {} as Record<string, SupplierLineCardDTO>,
        );
    }, [supplierLineCardRules]);

    const supplierNumberToSupplierId = React.useMemo(() => {
        return supplierAndStockLocations.reduce(
            (acc, sasl) => {
                if (isPresent(sasl.supplier_number)) {
                    acc[sasl.supplier_number] = sasl.supplier.id;
                }
                return acc;
            },
            {} as Record<string, string>,
        );
    }, [supplierAndStockLocations]);

    // In order to avoid the function to be instantiated on every render, we use useCallback
    const transformRecordAction = React.useCallback(
        (row: {
            action: 'insert' | 'skipped' | 'update';
            data: { supplier_number: string; manufacturer_id: string };
        }) => {
            // Get ths supplierId from the supplier number
            const supplierId = supplierNumberToSupplierId[row.data.supplier_number] ?? null;
            if (!isPresent(supplierId)) {
                return row.action;
            }

            // transform row to an item that can be used to generate the key
            const keyItem = {
                supplier_id: supplierId,
                manufacturer_id: row.data.manufacturer_id,
            };
            // Generate the key
            const keys = generateKey(keyItem);
            if (keys.length !== 1) {
                throwErrorUnlessProduction(new Error('Expected 1 key'));
            }

            // Get the supplier line card from the map
            const supplierLineCard = supplierLineCardMap[keys[0]];

            if (isPresent(supplierLineCard)) {
                // If the supplier line card exists, we need to skip it
                return 'skipped';
            }
            // If the supplier line card does not exist, we need to insert it
            return 'insert';
        },
        [supplierNumberToSupplierId, supplierLineCardMap],
    );

    return (
        <UniversalImporter
            batchSize={500}
            title={t`Import approved vendor list`}
            transformRecordAction={transformRecordAction}
            hrefBack={route('/suppliers')}
            onImportDone={() => {
                enqueueSnackbar(t`Approved vendor list imported successfully`, {
                    variant: 'success',
                    anchorOrigin: { vertical: 'top', horizontal: 'center' },
                });
                history.push(route('/suppliers'));
            }}
            createImportHandlers={createIdentityImportHandlers(async (batch) => {
                const items = batch
                    .map((row) => {
                        const supplierId = supplierNumberToSupplierId[row.data.supplier_number] ?? null;
                        return {
                            supplier_id: supplierId,
                            manufacturer_id: row.data.manufacturer_id,
                        };
                    })
                    .filter((item) => isPresent(item.supplier_id));

                // This should always be the same length as the items
                // This is to ensure that we get the import status in order of the items
                const itemKeys = items.flatMap(generateKey);

                if (itemKeys.length !== items.length) {
                    throwErrorUnlessProduction(new Error('Item import keys length does not match items length'));
                }

                const response = await importSupplierLineCardRule({
                    requestBody: {
                        items,
                    },
                });

                const importStatusRecord = transformResponseToImportStatusRecord(response);

                // This ensures that it we get the import status in order of the items
                return itemKeys.map((key) => {
                    const importStatus = importStatusRecord[key];
                    if (isPresent(importStatus)) {
                        return importStatus;
                    }
                    return {
                        success: false,
                        message: t`Failed to insert/update supplier line card rule`,
                    };
                });
            })}
            config={{
                fields: [
                    {
                        id: 'supplier_number' as const,
                        label: t`Supplier number`,
                        description: t`The unique identifier of the supplier`,
                        required: true,
                        columnIndices: [],
                        parser: {
                            type: 'supplier.number',
                            options: {
                                suppliers: supplierAndStockLocations
                                    .filter((sasl) => isPresent(sasl.supplier_number))
                                    .map(toParsedSupplierValue),
                                errorOnUnknown: true,
                                warnOnSystemSupplier: false,
                            },
                        },
                    },
                    {
                        id: 'manufacturer_id' as const,
                        label: t`Manufacturer`,
                        description: t`The manufacturer of the product`,
                        required: true,
                        columnIndices: [],
                        parser: {
                            type: 'manufacturer',
                            options: {
                                manufacturerMap,
                                alternatives: manufacturerAlternatives,
                                useOnlyAlphanumeric: true,
                                transformToLowerCase: true,
                                errorOnUnknown: true,
                                highQualityPartsCountThreshold: 50,
                            },
                        },
                    },
                ],
            }}
        />
    );
}
