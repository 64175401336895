import { assertUnreachable } from '@luminovo/commons';
import { CostResponseDTO, ScenarioCostDTO } from '@luminovo/http-client';
import React from 'react';
import { PageLayout } from '../../../components/PageLayout';
import { SpinnerWithBackdrop } from '../../../components/Spinners';
import { useHttpQuery } from '../../../resources/http/useHttpQuery';
import { UrlParams } from '../../../utils/routes';
import { CalculationAssemblyDetailsSwitch } from '../CalculationAssemblyHeader/CalculationAssemblyDetails';
import { useCalculationAssemblyDetails } from '../useCalculationAssemblyDetails';
import { CalculationStatus } from './CalculationApprovalSwitcher';
import { CalculationTableFormWrapper } from './CalculationTable';

const getScenarioCost = (costResponse: CostResponseDTO): ScenarioCostDTO[] => {
    const type = costResponse.type;
    switch (type) {
        case 'Calculated':
            return costResponse.value.costs;
        case 'Frozen':
            return costResponse.value.frozen_cost.costs;
        default:
            assertUnreachable(type);
    }
};

const getCostTime = (costResponse: CostResponseDTO) => {
    const type = costResponse.type;
    switch (type) {
        case 'Calculated':
            return costResponse.value.updated_at;
        case 'Frozen':
            return costResponse.value.created_at;
        default:
            assertUnreachable(type);
    }
};

const getCostStatus = (costResponse: CostResponseDTO): CalculationStatus => {
    const type = costResponse.type;
    switch (type) {
        case 'Calculated':
            return {
                type: 'Calculated',
                calculationAssemblyCostId: costResponse.value.id,
            };
        case 'Frozen':
            return {
                type: 'Frozen',
                frozenId: costResponse.value.id,
            };
        default:
            assertUnreachable(type);
    }
};

export const AssemblyCalculationPage = (
    props: UrlParams<'/rfqs/:rfqId/calculation/:assemblyId/calculation'>,
): JSX.Element => {
    const { rfqId, assemblyId } = props.pathParams;
    const [isDialogOpen, setIsDialogOpen] = React.useState(false);
    const { calculationAssemblyDetails, assemblyDetails } = useCalculationAssemblyDetails(assemblyId);
    const { data: rfqData } = useHttpQuery('GET /rfqs/:rfqId', { pathParams: { rfqId } });
    const { data: costsData, isFetching } = useHttpQuery('GET /calculations/calculation-assembly-costs', {
        queryParams: { assembly_id: assemblyId },
    });

    if (calculationAssemblyDetails && assemblyDetails && costsData?.data && costsData?.data[0] && rfqData?.data) {
        return (
            <>
                <CalculationAssemblyDetailsSwitch
                    isDialogOpen={isDialogOpen}
                    calculationAssemblyDetails={calculationAssemblyDetails}
                    setIsDialogOpen={setIsDialogOpen}
                    rfqId={rfqId}
                    assemblyId={assemblyId}
                />
                <CalculationTableFormWrapper
                    setIsDialogOpen={setIsDialogOpen}
                    calculationAssemblyDetails={calculationAssemblyDetails}
                    calculationAssemblyCostId={costsData.data[0].value.id}
                    assemblyDetails={assemblyDetails}
                    rfqId={rfqId}
                    rfqCurrency={rfqData.data.currency}
                    scenarioCost={getScenarioCost(costsData.data[0])}
                    costsUpdatedAt={getCostTime(costsData.data[0])}
                    calculationStatus={getCostStatus(costsData.data[0])}
                    isFetching={isFetching}
                />
            </>
        );
    }

    return (
        <PageLayout>
            <SpinnerWithBackdrop />
        </PageLayout>
    );
};
