import { t, Trans } from '@lingui/macro';
import {
    colorSystem,
    Dialog,
    DialogContent,
    DialogTitle,
    FieldTextControlled,
    Flexbox,
    Text,
} from '@luminovo/design-system';
import { useFormContext } from 'react-hook-form';
import { useDialogContext } from '../../../../components/contexts/ModalContext';
import { FormContainer } from '../../../../components/formLayouts/FormContainer';
import { SubmitButton } from '../../../../components/formLayouts/SubmitButton';
import { useDuplicateRfq } from '../../../../resources/rfq/rfqHandler';

export function useDuplicateRfQDialog({ rfqId, rfqName }: { rfqId: string; rfqName: string }) {
    const { setDialog, closeDialog } = useDialogContext();
    return {
        openDialog: () =>
            setDialog(
                <Dialog open={true} maxWidth={'xs'} fullWidth={true} onClose={() => closeDialog()}>
                    <DialogTitle
                        title={t`Duplicate RfQ`}
                        onClose={() => closeDialog()}
                        style={{ borderBottom: `1px solid ${colorSystem.neutral[1]}` }}
                    />

                    <DialogContent style={{ paddingBottom: '24px' }}>
                        <DashboardDuplicateRfqForm rfqId={rfqId} handleClose={closeDialog} rfqName={rfqName} />
                    </DialogContent>
                </Dialog>,
            ),
        closeDialog,
    };
}

type DuplicateRfQFormState = {
    rfqName: string;
};

const DashboardDuplicateRfqForm = ({
    rfqId,
    handleClose,
    rfqName,
}: {
    rfqId: string;
    handleClose: () => void;
    rfqName: string;
}) => {
    const defaultValues: DuplicateRfQFormState = {
        rfqName: `Copy of ${rfqName}`,
    };

    const { mutateAsync } = useDuplicateRfq({
        rfqId,
        onSuccess: () => {
            handleClose();
        },
    });
    const handleSubmit = async (form: DuplicateRfQFormState) => {
        const rfqName = form.rfqName.trim();
        await mutateAsync(rfqName);
    };
    return (
        <FormContainer onSubmit={handleSubmit} defaultValues={defaultValues}>
            <DashboardDuplicateRfqFormInner />
        </FormContainer>
    );
};

const DashboardDuplicateRfqFormInner = (): JSX.Element => {
    const { control } = useFormContext<DuplicateRfQFormState>();
    return (
        <Flexbox flexDirection={'column'} gap={28}>
            <Text variant="body">
                <Trans>This will duplicate the RfQ and its assemblies. Please choose a name for the new RfQ.</Trans>
            </Text>
            <FieldTextControlled name={'rfqName'} control={control} />
            <SubmitButton />
        </Flexbox>
    );
};
