import { t } from '@lingui/macro';
import { Protected } from '@luminovo/auth';
import { id } from '@luminovo/commons';
import { MenuButton, MenuItem, useNavigate } from '@luminovo/design-system';
import { Add } from '@mui/icons-material';
import { route } from '../../../../utils/routes';
import { useDialogAddSupplierAndStockLocation } from './SupplierDialogs/AddSupplierDialog';

export function ButtonAddSupplier(): JSX.Element {
    const navigate = useNavigate();
    const { openDialog } = useDialogAddSupplierAndStockLocation({
        onSuccessCallback: (res) =>
            navigate(route('/suppliers/:supplierAndStockLocationId', { supplierAndStockLocationId: res.id })),
        disableSupplierPreferenceField: false,
    });

    return (
        <Protected requiredPermissions={['create:supplier']}>
            <MenuButton
                appearance="secondary"
                label={t`Add suppliers`}
                size="medium"
                icon={<Add />}
                id={id('suppliers/button_add_supplier')}
            >
                <MenuItem
                    label={t`From .csv file`}
                    onClick={() => {
                        navigate(route('/supplier/importer'));
                    }}
                />
                <MenuItem label={t`Manually`} onClick={() => openDialog()} />
            </MenuButton>
        </Protected>
    );
}
