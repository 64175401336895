import { useDebugErrorHandler } from '@/resources/http/debugErrorHandler';
import { t } from '@lingui/macro';
import { getToken } from '@luminovo/auth';
import { isProductionEnvironment } from '@luminovo/commons';
import type { CommandBarItem } from '@luminovo/design-system';
import { createRfqHttpCallWithMock, createRfQWithCalculation } from '@luminovo/http-client';
import * as icons from '@mui/icons-material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';

interface UseTestingToolMutationProps<T> {
    asyncFunction: () => Promise<T>;
    successMessage: (result: T) => string;
}

const useTestingToolMutation = <T>({ asyncFunction, successMessage }: UseTestingToolMutationProps<T>) => {
    const queryClient = useQueryClient();
    const onError = useDebugErrorHandler();

    return useMutation({
        mutationFn: async () => {
            enqueueSnackbar('Loading...', { variant: 'info', persist: false });
            return await asyncFunction();
        },
        onSuccess: async (result) => {
            await queryClient.invalidateQueries();
            enqueueSnackbar(successMessage(result), { variant: 'success', persist: false });
        },
        onError,
    });
};

export const useCommandBarTestingTools = (): CommandBarItem[] => {
    const token = getToken();

    const { mutateAsync: createRfQWithCalcMutation } = useTestingToolMutation({
        asyncFunction: () =>
            createRfQWithCalculation({
                batchSizes: [1],
                unitPrice: '1',
                customerNamePrefix: 'Test',
                token,
            }),
        successMessage: (rfq) => `RfQ ${rfq.rfqName} created with calculation`,
    });

    const { mutateAsync: createRfQWithCalcMutationManufacturingScenarioTemplate } = useTestingToolMutation({
        asyncFunction: () =>
            createRfQWithCalculation({
                batchSizes: [1],
                unitPrice: '1',
                customerNamePrefix: 'Test',
                // from scripts/sql/manufacturing-data.sql
                manufacturingScenarioTemplates: ['80874f5e-5701-41ba-9d2b-1b18767e2577'],
                token,
            }),
        successMessage: (rfq) => `RfQ ${rfq.rfqName} created with calculation`,
    });

    const { mutateAsync: createRfQMutation } = useTestingToolMutation({
        asyncFunction: () => {
            // from scripts/sql/luminovo.sql
            const fantasticOemCustomerId = '51bcdf56-9a2f-4d19-a2e4-ef8e693d5dd8';
            return createRfqHttpCallWithMock({
                customerId: fantasticOemCustomerId,
                token,
            });
        },
        successMessage: (rfq) => `RfQ ${rfq.data.name} created with mock`,
    });

    return [
        {
            type: 'category',
            title: t`Testing tools`,
            defaultOpen: false,
            children: [
                {
                    type: 'button',
                    title: t`Create RfQ with calculation`,
                    alwaysHidden: isProductionEnvironment(),
                    Icon: icons.Bolt,
                    onClick: async () => {
                        await createRfQWithCalcMutation();
                    },
                },
                {
                    type: 'button',
                    title: t`Create RfQ with calculation with manufacturing scenario template`,
                    alwaysHidden: isProductionEnvironment(),
                    Icon: icons.Bolt,
                    onClick: async () => {
                        await createRfQWithCalcMutationManufacturingScenarioTemplate();
                    },
                },
                {
                    type: 'button',
                    title: 'Create RFQ',
                    Icon: icons.Bolt,
                    alwaysHidden: isProductionEnvironment(),
                    onClick: async () => {
                        await createRfQMutation();
                    },
                },
            ],
        },
    ];
};
