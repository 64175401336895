/* eslint-disable */
import { t, Trans } from '@lingui/macro';
import { getToken } from '@luminovo/auth';
import { Currency, formatDays, formatDecimal, uniqBy } from '@luminovo/commons';
import {
    CenteredLayout,
    colorSystem,
    Dropzone,
    FieldDate,
    FieldSelect,
    FieldText,
    FormItem,
    Step,
    Stepper,
    Text,
    useNavigate,
} from '@luminovo/design-system';
import { ComplianceStatus, http, OtsFullPart, Packaging, PdfAnalyzeResponse, ValidFor } from '@luminovo/http-client';
import { formatValidFor } from '@luminovo/sourcing-core';
import { Box } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useSnackbar } from 'notistack';
import { Prompt } from 'react-router-dom';
import {
    ExtractedOfferLineItem,
    ExtractedValue,
    FormFieldsConfiguration,
    PartialOfferLineItem,
    ValidatedFormState,
} from '../../types';
import { DataGridPdfOfferLine } from '../DataGridPdfOfferLine';
import { LayoutTwoPanels } from '../LayoutTwoPanels';
import {
    getPdfDocument,
    PdfViewer,
    PdfViewerContextProvider,
    processPdf,
    RegionNetwork,
    usePdfViewerState,
} from '../PdfViewer';
import { compareByVerticalDistance } from '../PdfViewer/model/RegionNetwork/compareByVerticalDistance';
import { findAttributeByType } from '../PdfViewer/model/RegionNetwork/findAttributeByType';
import { infer, InferredAttribute } from '../PdfViewer/model/RegionNetwork/infer';
import { Attribute, Attributes, AttributeValueOf, Region } from '../PdfViewer/model/RegionNetwork/types';
import { PdfOfferImporterState, usePdfOfferImporterState } from './context';
import { createFormState } from './state';
import { QuoteRequest } from './types';

export type PdfOfferImporterProps<TQuoteRequest extends QuoteRequest> = {
    defaultQuoteRequest?: TQuoteRequest;
    quoteRequests: TQuoteRequest[];
    onImport: (props: ValidatedFormState) => Promise<void>;
    returnTo: string;
    onSuccess: (_: { quoteRequest: TQuoteRequest; file: File }) => void;
    onError: () => void;
    initialFieldConfiguration: FormFieldsConfiguration;
};

export function PdfOfferImporter<TQuoteRequest extends QuoteRequest>(
    props: PdfOfferImporterProps<TQuoteRequest>,
): JSX.Element {
    return (
        <PdfViewerContextProvider>
            <PdfOfferImporterState
                initialFormState={createFormState({
                    quoteRequest: props.defaultQuoteRequest,
                    quoteRequests: props.quoteRequests,
                    initialFieldConfiguration: props.initialFieldConfiguration,
                })}
            >
                <PdfOfferImporterInner {...props} />
            </PdfOfferImporterState>
        </PdfViewerContextProvider>
    );
}

function PdfOfferImporterInner<TQuoteRequest extends QuoteRequest>(props: PdfOfferImporterProps<TQuoteRequest>) {
    const { formState } = usePdfOfferImporterState();
    const form = useAtomValue(formState.$formState);
    const file = useAtomValue(formState.fields.$file);
    const quoteRequest = useAtomValue(formState.fields.$quoteRequest);
    const defaultCurrency = useAtomValue(formState.fields.$defaultCurrency);
    const validFrom = useAtomValue(formState.fields.$validFrom);
    const navigate = useNavigate();
    const rows = useAtomValue(formState.fields.$rows);

    const isUploadEnabled = true;
    const isQuoteEnabled = isUploadEnabled && file.status === 'success';
    const isQuoteDone =
        isQuoteEnabled &&
        quoteRequest.status === 'success' &&
        defaultCurrency.status === 'success' &&
        validFrom.status === 'success';
    const isOffersEnabled = isQuoteEnabled && isQuoteDone;

    const steps: Step[] = [
        {
            content: StepUploadFile,
            enabled: isUploadEnabled,
            label: t`Upload`,
            isDone: file.status === 'success',
        },
        {
            content: StepPickQuote,
            enabled: isQuoteEnabled,
            label: t`Quote`,
            isDone: true,
        },
        {
            content: StepImportOffers,
            enabled: isOffersEnabled,
            label: t`Offers`,
            isDone: form.status === 'success',
        },
    ];

    const { enqueueSnackbar } = useSnackbar();

    const [stepIndex, setStepIndex] = useAtom(formState.$stepIndex);

    const { mutateAsync: importOffers, isPending: isImporting } = useMutation({
        mutationFn: async () => {
            if (form.status !== 'success') {
                throw new Error('Form is not valid');
            }
            const validatedForm = form.value;
            await props.onImport(validatedForm);
            enqueueSnackbar(t`${formatDecimal(validatedForm.rows.length)} offers imported successfully`, {
                variant: 'success',
            });
            navigate(props.returnTo);
            props.onSuccess({ quoteRequest: validatedForm.quoteRequest as TQuoteRequest, file: validatedForm.file });
        },
        onError: () => {
            props.onError();
            enqueueSnackbar(t`Error importing offers`, { variant: 'error' });
        },
    });

    return (
        <>
            <Stepper
                hrefBack={props.returnTo}
                onSubmit={async () => {
                    return importOffers();
                }}
                title={t`PDF offer importer`}
                steps={steps}
                onStepIndexChange={(newStepIndex) => setStepIndex(newStepIndex)}
                stepIndex={stepIndex}
            />
            <Prompt when={!isImporting} message={t`Are you sure you want to exit? Changes will be lost`} />
        </>
    );
}

function useMutationAnalyzePdf() {
    const [, pdfViewerDispatch] = usePdfViewerState();
    const { formState } = usePdfOfferImporterState();
    const setValidFrom = useSetAtom(formState.fields.$validFrom);
    const setCurrency = useSetAtom(formState.fields.$defaultCurrency);
    const setOfferNumber = useSetAtom(formState.fields.$offerNumber);
    const setFile = useSetAtom(formState.fields.$file);
    const setAnalyzeResult = useSetAtom(formState.fields.$analyzeResult);
    const dispatch = useSetAtom(formState.fields.$rows);

    return useMutation({
        mutationFn: async ({ file, quoteRequest }: { file: File; quoteRequest?: QuoteRequest }) => {
            const url = URL.createObjectURL(file);
            const pdf = await getPdfDocument(url);

            const form = new FormData();
            form.append('file', file);

            const analyzeResultPromise: Promise<PdfAnalyzeResponse> = http(
                'POST /analyze/pdf',
                {
                    queryParams: { type: 'Offer' },
                    requestBody: form,
                },
                getToken(),
            );

            let expectedParts: OtsFullPart[] = quoteRequest?.resolvedParts ?? [];

            const analyzeResult = await analyzeResultPromise;

            const regionNetwork = processPdf({ analyzeResult }, { expectedParts });
            pdfViewerDispatch({
                type: 'setPdfDocumentProxy',
                pdfDocumentProxy: pdf,
            });
            pdfViewerDispatch({
                type: 'setRegions',
                regions: regionNetwork,
            });

            const currency = inferCurrency(regionNetwork);
            setCurrency(currency?.value);

            const offerNumber = inferOfferNumber(regionNetwork);
            setOfferNumber(offerNumber?.value ?? '');

            const validFrom = inferOfferDate(regionNetwork);
            setValidFrom(validFrom?.value);

            const extractedOfferLineItems = inferOfferLineItems(regionNetwork);

            const rows: PartialOfferLineItem[] = extractedOfferLineItems.map(
                (extractedOfferLineItem, index): PartialOfferLineItem => ({
                    rowId: `row-${index}`,
                    source: extractedOfferLineItem,
                    currency: extractedOfferLineItem.currency.value,
                    unitPrice: extractedOfferLineItem.unitPrice.value,
                    moq: extractedOfferLineItem.moq.value,
                    mpq: extractedOfferLineItem.mpq.value,
                    packaging: extractedOfferLineItem.packaging.value,
                    standardFactoryLeadTime: extractedOfferLineItem.standardFactoryLeadTime.value,
                    stock: extractedOfferLineItem.stock.value,
                    ncnr: extractedOfferLineItem.ncnr.value,
                    notes: extractedOfferLineItem.notes.value ?? '',
                    part: extractedOfferLineItem.part.value,
                    reach: ComplianceStatus.Unknown,
                    rohs: ComplianceStatus.Unknown,
                }),
            );

            dispatch({ type: 'setRows', rows });

            setFile(file);
            setAnalyzeResult(analyzeResult);

            return analyzeResult;
        },
    });
}

function StepUploadFile({ onStepIndexChange }: { onStepIndexChange: (step: 'next' | 'prev' | number) => void }) {
    const { formState } = usePdfOfferImporterState();
    const quoteRequest = useAtomValue(formState.fields.$quoteRequest).value;
    const setFile = useSetAtom(formState.fields.$file);

    const { mutateAsync, isPending } = useMutationAnalyzePdf();

    return (
        <CenteredLayout>
            <Dropzone
                isLoading={isPending}
                overrides={{}}
                onDropAccepted={async ([file]) => {
                    setFile(file);
                    if (file.type === 'application/pdf') {
                        await mutateAsync({ file, quoteRequest });
                        onStepIndexChange('next');
                    }
                    if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                        onStepIndexChange('next');
                    }
                }}
                accept={{
                    'application/pdf': ['.pdf'],
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
                }}
                multiple={false}
                title=""
                persistentFiles={[]}
            />
            <Text variant="body-small" style={{ maxWidth: 400, color: colorSystem.neutral[6], textAlign: 'center' }}>
                <Trans>
                    AI-powered extraction may contain inaccuracies. Please carefully review all extracted values before
                    importing.
                </Trans>
            </Text>
        </CenteredLayout>
    );
}

function StepPickQuote() {
    const [state] = usePdfViewerState();
    const pdfDocumentProxy = state.pdfDocumentProxy;

    const rightPanel = (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, padding: 2 }}>
            <FormItemQuoteRequestNumber />
            <FormItem
                required
                label={t`Default currency`}
                description={t`Unless specified otherwise, the offers will be imported in this currency`}
                variant="description-inlined"
            >
                <FieldSelectCurrency />
            </FormItem>
            <FormItemOfferNumber />
            <FormItemValidFrom />
            <FormItemValidUntil />
            <FormItemValidFor />
        </Box>
    );

    if (!pdfDocumentProxy) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    padding: 2,
                    height: '100%',
                    width: '100%',
                    alignItems: 'center',
                }}
            >
                {rightPanel}
            </Box>
        );
    }

    return (
        <LayoutTwoPanels
            left={
                pdfDocumentProxy ? (
                    <Box
                        sx={{ background: colorSystem.neutral[1], minWidth: '1024', height: '100%', overflow: 'auto' }}
                    >
                        <PdfViewer
                            pdf={pdfDocumentProxy}
                            pdfViewerOptions={{
                                actionStyles: {
                                    marginTop: 48,
                                },
                            }}
                        />
                    </Box>
                ) : undefined
            }
            right={rightPanel}
        />
    );
}

function FormItemValidFor() {
    const { formState } = usePdfOfferImporterState();
    const [validFor, setValidFor] = useAtom(formState.fields.$validFor);
    const quoteRequest = useAtomValue(formState.fields.$quoteRequest).value;

    const formFieldsConfiguration = useAtomValue(formState.$formFieldsConfiguration);

    const possibleValues = [
        { option: ValidFor.EveryCustomer, isVisible: true },
        { option: ValidFor.ThisCustomer, isVisible: quoteRequest?.customerId !== undefined },
        { option: ValidFor.ThisRfQ, isVisible: quoteRequest?.rfqId !== undefined },
    ];

    const options = possibleValues.filter(({ isVisible }) => isVisible).map(({ option }) => option);

    if (!formFieldsConfiguration.validFor.visible) {
        return <></>;
    }

    return (
        <FormItem label={t`Valid for`} variant="description-inlined" description={t`For whom are the offers valid?`}>
            <FieldSelect
                options={options}
                value={validFor.value ?? null}
                disableClearable
                getOptionLabel={(option) => formatValidFor(option)}
                onChange={(value) => setValidFor(value ?? ValidFor.EveryCustomer)}
            />
        </FormItem>
    );
}

function FormItemValidFrom() {
    const { formState } = usePdfOfferImporterState();
    const [validFrom, setValidFrom] = useAtom(formState.fields.$validFrom);
    const [state, pdfViewerDispatch] = usePdfViewerState();

    const regions = state.regs.findRegions({ attribute: 'offerDate' });

    return (
        <FormItem
            required
            label={t`Valid from`}
            variant="description-inlined"
            description={t`The date from which the offer is valid`}
            onMouseEnter={() => {
                if (regions.length > 0) {
                    pdfViewerDispatch({
                        type: 'setMode',
                        mode: {
                            type: 'inspect',
                            attribute: 'offerDate',
                            pageNumber: regions[0].pageNumber,
                            selectedRegionIds: regions.map((r: Region) => r.id),
                        },
                    });
                }
            }}
        >
            <FieldDate
                value={validFrom.value ?? null}
                error={validFrom.status === 'error'}
                helperText={validFrom.message}
                onChange={(value) => setValidFrom(value ?? undefined)}
            />
        </FormItem>
    );
}

function FormItemValidUntil() {
    const { formState } = usePdfOfferImporterState();
    const [validUntil, setValidUntil] = useAtom(formState.fields.$validUntil);
    const [state, pdfViewerDispatch] = usePdfViewerState();

    const regions = state.regs.findRegions({ attribute: 'dueDate' });
    const validDays = useAtomValue(formState.$validDays);
    const formattedDays = validDays ? formatDays(validDays) : undefined;
    return (
        <FormItem
            label={t`Valid until`}
            variant="description-inlined"
            description={t`The date until which the offer is valid`}
            onMouseEnter={() => {
                if (regions.length > 0) {
                    pdfViewerDispatch({
                        type: 'setMode',
                        mode: {
                            type: 'inspect',
                            attribute: 'dueDate',
                            pageNumber: regions[0].pageNumber,
                            selectedRegionIds: regions.map((r: Region) => r.id),
                        },
                    });
                }
            }}
        >
            <FieldDate
                value={validUntil.value ?? null}
                error={validUntil.status === 'error'}
                helperText={validUntil.message}
                onChange={(value) => setValidUntil(value ?? undefined)}
            />
            {formattedDays && (
                <Text>
                    <Trans comment="Valid for {X days}">Valid for {formattedDays}</Trans>
                </Text>
            )}
        </FormItem>
    );
}

function FormItemQuoteRequestNumber() {
    const { formState } = usePdfOfferImporterState();
    const [quoteRequest, setQuoteRequest] = useAtom(formState.fields.$quoteRequest);
    const quoteRequests = useAtomValue(formState.$quoteRequests);
    const file = useAtomValue(formState.fields.$file).value;
    const rows = useAtomValue(formState.fields.$rows);

    const { mutateAsync: reAnalyzePdf, isPending: isReAnalyzing } = useMutationAnalyzePdf();

    return (
        <FormItem
            label={t`Quote request`}
            required
            description={t`Select the quote request that the offers are linked to`}
            variant="description-inlined"
        >
            <FieldSelect
                disabled={isReAnalyzing}
                groupBy={(group) => group?.status ?? ''}
                autoFocus={true}
                options={quoteRequests}
                getOptionLabel={(option) => {
                    if (!option) {
                        return '';
                    }
                    return `#${option.label}`;
                }}
                error={quoteRequest.status === 'error'}
                helperText={quoteRequest.message}
                value={quoteRequest.value}
                onChange={(newQuoteRequest) => {
                    setQuoteRequest(newQuoteRequest || undefined);
                    if (!newQuoteRequest || !file) {
                        return;
                    }
                    reAnalyzePdf({ quoteRequest: newQuoteRequest, file });
                    return;
                }}
            />
            {rows.length > 0 && (
                <Text color={colorSystem.green[7]} variant="body-small">
                    <Trans>Extracted {rows.length} offers</Trans>
                </Text>
            )}
        </FormItem>
    );
}

function StepImportOffers() {
    const { formState } = usePdfOfferImporterState();
    const [state, pdfViewerDispatch] = usePdfViewerState();
    const pdfDocumentProxy = state.pdfDocumentProxy;
    const rows = useAtomValue(formState.fields.$rows) ?? [];
    const quoteRequest = useAtomValue(formState.fields.$quoteRequest).value;

    return (
        <LayoutTwoPanels
            left={
                pdfDocumentProxy && (
                    <Box
                        sx={{ background: colorSystem.neutral[1], minWidth: '1024', height: '100%', overflow: 'auto' }}
                    >
                        <PdfViewer
                            pdf={pdfDocumentProxy}
                            pdfViewerOptions={{
                                actionStyles: {
                                    marginTop: 48,
                                },
                            }}
                        />
                    </Box>
                )
            }
            right={
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1,
                        padding: 0,
                        minWidth: 600,
                        height: '100%',
                        background: colorSystem.neutral[1],
                    }}
                >
                    <DataGridPdfOfferLine
                        formState={formState}
                        rows={rows}
                        requestedParts={quoteRequest?.resolvedParts ?? []}
                    />
                </Box>
            }
        />
    );
}

function FormItemOfferNumber(): JSX.Element {
    const { formState } = usePdfOfferImporterState();
    const [defaultOfferNumber, setDefaultOfferNumber] = useAtom(formState.fields.$offerNumber);
    const [state, pdfViewerDispatch] = usePdfViewerState();

    const regions = state.regs.findRegions({ attribute: 'offerNumber' });

    return (
        <FormItem
            onMouseEnter={() => {
                if (regions.length > 0) {
                    pdfViewerDispatch({
                        type: 'setMode',
                        mode: {
                            type: 'inspect',
                            attribute: 'offerNumber',
                            pageNumber: regions[0].pageNumber,
                            selectedRegionIds: regions.map((r: Region) => r.id),
                        },
                    });
                }
            }}
            onMouseLeave={() => {
                pdfViewerDispatch({ type: 'setMode', mode: { type: 'default' } });
            }}
            label={t`Offer number`}
            variant="description-inlined"
            description={t`All offers imported will have this offer number attached to them`}
        >
            <FieldText
                value={defaultOfferNumber.value ?? null}
                error={defaultOfferNumber.status === 'error'}
                helperText={defaultOfferNumber.message}
                onChange={(value) => setDefaultOfferNumber(value || '')}
            />
        </FormItem>
    );
}

function FieldSelectCurrency(): JSX.Element {
    const { formState } = usePdfOfferImporterState();
    const [state, pdfViewerDispatch] = usePdfViewerState();

    const [defaultCurrency, setDefaultCurrency] = useAtom(formState.fields.$defaultCurrency);
    const regions = state.regs.findRegions({ attribute: 'currency' });

    return (
        <Box
            onMouseEnter={() => {
                pdfViewerDispatch({
                    type: 'setMode',
                    mode: {
                        type: 'inspect',
                        attribute: 'currency',
                        pageNumber: regions[0]?.pageNumber ?? 1,
                        selectedRegionIds: regions.map((r) => r.id),
                    },
                });
            }}
            onMouseLeave={() => {
                pdfViewerDispatch({ type: 'setMode', mode: { type: 'default' } });
            }}
        >
            <FieldSelect
                placeholder={t`Currency`}
                options={Object.values(Currency)}
                error={defaultCurrency.status === 'error'}
                helperText={defaultCurrency.message}
                getOptionLabel={(option) => option ?? 'None'}
                value={defaultCurrency.value}
                onChange={(newCurrency) => {
                    setDefaultCurrency(newCurrency ?? undefined);
                }}
            />
        </Box>
    );
}

function inferCurrency(regionNetwork: RegionNetwork): ExtractedValue<Currency> | undefined {
    const currencyRegs = regionNetwork.findRegions({ attribute: 'currency' });

    const allCurrencies = currencyRegs
        .flatMap((currency) => currency.attributes)
        .flatMap((attr) => (attr.attr === 'currency' ? [attr.value] : []));

    // Group currencies by ID and count occurrences
    const currencyCount = new Map<Currency, number>();
    for (const currency of allCurrencies) {
        currencyCount.set(currency, (currencyCount.get(currency) || 0) + 1);
    }

    // Find currency with highest count
    let maxCount = 0;
    let mostCommonCurrency: Currency | undefined;
    for (const [currency, count] of currencyCount) {
        if (count > maxCount) {
            maxCount = count;
            mostCommonCurrency = currency;
        }
    }

    if (!mostCommonCurrency) {
        return undefined;
    }

    return {
        regions: currencyRegs,
        value: mostCommonCurrency,
        confidence: 1,
    };
}

function inferOfferNumber(regionNetwork: RegionNetwork): ExtractedValue<string> | undefined {
    const offerNumberRegs = regionNetwork.findRegions({ attribute: 'offerNumber' });

    const allOfferNumbers = offerNumberRegs
        .flatMap((offerNumber) => offerNumber.attributes)
        .flatMap((attr) => (attr.attr === 'offerNumber' ? [attr.value] : []));

    // If no offer numbers found, return undefined
    if (allOfferNumbers.length === 0) {
        return undefined;
    }

    // Group offer numbers and count occurrences
    const offerNumberCount = new Map<string, number>();
    for (const offerNumber of allOfferNumbers) {
        offerNumberCount.set(offerNumber, (offerNumberCount.get(offerNumber) || 0) + 1);
    }

    // Find offer number with highest count
    let maxCount = 0;
    let mostCommonOfferNumber: string | undefined;
    for (const [offerNumber, count] of offerNumberCount) {
        if (count > maxCount) {
            maxCount = count;
            mostCommonOfferNumber = offerNumber;
        }
    }

    if (!mostCommonOfferNumber) {
        return undefined;
    }

    return {
        value: mostCommonOfferNumber,
        regions: offerNumberRegs,
        confidence: 1,
    };
}

function inferOfferDate(regionNetwork: RegionNetwork): ExtractedValue<string> | undefined {
    const offerDateRegs = regionNetwork.findRegions({ attribute: 'offerDate' });

    const allOfferDates = offerDateRegs
        .flatMap((offerDate) => offerDate.attributes)
        .flatMap((attr) => (attr.attr === 'offerDate' ? [attr.value] : []));

    // If no offer numbers found, return undefined
    if (allOfferDates.length === 0) {
        return undefined;
    }

    // Group offer numbers and count occurrences
    const offerDateCount = new Map<string, number>();
    for (const offerDate of allOfferDates) {
        offerDateCount.set(offerDate, (offerDateCount.get(offerDate) || 0) + 1);
    }

    // Find offer number with highest count
    let maxCount = 0;
    let mostCommonOfferDate: string | undefined;
    for (const [offerDate, count] of offerDateCount) {
        if (count > maxCount) {
            maxCount = count;
            mostCommonOfferDate = offerDate;
        }
    }

    if (!mostCommonOfferDate) {
        return undefined;
    }

    return {
        value: mostCommonOfferDate,
        regions: offerDateRegs,
        confidence: 1,
    };
}

function* iterateAttributes(inferredAttrs: InferredAttribute[]): Generator<Attribute> {
    for (const attr of inferredAttrs) {
        yield attr;
        if (attr.inferredFrom) {
            yield* iterateAttributes(attr.inferredFrom);
        }
    }
}

export function isAttributableToRegion(region: Region, inferredAttrs: InferredAttribute[]): boolean {
    for (const attr of iterateAttributes(inferredAttrs)) {
        if (region.attributes.some((regionAttr) => regionAttr.attr === attr.attr && regionAttr.value === attr.value)) {
            return true;
        }
    }
    return false;
}

function inferOfferLineItems(regionNetwork: RegionNetwork): ExtractedOfferLineItem[] {
    return regionNetwork
        .findRegions({ attribute: 'part' })
        .map((partRegion): ExtractedOfferLineItem => {
            const part = partRegion.attributes.find((attr) => attr.attr === 'part')?.value ?? undefined;

            const regions = [partRegion, ...regionNetwork.findLinks({ from: partRegion }).map((link) => link.to)].sort(
                compareByVerticalDistance(partRegion),
            );
            const extractedAttrs = regions.flatMap((region) => region.attributes);
            const withInferredAttrs = infer(extractedAttrs);

            function inferAttribute<T extends Attributes>(
                type: T,
                defaultValue?: AttributeValueOf<T>,
            ): ExtractedValue<AttributeValueOf<T>> {
                const attr = findAttributeByType(withInferredAttrs, type);
                if (!attr) {
                    return { value: defaultValue, regions: [], confidence: 0 };
                }

                return {
                    value: (attr.value ?? defaultValue) as AttributeValueOf<T> | undefined,
                    regions: uniqBy(
                        regions.filter((region) => {
                            return isAttributableToRegion(region, [attr]);
                        }),
                        (x) => x.id,
                    ),
                    confidence: attr.confidence ?? 1,
                };
            }
            const moq = inferAttribute('moq');
            const mpq = inferAttribute('mpq');
            const standardFactoryLeadTime = inferAttribute('standardFactoryLeadTime');
            const stock = inferAttribute('stock');
            const unitPrice = inferAttribute('unitPrice');
            const packaging: ExtractedValue<Packaging> = ((): ExtractedValue<Packaging> => {
                const { regions, value, confidence } = inferAttribute('packaging');
                if (value === 'none') {
                    return { regions, value: undefined, confidence };
                }
                return { regions, value, confidence };
            })();

            const boundingBox = [
                [partRegion],
                moq?.regions,
                mpq?.regions,
                standardFactoryLeadTime?.regions,
                stock?.regions,
                unitPrice?.regions,
            ]
                .filter((x) => x !== undefined)
                .flatMap((x) => x)
                .sort((a, b) => a.pageNumber - b.pageNumber)
                .reduce((a, b) => {
                    if (a.pageNumber !== b.pageNumber) {
                        return a;
                    }
                    return { ...a, box: a.box.merge(b.box) };
                });

            return {
                notes: { regions: [], value: '', confidence: 1 },
                part: { value: part, regions: [partRegion], confidence: 1 },
                currency: { regions: [], value: undefined, confidence: 0.5 },
                moq: moq,
                mpq: mpq,
                standardFactoryLeadTime: standardFactoryLeadTime,
                stock: stock,
                unitPrice: unitPrice,
                ncnr: { value: undefined, regions: [], confidence: 1 },
                packaging: packaging,
                boundingBox: boundingBox.box,
                pageNumber: partRegion.pageNumber,
            };
        })
        .sort((a, b) => (a.pageNumber ?? 0) - (b.pageNumber ?? 0) || (a.boundingBox.y ?? 0) - (b.boundingBox.y ?? 0));
}
