import * as z from 'zod';
import { endpoint } from '../http/endpoint';
import { SchukatCredentialsInputDTORuntype, SchukatResponseBodyDTORuntype } from './schukatBackendTypes';

export const schukatEndpoints = {
    'GET /3rdparty/schukat/credentials': endpoint({
        description: 'Returns credentials for schukat integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: SchukatResponseBodyDTORuntype,
    }),
    'POST /3rdparty/schukat/credentials': endpoint({
        description: 'Uploads credentials for schukat API integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: SchukatCredentialsInputDTORuntype,
        responseBody: SchukatResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/schukat/credentials'],
    }),
    'PATCH /3rdparty/schukat/credentials': endpoint({
        description: 'Updates credentials for schukat API integratoin',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: SchukatCredentialsInputDTORuntype,
        responseBody: SchukatResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/schukat/credentials'],
    }),
    'DELETE /3rdparty/schukat/credentials': endpoint({
        description: 'Deletes existing credentials for schukat API integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({ deleted: z.number() }),
        invalidates: ['GET /3rdparty/schukat/credentials'],
    }),
};
