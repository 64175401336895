import * as z from 'zod';

// Domain model types

const TargetDateRuntype = z.union([
    z.object({
        type: z.literal('Date'),
        value: z.string(),
    }),
    z.object({
        type: z.literal('AsSoonAsPossible'),
    }),
]);

export const DeliveryBatchSizeRuntype = z.object({
    quantity: z.number(),
});

const DeliveryDateRuntype = z.object({
    quantity: z.number(),
    target_date: TargetDateRuntype,
    delivery_batch_size: DeliveryBatchSizeRuntype,
});

export type PrototypeDemandDTO = z.infer<typeof PrototypeDemandRuntype>;
const PrototypeDemandRuntype = z.object({
    assembly_id: z.string(),
    delivery_date: DeliveryDateRuntype,
});

export type PrototypeDemandScenarioDTO = z.infer<typeof PrototypeDemandScenarioRuntype>;
export const PrototypeDemandScenarioRuntype = z.object({
    id: z.string(),
    type: z.literal('Prototype'),
    start_year: z.number(),
    end_year: z.number(),
    rfq_id: z.string(),
    demands: z.array(PrototypeDemandRuntype),
});

export type AnnualDemandDTO = z.infer<typeof AnnualDemandRuntype>;
export const AnnualDemandRuntype = z.object({
    year: z.number(),
    quantity: z.number(),
    delivery_batch_sizes: z.array(DeliveryBatchSizeRuntype),
});

export type SeriesDemandDTO = z.infer<typeof SeriesDemandRuntype>;
const SeriesDemandRuntype = z.object({
    assembly_id: z.string(),
    annual_demands: z.array(AnnualDemandRuntype),
});

export type SeriesDemandScenarioDTO = z.infer<typeof SeriesDemandScenarioRuntype>;
export const SeriesDemandScenarioRuntype = z.object({
    id: z.string(),
    type: z.literal('Series'),
    start_year: z.number(),
    end_year: z.number(),
    rfq_id: z.string(),
    demands: z.array(SeriesDemandRuntype),
});

export type DemandScenarioDTO = z.infer<typeof DemandScenarioRuntype>;
export const DemandScenarioRuntype = z.union([PrototypeDemandScenarioRuntype, SeriesDemandScenarioRuntype]);

// Types for manual creation of demand scenarios

export const PrototypeDemandCreationRuntype = z.object({
    assembly_id: z.string(),
    quantity: z.number(),
});

export const PrototypeDemandScenarioCreationRuntype = z.object({
    type: z.literal('Prototype'),
    rfq_id: z.string(),
    demands: z.array(PrototypeDemandCreationRuntype),
    target_date: TargetDateRuntype,
});

export const SeriesDemandScenarioCreationRuntype = z.object({
    type: z.literal('Series'),
    rfq_id: z.string(),
    start_year: z.number(),
    end_year: z.number(),
    demands: z.array(SeriesDemandRuntype),
});

export type DemandScenarioCreationDTO = z.infer<typeof DemandScenarioCreationRuntype>;
export const DemandScenarioCreationRuntype = z.union([
    PrototypeDemandScenarioCreationRuntype,
    SeriesDemandScenarioCreationRuntype,
]);

// Types for updating demand scenarios

export const PrototypeDemandScenarioUpdateRuntype = z.object({
    type: z.literal('Prototype'),
    demands: z.array(PrototypeDemandCreationRuntype),
    target_date: TargetDateRuntype,
});

export const SeriesDemandScenarioUpdateRuntype = z.object({
    type: z.literal('Series'),
    start_year: z.number(),
    end_year: z.number(),
    demands: z.array(SeriesDemandRuntype),
});

export type DemandScenarioUpdateDTO = z.infer<typeof DemandScenarioUpdateRuntype>;
export const DemandScenarioUpdateRuntype = z.union([
    PrototypeDemandScenarioUpdateRuntype,
    SeriesDemandScenarioUpdateRuntype,
]);

// Types for creating demand scenarios as part of RfQ creation

export type RfqCreationPrototypeDemandDTO = z.infer<typeof RfqCreationPrototypeDemandRuntype>;
export const RfqCreationPrototypeDemandRuntype = z.object({
    assembly_designator: z.string(),
    quantity: z.number(),
});

export const RfqCreationPrototypeDemandScenarioRuntype = z.object({
    type: z.literal('Prototype'),
    demands: z.array(RfqCreationPrototypeDemandRuntype),
    target_date: TargetDateRuntype,
});

export type RfqCreationSeriesDemandDTO = z.infer<typeof RfqCreationSeriesDemandRuntype>;
export const RfqCreationSeriesDemandRuntype = z.object({
    assembly_designator: z.string(),
    annual_demands: z.array(AnnualDemandRuntype),
});

export const RfqCreationSeriesDemandScenarioRuntype = z.object({
    type: z.literal('Series'),
    start_year: z.number(),
    end_year: z.number(),
    demands: z.array(RfqCreationSeriesDemandRuntype),
});

export type RfqCreationDemandScenarioDTO = z.infer<typeof RfqCreationDemandScenarioRuntype>;
export const RfqCreationDemandScenarioRuntype = z.union([
    RfqCreationPrototypeDemandScenarioRuntype,
    RfqCreationSeriesDemandScenarioRuntype,
]);
