import { useNavigate } from '@luminovo/design-system';
import { DescendantsDTO, ParentAssembly } from '@luminovo/http-client';
import React from 'react';
import { getSpecificAssemblyPath, useParentsIdentifiers } from '../../../resources/assembly/assemblyHandler';
import { useQueryParams } from '../../../resources/hooks';
import { useBOMTableData } from './ModuleTableData/useBOMTableData';
function useShowTopLevelAssembly(): boolean {
    const { hideTopLevelAssembly } = useQueryParams<'/rfqs/:rfqId/bom'>();
    return hideTopLevelAssembly !== 'true';
}

export function useChangeBomPageIfSingleAssembly({ rfqId, assemblyId }: { rfqId: string; assemblyId: string }): void {
    const navigate = useNavigate();
    const { descendants } = useBOMTableData({ assemblyId, rfqId });

    const { data: { multi_parent_items: multiParentItems = [] } = {} } = useParentsIdentifiers(assemblyId);
    const showTopLevelAssembly = useShowTopLevelAssembly();
    const topLevelAssemblyId = getTopLevelAssemblyId(showTopLevelAssembly, multiParentItems, descendants, assemblyId);

    React.useEffect(() => {
        if (descendants !== undefined && topLevelAssemblyId) {
            navigate(
                getSpecificAssemblyPath({ rfqId, assemblyId: topLevelAssemblyId, currentParentAssemblyId: null }),
                { replace: true },
            );
        }
    }, [multiParentItems, showTopLevelAssembly, topLevelAssemblyId, assemblyId, descendants, navigate, rfqId]);
}

function getTopLevelAssemblyId(
    showTopLevelAssembly: boolean,
    parents: ParentAssembly[][],
    descendants: DescendantsDTO | undefined,
    assemblyId: string,
): string | undefined {
    const parentsFirstPath = parents[0] ?? [];
    if (showTopLevelAssembly && parentsFirstPath.length === 1 && descendants !== undefined) {
        const subAssemblies = descendants.assemblies.filter((assembly) => assemblyId in assembly.parents);
        if (subAssemblies.length === 1) {
            return subAssemblies[0].id;
        }
    }
    return undefined;
}
