import * as z from 'zod';
import { ApiIntegrationStatusRuntype } from './3rdPartyBackendTypes';

export type AvnetEbvLocaleCurrencyDTO = z.infer<typeof AvnetEbvLocaleCurrencyDTORuntype>;
export const AvnetEbvLocaleCurrencyDTORuntype = z.enum(['EUR', 'USD', 'GBP', 'JPY']);

export interface AvnetEbvCredentialsDTO extends z.infer<typeof AvnetEbvCredentialsDTORuntype> {}
export const AvnetEbvCredentialsDTORuntype = z.object({
    username: z.string(),
    password: z.string(),
    currency: AvnetEbvLocaleCurrencyDTORuntype,
});

export interface AvnetEbvCredentialsInputDTO extends z.infer<typeof AvnetEbvCredentialsInputDTORuntype> {}
export const AvnetEbvCredentialsInputDTORuntype = z.object({
    username: z.string().optional(),
    password: z.string().optional(),
    currency: AvnetEbvLocaleCurrencyDTORuntype.optional(),
});

export interface AvnetEbvResponseBodyDTO extends z.infer<typeof AvnetEbvResponseBodyDTORuntype> {}
export const AvnetEbvResponseBodyDTORuntype = z.object({
    data: AvnetEbvCredentialsDTORuntype.nullable(),
    status: ApiIntegrationStatusRuntype,
});
