import { HttpError, UNSAFE_formatHttpErrorForLlms } from './HttpError';
import { ResponseWithMethod } from './types/responseWithMethodType';
import { validateErrorCode } from './validateErrorCode';

export async function assertSuccessfulResponse(
    response: ResponseWithMethod,
    endpoint: string,
    requestBody: unknown,
): Promise<Response> {
    if (!response.ok) {
        const { status, statusText, method } = response;
        const clonedResponse = response.clone();
        const { code } = await validateErrorCode(response, endpoint);
        const responseBody = await clonedResponse.text();
        const sid = response.headers.get('x-epibator-sid') ?? undefined;
        const epibatorSha = response.headers.get('x-epibator-sha') ?? undefined;
        const luminovoVersion = response.headers.get('x-luminovo-version') ?? undefined;
        const error = new HttpError({ code, status, statusText, endpoint, sid, epibatorSha, luminovoVersion, method });
        if (process.env.CI || process.env.UNSAFE_ENABLE_HTTP_ERROR_FOR_LLMS) {
            throw UNSAFE_formatHttpErrorForLlms(error, requestBody, responseBody);
        }
        throw error;
    }
    return response;
}
