/* eslint-disable spellcheck/spell-checker*/

import {
    ApprovalStatusRuntype,
    CustomComponentFullRuntype,
    CustomFullPartRuntype,
    GenericFullPartRuntype,
    OtsComponentFullRuntype,
    OtsFullPartRuntype,
} from '@luminovo/http-client';
import * as z from 'zod';

// eslint-disable-next-line import/no-unused-modules
export interface OtsFullPartWithApprovalStatus extends z.infer<typeof OtsFullPartWithApprovalStatusRuntype> {}
const OtsFullPartWithApprovalStatusRuntype = z.object({
    type: z.literal('OffTheShelf'),
    part: OtsFullPartRuntype,
    approvalStatus: ApprovalStatusRuntype,
});

export interface GenericFullPartWithApprovalStatus extends z.infer<typeof GenericFullPartWithApprovalStatusRuntype> {}
const GenericFullPartWithApprovalStatusRuntype = z.object({
    type: z.literal('Generic'),
    part: GenericFullPartRuntype,
    approvalStatus: ApprovalStatusRuntype,
});

export interface IpnFullPartWithApprovalStatus extends z.infer<typeof IpnFullPartWithApprovalStatusRuntype> {}
const IpnFullPartWithApprovalStatusRuntype = z.object({
    type: z.literal('Ipn'),
    part: OtsComponentFullRuntype,
    approvalStatus: ApprovalStatusRuntype,
});

export type FullPartWithApprovalStatus = z.infer<typeof FullPartWithApprovalStatusRuntype>;
const FullPartWithApprovalStatusRuntype = z.union([
    OtsFullPartWithApprovalStatusRuntype,
    GenericFullPartWithApprovalStatusRuntype,
    IpnFullPartWithApprovalStatusRuntype,
    z.object({
        type: z.literal('Custom'),
        part: CustomFullPartRuntype,
        approvalStatus: ApprovalStatusRuntype,
    }),
    z.object({
        type: z.literal('CustomComponent'),
        part: CustomComponentFullRuntype,
        approvalStatus: ApprovalStatusRuntype,
    }),
]);

export { lifecycleEnumLongNamePublicTranslations } from '@luminovo/sourcing-core';

/**
 *
 * Used to know the section of the screen when adding a part option in the following analytics events:
 *   - select_part - property: section_of_screen
 *   - link_part - property: section_of_screen
 *
 */
export type SectionOfScreen =
    | 'comparePartsModal'
    | 'alternativeParts'
    | 'partialMatches'
    | 'ipnSearch'
    | 'mpnSearch'
    | 'otsPartForm'
    | 'genericPartForm'
    | 'customPartForm';
