import * as z from 'zod';
import {
    DesignItemOriginDTONewRuntype,
    DesignItemOriginDTORuntype,
    ExcelLinesRuntype,
    QuantityUnitDTORuntype,
} from '../backendTypes';
import { PartAlternativeRuntype, PartSpecificationRuntype, PartSuggestion } from '../part';

export const EventSummaryDTORuntype = z.object({
    category: z.string(),
    total: z.number(),
    unresolved: z.number(),
    last_change: z.null().or(z.string()),
});

export interface DesignItemResponseDTO extends z.infer<typeof DesignItemResponseDTORuntype> {}
export const DesignItemResponseDTORuntype = z.object({
    id: z.string(),
    designator: z.string().nullable(),
    quantity: QuantityUnitDTORuntype.nullable(),
    notes: z.string(),
    assembly: z.string(),
    do_not_place: z.boolean(),
    part_specification: z.null().or(PartSpecificationRuntype),
    origin: DesignItemOriginDTORuntype,
    event_summary: z.array(EventSummaryDTORuntype),
    is_consigned: z.boolean(),
    aggregation_key: z.string(),
    ignore_package_name_mismatch: z.boolean(),
});

export interface DesignItemResponseDTONew extends z.infer<typeof DesignItemResponseDTONewRuntype> {}
export const DesignItemResponseDTONewRuntype = z.object({
    id: z.string(),
    designator: z.string().nullable(),
    quantity: QuantityUnitDTORuntype.nullable(),
    notes: z.string(),
    assembly: z.string(),
    do_not_place: z.boolean(),
    part_specification: z.null().or(PartSpecificationRuntype),
    origin: DesignItemOriginDTONewRuntype,
    event_summary: z.array(EventSummaryDTORuntype),
    is_consigned: z.boolean(),
    aggregation_key: z.string(),
    ignore_package_name_mismatch: z.boolean(),
});

export interface BulkDesignItemDTOAPI extends z.infer<typeof BulkDesignItemDTOAPIRuntype> {}
export const BulkDesignItemDTOAPIRuntype = z.object({
    items: z.array(z.string()),
});

export function isPartSuggestion(object: PartSuggestion | undefined): object is PartSuggestion {
    return object !== undefined;
}

export interface BulkDesignItemDeleteResponseDTO extends z.infer<typeof BulkDesignItemDeleteResponseDTORuntype> {}
export const BulkDesignItemDeleteResponseDTORuntype = z.object({
    deleted: z.number(),
});

export interface DesignItemExcelOriginDTO extends z.infer<typeof DesignItemExcelOriginDTORuntype> {}
export const DesignItemExcelOriginDTORuntype = z.object({
    id: z.string(),
    bom_file_id: z.string(),
    original_lines: ExcelLinesRuntype,
});

export const DesignItemPartAlternativesRuntype = z.object({
    id: z.string(),
    part_alternatives: z.array(PartAlternativeRuntype),
});

export interface DesignItemPartAlternativesDTO extends z.infer<typeof DesignItemPartAlternativesRuntype> {}
