import { t, Trans } from '@lingui/macro';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    PrimaryButton,
    SecondaryButton,
    Text,
    Tooltip,
} from '@luminovo/design-system';
import { useSnackbar } from 'notistack';
import { useCurrentUserDetailsContext } from '../../../../../components/contexts/CurrentUserDetailsContext';
import { useDialogContext } from '../../../../../components/contexts/ModalContext';
import { useRequestEditQuotation } from '../../../../../resources/rfq/rfqHandler';
import { useHasAccessToCustomerPortal } from '../../../../../utils/featureFlags';
import { BannerCard } from './BannerCard';

export const BannerCardQuotationInProgress = ({ rfqId }: { rfqId: string }): JSX.Element => {
    const { openDialog: openRequestEditQuotationDialog } = useRequestEditQuotationDialog({ rfqId });
    const { hasAccessToCustomerPortal } = useHasAccessToCustomerPortal();

    const { organization } = useCurrentUserDetailsContext();
    const orgName = organization.name;

    return (
        <BannerCard
            background={'linear-gradient(83.52deg, #001E22 -1.88%, #6F7FFF 120.44%)'}
            title={t`Your request has been sent.`}
            description={t`You will receive a notification once the quotation is ready.`}
            icon="📦"
            callToAction={
                <Tooltip
                    variant={'white'}
                    title={<Text>{t`${orgName} has not granted you access to edit this request`}</Text>}
                    disableHoverListener={hasAccessToCustomerPortal}
                >
                    <span>
                        <SecondaryButton
                            disabled={!hasAccessToCustomerPortal}
                            onClick={() => openRequestEditQuotationDialog()}
                        >
                            <Trans>Edit request</Trans>
                        </SecondaryButton>
                    </span>
                </Tooltip>
            }
        />
    );
};

function useRequestEditQuotationDialog({ rfqId }: { rfqId: string }) {
    const { organization } = useCurrentUserDetailsContext();
    const { setDialog, closeDialog } = useDialogContext();
    const { mutateAsync } = useRequestEditQuotation(rfqId);
    const { enqueueSnackbar } = useSnackbar();

    const organizationName = organization.name;

    return {
        openDialog: () =>
            setDialog(
                <Dialog
                    open={true}
                    maxWidth={'sm'}
                    fullWidth={true}
                    transitionDuration={0}
                    onClose={() => closeDialog()}
                >
                    <DialogTitle
                        title={t`Edit request for quotation?`}
                        style={{
                            paddingTop: '12px',
                            marginTop: '12px',
                        }}
                        onClose={() => closeDialog()}
                    />
                    <DialogContent
                        style={{
                            paddingTop: '0px',
                            marginTop: '8px',
                            marginBottom: '12px',
                            paddingBottom: '12px',
                        }}
                    >
                        <Text variant="body">
                            <p>{t`Please keep in mind that the quotation might already be in progress and making changes could potentially delay your quotation. You will receive a notification when ${organizationName} changes the status to "Request in draft", at which point you will be able to make edits to the request.`}</p>
                            {t`Are you sure you want to notify ${organizationName} and request to edit the request for quotation?`}
                        </Text>
                    </DialogContent>
                    <DialogActions>
                        <SecondaryButton onClick={() => closeDialog()}>
                            <Trans>No</Trans>
                        </SecondaryButton>
                        <PrimaryButton
                            onClick={async () => {
                                await mutateAsync();
                                closeDialog();
                                enqueueSnackbar(t`Notified ${organizationName} about your request`, {
                                    variant: 'success',
                                });
                            }}
                        >
                            <Trans>Yes</Trans>
                        </PrimaryButton>
                    </DialogActions>
                </Dialog>,
            ),
    };
}
