import { FullPart } from '@luminovo/http-client';
import { Skeleton } from '@mui/material';
import { BomItem } from '../../resources/designItem/bomItemFrontendTypes';
import { withSuspense } from '../withSuspense';
import { PartAvailabilityLabel } from './PartAvailabilityLabel';
import { usePartAvailability } from './usePartAvailability';

interface Props {
    rfqId?: string;
    assemblyId: string;
    part: FullPart;
    bomItem: BomItem;
    disabled?: boolean;
}

export const PartOptionAvailabilityView = withSuspense(
    ({ bomItem, part, rfqId, assemblyId, disabled = false }: Props) => {
        const availability = usePartAvailability({ bomItem, part, rfqId, assemblyId });
        if (!availability) {
            return <Skeleton width={'50px'} />;
        }

        return <PartAvailabilityLabel availability={availability} part={part} disabled={disabled} />;
    },
);
