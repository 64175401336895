import { useSites } from '@/resources/organizationSettings/sitesHandler';
import { useSourcingCalculation } from '@/resources/sourcingScenario/sourcingScenarioHandlers';
import { useSupplierAndStockLocations } from '@/resources/supplierAndStockLocation/supplierAndStockLocationHandler';
import { t, Trans } from '@lingui/macro';
import {
    assertUnreachable,
    compareByString,
    formatDays,
    formatDecimal,
    formatMonetaryValue,
    isPresent,
    transEnum,
} from '@luminovo/commons';
import {
    colorSystem,
    createColumnHelper,
    DEFAULT_SELECT_COLUMN_ID,
    Tag as DesignSystemTag,
    Flexbox,
    Link,
    SupportedFilterFn,
    TagGroup,
    TanStackTable,
    Text,
    TextField,
    Tooltip,
    useTanStackTable,
} from '@luminovo/design-system';
import {
    CustomComponentPartLiteDto,
    DerivedScrapQuantityDTO,
    OfferOriginEnum,
    OtsComponentPartLiteDto,
    Packaging,
    PartLite,
    PartLiteTypes,
    PriceType,
    SolutionSelection,
    SolutionStatus,
    SolutionTag,
    Tag,
} from '@luminovo/http-client';
import {
    AvailabilityChip,
    extractSolutionTagChips,
    formatOfferOrigin,
    formatPackaging,
    formatSolutionStatus,
    formatSupplierAndStockLocationDTO,
    hasSolutionTag,
    InventorySiteChip,
    LabelPartLite,
    leadTimeDaysExtractor,
    priceTypeTranslations,
    QuantityUnitRecordSingular,
    SolutionStatusInformationInternal,
    SolutionStatusTransitionIcon,
    SupplierAndStockLocationChip,
    TcoCostSummaryTooltip,
    UnitOfMeasurementTag,
} from '@luminovo/sourcing-core';
import { styled } from '@mui/material';
import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { createBomRoute } from '@/modules/SolutionManager/components/Toolbar/ButtonGoToBom';
import { useCustomComponentDrawer } from '../../../../components/partSpecificationCards/CustomComponent/useCustomComponentDrawer';
import { useIpnDetailsDrawer } from '../../../../components/partSpecificationCards/Ipn/useIpnDetailsDrawer';
import { isTotalCostOfOwnershipEnabled } from '../../../../featureFlags';
import { route } from '../../../../utils/routes';
import { getQuantityUnitString } from '../../../../utils/stringFunctions';
import { formatPartLite } from '../../../Negotiations/model/formatPartLite';
import { OriginCellInternal } from '../../../SolutionManager/components/columns';
import {
    ApprovedPartLiteOptionLabel,
    SectionApprovedParts,
} from '../../../SolutionManager/components/Sidebar/SectionApprovedParts';
import { SolutionConfigurationCommentsButton } from '../../../SolutionManager/components/Toolbar/SolutionManagerCommentsButton';
import { ScrapDetails } from '../../../Sourcing/components/Formula/ScrapDetails';
import { useScrapQuantityHandlers } from './EditScrapQuantityButton';
import {
    createSolutionConfigurationTableData,
    OfferData,
    SolutionConfigurationsTableContext,
    SolutionConfigurationTableData,
} from './SolutionConfigurationTypes';

const StyledLink = styled(Link)({
    color: colorSystem.neutral['7'],
    textDecoration: 'none',
    '&:hover': { color: colorSystem.primary['6'] },
});

const extractOffer = (data: SolutionConfigurationTableData): OfferData | undefined => {
    const offerSummary = data.offerSummary;
    const type = offerSummary.type;
    switch (type) {
        case 'Consigned':
        case 'NoOffer':
            return undefined;
        case 'Offer':
            return offerSummary.data;
        default:
            assertUnreachable(type);
    }
};

const extractPackaging = (row: SolutionConfigurationTableData): Packaging | null => {
    const offer = extractOffer(row);

    if (!isPresent(offer)) {
        return null;
    }

    const { type, data } = offer;
    switch (type) {
        case 'OffTheShelf':
        case 'Inventory':
            return data.packaging;
        case 'CustomPart':
            return null;
        default:
            assertUnreachable(type);
    }
};

const extractSupplierPartNumber = (row: SolutionConfigurationTableData): string | null => {
    const offer = extractOffer(row);

    if (!isPresent(offer)) {
        return null;
    }

    const { type, data } = offer;
    switch (type) {
        case 'OffTheShelf':
        case 'Inventory':
            return data.supplier_part_number;
        case 'CustomPart':
            return null;
        default:
            assertUnreachable(type);
    }
};

const extractMoqAndMpq = (row: SolutionConfigurationTableData): { moq: number | null; mpq: number | null } => {
    const offer = extractOffer(row);

    if (!isPresent(offer)) {
        return { moq: null, mpq: null };
    }

    const { type, data } = offer;
    switch (type) {
        case 'OffTheShelf':
            return { moq: data.moq, mpq: data.mpq };
        case 'Inventory':
        case 'CustomPart':
            return { moq: null, mpq: null };
        default:
            assertUnreachable(type);
    }
};

const extractFactoryLeadTime = (row: SolutionConfigurationTableData): number | null => {
    const offer = extractOffer(row);

    if (!isPresent(offer)) {
        return null;
    }

    const { type, data } = offer;
    switch (type) {
        case 'OffTheShelf':
        case 'Inventory':
            return data.factory_lead_time;
        case 'CustomPart':
            return null;
        default:
            assertUnreachable(type);
    }
};

const extractOriginalSolutionStatus = (data: SolutionConfigurationTableData): SolutionStatus => {
    const originalStatus = data.solutionConfigurationSummary.originalStatus;
    const statusType = originalStatus.type;
    switch (statusType) {
        case 'NoApprovedParts':
        case 'NoOffer':
            return SolutionStatus.Error;
        case 'Status':
            return originalStatus.data.status;
        default:
            assertUnreachable(statusType);
    }
};

export const extractSolutionTags = (data: SolutionConfigurationTableData): Tag[] => {
    const originalStatus = data.solutionConfigurationSummary.originalStatus;
    const statusType = originalStatus.type;
    switch (statusType) {
        case 'NoApprovedParts':
            return [{ tag: SolutionTag.NoApprovedParts }];
        case 'NoOffer':
            return [{ tag: SolutionTag.Unavailable }];
        case 'Status':
            return originalStatus.data.tags;
        default:
            assertUnreachable(statusType);
    }
};

const formatDesignatorsForSolutionConfiguration = (data: SolutionConfigurationTableData): string => {
    return formatDesignators(data.solutionConfigurationSummary.designItems.map((designItem) => designItem.designator));
};

const formatDesignators = (designators: (string | null)[]): string => {
    return designators
        .map((designator) => designator ?? t`Unknown`)
        .sort(compareByString)
        .join(', ');
};

export const extractDisplayableSupplierOrInventoryName = (row: SolutionConfigurationTableData): string => {
    const offerSummary = row.offerSummary;
    const offerSummaryType = offerSummary.type;
    switch (offerSummaryType) {
        case 'NoOffer':
            return t`No offers`;
        case 'Consigned':
            return t`Consigned by the customer`;
        case 'Offer':
            const { data: offer, type } = offerSummary.data;
            switch (type) {
                case 'OffTheShelf':
                case 'CustomPart':
                    return formatSupplierAndStockLocationDTO(offer.supplier);
                case 'Inventory':
                    return t`Inventory`;
                default:
                    assertUnreachable(type);
            }
        /* eslint-disable-next-line no-fallthrough */
        default:
            assertUnreachable(offerSummaryType);
    }
};

const extractSelectedPartManufacturer = (row: SolutionConfigurationTableData): string | null => {
    const selectedPart = row.partSummary.selected_part;
    if (!isPresent(selectedPart)) {
        return null;
    }

    const kind = selectedPart.kind;
    switch (kind) {
        case PartLiteTypes.OffTheShelf:
        case PartLiteTypes.RawSpecification:
        case PartLiteTypes.Unknown:
            return selectedPart.manufacturer.name;
        case PartLiteTypes.Custom:
        case PartLiteTypes.Generic:
        case PartLiteTypes.CustomComponent:
        case PartLiteTypes.Ipn:
            return null;
        default:
            assertUnreachable(kind);
    }
};

const isComponent = (part: PartLite): part is CustomComponentPartLiteDto | OtsComponentPartLiteDto => {
    const kind = part.kind;
    switch (kind) {
        case PartLiteTypes.Custom:
        case PartLiteTypes.Generic:
        case PartLiteTypes.OffTheShelf:
        case PartLiteTypes.RawSpecification:
        case PartLiteTypes.Unknown:
            return false;
        case PartLiteTypes.Ipn:
        case PartLiteTypes.CustomComponent:
            return true;
        default:
            assertUnreachable(kind);
    }
};

const extractSelectedPartMatches = (row: SolutionConfigurationTableData): PartLite[] | null => {
    const selectedPart = row.partSummary.selected_part_option;
    if (!isPresent(selectedPart)) {
        return null;
    }

    const kind = selectedPart.kind;
    switch (kind) {
        case PartLiteTypes.OffTheShelf:
        case PartLiteTypes.RawSpecification:
        case PartLiteTypes.Unknown:
        case PartLiteTypes.Custom:
            return [selectedPart];
        case PartLiteTypes.CustomComponent:
            return selectedPart.parts;
        case PartLiteTypes.Generic:
        case PartLiteTypes.Ipn:
            return selectedPart.matches;
        default:
            assertUnreachable(kind);
    }
};

const StyledScrapQuantityInput = styled(TextField)({
    boxSizing: 'border-box',
});

const RenderScrapQuantityInternal: React.FunctionComponent<{
    solutionConfigurationId: string;
    scrapQuantity: DerivedScrapQuantityDTO | null;
    sharedContext: SolutionConfigurationsTableContext;
}> = ({ scrapQuantity, solutionConfigurationId, sharedContext }): JSX.Element => {
    const { getScrapQuantity, setScrapQuantity, isEditable } = sharedContext.scrapQuantityHandlers;

    const handleScrapQuantityChange = React.useCallback(
        (e) => {
            const newScrapQuantityAmount = parseFloat(e.target.value);
            setScrapQuantity(solutionConfigurationId, newScrapQuantityAmount);
        },
        [setScrapQuantity, solutionConfigurationId],
    );

    return (
        <>
            {isEditable ? (
                <StyledScrapQuantityInput
                    type="number"
                    variant="outlined"
                    value={getScrapQuantity(solutionConfigurationId)}
                    onChange={handleScrapQuantityChange}
                    size="small"
                    InputProps={{ inputProps: { min: 0 } }}
                />
            ) : (
                <Tooltip variant="white" title={scrapQuantity ? <ScrapDetails scrapType={scrapQuantity.type} /> : ''}>
                    <Text variant={scrapQuantity?.type.type === 'manual' ? 'body-small-semibold' : 'body-small'}>
                        {formatDecimal(scrapQuantity?.amount, { ifAbsent: '-' })}
                    </Text>
                </Tooltip>
            )}
        </>
    );
};

const columnHelper = createColumnHelper<SolutionConfigurationTableData, SolutionConfigurationsTableContext>();

const columns = [
    columnHelper.text((row) => formatPartLite(row.partSummary.selected_part), {
        id: 'selectedPart',
        size: 180,
        label: () => t`Selected part`,
        renderType: (item) => {
            if (item.partSummary.selected_part?.kind === PartLiteTypes.OffTheShelf) {
                return 'ellipsisOnly';
            }
            return 'generic';
        },
        cell: ({ row, sharedContext }) => {
            const selectedPart = row.original.partSummary.selected_part;
            const pcbAssemblyName = row.original.solutionConfigurationSummary.pcbAssemblyName;

            if (!isPresent(selectedPart)) {
                const approvedPartOptions = row.original.partSummary.approved_part_options;
                const numberOfApprovedPartOptions = approvedPartOptions.length;

                if (numberOfApprovedPartOptions === 0) {
                    return <DesignSystemTag attention={'low'} color="red" label={t`No approved parts`} />;
                }

                return (
                    <Tooltip
                        variant={'white'}
                        title={
                            <SectionApprovedParts
                                rfqId={sharedContext.rfqId}
                                solutionConfigurationId={row.original.id}
                            />
                        }
                    >
                        <Text
                            color={colorSystem.neutral[7]}
                        >{t`${numberOfApprovedPartOptions} approved part options`}</Text>
                    </Tooltip>
                );
            }

            return (
                <Tooltip
                    variant={'white'}
                    title={
                        <Flexbox flexDirection={'column'}>
                            <SectionApprovedParts
                                rfqId={sharedContext.rfqId}
                                solutionConfigurationId={row.original.id}
                            />
                        </Flexbox>
                    }
                >
                    <span>
                        <LabelPartLite
                            part={selectedPart}
                            formattedPart={formatPartLite(selectedPart)}
                            pcbAssemblyName={pcbAssemblyName ?? undefined}
                        />
                    </span>
                </Tooltip>
            );
        },
    }),

    columnHelper.enum((row) => extractSelectedPartManufacturer(row), {
        id: 'selectedPartManufacturer',
        size: 170,
        renderType: 'text',
        initialVisibility: false,
        label: () => t`Selected part (Manufacturer)`,
        getOptionLabel: (manufacturerName) => manufacturerName ?? t`Unknown`,
        cell: ({ getValue }) => {
            const manufacturerName = getValue();
            if (!isPresent(manufacturerName)) {
                return (
                    <Text variant="inherit" color={colorSystem.neutral[6]}>
                        <Trans>Unknown</Trans>
                    </Text>
                );
            }
            return manufacturerName;
        },
    }),

    columnHelper.array((row) => row.solutionConfigurationSummary.designItems, {
        id: 'designators',
        size: 100,
        label: () => t`Designators`,
        sortingFn: (a, b) => {
            const aDesignators = formatDesignatorsForSolutionConfiguration(a.original);
            const bDesignators = formatDesignatorsForSolutionConfiguration(b.original);
            return compareByString(aDesignators, bDesignators);
        },
        renderType: 'text',
        getOptionKey: (designItem) => designItem.id,
        getOptionLabel: (designItem) => designItem.designator ?? t`Unknown`,
        cell: ({ row, sharedContext }) => {
            const designItemPaths = row.original.solutionConfigurationSummary.designItemPaths;
            const designators = formatDesignatorsForSolutionConfiguration(row.original);

            return (
                <Tooltip
                    variant="white"
                    placement="bottom-start"
                    title={
                        <span onClick={(event) => event.stopPropagation()}>
                            <Flexbox flexDirection="column">
                                {designItemPaths.map((designItemPath) => {
                                    return (
                                        <span key={designItemPath.path}>
                                            <Text variant="body-semibold">{designItemPath.path} &gt;&nbsp;</Text>
                                            <Text variant="body-semibold">
                                                <StyledLink to={createBomRoute(sharedContext.rfqId, designItemPath)}>
                                                    {formatDesignators(designItemPath.designators)}
                                                </StyledLink>
                                            </Text>
                                        </span>
                                    );
                                })}
                            </Flexbox>
                        </span>
                    }
                >
                    <span>{designators}</span>
                </Tooltip>
            );
        },
    }),
    columnHelper.array((row) => row.solutionConfigurationSummary.assemblies, {
        id: 'assembly',
        size: 200,
        initialVisibility: false,
        label: () => t`Assembly`,
        getOptionLabel: (assembly) => assembly.designator,
        getOptionKey: (assembly) => assembly.id,
        enableOnRowClick: false,
        enableGlobalFilter: false,
        cell: ({ getValue }) => {
            return (
                <TagGroup
                    options={getValue()}
                    getOptionLabel={(assembly) => assembly.designator}
                    limit={1}
                    onOptionClick={(assembly) => {
                        window.open(
                            route('/assemblies/:assemblyId/dashboard', { assemblyId: assembly.id }),
                            '_blank',
                            'noopener noreferrer',
                        );
                    }}
                />
            );
        },
    }),
    columnHelper.number((row) => row.solutionConfigurationSummary.aggregatedQuantity, {
        id: 'quantity',
        label: () => t`Quantity`,
        size: 100,
        cell: ({ row }) => getQuantityUnitString(row.original.solutionConfigurationSummary.aggregatedQuantity),
    }),
    columnHelper.enum((row) => extractPackaging(row), {
        id: 'packaging',
        label: () => t`Packaging`,
        size: 100,
        options: [...Object.values(Packaging), null],
        getOptionLabel: (opt) => formatPackaging(opt),
        cell: ({ getValue }) => {
            const packaging = getValue();

            if (!isPresent(packaging)) {
                return (
                    <Text variant="inherit" color={colorSystem.neutral[6]}>
                        <Trans>Unknown</Trans>
                    </Text>
                );
            }
            return <DesignSystemTag color={'neutral'} attention={'low'} label={formatPackaging(packaging)} />;
        },
    }),
    columnHelper.number((row) => row.solutionConfigurationSummary.scrapQuantity?.amount ?? 0, {
        id: 'scrap-quantity',
        label: () => t`Scrap`,
        size: 80,
        cell: ({ row, sharedContext }) => {
            const scrapQuantity = row.original.solutionConfigurationSummary.scrapQuantity;
            return (
                <RenderScrapQuantityInternal
                    solutionConfigurationId={row.original.id}
                    scrapQuantity={scrapQuantity}
                    sharedContext={sharedContext}
                />
            );
        },
    }),
    columnHelper.enum((row) => extractDisplayableSupplierOrInventoryName(row), {
        id: 'supplier',
        label: () => t`Supplier`,
        size: 140,
        getOptionLabel: (supplierOrInventoryName) => supplierOrInventoryName,
        cell: ({ row, sharedContext }) => {
            const offerSummary = row.original.offerSummary;
            const offerSummaryType = offerSummary.type;
            switch (offerSummaryType) {
                case 'NoOffer':
                    return <DesignSystemTag attention={'low'} color="red" label={t`No offers`} />;
                case 'Consigned':
                    return <DesignSystemTag attention={'low'} color="green" label={t`Consigned`} />;
                case 'Offer':
                    const { data: offer, type } = offerSummary.data;
                    const solution = { solutionTags: extractSolutionTags(row.original) };
                    switch (type) {
                        case 'OffTheShelf':
                        case 'CustomPart':
                            if (!isPresent(offer.supplier)) {
                                return 'loading';
                            }
                            return (
                                <SupplierAndStockLocationChip
                                    isPreferred={hasSolutionTag(solution, SolutionTag.SupplierPreferred)}
                                    isApproved={hasSolutionTag(solution, SolutionTag.SupplierApproved)}
                                    supplier={offer.supplier}
                                />
                            );
                        case 'Inventory':
                            if (!isPresent(offer.inventorySite)) {
                                return <>"loading"</>;
                            }
                            return (
                                <InventorySiteChip
                                    isPreferred={hasSolutionTag(solution, SolutionTag.SupplierPreferred)}
                                    isApproved={hasSolutionTag(solution, SolutionTag.SupplierApproved)}
                                    site={offer.inventorySite}
                                    displaySiteName={sharedContext.displaySiteName}
                                />
                            );
                        default:
                            assertUnreachable(type);
                    }
                /* eslint-disable-next-line no-fallthrough */
                default:
                    assertUnreachable(offerSummaryType);
            }
        },
    }),
    columnHelper.number(
        (row) => {
            const offerSummary = extractOffer(row);
            if (!isPresent(offerSummary)) {
                return null;
            }
            const leadTime = offerSummary.data.lead_time;
            const shippingTimeInDays = leadTime?.shipping_time_in_days ?? 0;
            return leadTimeDaysExtractor(leadTime) + shippingTimeInDays;
        },
        {
            id: 'lead-time',
            label: () => t`Lead time`,
            size: 100,
            cell: ({ row }) => {
                const offer = extractOffer(row.original);
                if (!isPresent(offer)) {
                    return '';
                }

                const availability = offer.data.lead_time ?? null;
                const solutionTags = extractSolutionTags(row.original);
                const solution = { solutionTags, availability };

                return <AvailabilityChip solution={solution} />;
            },
        },
    ),
    columnHelper.monetaryValue((row) => row.costSummary.unit_price?.preferred, {
        id: 'unit-price',
        label: () => t`Unit price`,
        size: 100,
        formatAs: 'unit-price',
        cell: (item) => formatMonetaryValue(item.getValue(), 'unit-price', { ifAbsent: '' }),
    }),
    columnHelper.monetaryValue((row) => row.costSummary.tco_costs?.unit.cost?.preferred, {
        id: 'tco-cost',
        initialVisibility: false,
        enableHiding: isTotalCostOfOwnershipEnabled()!,
        size: 110,
        formatAs: 'unit-price',
        label: () => t`TCO cost`,
        cell: (item) => (
            <TcoCostSummaryTooltip summary={item.row.original.costSummary.tco_costs}>
                {formatMonetaryValue(item.getValue(), 'unit-price', { ifAbsent: '' })}
            </TcoCostSummaryTooltip>
        ),
    }),

    columnHelper.monetaryValue((row) => row.costSummary.tco_costs?.unit.breakdown.packaging_cost?.preferred, {
        id: 'tco-cost-packaging',
        initialVisibility: false,
        enableHiding: isTotalCostOfOwnershipEnabled()!,
        size: 130,
        formatAs: 'unit-price',
        label: () => t`TCO Packaging`,
        cell: (item) => (
            <TcoCostSummaryTooltip summary={item.row.original.costSummary.tco_costs}>
                {formatMonetaryValue(item.getValue(), 'unit-price', { ifAbsent: '' })}
            </TcoCostSummaryTooltip>
        ),
    }),

    columnHelper.monetaryValue((row) => row.costSummary.tco_costs?.unit.breakdown.discount?.preferred, {
        id: 'tco-cost-discount',
        initialVisibility: false,
        enableHiding: isTotalCostOfOwnershipEnabled()!,
        size: 130,
        formatAs: 'unit-price',
        label: () => t`TCO Discount`,
        cell: (item) => (
            <TcoCostSummaryTooltip summary={item.row.original.costSummary.tco_costs}>
                {formatMonetaryValue(item.getValue(), 'unit-price', { ifAbsent: '' })}
            </TcoCostSummaryTooltip>
        ),
    }),

    columnHelper.monetaryValue((row) => row.costSummary.tco_costs?.unit.breakdown.shipping_cost?.preferred, {
        id: 'tco-cost-shipping',
        initialVisibility: false,
        enableHiding: isTotalCostOfOwnershipEnabled()!,
        size: 130,
        formatAs: 'unit-price',
        label: () => t`TCO Shipping`,
        cell: (item) => (
            <TcoCostSummaryTooltip summary={item.row.original.costSummary.tco_costs}>
                {formatMonetaryValue(item.getValue(), 'unit-price', { ifAbsent: '' })}
            </TcoCostSummaryTooltip>
        ),
    }),

    columnHelper.monetaryValue((row) => row.costSummary.tco_costs?.unit.breakdown.customs_cost?.preferred, {
        id: 'tco-cost-customs',
        initialVisibility: false,
        enableHiding: isTotalCostOfOwnershipEnabled()!,
        size: 130,
        formatAs: 'unit-price',
        label: () => t`TCO Customs`,
        cell: (item) => (
            <TcoCostSummaryTooltip summary={item.row.original.costSummary.tco_costs}>
                {formatMonetaryValue(item.getValue(), 'unit-price', { ifAbsent: '' })}
            </TcoCostSummaryTooltip>
        ),
    }),
    columnHelper.monetaryValue((row) => row.costSummary.tco_costs?.unit.breakdown.other_cost?.preferred, {
        id: 'tco-cost-other',
        initialVisibility: false,
        enableHiding: isTotalCostOfOwnershipEnabled()!,
        size: 130,
        formatAs: 'unit-price',
        label: () => t`TCO Other`,
        cell: (item) => (
            <TcoCostSummaryTooltip summary={item.row.original.costSummary.tco_costs}>
                {formatMonetaryValue(item.getValue(), 'unit-price', { ifAbsent: '' })}
            </TcoCostSummaryTooltip>
        ),
    }),

    columnHelper.monetaryValue((row) => row.costSummary.tco_unit_price?.preferred, {
        id: 'tco-unit-price',
        initialVisibility: false,
        enableHiding: isTotalCostOfOwnershipEnabled(),
        label: () => t`TCO unit price`,
        size: 120,
        cell: (item) => formatMonetaryValue(item.getValue(), 'unit-price', { ifAbsent: '' }),
    }),
    columnHelper.monetaryValue((row) => row.costSummary.excess_price?.preferred ?? undefined, {
        id: 'excess',
        label: () => t`Excess`,
        size: 100,
        cell: (item) =>
            formatMonetaryValue(item.getValue(), item.sharedContext.overrideFormatter ?? 'default', { ifAbsent: '' }),
    }),
    columnHelper.monetaryValue((row) => row.costSummary.total_price?.preferred, {
        id: 'total-price',
        label: () => t`Total price`,
        size: 100,
        cell: (item) => {
            return formatMonetaryValue(item.getValue(), item.sharedContext.overrideFormatter ?? 'default', {
                ifAbsent: '',
            });
        },
    }),
    columnHelper.monetaryValue((row) => row.costSummary.line_value?.preferred, {
        id: 'line-value',
        label: () => t`Line value`,
        size: 100,
        initialVisibility: false,
        cell: (item) => formatMonetaryValue(item.getValue(), 'unit-price', { ifAbsent: '' }),
    }),
    columnHelper.enum(
        (row) => {
            const isConsigned = row.offerSummary.type === 'Consigned';
            if (isConsigned) {
                return null;
            }

            return row.solutionConfigurationSummary.selection;
        },
        {
            id: 'Selection',
            label: () => t`Selection`,
            size: 100,
            getOptionLabel: (selection) => {
                switch (selection) {
                    case SolutionSelection.Manual:
                        return t`Manual`;
                    case SolutionSelection.Automatic:
                        return t`Automatic`;
                    case SolutionSelection.Unknown:
                    case null:
                        return t`Unknown`;
                    default:
                        assertUnreachable(selection);
                }
            },
            cell: (item) => {
                const selection = item.getValue();
                switch (selection) {
                    case SolutionSelection.Manual:
                        return <DesignSystemTag color="neutral" label={t`Manual`} attention="low" />;
                    case SolutionSelection.Automatic:
                        return <DesignSystemTag color="neutral" label={t`Automatic`} attention="low" />;
                    case SolutionSelection.Unknown:
                    case null:
                        return '-';
                    default:
                        assertUnreachable(selection);
                }
            },
        },
    ),

    columnHelper.enum((row) => row.solutionConfigurationSummary.manualStatus ?? extractOriginalSolutionStatus(row), {
        id: 'status',
        label: () => t`Status`,
        size: 80,
        align: 'center',
        initialPinning: 'right',
        enableHiding: false,
        options: Object.values(SolutionStatus),
        getOptionLabel: (opt) => formatSolutionStatus(opt),
        cell: ({ row }) => {
            const originalStatus = extractOriginalSolutionStatus(row.original);
            const manualStatus = row.original.solutionConfigurationSummary.manualStatus;
            const effectiveStatus = isPresent(manualStatus) ? manualStatus : originalStatus;

            const solutionTags = extractSolutionTags(row.original);
            const chips = extractSolutionTagChips({ solutionTags });

            return (
                <Tooltip
                    variant="white"
                    title={
                        <SolutionStatusInformationInternal
                            chips={chips}
                            originalStatus={originalStatus}
                            effectiveStatus={effectiveStatus}
                        />
                    }
                >
                    <span>
                        <SolutionStatusTransitionIcon solution={{ solutionTags }} />
                    </span>
                </Tooltip>
            );
        },
        quickFilters: [
            {
                label: () => t`Only warnings and errors`,
                value: { filterFn: SupportedFilterFn.equalsAny, value: [SolutionStatus.Warning, SolutionStatus.Error] },
            },
        ],
    }),
    columnHelper.text(
        (row) => row.partSummary.approved_part_options.filter(isComponent).map(formatPartLite).join(', '),
        {
            id: 'approvedIpns',
            label: () => t`Approved IPNs`,
            size: 150,
            initialVisibility: false,
            enableOnRowClick: false,
            cell: function Cell({ row, sharedContext }) {
                const { openDrawer: openOtsComponent } = useIpnDetailsDrawer();
                const { openDrawer: openCustomComponent } = useCustomComponentDrawer();

                const approvedComponents = row.original.partSummary.approved_part_options.filter(isComponent);

                return (
                    <TagGroup
                        options={approvedComponents}
                        getOptionLabel={(part) => part.id}
                        limit={1}
                        onOptionClick={(part) => {
                            const kind = part.kind;
                            switch (kind) {
                                case PartLiteTypes.Ipn:
                                    openOtsComponent({
                                        ipnId: part.id,
                                        rfqContext: { type: 'WithinRfQ', rfq_id: sharedContext.rfqId },
                                    });
                                    break;
                                case PartLiteTypes.CustomComponent:
                                    openCustomComponent({
                                        componentId: part.id,
                                        rfqContext: { type: 'WithinRfQ', rfq_id: sharedContext.rfqId },
                                    });
                                    break;
                                default:
                                    assertUnreachable(kind);
                            }
                        }}
                    />
                );
            },
        },
    ),
    columnHelper.text((row) => row.partSummary.cpns.join(', '), {
        id: 'approvedCpns',
        label: () => t`Approved CPNs`,
        size: 150,
        initialVisibility: false,
        cell: function Cell({ row }) {
            return <TagGroup options={row.original.partSummary.cpns} getOptionLabel={(cpn) => cpn} limit={1} />;
        },
    }),
    columnHelper.text((row) => row.bomSummary.bom_ipns.join(', '), {
        id: 'bomIPNs',
        label: () => t`BOM IPNs`,
        size: 200,
        initialVisibility: false,
        cell: ({ row }) => (
            <TagGroup options={row.original.bomSummary.bom_ipns} getOptionLabel={(opt) => opt} limit={1} />
        ),
    }),

    columnHelper.text((row) => row.bomSummary.bom_cpns.join(', '), {
        id: 'bomCPNs',
        label: () => t`BOM CPNs`,
        size: 200,
        initialVisibility: false,
        cell: ({ row }) => (
            <TagGroup options={row.original.bomSummary.bom_cpns} getOptionLabel={(opt) => opt} limit={1} />
        ),
    }),

    columnHelper.text((row) => row.bomSummary.bom_notes.join('\n\n'), {
        id: 'bomNotes',
        label: () => t`BOM Notes`,
        size: 100,
        initialVisibility: false,
        enableGlobalFilter: false,
        cell: (item) => item.getValue() ?? '',
    }),
    columnHelper.text((row) => row.partSummary.approved_part_options.map((part) => formatPartLite(part)).join(' '), {
        id: 'selectedPartOptions',
        label: () => t`Part option`,
        description: () => t`The origin part option of the selected part`,
        size: 150,
        renderType: 'generic',
        initialVisibility: false,
        cell: ({ row, sharedContext }) => {
            const selectedPartOption = row.original.partSummary.selected_part_option;
            const pcbAssemblyName = row.original.solutionConfigurationSummary.pcbAssemblyName;

            if (!isPresent(selectedPartOption)) {
                return t`Unknown`;
            }

            const partMatches = extractSelectedPartMatches(row.original) ?? [];

            return (
                <Tooltip
                    variant={'white'}
                    title={
                        <Flexbox flexDirection={'column'} gap={4}>
                            <ul style={{ margin: 0, paddingLeft: 18, maxHeight: 200, overflowY: 'auto' }}>
                                {partMatches.map((p) => (
                                    <ApprovedPartLiteOptionLabel
                                        key={p.id}
                                        rfqId={sharedContext.rfqId}
                                        part={p}
                                        pcbAssemblyName={pcbAssemblyName ?? undefined}
                                    />
                                ))}
                            </ul>
                        </Flexbox>
                    }
                >
                    <span>
                        <Flexbox gap={4}>
                            <span
                                style={{
                                    overflow: 'hidden',
                                    overflowWrap: 'break-word',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    wordBreak: 'break-all',
                                }}
                            >
                                <LabelPartLite
                                    part={selectedPartOption}
                                    formattedPart={formatPartLite(selectedPartOption)}
                                />
                            </span>
                            {partMatches.length > 1 && (
                                <Text color={colorSystem.neutral[7]}>{`+${partMatches.length - 1}`}</Text>
                            )}
                        </Flexbox>
                    </span>
                </Tooltip>
            );
        },
    }),
    /*
    columnHelper.array((row) => row.partCategories.map((partCategory) => partCategory), {
        id: 'part-category',
        label: () => t`Part category`,
        size: 150,
        initialVisibility: false,
        enableGlobalFilter: false,
        options: ({ facetedValues }) => Array.from(facetedValues).sort(compareByNumber((a) => a.depth)),
        getOptionLabel: (opt) => opt.name,
        cell: ({ row }) => {
            // Improvement: Consider using extractFallbackSelectedPart during data creation to eliminate this check and to utilize it in the filters.
            const fallbackSelectedPart = extractFallbackSelectedPart(row.original);
            if (
                !isPresent(fallbackSelectedPart) ||
                !isOtsFullPart(fallbackSelectedPart) ||
                !isPresent(fallbackSelectedPart.part_category)
            ) {
                return '-';
            }

            return <PartCategoryTag partCategory={fallbackSelectedPart.part_category.part_category} />;
        },
    }),
    */
    columnHelper.text((row) => extractSupplierPartNumber(row), {
        id: 'supplierPartNumber',
        label: () => t`SKU`,
        size: 100,
        initialVisibility: false,
        cell: (info) => info.getValue() ?? '-',
    }),
    columnHelper.number((row) => extractMoqAndMpq(row).moq, {
        id: 'moq',
        label: () => t`MOQ`,
        size: 100,
        initialVisibility: false,
        cell: (item) => formatDecimal(item.getValue(), { ifAbsent: '-' }),
    }),
    columnHelper.number((row) => extractMoqAndMpq(row).mpq, {
        id: 'mpq',
        label: () => t`MPQ`,
        size: 100,
        initialVisibility: false,
        cell: (item) => formatDecimal(item.getValue(), { ifAbsent: '-' }),
    }),
    columnHelper.enum((row) => extractOffer(row)?.data.origin.origin, {
        id: 'origin',
        size: 130,
        label: () => t`Origin`,
        enableOnRowClick: false,
        initialVisibility: false,
        enableGlobalFilter: false,
        options: ({ facetedValues }) => [OfferOriginEnum.Manual, ...facetedValues],
        getOptionLabel: (origin) => (isPresent(origin) ? formatOfferOrigin({ origin }) : t`Unknown`),
        cell: ({ row }) => {
            const offer = extractOffer(row.original);
            if (!isPresent(offer)) {
                return <></>;
            }

            const { type: offerType, data: offerData } = offer;
            switch (offerType) {
                case 'OffTheShelf':
                case 'CustomPart':
                    return (
                        <OriginCellInternal
                            key={Math.random()}
                            url={offer.data.offer_url}
                            supplierOrInventorySite={{ supplier: offerData.supplier }}
                            origin={offerData.origin}
                        />
                    );
                case 'Inventory':
                    return (
                        <OriginCellInternal
                            key={Math.random()}
                            url={null}
                            supplierOrInventorySite="inventory-site"
                            origin={offerData.origin}
                        />
                    );
                default:
                    assertUnreachable(offerType);
            }
        },
    }),
    columnHelper.enum((row) => row.costSummary.unit_price?.original.currency, {
        id: 'currency',
        label: () => t`Currency`,
        size: 90,
        initialVisibility: false,
        getOptionLabel: (opt) => opt ?? t`Unknown`,
        cell: (item) => {
            const currency = item.getValue();
            if (!currency) {
                return '-';
            }
            return <DesignSystemTag color={'neutral'} attention={'low'} label={currency} />;
        },
    }),
    columnHelper.enum((row) => extractOffer(row)?.data.price_type, {
        id: 'priceType',
        label: () => t`Price type`,
        size: 90,
        initialVisibility: false,
        options: Object.values(PriceType),
        getOptionLabel: (option) => (option ? transEnum(option, priceTypeTranslations) : t`Unknown`),
        cell: (item) =>
            isPresent(item.getValue()) ? (
                <DesignSystemTag
                    color={'neutral'}
                    attention={'low'}
                    label={transEnum(item.getValue(), priceTypeTranslations)}
                />
            ) : (
                '-'
            ),
        quickFilters: [
            {
                label: () => t`Only inventory offers`, // This looks like a bug - the price type does not indicate if it is an inventory offer or not.
                value: {
                    filterFn: SupportedFilterFn.equalsAny,
                    value: [PriceType.PurchasePrice, PriceType.StandardPrice],
                },
            },
        ],
    }),
    columnHelper.number((row) => extractOffer(row)?.data.unit_of_measurement, {
        id: 'unitOfMeasurement',
        label: () => t`Unit of measurement`,
        size: 120,
        initialVisibility: false,
        renderType: 'generic',
        cell: ({ row }) => {
            const unitOfMeasurement = extractOffer(row.original)?.data.unit_of_measurement;
            return isPresent(unitOfMeasurement) ? (
                <UnitOfMeasurementTag unitOfMeasurement={unitOfMeasurement} />
            ) : (
                <></>
            );
        },
    }),
    columnHelper.enum((row) => row.solutionConfigurationSummary.aggregatedQuantity.unit, {
        id: 'unit',
        label: () => t`Unit`,
        size: 90,
        initialVisibility: false,
        enableHiding: false,
        renderType: 'generic',
        getOptionLabel: (opt) => transEnum(opt, QuantityUnitRecordSingular),
        cell: (item) => transEnum(item.getValue(), QuantityUnitRecordSingular),
    }),
    columnHelper.number((row) => extractFactoryLeadTime(row), {
        id: 'factoryLeadTime',
        label: () => t`Standard factory lead time`,
        size: 120,
        initialVisibility: false,
        cell: (item) => formatDays(item.getValue(), { ifNotPresent: '-' }),
    }),
    columnHelper.monetaryValue((row) => row.costSummary.unit_price?.original, {
        id: 'unitPriceOriginalCurrency',
        label: () => t`Unit price (original currency)`,
        size: 130,
        initialVisibility: false,
        cell: (item) => formatMonetaryValue(item.getValue(), 'unit-price', { ifAbsent: '-' }),
    }),
    columnHelper.monetaryValue((row) => row.costSummary.total_price?.original, {
        id: 'totalPriceOriginalCurrency',
        label: () => t`Total price (original currency)`,
        size: 160,
        initialVisibility: false,
        cell: (item) =>
            formatMonetaryValue(item.getValue(), item.sharedContext.overrideFormatter ?? 'default', { ifAbsent: '-' }),
    }),
    columnHelper.monetaryValue((row) => row.costSummary.one_time_costs?.preferred, {
        id: 'oneTimeCost',
        label: () => t`One-time costs`,
        size: 120,
        initialVisibility: false,
        cell: (item) =>
            formatMonetaryValue(item.getValue(), item.sharedContext.overrideFormatter ?? 'default', { ifAbsent: '-' }),
    }),
    columnHelper.monetaryValue((row) => row.costSummary.one_time_costs?.original, {
        id: 'oneTimeCostOriginalCurrency',
        label: () => t`One-time costs (original currency)`,
        size: 120,
        initialVisibility: false,
        cell: (item) =>
            formatMonetaryValue(item.getValue(), item.sharedContext.overrideFormatter ?? 'default', { ifAbsent: '-' }),
    }),
    columnHelper.text((row) => row.solutionConfigurationSummary.notes, {
        id: 'notes',
        label: () => t`Notes`,
        size: 130,
        initialVisibility: false,
        enableGlobalFilter: false,
        cell: (item) => item.getValue() ?? '',
    }),
    columnHelper.generic(() => null, {
        id: 'comments',
        label: () => t`Comments`,
        size: 100,
        initialVisibility: false,
        enableColumnFilter: false,
        enableSorting: false,
        enableOnRowClick: false,
        align: 'center',
        cell: ({ row, sharedContext }) => {
            const solutionConfiguration = row.original.solutionConfigurationSummary;
            const designItemIds = solutionConfiguration.designItems.map((designItem) => designItem.id);
            const designItemPaths = solutionConfiguration.designItemPaths;

            return (
                <SolutionConfigurationCommentsButton
                    rfqId={sharedContext.rfqId}
                    size={'small'}
                    iconButtonOnly={true}
                    designItemIds={designItemIds}
                    designItemPaths={designItemPaths}
                />
            );
        },
    }),
];

export function useSolutionConfigurationTableState({
    rfqId,
    sourcingScenarioId,
    filteredAssemblyId,
    overrideFormatter,
}: {
    rfqId: string;
    sourcingScenarioId: string;
    filteredAssemblyId: string | undefined;
    overrideFormatter: 'unit-price' | undefined;
}) {
    const { data: supplierAndStockLocations } = useSupplierAndStockLocations();
    const { data: sites } = useSites();
    const scrapQuantityHandlers = useScrapQuantityHandlers();
    const { data: sourcingCalculationDTO } = useSourcingCalculation(sourcingScenarioId);

    const sharedContext = React.useMemo(() => {
        return {
            rfqId,
            scrapQuantityHandlers,
            displaySiteName: sites.length > 1,
            overrideFormatter,
        };
    }, [rfqId, scrapQuantityHandlers, sites, overrideFormatter]);

    const data = React.useMemo(
        () => createSolutionConfigurationTableData(sourcingCalculationDTO, supplierAndStockLocations, sites),
        [sourcingCalculationDTO, supplierAndStockLocations, sites],
    );

    const initialColumnFilters = React.useMemo(() => {
        const assembly = data
            ?.flatMap((i) => i.solutionConfigurationSummary.assemblies)
            .find((a) => a.id === filteredAssemblyId);

        if (!isPresent(assembly)) {
            return undefined;
        }

        return [
            {
                id: 'assembly',
                value: {
                    filterFn: SupportedFilterFn.arrIncludesSome,
                    value: [assembly],
                },
            },
        ];
    }, [data, filteredAssemblyId]);

    return useTanStackTable({
        columns,
        data,
        sharedContext,
        enableColumnHiding: true,
        enableColumnOrdering: true,
        enablePersistentColumnFilters: true,
        enablePersistentGlobalFilter: true,
        enablePersistentRowSelection: `sourcingTable-2:${sourcingScenarioId}`,
        enableSaveAsDefault: `rfq:${rfqId}`,
        enablePersistentScrollPosition: true,
        enableSelection: { enabled: true, getRowId: (row) => row.id },
        initialState: {
            columnPinning: { left: [DEFAULT_SELECT_COLUMN_ID], right: ['status'] },
            columnFilters: initialColumnFilters,
        },
        getRowRoute: (row) => {
            if (sharedContext.scrapQuantityHandlers.isEditable) {
                return null;
            }
            return route('/rfqs/:rfqId/sourcing/:solutionConfigurationId', {
                rfqId: sharedContext.rfqId,
                solutionConfigurationId: row.original.id,
            });
        },
    });
}

export function SolutionConfigurationMiniTable({
    data,
    sharedContext,
}: {
    data: SolutionConfigurationTableData[] | undefined;
    sharedContext: SolutionConfigurationsTableContext;
}) {
    const miniColumns = React.useMemo(() => {
        return columns.filter((column) =>
            ['selectedPart', 'supplier', 'designators', 'unit-price', 'total-price', 'status'].includes(
                column.id ?? '',
            ),
        );
    }, []);

    const { table } = useTanStackTable({
        columns: miniColumns,
        data,
        sharedContext,
        enableColumnHiding: true,
        enableColumnOrdering: true,
        getRowRoute: (row) =>
            route('/rfqs/:rfqId/sourcing/:solutionConfigurationId', {
                rfqId: sharedContext.rfqId,
                solutionConfigurationId: row.original.id,
            }),
    });

    return <TanStackTable table={table} size={'small'} />;
}
