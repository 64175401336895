import { PageLayout } from '../../../../components/PageLayout';
import { UrlParams } from '../../../../utils/routes';
import { SolutionConfigurationsInner } from './SolutionConfigurations';
import { ToolbarSourcingScenario } from './ToolbarSourcingScenario';

export function SolutionConfigurationPage({
    pathParams,
    queryParams,
}: UrlParams<'/rfqs/:rfqId/sourcing/scenarios/:sourcingScenarioId'>) {
    const { rfqId, sourcingScenarioId } = pathParams;
    const { assemblyId } = queryParams;

    return (
        <PageLayout
            header={<ToolbarSourcingScenario rfqId={rfqId} sourcingScenarioId={sourcingScenarioId} />}
            layout="fragment"
        >
            <SolutionConfigurationsInner
                rfqId={rfqId}
                sourcingScenarioId={sourcingScenarioId}
                filteredAssemblyId={assemblyId ?? undefined}
            />
        </PageLayout>
    );
}
