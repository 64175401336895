import { assertUnreachable } from '@luminovo/commons';
import { CommandBarItem, EnrichedCommandbarItem } from './types';

type IterationProps = {
    getNextIndex: () => number;
    parent?: EnrichedCommandbarItem;
    query: string;
    selectedIndex?: number;
};

function enrichCommandBarItem(item: CommandBarItem, props: IterationProps): EnrichedCommandbarItem {
    const { getNextIndex, parent, query } = props;

    const isVisible = isItemVisible(item, query, parent?.original);
    const nextIndex = () => {
        if (!isVisible || item.type === 'separator' || item.type === 'category') {
            return undefined;
        }
        return getNextIndex();
    };

    const enriched: EnrichedCommandbarItem = {
        ...item,
        original: item,
        title: getTitle(item, query),
        isSelected: props.selectedIndex === nextIndex(),
        persistenceKey: generatePersistenceKey(item, props),
        isActive: isItemActive(item, window.location.pathname),
        isVisible,
        parent: props.parent,
        // set the children to an empty array initially.
        // This will be populated in the next lines
        children: [],
    };

    // Populate children first
    if (item.children) {
        enriched.children = item.children.map((child) => enrichCommandBarItem(child, { ...props, parent: enriched }));
    }

    // Update isVisible to expand category if any child is selected
    if (
        item.type === 'category' &&
        enriched.children &&
        enriched.children.length > 0 &&
        enriched.children.some((child) => child.isSelected || child.isActive)
    ) {
        enriched.original = { ...enriched.original, defaultOpen: true };
    }

    return enriched;
}

export function enrichCommandBarItems(
    items: CommandBarItem[],
    { query, selectedIndex }: { query: string; selectedIndex?: number },
): EnrichedCommandbarItem[] {
    let counter = 0;
    const getNextIndex = () => {
        const nextIndex = counter;
        counter = nextIndex + 1;
        return nextIndex;
    };
    return items.map((item) => enrichCommandBarItem(item, { getNextIndex, parent: undefined, query, selectedIndex }));
}

/**
 * Note that the persistenceKey is not guaranteed to be unique. So don't use it like an ID.
 */
function generatePersistenceKey(item: CommandBarItem, { parent }: { parent?: EnrichedCommandbarItem }): string {
    const href = item.type === 'link' ? item.href : '';
    const title = item.title ?? '';

    const localId = `${item.type}::${href}::${title}`;
    if (!parent) {
        return localId;
    }
    return `${parent.persistenceKey}/${localId}`;
}

function isItemVisible(item: CommandBarItem, query: string, parent?: CommandBarItem): boolean {
    if (item.alwaysHidden) {
        return false;
    }
    // when not querying, show all items
    if (query.length === 0) {
        return item.defaultVisible ?? true;
    }

    if (item.type === 'separator') {
        return false;
    }
    const titleAlternatives = [item.title ?? '', ...(item.alternatives ?? []), parent?.title ?? '']
        .map((alt) => alt.toLowerCase().trim())
        .filter((x) => x.length > 0);
    const isTitleMatch = titleAlternatives.some((part) => part.includes(query));

    if (item.type === 'category') {
        return isTitleMatch || (item.children ?? []).some((child) => isItemVisible(child, query));
    }

    if (item.type === 'link') {
        return isTitleMatch;
    }

    if (item.type === 'component') {
        return isTitleMatch;
    }

    if (item.type === 'button') {
        return isTitleMatch;
    }

    assertUnreachable(item);
}

function isItemActive(item: CommandBarItem, pathname: string): boolean {
    if (item.type !== 'link') {
        return false;
    }
    if (item.isActive !== undefined) {
        return item.isActive;
    }
    const href = item.href ?? '';
    if (href.length === 0) {
        return false;
    }
    if (item.match === 'exact') {
        return href === pathname;
    }

    if (item.match === 'startsWith' || item.match === undefined) {
        return pathname.startsWith(href);
    }
    return false;
}

function getTitle(item: CommandBarItem, query: string): string {
    const alternatives = [item.title ?? '', ...(item.alternatives ?? [])];

    const title = alternatives.find((alt) => alt.toLowerCase().includes(query));
    if (title) {
        return title;
    }
    return item.title ?? '';
}

export function findSelectedItem(items: EnrichedCommandbarItem[]): EnrichedCommandbarItem | undefined {
    for (const item of items) {
        if (item.isSelected) {
            return item;
        }
        const selectedItem = findSelectedItem(item.children ?? []);
        if (selectedItem) {
            return selectedItem;
        }
    }
    return undefined;
}
