import * as z from 'zod';
import { ApiIntegrationStatusRuntype } from './3rdPartyBackendTypes';

export type MyArrowLocaleCurrencyDTO = z.infer<typeof MyArrowLocaleCurrencyDTORuntype>;
export const MyArrowLocaleCurrencyDTORuntype = z.enum([
    'USD',
    'EUR',
    'PLN',
    'GBP',
    'CHF',
    'CAD',
    'DKK',
    'SEK',
    'NOK',
    'CZK',
    'ILS',
    'HKD',
    'JPY',
]);

export interface MyArrowCredentialsDTO extends z.infer<typeof MyArrowCredentialsDTORuntype> {}
export const MyArrowCredentialsDTORuntype = z.object({
    client_id: z.string(),
    client_secret: z.string(),
    currency: MyArrowLocaleCurrencyDTORuntype,
});

export interface MyArrowCredentialsInputDTO extends z.infer<typeof MyArrowCredentialsInputDTORuntype> {}
export const MyArrowCredentialsInputDTORuntype = z.object({
    client_id: z.string().optional(),
    client_secret: z.string().optional(),
    currency: MyArrowLocaleCurrencyDTORuntype.optional(),
});

export interface MyArrowResponseBodyDTO extends z.infer<typeof MyArrowResponseBodyDTORuntype> {}
export const MyArrowResponseBodyDTORuntype = z.object({
    data: MyArrowCredentialsDTORuntype.nullable(),
    status: ApiIntegrationStatusRuntype,
});
