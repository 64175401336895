import * as z from 'zod';
import { QuantityUnitDTORuntype } from '../backendTypes';

const IPNRuntype = z.object({
    value: z.string(),
    revision: z.string().nullable().optional(),
});

const DemandTypeRuntype = z.union([z.literal('gross'), z.literal('net')]);

const DemandCreationRequestSingleRuntype = z.object({
    type: DemandTypeRuntype,
    quantity: QuantityUnitDTORuntype,
    ship_to_site_number: z.string().optional(),
    ship_to_site_id: z.string().optional(),
    delivery_start_date: z.string(),
    delivery_end_date: z.string(),
    supplier_site_number: z.string().optional(),
    supplier_site_id: z.string().optional(),
    internal_part_number: IPNRuntype,
    production_start_date: z.string().optional(),
    end_customer_number: z.string().optional(),
    end_customer_id: z.string().optional(),
});

export type DemandPostDTO = z.infer<typeof DemandPostDTORuntype>;
export const DemandPostDTORuntype = z.object({
    context: z.string().nullable(),
    rfq_id: z.string(),
    assembly_id: z.string(),
    type: DemandTypeRuntype,
    quantity: QuantityUnitDTORuntype,
    ship_to_site_number: z.string().nullable(),
    ship_to_site_id: z.string().nullable(),
    delivery_start_date: z.string().nullable(),
    delivery_end_date: z.string().nullable(),
    supplier_site_number: z.string().nullable(),
    supplier_site_id: z.string().nullable(),
    production_start_date: z.string().nullable(),
    end_customer_number: z.string().nullable(),
    end_customer_id: z.string().nullable(),
});

export const DemandCreationRequestRuntype = z.object({
    items: z.array(DemandCreationRequestSingleRuntype),
    context: z.string(),
});

// If you want to narrow the types, please ensure you also include the error variants too
// to allow better error handling for the demand importer.
export const MultiStatusRuntype = z.object({
    description: z.string(),
    internal_part_number: IPNRuntype,
    status: z.number(),
});

export const DemandKeyIdRuntype = z.object({
    kind: z.union([z.literal('Component'), z.literal('OTSPart')]),
    content: z.string(),
});

const IpnIdRuntype = z.object({
    type: z.literal('Component'),
    component_ipn: z.object({
        value: z.string(),
        revision: z.string().nullable().optional(),
    }),
});

const AssemblyIdRuntype = z.object({
    type: z.literal('Assembly'),
    assembly_id: z.string(),
});

const DemandResponseIdRuntype = z.union([IpnIdRuntype, AssemblyIdRuntype]);

const DemandResposeSiteRuntype = z.object({
    id: z.string(),
    name: z.string(),
    site_number: z.string().nullable().optional(),
});

export const DemandResponseRuntype = z.object({
    id: DemandResponseIdRuntype,
    delivery_start_date: z.string(),
    delivery_end_date: z.string(),
    production_start_date: z.string().nullable().optional(),
    net_quantity: QuantityUnitDTORuntype,
    gross_quantity: QuantityUnitDTORuntype,
    inventory_quantity: QuantityUnitDTORuntype.nullable(),
    ship_to_site: DemandResposeSiteRuntype,
    supplier: DemandResposeSiteRuntype,
    origin: z.string(),
});

export type DemandResponseDTO = z.infer<typeof DemandResponseRuntype>;

export const DemandSupplierMissingSiteNumberWarningRuntype = z.object({
    type: z.literal('DemandSupplierMissingSiteNumber'),
    data: z.object({
        site_name: z.string(),
    }),
});

export const DemandRecipientMissingSiteNumberWarningRuntype = z.object({
    type: z.literal('DemandRecipientMissingSiteNumber'),
    data: z.object({
        site_name: z.string(),
    }),
});

export const DemandExportResponseWarningRuntype = z.union([
    DemandSupplierMissingSiteNumberWarningRuntype,
    DemandRecipientMissingSiteNumberWarningRuntype,
]);

export const DemandsExportResponseRuntype = z.array(DemandResponseRuntype);

export const DemandsExportRequestBodyRuntype = z.object({
    target_date: z.string().optional(),
    ipns: z.array(z.string()).optional(),
    supplier_numbers: z.array(z.string()).optional(),
    contexts: z.array(z.string()).optional(),
});

export const SourceListImportRuntype = z.object({
    internal_part_number: IPNRuntype,
    site_number: z.string().optional(),
    start_date: z.string(),
    end_date: z.string().optional(),
    supplier_number: z.string(),
});

export const SourceListImportArrayRuntype = z.array(SourceListImportRuntype);

// keep in sync with pub enum SourceListImportErrors in rust-workspace/routes-demand/src/source_list.rs
const SiteNotFoundErrorRuntype = z.object({
    error: z.literal('SiteNotFound'),
    details: z.string(),
});
const IpnNotFoundErrorRuntype = z.object({
    error: z.literal('IpnNotFound'),
    details: IPNRuntype,
});
const SupplierNotFoundErrorRuntype = z.object({
    error: z.literal('SupplierNotFound'),
    details: z.string(),
});
const AmbiguousSiteNumberErrorRuntype = z.object({
    error: z.literal('AmbiguousSiteNumber'),
    details: z.string(),
});
const InvalidDatesErrorRuntype = z.object({
    error: z.literal('InvalidDates'),
    details: z.object({ start_date: z.string(), end_date: z.string() }),
});

const SourceListImportResponseErrorRuntype = z.union([
    SiteNotFoundErrorRuntype,
    IpnNotFoundErrorRuntype,
    SupplierNotFoundErrorRuntype,
    AmbiguousSiteNumberErrorRuntype,
    InvalidDatesErrorRuntype,
]);
export const SourceListImportResponseRuntype = z.object({
    errors: z.array(SourceListImportResponseErrorRuntype),
    imported: z.number(),
});

export type SourceListImportResponseErrorDTO = z.infer<typeof SourceListImportResponseErrorRuntype>;
