import * as z from 'zod';
import { SelfSignupColorsRuntype } from '../organizationSettings';

export const PublicTenantRuntype = z.object({
    organization_name: z.string(),
    terms_and_conditions_link: z.string(),
    is_self_signup_enabled: z.boolean(),
    are_private_customers_allowed: z.boolean(),
    organization_logo: z.string().nullable(),
    colors: SelfSignupColorsRuntype,
});
