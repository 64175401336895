import { transEnum } from '@luminovo/commons';
import { ComplianceStatus } from '@luminovo/http-client';
import { ComplianceStatusTranslations } from '../components/chips/ComplianceStatusChip';

export function formatComplianceStatus(status?: ComplianceStatus | null | undefined): string {
    if (!status) {
        return '-';
    }
    return transEnum(status, ComplianceStatusTranslations);
}
