import * as z from 'zod';
import { endpoint } from '../http/endpoint';
import {
    TrustedPartsCredentialsInputDTORuntype,
    TrustedPartsResponseBodyDTORuntype,
    TrustedPartsSupportedSettingDTORuntype,
} from './trustedPartsBackendTypes';

export const trustedPartsEndpoints = {
    'GET /3rdparty/trustedparts/credentials': endpoint({
        description: 'Returns credentials for trustedparts integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: TrustedPartsResponseBodyDTORuntype,
    }),
    'POST /3rdparty/trustedparts/credentials': endpoint({
        description: 'Uploads credentials for trustedparts API integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: TrustedPartsCredentialsInputDTORuntype,
        responseBody: TrustedPartsResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/trustedparts/credentials'],
    }),
    'PATCH /3rdparty/trustedparts/credentials': endpoint({
        description: 'Updates credentials for trustedparts API integratoin',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: TrustedPartsCredentialsInputDTORuntype,
        responseBody: TrustedPartsResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/trustedparts/credentials'],
    }),
    'DELETE /3rdparty/trustedparts/credentials': endpoint({
        description: 'Deletes existing credentials for trustedparts API integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({ deleted: z.number() }),
        invalidates: ['GET /3rdparty/trustedparts/credentials'],
    }),

    'GET /3rdparty/trustedparts/supported-locale-settings': endpoint({
        description: 'Returns supported store locations and currencies for trustedparts API integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({ locales: z.array(TrustedPartsSupportedSettingDTORuntype) }),
    }),
};
