import * as z from 'zod';
import { ExcelLinesRuntype, QuantityUnitRuntype, RowRuntype } from '../backendTypes';
import { ErrorCodeRuntype } from '../errorCodes';
import { PartSuggestionOriginRuntype, TechnicalParametersRuntype } from '../part';
import { PartSpecificationRuntype } from '../part/fullPartBackendTypes';
import { BomImporterIssueRuntype } from './bomImporterIssuesEnum';

export type HeaderMetadata = z.infer<typeof HeaderMetadataRuntype>;
const HeaderMetadataRuntype = z.object({
    header_row_number: z.number(),
    raw_header_row: RowRuntype,
});

export type SingleOriginalExcelRow = z.infer<typeof SingleOriginalExcelRowRuntype>;
const SingleOriginalExcelRowRuntype = z.object({
    line_number: z.number(),
    raw_original_line: RowRuntype,
    issues: z.array(BomImporterIssueRuntype),
});

const PartSuggestionRuntype = z.union([
    z.object({
        part: z.object({
            type: z.enum(['OffTheShelf', 'Generic', 'Ipn']),
            data: z.string(),
        }),
        origin: PartSuggestionOriginRuntype.nullable().optional(),
    }),
    z.object({
        part: z.object({
            type: z.literal('IncompleteGeneric'),
            data: TechnicalParametersRuntype,
        }),
        origin: PartSuggestionOriginRuntype.nullable().optional(),
    }),
]);

const BomItem = z.object({
    designators: z.array(z.string()),
    quantity_per_designator: z.number().nullable(),
    dnp: z.boolean(),
    unit: QuantityUnitRuntype,
    notes: z.string().optional(),
    part_specification: PartSpecificationRuntype.nullable(),
    part_suggestions: z.array(PartSuggestionRuntype),
    is_consigned: z.boolean(),
});

export interface BomImporterLine extends z.infer<typeof BomImporterLineRuntype> {}

const BomImporterLineRuntype = z.object({
    excel_origins: z.array(SingleOriginalExcelRowRuntype),
    bom_item: BomItem.nullable(),
    issues: z.array(BomImporterIssueRuntype),
});

// We do not want to display some of the column types we get from the bom importer,
// because they would only add more confusion to the user
export type DisplayableColumnIds = Exclude<keyof ColumnMap, 'enumeration' | 'generic_part_type'>;

export type ColumnMap = z.infer<typeof ColumnMapRuntype>;
const ColumnMapRuntype = z.object({
    designators: z.array(z.number()),
    quantity: z.array(z.number()),
    unit: z.array(z.number()),
    dnp: z.array(z.number()),
    manufacturer_free: z.array(z.number()),
    generic_part_type: z.array(z.number()),
    manufacturer: z.array(z.number()),
    mpn: z.array(z.number()),
    supplier_part_number: z.array(z.number()),
    cpn: z.array(z.number()),
    technical_parameters: z.array(z.number()),
    notes: z.array(z.number()),
    enumeration: z.array(z.number()),
    ipn: z.array(z.number()),
    consigned: z.array(z.number()),
    cpn_revision: z.array(z.number()),
    export_pass_through: z.array(z.number()),
    level: z.array(z.number()),
    // @Tev - remove the .optional() call when the assembly_name feature lands on prod
    assembly_name: z.array(z.number()).optional(),
});

// input for step 1 is just excel file and how we did it for /converter endpoint

export type BomScreeningSheet = z.infer<typeof BomScreeningSheetRunType>;
export const BomScreeningSheetRunType = z.object({
    global_issues_response: z.array(BomImporterIssueRuntype),
    // The thing you want to edit
    column_map: ColumnMapRuntype,
    // We currently represent excel lines just as dumped json string but that should
    // potentially change in this story, I'm leaving it as was it for now though
    excel_lines: z.array(SingleOriginalExcelRowRuntype),
    header_metadata: z.null().or(HeaderMetadataRuntype),
    sheet_name: z.string(),
    reused_column_map: z.boolean(),
});

export type ScreenerHandlerOutput = z.infer<typeof ScreenerHandlerOutputRuntype>;
export const ScreenerHandlerOutputRuntype = z.object({
    file_name: z.string(),
    sheets: z.array(BomScreeningSheetRunType),
});

export type LineScreenerErrorOutput = z.infer<typeof LineScreenerErrorOutputRuntype>;
export const LineScreenerErrorOutputRuntype = z.object({
    file_name: z.string(),
    error_code: ErrorCodeRuntype,
});

export function isScreenerHandlerOutput(
    bom: LineScreenerErrorOutput | ScreenerHandlerOutput,
): bom is ScreenerHandlerOutput {
    return 'sheets' in bom;
}

export function isLineScreenerErrorOutput(
    bom: LineScreenerErrorOutput | ScreenerHandlerOutput,
): bom is LineScreenerErrorOutput {
    return 'error_code' in bom;
}

// The output we get for step 1, accepts excel file returns information for first screen

export const BomScreeningOutputRuntype = z.union([ScreenerHandlerOutputRuntype, LineScreenerErrorOutputRuntype]);

export enum PreviousLinesSearchVariant {
    Inactive = 'Inactive',
    AllColumns = 'AllColumns',
    SelectedColumns = 'SelectedColumns',
}

export type PreviousLinesSearchColumns = z.infer<typeof PreviousLinesSearchColumnsRuntype>;
const PreviousLinesSearchColumnsRuntype = z.object({
    variant: z.nativeEnum(PreviousLinesSearchVariant),
    columns: z.array(z.string()),
});

export type AmlLineBuildingInput = z.infer<typeof AmlLineBuildingInputRuntype>;
const AmlLineBuildingInputRuntype = z.object({
    column_map: ColumnMapRuntype,
    relevant_excel_lines: z.array(SingleOriginalExcelRowRuntype),
    aml_header_metadata: z.null().or(HeaderMetadataRuntype),
    sheet_name: z.string(),
});

// Input for step 2
export type BomLineBuildingInput = z.infer<typeof BomLineBuildingInputRuntype>;
export const BomLineBuildingInputRuntype = z.object({
    column_map: ColumnMapRuntype,
    relevant_excel_lines: z.array(SingleOriginalExcelRowRuntype),
    file_name: z.string(),
    bom_header_metadata: z.null().or(HeaderMetadataRuntype),
    sheet_name: z.string(),
    rfq_id: z.string(),
    importing_assembly: z.string(),
    previous_lines_search_columns: PreviousLinesSearchColumnsRuntype,
    aml_input: z.null().or(AmlLineBuildingInputRuntype),
});

export type BomLineBuildingOutput = z.infer<typeof BomLineBuildingOutputRuntype>;

const ParentOrAncestorData = z.object({
    id: z.string(),
    quantity: z.number().optional(),
});

const BomImporterAssemblyParentOrAncestor = z.object({
    ancestor_type: z.literal('Ancestor').or(z.literal('Parent')),
    data: ParentOrAncestorData,
});

const BomImporterAssemblyAncestorMapping = z.array(z.array(BomImporterAssemblyParentOrAncestor));

const CpnRevision = z.object({
    value: z.string(),
    revision: z.string().nullable(),
});

export const BomImportedAssemblyIssueRuntype = z.union([
    z.object({
        issue_type: z.literal('AutomaticallyGenerated'),
    }),
    z.object({
        issue_type: z.literal('ConflictingIpnIdentifier'),
        data: z.array(z.string()),
    }),
    z.object({
        issue_type: z.literal('ConflictingCpnIdentifier'),
        data: z.array(CpnRevision),
    }),
]);

export type BomImportedAssemblyIssue = z.infer<typeof BomImportedAssemblyIssueRuntype>;

const BomImporterAssemblyParentRuntype = z.object({
    id: z.string(),
    quantity: z.number(),
});

const BomImporterAssemblyInput = z.object({
    id: z.string(),
    designator: z.string(),
    issues: z.array(BomImportedAssemblyIssueRuntype),
    parents: z.array(BomImporterAssemblyParentRuntype),
    ancestor_mapping: BomImporterAssemblyAncestorMapping,
    excel_origins: ExcelLinesRuntype.nullable(),
});

export const BomLineBuildingOutputRuntype = z.object({
    lines_in_assembly: z.record(z.string(), z.array(BomImporterLineRuntype)),
    assemblies: z.array(BomImporterAssemblyInput),
});

export interface BomLineBuildingErrorOutput extends z.infer<typeof BomLineBuildingErrorOutputRuntype> {}

const AssemblyRecognitionErrorOutputRuntype = z.object({
    error_type: z.enum(['UnreachableAssemblyState', 'UnrecognizedTopLevelAssembly', 'UnrecognizedAssembly']),
    context: z.string().nullable(),
});

export type AssemblyRecognitionError = z.infer<typeof AssemblyRecognitionErrorOutputRuntype>;
export const BomLineBuildingErrorOutputRuntype = z.object({
    file_name: z.string(),
    data: z.union([BomScreeningSheetRunType, AssemblyRecognitionErrorOutputRuntype]),
});

const BomImporterBackendHeaderMetadataRuntype = z.object({
    header_line_number: z.number(),
    raw_header_line: RowRuntype,
});

export type BomImporterBackendInput = z.infer<typeof BomImporterBackendInputRuntype>;
export const BomImporterBackendInputRuntype = z.object({
    header_metadata: z.optional(BomImporterBackendHeaderMetadataRuntype.nullable()),
    column_map: ColumnMapRuntype,
    importing_assembly: z.string(),
    assemblies: z.array(BomImporterAssemblyInput),
    lines_per_assembly: z.record(z.string(), z.array(BomImporterLineRuntype)),
    accept_previous_bom_lines: z.boolean(),
    previous_lines_search_variant: z.nativeEnum(PreviousLinesSearchVariant),
});

export const BomImporterErrorResponseRuntype = z.object({
    detail: z.string(),
    title: z.string(),
    status: z.number(),
    code: ErrorCodeRuntype,
});

export type BomFileDTO = z.infer<typeof BomFileDTORuntype>;
export const BomFileDTORuntype = z.object({
    id: z.string(),
    raw_header: RowRuntype,
    // this assembly field will be deprecated soon
    assembly: z.string().optional(),
    // TODO: @Tev will remove this optional tag on the assembly_ids field in a separate MR, once the backend changes have been merged
    assembly_ids: z.array(z.string()).optional(),
    column_map: ColumnMapRuntype.nullable(),
});

export enum BomProgressStage {
    FileReading = 'FileReading',
    Screening = 'Screening',
    CrossChecking = 'CrossChecking',
    LineBuilding = 'LineBuilding',
    Completed = 'Completed',
}

const BomProgressStageRuntype = z.nativeEnum(BomProgressStage);

export type TaskAcceptedResponse = z.infer<typeof TaskAcceptedResponseRuntype>;
export const TaskAcceptedResponseRuntype = z.object({
    task_endpoint: z.string(),
});

export type TechnicalParams = z.infer<typeof TechnicalParamsRuntype>;
export const TechnicalParamsRuntype = z.record(z.string(), z.unknown());

export const BomProgressRuntype = z.union([
    z.object({
        status: z.literal('PROGRESS'),
        progress: z.number(),
        task_id: z.string(),
        data: z.object({ stage: z.optional(BomProgressStageRuntype) }).nullable(),
    }),
    z.object({
        status: z.literal('SUCCESS'),
        progress: z.number(),
        task_id: z.string(),
        data: z.object({
            data: z.union([BomLineBuildingOutputRuntype, BomScreeningOutputRuntype]),
        }),
    }),
    z.object({
        status: z.literal('FAILED'),
        task_id: z.string(),
        data: z
            .object({
                data: BomLineBuildingErrorOutputRuntype,
            })
            .nullable(),
    }),
]);
