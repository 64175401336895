import { isPresent } from '@luminovo/commons';
import { CustomerFormState } from '../types';

function isPresentAndNotEmpty<TValue>(value: TValue | null | undefined | string | Array<any>): value is TValue {
    return isPresent(value) && value !== '';
}

export function normalizeFormState(formState: CustomerFormState): CustomerFormState {
    const form = { ...formState };

    if (!isPresent(form.billingAddress)) {
        return form;
    }

    if (form.customerType.type === 'Business') {
        form.customerType.data.vatId = form.customerType.data.vatId?.trim() || undefined;
    }

    form.billingAddress.line1 = form.billingAddress.line1?.trim() || '';
    form.billingAddress.line2 = form.billingAddress.line2?.trim() || '';
    form.billingAddress.postalCode = form.billingAddress.postalCode?.trim() || '';
    form.billingAddress.city = form.billingAddress.city?.trim() || '';
    form.billingAddress.countryCode = form.billingAddress.countryCode || undefined;

    if (
        !isPresentAndNotEmpty(form.billingAddress.line1) &&
        !isPresentAndNotEmpty(form.billingAddress.line2) &&
        !isPresentAndNotEmpty(form.billingAddress.postalCode) &&
        !isPresentAndNotEmpty(form.billingAddress.city) &&
        !isPresentAndNotEmpty(form.billingAddress.countryCode)
    ) {
        form.billingAddress = undefined;
    }

    return form;
}
