import { t } from '@lingui/macro';
import { MenuItem, useConfirmationDialog } from '@luminovo/design-system';
import { Column, GridApi } from 'ag-grid-community';

export function MenuItemClearAllValues<T>({
    column,
    api,
    onClose,
}: {
    column: Column<T>;
    api: GridApi<T>;
    onClose: () => void;
}): JSX.Element {
    const { modal, setOpen } = useConfirmationDialog({
        title: t`Clear all column values`,
        description: t`Are you sure you want to clear all values in this column?`,
        onConfirmation: () => {
            api.forEachNode((node) => {
                node.setDataValue(column.getColId(), null);
            });
            onClose();
        },
    });
    if (column.getColDef().editable === false) {
        return <></>;
    }
    return (
        <>
            {modal}
            <MenuItem
                onClick={() => {
                    setOpen(true);
                }}
                label={'Clear all values'}
            />
        </>
    );
}
