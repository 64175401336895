import * as z from 'zod';
import { ApiIntegrationStatusRuntype } from './3rdPartyBackendTypes';

export type RsComponentsStoreIdDTO = z.infer<typeof RsComponentsStoreIdDTORuntype>;
export type RsComponentsCountryCodeDTO = z.infer<typeof RsComponentsCountryCodeDTORuntype>;

export const RsComponentsStoreIdDTORuntype = z.enum([
    'AT_1',
    'AU_1',
    'BE_1',
    'CN_1',
    'CZ_1',
    'DE_1',
    'DK_1',
    'ES_1',
    'FR_1',
    'GB_1',
    'HK_1',
    'HU_1',
    'IE_1',
    'IT_1',
    'JP_1',
    'MY_1',
    'NL_1',
    'NO_1',
    'NZ_1',
    'PH_1',
    'PL_1',
    'SE_1',
    'SG_1',
    'TH_1',
    'TW_1',
    'ZA_1',
]);

export const RsComponentsCountryCodeDTORuntype = z.enum([
    'AE',
    'AT',
    'AU',
    'AZ',
    'BA',
    'BE',
    'BR',
    'CL',
    'CN',
    'CY',
    'CZ',
    'DE',
    'DK',
    'EE',
    'ES',
    'FI',
    'FR',
    'GB',
    'GR',
    'HK',
    'HR',
    'HU',
    'ID',
    'IE',
    'IL',
    'IN',
    'IT',
    'JP',
    'KZ',
    'LT',
    'LV',
    'LY',
    'ME',
    'MT',
    'MX',
    'MY',
    'NL',
    'NO',
    'NZ',
    'PH',
    'PL',
    'PT',
    'RO',
    'RS',
    'RU',
    'SA',
    'SE',
    'SG',
    'SK',
    'TH',
    'TR',
    'TW',
    'UA',
    'ZA',
]);

export interface RsComponentsCredentialsDTO extends z.infer<typeof RsComponentsCredentialsDTORuntype> {}
export const RsComponentsCredentialsDTORuntype = z.object({
    client_id: z.string().optional(),
    client_secret: z.string().optional(),
    customer_number: z.string().nullable().optional(),
    store_id: RsComponentsStoreIdDTORuntype,
    country_code: RsComponentsCountryCodeDTORuntype,
});

export interface RsComponentsCredentialsInputDTO extends z.infer<typeof RsComponentsCredentialsInputDTORuntype> {}
export const RsComponentsCredentialsInputDTORuntype = z.object({
    client_id: z.string(),
    client_secret: z.string(),
    customer_number: z.string().nullable(),
    store_id: RsComponentsStoreIdDTORuntype,
    country_code: RsComponentsCountryCodeDTORuntype,
});

export interface RsComponentsResponseBodyDTO extends z.infer<typeof RsComponentsResponseBodyDTORuntype> {}
export const RsComponentsResponseBodyDTORuntype = z.object({
    data: RsComponentsCredentialsDTORuntype.nullable(),
    status: ApiIntegrationStatusRuntype,
});
