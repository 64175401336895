import { t, Trans } from '@lingui/macro';
import { assertUnreachable, formatCurrency, isPresent } from '@luminovo/commons';
import { chainComparators, colorSystem, compareByNumber } from '@luminovo/design-system';
import { CustomPartTypeEnum } from '@luminovo/http-client';
import { HorizontalStackedBarChart } from '@luminovo/viz';
import { useRfQ } from '../../../../resources/rfq/rfqHandler';
import { SolutionConfigurationTableData } from '../SolutionConfigurations/SolutionConfigurationTypes';
import { ChartSpec } from './ChartSpec';

type Datum = {
    id: string[];
    label: string;
    unitPrice?: number;
    scrapCost?: number;
    unitTcoCost?: number;
    discount?: number;
    lineValue?: number;
    consigned?: number;
    noOffers?: number;
};
type Keys = 'discount' | 'unitPrice' | 'scrapCost' | 'unitTcoCost' | 'consigned' | 'noOffers' | 'lineValue';

function generateLabel(datum: SolutionConfigurationTableData) {
    const designatorLabel = datum.solutionConfigurationSummary.designItems
        .map((designItem) => designItem.designator)
        .join(', ');

    const isPcb =
        datum.partSummary.selected_part?.kind === 'Custom' &&
        datum.partSummary.selected_part?.type.name === CustomPartTypeEnum.PCB;
    const pcbAssemblyName = datum.solutionConfigurationSummary.pcbAssemblyName;

    if (isPcb && isPresent(pcbAssemblyName)) {
        return `${designatorLabel} (${pcbAssemblyName})`;
    }

    return designatorLabel;
}

export const chartSpecUnitPrice: ChartSpec<Keys, Datum> = {
    id: 'unitPrice',
    title: <Trans>Unit price by</Trans>,
    aggregate(a, b) {
        throw new Error("Unit price shouldn't aggregate");
    },
    keys: ['discount', 'unitPrice', 'unitTcoCost', 'scrapCost', 'lineValue', 'consigned', 'noOffers'],
    groupBy: [
        {
            // Notice that designators can be duplicated in multi-assembly boms,
            // so we actually can't group by the designators.
            extractor: (x) => x.id,
            label: <Trans>Designator</Trans>,
        },
    ],
    map(datum) {
        const id = [datum.id];
        const label = generateLabel(datum);
        const offerSummaryType = datum.offerSummary.type;

        switch (offerSummaryType) {
            case 'Consigned':
                return {
                    id,
                    label,
                    consigned: 1,
                };
            case 'NoOffer':
                return {
                    id,
                    label,
                    noOffers: 1,
                };
            case 'Offer':
                const cost = datum.costSummary;
                const scrapCost = Number(cost.scrap_cost?.preferred.amount ?? null);
                const unitPrice = Number(cost.unit_price?.preferred.amount ?? null);
                const lineValue = Number(cost.line_value?.preferred.amount ?? null);
                let additionalCost: number | undefined = Number(cost.tco_costs?.unit.cost?.preferred.amount ?? null);

                let discount = 0;
                if (additionalCost < 0) {
                    discount = additionalCost;
                    additionalCost = 0;
                }

                return {
                    id,
                    label,
                    unitPrice,
                    scrapCost,
                    unitTcoCost: additionalCost,
                    discount,
                    lineValue,
                };
            default:
                assertUnreachable(offerSummaryType);
        }
    },
    orderBy: chainComparators(
        compareByNumber((a) => -(a.unitPrice ?? 0)),
        compareByNumber((d) => d.noOffers ?? 0),
        compareByNumber((a) => a.consigned ?? 0),
    ),
    render: ChartUnitPrice,
};

function ChartUnitPrice({
    rfqId,
    keys,
    data,
    onSelectDatum,
}: {
    rfqId: string;
    keys: Keys[];
    data: Datum[];
    onSelectDatum(datum: Datum): void;
}) {
    const { data: rfq } = useRfQ(rfqId);
    const currency = rfq?.currency;

    if (!currency) {
        return null;
    }

    return (
        <HorizontalStackedBarChart
            keys={keys}
            data={data}
            formatValue={(x) => formatCurrency(x, currency)}
            getTooltip={(key) => {
                if (key === 'lineValue') {
                    return [t`TCO unit price`, t`Designators`, t`Quantity per designator`].join(' × ');
                }
                return undefined;
            }}
            unstackedKey="lineValue"
            formatKey={(key) => {
                if (key === 'consigned') {
                    return t`Consigned`;
                }
                if (key === 'noOffers') {
                    return t`No offers`;
                }
                if (key === 'discount') {
                    return t`Discount`;
                }
                if (key === 'unitPrice') {
                    return t`Unit price`;
                }
                if (key === 'scrapCost') {
                    return t`Scrap cost`;
                }
                if (key === 'unitTcoCost') {
                    return t`Unit TCO cost`;
                }
                if (key === 'lineValue') {
                    return t`Line value`;
                }
                assertUnreachable(key);
            }}
            onBarClick={onSelectDatum}
            isMissingData={(datum, key) => {
                if (key === 'consigned' && datum.consigned) {
                    return true;
                }
                if (key === 'noOffers' && datum.noOffers) {
                    return true;
                }
                return false;
            }}
            getColor={(key) => {
                if (key === 'noOffers') {
                    return colorSystem.red[4];
                }
                if (key === 'consigned') {
                    return colorSystem.green[4];
                }
                if (key === 'discount') {
                    return colorSystem.primary[3];
                }
                if (key === 'unitPrice') {
                    return colorSystem.primary[5];
                }
                if (key === 'scrapCost') {
                    return colorSystem.yellow[3];
                }
                if (key === 'unitTcoCost') {
                    return colorSystem.yellow[6];
                }
                if (key === 'lineValue') {
                    return colorSystem.neutral[5];
                }

                assertUnreachable(key);
            }}
            width={800}
        />
    );
}
