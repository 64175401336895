import * as z from 'zod';

import { PCBFileTypesRuntimeType, PcbServerErrorTypeRuntype } from './pcbBackendTypes';

const PCBGraphicPositionRuntype = z.object({
    x: z.number(),
    y: z.number(),
});

export type PCBGraphicPosition = z.infer<typeof PCBGraphicPositionRuntype>;

const PCBGraphicDimensionRuntype = z.object({
    min: PCBGraphicPositionRuntype,
    max: PCBGraphicPositionRuntype,
});

const PCBGraphicFormatRuntype = z.object({
    unit: z.string(),
    resolution: z.number(),
    dimension: PCBGraphicDimensionRuntype.optional(),
    complexity: z.number().optional(),
    scaling: z.number().optional(),
    gerberscale: z.number().optional(),
});

const PCBGraphicFileTypeRuntype = z.object({
    service: z.string(),
    category: z.string().optional(),
    fileType: PCBFileTypesRuntimeType,
    productionFile: z.boolean(),
    mimeType: z.string().optional(),
    index: z.number().optional(),
});

const PCBGraphicMetaInfoRuntype = z.object({
    trace_width: z.number().optional(),
    copper_clearance: z.number().optional(),
});

const PCBGraphicFileRuntype = z.object({
    id: z.string().optional(),
    name: z.string().optional(),
    format: PCBGraphicFormatRuntype.optional(),
    fileType: PCBGraphicFileTypeRuntype.optional(),
    metaInfo: PCBGraphicMetaInfoRuntype,
    inverted: z.boolean(),
});

const PCBGraphicPathDefUseRuntype = z.object({
    reference: z.string(),
    location: PCBGraphicPositionRuntype,
});

const PCBGraphicPathDefRuntype = z.object({
    id: z.string(),
    path: z.string(),
});

export type PCBGraphicPathDef = z.infer<typeof PCBGraphicPathDefRuntype>;

const PCBGraphicPathRuntype = z.object({
    use: PCBGraphicPathDefUseRuntype.optional(),
    elementIds: z.array(z.string()).optional(),
    trace: z.string().optional(),
    path: z.string().optional(),
    orientation: z
        .object({
            degrees: z.number(),
            mirrored: z.boolean(),
        })
        .optional(),
});

export type PCBGraphicPath = z.infer<typeof PCBGraphicPathRuntype>;

const PCBGraphicCopperJsonRuntype = z.object({
    viewbox: PCBGraphicDimensionRuntype,
    format: PCBGraphicFormatRuntype,
    count: z.number(),
    paths: z.array(z.array(PCBGraphicPathRuntype)),
    defs: z.array(PCBGraphicPathDefRuntype),
});

export type PCBGraphicCopperJson = z.infer<typeof PCBGraphicCopperJsonRuntype>;

const PCBGraphicLayerDefinitionMetaRuntype = z.object({
    side: z.string(),
    polarity: z.string(),
    displayname: z.string(),
    cucount: z.number().optional(),
});

const PCBGraphicLayerDefinitionMaterialMetaRuntype = z.object({
    cuthickness: z.number().optional(),
    type: z.string().optional(),
});

const PCBGraphicLayerDefinitionMaterialRuntype = z.object({
    meta: PCBGraphicLayerDefinitionMaterialMetaRuntype.optional(),
    materialType: z.string().optional(),
});

export enum PCBLayerTypes {
    SOLDERMASK = 'soldermask',
    FOIL = 'foil',
    PREPREG = 'prepreg',
    CORE = 'core',
    FLEXCORE = 'flexcore',
    DIELECTRIC = 'dielectric',
}

const PCBLayerTypesRuntype = z.nativeEnum(PCBLayerTypes);

const PCBGraphicLayerDefinitionRuntype = z.object({
    id: z.string().optional(),
    layerType: PCBLayerTypesRuntype.optional(),
    meta: PCBGraphicLayerDefinitionMetaRuntype.optional(),
    material: PCBGraphicLayerDefinitionMaterialRuntype.optional(),
});

const PCBGraphicLayerRuntype = z.object({
    definition: PCBGraphicLayerDefinitionRuntype.optional(),
    file: PCBGraphicFileRuntype.optional(),
    graphic: z
        .object({
            copper_json: PCBGraphicCopperJsonRuntype,
        })
        .optional(),
});

export type PCBGraphicLayer = z.infer<typeof PCBGraphicLayerRuntype>;

const PCBGraphicDrillHoleToolRuntype = z.object({
    name: z.string(),
    drillType: z.union([z.literal('PLATED'), z.literal('NON_PLATED')]),
    diameter: z.number(),
    drills: z.array(PCBGraphicPositionRuntype),
});

export type PCBGraphicDrillHoleTool = z.infer<typeof PCBGraphicDrillHoleToolRuntype>;

const PCBGraphicDrillHoleRuntype = z.object({
    tools: z.array(PCBGraphicDrillHoleToolRuntype),
    scaling: z.number(),
});

export type PCBGraphicDrillHole = z.infer<typeof PCBGraphicDrillHoleRuntype>;

const PCBGraphicOutlineRuntype = z.object({
    id: z.string(),
    file: z.string().optional(),
    userChoice: z.boolean(),
    metaInfo: PCBGraphicMetaInfoRuntype,
    graphic: PCBGraphicCopperJsonRuntype,
    score: z.number().optional(),
});

export type PCBGraphicOutline = z.infer<typeof PCBGraphicOutlineRuntype>;

export const PCBGraphicsRuntype = z.object({
    layers: z.array(PCBGraphicLayerRuntype),
    outline: PCBGraphicOutlineRuntype,
    drillHoles: z.array(PCBGraphicDrillHoleRuntype),
});

export enum PCBGraphicType {
    LAYER = 'layer',
    OUTLINE = 'outline',
    DRILLS = 'drills',
}

export const WrapperPCBGraphicLayerRuntype = z.object({
    type: z.literal(PCBGraphicType.LAYER),
    data: PCBGraphicLayerRuntype,
    graphic: z
        .object({
            copper_json: PCBGraphicCopperJsonRuntype,
        })
        .optional(),
});

export const WrapperPCBGraphicOutlineRuntype = z.object({
    type: z.literal(PCBGraphicType.OUTLINE),
    data: PCBGraphicOutlineRuntype,
});

export const WrapperPCBGraphicDrillHoleRuntype = z.object({
    type: z.literal(PCBGraphicType.DRILLS),
    data: z.array(PCBGraphicDrillHoleRuntype),
});

export const WrapperPCBGraphicRuntype = z.union([
    WrapperPCBGraphicLayerRuntype,
    WrapperPCBGraphicOutlineRuntype,
    WrapperPCBGraphicDrillHoleRuntype,
]);

export const PCBGraphicsArrayRuntype = z.array(WrapperPCBGraphicRuntype);

export type PCBGraphics = z.infer<typeof PCBGraphicsRuntype>;
export type PCBGraphicsArray = z.infer<typeof PCBGraphicsArrayRuntype>;

const PCBGraphicsSuccessRuntype = z.object({
    status: z.literal('ok'),
    data: PCBGraphicsArrayRuntype,
});

const PCBGraphicsErrorRuntype = z.object({
    status: z.literal('error'),
    error: PcbServerErrorTypeRuntype,
});

export const PCBGraphicsResponseRuntype = z.union([PCBGraphicsSuccessRuntype, PCBGraphicsErrorRuntype]);

export type PCBGraphicsResponse = z.infer<typeof PCBGraphicsResponseRuntype>;
