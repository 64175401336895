import { useDialogContext } from '@/components/contexts/ModalContext';
import { FormContainer } from '@/components/formLayouts/FormContainer';
import { SubmitButton } from '@/components/formLayouts/SubmitButton';
import { useHttpMutation } from '@/resources/mutation/useHttpMutation';
import { useSupplierContactsFromSupplier } from '@/resources/supplierContact/supplierContactHandler';
import { analytics } from '@/utils/analytics';
import { t } from '@lingui/macro';
import { Dialog, DialogContent, DialogTitle, Flexbox } from '@luminovo/design-system';
import { SupplierContactDTO, SupplierContactUpdateDTO } from '@luminovo/http-client';
import {
    FormItemEmail,
    FormItemFirstName,
    FormItemLastName,
    FormItemPosition,
    FormItemUserLanguage,
} from './FormItems';

export function useDialogEditSupplierContact({ supplierContact }: { supplierContact: SupplierContactDTO }) {
    const { setDialog, closeDialog } = useDialogContext();

    return {
        openDialog: () => {
            setDialog(
                <Dialog open={true}>
                    <DialogTitle title={t`Edit contact`} onClose={() => closeDialog()} />
                    <DialogContent>
                        <EditSupplierContactForm supplierContact={supplierContact} handleClose={() => closeDialog()} />
                    </DialogContent>
                </Dialog>,
            );
        },
    };
}

function EditSupplierContactForm({
    supplierContact,
    handleClose,
}: {
    supplierContact: SupplierContactDTO;
    handleClose: () => void;
}) {
    const { id: supplierContactId, supplier: supplierId } = supplierContact;

    const { data: supplierContacts = [] } = useSupplierContactsFromSupplier(supplierId);

    const { mutateAsync } = useHttpMutation('PATCH /supplier-contacts/:supplierContactId', {
        snackbarMessage: t`Contact successfully updated`,
        onSuccess: () => {
            analytics.track('edit_supplier_contact');
            handleClose();
        },
    });

    const onSubmit = async (requestBody: SupplierContactUpdateDTO) => {
        await mutateAsync({
            pathParams: { supplierContactId },
            requestBody,
        });
    };

    return (
        <FormContainer defaultValues={supplierContact} onSubmit={onSubmit}>
            <Flexbox flexDirection="column" gap={28}>
                <Flexbox gap={32}>
                    <FormItemFirstName />
                    <FormItemLastName />
                </Flexbox>
                <FormItemEmail
                    existingSupplierContacts={supplierContacts.filter((c) => c.id !== supplierContactId)}
                    disabled={true}
                />
                <FormItemUserLanguage />
                <FormItemPosition />
                <Flexbox justifyContent="flex-end" style={{ paddingTop: '24px', paddingBottom: '12px' }}>
                    <SubmitButton label={t`Save contact`} />
                </Flexbox>
            </Flexbox>
        </FormContainer>
    );
}
