import { AssemblyIndustry } from '../../../resources/assembly';
import { Currency } from '../../../resources/currencyBackendTypes';
import { ExtractRequestBody, ExtractResponseBody, http } from '../../../resources/http';

/**
 * Creates a minimal RFQ mock with only the required fields.
 * Most fields are left as default/null since they rely on IDs from the backend
 * which we don't want to hardcode in the test mocks.
 */
export const createRfqRequestBodyMock = (customerId: string): ExtractRequestBody<'POST /rfqs'> => {
    const assemblyName = `Assembly - ${new Date().toISOString()}`;

    return {
        customer: { customer: { type: 'ExistingCustomer', data: customerId } },
        assembly: {
            industry: AssemblyIndustry.Auto,
            assemblies: [{ name: assemblyName, assemblyType: { type: 'Pcba' } }],
        },
        rfq: {
            name: `Test RfQ ${new Date().toISOString()}`,
            contributors: [],
            emsInternalNumber: null,
            dueDate: null,
            volumeEstimate: null,
            currency: Currency.EUR,
            comment: null,
        },
        additional_service_ids: [],
        demand_scenarios: [
            {
                type: 'Prototype',
                demands: [{ assembly_designator: assemblyName, quantity: 1 }],
                target_date: { type: 'AsSoonAsPossible' },
            },
        ],
    };
};

export const createRfqHttpCallWithMock = async ({
    customerId,
    token,
}: {
    customerId: string;
    token: string;
}): Promise<ExtractResponseBody<'POST /rfqs'>> => {
    const rfqMock = createRfqRequestBodyMock(customerId);
    return await http('POST /rfqs', { requestBody: rfqMock }, token);
};
