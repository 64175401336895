import { t } from '@lingui/macro';
import { logToExternalErrorHandlers } from '@luminovo/commons';
import { CenteredLayout, NonIdealState } from '@luminovo/design-system';
import { Lock } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useAuth } from '../hooks/useAuth';
import { setToken } from '../token';
import { TokenContext } from '../TokenContext';

interface AuthenticatedContextProviderBaseProps {
    /**
     * Callback to be called when the user is not authenticated.
     * This should handle the redirection to the login page.
     */
    onNotAuthenticated: (options: { redirectTo?: string }) => void;

    /**
     * The organization ID to use for the authentication.
     */
    auth0OrgId: string | undefined;
}

type AuthenticatedContextProviderProps = React.PropsWithChildren<AuthenticatedContextProviderBaseProps>;

export const ACCESS_TOKEN_QUERY_KEY = ['access-token'];

export function AuthenticatedContextProvider({
    children,
    onNotAuthenticated,
    auth0OrgId,
}: AuthenticatedContextProviderProps): JSX.Element {
    const { logout } = useAuth({ auth0OrgId });
    const { error, isLoading, isAuthenticated, token } = useFetchTokenSilently({
        onNotAuthenticated,
        auth0OrgId,
    });

    if (isLoading || !token) {
        return (
            <CenteredLayout>
                <CircularProgress />
            </CenteredLayout>
        );
    }

    // Handle auth0 errors
    if (error || !isAuthenticated) {
        if (error) {
            logToExternalErrorHandlers(error ?? new Error('Authentication error'));
        }

        return (
            <CenteredLayout>
                <NonIdealState
                    Icon={Lock}
                    title={t`Authentication problem`}
                    description={
                        t`Sorry, it looks like your session expired. Try refreshing the page or logging in again. If this problem persists, please reach out to our support team using the chat box in the bottom right corner.` +
                        `\n\n${error?.message}`
                    }
                    action={{
                        children: t`Log out`,
                        onClick: () => {
                            logout();
                        },
                    }}
                />
            </CenteredLayout>
        );
    }

    return (
        <React.Suspense
            fallback={
                <CenteredLayout>
                    <CircularProgress />
                </CenteredLayout>
            }
        >
            <TokenContext.Provider value={token}>{children}</TokenContext.Provider>
        </React.Suspense>
    );
}

function useFetchTokenSilently({
    onNotAuthenticated,
    auth0OrgId,
}: {
    onNotAuthenticated: (options: { redirectTo?: string }) => void;
    auth0OrgId: string | undefined;
}): {
    isAuthenticated: boolean;
    isLoading: boolean;
    error: Error | undefined | null;
    token: string | null | undefined;
} {
    const { isAuthenticated, isLoading, error, getAccessTokenSilently } = useAuth({ auth0OrgId });

    // refresh every hour
    const tokenRefreshIntervalMillis = 1000 * 60 * 60;
    // Handle token refresh
    const {
        data: token,
        error: tokenError,
        isFetching: isLoadingToken,
    } = useQuery({
        enabled: !isLoading,
        queryKey: [...ACCESS_TOKEN_QUERY_KEY, isAuthenticated, isLoading],
        refetchOnWindowFocus: true,
        queryFn: async () => {
            try {
                console.warn('[auth]', 'fetching token.');
                const token = await getAccessTokenSilently();
                console.warn('[auth]', 'got token.');
                setToken(token);
                return token;
            } catch (err) {
                console.log('[auth]', 'error fetching token, need to re-authenticate');
                onNotAuthenticated({ redirectTo: window.location.pathname + window.location.search });
                if (err instanceof Error && err.message.includes('Login required')) {
                    console.warn('[auth]', err.message);
                    return null;
                } else {
                    console.warn('[auth]', 'error fetching token, need to re-authenticate', err);
                    throw err;
                }
            }
        },
        throwOnError: false,
        refetchInterval: tokenRefreshIntervalMillis,
        refetchIntervalInBackground: true,
        retry: false,
        staleTime: 0,
    });

    return { isAuthenticated, isLoading: isLoading || isLoadingToken, error: error || tokenError, token };
}
