import * as z from 'zod';

const PanelWidthAndHeightRuntype = z.object({
    width_in_mm: z.string(),
    height_in_mm: z.string(),
});

export type DepanelizationDTO = z.infer<typeof DepanelizationRuntype>;
export const DepanelizationRuntype = z.enum(['VCut', 'Milling', 'MillingAndVCut']);

export type PanelPreferenceDTO = z.infer<typeof PanelPreferenceRuntype>;
export const PanelPreferenceRuntype = z.object({
    id: z.string(),
    name: z.string(),
    min_width: z.string(),
    min_height: z.string(),
    max_width: z.string(),
    max_height: z.string(),
    max_pcbs: z.number(),
    spacing: PanelWidthAndHeightRuntype,
    padding: PanelWidthAndHeightRuntype,
    depanelization: DepanelizationRuntype,
});

export type PanelPreferencePostDTO = z.infer<typeof PanelPreferencePostRuntype>;
export const PanelPreferencePostRuntype = PanelPreferenceRuntype.omit({ id: true });
