import type { IHeaderParams } from 'ag-grid-community';
import { useState } from 'react';
import { HeaderComponentDefault } from './HeaderComponentDefault';
import { MenuItemSetAllValues } from './MenuItemSetAllValues';

interface HeaderComponentEnumProps extends IHeaderParams {
    /**
     * Used for the "set all values" workflow.
     * Only used for enum and boolean columns.
     */
    options?: Array<string | boolean | number>;
}

export function HeaderComponentEnum({ column, api, options, setSort, ...rest }: HeaderComponentEnumProps): JSX.Element {
    const [anchorEl, setAnchorEl] = useState<null | Element>(null);

    return (
        <HeaderComponentDefault
            {...rest}
            column={column}
            setSort={setSort}
            api={api}
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            additionalMenuItems={
                options && (
                    <>
                        <MenuItemSetAllValues
                            column={column}
                            api={api}
                            options={options}
                            onClose={() => setAnchorEl(null)}
                        />
                    </>
                )
            }
        />
    );
}
