import * as z from 'zod';
import { DriverIdParameterRuntype } from '../driver';
import { endpoint } from '../http/endpoint';
import { ManufacturingEntityStatusRuntype } from '../sharedManufacturingBackendTypes';
import { ExpensePostRuntype, ExpenseRuntype } from './expensesBackendTypes';

export const expensesEndpoints = {
    'GET /expenses': endpoint({
        description: 'Returns an array of all expenses',
        pathParams: z.undefined(),
        queryParams: z.object({
            active: z.boolean().optional(),
            driver_id: DriverIdParameterRuntype.optional(),
        }),
        requestBody: z.undefined(),
        responseBody: z.object({
            data: z.array(ExpenseRuntype),
        }),
    }),
    'GET /expenses/:expenseId': endpoint({
        description: 'Returns an expense',
        pathParams: z.object({ expenseId: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({
            data: ExpenseRuntype,
        }),
    }),

    'POST /expenses': endpoint({
        description: 'Creates an expense',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: ExpensePostRuntype,
        responseBody: z.object({
            data: ExpenseRuntype,
        }),
    }),

    'DELETE /expenses/:expenseId': endpoint({
        description: 'Deletes an expense',
        pathParams: z.object({ expenseId: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({ deleted: z.number() }),
    }),

    'PATCH /expenses/:expenseId': endpoint({
        description: 'Updates an expense',
        pathParams: z.object({ expenseId: z.string() }),
        queryParams: z.undefined(),
        requestBody: ExpensePostRuntype.or(z.object({ status: ManufacturingEntityStatusRuntype })),
        responseBody: z.object({
            data: ExpenseRuntype,
        }),
    }),
};
