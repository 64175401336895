import { Currency } from '../../../resources/currencyBackendTypes';
import { ExtractRequestBody } from '../../../resources/http';
import { PriceType, StandardPartOfferLinkedPartType } from '../../../resources/offer/offerBackendTypes';

interface CreateOffTheShelfOfferMockParams {
    linkedPartId: string;
    unitPrice: string;
    priceBreakInputs?: Array<{
        moq: number;
        mpq: number;
        unit_price: number;
    }>;
    supplierAndStockLocation: string;
}

export const createOffTheShelfOfferMock = ({
    linkedPartId,
    unitPrice,
    priceBreakInputs,
    supplierAndStockLocation,
}: CreateOffTheShelfOfferMockParams): ExtractRequestBody<'POST /offers/off-the-shelf'> => {
    return {
        supplier_part_number: 'CUSTOM',
        linked_part: { type: StandardPartOfferLinkedPartType.OffTheShelf, id: linkedPartId },
        supplier_and_stock_location: supplierAndStockLocation,
        price_type: PriceType.QuotePrice,
        customer: null,
        rfq: null,
        notes: null,
        valid_until: null,
        valid_from: null,
        item_class: null,
        cancellation_window_in_days: null,
        currency: Currency.EUR,
        availability_input: { stock: null, factory_lead_time_days: null, factory_quantity: null, on_order: [] },
        price_break_inputs: priceBreakInputs ?? [{ moq: 1, mpq: 1, unit_price: parseFloat(unitPrice) }],
        one_time_costs: [],
        packaging: null,
        unit_of_measurement: { unit: 'Pieces', quantity: 1 },
        offer_number: null,
        ncnr: null,
    };
};
