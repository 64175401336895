import * as z from 'zod';
import { ApiIntegrationStatusRuntype } from './3rdPartyBackendTypes';

export interface WinSourceCredentialsDTO extends z.infer<typeof WinSourceCredentialsDTORuntype> {}
export const WinSourceCredentialsDTORuntype = z.object({
    access_key: z.string(),
    access_token: z.string(),
});

export interface WinSourceCredentialsInputDTO extends z.infer<typeof WinSourceCredentialsInputDTORuntype> {}
export const WinSourceCredentialsInputDTORuntype = z.object({
    access_key: z.string().optional(),
    access_token: z.string().optional(),
});

export interface WinSourceResponseBodyDTO extends z.infer<typeof WinSourceResponseBodyDTORuntype> {}
export const WinSourceResponseBodyDTORuntype = z.object({
    data: WinSourceCredentialsDTORuntype.nullable(),
    status: ApiIntegrationStatusRuntype,
});
