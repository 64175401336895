/* eslint-disable import/no-unused-modules */
import * as z from 'zod';

export const BigPointRuntype = z.object({ x: z.number(), y: z.number() });
export type BigPoint = z.infer<typeof BigPointRuntype>;

export const DimensionRuntype = z.object({
    min: BigPointRuntype,
    max: BigPointRuntype,
});

export type Dimension = z.infer<typeof DimensionRuntype>;

export const FormatRuntype = z.object({
    unit: z.string(),
    resolution: z.number(),
    dimension: DimensionRuntype.optional(),
    complexity: z.number().optional(),
    scaling: z.number().optional(),
    gerberscale: z.number().optional(),
});

export type Format = z.infer<typeof FormatRuntype>;

export enum PCBFileTypes {
    OUTLINE = 'Outline',
    KEEP_OUT = 'KeepOut',
    PASTE_TOP = 'PasteTop',
    PASTE_BOTTOM = 'PasteBottom',
    SILKSCREEN_TOP = 'SilkscreenTop',
    SILKSCREEN_BOTTOM = 'SilkscreenBottom',
    SOLDERMASK_TOP = 'SoldermaskTop',
    SOLDERMASK_BOTTOM = 'SoldermaskBottom',
    COPPER_TOP = 'CopperTop',
    COPPER_MID = 'CopperMid',
    PLANE_MID = 'PlaneMid',
    COPPER_BOTTOM = 'CopperBottom',
    DRILL = 'Drill',
    PHDRILL = 'PHDrill',
    NPHDRILL = 'NPHDrill',
    DRILLSETS = 'DrillSets',
    MECHANICAL = 'Mechanical',
    NATIVE_ALTIUM = 'native-altium',
    NATIVE_KICAD = 'native-kicad',
    NATIVE_EAGLE = 'native-eagle',
    LEGACY_GERBER = 'LegacyGerber',
    UNMATCHED = 'unmatched',
    UNKNOWN = 'unknown',
    ADHESIVE_TOP = 'AdhesiveTop',
    ADHESIVE_BOTTOM = 'AdhesiveBottom',
    PEELABLE_TOP = 'PeelableTop',
    PEELABLE_BOTTOM = 'PeelableBottom',
    STACK_UP = 'StackUp',
}

export const PCBFileTypesRuntimeType = z.nativeEnum(PCBFileTypes);

export enum PCBFileCategory {
    GERBER = 'gerber',
    ODB = 'odb',
    MECHANICAL = 'mechanical',
    UNKNOWN = 'unknown',
}

export const PCBFileCategoryRuntimeType = z.nativeEnum(PCBFileCategory);

export const FileTypeRuntype = z.object({
    service: z.string(),
    category: PCBFileCategoryRuntimeType.optional(),
    fileType: PCBFileTypesRuntimeType,
    productionFile: z.boolean(),
    mimeType: z.optional(z.string().nullable()),
    index: z.optional(z.number().nullable()),
    from: z.optional(z.number().nullable()),
    to: z.optional(z.number().nullable()),
});

export const MetaInfoRuntype = z.object({ properties: z.object({}) });

export const LayerFileRuntype = z.object({
    id: z.string().optional(),
    name: z.string().optional(),
    data: z.string().optional(),
    format: FormatRuntype.optional(),
    fileType: FileTypeRuntype.optional(),
    metaInfo: MetaInfoRuntype,
    inverted: z.boolean(),
});

export const MetaStatusRuntype = z.object({
    name: z.string(),
    since: z.string(),
});

export const DrillSetRuntype = z.object({
    name: z.string().optional(),
    filename: z.string().optional(),
    layers: z.array(z.string()),
});

export const PCBRuntype = z.object({
    assembly: z.string(),
    version: z.string(),
    outline: LayerFileRuntype.optional(),
    holes: z.array(z.string()),
    files: z.array(LayerFileRuntype),
    metaInfo: MetaInfoRuntype.optional(),
    analysisStatus: MetaStatusRuntype.optional(),
    specifications: z.array(z.string()),
    defaultSpecification: z.string().optional(),
    drillSets: z.array(DrillSetRuntype).optional(),
});

export type PCB = z.infer<typeof PCBRuntype>;

export const AssemblyFeatureRuntype = z.object({
    service: z.string(),
    feature: z.string(),
});

export const AssemblyStatusRuntype = z.object({});

export const UIStatusRuntype = z.object({
    status: z.string(),
    progress: z.number().optional(),
    messages: z.array(z.string()).optional(),
});

export const DeadlineDocRuntype = z.object({
    time: z.object({
        length: z.number(),
        unit: z.union([
            z.literal('NANOSECONDS'),
            z.literal('MICROSECONDS'),
            z.literal('MILLISECONDS'),
            z.literal('SECONDS'),
            z.literal('MINUTES'),
            z.literal('HOURS'),
            z.literal('DAYS'),
        ]),
    }),
});

export const FileLifecycleStateRuntype = z.object({
    name: z.union([
        z.literal('unknown'),
        z.literal('waiting'),
        z.literal('progress'),
        z.literal('error'),
        z.literal('success'),
        z.literal('timeout'),
    ]),
    messages: z.array(z.string()),
    percent: z.number().optional(),
    duration: z.number().optional(),
    deadline: DeadlineDocRuntype.optional(),
});

export const HistoricLifecycleStateRuntype = z.object({
    name: z.string(),
    start: z.number(),
    end: z.number().optional(),
});

export const FileLifecycleRuntype = z.object({
    name: z.union([
        z.literal('main'),
        z.literal('composition'),
        z.literal('dfmanalysis'),
        z.literal('customer-panel'),
        z.literal('dfm'),
        z.literal('outline'),
        z.literal('reconciliation'),
        z.literal('analysis'),
        z.literal('files'),
        z.literal('fileanalysis'),
        z.literal('layerstack'),
        z.literal('render'),
        z.literal('specification-render'),
        z.literal('reconciled-specification-render'),
        z.literal('initialization'),
        z.literal('production-analysis'),
        z.literal('preview'),
    ]),
    status: FileLifecycleStateRuntype,
    history: z.array(HistoricLifecycleStateRuntype).optional(),
});

export type FileLifecycle = z.infer<typeof FileLifecycleRuntype>;

export const FileRuntype = z.object({
    name: z.string(),
    fType: FileTypeRuntype,
    detectedTypes: z.array(FileTypeRuntype),
    created: z.string(),
    preview: z.string().optional(),
    lifecycles: z.array(FileLifecycleRuntype),
});

export type File = z.infer<typeof FileRuntype>;

export const VersionRuntype = z.object({
    id: z.string(),
    name: z.string().optional(),
    created: z.string(),
    released: z.string().optional(),
    files: z.array(FileRuntype).optional(),
    filesLocked: z.boolean(),
    lifecycles: z.array(FileLifecycleRuntype),
});

export type Version = z.infer<typeof VersionRuntype>;

export const AssemblyReferenceRuntype = z.object({
    team: z.string(),
    id: z.string(),
    gid: z.string().optional(),
    version: z.string(),
});

export const AssemblyRuntype = z.object({
    team: z.string(),
    id: z.string(),
    gid: z.string(),
    name: z.string(),
    description: z.string().optional(),
    creator: z.string(),
    created: z.string(),
    customer: z.string().optional(),
    orderId: z.string().optional(),
    assignee: z.string().optional(),
    itemNo: z.string().optional(),
    features: z.array(AssemblyFeatureRuntype),
    status: AssemblyStatusRuntype,
    uiStatus: UIStatusRuntype,
    currentVersion: VersionRuntype.optional(),
    preview: z.string().optional(),
    mail: z.string().optional(),
    template: AssemblyReferenceRuntype.optional(),
});

export type Assembly = z.infer<typeof AssemblyRuntype>;

export const MaterialRuntype = z.object({
    team: z.string().optional(),
    id: z.string().optional(),
    name: z.string().optional(),
    meta: MetaInfoRuntype.optional(),
    materialType: z.string().optional(),
});

export type Material = z.infer<typeof MaterialRuntype>;

export const LayerDefinitionRuntype = z.object({
    id: z.string().optional(),
    layerType: z.string().optional(),
    meta: MetaInfoRuntype.optional(),
    materialRef: z.string().optional(),
    material: MaterialRuntype.optional(),
});

export const DrillSupportRuntype = z.object({
    from: z.number(),
    to: z.number(),
    col: z.number().optional(),
    drillType: z.string(),
});

export const SubStackDefinitionRuntype = z.object({
    name: z.string(),
    layers: z.array(LayerDefinitionRuntype).optional(),
    stackType: z.string().optional(),
    drills: z.array(DrillSupportRuntype).optional(),
    offset: z.number().optional(),
});

export const LayerstackDefinitionAPIRuntype = z.object({
    id: z.string().optional(),
    team: z.string().optional(),
    name: z.string().optional(),
    stacks: z.array(SubStackDefinitionRuntype).optional(),
    price: z.number().optional(),
    metaInfo: MetaInfoRuntype.optional(),
    creation: z.string().optional(),
    image: z.string().optional(),
    default: z.boolean().optional(),
});

export const LayerAPIRuntype = z.object({
    definition: LayerDefinitionRuntype,
    files: z.array(LayerFileRuntype).optional(),
    metaInfo: MetaInfoRuntype,
});

export const SubStackAPIRuntype = z.object({
    definition: SubStackDefinitionRuntype,
    layers: z.array(LayerAPIRuntype),
    metaInfo: MetaInfoRuntype,
});

export const LayerStackAPIRuntype = z.object({
    assembly: AssemblyReferenceRuntype,
    definition: LayerstackDefinitionAPIRuntype,
    stacks: z.array(SubStackAPIRuntype),
    unmatchedFiles: z.array(LayerFileRuntype),
});

export const CustomerPanelElementRuntype = z.object({});

export const SpacingRuntype = z.object({
    topPadding: z.number().optional(),
    rightPadding: z.number().optional(),
    leftPadding: z.number().optional(),
    bottomPadding: z.number().optional(),
    verticalSpacing: z.number().optional(),
    horizontalSpacing: z.number().optional(),
});

export const WorkingPanelUsageAPIRuntype = z.object({
    workingPanel: z.string(),
    customerPanels: z.number(),
    customerBoards: z.number(),
    panelYield: z.number(),
    preview: z.string().optional(),
});

export const CustomerPanelAPIRuntype = z.object({
    id: z.string().optional(),
    name: z.string(),
    elements: z.array(CustomerPanelElementRuntype),
    description: z.string().optional(),
    preview: z.string().optional(),
    spacing: SpacingRuntype,
    working: z.array(WorkingPanelUsageAPIRuntype).optional(),
    selected: z.string().optional(),
    bestYield: z.string().optional(),
    width: z.number().optional(),
    height: z.number().optional(),
    weight: z.number().optional(),
});

export const CollectedPCBRuntype = z.object({
    assembly: AssemblyRuntype,
    pcb: PCBRuntype,
    layerstack: LayerStackAPIRuntype.optional(),
    panels: z.array(CustomerPanelAPIRuntype).optional(),
});

export const PCBUpdateRuntype = z.object({
    defaultSpecification: z.string().optional(),
});

export const SetOutlineRuntype = z.object({
    name: z.string(),
    userChoice: z.boolean().optional(),
});

export const GraphicUsageRuntype = z.object({
    reference: z.string(),
    location: BigPointRuntype,
});

export const GraphicElementRuntype = z.object({
    path: z.string().optional(),
    tag: z.string().optional(),
    use: GraphicUsageRuntype.optional(),
    attributes: z.object({}).optional(),
    elementIds: z.array(z.string()).optional(),
    trace: z.string().optional(),
});

export const GraphicDefinitionRuntype = z.object({
    id: z.string(),
    path: z.string(),
});

export const GraphicRuntype = z.object({
    viewbox: DimensionRuntype,
    format: FormatRuntype,
    count: z.number(),
    paths: z.array(z.array(GraphicElementRuntype)),
    defs: z.array(GraphicDefinitionRuntype),
});

export const LayerFileUpdateRuntype = z.object({
    inverted: z.boolean().optional(),
});

export const MessageRuntype = z.object({});

export type Message = z.infer<typeof MessageRuntype>;

export const FilePathRuntype = z.object({
    fsroot: z.string(),
    team: z.string(),
    resource: z.string(),
    base: z.string(),
    filename: z.string(),
});

export const PCBSpecificationRuntype = z.object({
    assembly: AssemblyReferenceRuntype,
    id: z.string(),
    alias: z.string(),
    dfm: MetaInfoRuntype,
    settings: MetaInfoRuntype,
    user: MetaInfoRuntype.optional(),
    status: z.string(),
    technology: z.string(),
    base: MetaInfoRuntype.optional(),
    preview: FilePathRuntype.optional(),
    previewRear: FilePathRuntype.optional(),
});

export const SpecificationPreviewsRuntype = z.object({
    front: z.string().optional(),
    rear: z.string().optional(),
});

export const PCBSpecificationApiRuntype = z.object({
    spec: PCBSpecificationRuntype,
    previews: SpecificationPreviewsRuntype,
});

export const PCBSpecificationCreationRuntype = z.object({
    alias: z.string(),
    template: z.string().optional(),
});

export const PropertyRuntype = z.object({});

export type Property = z.infer<typeof PropertyRuntype>;

export const PCBSpecificationUpdateRuntype = z.object({
    dfm: z.array(PropertyRuntype).optional(),
    settings: z.array(PropertyRuntype).optional(),
    user: z.array(PropertyRuntype).optional(),
    status: z.string().optional(),
});

export const PCBV2CreationRuntype = z.object({
    name: z.string().optional(),
    customer: z.string().optional(),
    contact: z.string().optional(),
    orderId: z.string().optional(),
    description: z.string().optional(),
});

export const PCBV2FileRuntype = z.object({
    id: z.string().optional(),
    name: z.string(),
    fileType: FileTypeRuntype,
    preview: z.optional(z.string().nullable()),
    path: z.string().optional(),
    lifecycles: z.array(FileLifecycleRuntype),
});

export type PCBV2File = z.infer<typeof PCBV2FileRuntype>;

export const FileAccessRuntype = z.object({ path: z.string(), key: z.string() });

export const PCBPreviewsRuntype = z.object({
    front: FileAccessRuntype.optional(),
    rear: FileAccessRuntype.optional(),
});

export enum PCBSurfaceFinish {
    NONE = 'none',
    HAL_PB = 'hal-pb',
    HAL_PB_FREE = 'hal-pb-free',
    ENIG = 'enig',
    ENIPIG = 'enipig',
    ENEPIG = 'enepig',
    ENAG = 'enag',
    ASIG = 'asig',
    EP = 'ep',
    EPAG = 'epag',
    EPIG = 'epig',
    IS = 'is',
    IT = 'it',
    ISIG = 'isig',
    OSP = 'osp',
}

export enum PCBSilkscreenColors {
    WHITE = 'white',
    BLACK = 'black',
    YELLOW = 'yellow',
    RED = 'red',
    GREEN = 'green',
    BLUE = 'blue',
}

export enum PCBSoldermaskColors {
    RED = 'red',
    GREEN = 'green',
    GREEN_MATT = 'green-matt',
    GREEN_GLOSSY = 'green-glossy',
    WHITE = 'white',
    BLUE = 'blue',
    BLACK = 'black',
    YELLOW = 'yellow',
    PURPLE = 'purple',
}

export enum PCBLayerSides {
    NONE = 'none',
    TOP = 'top',
    BOTTOM = 'bottom',
    BOTH = 'both',
}

export enum PCBIPC600Class {
    NONE = 'ipcnone',
    IPC1 = 'ipc1',
    IPC2 = 'ipc2',
    IPC2Plus = 'ipc2+',
    IPC3 = 'ipc3',
    IPC3A = 'ipc3a',
}

export enum FlammabilityRating {
    NONE = 'none',
    V0 = 'v-0',
    V1 = 'v-1',
    V2 = 'v-2',
}

export enum PCBBaseMaterial {
    FR2 = 'fr-2',
    FR3 = 'fr-3',
    FR4 = 'fr-4',
    CEM1 = 'cem1',
    CEM2 = 'cem2',
    CEM3 = 'cem3',
    POLYIMIDE = 'polyimide',
}

export enum UnitEnum {
    Millimeter = 'Millimeter',
    Inch = 'Inch',
    Micrometer = 'Micrometer',
    Mil = 'Mil',
    Microinch = 'Microinch',
    SquareMillimeter = 'SquareMillimeter',
    SquareInch = 'SquareInch',
    Celsius = 'Celsius',
    Fahrenheit = 'Fahrenheit',
}

const UnitRuntype = z.nativeEnum(UnitEnum);
export type UnitDTO = z.infer<typeof UnitRuntype>;

export type UnitWithValueDTO = z.infer<typeof UnitWithValueRuntype>;
const UnitWithValueRuntype = z.object({
    unit: UnitRuntype,
    value: z.number(),
});

export const PCBV2BoardPropertiesRuntype = z.object({
    boardHeight: UnitWithValueRuntype.optional(),
    boardWidth: UnitWithValueRuntype.optional(),
    silkscreenColor: z.nativeEnum(PCBSilkscreenColors).optional(),
    silkscreenSide: z.nativeEnum(PCBLayerSides).optional(),
    surfaceFinish: z.nativeEnum(PCBSurfaceFinish).optional(),
    enigThickness: UnitWithValueRuntype.optional(),
    applicationType: z
        .union([
            z.literal('industrial'),
            z.literal('military'),
            z.literal('automotive'),
            z.literal('medical'),
            z.literal('consumer'),
            z.literal('aerospace'),
        ])
        .optional(),
    notes: z.string().optional(),
    hardGold: z.boolean().optional(),
    hardGoldArea: UnitWithValueRuntype.optional(),
    hardGoldThickness: UnitWithValueRuntype.optional(),
    exposedCopperArea: UnitWithValueRuntype.optional(),
    exposedCopperAreaTop: UnitWithValueRuntype.optional(),
    exposedCopperAreaBottom: UnitWithValueRuntype.optional(),
    traceWidth: UnitWithValueRuntype.optional(),
    copperClearance: UnitWithValueRuntype.optional(),
    soldermaskColor: z.nativeEnum(PCBSoldermaskColors).optional(),
    soldermaskSide: z.nativeEnum(PCBLayerSides).optional(),
    soldermaskDam: UnitWithValueRuntype.optional(),
    soldermaskClearance: UnitWithValueRuntype.optional(),
    ipc600Class: z.nativeEnum(PCBIPC600Class).optional(),
    eTest: z.boolean().optional(),
    pressFit: z.boolean().optional(),
    pressFitTechnology: z.union([z.literal('massive'), z.literal('flexible')]).optional(),
    impedanceTested: z.boolean().optional(),
    impedanceTolerance: z.number().optional(),
    peelableMask: z.nativeEnum(PCBLayerSides).optional(),
    captonTape: z.union([z.literal('none'), z.literal('top'), z.literal('bottom'), z.literal('both')]).optional(),
    reports: z
        .array(
            z.union([
                z.literal('cross_section_report'),
                z.literal('first_sample_test_report'),
                z.literal('impedance_report'),
                z.literal('coc_report'),
            ]),
        )
        .optional(),
    itar: z.boolean().optional(),
    carbonPrint: z.boolean().optional(),
    carbonPrintSide: z.nativeEnum(PCBLayerSides).optional(),
    edgeMetalization: z.boolean().optional(),
    flammabilityRating: z.nativeEnum(FlammabilityRating).optional(),
    ctiClass: z.union([z.literal('i'), z.literal('ii'), z.literal('iiia'), z.literal('iiib')]).optional(),
    maxXOutsAllowed: z.number().optional(),
    halogenFree: z.boolean().optional(),
    placementSide: z.nativeEnum(PCBLayerSides).optional(),
    ecobond: z.boolean().optional(),
    numberOfLines: z.number().optional(),
    halfCutPlatedVias: z.boolean().optional(),
});

// Extracting values from the PCBV2BoardPropertiesRuntype record
export type PCBV2BoardPropertiesValuesDTO = z.infer<typeof PCBV2BoardPropertiesValuesRuntype>;
export const PCBV2BoardPropertiesValuesRuntype = z.union([
    PCBV2BoardPropertiesRuntype.shape.boardHeight,
    PCBV2BoardPropertiesRuntype.shape.boardWidth,
    PCBV2BoardPropertiesRuntype.shape.silkscreenColor,
    PCBV2BoardPropertiesRuntype.shape.silkscreenSide,
    PCBV2BoardPropertiesRuntype.shape.surfaceFinish,
    PCBV2BoardPropertiesRuntype.shape.enigThickness,
    PCBV2BoardPropertiesRuntype.shape.applicationType,
    PCBV2BoardPropertiesRuntype.shape.notes,
    PCBV2BoardPropertiesRuntype.shape.hardGold,
    PCBV2BoardPropertiesRuntype.shape.hardGoldThickness,
    PCBV2BoardPropertiesRuntype.shape.hardGoldArea,
    PCBV2BoardPropertiesRuntype.shape.exposedCopperArea,
    PCBV2BoardPropertiesRuntype.shape.exposedCopperAreaTop,
    PCBV2BoardPropertiesRuntype.shape.exposedCopperAreaBottom,
    PCBV2BoardPropertiesRuntype.shape.traceWidth,
    PCBV2BoardPropertiesRuntype.shape.copperClearance,
    PCBV2BoardPropertiesRuntype.shape.soldermaskColor,
    PCBV2BoardPropertiesRuntype.shape.soldermaskSide,
    PCBV2BoardPropertiesRuntype.shape.soldermaskDam,
    PCBV2BoardPropertiesRuntype.shape.soldermaskClearance,
    PCBV2BoardPropertiesRuntype.shape.ipc600Class,
    PCBV2BoardPropertiesRuntype.shape.eTest,
    PCBV2BoardPropertiesRuntype.shape.pressFit,
    PCBV2BoardPropertiesRuntype.shape.pressFitTechnology,
    PCBV2BoardPropertiesRuntype.shape.impedanceTested,
    PCBV2BoardPropertiesRuntype.shape.impedanceTolerance,
    PCBV2BoardPropertiesRuntype.shape.peelableMask,
    PCBV2BoardPropertiesRuntype.shape.captonTape,
    PCBV2BoardPropertiesRuntype.shape.reports,
    PCBV2BoardPropertiesRuntype.shape.itar,
    PCBV2BoardPropertiesRuntype.shape.carbonPrint,
    PCBV2BoardPropertiesRuntype.shape.edgeMetalization,
    PCBV2BoardPropertiesRuntype.shape.flammabilityRating,
    PCBV2BoardPropertiesRuntype.shape.ctiClass,
    PCBV2BoardPropertiesRuntype.shape.maxXOutsAllowed,
    PCBV2BoardPropertiesRuntype.shape.halogenFree,
    PCBV2BoardPropertiesRuntype.shape.placementSide,
]);

export type PCBV2BoardProperties = z.infer<typeof PCBV2BoardPropertiesRuntype>;

export const PCBV2LayerStackPropertiesRuntype = z.object({
    layerstackType: z
        .union([z.literal('rigid'), z.literal('flex'), z.literal('ims'), z.literal('rigid-flex')])
        .optional(),
    layerstackThicknessTolerance: UnitWithValueRuntype.optional(),
    ulLayerStack: z.boolean().optional(),
    ulMarkingType: z
        .union([z.literal('no-marking'), z.literal('default-marking'), z.literal('custom-marking')])
        .optional(),
    layercount: z.number().optional(),
    finalThickness: UnitWithValueRuntype.optional(),
    baseMaterial: z.nativeEnum(PCBBaseMaterial).optional(),
    outerCopperThickness: UnitWithValueRuntype.optional(),
    innerCopperThickness: UnitWithValueRuntype.optional(),
    minOuterLayerStructure: UnitWithValueRuntype.optional(),
    minInnerLayerStructure: UnitWithValueRuntype.optional(),
    rohsCompilant: z.boolean().optional(),
    tgValue: UnitWithValueRuntype.optional(),
    numberOfPrepregs: z.number().optional(),
    numberOfLaminationCycles: z.number().optional(),
});

export type PCBV2LayerStackProperties = z.infer<typeof PCBV2LayerStackPropertiesRuntype>;

export const PCBV2MechanicalPropertiesRuntype = z.object({
    minViaDiameter: UnitWithValueRuntype.optional(),
    viaFilling: z
        .union([
            z.literal('none'),
            z.literal('ia'),
            z.literal('ib'),
            z.literal('iib'),
            z.literal('iiia'),
            z.literal('iiib'),
            z.literal('iva'),
            z.literal('ivb'),
            z.literal('v'),
            z.literal('vi'),
            z.literal('vii'),
        ])
        .optional(),
    blindVias: z.boolean().optional(),
    buriedVias: z.boolean().optional(),
    chamfering: z.union([z.literal('none'), z.literal('30'), z.literal('20-pci'), z.literal('45-isa')]).optional(),
    outlineLength: UnitWithValueRuntype.optional(),
    aspectRatio: z.number().optional(),
    totalDrillCount: z.number().optional(),
    microVias: z.boolean().optional(),
    phCount: z.number().optional(),
    phToolCount: z.number().optional(),
    nphCount: z.number().optional(),
    phMinSize: UnitWithValueRuntype.optional(),
    nphMaxSize: UnitWithValueRuntype.optional(),
    phMaxSize: UnitWithValueRuntype.optional(),
    nphToolCount: z.number().optional(),
    phAnnularRing: UnitWithValueRuntype.optional(),
    nphMinSize: UnitWithValueRuntype.optional(),
    phSurfaceArea: z.number().optional(),
});

export type PCBV2MechanicalProperties = z.infer<typeof PCBV2MechanicalPropertiesRuntype>;

export const PCBV2ReportsPropertiesRuntype = z.object({
    crossSection: z.boolean().optional(),
    xRayMeasurement: z.boolean().optional(),
    xRayMeasurementPoints: z.number().optional(),
    firstArticleInspection: z.boolean().optional(),
    certificateOfConformance: z.boolean().optional(),
});

export const PCBV2MarkingsPropertiesRuntype = z.object({
    dateCode: z.union([z.literal('none'), z.literal('default'), z.literal('custom')]).optional(),
    fabricatorLogo: z.boolean().optional(),
    datamatrixCode: z.boolean().optional(),
});

export const PCBV2MiscellaneousPropertiesRuntype = z.object({
    manufacturingInformation: z.string().optional(),
});

export const PCBV2PropertiesRuntype = z.object({
    board: PCBV2BoardPropertiesRuntype,
    layerStack: PCBV2LayerStackPropertiesRuntype,
    mechanical: PCBV2MechanicalPropertiesRuntype,
    reports: PCBV2ReportsPropertiesRuntype,
    markings: PCBV2MarkingsPropertiesRuntype,
    miscellaneous: PCBV2MiscellaneousPropertiesRuntype,
});

export type PCBV2Properties = z.infer<typeof PCBV2PropertiesRuntype>;

export enum SpecificationStatusEnum {
    Changed = 'changed',
    Active = 'active',
    Removed = 'removed',
}

const SpecificationStatusEnumRuntype = z.nativeEnum(SpecificationStatusEnum);

const ChangedValuesRuntype = z.object({
    status: SpecificationStatusEnumRuntype.nullable(),
});

const SpecificationChangeRuntype = z.object({
    previousValue: ChangedValuesRuntype,
    newValue: ChangedValuesRuntype,
});

export const PCBV2SpecificationRuntype = z.object({
    id: z.string(),
    name: z.string(),
    previews: PCBPreviewsRuntype,
    settings: PCBV2PropertiesRuntype,
    status: SpecificationStatusEnumRuntype,
    hash: z.string(),
    changes: z.array(SpecificationChangeRuntype).optional(),
});

export const PCBV2Runtype = z.object({
    id: z.string(),
    name: z.string().optional(),
    assembly: z.string().optional(),
    description: z.string().optional(),
    created: z.string(),
    files: z.array(PCBV2FileRuntype).optional(),
    filesLocked: z.boolean(),
    lifecycles: z.array(FileLifecycleRuntype),
    orderId: z.string().optional(),
    outline: z.string().optional(),
    specifications: z.array(PCBV2SpecificationRuntype),
    properties: PCBV2PropertiesRuntype,
    customer: z.string().optional(),
    projectType: z.union([z.literal('WithFiles'), z.literal('NoFiles')]),
});

export type PCBV2 = z.infer<typeof PCBV2Runtype>;

export const NumericSpecificationCapabilityRuntype = z.object({
    min: z.number().optional(),
    max: z.number().optional(),
    allowed: z.array(z.number()).optional(),
    default: z.number().optional(),
    disabled: z.boolean().optional(),
    defaultUnit: UnitRuntype.optional(),
});

export const EnumSpecificationCapabilityRuntype = z.object({
    allowed: z.array(z.string()),
    default: z.string().optional(),
    defaultUnit: UnitRuntype.optional(),
});

export const BooleanSpecificationCapabilityRuntype = z.object({
    allowed: z.array(z.boolean()),
    default: z.boolean(),
});

export type BooleanSpecificationCapability = z.infer<typeof BooleanSpecificationCapabilityRuntype>;

export const StringSpecificationCapabilityRuntype = z.object({
    allowed: z.array(z.string()).optional(),
    forbidden: z.array(z.string()).optional(),
    // TODO: Adding this so line 46 of createInitialPcbFormValues doesn't break
    default: z.string().optional(),
});

export const PCBV2BoardCapabilitiesRuntype = z.object({
    boardHeight: NumericSpecificationCapabilityRuntype,
    boardWidth: NumericSpecificationCapabilityRuntype,
    silkscreenColor: EnumSpecificationCapabilityRuntype,
    silkscreenSide: EnumSpecificationCapabilityRuntype,
    surfaceFinish: EnumSpecificationCapabilityRuntype,
    enigThickness: NumericSpecificationCapabilityRuntype,
    applicationType: EnumSpecificationCapabilityRuntype,
    hardGold: BooleanSpecificationCapabilityRuntype,
    hardGoldArea: NumericSpecificationCapabilityRuntype,
    hardGoldThickness: NumericSpecificationCapabilityRuntype,
    exposedCopperArea: NumericSpecificationCapabilityRuntype,
    exposedCopperAreaTop: NumericSpecificationCapabilityRuntype,
    exposedCopperAreaBottom: NumericSpecificationCapabilityRuntype,
    traceWidth: NumericSpecificationCapabilityRuntype,
    copperClearance: NumericSpecificationCapabilityRuntype,
    soldermaskColor: EnumSpecificationCapabilityRuntype,
    soldermaskSide: EnumSpecificationCapabilityRuntype,
    soldermaskDam: NumericSpecificationCapabilityRuntype,
    soldermaskClearance: NumericSpecificationCapabilityRuntype,
    ipc600Class: EnumSpecificationCapabilityRuntype,
    eTest: BooleanSpecificationCapabilityRuntype,
    pressFit: BooleanSpecificationCapabilityRuntype,
    pressFitTechnology: EnumSpecificationCapabilityRuntype,
    impedanceTested: BooleanSpecificationCapabilityRuntype,
    impedanceTolerance: NumericSpecificationCapabilityRuntype,
    peelableMask: EnumSpecificationCapabilityRuntype,
    captonTape: EnumSpecificationCapabilityRuntype,
    reports: EnumSpecificationCapabilityRuntype,
    itar: BooleanSpecificationCapabilityRuntype,
    carbonPrint: BooleanSpecificationCapabilityRuntype,
    carbonPrintSide: EnumSpecificationCapabilityRuntype,
    edgeMetalization: BooleanSpecificationCapabilityRuntype,
    flammabilityRating: EnumSpecificationCapabilityRuntype,
    ctiClass: EnumSpecificationCapabilityRuntype,
    maxXOutsAllowed: NumericSpecificationCapabilityRuntype,
    halogenFree: BooleanSpecificationCapabilityRuntype,
    placementSide: EnumSpecificationCapabilityRuntype,
    ecobond: BooleanSpecificationCapabilityRuntype,
    numberOfLines: NumericSpecificationCapabilityRuntype,
    halfCutPlatedVias: BooleanSpecificationCapabilityRuntype,
});

export const PCBV2LayerStackCapabilitiesRuntype = z.object({
    layerstackType: EnumSpecificationCapabilityRuntype,
    layerstackThicknessTolerance: NumericSpecificationCapabilityRuntype,
    ulLayerStack: BooleanSpecificationCapabilityRuntype,
    ulMarkingType: EnumSpecificationCapabilityRuntype,
    layercount: NumericSpecificationCapabilityRuntype,
    finalThickness: NumericSpecificationCapabilityRuntype,
    baseMaterial: EnumSpecificationCapabilityRuntype,
    outerCopperThickness: NumericSpecificationCapabilityRuntype,
    innerCopperThickness: NumericSpecificationCapabilityRuntype,
    minOuterLayerStructure: NumericSpecificationCapabilityRuntype,
    minInnerLayerStructure: NumericSpecificationCapabilityRuntype,
    rohsCompilant: BooleanSpecificationCapabilityRuntype,
    tgValue: NumericSpecificationCapabilityRuntype,
    numberOfPrepregs: NumericSpecificationCapabilityRuntype,
    numberOfLaminationCycles: NumericSpecificationCapabilityRuntype,
});

export const PCBV2MechanicalCapabilitiesRuntype = z.object({
    minViaDiameter: NumericSpecificationCapabilityRuntype,
    viaFilling: EnumSpecificationCapabilityRuntype,
    blindVias: BooleanSpecificationCapabilityRuntype,
    buriedVias: BooleanSpecificationCapabilityRuntype,
    chamfering: EnumSpecificationCapabilityRuntype,
    aspectRatio: NumericSpecificationCapabilityRuntype,
    totalDrillCount: NumericSpecificationCapabilityRuntype,
    microVias: BooleanSpecificationCapabilityRuntype,
    phCount: NumericSpecificationCapabilityRuntype,
    phToolCount: NumericSpecificationCapabilityRuntype,
    nphCount: NumericSpecificationCapabilityRuntype,
    phMinSize: NumericSpecificationCapabilityRuntype,
    nphMaxSize: NumericSpecificationCapabilityRuntype,
    phMaxSize: NumericSpecificationCapabilityRuntype,
    nphToolCount: NumericSpecificationCapabilityRuntype,
    phAnnularRing: NumericSpecificationCapabilityRuntype,
    nphMinSize: NumericSpecificationCapabilityRuntype,
    phSurfaceArea: NumericSpecificationCapabilityRuntype,
});

export const PCBV2ReportsCapabilitiesRuntype = z.object({
    crossSection: BooleanSpecificationCapabilityRuntype,
    xRayMeasurement: BooleanSpecificationCapabilityRuntype,
    xRayMeasurementPoints: NumericSpecificationCapabilityRuntype,
    firstArticleInspection: BooleanSpecificationCapabilityRuntype,
    certificateOfConformance: BooleanSpecificationCapabilityRuntype,
});

export const PCBV2MarkingsCapabilitiesRuntype = z.object({
    dateCode: EnumSpecificationCapabilityRuntype,
    fabricatorLogo: BooleanSpecificationCapabilityRuntype,
    datamatrixCode: BooleanSpecificationCapabilityRuntype,
});

export const PCBV2MiscellaneousCapabilitiesRuntype = z.object({
    manufacturingInformation: StringSpecificationCapabilityRuntype,
});

export const PCBV2SpecificationCapabilitiesRuntype = z.object({
    board: PCBV2BoardCapabilitiesRuntype,
    layerStack: PCBV2LayerStackCapabilitiesRuntype,
    mechanical: PCBV2MechanicalCapabilitiesRuntype,
    reports: PCBV2ReportsCapabilitiesRuntype,
    markings: PCBV2MarkingsCapabilitiesRuntype,
    miscellaneous: PCBV2MiscellaneousCapabilitiesRuntype,
});

export interface PCBV2SpecificationCapabilities extends z.infer<typeof PCBV2SpecificationCapabilitiesRuntype> {}

export const PCBV2UpdateRuntype = z.object({
    name: z.string().optional(),
    settings: PCBV2PropertiesRuntype.optional(),
});

export type PCBV2Update = z.infer<typeof PCBV2UpdateRuntype>;

export const ARuntype = z.object({});

export type A = z.infer<typeof ARuntype>;

export const FileUploadRuntype = z.object({});

export type FileUpload = z.infer<typeof FileUploadRuntype>;

export const OfferPriceAvailabilityErrorRuntype = z.object({
    name: z.string(),
    error: z.string(),
    label: z.string(),
});

export type OfferPriceAvailabilityError = z.infer<typeof OfferPriceAvailabilityErrorRuntype>;

const PCBManufacturerRuntype = z.object({
    id: z.string(),
    name: z.string(),
    approved: z.boolean(),
    preferred: z.boolean(),
    region: z.string(),
});

const ValidationStatusRuntype = z.union([z.literal('success'), z.literal('error')]);
const ValidationErrorsRuntype = z.array(OfferPriceAvailabilityErrorRuntype);

export const OfferPriceAvailabilityResponseRuntype = z.object({
    manufacturer: PCBManufacturerRuntype,
    status: ValidationStatusRuntype,
    errors: ValidationErrorsRuntype,
});

export enum ValidationResultType {
    Detailed = 'DetailedValidationResultResponse',
    Simple = 'SimpleValidationResultResponse',
}

const DetailedValidationResultRuntype = z.object({
    results: z.array(OfferPriceAvailabilityResponseRuntype),
    type: z.literal(ValidationResultType.Detailed),
});

export type DetailedValidationResult = z.infer<typeof DetailedValidationResultRuntype>;

const SimpleValidationResultRuntype = z.object({
    status: ValidationStatusRuntype,
    errors: ValidationErrorsRuntype,
    type: z.literal(ValidationResultType.Simple),
});

export const OfferPriceAvailabilityRuntype = z.union([DetailedValidationResultRuntype, SimpleValidationResultRuntype]);

export type OfferPriceAvailability = z.infer<typeof OfferPriceAvailabilityRuntype>;

export const OfferPriceAvailabilityRequestRuntype = z.object({
    pcb_id: z.string(),
    quantity: z.number(),
    rfq_id: z.string(),
});

const SingleFileTypeUpdateRuntype = z.object({
    file: z.string(),
    function: FileTypeRuntype.optional(),
    detectedTypes: z.array(FileTypeRuntype).optional(),
});

export type SingleFileTypeUpdate = z.infer<typeof SingleFileTypeUpdateRuntype>;

export const FileTypeUpdateRequestRuntype = z.object({
    updates: z.array(SingleFileTypeUpdateRuntype),
});

export type FileTypeUpdateRequest = z.infer<typeof FileTypeUpdateRequestRuntype>;

const SingleFileUploadResultRuntype = z.object({
    name: z.string(),
    fType: FileTypeRuntype,
});

export const UploadFileResponseRuntype = z.object({
    files: z.array(SingleFileUploadResultRuntype),
    warnings: z.array(z.string()).optional(),
});

export type UploadFileResponse = z.infer<typeof UploadFileResponseRuntype>;

export enum PcbServerErrorType {
    TechnicalError = 'TechnicalError',
    PropertyErrors = 'PropertyErrors',
    MissingLayerstack = 'MissingLayerstack',
    MissingOutline = 'MissingOutline',
}

export const PcbServerErrorTypeRuntype = z.nativeEnum(PcbServerErrorType);

const PcbTechnicalErrorRuntype = z.object({
    type: z.literal(PcbServerErrorType.TechnicalError),
    message: z.string(),
});

const PcbMissingLayerstackErrorRuntype = z.object({
    type: z.literal(PcbServerErrorType.MissingLayerstack),
});

const PcbMissingOutlineErrorRuntype = z.object({
    type: z.literal(PcbServerErrorType.MissingOutline),
});

const PcbPropertyErrorsRuntype = z.object({
    type: z.literal(PcbServerErrorType.PropertyErrors),
    errors: z.array(z.string()),
});

export const PcbErrorRuntype = z.object({
    status: z.literal('error'),
    error: z.union([
        PcbTechnicalErrorRuntype,
        PcbMissingLayerstackErrorRuntype,
        PcbMissingOutlineErrorRuntype,
        PcbPropertyErrorsRuntype,
    ]),
});

export const PCBV2SpecificationUpdateRequest = z.object({
    status: SpecificationStatusEnumRuntype,
});

export const PcbApprovalStateRuntype = z.object({
    pcb: z.string(),
    is_pcb_specification_approved: z.boolean(),
    is_pcb_stackup_approved: z.boolean(),
    is_pcb_drill_manager_approved: z.boolean(),
    is_pcb_file_manager_approved: z.boolean(),
});

const CalculatedEmissionsRuntype = z.object({
    type: z.literal('CalculatedEmissions'),
    product_phase_gwp_in_kg_co2e_min: z.string(),
    product_phase_gwp_in_kg_co2e_max: z.string(),
    details: z.array(
        z.object({
            panel_id: z.string(),
            product_phase_gwp_in_kg_co2e: z.string(),
        }),
    ),
});

export const PcbEmissionsResponseRuntype = z.union([
    z.object({ type: z.literal('AnalysisInProgress') }),
    z.object({ type: z.literal('AnalysisError') }),
    z.object({ type: z.literal('MissingPcbInfo') }),
    CalculatedEmissionsRuntype,
]);
