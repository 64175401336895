/* eslint-disable spellcheck/spell-checker */
import { transEnum } from '@luminovo/commons';
import { UnitDTO, UnitEnum } from '@luminovo/http-client';
import {
    baseMaterialTranslations,
    chamferingTranslations,
    ctiClassTranslations,
    ipc600ClassTranslations,
    layerStackTypeTranslations,
    sideTranslations,
    surfaceFinishTranslations,
    ulMarkingTranslations,
    viaFillingTranslations,
} from '../../../../resources/pcb/i18n';
import { Capability } from '../../../../resources/pcb/pcbFunctions';
import { capitalizeFirstLetter } from '../../../../utils/stringFunctions';

export function formatUnit(unit: UnitDTO | undefined): string {
    if (!unit) {
        return '';
    }

    switch (unit) {
        case UnitEnum.Celsius:
            return '°C';
        case UnitEnum.Fahrenheit:
            return '°F';
        case UnitEnum.Inch:
            return 'in';
        case UnitEnum.Millimeter:
            return 'mm';
        case UnitEnum.Micrometer:
            return 'µm';
        case UnitEnum.Mil:
            return 'mil';
        case UnitEnum.Microinch:
            return 'µin';
        case UnitEnum.SquareMillimeter:
            return 'mm²';
        case UnitEnum.SquareInch:
            return 'in²';
        default:
            return '';
    }
}

export function formatOptionLabel(
    capabilityName: Capability['name'],
    value: string | number | boolean | null,
    unit: UnitDTO | undefined,
): string {
    if (
        capabilityName === 'board.soldermaskSide' ||
        capabilityName === 'board.silkscreenSide' ||
        capabilityName === 'board.peelableMask' ||
        capabilityName === 'board.placementSide' ||
        capabilityName === 'board.carbonPrintSide' ||
        capabilityName === 'board.captonTape'
    ) {
        return capitalizeFirstLetter(transEnum(value + '', sideTranslations));
    }
    if (capabilityName === 'mechanical.chamfering') {
        return capitalizeFirstLetter(transEnum(value + '', chamferingTranslations));
    }
    if (capabilityName === 'board.surfaceFinish') {
        return transEnum(value + '', surfaceFinishTranslations);
    }
    if (capabilityName === 'board.ipc600Class') {
        return transEnum(value + '', ipc600ClassTranslations);
    }
    if (capabilityName === 'board.ctiClass') {
        return transEnum(value + '', ctiClassTranslations);
    }
    if (capabilityName === 'layerStack.baseMaterial') {
        return transEnum(value + '', baseMaterialTranslations);
    }
    if (capabilityName === 'layerStack.ulMarkingType') {
        return transEnum(value + '', ulMarkingTranslations);
    }
    if (capabilityName === 'mechanical.viaFilling') {
        return transEnum(value + '', viaFillingTranslations);
    }
    if (capabilityName === 'layerStack.layerstackType') {
        return capitalizeFirstLetter(transEnum(value + '', layerStackTypeTranslations));
    }
    if (capabilityName === 'board.pressFitTechnology' || capabilityName === 'markings.dateCode') {
        return capitalizeFirstLetter(value + '');
    }
    if (unit) {
        return `${value} ${formatUnit(unit)}`;
    }

    return value + '';
}
