import { throwErrorUnlessProduction } from '@luminovo/commons';
import { ActivityConfigurationSummaryDTO, ActivityLevel, CalculationType, VariableTime } from '@luminovo/http-client';
import { assertUnreachable } from '../../../utils/typingUtils';
import { doesUnitActivityConfigurationHaveBatchTimeCalculation } from './activitiesTableComponents';

export function getActivityConfigurationLevels(activity: ActivityConfigurationSummaryDTO): ActivityLevel[] {
    const costComponentsLevel = activity.activity_configuration_details.cost_components.level;
    const level = [costComponentsLevel];

    if (doesUnitActivityConfigurationHaveBatchTimeCalculation(activity)) {
        level.push('Batch');
    }

    return level;
}

const getUnitTimeCalculationType = (
    activity: ActivityConfigurationSummaryDTO,
    timeLevel: ActivityLevel,
): CalculationType | undefined => {
    if (activity.activity_configuration_details.cost_components.level !== 'Unit') {
        throwErrorUnlessProduction(new Error('expected an unit level'));
    }
    if (activity.activity_configuration_details.cost_components.level === 'Unit') {
        switch (timeLevel) {
            case 'Batch':
            case 'Project':
                return activity.activity_configuration_details.cost_components.details.batch_time_components?.type;
            case 'Unit':
                return activity.activity_configuration_details.cost_components.details.unit_time_components?.type;
            default:
                assertUnreachable(timeLevel);
        }
    }
};

export function getActivityTimeCalculation(
    activity: ActivityConfigurationSummaryDTO,
    timeLevel: ActivityLevel,
): CalculationType | undefined {
    const level = activity.activity_configuration_details.cost_components.level;
    switch (level) {
        case 'Unit':
            return getUnitTimeCalculationType(activity, timeLevel);
        case 'Batch':
            return timeLevel === 'Batch'
                ? activity.activity_configuration_details.cost_components.details.time_components?.type
                : undefined;
        case 'Project':
            return timeLevel === 'Project'
                ? activity.activity_configuration_details.cost_components.details.time_components?.type
                : undefined;
        default:
            assertUnreachable(level);
    }
}

export function getActivityVariableTime(activity: ActivityConfigurationSummaryDTO): {
    unitVariableTime: VariableTime | undefined;
    unitBatchVariableTime: VariableTime | undefined;
    batchVariableTime: VariableTime | undefined;
    projectVariableTime: VariableTime | undefined;
} {
    const costComponent = activity.activity_configuration_details.cost_components;
    const projectVariableTime =
        costComponent.level === 'Project' && costComponent.details.time_components?.type === 'Linear'
            ? costComponent.details.time_components.details.variable_time
            : undefined;
    const batchVariableTime =
        costComponent.level === 'Batch' && costComponent.details.time_components?.type === 'Linear'
            ? costComponent.details.time_components.details.variable_time
            : undefined;
    const unitVariableTime =
        costComponent.level === 'Unit' && costComponent.details.unit_time_components?.type === 'Linear'
            ? costComponent.details.unit_time_components.details.variable_time
            : undefined;
    const unitBatchVariableTime =
        costComponent.level === 'Unit' && costComponent.details.batch_time_components?.type === 'Linear'
            ? costComponent.details.batch_time_components?.details.variable_time
            : undefined;

    return {
        unitVariableTime: unitVariableTime ?? undefined,
        batchVariableTime: batchVariableTime ?? undefined,
        unitBatchVariableTime: unitBatchVariableTime ?? undefined,
        projectVariableTime: projectVariableTime ?? undefined,
    };
}

export function getActivityFixedTime(activity: ActivityConfigurationSummaryDTO) {
    const costComponent = activity.activity_configuration_details.cost_components;

    // Unit level
    const unitFixedTime =
        costComponent.level === 'Unit' && costComponent.details.unit_time_components?.type === 'Fixed'
            ? costComponent.details.unit_time_components.details.fixed_time
            : undefined;

    const unitLinearFixedTime =
        costComponent.level === 'Unit' && costComponent.details.unit_time_components?.type === 'Linear'
            ? costComponent.details.unit_time_components.details.fixed_time
            : undefined;

    const unitBatchFixedTime =
        costComponent.level === 'Unit' && costComponent.details.batch_time_components?.type === 'Fixed'
            ? costComponent.details.batch_time_components.details.fixed_time
            : undefined;

    const unitBatchLinearFixedTime =
        costComponent.level === 'Unit' && costComponent.details.batch_time_components?.type === 'Linear'
            ? costComponent.details.batch_time_components.details.fixed_time
            : undefined;

    // Batch level
    const batchFixedTime =
        costComponent.level === 'Batch' && costComponent.details.time_components?.type === 'Fixed'
            ? costComponent.details.time_components.details.fixed_time
            : undefined;

    const batchLinearFixedTIme =
        costComponent.level === 'Batch' && costComponent.details.time_components?.type === 'Linear'
            ? costComponent.details.time_components.details.fixed_time
            : undefined;

    // Project level
    const projectFixedTime =
        costComponent.level === 'Project' && costComponent.details.time_components?.type === 'Fixed'
            ? costComponent.details.time_components.details.fixed_time
            : undefined;

    const projectLinearFixedTIme =
        costComponent.level === 'Project' && costComponent.details.time_components?.type === 'Linear'
            ? costComponent.details.time_components.details.fixed_time
            : undefined;

    return {
        unitTimeCalculation: {
            fixed: unitFixedTime,
            linear: unitLinearFixedTime,
        },
        unitBatchTimeCalculation: {
            fixed: unitBatchFixedTime,
            linear: unitBatchLinearFixedTime,
        },
        batchTimeCalculation: {
            fixed: batchFixedTime,
            linear: batchLinearFixedTIme,
        },
        projectTimeCalculation: {
            fixed: projectFixedTime,
            linear: projectLinearFixedTIme,
        },
    };
}
