import * as z from 'zod';
import { endpoint } from '../http/endpoint';
import {
    TotalCostOfOwnershipInputDTORuntype,
    TotalCostOfOwnershipRuleDTORuntype,
} from './totalCostOfOwnershipRulesBackendTypes';

export const totalCostOfOwnershipRulesEndpoints = {
    'GET /total-cost-of-ownership-rules': endpoint({
        description: 'Fetches all total cost of ownership rules',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.array(TotalCostOfOwnershipRuleDTORuntype),
    }),

    'POST /total-cost-of-ownership-rules': endpoint({
        description: 'Creates a new total cost of ownership rule',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: TotalCostOfOwnershipInputDTORuntype,
        responseBody: z.unknown(),
        invalidates: ['GET /total-cost-of-ownership-rules'],
    }),

    'PATCH /total-cost-of-ownership-rules/:id': endpoint({
        description: 'Updates a single total cost of ownership rule by ID',
        pathParams: z.object({ id: z.string() }),
        queryParams: z.undefined(),
        requestBody: TotalCostOfOwnershipInputDTORuntype,
        responseBody: z.unknown(),
        invalidates: ['GET /total-cost-of-ownership-rules'],
    }),

    'DELETE /total-cost-of-ownership-rules/:id': endpoint({
        description: 'Deletes a single total cost of ownership rule by ID',
        pathParams: z.object({ id: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.unknown(),
        invalidates: ['GET /total-cost-of-ownership-rules'],
    }),
};
