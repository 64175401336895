import { colorSystem, Text as DesignSystemText, Tooltip } from '@luminovo/design-system';
import { Clear } from '@mui/icons-material';
import { styled } from '@mui/material';
import React from 'react';

export function Legend<TKeys extends string>({
    keys,
    getColor,
    formatKey,
    selectedKeys,
    setSelectedKeys,
    getTooltip = () => undefined,
}: {
    keys: TKeys[];
    getColor?: (key: TKeys) => string;
    formatKey: (key: TKeys) => string;
    getTooltip?: (key: TKeys) => string | undefined;
    selectedKeys: TKeys[];
    setSelectedKeys: React.Dispatch<React.SetStateAction<TKeys[]>>;
}): JSX.Element {
    const allSelected = selectedKeys.length === keys.length;
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                gap: 8,
                justifyContent: 'flex-end',
            }}
        >
            {keys.map((key, i) => {
                const isSelected = selectedKeys.includes(key) && !allSelected;
                return (
                    <Tooltip title={getTooltip(key) ?? ''} key={i} placement="top">
                        <LegendButton
                            key={i}
                            role="button"
                            onClick={() =>
                                setSelectedKeys((selectedKeys) =>
                                    selectedKeys.length === 1 && selectedKeys[0] === key ? keys : [key],
                                )
                            }
                            style={
                                isSelected
                                    ? {
                                          background: colorSystem.primary[1],
                                          outline: `1px solid ${colorSystem.neutral[8]}`,
                                      }
                                    : {}
                            }
                        >
                            {!isSelected ? (
                                <div
                                    style={{
                                        borderRadius: 2,
                                        width: 8,
                                        height: 8,
                                        backgroundColor: getColor!(key),
                                    }}
                                />
                            ) : (
                                <Clear style={{ width: 12, height: '100%', color: colorSystem.neutral[6] }} />
                            )}

                            <DesignSystemText>{formatKey(key)}</DesignSystemText>
                        </LegendButton>
                    </Tooltip>
                );
            })}{' '}
        </div>
    );
}

const LegendButton = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    gap: 4,
    alignItems: 'center',
    justifyContent: 'flex-end',
    cursor: 'pointer',
    padding: '0 4px',
    boxSizing: 'border-box',
    borderRadius: 4,
    '&:hover': {
        outline: `1px solid ${colorSystem.neutral[5]}`,
    },
});
