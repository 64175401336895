import { Chip, Flexbox } from '@luminovo/design-system';
import { TableCell, TableHead, TableRow, Typography, styled } from '@mui/material';
import React from 'react';
import { AutocompleteColumnTags } from '../AutocompleteColumnTags/AutocompleteColumnTags';
import { getSelectedOptions } from '../AutocompleteColumnTags/reducer';
import { Column, ColumnTag, ColumnTagsSheetsAction, ColumnTagsSheetsState } from '../AutocompleteColumnTags/types';
import { topBoxId } from './const';

const ColumnNameCell = styled(TableCell)({
    verticalAlign: 'top',
    height: '100%',
});

const AutocompleteCell = styled(TableCell)({
    verticalAlign: 'top',
    height: '100%',
});

export function BomImporterTableHeaderRow({
    columns,
    haveBomImporterLinesChanged,
    setHaveBomImporterLinesChanged,
    tagsState,
    dispatch,
    isEditable = true,
}: {
    columns: Column[];
    haveBomImporterLinesChanged?: boolean;
    setHaveBomImporterLinesChanged?: React.Dispatch<React.SetStateAction<boolean>>;
    tagsState?: ColumnTagsSheetsState;
    dispatch?: React.Dispatch<ColumnTagsSheetsAction>;
    isEditable?: boolean;
}) {
    const firstHeaderRef = React.useRef<HTMLTableRowElement>(null);
    const [firstHeaderTop, setFirstHeaderTop] = React.useState(0);

    React.useLayoutEffect(() => {
        const top = firstHeaderRef.current?.offsetHeight ?? 0;
        setFirstHeaderTop(top);
        // Re-calculate the height of the first header whenever the tagsState changes
        // Ideally we would want to use a ResizeObserver, but this seems to work for
        // now.
    }, [firstHeaderRef, tagsState]);

    const columnTaggedCells =
        tagsState && dispatch
            ? columns.map((column) => {
                  const currentSheetIndex =
                      tagsState.activeTab === 'AML' && tagsState.amlSheetIndex
                          ? tagsState.amlSheetIndex
                          : tagsState.bomSheetIndex;
                  if (!isEditable) {
                      const selectedOptions = getSelectedOptions(tagsState, column.id, currentSheetIndex);

                      if (selectedOptions.length === 0) {
                          return <ColumnNameCell key={column.id} />;
                      }

                      return (
                          <ColumnNameCell key={column.id}>
                              <Flexbox gap={4} flexDirection={'row'} flexWrap="wrap" justifyContent={'flex-start'}>
                                  {selectedOptions.map((option, i) => {
                                      return <Chip key={i} color={'primary'} label={option.label} />;
                                  })}
                              </Flexbox>
                          </ColumnNameCell>
                      );
                  }
                  const selectedOptions = getSelectedOptions(tagsState, column.id, currentSheetIndex);
                  const className = getClassNameForHelpHero(selectedOptions);

                  return (
                      <AutocompleteCell key={column.id} style={{ padding: '8px 16px' }} className={className}>
                          <AutocompleteColumnTags
                              state={tagsState}
                              dispatch={dispatch}
                              column={column}
                              haveBomImporterLinesChanged={haveBomImporterLinesChanged}
                              setHaveBomImporterLinesChanged={setHaveBomImporterLinesChanged}
                          />
                      </AutocompleteCell>
                  );
              })
            : [];

    const columnNameCells = columns.map((column, i) => {
        return (
            <ColumnNameCell
                key={i}
                style={{
                    top: firstHeaderTop,
                }}
            >
                <Typography key={i} variant="h5" color="textSecondary">
                    {column.label}
                </Typography>
            </ColumnNameCell>
        );
    });

    return (
        <TableHead id={topBoxId}>
            <TableRow ref={firstHeaderRef}>{columnTaggedCells}</TableRow>
            <TableRow>{columnNameCells}</TableRow>
        </TableHead>
    );
}

const getClassNameForHelpHero = (tags: ColumnTag[]) => {
    const hasMpnTag = tags.some((tag) => tag.id === 'mpn');
    const hasTechnicalParametersTag = tags.some((tag) => tag.id === 'technical_parameters');
    const hasConsignedTag = tags.some((tag) => tag.id === 'consigned');

    return [
        hasMpnTag ? 'helphero-has-mpn-tag' : '',
        hasTechnicalParametersTag ? 'helphero-has-technical-parameters-tag' : '',
        hasConsignedTag ? 'helphero-has-consigned-tag' : '',
    ]
        .join(' ')
        .trim();
};
