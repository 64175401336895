import { t } from '@lingui/macro';
import { CenteredLayout, Flexbox } from '@luminovo/design-system';
import { ManufacturingScenarioTemplateDTO } from '@luminovo/http-client';
import { CircularProgress } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { FormContainer } from '../../../../components/formLayouts/FormContainer';
import { SubmitButton } from '../../../../components/formLayouts/SubmitButton';
import { useHttpQuery } from '../../../../resources/http/useHttpQuery';
import { useHttpMutation } from '../../../../resources/mutation/useHttpMutation';
import { TemplateSelectionControlled } from './TemplateSelection';
import { AddManufacturingScenarioFormState, TemplateSelectionData } from './types';

export const EditManufacturingScenarioTemplatesForm = ({
    manufacturingScenarioId,
    onSuccess,
}: {
    manufacturingScenarioId: string;
    onSuccess: () => void;
}): JSX.Element => {
    const { data: manufacturingScenarioTemplates } = useHttpQuery('GET /manufacturing-scenario-templates', {
        queryParams: {},
    });
    const { mutateAsync } = useHttpMutation('PATCH /manufacturing-scenarios/:manufacturingScenarioId/append', {
        snackbarMessage: t`Templates successfully appended`,
        onSuccess: (result) => {
            onSuccess();
        },
    });

    const defaultValues: TemplateSelectionData = {
        manufacturingScenarioTemplateIds: [],
    };

    const onSubmit = async (form: TemplateSelectionData) => {
        await mutateAsync({
            pathParams: {
                manufacturingScenarioId: manufacturingScenarioId,
            },
            queryParams: {
                from_templates: form.manufacturingScenarioTemplateIds,
            },
        });
    };

    if (!manufacturingScenarioTemplates?.data) {
        return (
            <CenteredLayout>
                <CircularProgress />
            </CenteredLayout>
        );
    }

    return (
        <FormContainer defaultValues={defaultValues} onSubmit={onSubmit}>
            <Flexbox flexDirection="column" height="100%" gap="24px">
                <Flexbox style={{ flexGrow: 1 }}>
                    <EditManufacturingScenarioTemplatesFormInner
                        manufacturingScenarioTemplates={manufacturingScenarioTemplates.data.filter(
                            (t) => t.status === 'Active',
                        )}
                    />
                </Flexbox>
                <Flexbox flexDirection="row" justifyContent="end">
                    <SubmitButton />
                </Flexbox>
            </Flexbox>
        </FormContainer>
    );
};

const EditManufacturingScenarioTemplatesFormInner = ({
    manufacturingScenarioTemplates,
}: {
    manufacturingScenarioTemplates: ManufacturingScenarioTemplateDTO[];
}): JSX.Element => {
    const { control } = useFormContext<AddManufacturingScenarioFormState>();

    return (
        <Flexbox flexDirection="column" width="100%" gap="24px">
            <TemplateSelectionControlled
                control={control}
                manufacturingScenarioTemplates={manufacturingScenarioTemplates}
            />
        </Flexbox>
    );
};
