import {
    ApprovalStatus,
    FullPart,
    isCustomComponentFull,
    isCustomFullPart,
    isGenericFullPart,
    isOtsComponentFull,
    isOtsFullPart,
    OriginSetMethod,
    PartOptionDTO,
    StandardPartTypes,
} from '@luminovo/http-client';
import { assertUnreachable } from '../../utils/typingUtils';

/**
 * Converts the part to a PartOption if possible
 */
export function convertToPartOption(
    fullPart: FullPart,
    opts: { approvalStatus: ApprovalStatus; setMethod: OriginSetMethod } = {
        approvalStatus: ApprovalStatus.Approved,
        setMethod: OriginSetMethod.Manual,
    },
): PartOptionDTO | undefined {
    if (isOtsFullPart(fullPart)) {
        return {
            part: { type: StandardPartTypes.OffTheShelf, data: fullPart.id },
            approval_status: opts.approvalStatus,
            origin: { set_method: opts.setMethod, column: null, candidate: null, reasons: [] },
        };
    }
    if (isGenericFullPart(fullPart)) {
        return {
            part: { type: StandardPartTypes.Generic, data: fullPart.id },
            approval_status: opts.approvalStatus,
            origin: { set_method: opts.setMethod, column: null, candidate: null, reasons: [] },
        };
    }
    if (isOtsComponentFull(fullPart)) {
        return {
            part: { type: StandardPartTypes.Ipn, data: fullPart.id },
            approval_status: opts.approvalStatus,
            origin: { set_method: opts.setMethod, column: null, candidate: null, reasons: [] },
        };
    }
    if (isCustomFullPart(fullPart) || isCustomComponentFull(fullPart)) {
        return undefined;
    }

    assertUnreachable(fullPart);
}
