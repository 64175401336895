import { t, Trans } from '@lingui/macro';
import { CenteredLayout, colorSystem, Flexbox, TertiaryButton, Text } from '@luminovo/design-system';
import { Download } from '@mui/icons-material';
import React from 'react';
import { SpinnerWithBackdrop } from '../../../components/Spinners';
import { useHttpQuery } from '../../../resources/http/useHttpQuery';

const InfoItem = ({
    title,
    children,
    style,
}: {
    title: string;
    children: React.ReactNode;
    style?: React.CSSProperties;
}) => {
    return (
        <Flexbox flexDirection="column" gap="4px" style={style}>
            <Text variant="h4">{title}</Text>
            {children}
        </Flexbox>
    );
};

export const ShippingAndPaymentCard = ({ rfqId }: { rfqId: string }) => {
    const { data: customerPortalSettings, isLoading } = useHttpQuery('GET /customer-portal-settings', {});
    const { data: quotationUrl, isLoading: isLoadingQuotationUrl } = useHttpQuery(
        'GET /rfqs/:rfqId/quotation',
        { pathParams: { rfqId } },
        { select: (res) => res.data?.url },
    );

    if (isLoading) {
        return (
            <CenteredLayout>
                <SpinnerWithBackdrop noBackdrop={true} />
            </CenteredLayout>
        );
    }
    // current customer use cases are either both shipping text and payment text or neither.
    // it is fine if any of the links are not there though.
    if (
        customerPortalSettings === undefined ||
        customerPortalSettings.shipping_information_text == null ||
        customerPortalSettings.payment_information_text == null
    ) {
        return null;
    }

    const shippingLink = customerPortalSettings.shipping_information_link;

    const paymentLink = customerPortalSettings.payment_information_link;

    return (
        <Flexbox
            borderRadius="8px"
            bgcolor={colorSystem.neutral['white']}
            flexDirection="column"
            gap={32}
            padding="24px"
        >
            <Text variant="h3">
                <Trans>Order information</Trans>
            </Text>

            <Flexbox flexDirection="column" gap="24px">
                <InfoItem title={t`Shipping`}>
                    <Text variant="body" color={colorSystem.neutral[9]}>
                        <div>{customerPortalSettings.shipping_information_text}</div>
                        {shippingLink && (
                            <span
                                onClick={() => {
                                    window.open(shippingLink, '_blank');
                                }}
                                style={{ cursor: 'pointer' }}
                            >
                                <u>{t`Shipping Terms`}</u>
                            </span>
                        )}
                    </Text>
                </InfoItem>

                <InfoItem title={t`Payment`}>
                    <Text variant="body" color={colorSystem.neutral[9]}>
                        <div>{customerPortalSettings.payment_information_text}</div>
                        {paymentLink && (
                            <span
                                onClick={() => {
                                    window.open(paymentLink, '_blank');
                                }}
                                style={{ cursor: 'pointer' }}
                            >
                                <u>{t`Payment Terms`}</u>
                            </span>
                        )}
                    </Text>
                </InfoItem>

                {quotationUrl && !isLoadingQuotationUrl && (
                    <InfoItem title={t`Quotation document`}>
                        <a href={quotationUrl} download>
                            <TertiaryButton startIcon={<Download fontSize="inherit" />} size="small">
                                {t`Download`}
                            </TertiaryButton>
                        </a>
                    </InfoItem>
                )}
            </Flexbox>
        </Flexbox>
    );
};
