import { Trans, t } from '@lingui/macro';
import { assertUnreachable, isPresent } from '@luminovo/commons';
import {
    ButtonGroup,
    ButtonGroupItem,
    FieldDateControlled,
    FieldNumericControlled,
    Flexbox,
    FormItem,
    RightBoxDrawer,
    SecondaryButton,
    Switch,
    Tag,
    Text,
    colorSystem,
} from '@luminovo/design-system';
import {
    DemandScenarioCreationDTO,
    DemandScenarioDTO,
    DemandScenarioUpdateDTO,
    RfqAssemblyDTO,
    RfqDTO,
} from '@luminovo/http-client';
import { Box, InputAdornment } from '@mui/material';
import { useEffect, useState } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { CloseDrawerButton, useDrawerContext } from '../../components/contexts/ModalContext';
import { FormContainer, ValidationErrors } from '../../components/formLayouts/FormContainer';
import { SubmitButton } from '../../components/formLayouts/SubmitButton';
import { useHttpMutation } from '../../resources/mutation/useHttpMutation';
import {
    PrototypeDemandScenarioFormState,
    SeriesDemandScenarioFormState,
    createDefaultPrototypeDemandScenario,
    createDefaultSeriesDemandScenario,
    defaultDeliveryBatchSizes,
} from '../RfqCreation/RfqCreationContext';
import { DeliveryBatchSizeInfoGraphic } from '../RfqCreation/components/Form/DeliveryBatchSizeInfoGraphic';
import { validateSeriesDemandStartEndYears } from './validateSeriesDemandStartEndYears';

interface DemandScenarioCreation {
    demand_scenario: PrototypeDemandScenarioFormState | SeriesDemandScenarioFormState;
}

const PrototypeDemandScenarioInput = ({
    topLevelAssemblies,
}: {
    topLevelAssemblies: RfqAssemblyDTO[];
}): JSX.Element => {
    const { control } = useFormContext<DemandScenarioCreation>();
    const { fields } = useFieldArray({
        control,
        name: `demand_scenario.demands`,
    });
    return (
        <>
            <Box
                sx={{
                    padding: '16px',
                    borderBottom: '1px solid',
                    borderColor: colorSystem.neutral[3],
                }}
                display="flex"
                flexDirection="column"
                gap={3}
            >
                <FormItem label={t`Quantity`} required>
                    {fields.map((_demand, demandIndex) => (
                        <Flexbox key={demandIndex} alignItems="center" gap={8}>
                            <FieldNumericControlled
                                control={control}
                                name={`demand_scenario.demands.${demandIndex}.quantity`}
                                min={0}
                                isInteger={true}
                                max={100_000_000}
                                FieldProps={{
                                    placeholder: t`Quantity`,
                                    style: {
                                        width: 120,
                                    },
                                }}
                            />
                            <Text variant="body-semibold" color={colorSystem.neutral[6]}>
                                {topLevelAssemblies[demandIndex].designator}
                            </Text>
                        </Flexbox>
                    ))}
                </FormItem>
                {/* TODO: should this be delivery date? or target date?*/}
                <FormItem label={t`Delivery date (ASAP if empty)`}>
                    <FieldDateControlled
                        control={control}
                        name={`demand_scenario.target_date`}
                        inFuture={true}
                        FieldProps={{
                            style: {
                                width: 200,
                            },
                        }}
                    />
                </FormItem>
            </Box>
        </>
    );
};

function getYearsInBetween(startYear: number, endYear: number): number[] {
    return Array.from({ length: endYear - startYear + 1 }, (_, i) => startYear + i);
}

const useSyncAnnualDemandsWithStartAndEndYears = ({ demandIndex }: { demandIndex: number }) => {
    const { control, setValue } = useFormContext<DemandScenarioCreation>();
    const startYear = useWatch({ control, name: `demand_scenario.start_year` });
    const endYear = useWatch({ control, name: `demand_scenario.end_year` });
    const annualDemands = useWatch({
        control,
        name: `demand_scenario.demands.${demandIndex}.annual_demands`,
    });

    useEffect(() => {
        setTimeout(() => {
            const error = validateSeriesDemandStartEndYears(startYear, endYear);

            if (error !== undefined) {
                return;
            }

            const yearsInBetweenStartAndEnd = getYearsInBetween(Number(startYear), Number(endYear));
            const newAnnualDemands = yearsInBetweenStartAndEnd.map((year) => {
                const oldAnnualDemand = annualDemands.find((annualDemand) => annualDemand.year === year);
                return {
                    year,
                    quantity: oldAnnualDemand?.quantity ?? 0,

                    delivery_batch_sizes: oldAnnualDemand?.delivery_batch_sizes ?? defaultDeliveryBatchSizes,
                };
            });
            setValue(`demand_scenario.demands.${demandIndex}.annual_demands`, newAnnualDemands);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startYear, endYear]);
};

const DeliveryBatchSizesMoreInfo = (): JSX.Element => {
    return (
        <Flexbox flexDirection="column" gap={12} padding={2}>
            <Text variant="h2">
                <Trans>What are delivery batch sizes?</Trans>
            </Text>
            <Text>
                <Trans>
                    To better document your customer's demand you can specify the wished size of delivery batches per
                    year. Please note that this step is for documentation purposes only and completely optional.
                </Trans>
            </Text>
            <Text>
                <Trans>You can add up to three different delivery batch sizes per year.</Trans>
            </Text>
            <DeliveryBatchSizeInfoGraphic />
        </Flexbox>
    );
};

const useDeliveryBatchSizesDrawer = () => {
    const { closeDrawer, setDrawer } = useDrawerContext();

    const drawer = (
        <RightBoxDrawer onClose={closeDrawer}>
            <Flexbox flexDirection="column" width="600px" minWidth="600px">
                <CloseDrawerButton />
                <DeliveryBatchSizesMoreInfo />
            </Flexbox>
        </RightBoxDrawer>
    );

    return () => setDrawer(drawer);
};

const DeliveryBatchSizeInputRow = ({
    demandIndex,
    annualDemandIndex,
}: {
    demandIndex: number;
    annualDemandIndex: number;
}): JSX.Element => {
    const { control } = useFormContext<DemandScenarioCreation>();
    const fieldName =
        `demand_scenario.demands.${demandIndex}.annual_demands.${annualDemandIndex}.delivery_batch_sizes` as const;
    const { fields } = useFieldArray({
        control,
        name: fieldName,
    });

    return (
        <Flexbox gap={8}>
            {fields.map((item, index) => (
                <FieldNumericControlled
                    key={item.id}
                    control={control}
                    FieldProps={{
                        InputProps: {
                            style: {
                                width: 90,
                            },
                        },
                    }}
                    name={`${fieldName}.${index}.quantity`}
                    isInteger
                    min={1}
                    max={100_000_000}
                    displayErrorAsTooltip={true}
                />
            ))}
        </Flexbox>
    );
};

const DeliveryBatchSizeInputs = ({ demandIndex }: { demandIndex: number }): JSX.Element => {
    const { control } = useFormContext<DemandScenarioCreation>();
    const annualDemandsFieldName = `demand_scenario.demands.${demandIndex}.annual_demands` as const;
    const { fields } = useFieldArray({
        control,
        name: annualDemandsFieldName,
    });

    const openDrawer = useDeliveryBatchSizesDrawer();

    return (
        <FormItem
            label={
                <Flexbox gap={16} justifyContent="space-between">
                    <Text variant="h4" color={colorSystem.neutral[6]}>
                        <Trans>Delivery batch size</Trans>
                    </Text>
                    <Text
                        variant="h4"
                        color={colorSystem.blue[5]}
                        onClick={() => {
                            openDrawer();
                        }}
                        style={{
                            cursor: 'pointer',
                        }}
                    >
                        <Trans>More info</Trans>
                    </Text>
                </Flexbox>
            }
            LabelProps={{
                width: '100%',
            }}
        >
            {fields.map((item, index) => (
                <DeliveryBatchSizeInputRow key={item.id} demandIndex={demandIndex} annualDemandIndex={index} />
            ))}
        </FormItem>
    );
};

const AnnualDemandInputs = ({
    demandIndex,
    addDeliveryBatchSizes,
}: {
    demandIndex: number;
    addDeliveryBatchSizes: boolean;
}): JSX.Element => {
    const { control } = useFormContext<DemandScenarioCreation>();
    const annualDemandsFieldName = `demand_scenario.demands.${demandIndex}.annual_demands` as const;
    const { fields } = useFieldArray({
        control,
        name: annualDemandsFieldName,
    });
    const annualDemands = useWatch({ control, name: annualDemandsFieldName });
    useSyncAnnualDemandsWithStartAndEndYears({ demandIndex: demandIndex });

    return (
        <>
            <Flexbox gap={24}>
                <FormItem
                    label={t`Annual demand`}
                    required
                    LabelProps={{
                        style: {
                            color: colorSystem.neutral[6],
                        },
                    }}
                >
                    {fields.map((item, index) => (
                        <FieldNumericControlled
                            key={item.id}
                            control={control}
                            FieldProps={{
                                placeholder: t`Quantity`,
                                InputProps: {
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Tag color="neutral" label={`${item.year}`} attention="low" />
                                        </InputAdornment>
                                    ),
                                    style: {
                                        width: 182,
                                    },
                                },
                            }}
                            name={`${annualDemandsFieldName}.${index}.quantity`}
                            isInteger
                            min={0}
                            max={100_000_000}
                        />
                    ))}
                </FormItem>
                {addDeliveryBatchSizes && <DeliveryBatchSizeInputs demandIndex={demandIndex} />}
            </Flexbox>
            <Flexbox gap={4}>
                <Text color={colorSystem.neutral[6]}>
                    <Trans>Total volume</Trans>:
                </Text>
                <Text>{annualDemands.reduce((acc, item) => acc + (item.quantity ?? 0), 0)}</Text>
            </Flexbox>
        </>
    );
};

const useResetDeliveryBatchSizes = () => {
    const { control, setValue } = useFormContext<DemandScenarioCreation>();
    const demandScenario = useWatch({ control, name: 'demand_scenario' });

    return () => {
        if (demandScenario.type === 'Prototype') {
            return;
        }

        demandScenario.demands.forEach((demand, demandIndex) => {
            demand.annual_demands.forEach((_annualDemand, annualDemandIndex) => {
                setValue(
                    `demand_scenario.demands.${demandIndex}.annual_demands.${annualDemandIndex}.delivery_batch_sizes`,
                    defaultDeliveryBatchSizes,
                );
            });
        });
    };
};

const useAddDeliveryBatchSizesState = () => {
    const { control } = useFormContext<DemandScenarioCreation>();
    const demands = useWatch({ control, name: `demand_scenario.demands` });

    const initValue = demands.some((demand) => {
        return (
            'annual_demands' in demand &&
            Array.isArray(demand.annual_demands) &&
            demand.annual_demands.some((annualDemand) => {
                return annualDemand.delivery_batch_sizes.some((batchSize) => batchSize.quantity !== undefined);
            })
        );
    });

    return useState(initValue);
};

const SeriesDemandInputs = ({ topLevelAssemblies }: { topLevelAssemblies: RfqAssemblyDTO[] }): JSX.Element => {
    const { control } = useFormContext<DemandScenarioCreation>();

    const [addDeliveryBatchSizes, setAddDeliveryBatchSizes] = useAddDeliveryBatchSizesState();

    const demandsFieldName = `demand_scenario.demands` as const;
    const { fields } = useFieldArray({
        control,
        name: demandsFieldName,
    });
    const resetDeliveryBatchSizes = useResetDeliveryBatchSizes();
    return (
        <Flexbox flexDirection="column" gap={24}>
            {fields.map((demand, demandIndex) => (
                <FormItem
                    key={demand.id}
                    label={
                        <Flexbox alignItems="center" justifyContent="space-between">
                            <Text variant="h4">{topLevelAssemblies[demandIndex].designator}</Text>
                            {demandIndex === 0 && (
                                <Flexbox alignItems="center" gap={4}>
                                    <Switch
                                        checked={addDeliveryBatchSizes}
                                        onChange={() =>
                                            setAddDeliveryBatchSizes((oldValue) => {
                                                if (oldValue) {
                                                    resetDeliveryBatchSizes();
                                                }
                                                return !oldValue;
                                            })
                                        }
                                    />
                                    <Text variant="body-semibold" color={colorSystem.neutral[7]}>
                                        <Trans>Add delivery batch sizes</Trans>
                                    </Text>
                                </Flexbox>
                            )}
                        </Flexbox>
                    }
                    LabelProps={{
                        width: '100%',
                    }}
                >
                    <AnnualDemandInputs demandIndex={demandIndex} addDeliveryBatchSizes={addDeliveryBatchSizes} />
                </FormItem>
            ))}
        </Flexbox>
    );
};

const useStartYearValidation = () => {
    const endYear = useWatch({
        control: useFormContext<DemandScenarioCreation>().control,
        name: `demand_scenario.end_year`,
    });
    return (startYear: unknown) => validateSeriesDemandStartEndYears(startYear, endYear);
};

const SeriesDemandScenarioInput = ({ topLevelAssemblies }: { topLevelAssemblies: RfqAssemblyDTO[] }): JSX.Element => {
    const { control, trigger } = useFormContext<DemandScenarioCreation>();
    const startYearValidation = useStartYearValidation();
    const endYear = useWatch({
        control: control,
        name: `demand_scenario.end_year`,
    });

    useEffect(() => {
        trigger(`demand_scenario.start_year`);
    }, [endYear, trigger]);

    return (
        <>
            <Box
                sx={{
                    padding: '16px',
                    borderBottom: '1px solid',
                    borderColor: colorSystem.neutral[3],
                }}
            >
                <FormItem label={t`Time frame`} required>
                    <Flexbox gap={12} alignItems="center">
                        <FieldNumericControlled
                            control={control}
                            name={`demand_scenario.start_year`}
                            FieldProps={{
                                placeholder: 'YYYY',
                                style: {
                                    width: 100,
                                },
                            }}
                            validate={startYearValidation}
                            displayErrorAsTooltip
                        />
                        {/* this is an en dash, because a normal hyphen is too short */}
                        <Text>–</Text>
                        <FieldNumericControlled
                            control={control}
                            name={`demand_scenario.end_year`}
                            FieldProps={{
                                placeholder: 'YYYY',
                                style: {
                                    width: 100,
                                },
                            }}
                            displayErrorAsTooltip
                        />
                    </Flexbox>
                </FormItem>
            </Box>
            <Box
                sx={{
                    padding: '16px',
                    borderBottom: '1px solid',
                    borderColor: colorSystem.neutral[3],
                }}
            >
                <SeriesDemandInputs topLevelAssemblies={topLevelAssemblies} />
            </Box>
        </>
    );
};

const DemandScenarioInput = ({ topLevelAssemblies }: { topLevelAssemblies: RfqAssemblyDTO[] }): JSX.Element => {
    const { setValue, control } = useFormContext<DemandScenarioCreation>();
    const name = `demand_scenario` as const;
    const demandScenarioType = useWatch({ control, name: `${name}.type` });
    return (
        <Box
            sx={{
                borderColor: colorSystem.neutral[3],
                borderRadius: '8px',
                marginBottom: '16px',
            }}
        >
            <Box
                sx={{
                    padding: '16px',
                    background: colorSystem.neutral[0],
                    borderColor: colorSystem.neutral[3],
                }}
            >
                <Flexbox justifyContent={'space-between'}>
                    <ButtonGroup size="large">
                        <ButtonGroupItem
                            selected={demandScenarioType === 'Prototype'}
                            onClick={() => {
                                setValue(name, createDefaultPrototypeDemandScenario(topLevelAssemblies.length));
                            }}
                        >
                            <Trans>Prototype</Trans>
                        </ButtonGroupItem>
                        <ButtonGroupItem
                            selected={demandScenarioType === 'Series'}
                            onClick={() => {
                                setValue(name, createDefaultSeriesDemandScenario(topLevelAssemblies.length));
                            }}
                        >
                            <Trans>Series</Trans>
                        </ButtonGroupItem>
                    </ButtonGroup>
                </Flexbox>
            </Box>
            {demandScenarioType === 'Prototype' ? (
                <PrototypeDemandScenarioInput topLevelAssemblies={topLevelAssemblies} />
            ) : demandScenarioType === 'Series' ? (
                <SeriesDemandScenarioInput topLevelAssemblies={topLevelAssemblies} />
            ) : (
                assertUnreachable(demandScenarioType)
            )}
        </Box>
    );
};

export const AddDemandScenarioFormInner = ({ rfq, onSuccess }: { rfq: RfqDTO; onSuccess: () => void }): JSX.Element => {
    const { mutateAsync } = useHttpMutation('POST /demand-scenarios', {
        snackbarMessage: t`Demand scenario added`,
        onSuccess,
    });

    const handleSubmit = async ({ demand_scenario }: DemandScenarioCreation) => {
        const requestBody: DemandScenarioCreationDTO =
            demand_scenario.type === 'Prototype'
                ? {
                      type: 'Prototype',
                      rfq_id: rfq.id,
                      demands: demand_scenario.demands.map((demand, demandIndex) => ({
                          quantity: demand.quantity ?? 0,
                          assembly_id: rfq.top_level_assemblies[demandIndex].id,
                      })),
                      target_date: demand_scenario.target_date
                          ? { type: 'Date', value: demand_scenario.target_date }
                          : { type: 'AsSoonAsPossible' },
                  }
                : {
                      type: 'Series',
                      rfq_id: rfq.id,
                      start_year: demand_scenario.start_year,
                      end_year: demand_scenario.end_year,
                      demands: demand_scenario.demands.map((demand, demandIndex) => ({
                          assembly_id: rfq.top_level_assemblies[demandIndex].id,
                          annual_demands: demand.annual_demands.map((annualDemand) => ({
                              quantity: annualDemand.quantity ?? 0,
                              year: annualDemand.year,
                              delivery_batch_sizes: annualDemand.delivery_batch_sizes
                                  .filter((size) => isPresent(size.quantity))
                                  .map((size) => ({
                                      quantity: size.quantity ?? 0,
                                  })),
                          })),
                      })),
                  };

        await mutateAsync({
            requestBody,
        });
    };
    const topLevelAssemblyCount = rfq.top_level_assemblies.length;
    return (
        <>
            <FormContainer
                onSubmit={handleSubmit}
                defaultValues={{
                    demand_scenario: createDefaultPrototypeDemandScenario(topLevelAssemblyCount),
                }}
                mode="onChange"
            >
                <DemandScenarioInput topLevelAssemblies={rfq.top_level_assemblies} />
                <SubmitButton />
            </FormContainer>
        </>
    );
};

export const EditDemandScenarioFormInner = ({
    demandScenario,
    onSuccess,
    rfq,
    onCancel,
}: {
    demandScenario: DemandScenarioDTO;
    onSuccess: () => void;
    rfq: RfqDTO;
    onCancel: () => void;
}): JSX.Element => {
    const { mutateAsync } = useHttpMutation('PATCH /demand-scenarios/:demandScenarioId', {
        snackbarMessage: t`Demand scenario updated`,
        onSuccess,
    });

    const handleSubmit = async ({ demand_scenario }: DemandScenarioCreation) => {
        const requestBody: DemandScenarioUpdateDTO =
            demand_scenario.type === 'Prototype'
                ? {
                      type: 'Prototype',
                      demands: demand_scenario.demands.map((demand, demandIndex) => ({
                          quantity: demand.quantity ?? 0,
                          assembly_id: rfq.top_level_assemblies[demandIndex].id,
                      })),
                      target_date: demand_scenario.target_date
                          ? { type: 'Date', value: demand_scenario.target_date }
                          : { type: 'AsSoonAsPossible' },
                  }
                : {
                      type: 'Series',
                      start_year: demand_scenario.start_year,
                      end_year: demand_scenario.end_year,
                      demands: demand_scenario.demands.map((demand, demandIndex) => ({
                          assembly_id: rfq.top_level_assemblies[demandIndex].id,
                          annual_demands: demand.annual_demands.map((annualDemand) => ({
                              quantity: annualDemand.quantity ?? 0,
                              year: annualDemand.year,
                              delivery_batch_sizes: annualDemand.delivery_batch_sizes
                                  .filter((size) => isPresent(size.quantity))
                                  .map((size) => ({ quantity: size.quantity ?? 0 })),
                          })),
                      })),
                  };
        await mutateAsync({
            pathParams: { demandScenarioId: demandScenario.id },
            requestBody,
        });
    };
    const defaultValues =
        demandScenario.type === 'Prototype'
            ? {
                  type: 'Prototype' as const,
                  demands: rfq.top_level_assemblies.map((assembly) => ({
                      quantity: demandScenario.demands.find((demand) => demand.assembly_id === assembly.id)
                          ?.delivery_date.quantity,
                  })),
                  delivery_date: demandScenario.demands.length
                      ? demandScenario.demands[0].delivery_date.target_date.type === 'Date'
                          ? demandScenario.demands[0].delivery_date.target_date.value
                          : undefined
                      : undefined,
              }
            : {
                  type: 'Series' as const,
                  start_year: demandScenario.start_year,
                  end_year: demandScenario.end_year,
                  demands: rfq.top_level_assemblies
                      .map((assembly) => {
                          const demand = demandScenario.demands.find((demand) => demand.assembly_id === assembly.id);
                          if (demand === undefined) {
                              return undefined;
                          }
                          return {
                              annual_demands: demand.annual_demands.map((annual_demand) => {
                                  const deliveryBatchSizes: { quantity: number | undefined }[] = [
                                      { quantity: undefined },
                                      { quantity: undefined },
                                      { quantity: undefined },
                                  ];
                                  annual_demand.delivery_batch_sizes.forEach((size, index) => {
                                      deliveryBatchSizes[index] = size;
                                  });
                                  return {
                                      year: annual_demand.year,
                                      quantity: annual_demand.quantity,
                                      delivery_batch_sizes: deliveryBatchSizes,
                                  };
                              }),
                          };
                      })
                      .filter(isPresent),
              };

    const validationErrors: ValidationErrors<DemandScenarioCreation> = {
        'demand.prototype_demand_scenario_date_not_in_future': { fieldPath: 'demand_scenario.target_date' },
        'demand.invalid_prototype_demand_quantity': { fieldPath: 'demand_scenario.demands' },
        'demand.invalid_demand_scenario_year': { fieldPath: 'demand_scenario.start_year' },
        'demand.invalid_demand_scenario_end_year': { fieldPath: 'demand_scenario.end_year' },
        'demand.no_annual_demands': { fieldPath: 'demand_scenario.demands' },
        'demand.invalid_annual_demand': { fieldPath: 'demand_scenario.demands' },
        'demand.multiple_annual_demands_for_one_year': { fieldPath: 'demand_scenario.demands' },
        'demand.missing_annual_demand': { fieldPath: 'demand_scenario.demands' },
        'demand.more_than_three_delivery_batch_sizes': { fieldPath: 'demand_scenario.demands' },
        'demand.delivery_batch_size_out_of_bounds': { fieldPath: 'demand_scenario.demands' },
        'demand.invalid_assembly_id': { fieldPath: 'root.serverError' },
    };
    return (
        <FormContainer
            onSubmit={handleSubmit}
            defaultValues={{
                demand_scenario: defaultValues,
            }}
            mode="onChange"
            validationErrors={validationErrors}
        >
            <DemandScenarioInput topLevelAssemblies={rfq.top_level_assemblies} />
            <Flexbox gap={8} justifyContent="end">
                <SecondaryButton onClick={onCancel}>
                    <Trans>Cancel</Trans>
                </SecondaryButton>
                <SubmitButton />
            </Flexbox>
        </FormContainer>
    );
};
