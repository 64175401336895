import * as z from 'zod';
import { HttpEndpoint } from './HttpEndpoint';

export type TQueryParamValue = string | number | boolean | string[];
interface EndpointProps<
    TPathParams extends z.ZodTypeAny,
    TQueryParams extends z.ZodTypeAny,
    THttpResponseBody extends z.ZodTypeAny,
    THttpRequestBody extends z.ZodTypeAny,
> {
    /**
     * Provide a human-readable description for the endpoint.
     */
    description: string;

    /**
     * Describes the path parameters of the endpoint.
     */
    pathParams: TPathParams;

    /**
     * Describes the query parameters of the endpoint
     */
    queryParams: TQueryParams;

    requestBody: THttpRequestBody;
    responseBody: THttpResponseBody;
    handleResponse?: (response: Response, registeredEndpoint: string) => Promise<z.infer<THttpResponseBody>>;

    /**
     * Array of endpoints to **invalidate** after a mutation call.
     */
    invalidates?: HttpEndpoint[];

    /**
     * Array of endpoints to **remove** after a mutation call. Typical used in combination with DELETE endpoints.
     */
    removes?: HttpEndpoint[];

    /**
     * Root URL of the endpoint. If not provided, the BACKEND_BASE will be used.
     */
    rootUrl?: string;
}

/**
 * Utility function that defines an endpoint. As you can see from the implementation,
 * this function doesn't do anything - it exists merely to enforce the definition of types.
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function endpoint<
    TPathParams extends z.ZodTypeAny,
    TQueryParams extends z.ZodTypeAny,
    THttpRequestBody extends z.ZodTypeAny,
    THttpResponseBody extends z.ZodTypeAny,
>({
    description,
    pathParams,
    queryParams,
    requestBody,
    responseBody,
    handleResponse,
    invalidates = [],
    removes = [],
    rootUrl,
}: EndpointProps<TPathParams, TQueryParams, THttpRequestBody, THttpResponseBody>) {
    return {
        description,
        pathParams,
        queryParams,
        requestBody,
        responseBody,
        handleResponse,
        invalidates,
        removes,
        rootUrl,
    };
}
