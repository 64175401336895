import * as z from 'zod';
import { HttpEndpoint } from '../http/HttpEndpoint';
import { endpoint } from '../http/endpoint';
import {
    ManufacturingScenarioDuplicatePostRuntype,
    ManufacturingScenarioFullSummaryRuntype,
    ManufacturingScenarioPatchRuntype,
    ManufacturingScenarioPostRuntype,
    ManufacturingScenarioRuntype,
} from './manufacturingScenarioBackendTypes';

const invalidates: HttpEndpoint[] = [
    'GET /manufacturing-scenarios',
    'GET /manufacturing-scenarios/:manufacturingScenarioId/full-summary',
    'GET /manufacturing-scenarios/:manufacturingScenarioId',
    'GET /manufacturing-assembly-details/:manufacturingAssemblyDetailsId/overview',
];

export const manufacturingScenarioEndpoints = {
    'GET /manufacturing-scenarios': endpoint({
        description: 'Fetches a list of manufacturing scenarios of a particular manufacturing assembly details',
        pathParams: z.undefined(),
        queryParams: z.object({
            manufacturing_assembly_details_id: z.string().optional(),

            assembly_id: z.string().optional(),
        }),
        requestBody: z.undefined(),
        responseBody: z.object({ data: z.array(ManufacturingScenarioRuntype) }),
        invalidates,
    }),
    'POST /manufacturing-scenarios': endpoint({
        description: 'Creates a new manufacturing scenario',
        pathParams: z.undefined(),
        queryParams: z.object({
            from_templates: z.array(z.string()).optional(),
            pcb_sides: z.number().optional(), // TODO: to be used in this story https://www.notion.so/luminovo/FE-Add-sides_of_placement-input-to-PCB-area-3ea60ce4b3b64f44aff648117935ec70 (please remove the comment when story is Done)
        }),
        requestBody: ManufacturingScenarioPostRuntype,
        responseBody: z.object({ data: ManufacturingScenarioRuntype }),
        invalidates,
    }),
    'PATCH /manufacturing-scenarios/:manufacturingScenarioId': endpoint({
        description: 'Edits an existing manufacturing scenario',
        pathParams: z.object({ manufacturingScenarioId: z.string() }),
        queryParams: z.undefined(),
        requestBody: ManufacturingScenarioPatchRuntype,
        responseBody: z.object({ data: ManufacturingScenarioRuntype }),
        invalidates,
    }),

    'POST /manufacturing-scenarios/:manufacturingScenarioId/duplicate': endpoint({
        description:
            'Creates a duplicate from an existing manufacturing scenario, including expense and activity configurations.',
        pathParams: z.object({ manufacturingScenarioId: z.string() }),
        queryParams: z.undefined(),
        requestBody: ManufacturingScenarioDuplicatePostRuntype,
        responseBody: z.object({ data: ManufacturingScenarioRuntype }),
        invalidates,
    }),

    'PATCH /manufacturing-scenarios/:manufacturingScenarioId/append': endpoint({
        description: 'Appends a template to a manufacturing scenario',
        pathParams: z.object({ manufacturingScenarioId: z.string() }),
        queryParams: z.object({
            from_templates: z.array(z.string()).optional(),
        }),
        requestBody: z.undefined(),
        responseBody: z.object({ data: ManufacturingScenarioRuntype }),
        invalidates,
    }),

    'GET /manufacturing-scenarios/:manufacturingScenarioId': endpoint({
        description: 'Fetches a particular manufacturing scenario ',
        pathParams: z.object({ manufacturingScenarioId: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({ data: ManufacturingScenarioRuntype }),
    }),

    'GET /manufacturing-scenarios/:manufacturingScenarioId/full-summary': endpoint({
        description: 'Fetches a full summary of a particular manufacturing scenario ',
        pathParams: z.object({ manufacturingScenarioId: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({ data: ManufacturingScenarioFullSummaryRuntype }),
    }),

    'DELETE /manufacturing-scenarios/:manufacturingScenarioId': endpoint({
        description: 'Deletes a particular manufacturing scenario ',
        pathParams: z.object({ manufacturingScenarioId: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({ deleted: z.number() }),
    }),
    'PATCH /manufacturing-scenarios/customer-portal/update-batch-size-from-sourcing-scenarios-order-size': endpoint({
        description:
            'Syncs the order sizes to batch sizes in all manufacturing scenarios. One example where it should be called is after editing order sizes over the customer portal.',
        pathParams: z.undefined(),
        queryParams: z.object({
            rfq_id: z.string(),
        }),
        requestBody: z.undefined(),
        responseBody: z.null(),
        invalidates,
    }),
    'PATCH /manufacturing-scenario-template-instances/:manufacturingScenarioTemplateInstanceId/rank': endpoint({
        description: 'Sets the new rank of a manufacturing scenario template instance',
        pathParams: z.object({ manufacturingScenarioTemplateInstanceId: z.string() }),
        queryParams: z.object({}),
        requestBody: z.object({ rank: z.number() }),
        responseBody: z.null(),
        invalidates,
    }),
    'DELETE /manufacturing-scenario-template-instances/:manufacturingScenarioTemplateInstanceId': endpoint({
        description: 'Deletes a manufacturing scenario template instance',
        pathParams: z.object({ manufacturingScenarioTemplateInstanceId: z.string() }),
        queryParams: z.object({}),
        requestBody: z.null(),
        responseBody: z.object({ deleted: z.number() }),
        invalidates: ['GET /manufacturing-scenarios/:manufacturingScenarioId/full-summary'],
    }),
};
