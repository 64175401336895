import { isProductionEnvironment, WEBSOCKET_BASE } from '../../const';
import { printFormattedRuntypeError } from '../../utils/runtypeUtils';
import { buildUrl } from '../http/http';
import { webSocketEndpointRegistry } from './websocketEndpointRegistry';
import {
    RegisteredWebSocketEndpoint,
    WebSocketEndpointOptions,
    WebSocketFullEndpointOptions,
    WebSocketMessageHandler,
} from './websocketTypes';
function composeErrorMessage({
    message,
    endpoint,
    url,
    errorMessage,
    responseBody,
}: {
    message: string;
    endpoint: RegisteredWebSocketEndpoint;
    url: string;
    errorMessage: string;
    responseBody?: unknown;
}) {
    const endpointDef = webSocketEndpointRegistry[endpoint];
    return `${message}

Endpoint      : ${endpoint}
Description   : ${endpointDef.description}


Error message :
${errorMessage}

Here's the relevant information:

URL           : ${url}
Response Body :
${responseBody === undefined ? 'None' : JSON.stringify(responseBody, null, 2)}
`;
}

// For now we only support receiving messages, not sending them
export function generateWebSocket<T extends RegisteredWebSocketEndpoint>({
    endpoint,
    options,
}: {
    endpoint: T;
    options: WebSocketEndpointOptions<T>;
}): WebSocket {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const unsafeOptions = options as WebSocketFullEndpointOptions<T>;

    const { url } = buildUrl({
        endpoint,
        rootUrl: WEBSOCKET_BASE,
        pathParams: unsafeOptions.pathParams ?? {},
        queryParams: unsafeOptions.queryParams ?? {},
    });

    const connection = new WebSocket(url);
    connection.onerror = () => {
        const errorMessage = composeErrorMessage({
            message: 'Error in websocket!',
            endpoint,
            url,
            errorMessage: 'WebSocket connection error',
        });

        throw new Error(errorMessage);
    };

    return connection;
}

/**
 * Creates a WebSocket message handler function that validates incoming messages against the expected response body
 * for the given WebSocket endpoint and invokes the provided message handler with the parsed message data.
 * @template T - The type of the registered WebSocket endpoint.
 * @param {T} endpoint - The registered WebSocket endpoint.
 * @param {(data: WebSocketEndpointResponseBody<T>) => void} messageHandler - The message handler function to invoke with the parsed message data.
 * @returns {(event: MessageEvent<string>) => void} - The WebSocket message handler function.
 */
export function makeWebSocketMessageHandler<T extends RegisteredWebSocketEndpoint>(
    endpoint: T,
    messageHandler: WebSocketMessageHandler<T>,
): (event: MessageEvent<string>) => void {
    return (unsafeEvent: MessageEvent<string>) => {
        try {
            const message = JSON.parse(unsafeEvent.data);

            if (!isProductionEnvironment()) {
                webSocketEndpointRegistry[endpoint].responseMessage.parse(message);
            }

            messageHandler(message);
        } catch (e) {
            const errorMessage = composeErrorMessage({
                message: 'Schema validation failed!',
                endpoint,
                url: '',
                errorMessage: printFormattedRuntypeError(e),
                responseBody: unsafeEvent.data,
            });

            throw new Error(errorMessage);
        }
    };
}

// Helper method to close the websocket connection
// The retry logic is needed because if the websocket is in the process of connecting,
// closing it throws an error (interrupted connection)
export function closeWsConnection(ws: WebSocket, retriesLeft: number = 3): Promise<void> {
    return new Promise((resolve) => {
        if (ws.readyState === WebSocket.CONNECTING && retriesLeft > 0) {
            setTimeout(() => closeWsConnection(ws, retriesLeft - 1), 100);
        } else {
            ws.close();
            resolve();
        }
    });
}
