import React from 'react';
import { useAssembly, useParentsIdentifiers } from '../../../resources/assembly/assemblyHandler';
import { useChangeToPcbPageIfPcbOnlyAssemblyType } from '../hooks/useChangeToPcbPageIfPcbOnlyAssemblyType';
import { BomHealthDashboard } from './BomHealthDashboard/BomHealthDashboard';
import { ViewContext } from './ModuleTableData';
import { useBOMTableData } from './ModuleTableData/useBOMTableData';
import { useChangeBomPageIfSingleAssembly } from './useChangeBomPageIfSingleAssembly';

interface BomViewerProps {
    rfqId: string;
    assemblyId: string;
    isEditable: boolean;
}

const BomViewer: React.FunctionComponent<BomViewerProps> = ({
    rfqId,
    assemblyId,
    isEditable,
}: BomViewerProps): JSX.Element => {
    const { data: { multi_parent_items: multiParentItems = [] } = {} } = useParentsIdentifiers(assemblyId);
    const { data: currentAssembly } = useAssembly(assemblyId);
    const {
        descendants,
        moduleData,
        containsNestedAssembliesOrDesignItems,
        isLoading: isModuleDataLoading,
    } = useBOMTableData({ assemblyId, rfqId });

    const viewContext: ViewContext = React.useMemo(() => {
        return {
            type: 'WithinRfQ',
            rfqId,
        };
    }, [rfqId]);

    useChangeToPcbPageIfPcbOnlyAssemblyType({
        rfqId,
        assembly: currentAssembly,
    });

    useChangeBomPageIfSingleAssembly({ rfqId, assemblyId });

    if (!currentAssembly || !descendants) {
        return <></>;
    }

    return (
        <BomHealthDashboard
            assemblyId={assemblyId}
            assembly={currentAssembly}
            descendants={descendants}
            moduleData={moduleData}
            isModuleDataLoading={isModuleDataLoading}
            isEditable={isEditable}
            parents={multiParentItems}
            isBomNestedTableShown={containsNestedAssembliesOrDesignItems}
            viewContext={viewContext}
        />
    );
};

export default BomViewer;
