import { CenteredLayout } from '@luminovo/design-system';
import { CircularProgress } from '@mui/material';
import * as React from 'react';
import { DataGridProps } from '../DataGrid';

const LazyDataGrid = React.lazy(() => import('../DataGrid').then((module) => ({ default: module.DataGrid })));

export function DataGrid<T>(props: DataGridProps<T>): JSX.Element {
    return (
        <React.Suspense
            fallback={
                <CenteredLayout>
                    <CircularProgress />
                </CenteredLayout>
            }
        >
            <LazyDataGrid {...(props as any)} />
        </React.Suspense>
    );
}
