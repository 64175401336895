import { ColumnName, PartSuggestionFull } from '@luminovo/http-client';
import { getPartOptions } from '../part/getPartOptions';
import { BomItem } from './bomItemFrontendTypes';

/**
 * @returns unique candidate MPNs coming both from the part suggestions AND the part specification
 */
export function getCandidateMpns(bomItem: BomItem, suggestions: PartSuggestionFull[]): string[] {
    const mpnCandidatesFromPartSuggestions: string[] = getCandidateMpnsSuggestions(suggestions);

    const mpnCandidatesFromPartOptions: string[] = getPartOptions(bomItem.specification).reduce((arr: string[], p) => {
        const origin = p.origin;
        // filter out candidate cpns
        if (origin && origin.column !== ColumnName.TechSpecs && typeof origin.candidate === 'string') {
            arr.push(origin.candidate);
        }
        return arr;
    }, []);

    return Array.from(new Set(mpnCandidatesFromPartSuggestions.concat(mpnCandidatesFromPartOptions)));
}

/**
 * @returns unique candidate MPNs from the part suggestions
 */
export function getCandidateMpnsSuggestions(suggestions: PartSuggestionFull[]): string[] {
    const candidateMpns = suggestions.reduce((arr: string[], p) => {
        const origin = p.origin;
        // filter out candidate cpns
        if (
            origin &&
            origin.column !== ColumnName.TechSpecs &&
            'candidate' in origin &&
            typeof origin.candidate === 'string'
        ) {
            arr.push(origin.candidate);
        }
        return arr;
    }, []);

    const candidatesBeforeAsterisk = candidateMpns
        .filter((query) => query.includes('*'))
        .map((query) => {
            return query.split('*')[0];
        });
    return candidateMpns.concat(candidatesBeforeAsterisk);
}
