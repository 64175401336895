import { getToken } from '@luminovo/auth';
import { http } from '@luminovo/http-client';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDebugErrorHandler } from '../http/debugErrorHandler';
import { httpQueryKey } from '../http/httpQueryKey';

export function useDeleteQuotation(rfqId: string) {
    const queryClient = useQueryClient();
    const onError = useDebugErrorHandler();
    return useMutation({
        mutationFn: (value: string) => {
            return http(
                'DELETE /rfqs/:rfqId/quotation',
                { pathParams: { rfqId }, queryParams: { blob_name: value } },
                getToken(),
            );
        },

        onSuccess: async () => {
            queryClient.invalidateQueries({ queryKey: httpQueryKey('GET /rfqs/:rfqId/quotation') });
        },
        onError,
    });
}
