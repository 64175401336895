import { CustomPartOfferInputDTO, CustomPartOfferUpdateDTO } from '@luminovo/http-client';
import { nullToUndefined, undefinedToNull } from '../../../utils/typingUtils';
import { CustomPartOfferFormValues } from './CustomPartOfferForm';

export function convertToCustomPartOfferUpdateDTO(values: CustomPartOfferFormValues): CustomPartOfferUpdateDTO {
    return {
        linked_part: values.linkedPart,
        supplier_and_stock_location: values.supplierAndStockLocation.id,
        unit_of_measurement: values.quantityUnit,
        currency: values.currency,
        price_breaks: values.priceBreaks,
        one_time_costs: values.oneTimeCosts,
        valid_until: undefinedToNull(values.validUntilDate),
        notes: undefinedToNull(values.notes),
        price_type: values.priceType,
        sourcing_batch_size: values.sourcingBatchSize,
        total_offered_quantity: nullToUndefined(values.totalOfferedQuantity),
    };
}

export function convertToCustomPartOfferInputDTO(values: CustomPartOfferFormValues): CustomPartOfferInputDTO {
    return {
        linked_part: values.linkedPart,
        supplier_and_stock_location: values.supplierAndStockLocation.id,
        unit_of_measurement: values.quantityUnit,
        currency: values.currency,
        one_time_costs: values.oneTimeCosts,
        valid_until: values.validUntilDate,
        notes: nullToUndefined(values.notes),
        price_type: values.priceType,
        offer_number: undefined,
        sourcing_scenario_id: undefined,
        sourcing_batch_size: values.sourcingBatchSize,
        price_breaks: values.priceBreaks,
        total_offered_quantity: nullToUndefined(values.totalOfferedQuantity),
    };
}
