import * as z from 'zod';

const TemplateReferenceManufacturingScenarioTemplate = z.object({
    type: z.literal('ManufacturingScenarioTemplate'),
    data: z.string(),
});

const TemplateReferenceAdditionService = z.object({
    type: z.literal('RfqAdditionalService'),
    data: z.string(),
});

export const TemplateReferenceRuntype = z
    .union([TemplateReferenceManufacturingScenarioTemplate, TemplateReferenceAdditionService])
    .nullable();

export type TemplateReferenceDTO = z.infer<typeof TemplateReferenceRuntype>;

export const ManufacturingScenarioTemplateInstanceRuntype = z.object({
    id: z.string(),
    manufacturing_scenario_id: z.string(),
    template_reference: TemplateReferenceRuntype,
    activity_configuration_id: z.string().nullable(),
    expense_configuration_id: z.string().nullable(),
    rank: z.number(),
    created_at: z.string(),
    updated_at: z.string(),
});

export type ManufacturingScenarioTemplateInstanceDTO = z.infer<typeof ManufacturingScenarioTemplateInstanceRuntype>;
