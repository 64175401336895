import { LayoutCard } from '@/components/LayoutCard';
import { PageLayoutCollapsibleSidebar } from '@/components/PageLayoutCollapsibleSidebar';
import { ButtonDeleteNegotiation } from '@/modules/Negotiations/components/ButtonDeleteNegotiation';
import { ButtonEditNegotiation } from '@/modules/Negotiations/components/ButtonEditNegotiation';
import { useNegotiations } from '@/modules/Negotiations/hooks/negotiationHandlers';
import { route, UrlParams } from '@/utils/routes';
import { t, Trans } from '@lingui/macro';
import { formatRelativeTime, formatToLongDate, isPresent } from '@luminovo/commons';
import {
    AvatarIcon,
    CenteredLayout,
    createColumnHelper,
    Flexbox,
    Message,
    NonIdealState,
    PrimaryButton,
    TanStackTable,
    Text,
    Tooltip,
    useNavigate,
    useTanStackTable,
} from '@luminovo/design-system';
import { NegotiationCategory, NegotiationDTO } from '@luminovo/http-client';
import { Add, ForwardToInboxRounded } from '@mui/icons-material';
import { Box } from '@mui/material';
import React from 'react';
import { NavigationSidebarSourcing } from '../../NavigationSidebarSourcing';
import { useCreateQuoteRoundDialog } from './useCreateQuoteRoundDialog';

type SharedContext = {
    rfqId: string;
};

const columnHelper = createColumnHelper<NegotiationDTO, SharedContext>();
const columns = [
    columnHelper.text('name', { size: 100, label: () => t`Name` }),
    columnHelper.date('created_at', {
        size: 100,
        label: () => t`Created at`,
        cell: (item) => (
            <Tooltip title={formatToLongDate(item.getValue())}>
                <Text variant={'inherit'}>{formatRelativeTime(item.getValue())}</Text>
            </Tooltip>
        ),
    }),
    columnHelper.enum('created_by', {
        size: 40,
        label: () => t`Created by`,
        getOptionLabel(option) {
            if (!isPresent(option)) {
                return t`Unknown`;
            }
            return `${option.first_name} ${option.last_name}`;
        },
        getOptionKey: (option) => option?.id ?? 'unknown',
        cell({ row }) {
            const user = row.original.created_by;
            if (!user) {
                return t`Unknown`;
            }

            return (
                <Tooltip title={`${user.first_name} ${user.last_name} ${user.email}`}>
                    <AvatarIcon
                        size="small"
                        user={{
                            firstName: user.first_name,
                            lastName: user.last_name,
                            email: user.email,
                        }}
                    />
                </Tooltip>
            );
        },
    }),

    columnHelper.action({
        id: 'actions',
        size: 100,
        align: 'right',
        cell: ({ row, sharedContext }) => (
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                <ButtonEditNegotiation negotiationId={row.original.id} />
                <ButtonDeleteNegotiation
                    href={route('/rfqs/:rfqId/sourcing/negotiations', { rfqId: sharedContext.rfqId })}
                    negotiationId={row.original.id}
                />
            </Box>
        ),
    }),
];

function useTableNegotiations({ rfqId }: { rfqId: string }) {
    const navigate = useNavigate();
    const { data: unfilteredData } = useNegotiations();
    const data = React.useMemo(
        () =>
            unfilteredData?.filter(
                (negotiation) =>
                    negotiation.category.kind === NegotiationCategory.Project && negotiation.category.rfq_id === rfqId,
            ),
        [unfilteredData, rfqId],
    );

    return useTanStackTable({
        data,
        columns,
        onRowClick(row) {
            navigate(
                route('/rfqs/:rfqId/sourcing/negotiations/:negotiationId/line-items', {
                    rfqId,
                    negotiationId: row.original.id,
                }),
            );
        },
        sharedContext: { rfqId },
    });
}

function ActionButtons({ sharedContext }: { sharedContext: SharedContext }) {
    const { rfqId } = sharedContext;
    const { openDialog } = useCreateQuoteRoundDialog({ rfqId });
    return (
        <Flexbox justifyContent="flex-end" alignItems="center" gap={4}>
            <PrimaryButton size="medium" onClick={openDialog}>{t`Start negotiation`}</PrimaryButton>
        </Flexbox>
    );
}

function EmptyPlaceholder({ sharedContext }: { sharedContext: SharedContext }) {
    const { openDialog } = useCreateQuoteRoundDialog({ rfqId: sharedContext.rfqId });
    return (
        <CenteredLayout>
            <NonIdealState
                Icon={ForwardToInboxRounded}
                title={t`Start your first negotiation`}
                description={t`Create a negotiation round to begin quoting suppliers`}
                action={{
                    onClick: () => openDialog(),
                    startIcon: <Add />,
                    children: t`Start negotiation`,
                }}
                style={{ maxWidth: '400px' }}
                overrides={{ ActionButton: PrimaryButton }}
            />
        </CenteredLayout>
    );
}

export default function SourcingNegotiationsPage({ pathParams }: UrlParams<'/rfqs/:rfqId/sourcing/negotiations'>) {
    const { rfqId } = pathParams;
    const { table } = useTableNegotiations({ rfqId });

    return (
        <PageLayoutCollapsibleSidebar
            sidebar={<NavigationSidebarSourcing rfqId={pathParams.rfqId} />}
            layoutVariant="fullWidth"
        >
            <LayoutCard title={t`Negotiations`} style={{ height: '100%' }}>
                <Message
                    size="large"
                    attention="high"
                    variant="blue"
                    title={`Beta phase`}
                    message={
                        <Text>
                            <Trans>
                                Negotiations are currently in beta and will be improved with further updates in the
                                coming weeks. Please note that some bugs may still exist in this version. To ensure a
                                smooth experience, the existing quote request feature remains available during this beta
                                phase. The following functionalities are not yet supported:
                                <ul>
                                    <li>PDF importer</li>
                                    <li>Custom parts support</li>
                                    <li>Automatic inclusion of changed parts from the BOM area</li>
                                </ul>
                            </Trans>
                        </Text>
                    }
                />
                <Box height={'calc(100% - 48px)'}>
                    <TanStackTable table={table} ActionButton={ActionButtons} EmptyPlaceholder={EmptyPlaceholder} />;
                </Box>
            </LayoutCard>
        </PageLayoutCollapsibleSidebar>
    );
}
