import * as z from 'zod';
import { CalculationResult, DriverStatusDetailsRuntype } from '../activityConfiguration';
import { DriverCountsRuntype } from '../activityConfiguration/activityConfigurationBackendTypes';
import {
    ExchangedMonetaryValueBackendOriginalMVOnlyRuntype,
    ExchangedMonetaryValueBackendRuntype,
} from '../backendTypes';
import { DriverRuntype } from '../driver';
import { CategoryRuntype, ProcessRuntype } from '../sharedManufacturingBackendTypes';
import { ExpenseLevelRuntype } from './expensesBackendTypes';

export interface ExpenseConfigurationDTO extends z.infer<typeof ExpenseConfigurationRuntype> {}

export const ExpenseConfigurationRuntype = z.object({
    id: z.string(),
    expense_id: z.string(),
});

export const ExpenseConfigurationsPostRuntype = z.object({
    manufacturing_scenario_id: z.string(),
    manufacturing_scenario_template_instance_id: z.string().nullable(),
    expenses: z.array(z.string()),
});

export interface ExpenseConfigurationsPostDTO extends z.infer<typeof ExpenseConfigurationsPostRuntype> {}

const CostComponentExpensesRuntype = z.object({
    driver_count: DriverCountsRuntype,
    automatic_driver_count: DriverCountsRuntype,
    driver_statuses: z.array(DriverStatusDetailsRuntype),
    costs_per_batch_size: z.record(
        z.string(),
        z.object({
            variable_cost: CalculationResult(ExchangedMonetaryValueBackendOriginalMVOnlyRuntype.nullable()),
            fixed_cost: CalculationResult(ExchangedMonetaryValueBackendOriginalMVOnlyRuntype.nullable()),
            total_cost: CalculationResult(ExchangedMonetaryValueBackendRuntype.nullable()),
        }),
    ),
});

export interface CostComponentExpenseConfiguration extends z.infer<typeof CostComponentExpensesRuntype> {}

export const ExpenseConfigurationSummaryRuntype = z.object({
    expense_configuration_details: z.object({
        expense_configuration_id: z.string(),
        expense_id: z.string(),
        expense_name: z.string(),
        level: ExpenseLevelRuntype,
        process: ProcessRuntype,
        expense_internal_number: z.string().nullable(),
        category: CategoryRuntype,
        cost_components: CostComponentExpensesRuntype,
        expense_description: z.string().nullable(),
        template_instance_id: z.string().nullable(),
        expense_configuration_notes: z.string().nullable(),
        rank: z.number(),
    }),
    used_drivers: z.array(DriverRuntype),
});

export interface ExpenseConfigurationSummaryDTO extends z.infer<typeof ExpenseConfigurationSummaryRuntype> {}
