import { useDialogContext } from '@/components/contexts/ModalContext';
import { ViewContext } from '@/modules/Bom/components/ModuleTableData';
import { formatRouteBasedOnViewContext } from '@/modules/Pcb/utils/formatRoutebasedOnViewContext';
import { useHttpMutation } from '@/resources/mutation/useHttpMutation';
import { route } from '@/utils/routes';
import { plural, t, Trans } from '@lingui/macro';
import {
    CenteredLayout,
    colorSystem,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Flexbox,
    Message,
    NonIdealState,
    SecondaryButton,
    TertiaryButton,
    useNavigate,
} from '@luminovo/design-system';
import { WarningRounded } from '@mui/icons-material';
import { usePcbAlerts } from '../utils/usePcbAlerts';

const AlertsDialog = ({
    onClose,
    rfqId,
    assemblyId,
    viewContext,
    pcbId,
}: {
    onClose: () => void;
    rfqId: string;
    assemblyId: string;
    viewContext: ViewContext;
    pcbId: string;
}) => {
    const navigate = useNavigate();
    const { data: alerts } = usePcbAlerts({ pcbId, assemblyId });

    const { mutate: dismissAlert, isPending: isDismissingAlert } = useHttpMutation(
        'POST /custom-part-alerts/dismiss/:alertId',
        {
            snackbarMessage: t`Alert dismissed`,
        },
    );

    const handleDismissAlert = async (alertId: string): Promise<void> => {
        if (isDismissingAlert) return;
        await dismissAlert({ pathParams: { alertId } });
    };

    const handleContactSupport = (): void => {
        if (window.Intercom !== undefined) {
            window.Intercom('showNewMessage', t`Hi! I have a question regarding the PCB alerts.`);
        }
    };

    if (!alerts) return null;

    return (
        <Dialog open={true} maxWidth="xs">
            <DialogTitle title={t`PCB Alerts`} />
            <DialogContent>
                <Flexbox flexDirection="column" gap={16}>
                    {alerts.length === 0 ? (
                        <CenteredLayout>
                            <NonIdealState title={t`No alerts`} description={''} />
                        </CenteredLayout>
                    ) : (
                        <>
                            {alerts.map((alert, index) => (
                                <Message
                                    key={index}
                                    onClose={() => handleDismissAlert(alert.alert.id)}
                                    title={alert.title}
                                    message={alert.message}
                                    variant={alert.severity === 'error' ? 'red' : 'yellow'}
                                    action={{
                                        label: alert.action.label,
                                        onClick: () => {
                                            navigate(
                                                route(formatRouteBasedOnViewContext(alert.action.route, viewContext), {
                                                    rfqId,
                                                    assemblyId,
                                                }),
                                            );
                                            onClose();
                                        },
                                    }}
                                    size="large"
                                    attention="high"
                                />
                            ))}
                        </>
                    )}
                </Flexbox>
            </DialogContent>
            <DialogActions>
                <TertiaryButton size="small" onClick={handleContactSupport} style={{ marginRight: 'auto' }}>
                    <Trans>Contact support</Trans>
                </TertiaryButton>
                <SecondaryButton size="small" onClick={onClose}>
                    <Trans>Close</Trans>
                </SecondaryButton>
            </DialogActions>
        </Dialog>
    );
};

const usePcbAlertsDialog = (props: { rfqId: string; assemblyId: string; viewContext: ViewContext; pcbId: string }) => {
    const { setDialog, closeDialog } = useDialogContext();

    return {
        openDialog: () => setDialog(<AlertsDialog {...props} onClose={closeDialog} />),
    };
};

export const PcbAlertCenter = ({
    pcbId,
    assemblyId,
    rfqId,
    viewContext,
}: {
    pcbId: string;
    assemblyId: string;
    rfqId: string;
    viewContext: ViewContext;
}) => {
    const { data } = usePcbAlerts({ pcbId, assemblyId });
    const alerts = data ?? [];

    const { openDialog } = usePcbAlertsDialog({ rfqId, assemblyId, viewContext, pcbId });

    return (
        <SecondaryButton
            onClick={openDialog}
            disabled={alerts.length < 1}
            startIcon={
                <WarningRounded
                    fontSize="small"
                    style={{ color: alerts.length < 1 ? colorSystem.neutral[5] : colorSystem.yellow[6] }}
                />
            }
            size="medium"
        >
            {alerts.length}{' '}
            {plural(alerts.length, {
                one: 'alert present',
                other: 'alerts present',
            })}
        </SecondaryButton>
    );
};
