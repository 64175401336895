import { PCBSilkscreenColors, PCBSoldermaskColors } from '@luminovo/http-client';
import { findNearestTopLeftRegion } from '../../framework/findNearestTopLeftRegion';
import { Region } from '../../framework/types';
import { PcbAttribute } from '../PcbAttribute';
import { isAllowedForSilkscreenColor } from '../parsers/parseColor';

export const combineColors = (regions: Region<PcbAttribute>[]): Region<PcbAttribute>[] => {
    const regionsWithAttributes = regions.flatMap((region) =>
        region.attributes.map((attribute) => ({
            region,
            attribute,
        })),
    );
    const regionsWithColor = regionsWithAttributes.filter(({ attribute }) => attribute.attr === 'color');
    const regionsWithSoldermaskOrSilkscreen = regionsWithAttributes.filter(
        ({ attribute }) => attribute.attr === 'soldermaskKeyword' || attribute.attr === 'silkscreenKeyword',
    );
    const maxDistanceToSilkscreenOrSoldermaskHeader = 0.1;
    regionsWithColor.forEach(({ region, attribute }) => {
        // find nearest region with soldermask or silkscreen
        const nearestSoldermaskOrSilkscreen = findNearestTopLeftRegion(
            region,
            regionsWithSoldermaskOrSilkscreen,
            maxDistanceToSilkscreenOrSoldermaskHeader,
        );
        if (nearestSoldermaskOrSilkscreen?.attribute.attr === 'soldermaskKeyword') {
            region.attributes.push({
                attr: 'soldermaskColor',
                value: attribute.value as PCBSoldermaskColors,
                // use 0.5 as a fallback value if confidence of linked attributes is not given as we are less sure
                // about these links than about direct matches
                // multiply confidence with 1 / (1 + distance) to penalize links that are further away
                confidence:
                    ((nearestSoldermaskOrSilkscreen.attribute.confidence ?? 0.5) * (attribute.confidence ?? 0.5)) /
                    (1 + nearestSoldermaskOrSilkscreen.distance),
            });
        }
        if (
            nearestSoldermaskOrSilkscreen?.attribute.attr === 'silkscreenKeyword' &&
            isAllowedForSilkscreenColor(attribute?.value as PCBSilkscreenColors)
        ) {
            region.attributes.push({
                attr: 'silkscreenColor',
                value: attribute.value as PCBSilkscreenColors,
                // use 0.5 as a fallback value if confidence of linked attributes is not given as we are less sure
                // about these links than about direct matches
                // multiply confidence with 1 / (1 + distance) to penalize links that are further away
                confidence:
                    ((nearestSoldermaskOrSilkscreen.attribute.confidence ?? 0.5) * (attribute.confidence ?? 0.5)) /
                    (1 + nearestSoldermaskOrSilkscreen.distance),
            });
        }
    });
    return regions;
};
