import * as z from 'zod';
import { ApiIntegrationStatusRuntype } from './3rdPartyBackendTypes';

export interface VenkelCredentialsDTO extends z.infer<typeof VenkelCredentialsDTORuntype> {}
export const VenkelCredentialsDTORuntype = z.object({
    api_key: z.string(),
});

export interface VenkelCredentialsInputDTO extends z.infer<typeof VenkelCredentialsInputDTORuntype> {}
export const VenkelCredentialsInputDTORuntype = z.object({
    api_key: z.string().optional(),
});

export interface VenkelResponseBodyDTO extends z.infer<typeof VenkelResponseBodyDTORuntype> {}
export const VenkelResponseBodyDTORuntype = z.object({
    data: VenkelCredentialsDTORuntype.nullable(),
    status: ApiIntegrationStatusRuntype,
});
