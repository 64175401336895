import * as z from 'zod';
import { endpoint } from '../http/endpoint';
import {
    RsComponentsCountryCodeDTORuntype,
    RsComponentsCredentialsInputDTORuntype,
    RsComponentsResponseBodyDTORuntype,
    RsComponentsStoreIdDTORuntype,
} from './rsComponentsBackendTypes';

export const rsComponentsEndpoints = {
    'GET /3rdparty/rscomponents/credentials': endpoint({
        description: 'Returns credentials for RS Components integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: RsComponentsResponseBodyDTORuntype,
    }),
    'POST /3rdparty/rscomponents/credentials': endpoint({
        description: 'Uploads credentials for RS Components API integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: RsComponentsCredentialsInputDTORuntype,
        responseBody: RsComponentsResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/rscomponents/credentials'],
    }),
    'PATCH /3rdparty/rscomponents/credentials': endpoint({
        description: 'Updates credentials for RS Components API integratoin',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: RsComponentsCredentialsInputDTORuntype,
        responseBody: RsComponentsResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/rscomponents/credentials'],
    }),
    'DELETE /3rdparty/rscomponents/credentials': endpoint({
        description: 'Deletes existing credentials for RS Components API integration',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({ deleted: z.number() }),
        invalidates: ['GET /3rdparty/rscomponents/credentials'],
    }),
    'GET /3rdparty/rscomponents/supported-store-id': endpoint({
        description: 'Returns possible store id for RsComponents',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({ stores: z.array(RsComponentsStoreIdDTORuntype) }),
    }),
    'GET /3rdparty/rscomponents/supported-country-code': endpoint({
        description: 'Returns possible country codes for RsComponents',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({ country_codes: z.array(RsComponentsCountryCodeDTORuntype) }),
    }),
};
