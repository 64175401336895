import { Messages } from '@lingui/core';
import { LanguageEnum } from '@luminovo/commons';
import { messages as de } from './locales/de/messages';
import { messages as en } from './locales/en/messages';
import { messages as es } from './locales/es/messages';
import { messages as fr } from './locales/fr/messages';
import { messages as it } from './locales/it/messages';

export * from './comparators';
export * from './components';
export * from './extractors';
export * from './formatters';
export * from './i18n';
export * from './inputControls';
export * from './regex';
export * from './selectors';
export * from './sorters';

export const locales = { de, en, es, fr, it } satisfies Record<LanguageEnum, Messages>;
