import * as z from 'zod';
import { endpoint } from '../http/endpoint';
import { PartSuggestionFullRuntype } from './bomItemBackendTypes';

export const bomItemEndpoints = {
    'GET /bom-items/:aggregationKey/suggestions': endpoint({
        description: 'Fetches a list of suggestions for a given bomItem',
        pathParams: z.object({ aggregationKey: z.string() }),
        queryParams: z.object({ assembly_id: z.string(), max_components: z.number().optional() }),
        requestBody: z.undefined(),
        responseBody: z.object({
            items: z.array(PartSuggestionFullRuntype),
        }),
    }),
};
