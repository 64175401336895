import * as z from 'zod';
import { endpoint } from '../http/endpoint';
import { ManufacturingEntityStatusRuntype } from '../sharedManufacturingBackendTypes';
import { ResourceDTORuntype, ResourcePostDTORuntype, ResourcesDTORuntype } from './resourceBackendTypes';

export const resourceEndpoints = {
    'GET /resources/:resourceId': endpoint({
        description: 'Returns a resource',
        pathParams: z.object({ resourceId: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: ResourceDTORuntype,
    }),

    'GET /resources': endpoint({
        description: 'Returns an array of all resources',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: ResourcesDTORuntype,
    }),

    'POST /resources': endpoint({
        description: 'Creates a new resource',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: ResourcePostDTORuntype,
        responseBody: ResourceDTORuntype,
    }),

    'PATCH /resources/:resourceId': endpoint({
        description: 'updates a resource',
        pathParams: z.object({ resourceId: z.string() }),
        queryParams: z.undefined(),
        requestBody: ResourcePostDTORuntype.or(z.object({ status: ManufacturingEntityStatusRuntype })),
        responseBody: ResourceDTORuntype,
    }),

    'DELETE /resources/:resourceId': endpoint({
        description: 'Deletes a resource',
        pathParams: z.object({ resourceId: z.string() }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({ deleted: z.number() }),
    }),
};
