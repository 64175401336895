import { t, Trans } from '@lingui/macro';
import { compareByStringKey, isPresent, uniqBy } from '@luminovo/commons';
import {
    ColumnBuilder,
    DataGrid,
    DataGridColumn,
    DataGridQuickFilters,
    DataGridReadonlyContextProvider,
} from '@luminovo/data-grid';
import { chainComparators, colorSystem, FieldSelect, SecondaryButton } from '@luminovo/design-system';
import { Validated } from '@luminovo/fields';
import { ComplianceStatus, OtsFullPart, Packaging } from '@luminovo/http-client';
import { formatComplianceStatus, formatPackaging, formatPart } from '@luminovo/sourcing-core';
import * as icons from '@mui/icons-material';
import { Box } from '@mui/material';
import { useAtomValue, useSetAtom } from 'jotai';
import { FormFieldConfiguration, FormFieldsConfiguration, PdfOfferLineItem } from '../types';
import { columnId } from './PdfOfferImporter/model';
import { FormState, RowAction } from './PdfOfferImporter/state';
import { formatLeadTimeUnit, LeadTimeUnit, Region, scrollToRegions, usePdfViewerState } from './PdfViewer';

function getCellStyle(validated?: Validated<unknown>) {
    if (!validated) {
        return { backgroundColor: 'transparent' };
    }
    if (validated.status === 'error') {
        return { backgroundColor: colorSystem.red[1] };
    }
    if (validated.status === 'success' && validated.message) {
        return { backgroundColor: colorSystem.yellow[1] };
    }
    return { backgroundColor: 'transparent' };
}

type DispatchRowAction = (action: RowAction) => void;

export function DataGridPdfOfferLine({
    rows,
    requestedParts,
    formState,
}: {
    rows: PdfOfferLineItem[];
    requestedParts: OtsFullPart[];
    formState: FormState;
}): JSX.Element {
    const [, pdfViewerDispatch] = usePdfViewerState();

    const columnPart = createColumnPart(requestedParts);
    const dispatch = useSetAtom(formState.fields.$rows);
    const formFieldsConfiguration = useAtomValue(formState.$formFieldsConfiguration);
    const defaultCurrency = useAtomValue(formState.fields.$defaultCurrency).value;

    const columnDefs = getVisibleColumns({
        formFieldsConfiguration,
        requestedParts,
    });

    return (
        <DataGridReadonlyContextProvider rowData={rows}>
            <style>
                {`
                    .readonly-header {
                        background-color: ${colorSystem.neutral[2]};
                    }
                `}
            </style>
            <Box sx={{ display: 'flex', padding: 1, gap: 1, alignItems: 'center' }}>
                <DataGridQuickFilters />

                <span style={{ flex: 1 }} />

                <SecondaryButton
                    startIcon={<icons.Add />}
                    size="medium"
                    onClick={() => {
                        dispatch({
                            type: 'addEmptyRowBelow',
                        });
                    }}
                >
                    <Trans>Add row</Trans>
                </SecondaryButton>
            </Box>
            <DataGrid
                context={{
                    dispatch,
                    defaultCurrency,
                }}
                onCellFocused={({ column, rowIndex, api }) => {
                    if (!column || typeof column === 'string' || rowIndex === null) {
                        return;
                    }

                    const gridRow = api.getDisplayedRowAtIndex(rowIndex);

                    const row = gridRow?.data;
                    const source = row?.source;
                    if (!row || !source) {
                        return;
                    }

                    function highlightRegion(regions: Region[]) {
                        scrollToRegions(regions);
                        pdfViewerDispatch({
                            type: 'setMode',
                            mode: {
                                type: 'inspect',
                                attribute: 'part',
                                highlightBox: source?.boundingBox,
                                pageNumber: source?.pageNumber ?? 1,
                                selectedRegionIds: regions.map((r) => r.id),
                            },
                        });
                    }

                    const { part, moq, mpq, unitPrice, standardFactoryLeadTime, stock, packaging } = source;
                    if (column.getColId() === columnPart.colId && part) {
                        highlightRegion(part.regions);
                    }
                    if (column.getColId() === columnMoq.colId && moq) {
                        highlightRegion(moq.regions);
                    }
                    if (column.getColId() === columnMpq.colId && mpq) {
                        highlightRegion(mpq.regions);
                    }
                    if (column.getColId() === columnUnitPrice.colId && unitPrice) {
                        highlightRegion(unitPrice.regions);
                    }
                    if (column.getColId() === columnLeadTime.colId && standardFactoryLeadTime) {
                        highlightRegion(standardFactoryLeadTime.regions);
                    }
                    if (column.getColId() === columnLeadTimeUnit.colId && standardFactoryLeadTime) {
                        highlightRegion(standardFactoryLeadTime.regions);
                    }
                    if (column.getColId() === columnStock.colId && stock) {
                        highlightRegion(stock.regions);
                    }
                    if (column.getColId() === columnPackaging.colId && packaging) {
                        highlightRegion(packaging.regions);
                    }
                }}
                columnDefs={columnDefs}
            />
        </DataGridReadonlyContextProvider>
    );
}

function getVisibleColumns({
    formFieldsConfiguration,
    requestedParts,
}: {
    formFieldsConfiguration: FormFieldsConfiguration;
    requestedParts: OtsFullPart[];
}) {
    const columnPart = createColumnPart(requestedParts);

    function toggleColumn(column: DataGridColumn<PdfOfferLineItem>, formFieldConfiguration: FormFieldConfiguration) {
        if (!formFieldConfiguration.visible) {
            return null;
        }
        return column;
    }

    const bidColumns = [
        toggleColumn(columnRequestedPart, formFieldsConfiguration.requestedPart),
        toggleColumn(columnPartDescription, formFieldsConfiguration.partDescription),
        toggleColumn(columnRequiredQuantity, formFieldsConfiguration.requiredQuantity),
        toggleColumn(columnPotentialQuantity, formFieldsConfiguration.potentialQuantity),
        toggleColumn(columnRecipients, formFieldsConfiguration.recipients),
        toggleColumn(columnTargetPrice, formFieldsConfiguration.targetPrice),
        toggleColumn(columnCustomerName, formFieldsConfiguration.customerName),
    ].filter(isPresent);

    const offerColumns = [
        toggleColumn(columnPart, formFieldsConfiguration.part),
        toggleColumn(columnUnitPrice, formFieldsConfiguration.unitPrice),
        toggleColumn(columnMoq, formFieldsConfiguration.moq),
        toggleColumn(columnMpq, formFieldsConfiguration.mpq),
        toggleColumn(columnStock, formFieldsConfiguration.stock),
        toggleColumn(columnPackaging, formFieldsConfiguration.packaging),
        toggleColumn(columnLeadTime, formFieldsConfiguration.standardFactoryLeadTime),

        toggleColumn(columnLeadTimeUnit, formFieldsConfiguration.standardFactoryLeadTimeUnit),
        toggleColumn(columnNotes, formFieldsConfiguration.notes),
        toggleColumn(columnCurrency, formFieldsConfiguration.currency),
        toggleColumn(columnNcnr, formFieldsConfiguration.ncnr),
        toggleColumn(columnReach, formFieldsConfiguration.reach),
        toggleColumn(columnRohs, formFieldsConfiguration.rohs),
        toggleColumn(columnSupplierPartNumber, formFieldsConfiguration.supplierPartNumber),
        columnStatus(),
        columnActions,
    ].filter(isPresent);

    if (bidColumns.length === 0) {
        return [columnIndex, ...offerColumns];
    }

    return [
        columnIndex,
        {
            headerName: 'Bid',
            headerClass: 'readonly-header',
            children: bidColumns,
        },
        {
            headerName: 'Offer',
            children: offerColumns,
        },
    ];
}

const columnBuilder = new ColumnBuilder<PdfOfferLineItem>();

const columnIndex = columnBuilder.rowIndex();

const columnStatus = () => {
    const statusReadyToBid = {
        color: 'green' as const,
        label: t`Ready to bid`,
    };
    const statusWarning = {
        color: 'yellow' as const,
        label: t`Warning`,
    };
    const statusError = {
        color: 'red' as const,
        label: t`Error`,
    };

    return columnBuilder.status({
        colId: 'status',
        quickFilters: [
            {
                label: () => t`Ready to bid`,
                value: statusReadyToBid,
            },
            {
                label: () => t`Warning`,
                value: statusWarning,
            },
            {
                label: () => t`Error`,
                value: statusError,
            },
        ],
        valueGetter: (params) => {
            const row = params.data?.row;
            if (!row) {
                return null;
            }
            if (row.status === 'error') {
                return statusError;
            }
            if (row.status === 'success' && row.message) {
                return statusWarning;
            }
            return statusReadyToBid;
        },
    });
};

const compareParts = chainComparators(
    compareByStringKey((part: OtsFullPart) => part.manufacturer.name),
    compareByStringKey((part: OtsFullPart) => part.mpn),
);

const createColumnPart = (partOptions: OtsFullPart[]): DataGridColumn<PdfOfferLineItem, OtsFullPart> => ({
    colId: columnId('part'),
    label: () => t`Part`,
    valueGetter: ({ data }) => {
        return data?.part?.value;
    },
    cellStyle: ({ data }) => {
        return getCellStyle(data?.part);
    },
    valueFormatter: ({ value }) => {
        if (!value) {
            return '-';
        }
        return formatPart(value);
    },
    cellEditor: ({
        value,
        onValueChange,
    }: {
        value: OtsFullPart | null | undefined;
        onValueChange: (value: OtsFullPart | null) => void;
    }) => {
        const uniqueParts = uniqBy(partOptions, (part) => part.id).sort(compareParts);
        return (
            <FieldSelect
                selectOnFocus
                autoFocus
                autoSelect
                disableClearable
                size="small"
                getOptionLabel={(option) => formatPart(option)}
                getOptionKey={(option) => option.id}
                options={uniqueParts}
                value={value ?? null}
                onChange={onValueChange}
            />
        );
    },
    valueSetter: (params) => {
        const dispatch: DispatchRowAction = params.context.dispatch;
        const part = params.newValue ?? undefined;
        dispatch({
            type: 'setAttribute',
            rowId: params.data.rowId ?? '',
            attribute: 'part',
            value: part,
        });
        return true;
    },
});

const readonlyCellStyle = {
    backgroundColor: colorSystem.neutral[1],
};

const columnPartDescription = columnBuilder.text({
    colId: columnId('partDescription'),
    headerClass: 'readonly-header',
    label: () => t`Part description`,
    width: 140,
    valueGetter: (params) => params.data?.part?.value?.description,
    editable: false,
    cellStyle: readonlyCellStyle,
});

const columnRequestedPart = columnBuilder.text({
    colId: columnId('requestedPart'),
    headerClass: 'readonly-header',
    label: () => t`Requested part`,
    valueGetter: (params) => null,
    editable: false,
    cellStyle: readonlyCellStyle,
});

const columnRequiredQuantity = columnBuilder.integer({
    colId: columnId('requiredQuantity'),
    headerClass: 'readonly-header',
    label: () => t`Required quantity`,
    valueGetter: (params) => null,
    editable: false,
    cellStyle: readonlyCellStyle,
});

const columnPotentialQuantity = columnBuilder.integer({
    colId: columnId('potentialQuantity'),
    headerClass: 'readonly-header',
    label: () => t`Potential quantity`,
    valueGetter: (params) => null,
    editable: false,
    cellStyle: readonlyCellStyle,
});

const columnRecipients = columnBuilder.text({
    colId: columnId('recipients'),
    headerClass: 'readonly-header',
    label: () => t`Recipients`,
    valueGetter: (params) => null,
    editable: false,
    cellStyle: readonlyCellStyle,
});

const columnTargetPrice = columnBuilder.monetaryValue({
    colId: columnId('targetPrice'),
    headerClass: 'readonly-header',
    label: () => t`Target price`,
    valueGetter: (params) => null,
    editable: false,
    cellStyle: readonlyCellStyle,
});

const columnCustomerName = columnBuilder.text({
    colId: columnId('customerName'),
    headerClass: 'readonly-header',
    label: () => t`Customer name`,
    valueGetter: (params) => null,
    editable: false,
    cellStyle: readonlyCellStyle,
});

const columnUnitPrice = columnBuilder.unitPriceDecimal({
    colId: columnId('unitPrice'),
    label: () => t`Unit price`,
    valueGetter: (params) => params.data?.unitPrice?.value,
    valueSetter: (params) => {
        const dispatch: DispatchRowAction = params.context.dispatch;
        dispatch({
            type: 'setAttribute',
            rowId: params.data.rowId,
            attribute: 'unitPrice',
            value: params.newValue ?? undefined,
        });
        return true;
    },
    cellStyle: ({ data }) => {
        return getCellStyle(data?.unitPrice);
    },
});

const columnMoq = columnBuilder.moq({
    colId: columnId('moq'),
    label: () => t`MOQ`,
    valueGetter: (params) => params.data?.moq?.value,
    valueSetter: (params) => {
        const moq = params.newValue ?? undefined;
        const dispatch: DispatchRowAction = params.context.dispatch;
        dispatch({
            type: 'setAttribute',
            rowId: params.data.rowId ?? '',
            attribute: 'moq',
            value: moq,
        });
        return true;
    },
    cellStyle: ({ data }) => {
        return getCellStyle(data?.moq);
    },
});

const columnMpq = columnBuilder.mpq({
    colId: columnId('mpq'),
    label: () => t`MPQ`,
    valueGetter: (params) => params.data?.mpq?.value,
    valueSetter: (params) => {
        const mpq = params.newValue ?? undefined;
        const dispatch: DispatchRowAction = params.context.dispatch;
        dispatch({
            type: 'setAttribute',
            rowId: params.data.rowId ?? '',
            attribute: 'mpq',
            value: mpq,
        });
        return true;
    },

    cellStyle: ({ data }) => {
        return getCellStyle(data?.mpq);
    },
});

const columnStock = columnBuilder.integer({
    colId: columnId('stock'),
    label: () => t`Stock`,
    valueGetter: (params) => params.data?.stock?.value,
    valueSetter: (params) => {
        const stock = params.newValue ?? undefined;
        const dispatch: DispatchRowAction = params.context.dispatch;
        dispatch({
            type: 'setAttribute',
            rowId: params.data.rowId ?? '',
            attribute: 'stock',
            value: stock,
        });
        return true;
    },
    cellStyle: ({ data }) => {
        return getCellStyle(data?.stock);
    },
});

const columnPackaging = columnBuilder.enum({
    colId: columnId('packaging'),
    label: () => t`Packaging`,
    options: Object.values(Packaging),
    valueFormatter: ({ value }) => {
        return formatPackaging(value);
    },
    valueGetter: (params) => params.data?.packaging?.value,
    valueSetter: (params) => {
        const dispatch: DispatchRowAction = params.context.dispatch;
        const packaging = params.newValue ?? undefined;
        dispatch({
            type: 'setAttribute',
            rowId: params.data.rowId ?? '',
            attribute: 'packaging',
            value: packaging,
        });
        return true;
    },
    cellStyle: ({ data }) => {
        return getCellStyle(data?.packaging);
    },
});

const columnLeadTime = columnBuilder.integer({
    colId: columnId('standardFactoryLeadTime'),
    label: () => t`Factory lead time`,
    valueGetter: (params) => params.data?.standardFactoryLeadTime?.value?.value,
    valueSetter: (params) => {
        const dispatch: DispatchRowAction = params.context.dispatch;
        const leadTime = params.newValue ?? undefined;
        dispatch({
            type: 'setAttribute',
            rowId: params.data.rowId ?? '',
            attribute: 'standardFactoryLeadTime',
            value: {
                unit: params.data?.standardFactoryLeadTime?.value?.unit ?? LeadTimeUnit.Days,
                value: leadTime ?? -1,
            },
        });
        return true;
    },
    cellStyle: ({ data }) => {
        return getCellStyle(data?.standardFactoryLeadTime);
    },
});

const columnLeadTimeUnit = columnBuilder.enum({
    colId: columnId('standardFactoryLeadTimeUnit'),
    label: () => t`Lead time unit`,
    valueFormatter: ({ value }) => {
        if (!value) {
            return '-';
        }
        return formatLeadTimeUnit(value);
    },
    options: [LeadTimeUnit.Days, LeadTimeUnit.Weeks, LeadTimeUnit.Months],
    valueGetter: (params) => params.data?.standardFactoryLeadTime?.value?.unit,
    valueSetter: (params) => {
        const dispatch: DispatchRowAction = params.context.dispatch;
        const leadTimeUnit = params.newValue ?? undefined;
        dispatch({
            type: 'setAttribute',
            rowId: params.data.rowId ?? '',
            attribute: 'standardFactoryLeadTime',
            value: {
                unit: leadTimeUnit ?? LeadTimeUnit.Days,
                value: params.data?.standardFactoryLeadTime?.value?.value ?? -1,
            },
        });
        return true;
    },
    cellStyle: ({ data }) => {
        return getCellStyle(data?.standardFactoryLeadTime);
    },
});

const columnNotes = columnBuilder.text({
    colId: columnId('notes'),
    label: () => t`Notes`,
    valueGetter: (params) => params.data?.notes?.value,
    valueSetter: (params) => {
        const dispatch: DispatchRowAction = params.context.dispatch;
        const notes = params.newValue ?? undefined;

        dispatch({
            type: 'setAttribute',
            rowId: params.data.rowId ?? '',
            attribute: 'notes',
            value: notes,
        });
        return true;
    },
    cellStyle: ({ data }) => {
        return getCellStyle(data?.notes);
    },
});

const columnCurrency = columnBuilder.currency({
    colId: columnId('currency'),
    label: () => t`Currency`,
    valueFormatter: ({ value, context }) => {
        const defaultCurrency = context?.defaultCurrency;
        if (!value && defaultCurrency) {
            return defaultCurrency;
        }
        return value;
    },
    valueGetter: (params) => params.data?.currency?.value,
    valueSetter: (params) => {
        const dispatch: DispatchRowAction = params.context.dispatch;
        const currency = params.newValue ?? undefined;
        dispatch({
            type: 'setAttribute',
            rowId: params.data.rowId ?? '',
            attribute: 'currency',
            value: currency,
        });
        return true;
    },
    cellStyle: ({ data }) => {
        if (!data?.currency.value) {
            return { color: colorSystem.neutral[5] };
        }
        return getCellStyle(data?.currency);
    },
});

const columnNcnr = columnBuilder.boolean({
    colId: columnId('ncnr'),
    label: () => t`NCNR`,
    valueGetter: (params) => params.data?.ncnr?.value,
    valueSetter: (params) => {
        const dispatch: DispatchRowAction = params.context.dispatch;
        const ncnr = params.newValue;
        if (!isPresent(ncnr)) {
            return false;
        }
        dispatch({
            type: 'setAttribute',
            rowId: params.data.rowId ?? '',
            attribute: 'ncnr',
            value: ncnr,
        });
        return true;
    },
    cellStyle: ({ data }) => {
        return getCellStyle(data?.ncnr);
    },
});

const columnReach = columnBuilder.enum({
    colId: columnId('reach'),
    label: () => t`REACH`,
    valueFormatter: ({ value }) => {
        return formatComplianceStatus(value);
    },
    options: Object.values(ComplianceStatus),
    valueGetter: (params) => params.data?.reach?.value,
    valueSetter: (params) => {
        const dispatch: DispatchRowAction = params.context.dispatch;
        const reach = params.newValue;
        dispatch({
            type: 'setAttribute',
            rowId: params.data.rowId ?? '',
            attribute: 'reach',
            value: reach ?? ComplianceStatus.Unknown,
        });
        return true;
    },
    cellStyle: ({ data }) => {
        return getCellStyle(data?.reach);
    },
});

const columnSupplierPartNumber = columnBuilder.text({
    colId: columnId('supplierPartNumber'),
    label: () => t`Supplier part number`,
    valueGetter: (params) => params.data?.supplierPartNumber?.value,
    valueSetter: (params) => {
        const dispatch: DispatchRowAction = params.context.dispatch;
        const supplierPartNumber = params.newValue ?? undefined;
        dispatch({
            type: 'setAttribute',
            rowId: params.data.rowId ?? '',
            attribute: 'supplierPartNumber',
            value: supplierPartNumber,
        });
        return true;
    },
    cellStyle: ({ data }) => {
        return getCellStyle(data?.supplierPartNumber);
    },
});

const columnRohs = columnBuilder.enum({
    colId: columnId('rohs'),
    label: () => t`RoHS`,
    valueFormatter: ({ value }) => {
        return formatComplianceStatus(value);
    },
    options: Object.values(ComplianceStatus),
    valueGetter: (params) => params.data?.rohs?.value,
    valueSetter: (params) => {
        const dispatch: DispatchRowAction = params.context.dispatch;
        const rohs = params.newValue;
        dispatch({
            type: 'setAttribute',
            rowId: params.data.rowId ?? '',
            attribute: 'rohs',
            value: rohs ?? ComplianceStatus.Unknown,
        });
        return true;
    },
    cellStyle: ({ data }) => {
        return getCellStyle(data?.rohs);
    },
});

const columnActions = columnBuilder.actions({
    label: () => ``,
    valueGetter: (params) => {
        const dispatch: DispatchRowAction = params.context.dispatch;
        const row = params.data;
        if (!row) {
            return [];
        }
        return [
            {
                label: t`Delete`,
                onClick: () => {
                    dispatch({
                        type: 'deleteRow',
                        rowId: row.rowId,
                    });
                },
            },
            {
                label: t`Duplicate`,
                onClick: () => {
                    dispatch({
                        type: 'duplicateRow',
                        rowId: row.rowId,
                    });
                },
            },
            {
                label: t`Add empty row below`,
                onClick: () => {
                    dispatch({
                        type: 'addEmptyRowBelow',
                        rowId: row.rowId,
                    });
                },
            },
        ];
    },
});
