import { useHttpMutation } from '@/resources/mutation/useHttpMutation';
import { t } from '@lingui/macro';
import { colorSystem, Flexbox, MenuButton, Text } from '@luminovo/design-system';
import { SolutionConfigurationDTO, SolutionStatus } from '@luminovo/http-client';
import {
    extractSolutionStatus,
    formatSolutionStatus,
    Solution,
    SolutionStatusCircleIcon,
} from '@luminovo/sourcing-core';
import { ArrowDropDownRounded } from '@mui/icons-material';
import { MenuItem } from '@mui/material';
import React from 'react';

const ManualSolutionStatusSelector: React.FunctionComponent<{
    solution: Solution;
    solutionConfiguration: SolutionConfigurationDTO;
}> = ({ solution, solutionConfiguration }): JSX.Element => {
    const effectiveStatus = extractSolutionStatus(solution, 'effective');

    const { mutateAsync, isPending } = useHttpMutation('PATCH /solution-configurations/:solutionConfigurationId', {
        snackbarMessage: t`Solution status updated`,
    });

    return (
        <MenuButton
            size="small"
            label={<ArrowDropDownRounded />}
            appearance="secondary"
            disabled={isPending}
            menuProps={{
                anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
                transformOrigin: { horizontal: 'right', vertical: -4 },
            }}
        >
            {[SolutionStatus.Good, SolutionStatus.Warning, SolutionStatus.Error].map((manualSolutionStatus) => (
                <MenuItem
                    key={manualSolutionStatus}
                    onClick={() =>
                        mutateAsync({
                            pathParams: { solutionConfigurationId: solutionConfiguration.id },
                            requestBody: { manual_solution_status: manualSolutionStatus },
                        })
                    }
                    disabled={effectiveStatus === manualSolutionStatus}
                >
                    <Flexbox alignItems="center" gap={4}>
                        <SolutionStatusCircleIcon status={manualSolutionStatus} />
                        <Text variant="body">{formatSolutionStatus(manualSolutionStatus)}</Text>
                    </Flexbox>
                </MenuItem>
            ))}
        </MenuButton>
    );
};

export const ManualSolutionStatusBox: React.FunctionComponent<{
    solution: Solution;
    solutionConfiguration: SolutionConfigurationDTO;
}> = ({ solutionConfiguration, solution }): JSX.Element => {
    const effectiveStatus = extractSolutionStatus(solution, 'effective');
    const originalStatus = extractSolutionStatus(solution, 'original');

    return (
        <Flexbox
            paddingY={'8px'}
            paddingX={'12px'}
            borderRadius={'8px'}
            border={`1px solid ${colorSystem.neutral[2]}`}
            bgcolor={colorSystem.neutral[0]}
            justifyContent="space-between"
            alignItems="center"
        >
            <Flexbox gap={4} alignItems="baseline">
                <SolutionStatusCircleIcon status={effectiveStatus} />
                <Flexbox flexDirection="column">
                    <Text variant={'body'}>{formatSolutionStatus(effectiveStatus)}</Text>
                    {effectiveStatus !== originalStatus && (
                        <Text variant={'caption'} color={colorSystem.neutral[7]}>
                            {t`Manually changed from ${formatSolutionStatus(originalStatus)}`}
                        </Text>
                    )}
                </Flexbox>
            </Flexbox>
            <ManualSolutionStatusSelector solution={solution} solutionConfiguration={solutionConfiguration} />
        </Flexbox>
    );
};
