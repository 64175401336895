import { ImportBatchHandler, ImportHandlersCreator, ImportRecord, ImporterConfig } from '../types';

export const createIdentityImportHandlers =
    <TConfig extends ImporterConfig>(
        onImportBatch: ImportBatchHandler<TConfig>,
    ): ImportHandlersCreator<TConfig, ImportRecord<TConfig>> =>
    (globalFields) => ({
        postprocessRows: (rows) => rows.map(({ row, tableRecord }) => ({ rows: [row], handlerRecord: tableRecord })),
        handleBatch: (handlerRecords) => onImportBatch(handlerRecords, globalFields),
    });
