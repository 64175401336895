import { ApprovalStatus } from '../../../resources/backendTypes';
import { ExtractRequestBody } from '../../../resources/http';
import { OriginSetMethod, PartSpecificationTypes, StandardPartTypes } from '../../../resources/part';

interface CreateBomItemMockParams {
    assemblyId: string;
    quantity: number;
    partOptionId: string;
}

export const createBomItemMock = ({
    assemblyId,
    quantity,
    partOptionId,
}: CreateBomItemMockParams): ExtractRequestBody<'POST /design-items/many'> => {
    return {
        inputs: [
            {
                designator: 'A1',
                assembly: assemblyId,
                quantity: { quantity, unit: 'Pieces' },
                notes: '',
                do_not_place: false,
                part_specification: {
                    type: PartSpecificationTypes.OffTheShelf,
                    data: {
                        is_manufacturer_free: false,
                        part_options: [
                            {
                                part: {
                                    type: StandardPartTypes.OffTheShelf,
                                    data: partOptionId,
                                },
                                approval_status: ApprovalStatus.Approved,
                                origin: {
                                    set_method: OriginSetMethod.Manual,
                                    column: null,
                                    candidate: null,
                                    reasons: [],
                                },
                            },
                        ],
                    },
                },
                origin: null,
                is_consigned: false,
                aggregation_key: null,
                ignore_package_name_mismatch: false,
            },
        ],
    };
};
