import { t, Trans } from '@lingui/macro';
import { getIntercomArticleURL, isPresent } from '@luminovo/commons';
import {
    CenteredLayout,
    Flexbox,
    Link,
    Message,
    NonIdealState,
    SecondaryButton,
    TanStackTable,
    Text,
    Tooltip,
    useBackNavigation,
    useNavigate,
} from '@luminovo/design-system';
import {
    isCustomComponentFull,
    isCustomFullPart,
    isOtsFullPart,
    isPcbCustomFullPart,
    SolutionTag,
    SourcingScenarioDTO,
} from '@luminovo/http-client';
import { hasSolutionTag, visibleSolutionPreferences } from '@luminovo/sourcing-core';
import { ArrowBack, DangerousOutlined } from '@mui/icons-material';
import { Box, CircularProgress } from '@mui/material';
import React from 'react';
import { AlertLoadingRfqOffers } from '../../../components/Alerts';
import { resolvePartOptions, usePartOptionsFromSolutionConfigurationId } from '../../../resources/part/partHandler';
import { useMutationUpdatePcbOffers } from '../../../resources/pcb/pcbHandlers';
import { useSolutionConfiguration } from '../../../resources/solutionConfiguration/solutionConfigurationHandler';
import { route } from '../../../utils/routes';
import { useIsSolutionConfigurationPcbOnlyType } from '../../Sourcing/utils/useIsSolutionConfigurationPcbOnlyType';
import { useIsSourcingScenarioPcbOnlyType } from '../../Sourcing/utils/useIsSourcingScenarioPcbOnlyType';
import { SelectedSolutionSidebar } from './Sidebar/SelectedSolutionSidebar';
import { useSolutionsTableState } from './SolutionsTable';
import { ButtonAddManualOffer } from './Toolbar/ButtonAddManualOffer';
import { ButtonGoToBom } from './Toolbar/ButtonGoToBom';
import { SolutionTableData, SolutionTableSharedContext } from './types';

function BackButton({
    rfqId,
    solutionConfigurationId,
    sourcingScenarioId,
}: {
    rfqId: string;
    solutionConfigurationId: string;
    sourcingScenarioId: string | undefined;
}) {
    const { data: isPcbOnlyType } = useIsSourcingScenarioPcbOnlyType(sourcingScenarioId);
    const { data: solutionConfiguration } = useSolutionConfiguration(solutionConfigurationId);
    const navigate = useNavigate();
    const { navigateBack } = useBackNavigation();

    return (
        <SecondaryButton
            disabled={!isPresent(solutionConfiguration)}
            size="medium"
            startIcon={<ArrowBack />}
            onClick={() => {
                if (isPcbOnlyType) {
                    navigate(
                        route(`/rfqs/:rfqId/sourcing`, {
                            rfqId,
                        }),
                    );
                }
                navigateBack({
                    defaultPath: route('/rfqs/:rfqId/sourcing/scenarios/:sourcingScenarioId', {
                        rfqId,
                        sourcingScenarioId: solutionConfiguration?.sourcing_scenario ?? '',
                    }),
                });
            }}
            style={{ width: 'fit-content' }}
        >
            <Trans>Back</Trans>
        </SecondaryButton>
    );
}

export const SolutionsTableContainer = React.memo(function SolutionsTableContainer({
    rfqId,
    solutionConfigurationId,
    sourcingScenarioDTO,
}: {
    rfqId: string;
    solutionConfigurationId: string;
    sourcingScenarioDTO?: SourcingScenarioDTO;
}): JSX.Element | null {
    const { data: approvedPartOptions } = usePartOptionsFromSolutionConfigurationId({
        rfqId,
        solutionConfigurationId,
    });
    const { data: solutionConfiguration } = useSolutionConfiguration(solutionConfigurationId);

    const { table, items } = useSolutionsTableState({
        solutionConfigurationId,
        rfqId,
        columnsKey:
            isPresent(approvedPartOptions) &&
            (isCustomFullPart(approvedPartOptions[0]) || isCustomComponentFull(approvedPartOptions[0]))
                ? isPcbCustomFullPart(approvedPartOptions[0])
                    ? 'pcb-custom-part-solution-manager'
                    : 'custom-part-solution-manager'
                : 'standard-part-solution-manager',
    });

    if (!items || !approvedPartOptions || !solutionConfiguration) {
        return (
            <CenteredLayout style={{ height: '80vh' }}>
                <CircularProgress />
            </CenteredLayout>
        );
    }

    return (
        <Box display={'grid'} gridTemplateColumns="400px 1fr" position={'relative'} columnGap={'20px'}>
            <Flexbox flexDirection={'column'} gap={26}>
                <Flexbox gap={8} alignItems={'center'}>
                    <BackButton
                        rfqId={rfqId}
                        solutionConfigurationId={solutionConfigurationId}
                        sourcingScenarioId={sourcingScenarioDTO?.id}
                    />
                    {isPresent(sourcingScenarioDTO) && (
                        <Flexbox flexDirection={'column'}>
                            <Tooltip
                                variant="white"
                                title={visibleSolutionPreferences(sourcingScenarioDTO.solution_preference).join(' • ')}
                            >
                                <Text variant="h4">{sourcingScenarioDTO.name}</Text>
                            </Tooltip>
                        </Flexbox>
                    )}
                </Flexbox>

                <SelectedSolutionSidebar
                    solutionConfiguration={solutionConfiguration}
                    rfqId={rfqId}
                    selected={items.find((x) => x.isSelected) ?? null}
                />
            </Flexbox>

            <Flexbox flexDirection={'column'} gap={8} flexGrow={1} marginBottom={'60px'} minHeight={'600px'}>
                <InvalidSpecificationAlert items={items} />
                <WrappedAlertLoadingRfqOffers rfqId={rfqId} solutionConfigurationId={solutionConfigurationId} />
                <TanStackTable table={table} size={'medium'} EmptyPlaceholder={EmptyPlaceholder} />
            </Flexbox>
        </Box>
    );
});

function EmptyPlaceholder({
    sharedContext: { rfqId, solutionConfigurationId },
}: {
    sharedContext: SolutionTableSharedContext;
}) {
    const { data: isSolutionPcbOnly } = useIsSolutionConfigurationPcbOnlyType(solutionConfigurationId);

    const ActionButton = React.useCallback(() => {
        return (
            <Flexbox alignItems={'center'} gap={'8px'}>
                <ButtonGoToBom rfqId={rfqId} solutionConfigurationId={solutionConfigurationId} />
                <ButtonAddManualOffer rfqId={rfqId} solutionConfigurationId={solutionConfigurationId} />
            </Flexbox>
        );
    }, [rfqId, solutionConfigurationId]);

    if (isSolutionPcbOnly) {
        return (
            <NonIdealState
                title={t`No solutions available`}
                description={t`We couldn't find any offers for this part.`}
            />
        );
    }

    return (
        <CenteredLayout>
            <NonIdealState
                Icon={DangerousOutlined}
                title={t`No solutions available`}
                description={t`We couldn't find any offers for this part. To add one, click the 'Add Offer' button below or go to the BOM and add a part alternative.`}
                overrides={{ ActionButton }}
                style={{ maxWidth: '400px' }}
            />
        </CenteredLayout>
    );
}

function InvalidSpecificationAlert({ items }: { items: SolutionTableData[] | undefined }) {
    const { mutateAsync, isPending: isLoading } = useMutationUpdatePcbOffers();

    const itemWithInvalidSpecification = items?.find((item) => {
        return hasSolutionTag(item.solution, SolutionTag.InvalidSpecification);
    });

    if (!isPresent(itemWithInvalidSpecification)) {
        return null;
    }

    if (itemWithInvalidSpecification.partType !== 'CustomPart') {
        return null;
    }

    if (itemWithInvalidSpecification.linkedPart.type.name !== 'PCB') {
        return null;
    }

    if (!isPresent(itemWithInvalidSpecification.linkedPart.type.content)) {
        return null;
    }

    const pcbId = itemWithInvalidSpecification.linkedPart.type.content;

    const getLink = (): string => {
        return getIntercomArticleURL(85676);
    };

    return (
        <Message
            size="small"
            variant="red"
            attention="high"
            message={
                <Trans>
                    Offers for this PCB are based on an outdated specification. Click on "Update offers" to load new
                    offers.{' '}
                    <Link href={getLink()} target="_blank" attention="high">
                        Learn more
                    </Link>
                </Trans>
            }
            action={{
                label: isLoading ? t`Updating offers...` : t`Update offers`,
                onClick: () => mutateAsync(pcbId),
                disabled: isLoading,
            }}
        />
    );
}

function WrappedAlertLoadingRfqOffers({
    rfqId,
    solutionConfigurationId,
}: {
    rfqId: string;
    solutionConfigurationId: string;
}) {
    const { data: fullParts = [] } = usePartOptionsFromSolutionConfigurationId({
        rfqId,
        solutionConfigurationId,
    });

    const partIds = React.useMemo(
        () =>
            fullParts
                .flatMap((p) => resolvePartOptions(p))
                .filter((p) => isOtsFullPart(p) || isCustomFullPart(p))
                .map((p) => p.id),
        [fullParts],
    );

    return <AlertLoadingRfqOffers rfqId={rfqId} partIds={partIds} />;
}
