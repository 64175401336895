import * as z from 'zod';

export interface StockConditionDTO extends z.infer<typeof StockConditionDTORuntype> {}
const StockConditionDTORuntype = z.object({
    condition: z.literal('Gte'),
    content: z.number(),
});

export interface AlertSupplierPreferenceDTO extends z.infer<typeof AlertSupplierPreferenceDTORuntype> {}
const AlertSupplierPreferenceDTORuntype = z.object({
    use_preferred: z.boolean(),
    use_approved: z.boolean(),
    use_not_approved: z.boolean(),
    additional_suppliers: z.array(z.string()),
});

const AlertStatusDTORuntype = z.object({
    status: z.enum(['Active', 'Triggered']),
});

export interface AlertDTO extends z.infer<typeof AlertDTORuntype> {}
export const AlertDTORuntype = z.object({
    id: z.string(),
    part: z.string(),
    owner: z.string().nullable(),
    status: AlertStatusDTORuntype,
    condition: StockConditionDTORuntype,
    supplier_preference: AlertSupplierPreferenceDTORuntype,
    notes: z.string(),
    creation_date: z.string(),
});

export interface CreateAlertRequestBody extends z.infer<typeof CreateAlertRequestBodyRuntype> {}
export const CreateAlertRequestBodyRuntype = z.object({
    part: z.string(),
    condition: StockConditionDTORuntype,
    supplier_preference: AlertSupplierPreferenceDTORuntype,
    notes: z.string(),
});

export interface UpdateAlertRequestBody extends z.infer<typeof UpdateAlertRequestBodyRuntype> {}
export const UpdateAlertRequestBodyRuntype = z.object({
    part: z.string().optional(),
    condition: StockConditionDTORuntype.optional(),
    supplier_preference: AlertSupplierPreferenceDTORuntype.optional(),
    notes: z.string().optional(),
});
