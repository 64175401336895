import * as z from 'zod';
import { endpoint } from '../http/endpoint';
import { FileTypeUpdateRequestRuntype } from './pcbBackendTypes';

export const pcbFilesEndpoints = {
    'PUT /pcb/:pcbId/files': endpoint({
        description: 'Update pcb file types',
        pathParams: z.object({ pcbId: z.string() }),
        requestBody: FileTypeUpdateRequestRuntype,
        queryParams: z.undefined(),
        responseBody: z.literal('OK'),
        invalidates: ['GET /ems/pcb/v2/pcbs/:pcbId', 'GET /custom-part-alerts/:partId'],
    }),

    'DELETE /pcb/:pcbId/files': endpoint({
        description: 'Delete pcb file',
        pathParams: z.object({ pcbId: z.string() }),
        requestBody: z.object({
            files: z.array(z.string()),
        }),
        queryParams: z.undefined(),
        responseBody: z.literal('OK'),
        invalidates: ['GET /ems/pcb/v2/pcbs/:pcbId'],
    }),

    'GET /ems/renderer/assemblies/:assemblyId/versions/:pcbId/files/:fileName': endpoint({
        description: 'Get pcb file svg',
        pathParams: z.object({
            assemblyId: z.string(),
            pcbId: z.string(),
            fileName: z.string(),
        }),
        requestBody: z.undefined(),
        queryParams: z.object({
            format: z.literal('svg'),
        }),
        responseBody: z.string().nullable(),
        handleResponse: async (response, _) => {
            if (response.ok) return response.text();
            if (response.status === 404) return null;
            throw Error(`Unexpected response status ${response.status}`);
        },
    }),
};
