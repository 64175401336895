import { useNonExcludedSupplierAndStockLocations } from '@/resources/supplierAndStockLocation/supplierAndStockLocationHandler';
import { plural, t } from '@lingui/macro';
import { assertPresent, compareByString, formatDecimal } from '@luminovo/commons';
import {
    DEFAULT_EXPAND_COLUMN_ID,
    Flexbox,
    ReleaseTag,
    TanStackTable,
    Text,
    colorSystem,
    createColumnHelper,
    useNavigate,
    useTanStackTable,
} from '@luminovo/design-system';
import { ManufacturerWithPartCountDTO, SupplierPreference } from '@luminovo/http-client';
import { SupplierChip, formatSupplierDTO } from '@luminovo/sourcing-core';
import { Box } from '@mui/material';
import React from 'react';
import { LayoutCard } from '../../components/LayoutCard';
import { PageLayout } from '../../components/PageLayout';
import { useHttpQuery } from '../../resources/http/useHttpQuery';
import { useManufacturers } from '../../resources/manufacturer/manufacturerHandler';
import { route } from '../../utils/routes';
import { ManufacturerLineCardTableItem, convertToManufacturerLineCardTableItems } from './ManufacturerLineCardTab';

type ManufacturerItem = {
    type: 'row';
    manufacturer: ManufacturerWithPartCountDTO;
    lineCardItems: ManufacturerLineCardTableItem[];
};

const columnHelper = createColumnHelper<ManufacturerItem | ManufacturerLineCardTableItem>();

const columns = [
    columnHelper.text((row) => row.manufacturer.name, {
        id: 'ManufacturerName',
        size: 140,
        label: () => t`Manufacturer name`,
        cell: (item) => {
            if (item.row.original.type === 'row') {
                return item.getValue();
            }
            return <></>;
        },
    }),

    columnHelper.text(
        (row) =>
            row.type === 'subRow'
                ? formatSupplierDTO(row.supplierAndStockLocation.supplier)
                : row.lineCardItems.map((item) => formatSupplierDTO(item.supplierAndStockLocation.supplier)).join(', '),
        {
            id: 'supplierAndStockLocation',
            size: 160,
            description: () => t`Suppliers that have the manufacturer in their line card.`,
            label: () => t`Line card suppliers`,
            cell: ({ row }) => {
                if (row.original.type === 'row') {
                    const numberOfSuppliers = row.getLeafRows().length;
                    return (
                        <Text variant="inherit" color={colorSystem.neutral[6]}>
                            {plural(numberOfSuppliers, {
                                one: `${formatDecimal(numberOfSuppliers)} supplier supports this manufacturer`,
                                other: `${formatDecimal(numberOfSuppliers)} suppliers support this manufacturer`,
                            })}
                        </Text>
                    );
                }

                row.getLeafRows();
                return (
                    <SupplierChip
                        isPreferred={row.original.supplierAndStockLocation.preference === SupplierPreference.Preferred}
                        isApproved={row.original.supplierAndStockLocation.preference === SupplierPreference.Approved}
                        supplier={row.original.supplierAndStockLocation.supplier}
                    />
                );
            },
        },
    ),

    columnHelper.number((row) => row.manufacturer.part_count, {
        id: 'partCount',
        size: 100,
        label: () => t`# Parts`,
        cell: (item) => formatDecimal(item.getValue()),
    }),

    columnHelper.text((row) => row.manufacturer.alternative_names.join(', '), {
        id: 'alternativeNames',
        size: 240,
        label: () => t`Alternative names`,
        cell: (item) => {
            if (item.row.original.type === 'row') {
                return (
                    <Text variant="inherit" color={colorSystem.neutral[6]}>
                        {item.getValue()}
                    </Text>
                );
            }
            return <></>;
        },
    }),
];

function ManufacturerOverviewTable() {
    const navigate = useNavigate();
    const { data: manufacturers } = useManufacturers();
    const { data: manufacturerPreferences } = useHttpQuery(
        'GET /organization-settings/manufacturer-preferences',
        {},
        { select: (res) => res.items },
    );
    const { data: supplierAndStockLocations } = useNonExcludedSupplierAndStockLocations();

    const { data: manufacturerLineCards } = useHttpQuery(
        'GET /manufacturers/line-card',
        {},
        { select: (res) => res.data },
    );

    const data: Array<ManufacturerItem | ManufacturerLineCardTableItem> | undefined = React.useMemo(() => {
        const lineCardMap = new Map<string, ManufacturerLineCardTableItem[]>();

        if (!manufacturerPreferences || !manufacturerLineCards) {
            return undefined;
        }

        manufacturerLineCards.forEach((lineCard) => {
            if (!lineCardMap.has(lineCard.manufacturer_id)) {
                lineCardMap.set(lineCard.manufacturer_id, []);
            }
            assertPresent(lineCardMap.get(lineCard.manufacturer_id)).push(
                ...convertToManufacturerLineCardTableItems(manufacturers, lineCard, supplierAndStockLocations),
            );
        });

        return manufacturers
            .map((manufacturer) => ({
                type: 'row' as const,
                manufacturer,
                lineCardItems: lineCardMap.get(manufacturer.id) ?? [],
            }))
            .sort((a, b) => compareByString(a.manufacturer.name, b.manufacturer.name));
    }, [manufacturers, manufacturerPreferences, manufacturerLineCards, supplierAndStockLocations]);

    const { table } = useTanStackTable({
        columns,
        data,
        enableColumnOrdering: true,
        enableColumnHiding: true,
        enablePersistentColumnFilters: true,
        enablePersistentGlobalFilter: true,
        enableExcelExport: true,
        initialState: {
            columnPinning: {
                left: [DEFAULT_EXPAND_COLUMN_ID],
            },
        },
        getSubRows: (row) => (row.type === 'row' ? row.lineCardItems : []),
        onRowClick: (item) => {
            if (item.original.type === 'row') {
                navigate(route('/manufacturer/:manufacturerId', { manufacturerId: item.original.manufacturer.id }));
            }
            if (item.original.type === 'subRow') {
                navigate(
                    route(
                        '/suppliers/:supplierAndStockLocationId',
                        {
                            supplierAndStockLocationId: item.original.supplierAndStockLocation.id,
                        },
                        { tab: 'lineCard' },
                    ),
                );
            }
        },
    });

    return <TanStackTable table={table} size={'medium'} />;
}

export function ManufacturerManagementPage() {
    return (
        <PageLayout style={{ background: colorSystem.neutral[1] }} layout={'fragment'}>
            <LayoutCard
                title={
                    <Flexbox gap={8} alignItems={'center'}>
                        <Text variant={'h2'}>{t`Manufacturers`}</Text>
                        <ReleaseTag color="primary" label={t`BETA`} />
                    </Flexbox>
                }
                style={{ marginTop: '24px', marginLeft: '32px', marginRight: '32px', height: '100%' }}
            >
                <Box height={'calc(100% - 48px)'}>
                    <ManufacturerOverviewTable />
                </Box>
            </LayoutCard>
        </PageLayout>
    );
}
