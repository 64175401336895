import { t } from '@lingui/macro';
import { colorSystem, Flexbox, Text } from '@luminovo/design-system';
import { Box } from '@mui/material';
import { PageLayout } from '../../components/PageLayout';
import { AuthPageIconLight } from './AuthPageIcon';
import { SignupBackground, SignupBackgroundProps } from './SignupBackground';

function TenantName({ tenantName, fontSize }: { tenantName: string; fontSize: number }): JSX.Element {
    return (
        <Text
            variant="h1"
            style={{
                fontSize,
                lineHeight: 1,
                zIndex: 1,
                maxWidth: 400,
            }}
            color={colorSystem.neutral.white}
        >
            {tenantName}
        </Text>
    );
}

function SubHeading({ text, fontSize }: { text: string; fontSize: string | number }): JSX.Element {
    return (
        <Text
            variant="h1"
            style={{
                fontSize,
                lineHeight: 1.2,
                zIndex: 1,
            }}
            color={colorSystem.neutral.white}
        >
            {text}
        </Text>
    );
}

export function AuthPage({
    isCustomerPortal,
    organizationLogoLink,
    tenantName,
    children,
    position = 'center',
    SignupBackgroundProps,
}: React.PropsWithChildren<{
    isCustomerPortal: boolean;
    organizationLogoLink: string | undefined;
    tenantName: string;
    position?: 'top' | 'center';
    SignupBackgroundProps?: SignupBackgroundProps;
}>) {
    return (
        <PageLayout layout="fragment" navbar={<></>} header={<></>} removeBottomPadding={true}>
            <Box
                minWidth={'1200px'}
                display="grid"
                gridTemplateColumns={'1fr 1fr'}
                alignItems={position}
                justifyItems={'center'}
                height="100vh"
            >
                <Flexbox
                    flexDirection={'column'}
                    maxWidth="392px"
                    padding="64px"
                    paddingTop={position === 'top' ? '88px' : '64px'}
                    gap={16}
                >
                    {children}
                </Flexbox>
                <Flexbox
                    width="100%"
                    height="100%"
                    flexDirection={'column'}
                    alignItems="center"
                    justifyContent={'start'}
                    boxSizing="border-box"
                    position={'relative'}
                    overflow="hidden"
                >
                    <SignupBackground {...SignupBackgroundProps} />
                    <Flexbox justifyContent="center" alignItems="center" height="100%" width="100%">
                        <Flexbox flexDirection="column" gap="40px" maxWidth="50%">
                            {isCustomerPortal && organizationLogoLink ? (
                                <AuthPageIconLight organizationLogoLink={organizationLogoLink} />
                            ) : (
                                <TenantName tenantName={isCustomerPortal ? tenantName : 'Luminovo'} fontSize={80} />
                            )}
                            <SubHeading
                                text={
                                    isCustomerPortal
                                        ? t`Customer portal`
                                        : t`The software suite for the electronics industry.`
                                }
                                fontSize={isCustomerPortal ? '48px' : '30px'}
                            />
                        </Flexbox>
                    </Flexbox>
                </Flexbox>
            </Box>
        </PageLayout>
    );
}
