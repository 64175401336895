import * as z from 'zod';
import { ApiIntegrationStatusRuntype } from './3rdPartyBackendTypes';

export interface EveCredentialsDTO extends z.infer<typeof EveCredentialsDTORuntype> {}
export const EveCredentialsDTORuntype = z.object({
    customer_number: z.string(),
    api_key: z.string(),
});

export interface EveCredentialsInputDTO extends z.infer<typeof EveCredentialsInputDTORuntype> {}
export const EveCredentialsInputDTORuntype = z.object({
    customer_number: z.string().optional(),
    api_key: z.string().optional(),
});

export interface EveResponseBodyDTO extends z.infer<typeof EveResponseBodyDTORuntype> {}
export const EveResponseBodyDTORuntype = z.object({
    data: EveCredentialsDTORuntype.nullable(),
    status: ApiIntegrationStatusRuntype,
});
