import * as z from 'zod';
import { SourcingCombinationCostsRuntype } from './calculationBackendTypes';

export const FrozenScenarioCostRuntype = z.object({
    id: z.string(),
    created_at: z.string(),
    calculation_assembly_details: z.string(),
    frozen_cost: SourcingCombinationCostsRuntype,
});

export const FrozenScenarioCostPostRuntype = FrozenScenarioCostRuntype.omit({ id: true, created_at: true });

export type FrozenScenarioCostDTO = z.infer<typeof FrozenScenarioCostRuntype>;

export const FrozenSourcingCombinationCostsRuntype = z.object({
    type: z.literal('Frozen'),
    value: FrozenScenarioCostRuntype,
});

export const CalculatedResponseRuntype = z.object({
    type: z.literal('Calculated'),
    value: SourcingCombinationCostsRuntype,
});
export type CalculatedResponseDTO = z.infer<typeof CalculatedResponseRuntype>;

export type CostResponseDTO = CalculatedResponseDTO | { type: 'Frozen'; value: FrozenScenarioCostDTO };

export const CostResponseRuntype: z.ZodType<CostResponseDTO> = z.union([
    CalculatedResponseRuntype,
    FrozenSourcingCombinationCostsRuntype,
]);
