import { CenteredLayout, FullWidthLayout, MaxWidthLayout, StickyLayout, colorSystem } from '@luminovo/design-system';
import { Box, BoxProps, CircularProgress, styled } from '@mui/material';
import * as Sentry from '@sentry/react';
import React from 'react';
import { ErrorFallback } from './errorHandlers/ErrorBoundary';
import { PageLayout } from './PageLayout';

type Props = {
    children: React.ReactNode;
    sidebar: React.ReactNode;
    header?: React.ReactNode;
    layoutVariant?: 'fullWidth' | 'maxWidth' | 'fragment';
} & BoxProps;

const MainContentBox = styled(Box)(() => ({
    boxSizing: 'border-box',
    position: 'relative',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    width: '100%',
    height: '100%',
}));

export function PageLayoutCollapsibleSidebar({ children, sidebar, layoutVariant = 'maxWidth', header }: Props) {
    const getLayoutComponent = () => {
        switch (layoutVariant) {
            case 'maxWidth':
                return MaxWidthLayout;
            case 'fullWidth':
                return FullWidthLayout;
            case 'fragment':
                return React.Fragment;
        }
    };
    const LayoutComponent = getLayoutComponent();

    return (
        <PageLayout layout="fragment" flexDirection="row" removeBottomPadding bgcolor={colorSystem.neutral[1]}>
            {sidebar}
            <MainContentBox>
                <Sentry.ErrorBoundary fallback={ErrorFallback}>
                    <React.Suspense
                        fallback={
                            <CenteredLayout>
                                <CircularProgress />
                            </CenteredLayout>
                        }
                    >
                        <StickyLayout>{header}</StickyLayout>
                        <LayoutComponent height={'100%'}>{children}</LayoutComponent>
                    </React.Suspense>
                </Sentry.ErrorBoundary>
            </MainContentBox>
        </PageLayout>
    );
}
