import * as z from 'zod';
import { endpoint } from '../http/endpoint';
import { PanelPreferencePostRuntype, PanelPreferenceRuntype } from './pcbPanelPreferenceBackendTypes';

export const pcbPanelPreferenceEndpoints = {
    'GET /pcb-panel-preferences': endpoint({
        description: 'Gets all PCB panel preferences',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.array(PanelPreferenceRuntype),
    }),

    'POST /pcb-panel-preferences': endpoint({
        description: 'Creates a new PCB panel preference',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: PanelPreferencePostRuntype,
        responseBody: PanelPreferenceRuntype,
        invalidates: ['GET /pcb-panel-preferences'],
    }),

    'PATCH /pcb-panel-preferences/:panelPreferenceId': endpoint({
        description: 'Updates a PCB panel preference',
        pathParams: z.object({
            panelPreferenceId: z.string(),
        }),
        queryParams: z.undefined(),
        requestBody: PanelPreferencePostRuntype,
        responseBody: PanelPreferenceRuntype,
        invalidates: ['GET /pcb-panel-preferences'],
    }),

    'DELETE /pcb-panel-preferences/:preferenceId': endpoint({
        description: 'Deletes a PCB panel preference',
        pathParams: z.object({
            preferenceId: z.string(),
        }),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: z.object({ deleted: z.number() }),
        invalidates: ['GET /pcb-panel-preferences'],
    }),

    'GET /organization-settings/default-pcb-panel-preferences': endpoint({
        description: 'Gets the default PCB panel preference for an organization',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.undefined(),
        responseBody: PanelPreferenceRuntype.nullable(),
    }),

    'POST /organization-settings/default-pcb-panel-preferences': endpoint({
        description: 'Sets the default PCB panel preference for an organization',
        pathParams: z.undefined(),
        queryParams: z.undefined(),
        requestBody: z.object({
            id: z.string().nullable(),
        }),
        responseBody: PanelPreferenceRuntype.nullable(),
        invalidates: ['GET /organization-settings/default-pcb-panel-preferences'],
    }),
};
