import { getToken } from '@luminovo/auth';
import { sortBy } from '@luminovo/commons';
import { ModuleIdentifier, ModuleIdentifierRuntype, http } from '@luminovo/http-client';
import { useQuery } from '@tanstack/react-query';
import { assertAgainstRuntype } from '../../utils/customConsole';
import { useBomTreeIds } from '../assembly/assemblyHandler';

export interface TextBreadcrumbWithAssemblyId {
    id: string;
    breadcrumb: string;
    assemblies: string[];
}

export const useAllBreadcrumbs = (assemblyId: string) => {
    const { data: treeData } = useBomTreeIds(assemblyId);
    const assemblyIds = treeData?.items;

    const {
        data: breadcrumbs = [],
        isLoading,
        isError,
    } = useQuery({
        queryKey: ['allAssemblyData', assemblyIds],
        queryFn: async () => {
            const responses = await Promise.all(
                (assemblyIds ?? []).map((assemblyId) =>
                    http('GET /assemblies/:assemblyId/parents', { pathParams: { assemblyId } }, getToken())
                        .then((data) => ({ data, assemblyId }))
                        .catch(() => null),
                ),
            );
            return responses.filter((response): response is NonNullable<typeof response> => response !== null);
        },
        select: (responses) => {
            const breadcrumbs = responses.map(({ data, assemblyId }) => {
                const parentsPath = data.multi_parent_items[0];
                const breadcrumb = parentsPath.map((d: ModuleIdentifier) => d.designator).join(' > ');
                const assemblies = parentsPath.map((d: ModuleIdentifier) => {
                    assertAgainstRuntype(d, ModuleIdentifierRuntype);
                    return d.id;
                });
                return { id: assemblyId, breadcrumb, assemblies };
            });
            return sortBy(breadcrumbs, (s) => s.breadcrumb);
        },
        enabled: assemblyIds !== undefined,
    });

    return { breadcrumbs, isLoading, isError };
};
