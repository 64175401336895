/* eslint-disable */
import { getToken } from '@luminovo/auth';
import {
    http,
    PriceType,
    QuantityUnit,
    QuoteTrackingDTO,
    StandardPartOfferBulkInputDTO,
    StandardPartTypes,
    ValidFor,
} from '@luminovo/http-client';
import { formatQuoteTrackingState } from '@luminovo/sourcing-core';
import { useSuspenseQuery } from '@tanstack/react-query';
import { OfferLineItem, ValidatedFormState } from '../../types';
import { convertLeadTimeToDays } from '../PdfViewer/model/RegionNetwork/parsers/LeadTime';
import { resolveOtsFullPartsRecursively } from './model';
import { PdfOfferImporter } from './PdfOfferImporter';
import { QuoteRequest } from './types';

type QuoteRequestWithQuoteTracking = QuoteRequest & { quoteTracking: QuoteTrackingDTO };

export function PdfQuoteTrackingImporterDeprecated(props: {
    rfqId: string;
    quoteTrackingId?: string;
    returnTo: string;
    onSuccess: (_: { quoteTrackingId: string; file: File }) => void;
    onError: () => void;
}): JSX.Element {
    const { data: customerId } = useSuspenseQuery({
        queryKey: ['rfq', props.rfqId],
        queryFn: () =>
            http('GET /rfqs/:rfqId', { pathParams: { rfqId: props.rfqId } }, getToken()).then(
                (data) => data.data.customer,
            ),
    });

    const {
        data: { quoteRequests, defaultQuoteRequest },
    } = useSuspenseQuery({
        queryKey: ['quote-requests', { rfqId: props.rfqId, customerId }],
        queryFn: () => {
            return http('GET /rfqs/:rfqId/quote-tracking', { pathParams: { rfqId: props.rfqId } }, getToken());
        },
        select: (
            data,
        ): {
            quoteRequests: QuoteRequestWithQuoteTracking[];
            defaultQuoteRequest: QuoteRequestWithQuoteTracking | undefined;
        } => {
            const quoteRequests = data.items.map(
                (item): QuoteRequestWithQuoteTracking => ({
                    id: item.id,
                    label: `${item.supplier_and_stock_location.supplier.name} #${item.number}`,
                    supplierAndStockLocation: item.supplier_and_stock_location.id,
                    status: formatQuoteTrackingState(item.state),
                    resolvedParts: item.request_standard_parts.flatMap((part) =>
                        resolveOtsFullPartsRecursively(part.kind),
                    ),
                    rfqId: item.rfq,
                    customerId: customerId,
                    quoteTracking: item,
                    quoteRequestLineItems: [],
                }),
            );

            const defaultQuoteRequest: QuoteRequestWithQuoteTracking | undefined =
                quoteRequests.find((quoteRequest) => quoteRequest.id === props.quoteTrackingId) ?? quoteRequests[0];

            return { quoteRequests, defaultQuoteRequest };
        },
    });

    return (
        <PdfOfferImporter
            onSuccess={({ quoteRequest, file }) => props.onSuccess({ quoteTrackingId: quoteRequest.id, file })}
            onError={props.onError}
            onImport={async (importProps) => {
                const { quoteRequest, rows } = importProps;

                const quoteTracking: QuoteTrackingDTO = (quoteRequest as QuoteRequestWithQuoteTracking).quoteTracking;

                await http(
                    'POST /offers/off-the-shelf/bulk/import',
                    {
                        requestBody: {
                            quote_tracking_id: quoteRequest?.id,
                            rfq_id: quoteTracking.rfq,
                            supplier_and_stock_location: quoteRequest?.supplierAndStockLocation,
                            inputs: rows.map((row) =>
                                convertPdfOfferLineItemToOfferImportInput(row, quoteTracking.rfq, importProps),
                            ),
                            event_metadata: {
                                type: 'Pdf',
                            },
                        },
                    },
                    getToken(),
                );
            }}
            initialFieldConfiguration={{
                requestedPart: { visible: false },
                partDescription: { visible: false },
                requiredQuantity: { visible: false },
                potentialQuantity: { visible: false },
                recipients: { visible: false },
                targetPrice: { visible: false },
                customerName: { visible: false },
                validFor: { visible: true },

                currency: { visible: true },
                moq: { visible: true },
                mpq: { visible: true },
                ncnr: { visible: true },
                notes: { visible: true },
                packaging: { visible: true },
                part: { visible: true },
                reach: { visible: false },
                rohs: { visible: false },
                standardFactoryLeadTime: { visible: true },
                standardFactoryLeadTimeUnit: { visible: true },
                stock: { visible: true },
                unitPrice: { visible: true },
                supplierPartNumber: { visible: true },
            }}
            quoteRequests={quoteRequests}
            defaultQuoteRequest={defaultQuoteRequest}
            returnTo={props.returnTo}
        />
    );
}

function convertPdfOfferLineItemToOfferImportInput(
    item: OfferLineItem,
    rfqId: string,
    props: ValidatedFormState,
): StandardPartOfferBulkInputDTO {
    const validForFields: { rfq_id: string | null; customer: string | null } = {
        [ValidFor.EveryCustomer]: {
            customer: null,
            rfq_id: null,
        },
        [ValidFor.ThisCustomer]: {
            customer: props.quoteRequest.customerId ?? null,
            rfq_id: null,
        },
        [ValidFor.ThisRfQ]: {
            customer: null,
            rfq_id: props.quoteRequest.rfqId ?? null,
        },
    }[props.validFor];

    const part = item.part;
    const unitPrice = item.unitPrice;
    const moq = item.moq;
    const mpq = item.mpq;
    const currency = item.currency;
    const standardFactoryLeadTime = item.standardFactoryLeadTime;
    const stock = item.stock ?? null;
    const ncnr = item.ncnr ?? null;
    const packaging = item.packaging ?? null;
    const notes = item.notes;
    const supplierPartNumber = item.supplierPartNumber ?? '';

    const priceBreakInput = {
        moq: moq ?? 1,
        unit_price: unitPrice ?? null,
        mpq: mpq ?? 1,
    };

    const factoryLeadTimeDays = convertLeadTimeToDays(standardFactoryLeadTime);

    return {
        requested_part: { type: StandardPartTypes.OffTheShelf, data: part!.id },
        linked_part: { type: StandardPartTypes.OffTheShelf, data: part!.id },
        supplier_part_number: supplierPartNumber,
        supplier_and_stock_location: props.quoteRequest.supplierAndStockLocation,
        price_type: PriceType.QuotePrice,
        customer: validForFields.customer,
        rfq_id: validForFields.rfq_id,
        notes,
        valid_until: props.validUntil ?? null,
        valid_from: props.validFrom ?? null,
        currency: currency ?? props.defaultCurrency,
        availability_input: {
            stock,
            factory_lead_time_days: factoryLeadTimeDays ?? null,
            factory_quantity: null,
            on_order: [],
        },
        price_break_inputs: [priceBreakInput],
        packaging,
        unit_of_measurement: {
            unit: QuantityUnit.Pieces,
            quantity: 1,
        },
        offer_number: props.offerNumber ?? null,
        attachment: props.attachment ?? null,
        ncnr,
    };
}
